import * as actionTypes from "./actionTypes";
import { toast } from "react-toastify";
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import api, { API } from "../../../../helper/api/api";

export const fetchUsersRequest = () => {
  return {
    type: actionTypes.FETCH_USERS_REQUEST,
  };
};

export const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCEEDED,
    result: users,
  };
};

export const fetchUsersFailed = (error) => {
  return {
    type: actionTypes.FETCH_USERS_FAILED,
    result: error,
  };
};

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(fetchUsersRequest());
    return api(API.users.get)
      .then((response) => {
        dispatch(fetchUsersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUsersFailed(error.message));
      });
  };
};

export const fetchListUsersRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_USERS_REQUEST,
  };
};

export const fetchListUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_LIST_USERS_SUCCEEDED,
    result: users,
  };
};

export const fetchListUsersFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_USERS_FAILED,
    result: error,
  };
};

export const fetchListUsers = () => {
  return (dispatch) => {
    dispatch(fetchListUsersRequest());
    return api(API.users.selectlist)
      .then((response) => {
        dispatch(fetchListUsersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListUsersFailed(error.message));
      });
  };
};

export const addUserSuccess = (user) => {
  return {
    type: actionTypes.ADD_USER_SUCCEEDED,
    result: user,
  };
};

export const changeUserRoleRequest = (userRole) => {
  return {
    type: actionTypes.CHANGE_ROLE_REQUEST,
    result: userRole,
  };
};

export const changeUserRoleSuccess = () => {
  return {
    type: actionTypes.CHANGE_ROLE_SUCCEEDED,
  };
};

export const changeUserRoleFailed = (error) => {
  return {
    type: actionTypes.CHANGE_ROLE_FAILED,
    result: error,
  };
};

export const changeUserRole = (userRole, prevUserRole) => {
  return (dispatch) => {
    dispatch(changeUserRoleRequest(userRole));

    return api(API.users.changeRole, userRole)
      .then((response) => {
        dispatch(changeUserRoleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          changeUserRoleFailed({
            prevState: prevUserRole,
            message: error.message,
          })
        );
        toast.error(toastMessages.users.errorChangeUserRole);
      });
  };
};

export const removeUserRequest = () => {
  return {
    type: actionTypes.REMOVE_USER_REQUEST,
  };
};

export const removeUserSuccess = (user) => {
  return {
    type: actionTypes.REMOVE_USER_SUCCEEDED,
    result: user,
  };
};

export const removeUserFailed = (error) => {
  return {
    type: actionTypes.REMOVE_USER_FAILED,
    result: error,
  };
};

export const removeUser = (user) => {
  return (dispatch) => {
    dispatch(removeUserRequest(user));

    return api(API.users.remove, user)
      .then((response) => {
        dispatch(removeUserSuccess(user));
      })
      .catch((error) => {
        dispatch(removeUserFailed(error.message));
        toast.error(toastMessages.users.errorRemoveUser);
      });
  };
};
