import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";

//Redux
import {
  changeBackToUnresolve,
  changeToResolveSuccess,
  changeToResolveFailed,
  fetchListOfProspects,
  clearListOfProspects,
} from "../../../store/ResponderInbox/actions/responderInbox";
import { fetchResponderInbox, setMessageResolved } from "../../../store/ResponderInbox/Table/actions/responderInboxTable";

//Helpers
import { responseTypeOptions, responseTypesAllowedToAddToExclusions } from "../helpers/responseTypeOptions";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import api, { API, formUrl } from "../../../helper/api/api";
import { errorResponseToList } from "../../../helper/errorHelper";
import { permissionHelper } from "../../../helper/permissionHelper";
import { dateToUTC } from "@helper/dateHelper";

// Styles
import styles from "./ResponseType.module.scss";

//Icons
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import MessageResponseType from "../../../assets/Icons/SectionResponseType/MessageResponseType";

//Components
import Button from "../../../components/UI/Button/Button";
import FormikSelect from "../../../components/UI/Formik/FormikSelect";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import ProspectInfoCard from "../../../components/UI/ProspectInfoCard/ProspectInfoCard";
import UserPhoto from "../../../components/UI/UserPhoto/UserPhoto";
import formikStyles from "../../../components/UI/Formik/FormikInput.module.scss";
import FormikDatePicker from "@components/UI/Formik/FormikDatePicker";
import Select from "../../../components/UI/Select/Select";
import Permission from "../../../components/auth/Permission";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import ResponseTypeButton from "./ResponseTypeButton/ResponseTypeButton";
import AISuggestion from "../AISuggestion/AISuggestion";
import { responseTypeStringEnum } from "../../../helper/enums/responseTypeEnum";
import IconManualChange from "../../../assets/Icons/IconManualChange/IconManualChange";
import { addDays } from "date-fns";
import { ModeComment } from "@mui/icons-material";

const ResponseType = (props) => {
  const { activeTab, messagePreview, listOfProspects, params, setMessageResolved } = props;
  const [activeElementIndex, setActiveElementIndex] = useState(
    messagePreview ? responseTypeOptions.findIndex((type) => type.value === messagePreview.responseType) : -1
  );

  const defaultPreview = {
    id: undefined,
    referralEmails: [],
    prospectId: undefined,
    type: undefined,
    sendAfterDateOOO: addDays(new Date(), 10),
    exclusionType: 0,
    autoresolveResponseType: undefined,
    isAutoMatched: undefined,
    isResolved: undefined,
    previousResponseType: undefined,
  };

  const checkedMessagePreview = { ...defaultPreview, ...messagePreview };

  useEffect(() => {
    if (messagePreview) {
      setActiveElementIndex(responseTypeOptions.findIndex((type) => type.value === messagePreview.type));
      if (!_.isEmpty(messagePreview.referralEmail)) setIsReferral(true);
      else setIsReferral(false);
    }
  }, [messagePreview]);

  useEffect(() => {
    if (messagePreview.type === 7 && activeTab === 1) {
      setIsOutOfOffice(true);
    } else {
      setIsOutOfOffice(false);
    }

    if (messagePreview.type === 4) {
      setShowAddToExclusion(true);
      setAddToExclusionChecked(true);
    } else {
      setShowAddToExclusion(false);
      setAddToExclusionChecked(false);
    }
  }, [messagePreview.type]);

  useEffect(() => {
    if (messagePreview && messagePreview.prospectId > 0) {
      api(
        formUrl(API.conversation.getProspectDetails, {
          id: messagePreview.prospectId,
        })
      )
        .then((response) => {
          setProspect(response.data);
        })
        .catch((error) => {
          setProspect(null);
        });
    } else {
      setProspect(null);
    }
  }, [messagePreview]);

  const prospectSelectionChanged = (id) => {
    if (id != null) {
      api(formUrl(API.conversation.getProspectDetails, { id: id }))
        .then((response) => {
          setProspect(response.data);
        })
        .catch((error) => {
          setProspect(null);
        });
    }
  };

  const handleUnresolveClick = () => {
    props
      .changeBackToUnresolve(messagePreview)
      .then((res) => {
        props.fetchResponderInbox(params, activeTab);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isReferral, setIsReferral] = useState(false);

  const [showAddToExclusion, setShowAddToExclusion] = useState(false);
  const [addToExclusionChecked, setAddToExclusionChecked] = useState(false);

  const [isOutOfOffice, setIsOutOfOffice] = useState(false);
  const [prospect, setProspect] = useState(null);
  const [changedResponse, setChangedResponse] = useState(false);

  const searchProspects = (query) => {
    if (query.length > 1) {
      props.fetchListOfProspects(messagePreview.emailAccountId, query);
    } else {
      props.clearListOfProspects();
    }
  };

  const prospectMatched = (checkedMessagePreview.isResolved || checkedMessagePreview.isAutoMatched) && prospect != null;

  return (
    <Formik
      initialValues={{
        Id: checkedMessagePreview.id,
        Tags: checkedMessagePreview.referralEmails,
        ProspectId: checkedMessagePreview.prospectId,
        ResponseType: checkedMessagePreview.type,
        sendAfterDateOOO: moment(checkedMessagePreview.sendAfterDateOOO).isValid()
          ? moment(checkedMessagePreview.sendAfterDateOOO).format("DD MMM YYYY")
          : moment(addDays(new Date(), 10)).format("DD MMM YYYY"),
        ExclusionType: checkedMessagePreview.exclusionType,
        AutoresolveResponseType: checkedMessagePreview.autoresolveResponseType,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        ProspectId: Yup.number().typeError("Please choose a valid prospect.").required("Please choose a valid prospect."),
        ResponseType: Yup.number()
          .typeError("Please choose a response type.")
          .required("Please choose a response type.")
          .min(1, "Please choose a response type."),
      })}
      onSubmit={(values, actions) => {
        const formData = new FormData();
        formData.append("id", values.Id);
        formData.append("responseType", values.ResponseType);
        formData.append("prospectId", values.ProspectId);
        if (values.AutoresolveResponseType) formData.append("autoresolveResponseType", values.AutoresolveResponseType);
        for (let index = 0; index < values.Tags.length; index++) {
          formData.append(`referralEmails[${index}]`, values.Tags[index]);
        }
        let date = dateToUTC(values.sendAfterDateOOO).format("YYYY-MM-DD");
        formData.append("sendAfterDateOOO", date);

        // Check if this response type is allowed to add exclusions
        // Check if exclusion type is 1. Email or 2. Domain
        if (
          responseTypesAllowedToAddToExclusions.includes(values.ResponseType) &&
          (values.ExclusionType === 1 || values.ExclusionType === 2)
        ) {
          formData.append("ExclusionType", values.ExclusionType);
        }

        let data = {
          id: values.Id,
          responseType: values.ResponseType,
        };

        api(API.responderInbox.resolve, formData)
          .then((response) => {
            setMessageResolved(data);
            props.changeToResolveSuccess(values);
            setChangedResponse(false);
            props.fetchResponderInbox(params, activeTab);
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              props.changeToResolveFailed(error);
              toast.error(error.message);
            }
            setChangedResponse(false);
          })
          .finally(() => {
            actions.setSubmitting(false);
            if (activeTab === 0) {
              props.fetchResponderInbox(params, activeTab);
              setActiveElementIndex(null);
            }
            setChangedResponse(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, values } = formikProps;

        const changeAddToExclusionValue = (event) => {
          setFieldValue("ExclusionType", event);
        };

        const handleAddToExclusionChange = (value) => {
          setAddToExclusionChecked(value);
          if (value) {
            setFieldValue("ExclusionType", 1);
          } else {
            setFieldValue("ExclusionType", 0);
          }
        };

        return (
          <>
            {props.responseAdminClose ? (
              <div className={styles.headerSectionClosed}>
                <p className={styles.sectionTitleHide}>Response Admin</p>
                <div className="flex_center flexCol">
                  <ArrowLeftSLineIcon
                    className={`circleToggleICon cursor_pointer iconGray400 ${styles.arrowIcon}`}
                    onClick={() => props.setResponseAdminClose(!props.responseAdminClose)}
                  />
                  <MessageResponseType imgStyle="mT5" />
                </div>
              </div>
            ) : (
              <Form
                className={`${styles.responseAdminForm} responseAdminForm`}
                onKeyPress={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              >
                <div className={styles.stickyWrapper}>
                  <SectionTitle
                    titleClass={styles.sectionTitle}
                    icon={<MessageResponseType />}
                    title={"Response Admin"}
                    rightContentClass="textRight"
                    rightContent={
                      <ArrowLeftSLineIcon
                        className={`circleToggleICon cursor_pointer iconGray400 ${styles.transformRight}`}
                        onClick={() => props.setResponseAdminClose(!props.responseAdminClose)}
                      />
                    }
                  />
                  <Field value={values.Id} type="hidden" />

                  {prospectMatched ? (
                    <label className={styles.infoMessage}>
                      This response has been <b>{checkedMessagePreview.isAutoMatched ? "auto-matched" : "manually matched"}</b> with the
                      following prospect:{" "}
                    </label>
                  ) : null}

                  {prospect != null ? (
                    <ProspectInfoCard
                      image={<UserPhoto alt="org-photo" isCompanyImage={true} size="medium" />}
                      name={prospect.fullName}
                      jobTitle={prospect.jobTitle}
                      company={prospect.cleanCompanyName ? prospect.cleanCompanyName : prospect.companyName}
                      email={prospect.email}
                    />
                  ) : null}

                  {checkedMessagePreview.isAutoMatched ? null : (
                    <>
                      <h4 className={styles.sectionTitle}>{prospectMatched ? "Map to other prospect" : "Map to prospect"}</h4>
                      <div className={`${styles.matchEmail} flex`}>
                        <FormikSelect
                          name="ProspectId"
                          placeholder="Prospect..."
                          options={listOfProspects}
                          isSearchable={true}
                          isAutoComplete={true}
                          onChange={(event) => prospectSelectionChanged(event)}
                          onInputChange={(e) => searchProspects(e)}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={`${styles.responsesWrapper} ${
                    activeTab === 0 && !changedResponse
                      ? styles.unresolvedView
                      : activeTab === 0 && changedResponse
                      ? styles.unresolvedViewResolveButton
                      : activeTab === 1 && changedResponse
                      ? styles.buttonResolveVisible
                      : styles.resolvedView
                  }`}
                >
                  {(permissionHelper(props.userRole, PERMISSIONS.responderInbox.edit) || activeTab === 1) && (
                    <>
                      <h3 className={styles.sectionTitle}>Categorize this response</h3>
                      {checkedMessagePreview.isResolved ? null : <AISuggestion messagePreview={messagePreview} />}
                      <div className={styles.mapWrapperContainer}>
                        {responseTypeOptions.map((type, index) => {
                          return (
                            <ResponseTypeButton
                              type={type}
                              index={index}
                              activeTab={activeTab}
                              activeElementIndex={activeElementIndex}
                              setIsReferral={setIsReferral}
                              setShowAddToExclusion={setShowAddToExclusion}
                              setIsOutOfOffice={setIsOutOfOffice}
                              setChangedResponse={setChangedResponse}
                              setFieldValue={setFieldValue}
                              key={type.value}
                              setActiveElementIndex={setActiveElementIndex}
                            />
                          );
                        })}
                      </div>
                      <div className={styles.unsubscribeEmailNotificationsInput}>
                        <div className={`${styles.horizontalFormInput} flex`}>
                          <>
                            <div
                              className={`${styles.matchEmail} flex`}
                              style={{
                                flexDirection: "column",
                              }}
                            >
                              {checkedMessagePreview.previousResponseType && (
                                <div>
                                  <div className={`${styles.previousResponseTypeWrapper} flex align-center`}>
                                    <IconManualChange imgStyle={`mR5 ${styles.manualChangeIcon}`} />
                                    <div>
                                      <p>The Response has been manually updated</p>
                                      <p>
                                        {responseTypeStringEnum[messagePreview.previousResponseType]} <ArrowRightLineIcon size={16} />{" "}
                                        {responseTypeStringEnum[messagePreview.type]}
                                      </p>
                                    </div>
                                  </div>
                                  <p className={styles.previousResponseDate}>
                                    *Updated: {messagePreview.modifiedResponseTypeDate.split(" ")[0]} by:{" "}
                                    {messagePreview.modifiedResponseTypeBy}
                                  </p>
                                </div>
                              )}
                              {isOutOfOffice && (
                                <>
                                  <h3 className={styles.sectionTitle}>Send next email in campaigns sequence after</h3>
                                  <div className={`${styles.afterDaysInput} flex align-center`}>
                                    <FormikDatePicker
                                      formgroupclass={styles.dateForm}
                                      datePickerClass={styles.formInput}
                                      name="sendAfterDateOOO"
                                      selected={moment(values.sendAfterDateOOO, "DD MMM YYYY").toDate()}
                                      value={values.sendAfterDateOOO}
                                      dateFormat="dd MMM yyyy"
                                      minDate={moment().toDate()}
                                      onChange={() => setChangedResponse(true)}
                                    />
                                  </div>
                                </>
                              )}

                              {showAddToExclusion && (
                                <>
                                  <Checkbox
                                    checkBoxClass={styles.checkBoxWrapper}
                                    label={"Add to domain exclusions"}
                                    checked={addToExclusionChecked}
                                    onChange={(value) => {
                                      setChangedResponse(true);
                                      handleAddToExclusionChange(value);
                                      if (value) {
                                        changeAddToExclusionValue(2);
                                      }
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        </div>
                      </div>
                      <Permission has={PERMISSIONS.responderInbox.edit}>
                        <div
                          className={
                            (activeTab === 0 && changedResponse) || (activeTab === 1 && changedResponse) || activeTab !== 0
                              ? `${styles.emailsWrapper} flex`
                              : " "
                          }
                        >
                          {(activeTab === 0 && changedResponse) || (activeTab === 1 && changedResponse) ? (
                            <Button
                              id="resolve"
                              disabled={isSubmitting}
                              onClick={handleSubmit}
                              type="submit"
                              variant="primary"
                              classes={styles.buttonSm}
                            >
                              Resolve
                            </Button>
                          ) : null}
                          {activeTab === 0 ? null : (
                            <Button
                              id="unResolve"
                              classes={`${styles.unresolvedButton} textTransformInherit`}
                              variant="danger-outline"
                              type="button"
                              onClick={handleUnresolveClick}
                            >
                              Unresolve
                            </Button>
                          )}
                        </div>
                      </Permission>
                      <div className={formikStyles.errorRelative}>
                        <ErrorMessage name="ResponseType" />
                      </div>
                    </>
                  )}
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.responderInbox.activeTab,
    messagePreview: state.responderInbox.messagePreview,
    params: state.responderInboxTable.params,
    listOfProspects: state.responderInbox.listOfProspects,
    userRole: state.user.organization.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListOfProspects: (emailAccountId, query) => dispatch(fetchListOfProspects(emailAccountId, query)),
    clearListOfProspects: () => dispatch(clearListOfProspects()),
    fetchResponderInbox: (param, activeTab) => dispatch(fetchResponderInbox(param, activeTab)),
    changeBackToUnresolve: (messagePreview) => dispatch(changeBackToUnresolve(messagePreview)),
    changeToResolveSuccess: (message) => dispatch(changeToResolveSuccess(message)),
    changeToResolveFailed: (error) => dispatch(changeToResolveFailed(error)),
    setMessageResolved: (data) => dispatch(setMessageResolved(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseType);
