export const responseTypeOptions = [
  { value: 1, label: "Positive response" },
  { value: 2, label: "Referral" },
  { value: 3, label: "Deferred interest" },
  { value: 4, label: "Decline" },
  { value: 5, label: "No longer with business" },
  { value: 6, label: "Undelivered" },
  { value: 7, label: "Out of office" },
  { value: 9, label: "Ignore & archive" },

  // Keeping this comment for historic data
  // { value: 8, label: "Add to exclusions" },
];

export const responseTypesAllowedToAddToExclusions = [4];
