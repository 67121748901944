import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

//Helpers
import api, { API } from "../../../../../helper/api/api";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";
import { statusCodes } from "../../../../../helper/enums/statusCodes";
import { calculateDiscountedPrice } from "../../../../../helper/pricingPlanHelper";

// Styles
import styles from "./CheckoutModal.module.scss";

//Images & Icons
import LockFillIcon from "remixicon-react/LockFillIcon";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import SummaryCard from "../../../../Pricing/components/SummaryCard/SummaryCard";
import PaymentDetails from "../../../../Pricing/components/PaymentDetails/PaymentDetails";
import FormikEffect from "../../../../../components/UI/Formik/FormikEffect";
import ReadOnlyInput from "../../../../../components/ReadOnlyInput/ReadOnlyInput";

const CheckoutModal = (props) => {
  const {
    billingInfo,
    planId,
    pricePointId,
    planChargifyHandle,
    pricePointChargifyHandle,
    currentPlan,
    price,
    credits,
    currencySign,
    typeOfBilling,
    creditCardInfo,
    billingPeriod,
    users,
    fullPrice,
    period,
    createBillingInfo,
    coupon,
  } = props;

  let renderPage = null;

  const [token, setToken] = useState();
  const [getToken, setGetToken] = useState(false);

  const [discountPrice, setDiscountPrice] = useState(fullPrice);
  const firstCardEver = props.creditCardInfo === null || props.creditCardInfo === undefined;
  const [isNewCard, setIsNewCard] = useState(firstCardEver || false);

  useEffect(() => {
    if (coupon != null) {
      let priceDiscount = calculateDiscountedPrice(fullPrice, coupon);
      setDiscountPrice(priceDiscount);
    }
  }, [coupon]);

  return (
    <>
      <Helmet>
        <script src="https://js.chargify.com/latest/chargify.js"></script>
      </Helmet>
      <Formik
        initialValues={{}}
        enableReinitialize={billingInfo !== null}
        validationSchema={Yup.object({})}
        onSubmit={(values, actions) => {
          let obj = {
            token: token,
            billingInfo: billingInfo,
            //  updateBillingInfo: updateBillingInfo,
            planChargifyHandle: planChargifyHandle,
            pricingPointChargifyHandle: pricePointChargifyHandle,
            pricingPlan: planId,
            pricingPoint: pricePointId,
            billingPeriod: billingPeriod,
            createBillingInfo: createBillingInfo,
            couponCode: coupon?.code,
          };

          api(API.billingAndPayment.upgradePricingPlan, obj)
            .then((res) => {
              props.handleClose();
              actions.setSubmitting(false);
              toast.success(toastMessages.billing.successUpgradePricingPlanCall);
            })
            .catch((error) => {
              if (error.response.status != null && error.response.status == statusCodes.unprocessableEntity) {
                var url =
                  error.response.data.action_link +
                  "&callback_url=" +
                  import.meta.env.VITE_SERVER_URL +
                  "/chargify/callback&redirect_url=" +
                  import.meta.env.VITE_LOCAL_URL +
                  "/" +
                  props.orgId +
                  "/organization-settings/billing-and-payment";

                window.location.href = url;
              } else {
                let errorMessage = null;
                if (error?.response?.data?.errors?.length > 0) errorMessage = error?.response?.data?.errors[0];
                if (errorMessage) toast.warn(errorMessage, { autoClose: false });
                else toast.error(error.message, { autoClose: false });
              }
              props.handleClose();
              actions.setSubmitting(false);
            })
            .finally(() => {
              props.handleClose();
              actions.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          const { handleSubmit, values, isSubmitting } = formikProps;
          const handleSubmitWholeForm = () => {
            setGetToken(true);
          };

          const handleReturnToken = (token) => {
            //if its an invalid card
            if (token.errors != null) {
              toast.error(token.errors);
            } else {
              //if we all ready have credit card info
              if (creditCardInfo != null && !isNewCard) {
                //there is no change in the credit card submit form no token
                if (token?.invalidFields?.length === 5 && token?.message != null) {
                  setToken("noToken");
                  handleSubmit();
                } else {
                  //we have an error show it to the user
                  if (token.message != null) {
                    toast.error(token.message);
                  } else {
                    setToken(token);
                    handleSubmit();
                  }
                }
              } //first time input credit card
              else {
                //we have an error show it to the user
                if (token.message != null) {
                  toast.error(token.message);
                } //submit form with token
                else {
                  setToken(token);
                  handleSubmit();
                }
              }
            }
          };

          renderPage = (
            <PaymentDetails
              click={handleSubmit}
              setGetToken={setGetToken}
              getToken={getToken}
              isSubmitting={isSubmitting}
              handleReturnToken={handleReturnToken}
              creditCardInfo={creditCardInfo}
              isNewCard={isNewCard}
              setIsNewCard={setIsNewCard}
              firstCardEver={firstCardEver}
            />
          );

          return (
            <Modal
              title={"Payment"}
              handleClose={props.handleClose}
              handleSave={() => handleSubmitWholeForm()}
              show={props.showModal}
              closeButtonText={"Cancel"}
              saveButtonText={"Confirm"}
              btnType="submit"
              backdrop={true}
              dialogClassName={`${styles.paymentModal} customModal`}
              size="lg"
              saveButtonDisabled={isSubmitting}
            >
              <Form>
                <FormikEffect />
                <div className={`${styles.modalFormWrapper}`}>
                  <div className="flex">
                    <div className={styles.stepperWrapper}>
                      <h1>Payment</h1>
                      {renderPage}
                    </div>
                    <div className={styles.summaryWrapper}>
                      <SummaryCard
                        createBillingInfo={createBillingInfo}
                        currentPlan={currentPlan}
                        license="Free"
                        credits={credits}
                        users={users}
                        totalPrice={typeOfBilling ? fullPrice : price}
                        period={period}
                        currencySign={currencySign}
                        typeOfBilling={typeOfBilling}
                        coupon={coupon}
                        discountPrice={discountPrice}
                        couponDiscountedFullPrice={discountPrice}
                      />
                      {coupon != null ? <ReadOnlyInput label="Coupon" readOnlyLabelClass="mT15" value={coupon.code} /> : null}
                    </div>
                  </div>
                  <div className={styles.secureDescriptionWrapper}>
                    <LockFillIcon size={18} className="iconGray400" />
                    <p className={styles.descriptionText}>This is a secured 128-bit SSL encryption payment.</p>
                  </div>
                </div>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default CheckoutModal;
