// Helpers
import { CreateCampaignModel } from "@models/assistant/CreateCampaignModel";
import { api } from "./api";

// Models
import {
  AssistantExecutionModel,
  StartExecutionModel,
  LoadStepDataModel,
  SaveStepDataModel,
  SummaryResponseModel,
} from "@models/assistant/AssistantModels";

export const assistantApi: any = api.injectEndpoints({
  endpoints: (builder) => ({
    startExecution: builder.mutation<AssistantExecutionModel, StartExecutionModel>({
      query: (model) => ({
        url: "/assistant/execution",
        method: "POST",
        body: model,
      }),
    }),
    loadData: builder.mutation<Object, LoadStepDataModel>({
      query: (model) => ({
        url: "/assistant/step/load",
        method: "POST",
        body: model,
      }),
    }),
    saveData: builder.mutation<{ searchKey?: string }, SaveStepDataModel>({
      query: (model) => ({
        url: "/assistant/step/save",
        method: "POST",
        body: model,
      }),
    }),
    quit: builder.mutation<string, number>({
      query: (executionId) => ({
        url: "assistant/quit",
        method: "POST",
        body: executionId,
      }),
    }),
    complete: builder.mutation<string, number>({
      query: (executionId) => ({
        url: "/assistant/complete",
        method: "POST",
        body: executionId,
      }),
    }),
    create: builder.mutation<string, CreateCampaignModel>({
      query: (model) => ({
        url: "/assistant/create-campaign",
        method: "POST",
        body: model,
      }),
    }),
    getSummary: builder.query<SummaryResponseModel, string>({
      query: (url) => ({
        url: `/assistant/summary?url=${url}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useStartExecutionMutation,
  useSaveDataMutation,
  useLoadDataMutation,
  useCreateMutation,
  useCompleteMutation,
  useQuitMutation,
  useGetSummaryQuery,
} = assistantApi;
