import React, { useState } from "react";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";

//Helpers
import api, { API } from "../../../../helper/api/api";
import { outbossUserTypeOptions } from "../../../../helper/enums/outbossUserTypeEnum";

//Styles
import styles from "../Users.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const EditUserInfoModal = (props) => {
  const { userId, user } = props;
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  let render = null;

  if (user.userType == undefined) {
    render = <Spinner />;
  } else {
    render = (
      <Form>
        <div className={`${styles.contentBody} flex`}>
          <div className={styles.leftForm}>
            <p className={styles.details}>
              <span className={styles.label}>First Name: </span>
              <span className={styles.dataDetails}>{user.firstName}</span>
            </p>
            <p className={styles.details}>
              <span className={styles.label}>Last Name</span> <span className={styles.dataDetails}>{user.lastName}</span>
            </p>
          </div>
          <div className={styles.variablesWrapperForm}>
            <p className={styles.details}>
              <span className={styles.label}>Email Address</span> <span className={styles.dataDetails}>{user.email}</span>
            </p>
            <p className={styles.details}>
              <span className={styles.label}>Job Title </span>
              <span className={styles.dataDetails}>{user.jobTitle}</span>
            </p>
          </div>
        </div>
        <div className={`${styles.actionBody} flex`}>
          <div className={styles.userTypeDiv}>
            <FormikSelect
              label="User Type"
              name="userType"
              options={outbossUserTypeOptions}
              value={user.userType}
              onChange={(e) => {
                setSaveButtonDisabled(false);
              }}
              isMenuPortalTargetDisabled={true}
            />
          </div>
        </div>
      </Form>
    );
  }

  return (
    <Formik
      initialValues={{
        userTypeValue: user.userType,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.email,
        jobTitle: user.jobTitle,
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        let data = {
          userId: user.id,
          userType: values.userType,
        };
        api(API.OUTBOSS.users.changeUserType, data)
          .then((res) => {
            toast.success("User type changed");
            props.fetchUsers();
            props.handleClose();
            setSaveButtonDisabled(true);
          })
          .catch((error) => {
            toast.error(error.message);
            setSaveButtonDisabled(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldError } = formikProps;
        return (
          <Modal
            title="Edit User"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            btnType="submit"
            saveButtonText="Save"
            closeButtonText="Cancel"
            size="lg"
            saveButtonDisabled={saveButtonDisabled}
          >
            {render}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditUserInfoModal;
