import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonCampaignPerformance = () => {
  return (
    <div className="skeleton-wrapper">
      <SkeletonElement type="text" />
      <div className="flex width_100">
        <SkeletonElement type="text-26" />
        <SkeletonElement type="text-26" />
      </div>
      <SkeletonElement type="text-44" />
      <Shimmer />
    </div>
  );
};

export default SkeletonCampaignPerformance;
