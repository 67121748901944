import React from "react";
import { Modal as BootsrapModal, Container, Row, Col } from "react-bootstrap";

//Styles
import styles from "./AddOrEditCardModal.module.scss";

//Images & Icons
import CloseLineIcon from "remixicon-react/CloseLineIcon";

//Components
import Button from "../../../../components/UI/Button/Button";

const AddOrEditCardModal = (props) => {
  let backdrop = "static";
  if (props.backdrop == true) {
    backdrop = true;
  }

  return (
    <BootsrapModal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName={styles.dialog}
      classes={styles.modalContent}
      enforceFocus={false}
      backdrop={backdrop}
    >
      <BootsrapModal.Header className={styles.header}>
        <h1>Edit Card</h1>
        <CloseLineIcon size={20} onClick={props.handleClose} className="cursor_pointer" />
      </BootsrapModal.Header>
      <BootsrapModal.Body className={styles.body}>{props.children}</BootsrapModal.Body>
      <BootsrapModal.Footer className={styles.footer}>
        <Container>
          <Row>
            <Col xs={12} className={styles.footerCustomStyle}>
              <Button id={props.closeButtonText.replaceAll(" ", "")} variant="secondary" onClick={props.handleClose}>
                {props.closeButtonText}
              </Button>
              <Button
                id={props.saveButtonText.replaceAll(" ", "")}
                variant="primary"
                onClick={props.handleSave}
                type={props.btnType}
                disabled={props.saveButtonDisabled}
              >
                {props.saveButtonText}
              </Button>
            </Col>
          </Row>
        </Container>
      </BootsrapModal.Footer>
    </BootsrapModal>
  );
};

export default AddOrEditCardModal;
