import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { toast } from "react-toastify";
import { connect } from "react-redux";

//Redux
import {
  fetchCompaniesProperties,
  fetchContactsProperties,
  fetchDealsPipelineById,
  fetchDealsPipelines,
  fetchDealsProperties,
  fetchRulesCustomFields,
} from "../../../../store/CRM-Integrations/HubSpot/Global/actions/hubspot-integration";

//Helpers
import api, { API, formUrl } from "../../../../helper/api/api";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { actionsOptions, actionsOptionsGrouped } from "../../Trigger-ActionsOptions/actionsOptions";
import { triggerOptions } from "../../Trigger-ActionsOptions/triggerOptions";

//Style
import styles from "./PredefinedRules.module.scss";

//Images & Icons
import CombinationMarkLogoDark from "../../../../assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";
import HubSpotLogo from "../../../../assets/Images/CRM/HubSpotLogo/HubSpotLogo";

//Components
import Button from "../../../../components/UI/Button/Button";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import Spinner from "../../../../components/UI/Spinner/Spinner";

import * as Yup from "yup";

const PredefinedRules = (props) => {
  const { dealsPipelines, fetchDealsPipelines, fetchDealsPipelineById, dealStages, isLoadingProps } = props;

  const [mappingKey, setMappingKey] = useState("");
  const [description, setDescription] = useState("");
  const [actionCode, setActionCode] = useState(0);
  const [triggerType, setTriggerType] = useState(-1);
  const [pipelineId, setPipelineId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [groupedActions, setGroupedActions] = useState([]);

  const [dealsPipelineState, setDealsPipelineState] = useState([]);
  const [dealStagesState, setDealStagesState] = useState([]);

  const [leadStatusState, setLeadStatusState] = useState([]);
  const [lifecycleStageState, setLifecycleState] = useState([]);
  const [isFinished, setIsFinished] = useState(false);

  const getFieldsState = (key) => {
    api(
      formUrl(API.integrations.hubspot.getRulesCustomFields, {
        key: key,
        fieldName: "hs_lead_status,lifecyclestage",
      })
    ).then((response) => {
      if (response.data) {
        setLeadStatusState(response.data["hs_lead_status"]);
        setLifecycleState(response.data["lifecyclestage"]);
        setIsFinished(true);
      }
    });
  };

  useEffect(() => {
    if (mappingKey === "deals") {
      fetchDealsPipelines();
    } else if (mappingKey === "companies") {
      // fetchCompaniesProperties();
      getFieldsState(mappingKey);
    } else if (mappingKey === "contacts") {
      // fetchContactsProperties();
      getFieldsState(mappingKey);
    }
  }, [mappingKey]);

  useEffect(() => {
    if (!_.isUndefined(dealsPipelines) && dealsPipelines.length > 0 && mappingKey === "deals") {
      setDealsPipelineState(dealsPipelines);
    }
  }, [dealsPipelines, mappingKey]);

  useEffect(() => {
    if (!_.isUndefined(dealStages) && dealStages.length > 0 && mappingKey === "deals") {
      setDealStagesState(dealStages);
    }
  }, [dealStages, mappingKey]);

  useEffect(() => {
    if (mappingKey === "deals" && pipelineId !== "") {
      fetchDealsPipelineById(pipelineId);
    }
  }, [mappingKey, pipelineId]);

  useEffect(() => {
    let list = [];
    let item = actionsOptionsGrouped.find((x) => x.eventValue === triggerType);
    if (item) list = item.actions;
    setGroupedActions(list);
  }, [triggerType]);

  // const breadcrumbs = [
  //   {
  //     // href: "/" + organizationId + '/integrationRules',
  //     text: 'Integration rules'
  //   },
  //   { active: true, text: "Add new" },
  // ];

  const selectActionChange = (event) => {
    let action = actionsOptions.find((action) => action.value === event);

    if (!_.isNull(action) && action.hasOwnProperty("actionRelated")) setMappingKey(action.actionRelated);

    setDescription(action.description);
    setActionCode(action.value);
  };

  return (
    <div className={styles.fieldsWrapper}>
      <div className={styles.componentWrapper}>
        <Formik
          initialValues={{
            eventType: triggerType,
            actionName: "",
            actionCode: actionCode,
            actionDescription: "",
            additionalMappingProperties: {},
            dealsProperties: {},
            mappingKey: mappingKey,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            eventType: Yup.number("Choose valid event").notOneOf([-1], "Choose valid event").required("Required"),
            actionCode: Yup.number("Choose valid event").notOneOf([0], "Choose valid action").required("Required"),
            // additionalMappingProperties: Yup.object().shape({
            //   leadStatus: Yup.string()
            //     .notOneOf([""], "Choose valid value")
            //     .required("Required")
            // })
          })}
          onSubmit={(values, actions) => {
            if (values.eventType === -1 || values.actionCode === 0) return;
            if (mappingKey === "deals") values.additionalMappingProperties = null;
            else values.dealsProperties = null;

            let model = {
              eventType: triggerType,
              actionCode: actionCode,
              description: description,
              additionalMappingProperties: values.additionalMappingProperties,
              dealsProperties: values.dealsProperties,
            };

            if (model.additionalMappingProperties !== null) model.additionalMappingProperties.globalMapping = mappingKey;
            setIsLoading(true);
            api(API.integrations.hubspot.addCustomRules, model)
              .then((response) => {
                setIsLoading(false);
                window.location.reload();
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);

                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                } else {
                  let errorMessage = error.message;
                  if (error?.response?.data) errorMessage = error.response.data;
                  toast.error(errorMessage, { autoClose: false });
                }
              })
              .finally(() => {
                setIsLoading(false);
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            const { values, setFieldValue, handleSubmit, handleReset, handleChange } = formikProps;

            return (
              <>
                <div className={styles.navWrapper}>
                  <h3>Add rule</h3>
                  {/* <BreadcrumbElement items={breadcrumbs} /> */}
                  <div className={styles.buttonsWrapper}>
                    <Button id="predefinedCancel" variant="secondary" onClick={() => window.location.reload()}>
                      Cancel
                    </Button>
                    <Button id="predefinedSave" variant="primary" type="submit" onClick={handleSubmit} disabled={isLoading}>
                      Save rule
                    </Button>
                  </div>
                </div>
                <div className={styles.formsWrapper}>
                  <div className="flex">
                    <div className={styles.triggerEventForm}>
                      <CombinationMarkLogoDark width="30%" height="100%" />
                      <FormikSelect
                        label="Trigger"
                        name="eventType"
                        enableReinitialize={true}
                        options={triggerOptions}
                        value={values.eventType}
                        formgroupClass="mT20"
                        onChange={(event) => setTriggerType(event)}
                      />
                      <div className={styles.formWrapper}></div>
                    </div>
                    <div className={styles.actionForm}>
                      <HubSpotLogo width="25%" height="100%" />
                      <FormikSelect
                        label="Action"
                        name="actionCode"
                        enableReinitialize={true}
                        options={groupedActions}
                        value={values.actionCode}
                        onChange={selectActionChange}
                        formgroupClass="mT20"
                        disabled={groupedActions.length === 0}
                      />
                      <div className={styles.formWrapper}></div>
                    </div>
                  </div>
                  <div>
                    {mappingKey === "contacts" && leadStatusState.length > 0 && lifecycleStageState.length > 0 ? (
                      <>
                        <p className={styles.mappingTitle}>Define mapping for HubSpot action fields</p>

                        <div className={styles.formGroupWrapper}>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lead Status"
                              name="additionalMappingProperties.leadStatus"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={leadStatusState}
                              value={values.additionalMappingProperties.leadStatus}
                              isSearchable={true}
                            />
                          </div>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lifecycle Stage"
                              name="additionalMappingProperties.lifecycleStage"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={lifecycleStageState}
                              value={values.additionalMappingProperties.lifecycleStage}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </>
                    ) : mappingKey === "companies" && leadStatusState.length > 0 && lifecycleStageState.length > 0 ? (
                      <>
                        <p className={styles.mappingTitle}>Define mapping for HubSpot action fields</p>

                        <div className={styles.formGroupWrapper}>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lead Status"
                              name="additionalMappingProperties.leadStatus"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={leadStatusState}
                              value={values.additionalMappingProperties.leadStatus}
                              isSearchable={true}
                            />
                          </div>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lifecycle Stage"
                              name="additionalMappingProperties.lifecycleStage"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={lifecycleStageState}
                              value={values.additionalMappingProperties.lifecycleStage}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </>
                    ) : mappingKey === "deals" && dealsPipelineState.length > 0 ? (
                      <>
                        <p className={styles.mappingTitle}>Define mapping for HubSpot action fields</p>
                        <div className={styles.formGroupWrapper}>
                          {/* <div className={styles.formGroup}>
                            <FormikSelect
                              label="Deal Name"
                              name='dealsProperties.dealNameSetup'
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={DealNameOptions}
                              value={values.dealsProperties.dealNameSetup}
                              isSearchable={true}
                            />
                          </div> */}
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Pipeline"
                              name="dealsProperties.pipeline"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={dealsPipelineState}
                              value={values.dealsProperties.pipeline}
                              isSearchable={true}
                              onChange={(event) => {
                                setPipelineId(event);
                              }}
                            />
                          </div>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Deal Stage"
                              name="dealsProperties.dealStage"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={dealStagesState}
                              value={values.dealsProperties.dealStage}
                              isSearchable={true}
                              disabled={dealStagesState.length === 0}
                            />
                          </div>
                        </div>
                      </>
                    ) : isLoadingProps ? (
                      <Spinner />
                    ) : null}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dealsProperties: state.hubspotIntegration.dealsProperties,
    companiesProperties: state.hubspotIntegration.companiesProperties,
    contactsProperties: state.hubspotIntegration.contactsProperties,
    customFields: state.hubspotIntegration.customFields,
    dealsPipelines: state.hubspotIntegration.dealsPipelines,
    dealStages: state.hubspotIntegration.dealsPipeline,
    isLoadingProps: state.hubspotIntegration.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDealsProperties: () => dispatch(fetchDealsProperties()),
    fetchContactsProperties: () => dispatch(fetchContactsProperties()),
    fetchCompaniesProperties: () => dispatch(fetchCompaniesProperties()),
    fetchDealsPipelines: () => dispatch(fetchDealsPipelines()),
    fetchDealsPipelineById: (id) => dispatch(fetchDealsPipelineById(id)),
    fetchRulesCustomFields: (key, fieldName) => dispatch(fetchRulesCustomFields(key, fieldName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedRules);
