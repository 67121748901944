import React from "react";

//Icons
import StarLineIcon from "remixicon-react/StarLineIcon";
import Building3LineIcon from "remixicon-react/Building3LineIcon";
import AwardLineIcon from "remixicon-react/AwardLineIcon";
import TeamLineIcon from "remixicon-react/TeamLineIcon";
import MapPinLineIcon from "remixicon-react/MapPinLineIcon";

//Components
import AccordionSimple from "../../../../components/UI/AccordionSimple/AccordionSimple";
import FilterCompanyIndustry from "../Filters/CompanyFilters/FilterCompanyIndustry";
import FilterCompanySize from "../Filters/CompanyFilters/FilterCompanySize";
import { useFormikContext } from "formik";
import FilterContactLocation from "../Filters/ContactFilters/FilterContactLocation";
import FilterCompanyLocation from "../Filters/CompanyFilters/FilterCompanyLocation";
import FilterJobTitleDepartments from "../Filters/ContactFilters/FilterJobTitleDepartments";
import FilterJobTitleSeniorities from "../Filters/ContactFilters/FilterJobTitleSeniorities";

import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const Recommended: React.FC = () => {
  const {
    values: { company, contact },
  } = useFormikContext<SearchModel>();

  // No job title count on purpose here, recommended section only displays seniorties and deparments
  const jobTitleSectionCount = contact.departments.length + contact.seniorities.length;

  const contactLocationsCount =
    (contact.location.region.locations.length > 0 ? contact.location.region.locations.length : 0) +
    (contact.location.range.location?.name ? 1 : 0);
  const companyLocationsCount =
    (company.location.region.locations.length > 0 ? company.location.region.locations.length : 0) +
    (company.location.range.location?.name ? 1 : 0);

  return (
    <AccordionSimple
      icon={<StarLineIcon size={16} />}
      className="mB10 mT10"
      header={"Recommended"}
      eventKey="recommended"
      defaultOpen={true}
      body={
        <>
          <AccordionSimple
            icon={<Building3LineIcon size={16} />}
            infoIconTooltip
            popoverContent="Select the industries you want to sell to for targeted, firmographic data-based market segmentation."
            className="mB10"
            header={"Company Industry"}
            eventKey="companyIndustry"
            body={<FilterCompanyIndustry />}
            rightContent={company.industries.length}
            defaultOpen={company.industries.length > 0}
          />

          <AccordionSimple
            icon={<TeamLineIcon size={16} />}
            infoIconTooltip
            popoverContent="Please select the company sizes are you looking to target.."
            className="mB10"
            header={"Company Size"}
            eventKey="companySize"
            body={<FilterCompanySize />}
            rightContent={company.sizes?.length || 0}
            defaultOpen={company.sizes.length > 0}
          />

          <AccordionSimple
            icon={<MapPinLineIcon size={16} />}
            infoIconTooltip
            popoverContent="Select the locations of your prospects to refine your geographical market targeting."
            rightContent={companyLocationsCount + contactLocationsCount}
            className="mB10"
            header="Locations"
            eventKey="locations"
            body={
              <>
                <AccordionSimple
                  icon={<></>}
                  className="mB10"
                  header="Company locations"
                  eventKey="companyLocations"
                  rightContent={companyLocationsCount}
                  defaultOpen={companyLocationsCount > 0}
                  body={<FilterCompanyLocation />}
                />

                <AccordionSimple
                  icon={<></>}
                  className="mB10"
                  header="Contact locations"
                  eventKey="contactLocations"
                  rightContent={contactLocationsCount}
                  defaultOpen={contactLocationsCount > 0}
                  body={<FilterContactLocation />}
                />
              </>
            }
            defaultOpen={contactLocationsCount > 0 || companyLocationsCount > 0}
          />

          <AccordionSimple
            icon={<AwardLineIcon size={16} />}
            infoIconTooltip
            popoverContent="Search for individual prospects based on their job titles and level of seniority."
            className="mB10"
            header="Job title & seniority"
            eventKey="jobTitle"
            rightContent={jobTitleSectionCount}
            defaultOpen={jobTitleSectionCount > 0}
            body={
              <>
                <AccordionSimple
                  icon={<></>}
                  className="mB10"
                  header="Departments"
                  eventKey="departmentsFunctions"
                  rightContent={contact.departments.length}
                  defaultOpen={contact.departments.length > 0}
                  body={<FilterJobTitleDepartments />}
                />
                <AccordionSimple
                  icon={<></>}
                  className="mB10"
                  header="Seniority"
                  eventKey="managementLevel"
                  rightContent={contact.seniorities.length}
                  defaultOpen={contact.seniorities.length > 0}
                  body={<FilterJobTitleSeniorities />}
                />
              </>
            }
          />
        </>
      }
    />
  );
};

export default Recommended;
