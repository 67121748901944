import React, { FC } from "react";
import { Dropdown } from "react-bootstrap";

//Styles
import styles from "./SortByFilter.module.scss";

interface SortByFilterProps {
  sortBy: "Latest" | "Priority";
  onSortChange: (option: "Latest" | "Priority") => void;
}

const SortByFilter: FC<SortByFilterProps> = ({ sortBy, onSortChange }) => {
  return (
    <Dropdown className={styles.dropdownComponent} onSelect={(eventKey) => onSortChange(eventKey as "Latest" | "Priority")}>
      <Dropdown.Toggle className={styles.dropdownBtn} id="dropdown-basic">
        Sort by: {sortBy}
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.dropdownMenu}>
        <Dropdown.Item className={styles.dropdownItem} eventKey="Latest">
          Latest
        </Dropdown.Item>
        <Dropdown.Item className={styles.dropdownItem} eventKey="Priority">
          Priority
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SortByFilter;
