import React, { useEffect, useState } from "react";
import _ from "lodash";

//Style
import styles from "./Contact.module.scss";

//Components
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";
import HUBSPOT_DEFAULTS from "../HubspotCompanyContactDefaults";

const Contact = (props) => {
  const { contactsProperties, contactsMappings, listOfSocialNetworks } = props;

  const [socialNetwokChoice, setSocialNetwokChoice] = useState(null);

  const [contactOptions, setContactOptions] = useState([]);

  useEffect(() => {
    setContactOptions([{ value: "", label: "None" }].concat(contactsProperties));
    if (
      contactsProperties &&
      contactsProperties.length > 0 &&
      contactsMappings &&
      !contactsMappings.FirstName &&
      !contactsMappings.LastName &&
      !contactsMappings.Email &&
      !contactsMappings.JobTitle
    ) {
      let tmp = contactsProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Contact.FirstName);
      if (tmp) contactsMappings.FirstName = tmp.value;

      tmp = contactsProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Contact.LastName);
      if (tmp) contactsMappings.LastName = tmp.value;

      tmp = contactsProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Contact.Email);
      if (tmp) contactsMappings.EmailAddress = tmp.value;

      tmp = contactsProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Contact.JobTitle);
      if (tmp) contactsMappings.JobTitle = tmp.value;

      tmp = contactsProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Contact.CountryLocation);
      if (tmp) contactsMappings.CountryLocation = tmp.value;
    }
  }, [contactsProperties]);

  return contactOptions.length > 0 ? (
    <>
      <div className={styles.formWrapper}>
        <p className={styles.label}>First Name</p>
        <FormikSelect
          name="globalMappingsContacts.FirstName"
          options={contactOptions}
          isSearchable={true}
          deselectOption={true}
          isMenuPortalTargetDisabled={true}
          value={
            !_.isUndefined(contactsMappings) &&
            !_.isNull(contactsMappings) &&
            !_.isEmpty(contactsProperties) &&
            contactsProperties.some((x) => x.value === contactsMappings.FirstName)
              ? contactsProperties.find((x) => x.value === contactsMappings.FirstName).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Last Name</p>
        <FormikSelect
          name="globalMappingsContacts.LastName"
          options={contactOptions}
          isSearchable={true}
          deselectOption={true}
          isMenuPortalTargetDisabled={true}
          formgroupClass="mB10"
          value={
            !_.isUndefined(contactsMappings) &&
            !_.isNull(contactsMappings) &&
            !_.isEmpty(contactsProperties) &&
            contactsProperties.some((x) => x.value === contactsMappings.LastName)
              ? contactsProperties.find((x) => x.value === contactsMappings.LastName).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Email Address</p>
        <FormikSelect
          name="globalMappingsContacts.EmailAddress"
          disabled={true}
          options={contactOptions}
          isSearchable={true}
          isMenuPortalTargetDisabled={true}
          value={
            !_.isUndefined(contactsMappings) &&
            !_.isNull(contactsMappings) &&
            !_.isEmpty(contactsProperties) &&
            contactsProperties.some((x) => x.value === contactsMappings.EmailAddress)
              ? contactsProperties.find((x) => x.value === contactsMappings.EmailAddress).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Job Title</p>
        <FormikSelect
          name="globalMappingsContacts.JobTitle"
          options={contactOptions}
          isMenuPortalTargetDisabled={true}
          isSearchable={true}
          value={
            !_.isUndefined(contactsMappings) &&
            !_.isNull(contactsMappings) &&
            !_.isEmpty(contactsProperties) &&
            contactsProperties.some((x) => x.value === contactsMappings.JobTitle)
              ? contactsProperties.find((x) => x.value === contactsMappings.JobTitle).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Country Location</p>
        <FormikSelect
          name="globalMappingsContacts.CountryLocation"
          options={contactOptions}
          isMenuPortalTargetDisabled={true}
          isSearchable={true}
          value={
            !_.isUndefined(contactsMappings) &&
            !_.isNull(contactsMappings) &&
            !_.isEmpty(contactsProperties) &&
            contactsProperties.some((x) => x.value === contactsMappings.CountryLocation)
              ? contactsProperties.find((x) => x.value === contactsMappings.CountryLocation).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>LinkedIn Url</p>
        <FormikInput type="text" name="globalMappingsCompanies.SocialNetworks" />
      </div>
    </>
  ) : null;
};

export default Contact;
