import { useState } from "react";
import { useFormikContext } from "formik";

// API
import { useGetCompanyLocationsAutoCompleteQuery, useGetCompanyLocationsRangeAutoCompleteQuery } from "@api/audienceExplorerApi";

// Enums
import { locationRangeOptions } from "@helper/enums/locationRangeOptionsEnum";
import { locationFilterTypeEnum as filterType } from "@helper/enums/audienceBuilderActiveLocationFilterEnum";

// Styles
import styles from "../Filters.module.scss";

// Icons
import CloseLineIcon from "remixicon-react/CloseLineIcon";

// Components
import FormikInput from "@ui/Formik/FormikInput";
import RadioButton from "@ui/RadioButton/RadioButton";
import FormikSelect from "@ui/Formik/FormikSelect";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterCompanyLocation = () => {
  const {
    values: {
      company: {
        location: {
          type,
          region: { locations },
          range: { rangeMiles, location },
        },
      },
    },
    setFieldValue,
  } = useFormikContext<SearchModel>();

  const [searchInput, setSearchInput] = useState("");
  const { data: options } = useGetCompanyLocationsAutoCompleteQuery(searchInput, { skip: searchInput.length < 3 });
  const { data: optionsRange } = useGetCompanyLocationsRangeAutoCompleteQuery(searchInput, { skip: searchInput.length < 3 });

  const resetRange = () => {
    setFieldValue("company.location.range", {});
  };

  return (
    <>
      <>
        <div className="flex">
          <RadioButton selectedValue={type} id="Region" labelText="Region" value={filterType.Region} name="company.location.type" />
          <RadioButton
            selectedValue={type}
            id="ZipPostalCode"
            labelText="Zip/Postal Code"
            value={filterType.Range}
            name="company.location.type"
          />
        </div>
        {type == filterType.Region ? (
          <FormikSelect
            id="companiesSelectRegion"
            name="company.location.region.locations"
            value={locations}
            options={options}
            displayName="Select region"
            placeholder="e.g. England, United Kingdom"
            onInputChange={(input: string) => setSearchInput(input)}
            dropdownButtonClass={styles.selectDropdown}
            allowIncludeExclude
            enableReinitialize
            hideNoOptions
            isAutoComplete
            isMulti
            isSearchable
            showValuesOutside
            requireInputToShowOptions
            selectClass={styles.selectDropdown}
          />
        ) : (
          <>
            <div className="flex">
              <FormikSelect
                formgroupClass={styles.formGroupLocationRange}
                inputGroupClass={location?.name ? " " + styles.locationPlaceholderCss : ""}
                id="companiesLocationRangeName"
                name="company.location.range.location"
                options={optionsRange}
                placeholder={location?.name ? location.name : "e.g. BN2 1PJ, Brighton and Hove, England, United Kingdom"}
                onInputChange={(input: string) => setSearchInput(input)}
                enableReinitialize
                hideNoOptions
                isAutoComplete
                isSearchable
                requireInputToShowOptions
              />
              <span className={styles.resetLocationRange} onClick={resetRange}>
                <CloseLineIcon size={16} />
              </span>
            </div>
            <FormikSelect
              disabled={location?.name?.length === 0}
              name="company.location.range.rangeMiles"
              value={rangeMiles}
              id="companiesRangeDropdown"
              placeholder="e.g. Within: 5mi (8km)"
              isSearchable
              options={locationRangeOptions}
              enableReinitialize={true}
              onChange={(value: number) => {
                // Only setting when > 0, because if we select "Specify radius" the value is 0 and that should display the other input
                if (value > 0) {
                  setFieldValue("company.location.range.rangeMiles", value);
                }
              }}
            />

            {/* Only show this if "Specify radius" is selected */}
            {rangeMiles === 0 ? (
              <div className="flex">
                <FormikInput
                  placeholder="e.g. Within: 5mi (8km)"
                  type="number"
                  name="company.location.range.location.range"
                  value={location?.range ?? 0}
                />
                <label className={styles.formLabel}>mi</label>
              </div>
            ) : null}
          </>
        )}
      </>
    </>
  );
};

export default FilterCompanyLocation;
