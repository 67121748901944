import React, { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import { Routes } from "./routes/routes";

import { Provider } from "react-redux";
import axios from "./axios";
import { HelmetProvider } from "react-helmet-async";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCoffee, faColumns } from "@fortawesome/free-solid-svg-icons";
import SignalRWrapper from "./SignalRWrapper";
import GoogleTagManager from "./helper/GoogleTagManager/GoogleTagManager";
import renderGoogleTagManagerIframe from "./helper/GoogleTagManager/GoogleTagManagerIframe";

import { QueryClient, QueryClientProvider } from "react-query";
import UserMetadata from "./helper/UserMetadata/userMetadata";
import { IDENTITY_CONFIG } from "./helper/authConstants";
import AuthEvents from "./components/auth/AuthEvents";
import CacheBuster from "./components/CacheBuster";
import { store } from "./store/configureStore";
import { Exceptionless } from "@exceptionless/react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

const theme = createTheme({});

library.add(faCheckSquare, faCoffee, faColumns);

const version = import.meta.env.VITE_VERSION;
if (import.meta.env.VITE_ENV !== "development") {
  Sentry.init({
    dsn: "https://42f2ad149d2c4414ef2a1ba7f9922d17@o4507379771899904.ingest.de.sentry.io/4507379774652496",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: import.meta.env.VITE_ENV,
    release: `outbase@${version}`,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["app.outbase.com", "api.outbase.com", "staging.app.outbase.com", "testing.app.outbase.com"],
    // tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
// require("dotenv-expand")(require("dotenv").config());

axios.interceptors.request.use(function (config) {
  localStorage.setItem("isCallSent", "false");

  try {
    const state = store.getState();
    if (state.user.organization !== undefined) {
      const orgId = state.user.organization.id;
      config.headers.OrganizationId = orgId;
    }
  } catch (ex) {
    // Do nothing
  }

  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    localStorage.setItem("isCallSent", "false");

    if (error.response == null) {
      const state = store.getState();
      let orgId = null;
      if (state.user != null && state.user.organization != null) {
        orgId = state.user.organization.id;
      }

      let url = `${import.meta.env.VITE_LOCAL_URL}/error`;
      if (orgId != null) {
        url += "/" + orgId;
      }
      window.location.replace(url);
    }

    if (error.response.status === 401) {
      window.location.replace(`${import.meta.env.VITE_LOCAL_URL}/logout`);
    }

    if (error.response.status === 403 && !error.response.data?.errorData?.message) {
      window.location.replace(`${import.meta.env.VITE_LOCAL_URL}/logout`);
    }

    if (error.response.status === 404) {
      window.location.replace(`${import.meta.env.VITE_LOCAL_URL}/`);
    }

    if (error.response.data?.errorData?.message) toast.error(error.response.data?.errorData?.message);
    // Do something with response error
    return Promise.reject(error.response.data?.errorData?.message ?? error);
  }
);
document.body.classList.add("sidebarClosed");

// const script = document.createElement("script");
// script.type = "text/javascript";
// script.text = (function () {
//   var APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
//   var w = window;
//   var ic = w.Intercom;
//   if (typeof ic === "function") {
//     ic("reattach_activator");
//     ic("update", w.intercomSettings);
//   } else {
//     var d = document;
//     var i = function () {
//       i.c(arguments);
//     };
//     i.q = [];
//     i.c = function (args) {
//       i.q.push(args);
//     };
//     w.Intercom = i;
//     var l = function () {
//       var s = d.createElement("script");
//       s.type = "text/javascript";
//       s.async = true;
//       s.src = "https://widget.intercom.io/widget/" + APP_ID;
//       var x = d.getElementsByTagName("script")[0];
//       x.parentNode.insertBefore(s, x);
//     };
//     if (w.attachEvent) {
//       w.attachEvent("onload", l);
//     } else {
//       w.addEventListener("load", l, false);
//     }
//   }
// })();
// document.body.appendChild(script);

const state = store.getState();
const user = state.user;
if (user != null) {
  // window.Intercom("boot", {
  //   app_id: import.meta.env.VITE_INTERCOM_APP_ID,
  //   email: user.email,
  //   user_id: user.id,
  //   name: `${user.firstName} ${user.lastName}`,
  // });

  if (user.userAccessRemoved) {
    localStorage.removeItem("user");
    window.location.replace(`${import.meta.env.VITE_LOCAL_URL}/logout`);
  }

  window.userInfo = {
    email: user.email,
    user_id: user.id,
  };
}

// Here for debugging purposes
// window.addEventListener('outbase.user_ready', function (event) {
//   // console.log('outbase.user_ready');
//   // alert('outbase.user_ready');
// });

// Here for debugging purposes
// window.addEventListener('outbase.org_updated', function (event) {
//   // console.log('outbase.org_updated');
//   // alert('outbase.org_updated');
// });

renderGoogleTagManagerIframe();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const state = store.getState();
  const user = state.user;

  useEffect(() => {
    const startExceptionless = async () => {
      if (import.meta.env.VITE_EXCEPTIONLESS_ID) {
        await Exceptionless.startup((client) => {
          client.apiKey = import.meta.env.VITE_EXCEPTIONLESS_ID;

          if (user?.email) {
            const userData = {
              identity: user.email,
              name: `${user.firstName} ${user.lastName}`,
              data: {
                id: user.id,
              },
            };
            client.setUserIdentity(userData);
          }
        });
      }
    };

    startExceptionless();
  }, [user]); // Empty dependency array means this effect runs once on mount

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) {
          return <div>...loading</div>;
        }

        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            <HelmetProvider>
              <GoogleTagManager />
              <Provider store={store}>
                {/* <Hotjar /> */}
                <AuthProvider {...IDENTITY_CONFIG} store={store}>
                  <AuthEvents />
                  {/* <LoginTest /> */}

                  <SignalRWrapper>
                    <QueryClientProvider client={queryClient}>
                      <ThemeProvider theme={theme}>
                        <UserMetadata />
                        <BrowserRouter children={Routes} basename={"/"} />
                      </ThemeProvider>
                    </QueryClientProvider>
                  </SignalRWrapper>
                </AuthProvider>
              </Provider>
            </HelmetProvider>
          </>
        );
      }}
    </CacheBuster>
  );
}

export default App;
