const HUBSPOT_DEFAULTS = {
  Company: {
    CompanyName: "name", // "Company name",
    Website: "website", // "Website URL",
    Industry: "industry", // "Industry",
    //CompanySize: "numberofemployees", // "Number of Employees",
    CountryLocation: "country", //"Country/Region",
  },
  Contact: {
    FirstName: "firstname", //"First Name",
    LastName: "lastname", //"Last Name",
    Email: "email", //"Email",
    JobTitle: "jobtitle", // "Job Title",
    LinkedInId: "hs_linkedinid",
    CountryLocation: "country", //"Country/Region",
  },
};

export default HUBSPOT_DEFAULTS;
