import React, { FC } from "react";
import Moment from "moment";
import { Form } from "react-bootstrap";
import { subDays } from "date-fns";
import { Formik } from "formik";

//helper
import { addOffsetToDate } from "../../../helper/dateHelper";

//style
import styles from "./TableColumnFilters.module.scss";

//Components
import FormikRangeFilter from "../../UI/Formik/FormikRangeFilter/FormikRangeFilter";
import DelayedInput from "../../UI/DelayedInput/DelayedInput";
import ShowDateRangePicker from "../ShowDateRangePicker/ShowDateRangePicker";
import { customStaticRanges } from "../../../pages/ActivityOverview/Shared/customDaysRanges";
import SelectMulti from "../../UI/Select/SelectMulti";
import { ColumnDefinition } from "../DataTable";
import { filterData } from "@helper/tableConstants";
import { DTParameters } from "@models/dataTables/DtParameters";

interface TableColumnFiltersProps {
  column: ColumnDefinition;
  setFilter: any;
  params: DTParameters;
}

const TableColumnFilters: FC<TableColumnFiltersProps> = (props) => {
  const { column, params } = props;
  let filter = null;

  // Find the filter that matches column.field
  const matchingFilter = params?.filter?.find((filter) => filter.type === column.field);

  // If a matching filter is found, take its value
  const value = matchingFilter ? matchingFilter.value : null;

  const utcMoment = Moment.utc();
  const utcDate = new Date(utcMoment.format());

  const filterDate = (obj: { startDate?: Date; endDate?: Date }) => {
    const arrayDates: Date[] = [];

    if (obj.startDate) {
      arrayDates.push(addOffsetToDate(obj.startDate));
    }
    if (obj.endDate) {
      arrayDates.push(addOffsetToDate(obj.endDate));
    }
    filterData(column.field, arrayDates, props.setFilter);
  };

  const dateOptions = {
    onChange: (item: any) => filterDate(item),
    showSelectionPreview: true,
    key: "selection",
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    editableDateInputs: false,
    maxDate: utcDate,
    inputRanges: [],
    staticRanges: customStaticRanges,
    startDate: new Date(subDays(utcDate, 30)),
    endDate: utcDate,
    onSubmit: false,
  };

  switch (column.searchType) {
    case "text":
      filter = (
        <DelayedInput
          showSearchIcon={true}
          type="text"
          placeholder={""}
          textChanged={(o) => {
            filterData(column.field, o, props.setFilter);
          }}
          searchOnFirstCharacter={true}
          className={styles.searchableInput}
          ariaLabel="Text"
        />
      );
      break;
    case "number":
      filter = (
        <DelayedInput
          showSearchIcon={true}
          type="number"
          placeholder={""}
          textChanged={(o) => {
            filterData(column.field, o, props.setFilter);
          }}
          searchOnFirstCharacter={true}
          className={styles.searchableInput}
          ariaLabel="Number"
        />
      );
      break;
    case "range":
      filter = (
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <FormikRangeFilter title="Set range" filterData={filterData} field={column.field} setFilter={props.setFilter} />
          </Form>
        </Formik>
      );
      break;
    case "date": {
      const updatedDateOptions = {
        ...dateOptions,
        direction: "horizontal" as "horizontal" | "vertical" | undefined,
      };
      filter = <ShowDateRangePicker {...updatedDateOptions} customDropdownClass={styles.datepickerDropdownCustomClass} />;
      break;
    }
    case "select":
      filter = (
        <SelectMulti
          options={column.option}
          displayName={column.placeholder}
          value={value ? value : []}
          onChange={(e) => {
            filterData(column.field, e, props.setFilter);
          }}
          dropdownButtonClass={styles.datepickerDropdownCustomClass}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div
      className={styles.filterWrapper}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {filter}
    </div>
  );
};

export default TableColumnFilters;
