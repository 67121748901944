import React from "react";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./EventTimeline.module.scss";

//Helpers
import { prospectEventType } from "../../../helper/enums/prospectEventType";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { useOrgTimeFormat } from "../../../helper/hooks/useOrgTimeFormat";

// Icons
import IconClicked from "../../../assets/Icons/Clicked/Clicked";
import IconEmailOpened from "../../../assets/Icons/EmailOpened/EmailOpened";
import IconAwaitingEngagement from "../../../assets/Icons/AwaitingEngagment/AwaitingEngagment";
import IconResponded from "../../../assets/Icons/Responded/Responded";
import IconAutomaticReply from "../../../assets/Icons/AutomaticReply/AutomaticReply";

//Components
import TimelineElement from "../../../components/UI/Timeline/VerticalTimelineElement";

const Events = (props) => {
  const { action, index, handleResponseDetailsShow, handleSentEmailDetailsShow } = props;
  let dateFormat = useOrgDateFormat();
  let timeFormat = useOrgTimeFormat();

  let styleBorder = null;

  let date = moment(action.date).format(dateFormat);
  let time = moment(action.date).format(timeFormat);

  let eventTypeLabelClass = styles.eventTypeLabel;
  let responseTypeLabelClass = styles.eventTypeLabel;

  let showResponse = false;
  let showEventType = true;

  let iconStyleColor = "";
  let icon = "";
  let textClassName = "";

  switch (action.eventType) {
    case prospectEventType.Delivered:
      iconStyleColor = `var(--engaged-color)`;
      icon = (
        <OverlayTrigger
          key={"awaiting"}
          placement={"auto"}
          overlay={
            <Popover id={`awaiting-tooltip`}>
              <Popover.Body>Email delivered</Popover.Body>
            </Popover>
          }
        >
          <div>
            <IconAwaitingEngagement />
          </div>
        </OverlayTrigger>
      );
      eventTypeLabelClass += " " + styles.delivered;
      textClassName = styles.textDelivered;
      break;
    case prospectEventType.Clicked:
      iconStyleColor = `var(--clicked-color)`;
      icon = (
        <OverlayTrigger
          key={"clicked"}
          placement={"auto"}
          overlay={
            <Popover id={`clicked-tooltip`}>
              <Popover.Body>Clicked</Popover.Body>
            </Popover>
          }
        >
          <div>
            <IconClicked />
          </div>
        </OverlayTrigger>
      );
      eventTypeLabelClass += " " + styles.clicked;
      textClassName = `${styles.textClick} hidingArrow `;
      break;
    case prospectEventType.Opened:
      iconStyleColor = `var(--opened-color)`;
      icon = (
        <OverlayTrigger
          key={"opened"}
          placement={"auto"}
          overlay={
            <Popover id={`opened-tooltip`}>
              <Popover.Body>Opened</Popover.Body>
            </Popover>
          }
        >
          <div>
            <IconEmailOpened />
          </div>
        </OverlayTrigger>
      );
      eventTypeLabelClass += " " + styles.opened;
      textClassName = `${styles.textOpened} hidingArrow `;
      break;
    case prospectEventType.Responded:
      iconStyleColor = `var(--responded-color)`;
      icon = (
        <OverlayTrigger
          key={"responded"}
          placement={"auto"}
          overlay={
            <Popover id={`responded-tooltip`}>
              <Popover.Body>Responded</Popover.Body>
            </Popover>
          }
        >
          <div>
            <IconResponded />
          </div>
        </OverlayTrigger>
      );
      eventTypeLabelClass += " " + styles.responded;
      textClassName = styles.textResponded;
      showResponse = true;

      if (action.isLead) {
        // iconStyleColor = "#0B9195";
        // icon = <IconLead />;
        responseTypeLabelClass += " " + styles.lead;
        textClassName = styles.textLead;
      } else if (action.responseType === "Decline") {
        // iconStyleColor = "#2A3347";
        // icon = <IconError />;
        responseTypeLabelClass += " " + styles.negativeResponse;
        textClassName = styles.textNegativeResponse;
      } else {
        // iconStyleColor = "#EE3030";
        // icon = <IconComments />;
        responseTypeLabelClass += " " + styles.otherResponse;
        textClassName = styles.textOtherResponse;
      }
      break;
    case prospectEventType.AutomaticReply:
      iconStyleColor = `var(--automatic-reply-color)`;
      icon = (
        <OverlayTrigger
          key={"automaticReply"}
          placement={"auto"}
          overlay={
            <Popover id={`automatic-reply-tooltip`}>
              <Popover.Body>Automatic reply</Popover.Body>
            </Popover>
          }
        >
          <div>
            <IconAutomaticReply />
          </div>
        </OverlayTrigger>
      );
      eventTypeLabelClass += " " + styles.automaticReply;
      textClassName = styles.textAutomaticReply;
      showResponse = true;
      showEventType = false;

      responseTypeLabelClass += " " + styles.automaticReply;
      textClassName = styles.textAutomaticReply;
      break;
    default:
      break;
  }

  styleBorder =
    index % 2 === 0
      ? {
          borderRight: "1px solid #D6D6D6",
          borderTop: "1px solid #D6D6D6",
          width: "15px",
          height: "15px",
          background: "#fff",
          transform: "rotate(45deg)",
          left: "97.7%",
        }
      : {
          borderRight: "1px solid #D6D6D6",
          borderTop: "1px solid #D6D6D6",
          width: "15px",
          height: "15px",
          background: "#fff",
          transform: "rotate(-135deg)",
          right: "97.7%",
        };

  const handleClick = () => {
    if (action.eventType === prospectEventType.Responded) {
      handleResponseDetailsShow(action.receivedEmailId);
    } else {
      handleSentEmailDetailsShow(action);
    }
  };

  return (
    <TimelineElement
      key={index}
      contentArrowStyle={styleBorder}
      iconStyle={{
        color: iconStyleColor,
        background: "#fff",
        border: `1px solid ${iconStyleColor}`,
        boxShadow: "none",
        width: "32px",
        height: "32px",
        marginLeft: "-16px",
        top: "13px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
        cursor: "pointer",
      }}
      icon={icon}
      date={
        <>
          <span className="font600">{date}</span>
          <br />
          <span>{time}</span>
        </>
      }
      dateClassName={styles.date}
      className={styles.timelineCard}
      textClassName={`${styles.card} ${textClassName}`}
      onClick={handleClick}
    >
      <div className={styles.cardsTitleWrapper}>
        <span className={styles.cardTitle}>{action.campaignName}</span>
        <span className={styles.typesWrapper}>
          {showEventType && <span className={eventTypeLabelClass}>{action.eventType}</span>}
          {showResponse && (
            <span className={responseTypeLabelClass}>
              {action.responseType == null || action.responseType === "" ? "Uncategorized" : action.responseType}
            </span>
          )}
        </span>
      </div>
      <div className={styles.bodyCard}>
        <p>
          <span className={`${styles.infoLabel} font600 mR5`}>From: </span>
          <span>{action.from}</span>
        </p>
        <p>
          <span className={`${styles.infoLabel} font600 mR5`}>To: </span>
          <span>{action.to}</span>
        </p>
        <p>
          <span className={`${styles.infoLabel} font600 mR5`}>Subject: </span>
          <span>{action.subject}</span>
        </p>
      </div>
      <div className={styles.messageBody}>
        <p className={`${styles.messageLink} font600 mR5`}>
          <span>Message</span>
        </p>
        <span>{action.snippet}</span>
      </div>
    </TimelineElement>
  );
};

export default Events;
