import { booleanOptions } from "../../../helper/tableConstants";

export const columns = [
  {
    label: "",
    field: "status",
    type: "action",
    sort: "asc",
    flex: 2,
  },
  {
    label: "Id",
    field: "id",
    type: "number",
    searchable: true,
    searchType: "number",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Email",
    field: "email",
    type: "text",
    searchable: true,
    searchType: "text",
    flex: 2,
    sort: "asc",
  },
  {
    label: "Type",
    field: "type",
    type: "text",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Last used",
    field: "lastDateUsed",
    type: "text",
    searchable: true,
    searchType: "date",
    sort: "asc",
    class: "textLeft",
    showTooltip: true,
    flex: 1,
  },
  {
    label: "No. of campaigns",
    field: "numberOfCampaigns",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Messages per day",
    field: "messagesPerDay",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Total emails sent",
    field: "emailsDelivered",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Organization",
    field: "organizationName",
    type: "text",
    searchable: true,
    searchType: "text",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Last sync",
    field: "lastSync",
    type: "text",
    searchable: true,
    searchType: "date",
    sort: "asc",
    class: "textLeft",
    showTooltip: true,
    flex: 1,
  },
  {
    label: "Errors",
    field: "errors",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    sort: "asc",
    flex: 1,
  },
  {
    label: "In progress?",
    field: "isInProgress",
    type: "boolean",
    searchable: true,
    searchType: "select",
    option: booleanOptions,
    placeholder: "Choose...",
    class: "textCenter",
    flex: 1,
  },
  {
    label: "Is queued?",
    field: "isQueued",
    type: "boolean",
    searchable: true,
    searchType: "select",
    option: booleanOptions,
    placeholder: "Choose...",
    class: "textCenter",
    flex: 1,
  },
  {
    label: "Is suspended?",
    field: "isSuspended",
    type: "boolean",
    searchable: true,
    searchType: "select",
    option: booleanOptions,
    placeholder: "Choose...",
    class: "textCenter",
    flex: 1,
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    sort: false,
    flex: 2,
  },
];
