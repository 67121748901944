import React from "react";

//Style
import "./SkeletonThreeRowCard.scss";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonThreeRowCard = (props) => {
  return (
    <div className="skeleton-wrapper messageSkeletonCard">
      <SkeletonElement type="text-8 w-3" />
      <SkeletonElement type="text-8 w-5" />
      <SkeletonElement type="text-8" />
      <Shimmer />
    </div>
  );
};

export default SkeletonThreeRowCard;
