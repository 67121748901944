import React, { ReactNode } from "react";
import styles from "./PageContentWrapper.module.scss";
interface PageContentWrapperProps {
  children: ReactNode;
  noPadding?: boolean;
  className?: string;
}

const PageContentWrapper: React.FC<PageContentWrapperProps> = ({ children, noPadding, className }) => {
  const wrapperClass = `${noPadding ? styles.noPadding : styles.defaultPadding}  ${className || ""}`.trim();

  return <div className={wrapperClass}>{children}</div>;
};

export default PageContentWrapper;
