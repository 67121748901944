import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

//Helper
import { activeApplicationEnum } from "../../../helper/enums/activeApplicationEnum";

//Styles
import classes from "./Header.module.scss";

//Images & Icons
import { ArrowForwardIos } from "@mui/icons-material";
import Logo from "../../../assets/Logo/Logo";
import OutBossLogo from "../../../assets/Logo/OutBossLogo/OutBossLogo";

const SidebarHeader = (props) => {
  let url = "/dashboard";

  if (props != null && props.user != null && props.user.organization != null) {
    url = "/" + props.user.organization.id + "/dashboard";
  }

  let logo = null;
  if (props.currentApplication === activeApplicationEnum.Outboss) {
    logo = <OutBossLogo />;
    url = "/outboss/organizations";
  } else {
    logo = <Logo />;
  }
  return (
    <div className={`${classes.sidemenuHeader} sideMenuHeader`}>
      <NavLink aria-label="Go to dashboard" className={`${classes.sidemenuLogo} logoSideMenuClose`} to={url}>
        {logo}
      </NavLink>
      <div className={`${classes.collapseArrow} sidebarIcon`}>
        {props.isOpen ? <ArrowForwardIos className={`${classes.arrowIcon} sideMenuIcon`} onClick={props.sideBarToggle} /> : ""}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
  };
};

export default connect(mapStateToProps)(SidebarHeader);
