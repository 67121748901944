import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isLoaded: false,
  error: "",
  settings: {},
  organizationOnboardingModel: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GENERAL_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_GENERAL_SETTINGS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        settings: action.result,
        error: "",
      };
    case actionTypes.FETCH_GENERAL_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        settings: {},
        error: action.result,
      };
    case actionTypes.FETCH_ORGANIZATION_ONBOARDING_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_ORGANIZATION_ONBOARDING_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        organizationOnboardingModel: action.result,
        error: "",
      };
    case actionTypes.FETCH_ORGANIZATION_ONBOARDING_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        organizationOnboardingModel: {},
        error: action.result,
      };
    default:
      break;
  }

  return state;
};

export default reducer;
