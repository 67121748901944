import React, { useState } from "react";
import { Accordion as AccordionBootstrap, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { NavLink } from "react-router-dom";

// Styles
import styles from "./Accordion.module.scss";
import classNames from "classnames";

//Icons
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import IndeterminateCircleLineIcon from "remixicon-react/IndeterminateCircleLineIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import ErrorWarningFillIcon from "remixicon-react/ErrorWarningFillIcon";
import IconExclamationMark from "../../../assets/Icons/IconExclamationMark/IconExclamationMark";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";

//Components
import Spinner from "../Spinner/Spinner";
import GetStarted from "../../../pages/CampaignSettings/Components/GetStarted/GetStarted";

interface AccordionProps {
  accordionWrapperClass?: string;
  header: string;
  headerExpanded?: string;

  subHeader?: string;
  body: JSX.Element;
  bodyClass?: string;
  headerClassName?: string;

  isChecked?: boolean;
  isClickable?: boolean;
  showInfoIcon?: boolean;
  infoIconText?: string;

  showActionButton?: boolean;
  actionButtonText?: string;
  actionButtonUrl?: string;
  actionButtonClick?: () => void;

  toggleHandler?: (isOpen: boolean) => void;

  variant?: "white" | "green" | "whiteTransparent" | "primary";
  classes?: string;
  defaultOpen?: boolean;
  noShadow?: boolean;

  eventKey: string;
  noCaret?: boolean;

  hasPermission?: boolean;

  openButtonText?: string;
  openButtonStyle?: string;
  openButtonUrl?: string;
  openButtonClick?: () => void;

  openSecondButtonId?: string;
  openSecondButtonText?: string;
  openSecondButtonStyle?: string;
  openSecondButtonClick?: () => void;
  openSecondButtonIcon?: JSX.Element;

  warningText?: string;
  generatingText?: string;

  showConfiguration?: boolean;
  configButtonText?: string;
  configButtonUrl?: string;
  configButtonClick?: () => void;
  configurationId?: string;
  configurationLabel?: string;
  configDisplayVideo?: () => void;
  configShowVideoModal?: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  accordionWrapperClass,
  header,
  headerExpanded,
  subHeader,
  body,
  bodyClass,
  headerClassName,

  isChecked,
  isClickable = true,
  showInfoIcon = false,
  infoIconText,

  showActionButton = false,
  actionButtonText,
  actionButtonUrl,
  actionButtonClick,

  variant,
  classes,
  noShadow,
  defaultOpen = false,
  eventKey,
  toggleHandler,
  noCaret = false,

  hasPermission = false,

  openButtonText = "Review & Edit",
  openButtonClick,
  openButtonUrl,

  openSecondButtonId,
  openSecondButtonText,
  openSecondButtonClick,
  openSecondButtonIcon,

  warningText,
  generatingText,

  showConfiguration = false,
  configButtonUrl,
  configButtonClick,
  configurationId = "",
  configurationLabel,
  // configDisplayVideo,
  // configShowVideoModal,
}) => {
  const combinedClasses = classNames(styles.accordionStyle, variant ? styles[variant] : undefined, classes);

  const [open, setOpen] = useState(defaultOpen);
  const withColor = open ? styles.active : "";

  const titleId = header.replace(/\s/g, "");
  if (!open && headerExpanded) {
    header = headerExpanded;
  }

  if (showConfiguration) {
    return (
      <GetStarted
        title={header}
        subTitle={subHeader}
        url={configButtonUrl}
        onClick={configButtonClick}
        text={configurationLabel}
        id={configurationId}
        // displayVideo={configDisplayVideo}
        // showVideoModal={configShowVideoModal}
      />
    );
  }

  const toggleAccordion = (event: React.MouseEvent) => {
    if (!isClickable) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    setOpen(!open);

    if (toggleHandler) {
      toggleHandler(!open);
    }
  };

  let titleIcon = null;
  if (showInfoIcon) {
    titleIcon = (
      <OverlayTrigger
        key={"InfoIcon"}
        placement={"bottom"}
        overlay={
          <Popover id={`popover-tooltip-info-icon`}>
            <Popover.Body>{infoIconText}</Popover.Body>
          </Popover>
        }
      >
        <div className="max-x-content">
          <ErrorWarningFillIcon color="#FAAC14" size={18} className="mL10" />
        </div>
      </OverlayTrigger>
    );
  } else if (isChecked) {
    titleIcon = <CheckboxCircleFillIcon className="mL10" size={18} color="var(--first-stage-color)" />;
  }

  const rightContent = [];
  if (generatingText) {
    rightContent.push(
      <div key={"spinner"} className={`${styles.infoMessageWrapper} flex`}>
        <Spinner size="18px" spinnerClass="mR5" />
        {generatingText}
      </div>
    );
  }
  // Warning text is only shown when the accordion is closed
  else if (warningText && !open) {
    rightContent.push(
      <div className={styles.warningHeader} key={"warringWrapper"}>
        <IconExclamationMark imgStyle="mR5" />
        <p className="danger">{warningText}</p>
      </div>
    );
  }

  if (hasPermission) {
    if (open) {
      rightContent.push(
        <div key={"buttonsWrapper"} className={styles.accordionFooter}>
          {openSecondButtonText && (
            <div
              id={openSecondButtonId || ""}
              className={`${styles.accordionButton} ${styles.primaryButton} mL10`}
              onClick={(event) => {
                event.stopPropagation();
                openSecondButtonClick && openSecondButtonClick();
              }}
            >
              <span className="mR5">{openSecondButtonIcon}</span>
              {openSecondButtonText}
            </div>
          )}

          {openButtonUrl ? (
            <NavLink className={`${styles.accordionButton} ${styles.reviewButton} mL10`} to={openButtonUrl} id={`button${titleId}`}>
              {openButtonText}
            </NavLink>
          ) : (
            <div
              className={`${styles.accordionButton} ${styles.reviewButton} mL10`}
              id={`button${titleId}`}
              onClick={(event) => {
                openButtonClick && openButtonClick();
                event.stopPropagation();
              }}
            >
              {openButtonText}
            </div>
          )}
        </div>
      );
    } else if (showActionButton) {
      rightContent.push(
        <>
          {actionButtonUrl ? (
            <NavLink key={`key${titleId}`} className={`primaryButtonStyle mL10`} to={actionButtonUrl} id={`button${titleId}`}>
              {actionButtonText}
            </NavLink>
          ) : (
            <div
              key={`key${titleId}`}
              className={`${styles.accordionButton} ${styles.reviewButton} mL10`}
              id={`button${titleId}`}
              onClick={(event) => {
                event?.stopPropagation();
                if (actionButtonClick) {
                  actionButtonClick();
                }
              }}
            >
              {actionButtonText}
            </div>
          )}
        </>
      );
    }
  }

  return (
    <AccordionBootstrap className={accordionWrapperClass} id={titleId} defaultActiveKey={defaultOpen ? eventKey : ""}>
      <AccordionBootstrap.Item eventKey={eventKey} className={combinedClasses}>
        <AccordionBootstrap.Header
          as={Card.Header}
          variant="link"
          className={`${styles.cardHeader} ${styles.toggleWrapper} ${withColor} ${noShadow ? styles.noShadow : ""}`}
          onClick={toggleAccordion}
          id={`accordion${titleId}`}
        >
          <div className={styles.titleWrapper}>
            {open ? (
              <IndeterminateCircleLineIcon
                size={21}
                className={`${variant === "primary" ? "iconGray" : "iconWhite"} ${styles.expandWrapper} ${styles.expandWrapperWhiteIcon}`}
              />
            ) : (
              <AddCircleLineIcon className={`iconGray ${styles.expandWrapper}`} size={21} />
            )}

            <div className={styles.accordionTitle}>
              <div className={styles.draftWrapper}>
                <div className={styles.headTitle}>
                  <div className={styles.headerTitleWrapper}>
                    <div className="textLeft width_100">
                      <div className="flex">
                        <p className={`${styles.stepTitle} ${headerClassName}`}>{header}</p>
                        {titleIcon}
                      </div>
                      {subHeader && <p className={`m0 ${styles.description}`}>{subHeader}</p>}
                    </div>
                    {rightContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {noCaret ? null : <ArrowDownSLineIcon className={`${styles.caretIcon} ${open ? styles.caretIconToggle : ""}`} />}
        </AccordionBootstrap.Header>
        <AccordionBootstrap.Body id={`body${titleId}`} className={classNames(bodyClass, styles.accordionBody)}>
          {body}
        </AccordionBootstrap.Body>
      </AccordionBootstrap.Item>
    </AccordionBootstrap>
  );
};

export default Accordion;
