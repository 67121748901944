import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const fetchBasicEmailTestDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_TEST_DETAILS_REQUEST,
  };
};

export const fetchBasicEmailTestDetailsSuccess = (details) => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_TEST_DETAILS_SUCCEEDED,
    result: details,
  };
};

export const fetchBasicEmailTestDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_TEST_DETAILS_FAILED,
    result: error,
  };
};

export const fetchBasicEmailTestDetails = (emailAccountId) => {
  return (dispatch) => {
    dispatch(fetchBasicEmailTestDetailsRequest());
    return api(
      formUrl(API.emailDeliverabilityTest.getBasicEmailTestDetails, {
        emailAccountId: emailAccountId,
      })
    )
      .then((response) => {
        dispatch(fetchBasicEmailTestDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBasicEmailTestDetailsFailed(error.message));
      });
  };
};

export const fetchBasicEmailSendTestRequest = () => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_SEND_TEST_REQUEST,
  };
};

export const fetchBasicEmailSendTestSuccess = (status) => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_SEND_TEST_SUCCEEDED,
    result: status,
  };
};

export const fetchBasicEmailSendTestFailed = (error) => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_SEND_TEST_FAILED,
    result: error,
  };
};

export const fetchBasicEmailSendTest = (emailAccountId) => {
  return (dispatch) => {
    dispatch(fetchBasicEmailSendTestRequest());
    return api(
      formUrl(API.emailDeliverabilityTest.sendBasicEmailTest, {
        emailAccountId: emailAccountId,
      })
    )
      .then((response) => {
        dispatch(fetchBasicEmailSendTestSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBasicEmailSendTestFailed(error.response.data));
      });
  };
};

export const fetchListOfBasicEmailTestsRequest = () => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_TEST_LIST_REQUEST,
  };
};

export const fetchListOfBasicEmailTestsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_TEST_LIST_SUCCEEDED,
    result: data,
  };
};

export const fetchListOfBasicEmailTestsFailed = (error) => {
  return {
    type: actionTypes.FETCH_BASIC_EMAIL_TEST_LIST_FAILED,
    result: error,
  };
};

export const fetchListOfBasicEmailTests = () => {
  return (dispatch) => {
    dispatch(fetchListOfBasicEmailTestsRequest());
    return api(API.emailDeliverabilityTest.listOfBasicEmailTest)
      .then((response) => {
        dispatch(fetchListOfBasicEmailTestsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListOfBasicEmailTestsFailed(error.message));
      });
  };
};

export const clearInitiatedTest = () => {
  return {
    type: actionTypes.BASIC_EMAIL_TEST_CLEAR_INITIATED,
  };
};
