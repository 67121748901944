import React from "react";
import svg from "./cart_icon.svg";

const IconBlueCard = (props) => (
  <img
    src={svg}
    onClick={props.onClick}
    className={props.imgStyle}
    alt="cart-icon"
  />
);

export default IconBlueCard;
