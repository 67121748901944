import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./SizeColumn.module.scss";
import AudienceProgressBar from "../../../../components/UI/AuidenceProgressBar/AudienceProgressBar";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const SizeColumn = (props) => {
  return (
    <>
      <OverlayTrigger
        key={"audience-size"}
        className={styles.notInitiated}
        placement={"bottom"}
        overlay={
          <Popover id={`audience-size-tooltip`}>
            <Popover.Body className="audienceContactsTooltip">
              <span>
                Contacts <br />
                Available / Total
              </span>
            </Popover.Body>
          </Popover>
        }
      >
        <div className={styles.borderWrapper}>
          <div className={props.isInitiated ? "" : "notInitiated"}>
            <AudienceProgressBar
              contactsAvailable={props.availableContacts}
              audienceSize={props.audienceSize}
              separators={props.separators}
            />
          </div>
          {!props.isInitiated ? (
            <div className={`${styles.infoMessageWrapper} flex`}>
              <Spinner size="10px" />
              Please wait while we create your list.
            </div>
          ) : (
            ""
          )}
        </div>
      </OverlayTrigger>
    </>
  );
};

export default SizeColumn;
