import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

//Redux
import { resetDealNavigator } from "../../../../store/DealNavigator/actions/dealNavigator";

//Style
import styles from "./ResetModal.module.scss";

//Images & Icons
import ErrorWarningFillIcon from "remixicon-react/ErrorWarningFillIcon";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import FormikInput from "../../../../components/UI/Formik/FormikInput";

//Helpers
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import { toast } from "react-toastify";

const ResetModal = (props) => {
  const { resetDealNavigator, handleClose } = props;
  return (
    <Formik
      initialValues={{
        reset: "",
      }}
      onSubmit={(values, actions) => {
        if (values.reset.toLowerCase() === "reset") {
          resetDealNavigator();
          handleClose();
        } else {
          toast.error(toastMessages.dealNavigator.errorResetValue);
        }
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps;
        return (
          <Modal
            title="Reset Deal Navigator"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            saveButtonText="Confirm"
            closeButtonText="Don't reset"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <div className={styles.modalContent}>
              <p className={styles.info}>Please confirm you want to reset your Deal Navigator.</p>
              {/* Warning message should be removed/changed with the corresponding component after the Billing and payments code will be merged */}
              <div className={styles.warningMessage}>
                <span className={styles.iconWrapper}>
                  {" "}
                  <ErrorWarningFillIcon size={20} color="var(--danger-color)" />
                </span>
                <p>
                  Text and information in all cards will be erased permanently. Attached documents will still be visible in Sales Resources,
                  but removed from Deal Navigator.
                </p>
              </div>
              <p className={styles.infoReset}>To continue, type RESET in the box below and then click Confirm.</p>
              <Form>
                <FormikInput name="reset" type="text" placeholder="Enter RESET" />
              </Form>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetDealNavigator: () => dispatch(resetDealNavigator()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetModal);
