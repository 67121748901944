export const SET_FILTER_REPORT = "SET_FILTER_REPORT";

export const FETCH_ORGANIZATION_REPORT_REQUEST =
  "FETCH_ORGANIZATION_REPORT_REQUEST";
export const FETCH_ORGANIZATION_REPORT_SUCCEEDED =
  "FETCH_ORGANIZATION_REPORT_SUCCEEDED";
export const FETCH_ORGANIZATION_REPORT_FAILED =
  "FETCH_ORGANIZATION_REPORT_FAILED";

export const FETCH_ORGANIZATION_REPORT_BY_DAY_REQUEST =
  "FETCH_ORGANIZATION_REPORT_BY_DAY_REQUEST";
export const FETCH_ORGANIZATION_REPORT_BY_DAY_SUCCEEDED =
  "FETCH_ORGANIZATION_REPORT_BY_DAY_SUCCEEDED";
export const FETCH_ORGANIZATION_REPORT_BY_DAY_FAILED =
  "FETCH_ORGANIZATION_REPORT_BY_DAY_FAILED";

export const FETCH_ORGANIZATION_REPORT_BY_MONTH_REQUEST =
  "FETCH_ORGANIZATION_REPORT_BY_MONTH_REQUEST";
export const FETCH_ORGANIZATION_REPORT_BY_MONTH_SUCCEEDED =
  "FETCH_ORGANIZATION_REPORT_BY_MONTH_SUCCEEDED";
export const FETCH_ORGANIZATION_REPORT_BY_MONTH_FAILED =
  "FETCH_ORGANIZATION_REPORT_BY_MONTH_FAILED";

export const FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_REQUEST =
  "FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_REQUEST";
export const FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_SUCCEEDED =
  "FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_SUCCEEDED";
export const FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_FAILED =
  "FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_FAILED";

export const FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_REQUEST =
  "FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_REQUEST";
export const FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_SUCCEEDED =
  "FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_SUCCEEDED";
export const FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_FAILED =
  "FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_FAILED";

export const FETCH_ORGANIZATION_REPORT_BY_STAGE_REQUEST =
  "FETCH_ORGANIZATION_REPORT_BY_STAGE_REQUEST";
export const FETCH_ORGANIZATION_REPORT_BY_STAGE_SUCCEEDED =
  "FETCH_ORGANIZATION_REPORT_BY_STAGE_SUCCEEDED";
export const FETCH_ORGANIZATION_REPORT_BY_STAGE_FAILED =
  "FETCH_ORGANIZATION_REPORT_BY_STAGE_FAILED";

export const FETCH_RUN_RATES_REPORT_REQUEST = "FETCH_RUN_RATES_REPORT_REQUEST";
export const FETCH_RUN_RATES_REPORT_SUCCEEDED =
  "FETCH_RUN_RATES_REPORT_SUCCEEDED";
export const FETCH_RUN_RATES_REPORT_FAILED = "FETCH_RUN_RATES_REPORT_FAILED";

export const CLEAR_INITIATED_REPORT = "CLEAR_INITIATED_REPORT";
