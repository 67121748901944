import { useFormikContext } from "formik";
import MultiLevelDropdown from "@components/MultiLevelDropdown/MultiLevelDropdown";
import { useGetJobTitleDepartmentsQuery } from "@api/audienceExplorerApi";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterJobTitleDepartments = () => {
  const {
    values: {
      contact: { departments },
    },
    setFieldValue,
  } = useFormikContext<SearchModel>();

  const { data: options } = useGetJobTitleDepartmentsQuery();

  return (
    options && (
      <MultiLevelDropdown
        title="e.g. Marketing, Digital"
        name="contact.departments"
        id="contactsJobTitleDepartments"
        selectedValues={departments}
        data={options}
        onChange={(values: any) => setFieldValue("contact.departments", values)}
        enableScrollToSelection={false}
      />
    )
  );
};

export default FilterJobTitleDepartments;
