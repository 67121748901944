export const columns = [
  {
    label: "Email Account",
    field: "emailAccount",
    type: "text",
    sort: "asc",
    width: 150,
    minWidth: 150,
    flex: 2,
    showTooltip: true,
  },
  {
    label: "Sent To",
    field: "sentTo",
    type: "text",
    sort: "asc",
    width: 150,
    flex: 2,
    showTooltip: true,
  },
  {
    label: " Name",
    field: "name",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Send Time",
    field: "sendTime",
    type: "date",
    sort: "asc",
    flex: 1,
    width: 110,
    showTooltip: true,
  },
  {
    label: "Campaign Name",
    field: "campaignName",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Email Stage",
    field: "emailStage",
    type: "text",
    flex: 1,
    width: 150,
    sort: "asc",
  },
  {
    label: "Status",
    field: "sendStatus",
    type: "text",
    flex: 1,
    width: 150,
    sort: "asc",
    showTooltip: true,
  },
  {
    label: "Description",
    field: "description",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 180,
    showTooltip: true,
  },
];
