import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DropdownButton, OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "moment";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

//Redux
import { fetchAudienceDetails, fetchAudiences } from "../../store/Audiences/Global/actions/audiences";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { audienceTypeEnum } from "../../helper/enums/audienceTypeEnum";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { errorResponseToList } from "../../helper/errorHelper";

// Style
import styles from "./TopBannerAudienceContacts.module.scss";

// Icons images
import RemixIConRename from "../../assets/Icons/RemixIConRename/RemixIConRename";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import Focus3LineIcon from "remixicon-react/Focus3LineIcon";
import EditBoxLineIcon from "remixicon-react/EditBoxLineIcon";
import InboxUnarchiveLineIcon from "remixicon-react/InboxUnarchiveLineIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import InboxArchiveLineIcon from "remixicon-react/InboxArchiveLineIcon";

// Components
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";
import BreadcrumbElement from "../../components/UI/BreadcrumbElement/BreadcrumbElement";
import Permission from "../../components/auth/Permission";
import Button from "../../components/UI/Button/Button";
import Select from "../../components/UI/Select/Select";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import FormikInput from "../../components/UI/Formik/FormikInput";
import AudiencePushToCampaignForm from "../Audiences/AudiencePushToCampaignForm";
import AudienceTargetingDrawer from "./AudienceTargetingDrawer/AudienceTargetingDrawer";
import ContactCards from "./ContactCards/ContactCards";

const TopBannerAudienceContacts = (props) => {
  const { fetchAudienceDetails, audienceDetails } = props;
  const audience = props.audienceDetails;

  const campaignNames = audience.campaignName || "";
  const campaignList = campaignNames.split(",").map((name) => name.trim());
  const displayCount = 2;

  let displayedCampaigns = [];
  let remainingCampaigns = [];

  if (Array.isArray(campaignList)) {
    displayedCampaigns = campaignList.slice(0, displayCount);
    remainingCampaigns = campaignList.slice(displayCount);
  }

  const [audienceNameEditMode, setAudienceNameEditMode] = useState(false);

  const [showAudiencePushToCampaignFormModal, setAudiencePushToCampaignFormModal] = useState(false);

  let dateFormat = useOrgDateFormat();
  const { audienceId } = useParams();
  const breadcrumbs = [
    {
      href: "/" + props.orgId + "/lists",
      text: "Lists",
    },
    { active: true, text: audience.name },
  ];

  const handleAudiencePushToCampaignFormClose = () => {
    setAudiencePushToCampaignFormModal(false);
  };

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleAudiencePushToCampaignFormShow = (audience) => {
    if (audience.availableContacts) {
      setAudiencePushToCampaignFormModal(true);
    } else if (audience.availableContacts === 0 && audience.status === 2) {
      setAudiencePushToCampaignFormModal(false);
    } else if (audience.availableContacts === 0 && audience.status === 1) {
      var data = {
        audienceId: audience.id,
        campaignId: [],
      };

      api(API.audience.setAudienceImportSettings, data)
        .then((response) => {
          toast.success(toastMessages.audience.listUpdated);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const checkIfActionAssignIsDisable = (audience) => {
    if (audience.status === 1) return false;

    return !audience.availableContacts;
  };

  const history = useHistory();

  const audiencePushToCampaignForm = (
    <AudiencePushToCampaignForm
      handleClose={handleAudiencePushToCampaignFormClose}
      showModal={showAudiencePushToCampaignFormModal}
      listAudiences={props.listAudiences}
      audience={props.audienceDetails}
      updateImportSettingsOnly={false}
      getAudiences={() => fetchAudienceDetails(audienceId)}
      isFromAudiencesListView={true}
      orgId={props.orgId}
    />
  );

  const handleAudienceArchiveRestoreFormModalShow = () => {
    if (audience.campaignId && audience.campaignId.length === 0) {
      let data = {
        audienceId: audience.id,
      };
      api(formUrl(API.audience.archiveRestoreAudience, data))
        .then((res) => {
          toast.success(
            audience.isArchived ? toastMessages.audience.successRestoreAudience : toastMessages.audience.successArchiveAudience
          );
          fetchAudiences(props.param);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      props.setAudience(audience);
      props.setAudienceArchiveRestoreFormModal(true);
    }
  };

  return props.isLoaded ? (
    <div className={styles.audienceContactsWrapper}>
      <div className={styles.audienceContactsInfo}>
        <div className={styles.audienceContactsDetails}>
          <div className={styles.audienceContactsHistory}>
            <BreadcrumbElement items={breadcrumbs} />
            {props.remove ? (
              <HeaderTitle title={props.title} />
            ) : (
              <div className={styles.audienceNameWrapper}>
                {audienceNameEditMode ? (
                  <Formik
                    initialValues={{
                      name: audience.name,
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Required").max(100, "The list name exceeds the maximum length (100 characters)"),
                    })}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(true);

                      if (values.name !== audience.name) {
                        api(API.audience.updateAudienceName, {
                          audienceName: values.name,
                          audienceId: audienceId,
                        })
                          .then((response) => {
                            toast.success(toastMessages.audience.listUpdated);
                            props.changeAudience(audienceId);
                            setAudienceNameEditMode(false);
                          })
                          .catch((error) => {
                            const errorList = errorResponseToList(error);
                            if (errorList.length > 0) {
                              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                            } else {
                              toast.error(error.message);
                            }
                            actions.setSubmitting(false);
                          });
                      } else {
                        setAudienceNameEditMode(false);
                        actions.setSubmitting(false);
                      }
                    }}
                  >
                    {(formikProps) => {
                      const { handleSubmit, isSubmitting, values, submitForm } = formikProps;
                      return (
                        <form
                          className={styles.formFullWidth}
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (isSubmitting === false) {
                              handleSubmit();
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (isSubmitting === false) {
                                handleSubmit();
                              }
                            }
                          }}
                        >
                          <FormikInput
                            formgroupclass={styles.formGroupClass}
                            errorinputclass={styles.errorMessage}
                            name={"name"}
                            value={values.name}
                            placeholder={"Type Here..."}
                            onBlur={submitForm}
                          />
                        </form>
                      );
                    }}
                  </Formik>
                ) : (
                  <h1 className={styles.audienceTitle}>{audience.name}</h1>
                )}

                <Permission has={PERMISSIONS.campaign.edit}>
                  <div className={styles.editAudienceActionButton}>
                    <OverlayTrigger
                      key={"key"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`popover-tooltip`}>
                          <Popover.Body>Rename List</Popover.Body>
                        </Popover>
                      }
                    >
                      <div className="max-x-content">
                        <OverlayTrigger
                          key={"key"}
                          placement={"bottom"}
                          overlay={
                            <Popover id={`popover-tooltip`}>
                              <Popover.Body>Rename List</Popover.Body>
                            </Popover>
                          }
                        >
                          <div className="max-x-content">
                            <RemixIConRename
                              className="iconGray cursor_pointer"
                              onClick={() => setAudienceNameEditMode(!audienceNameEditMode)}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Permission>
                <DropdownButton title="" className={`${styles.dropdownButton} audienceDropdownButton`}>
                  <Select
                    options={props.listAudiences}
                    onChange={(c) => {
                      props.changeAudience(c);
                    }}
                    isSearchable={true}
                    menuIsOpen={true}
                    multiDropdown={true}
                  />
                </DropdownButton>
              </div>
            )}
            <div className={`${styles.actionDetails} ${props.remove ? styles.none : null}`}>
              <div className={styles.useDetailsWrapper}>
                <div className="flex align-center">
                  <UserPhoto name={audience.createdBy} size="small" />
                  {audience.createdBy && audience.createdBy.length > 22 ? (
                    <OverlayTrigger
                      key={"key"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`popover-tooltip-full-name`}>
                          <Popover.Body>{audience.createdBy}</Popover.Body>
                        </Popover>
                      }
                    >
                      <span className={styles.spanDataInfo}> {audience.createdBy}</span>
                    </OverlayTrigger>
                  ) : (
                    <span className={styles.spanDataInfo}> {audience.createdBy}</span>
                  )}
                </div>
                <p className="m0">
                  <span className={styles.dataTitle}>Source:</span>
                  <span className={styles.spanDataInfo}>
                    {audience.type === audienceTypeEnum.File ? "File" : ""}
                    {audience.type === audienceTypeEnum.Search ? "Search" : ""}
                  </span>
                </p>
              </div>
              <div className="mL10">
                <div className={`flex align-center ${styles.dateWrapper}`}>
                  <CalendarLineIcon size={18} className="iconGray" />
                  <span className={`m0 mL5 ${styles.spanDataInfo}`}>
                    {audience.createdDate != null && audience.createdDate !== "" ? Moment(audience.createdDate).format(dateFormat) : null}
                  </span>
                </div>
                <div className={`flex ${styles.listCampaigns}`}>
                  <span className={`${styles.dataTitle}`}>Campaign(s):</span>
                  <span className={styles.spanDataInfo}>
                    {audience.campaignId && audience.campaignId.length > 0 ? (
                      <>
                        <OverlayTrigger
                          key={"key"}
                          placement={"bottom"}
                          overlay={
                            <Popover id={`popover-tooltip`}>
                              <Popover.Body>
                                {remainingCampaigns.length === 0 ? displayedCampaigns.join("; ") : "+ " + remainingCampaigns.join("; ")}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <div>
                            {displayedCampaigns.join("; ").slice(0, 35)}
                            {displayedCampaigns.join("; ").length > 35 && "..."}
                            {remainingCampaigns.length > 0 && <span>; +{remainingCampaigns.length} more</span>}
                          </div>
                        </OverlayTrigger>
                      </>
                    ) : (
                      "Unassigned"
                    )}
                  </span>
                </div>
                <div className={styles.statusInfo}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.rightSideWraper} ${props.remove ? styles.none : null}`}>
          <ContactCards audienceDetails={audienceDetails} />
        </div>

        <div className={styles.topBannerButtonsDiv}>
          <Button
            id="viewTargeting"
            variant="secondary"
            classes={styles.targetingBtn}
            title="Targeting"
            onClick={() => setDrawerOpen(true)}
          >
            <Focus3LineIcon size={20} className="mR5" />
            View Targeting
          </Button>
          {audience.type === audienceTypeEnum.Search ? (
            <Button
              id="editList"
              variant="secondary-l-icon"
              classes="p0"
              title="Edit List"
              onClick={() => {
                history.push("/" + props.orgId + "/search?s=" + audience.searchURL + "&id=" + audience.id);
              }}
            >
              <Edit2LineIcon size={20} className="mR5 iconGray" />
            </Button>
          ) : null}
          <Button
            id="signBtn"
            variant="secondary-l-icon"
            classes={styles.assignToCampaign}
            onClick={() => handleAudiencePushToCampaignFormShow(audience)}
            title={audience.campaignId && audience.campaignId.length > 0 ? "Reassign/Unassign" : "Assign to campaign"}
            disabled={checkIfActionAssignIsDisable(audience)}
          >
            <EditBoxLineIcon size={20} className="iconGray" />
          </Button>
          {audiencePushToCampaignForm}

          {audience.isArchived === false ? (
            <>
              <Permission has={PERMISSIONS.audience.archiveRestoreAudience}>
                <Button
                  id="archiveButton"
                  variant="secondary-l-icon"
                  onClick={() => handleAudienceArchiveRestoreFormModalShow()}
                  title="Archive list"
                  classes="p0"
                >
                  <InboxArchiveLineIcon size={20} className="iconGray" />
                </Button>
              </Permission>
            </>
          ) : (
            <Button
              id="unarchive"
              title="Restore"
              variant="secondary-l-icon"
              classes="mR0 p0"
              onClick={() => handleAudienceArchiveRestoreFormModalShow()}
            >
              <InboxUnarchiveLineIcon size={21} className="cursor_pointer iconGray" />
            </Button>
          )}
        </div>
      </div>
      <AudienceTargetingDrawer
        audienceName={audienceDetails.name}
        audienceId={audienceDetails.id}
        isOpen={isDrawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        searchModel={audienceDetails.searchModel}
      />
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
    isLoading: state.globalAudiences.isLoading,
    isLoaded: state.globalAudiences.isLoaded,
    error: state.globalAudiences.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAudienceDetails: (audienceId) => dispatch(fetchAudienceDetails(audienceId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBannerAudienceContacts);
