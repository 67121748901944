export const emailTemplateTypeEnum = {
  InitialEmail: 1,
  FollowUp: 2,
};

export const emailTemplateTypeEnumList = [
  { value: "1", label: "Initial Email" },
  { value: "2", label: "Follow Up" },
];

export const emailTemplateTypeEnumOptions = [
  { value: "", label: "Select Type" },
  { value: "1", label: "Initial Email" },
  { value: "2", label: "Follow Up" },
];

export const EmailTemplateTone = {
  InformalTone: "Informal",
  FormalTone: "Formal",
};
