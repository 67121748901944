export const FETCH_GLOBAL_SEARCH_PROSPECTS_REQUEST =
  "FETCH_GLOBAL_SEARCH_PROSPECTS_REQUEST";
export const FETCH_GLOBAL_SEARCH_PROSPECTS_SUCCEEDED =
  "FETCH_GLOBAL_SEARCH_PROSPECTS_SUCCEEDED";
export const FETCH_GLOBAL_SEARCH_PROSPECTS_FAILED =
  "FETCH_GLOBAL_SEARCH_PROSPECTS_FAILED";

export const FETCH_GLOBAL_SEARCH_COMPANIES_REQUEST =
  "FETCH_GLOBAL_SEARCH_COMPANIES_REQUEST";
export const FETCH_GLOBAL_SEARCH_COMPANIES_SUCCEEDED =
  "FETCH_GLOBAL_SEARCH_COMPANIES_SUCCEEDED";
export const FETCH_GLOBAL_SEARCH_COMPANIES_FAILED =
  "FETCH_GLOBAL_SEARCH_COMPANIES_FAILED";

export const FETCH_GLOBAL_SEARCH_CAMPAIGNS_REQUEST =
  "FETCH_GLOBAL_SEARCH_CAMPAIGNS_REQUEST";
export const FETCH_GLOBAL_SEARCH_CAMPAIGNS_SUCCEEDED =
  "FETCH_GLOBAL_SEARCH_CAMPAIGNS_SUCCEEDED";
export const FETCH_GLOBAL_SEARCH_CAMPAIGNS_FAILED =
  "FETCH_GLOBAL_SEARCH_CAMPAIGNS_FAILED";

export const GLOBAL_SEARCH_RECORDS = "GLOBAL_SEARCH_RECORDS";
