import React from "react";
import svg from "./campaign_create_new_audience.svg";

const CampaignCreateNewAudience = (props) => (
  <img
    src={svg}
    className={props.className}
    alt="campaign_create_new_audience"
  />
);

export default CampaignCreateNewAudience;
