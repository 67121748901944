import React from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

// Styles
import styles from "./MessagePreview.module.scss";

//Images & Icons
import MessagePreviewIcon from "../../../assets/Icons/SectionMessagePreview/MessagePreview";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import IconManualChange from "../../../assets/Icons/IconManualChange/IconManualChange";
import IconArtificialIntelligence from "../../../assets/Icons/IconArtificialIntelligence/IconArtificialIntelligence";

//Components
import MessageIframe from "../../../components/UI/MessageIframe/MessageIframe";
import ResponseTypePill from "../MessageList/ResponseType/ResponseTypePill";
import WarningIcon from "../../../assets/Icons/Warning/WarningIcon";
import AISuggestion from "../AISuggestion/AISuggestion";

const MessagePreview = (props) => {
  const { messagePreview } = props;

  let preview = null;
  if (props.isLoaded) {
    preview = (
      <div id="messageDetailView" className={`${styles.messagePreviewWrapper} messageDetailView`}>
        <div className={styles.selectedMessage}>
          {props.messagePreviewClosed ? (
            <>
              {" "}
              <p className={styles.sectionTitle}>Message Preview</p>
              <div className="flex_center flexCol">
                <ArrowLeftSLineIcon
                  className={`circleToggleICon cursor_pointer iconGray400 ${styles.arrowIcon}`}
                  onClick={() => props.setMessagePreviewClosed(!props.messagePreviewClosed)}
                />
                <MessagePreviewIcon imgStyle="mT5 cursor_pointer" />
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className={styles.selectedMessageBody}>
                <div className={styles.headerMessage}>
                  <div className="flex space_between">
                    <h2>
                      <span>Subject: </span>
                      {messagePreview?.subject}
                    </h2>
                    <div>
                      <span className="f12 secondary_text_color">{messagePreview?.date}</span>
                      <ArrowLeftSLineIcon
                        className={`circleToggleICon cursor_pointer iconGray400 ${styles.transformRight}`}
                        onClick={() => props.setMessagePreviewClosed(!props.messagePreviewClosed)}
                      />
                    </div>
                  </div>
                  <div className={styles.senderInfo}>
                    <p>
                      <span className={styles.emailDetails}>{messagePreview?.emailFrom}</span>
                    </p>
                    <p>
                      <span className="secondary_text_color">To:</span>
                      {messagePreview?.emailTo}
                    </p>
                  </div>
                  {messagePreview?.cc && messagePreview?.cc !== "" ? (
                    <div>
                      <p>
                        <span className="secondary_text_color">Cc:</span> {messagePreview?.cc}
                      </p>
                    </div>
                  ) : null}
                  <div className="flex align-center">
                    {messagePreview?.isResolved && messagePreview?.isAutoResolved && messagePreview?.manualReview ? (
                      <OverlayTrigger
                        key={"active"}
                        placement={"bottom"}
                        overlay={
                          <Popover id={`warning`}>
                            <Popover.Body>Response categorized by AI but needs review</Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <WarningIcon />
                        </div>
                      </OverlayTrigger>
                    ) : null}
                    {messagePreview?.isResolved && messagePreview?.isAutoResolved && !messagePreview.isManuallyResolved ? (
                      <OverlayTrigger
                        key={"active"}
                        placement={"bottom"}
                        overlay={
                          <Popover id={`ai`}>
                            <Popover.Body>Response categorized by AI</Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <IconArtificialIntelligence imgStyle="mR5" />
                        </div>
                      </OverlayTrigger>
                    ) : messagePreview?.isResolved ? (
                      <OverlayTrigger
                        key={"manual"}
                        placement={"bottom"}
                        overlay={
                          <Popover id={`manual`}>
                            <Popover.Body>Response categorized manually</Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <IconManualChange imgStyle="mR5" />
                        </div>
                      </OverlayTrigger>
                    ) : null}
                    <ResponseTypePill type={messagePreview?.responseTypeName} />
                  </div>
                </div>
                <div className={styles.messageBody}>
                  {messagePreview?.isResolved ? null : <AISuggestion messagePreview={messagePreview} />}
                  <MessageIframe>{messagePreview?.responseMessage}</MessageIframe>
                  {/* <iframe title="Message preview" srcDoc={messagePreview.responseMessage}></iframe> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return <>{preview}</>;
};

const mapStateToProps = (state) => {
  return {
    messagePreview: state.responderInbox.messagePreview,
    isLoaded: state.responderInboxTable.isLoaded,
  };
};

export default connect(mapStateToProps)(MessagePreview);
