import { api } from "./api";
import { AllInAppNotificationsModel } from "../models/notifications/AllInAppNotificationsModel";

export const notificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<AllInAppNotificationsModel, void>({
      query: () => "/in-app-notifications-log/in-app-notifications-log",
      providesTags: ["Notifications"],
    }),
    markAllNotificationsAsRead: builder.mutation<boolean, void>({
      query: () => ({
        url: "/in-app-notifications-log/mark-all-notifications-as-read",
        method: "POST",
      }),
      invalidatesTags: ["Notifications"],
    }),
    markNotificationAsRead: builder.mutation<boolean, number>({
      query: (notificationId) => ({
        url: `/in-app-notifications-log/mark-notification-as-read?notificationId=${notificationId}`,
        method: "POST",
      }),
    }),
    deleteNotification: builder.mutation<boolean, number>({
      query: (notificationId) => ({
        url: `/in-app-notifications-log/delete-notification?notificationId=${notificationId}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetNotificationsQuery, useMarkAllNotificationsAsReadMutation, useMarkNotificationAsReadMutation, useDeleteNotificationMutation } = notificationApi;
