import { FC, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import Modal from "../../../components/UI/Modal/Modal";
import { useRemoveSuspensionMutation, useSuspendAccountMutation } from "@api/outboss/outbossEmailAccountsApi";
import { SuspendAccountModel } from "@models/outboss/emailAccounts/SuspendAccountModal";

//Styles

interface SuspendAccountModalProps {
  emailAccountId: number;
  email: string;
  showModal: boolean;
  handleClose: () => void;
  fetchEmailAccountsCallback: () => void;
}

const SuspendAccountModal: FC<SuspendAccountModalProps> = ({
  emailAccountId,
  email,
  showModal,
  handleClose,
  fetchEmailAccountsCallback,
}) => {
  const [suspendAccount, query] = useSuspendAccountMutation();
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (query.isSuccess) {
      handleClose();
      // Using this until we switch fetchEmailAccounts to RTK query - then we can invalidate the tag.
      fetchEmailAccountsCallback();
    }
  }, [query.isSuccess, handleClose]);

  return (
    <Modal
      title="Suspension"
      handleClose={handleClose}
      handleSave={() => {
        const data: SuspendAccountModel = {
          emailAccountId: emailAccountId,
          reason: reason,
        };
        suspendAccount(data);
      }}
      show={showModal}
      closeButtonText="Cancel"
      saveButtonText="Suspend account"
      btnType="submit"
      customBodyClass="overflow_unset max_height_none"
      saveButtonDisabled={query.isLoading}
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>
              Are you sure you want to suspend this account? <b>{email}</b>?<br />
              <textarea name="suspensionReason" placeholder="Enter a suspension reason..." onChange={(e) => setReason(e.target.value)} />
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default SuspendAccountModal;
