export const prospectEngagedTableColumns = [
  // {
  //   label: '',
  //   field: 'checkBox',
  //   sort: 'asc',
  //   width: 50
  // },
  {
    label: "Email",
    field: "email",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Name",
    field: "name",
    type: "text",
    sort: "asc",
    width: 150,
    showTooltip: true,
  },
  {
    label: "Job Title",
    field: "jobTitle",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Company Name",
    field: "companyName",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Current Status",
    field: "currentStatus",
    type: "actions",
    sort: "asc",
    width: 150,
  },
  {
    label: "Response Type",
    field: "responseType",
    type: "text",
    sort: "asc",
    width: 150,
    showTooltip: false,
  },
  {
    label: "Current Stage",
    field: "currentStage",
    type: "number",
    sort: "asc",
    width: 150,
  },
  {
    label: "Campaign Name",
    field: "campaignName",
    type: "text",
    sort: "asc",
    width: 150,
    showTooltip: true,
  },
  {
    label: "Conversations",
    field: "conversations",
    type: "actions",
    sort: "disabled",
    width: 150,
    isHidden: true,
  },
  {
    label: "Last Send",
    field: "lastSend",
    type: "date",
    sort: "asc",
    width: 150,
    isHidden: true,
  },
  {
    label: "Last Activity",
    field: "lastActivity",
    type: "date",
    sort: "asc",
    width: 150,
    isHidden: true,
  },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    width: 90,
    isHidden: true,
  },
  {
    label: "Excluded",
    field: "isExcluded",
    type: "actions",
    sort: "asc",
    width: 80,
    isHidden: true,
  },
  {
    label: "Prospect Country",
    field: "prospectCountry",
    type: "text",
    sort: "asc",
    width: 150,
    isHidden: true,
  },
];
