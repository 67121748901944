import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";
import moment_timezone from "moment-timezone";
import { Link } from "react-router-dom";

//Redux
import {
  fetchOrganizations,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../store/Outboss/Organizations/Table/actions/organizations";

//Helpers
import api, { API } from "../../../helper/api/api";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { filterData, filtersToQueryString } from "../../../helper/tableConstants";
import { useQueryTableFilters } from "../../../helper/hooks/useQueryTableFilters";
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";

//Style
import styles from "./Organizations.module.scss";

//Images & Icons
import ActionCreditAdjustment from "../../../components/UI/ActionWrapper/Actions/ActionCreditAdjustment";
import ActionEnableDisableOrganization from "../../../components/UI/ActionWrapper/Actions/ActionEnableDisableOrganization";
import ActionLoginAsAdmin from "../../../components/UI/ActionWrapper/Actions/ActionLoginAsAdmin";

//Components
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import { columns } from "./columns";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import Table from "../../../components/DataTable/Table";
import DisableOrganizationModal from "./Modals/DisableOrganizationModal/DisableOrganizationModal";
import CreditAdjustmentModal from "./Modals/CreditAdjustmentModal/CreditAdjustmentModal";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import OrganizationInfoColOutbOss from "../../../components/DataTable/OrganizationInfoColOutbOss/OrganizationInfoColOutbOss";
import TotalLoginsColOutbOss from "../../../components/DataTable/TotalLoginsColOutbOss/TotalLoginsColOutbOss";
import PlanColOutbOss from "../../../components/DataTable/PlanColOutbOss/PlanColOutbOss";
import CreditBalanceColOutbOss from "../../../components/DataTable/CreditBalanceColOutbOss/CreditBalanceColOutbOss";
import NoUsersColOutbOss from "../../../components/DataTable/NoUsersColOutbOss/NoUsersColOutbOss";
import TableOptionsRow from "../../../components/DataTable/TableOptionsRow/TableOptionsRow";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";

const Organizations = (props) => {
  const { param, rows, fetchOrganizations, isInitiated, initiate, clearInitiated } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  let filterTypes = ["Campaign", "Type", "Status"];
  let state = useQueryTableFilters(filterTypes);
  const [tableRows, setTableRows] = useState([]);
  let minDate = Moment.utc("0001-01-01").format(dateFormat);
  moment_timezone.tz.setDefault("UTC");
  let utcMoment = Moment().toDate();
  let separators = useGroupDecimalSeparator();
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);

  useEffect(() => {
    initiate(state);
  }, []);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
    };
    initiate(updateState);
  };

  const exportAllOrganizations = () => {
    let chColumns = dataTableColumns.map((column) => column.field);
    let data = {
      chosenColumns: chColumns,
      param: param,
    };
    api(API.OUTBOSS.organizations.exportAllOrganizations, data, "blob")
      .then((res) => {
        fileDownload("_allOutbossOrganizations.csv", res.data);
        setExportButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (isInitiated) {
      fetchOrganizations(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
        // props.history.push(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationAccessRemoved, setOrganizationAccessRemoved] = useState(false);

  const [showDisableOrganizationModal, setShowDisableOrganizationModal] = useState(false);
  const handleDisableOrganizationModalClose = () => {
    setOrganizationId(null);
    setOrganizationName("");
    setOrganizationAccessRemoved(false);
    setShowDisableOrganizationModal(false);
  };
  const handleDisableOrganizationModalShow = (organizationId, organizationName, organizationAccessRemoved) => {
    setOrganizationId(organizationId);
    setOrganizationName(organizationName);
    setOrganizationAccessRemoved(organizationAccessRemoved);
    setShowDisableOrganizationModal(true);
  };

  const [showCreditAdjustmentModal, setShowCreditAdjustmentModal] = useState(false);
  const handleCreditAdjustmentModalClose = () => {
    setOrganizationId(null);
    setOrganizationName("");
    setShowCreditAdjustmentModal(false);
  };
  const handleCreditAdjustmentModalShow = (organizationId, organizationName) => {
    setOrganizationId(organizationId);
    setOrganizationName(organizationName);
    setShowCreditAdjustmentModal(true);
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r, i) => {
      let orgName = r.name;

      let name = (
        <Link
          key={r.id}
          to={{
            pathname: "/outboss/organization-settings/" + r.id + "/general-settings",
          }}
        >
          <OrganizationInfoColOutbOss name={r.name} owner={r.owner} />
        </Link>
      );

      r.name = name;

      r.createdDate = Moment(r.createdDate).format(dateFormat);

      let totalLogin = <TotalLoginsColOutbOss totalLogins={r.totalLogins} minDate={minDate} />;
      r.totalLogin = totalLogin;

      r.lastLogin = Moment(r.lastLogin).format(dateFormat);

      let plan = <PlanColOutbOss plan={r.isTrial ? "Free trial" : r.plan} />;
      r.plan = plan;
      r.renewalDate = Moment(r.renewalDate).format(dateFormat);

      let numberOfUsers = (
        <NoUsersColOutbOss
          invitedUsers={formatNumberHelper(r.invitedUsers, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
          totalUsers={formatNumberHelper(r.totalUsers, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
          availableUsers={formatNumberHelper(
            r.totalUsers - r.invitedUsers,
            separators.numberGroupSeperator,
            separators.numberDecimalSeperator
          )}
          organizationId={r.id}
          userPermissions={props.userPermissions}
          history={props.history}
        />
      );

      r.numberOfUsers = numberOfUsers;

      let creditBalance = (
        <CreditBalanceColOutbOss
          availableFormatted={formatNumberHelper(r.availableCredits, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
          totalFormatted={formatNumberHelper(r.totalCredits, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
          usedFormatted={formatNumberHelper(r.usedCredits, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
          isTrial={r.isTrial}
          nextRefill={Moment(r.renewalDate).diff(utcMoment, "days")}
        />
      );
      r.creditBalance = creditBalance;
      let actions = (
        <ActionWrapper>
          <div className={styles.iconsWrapper}>
            <ActionWrapper>
              <ActionLoginAsAdmin key={"r.id" + i} organizationId={r.id} />
              <ActionCreditAdjustment key={orgName + i} onClick={() => handleCreditAdjustmentModalShow(r.id, orgName)} />
              <ActionEnableDisableOrganization
                key={r.organizationAccessRemoved + i}
                onClick={() => handleDisableOrganizationModalShow(r.id, orgName, r.organizationAccessRemoved)}
                organizationAccessRemoved={r.organizationAccessRemoved}
              />
            </ActionWrapper>
          </div>
        </ActionWrapper>
      );
      r.actions = actions;

      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>Error getting Organizations</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  let tableFiltersRow = null;
  let tableOptionsRow = null;

  if (props.isLoaded) {
    table = (
      <Table
        tableName={"Organizations"}
        columns={dataTableColumns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        scrollX={true}
        tableClassName={`${styles.orgFiltersTable} orgFiltersTable`}
        variant="extraLarge"
        outBoss
        tableHeight={250}
        tableFooterClass="datePickerTable"
        filterData={filterData}
        setFilter={props.setFilter}
      />
    );

    tableFiltersRow = (
      <TableFiltersRow
        searchValue={param.search}
        placeholder="organizations"
        changeFilter={(text) => props.searchRecords(text)}
        searchOnFirstCharacter={true}
      />
    );

    tableOptionsRow = (
      <TableOptionsRow
        applyChanges={applyDataTableColumnsChange}
        exportTable={exportAllOrganizations}
        exportButtonDisabled={exportButtonDisabled}
        setExportButtonDisabled={setExportButtonDisabled}
        importData={null}
        dataTableColumns={dataTableColumns}
        setDataTableColumns={setDataTableColumns}
        allDataTableColumns={columns}
      />
    );
  }

  return (
    <PageContentWrapper className={styles.contentWrapper}>
      <HeaderTitle title="Organizations" />
      {tableFiltersRow}
      {tableOptionsRow}
      {table}
      <DisableOrganizationModal
        handleClose={handleDisableOrganizationModalClose}
        showModal={showDisableOrganizationModal}
        fetchOrganizations={() => fetchOrganizations(param)}
        organizationId={organizationId}
        organizationName={organizationName}
        organizationAccessRemoved={organizationAccessRemoved}
      />
      <CreditAdjustmentModal
        handleClose={handleCreditAdjustmentModalClose}
        showModal={showCreditAdjustmentModal}
        fetchOrganizations={() => fetchOrganizations(param)}
        organizationId={organizationId}
        organizationName={organizationName}
      />
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.outbossOrganizations.params,
    isInitiated: state.outbossOrganizations.isInitiated,
    isLoading: state.outbossOrganizations.isLoading,
    isLoaded: state.outbossOrganizations.isLoaded,
    error: state.outbossOrganizations.error,
    rows: state.outbossOrganizations.rows,
    totalCount: state.outbossOrganizations.totalCount,
    pageCount: state.outbossOrganizations.pageCount,
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizations: (param) => dispatch(fetchOrganizations(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
