import React from "react";

// Styles
import styles from "./GDPRSamplesDocuments.module.scss";

//Images & Icons
import SectionInbox from "../../../assets/Icons/SectionInbox/SectionInbox";
import IconFileCsv from "../../../assets/Icons/IconFileCsv/IconFileCsv";
import IconFileWord from "../../../assets/Icons/IconFileWord/IconFileWord";
import LinksFillIcon from "remixicon-react/LinksFillIcon";

//Components
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";

export const GDPRSamplesDocuments = () => (
  <div className={styles.gdprsWrapper}>
    <SectionTitle
      titleClass={styles.sectionTitle}
      titleWrapperClass="align-flex-start"
      icon={<SectionInbox imgStyle={styles.sectionIcon} />}
      title="COMPLIANCE SUPPORT LINKS AND DOCS"
    />
    <div className={styles.linksWrapper}>
      <div className={`${styles.documentLink} flex`}>
        <span>
          <LinksFillIcon size={16} className="iconGray400" />
        </span>
        <a className="f14" target="_blank" rel="noopener noreferrer" href="https://outbase.com/privacy-policy/#out-gdpr-policy">
          Outbase Privacy Policy
        </a>
      </div>
      <div className={`${styles.documentLink} flex`}>
        <span>
          <IconFileWord imgStyle={styles.iconCsv} />
        </span>
        <a
          className="f14"
          target="_blank"
          rel="noopener noreferrer"
          href="https://outbase.blob.core.windows.net/documents/Legitimate Interest Assessment (LIA) Template.docx"
        >
          LIA Template
        </a>
      </div>
      <div className={`${styles.documentLink} flex`}>
        <span>
          <IconFileWord imgStyle={styles.iconCsv} />
        </span>
        <a
          className="f14"
          target="_blank"
          rel="noopener noreferrer"
          href="https://outbase.blob.core.windows.net/documents/Outbase Data Processing Addendum (DPA).docx"
        >
          Outbase DPA
        </a>
      </div>
      {/* <div className={`${styles.documentLink} flex`}>
        <span>
          <IconLink />
        </span>
        <a
          className="f14"
          target="_blank"
          rel="noopener noreferrer"
          href="https://outbase.com/privacy-policy/"
        >
          Sample privacy policy
        </a>
      </div> */}
      <div className={`${styles.documentLink} flex`}>
        <span>
          <IconFileCsv imgStyle={styles.iconCsv} />
        </span>
        <a
          className="f14"
          target="_blank"
          rel="noopener noreferrer"
          href="https://outbase.blob.core.windows.net/documents/SAR_Export_Sample.csv"
        >
          SAR Export sample
        </a>
      </div>
    </div>
  </div>
);
