import React from "react";

//Helpers
import { emailTemplateStatusEnum } from "../../../../../helper/enums/emailTemplateStatusEnum";

//Images & Icons
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import ChatUploadLineIcon from "remixicon-react/ChatUploadLineIcon";

//Styles
import styles from "./StatsBox.module.scss";

const StatsBox = (props) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div className={`${props.boxClassName ? props.boxClassName : ""} width_100`}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTemplateInfo}>
          <h1 className={styles.cardTitle}>
            {props.name}{" "}
            <span
              className={`f12 mL5 ${styles.statusTag} ${
                props.status === emailTemplateStatusEnum.Draft ? styles.draftStatus : styles.publishedStatus
              }`}
            >
              {props.status === emailTemplateStatusEnum.Draft ? "Draft" : "Published"}
            </span>
          </h1>

          {/* <span className={styles.templateType}>{props.type === 1 ? "Initial" : "Follow up"}</span> */}
        </div>
        {props.isAccordion && (
          <div onClick={handleClick} className={styles.actionButtons}>
            {props.status === emailTemplateStatusEnum.Draft && (
              <button
                id="publish"
                className={`${styles.publishLinkButton} ${styles.linkButton}`}
                onClick={props.onPublishClick}
                rel="noopener noreferrer"
                href="#"
              >
                <ChatUploadLineIcon className={styles.publishLinkIcon} size={17} /> Publish
              </button>
            )}

            <button className={styles.linkButton} id="edit" rel="noopener noreferrer" href="#" onClick={props.onClick}>
              <Edit2LineIcon size={16} /> Edit
            </button>
          </div>
        )}
      </div>
      <div className={`${props.statsWrapperClassName ? props.statsWrapperClassName : ""} ${styles.statsWrapper}`}>
        <div className={`${props.isAccordion && styles.cardBodyInAccordion} ${styles.cardBody}`}>
          <div className={styles.dataWrapper}>
            <span className={`${styles.dataHeader} f13`}>Open</span>
            <span className={`${styles.data} ${styles.info} f13`}>{props.openRate.toFixed(2)}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={`${styles.dataHeader} f13`}>Click</span>
            <span className={`${styles.data} ${styles.warning} f13`}>{props.clickRate.toFixed(2)}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={`${styles.dataHeader} f13`}>Lead</span>
            <span className={`${styles.data} ${styles.success} f13`}>{props.leadRate.toFixed(2)}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={`${styles.dataHeader} f13`}>In Use</span>
            <span className={`${styles.data} f13`}>{props.inUse}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={`${styles.dataHeader} f13`}>Total Sent</span>
            <span className={`${styles.data} f13`}>{props.totalSend}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsBox;
