import React, { useEffect, useState } from "react";

//Styles
import styles from "./CheckListProgressBar.module.scss";

//Components
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const CheckListProgressBar = (props) => {
  const {
    audienceCreated,
    emailTemplatesFollowUpPublishedCount,
    emailTemplatesInitialPublishedCount,
    emailAccountConnected,
    campaignSetup,
  } = props.organizationOnboardingModel || {};

  let [progress, setProgress] = useState(0);

  useEffect(() => {
    let newProgress = 0;

    if (audienceCreated) newProgress += 25;
    if (emailTemplatesInitialPublishedCount && emailTemplatesFollowUpPublishedCount > 0) newProgress += 25;
    if (emailAccountConnected) newProgress += 25;
    if (campaignSetup) newProgress += 25;

    setProgress(newProgress);
  }, [audienceCreated, emailTemplatesInitialPublishedCount, emailTemplatesFollowUpPublishedCount, emailAccountConnected, campaignSetup]);

  if (props.organizationOnboardingModel === null) return <></>;

  return (
    <div className={styles.checkListProgressBar}>
      <NavLink
        to={{
          pathname: "/" + props.organizationId + "/dashboard",
        }}
      >
        <ProgressBar
          variant={progress === 100 ? "green" : "white_transparent"}
          now={progress}
          max={100}
          min={0}
          topLabelText={progress === 100 ? "Starting your campaign" : "Get started on Outbase!"}
        />
      </NavLink>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationOnboardingModel: state.organizationSettingsGeneral.organizationOnboardingModel,
    organizationId: state?.user?.organization?.id ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckListProgressBar);
