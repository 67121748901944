import React, { useState, useEffect } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";

//Redux
import {
  fetchUserActivityLogs,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../store/Outboss/UserActivityLogs/Table/actions/userActivityLogs";

//Helpers
import { numberOfRecords, filtersToQueryString, filterData } from "../../../helper/tableConstants";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { useQueryTableFilters } from "../../../helper/hooks/useQueryTableFilters";

//Style
import styles from "./UserActivityLogs.module.scss";

//Components
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Table from "../../../components/DataTable/Table";
import ActivityDetailsModal from "./Modal/ActivityDetailsModal";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import { columns } from "./columns";
import TableOptionsRow from "../../../components/DataTable/TableOptionsRow/TableOptionsRow";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import EyeLineIcon from "remixicon-react/EyeLineIcon";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";

const UserActivityLogs = (props) => {
  const { param, rows, fetchUserActivityLogs, isInitiated, initiate, clearInitiated } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  const [tableRows, setTableRows] = useState([]);
  let state = useQueryTableFilters([]);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));

  const [activityLogId, setActivityLogId] = useState(null);
  const [showActivityDetailsModal, setShowActivityDetailsModal] = useState(false);
  const handleActivityDetailsModalClose = () => {
    setActivityLogId(null);
    setShowActivityDetailsModal(false);
  };
  const handleUpdateDomainModalShow = (rowData) => {
    setActivityLogId(rowData.id);
    setShowActivityDetailsModal(true);
  };

  useEffect(() => {
    initiate(state);
  }, []);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
    };
    initiate(updateState);
  };

  useEffect(() => {
    if (isInitiated) {
      fetchUserActivityLogs(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r) => {
      r.activityDate = Moment(r.createdDate).format("DD MMM YYYY hh:mm");

      let activityDetails = (
        <ActionWrapper>
          <EyeLineIcon size={14} imgStyle="cursor_pointer iconGray" onClick={() => handleUpdateDomainModalShow(r)} />
        </ActionWrapper>
      );
      r.activityDetails = activityDetails;
      r.activityType = r.typeDescription;
      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>Error getting User activity logs</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  let tableFiltersRow = null;
  let tableOptionsRow = null;
  if (props.isLoaded) {
    table = (
      <Table
        outBoss
        tableName={"User ActivityLogs"}
        columns={dataTableColumns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        scrollX={true}
        variant="extraLarge"
        tableHeight={250}
        filterData={filterData}
        setFilter={props.setFilter}
      />
    );

    tableFiltersRow = (
      <TableFiltersRow searchValue={param.search} placeholder="user activity logs" changeFilter={(text) => props.searchRecords(text)} />
    );

    tableOptionsRow = (
      <TableOptionsRow
        applyChanges={applyDataTableColumnsChange}
        exportTable={null}
        importData={null}
        dataTableColumns={dataTableColumns}
        setDataTableColumns={setDataTableColumns}
        allDataTableColumns={columns}
      />
    );
  }

  return (
    <PageContentWrapper className={styles.contentWrapper}>
      <HeaderTitle title="User Logs" />
      {tableFiltersRow}
      {tableOptionsRow}
      {table}
      <ActivityDetailsModal
        handleClose={handleActivityDetailsModalClose}
        activityLogId={activityLogId}
        showModal={showActivityDetailsModal}
        userActivityLogDetails={props.userActivityLogDetails}
        userActivityLogDetailsLoading={props.userActivityLogDetailsLoading}
        userActivityLogDetailsLoaded={props.userActivityLogDetailsLoaded}
        userActivityLogDetailsError={props.userActivityLogDetailsError}
      />
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.outbossUserActivityLogsDataTable.params,
    isInitiated: state.outbossUserActivityLogsDataTable.isInitiated,
    isLoading: state.outbossUserActivityLogsDataTable.isLoading,
    isLoaded: state.outbossUserActivityLogsDataTable.isLoaded,
    error: state.outbossUserActivityLogsDataTable.error,
    rows: state.outbossUserActivityLogsDataTable.rows,
    totalCount: state.outbossUserActivityLogsDataTable.totalCount,
    pageCount: state.outbossUserActivityLogsDataTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserActivityLogs: (param) => dispatch(fetchUserActivityLogs(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivityLogs);
