import React from "react";

// Style
import styles from "./InfoRow.module.scss";

// Components
import AlertNotification from "../AlertNotification/AlertNotification";

interface InfoRowProps {
  text: string;
}

const InfoRow: React.FC<InfoRowProps> = ({ text }) => {
  return (
    <div className={`${styles.center} mTb15`}>
      <AlertNotification variant="info_alert">
        <span className="m0">{text}</span>
      </AlertNotification>
    </div>
  );
};

export default InfoRow;
