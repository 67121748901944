import React from "react";

//Style
import styles from "./ContactCards.module.scss";
import InfoIconTooltip from "../../../components/UI/CustomTooltip/InfoIconTooltip";
import { useOrgNumberFormatting } from "../../../helper/hooks/useOrgNumberFormatting";

const ContactCards = (props) => {
  let audienceInformations = null;
  let availableContacts = parseInt(props.audienceDetails.availableContacts);
  let dontImportContacts = parseInt(props.audienceDetails.dontImportContacts);
  let audienceSize = props.audienceDetails.audienceSize;
  let availableContactsFormatted = useOrgNumberFormatting(availableContacts);
  let dontImportContactsFormatted = useOrgNumberFormatting(dontImportContacts);
  let audienceSizeFormatted = useOrgNumberFormatting(audienceSize);

  audienceInformations = (
    <div id="availableContacts" className={styles.audienceInformationsRow}>
      <div className={`${styles.statusBox} ${styles.available}`}>
        <p className={styles.number}>{props.audienceDetails ? availableContactsFormatted : 0}</p>
        <h5>Available</h5>
        <div className={`m0 flex center_flex f12 ${styles.infoExcluded}`}>
          <span>{props.audienceDetails ? dontImportContactsFormatted : 0} excluded</span>
          <InfoIconTooltip
            tooltipBody={"These contacts are excluded from the process of importing prospects"}
            disableStartTour
            tooltipClass={styles.customTooltipPosition}
          />
        </div>
      </div>

      <div className={`${styles.statusBox} ${styles.totalCol}`}>
        <p className={styles.number}>{audienceSizeFormatted}</p>
        <h5>Total</h5>
      </div>
    </div>
  );
  return <div>{audienceInformations}</div>;
};

export default ContactCards;
