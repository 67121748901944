import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
// import { ExceptionlessClient } from "exceptionless";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./ErrorPage.module.scss";

//Images & Icons
import HustonErrorImage from "../../assets/Images/404/HustonErrorImage/HustonErrorImage";
import GlitchErrorImage from "../../assets/Images/404/GlitchErrorImage/GlitchErrorImage";
import PageDisturbingErrorImage from "../../assets/Images/404/PageDisturbingErrorImage/PageDisturbingErrorImage";
import GroundControlErrorImage from "../../assets/Images/404/GroundControlErrorImage/GroundControlErrorImage";
import MajorTeamErrorImage from "../../assets/Images/404/MajorTomErrorImage/MajorTomErrorImage";
import BeamErrorImage from "../../assets/Images/404/BeamErrorImage/ScottyErrorImage";
import ReadInSpaceErrorImage from "../../assets/Images/404/ReadInSpaceErrorImage/ReadInSpaceErrorImage";
import UncharteredErrorImage from "../../assets/Images/404/UncharteredErrorImage/UncharteredErrorImage";
import CombinationMarkLogoDark from "../../assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import LoggedInUser from "../../components/Header/LoggedInUser/LoggedInUser";
import ErrorPageComponent from "./components/ErrorPageComponent/ErrorPageComponent";
import { Exceptionless } from "@exceptionless/react";
import * as Sentry from "@sentry/react";

const ErrorPage = (props) => {
  const { location, error } = props;
  let userComponent = <LoggedInUser orgPage={true} />;

  let navLinkUrl = "/dashboard";

  const goToDashboard = () => {
    window.location.replace(navLinkUrl);
  };

  let { organizationId } = useParams();
  if (organizationId == null && location != null) {
    var orgMatch = location.pathname.match(/\/(\d+)\//);
    if (orgMatch != null && orgMatch[1] != null) {
      organizationId = orgMatch[1];
    }
  }

  if (organizationId != null) {
    navLinkUrl = `/${organizationId}${navLinkUrl}`;
  }

  const goBack = () => {
    const referrer = document.referrer;
    if (referrer) {
      window.location.href = referrer;
    } else {
      window.location.replace(navLinkUrl);
    }
  };

  let logo = (
    <div>
      <NavLink aria-label="Go to dashboard" to={navLinkUrl}>
        <CombinationMarkLogoDark width={150} height={37} />
      </NavLink>
    </div>
  );

  const content = [
    {
      title: "HOUSTON, WE HAVE A PROBLEM…",
      image: <HustonErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control , or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "GROUND CONTROL TO MAJOR TOM…",
      image: <GroundControlErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control , or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "IT'S ONE SMALL GLITCH FOR MAN…",
      image: <GlitchErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control , or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "I FIND YOUR LACK OF PAGE DISTURBING…",
      image: <PageDisturbingErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control, or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "GROUND CONTROL TO MAJOR TOM…",
      image: <MajorTeamErrorImage imgStyle={styles.imageRoundedLight} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control, or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "BEAM US UP, SCOTTY…",
      image: <BeamErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control , or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "IN SPACE, NO ONE CAN READ THIS PAGE…",
      image: <ReadInSpaceErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control , or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
    {
      title: "YOU'RE IN UNCHARTERED TERRITORY…",
      image: <UncharteredErrorImage imgStyle={styles.imageRoundedDark} />,
      subtitle1: (
        <>
          There's a glitch with this page, but you can head back to ground control , or report an error via live chat or{" "}
          <a className={styles.linkText} href="mailto:support@outbase.com">
            support@outbase.com
          </a>
        </>
      ),
    },
  ];

  useEffectOnce(() => {
    if (error) {
      Sentry.captureException(error);
    }

    let errorObject = error ?? new Error("Unknown error");
    let exception = Exceptionless.createException(error);
    let organizationId = null;

    if (location && location.pathname) {
      var orgMatch = location.pathname.match(/\/(\d+)/);
      if (orgMatch && orgMatch[1]) {
        organizationId = parseInt(orgMatch[1]);
        exception.setProperty("organizationId", organizationId);
        exception.setProperty("test", "test2");
      }
    }

    // if (user?.email) {
    //   const userData = {
    //     identity: user.email,
    //     name: `${user.firstName} ${user.lastName}`,
    //     data: {
    //       id: user.id,
    //     },
    //   };
    //   if (organizationId) {
    //     userData.data.organizationId = organizationId;
    //   }

    //   exception = exception.setUserIdentity(userData);
    // }

    exception.setProperty("errorMessage", errorObject?.message);

    (async () => {
      await Exceptionless.submitException(exception);
    })();
  });

  let randomContentNumber = Math.floor(Math.random() * content.length);
  return (
    <div className={styles.pageWrapper}>
      <HeaderTitle title={logo} rightContent={userComponent} />
      <ErrorPageComponent
        title={content[randomContentNumber].title}
        subtitle1={content[randomContentNumber].subtitle1}
        subtitle2={content[randomContentNumber].subtitle2}
        subtitle3={content[randomContentNumber].subtitle3}
        image={content[randomContentNumber].image}
        error={props.error}
        onDashboardBackButtonClick={goToDashboard}
        onReloadButtonClick={goBack}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ErrorPage);
