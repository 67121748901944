import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { RadioGroup } from "@mui/material";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import { toast } from "react-toastify";

//Redux
import {
  changeSupportCenterEmailNotificationsSuccess,
  changeSupportCenterEmailNotificationsFailed,
} from "../../../store/ComplianceSupportCenter/actions/supportCenterQA";

//Helpers
import api, { API } from "../../../helper/api/api";
import { errorResponseToList } from "../../../helper/errorHelper";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { checkIfIsChecked } from "../../../helper/checkIfIsChecked";

//Images & Icons
import InformationFillIcon from "remixicon-react/InformationFillIcon";

//Style
import styles from "./UnsubscribeSettings.module.scss";

//Components
import FormikInput from "../../../components/UI/Formik/FormikInput";
import Permission from "../../../components/auth/Permission";
import Button from "../../../components/UI/Button/Button";
import FormikRadio from "../../../components/UI/Formik/FormikRadio";

const UnsubscribeEmailNotifications = (props) => {
  return (
    <Formik
      initialValues={{
        unsubscribeNotificationType: props.unsubscribeNotificationType,
        unsubscribeEmail: props.unsubscribeEmail,
        unsubscribePassword: props.unsubscribePassword,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        unsubscribeNotificationType: Yup.number(),
        unsubscribeEmail: Yup.string().email("Invalid email").required("Required").nullable(),
        unsubscribePassword: Yup.string().required("Required").nullable(),
      }).required("Required")}
      onSubmit={(values, actions) => {
        api(API.complianceSupportCenter.updateUpdateSettingsEmailNotification, values)
          .then((response) => {
            props.changeSupportCenterEmailNotificationsSuccess(response.data);
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              props.changeSupportCenterEmailNotificationsFailed(error);
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, handleChange, values } = formikProps;

        return (
          <>
            <span className={"f14 font500 secondary_text_color"}>Should we send you notifications of everyone that unsubscribes?</span>
            <Form>
              <div>
                <RadioGroup name="unsubscribeNotificationType" onChange={handleChange} value={values.unsubscribeNotificationType}>
                  <div className={`${styles.radioButtonWrapper} flex`}>
                    <FormikRadio
                      name="unsubscribeNotificationType"
                      labelText="None"
                      onClick={() => setFieldValue("unsubscribeNotificationType", 0)}
                      checked={checkIfIsChecked(values.unsubscribeNotificationType, 0)}
                    />
                    <OverlayTrigger
                      key={"subject-tooltip-none"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`tooltip-subject-none`}>
                          <Popover.Body>No emails will be sent to you.</Popover.Body>
                        </Popover>
                      }
                    >
                      <span className={styles.subjectTooltip}>
                        <InformationFillIcon className="iconGray" size={18} />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className={`${styles.radioButtonWrapper} flex`}>
                    <FormikRadio
                      name="unsubscribeNotificationType"
                      labelText="Plain text emails"
                      onClick={() => setFieldValue("unsubscribeNotificationType", 1)}
                      checked={checkIfIsChecked(values.unsubscribeNotificationType, 1)}
                    />
                    <OverlayTrigger
                      key={"subject-tooltip-plain"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`tooltip-subject-plain`}>
                          <Popover.Body>We will send you email notifications with the data presented as plain text.</Popover.Body>
                        </Popover>
                      }
                    >
                      <span className={styles.subjectTooltip}>
                        <InformationFillIcon className="iconGray" size={18} />
                      </span>
                    </OverlayTrigger>
                  </div>

                  <div className={`${styles.radioButtonWrapper} flex`}>
                    <FormikRadio
                      name="unsubscribeNotificationType"
                      labelText="Attachment emails"
                      onClick={() => setFieldValue("unsubscribeNotificationType", 2)}
                      checked={checkIfIsChecked(values.unsubscribeNotificationType, 2)}
                    />
                    <OverlayTrigger
                      key={"subject-tooltip-type"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`tooltip-subject-type`}>
                          <Popover.Body>
                            We will send you email notifications with password protected documents containing the data.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span className={styles.subjectTooltip}>
                        <InformationFillIcon className="iconGray" size={18} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </RadioGroup>
              </div>
              <div className={styles.unsubscribeEmailNotificationsInput}>
                <div className={`${styles.horizontalFormInput} flex`}>
                  <div className="mR10">
                    <div className="width_400">
                      <label className="f14 font600 secondary_text_color mT10 mB5">Email: </label>
                      <FormikInput
                        name="unsubscribeEmail"
                        type="email"
                        value={_.isEmpty(values.unsubscribeEmail) ? "" : values.unsubscribeEmail}
                        onChange={handleChange}
                        placeholder="Your email"
                        errorinputclass={styles.errorMessage}
                      />
                    </div>
                  </div>
                  <div className="width_400">
                    <label className="f14 font600 secondary_text_color mT10 mB5">Attachment password: </label>
                    <FormikInput
                      name="unsubscribePassword"
                      type="password"
                      value={_.isEmpty(values.unsubscribePassword) ? "" : values.unsubscribePassword}
                      onChange={handleChange}
                      placeholder="Your password"
                      errorinputclass={styles.errorMessage}
                    />
                  </div>
                </div>
                <Permission has={PERMISSIONS.compliance.unsubscribeSettings.edit}>
                  <Button id="saveChanges" disabled={isSubmitting} type="submit" onClick={handleSubmit} variant="primary">
                    Save changes
                  </Button>
                </Permission>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSupportCenterEmailNotificationsSuccess: (emailNotificationsModel) =>
      dispatch(changeSupportCenterEmailNotificationsSuccess(emailNotificationsModel)),
    changeSupportCenterEmailNotificationsFailed: (error) => dispatch(changeSupportCenterEmailNotificationsFailed(error)),
  };
};

export default connect(null, mapDispatchToProps)(UnsubscribeEmailNotifications);
