import React from "react";
import { NavLink } from "react-router-dom";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Styles
import styles from "./GetStarted.module.scss";

//Components
import Button from "../../../../components/UI/Button/Button";
import Permission from "../../../../components/auth/Permission";
import ProductTourLinks from "../../ProductTourLinks/ProductTourLinks";

interface GetStartedProps {
  title: string;
  subTitle?: string;
  showVideoButton?: boolean;

  id?: string;
  text?: string;
  displayVideo?: () => void;
  showVideoModal?: () => void;

  url?: string;
  onClick?: () => void;
}

const GetStarted: React.FC<GetStartedProps> = ({
  title,
  subTitle = "",
  url = "",

  id = "",
  text = "",
  // displayVideo,
  // showVideoModal,
  // showVideoButton,
  onClick,
}) => {
  const handleModal = () => {
    if (onClick) {
      onClick();
    }
  };

  const changeUrl = async () => {
    // if (props.originalPage) {
    //   await props.clearAudience();
    // }
  };

  const titleId = title.replace(/\s/g, "");

  return (
    <div className="mB10">
      <div className={styles.cardWrapper}>
        <div className={styles.cardHeader} id={titleId}>
          <div className={styles.getStartedWrapper}>
            <div className={styles.getStartedHeader}>
              <div className={styles.getStartedAccordionTitle}>
                <p className={styles.getStartedHeadTitle}>{title}</p>
                <p className={styles.getStartedDescription}>{subTitle}</p>
              </div>
            </div>
            <Permission has={PERMISSIONS.campaign.create}>
              <div className={`${styles.getStardButtonWrapper} flex`}>
                <ProductTourLinks
                  linksWrapperClass="mR5"
                  labelTour={text}
                  // setDisplayVideo={displayVideo}
                  // setShowVideoModal={showVideoModal}
                  // showVideoButton={showVideoButton}
                  id={id}
                  tourButton
                />

                {onClick ? (
                  <Button id={"configure" + titleId} onClick={handleModal} variant="primary" ariaLabel="configure">
                    Configure
                  </Button>
                ) : (
                  <NavLink className={styles.aHrefLink} to={url}>
                    <Button id={"configure" + titleId} variant="primary" ariaLabel="configure" onClick={changeUrl}>
                      Configure
                    </Button>
                  </NavLink>
                )}
              </div>
            </Permission>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
