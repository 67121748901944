import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import Moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Redux
import {
  fetchAudienceContacts,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
  updateRows,
} from "../../store/AudienceContacts/Table/audienceContacts";
import {
  audienceDetailsUpdate,
  audienceStatsUpdate,
  fetchAudienceDetails,
  fetchAudiences,
} from "../../store/Audiences/Global/actions/audiences";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { permissionHelper } from "../../helper/permissionHelper";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { filterData, filtersToQueryString, getFilterValue } from "../../helper/tableConstants";
import { useQueryTableFilters } from "../../helper/hooks/useQueryTableFilters";
import { fileDownload } from "../../helper/fileDownloadHelper";
import { audienceContactStatusEnum } from "../../helper/enums/audienceContactStatusEnum";
import { audienceTypeEnum } from "../../helper/enums/audienceTypeEnum";
import { useOrgNumberFormatting } from "../../helper/hooks/useOrgNumberFormatting";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";
import { SIGNALR_CONSTANTS } from "../../helper/signalrConstants";
import { isMoreThanASecondAgo } from "../../helper/timeHelper";

//Styles
import styles from "./AudienceContacts.module.scss";

//Images & Icons
import EmptyAudiencesList from "../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";
import IconIncludeContact from "../../assets/Icons/IconIncludeContact/IconIncludeContact";
import IconExcludeContact from "../../assets/Icons/IconExcludeContact/IconExcludeContact";

//Components
import { columns as defaultColumns } from "./columns";
import { audExplorerColumns } from "./audExplorerColumns";
import ContactOrCompanyDetailsForm from "./ContactOrCompanyDetailsForm";
import AudExplorerCompanyDetailsForm from "./AudExplorerCompanyDetailsForm";
import ContactDetailsForm from "./ContactDetailsForm";
import TopBannerAudienceContacts from "./TopBannerAudienceContacts";
import TableOptionsRow from "../../components/DataTable/TableOptionsRow/TableOptionsRow";
import AudiencePushToCampaignForm from "../Audiences/AudiencePushToCampaignForm";
import AudienceArchiveRestoreForm from "../Audiences/AudienceArchiveRestoreForm";
import AudienceUploadForm from "../Audiences/AudienceUploadForm";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Table from "../../components/DataTable/Table";
import Tabs from "../../components/UI/Tabs/Tabs";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import ImportStartedModal from "./ImportStartedModal";
import Spinner from "../../components/UI/Spinner/Spinner";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import {
  useGetAudienceContactsCompanyIndustriesQuery,
  useGetAudienceContactsCompanySizesQuery,
  useGetAudienceContactsCountriesQuery,
} from "@api/audienceApi";

const AudienceContacts = (props) => {
  const {
    param,
    rows,
    fetchAudienceContacts,
    isInitiated,
    clearInitiated,
    initiate,
    fetchAudienceDetails,
    fetchAudiences,
    updateRows,
    audienceDetailsUpdate,
    audienceStatsUpdate,

    signal,
  } = props;

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("CampaignId");
  const importRemaining = params.get("ImportRemaining");

  let table = null;
  let image = <EmptyAudiencesList />;
  let topBanner = null;
  let filterTypes = ["Industry", "Company Size", "Contact Country"];
  let state = useQueryTableFilters(filterTypes);
  let dateFormat = useOrgDateFormat();
  const [tableRows, setTableRows] = useState([]);
  const { audienceId } = useParams();

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const audienceType = pathParts[pathParts.length - 1];

  const isSearchBasedAudience = parseInt(audienceType) === audienceTypeEnum.Search;
  const [getAwaitingProcessing, setGetAwaitingProcessing] = useState(isSearchBasedAudience);
  const [columns, setColumns] = useState(parseInt(audienceType) === audienceTypeEnum.Search ? audExplorerColumns : defaultColumns);
  const [customColumnsSet, setCustomColumnsSet] = useState(false);
  const [allDataTableColumns, setAllDataTableColumns] = useState(
    _.cloneDeep(parseInt(audienceType) === audienceTypeEnum.Search ? audExplorerColumns : defaultColumns)
  );
  const [dataTableColumns, setDataTableColumns] = useState(
    _.cloneDeep(parseInt(audienceType) === audienceTypeEnum.Search ? audExplorerColumns : defaultColumns)
  );
  const [audienceExplorerError, setAudienceExplorerError] = useState(false);

  const [prospectsImportStartedModel, setProspectsImportStartedModel] = useState(null);
  const [showImportStartedModal, setShowImportStartedModal] = useState(false);

  const handleCloseImportStartedModal = () => {
    setShowImportStartedModal(false);
  };

  const handleIncludedExcluded = (included, data, audienceFromAudExplorer) => {
    let apiEndpoint = API.audience.setDontImportFlag;

    data.dontImportFlagValue = !included;
    api(apiEndpoint, data)
      .then((res) => {
        if (res.data != null) {
          audienceStatsUpdate(res.data);
        }
        fetchAudienceContacts(param, getAwaitingProcessing);
        if (included) toast.info("Contact can be imported in campaign");
        else toast.info("Contact won't be imported in campaign");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  // useEffect(() => {
  //   if (campaignId > 0 && importRemaining > 0) {
  //     setAudienceExplorerPushToCampaignFormModal(true);
  //   }
  // }, [campaignId, importRemaining, audienceId]);

  useEffect(() => {
    if (signal == null || signal.name == null) return;

    // If this is an object from an old state ignore it.
    if (isMoreThanASecondAgo(signal.time)) return;

    // Check if correct audience
    if (audienceId === "" || signal.data.audienceId !== parseInt(audienceId)) return;

    switch (signal.name) {
      case SIGNALR_CONSTANTS.IMPORT_PROSPECTS_STARTED:
        setShowImportStartedModal(true);
        setProspectsImportStartedModel(signal.data);
        break;
      case SIGNALR_CONSTANTS.IMPORT_PROSPECTS_UPDATE:
        updateRows(signal.data.contacts);
        audienceDetailsUpdate(signal.data);
        break;
      case SIGNALR_CONSTANTS.IMPORT_PROSPECTS_FINISHED:
        toast.success(toastMessages.audience.setAudienceImportSettings.importFinished);
        fetchAudienceContacts(param, getAwaitingProcessing);
        fetchAudiences(param);
        break;
      default:
        break;
    }
  }, [signal]);

  useEffect(() => {
    fetchAudienceDetails(audienceId);
  }, []);

  const resetColumns = (getAwaitingProcessing = false) => {
    var defaultColumnsTemp = columns;

    if (getAwaitingProcessing) {
      setColumns(_.cloneDeep(audExplorerColumns));
      defaultColumnsTemp = audExplorerColumns;
    } else {
      setColumns(_.cloneDeep(defaultColumns));
    }

    let dataTableColumnsTemp = _.cloneDeep([]);
    let allDataTableColumnsTemp = _.cloneDeep([]);
    let dataTableColumnsTemp1 = defaultColumnsTemp;
    let allDataTableColumnsTemp1 = defaultColumnsTemp;

    defaultColumnsTemp.map((el) => {
      dataTableColumnsTemp1.map((col) => {
        if (col.label === el.label) {
          if (col.isHidden === undefined || col.isHidden === false) dataTableColumnsTemp.push(el);
        }
      });
    });

    defaultColumnsTemp.map((el) => {
      allDataTableColumnsTemp1.map((col) => {
        if (col.label === el.label) {
          allDataTableColumnsTemp.push(el);
        }
      });
    });

    setAllDataTableColumns(_.cloneDeep(allDataTableColumnsTemp));
    setDataTableColumns(_.cloneDeep(dataTableColumnsTemp));
  };

  useEffect(() => {
    if (param.filter.length === 0 || param.filter.length === 1) {
      let updateState = {
        params: {
          filter: [{ type: "List", value: [audienceId] }],
          start: 0,
          page: 0,
          length: 10,
          sort: {},
          search: "",
        },
      };
      initiate(updateState);
    } else {
      initiate(state);
    }
  }, [audienceId]);

  useEffect(() => {
    return () => {
      clearInitiated();
      setAllDataTableColumns(_.cloneDeep([]));
      setDataTableColumns(_.cloneDeep([]));
    };
  }, [audienceId]);

  useEffect(() => {
    if (customColumnsSet === false) {
      resetColumns();
    }
  }, [audienceId]);

  const { data: industries } = useGetAudienceContactsCompanyIndustriesQuery(audienceId, {
    skip: !audienceId,
    refetchOnMountOrArgChange: true,
  });

  const { data: companySizes } = useGetAudienceContactsCompanySizesQuery(audienceId, {
    skip: !audienceId,
    refetchOnMountOrArgChange: true,
  });

  const { data: countries } = useGetAudienceContactsCountriesQuery(audienceId, {
    skip: !audienceId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    fetchAudiences();
    // initiate(state);
  }, [audienceId]);

  useEffect(() => {
    if (isInitiated) {
      const querystring = props.location.search;
      let newQuerystring = filtersToQueryString(param);
      if (querystring !== newQuerystring) {
        const params = new URLSearchParams(search);
        const campaignIdParam = params.get("CampaignId");
        const importRemainingParam = params.get("ImportRemaining");
        if (campaignIdParam != null && importRemainingParam != null) {
          newQuerystring += "&CampaignId=" + campaignIdParam + "&ImportRemaining=" + importRemainingParam;
        }
        props.history.replace(props.location.pathname + newQuerystring);
      }

      fetchAudienceContacts(param, getAwaitingProcessing);
    }
  }, [param, isInitiated, getAwaitingProcessing]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    let dataTableColumnsTemp = _.cloneDeep(dataTableColumns);
    let allDataTableColumnsTemp = _.cloneDeep(allDataTableColumns);

    if ((rowsTemp === null || rowsTemp === undefined) && getAwaitingProcessing) {
      setAudienceExplorerError(true);
    }
    if (rowsTemp !== null && rowsTemp !== undefined && rowsTemp.length === 0 && getAwaitingProcessing === false) {
      setCustomColumnsSet(true);
      resetColumns();
    }
    if (rowsTemp !== null && rowsTemp !== undefined && rowsTemp.length > 0) {
      resetColumns();
      rowsTemp.map((r) => {
        let statusClass = "";
        let statusText = "";

        let data = {
          id: r.id,
          externalId: r.externalId,
          company: r.cleanCompanyName ? r.cleanCompanyName : r.companyName,
          emailAddress: r.emailAddress,
          firstName: r.firstName,
          lastName: r.lastName,
          companyIndustry: r.companyIndustry,
          companyWebsite: r.companyWebsite,
          companySize: r.companySize,
          location: r.location,
          companyId: r.companyId,
          status: r.status,
          experienceId: r.experienceId,
          audienceId: parseInt(audienceId),
        };

        switch (r.status) {
          case audienceContactStatusEnum.Ready:
            statusClass = `${styles.statusImport} ${
              parseInt(audienceType) === audienceTypeEnum.File ? styles.statusAwaitingAssignment : styles.statusAddedToCampaign
            }`;
            statusText = parseInt(audienceType) === audienceTypeEnum.File ? "Awaiting Assignment" : "Available";
            break;
          case audienceContactStatusEnum.AddedToCampaign:
            statusClass = `${styles.statusImport} ${styles.statusAddedToCampaign}`;
            statusText = parseInt(audienceType) === audienceTypeEnum.File ? "Added To Campaign" : "Purchased";
            break;
          case audienceContactStatusEnum.Duplicate:
            statusClass = `${styles.statusImport} ${styles.statusDuplicate}`;
            statusText = "Duplicate";
            break;
          case audienceContactStatusEnum.Deleted:
            statusClass = `${styles.statusImport} ${styles.statusDuplicate}`;
            statusText = "Deleted";
            break;
          case audienceContactStatusEnum.DontImport:
            statusClass = `${styles.statusImport} ${styles.statusDoNotImport}`;
            statusText = "Don't import";
            break;
          case audienceContactStatusEnum.NotFound:
            statusClass = `${styles.statusImport} ${styles.statusNotFound}`;
            statusText = "Not found";
            break;
          case audienceContactStatusEnum.Excluded:
            statusClass = `${styles.statusImport} ${styles.statusExcluded}`;
            statusText = "Excluded";
            break;
          default:
            break;
        }

        r.status = (
          <OverlayTrigger
            key={"exclude"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-exclude`}>
                <Popover.Body>
                  {data.status === audienceContactStatusEnum.Deleted ? "The contact is deleted " : "Status: " + statusText}{" "}
                </Popover.Body>
              </Popover>
            }
          >
            <div className={statusClass}>{statusText}</div>
          </OverlayTrigger>
        );

        let checkbox = null;
        if ((r.prospectId === null || r.prospectId === 0) && !r.isUsed) {
          if (r.dontImport) {
            if (data.status === audienceContactStatusEnum.DontImport) {
              checkbox = (
                <div className={styles.includeContact} onClick={() => handleIncludedExcluded(true, data, props.audienceFromAudExplorer)}>
                  <OverlayTrigger
                    key={"include"}
                    placement={"bottom"}
                    overlay={
                      <Popover id={`tooltip-include`}>
                        <Popover.Body>Include this contact back in the audience</Popover.Body>
                      </Popover>
                    }
                  >
                    <div>
                      <IconIncludeContact />
                    </div>
                  </OverlayTrigger>
                </div>
              );
            } else if (data.status === audienceContactStatusEnum.Deleted) {
              checkbox = null;
            }

            r.fullName = <div className={styles.excluded}>{r.fullName}</div>;
            r.jobTitle = <div className={styles.excluded}>{r.jobTitle}</div>;
            r.company = <div className={styles.excluded}>{r.company}</div>;
            r.cleanCompanyName = <div className={styles.excluded}>{r.cleanCompanyName}</div>;
            r.companyWebsite = <div className={styles.excluded}>{r.companyWebsite}</div>;
            r.companyIndustry = <div className={styles.excluded}>{r.companyIndustry}</div>;
            r.cleanCompanyIndustry = <div className={styles.excluded}>{r.cleanCompanyIndustry}</div>;
            r.cleanCompanySize = <div className={styles.excluded}>{r.cleanCompanySize}</div>;
            r.companySize = <div className={styles.excluded}>{r.companySize}</div>;
            r.contactCountry = <div className={styles.excluded}>{r.contactCountry}</div>;
            r.emailAddress = <div className={styles.excluded}>{r.emailAddress}</div>;
            r.createdDate = <div className={`${styles.excluded} float-right`}>{Moment(r.createdDate).format(dateFormat)}</div>;
          } else {
            checkbox = (
              <div className={styles.excludeContact} onClick={() => handleIncludedExcluded(false, data, props.audienceFromAudExplorer)}>
                <OverlayTrigger
                  key={"exclude"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`tooltip-exclude`}>
                      <Popover.Body>Exclude this contact from the audience</Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <IconExcludeContact />
                  </div>
                </OverlayTrigger>
              </div>
            );
          }
        }

        if (r.prospectId !== null && r.prospectId > 0) {
          r.fullName = (
            <Link
              className="tableLink"
              to={{
                pathname: "/" + props.orgId + "/prospect-activity-overview/" + r.prospectId,
              }}
            >
              {r.fullName}
            </Link>
          );

          r.emailAddress = (
            <Link
              className="tableLink"
              to={{
                pathname: "/" + props.orgId + "/prospect-activity-overview/" + r.prospectId,
              }}
            >
              {r.emailAddress}
            </Link>
          );
        } else {
          if (getAwaitingProcessing) {
            r.fullName = (
              <span
                className={r.dontImport ? styles.excluded : styles.columnLink}
                onClick={() => handleAudExplorerProspectDetailsFormShow(r.externalId)}
              >
                {r.firstName + " " + r.lastName}
              </span>
            );
            r.emailAddress = (
              <span className="tableLink" onClick={() => handleAudExplorerProspectDetailsFormShow(r.externalId)}>
                {r.emailAddress}
              </span>
            );
          } else {
            r.fullName = (
              <span className="tableLink" onClick={() => handleContactOrCompanyDetailsFormShow(r.id)}>
                {r.fullName}
              </span>
            );
            r.emailAddress = (
              <span className="tableLink" onClick={() => handleContactOrCompanyDetailsFormShow(r.id)}>
                {r.emailAddress}
              </span>
            );
          }
        }

        if (r.companyId !== null && r.companyId > 0 && getAwaitingProcessing === false) {
          r.cleanCompanyName = (
            <Link
              className="tableLink"
              to={{
                pathname: "/" + props.orgId + "/company-activity-overview/" + r.companyId,
              }}
            >
              {r.cleanCompanyName}
            </Link>
          );
        } else {
          if (getAwaitingProcessing) {
            r.cleanCompanyName = (
              <span className="tableLink" onClick={() => handleAudExplorerCompanyDetailsFormShow(r.companyId)}>
                {r.cleanCompanyName}
              </span>
            );
          } else {
            r.cleanCompanyName = (
              <span className="tableLink" onClick={() => handleContactOrCompanyDetailsFormShow(r.id, true)}>
                {r.cleanCompanyName}
              </span>
            );
          }
        }

        Object.entries(r.customFields).map(([key, value]) => {
          var newColumn = {
            label: key,
            field: key,
            type: "text",
            sort: "disabled",
            minimal: "lg",
            width: "90",
            columnClass: "breakWord",
          };

          if (customColumnsSet === false) {
            if (dataTableColumnsTemp.some((item) => item.label === key) === false) {
              dataTableColumnsTemp.push(newColumn);
            }

            if (allDataTableColumnsTemp.some((item) => item.label === key) === false) {
              allDataTableColumnsTemp.push(newColumn);
            }
          }

          if (r[key] === undefined) {
            r[key] = value;
          }
        });

        r.createdDate = Moment(r.createdDate).format(dateFormat);
        setAllDataTableColumns(_.cloneDeep(allDataTableColumnsTemp));
        setDataTableColumns(_.cloneDeep(dataTableColumnsTemp));

        if (r.audienceId.toString() === audienceId || getAwaitingProcessing) {
          setCustomColumnsSet(true);
        } else {
          resetColumns();
        }
        r.checkbox = checkbox;
        return r;
      });

      setAudienceExplorerError(false);
    }

    setTableRows(_.cloneDeep(rowsTemp));
  }, [rows, audienceId]);

  const [audience, setAudience] = useState("");
  const [showAudiencePushToCampaignFormModal, setAudiencePushToCampaignFormModal] = useState(false);
  const handleAudiencePushToCampaignFormClose = () => {
    setAudiencePushToCampaignFormModal(false);
    setAudience("");
  };
  const handleAudiencePushToCampaignFormShow = (audience) => {
    setAudience(props.audienceDetails);
    setAudiencePushToCampaignFormModal(true);
  };

  const [updateImportSettingsOnly, setUpdateImportSettingsOnly] = useState(false);
  const [audienceLastPushToCampaignFinished, setAudienceLastPushToCampaignFinished] = useState(true);

  const changeAudience = async (val) => {
    fetchAudienceDetails(val);

    let data = {
      audienceId: val,
    };
    api(formUrl(API.audience.getAudienceType, data))
      .then((res) => {
        if (res.data === audienceTypeEnum.File) {
          setGetAwaitingProcessing(false);
          resetColumns(false);
        } else {
          setGetAwaitingProcessing(true);
          resetColumns(true);
        }
        setCustomColumnsSet(false);
        props.history.push("/" + props.orgId + "/list/" + val + "/list-contacts/" + res.data + "?List=" + val + "&show=25");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [chosenAudienceContact, setChosenAudienceContact] = useState(null);
  const [isChosenCompany, setIsChosenCompany] = useState(false);
  const [chosenAudExplorerCompany, setChosenAudExplorerCompany] = useState(null);
  const [chosenAudExplorerProspect, setChosenAudExplorerProspect] = useState(null);

  const [showContactOrCompanyDetailsFormModal, setShowContactOrCompanyDetailsFormModal] = useState(false);
  const handleContactOrCompanyDetailsFormClose = () => {
    setShowContactOrCompanyDetailsFormModal(false);
  };
  const handleContactOrCompanyDetailsFormShow = (id, isCompany = false) => {
    let data = {
      audienceContactid: id,
    };
    api(formUrl(API.audience.audienceContactDetails, data))
      .then((res) => {
        setChosenAudienceContact(res.data);
        setIsChosenCompany(isCompany);
        setShowContactOrCompanyDetailsFormModal(true);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [showAudExplorerCompanyDetailsFormModal, setShowAudExplorerCompanyDetailsFormModal] = useState(false);
  const handleAudExplorerCompanyDetailsFormClose = () => {
    setChosenAudExplorerCompany(null);
    setShowAudExplorerCompanyDetailsFormModal(false);
  };
  const handleAudExplorerCompanyDetailsFormShow = (companyId) => {
    setChosenAudExplorerCompany(companyId);
    setIsChosenCompany(true);
    setShowAudExplorerCompanyDetailsFormModal(true);
  };

  const [showAudExplorerProspectDetailsFormModal, setShowAudExplorerProspectDetailsFormModal] = useState(false);
  const handleAudExplorerProspectDetailsFormClose = () => {
    setChosenAudExplorerProspect(null);
    setShowAudExplorerProspectDetailsFormModal(false);
  };
  const handleAudExplorerProspectDetailsFormShow = (audienceDetailsId) => {
    setChosenAudExplorerProspect(audienceDetailsId);
    setIsChosenCompany(false);
    setShowAudExplorerProspectDetailsFormModal(true);
  };

  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const exportAudienceContacts = () => {
    let data = {
      audienceId: audienceId,
    };
    api(formUrl(API.audience.exportAudienceContacts, data, "blob"))
      .then((res) => {
        fileDownload(props.audienceDetails.name + "_contacts.csv", res.data);
        setExportButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: {
        filter: [{ type: "Audience", value: [audienceId] }],
        start: 0,
        page: 0,
        length: 10,
        sort: {},
        search: "",
      },
    };
    initiate(updateState);
  };

  const [audienceArchiveRestoreFormModal, setAudienceArchiveRestoreFormModal] = useState(false);
  const handleAudienceArchiveRestoreFormModalClose = () => setAudienceArchiveRestoreFormModal(false);

  const handleAudienceArchiveRestoreFormModalShow = () => {
    if (audience.campaignId && audience.campaignId.length === 0) {
      let data = {
        audienceId: audience.id,
      };
      api(formUrl(API.audience.archiveRestoreAudience, data))
        .then((res) => {
          toast.success(
            audience.isArchived ? toastMessages.audience.successRestoreAudience : toastMessages.audience.successArchiveAudience
          );
          fetchAudiences(param);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setAudience(audience);
      setAudienceArchiveRestoreFormModal(true);
    }
  };

  const [showAudienceUploadFormModal, setAudienceUploadFormModal] = useState(false);
  const handleAudienceUploadFormClose = () => setAudienceUploadFormModal(false);
  const handleAudienceUploadFormShow = () => setAudienceUploadFormModal(true);

  if (props.error) {
    table = <h2 className="f18 textCenter">Error getting Audience Contacts</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  let selectRowOptions = null;
  let contactOrCompanyDetailsForm = null;
  let audExplorerCompanyDetailsForm = null;
  let audExplorerProspectDetailsForm = null;
  let audienceInformations = null;
  let tableOptionsRow = null;
  let audiencePushToCampaignForm = null;
  let audienceArchiveRestoreForm = null;
  let audienceUploadForm = null;
  let tabs = null;
  let config = null;
  let audienceExplorerPushToCampaignForm = null;
  let availableContacts = parseInt(props.audienceDetails.availableContacts);
  let tableFiltersRow = null;
  let availableContactsFormatted = useOrgNumberFormatting(availableContacts);
  let processedContactsFormatted = useOrgNumberFormatting(
    props.audienceDetails.prospectsImported + props.audienceDetails.prospectsNotImported
  );

  let importStartedModal = (
    <ImportStartedModal show={showImportStartedModal} handleClose={handleCloseImportStartedModal} model={prospectsImportStartedModel} />
  );

  if (props.isAudienceLoading) {
    topBanner = <Spinner />;
  }

  if (props.isAudienceLoaded) {
    topBanner = (
      <TopBannerAudienceContacts
        audiencePushToCampaignForm={handleAudiencePushToCampaignFormShow}
        //audienceExplorerPushToCampaignForm={handleAudienceExplorerPushToCampaignFormShow}
        changeAudience={changeAudience}
        audienceDetails={props.audienceDetails}
        listAudiences={props.listAudiences}
        handleAudienceArchiveRestoreFormModalShow={handleAudienceArchiveRestoreFormModalShow}
        setUpdateImportSettingsOnly={setUpdateImportSettingsOnly}
        availableContacts={availableContacts}
        audienceCampaignId={audience.campaignId}
        setAudience={setAudience}
        setAudienceArchiveRestoreFormModal={setAudienceArchiveRestoreFormModal}
      />
    );

    selectRowOptions = [
      {
        option: industries,
        change: (o) => filterData("Industry", o, props.setFilter),
        placeholder: "Industry",
        value: getFilterValue(param.filter, "Industry"),
      },
      {
        option: companySizes,
        change: (o) => filterData("Company Size", o, props.setFilter),
        placeholder: "Company Size",
        value: getFilterValue(param.filter, "Company Size"),
      },
      {
        option: countries,
        change: (o) => filterData("Contact Country", o, props.setFilter),
        placeholder: "Contact Country",
        value: getFilterValue(param.filter, "Contact Country"),
      },
    ];

    tableFiltersRow = (
      <TableFiltersRow
        selects={selectRowOptions}
        placeholder="contacts"
        changeFilter={(text) => props.searchRecords(text)}
        rightContent={audienceInformations}
        classRightWrapper={styles.boxesWrapper}
        headerClassName={styles.filtersWrapper}
      />
    );
  }

  tableOptionsRow = (
    <TableOptionsRow
      applyChanges={applyDataTableColumnsChange}
      exportTable={
        props.audienceFromAudExplorer || !permissionHelper(props.userRole, PERMISSIONS.audience.exportAudienceContact)
          ? null
          : exportAudienceContacts
      }
      exportButtonDisabled={exportButtonDisabled}
      setExportButtonDisabled={setExportButtonDisabled}
      importData={
        props.audienceFromAudExplorer || !permissionHelper(props.userRole, PERMISSIONS.audience.importAudienceContact)
          ? null
          : props.audienceDetails.isArchived === true
          ? null
          : () => handleAudienceUploadFormShow()
      }
      dataTableColumns={dataTableColumns}
      setDataTableColumns={setDataTableColumns}
      allDataTableColumns={allDataTableColumns}
      deleteHiddenColumnsFromAllDataTableColumns={props.audienceDetails.type === audienceTypeEnum.File}
      audienceId={audienceId}
    />
  );

  if (props.isLoaded && props.isAudienceLoaded && (customColumnsSet || props.audienceFromAudExplorer)) {
    if (audienceExplorerError) {
      table = (
        <EmptyState
          image={image}
          title="No contacts found"
          description="Waiting for the system to create the audience and display the results."
        />
      );
    } else if (tableRows && tableRows.length > 0) {
      table = (
        <>
          <Table
            tableName={"Audience Contacts"}
            columns={dataTableColumns}
            rows={tableRows}
            pageCount={props.pageCount}
            totalCount={props.totalCount}
            parentCallback={props.changePage}
            parentSort={props.sortData}
            param={param}
            changeNumRecords={props.numRecords}
            parentSearchHandler={props.searchRecords}
            tableClassName={styles.audienceContactsTable}
            variant="small"
            tableHeight={!props.audienceFromAudExplorer ? 320 : 360}
            forcePageChanged={true}
          />
        </>
      );
    } else if (props.totalCount === 0) {
      table = <EmptyState image={image} title="No contacts found" />;
    } else {
      table = null;
    }

    contactOrCompanyDetailsForm = (
      <ContactOrCompanyDetailsForm
        handleClose={handleContactOrCompanyDetailsFormClose}
        showModal={showContactOrCompanyDetailsFormModal}
        audienceContact={chosenAudienceContact}
        isChosenCompany={isChosenCompany}
        organizationSettings={props.organizationSettings}
      />
    );

    audExplorerCompanyDetailsForm = (
      <AudExplorerCompanyDetailsForm
        handleClose={handleAudExplorerCompanyDetailsFormClose}
        showModal={showAudExplorerCompanyDetailsFormModal}
        companyId={chosenAudExplorerCompany}
        organizationSettings={props.organizationSettings}
      />
    );

    audExplorerProspectDetailsForm = (
      <ContactDetailsForm
        handleClose={handleAudExplorerProspectDetailsFormClose}
        showModal={showAudExplorerProspectDetailsFormModal}
        contactId={chosenAudExplorerProspect}
        organizationSettings={props.organizationSettings}
      />
    );

    audiencePushToCampaignForm = (
      <AudiencePushToCampaignForm
        handleClose={handleAudiencePushToCampaignFormClose}
        showModal={showAudiencePushToCampaignFormModal}
        listAudiences={props.listAudiences}
        audience={audience}
        getAudiences={() => applyDataTableColumnsChange()}
        fromAudienceContacts={true}
        importRemaining={importRemaining}
        campaignSettings={{ id: campaignId }}
      />
    );

    audienceArchiveRestoreForm = (
      <AudienceArchiveRestoreForm
        handleClose={handleAudienceArchiveRestoreFormModalClose}
        showModal={audienceArchiveRestoreFormModal}
        audience={props.audienceDetails}
        getAudiences={() => changeAudience(audienceId)}
      />
    );

    audienceUploadForm = (
      <AudienceUploadForm
        handleClose={handleAudienceUploadFormClose}
        showModal={showAudienceUploadFormModal}
        listAudiences={props.listAudiences}
        audience={props.audienceDetails}
        getAudiences={() => changeAudience(audienceId)}
        orgId={props.orgId}
      />
    );

    tabs = [
      {
        link: `${audienceTypeEnum.Search}`,
        component: (
          <>
            {tableOptionsRow}
            {table}
          </>
        ),
        title: "Available contacts",
        tabData: props.audienceDetails ? availableContactsFormatted : 0,
        onClick: () => {
          table = null;
          setTableRows([]);
          setGetAwaitingProcessing(true);
          resetColumns(true);
          props.changePage(0);
        },
      },
      {
        link: `${audienceTypeEnum.File}`,
        component: (
          <>
            <div className={styles.filtersWrapper}>
              {tableFiltersRow}
              {tableOptionsRow}
            </div>
            {table}
          </>
        ),
        title: "Processed",
        tabData: processedContactsFormatted,
        onClick: () => {
          table = null;
          setTableRows([]);
          setGetAwaitingProcessing(false);
          resetColumns();
          props.changePage(0);
        },
      },
    ];

    config = <Tabs tabsClassName={styles.audiencesTabs} tabContentClass={styles.audienceContactsTabs} {...props} tabs={tabs} />;
  } else {
    table = null;
  }

  useEffect(() => {
    props.changePage(0);
  }, [param.length, param.filter]);

  return (
    <PageContentWrapper className="contentHeight">
      {topBanner}
      <>
        {props.audienceFromAudExplorer ? (
          config
        ) : (
          <>
            <div className={`${styles.filtersWrapper} ${!props.audienceFromAudExplorer ? "mT15" : ""}`}>
              {tableFiltersRow}
              {tableOptionsRow}
            </div>
            {table}
          </>
        )}

        {contactOrCompanyDetailsForm}
        {audExplorerCompanyDetailsForm}
        {audExplorerProspectDetailsForm}
        {audiencePushToCampaignForm}
        {audienceArchiveRestoreForm}
        {audienceUploadForm}
        {audienceExplorerPushToCampaignForm}
        {importStartedModal}
      </>
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
    param: state.audienceContacts.params,
    isInitiated: state.audienceContacts.isInitiated,
    isLoading: state.audienceContacts.isLoading,
    isLoaded: state.audienceContacts.isLoaded,
    isAudienceLoaded: state.globalAudiences.isLoaded,
    isAudienceLoading: state.globalAudiences.isLoading,
    error: state.audienceContacts.error,
    rows: state.audienceContacts.rows,
    totalCount: state.audienceContacts.totalCount,
    pageCount: state.audienceContacts.pageCount,
    audienceDetails: state.globalAudiences.audienceDetails,
    listAudiences: state.globalAudiences.listAudiences,
    audienceFromAudExplorer: state.globalAudiences.audienceDetails.type === audienceTypeEnum.Search,
    organizationSettings: state.organizationSettingsGeneral.settings,
    userRole: state.user.organization.role,

    signal: state.signal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAudienceContacts: (param, isSearchBasedAudience) => dispatch(fetchAudienceContacts(param, isSearchBasedAudience)),
    updateRows: (rows) => dispatch(updateRows(rows)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
    fetchAudienceDetails: (audienceId) => dispatch(fetchAudienceDetails(audienceId)),
    fetchAudiences: () => dispatch(fetchAudiences()),
    audienceDetailsUpdate: (update) => dispatch(audienceDetailsUpdate(update)),
    audienceStatsUpdate: (update) => dispatch(audienceStatsUpdate(update)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceContacts);
