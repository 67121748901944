import React, { useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

//Redux
import { fetchConversationDetails } from "../../../store/Conversation/actions/conversation";

//Helpers
import { useOrgDateTimeFormat } from "../../../helper/hooks/useOrgDateTimeFormat";

// Styles
import styles from "./LeadConversationModal.module.scss";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import ConversationPreview from "../../../pages/Conversation/components/ConversationPreview/ConversationPreview";

const LeadConversationModal = (props) => {
  const { deal, fetchConversationDetails, conversationIsLoading } = props;
  let dateTimeFormat = useOrgDateTimeFormat();

  useEffect(() => {
    if (deal && deal.prospectId) {
      fetchConversationDetails(deal.prospectId);
    }
  }, [deal]);

  return (
    <Modal
      dialogClassName="customModal"
      title="Response Details"
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
      backdrop={true}
      size="lg"
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.modalBody}>
            <div className={styles.details}>
              <p className="font600">
                Company Name: <span>{deal.companyName}</span>
              </p>
              <p className="font600">
                Company Size: <span>{deal.companySize}</span>
              </p>
              <p className="font600">
                Date of Response: <span>{moment(deal.dateOfResponse).format(dateTimeFormat)}</span>
              </p>
            </div>
            <div className={styles.messageInfo}>
              <p className={styles.titleInfo}> Conversation Information:</p>
              <p>From Name: {deal.prospectName}</p>
              <p>From Email: {deal.ProspectEmail}</p>
              <p>To: {deal.emailAccountEmail}</p>
              <div>{conversationIsLoading ? <Spinner /> : <ConversationPreview />}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    conversationIsLoading: state.conversation.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConversationDetails: (id) => dispatch(fetchConversationDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadConversationModal);
