import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  listOfProspects: [],
  unresolved: 0,
  resolved: 0,
  forReviewCount: 0,
  messagePreview: {},
  activeTab: 0,
  filterForReview: false,
  isLoading: false,
  isLoaded: false,
  isLoadingProspects: false,
  isLoadedProspects: false,
  isLoadingNumberOfEmails: false,
  isLoadedNumberOfEmails: false,
  isMessageLoading: false,
  isMessageLoaded: false,
  isLoadingResolve: false,
  isLoadedResolve: false,
  error: "",
  campaignLocation: false,
};

const clearListOfProspects = (state, action) => {
  return {
    ...state,
    listOfProspects: [],
  };
};

//#region FETCH
const fetchListOfProspectsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchListOfProspectsSuccess = (state, action) => {
  const newState = { ...state };
  let list = [...state.listOfProspects];
  list = action.result.map((prospect) => {
    return {
      value: prospect.prospectId,
      label: prospect.email,
    };
  });

  newState.isLoadedProspects = true;
  newState.isLoadingProspects = false;
  newState.listOfProspects = list;
  return newState;
};

const fetchListOfProspectsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoadingProspects = false;
  newState.isLoadedProspects = true;
  return newState;
};

const fetchTotalNumberOfEmailsRequest = (state, action) => {
  return {
    ...state,
    isLoadingNumberOfEmails: true,
    isLoadedNumberOfEmails: false,
  };
};

const fetchTotalNumberOfEmailsSuccess = (state, action) => {
  const newState = { ...state };
  let totalNumberOfEmails = { ...state.totalNumberOfEmails };
  totalNumberOfEmails = action.result;
  newState.isLoadedNumberOfEmails = true;
  newState.isLoadingNumberOfEmails = false;
  newState.totalNumberOfEmails = totalNumberOfEmails;
  return newState;
};

const fetchTotalNumberOfEmailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoadingNumberOfEmails = false;
  newState.isLoadedNumberOfEmails = true;
  return newState;
};

const fetchMailDetailsRequest = (state, action) => {
  return {
    ...state,
    isMessageLoading: true,
    isMessageLoaded: false,
    messagePreview: null,
  };
};

const fetchMailDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let messagePreview = { ...state.messagePreview };
  messagePreview = action.result;
  newState.isMessageLoaded = true;
  newState.isMessageLoading = false;
  newState.messagePreview = messagePreview;
  return newState;
};

const fetchMailDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isMessageLoading = false;
  newState.isMessageLoaded = true;
  return newState;
};
//#endregion

//#region CHANGE
const changeResolveRequest = (state, action) => {
  return {
    ...state,
    isLoadingResolve: true,
    isLoadedResolve: false,
  };
};

const changeBackToUnresolveRequest = (state, action) => {
  return {
    ...state,
    isLoadingResolve: true,
    isLoadedResolve: false,
  };
};

const changeResolveSuccess = (state, action) => {
  const newState = { ...state };
  const currentMessagePreview = { ...newState.messagePreview };
  currentMessagePreview.isResolved = true;
  currentMessagePreview.type = action.result.ResponseType;
  let newReferralEmails =
    currentMessagePreview.referralEmail == null ||
      currentMessagePreview.referralEmail === "undefined"
      ? ""
      : currentMessagePreview.referralEmail.concat(
        action.result.Tags.filter(
          (element) => !currentMessagePreview.referralEmail.includes(element)
        )
      );
  currentMessagePreview.referralEmail = newReferralEmails;
  currentMessagePreview.prospectEmail = action.result.ProspectEmail;
  currentMessagePreview.SendAfterDaysOOO = action.result.SendAfterDaysOOO;
  newState.messagePreview = currentMessagePreview;
  return newState;
};

const changeBackToUnresolveSuccess = (state, action) => {
  const newState = { ...state };
  const messageToUnresolved = { ...action.result };
  messageToUnresolved.isResolved = false;
  messageToUnresolved.responseType = "";
  messageToUnresolved.referralEmail = [];
  messageToUnresolved.prospectEmail = "";
  newState.messagePreview = messageToUnresolved;
  return newState;
};

const changeResolveFailed = (state, action) => {
  return {
    ...state,
    isLoadedResolve: true,
    error: action.result,
  };
};

const changeBackToUnresolveFailed = (state, action) => {
  return {
    ...state,
    isLoadedResolve: true,
    error: action.result,
  };
};


const confirmAllAutoResolvedRequest = (state, action) => {
  return {
    ...state,
    isLoadingResolve: true,
    isLoadedResolve: false,
  };
};

const confirmAllAutoResolvedSuccess = (state, action) => {
  return {
    ...state,
    isLoadingResolve: false,
    isLoadedResolve: true,
  };
};

const confirmAllAutoResolvedFailed = (state, action) => {
  return {
    ...state,
    error: action.result,
    isLoadedResolve: true
  };
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST_OF_PROSPECTS_REQUEST:
      return fetchListOfProspectsRequest(state, action);

    case actionTypes.FETCH_LIST_OF_PROSPECTS_SUCCEEDED:
      return fetchListOfProspectsSuccess(state, action);

    case actionTypes.FETCH_LIST_OF_PROSPECTS_FAILED:
      return fetchListOfProspectsFailed(state, action);

    case actionTypes.FETCH_TOTAL_NUMBER_OF_EMAILS_REQUEST:
      return fetchTotalNumberOfEmailsRequest(state, action);

    case actionTypes.FETCH_TOTAL_NUMBER_OF_EMAILS_SUCCEEDED:
      return fetchTotalNumberOfEmailsSuccess(state, action);

    case actionTypes.FETCH_TOTAL_NUMBER_OF_EMAILS_FAILED:
      return fetchTotalNumberOfEmailsFailed(state, action);

    case actionTypes.FETCH_MESSAGE_PREVIEW_REQUEST:
      return fetchMailDetailsRequest(state, action);

    case actionTypes.FETCH_MESSAGE_PREVIEW_SUCCEEDED:
      return fetchMailDetailsSuccess(state, action);

    case actionTypes.FETCH_MESSAGE_PREVIEW_FAILED:
      return fetchMailDetailsFailed(state, action);

    case actionTypes.CHANGE_RESOLVE_REQUEST:
      return changeResolveRequest(state, action);

    case actionTypes.CHANGE_RESOLVE_SUCCEEDED:
      return changeResolveSuccess(state, action);

    case actionTypes.CHANGE_RESOLVE_FAILED:
      return changeResolveFailed(state, action);

    case actionTypes.CHANGE_BACK_TO_UNRESOLVED_REQUEST:
      return changeBackToUnresolveRequest(state, action);

    case actionTypes.CHANGE_BACK_TO_UNRESOLVED_SUCCEEDED:
      return changeBackToUnresolveSuccess(state, action);

    case actionTypes.CHANGE_BACK_TO_UNRESOLVED_FAILED:
      return changeBackToUnresolveFailed(state, action);

    case actionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.result,
      };
    case actionTypes.SET_FILTER_FOR_REVIEW:
      return {
        ...state,
        filterForReview: action.result,

      };
    case actionTypes.SET_LOCATION:
      return {
        ...state,
        campaignLocation: action.result,
      };

    case actionTypes.CLEAR_LIST_OF_PROSPECTS:
      return clearListOfProspects(state, action);

    case actionTypes.CONFIRM_ALL_AUTO_RESOLVED_REQUEST:
      return confirmAllAutoResolvedRequest(state, action);

    case actionTypes.CONFIRM_ALL_AUTO_RESOLVED_SUCCEEDED:
      return confirmAllAutoResolvedSuccess(state, action);

    case actionTypes.CONFIRM_ALL_AUTO_RESOLVED_FAILED:
      return confirmAllAutoResolvedFailed(state, action);

    default:
      break;
  }

  return state;
};

export default reducer;
