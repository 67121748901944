import React, { useState } from "react";
import { connect } from "react-redux";

//Helpers
import { activeApplicationEnum } from "../../../helper/enums/activeApplicationEnum";
import { permissionHelper } from "../../../helper/permissionHelper";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";

//Styles
import styles from "./SideMenuContent.module.scss";

//Images & Icons
import Dashboard from "../../../assets/Icons/SidebarIcons/Dashboard/Dashboard";
import DashboardActive from "../../../assets/Icons/SidebarIcons/Dashboard/DashboardActive";
import Inbox from "../../../assets/Icons/SidebarIcons/Inbox/Inbox";
import InboxActive from "../../../assets/Icons/SidebarIcons/Inbox/InboxActive";
import Campaigns from "../../../assets/Icons/SidebarIcons/Campaigns/Campaigns";
import CampaignsActive from "../../../assets/Icons/SidebarIcons/Campaigns/CampaignsActive";
import DataTargeting from "../../../assets/Icons/SidebarIcons/DataTargeting/DataTargeting";
import DataTargetingActive from "../../../assets/Icons/SidebarIcons/DataTargeting/DataTargetingActive";
import MessageTemplates from "../../../assets/Icons/SidebarIcons/MessageTemplates/MessageTemplates";
import MessageTemplatesActive from "../../../assets/Icons/SidebarIcons/MessageTemplates/MessageTemplatesActive";
import EmailAccounts from "../../../assets/Icons/SidebarIcons/EmailAccounts/EmailAccounts";
import EmailAccountsActive from "../../../assets/Icons/SidebarIcons/EmailAccounts/EmailAccountsActive";
import AllProspects from "../../../assets/Icons/SidebarIcons/AllProspects/AllProspects";
import AllProspectsActive from "../../../assets/Icons/SidebarIcons/AllProspects/AllProspectsActive";
import Reports from "../../../assets/Icons/SidebarIcons/Reports/Reports";
import ReportsActive from "../../../assets/Icons/SidebarIcons/Reports/ReportsActive";
import Sales from "../../../assets/Icons/SidebarIcons/Sales/Sales";
import SalesActive from "../../../assets/Icons/SidebarIcons/Sales/SalesActive";
import DealNavigator from "../../../assets/Icons/SidebarIcons/DealNavigator/DealNavigator";
import DealNavigatorActive from "../../../assets/Icons/SidebarIcons/DealNavigator/DealNavigatorActive";
import Compliance from "../../../assets/Icons/SidebarIcons/Compliance/Compliance";
import ComplianceActive from "../../../assets/Icons/SidebarIcons/Compliance/ComplianceActive";
import CRM from "../../../assets/Icons/SidebarIcons/CRM/CRM";
import CRMActive from "../../../assets/Icons/SidebarIcons/CRM/CRMActive";
import OrganizationIcon from "../../../assets/Icons/SidebarIcons/OrganizationIcon/OrganizationIcon";
import OrgActiveOutbOssIcon from "../../../assets/Icons/SidebarIcons/OutbOss/OrgActiveOutbOssIcon/OrganizationActiveIcon";
import UserActiveOutbOssIcon from "../../../assets/Icons/SidebarIcons/OutbOss/UserActiveOutbOssIcon/UserActiveOutbOssIcon";
import ActivityLogsIcon from "../../../assets/Icons/SidebarIcons/ActivityLogsIcon/ActivityLogsIcon";
import ActivityLogsIconActive from "../../../assets/Icons/SidebarIcons/ActivityLogsIcon/ActivityLogsIconActive";
import ActivityLogsActiveIcon from "../../../assets/Icons/SidebarIcons/ActivityLogsIcon/ActivityLogsActiveIcon";

//Components
import MenuItem from "./MenuItem";
import MenuSubItem from "./MenuSubItem";

const SideMenuContent = (props) => {
  const { organizationId, userRole } = props;
  const [openedMenu, setOpenMenu] = useState("");

  const isMenuOpened = (menuLink) => {
    const links = menuLink.split(";");

    if (links.includes(props.props.match.url)) return true;
    else return false;
  };

  const clicked = (e) => {
    if (
      e.currentTarget.classList.contains(styles.menuDropdownOpened) &&
      ((openedMenu !== e.currentTarget && openedMenu !== "") ||
        (openedMenu === e.currentTarget && e.currentTarget.classList.contains(styles.dropLiActive)))
    ) {
      e.currentTarget.classList.remove(styles.menuDropdownOpened);
      setOpenMenu("");
    } else {
      if (
        openedMenu !== "" &&
        openedMenu.classList.contains(styles.dropLiActive) === false &&
        openedMenu.classList.contains(styles.menuDropdownOpened)
      ) {
        openedMenu.classList.remove(styles.menuDropdownOpened);
        if (
          e.currentTarget.classList.contains(styles.dropLiActive) === false &&
          e.currentTarget.classList.contains(styles.menuDropdownOpened) === false &&
          openedMenu !== e.currentTarget
        ) {
          e.currentTarget.classList.add(styles.menuDropdownOpened);
          setOpenMenu(e.currentTarget);
        }
      } else {
        e.currentTarget.classList.add(styles.menuDropdownOpened);
        setOpenMenu(e.currentTarget);
      }
    }
  };

  let menuItems = [];
  if (props.currentApplication === activeApplicationEnum.Outboss) {
    if (globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.organization.view)) {
      menuItems.push({
        title: "Organizations",
        url: `/outboss/organizations`,
        iconActive: <OrgActiveOutbOssIcon />,
        iconInactive: <OrganizationIcon />,
      });
    }
    if (globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.users.view)) {
      menuItems.push({
        title: "Users",
        url: `/outboss/users`,
        iconActive: <UserActiveOutbOssIcon />,
        iconInactive: <AllProspects />,
      });
    }
    if (globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.userActivityLog.view)) {
      menuItems.push({
        title: "UserActivityLogs",
        iconActive: <ActivityLogsActiveIcon />,
        iconInactive: <ActivityLogsIcon />,
        subitems: [
          {
            title: "User Logs",
            url: `/outboss/user-activity-logs`,
          },
        ],
      });
    }
    if (globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.emailAccounts.view)) {
      menuItems.push({
        title: "Email accounts",
        url: `/outboss/email-accounts`,
        iconActive: <EmailAccountsActive />,
        iconInactive: <EmailAccounts />,
      });
    }
  } else {
    // set standard outbase json
    menuItems = [
      {
        title: "Dashboard",
        url: `/${organizationId}/dashboard`,
        iconActive: <DashboardActive />,
        iconInactive: <Dashboard />,
      },
    ];

    if (permissionHelper(userRole, PERMISSIONS.responderInbox.view)) {
      menuItems.push({
        title: "Inbox",
        url: `/${organizationId}/inbox`,
        iconActive: <InboxActive />,
        iconInactive: <Inbox />,
      });
    }

    if (permissionHelper(userRole, PERMISSIONS.campaign.view)) {
      menuItems.push({
        title: "Campaigns",
        iconActive: <CampaignsActive />,
        iconInactive: <Campaigns />,
        subitems: [
          {
            title: "Campaign Manager",
            url: `/${organizationId}/campaigns`,
          },
          {
            title: "Calendar",
            url: `/${organizationId}/calendar`,
          },
        ],
      });
    }

    if (permissionHelper(userRole, PERMISSIONS.audience.view) || permissionHelper(userRole, PERMISSIONS.audienceExplorer.view)) {
      menuItems.push({
        title: "Lists",
        iconActive: <DataTargetingActive />,
        iconInactive: <DataTargeting />,
        subitems: [
          {
            title: "Search",
            url: `/${organizationId}/search`,
          },
          {
            title: "Your Lists",
            url: `/${organizationId}/lists`,
          },
        ],
      });
    }
    if (permissionHelper(userRole, PERMISSIONS.emailTemplates.view)) {
      menuItems.push({
        title: "Email Templates",
        url: `/${organizationId}/email-templates`,
        iconActive: <MessageTemplatesActive />,
        iconInactive: <MessageTemplates />,
      });
    }
    if (permissionHelper(userRole, PERMISSIONS.emailAccounts.view)) {
      let emailSubitems = [
        {
          title: "Email account manager",
          url: `/${organizationId}/email-accounts`,
        },
      ];
      if (permissionHelper(userRole, PERMISSIONS.domain.view)) {
        emailSubitems.push({
          title: "Domains",
          url: `/${organizationId}/domains`,
        });
      }
      menuItems.push({
        title: "Email Accounts",
        iconActive: <EmailAccountsActive />,
        iconInactive: <EmailAccounts />,
        subitems: emailSubitems,
      });
    }
    if (permissionHelper(userRole, PERMISSIONS.prospect.view)) {
      menuItems.push({
        title: "All Prospects",
        iconActive: <AllProspectsActive />,
        iconInactive: <AllProspects />,
        subitems: [
          {
            title: "Prospects",
            url: `/${organizationId}/prospects`,
          },
          {
            title: "Companies",
            url: `/${organizationId}/companies`,
          },
          {
            title: "Exclusions",
            url: `/${organizationId}/exclusions`,
          },
        ],
      });
    }

    let reportsSubItems = [];
    if (permissionHelper(userRole, PERMISSIONS.reports.view)) {
      reportsSubItems.push({
        title: "Campaign Reports",
        url: `/${organizationId}/reports`,
      });
    }

    if (permissionHelper(userRole, PERMISSIONS.sendReports.view)) {
      reportsSubItems.push({
        title: "Send reports",
        url: `/${organizationId}/send-reports`,
      });
    }
    menuItems.push({
      title: "Reports",
      url: `/${organizationId}/reports`,
      iconActive: <ReportsActive />,
      iconInactive: <Reports />,
      subitems: reportsSubItems,
    });

    const dealsView = permissionHelper(userRole, PERMISSIONS.deals.view);
    // const conversationsView = permissionHelper(userRole, PERMISSIONS.conversation.view);
    const salesDocumentsView = permissionHelper(userRole, PERMISSIONS.salesDocuments.documents.view);

    // if (dealsView || conversationsView || salesDocumentsView) {
    if (dealsView || salesDocumentsView) {
      let salesSub = [];
      if (dealsView)
        salesSub.push({
          title: "Leads",
          url: `/${organizationId}/leads`,
        });

      // if (conversationsView)
      //   salesSub.push({
      //     title: "Conversations",
      //     url: `/${organizationId}/conversations`,
      //   });

      if (salesDocumentsView)
        salesSub.push({
          title: "Sales Resources",
          url: `/${organizationId}/sales-resources`,
        });

      menuItems.push({
        title: "Sales",
        iconActive: <SalesActive />,
        iconInactive: <Sales />,
        subitems: salesSub,
      });
    }
    if (permissionHelper(userRole, PERMISSIONS.dealNavigator.view)) {
      menuItems.push({
        title: "Deal Navigator",
        url: `/${organizationId}/deal-navigator`,
        iconActive: <DealNavigatorActive />,
        iconInactive: <DealNavigator />,
      });
    }

    if (
      permissionHelper(userRole, PERMISSIONS.compliance.view) ||
      permissionHelper(userRole, PERMISSIONS.compliance.questionsAndAnswers.view)
    ) {
      menuItems.push({
        title: "Compliance",
        url: `/${organizationId}/compliance`,
        iconActive: <ComplianceActive />,
        iconInactive: <Compliance />,
      });
    }

    if (permissionHelper(userRole, PERMISSIONS.crm.view)) {
      menuItems.push({
        title: "CRM",
        url: `/${organizationId}/crm`,
        iconActive: <CRMActive />,
        iconInactive: <CRM />,
      });
    }
  }

  const components = menuItems.map((item) => {
    let children = null;
    let url = item.url;

    if (item.subitems != null) {
      const urls = item.subitems.map((subitem) => subitem.url);
      url = urls.join(";");

      children = item.subitems.map((subitem) => {
        const subItemComp = <MenuSubItem key={subitem.title} isMenuOpened={isMenuOpened} title={subitem.title} url={subitem.url} />;

        return subItemComp;
      });
    }

    const comp = (
      <MenuItem
        key={item.title}
        organizationId={organizationId}
        isMenuOpened={isMenuOpened}
        url={url}
        title={item.title}
        iconActive={item.iconActive}
        iconInactive={item.iconInactive}
        onClick={clicked}
        open={false}
      >
        {children}
      </MenuItem>
    );

    return comp;
  });

  return (
    <div>
      {/* <!------- Side-menu Expanded --------> */}
      <ul className={`${styles.sideMenuExp} sideMenuCloseExp`}>{components}</ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state?.user?.organization?.id ?? 0,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
    userRole: state?.user?.organization?.role ?? 0,
    userPermissions: state.user.userPermissions,
  };
};

export default connect(mapStateToProps)(SideMenuContent);
