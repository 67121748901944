import React from "react";

// Styles
import "./MessageIframe.scss";

const MessageIframe = (props) => {
  let htmlObject = document.createElement("html");
  let head = document.createElement("head");
  head.innerHTML = `<style>
    ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    }
    ::-webkit-scrollbar-thumb {
    background: #7e7e7e3d;
    border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(207, 207, 207);
    border-radius: 10px;
    }
    tbody,
    table,
    tr {
        width: 100% !important;
    }
    img {
        max-width: 500px
    }
    table {
        margin : 0 auto !important;
    }
    </style>`;

  let body = document.createElement("body");
  body.innerHTML = props.children;
  htmlObject.appendChild(head);
  htmlObject.appendChild(body);

  const htmlString = htmlObject.outerHTML;

  return <iframe title="message" srcDoc={htmlString}></iframe>;
};

export default MessageIframe;
