import React, { useState } from "react";

//Helpers
import { useSlice } from "@hooks/useSlice";
import { useGetScheduleQuery } from "@api/sendingScheduleApi";
import { checkFullArray } from "@helper/arrayHelper";

//Components
import EmailAccountsWeighting from "@pages/CampaignSettings/Components/EmailAccountsWeighting/EmailAccountsWeighting";
import Spinner from "@ui/Spinner/Spinner";

const EmailAccountDetails = () => {
  const campaign = useSlice((state) => state.campaign.campaign);
  const { data: schedule, isLoading, isSuccess } = useGetScheduleQuery(campaign.id, { refetchOnMountOrArgChange: true });

  let emailAccounts = null;
  if (isLoading) {
    emailAccounts = <Spinner />;
  }

  if (isSuccess && checkFullArray(campaign.emailAccounts)) {
    emailAccounts = <EmailAccountsWeighting emailAccounts={campaign.emailAccounts || []} />;
  }
  return <>{emailAccounts}</>;
};
export default EmailAccountDetails;
