export const tableColumns = [
  {
    label: "Name",
    field: "name",
    width: 150,
    showTooltip: true,
  },
  {
    label: "Email",
    field: "email",
    width: 150,
    showTooltip: true,
  },
  {
    label: "Action",
    field: "action",
    width: 150,
  },
  {
    label: "Date Of Action",
    field: "dateOfAction",
    type: "date",
    width: 150,
  },
];
