import React, { FC } from "react";
import Action from "../Action";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

interface ActionDeleteProps {
  size?: number;
  classNameIcon?: string;
  onClick?: () => void;
}

const ActionDelete: FC<ActionDeleteProps> = ({ size = 16, classNameIcon = "iconGray", ...props }) => {
  return <Action svgIcon={<DeleteBin6LineIcon className={`iconGray ${classNameIcon}`} size={size} id="delete" />} {...props} />;
};

export default ActionDelete;
