import React, { FC } from "react";

// Style
import style from "./TableHeaderSort.module.scss";

interface TableHeaderSortProps {
  name: string;
  img?: string | null;
  clickHandler: () => void;
}

const TableHeaderSort: FC<TableHeaderSortProps> = ({ name, img, clickHandler }) => {
  return (
    <div className="flex space_between width_100" onClick={clickHandler}>
      {name} {img ? <img className={`${style.sortIcon} `} src={img} alt="header_sort_img" /> : null}
    </div>
  );
};

export default TableHeaderSort;
