import React from "react";
import { Field } from "formik";
import styles from "./RadioButton.module.scss";

interface RadioButtonProps {
  selectedValue: string | number;
  labelText: string;
  value: string | number;
  name: string;
  id?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ selectedValue, labelText, value, name, id }) => {
  const isChecked = selectedValue.toString() === value.toString();

  return (
    <label id={"radioButton" + id || (name?.replace(/\./g, "") ?? "")} className={styles.radioButtonWrapper}>
      <span
        className={`
          ${isChecked ? styles.checkedBackground : ""} 
          ${styles.radioLabel}`}
      ></span>
      <Field
        data-field-type={id || (name?.replace(/\./g, "") ?? "")}
        type="radio"
        name={name}
        value={value}
        className={`${isChecked ? styles.checked : ""}`}
      />
      {labelText}
    </label>
  );
};

export default RadioButton;
