import { createSlice } from "@reduxjs/toolkit";

export enum ViewMode {
    AiWriter,
    TemplatePreview,
    CheckWording,
    Variables,
    None
  }
export interface EmailTemplateState{
    viewMode: ViewMode;
};

const initialState: EmailTemplateState = {
    viewMode: ViewMode.None
} as EmailTemplateState;

export const emailTemplateSlice = createSlice({
    name: 'emailTemplateSlice',
    initialState,
    reducers: {
        showNone: (state) => {
            state.viewMode = ViewMode.None;
        },
        showAiWriter: (state) => {
            state.viewMode = ViewMode.AiWriter;
        },
        showTemplatePreview: (state) => {
            state.viewMode = ViewMode.TemplatePreview;
        },
        showCheckWording: (state) => {
            state.viewMode = ViewMode.CheckWording;
        },
        showVariables: (state) => {
            state.viewMode = ViewMode.Variables;
        },
    },
});

export default emailTemplateSlice;