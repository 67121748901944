import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

//Helpers
import { globalPermissionHelper } from "../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../helper/globalUserRolePermissionsConstants";
import { ROLE_PERMISSIONS } from "../../helper/rolePermissions";
import { userSystemRoleEnum } from "../../helper/enums/userSystemRoleEnum";

const Permission = (props) => {
  const hasViewAllPermission = globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll);
  const hasOutbosssPermission = globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.view);

  if (hasOutbosssPermission) {
    return props.children;
  }
  // No user:
  // Redirect to landing page
  if ((props.user == null || props.user.organization == null) && !hasViewAllPermission) {
    return <Redirect to="/noPermission" />;
  }

  // If user has permission:
  // Render view
  if (props.user.organization) {
    var permissions = ROLE_PERMISSIONS[props.user.organization.role];
    //We'll allow everything for the System admins.
    if ((permissions != null && permissions.includes(props.has)) || props.user.role === userSystemRoleEnum.admin) {
      return props.children;
    }
  }

  // If no permission and redirect option is set:
  // Redirect to redirectUrl or default landing page
  if (props.redirect === true) {
    if (props.redirectUrl != null) {
      return <Redirect to={props.redirectUrl} />;
    }
    return <Redirect to="/" />;
  }

  // If no permission and no redirect:
  // Render access denied
  if (props.no != null) {
    return props.no;
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organization: state.user.organization,
    userPermissions: state.user.userPermissions,
    // claimsPermissions: state.user.organization.permissions,
    // permissions: state.global.organizationPermissions,
  };
};

export default connect(mapStateToProps)(Permission);
