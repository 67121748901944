import React from "react";

const IconExcludeContact = (props) => (
  <svg
    className={props.imgStyle}
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={props.onClick}
  >
    <path
      d="M10.6665 3.66665C10.6665 1.82498 9.17484 0.333313 7.33317 0.333313C5.4915 0.333313 3.99984 1.82498 3.99984 3.66665C3.99984 5.50831 5.4915 6.99998 7.33317 6.99998C9.17484 6.99998 10.6665 5.50831 10.6665 3.66665ZM13.1665 5.33331V6.99998H18.1665V5.33331H13.1665ZM0.666504 12V13.6666H13.9998V12C13.9998 9.78331 9.55817 8.66665 7.33317 8.66665C5.10817 8.66665 0.666504 9.78331 0.666504 12Z"
      fill="#5c656f"
    />
  </svg>
);

export default IconExcludeContact;
