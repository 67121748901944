import React from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";

// Api
import { useEditCampaignNameMutation } from "@api/campaignApi";

interface FormValues {
  id: number;
  name: string;
}

interface ChangeNameModalProps {
  id: number;
  name: string;
  handleClose: () => void;
  showModal: boolean;
}

const EditCampaignNameModal: React.FC<ChangeNameModalProps> = ({ id, name, handleClose, showModal }) => {
  const [editName] = useEditCampaignNameMutation();

  return (
    <Formik
      initialValues={{
        id: id,
        name: name,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        id: Yup.number().required("Required"),
        name: Yup.string().required("Required").max(100, "Campaign name exceeds the maximum length (100 characters)"),
      })}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        editName({ id: values.id, name: values.name })
          .unwrap()
          .then(() => {
            handleClose();
          })
          .catch((error) => {
            toast.error(error?.data);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, values } = formikProps;

        return (
          <Modal
            title={`Edit ${name} Name`}
            handleClose={handleClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form>
              <Field value={id} type="hidden" />
              <FormikInput type="text" name="name" placeholder="Give it a Name" value={values.name} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditCampaignNameModal;
