export const columns = [
  {
    label: "Date",
    field: "createdDate",
    type: "text",
    sort: "asc",
  },
  {
    label: "Reason",
    field: "reason",
    type: "text",
    sort: "asc",
    showTooltip: true,
  },
  {
    label: "User",
    field: "user",
    type: "text",
    sort: "asc",
    showTooltip: true,
  },
  {
    label: "Adjustment",
    field: "adjustment",
    type: "number",
    sort: "asc",
  },
  {
    label: "No. of Credits",
    field: "noOfCredits",
    type: "number",
    sort: "asc",
  },
];
