import React from "react";

//Styles
import styles from "./CreatedRulesList.module.scss";

//Components
import RulesCard from "./RulesCard/RulesCard";
import Button from "../../../../components/UI/Button/Button";

const CreatedRulesList = (props) => {
  const { setIsAddingRule, customRules, setEditCustomRuleId, setIsEdittingRule } = props;

  return (
    <div>
      <div className={styles.componentHeader}>
        <h3 className="m0">Integration Rules</h3>
        <div>
          <Button id="rule" variant="primary" onClick={() => setIsAddingRule(true)}>
            Add Rule
          </Button>
        </div>
      </div>
      <div className="mT20">
        {customRules.map((rule, index) => {
          return (
            <RulesCard
              eventTitle={rule.eventDescription}
              actionTitle={`${rule.description}`}
              key={index}
              setIsEdittingRule={setIsEdittingRule}
              setEditCustomRuleId={setEditCustomRuleId}
              ruleId={rule.id}
              showActionButtons={true}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CreatedRulesList;
