import { set } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

const semverGreaterThan = (newVersion, oldVersion) => {
  const newVersionSplit = newVersion.split(/\.|-/g);
  const oldVersionSplit = oldVersion.split(/\.|-/g);

  while (newVersionSplit.length || oldVersionSplit.length) {
    const a = Number(newVersionSplit.shift());
    const b = Number(oldVersionSplit.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }

  return false;
};

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);
  const [lastRefresh, setLastRefresh] = useLocalStorage("lastRefresh", null);

  const refreshCacheAndReload = () => {
    const currentTime = Date.now();

    // Check if last refresh was less than 5 minutes ago
    if (lastRefresh && currentTime - lastRefresh < 5 * 60 * 1000) {
      return;
    }

    // Update last refresh time
    setLastRefresh(currentTime.toString());

    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload(true);
  };

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((meta) => {
        const metaVersion = meta.version;
        const cachedVersion = import.meta.env.VITE_VERSION;

        // Checking if we have the latest version by comparing the cached version number to meta.version
        const isLatest = !semverGreaterThan(metaVersion, cachedVersion);
        setIsLatestVersion(isLatest);
        setLoading(false);
      });
  }, []);

  if (loading) return null;

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();
  }
  return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
