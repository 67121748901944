import { ca } from "date-fns/locale";
import axios from "../../axios";
import { create } from "lodash";
import { connectEmailAccounts } from "@store/EmailAccounts/Global/actions/emailAccounts";
const api = (endpoint, data, responseType = null, onUploadProgress = null) => {
  const method = endpoint[0];
  let url = endpoint[1];
  const contentType = endpoint[2];

  let params = {
    method: method,
    url: url,
  };

  if (data != null) {
    params.data = data;
  }

  if (contentType != null) {
    params.headers = {
      "Content-Type": contentType,
    };
  }

  if (responseType != null) {
    params.responseType = responseType;
  }

  if (onUploadProgress != null) {
    params.onUploadProgress = onUploadProgress;
  }
  return axios(params);
};

export const formUrl = (endpoint, query) => {
  const method = endpoint[0];

  let url = endpoint[1];
  let queryString = "";

  if (query != null) {
    Object.keys(query).forEach((key) => {
      const keyVariable = `{${key}}`;

      if (url.indexOf(keyVariable) > -1) {
        url = url.replace(keyVariable, query[key]);
      } else {
        queryString += queryString.length === 0 ? "?" : "&";
        queryString += `${key}=${query[key]}`;
      }
    });

    url += queryString;
  }

  return [method, url];
};

export default api;

export const API = {
  settings: {
    permissions: ["GET", "settings/permissions"],
    timeZones: ["GET", "settings/timezones"],
    general: ["GET", "settings/general"],
  },

  organization: {
    get: ["GET", "organization"],
    create: ["POST", "organization", "multipart/form-data"],
    roles: ["GET", "organization/roles"],
    claims: ["GET", "organization/claims"],

    remove: ["DELETE", "organization"],
    deleteReasons: ["DELETE", "organization/delete-reasons"],
    checkDeleteAvailability: ["POST", "organization/check-delete-availability"],
    settings: {
      get: ["GET", "organization/settings"],
      update: ["PUT", "organization/settings"],
      updateAutoResponseResolver: ["POST", "organization/auto-response-resolver"],
    },

    changeImage: ["POST", "organization/change-image", "multipart/form-data"],
    getOrganizationDetails: ["GET", "organization/get-organization-details"],
    toggleNotificationForOrganization: ["POST", "organization/disable-enable-organization-notification"],
    getNotificationsForOrganization: ["GET", "organization/get-organization-notifications"],

    getMetadata: ["GET", "organization/get-metadata"],
    getOnboardingSteps: ["GET", "organization/get-onboarding-steps"],
  },

  users: {
    get: ["GET", "users"],
    getById: ["GET", "users/{id}/{campaignId}"],
    create: ["POST", "users"],
    remove: ["DELETE", "users"],
    invites: ["GET", "users/invites"],
    acceptInvite: ["POST", "users/accept-invite"],
    declineInvite: ["POST", "users/decline-invite"],
    changeRole: ["PUT", "users/role"],
    smtp: ["GET", "users/smtp"],
    selectlist: ["GET", "users/selectlistusers"],
    resendInvite: ["POST", "users/resend-invite"],
    logUserLoginToOrganization: ["POST", "users/log-user-login-to-organization"],
    userMeta: ["GET", "users/user-meta"],
  },

  complianceSupportCenter: {
    getAllCategories: ["GET", "compliance/categories-list"],
    getAllQA: ["POST", "compliance/questions-and-answers/get-all"],
    createQA: ["POST", "compliance/questions-and-answers"],
    updateQA: ["PUT", "compliance/questions-and-answers"],
    removeQA: ["DELETE", "compliance/questions-and-answers/{id}"],
    getComplianceSettings: ["GET", "compliance/compliance-settings"],
    getRTE: ["GET", "compliance/right-to-erase"],
    getRTEExport: ["GET", "compliance/right-to-erase/export"],
    updateUpdateSettingsEmailNotification: ["PUT", "compliance/email-notifications"],
    updateUpdateSettingsExternalSeervice: ["PUT", "compliance/external-service"],
    removeRTE: ["DELETE", "compliance/right-to-erase"],
    getComplianceSentences: ["GET", "compliance/get-compliance-sentences"],
  },

  campaigns: {
    post: ["POST", "campaigns/campaigns"],
    edit: ["PUT", "campaigns"],
    listCampaignStatuses: ["GET", "campaigns/selectlist-campaign-statuses"],
    getList: ["GET", "campaigns/selectlistcampaigns"],
    getListUnarchived: ["GET", "campaigns/select-list-unarchived-campaigns"],
    globalSearch: ["GET", "campaigns/global-search"],
    editStatus: ["PUT", "campaigns/edit-status"],
    getCrossCampaignDays: ["GET", "campaigns/get-cross-campaign-days"],
    moveProspectsToCampaign: ["POST", "campaigns/move-prospects-to-campaign"],

    // create: ["POST", "campaigns"],
    // unassignAudience: ["PUT", "campaigns/unassign-audience"],
    // getSettings: ["GET", "campaigns/settings"],
    // editResponderExclusionsCrossCampaigns: ["PUT", "campaigns/responder-exclusion-cross-campaign"],
    // editTracking: ["PUT", "campaigns/edit-tracking"],
    // editCrmStatus: ["GET", "campaigns/campaign-enable-disable-crm"],
    // cloneCampaign: ["POST", "campaigns/campaign-clone"],
  },

  account: {
    changeImage: ["POST", "account/change-image", "multipart/form-data"],
    editUser: ["POST", "account/edituser"],
  },

  prospects: {
    post: ["POST", "prospects/prospects"],
    exportProspectsData: ["POST", "prospects/export-all-prospects"],
    listCompanies: ["GET", "prospects/selectlistcompanies"],
    listCompaniesAutoComplete: ["POST", "prospects/select-list-companies-auto-complete"],
    listResponseTypes: ["GET", "prospects/selectlistresponsetypes"],
    listSenderEmailAccounts: ["GET", "prospects/select-list-email-accounts"],
    statuscount: ["POST", "prospects/statuscount"],
    globalSearch: ["GET", "prospects/global-search"],
  },

  companies: {
    post: ["POST", "companies/companies"],
    statuscount: ["POST", "companies/status-count"],
    listIndustry: ["GET", "companies/selectlistindustry"],
    size: ["GET", "companies/selectlistsize"],
    globalSearch: ["GET", "companies/global-search"],
    exportCompanies: ["POST", "companies/export-all-companies"],
  },

  exclusions: {
    post: ["POST", "exclusions/exclusions"],
    create: ["POST", "exclusions"],
    export: ["POST", "exclusions/exportexclusions"],
    remove: ["DELETE", "exclusions"],
    uploadcsv: ["POST", "exclusions/uploadcsv", "multipart/form-data"],
    // exclusionCount: ["GET", "exclusions/exclusions-count"],
    exportInvalidExclusions: ["POST", "exclusions/export-invalid-exclusions"],
    downloadCSVTemplate: ["GET", "exclusions/download-csv-template"],
  },

  emailAccounts: {
    post: ["POST", "email-accounts/email-accounts"],
    create: ["POST", "email-accounts"],
    createThirdParty: ["POST", "email-accounts/create-third-party"],
    reconnectThirdParty: ["POST", "email-accounts/reconnect-third-party"],
    sendTest: ["POST", "email-accounts/send-test"],
    receiveTest: ["POST", "email-accounts/receive-test"],
    testEmailAccount: ["PUT", "email-accounts/full-test"],
    get: ["GET", "email-accounts/{id}"],
    listEmailAccounts: ["GET", "email-accounts/list-email-accounts"],
    listOrganizationEmailAccounts: ["GET", "email-accounts/list-organization-email-accounts"],
    connect: ["POST", "email-accounts/connect"],
    delete: ["DELETE", "email-accounts"],
    mxrecords: ["GET", "email-accounts/mxrecords"],
    selectListEmailAccounts: ["GET", "email-accounts/select-list-email-accounts"],
    listOfEmailAccountsMultiCampaigns: ["POST", "email-accounts/list-email-accounts-multi-campaigns"],
    changePassword: ["POST", "email-accounts/change-password"],
  },

  dealNavigator: {
    get: ["GET", "deal-navigator"],
    publishDraftVersion: ["POST", "deal-navigator/publish"],
    discardDraftVersion: ["POST", "deal-navigator/discard"],
    chooseTemplate: ["PUT", "deal-navigator/choose-template"],
    getTemplate: ["GET", "deal-navigator/templates"],
    getTemplateEdit: ["GET", "deal-navigator/template-edit"],
    reset: ["PUT", "deal-navigator/reset"],
    publishTemplate: ["PUT", "deal-navigator/publish-template"],
    discardTemplateDraftVersion: ["PUT", "deal-navigator/discard-template"],

    stages: {
      getById: ["GET", "deal-navigator/stages/{id}"],
      create: ["POST", "deal-navigator/stages"],
      update: ["PUT", "deal-navigator/stages"],
      remove: ["DELETE", "deal-navigator/stages/{id}"],
      reorder: ["PUT", "deal-navigator/stages/reorder"],
    },

    cards: {
      getById: ["GET", "deal-navigator/cards/{id}"],
      create: ["POST", "deal-navigator/cards"],
      update: ["PUT", "deal-navigator/cards"],
      remove: ["DELETE", "deal-navigator/cards/{id}"],
      reorder: ["PUT", "deal-navigator/cards/reorder"],
      swap: ["PUT", "deal-navigator/cards/swap"],
    },

    elements: {
      getById: ["GET", "deal-navigator/elements/{id}"],
      create: ["POST", "deal-navigator/elements", "multipart/form-data"],
      update: ["PUT", "deal-navigator/elements"],
      remove: ["DELETE", "deal-navigator/elements/{id}"],
    },
  },

  salesDocument: {
    getAll: ["GET", "sales-documents"],
    getAllVideos: ["GET", "sales-documents/videos"],
    getAllImages: ["GET", "sales-documents/images"],
    getListOfTags: ["GET", "sales-documents/tag-list"],
    getTable: ["POST", "sales-documents/table"],
    getById: ["GET", "sales-documents/{id}"],
    createDocument: ["POST", "sales-documents/document", "multipart/form-data"],
    createImage: ["POST", "sales-documents/image", "multipart/form-data"],
    createVideo: ["POST", "sales-documents/video"],
    updateDocument: ["PUT", "sales-documents/document", "multipart/form-data"],
    updateImage: ["PUT", "sales-documents/image", "multipart/form-data"],
    updateVideo: ["PUT", "sales-documents/video"],
    remove: ["DELETE", "sales-documents/{id}"],
    downloadDocument: ["GET", "sales-documents/download-document/{id}"],
    getImage: ["GET", "sales-documents/image/{id}"],
  },

  emailTemplates: {
    post: ["POST", "email-templates/email-templates"],
    create: ["POST", "email-templates"],
    get: ["GET", "email-templates"],
    remove: ["DELETE", "email-templates"],
    sendTestEmail: ["POST", "email-templates/send-test-email"],
    sendTestEmailSequence: ["POST", "email-templates/send-test-email-sequence"],
    uploadImage: ["POST", "email-templates/upload-template-image", "multipart/form-data"],
    checkAwesomeness: ["POST", "email-templates/check-awesomeness"],
    listEmailTemplatesTypes: ["GET", "email-templates/select-list-email-template-types"],
    listEmailTemplatesStatus: ["GET", "email-templates/select-list-email-template-status"],
    listEmailTemplatesVariables: ["GET", "email-templates/get-email-template-variables"],
    listEmailTemplatesVariableTypes: ["GET", "email-templates/get-email-template-variable-types"],
    listOrganizationCustomColumns: ["GET", "email-templates/get-organization-custom-columns"],
    listEmailTemplateCustomVariables: ["GET", "email-templates/get-email-template-custom-variables"],
    getDeleteDetails: ["GET", "email-templates/delete-details"],
    getInitialEmailTemplates: ["GET", "email-templates/get-initial-email-templates"],
    getAIPromptInitialInfo: ["GET", "email-templates/get-ai-prompt-initial-info"],
    generateAITemplate: ["POST", "email-templates/generate-template"],
    checkWebsiteInfoSummary: ["POST", "email-templates/check-website-info-summary"],
    getAllWebsiteInfoLogs: ["POST", "email-templates/get-all-website-info-logs"],
    resetAIPrompt: ["POST", "email-templates/reset-ai-prompt"],
    tweakTemplate: ["POST", "email-templates/tweak-template"],
    getAllTweakTemplateLogs: ["GET", "email-templates/get-all-tweak-template-logs"],
    generateAIFollowUpTemplate: ["POST", "email-templates/generate-ai-followup-template"],
  },

  workflowSteps: {
    create: ["POST", "workflow-steps/create"],
    getList: ["GET", "workflow-steps/workflow-steps-list"],
    delete: ["DELETE", "workflow-steps"],
  },

  domains: {
    get: ["POST", "domains/domains?activeStatus=true"],
    getInactive: ["POST", "domains/domains?activeStatus=false"],
    update: ["PUT", "domains"],
    remove: ["DELETE", "domains/{id}"],
    verify: ["POST", "domains/verify"],
    unverifiedCount: ["GET", "domains/unverified-count"],
    usageCount: ["GET", "domains/usage-count/{id}"],
  },
  sendingSchedule: {
    create: ["POST", "sending-schedule"],
    get: ["GET", "sending-schedule"],
    connectEmailAccounts: ["POST", "sending-schedule/connect-email-account"],
  },

  audience: {
    post: ["POST", "audiences/audiences"],
    listAudiences: ["GET", "audiences/select-list-audiences"],
    //listAudiencesForCampaign: ["GET", "audiences/select-list-audiences-campaign"],
    // listNotInitiatedAudiencesForCampaign: ["GET", "audiences/select-list-not-initiated-audiences-campaign"],
    listAudienceTypes: ["GET", "audiences/select-list-audience-types"],
    listAudienceStatuses: ["GET", "audiences/select-list-audience-statuses"],
    uploadCSV: ["POST", "audiences/upload-csv", "multipart/form-data"],
    downloadCSVTemplate: ["GET", "audiences/download-csv-template"],
    importAudienceContacts: ["POST", "audiences/import-audience-contacts", "multipart/form-data"],
    setAudienceImportSettings: ["POST", "audiences/set-audience-import-settings"],
    checkIfAudienceNameExists: ["POST", "audiences/check-audience-name"],
    exportAudienceContacts: ["POST", "audiences/export-audience-contacts"],
    archiveRestoreAudience: ["POST", "audiences/archive-restore-audience"],
    audienceDetails: ["GET", "audiences/audience-details"],
    audienceContacts: ["POST", "audiences/audience-contacts"],
    audienceContactDetails: ["GET", "audiences/audience-contact-details"],
    // listAudienceContactsCompanyIndustries: ["GET", "audiences/select-list-audience-contacts-company-industries"],
    // listAudienceContactsCompanySizes: ["GET", "audiences/select-list-audience-contacts-company-sizes"],
    // listAudienceContactsCountries: ["GET", "audiences/select-list-audience-contacts-countries"],
    getProspectDetails: ["GET", "prospects/prospect-details"],
    getCompanyDetails: ["GET", "companies/company-details"],
    updateAudienceName: ["POST", "audiences/update-audience-name"],
    exportInvalidAudienceContacts: ["POST", "audiences/export-invalid-audience-contacts"],
    getAudienceType: ["GET", "audiences/get-audience-type"],
    audienceLastPushToCampaignFinished: ["POST", "audiences/audience-last-push-to-campaign-finished"],
    getRunningImportProcesses: ["GET", "audiences/running-import-processes"],
    setDontImportFlag: ["POST", "audiences/set-dont-import-flag"],
    getAudienceTargetingInfo: ["POST", "audiences/audience-targeting-info"],
  },

  audienceExplorer: {
    listTechnologies: ["GET", "audience-explorer/select-list-technologies"],
    listTechnologiesAutoComplete: ["GET", "audience-explorer/select-list-technologies-auto-complete"],
    listCompanySizes: ["GET", "audience-explorer/select-list-company-sizes"],
    listJobTitles: ["GET", "audience-explorer/select-list-job-titles"],
    listJobTitlesAutoComplete: ["GET", "audience-explorer/select-list-job-titles-auto-complete"],
    listCountries: ["GET", "audience-explorer/select-list-countries"],
    listCompanyNamesAutoComplete: ["GET", "audience-explorer/select-list-company-name-auto-complete"],
    listDepartments: ["GET", "audience-explorer/select-list-departments"],
    listIndustries: ["GET", "audience-explorer/select-list-industries"],
    searchCompanies: ["POST", "audience-explorer/company-search"],
    searchCompaniesFull: ["POST", "audience-explorer/company-search-full"],
    searchProspects: ["POST", "audience-explorer/prospect-search"],
    searchProspectsFull: ["POST", "audience-explorer/prospect-search-full"],
    listCompanyLocationAutoComplete: ["GET", "audience-explorer/select-list-company-location-auto-complete"],
    listCompanyLocationRangeAutoComplete: ["GET", "audience-explorer/select-list-company-location-range-auto-complete"],
    createAudience: ["POST", "audience-explorer/create-audience"],
    getAudienceContactById: ["GET", "audience-explorer/get-audience-contact-by-id"],
    getAudienceExplorerContact: ["GET", "audience-explorer/get-audience-explorer-contact"],
    getCompanyById: ["GET", "audience-explorer/get-company-by-id"],
    audienceContacts: ["POST", "audience-explorer/audience-contacts"],
    setAudienceImportSettings: ["POST", "audience-explorer/set-audience-import-settings"],
    listStandardLocationAutoComplete: ["GET", "audience-explorer/select-list-standard-location-auto-complete"],
    listProspectLocationRangeAutoComplete: ["GET", "audience-explorer/select-list-prospect-location-range-auto-complete"],
    listCountriesAutoComplete: ["GET", "audience-explorer/select-list-countries-auto-complete"],
    listRevenueRanges: ["GET", "audience-explorer/select-list-revenue-ranges"],
    // getURLForSearch: ["POST", "audience-explorer/get-url-for-search"],
    listJobTitleDepartments: ["GET", "audience-explorer/select-list-job-title-departments"],
    listJobTitleSeniorities: ["GET", "audience-explorer/select-list-job-title-seniorities"],
    editAudience: ["POST", "audience-explorer/edit-audience"],
  },

  assistant: {
    loadStepData: ["POST", "assistant/step/load"],
    saveStepData: ["POST", "assistant/step/save"],
    startExecution: ["POST", "assistant/execution"],
    complete: ["POST", "assistant/complete"],
    quit: ["POST", "assistant/quit"],
    getSummary: ["GET", "assistant/summary"],
    getAssistantStatus: ["GET", "assistant/execution-status"],
    createCampaign: ["POST", "assistant/create-campaign"],
  },

  deals: {
    getResponseDetails: ["GET", "deals/response-details"],
    getReceivedEmailDetails: ["GET", "deals/received-email-details"],
    getAllDeals: ["POST", "deals/deals"],
    exportAll: ["POST", "deals/export-all"],
    export: ["POST", "deals/export"],
  },

  responderInbox: {
    getListEmails: ["GET", "responder-inbox/emails-list"],
    getListOfProspects: ["POST", "responder-inbox/prospects-list"],
    getTotalNumberOfEmailsByType: ["GET", "responder-inbox/total"],
    getMailDetails: ["GET", "responder-inbox/mail-details"],
    getInboxResponses: ["POST", "responder-inbox/responder-inbox"],
    resolve: ["PUT", "responder-inbox/resolve"],
    export: ["POST", "responder-inbox/export-responder-exclusions"],
    backToUnresolve: ["PUT", "responder-inbox/unresolve"],
    getResponderExclusionsTable: ["POST", "responder-inbox/responder-exclusions-table"],
    confirmAllAutoResolved: ["POST", "responder-inbox/confirm-all-autoResolved"],
  },

  companyActivityOverview: {
    getAllProspects: ["POST", "company-activity-overview/prospects"],
    getCompanyDetails: ["GET", "company-activity-overview/company"],
    getListOfCampaings: ["GET", "company-activity-overview/campaings-list"],
    getStatuses: ["GET", "company-activity-overview/statuses-stats"],
    getResponses: ["POST", "company-activity-overview/timeline"],
  },

  prospectActivityOverview: {
    getProspectDetails: ["GET", "prospects/prospect-details"],
    getListOfCampaings: ["GET", "campaigns/selectlistcampaigns"],
    getTimeline: ["POST", "prospect-activity-overview/timeline"],
    getProspectStatuses: ["GET", "prospect-activity-overview/prospect-statuses"],
  },

  conversation: {
    getListEmails: ["GET", "conversation/emails-list"],
    getConversationDetails: ["GET", "conversation/conversation-details"],
    getConversationInbox: ["POST", "conversation/conversation"],
    getProspectDetails: ["GET", "prospects/prospect-details"],
  },

  calendar: {
    getCalendarEvents: ["POST", "calendar/calendar-events"],
    getCalendarEventId: ["POST", "calendar/calendar-event-id"],
    getEventDetails: ["POST", "calendar/calendar-event-details"],
    getScheduledEventDetails: ["POST", "calendar/scheduled-calendar-event-details"],
  },

  inAppNotifications: {
    getAllInAppNotifications: ["GET", "in-app-notifications-log/in-app-notifications-log"],
    deleteInAppNotification: ["POST", "in-app-notifications-log/delete-notification"],
    markInAppNotificationAsRead: ["POSt", "in-app-notifications-log/mark-notification-as-read"],
    markAllInAppNotificationsAsRead: ["POSt", "in-app-notifications-log/mark-all-notifications-as-read"],
  },

  reports: {
    getOrganizationReport: ["POST", "report/organization"],
    getReportByDay: ["POST", "report/daily"],
    getReportByMonth: ["POST", "report/monthly"],
    getReportByIndustry: ["POST", "report/industry"],
    getReportByCompanySize: ["POST", "report/company-size"],
    getReportByStage: ["POST", "report/stage"],
    getReportByRunRates: ["POST", "report/run-rates"],
  },

  filteredreports: {
    getOrganizationReport: ["GET", "report/organization"],
    getFilteredReportByDay: ["POST", "report/filtered-daily"],
    getFilteredReportByMonth: ["POST", "report/filtered-monthly"],
    getFilteredReportByIndustry: ["POST", "report/filtered-industry"],
    getFilteredReportByCompanySize: ["POST", "report/filtered-company-size"],
    getFilteredReportByStage: ["POST", "report/filtered-stage"],
  },

  emailDeliverabilityTest: {
    getAllBasicEmailTests: ["POST", "email-deliverability-test/basic-tests-table"],
    getAllAdvnaceEmailTests: ["POST", "email-deliverability-test/advance-tests-table"],
    getBasicEmailTestDetails: ["GET", "email-deliverability-test/basic-test-details"],
    getAdvanceEmailTestDetails: ["GET", "email-deliverability-test/advance-test-details"],
    basicEmailTestRequest: ["GET", "email-deliverability-test/basic-test-request"],
    advanceEmailTestRequest: ["GET", "email-deliverability-test/advance-test-request"],
    sendBasicEmailTest: ["POST", "email-deliverability-test/send-basic-test"],
    sendAdvanceEmailTest: ["POST", "email-deliverability-test/send-advance-test"],
    listOfBasicEmailTest: ["GET", "email-deliverability-test/list-basic-tests"],
    listOfAdvanceEmailTest: ["GET", "email-deliverability-test/list-advance-tests"],
  },

  integrations: {
    getAllIntegrations: ["GET", "integrations/get-all-integrations"],
    getHasSetIntegration: ["GET", "integrations/has-set-integration"],
    disconnectIntegration: ["DELETE", "integrations/disconnect-integration", "application/json"],

    hubspot: {
      getCompaniesProperties: ["GET", "integration/hubspot/get-companies-properties"],
      getContactsProperties: ["GET", "integration/hubspot/get-contacts-properties"],
      getDealsProperties: ["GET", "integration/hubspot/get-deals-properties"],
      mapContactsAndCompaniesProperties: ["POST", "integration/hubspot/save-contacts-and-companies-mappings"],
      getAllCustomRules: ["GET", "integration/hubspot/get-all-rules"],
      getRulesets: ["GET", "integration/hubspot/get-rulesets"],
      deleteCustomRules: ["DELETE", "integration/hubspot/delete-custom-rule"],
      addCustomRules: ["POST", "integration/hubspot/add-custom-rule"],
      editCustomRules: ["POST", "integration/hubspot/edit-custom-rule"],
      getCustomRuleById: ["GET", "integration/hubspot/get-custom-rule-by-id"],
      getRulesCustomFields: ["GET", "integration/hubspot/get-rules-custom-fields"],
      getDealsPipelines: ["GET", "integration/hubspot/get-deals-pipelines"],
      getDealsPipelineById: ["GET", "integration/hubspot/get-deals-pipeline-by-id"],
      getConfiguration: ["GET", "integration/hubspot/get-global-configuration"],
      getGlobalMappingsCustomFields: ["GET", "integration/hubspot/get-global-mappings-custom-fields"],
      createConfigurationFromRuleset: ["GET", "integration/hubspot/create-from-ruleset"],
    },
  },

  dashboard: {
    getTotalDataAndAudience: ["GET", "dashboard/total-data-and-audiance"],
    getDataAndAudienceByCampaign: ["POST", "dashboard/total-data-and-audiance-by-campaign"],
    getTotalCampaignsPerformance: ["GET", "dashboard/total-campaign-performance"],
    getCampaignPerformanceByCampaign: ["POST", "dashboard/total-campaign-performance-by-campaign"],
    getTotalResponseRate: ["GET", "dashboard/response-rate"],
    getResponseRateByCampaign: ["POST", "dashboard/response-rate-by-campaign"],
    getTotalRunRates: ["GET", "dashboard/run-rates"],
    getRunRatesByCampaign: ["POST", "dashboard/run-rates-by-campaign"],
    getLeadRate: ["GET", "dashboard/lead-rate"],
    getProspectFunnelStatistics: ["GET", "dashboard/prospect-funnel-statistics"],
    getCampaignsInfo: ["GET", "dashboard/campaign-info"],
    getGlobalAverageRate: ["GET", "dashboard/global-average-rate"],
    getStepsDone: ["GET", "dashboard/steps-done"],
  },

  billingAndPayment: {
    //createPaymentProfile: ["POST", "billing-payment/create-payment-profile"],
    getCreditInfo: ["GET", "billing-payment/credits-info"],
    createUpdateBillingInfo: ["POST", "billing-payment/create-update-billing-info"],
    getBillingInfo: ["GET", "billing-payment/billing-info"],
    deleteBillingInfo: ["DELETE", "billing-payment/delete-billing-info/{id}"],
    createBillingInfoPaymentProfile: ["POST", "billing-payment/create-billing-info-payment-profile"],
    upgradePricingPlan: ["POST", "billing-payment/upgrade-pricing-plan"],
    getPricingPlans: ["GET", "billing-payment/pricing-plans"],
    createUpdateCreditCard: ["POST", "billing-payment/create-update-credit-card/{token}"],
    updateAutoRenewal: ["PUT", "billing-payment/update-auto-renewal/{autoRenewal}"],
    post: ["POST", "billing-payment/invoices"],
    requestReminder: ["POST", "billing-payment/hello-sign/reminder"],
    addBillingEmailAddress: ["POST", "billing-payment/billing-email-address/{email}"],
    deleteBillingEmailAddress: ["DELETE", "billing-payment/billing-email-address/{email}"],
    getContractStatus: ["GET", "billing-payment/contract-status"],
    requestSigning: ["POST", "billing-payment/request-signing"],
    getPlanInfoCheckoutStatus: ["GET", "billing-payment/checkout-info"],
    getCountryStateInfo: ["GET", "billing-payment/country-state-info"],
    getRegisteredUserParams: ["GET", "billing-payment/registered-user-params"],
    cancelContract: ["POST", "billing-payment/hello-sign/cancel-contract"],
    updateInvoiceUrl: ["POST", "billing-payment/update-invoice-url"],
    logPaymentError: ["POST", "billing-payment/log-payment-error"],
    cancelSubscription: ["POST", "billing-payment/cancel-subscription"],
    reactivateCancelSubscription: ["PUT", "billing-payment/reactivate-cancel-subscription"],
    cancelScheduledDowngrade: ["POST", "billing-payment/cancel-scheduled-downgrade"],
  },

  impersonate: {
    impersonateUser: ["POST", "impersonate/impersonate-user"],
    stopImpersonation: ["POST", "impersonate/stop-impersonation"],
  },

  purchaseEmailTemplates: {
    post: ["POST", "purchase-email-template/purchase-email-template"],
    get: ["GET", "purchase-email-template/get-purchase-template"],
    getJobTitleList: ["GET", "purchase-email-template/select-list-job-titles"],
    getPurchaseTemplatePrice: ["GET", "purchase-email-template/get-purchase-template-price"],
  },
  OUTBOSS: {
    organizations: {
      post: ["POST", "outboss-organization/organizations"],
      exportAllOrganizations: ["POST", "outboss-organization/export-all-organizations"],
      adjustCreditsForOrganization: ["POST", "outboss-organization/adjust-credits-for-organization"],
      getCreditAdjustmentHistoryForOrganization: ["POST", "outboss-organization/get-credit-adjustment-history-for-organization"],
      getOrganizationCreditsInfo: ["GET", "outboss-organization/get-organization-credits-info"],
      disableEnableOrganizationAccess: ["POST", "outboss-organization/disable-enable-organization-access"],
      getOrganizationPaymentDetails: ["GET", "outboss-organization/get-organization-payment-details"],
      extendTrialForOrganization: ["POST", "outboss-organization/extend-trial"],
    },
    users: {
      post: ["POST", "outboss-user/users"],
      changeUserType: ["POST", "outboss-user/change-user-type"],
      getUserDetailsDataTable: ["POST", "outboss-user/user-details-data-table"],
      getUserDetails: ["GET", "outboss-user/user-details"],
      exportAllOutbaseUsers: ["POST", "outboss-user/export-all-outbase-users"],
      removeUserAccess: ["POST", "outboss-user/remove-user-access"],
      resendVerificationEmails: ["POST", "outboss-user/resend-verification-email"],
      changeOrganizationOwner: ["POST", "outboss-user/change-organization-owner"],
      disableEnableUserAccess: ["POST", "outboss-user/disable-enable-user-access"],
      logAdminLogin: ["POST", "outboss-user/log-admin-login"],
      confirmUserEmail: ["POST", "outboss-user/confirm-user-email"],
    },
    userActivityLog: {
      post: ["POST", "outboss-user-activity-log/user-activity-logs"],
      getUserActivityLogDetails: ["GET", "outboss-user-activity-log/get-user-activity-log-details"],
    },
    emailAccounts: {
      post: ["POST", "outboss-email-accounts/email-accounts"],
      exportEmailAccounts: ["POST", "outboss-email-accounts/export-email-accounts"],
      syncLogs: ["POST", "outboss-email-accounts/sync-log"],
      emailAccountDetails: ["POST", "outboss-email-accounts/email-account-details/{id}"],
      resetSync: ["POST", "outboss-email-accounts/reset-sync/{id}"],
      syncFromDate: ["POST", "outboss-email-accounts/sync-from-date"],
    },
  },
  sendReports: {
    post: ["POST", "send-reports/send-reports"],
    getEmailStagesList: ["POST", "send-reports/email-stages-list"],
  },
  coupon: {
    validate: ["POST", "coupon/validate/{code}/{appliesTo}/{term}/{isAnnual}"],
  },
  organizationDetails: {
    getOrganizationDetails: ["GET", "organization-details"],
  },

  campaignWizard: {
    getLatest: ["GET", "campaign-wizard/get-latest"],
    getLatestForUser: ["GET", "campaign-wizard/get-latest-for-user"],
  },
};
