import React from "react";

const BrandMarkLogoDark = (props) => (
  <svg
    width="118"
    height="131"
    viewBox="0 0 118 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M117.351 57.5979C117.329 66.657 115.127 75.5786 110.932 83.6134C106.737 91.6482 100.67 98.5617 93.2402 103.773C83.1846 96.6452 71.1463 92.8308 58.8096 92.8637C65.7681 92.7079 72.5256 90.5076 78.2358 86.5383C83.946 82.5689 88.3548 77.0072 90.9097 70.5498C93.4647 64.0925 94.0524 57.0267 92.5989 50.2378C91.1455 43.4488 87.7158 37.2386 82.7393 32.3852C77.7628 27.5318 71.4609 24.2511 64.6233 22.9541C57.7856 21.657 50.7164 22.4012 44.301 25.0937C37.8856 27.7861 32.4094 32.3069 28.5586 38.0896C24.7079 43.8724 22.6537 50.6601 22.6536 57.602C22.762 67.0594 26.6317 76.0867 33.4118 82.6991C40.1919 89.3115 49.3274 92.9676 58.8096 92.8637C46.4717 92.8305 34.4321 96.6449 24.3751 103.773C16.9467 98.561 10.8804 91.6473 6.68574 83.6125C2.49106 75.5778 0.290408 66.6566 0.268295 57.5979C0.581164 42.319 6.88637 27.771 17.8309 17.0758C28.7755 6.38054 43.4873 0.390244 58.8096 0.390244C74.1319 0.390244 88.8437 6.38054 99.7883 17.0758C110.733 27.771 117.038 42.319 117.351 57.5979Z"
      fill="#FE7904"
    />
    <path
      d="M67.4163 57.8822C67.4163 59.5807 66.9112 61.2411 65.9651 62.6534C65.0189 64.0657 63.6741 65.1665 62.1007 65.8166C60.5273 66.4666 58.7961 66.6366 57.1258 66.3052C55.4554 65.9738 53.9211 65.1559 52.7169 63.9548C51.5127 62.7537 50.6926 61.2236 50.3603 59.5576C50.0281 57.8917 50.1987 56.1648 50.8504 54.5955C51.5021 53.0262 52.6056 51.685 54.0216 50.7413C55.4376 49.7977 57.1025 49.2939 58.8055 49.2939C59.9364 49.2934 61.0565 49.5152 62.1014 49.9466C63.1463 50.3781 64.0957 51.0106 64.8953 51.8082C65.695 52.6057 66.3293 53.5527 66.7619 54.5949C67.1944 55.6371 67.4168 56.7542 67.4163 57.8822Z"
      fill="#474747"
    />
    <path
      d="M93.0937 125.236C93.0937 126.603 92.5491 127.914 91.5799 128.881C90.6107 129.847 89.2963 130.39 87.9256 130.39H29.5023C28.1321 130.39 26.8183 129.847 25.8494 128.881C24.8806 127.915 24.3364 126.604 24.3364 125.238C24.3364 123.871 24.8806 122.561 25.8494 121.594C26.8183 120.628 28.1321 120.085 29.5023 120.085H87.9256C89.2956 120.085 90.6096 120.628 91.5787 121.594C92.5478 122.559 93.0927 123.869 93.0937 125.236Z"
      fill="#474747"
    />
  </svg>
);

export default BrandMarkLogoDark;
