import * as actionTypes from "./actionTypes";
import api, { API } from "../../../../helper/api/api";

export const fetchResponderInboxRequest = () => {
  return {
    type: actionTypes.FETCH_RESPONDER_INBOX_REQUEST,
  };
};

export const fetchResponderInboxSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_RESPONDER_INBOX_SUCCEEDED,
    result: setup,
  };
};

export const fetchResponderInboxFailed = (error) => {
  return {
    type: actionTypes.FETCH_RESPONDER_INBOX_FAILED,
    result: error,
  };
};

export const fetchResponderInbox = (param, isResolvedTabActivated) => {
  let data = {
    param: param,
    isResolvedTabActivated: isResolvedTabActivated,
  };

  return (dispatch) => {
    dispatch(fetchResponderInboxRequest());
    return api(API.responderInbox.getInboxResponses, data)
      .then((response) => {
        dispatch(fetchResponderInboxSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchResponderInboxFailed(error.message));
      });
  };
};

export const setMessageResolved = (data) => {
  return {
    type: actionTypes.SET_MESSAGE_RESOLVED,
    result: data,
  };
};

export const initiate = (state) => {
  return {
    type: actionTypes.RESPONDER_INBOX_INITIATE,
    result: state,
  };
};

export const changePage = (page) => {
  return {
    type: actionTypes.RESPONDER_INBOX_CHANGE_PAGE,
    result: page,
  };
};

export const sortData = (obj) => {
  return {
    type: actionTypes.RESPONDER_INBOX_SORT_DATA,
    result: obj,
  };
};

export const numRecords = (obj) => {
  return {
    type: actionTypes.RESPONDER_INBOX_NUM_RECORDS,
    result: obj,
  };
};

export const searchRecords = (value) => {
  return {
    type: actionTypes.RESPONDER_INBOX_SEARCH_RECORDS,
    result: value,
  };
};

export const filterRecords = (obj) => {
  return {
    type: actionTypes.RESPONDER_INBOX_FILTER_RECORDS,
    result: obj,
  };
};

export const setFilter = (type, value) => {
  return {
    type: actionTypes.RESPONDER_INBOX_SET_FILTER,
    filterType: type,
    filterValue: value,
  };
};

export const clearFilter = (type) => {
  return {
    type: actionTypes.RESPONDER_INBOX_CLEAR_FILTER,
    filterType: type,
  };
};

export const setSelectedRow = (obj) => {
  return {
    type: actionTypes.RESPONDER_INBOX_SET_SELECTED_ROW,
    result: obj,
  };
};

export const clearSelectedRow = () => {
  return {
    type: actionTypes.RESPONDER_INBOX_CLEAR_SELECTED_ROW,
  };
};

export const clearInitiated = (type) => {
  return {
    type: actionTypes.RESPONDER_INBOX_CLEAR_INITIATE,
  };
};
