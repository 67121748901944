import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { errorResponseToList } from "../../helper/errorHelper";
import { fileDownload } from "../../helper/fileDownloadHelper";
import { toastMessages } from "../../helper/toastMessagesConstants";

//Styles
import styles from "./Audiences.module.scss";

//Components
import Modal from "../../components/UI/Modal/Modal";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import FirstStep from "./components/Steps/FirstStep";
import SecondStep from "./components/Steps/SecondStep";
import ThirdStep from "./components/Steps/ThirdStep";
import FourthStep from "./components/Steps/FourthStep";
import { useSetAudienceCampaignsMutation } from "@api/audienceApi";

const AudienceUploadForm = (props) => {
  let renderPage = null;
  const [page, setPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFileError, setIsFileError] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Next");
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [invalidContactsCSVString, setInvalidContactsCSVString] = useState("");
  const [customBodyClass, setCustomBodyClass] = useState(styles.customBodyClass);
  const [signalRConnectionId, setSignalRConnectionId] = useState("");

  const [setAudienceCampaigns] = useSetAudienceCampaignsMutation();

  //FirstStep
  const [CSVFileName, setCSVFileName] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [CSVLimitExceeded, setCSVLimitExceeded] = useState(false);
  const [unexpectedCSVError, setUnexpectedCSVError] = useState(false);
  const [wrongTypeError, setWrongTypeError] = useState(false);
  //SecondStep
  const [fieldsMatched, setFieldsMatched] = useState(false);
  const [fieldsMatchedDictionary, setFieldsMatchedDictionary] = useState([]);

  //FourthStep
  const [contactsImported, setContactsImported] = useState(false);
  const [audienceContactsImportInfo, setAudienceContactsImportInfo] = useState("");

  const checkIfNameExists = (newName) => {
    var result = false;
    if (props.audience?.id === 0) {
      props.listAudiences.map((name) => {
        var existingName = name.label;
        if (existingName === newName) {
          result = true;
        }
      });
    }

    return result;
  };

  const resetConstancts = () => {
    setPage(1);
    setIsFileError(false);
    setSaveButtonText("Next");
    setCSVFileName("");
    setShowButton(false);
    setPercentCompleted(0);
    setCSVLimitExceeded(false);
    setUnexpectedCSVError(false);
    setWrongTypeError(false);
    setDisableSaveButton(false);
    setContactsImported(false);
    setAudienceContactsImportInfo("");
    setFieldsMatched(false);
    setFieldsMatchedDictionary([]);
    setInvalidContactsCSVString("");
  };

  const nextPageHandler = () => {
    let newPage = page + 1;
    setPage(newPage);
  };

  const pageHandler = (newPage, handleSubmit) => {
    if (contactsImported === false) {
      if (newPage <= page) {
        setPage(newPage);
      } else {
        handleSubmit();
      }

      if (newPage < 3) {
        setSaveButtonText("Next");
      }
    }
  };

  const handleGoBack = (isSave = false) => {
    if (page > 1 && isSave === false && contactsImported === false) {
      setPage(page - 1);
      setIsSubmitting(false);
      setDisableSaveButton(false);
      setSaveButtonText("Next");
    }
    if (page === 1 && isSave === false) {
      props.handleClose();
      resetConstancts();
    }
    if (isSave === true && contactsImported === true) {
      props.handleClose();
      resetConstancts();
      props.getAudiences();
    }
  };

  const handleHideModal = () => {
    props.handleClose();
    resetConstancts();
  };

  const importAudienceContacts = (values, actions) => {
    if (contactsImported === false) {
      var data = new FormData();
      data.append("csvFile", values.csvFile);
      data.append("audienceId", values.audienceId);
      data.append("audienceName", values.name);

      var csvCustomMappedColumnsData = values.csvCustomMappedColumns;
      if (csvCustomMappedColumnsData !== undefined) {
        for (let i = 0; i < csvCustomMappedColumnsData.length; i++) {
          data.append(`csvCustomMappedColumns[${i}].customFieldKey`, csvCustomMappedColumnsData[i].customFieldKey || "");
          data.append(`csvCustomMappedColumns[${i}].customFieldValue`, csvCustomMappedColumnsData[i].customFieldValue || "");
        }
      }

      let csvMappedColumns = values.csvMappedColumns;
      data.append("csvMappedColumns.firstName", csvMappedColumns.firstName);
      data.append("csvMappedColumns.lastName", csvMappedColumns.lastName);
      data.append("csvMappedColumns.jobTitle", csvMappedColumns.jobTitle);
      data.append("csvMappedColumns.emailAddress", csvMappedColumns.emailAddress);
      data.append("csvMappedColumns.contactCountry", csvMappedColumns.contactCountry);
      data.append("csvMappedColumns.company", csvMappedColumns.company);
      data.append("csvMappedColumns.companyWebsite", csvMappedColumns.companyWebsite);
      data.append("csvMappedColumns.companyIndustry", csvMappedColumns.companyIndustry);
      data.append("csvMappedColumns.companySize", csvMappedColumns.companySize);

      data.append("signalRConnectionId", signalRConnectionId);

      api(API.audience.importAudienceContacts, data)
        .then((res) => {
          var csvString = res.data.csvString;
          setInvalidContactsCSVString(csvString);

          actions.setFieldValue("audienceId", res.data.audienceId);

          // Delete completely if SignalR works on Staging/Live
          // setAudienceContactsImportInfo(res.data.signalRModel);

          setTimeout(() => {
            setSaveButtonText("Save");
            setDisableSaveButton(false);
            setContactsImported(true);
          }, 500);
        })
        .catch((error) => {})
        .finally(() => {});
    }
  };

  const downloadInvalidContactsCSV = (audienceName) => {
    let data = {
      csvString: invalidContactsCSVString,
    };
    api(API.audience.exportInvalidAudienceContacts, data, "blob")
      .then((res) => {
        fileDownload(audienceName + "-InvalidContacts.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  let schema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  if (page === 2) {
    schema = Yup.object().shape({
      csvMappedColumns: Yup.object({
        emailAddress: Yup.string().required("Required"),
      }),
    });
  }

  if (page === 4) {
    schema = Yup.object({
      name: Yup.string(),
    });
  }

  return (
    <Formik
      initialValues={{
        audienceId: props?.audience?.id || 0,
        name: props?.audience?.name || "",
        csvFile: "",
        csvFileLink: "",
        csvFileColumns: "",
        csvFileCustomColumns: "",
        csvMappedColumns: {
          firstName: "",
          lastName: "",
          jobTitle: "",
          emailAddress: "",
          contactCountry: "",
          company: "",
          companyWebsite: "",
          companyIndustry: "",
          companySize: "",
        },
        campaignId: props.audience?.campaignId || 0,
        csvCustomMappedColumns: [],
      }}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        if (page === 1) {
          let fileIsEmpty = values.csvFileLink === "";

          if (values.audienceId === 0) {
            api(
              formUrl(API.audience.checkIfAudienceNameExists, {
                audienceName: values.name,
                audienceId: 0,
              })
            )
              .then((response) => {
                let nameExists = checkIfNameExists(values.name);

                if (fileIsEmpty) setIsFileError(true);

                if (nameExists && values.audienceId === 0) {
                  actions.setFieldError("name", "This List Name already exists");
                }

                if (fileIsEmpty === false && nameExists === false) nextPageHandler();
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);
                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                } else {
                  toast.error(error.message);
                }
              });
          } else {
            if (fileIsEmpty) setIsFileError(true);

            if (fileIsEmpty === false) nextPageHandler();
          }

          setIsSubmitting(false);
        }
        if (page === 2) {
          nextPageHandler();
          setIsSubmitting(false);
          setSaveButtonText("Import");
        }
        if (page === 3) {
          setIsSubmitting(true);
          nextPageHandler();
          importAudienceContacts(values, actions);
          setDisableSaveButton(true);
          setSaveButtonText("Importing");
        }
        if (page === 4) {
          if (contactsImported) {
            actions.resetForm();
            setIsSubmitting(false);
            setDisableSaveButton(false);

            if (props.campaignConfigCampaignId !== undefined && props.campaignConfigCampaignId > 0) {
              let campaignIds = [];
              campaignIds.push(props.campaignConfigCampaignId);

              var data = {
                audienceId: values.audienceId,
                campaignIds: campaignIds,
                dontUnassignCampaigns: true,
              };

              setAudienceCampaigns(data)
                .unwrap()
                .then((response) => {
                  toast.success(toastMessages.audience.setAudienceImportSettings.importStarted);
                  props.handleClose();
                  actions.resetForm();
                  if (props.getAudiences) props.getAudiences();
                })
                .catch((error) => {
                  const errorList = errorResponseToList(error);
                  if (errorList.length > 0) {
                    errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                  } else {
                    toast.error(error.message);
                  }
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }

            handleGoBack(true);
          } else {
            setIsSubmitting(false);
            setDisableSaveButton(false);
          }
        }
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, errors, setFieldValue } = formikProps;
        switch (page) {
          case 1:
            renderPage = (
              <Row className="pB10">
                <FirstStep
                  onClick={handleSubmit}
                  audienceName={"name"}
                  audienceValue={values.name}
                  csvFileLink={"csvFileLink"}
                  formikProps={formikProps}
                  listAudiences={props.listAudiences}
                  isFileError={isFileError}
                  setIsFileError={setIsFileError}
                  CSVFileName={CSVFileName}
                  showButton={showButton}
                  percentCompleted={percentCompleted}
                  CSVLimitExceeded={CSVLimitExceeded}
                  unexpectedCSVError={unexpectedCSVError}
                  wrongTypeError={wrongTypeError}
                  setCSVFileName={setCSVFileName}
                  setShowButton={setShowButton}
                  setPercentCompleted={setPercentCompleted}
                  setCSVLimitExceeded={setCSVLimitExceeded}
                  setUnexpectedCSVError={setUnexpectedCSVError}
                  setWrongTypeError={setWrongTypeError}
                  setCustomBodyClass={() => setCustomBodyClass(styles.customBodyClassWithHeight)}
                  setIsSubmitting={setIsSubmitting}
                  audienceId = {props.audience?.id}
                />
              </Row>
            );
            break;
          case 2:
            renderPage = (
              <Row className="pB10">
                <SecondStep
                  click={handleSubmit}
                  formikProps={formikProps}
                  fieldsMatched={fieldsMatched}
                  setFieldsMatched={setFieldsMatched}
                  fieldsMatchedDictionary={fieldsMatchedDictionary}
                  setFieldsMatchedDictionary={setFieldsMatchedDictionary}
                />
              </Row>
            );
            break;
          case 3:
            renderPage = (
              <Row className="pB10">
                <ThirdStep click={handleSubmit} formikProps={formikProps} />
              </Row>
            );
            break;
          case 4:
            renderPage = (
              <Row className="pB10">
                <FourthStep
                  click={handleSubmit}
                  csvMappedColumns={values.csvMappedColumns}
                  audienceId={values.audienceId}
                  audienceName={values.name}
                  formikProps={formikProps}
                  contactsImported={contactsImported}
                  setContactsImported={setContactsImported}
                  setSaveButtonText={setSaveButtonText}
                  setDisableSaveButton={setDisableSaveButton}
                  CSVFileName={CSVFileName}
                  audienceContactsImportInfo={audienceContactsImportInfo}
                  setAudienceContactsImportInfo={setAudienceContactsImportInfo}
                  invalidContactsCSVString={invalidContactsCSVString}
                  downloadInvalidContactsCSV={downloadInvalidContactsCSV}
                  setSignalRConnectionId={setSignalRConnectionId}
                />
              </Row>
            );
            break;
          default:
            break;
        }
        return (
          <Modal
            title={props.audience?.id === undefined ? "Create New List" : "Upload file to existing List"}
            handleHideModal={() => handleHideModal()}
            handleClose={() => {
              handleGoBack();
              formikProps.resetForm();
            }}
            handleSave={handleSubmit}
            idSaveButton="saveButton"
            idCloseButton="idCloseButton"
            show={props.showModal}
            closeButtonText={page === 1 ? "Cancel" : "Back"}
            oneButton={page < 4 ? false : true}
            saveButtonText={saveButtonText}
            saveButtonDisabled={(page === 1 && (isFileError || !values.csvFile)) || (page === 4 ? disableSaveButton : isSubmitting)}
            btnType="submit"
            customBodyClass={customBodyClass}
          >
            <Form>
              <ProgressBar
                pageClass={`${isFileError && styles.notActive}`}
                audienceId={props.audience?.id}
                pageActive={page}
                pageOneClicked={() => pageHandler(1, handleSubmit)}
                pageTwoClicked={() => pageHandler(2, handleSubmit)}
                pageThreeClicked={() => pageHandler(3, handleSubmit)}
                pageFourClicked={() => pageHandler(4, handleSubmit)}
              />
              <div>{renderPage}</div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AudienceUploadForm;
