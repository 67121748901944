import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, FormGroup, InputGroup } from "react-bootstrap";
import { useField, FieldInputProps, FieldMetaProps, useFormikContext } from "formik";
import styles from "./FormikInput.module.scss";
import EyeLineIcon from "remixicon-react/EyeLineIcon";
import { inputFieldSymbolPlaceEnum } from "@helper/enums/inputFieldSymbolPlaceEnum";

interface FormikInputProps {
  label?: string;
  id?: string;
  name?: string;
  type?: string;
  rows?: number;
  placeholder?: string;
  value?: any;
  inputclass?: string;
  //onEditorChange?: (value: string) => void;
  readOnly?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (value: any) => void;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  labelPosition?: string;
  formgroupclass?: string;
  labelclass?: string;
  errorinputclass?: string;
  hideEye?: boolean;
  inputGroupClass?: string;
  inputSymbol?: string;
  inputSymbolPosition?: number;
  allowNegative?: boolean;
}

const FormikInput: React.FC<FormikInputProps> = (props) => {
  const [field, meta] = useField({ ...props, name: props.name || "" });
  const { setFieldValue } = useFormikContext();
  const [value, setValue] = useState(props.value ?? "");

  const [passVisible, setPassVisible] = useState(false);

  let inputElement = null;
  let labelElement = null;
  let errorElement = null;

  let errorInput = null;

  let formgroupclass = styles.group + " " + props.inputGroupClass;

  const changeHandler = (event: any) => {
    if (event?.currentTarget?.value != null && event?.currentTarget?.value != undefined)
      setFieldValue(field.name, event.currentTarget.value);

    if (props.onChange != null) {
      props.onChange(event);
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  if (props.labelPosition != null) {
    formgroupclass += " " + styles[props.labelPosition];
  }

  if (props.formgroupclass != null) {
    formgroupclass += " " + props.formgroupclass;
  }

  if (props.label) {
    labelElement = (
      <FormLabel className={props.labelclass + " " + styles.label} htmlFor={props.id || props.name}>
        {props.label}
      </FormLabel>
    );
  }

  if (meta.touched && meta.error) {
    errorElement = <div className={props.errorinputclass + " " + styles.error}>{meta.error}</div>;
    errorInput = styles.errorInput;
  }

  const type = props.type != null ? props.type : "text";

  const handleNumericChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const regex = props.allowNegative ? /[^0-9.-]/g : /[^0-9.]/g;
    const sanitizedValue = value.replace(regex, "");
    const validValue = props.allowNegative && sanitizedValue.startsWith("-") ? "-" + sanitizedValue.replace(/-/g, "") : sanitizedValue;
    setFieldValue(field.name, validValue);

    if (props.onChange) {
      props.onChange(validValue);
    }
  };

  const handleBlur = () => {
    const currentValue = parseFloat(field.value);
    if (!isNaN(currentValue)) {
      setFieldValue(field.name, currentValue);
    } else {
      setFieldValue(field.name, "");
    }
  };

  switch (type) {
    case "text":
      inputElement = (
        <FormControl
          rows={props.rows}
          type="text"
          {...field}
          placeholder={props.placeholder}
          {...props}
          value={props.value}
          onChange={changeHandler}
          className={styles.input + " " + props.inputclass + " " + errorInput}
          // onEditorChange={props.onEditorChange}
          readOnly={props.readOnly}
          autoComplete="off"
          aria-label={props.ariaLabel ? props.ariaLabel : ""}
          id={(props.id || props.name?.replace(/\./g, "")) ?? ""}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <FormControl
          id={(props.id || props.name?.replace(/\./g, "")) ?? ""}
          as="textarea"
          rows={props.rows}
          type="text"
          {...field}
          placeholder={props.placeholder}
          {...props}
          value={props.value}
          onChange={changeHandler}
          className={styles.input + " " + props.inputclass + " " + errorInput}
          // onEditorChange={props.onEditorChange}
          readOnly={props.readOnly}
          autoComplete="off"
          aria-label={props.ariaLabel ? props.ariaLabel : ""}
        />
      );
      break;
    case "password":
      inputElement = (
        <InputGroup>
          <FormControl
            {...props}
            type={passVisible ? "text" : "password"}
            disabled={props.disabled}
            {...field}
            placeholder={props.placeholder}
            value={props.value}
            className={styles.input + " " + props.inputclass + " " + errorInput}
            readOnly={props.readOnly}
            onBlur={props.onBlur}
            id={
              props.id
                ? props.id
                : props.name && typeof props.name === "string"
                ? props.name.replace(/ /g, "").replace(/[^a-zA-Z0-9-_]/g, "_")
                : undefined
            }
            autoComplete="off"
          />

          {props.hideEye != null && props.hideEye === true ? null : (
            <EyeLineIcon size={14} className={`iconGray ${styles.showPasswordIcon}`} onClick={() => setPassVisible(!passVisible)} />
          )}
        </InputGroup>
      );
      break;
    case "numberWithSymbol":
      inputElement = (
        <div className={styles.formControlSymbolWrapper}>
          {props.inputSymbolPosition === inputFieldSymbolPlaceEnum.Prefix && props.inputSymbol && (
            <span className={styles.prefixSymbol}>{props.inputSymbol}</span>
          )}
          <FormControl
            id={(props.id || props.name?.replace(/\./g, "")) ?? ""}
            rows={props.rows}
            type="number"
            {...field}
            placeholder={props.placeholder}
            onChange={handleNumericChange}
            onBlur={handleBlur}
            {...props}
            value={props.value ?? 0}
            className={
              styles.input +
              " " +
              props.inputclass +
              " " +
              errorInput +
              (props.inputSymbolPosition === inputFieldSymbolPlaceEnum.Prefix ? " " + styles.inputWithPrefix : "")
            }
            // onEditorChange={props.onEditorChange}
            readOnly={props.readOnly}
            autoComplete="off"
            aria-label={props.ariaLabel ? props.ariaLabel : ""}
          />
          {props.inputSymbolPosition === inputFieldSymbolPlaceEnum.Suffix && props.inputSymbol && (
            <span className={styles.suffixSymbol}>{props.inputSymbol}</span>
          )}
        </div>
      );
      break;
    default:
      inputElement = (
        <FormControl
          id={(props.id || props.name?.replace(/\./g, "")) ?? ""}
          {...props}
          type={type}
          disabled={props.disabled}
          {...field}
          placeholder={props.placeholder}
          value={props.value}
          className={styles.input + " " + props.inputclass + " " + errorInput}
          readOnly={props.readOnly}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onChange={changeHandler}
          autoComplete="off"
          aria-label={props.ariaLabel ? props.ariaLabel : ""}
          title={props.value}
        />
      );
  }

  if (props.append != null || props.prepend != null) {
    inputElement = (
      <InputGroup className={styles.inputGroup}>
        {props.prepend != null ? (
          <InputGroup.Text id="prepend" className={styles.inputGroupAppend}>
            {props.prepend}
          </InputGroup.Text>
        ) : null}
        {inputElement}
        {props.append != null ? (
          <InputGroup.Text id="append" className={styles.inputGroupAppend}>
            {props.append}
          </InputGroup.Text>
        ) : null}
      </InputGroup>
    );
  }

  let result = (
    <FormGroup id={props.id || undefined} className={formgroupclass}>
      {labelElement}
      {inputElement}
      {errorElement}
    </FormGroup>
  );

  return result;
};

export default FormikInput;
