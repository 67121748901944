import React from "react";

//Styles
import styles from "./Steps.module.scss";

//Components
import AlertNotification from "../../../../components/UI/AlertNotification/AlertNotification";

const UploadErrorMessages = (props) => {
  if (props.CSVLimitExceeded) {
    return (
      <AlertNotification bodyWrapperClass="width_100" bodyClassName="flex align-center" variant="danger_alert">
        <p className={`f12 ${styles.errorMessage}`}>
          {props.errorMessage ? (
            <span>{props.errorMessage}</span>
          ) : (
            <span>Please upload a file that has list size with 10000 rows or less.</span>
          )}
        </p>
      </AlertNotification>
    );
  }

  if (props.unexpectedCSVError) {
    return (
      <AlertNotification bodyWrapperClass="width_100" bodyClassName="flex align-center" variant="danger_alert">
        <p className={`f12 ${styles.errorMessage}`}>
          Something went wrong while uploading your file. Please try again or contact our{" "}
          <a className="f12" href="mailto:support@outbase.com">
            support team{" "}
          </a>
          for further assistance.
        </p>
      </AlertNotification>
    );
  }

  if (props.wrongTypeError) {
    return (
      <AlertNotification bodyWrapperClass="width_100" bodyClassName="flex align-center" variant="danger_alert">
        <p className={`f12 ${styles.errorMessage}`}>Please upload a CSV file.</p>
      </AlertNotification>
    );
  }

  return null;
};

export default UploadErrorMessages;
