import React, { useContext, useEffect, useState } from "react";
import Action from "../Action";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";

//Styles
import styles from "../Action.module.scss";

//Helpers
import api, { API, formUrl } from "../../../../helper/api/api";

//Icons
import LoginBoxLineIcon from "remixicon-react/LoginBoxLineIcon";

const ActionImpersonate = (props) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (props.user && props.user.isImpersonating === true && props.organizationId) {
      props.history.push("/" + props.organizationId + "/dashboard");
    }
  }, [props.user]);

  const impersonateUser = () => {
    if (props.user && props.user.id === props.userId && props.organizationId) {
      props.history.push("/" + props.organizationId + "/dashboard");
    } else {
      setButtonDisabled(true);
      let data = {
        impersonateUserId: props.userId,
      };

      api(formUrl(API.impersonate.impersonateUser, data))
        .then((res) => {
          setButtonDisabled(false);
          props.history.push("/impersonate");
        })
        .catch((error) => {
          setButtonDisabled(false);
        });
    }
  };

  return (
    <Action
      className={props.className}
      onClick={impersonateUser}
      disabled={buttonDisabled}
      materialIcon={
        <OverlayTrigger
          key={"log-in"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-log-in`}>
              <Popover.Body>Login as user</Popover.Body>
            </Popover>
          }
        >
          <div className={styles.loginAsUserOrToOrganization}>
            <LoginBoxLineIcon size={16} className="iconGray" />{" "}
          </div>
        </OverlayTrigger>
      }
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    newUser: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionImpersonate);
