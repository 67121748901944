import { audienceExplorerApi } from "@api/audienceExplorerApi";
import { DropdownModel } from "@models/DropDownModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ViewMode {
  Companies,
  Contacts,
  Split,
}

export interface ExplorerState {
  excludedContacts: DropdownModel[];
  peopleCount: number;
  viewMode: ViewMode;
}

const initialState: ExplorerState = {
  excludedContacts: [],
  peopleCount: 0,
  viewMode: ViewMode.Split,
};

export const audienceExplorerSlice = createSlice({
  name: "audienceExplorer",
  initialState,
  reducers: {
    toggleContactExcluded: (state, action: PayloadAction<DropdownModel>) => {
      const index = state.excludedContacts.findIndex((c) => c.value === action.payload.value);

      if (index !== -1) {
        state.excludedContacts.splice(index, 1);
      } else {
        state.excludedContacts.push(action.payload);
      }
    },
    resetExcludedContacts: (state) => {
      state.excludedContacts = [];
    },
    clickCompaniesArrow: (state) => {
      // If split, expand the Companies view
      if (state.viewMode === ViewMode.Split) {
        state.viewMode = ViewMode.Companies;
      }
      // If not split (Contacts or Companies), switch to split
      else {
        state.viewMode = ViewMode.Split;
      }
    },
    clickContactsArrow: (state) => {
      // If split, expand the Companies view
      if (state.viewMode === ViewMode.Split) {
        state.viewMode = ViewMode.Contacts;
      }
      // If not split (Contacts or Companies), switch to split
      else {
        state.viewMode = ViewMode.Split;
      }
    },
    showSplit: (state) => {
      state.viewMode = ViewMode.Split;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(audienceExplorerApi.endpoints.getContacts.matchFulfilled, (state, action) => {
      state.peopleCount = action.payload.totalCount;
    });
  },
});

export default audienceExplorerSlice;
