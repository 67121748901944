import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  listOfAdvanceTests: [],
  sendStatus: 0,
  isLoadingTestDetails: false,
  isLoadedTestDetails: true,
  errorTestDetails: "",
  advanceTestDetails: {},
  dictionary: {},
};

const fetchListOfAdvanceEmailTestsRequest = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = false;
  newState.isLoading = true;
  newState.listOfAdvanceTests = [];
  newState.advanceTestDetails = {};
  return newState;
};

const fetchListOfAdvanceEmailTestsSuccess = (state, action) => {
  const newState = { ...state };
  let list = [...state.listOfAdvanceTests];
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listOfAdvanceTests = list;
  return newState;
};

const fetchListOfAdvanceEmailTestsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  newState.listOfAdvanceTests = [];
  newState.advanceTestDetails = {};
  return newState;
};

const fetchAdvanceEmailSendTestRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchAdvanceEmailSendTestSuccess = (state, action) => {
  const newState = { ...state };
  let sendStatus = state.sendStatus;
  sendStatus = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.sendStatus = sendStatus;
  return newState;
};

const fetchAdvanceEmailSendTestFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchAdvanceEmailTestDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoadingTestDetails: true,
    isLoadedTestDetails: false,
    advanceTestDetails: {},
  };
};

const fetchAdvanceEmailTestDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let advanceTestDetails = { ...state.advanceTestDetails };
  advanceTestDetails = action.result;
  newState.isLoadedTestDetails = true;
  newState.isLoadingTestDetails = false;
  newState.advanceTestDetails = advanceTestDetails;
  return newState;
};

const fetchAdvanceEmailTestDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.errorTestDetails = action.result;
  newState.isLoadingTestDetails = false;
  newState.isLoadedTestDetails = true;
  newState.advanceTestDetails = {};
  return newState;
};

const setDictionaryValues = (state, action) => {
  const newState = { ...state };
  let dictionary = { ...state.dictionary };
  dictionary = action.result;
  newState.dictionary = dictionary;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADVANCE_EMAIL_TEST_DETAILS_REQUEST:
      return fetchAdvanceEmailTestDetailsRequest(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_TEST_DETAILS_SUCCEEDED:
      return fetchAdvanceEmailTestDetailsSuccess(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_TEST_DETAILS_FAILED:
      return fetchAdvanceEmailTestDetailsFailed(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_SEND_TEST_REQUEST:
      return fetchAdvanceEmailSendTestRequest(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_SEND_TEST_SUCCEEDED:
      return fetchAdvanceEmailSendTestSuccess(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_SEND_TEST_FAILED:
      return fetchAdvanceEmailSendTestFailed(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_TEST_LIST_REQUEST:
      return fetchListOfAdvanceEmailTestsRequest(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_TEST_LIST_SUCCEEDED:
      return fetchListOfAdvanceEmailTestsSuccess(state, action);
    case actionTypes.FETCH_ADVANCE_EMAIL_TEST_LIST_FAILED:
      return fetchListOfAdvanceEmailTestsFailed(state, action);
    case actionTypes.SET_DICTIONARY_VALUES:
      return setDictionaryValues(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
