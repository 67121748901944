import React from "react";
import svg from "./exclamation_mark_icon.svg";

const IconExclamationMark = (props) => (
  <svg className={props.imgStyle} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5601 15.2999L13.8901 2.57994C13.58 2.09475 13.1527 1.69546 12.6476 1.41888C12.1425 1.14229 11.5759 0.997314 11.0001 0.997314C10.4243 0.997314 9.85769 1.14229 9.35262 1.41888C8.84755 1.69546 8.42024 2.09475 8.1101 2.57994L0.4401 15.2999C0.169154 15.7516 0.021889 16.2666 0.013112 16.7932C0.00433502 17.3199 0.134355 17.8395 0.3901 18.2999C0.685779 18.8182 1.11376 19.2487 1.6303 19.5474C2.14684 19.846 2.73343 20.0022 3.3301 19.9999H18.6701C19.2629 20.0063 19.8468 19.8561 20.363 19.5647C20.8792 19.2732 21.3093 18.8508 21.6101 18.3399C21.8734 17.8747 22.0075 17.3476 21.9987 16.8131C21.9899 16.2787 21.8385 15.7563 21.5601 15.2999ZM19.8601 17.3499C19.7399 17.5565 19.5655 17.7263 19.3557 17.8409C19.146 17.9555 18.9089 18.0105 18.6701 17.9999H3.3301C3.09132 18.0105 2.85422 17.9555 2.64446 17.8409C2.4347 17.7263 2.26029 17.5565 2.1401 17.3499C2.05233 17.1979 2.00613 17.0255 2.00613 16.8499C2.00613 16.6744 2.05233 16.502 2.1401 16.3499L9.8201 3.61994C9.9581 3.43764 10.1365 3.2898 10.3412 3.18801C10.5459 3.08622 10.7715 3.03325 11.0001 3.03325C11.2287 3.03325 11.4543 3.08622 11.659 3.18801C11.8637 3.2898 12.0421 3.43764 12.1801 3.61994L19.8501 16.3399C19.9413 16.4923 19.9902 16.6661 19.992 16.8436C19.9937 17.0211 19.9482 17.1959 19.8601 17.3499Z"
      fill="#EE3030"
    />
    <path
      d="M11 16C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16Z"
      fill="#EE3030"
    />
    <path
      d="M11 7C10.7348 7 10.4804 7.10536 10.2929 7.29289C10.1054 7.48043 10 7.73478 10 8V12C10 12.2652 10.1054 12.5196 10.2929 12.7071C10.4804 12.8946 10.7348 13 11 13C11.2652 13 11.5196 12.8946 11.7071 12.7071C11.8946 12.5196 12 12.2652 12 12V8C12 7.73478 11.8946 7.48043 11.7071 7.29289C11.5196 7.10536 11.2652 7 11 7Z"
      fill="#EE3030"
    />
  </svg>
);

export default IconExclamationMark;
