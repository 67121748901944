import FormikTags from "@ui/Formik/FormikTags";

const FilterContactName = () => {
  return (
    <FormikTags
      id="names"
      placeholder="e.g. Margaret"
      name="contact.names"
      content={
        <p>
          Press <b>enter</b> to add a name.
        </p>
      }
    />
  );
};

export default FilterContactName;
