import React, { useEffect, useState } from "react";
import Moment from "moment";
import _ from "lodash";
import { useSlice } from "@hooks/useSlice";
import { useDataTable } from "@hooks/useDataTable";
import { templatesDataTable } from "@store/registerDataTables";
import { useGetTemplatesQuery } from "@api/emailTemplateApi";
import DataTable from "@components/DataTable/DataTable";
import { numberOfRecords } from "../../../../../helper/tableConstants";
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";
import styles from "./TemplatesTable.module.scss";
import { columns } from "./columns";
import SkeletonTable from "../../../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import StatusColumn from "../../../../EmailTemplates/Templates/StatusColumn/StatusColumn";
import useRouteParams from "@hooks/useRouteParams";
import { TemplateRowModel } from "@models/emailTemplate/TemplateRowModel";

const TemplatesTable: React.FC = () => {
  const dateFormat = useOrgDateFormat();
  let table: React.ReactNode = null;
  const [tableRows, setTableRows] = useState<TemplateRowModel[]>([]);

  const { campaignId } = useRouteParams();

  const templatesState = useSlice((state) => state.templatesDataTable);
  const dataTable = useDataTable({
    slice: templatesState,
    dtActions: templatesDataTable.actions,
    useQuery: useGetTemplatesQuery,
    filterTypes: ["campaignSettings"],
    useWithQuery: false,
    autoInitiate: false,
  });
  const {
    state: { rows },
    actions: { setFilter, clearFilter, initiate },
    query,
  } = dataTable;

  useEffect(() => {
    const params = {
      length: numberOfRecords[1].value,
      sort: { column: "[order]", direction: "asc" },
    };
    initiate(params);

    return () => {
      clearFilter("campaignSettings");
    };
  }, []);

  useEffect(() => {
    if (campaignId) {
      setFilter("campaignSettings", campaignId);
    }
  }, [campaignId]);

  useEffect(() => {
    const rowsTemp: TemplateRowModel[] = _.cloneDeep(rows).map((r) => {
      const newRow: TemplateRowModel = {
        ...r,
        dateCreated: <span>{Moment(r.dateCreated).format(dateFormat)}</span>,
        lastUsed: r.lastUsed != null && r.lastUsed !== "" ? <span>{Moment(r.lastUsed).format(dateFormat)}</span> : "-",
        openRate: r.openRate.toFixed(2),
        clickRate: r.clickRate.toFixed(2),
        leadRate: r.leadRate.toFixed(2),
        status: <StatusColumn status={r.status} />,
      };
      return newRow;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  if (query.error) {
    table = <h2>Error getting Email Templates</h2>;
  }

  if (query.isLoading) {
    table = <SkeletonTable />;
  }

  if (query.isSuccess && tableRows.length > 0) {
    table = (
      <DataTable
        rows={tableRows}
        columns={columns}
        dataTable={dataTable}
        tableClassName={`${styles.campaignTable}`}
        scrollY={true}
        variant="large"
        showPagination={false}
      />
    );
  }

  return <div>{table}</div>;
};

export default TemplatesTable;
