import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../../helper/api/api";

export const fetchUserDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_REQUEST,
  };
};

export const fetchUserDetailsSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_SUCCESS,
    result: settings,
  };
};

export const fetchUserDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_USER_DETAILS_FAILED,
    result: error,
  };
};

export const fetchUserDetails = (userId) => {
  return (dispatch) => {
    dispatch(fetchUserDetailsRequest());
    let data = {
      userId: userId,
    };
    return api(formUrl(API.OUTBOSS.users.getUserDetails, data))
      .then((response) => {
        dispatch(fetchUserDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserDetailsFailed(error.message));
      });
  };
};
