import React, { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import axios from "../../../../axios";
import * as Yup from "yup";
import { useAuth } from "react-oidc-context";

//Helpers
import { PASSWORD_CHECK } from "../../../../helper/validationConstants";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { toastMessages } from "../../../../helper/toastMessagesConstants";

// Style
import styles from "./Password.module.scss";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import FormikEffect from "../../../../components/UI/Formik/FormikEffect";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import SaveChangesFooter from "../../../../components/SaveChangesFooter/SaveChangesFooter";

const Password = (props) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [hasEightCharacters, setHasEightCharacters] = useState(false);
  const [hasOneLower, setHasOneLower] = useState(false);
  const [hasOneUpper, setHasOneUpper] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);

  const auth = useAuth();

  const onChange = (values) => {
    setHasChanges(true);
    let charArray = [...values.newPassword];
    let oneLower = charArray.filter((c) => c.toLowerCase() === c && /[a-zA-Z]/.test(c));
    let oneUpper = charArray.filter((c) => c.toUpperCase() === c && /[a-zA-Z]/.test(c));
    let special = charArray.filter((c) => /[0-9|\s]/.test(c) === true);

    charArray.length >= 8 ? setHasEightCharacters(true) : setHasEightCharacters(false);
    oneLower.length > 0 ? setHasOneLower(true) : setHasOneLower(false);
    oneUpper.length > 0 ? setHasOneUpper(true) : setHasOneUpper(false);
    special.length > 0 ? setHasSpecial(true) : setHasSpecial(false);

    props.setUpdatePassword(values);
  };
  return (
    <div className={styles.formWrapper}>
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          currentPassword: Yup.string().required("Required"),
          newPassword: Yup.string()
            .required("Required")
            .matches(PASSWORD_CHECK, "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"),
          confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
        })}
        onSubmit={(values, actions) => {
          return axios
            .post("account/changepassword", values)
            .then((res) => {
              toast.success(toastMessages.settings.successChangePassword);
              auth.signinSilent();
              setHasChanges(false);
              // props.handlePassClose();
            })
            .catch((err) => {
              const errorList = errorResponseToList(err);
              if (errorList.length > 0) {
                errorList.forEach((err) => toast.error(err.message));
              } else {
                toast.error(err.message);
              }
            });
        }}
        onReset={() => {
          setHasChanges(false);
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, handleReset, isSubmitting } = formikProps;

          return (
            <Form>
              <FormikEffect onChange={() => onChange(values)} />
              <FormikInput label="Current Password" name="currentPassword" type={"password"} placeholder="Type Here" />

              <FormikInput label="New Password" name="newPassword" type={"password"} placeholder="Type Here" />
              {/* <FormikInput
                                label='Confirm Password'
                                name='confirmPassword'
                                type='password'
                                placeholder='Type Here'
                            /> */}
              <Container className={styles.general}>
                <Checkbox label="At least 8 characters long" checked={hasEightCharacters} disabled={true} />
                <Checkbox label="One lowercase letter" checked={hasOneLower} disabled={true} />
                <Checkbox label="One uppercase letter" checked={hasOneUpper} disabled={true} />
                <Checkbox label="One number or space" checked={hasSpecial} disabled={true} />

                <Row>
                  <Col className={styles.changesFooter} xl={12} lg={12} sm={12} xs={12}>
                    {hasChanges ? (
                      <SaveChangesFooter
                        cancelClick={handleReset}
                        saveChangesClick={handleSubmit}
                        cancelButtonDisabled={isSubmitting}
                        saveButtonDisabled={isSubmitting}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Password;
