import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";

//Styles
import styles from "./Audiences.module.scss";

//Helpers
import api, { API } from "../../helper/api/api";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { errorResponseToList } from "../../helper/errorHelper";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";

//Components
import Modal from "../../components/UI/Modal/Modal";
import FormikSelect from "../../components/UI/Formik/FormikSelect";
import Spinner from "../../components/UI/Spinner/Spinner";

const AudiencePushToCampaignForm = (props) => {
  const { data: campaignsList, isLoading: campaignsIsLoading } = useSelectList(SelectListEnum.UnarchivedCampaignsList);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);

  const { showModal, audience, handleClose, getAudiences } = props;

  useEffect(() => {
    if (showModal) {
      setSelectedCampaigns(audience?.campaignIds ? [...audience.campaignIds] : []);
    }
  }, [audience, showModal]);

  return (
    <Formik
      initialValues={{
        campaignId: audience?.campaignIds ? audience.campaignIds : [],
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        setIsLoading(true);
        actions.setSubmitting(true);

        const data = {
          audienceId: audience.id,
          campaignId: values.campaignId,
        };

        api(API.audience.setAudienceImportSettings, data)
          .then((response) => {
            toast.success(toastMessages.audience.listUpdated);
            handleClose();
            actions.resetForm();
            getAudiences();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
            setIsLoading(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <Modal
            title={"Add to campaign"}
            handleClose={handleClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText={"I'm done"}
            saveButtonDisabled={isSubmitting || (campaignsList ? campaignsList.length === 0 : true)}
            btnType="submit"
            customBodyClass="overflow_unset max_height_none"
          >
            <Container>
              <Row className={styles.audienceEditForm}>
                <p className="mB10">
                  <b>List name: </b>
                  {audience.name}
                </p>
                <div className={styles.selectDiv}>
                  {campaignsIsLoading ? (
                    <Spinner />
                  ) : (
                    <FormikSelect
                      label="Select a campaign/s"
                      placeholder={"Campaign/s"}
                      name="campaignId"
                      value={selectedCampaigns}
                      showBorder={true}
                      options={campaignsList}
                      isMulti={true}
                      isSearchable={true}
                      isMenuPortalTargetDisabled={true}
                      enableReinitialize={true}
                      showValuesOutside={true}
                    />
                  )}
                </div>
                {isLoading ? (
                  <Col xl={12}>
                    <Spinner />
                  </Col>
                ) : null}
              </Row>
            </Container>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AudiencePushToCampaignForm;
