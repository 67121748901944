import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  globalSettings: {},
};

const fetchGlobalSettingsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchGlobalSettingsSuccess = (state, action) => {
  const newState = { ...state };
  let settings = { ...state.globalSettings };
  settings = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.globalSettings = settings;
  return newState;
};

const fetchGlobalSettingsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GLOBAL_SETTINGS_REQUEST:
      return fetchGlobalSettingsRequest(state, action);
    case actionTypes.FETCH_GLOBAL_SETTINGS_SUCCESS:
      return fetchGlobalSettingsSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_SETTINGS_FAILED:
      return fetchGlobalSettingsFailed(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
