import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Slider } from "@mui/material";

const StyledSlider = styled(Slider)({
  color: "#CACCD1",
  width: "296px",
  border: 0,
  borderRadius: 3,
  padding: "0px",
  height: 4,
  opacity: 1,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    fontSize: 14,
    backgroundColor: "#FE7904",
    border: "5px solid currentColor",
    marginTop: 0,
    marginLeft: 0,
    color: "#fff",
    padding: 0,
    cursor: "pointer",
    boxShadow: "#ebebeb 0px 2px 2px",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "#ccc 0px 2px 3px 1px",
    },
  },
  "& .MuiSlider-valueLabel": {
    left: "-calc(-50% + 4px)",
  },
  "& .MuiSlider-track": {
    height: 4,
    backgroundColor: "#FE7904",
    borderRadius: "100px",
  },
  "& .MuiSlider-rail": {
    height: 4,
    borderRadius: "100px",
    backgroundColor: "#FE7904",
  },
});

const RangeSlider = (props) => {
  const [value, setValue] = useState([props.initialValue]);

  useEffect(() => {
    if (props.initialValue !== value) {
      setValue(props.initialValue);
    }
  }, [props.initialValue]);

  const handleChange = (_event, newValue) => {
    setValue(newValue);

    if (props.onChange != null) {
      props.onChange(newValue);
    }
  };

  return <StyledSlider value={value} min={0} max={100} onChange={handleChange} aria-labelledby="range-slider" />;
};

export default RangeSlider;
