import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  pricingPlanIsLoading: false,
  pricingPlanIsLoaded: false,
  error: "",
  creditInfo: {},
  billingInfo: {},
  creditCardInfo: {},
  pricingPlans: {},
  countryStateInfoIsLoading: false,
  countryStateInfoIsLoaded: false,
  countryStateInfoError: "",
  countryWithStates: [],
  selectListCountryWithStates: [],
  selectListStates: [],
  contractStatus: "",
  waitingDowngrade: false,
  nextBillingDate: "",
  nextPlanName: "",
  inValidPayment: "",
  planInfoCheckout: {},
  isCouponUsed: false,
  organizationContractUrl: ""
};

const fetchCreditInfoRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchCreditInfoSuccess = (state, action) => {
  const newState = { ...state };
  let newCreditInfo = { ...newState.creditInfo };
  newCreditInfo = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.creditInfo = newCreditInfo;
  return newState;
};

const updateCurrentCredits = (state, action) => {
  const newState = { ...state };
  let newCreditInfo = { ...newState.creditInfo };
  newCreditInfo.currentCredits = action.result;
  newState.creditInfo = newCreditInfo;

  return newState;
};

const fetchCreditInfoFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchBillingInfoRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchBillingInfoSuccess = (state, action) => {
  const newState = { ...state };
  let newBillingInfo = { ...newState.billingInfo };
  let newCreditCardInfo = { ...newState.creditCardInfo };


  let newContractStatus = { ...newState.contractStatus };
  let newWaitingDowngrade = { ...newState.waitingDowngrade };
  let newNextBillingDate = { ...newState.nextBillingDate };
  let newNextPlanName = { ...newState.nextPlanName };
  let newInValidPayment = { ...newState.inValidPayment };
  let newIsCouponUsed = { ...newState.isCouponUsed };
  let newOrganizationContractUrl = { ...newState.organizationContractUrl };

  newBillingInfo = action.result.billingInfo;
  newCreditCardInfo = action.result.creditCardInfo;

  newContractStatus = action.result.contractStatus;
  newInValidPayment = action.result.inValidPayment;
  newWaitingDowngrade = action.result.waitingDowngrade;
  newNextBillingDate = action.result.nextBillingDate;
  newNextPlanName = action.result.nextPlanName;
  newIsCouponUsed = action.result.isCouponUsed;
  newOrganizationContractUrl = action.result.organizationContractUrl;

  newState.isLoaded = true;
  newState.isLoading = false;

  newState.billingInfo = newBillingInfo;
  newState.creditCardInfo = newCreditCardInfo;

  newState.contractStatus = newContractStatus;
  newState.waitingDowngrade = newWaitingDowngrade;
  newState.nextBillingDate = newNextBillingDate;
  newState.nextPlanName = newNextPlanName;
  newState.inValidPayment = newInValidPayment;
  newState.isCouponUsed = newIsCouponUsed;
  newState.organizationContractUrl = newOrganizationContractUrl;
  return newState;
};

const fetchBillingInfoFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchPricingPlansRequest = (state, action) => {
  return {
    ...state,
    pricingPlanIsLoading: true,
    pricingPlanIsLoaded: false,
  };
};

const fetchPricingPlansSuccess = (state, action) => {
  const newState = { ...state };
  let newPricingPlans = { ...newState.pricingPlans };
  newPricingPlans = action.result;
  newState.pricingPlanIsLoaded = true;
  newState.pricingPlanIsLoading = false;
  newState.pricingPlans = newPricingPlans;
  return newState;
};

const fetchPricingPlansFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.pricingPlanIsLoading = false;
  newState.pricingPlanIsLoaded = true;
  return newState;
};

const fetchContractStatusRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchContractStatusSuccess = (state, action) => {
  const newState = { ...state };
  let newContractStatus = { ...newState.contractStatus };
  newContractStatus = action.result;

  newState.isLoaded = true;
  newState.isLoading = false;
  newState.contractStatus = newContractStatus;

  return newState;
};

const fetchContractStatusFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchPlanInfoCheckoutRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchPlanInfoCheckoutSuccess = (state, action) => {
  const newState = { ...state };
  let newContractStatus = { ...newState.contractStatus };
  newContractStatus = action.result;

  let newPlanInfoCheckout = { ...newState.planInfoCheckout };
  newPlanInfoCheckout = action.result;
  newState.planInfoCheckout = newPlanInfoCheckout;
  newState.isLoaded = true;
  newState.isLoading = false;

  return newState;
};

const fetchPlanInfoCheckoutFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const setContractStatus = (state, action) => {
  const newState = { ...state };
  let newContractStatus = { ...newState.contractStatus };
  newContractStatus = action.result;
  newState.contractStatus = newContractStatus;
  return newState;
};

const fetchCountryStateInfoRequest = (state, action) => {
  return {
    ...state,
    countryStateInfoIsLoading: true,
    countryStateInfoIsLoaded: false,
  };
};

const fetchCountryStateInfoSuccess = (state, action) => {
  const newState = { ...state };

  let newCountryWithStates = { ...newState.countryWithStates };
  let newSelectListCountryWithStates = {
    ...newState.selectListCountryWithStates,
  };

  newCountryWithStates = action.result.countryWithStates;
  newSelectListCountryWithStates = action.result.selectListCountryWithStates;

  newState.countryWithStates = newCountryWithStates;
  newState.selectListCountryWithStates = newSelectListCountryWithStates;

  newState.countryStateInfoIsLoaded = true;
  newState.countryStateInfoIsLoading = false;

  return newState;
};

const fetchCountryStateInfoFailed = (state, action) => {
  const newState = { ...state };
  newState.countryStateInfoError = action.result;
  newState.countryStateInfoIsLoading = false;
  newState.countryStateInfoIsLoaded = true;
  return newState;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_INFO_REQUEST:
      return fetchCreditInfoRequest(state, action);
    case actionTypes.FETCH_CREDIT_INFO_SUCCEEDED:
      return fetchCreditInfoSuccess(state, action);
    case actionTypes.FETCH_CREDIT_INFO_FAILED:
      return fetchCreditInfoFailed(state, action);
    case actionTypes.UPDATE_CURRENT_CREDITS:
      return updateCurrentCredits(state, action);
    case actionTypes.FETCH_BILLING_INFO_REQUEST:
      return fetchBillingInfoRequest(state, action);
    case actionTypes.FETCH_BILLING_INFO_SUCCEEDED:
      return fetchBillingInfoSuccess(state, action);
    case actionTypes.FETCH_BILLING_INFO_FAILED:
      return fetchBillingInfoFailed(state, action);
    case actionTypes.FETCH_PRICING_PLANS_REQUEST:
      return fetchPricingPlansRequest(state, action);
    case actionTypes.FETCH_PRICING_PLANS_SUCCEEDED:
      return fetchPricingPlansSuccess(state, action);
    case actionTypes.FETCH_PRICING_PLANS_FAILED:
      return fetchPricingPlansFailed(state, action);
    case actionTypes.FETCH_CONTRACT_STATUS_REQUEST:
      return fetchContractStatusRequest(state, action);
    case actionTypes.FETCH_CONTRACT_STATUS_SUCCEEDED:
      return fetchContractStatusSuccess(state, action);
    case actionTypes.FETCH_CONTRACT_STATUS_FAILED:
      return fetchContractStatusFailed(state, action);
    case actionTypes.SET_CONTRACT_STATUS:
      return setContractStatus(state, action);
    case actionTypes.FETCH_PLAN_INFO_CHECKOUT_REQUEST:
      return fetchPlanInfoCheckoutRequest(state, action);
    case actionTypes.FETCH_PLAN_INFO_CHECKOUT_SUCCEEDED:
      return fetchPlanInfoCheckoutSuccess(state, action);
    case actionTypes.FETCH_PLAN_INFO_CHECKOUT_FAILED:
      return fetchPlanInfoCheckoutFailed(state, action);
    case actionTypes.FETCH_COUNTRY_STATE_INFO_REQUEST:
      return fetchCountryStateInfoRequest(state, action);
    case actionTypes.FETCH_COUNTRY_STATE_INFO_SUCCEEDED:
      return fetchCountryStateInfoSuccess(state, action);
    case actionTypes.FETCH_COUNTRY_STATE_INFO_FAILED:
      return fetchCountryStateInfoFailed(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
