import _ from "lodash";

export const errorResponseToList = (error) => {
  const errorList = [];
  if (error != null && error.response != null && error.response.data != null) {
    if (_.isArray(error.response.data)) {
      const errorsArray = error.response.data;
      errorsArray.forEach((errObj) => {
        Object.keys(errObj).forEach((key) => {
          errorList.push({
            message: errObj[key],
          });
        });
      });
    } else if (_.isObject(error.response.data.errors)) {
      const errors = error.response.data.errors;
      Object.keys(errors).forEach((errorField) => {
        if (_.isArray(errors[errorField])) {
          errors[errorField].forEach((message) => {
            errorList.push({
              field: _.camelCase(errorField),
              message: message,
            });
          });
        }
      });
    }
    else if (_.isObject(error.response.data)) {
      const errors = error.response.data;
      Object.keys(errors).forEach((errorField) => {
        if (_.isArray(errors[errorField])) {
          errors[errorField].forEach((message) => {
            errorList.push({
              field: _.camelCase(errorField),
              message: message,
            });
          });
        }
      });
    }
  }
  return errorList;
};
