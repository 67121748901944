import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Helpers
import { formatNumberHelper } from "../../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../../helper/hooks/useGroupDecimalSeparator";

//Images & Icons
import InformationFillIcon from "remixicon-react/InformationFillIcon";

//Styles
import styles from "./StatisticWidget.module.scss";

const StatisticWidget = (props) => {
  let separators = useGroupDecimalSeparator();
  let totalProspectsEngagedFormatted = formatNumberHelper(props.total, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  let totalEmailsDeliveredFormatted = formatNumberHelper(
    props.emailsSent,
    separators.numberGroupSeperator,
    separators.numberDecimalSeperator
  );

  return (
    <div className={styles.widgetCard}>
      <div className={styles.prospectsEngaged}>
        <div className={styles.prospectsHeaderWrapper}>
          <h1 className={styles.title}>{props.title}</h1>
          <OverlayTrigger
            key={"key-prospects-engaged"}
            placement={"top"}
            overlay={
              <Popover id={`popover-tooltip`}>
                <Popover.Body>
                  The total count of first-time emails you've sent to both new prospects and those you're reconnecting with.
                </Popover.Body>
              </Popover>
            }
          >
            <div className="max-x-content flex_center">
              <InformationFillIcon size={14} />
            </div>
          </OverlayTrigger>
        </div>
        <div className={styles.total}>
          <span>{totalProspectsEngagedFormatted}</span>
        </div>
      </div>

      <div className={`${styles.widgetBody} flex`}>
        <div className={styles.detailStatistic}>
          {props.progress !== undefined && props.progress !== "" ? (
            <div className={styles.statistic}>
              <p>In progress</p>
              <span className={styles.number}>{props.progress}</span>
            </div>
          ) : null}
          {props.completed !== undefined && props.completed !== "" ? (
            <div className={styles.statistic}>
              <p>Completed</p>
              <span className={styles.number}>{props.completed}</span>
            </div>
          ) : null}
          {props.prospect !== undefined && props.prospect !== "" ? (
            <div className={styles.statistic}>
              <p>Per Prospect</p>
              <span className={styles.number}>{props.prospect}</span>
            </div>
          ) : null}
          {props.emailsSent !== undefined && props.emailsSent !== "" ? (
            <div className={styles.statistic}>
              <div className={styles.statisticsWrapper}>
                <h1 className={styles.title}>Emails delivered</h1>
                <OverlayTrigger
                  key={"key-email-delivered"}
                  placement={"top"}
                  overlay={
                    <Popover id={`popover-tooltip`}>
                      <Popover.Body>
                        The total count of emails, including both initial and follow-up messages, that have been successfully delivered to
                        your prospects across all campaigns.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="max-x-content flex_center">
                    <InformationFillIcon size={14} />
                  </div>
                </OverlayTrigger>
              </div>
              <span className={styles.number}>{totalEmailsDeliveredFormatted}</span>
            </div>
          ) : null}
          <p className={styles.globalAvgLabel}>Global Avg. Rate</p>
        </div>
      </div>
    </div>
  );
};

export default StatisticWidget;
