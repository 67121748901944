import React, { useState, useEffect, useContext } from "react";
import Moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Redux
import { connect } from "react-redux";
import { fetchSettings } from "../../../store/OrganizationSettings/General/actions/general";
import {
  fetchPlanInfoCheckout,
  fetchBillingInfoSuccess,
  fetchCreditInfoSuccess,
  setContractStatus,
} from "../../../store/BillingAndPayment/actions/billingAndPayment";
import { updateOrganizationNumberOfUsers } from "../../../store/global/actions/user";
import { signalReceived } from "../../../store/Signal/actions/signal";

// Style
import styles from "./CreditUsage.module.scss";

// Icons
import ArrowUpCircleLineIcon from "remixicon-react/ArrowUpCircleLineIcon";

//Helper
import { useOrgNumberFormatting } from "../../../helper/hooks/useOrgNumberFormatting";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { termsAndPaymentStatusEnum } from "../../../helper/enums/contractStatusEnum";
import { pricingPlanEnum } from "../../../helper/pricingPlanHelper";
import SignalRContext from "../../../helper/SignalRContext";
import { SIGNALR_CONSTANTS } from "../../../helper/signalrConstants";

//Components
import Button from "../../UI/Button/Button";
import Permission from "../../../components/auth/Permission";
import CheckoutModal from "../../../pages/OrganizationSettings/components/BillingAndPayment/CheckoutModal/CheckoutModal";
import AddCreditCardModalWarning from "../../../pages/OrganizationSettings/components/BillingAndPayment/AddCreditCardModalWarning/AddCreditCardModalWarning";
import ProgressBarUI from "../../UI/ProgressBar/ProgressBar";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";

const CreditUsage = (props) => {
  const {
    fetchSettings,
    contractStatus,
    waitingDowngrade,
    nextBillingDate,
    nextPlanName,
    plan,
    fetchPlanInfoCheckout,
    creditCardInfo,
    billingInfo,
    planInfoCheckout,
    fetchBillingInfoSuccess,
    fetchCreditInfoSuccess,
    setContractStatus,
    updateOrganizationNumberOfUsers,
    campaignsInfo,
  } = props;

  const [text, setText] = useState("Upgrade Now");
  const [isCheckout, setIsCheckout] = useState(false);
  const [isUpgradeDisabled, setIsUpgradeDisabled] = useState(false);
  const [warningModalInfo, setWarningModalInfo] = useState({
    Title: "Processing payment",
    Content: "We are processing the payment and your information will be updated shortly",
  });
  let dateFormat = useOrgDateFormat();
  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    // if (contractStatus === termsAndPaymentStatusEnum.awaitingPaymentConfirmation) {
    //   //setText("Add Billing Info");
    //   setIsUpgradeDisabled(false);
    //   setIsCheckout(true);
    //   setWarningModalInfo({
    //     Title: "Processing payment",
    //     Content: "We are processing the payment and your information will be updated shortly",
    //   });
    // } else if (contractStatus === termsAndPaymentStatusEnum.accepted && plan === pricingPlanEnum.trial) {
    //   fetchPlanInfoCheckout();
    //   //setText("Add Billing Info");
    //   setIsUpgradeDisabled(true);
    //   setIsCheckout(true);
    //   setWarningModalInfo({
    //     Title: "Processing payment",
    //     Content: "We are processing the payment and your information will be updated shortly",
    //   });
    // } else if (waitingDowngrade) {
    //   setIsUpgradeDisabled(false);
    //   setIsCheckout(true);
    //   setWarningModalInfo({
    //     Title: "Scheduled plan change",
    //     Content:
    //       "We've received your request. Changes to your " +
    //       nextPlanName +
    //       " Plan are scheduled and will take place at the end of current billing period on " +
    //       Moment(nextBillingDate).format(dateFormat),
    //   });
    // } else {
    //   setText("Upgrade Now");
    //   setIsUpgradeDisabled(false);
    //   setIsCheckout(false);
    //   setWarningModalInfo({
    //     Title: "Processing payment",
    //     Content: "We are processing the payment and your information will be updated shortly",
    //   });
    // }

    if (plan != null) {
      // window.Intercom("update", {
      //   app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      //   email: user.email,
      //   user_id: user.id,
      //   jobTitle: user?.jobTitle,
      //   name: `${user.firstName} ${user.lastName}`,
      //   organizationId: user.organization.id,
      //   organizationName: user.organization.name,
      //   plan: plan,
      //   subscription_end_date: Moment(contractRenewal).format("DD MMM YYYY"),
      //   user_permissions: user.organization.roleName,
      //   active_campaign: campaignsInfo.active > 0 ? "Yes" : "No",
      //   active_email_account: campaignsInfo.totalConnectedEmailAccounts > 0 ? "Yes" : "No",
      //   template_order: campaignsInfo.totalPurchasedTemplates > 0 ? "Yes" : "No",
      // });
    }
  }, [contractStatus, plan, campaignsInfo.active]);

  const handlePlanChangedPayment = (data) => {
    fetchBillingInfoSuccess(data.billingInfo);
    fetchCreditInfoSuccess(data.creditInfo);
    updateOrganizationNumberOfUsers(data.creditInfo.numberOfUsers);
  };
  const signalRConnection = useContext(SignalRContext);

  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.CONTRACT_CHANGED, (data) => {
        setContractStatus(data);
        signalReceived(SIGNALR_CONSTANTS.CONTRACT_CHANGED, data);
      });
      signalRConnection.on(SIGNALR_CONSTANTS.PLAN_CHANGED, (data) => {
        handlePlanChangedPayment(data);
        signalReceived(SIGNALR_CONSTANTS.PLAN_CHANGED, data);
      });
      signalRConnection.on(SIGNALR_CONSTANTS.FAILED_PAYMENT, (data) => {
        handlePlanChangedPayment(data);
        signalReceived(SIGNALR_CONSTANTS.FAILED_PAYMENT, data);
      });
    }

    return () => {
      console.log("Signalr OFF");
      if (signalRConnection != null) {
        try {
          signalRConnection.off(SIGNALR_CONSTANTS.CONTRACT_CHANGED);
          signalRConnection.off(SIGNALR_CONSTANTS.PLAN_CHANGED);
          signalRConnection.off(SIGNALR_CONSTANTS.FAILED_PAYMENT);
        } catch {}
      }
    };
  }, [signalRConnection]);

  const [checkoutModal, setCheckoutModal] = useState(false);
  const handleCheckoutModalClose = () => {
    setCheckoutModal(false);
  };

  const [showAddCreditCardModalWarning, setShowAddCreditCardModalWarning] = useState(false);

  const handleAddCreditCardModalCloseWarning = () => {
    setShowAddCreditCardModalWarning(false);
  };

  const changeUrl = () => {
    if (isCheckout) {
      if (contractStatus === termsAndPaymentStatusEnum.awaitingPaymentConfirmation || waitingDowngrade) {
        setShowAddCreditCardModalWarning(true);
      } else setCheckoutModal(true);
    } else props.history.push("/" + props.orgId + "/pricing");
  };
  return (
    <div className={`${styles.wrapper} ${props.usageWrapperClass ? props.usageWrapperClass : ""}`}>
      <div className={`${styles.creditUsageDetails} ${props.usageDetailsWrapperClass ? props.usageDetailsWrapperClass : ""}`}>
        <div className={styles.creditsWrapper}>
          <p>Credits</p>
          <p>
            <span>{useOrgNumberFormatting(props.current)}</span>/<span className={styles.total}>{useOrgNumberFormatting(props.total)}</span>
          </p>
        </div>
        <ProgressBarUI now={props.current} max={props.total} min={0} ariaLabel={props.ariaLabel} />
        <div className={styles.plansWrapper}>
          <p>
            Plan: <span className={styles.freePlan}>{props.plan}</span>
          </p>
          <p className={styles.noRemaining}>
            Remaining Days: <span>{props.remainingDays}</span>
          </p>
        </div>
      </div>
      <Permission has={PERMISSIONS.billingPayment.create}>
        <Button
          id="upgradeButton"
          variant="primary"
          onClick={changeUrl}
          classes={`${styles.upgradeButton} ${props.upgradeButtonClass}`}
          disabled={true}
          ariaLabel="Add billing info"
        >
          <OverlayTrigger
            key={"key-tour"}
            placement={"bottom"}
            overlay={
              <Popover id={`popover-tooltip-take-tour`}>
                <Popover.Body>Sorry we are not accepting new signups or upgrades at this time</Popover.Body>
              </Popover>
            }
          >
            <p className="max-x-content m0 flex">
              {" "}
              <ArrowUpCircleLineIcon size={20} className="mR5" />
              <span>{text}</span>
            </p>
          </OverlayTrigger>
        </Button>
      </Permission>
      {planInfoCheckout ? (
        <CheckoutModal
          {...props}
          showModal={checkoutModal}
          handleClose={handleCheckoutModalClose}
          creditCardInfo={creditCardInfo}
          planId={planInfoCheckout.planId}
          pricePointId={planInfoCheckout.pricePointId}
          planChargifyHandle={planInfoCheckout.planChargifyHandle}
          pricePointChargifyHandle={planInfoCheckout.pricePointChargifyHandle}
          billingPeriod={planInfoCheckout.billingPeriod}
          currentPlan={planInfoCheckout.currentPlan}
          credits={planInfoCheckout.credits}
          users={planInfoCheckout.users}
          typeOfBilling={planInfoCheckout.typeOfBilling}
          price={planInfoCheckout.price}
          fullPrice={planInfoCheckout.fullPrice}
          period={planInfoCheckout.period}
          currencySign={planInfoCheckout.currencySign}
          coupon={planInfoCheckout.coupon}
          orgId={props.orgId}
          billingInfo={billingInfo}
        />
      ) : null}
      <AddCreditCardModalWarning
        handleClose={handleAddCreditCardModalCloseWarning}
        showModal={showAddCreditCardModalWarning}
        text={warningModalInfo.Content}
        title={warningModalInfo.Title}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    contractStatus: state.billingAndPayment.contractStatus,
    waitingDowngrade: state.billingAndPayment.waitingDowngrade,
    nextBillingDate: state.billingAndPayment.nextBillingDate,
    nextPlanName: state.billingAndPayment.nextPlanName,
    plan: state.billingAndPayment.creditInfo.plan,
    creditCardInfo: state.billingAndPayment.creditCardInfo,
    planInfoCheckout: state.billingAndPayment.planInfoCheckout,
    billingInfo: state.billingAndPayment.billingInfo,
    user: state.user,
    contractRenewal: state.billingAndPayment.creditInfo.contractRenewal,
    campaignsInfo: state.dashboard.campaignsInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
    fetchPlanInfoCheckout: () => dispatch(fetchPlanInfoCheckout()),
    setContractStatus: (val) => dispatch(setContractStatus(val)),
    fetchCreditInfoSuccess: (data) => dispatch(fetchCreditInfoSuccess(data)),
    fetchBillingInfoSuccess: (data) => dispatch(fetchBillingInfoSuccess(data)),
    updateOrganizationNumberOfUsers: (data) => dispatch(updateOrganizationNumberOfUsers(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditUsage);
