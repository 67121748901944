import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { addSalesDocumentsDocumentSuccess } from "../../../store/SalesDocuments/actions/salesDocuments";

// Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";

// Icons
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import FileUploadLineIcon from "remixicon-react/FileUploadLineIcon";

// Styles
import styles from "../../../components/UI/Modal/Modal.module.scss";

// Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import FormikTags from "@ui/Formik/FormikTags";
import DocumentUploader from "../../../components/UI/FileUploader/DocumentUploader";

const AddDocumentForm = (props) => {
  const [fileName, setFileName] = useState("");
  const [formKey, setFormKey] = useState(Date.now());

  const initialValues = {
    Document: null,
    Tags: [],
    Comment: "",
  };

  const handleModalClose = () => {
    props.handleClose();
    setFileName("");
    setFormKey(Date.now());
  };

  const handleFileRemove = (setFieldValue) => {
    setFieldValue("Document", null);
    setFileName("");
  };

  useEffect(() => {
    if (!props.showModal) {
      setFileName("");
    }
  }, [props.showModal]);

  return (
    <Formik
      key={formKey}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const formData = new FormData();
        formData.append("document", values.Document);
        for (let index = 0; index < values.Tags.length; index++) {
          formData.append(`tags[${index}]`, values.Tags[index]);
        }
        formData.append("comment", values.Comment);

        api(API.salesDocument.createDocument, formData)
          .then((response) => {
            props.addSalesDocumentsDocumentSuccess(response.data);
            handleModalClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => (e.field === "document" ? toast.error(e.message) : actions.setFieldError(e.field, e.message)));
            } else {
              let message = error?.response?.data?.errors?.Document?.length > 0 ? error.response.data.errors.Document[0] : error.message;
              toast.error(message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
            actions.resetForm();
            setFileName("");
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, values } = formikProps;

        return (
          <Modal
            title="Add New File"
            handleClose={handleModalClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting || !values.Document}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <div className="flex align-center">
                <DocumentUploader
                  buttonText={
                    <>
                      <FileUploadLineIcon size={16} className="mR5" /> Upload file
                    </>
                  }
                  variant="primary"
                  handleFile={(file) => {
                    setFieldValue("Document", file);
                    setFileName(file.name);
                  }}
                  disabled={!!fileName}
                />
                {fileName && (
                  <div className="mL10">
                    <span>{fileName}</span>
                    <DeleteBinLineIcon size={16} className="iconGray cursor_pointer mL10" onClick={() => handleFileRemove(setFieldValue)} />
                  </div>
                )}
              </div>
              <label className="f14 mT10">Tags: </label>
              <FormikTags
                id="tags"
                placeholder="Add tags here..."
                name="Tags"
                content="Type a tag, then press enter. You can add multiple tags."
              />
              <FormikInput label="Comment: " name="Comment" type="textarea" placeholder="Comment" className={styles.modal_input} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSalesDocumentsDocumentSuccess: (document) => {
      dispatch(addSalesDocumentsDocumentSuccess(document));
    },
  };
};

export default connect(null, mapDispatchToProps)(AddDocumentForm);
