import React from "react";

// Styles
import "./SkeletonTable.scss";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonTable = ({ variant = null }) => {
  const classes = `skeleton-table ${variant}`;
  return (
    <div className="skeleton-wrapper">
      <table className={classes}>
        <thead className="width_100">
          <tr>
            <th style={{ width: "20px" }}></th>
            <th>
              <SkeletonElement type="text-15 w-5" />
            </th>
            <th>
              <SkeletonElement type="text-15 w-5" />
            </th>
            <th>
              <SkeletonElement type="text-15 w-5" />
            </th>
          </tr>
        </thead>
        <tbody className="datagrid__row">
          <tr>
            <td>
              <SkeletonElement type="circle-s" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-4" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-7" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-5" />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonElement type="circle-s" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-4" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-7" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-5" />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonElement type="circle-s" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-4" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-7" />
            </td>
            <td>
              <SkeletonElement type="text-15 w-5" />
            </td>
          </tr>
        </tbody>
      </table>
      <Shimmer />
    </div>
  );
};

export default SkeletonTable;
