import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import { useOrgDateTimeFormat } from "../../../../helper/hooks/useOrgDateTimeFormat";

// Styles
import styles from "./SentEmailDetailsModal.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import CampaignLabel from "../../../../components/Label/CampaignLabel";

const SentEmailDetailsModal = (props) => {
  const { show, handleClose, action } = props;
  let dateTimeFormat = useOrgDateTimeFormat();

  return (
    <Modal
      dialogClassName="customModal"
      title="Email details"
      handleClose={handleClose}
      handleSave={handleClose}
      show={show}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
      backdrop={true}
      size="lg"
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.modalBody}>
            {action == null ? null : (
              <>
                <div className={styles.cardsTitleWrapper}>
                  <CampaignLabel title={action.campaignName} />
                </div>
                <div>
                  <p>
                    <span className={`${styles.infoLabel} font600 mR5`}>
                      From:{" "}
                    </span>
                    <span>{action.from}</span>
                  </p>
                  <p>
                    <span className={`${styles.infoLabel} font600 mR5`}>
                      To:{" "}
                    </span>
                    <span>{action.to}</span>
                  </p>
                  <p>
                    <span className={`${styles.infoLabel} font600 mR5`}>
                      Subject:{" "}
                    </span>
                    <span>{action.subject}</span>
                  </p>
                </div>
                <div>
                  <p className={`${styles.messageLink} font600 mR5`}>
                    <span>Message</span>
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: action.message }} />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default SentEmailDetailsModal;
