import React from "react";

const IconCheckMark = (props) => (
  <svg
    className={props.iconClass}
    width={props.width}
    height={props.height}
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77313 6.20592L0.151132 3.56892C0.0752884 3.49266 0.0327148 3.38947 0.0327148 3.28192C0.0327148 3.17436 0.0752884 3.07118 0.151132 2.99492L0.721132 2.42092C0.758352 2.38313 0.802717 2.35311 0.851645 2.33262C0.900573 2.31214 0.953088 2.30159 1.00613 2.30159C1.05918 2.30159 1.11169 2.31214 1.16062 2.33262C1.20955 2.35311 1.25391 2.38313 1.29113 2.42092L3.05813 4.19692L6.84113 0.396919C6.87835 0.359125 6.92272 0.329111 6.97164 0.308625C7.02057 0.288138 7.07309 0.277588 7.12613 0.277588C7.17918 0.277588 7.23169 0.288138 7.28062 0.308625C7.32955 0.329111 7.37391 0.359125 7.41113 0.396919L7.98113 0.970919C8.05698 1.04718 8.09955 1.15036 8.09955 1.25792C8.09955 1.36547 8.05698 1.46866 7.98113 1.54492L3.34313 6.20592C3.30591 6.24371 3.26155 6.27373 3.21262 6.29421C3.16369 6.3147 3.11118 6.32525 3.05813 6.32525C3.00509 6.32525 2.95257 6.3147 2.90365 6.29421C2.85472 6.27373 2.81035 6.24371 2.77313 6.20592Z"
      fill={props.fill ? props.fill : "#2a3347"}
    />
  </svg>
);
export default IconCheckMark;
