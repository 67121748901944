import api, { API } from "../../api/api";
import { useQuery } from "react-query";
import { SelectListEnum } from "./SelectListEnum";

const getApiEndpointFromType = (type: SelectListEnum) => {
  switch (type) {
    case SelectListEnum.CampaignStatuses:
      return API.campaigns.listCampaignStatuses;
    case SelectListEnum.AudienceStatuses:
      return API.audience.listAudienceStatuses;
    case SelectListEnum.CampaignsList:
      return API.campaigns.getList;
    case SelectListEnum.UnarchivedCampaignsList:
      return API.campaigns.getListUnarchived;
    case SelectListEnum.EmailAccounts:
      return API.emailAccounts.selectListEmailAccounts;
    case SelectListEnum.ListAudienceTypes:
      return API.audience.listAudienceTypes;
    case SelectListEnum.ListEmailTemplateTypes:
      return API.emailTemplates.listEmailTemplatesTypes;
    case SelectListEnum.Categories:
      return API.complianceSupportCenter.getAllCategories;
    case SelectListEnum.ListOfTags:
      return API.salesDocument.getListOfTags;
    case SelectListEnum.ListEmailTemplateStatus:
      return API.emailTemplates.listEmailTemplatesStatus;
    default:
      return null;
  }
};

const useSelectList = (type: SelectListEnum) => {
  const apiEndpoint = getApiEndpointFromType(type);
  const apiKey = apiEndpoint != null && apiEndpoint[1] != null ? apiEndpoint[1] : "";

  return useQuery([apiKey], () => api(apiEndpoint).then((response) => response.data));
};

export default useSelectList;
