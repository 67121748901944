import * as actionTypes from "../actions/actionTypes";
import update from "immutability-helper";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  listEmailStages: [],
};

const fetchEmailStagesListSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listEmailStages = action.result;
  return newState;
};

const fetchEmailStagesListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchEmailStagesListFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMAIL_STAGES_LIST_REQUEST:
      return fetchEmailStagesListRequest(state, action);
    case actionTypes.FETCH_EMAIL_STAGES_LIST_SUCCEEDED:
      return fetchEmailStagesListSuccess(state, action);
    case actionTypes.FETCH_EMAIL_STAGES_LIST_FAILED:
      return fetchEmailStagesListFailed(state, action);
  }
  return state;
};

export default reducer;
