import React from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import { useUpdateQuestionMutation } from "../../../api/complianceApi";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import TagsInput from "../../../components/UI/TagsInput/TagsInput";
import { UpdateCSCQuestionsAndAnswersModel } from "../../../models/compliance/UpdateCSCQuestionsAndAnswersModel";
import FormikTags from "@ui/Formik/FormikTags";

interface ChangeQAFormProps {
  model: UpdateCSCQuestionsAndAnswersModel;
  handleClose: () => void;
  showModal: boolean;
}

interface FormValues {
  id: number;
  question: string;
  categories: string[];
  answer: string;
}

const ChangeQAForm: React.FC<ChangeQAFormProps> = ({ model, handleClose, showModal }) => {
  const [updateQuestion, { isLoading, data, error }] = useUpdateQuestionMutation();

  return (
    <Formik
      initialValues={{
        id: model.id,
        question: model.question,
        categories: model.category,
        answer: model.answer,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        id: Yup.number().required("Required"),
        question: Yup.string().required("Required"),
        answer: Yup.string().required("Required"),
        categories: Yup.array().of(Yup.string().required("Required")).required("Required"),
      }).required("Required")}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        const questionData = {
          id: values.id,
          question: values.question,
          category: values.categories,
          answer: values.answer,
        };

        updateQuestion(questionData)
          .unwrap()
          .then((response) => {
            handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, handleChange, values } = formikProps;

        return (
          <Modal
            title={`Update Q & A`}
            handleClose={handleClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText="Update Question"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Field value={model.id} type="hidden" />
              <FormikInput
                label="Question: "
                name="question"
                type="text"
                placeholder="Question"
                value={values.question}
                onChange={handleChange}
              />
              <label>Categories: </label>
              <FormikTags
                id="tags"
                placeholder="Add tags here..."
                name="categories"
                content="Type a category, then press enter. You can add multiple categories."
              />
              <FormikInput
                label="Answer: "
                name="answer"
                type="textarea"
                placeholder="Answer"
                value={values.answer}
                onChange={handleChange}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ChangeQAForm;
