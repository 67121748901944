export enum ResponseTypeEnum {
  PositiveResponse = 1,
  Referral = 2,
  DeferredInterest = 3,
  Decline = 4,
  NoLongerWithBusiness = 5,
  Undelivered = 6,
  OutOfOffice = 7,
  Archived = 9,
}

export enum ResponseOriginTypeEnum {
  Email = 1,
  SMS = 2,
  PhoneCall = 3,
  LiveChat = 4,
  LinkedIn = 5,
  Task = 6,

  Other = 100,
}
