import { api } from "@api/api";
import { SuspendAccountModel } from "@models/outboss/emailAccounts/SuspendAccountModal";

export const outbossEmailAccountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    suspendAccount: builder.mutation<boolean, SuspendAccountModel>({
      query: (model) => ({
        url: `/outboss-email-accounts/suspend`,
        method: "POST",
        body: model,
      }),
      invalidatesTags: ["OutbossEmailAccounts"],
    }),
    removeSuspension: builder.mutation<boolean, number>({
      query: (emailAccountId) => ({
        url: "/outboss-email-accounts/remove-suspension?emailAccountId=" + emailAccountId,
        method: "POST",
      }),
      invalidatesTags: ["OutbossEmailAccounts"],
    }),
  }),
});

export const { useSuspendAccountMutation, useRemoveSuspensionMutation } = outbossEmailAccountsApi;
