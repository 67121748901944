import React, { FC } from "react";
import Select from "react-select";

//Helpers
import { showingPerPage } from "../../../helper/tableConstants.js";

//Styles
import style from "./ShowingPerPage.module.scss";

interface ShowingPerPageProps {
  hideLabelShow?: boolean;
  numberOfRecords: { value: number; label: string }[];
  change?: (obj: any) => void;
  defaultValue?: { value: string; label: string };
  menuPlacement?: "auto" | "bottom" | "top";
}

const ShowingPerPage: FC<ShowingPerPageProps> = (props) => {
  return (
    <div className={props.hideLabelShow ? " " : style.select_wrapper}>
      {props.hideLabelShow ? null : <span className={style.label}>Rows per page:</span>}
      <Select
        options={props.numberOfRecords.map((option) => ({ value: Number(option.value), label: option.label }))}
        onChange={(obj) => {
          if (props.change) props.change(obj);
        }}
        defaultValue={props.numberOfRecords.find((option) => Number(option.value) === Number(props.defaultValue?.value))}
        styles={showingPerPage}
        isSearchable={false}
        className={`${style.perPageSelect} perPageSelect`}
        menuPlacement={props.menuPlacement ? props.menuPlacement : "auto"}
        aria-label="select"
      />
    </div>
  );
};

export default ShowingPerPage;
