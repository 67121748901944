import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const fetchResponseDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_RESPONSE_DETAILS_REQUEST,
  };
};

export const fetchResponseDetailsSuccess = (responseDetails) => {
  return {
    type: actionTypes.FETCH_RESPONSE_DETAILS_SUCCEEDED,
    result: responseDetails,
  };
};

export const fetchResponseDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_RESPONSE_DETAILS_FAILED,
    result: error,
  };
};

export const fetchResponseDetails = (receivedEmailId) => {
  return (dispatch) => {
    dispatch(fetchResponseDetailsRequest());
    return api(
      formUrl(API.deals.getReceivedEmailDetails, {
        receivedEmailId: receivedEmailId,
      })
    )
      .then((response) => {
        dispatch(fetchResponseDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchResponseDetailsFailed(error.message));
      });
  };
};

export const fetchSentEmailDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_RESPONSE_DETAILS_REQUEST,
  };
};

export const fetchSentEmailDetailsSuccess = (responseDetails) => {
  return {
    type: actionTypes.FETCH_RESPONSE_DETAILS_SUCCEEDED,
    result: responseDetails,
  };
};

export const fetchSentEmailDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_RESPONSE_DETAILS_FAILED,
    result: error,
  };
};

export const fetchSentEmailDetails = (prospectActionId) => {
  return (dispatch) => {
    dispatch(fetchSentEmailDetailsRequest());
    return api(
      formUrl(API.deals.getReceivedEmailDetails, {
        prospectActionId: prospectActionId,
      })
    )
      .then((response) => {
        dispatch(fetchSentEmailDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSentEmailDetailsFailed(error.message));
      });
  };
};
