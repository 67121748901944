export const responseTypeEnum = {
    PositiveResponse: 1,
    Referral: 2,
    DeferredInterest: 3,
    Decline: 4,
    NoLongerWithBusiness: 5,
    Undelivered: 6,
    OutOfOffice: 7,
    Archived: 9
  };

  export const responseTypeStringEnum = [null, "Positive response", "Referral", "Deferred interest", "Decline", "No longer with business", "Undelivered", "Out of office", "Archived"];