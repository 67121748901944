import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Row, Col } from "react-bootstrap";

//Helpers
import api, { API, formUrl } from "../../../../../../helper/api/api";
import { organizationStatusOptions } from "../../../../../../helper/enums/organizationStatus";

//Styles
import styles from "./ExtendTrialModal.module.scss";

//Components
import Modal from "../../../../../../components/UI/Modal/Modal";
import FormikSelect from "../../../../../../components/UI/Formik/FormikSelect";
import { toast } from "react-toastify";

const ExtendTrialModal = (props) => {
  const { organizationId, organizationPayment } = props;

  return (
    <Formik
      initialValues={{
        startDate: organizationPayment.startDate ? new Date(organizationPayment.startDate) : new Date(),
        endDate: organizationPayment.endDate ? new Date(organizationPayment.endDate) : new Date(),
        orgStatus: organizationPayment.organizationStatus,
      }}
      validationSchema={Yup.object({
        startDate: Yup.date().required("Required"),
        endDate: Yup.date().required("Required"),
        orgStatus: Yup.number().required("Required"),
      })}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        let data = {
          organizationId: organizationId,
          startDate: values.startDate,
          endDate: values.endDate,
          organizationStatus: values.orgStatus,
        };
        api(API.OUTBOSS.organizations.extendTrialForOrganization, data)
          .then((res) => {
            if (res.data) toast.success("Trial successfully extended");
            props.handleClose();
          })
          .catch((error) => console.log(error));
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, resetForm, setFieldValue } = formikProps;
        {
          return (
            <Modal
              title="Extend Organization Trial"
              handleClose={props.handleClose}
              handleSave={handleSubmit}
              show={props.showModal}
              btnType="submit"
              closeButtonText="Cancel"
              saveButtonText="OK"
              size="lg"
            >
              {organizationPayment != null ? (
                <div className={`${styles.contentBody} flex`}>
                  <Col xl={12}>
                    <p className={styles.details}>Important</p>
                  </Col>
                  <Col xl={12}>
                    <p>
                      Dates must have the same DAY of the month. This day will be set as credit renewal day. {organizationPayment.startDate}
                    </p>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <p className={styles.details}>
                        Plan start date:{" "}
                        <DatePicker
                          id="startDate"
                          showTimeSelect={false}
                          selected={organizationPayment.startDate ? new Date(organizationPayment.startDate) : new Date(values.startDate)}
                          name="startDate"
                          onChange={(val) => setFieldValue("startDate", val)}
                          placeholderText="Pick a date"
                        />
                      </p>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div>
                      <p className={styles.details}>
                        Plan end date:{" "}
                        <DatePicker
                          showTimeSelect={false}
                          selected={organizationPayment.endDate ? new Date(organizationPayment.endDate) : new Date(values.endDate)}
                          name="endDate"
                          onChange={(val) => setFieldValue("endDate", val)}
                          placeholderText="Pick a date"
                        />
                      </p>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <p className={styles.details}>
                      Organization status:{" "}
                      <FormikSelect
                        placeholder={"Organization status"}
                        name="orgStatus"
                        value={organizationPayment.organizationStatus}
                        showBorder={true}
                        options={organizationStatusOptions}
                        isSearchable={true}
                        enableReinitialize={true}
                      />
                    </p>
                  </Col>
                </div>
              ) : null}
            </Modal>
          );
        }
      }}
    </Formik>
  );
};

export default ExtendTrialModal;
