import { ROLE_PERMISSIONS } from "./rolePermissions";

export const permissionHelper = (role, permission) => {
  var permissions = ROLE_PERMISSIONS[role];

  if (permissions != null && permissions.includes(permission)) {
    return true;
  }
  return false;
};
