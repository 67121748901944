import React from "react";
import Action from "../Action";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";

//Helpers
import api, { API, formUrl } from "../../../../helper/api/api";

//Icons
import LoginBoxLineIcon from "remixicon-react/LoginBoxLineIcon";

const ActionLoginAsAdmin = (props) => {
  const logAdminLogin = () => {
    api(
      formUrl(API.OUTBOSS.users.logAdminLogin, {
        organizationId: props.organizationId,
      })
    )
      .then((response) => {})
      .catch((error) => {});
  };

  const redirectToUrl = props.redirectToUrl ?? "dashboard";

  return (
    <Action
      className={props.className}
      disabled={false}
      onClick={logAdminLogin}
      materialIcon={
        <OverlayTrigger
          key={"log-in"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-log-in`}>
              <Popover.Body>Login as admin</Popover.Body>
            </Popover>
          }
        >
          <a rel="noopener noreferrer" aria-label="Login as admin" href={`/${props.organizationId}/${redirectToUrl}`} target="_blank">
            <LoginBoxLineIcon size={16} className="iconGray" />
          </a>
        </OverlayTrigger>
      }
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    newUser: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionLoginAsAdmin);
