import React from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";

//Styles
import styles from "../../../components/UI/Modal/Modal.module.scss";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import TagsInput from "../../../components/UI/TagsInput/TagsInput";
import { useCreateQuestionMutation } from "../../../api/complianceApi";
import FormikTags from "@ui/Formik/FormikTags";

interface AddQAFormProps {
  handleClose: () => void;
  showModal: boolean;
}

interface FormValues {
  question: string;
  categories: string[];
  answer: string;
}

const AddQAForm: React.FC<AddQAFormProps> = ({ handleClose, showModal }) => {
  const [createQuestion, { isLoading, data, error }] = useCreateQuestionMutation();

  return (
    <Formik
      initialValues={
        {
          question: "",
          categories: [],
          answer: "",
        } as FormValues
      }
      enableReinitialize={true}
      validationSchema={Yup.object({
        question: Yup.string().required("Required"),
        answer: Yup.string().required("Required"),
        categories: Yup.array().of(Yup.string().required("Required")).required("Required"),
      }).required("Required")}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        if (values.categories.length === 0) {
          actions.setFieldError("categories", "You must press ENTER to insert the category");
          actions.setSubmitting(false);
          return;
        } else {
          const questionData = {
            question: values.question,
            category: values.categories,
            answer: values.answer,
          };

          createQuestion(questionData)
            .unwrap()
            .then((response) => {
              handleClose();
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);

              if (errorList.length > 0) {
                errorList.forEach((e) => actions.setFieldError(e.field, e.message));
              } else {
                toast.error(error.message);
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, errors } = formikProps;

        return (
          <Modal
            title={`Add Q&A`}
            handleClose={handleClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText="Add"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form>
              <FormikInput label="Question: " name="question" type="text" placeholder="Question" className={styles.modal_input} />
              <label>Categories: </label>
              <FormikTags
                id="tags"
                placeholder="Add tags here..."
                name="categories"
                content="Type a category, then press enter. You can add multiple categories."
              />
              <FormikInput label="Answer: " name="answer" type="textarea" placeholder="Answer" className={styles.modal_input} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddQAForm;
