import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

//Redux
import {
  fetchBasicEmailSendTest,
  fetchBasicEmailTestDetails,
} from "../../../../../store/EmailDeliverabilityTest/Global/actions/basicEmailTest";

//Helpers
import { useOrganizationId } from "../../../../../helper/hooks/useOrganizationId";
import api, { API, formUrl } from "../../../../../helper/api/api";

//Style
import styles from "./HeaderTabs.module.scss";

//Icons
import MailSendLineIcon from "remixicon-react/MailSendLineIcon";
import PlayCircleLineIcon from "remixicon-react/PlayCircleLineIcon";

//Components
import Tabs from "../../../../../components/UI/Tabs/Tabs";
import ConfigurationTest from "../ConfigurationTest/ConfigurationTest";
import EmailAccountSettings from "../../EmailAccountSettings";
import HeaderTitle from "../../../../../components/UI/HeaderTitle/HeaderTitle";
import Button from "../../../../../components/UI/Button/Button";
import BreadcrumbElement from "../../../../../components/UI/BreadcrumbElement/BreadcrumbElement";
import PageContentWrapper from "../../../../../components/PageContentWrapper/PageContentWrapper";

const HeaderTabs = (props) => {
  const { fetchBasicEmailSendTest, fetchBasicEmailTestDetails } = props;
  let history = useHistory();

  let organizationId = useOrganizationId();

  const [sendTestValues, setSendTestValues] = useState({});
  const [sendStatus, setSendStatus] = useState("Not started");
  const [receiveStatus, setReceiveStatus] = useState("Not started");
  const [showTestEmailFormModal, setShowTestEmailFormModal] = useState(false);
  const handleTestMailFormClose = () => setShowTestEmailFormModal(false);

  let emailAccountId = props.match.params.accountId;

  let emailAccountType = props.match.params.accountType;
  let isOnCreate = props.match.url.includes("create");

  let isOnConfigurationTab = props.history.location.pathname.includes("configuration-test");

  const icon = (
    <>
      <a
        id="setUpEmailLink"
        href="https://vimeo.com/699341221/ce15340b7b?embedded=true&source=video_title&owner=82836407"
        rel="noopener noreferrer"
        target="_blank"
        className={styles.infoLink}
      >
        <PlayCircleLineIcon size={20} className="mR5" /> How do I setup an email account?
      </a>
    </>
  );

  const sendTest = (emailAccountId) => {
    setShowTestEmailFormModal(true);
    setSendStatus("Started");
    setReceiveStatus("Started");

    api(
      formUrl(API.emailAccounts.testEmailAccount, {
        emailAccountId: emailAccountId,
      })
    )
      .then((response) => {
        if (response.data.sendTestSuccess) setSendStatus("Successful");
        else setSendStatus("Failed");

        if (response.data.receiveTestSuccess) setReceiveStatus("Successful");
        else setReceiveStatus("Failed");
      })
      .catch((err) => {
        setReceiveStatus("Failed");
        setSendStatus("Failed");
      });
  };

  const tabs = [];

  if (isOnCreate) {
    tabs.push({
      link: "",
      component: (
        <EmailAccountSettings
          emailAccountType={emailAccountType}
          setSendTestValues={setSendTestValues}
          showTestEmailFormModal={showTestEmailFormModal}
          receiveStatus={receiveStatus}
          sendStatus={sendStatus}
          handleTestMailFormClose={handleTestMailFormClose}
          history={props.history}
        />
      ),
      title: "Account Settings",
    });
  } else {
    tabs.push(
      {
        link: "edit",
        component: (
          <EmailAccountSettings
            emailAccountId={emailAccountId}
            emailAccountType={emailAccountType}
            history={props.history}
            setSendTestValues={setSendTestValues}
            showTestEmailFormModal={showTestEmailFormModal}
            receiveStatus={receiveStatus}
            sendStatus={sendStatus}
            handleTestMailFormClose={handleTestMailFormClose}
          />
        ),
        title: "Account Settings",
      },
      {
        link: "configuration-test",
        component: (
          <ConfigurationTest
            emailAccountId={emailAccountId}
            emailAccountType={emailAccountType}
            showTestEmailFormModal={showTestEmailFormModal}
            receiveStatus={receiveStatus}
            sendStatus={sendStatus}
            handleTestMailFormClose={handleTestMailFormClose}
            link={props.match.url + "/edit"}
          />
        ),
        title: "Configuration Test",
      }
    );
  }

  let config = <Tabs tabContentClass={styles.accountSettingsTabs} {...props} tabs={tabs} />;

  const breadcrumbs = [
    {
      href: "/" + organizationId + "/email-accounts",
      text: "Email Accounts",
    },
    { active: true, text: isOnCreate ? "Create" : "Edit" },
  ];

  const handleSendTest = () => {
    fetchBasicEmailSendTest(emailAccountId)
      .then((res) => {
        if (isOnConfigurationTab) {
          fetchBasicEmailTestDetails(emailAccountId);
        } else {
          history
            .push(`/${organizationId}/email-accounts/${emailAccountId}/configuration-test`)
            .then((res) => {})
            .catch((err) => err);
        }
      })
      .catch((err) => toast.error(err));
  };

  return (
    <PageContentWrapper>
      {props.fromCampaignSequence ? null : (
        <div className={styles.breadcrumbDiv}>
          <BreadcrumbElement items={breadcrumbs} />
        </div>
      )}

      <HeaderTitle
        title="Email Account Settings"
        icon={icon}
        secondTitle={
          <div className="flex">
            {!isOnCreate ? (
              <Button id="configTest" variant="secondary" classes="mR15" onClick={() => handleSendTest()} type="button">
                Configuration test
              </Button>
            ) : null}
            <Button id="sendTest" variant="primary" onClick={() => sendTest(emailAccountId)} type="button">
              <MailSendLineIcon size={16} className="mR5" />
              Send test email
            </Button>
          </div>
        }
      />
      {config}
    </PageContentWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBasicEmailSendTest: (emailAccountId) => dispatch(fetchBasicEmailSendTest(emailAccountId)),
    fetchBasicEmailTestDetails: (emailAccountId) => dispatch(fetchBasicEmailTestDetails(emailAccountId)),
  };
};

export default connect(null, mapDispatchToProps)(HeaderTabs);
