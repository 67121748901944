import React from "react";

//Styles
import styles from "./Separator.module.scss";

const Separator = (props) => {
  return <div className={styles.separator} style={props.style}></div>;
};

export default Separator;
