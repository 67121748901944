import { FC } from "react";
import Modal from "../../components/UI/Modal/Modal";
import { Link } from "react-router-dom";
import styles from "./Campaigns.module.scss";

interface Props {
  handleClose: () => void;
  handleSave: () => void;
  showModal: boolean;
  organizationId: number;
  campaignId: number;
  campaignName: string;
}

const DraftEmailTemplatesSequenceModal: FC<Props> = (props) => {
  return (
    <Modal
      title={`Warning`}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="OK"
      btnType="submit"
    >
      <div>
        <p>
          Your {props.campaignName} campaign cannot be activated because there are unpublished email templates in the messaging sequence.
          Please click
          <Link className={styles.noEmailAccountModalLink} to={`/${props.organizationId}/campaigns/${props.campaignId}/campaign-sequence`}>
            {" "}
            here{" "}
          </Link>
          to re review and publish email templates.
        </p>
      </div>
    </Modal>
  );
};

export default DraftEmailTemplatesSequenceModal;
