import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment_timezone from "moment-timezone";

//Styles
import styles from "./FormikInput.module.scss";

export const FormikDatePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  moment_timezone.tz.setDefault("UTC");

  let errorInput = null;
  let errorElement = null;
  let minutes = null;

  if (meta.touched && meta.error) {
    errorElement = <div className={props.errorClass + " " + styles.error}>{meta.error}</div>;
    errorInput = styles.errorInput;
  }

  if (props.from != null && props.from !== "") {
    minutes = props.from.getMinutes() + 15;
  }

  return (
    <div className={styles.group + " " + props.formgroupclass}>
      <DatePicker
        // id={field.name ? field.name : ""}
        id={field.name ? field.name.replace(/\./g, "") : ""}
        {...field}
        {...props}
        showTimeSelect={props.showTimeSelect}
        showYearPicker={props.showYearPicker}
        selected={(props.value != null && new Date(props.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
          if (props.onChange) {
            props.onChange(val);
          }
        }}
        className={props.datePickerClass}
        showTimeSelectOnly={props.showTimeSelectOnly}
        timeIntervals={props.timeIntervals}
        timeCaption={props.timeCaption}
        dateFormat={props.dateFormat}
        placeholderText={props.placeholderText}
        minTime={props.from != null && props.from !== "" ? props.from.setMinutes(minutes) : ""}
        maxTime={props.from != null && props.from !== "" ? props.from.setHours(23, 45) : ""}
      />
      <p>{errorElement}</p>
    </div>
  );
};

export default FormikDatePicker;
