import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";

//Hooks
import useSignalRHook from "@hooks/useSignalRHook";

//Helpers
import { SIGNALR_CONSTANTS } from "@helper/signalrConstants";

//Styles
import styles from "./Steps.module.scss";

const FourthStep = (props) => {
  const { setAudienceContactsImportInfo } = { ...props };

  useSignalRHook(SIGNALR_CONSTANTS.AUDIENCE_CONTACTS_IMPORT_INFO, (data) => setAudienceContactsImportInfo(data));

  return (
    <Container>
      <Row>
        <Col xl={12}>
          <p>Processing your csv file.</p>
        </Col>

        <Col xl={12}>
          <label>{props.CSVFileName}</label>
        </Col>
        <Col xl={12} className="mB10">
          <ProgressBar
            className={styles.uploadFileProgressBar}
            now={props.audienceContactsImportInfo.importPercentage}
            max={100}
            min={1}
            animated
          />
        </Col>

        <Col xl={6} className={`floatLeft ${styles.uploadFileProgressInfo}`}>
          {props.audienceContactsImportInfo.importPercentage >= 100 ? "Done" : "Processing..."}
        </Col>

        <Col xl={6} className={`textRight ${styles.uploadFileProgressInfo}`}>
          {props.audienceContactsImportInfo.importPercentage} %
        </Col>
      </Row>
      <Row className={styles.procesingDetails}>
        <Col xl={3}>
          <h2 id="validContacts" className={styles.validContacts}>
            {props.audienceContactsImportInfo.validContacts || 0}
          </h2>
          <p className="font600">Valid Contacts</p>
        </Col>
        <Col xl={3}>
          <h2 id="duplicateContacts" className={styles.duplicates}>
            {props.audienceContactsImportInfo.duplicateContacts || 0}
          </h2>
          <p className="font600">Duplicates</p>
        </Col>
        <Col xl={3}>
          <h2 id="excludedContacts" className={styles.excluded}>
            {props.audienceContactsImportInfo.excludedContacts || 0}
          </h2>
          <p className="font600">Excluded</p>
        </Col>
        <Col xl={3}>
          <h2 id="invalidContacts" className={styles.invalids}>
            {props.audienceContactsImportInfo.invalidContacts || 0}
          </h2>
          <p className="font600">Invalid</p>
        </Col>
      </Row>

      <Row>
        <Col className="textCenter">
          {props.invalidContactsCSVString !== "" ? (
            <p className={styles.downloadInvalidContacts} onClick={() => props.downloadInvalidContactsCSV(props.audienceName)}>
              Download failed records
            </p>
          ) : null}

          {props.audienceContactsImportInfo.invalidHeaderRow ? (
            <p className={styles.invalidColumnHeaders}>
              The file contains invalid column headers.
              <br />
              Check the names and delete any empty spaces.
            </p>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default FourthStep;
