import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { integrationsEnum } from "../../../../helper/enums/integrationsEnum";

// Actions
import { fatchAllIntegrations } from "../../../../store/CRM-Integrations/Global/actions/globalIntegrationActions";

// Images
import HubSpotLogo from "../../../../assets/Images/CRM/HubSpotLogo/HubSpotLogo";

//Styles
import styles from "./SelectCRMModal.module.scss";

interface RootState {
  globalIntegrations: {
    integrations: any[];
  };
}

interface SelectCRMModalProps extends PropsFromRedux {
  setIsHubSpotSelected: (selected: boolean) => void;
  setIntegrationType: (type: number) => void;
  setOAuthUrl: (url: string) => void;
  isIntegrationSelected: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

const SelectCRMModal: React.FC<SelectCRMModalProps> = (props) => {
  const { fatchAllIntegrations, setIsHubSpotSelected, globalIntegrations, setIntegrationType, setOAuthUrl, isIntegrationSelected } = props;
  const logoRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    fatchAllIntegrations();

    const handleClickOutside = (event: MouseEvent) => {
      if (logoRef.current && !logoRef.current.contains(event.target as Node)) {
        setTimeout(() => {
          setIsHubSpotSelected(false);
        }, 200);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.componentWrapper}>
      <h1>Select your CRM</h1>
      <div className={styles.crmList}>
        {globalIntegrations.map((integration, index) => {
          let classes = styles.selectButton;
          let name = "";
          let image = null;

          if (integration.name !== "HubSpot") classes += " " + styles.comingSoon;

          return (
            <div className={styles.crmWrapper} key={integration.id}>
              <button
                ref={logoRef}
                className={`${classes} ${isIntegrationSelected ? styles.focused : ""}`}
                onClick={() => {
                  setIntegrationType(integrationsEnum.HubSpot);
                  setOAuthUrl(integration.oAuthURL);
                  setIsHubSpotSelected(!isIntegrationSelected);
                }}
              >
                <HubSpotLogo />
              </button>
              <p>HubSpot</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fatchAllIntegrations: () => dispatch(fatchAllIntegrations()),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    globalIntegrations: state.globalIntegrations.integrations,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SelectCRMModal);
