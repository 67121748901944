import React from "react";

const renderGoogleTagManagerIframe = () => {
  let env = import.meta.env.VITE_ENV;
  if (env === "production" || true) {
    const noscript = document.createElement("noscript");

    const iframe = document.createElement("iframe");
    iframe.title = "googleTagManager";
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-WDGK5B6";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style = "display: none; visibility: hidden";

    noscript.appendChild(iframe);
    document.body.prepend(noscript);
  };
};

export default renderGoogleTagManagerIframe;