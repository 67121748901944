import React from "react";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const UserEmailConfirmationModal = (props) => {
  const { userEmail } = props;

  let render = userEmail ? <p>Are you sure this user email: {userEmail} should be marked as confirmed?</p> : <Spinner />;

  return (
    <Modal
      title="Verify account"
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      btnType="submit"
      saveButtonText="Confirm"
      closeButtonText="Cancel"
      size="lg"
    >
      {render}
    </Modal>
  );
};

export default UserEmailConfirmationModal;
