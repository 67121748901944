import React from "react";

//Styles
import styles from "./PlanColOutbOss.module.scss";

const PlanColOutbOss = (props) => {
  return (
    <div className={styles.planWrapper}>
      <p className={styles.planLabel}>{props.plan}</p>
    </div>
  );
};

export default PlanColOutbOss;
