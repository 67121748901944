import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isTotalDataAndAudienceLoading: true,
  isTotalDataAndAudienceLoaded: false,
  totalDataAndAudience: {},
  errorTotalDataAndAudience: "",
  isCampaignsInfoLoading: true,
  isCampaignsInfoLoaded: false,
  campaignsInfo: {},
  errorCampaignsInfo: "",
  isTotalCampaignsPerformanceLoading: true,
  isTotalCampaignsPerformanceLoaded: false,
  totalCampaignsPerformance: {},
  errorTotalCampaignsPerformance: "",
  isTotalRunRatesLoading: true,
  isTotalRunRatesLoaded: false,
  totalRunRates: [],
  errorTotalRunRates: "",
  isTotalResponsesLoading: true,
  isTotalResponsesLoaded: false,
  totalResponses: {},
  errorTotalResponses: "",
  isProspectFunnelStatisticsLoading: true,
  isProspectFunnelStatisticsLoaded: false,
  prospectFunnelStatistics: {},
  errorProspectFunnelStatistics: "",
  isLeadRateLoading: true,
  isLeadRateLoaded: false,
  leadRate: {},
  errorLeadRate: "",
  isGlobalAverageRateLoading: true,
  isGlobalAverageRateLoaded: false,
  globalAverageRate: {},
  errorGlobalAverageRate: "",
  previousOrganizationId: 0,
  isStepsDoneLoading: true,
  isStepsDoneLoaded: false,
  stepsDone: {},
  errorStepsDone: "",
  showProductTour: false,
};

const fetchTotalDataAndAudienceRequest = (state, action) => {
  return {
    ...state,
    isTotalDataAndAudienceLoading: true,
    isTotalDataAndAudienceLoaded: false,
  };
};

const fetchTotalDataAndAudienceSuccess = (state, action) => {
  const newState = { ...state };
  let dataAndAudience = { ...state.totalDataAndAudience };
  dataAndAudience = action.result;
  newState.isTotalDataAndAudienceLoaded = true;
  newState.isTotalDataAndAudienceLoading = false;
  newState.totalDataAndAudience = dataAndAudience;
  return newState;
};

const fetchTotalDataAndAudienceFailed = (state, action) => {
  const newState = { ...state };
  newState.errorTotalDataAndAudience = action.result;
  newState.isTotalDataAndAudienceLoading = false;
  newState.isTotalDataAndAudienceLoaded = true;
  return newState;
};

const fetchCampaignsInfoRequest = (state, action) => {
  return {
    ...state,
    isCampaignsInfoLoading: true,
    isCampaignsInfoLoaded: false,
  };
};

const fetchCampaignsInfoSuccess = (state, action) => {
  const newState = { ...state };
  let info = { ...state.campaignsInfo };
  info = action.result;
  newState.isCampaignsInfoLoaded = true;
  newState.isCampaignsInfoLoading = false;
  newState.campaignsInfo = info;
  return newState;
};

const fetchCampaignsInfoFailed = (state, action) => {
  const newState = { ...state };
  newState.errorCampaignsInfo = action.result;
  newState.isCampaignsInfoLoading = false;
  newState.isCampaignsInfoLoaded = true;
  return newState;
};

const fetchTotalCampaignsPerformanceRequest = (state, action) => {
  return {
    ...state,
    isTotalCampaignsPerformanceLoading: true,
    isTotalCampaignsPerformanceLoaded: false,
  };
};

const fetchTotalCampaignsPerformanceSuccess = (state, action) => {
  const newState = { ...state };
  let campaignsPerformance = { ...state.totalCampaignsPerformance };
  campaignsPerformance = action.result;
  newState.isTotalCampaignsPerformanceLoaded = true;
  newState.isTotalCampaignsPerformanceLoading = false;
  newState.totalCampaignsPerformance = campaignsPerformance;
  return newState;
};

const fetchTotalCampaignsPerformanceFailed = (state, action) => {
  const newState = { ...state };
  newState.errorTotalCampaignsPerformance = action.result;
  newState.isTotalCampaignsPerformanceLoading = false;
  newState.isTotalCampaignsPerformanceLoaded = true;
  return newState;
};

const fetchTotalRunRatesRequest = (state, action) => {
  return {
    ...state,
    isTotalRunRatesLoading: true,
    isTotalRunRatesLoaded: false,
  };
};

const fetchTotalRunRatesSuccess = (state, action) => {
  const newState = { ...state };
  let listOfRunRates = [...state.totalRunRates];
  listOfRunRates = action.result;
  newState.isTotalRunRatesLoaded = true;
  newState.isTotalRunRatesLoading = false;
  newState.totalRunRates = listOfRunRates;
  return newState;
};

const fetchTotalRunRatesFailed = (state, action) => {
  const newState = { ...state };
  newState.errorTotalRunRates = action.result;
  newState.isTotalRunRatesLoading = false;
  newState.isTotalRunRatesLoaded = true;
  return newState;
};

const fetchTotalResponsesRequest = (state, action) => {
  return {
    ...state,
    isTotalResponsesLoading: true,
    isTotalResponsesLoaded: false,
  };
};

const fetchTotalResponsesSuccess = (state, action) => {
  const newState = { ...state };
  let responses = { ...state.totalResponses };
  responses = action.result;
  newState.isTotalResponsesLoaded = true;
  newState.isTotalResponsesLoading = false;
  newState.totalResponses = responses;
  return newState;
};

const fetchTotalResponsesFailed = (state, action) => {
  const newState = { ...state };
  newState.errorTotalResponses = action.result;
  newState.isTotalResponsesLoading = false;
  newState.isTotalResponsesLoaded = true;
  return newState;
};

const fetchProspectFunnelStatisticsRequest = (state, action) => {
  return {
    ...state,
    isProspectFunnelStatisticsLoading: true,
    isProspectFunnelStatisticsLoaded: false,
  };
};

const fetchProspectFunnelStatisticsSuccess = (state, action) => {
  const newState = { ...state };
  let statistics = { ...state.prospectFunnelStatistics };
  statistics = action.result;
  newState.isProspectFunnelStatisticsLoaded = true;
  newState.isProspectFunnelStatisticsLoading = false;
  newState.prospectFunnelStatistics = statistics;
  return newState;
};

const fetchProspectFunnelStatisticsFailed = (state, action) => {
  const newState = { ...state };
  newState.errorProspectFunnelStatistics = action.result;
  newState.isProspectFunnelStatisticsLoading = false;
  newState.isProspectFunnelStatisticsLoaded = true;
  return newState;
};

const fetchLeadRateRequest = (state, action) => {
  return {
    ...state,
    isLeadRateLoading: true,
    isLeadRateLoaded: false,
  };
};

const fetchLeadRateSuccess = (state, action) => {
  const newState = { ...state };
  let rates = { ...state.leadRate };
  rates = action.result;
  newState.isLeadRateLoaded = true;
  newState.isLeadRateLoading = false;
  newState.leadRate = rates;
  return newState;
};

const fetchLeadRateFailed = (state, action) => {
  const newState = { ...state };
  newState.errorLeadRate = action.result;
  newState.isLeadRateLoading = false;
  newState.isLeadRateLoaded = true;
  return newState;
};

const fetchGlobalAverageRateRequest = (state, action) => {
  return {
    ...state,
    isGlobalAverageRateLoading: true,
    isGlobalAverageRateLoaded: false,
  };
};

const fetchGlobalAverageRateSuccess = (state, action) => {
  const newState = { ...state };
  let globalRates = { ...state.globalAverageRate };
  globalRates = action.result;
  newState.isGlobalAverageRateLoaded = true;
  newState.isGlobalAverageRateLoading = false;
  newState.globalAverageRate = globalRates;
  return newState;
};

const fetchGlobalAverageRateFailed = (state, action) => {
  const newState = { ...state };
  newState.errorGlobalAverageRate = action.result;
  newState.isGlobalAverageRateLoading = false;
  newState.isGlobalAverageRateLoaded = true;
  return newState;
};

const setPreviousOrganizationId = (state, action) => {
  return {
    ...state,
    previousOrganizationId: action.result,
  };
};

const fetchStepsDoneRequest = (state, action) => {
  return {
    ...state,
    isStepsDoneLoading: true,
    isStepsDoneLoaded: false,
  };
};

const fetchStepsDoneSuccess = (state, action) => {
  const newState = { ...state };
  let stepsDone = { ...state.stepsDone };
  stepsDone = action.result;
  newState.isStepsDoneLoaded = true;
  newState.isStepsDoneLoading = false;
  newState.stepsDone = stepsDone;
  return newState;
};

const fetchStepsDoneFailed = (state, action) => {
  const newState = { ...state };
  newState.errorStepsDone = action.result;
  newState.isStepsDoneLoading = false;
  newState.isStepsDoneLoaded = true;
  return newState;
};

const setShowProductTour = (state, action) => {
  const newState = { ...state };
  newState.showProductTour = action.result;
  return newState;
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOTAL_DATA_AND_AUDIENCE_REQUEST:
      return fetchTotalDataAndAudienceRequest(state, action);
    case actionTypes.FETCH_TOTAL_DATA_AND_AUDIENCE_SUCCEEDED:
      return fetchTotalDataAndAudienceSuccess(state, action);
    case actionTypes.FETCH_TOTAL_DATA_AND_AUDIENCE_FAILED:
      return fetchTotalDataAndAudienceFailed(state, action);
    case actionTypes.FETCH_CAMPAIGNS_INFO_REQUEST:
      return fetchCampaignsInfoRequest(state, action);
    case actionTypes.FETCH_CAMPAIGNS_INFO_SUCCEEDED:
      return fetchCampaignsInfoSuccess(state, action);
    case actionTypes.FETCH_CAMPAIGNS_INFO_FAILED:
      return fetchCampaignsInfoFailed(state, action);
    case actionTypes.FETCH_TOTAL_CAMPAIGN_PERFORMANCE_REQUEST:
      return fetchTotalCampaignsPerformanceRequest(state, action);
    case actionTypes.FETCH_TOTAL_CAMPAIGN_PERFORMANCE_SUCCEEDED:
      return fetchTotalCampaignsPerformanceSuccess(state, action);
    case actionTypes.FETCH_TOTAL_CAMPAIGN_PERFORMANCE_FAILED:
      return fetchTotalCampaignsPerformanceFailed(state, action);
    case actionTypes.FETCH_TOTAL_RUN_RATES_REQUEST:
      return fetchTotalRunRatesRequest(state, action);
    case actionTypes.FETCH_TOTAL_RUN_RATES_SUCCEEDED:
      return fetchTotalRunRatesSuccess(state, action);
    case actionTypes.FETCH_TOTAL_RUN_RATES_FAILED:
      return fetchTotalRunRatesFailed(state, action);
    case actionTypes.FETCH_TOTAL_RESPONESES_REQUEST:
      return fetchTotalResponsesRequest(state, action);
    case actionTypes.FETCH_TOTAL_RESPONESES_SUCCEEDED:
      return fetchTotalResponsesSuccess(state, action);
    case actionTypes.FETCH_TOTAL_RESPONESES_FAILED:
      return fetchTotalResponsesFailed(state, action);
    case actionTypes.FETCH_TOTAL_PROESPECT_FUNNEL_REQUEST:
      return fetchProspectFunnelStatisticsRequest(state, action);
    case actionTypes.FETCH_TOTAL_PROESPECT_FUNNEL_SUCCEEDED:
      return fetchProspectFunnelStatisticsSuccess(state, action);
    case actionTypes.FETCH_TOTAL_PROESPECT_FUNNEL_FAILED:
      return fetchProspectFunnelStatisticsFailed(state, action);
    case actionTypes.FETCH_TOTAL_LEAD_RATE_REQUEST:
      return fetchLeadRateRequest(state, action);
    case actionTypes.FETCH_TOTAL_LEAD_RATE_SUCCEEDED:
      return fetchLeadRateSuccess(state, action);
    case actionTypes.FETCH_TOTAL_LEAD_RATE_FAILED:
      return fetchLeadRateFailed(state, action);
    case actionTypes.FETCH_GLOBAL_AVERAGE_RATE_REQUEST:
      return fetchGlobalAverageRateRequest(state, action);
    case actionTypes.FETCH_GLOBAL_AVERAGE_RATE_SUCCEEDED:
      return fetchGlobalAverageRateSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_AVERAGE_RATE_FAILED:
      return fetchGlobalAverageRateFailed(state, action);
    case actionTypes.SET_PREVIOUS_ORGANIZATION_ID:
      return setPreviousOrganizationId(state, action);
      case actionTypes.FETCH_STEPS_DONE_REQUEST:
        return fetchStepsDoneRequest(state, action);
      case actionTypes.FETCH_STEPS_DONE_SUCCEEDED:
        return fetchStepsDoneSuccess(state, action);
      case actionTypes.FETCH_STEPS_DONE_FAILED:
        return fetchStepsDoneFailed(state, action);
        case actionTypes.SET_SHOW_PRODUCT_TOUR:
        return setShowProductTour(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
