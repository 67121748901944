import styles from "../Filters.module.scss";
import { useState } from "react";
import { useGetCompanyNamesAutoCompleteQuery } from "@api/audienceExplorerApi";
import FormikSelect from "@ui/Formik/FormikSelect";
import { useFormikContext } from "formik";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterCompanyName = () => {
  const {
    values: {
      company: { names },
    },
  } = useFormikContext<SearchModel>();
  const [searchInput, setSearchInput] = useState("");
  const { data: options } = useGetCompanyNamesAutoCompleteQuery(searchInput, { skip: searchInput.length < 3 });

  return (
    <FormikSelect
      showValuesOutside={true}
      isMulti={true}
      name="company.names"
      value={names}
      options={options}
      displayName="Select company name"
      placeholder="e.g. Outbase"
      isSearchable={true}
      onInputChange={(input: string) => setSearchInput(input)}
      dropdownButtonClass={styles.selectDropdown}
      isAutoComplete={true}
      allowCustomValues={true}
      allowIncludeExclude={true}
      allowIncludeExcludeForCustomValues
      hideNoOptions={true}
      requireInputToShowOptions
      disabled={names && names.length >= 20}
      enableReinitialize
    />
  );
};

export default FilterCompanyName;
