import React from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";

//Redux
import { PERMISSIONS } from "../../../../helper/permissionConstants";
import { audienceTypeEnum } from "../../../../helper/enums/audienceTypeEnum";

//Images & Icons
import Focus3LineIcon from "remixicon-react/Focus3LineIcon";
import DownloadCloud2LineIcon from "remixicon-react/DownloadCloud2LineIcon";
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import InboxArchiveLineIcon from "remixicon-react/InboxArchiveLineIcon";
import InboxUnarchiveLineIcon from "remixicon-react/InboxUnarchiveLineIcon";
import More2FillIcon from "remixicon-react/More2FillIcon";
import EditBoxLineIcon from "remixicon-react/EditBoxLineIcon";

//Styles
import styles from "./ActionColumn.module.scss";

//Components
import ActionWrapper from "../../../../components/UI/ActionWrapper/ActionWrapper";
import Permission from "../../../../components/auth/Permission";

const ActionColumn = (props) => {
  return (
    <>
      <ActionWrapper wrapperClass={styles.actionButtonWrapper}>
        <>
          {props.audienceBuilderSearchURL}
          {props.isArchived === true ? (
            <div className={props.isInitiated ? "" : styles.notInitiated} onClick={props.onClick}>
              <OverlayTrigger
                key={props.key}
                placement={"bottom"}
                overlay={
                  <Popover id={`upload-tooltip`}>
                    <Popover.Body>Restore</Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <InboxUnarchiveLineIcon size={22} className="iconGray mL5 cursor_pointer" />
                </div>
              </OverlayTrigger>
            </div>
          ) : (
            <>
              {props.type === audienceTypeEnum.File ? (
                <>
                  <Permission has={PERMISSIONS.audience.importAudienceContact}>
                    <OverlayTrigger
                      key={props.key}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`upload-tooltip`}>
                          <Popover.Body>Upload</Popover.Body>
                        </Popover>
                      }
                    >
                      <div onClick={props.uploadOnClick} disabled={props.type === audienceTypeEnum.File ? false : true}>
                        <UploadCloud2LineIcon size={22} className="mR5 iconGray" />
                      </div>
                    </OverlayTrigger>
                  </Permission>
                  <Permission has={PERMISSIONS.audience.exportAudienceContact}>
                    <OverlayTrigger
                      key={props.key}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`download-tooltip`}>
                          <Popover.Body>Download</Popover.Body>
                        </Popover>
                      }
                    >
                      <div onClick={props.downloadOnClick} disabled={props.type === audienceTypeEnum.File ? false : true}>
                        <DownloadCloud2LineIcon size={22} className="iconGray" />
                      </div>
                    </OverlayTrigger>
                  </Permission>

                  <Dropdown drop="start">
                    <Dropdown.Toggle className={styles.dropdownButton} id="dropdown-basic">
                      <More2FillIcon size={18} className="iconGray" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.actionList}>
                      <Dropdown.Item
                        onClick={props.assignToCampaignOnClick}
                        className={`${styles.dropdownItem} ${props.isInitiated ? "" : styles.notInitiated}`}
                      >
                        <EditBoxLineIcon size={18} className="mR5 iconGray" />
                        {props.popoverContent}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={props.onClick}
                        className={`${styles.dropdownItem} ${props.isInitiated ? "" : styles.notInitiated}`}
                      >
                        {" "}
                        <Permission has={PERMISSIONS.audience.archiveRestoreAudience}>
                          <InboxArchiveLineIcon size={18} className="iconGray mR5" />
                          Archive
                        </Permission>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <OverlayTrigger
                    key={props.key}
                    placement={"bottom"}
                    overlay={
                      <Popover id={`assign-tooltip`}>
                        <Popover.Body>View Targeting</Popover.Body>
                      </Popover>
                    }
                  >
                    <div
                      className={props.isInitiated ? "" : styles.notInitiated}
                      onClick={() => {
                        props.setChosenAudience();
                        props.handleTargetingDrawerShow();
                      }}
                    >
                      <Focus3LineIcon size={22} className="iconGray" />
                    </div>
                  </OverlayTrigger>
                  <Dropdown drop="start">
                    <Dropdown.Toggle className={styles.dropdownButton} id="dropdown-basic">
                      <More2FillIcon size={18} className="iconGray" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.actionList}>
                      <Dropdown.Item
                        onClick={props.assignToCampaignOnClick}
                        className={`${styles.dropdownItem} ${props.isInitiated ? "" : styles.notInitiated}`}
                      >
                        <EditBoxLineIcon size={18} className="mR5 iconGray" />
                        {props.popoverContent}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={props.onClick}
                        className={`${styles.dropdownItem} ${props.isInitiated ? "" : styles.notInitiated}`}
                      >
                        {" "}
                        <Permission has={PERMISSIONS.audience.archiveRestoreAudience}>
                          <InboxArchiveLineIcon size={18} className="iconGray mR5" />
                          Archive
                        </Permission>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </>
          )}
        </>
      </ActionWrapper>
    </>
  );
};

export default ActionColumn;
