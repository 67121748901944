import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Styles
import styles from "./SendTestModal.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";

const SendTestModal = (props) => {
  const options = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
  ];

  const selectChange = (event) => {
    if (event != null) {
      if (Array.isArray(event)) {
        toast.success(event.join(","));
      } else {
        toast.success(event);
      }
    } else {
      toast.success("Empty");
    }
  };

  return (
    <Formik
      initialValues={{
        selectDefault: 1,
      }}
      validationSchema={Yup.object({
        text: Yup.string().required("Required"),
        textarea: Yup.string().required("Required"),
        number: Yup.number().required("Required"),
        password: Yup.string().required("Required"),
        select: Yup.number().required("Required"),
        selectDefault: Yup.number().required("Required"),
        selectWithValue: Yup.number().required("Required"),
        selectsearchable: Yup.number().required("Required"),
        selectmulti: Yup.array().of(Yup.number()).required("Required"),
        selectmultisearchable: Yup.array()
          .of(Yup.number())
          .required("Required"),
      })}
      onSubmit={(values, actions) => {
        let data = {
          name: values.campaignName,
        };
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleBlur, handleSubmit, isSubmitting } =
          formikProps;
        return (
          <Modal
            title="Message Preview"
            handleClose={props.handleClose}
            handleSave={props.handleClose}
            show={props.showModal}
            oneButton="true"
            btnType="submit"
            saveButtonText="OK"
            size="lg"
          >
            <>
              <Form className={`${styles.contentBody} flex`}>
                <div className={styles.templateWrapperForm}>
                  <p className={styles.emailDetails}>
                    Template Name: <span>John Doe</span>
                  </p>
                  <FormikInput
                    label="Subject"
                    name="subject"
                    value={values.text}
                    placeholder="This is a text field..."
                    isMenuPortalTargetDisabled={true}
                  />
                  <FormikSelect
                    label="Send From"
                    name="sendFrom"
                    options={options}
                    value={values.select}
                    onChange={selectChange}
                    isMenuPortalTargetDisabled={true}
                  />
                  <FormikSelect
                    label="Send To"
                    name="sendTo"
                    options={options}
                    value={values.select}
                    onChange={selectChange}
                    isMenuPortalTargetDisabled={true}
                  />
                </div>
                <div className={styles.variablesWrapperForm}>
                  <p className={`${styles.variablesTitle} font700`}>
                    Set variables'Value (optional)
                  </p>
                  <FormikInput
                    label="First_Name"
                    name="firstName"
                    value={values.text}
                    placeholder="This is a text field..."
                  />
                  <FormikInput
                    label="Job_title"
                    name="jobTitle"
                    value={values.text}
                    placeholder="This is a text field..."
                  />
                </div>
              </Form>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default SendTestModal;
