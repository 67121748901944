export const couponTypeEnum = {
    percentageOff: 0,
    amountOf: 1 ,
    multiplicator: 2
  };

  export const couponAppliesToEnum = {
    subscription: 0,
    oneTimePurchase: 1
  };

  export const couponProviderEnum = {
    chargify: 0,
    stripe: 1
  };