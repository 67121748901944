import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";

//Helpers
import { timeToDate, dateToTime } from "../../../helper/dateHelper";

//Styles
import styles from "./FormikInput.module.scss";

export const FormikTimePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  let errorElement = null;

  if (meta.touched && meta.error) {
    errorElement = <div className={styles.error}>{meta.error}</div>;
  }

  let value = "";
  let minValue = "";
  let maxValue = "";

  if (props.value != null && props.value !== "") {
    value = timeToDate(props.value);
  }

  if (props.minValue != null && props.minValue !== "") {
    minValue = timeToDate(props.minValue);
  } else {
    minValue = Moment().hours(0).minutes(0).toDate();
  }

  if (props.maxValue != null && props.maxValue !== "") {
    maxValue = timeToDate(props.maxValue);
  } else {
    maxValue = Moment().hours(23).minutes(59).toDate();
  }

  const changeHandler = (event) => {
    const timeEvent = dateToTime(event);
    const timeMin = dateToTime(minValue);
    const timeMax = dateToTime(maxValue);

    if (timeEvent >= timeMin && timeEvent <= timeMax) {
      setFieldValue(field.name, timeEvent);
      if (props.onChange != null) {
        props.onChange(timeEvent);
      }
    }
  };

  return (
    <div className={styles.group + " " + props.formgroupclass}>
      <DatePicker
        id="formikTimePicker"
        selected={value}
        onChange={(date) => changeHandler(date)}
        showTimeSelect
        showTimeSelectOnly
        minTime={minValue}
        maxTime={maxValue}
        timeIntervals={props.timeIntervals}
        timeCaption={props.placeholder}
        disabled={props.disabled}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        className={props.timePickerClass}
      />
      <p>{errorElement}</p>
    </div>
  );
};

export default FormikTimePicker;
