export const columns = [
  {
    label: "Campaign Name",
    field: "name",
    type: "text",
    sort: "asc",
    width: 50,
  },
  {
    label: "Created By",
    field: "CreatedBy",
    type: "text",
    sort: "asc",
    width: 50,
  },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    width: 50,
  },
  {
    label: "Prospects Imported",
    field: "prospectsImported",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Prospects Available",
    field: "prospectsAvailable",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Emails Sent",
    field: "emailsSent",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Prospects Engaged",
    field: "prospectsEngaged",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Opens",
    field: "opens",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Clicks",
    field: "clicks",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Responses",
    field: "responses",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Leads",
    field: "leads",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Status",
    field: "status",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 50,
  },
  {
    label: "Last Active Date",
    field: "lastActiveDate",
    type: "date",
    sort: "asc",
    width: 50,
  },
];
