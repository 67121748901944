import React from "react";
import { Field } from "formik";

//Components
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";

const TestInfoStep = (props) => {
  return (
    <>
      <Field value={props.templateId} type="hidden" />
      <FormikInput
        type="text"
        name="emailTemplateName"
        placeholder="Type here..."
        onChange={props.onChange}
        value={props.emailTemplateName}
        label="Email Template"
        disabled
      />
      <FormikSelect
        name="emailAccountId"
        label="Email account"
        isMenuPortalTargetDisabled={true}
        options={props.optionsListEmailAccounts}
        placeholder="Choose email account"
        value={props.valueEmailAccountId}
      />
      <FormikInput
        type="text"
        name={"subject"}
        label="Email Subject"
        placeholder="Type here..."
        onChange={props.onChange}
        value={props.valuesSubject}
      />
    </>
  );
};

export default TestInfoStep;
