import React, { FC } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDeleteNotificationMutation } from "../../../api/notificationApi";
import { removeNotification } from "../../../store/global/notificationsSlice";
import { useDispatch } from "react-redux";

//Components
import ActionDeleteNotification from "../../UI/ActionWrapper/Actions/ActionDeleteNotification";
import ActionMarkAsRead from "../../UI/ActionWrapper/Actions/ActionMarkAsRead";

//Styles
import styles from "./HeaderNotifications.module.scss";

interface NotificationActionsProps {
  notification: {
    id: number;
    isInAppNotificationRead: boolean;
  };
  markAsRead: (id: number) => void;
}

const NotificationActions: FC<NotificationActionsProps> = ({ notification, markAsRead }) => {
  const dispatch = useDispatch();
  const [deleteNotificationRequest] = useDeleteNotificationMutation();

  const deleteNotification = () => {
    deleteNotificationRequest(notification.id)
      .unwrap()
      .then(() => {
        dispatch(removeNotification(notification.id));
      })
      .catch((error: Error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      {notification.isInAppNotificationRead ? null : (
        <OverlayTrigger
          key={"mar-as-read"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-mark-as-read`}>
              <Popover.Body>Mark as read</Popover.Body>
            </Popover>
          }
        >
          <div className={`${styles.markAsReadAction} ${styles.width15} max-y-content`}>
            <ActionMarkAsRead onClick={() => markAsRead(notification.id)} />
          </div>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        key={"delete"}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-delete`}>
            <Popover.Body className="campaignsStatusColTooltip">Clear Notification</Popover.Body>
          </Popover>
        }
      >
        <div className={`${styles.deleteAction} ${styles.width15} max-y-content`}>
          <ActionDeleteNotification onClick={deleteNotification} style={{ opacity: 0.7 }} />
        </div>
      </OverlayTrigger>
    </>
  );
};

export default NotificationActions;
