import React from "react";

//Styles
import styles from "./Radio.module.scss";

const Radio = ({ icon: Icon, ...props }) => (
  <div className={`${styles.radioWrapper} flex relative`}>
    <label className={`${styles.radioLabel} ${Icon ? styles.radioLabelIcon : ""}`} id={props.id}>
      {props.labelText}
      {props.labelSubText ? (
        <>
          <br />
          <small className={styles.labelSubText}> {props.labelSubText}</small>
        </>
      ) : null}
      <input
        name={props.name}
        onClick={props.onClick}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
        readOnly={props.readOnly}
        type="radio"
        className={styles.inputPozition}
      />
      <span
        className={`${props.labelSubText ? styles.checkmark + " " + styles.top25 : styles.checkmark} ${Icon ? styles.hideWhenIcon : ""}`}
      ></span>{" "}
    </label>
    {Icon && <Icon className={props.checked ? `${props.labelstyle}` : ""} />}
  </div>
);

export default Radio;
