import React from "react";
import { Grid } from "styled-css-grid";
import _ from "lodash";

//Helpers
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { filterData, getFilterValue } from "../../../helper/tableConstants";
// import parse as htmlParse from "html-react-parser";

// Styles
import styles from "./QuestionsAndAnswers.module.scss";

//Images & Icons
import SectionInbox from "../../../assets/Icons/SectionInbox/SectionInbox";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";

// Components
import Button from "../../../components/UI/Button/Button";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Permission from "../../../components/auth/Permission";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import { useDeleteQuestionMutation, useGetCategoriesQuery, useGetQuestionsAndAnswersQuery } from "../../../api/complianceApi";
import ChangeQAForm from "../Modals/ChangeQAForm";
import AddQAForm from "../Modals/AddQAForm";
import useModal from "../../../components/UI/Modal/useModal";
import { Question } from "../../../models/compliance/Question";
import QuestionAccordion from "./QuestionAccordion";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { useSlice } from "../../../helper/hooks/useSlice";
import { useDataTable } from "../../../helper/hooks/useDataTable";
import { questionsDataTable } from "../../../store/registerDataTables";

const QuestionsAndAnswers: React.FC = () => {
  // Api Calls
  const complianceState = useSlice((state) => state.questionsDataTable);
  const dataTable = useDataTable({
    slice: complianceState,
    dtActions: questionsDataTable.actions,
    useQuery: useGetQuestionsAndAnswersQuery,
    filterTypes: ["Status"],
  });
  const {
    state: { rows: questions, params },
    actions: { searchRecords, setFilter },
    query: { isFetching },
  } = dataTable;

  const { data: categories } = useGetCategoriesQuery();
  const [deleteQuestion] = useDeleteQuestionMutation();

  // Modals
  const { isVisible: addModalVisible, show: addModalShow, hide: addModalHide } = useModal();
  const { isVisible: updateModalVisible, show: updateModalShow, hide: updateModalHide, data: updateModalQuestion } = useModal<Question>();

  let selectRowOptions = [
    {
      option: categories,
      change: (c: any) => filterData("Category", c, setFilter),
      placeholder: "Category",
      value: getFilterValue(params.filter, "Category"),
    },
  ];

  return (
    <div className={styles.qasWrapper}>
      <div className="flex align-center">
        <SectionTitle icon={<SectionInbox imgStyle={styles.sectionIcon} />} title={`Compliance Q&A's`} />
        <Permission has={PERMISSIONS.compliance.questionsAndAnswers.create}>
          <Button id="addQuestion" variant="primary" onClick={addModalShow}>
            <AddCircleLineIcon size="18" className="mR5 iconWhite" /> {`Add Q&A`}
          </Button>
        </Permission>
      </div>

      <TableFiltersRow
        selects={selectRowOptions}
        placeholder={`Q&A's`}
        showPerPage={false}
        changeFilter={(text: string) => searchRecords(text)}
      />

      {isFetching && <Spinner />}
      {!isFetching && questions?.length === 0 && <p>No Q&A's found</p>}
      {!isFetching && (
        <div>
          <Grid columns={1}>
            {questions &&
              questions.map((question: Question) => (
                <QuestionAccordion
                  key={question.id}
                  question={question}
                  isCustom={question.organizationId !== 0}
                  updateQuestion={updateModalShow}
                  deleteQuestion={deleteQuestion}
                />
              ))}
          </Grid>
        </div>
      )}

      <Permission has={PERMISSIONS.compliance.questionsAndAnswers.create}>
        <AddQAForm handleClose={addModalHide} showModal={addModalVisible} />
      </Permission>

      <Permission has={PERMISSIONS.compliance.questionsAndAnswers.edit}>
        {!_.isEmpty(updateModalQuestion) && updateModalQuestion?.id > 0 ? (
          <ChangeQAForm handleClose={updateModalHide} showModal={updateModalVisible} model={updateModalQuestion} />
        ) : null}
      </Permission>
    </div>
  );
};

export default QuestionsAndAnswers;
