import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonTableFilterRow = (props) => {
  return (
    <div className="skeleton-wrapper skeleton-table-filters p0 flex w-5">
      <SkeletonElement type="text-33 w-8" />
      <SkeletonElement type="text-33 w-5" />
      <SkeletonElement type="text-33 w-5" />
      <Shimmer />
    </div>
  );
};

export default SkeletonTableFilterRow;
