import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";
import { toast } from "react-toastify";

///////////////FETCH////////////////////////
export const fetchEmailAccountsListRequest = () => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNTS_LIST_REQUEST,
  };
};

export const fetchEmailAccountsListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNTS_LIST_SUCCEEDED,
    result: setup,
  };
};

export const fetchEmailAccountsListFailed = (error) => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNTS_LIST_FAILED,
    result: error,
  };
};

export const fetchEmailAccountsList = (val) => {
  return (dispatch) => {
    dispatch(fetchEmailAccountsListRequest());
    return api(formUrl(API.emailAccounts.listEmailAccounts, { campaignId: val }))
      .then((response) => {
        dispatch(fetchEmailAccountsListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEmailAccountsListFailed(error.message));
      });
  };
};

export const fetchOrganizationEmailAccountsList = () => {
  return (dispatch) => {
    dispatch(fetchEmailAccountsListRequest());
    return api(formUrl(API.emailAccounts.listOrganizationEmailAccounts))
      .then((response) => {
        dispatch(fetchEmailAccountsListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEmailAccountsListFailed(error.message));
      });
  };
};
////////////////SELECT LIST EMAIL ACCOUNT//////////////////////////////
export const fetchEmailAccountsSelectListRequest = () => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNTS_SELECT_LIST_REQUEST,
  };
};

export const fetchEmailAccountsSelectListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNTS_SELECT_LIST_SUCCEEDED,
    result: setup,
  };
};

export const fetchEmailAccountsSelectListFailed = (error) => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNTS_SELECT_LIST_FAILED,
    result: error,
  };
};

export const fetchEmailAccountsSelectList = () => {
  return (dispatch) => {
    dispatch(fetchEmailAccountsSelectListRequest());
    return api(formUrl(API.emailAccounts.selectListEmailAccounts))
      .then((response) => {
        dispatch(fetchEmailAccountsSelectListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEmailAccountsSelectListFailed(error.message));
      });
  };
};

export const fetchListEmailAccountsMultiCampaignsRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_REQUEST,
  };
};

export const fetchListEmailAccountsMultiCampaignsSuccess = (campaigns) => {
  return {
    type: actionTypes.FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_SUCCEEDED,
    result: campaigns,
  };
};

export const fetchListEmailAccountsMultiCampaignsFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_FAILED,
    result: error,
  };
};

export const fetchListEmailAccountsMultiCampaigns = (campaigns) => {
  return (dispatch) => {
    dispatch(fetchListEmailAccountsMultiCampaignsRequest());
    return api(API.emailAccounts.listOfEmailAccountsMultiCampaigns, campaigns)
      .then((response) => {
        dispatch(fetchListEmailAccountsMultiCampaignsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListEmailAccountsMultiCampaignsFailed(error.message));
      });
  };
};

//////////////////////////CONNECT//////////////////////////
export const connectEmailAccountsRequest = () => {
  return {
    type: actionTypes.CONNECT_EMAIL_ACCOUNTS_REQUEST,
  };
};

export const connectEmailAccountsSuccess = (setup) => {
  return {
    type: actionTypes.CONNECT_EMAIL_ACCOUNTS_SUCCEEDED,
    result: setup,
  };
};

export const connectEmailAccountsFailed = (error) => {
  return {
    type: actionTypes.CONNECT_EMAIL_ACCOUNTS_FAILED,
    result: error,
  };
};

export const connectEmailAccounts = (data) => {
  return (dispatch) => {
    dispatch(connectEmailAccountsRequest());
    return api(API.emailAccounts.connect, data)
      .then((response) => {
        dispatch(connectEmailAccountsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(connectEmailAccountsFailed(error.message));
        toast.error(error.message);
      });
  };
};

export const clearInitiated = (type) => {
  return {
    type: actionTypes.EMAIL_ACCOUNTS_CLEAR_INITIATED,
  };
};
