export const purchaseTemplateStatusEnum = {
  Submitted: 0,
  Progress: 1,
  Completed: 2,
};

export const purchaseTemplateStatusDropdown = [
  { value: 0, label: "Submitted" },
  { value: 1, label: "InProgress" },
  { value: 2, label: "Completed" },
];

export const purchaseTemplateStatusText = [
  "Submitted",
  "In Progress",
  "Completed",
];

export const purchaseTemplateStatusVariant = [
  "submitted",
  "inProgress",
  "completed",
];
