import React from "react";
import HubSpotLogo from "./HubSpotLogo/HubSpotLogo";

const CrmLogo = (props) => {
  if (props.integrationName) {
    switch (props.integrationName) {
      case "HubSpot":
        return <HubSpotLogo width="100%" height="100%" />;
      default:
        return "";
    }
  }

  return "";
};

export default CrmLogo;
