import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";
import { getTimeZoneOffset } from "../../../helper/dateHelper";

export const fetchSendingScheduleRequest = () => {
  return {
    type: actionTypes.FETCH_SENDING_SCHEDULE_REQUEST,
  };
};

export const fetchSendingScheduleSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_SENDING_SCHEDULE_SUCCEEDED,
    result: setup,
  };
};

export const fetchSendingScheduleFailed = (error) => {
  return {
    type: actionTypes.FETCH_SENDING_SCHEDULE_FAILED,
    result: error,
  };
};

export const fetchSendingSchedule = (id) => {
  let offset = getTimeZoneOffset();
  return (dispatch) => {
    dispatch(fetchSendingScheduleRequest());
    return api(formUrl(API.sendingSchedule.get, { campaignId: id, utcOffset: offset }))
      .then((response) => {
        dispatch(fetchSendingScheduleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSendingScheduleFailed(error.message));
      });
  };
};
