import * as actionTypes from "./actionTypes";
import api, { API } from "../../../../helper/api/api";

export const fetchResponseTypesRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_RESPONSE_TYPES_REQUEST,
  };
};

export const fetchResponseTypesSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_LIST_RESPONSE_TYPES_SUCCEEDED,
    result: setup,
  };
};

export const fetchResponseTypesFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_RESPONSE_TYPES_FAILED,
    result: error,
  };
};

export const fetchResponseTypes = () => {
  return (dispatch) => {
    dispatch(fetchResponseTypesRequest());
    return api(API.prospects.listResponseTypes)
      .then((response) => {
        dispatch(fetchResponseTypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchResponseTypesFailed(error.message));
      });
  };
};

export const fetchSenderEmailAccountsRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_EMAIL_ACCOUNT_REQUEST,
  };
};

export const fetchSenderEmailAccountsSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_LIST_EMAIL_ACCOUNT_SUCCEEDED,
    result: setup,
  };
};

export const fetchSenderEmailAccountsFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_EMAIL_ACCOUNT_FAILED,
    result: error,
  };
};

export const fetchSenderEmailAccounts = () => {
  return (dispatch) => {
    dispatch(fetchSenderEmailAccountsRequest());
    return api(API.prospects.listSenderEmailAccounts)
      .then((response) => {
        dispatch(fetchSenderEmailAccountsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSenderEmailAccountsFailed(error.message));
      });
  };
};

export const fetchStatusCountRequest = () => {
  return {
    type: actionTypes.FETCH_STATUS_COUNT_REQUEST,
  };
};

export const fetchStatusCountSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_STATUS_COUNT_SUCCEEDED,
    result: setup,
  };
};

export const fetchStatusCountFailed = (error) => {
  return {
    type: actionTypes.FETCH_STATUS_COUNT_FAILED,
    result: error,
  };
};

export const fetchStatusCount = (data) => {
  return (dispatch) => {
    dispatch(fetchStatusCountRequest());
    return api(API.prospects.statuscount, data)
      .then((response) => {
        dispatch(fetchStatusCountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchStatusCountFailed(error.message));
      });
  };
};
