import React from "react";

import UserPhoto from "../../../components/UI/UserPhoto/UserPhoto";

const UserPhotoSandbox = (props) => {
  return (
    <div className="flex">
      <div style={{ marginRight: "25px" }}>
        <p>Small photo</p>
        <UserPhoto
          alt="org-photo"
          isCompanyImage={true}
          size="small"
          src={props.imageUrl}
        />
      </div>
      <div style={{ marginRight: "25px" }}>
        <p>Medium photo</p>
        <UserPhoto
          alt="org-photo"
          isCompanyImage={true}
          size="medium"
          src={props.imageUrl}
        />
      </div>
      <div>
        <p>Large Photo</p>
        <UserPhoto
          alt="org-photo"
          isCompanyImage={true}
          size="large"
          src={props.imageUrl}
        />
      </div>
    </div>
  );
};

export default UserPhotoSandbox;
