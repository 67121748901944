import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./EmailAccountCircle.module.scss";

export const EmailAccountCircle = ({
  isConnected,
  isSuspended,
  reason,
}: {
  isConnected: boolean;
  isSuspended: boolean;
  reason: string | null;
}) => {
  let disconnectReason = "Connection test failed.";

  if (isSuspended) {
    disconnectReason = "Email account is suspended. Please contact customer support.";

    if (reason) {
      disconnectReason += " Reason: " + reason;
    }
  }

  return (
    <OverlayTrigger
      // key={r.name}
      placement={"bottom"}
      overlay={
        isConnected ? (
          <Popover id={`tooltip-status-connected}`}>
            <Popover.Body>Email account test successful!</Popover.Body>
          </Popover>
        ) : (
          <Popover id={`tooltip-status-not-connected}`}>
            <Popover.Body>{disconnectReason}</Popover.Body>
          </Popover>
        )
      }
    >
      <div className="text-overflow-hidden max-x-content">
        {isConnected ? (
          <span className={`${styles.circle} ${styles.connected}`}></span>
        ) : (
          <span className={`${styles.circle} ${styles.notConnected}`}></span>
        )}
      </div>
    </OverlayTrigger>
  );
};
