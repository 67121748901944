import React from "react";

//Components
import Modal from "../../../../components/UI/Modal/Modal";

const EmailConfirmationModal = (props) => {
  const { userEmail } = props;

  return (
    <Modal
      title="Email confirmation"
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      btnType="submit"
      saveButtonText="Send"
      closeButtonText="Cancel"
      size="lg"
    >
      <p>Are you sure you want to send confirmation email to {userEmail}?</p>
    </Modal>
  );
};

export default EmailConfirmationModal;
