import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isLoaded: false,
  error: "",
  userDetails: {},
};

const fetchUserDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchUserDetailsSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.userDetails = action.result;
  return newState;
};

const fetchUserDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.userDetails = null;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_DETAILS_REQUEST:
      return fetchUserDetailsRequest(state, action);
    case actionTypes.FETCH_USER_DETAILS_SUCCESS:
      return fetchUserDetailsSuccess(state, action);
    case actionTypes.FETCH_USER_DETAILS_FAILED:
      return fetchUserDetailsFailed(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
