import FormikSelect from "@ui/Formik/FormikSelect";

import styles from "../Filters.module.scss";
import { useGetCompanySizesQuery } from "@api/audienceExplorerApi";
import { useFormikContext } from "formik";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterCompanySize = () => {
  const {
    values: {
      company: { sizes },
    },
  } = useFormikContext<SearchModel>();
  const listCompanySizes = useGetCompanySizesQuery();

  return (
    <FormikSelect
      showValuesOutside={true}
      menuId="recommendedCompanySize"
      isMulti={true}
      name="company.sizes"
      value={sizes}
      options={listCompanySizes && listCompanySizes.data ? listCompanySizes.data : []}
      displayName="Select company sizes"
      placeholder="e.g. 51-200 employees"
      isSearchable={true}
      enableReinitialize={true}
      dropdownButtonClass={styles.selectDropdown}
    />
  );
};

export default FilterCompanySize;
