import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

//Redux
import { fetchProspectDetails } from "@store/ProspectActivityOverview/actions/prospectActivityOverview";
import { setFilter, clearFilter } from "@store/ProspectActivityOverview/actions/prospectActivityOverview";

// Style
import styles from "./ProspectActivityOverview.module.scss";

// Components
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import PageContentWrapper from "@components/PageContentWrapper/PageContentWrapper";
import ProspectDetails from "./components/ProspectDetails/ProspectDetails";
import EventTimeline from "./components/EventTimeline/EventTimeline";

const ProspectActivityOverview = (props) => {
  const { fullName } = props.prospectDetails;

  let { prospectId } = useParams();
  if (prospectId == null) prospectId = 0;

  const [params, setParams] = useState(null);

  useEffect(() => {
    props.fetchProspectDetails(prospectId);
  }, [prospectId]);

  return (
    <PageContentWrapper className={styles.prospectActivityWrapper}>
      <HeaderTitle title={fullName ? fullName : ""} />
      <TableFiltersRow leftContent={null} showPerPage={false} />
      <div className="flex">
        <div className={`${styles.eventTimeline} relative`}>
          <EventTimeline setParams={setParams} id={prospectId} />
        </div>
        <div className={`${styles.prospectDetails} border-5`}>
          <ProspectDetails id={prospectId} prospectDetails={props.prospectDetails} />
        </div>
      </div>
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.prospectActivityOverview.statuses,
    prospectDetails: state.prospectActivityOverview.prospectDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    fetchProspectDetails: (id) => dispatch(fetchProspectDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectActivityOverview);
