import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { changeSalesDocumentsDocumentSuccess } from "../../../store/SalesDocuments/actions/salesDocuments";

// Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";

// Icons
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";

// Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import DocumentUploader from "../../../components/UI/FileUploader/DocumentUploader";
import FormikTags from "@ui/Formik/FormikTags";

const ChangeDocumentForm = (props) => {
  const { id, tags, comment, name, showModal, handleClose } = props;
  const [currentDocumentName, setCurrentDocumentName] = useState(name);

  useEffect(() => {
    if (showModal) {
      setCurrentDocumentName(name);
    }
  }, [name, showModal]);

  return (
    <Formik
      initialValues={{
        Id: id,
        Document: null,
        Tags: tags,
        Comment: comment,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        Id: Yup.number().required("Required"),
      }).required("Required")}
      onSubmit={(values, actions) => {
        const formData = new FormData();
        formData.append("id", values.Id);
        if (values.Document) {
          formData.append("document", values.Document);
        }
        for (let index = 0; index < values.Tags.length; index++) {
          formData.append(`tags[${index}]`, values.Tags[index]);
        }
        formData.append("comment", values.Comment);

        api(API.salesDocument.updateDocument, formData)
          .then((response) => {
            props.changeSalesDocumentsDocumentSuccess(response.data);
            handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, handleChange, values, resetForm, dirty } = formikProps;

        const handleModalClose = () => {
          resetForm();
          setCurrentDocumentName(name);

          handleClose();
        };

        const handleDeleteDocument = () => {
          setFieldValue("Document", null);
          setCurrentDocumentName("");
        };

        return (
          <Modal
            title="Update file"
            handleClose={handleModalClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting || !dirty}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Field value={props.id} type="hidden" />
              <div className="flex align-center">
                <DocumentUploader
                  buttonText="Your computer"
                  variant="primary"
                  handleFile={(file) => {
                    setFieldValue("Document", file);
                    setCurrentDocumentName(file.name);
                  }}
                  disabled={!!currentDocumentName}
                />
                <div className="mL10">
                  <span>{currentDocumentName || "No document uploaded"}</span>
                  {currentDocumentName && (
                    <DeleteBinLineIcon size={16} className="iconGray cursor_pointer mL10" onClick={handleDeleteDocument} />
                  )}
                </div>
              </div>
              <label className="f14 mT10">Tags: </label>
              <FormikTags
                id="tags"
                placeholder="Add tags here..."
                name="Tags"
                content="Type a tag, then press enter. You can add multiple tags."
                onChange={handleChange}
              />
              <FormikInput
                label="Comment: "
                name="Comment"
                type="textarea"
                placeholder="Comment"
                value={values.Comment}
                onChange={handleChange}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSalesDocumentsDocumentSuccess: (document) => {
      dispatch(changeSalesDocumentsDocumentSuccess(document));
    },
  };
};

export default connect(null, mapDispatchToProps)(ChangeDocumentForm);
