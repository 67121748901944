import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

//Redux
import { searchRecords } from "../../store/GlobalSearch/actions/globalSearch";
import { fetchCreditInfo, fetchBillingInfo } from "../../store/BillingAndPayment/actions/billingAndPayment";
import { fetchOrganizationOnboarding } from "../../store/OrganizationSettings/General/actions/general";
import { setShowProductTour, fetchStepsDone } from "../../store/Dashboard/Global/actions/dashboard";

//Helpers
import { activeApplicationEnum } from "../../helper/enums/activeApplicationEnum";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { permissionHelper } from "../../helper/permissionHelper";
import SignalRContext from "../../helper/SignalRContext";
import { SIGNALR_CONSTANTS } from "../../helper/signalrConstants";

//Images & Icons
import SearchLineIcon from "remixicon-react/SearchLineIcon";
import QuestionLineIcon from "remixicon-react/QuestionLineIcon";

// Styles
import styles from "./Header.module.scss";

//Components
import LoggedInUser from "./LoggedInUser/LoggedInUser";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import HeaderNotifications from "./HeaderNotifications/HeaderNotifications";
import CreditUsage from "./CreditUsage/CreditUsage";
import Permission from "../../components/auth/Permission";
import ImportProgress from "./ImportProgress/ImportProgress";
import Separator from "./Separator/Separator";
import InfoLinks from "./InfoLinks/InfoLinks";
import WelcomeModal from "../../pages/Dashboard/Components/WelcomeModal/WelcomeModal";
import CheckListProgressBar from "./CheckListProgressBar/CheckListProgressBar";

const Header = (props) => {
  const {
    fetchCreditInfo,
    creditInfo,
    organizationId,
    fetchBillingInfo,
    stepsDone,
    showProductTour,
    fetchStepsDone,
    setShowProductTour,
    isStepsDoneLoaded,
    currentApplication,
    fetchOrganizationOnboarding,
  } = props;

  const { currentCredits, monthlyCredits, remainingDays, plan } = creditInfo;

  useEffect(() => {
    if (props.currentApplication === activeApplicationEnum.Outbase) {
      if (permissionHelper(props.userRole, PERMISSIONS.credits.view)) fetchCreditInfo();
    }
  }, []);

  useEffect(() => {
    if (currentApplication === activeApplicationEnum.Outbase && organizationId > 0) {
      fetchOrganizationOnboarding();
    }
  }, [currentApplication, organizationId, fetchOrganizationOnboarding]);

  useEffect(() => {
    setShowSearch(false);
  }, [creditInfo, currentCredits]);

  useEffect(() => {
    if (props.user.userAccessRemoved) {
      props.history.push("/logout");
    }
  }, [props.user]);

  useEffect(() => {
    if (organizationId > 0) {
      if (permissionHelper(props.userRole, PERMISSIONS.credits.view)) fetchCreditInfo();
      if (permissionHelper(props.userRole, PERMISSIONS.billingPayment.view)) fetchBillingInfo();
    }
  }, [organizationId]);

  // Check List modal commented for now

  // useEffect(() => {
  //   if (isStepsDoneLoaded && showProductTour) {
  //     setShowWelcomeModal(true);
  //   }
  // }, [stepsDone, isStepsDoneLoaded]);

  useEffect(() => {
    if (showProductTour) fetchStepsDone();
  }, [showProductTour]);

  const signalRConnection = useContext(SignalRContext);
  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.OUTBASE_ACCESS_REMOVED, (data) => {
        if (data.userId === props.user.id) {
          props.history.push("/logout");
        }
      });

      signalRConnection.on(SIGNALR_CONSTANTS.ORGANIZATION_UPDATED, (data) => {
        props.fetchOrganizationOnboarding();
      });
    }

    return () => {
      if (signalRConnection != null) {
        try {
          signalRConnection.off(SIGNALR_CONSTANTS.ORGANIZATION_UPDATED);
          signalRConnection.off(SIGNALR_CONSTANTS.OUTBASE_ACCESS_REMOVED);
        } catch {}
      }
    };
  }, [signalRConnection]);

  const [showSearch, setShowSearch] = useState(false);

  // welcome modal
  // const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  // Check List modal commented for now
  // const handleWelcomeModalClose = () => {
  //   setShowWelcomeModal(false);
  //   setShowProductTour(false);
  // };

  let timeout = 0;
  let rightWidth = null;
  document.body.classList.contains("sidebarClosed") ? (rightWidth = window.innerWidth - 720) : (rightWidth = window.innerWidth - 900);

  const globalSearch = (value) => {
    if (timeout !== 0) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (value.length >= 3) {
        setShowSearch(true);
        props.searchRecords(value);
      } else {
        setShowSearch(false);
      }
    }, 500);
  };

  let outbaseHeaderContent = null;

  if (props.organizationOnboardingModel?.onboardingCompleted) {
    outbaseHeaderContent = (
      <>
        <Permission has={PERMISSIONS.audience.view}>
          <ImportProgress orgId={props.organizationId} />
        </Permission>
        <Permission has={PERMISSIONS.credits.view}>
          <CreditUsage
            {...props}
            current={currentCredits}
            total={monthlyCredits}
            now={currentCredits}
            plan={plan}
            remainingDays={remainingDays}
            orgId={props.organizationId}
            ariaLabel="Remaining days"
          />
        </Permission>
        {/* <div className={styles.separator}></div>
        <HeaderNotifications /> */}
      </>
    );
  } else {
    // Waiting for onboarding data before showing anything
    if (props.organizationOnboardingModel == null) {
      outbaseHeaderContent = null;
    } else if (
      props.organizationOnboardingModel.onboardingCompleted != null &&
      props.organizationOnboardingModel.onboardingCompleted === false
    ) {
      outbaseHeaderContent = <CheckListProgressBar />;
    }
  }

  return (
    <div className={styles.noPadding}>
      <section className={`${styles.header} topNavigation`}>
        <Row className={`${styles.headerNav}`}>
          {/* {props.currentApplication === activeApplicationEnum.Outbase ? (
            <>
              <Col md={3} className={styles.searchDiv}>
                <div className={styles.searchWrapper}>
                  <SearchLineIcon size={14} className={`iconGray400 ${styles.searchICon}`} />
                  <input
                    className={styles.headerSearch}
                    type="text"
                    placeholder="Search"
                    tabIndex="-1"
                    onKeyUp={(e) => globalSearch(e.target.value)}
                  />
                </div>

                <GlobalSearch
                  customStyle={showSearch ? styles.showSearch : styles.hideSearch}
                  rightWidth={rightWidth}
                  hrefClicked={() => setShowSearch(false)}
                />
              </Col>
            </>
          ) : null} */}
          <Col md={9} className={styles.headerBtns}>
            <div className="d-flex mr-0 flex1">
              <div className={`${styles.notificationContainer} flex flex2`}>
                {props.currentApplication === activeApplicationEnum.Outbase ? (
                  <>
                    {outbaseHeaderContent} <div className={styles.separator}></div>
                    <HeaderNotifications />
                  </>
                ) : null}
                <div className={styles.infoIconWrapper}>
                  <QuestionLineIcon className="iconGray400" />
                  <InfoLinks setShowProductTour={setShowProductTour} dropdownClass={styles.infoIconDropdown} />
                </div>
                <Separator />
              </div>
              <LoggedInUser customStyle={styles.accountDetailsDropdown} history={props.history} />
              {/* <WelcomeModal handleClose={handleWelcomeModalClose} showModal={showWelcomeModal} stepsDone={stepsDone} /> */}
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationId: state?.user?.organization?.id ?? 0,
    organizationName: state?.user?.organization?.name ?? "",
    creditInfo: state.billingAndPayment.creditInfo,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
    userRole: state?.user?.organization?.role ?? null,
    stepsDone: state.dashboard.stepsDone,
    showProductTour: state.dashboard.showProductTour,
    isStepsDoneLoaded: state.dashboard.isStepsDoneLoaded,
    organization: state.user.organization,
    organizationOnboardingModel: state.organizationSettingsGeneral.organizationOnboardingModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRecords: (value) => dispatch(searchRecords(value)),
    fetchCreditInfo: () => dispatch(fetchCreditInfo()),
    fetchBillingInfo: () => dispatch(fetchBillingInfo()),
    setShowProductTour: (val) => dispatch(setShowProductTour(val)),
    fetchStepsDone: () => dispatch(fetchStepsDone()),
    fetchOrganizationOnboarding: () => dispatch(fetchOrganizationOnboarding()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
