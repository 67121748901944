import React, { useState, ChangeEvent } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";

//Helpers
import { templateVariableToEditorType } from "@helper/enums/templateVariableToEditorTypeEnum";

//Styles
import styles from "./EmailTemplates.module.scss";

//Components
import FormikRadio from "@components/UI/Formik/FormikRadio";
import Modal from "@components/UI/Modal/Modal";

interface Variable {
  id: string;
  text: string;
  example?: string;
}

interface Props {
  variable: Variable;
  addVariableToEditor: (variableText: string) => void;
  handleClose: () => void;
  showModal: boolean;
}

interface FormValues {
  variableToEditorType: number;
}

const AddVariableToEditorForm: React.FC<Props> = (props) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>({});
  const [disableButton, setDisableButton] = useState(false);
  const [showErrorOptionalMessage, setShowErrorOptionalMessage] = useState(false);

  const checkIfIsChecked = (field: string, value: string) => {
    return field === value;
  };

  return (
    <Formik
      initialValues={{
        variableToEditorType: templateVariableToEditorType.mandatoryVariable,
      }}
      enableReinitialize={true}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        actions.setSubmitting(true);
        var variableText = props.variable.text;

        if (values.variableToEditorType === templateVariableToEditorType.mandatoryVariable) {
          props.addVariableToEditor(variableText);
        } else {
          const optionalValue = selectedValues[props.variable.id];
          if (optionalValue) {
            variableText = variableText.replace("}}", "|") + optionalValue + "}}";
          }
          props.addVariableToEditor(variableText);
        }
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, values } = formikProps;

        return (
          <Modal
            title={"Add variable"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText={"Apply"}
            saveButtonDisabled={isSubmitting || disableButton}
            btnType="submit"
            size="lg"
          >
            {props.variable !== null ? (
              <Container className={styles.templateVariables}>
                <Row className={styles.templateVariablesRowModal}>
                  <Col xl={12}>
                    <FormikRadio
                      type="radio"
                      name="MandatoryVariable"
                      labelText="Required parameter"
                      labelstyle="m0"
                      onClick={() => {
                        setFieldValue("variableToEditorType", templateVariableToEditorType.mandatoryVariable);
                        setDisableButton(false);
                      }}
                      checked={checkIfIsChecked(
                        String(values.variableToEditorType),
                        String(templateVariableToEditorType.mandatoryVariable)
                      )}
                    />
                    <p className="f14 fontNormal">
                      This field is mandatory. If a prospect record does not include it the email message will not be sent
                    </p>
                  </Col>
                  <Col xl={12}>
                    <FormikRadio
                      type="radio"
                      name="NotMandatoryVariable"
                      labelText="Optional parameter"
                      labelstyle="m0"
                      onClick={() => {
                        setFieldValue("variableToEditorType", templateVariableToEditorType.notMandatoryVariable);
                        setDisableButton(true);
                      }}
                      checked={checkIfIsChecked(
                        String(values.variableToEditorType),
                        String(templateVariableToEditorType.notMandatoryVariable)
                      )}
                    />
                    <p className="f14 fontNormal">
                      If a prospect record does not include this field then the default value will be used and the email sent
                    </p>
                  </Col>

                  {values.variableToEditorType === templateVariableToEditorType.mandatoryVariable ? (
                    <Col xl={12}>
                      <div className={styles.variableText}>
                        <h6>
                          {props.variable.text}
                          {props.variable.example != null ? (
                            <>
                              <br />
                              <small>{props.variable.example}</small>
                            </>
                          ) : null}
                        </h6>
                      </div>
                    </Col>
                  ) : (
                    <Col xl={12}>
                      <div className={styles.variableText}>
                        <div className={styles.optionalVariableText}>
                          <div className="flex">
                            {props.variable.text.replace("}}", "  |")}
                            <div className="mL5 flex_column">
                              <input
                                type="text"
                                placeholder="Optional value"
                                tabIndex={-1}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  const newValue = event.target.value;
                                  setSelectedValues((prevValues) => ({
                                    ...prevValues,
                                    [props.variable.id]: newValue,
                                  }));
                                  const isInvalid = newValue === "" || newValue.length > 50;
                                  setDisableButton(isInvalid);
                                  setShowErrorOptionalMessage(isInvalid);
                                }}
                              />
                              {showErrorOptionalMessage && <small className={styles.errorMsg}>Input cannot exceed 50 characters.</small>}
                            </div>
                          </div>
                          {props.variable.example != null ? (
                            <>
                              <br />
                              <small>
                                Example: {props.variable.example}
                                <span className={styles.orangeSpan}>{" OR "}</span>
                                {selectedValues[props.variable.id]}
                              </small>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            ) : null}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddVariableToEditorForm;
