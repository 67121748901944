import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { columns } from "./columns";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { organizationTypeEnum, organizationTypeOptionsString } from "../../../helper/enums/organizationTypeEnum";
import { organizationStatusEnum, organizationStatusOptionsString } from "../../../helper/enums/organizationStatus";

//Redux
import {
  fetchEmailAccounts,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../store/Outboss/EmailAccounts/Table/actions/emailAccounts";

//Helpers
import api, { API, formUrl } from "../../../helper/api/api";
import { filtersToQueryString, filterData, getFilterValue } from "../../../helper/tableConstants";
import { useQueryTableFilters } from "../../../helper/hooks/useQueryTableFilters";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { emailAccountTypeEnum, emailAccountTypeOptionsString } from "../../../helper/enums/emailAccountTypeEnum";
import { campaignStatusEnum, campaignStatusOptionsString } from "../../../helper/enums/campaignStatusEnum";

//Style
import styles from "./EmailAccounts.module.scss";

//Images & Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

//Components
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import Table from "../../../components/DataTable/Table";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import TableOptionsRow from "../../../components/DataTable/TableOptionsRow/TableOptionsRow";
import Button from "../../../components/UI/Button/Button";
import { EmailAccountCircle } from "../../../components/EmailAccountCircle/EmailAccountCircle";
import ActionLoginAsAdmin from "../../../components/UI/ActionWrapper/Actions/ActionLoginAsAdmin";
import ActionUnstuckEmailAccount from "../../../components/UI/ActionWrapper/Actions/ActionUnstuckEmailAccount";
import ResetSyncModal from "./ResetSyncModal";
import SyncModal from "./SyncModal";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";
import useModal from "@ui/Modal/useModal";
import RemoveSuspensionModal from "./RemoveSuspensionModal";
import SuspendAccountModal from "./SuspendAccountModal";

const EmailAccounts = (props) => {
  const { param, rows, fetchEmailAccounts, isInitiated, initiate, clearInitiated } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  let filterTypes = ["Type", "CampaignStatus", "OrgType", "OrgStatus"];
  let state = useQueryTableFilters(filterTypes);

  const [tableRows, setTableRows] = useState([]);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);

  let minDate = moment.utc("0001-01-01").format(dateFormat);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  let chosenColumns = dataTableColumns.map((column) => column.label);

  useEffect(() => {
    initiate(state);

    return () => {
      clearInitiated();
    };
  }, []);

  const applyPredefinedFilters = () => {
    if (getFilterValue(param.filter, "OrgType") == null) {
      filterData(
        "OrgType",
        [organizationTypeEnum.Client.toString(), organizationTypeEnum.Incubator.toString(), organizationTypeEnum.Internal.toString()],
        props.setFilter
      );
    }

    if (getFilterValue(param.filter, "CampaignStatus") == null) {
      filterData("CampaignStatus", [campaignStatusEnum.active.toString()], props.setFilter);
    }

    if (getFilterValue(param.filter, "OrgStatus") == null) {
      filterData(
        "OrgStatus",
        [organizationStatusEnum.active.toString(), organizationStatusEnum.pendingRenewal.toString()],
        props.setFilter
      );
    }

    if (getFilterValue(param.filter, "Type") == null) {
      filterData(
        "Type",
        [emailAccountTypeEnum.SmtpImap.toString(), emailAccountTypeEnum.Gmail.toString(), emailAccountTypeEnum.Microsoft.toString()],
        props.setFilter
      );
    }
  };

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
    };
    initiate(updateState);
  };

  const exportEmailAccounts = () => {
    let data = {
      chosenColumns: dataTableColumns.map((column) => column.field),
      param: param,
    };
    api(API.OUTBOSS.emailAccounts.exportEmailAccounts, data, "blob")
      .then((res) => {
        let now = moment.utc().format("YYYY MMM DD");
        fileDownload(`Export email accounts (${now}).csv`, res.data);
        setExportButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (isInitiated) {
      applyPredefinedFilters();
      fetchEmailAccounts(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  const [showResetSyncModal, setShowResetSyncModal] = useState(false);
  const [resetSyncEmailAccountId, setResetSyncEmailAccountId] = useState(0);
  const [resetSyncEmail, setResetSyncEmail] = useState("");

  const handleShowResetSyncModal = (id, email) => {
    setResetSyncEmailAccountId(id);
    setResetSyncEmail(email);
    setShowResetSyncModal(true);
  };

  const handleCloseResetSyncModal = () => {
    setShowResetSyncModal(false);
  };

  const resetEmailAccountClickHandler = () => {
    setShowResetSyncModal(false);
    api(
      formUrl(API.OUTBOSS.emailAccounts.resetSync, {
        id: resetSyncEmailAccountId,
      })
    )
      .then((response) => {
        toast.success("Inbox reset successful.");
        fetchEmailAccounts(param);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [syncEmailAccountId, setSyncEmailAccountId] = useState(0);
  const [syncEmail, setSyncEmail] = useState("");

  const handleShowSyncModal = (id, email) => {
    setSyncEmailAccountId(id);
    setSyncEmail(email);
    setShowSyncModal(true);
  };

  const handleCloseSyncModal = () => {
    setShowSyncModal(false);
  };

  const syncEmailAccountClickHandler = (date) => {
    setShowSyncModal(false);
    let data = {
      id: syncEmailAccountId,
      date: date,
    };
    api(API.OUTBOSS.emailAccounts.syncFromDate, data)
      .then((response) => {
        toast.success("Inbox sync enqueued.");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const fetchEmailAccountsCallback = () => {
    fetchEmailAccounts(param);
  };

  const removeSuspensionModal = useModal();
  const suspendAccountModal = useModal();

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r, i) => {
      let email = r.email;
      let actions = (
        <ActionWrapper>
          <div className={styles.iconsWrapper}>
            <div className="flex mB5 mT5">
              <ActionLoginAsAdmin key={"r.id" + i} organizationId={r.id} redirectToUrl={`email-accounts/${r.id}/edit`} />
              <ActionUnstuckEmailAccount onClick={() => handleShowResetSyncModal(r.id, email)} />
            </div>
            <div className="flex">
              <Button id="syncBtn" variant="primary-s" classes={styles.syncBtn} onClick={() => handleShowSyncModal(r.id, email)}>
                Sync
              </Button>
              {r.isSuspended ? (
                <Button
                  classes={styles.suspendBtn}
                  id="removeSuspensionBtn"
                  variant="warning-s"
                  onClick={() => removeSuspensionModal.show({ id: r.id, email })}
                >
                  Unsuspend
                </Button>
              ) : (
                <Button
                  classes={styles.suspendBtn}
                  id="suspendBtn"
                  variant="warning-s"
                  onClick={() => suspendAccountModal.show({ id: r.id, email })}
                >
                  Suspend
                </Button>
              )}
            </div>
          </div>
        </ActionWrapper>
      );
      r.actions = actions;

      if (r.lastSync && r.lastSync !== null) {
        let lastSyncFormatted = moment(r.lastSync).format("DD MMM YYYY");
        if (lastSyncFormatted !== minDate) {
          r.lastSync = (
            <span className="textLeft">
              {moment.utc(r.lastSync).local().format("DD MMM YYYY HH:mm:ss")} (<i>{moment.utc(r.lastSync).local().fromNow()}</i>)
            </span>
          );
        } else {
          r.lastSync = "-";
        }
      } else {
        r.lastSync = "-";
      }

      if (r.lastDateUsed && r.lastDateUsed !== null) {
        let lastDateUsedFormatted = moment(r.lastDateUsed).format("DD MMM YYYY");
        if (lastDateUsedFormatted > minDate) {
          r.lastDateUsed = (
            <span className="textLeft">
              {moment.utc(r.lastDateUsed).local().format("DD MMM YYYY HH:mm:ss")} (<i>{moment.utc(r.lastDateUsed).local().fromNow()}</i>)
            </span>
          );
        } else {
          r.lastDateUsed = "-";
        }
      } else {
        r.lastDateUsed = "-";
      }

      let errorColor = "#CCC";
      if (r.errors > 10) {
        errorColor = "#D50000";
      } else if (r.errors > 0) {
        errorColor = "#F4511E";
      }
      r.errors = <span style={{ color: errorColor, fontWeight: "bold", fontSize: "16px" }}>{r.errors}</span>;

      r.status = (
        <div className="flex align-center">
          <EmailAccountCircle isConnected={r.isConnected} isSuspended={r.isSuspended} reason={r.suspensionReason} />
          <OverlayTrigger
            key={"key"}
            placement={"bottom"}
            overlay={
              <Popover id={`popover-tooltip`}>
                <Popover.Body>{r.isConnected ? "Connected" : r.isSuspended ? "Suspended" : "Not connected"}</Popover.Body>
              </Popover>
            }
          >
            <div className="textOverflow mL5">{r.isConnected ? "Connected" : r.isSuspended ? "Suspended" : "Not connected"}</div>
          </OverlayTrigger>
        </div>
      );

      r.email = (
        <Link
          className={`${styles.link} ${r.isNotWorking ? styles.accountError : ""}`}
          to={{ pathname: `/outboss/email-accounts/${r.id}/details` }}
        >
          {r.email}
        </Link>
        // <Link className={styles.link} to={{ pathname: `/${r.organizationId}/email-accounts/${r.id}/edit` }}>
        //   {r.email}
        // </Link>
      );

      r.organizationName = (
        <Link className={styles.link} to={{ pathname: `/${r.organizationId}/email-accounts/` }}>
          {r.organizationName}
        </Link>
      );

      r.isInProgress = r.isInProgress === true ? <CheckboxCircleFillIcon size={20} color="var(--first-stage-color)" /> : ""; //<CrossMark />;
      r.isQueued = r.isQueued === true ? <CheckboxCircleFillIcon size={20} color="var(--first-stage-color)" /> : ""; //<CrossMark />;

      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>{props.error}</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  let tableFiltersRow = null;
  let tableOptionsRow = null;

  let selects = [
    {
      option: emailAccountTypeOptionsString,
      change: (o) => filterData("Type", o, props.setFilter),
      placeholder: "Email account type",
      value: getFilterValue(param.filter, "Type") ?? [
        emailAccountTypeEnum.SmtpImap.toString(),
        emailAccountTypeEnum.Gmail.toString(),
        emailAccountTypeEnum.Microsoft.toString(),
      ],
    },
    {
      option: campaignStatusOptionsString,
      change: (o) => filterData("CampaignStatus", o, props.setFilter),
      placeholder: "Campaign status",
      value: getFilterValue(param.filter, "CampaignStatus") ?? [campaignStatusEnum.active.toString(), campaignStatusEnum.paused.toString()],
    },
    {
      option: organizationTypeOptionsString,
      change: (o) => filterData("OrgType", o, props.setFilter),
      placeholder: "Organization type",
      value: getFilterValue(param.filter, "OrgType") ?? [
        organizationTypeEnum.Client.toString(),
        organizationTypeEnum.Incubator.toString(),
        organizationTypeEnum.Internal.toString(),
      ],
    },
    {
      option: organizationStatusOptionsString,
      change: (o) => filterData("OrgStatus", o, props.setFilter),
      placeholder: "Organization status",
      value: getFilterValue(param.filter, "OrgStatus") ?? [
        organizationStatusEnum.active.toString(),
        organizationStatusEnum.pendingRenewal.toString(),
      ],
    },
  ];

  if (props.isLoaded) {
    table = (
      <Table
        outBoss
        tableName={"EmailAccounts"}
        columns={dataTableColumns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        variant="extraLarge"
        tableHeight={260}
        filterData={filterData}
        setFilter={props.setFilter}
        tableFooterClass="datePickerTable"
      />
    );

    tableFiltersRow = (
      <div className={styles.hideInternalUsers}>
        <TableFiltersRow
          selects={selects}
          searchValue={param.search}
          placeholder="email..."
          changeFilter={(text) => props.searchRecords(text)}
          searchOnFirstCharacter={true}
        />
        {/* <Checkbox
          onChange={(checked) => hideInternalUsersHandler(checked)}
          checked={hideInternalUsers}
          label={"Hide internal users"}
          checkBoxClass="mB10"
        /> */}
      </div>
    );

    tableOptionsRow = (
      <TableOptionsRow
        applyChanges={applyDataTableColumnsChange}
        exportTable={exportEmailAccounts}
        exportButtonDisabled={exportButtonDisabled}
        setExportButtonDisabled={setExportButtonDisabled}
        importData={null}
        dataTableColumns={dataTableColumns}
        setDataTableColumns={setDataTableColumns}
        allDataTableColumns={columns}
      />
    );
  }

  return (
    <PageContentWrapper className={styles.contentWrapper}>
      <HeaderTitle title="Email accounts" />
      {tableFiltersRow}
      {tableOptionsRow}
      {table}

      <ResetSyncModal
        showModal={showResetSyncModal}
        handleClose={handleCloseResetSyncModal}
        handleSave={resetEmailAccountClickHandler}
        email={resetSyncEmail}
      />

      <SyncModal
        showModal={showSyncModal}
        handleClose={handleCloseSyncModal}
        handleSave={(date) => syncEmailAccountClickHandler(date)}
        email={syncEmail}
      />

      <RemoveSuspensionModal
        showModal={removeSuspensionModal.isVisible}
        handleClose={removeSuspensionModal.hide}
        emailAccountId={removeSuspensionModal?.data?.id}
        email={removeSuspensionModal?.data?.email}
        fetchEmailAccountsCallback={fetchEmailAccountsCallback}
      />

      <SuspendAccountModal
        showModal={suspendAccountModal.isVisible}
        handleClose={suspendAccountModal.hide}
        emailAccountId={suspendAccountModal?.data?.id}
        email={suspendAccountModal?.data?.email}
        fetchEmailAccountsCallback={fetchEmailAccountsCallback}
      />
    </PageContentWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    param: state.outbossEmailAccounts.params,
    isInitiated: state.outbossEmailAccounts.isInitiated,
    isLoading: state.outbossEmailAccounts.isLoading,
    isLoaded: state.outbossEmailAccounts.isLoaded,
    error: state.outbossEmailAccounts.error,
    rows: state.outbossEmailAccounts.rows,
    totalCount: state.outbossEmailAccounts.totalCount,
    pageCount: state.outbossEmailAccounts.pageCount,
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailAccounts: (param) => dispatch(fetchEmailAccounts(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAccounts);
