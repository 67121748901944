import React from "react";

const IconDecline = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.45455 16L0 19.5V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V15C20 15.5523 19.5523 16 19 16H4.45455ZM2 15.3851L3.76282 14H18V2H2V15.3851ZM11.4142 8L13.8891 10.4749L12.4749 11.8891L10 9.4142L7.52513 11.8891L6.11091 10.4749L8.5858 8L6.11091 5.52513L7.52513 4.11091L10 6.58579L12.4749 4.11091L13.8891 5.52513L11.4142 8Z"
      fill="#5C656F"
    />
  </svg>
);

export default IconDecline;
