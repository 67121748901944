import React from "react";

//Helpers
import { templateGoalOptions } from "../../../../helper/enums/templateGoalOptions";
import { templateToneOptions } from "../../../../helper/enums/templateToneOptions";
import { followUpTypeSettingsOptions } from "../../../../helper/enums/followUpTypeSettingsOptions";

//Styles
import styles from "./FollowUpEmailType.module.scss";

//Components
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import InfoIconTooltip from "../../../../components/UI/CustomTooltip/InfoIconTooltip";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { OverlayTrigger, Popover } from "react-bootstrap";
import InformationFillIcon from "remixicon-react/InformationFillIcon";

const FollowUpEmailType = (props) => {
  const { initialEmailTemplatesOptions } = props;

  const followUpTypeLabel = (
    <>
      Follow Up Type
      <OverlayTrigger
        key={"template-goal"}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-template-goal`}>
            <Popover.Body>
              <div className={styles.subjectTooltipDiv}>Choose the type of email follow-up you want the AI to prepare.</div>
            </Popover.Body>
          </Popover>
        }
      >
        <span className={styles.infoIconWrapper}>
          <InformationFillIcon className="iconGray" size={18} />
        </span>
      </OverlayTrigger>
    </>
  );

  const initialEmailLabel = (
    <>
      Choose initial email
      <OverlayTrigger
        key={"template-goal"}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-template-goal`}>
            <Popover.Body>
              <div className={styles.subjectTooltipDiv}>
                Select the initial email template for which the AI shall prepare a follow-up email.
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <span className={styles.infoIconWrapper}>
          <InformationFillIcon className="iconGray" size={18} />
        </span>
      </OverlayTrigger>
    </>
  );

  return (
    <div className={`${styles.steps} ${props.stepsWrapperClass}`}>
      <div
        className={`${props.stepperContentClass ? props.stepperContentClass : styles.stepperContent} campaignSequenceStepperContent mT15`}
      >
        <>
          {props.waitingAIResponse || props.waitingAIReset ? (
            <Spinner
              spinnerClassName={`absolute transform-x-y-50 ${styles.spinner}`}
              size="3rem"
              descriptionClass={styles.descriptionText}
              description="Sit back and relax while our AI writer prepares your awesome email templates."
            />
          ) : (
            <>
              <div className="d-flex ">
                <p className="f16 font700 m0">TEMPLATE REQUIREMENTS</p>
              </div>
              <p className="f14 secondary_text_color">
                Tell us more about the template you would like to generate, such as the goal and tone of voice.
              </p>
              <div
                className={`campaignSequenceHeight ${styles.formWrapper} ${props.formEmailWrapperClass ? props.formEmailWrapperClass : ""}`}
              >
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  name="followUpType"
                  placeholder={"Select follow-up type"}
                  showBorder={true}
                  selectChange={true}
                  options={followUpTypeSettingsOptions}
                  value={props.followUpSettingId}
                  label={followUpTypeLabel}
                />
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  name="initialEmailTemplateId"
                  placeholder={"Select initial email"}
                  showBorder={true}
                  selectChange={true}
                  options={initialEmailTemplatesOptions}
                  value={props.initialEmailTemplateId}
                  label={initialEmailLabel}
                />
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  label="Template Goal"
                  name="templateGoal"
                  value={props.templateGoalValue}
                  placeholder={"Select template goal "}
                  showBorder={true}
                  selectChange={true}
                  options={templateGoalOptions}
                />
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  label="Tone"
                  name="templateTone"
                  value={props.templateToneValue}
                  placeholder={"Select tone "}
                  showBorder={true}
                  selectChange={true}
                  options={templateToneOptions}
                />
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default FollowUpEmailType;
