import React from "react";

// Style
import styles from "./OrderStatusCol.module.scss";

const OrderStatusCol = (props) => {
  let classes = styles.pillsStyle;

  if (props.variant != null) {
    classes += " " + styles[props.variant];
  }

  if (props.classes != null) {
    classes += " " + props.classes;
  }

  return (
    <div className={styles.columnsWrapper + " " + props.wrapperClass}>
      <p className={classes} style={props.style}>
        {props.status}
      </p>
    </div>
  );
};

export default OrderStatusCol;
