import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isLoaded: false,
  error: "",
  processes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_IMPORT_PROCESSES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_IMPORT_PROCESSES_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        processes: action.result,
        error: "",
      };
    case actionTypes.FETCH_IMPORT_PROCESSES_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        processes: [],
        error: action.result,
      };
    case actionTypes.IMPORT_PROCESSES_UPDATE_PROGRESS:
      const progress = action.result;
      const index = state.processes.findIndex((p) => p.id === progress.currentImportProcessId);
      if (index != null && index > -1) {
        const processes = [...state.processes];

        let process = processes[index];
        process.accepted = progress.importImported;
        process.isFinished = progress.isComplete;

        processes[index] = process;

        return {
          ...state,
          processes: processes,
        };
      }

      return state;

    default:
      break;
  }

  return state;
};

export default reducer;
