import React from "react";
import { Row, Col } from "react-bootstrap";

//Style
import styles from "./Stepper.module.scss";

interface StepperProps {
  pageActive: number;
  label: string;
  label2: string;
  pageOneClicked: () => void;
  pageTwoClicked: () => void;
}

const Stepper: React.FC<StepperProps> = ({ pageActive, label, label2, pageOneClicked, pageTwoClicked }) => {
  let pageOneClass: string = "";
  let pageTwoClass: string = "";

  switch (pageActive) {
    case 1:
      pageOneClass = styles.active;
      break;
    case 2:
      pageOneClass = styles.completed;
      pageTwoClass = styles.active;
      break;
    default:
      break;
  }

  return (
    <>
      <ul className={styles.stepper}>
        <li className={pageOneClass} onClick={pageOneClicked}>
          <p className={styles.line}></p>
        </li>
        <li className={pageTwoClass} onClick={pageTwoClicked}>
          <p className={styles.line}></p>
        </li>
      </ul>

      <Row className={styles.stepperBarText}>
        <Col xl={3} className={pageOneClass}>
          <span>{label}</span>
        </Col>

        <Col xl={3} className={pageTwoClass}>
          <span>{label2}</span>
        </Col>
      </Row>
    </>
  );
};

export default Stepper;
