import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { toastMessages } from "../../helper/toastMessagesConstants";

//Components
import Modal from "../../components/UI/Modal/Modal";

const AudienceArchiveRestoreForm = (props) => {
  const archiveOrRestore = () => {
    let data = {
      audienceId: props.audience.id,
    };
    api(formUrl(API.audience.archiveRestoreAudience, data))
      .then((res) => {
        toast.success(
          props.audience.isArchived ? toastMessages.audience.successRestoreAudience : toastMessages.audience.successArchiveAudience
        );
        props.getAudiences();
        props.handleClose();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <Modal
      title={props.audience.isArchived ? "Restore List" : "Archive List"}
      handleClose={props.handleClose}
      handleSave={archiveOrRestore}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      idCloseButton="closeButton"
      idSaveButton="saveButton"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            {props.audience.campaignId ? (
              props.audience.isArchived ? (
                <p>
                  You will restore the List for campaign - {props.audience.campaignName}.<br /> Do you want to continue?
                </p>
              ) : (
                <p>
                  This list will be archived and will stop sourcing prospects for the campaign/s:
                  <br /> {props.audience.campaignName}, <br />
                  <br />
                  Are you sure you want to continue? You can retrieve archived lists through Your Lists page filtered by status.
                </p>
              )
            ) : props.audience.isArchived ? (
              <p>You will restore the List.</p>
            ) : (
              <p>You will archive the List.</p>
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default AudienceArchiveRestoreForm;
