import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";

//Helpers
import { numberOfRecords } from "../../../../helper/tableConstants";
import { useOrgDateTimeFormat } from "../../../../helper/hooks/useOrgDateTimeFormat";

//Redux
import {
  changePage,
  clearFilter,
  fetchAdvanceEmailTests,
  filterRecords,
  initiate,
  numRecords,
  searchRecords,
  setFilter,
  sortData,
} from "../../../../store/EmailDeliverabilityTest/AdvanceEmailTestTable/actions/advanceEmailTestTable";

// Style
import styles from "./AllReports.module.scss";

//Images & Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";

//Components
import { columns } from "./Columns";
import Table from "../../../../components/DataTable/Table";
import SkeletonTable from "../../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";

const AllReports = (props) => {
  const { param, rows, fetchAdvanceEmailTests, initiate, isInitiated, setAllReportsTestId } = props;
  const [tableRows, setTableRows] = useState([]);
  const records = numberOfRecords[1];

  let dateFormat = useOrgDateTimeFormat();

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "EmailTemplateId", value: [props.emailTemplateId] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchAdvanceEmailTests(param);
    }
  }, [param, isInitiated]);

  let table = null;

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((test) => {
      let status = null;
      let completed = null;
      let date = null;
      let subject = <span onClick={() => setAllReportsTestId(test.testId)}>{test.subject}</span>;

      test.subject = subject;

      if (test.status === 1) {
        status = "Awaiting email";
      } else if (test.status === 2) {
        status = "Processed";
      } else {
        status = "Unknown";
      }

      test.status = status;

      if (test.completed === 1) {
        completed = <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" />;
      } else {
        completed = <CloseCircleFillIcon size={22} color="var(--primary-color)" />;
      }

      test.completed = completed;

      date = moment(test.date).format(dateFormat);

      test.date = date;

      return test;
    });

    setTableRows(tempRows);
  }, [rows]);

  if (props.error) {
    table = <h2>Error getting Domains</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table = (
      <div>
        <Table
          tableName={"Advance Email Deliverability Tests"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          variant="small"
          scrollY={true}
          scrollX={true}
          tableHeight={275}
        />
      </div>
    );
  }

  return (
    <div className={styles.contentWrapper}>
      <div>{table}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.advanceEmailDeliverabilityTestTable.params,
    isInitiated: state.advanceEmailDeliverabilityTestTable.isInitiated,
    isLoading: state.advanceEmailDeliverabilityTestTable.isLoading,
    isLoaded: state.advanceEmailDeliverabilityTestTable.isLoaded,
    error: state.advanceEmailDeliverabilityTestTable.error,
    rows: state.advanceEmailDeliverabilityTestTable.rows,
    totalCount: state.advanceEmailDeliverabilityTestTable.totalCount,
    pageCount: state.advanceEmailDeliverabilityTestTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAdvanceEmailTests: (param) => dispatch(fetchAdvanceEmailTests(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllReports);
