import React, { FC } from "react";

//Helpers
import { checkFullArray } from "../../../../helper/arrayHelper";
//Styles
import styles from "./EmailAccountsWeighting.module.scss";
//Components
import EmailAccount from "./EmailAccount/EmailAccount";
import { useOrganizationId } from "@hooks/useOrganizationId";
import { CampaignEmailAccountDetailsModel } from "@models/campaign/CampaignEmailAccountDetailsModel";

interface EmailAccountsWeightingProps {
  emailAccounts: CampaignEmailAccountDetailsModel[];
}

const EmailAccountsWeighting: FC<EmailAccountsWeightingProps> = ({ emailAccounts }) => {
  const organizationId = useOrganizationId();

  let emails = null;

  if (checkFullArray(emailAccounts)) {
    emails = emailAccounts.map((e, index) => {
      return (
        <EmailAccount
          emailAccountDetailsView
          showUserPhoto
          imageUrl={e.imageUrl}
          emailName={e.emailName}
          userFullName={e.userFullName}
          email={e.email}
          messagesPerDay={e.prospectsPerDay}
          usedInOtherCampaigns={e.usedInOtherCampaigns}
          isConnected={e.isConnected}
          key={index}
          hasSignature={e.hasSignature}
          orgId={organizationId}
          emailAccountId={e.emailAccountId}
          cardClass={styles.emailAccountCard}
          isSuspended={e.isSuspended}
        />
      );
    });
  }

  return (
    <div className={styles.accountWrapper}>
      <div className={styles.accountProfilesWrapper}>{emails}</div>
    </div>
  );
};

export default EmailAccountsWeighting;
