import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";
import { toast } from "react-toastify";
import { toastMessages } from "../../../helper/toastMessagesConstants";

export const loadUser = (res) => {
  return {
    type: actionTypes.LOAD_USER,
    result: res,
  };
};

export const loadUserFromStorage = (res) => {
  return {
    type: actionTypes.LOAD_USER_FROM_STORAGE,
    result: res,
  };
};

export const changeOrganizationSuccess = (res) => {
  return {
    type: actionTypes.CHANGE_ORGANIZATION_SUCCESS,
    result: res,
  };
};

export const changeOrganization = (organizationId) => {
  return (dispatch) => {
    dispatch(changeOrganizationSuccess(organizationId));
  };
};

export const changeUserInfoSuccess = (userInfo) => {
  return {
    type: actionTypes.CHANGE_USER_INFO_SUCCESS,
    result: userInfo,
  };
};

export const updateOrganizationSettingsSuccess = (organization) => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_GENERAL_SETTINGS_SUCCEEDED,
    result: organization,
  };
};

export const leaveOrganizationRequest = (user) => {
  return {
    type: actionTypes.LEAVE_ORGANIZATION_REQUEST,
    result: user,
  };
};

export const leaveOrganizationSuccess = (user) => {
  return {
    type: actionTypes.LEAVE_ORGANIZATION_SUCCESS,
    result: user,
  };
};

export const leaveOrganizationFailed = (user) => {
  return {
    type: actionTypes.LEAVE_ORGANIZATION_FAILED,
    result: user,
  };
};

export const leaveOrganization = (user) => {
  return (dispatch) => {
    dispatch(leaveOrganizationRequest(user));
    return api(API.users.remove, user)
      .then((response) => {
        toast.success(toastMessages.users.successLeaveOrganization);
        dispatch(leaveOrganizationSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(leaveOrganizationFailed(error.message));
      });
  };
};

export const deleteOrganizationRequest = (user) => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_REQUEST,
    result: user,
  };
};

export const deleteOrganizationSuccess = (user) => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_SUCCESS,
    result: user,
  };
};

export const deleteOrganizationFailed = (user) => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_FAILED,
    result: user,
  };
};

export const deleteOrganization = (user) => {
  return (dispatch) => {
    dispatch(deleteOrganizationRequest(user));

    return api(API.organization.remove, user)
      .then((response) => {
        toast.success(toastMessages.users.successDeleteOrganization);
        dispatch(deleteOrganizationSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(deleteOrganizationFailed(error.message));
      });
  };
};

export const changeOrganizationDateFormat = (dateFormat) => {
  return {
    type: actionTypes.CHANGE_ORGANIZATION_DATE_FORMAT,
    result: dateFormat,
  };
};

export const changeOrganizationTimeFormat = (timeFormat) => {
  return {
    type: actionTypes.CHANGE_ORGANIZATION_TIME_FORMAT,
    result: timeFormat,
  };
};

export const changeApplication = (res) => {
  return {
    type: actionTypes.CHANGE_APPLICATION,
    result: res,
  };
};

export const getAdminOrganizationRequest = (res) => {
  return {
    type: actionTypes.GET_ADMIN_ORGANIZATION_REQUEST,
    result: res,
  };
};

export const getAdminOrganizationSuccess = (res) => {
  return {
    type: actionTypes.GET_ADMIN_ORGANIZATION_SUCCESS,
    result: res,
  };
};

export const getAdminOrganizationFailed = (res) => {
  return {
    type: actionTypes.GET_ADMIN_ORGANIZATION_FAILED,
    result: res,
  };
};

export const getAdminOrganization = (organizationId) => {
  return (dispatch) => {
    dispatch(getAdminOrganizationRequest(organizationId));
    return api(
      formUrl(API.organization.getOrganizationDetails, {
        organizationId: organizationId,
      })
    )
      .then((response) => {
        dispatch(getAdminOrganizationSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(getAdminOrganizationFailed(error.message));
      });
  };
};

export const updateOrganizationNumberOfUsers = (res) => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_NUMBER_OF_USERS,
    result: res,
  };
};

export const updateOrganizationAccessRemoved = (res) => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_ACCESS_REMOVED,
    result: res,
  };
};
