export const FETCH_TOTAL_DATA_AND_AUDIENCE_REQUEST =
  "FETCH_TOTAL_DATA_AND_AUDIENCE_REQUEST";
export const FETCH_TOTAL_DATA_AND_AUDIENCE_SUCCEEDED =
  "FETCH_TOTAL_DATA_AND_AUDIENCE_SUCCEEDED";
export const FETCH_TOTAL_DATA_AND_AUDIENCE_FAILED =
  "FETCH_TOTAL_DATA_AND_AUDIENCE_FAILED";

export const FETCH_CAMPAIGNS_INFO_REQUEST = "FETCH_CAMPAIGNS_INFO_REQUEST";
export const FETCH_CAMPAIGNS_INFO_SUCCEEDED = "FETCH_CAMPAIGNS_INFO_SUCCEEDED";
export const FETCH_CAMPAIGNS_INFO_FAILED = "FETCH_CAMPAIGNS_INFO_FAILED";

export const FETCH_TOTAL_CAMPAIGN_PERFORMANCE_REQUEST =
  "FETCH_TOTAL_CAMPAIGN_PERFORMANCE_REQUEST";
export const FETCH_TOTAL_CAMPAIGN_PERFORMANCE_SUCCEEDED =
  "FETCH_TOTAL_CAMPAIGN_PERFORMANCE_SUCCEEDED";
export const FETCH_TOTAL_CAMPAIGN_PERFORMANCE_FAILED =
  "FETCH_TOTAL_CAMPAIGN_PERFORMANCE_FAILED";

export const FETCH_TOTAL_RUN_RATES_REQUEST = "FETCH_TOTAL_RUN_RATES_REQUEST";
export const FETCH_TOTAL_RUN_RATES_SUCCEEDED =
  "FETCH_TOTAL_RUN_RATES_SUCCEEDED";
export const FETCH_TOTAL_RUN_RATES_FAILED = "FETCH_TOTAL_RUN_RATES_FAILED";

export const FETCH_TOTAL_RESPONESES_REQUEST = "FETCH_TOTAL_RESPONESES_REQUEST";
export const FETCH_TOTAL_RESPONESES_SUCCEEDED =
  "FETCH_TOTAL_RESPONESES_SUCCEEDED";
export const FETCH_TOTAL_RESPONESES_FAILED = "FETCH_TOTAL_RESPONESES_FAILED";

export const FETCH_TOTAL_PROESPECT_FUNNEL_REQUEST =
  "FETCH_TOTAL_PROESPECT_FUNNEL_REQUEST";
export const FETCH_TOTAL_PROESPECT_FUNNEL_SUCCEEDED =
  "FETCH_TOTAL_PROESPECT_FUNNEL_SUCCEEDED";
export const FETCH_TOTAL_PROESPECT_FUNNEL_FAILED =
  "FETCH_TOTAL_PROESPECT_FUNNEL_FAILED";

export const FETCH_TOTAL_LEAD_RATE_REQUEST = "FETCH_TOTAL_LEAD_RATE_REQUEST";
export const FETCH_TOTAL_LEAD_RATE_SUCCEEDED =
  "FETCH_TOTAL_LEAD_RATE_SUCCEEDED";
export const FETCH_TOTAL_LEAD_RATE_FAILED = "FETCH_TOTAL_LEAD_RATE_FAILED";

export const FETCH_GLOBAL_AVERAGE_RATE_REQUEST =
  "FETCH_GLOBAL_AVERAGE_RATE_REQUEST";
export const FETCH_GLOBAL_AVERAGE_RATE_SUCCEEDED =
  "FETCH_GLOBAL_AVERAGE_RATE_SUCCEEDED";
export const FETCH_GLOBAL_AVERAGE_RATE_FAILED =
  "FETCH_GLOBAL_AVERAGE_RATE_FAILED";

export const SET_PREVIOUS_ORGANIZATION_ID = "SET_PREVIOUS_ORGANIZATION_ID";

export const FETCH_STEPS_DONE_REQUEST =
  "FETCH_STEPS_DONE_REQUEST";
export const FETCH_STEPS_DONE_SUCCEEDED =
  "FETCH_STEPS_DONE_SUCCEEDED";
export const FETCH_STEPS_DONE_FAILED =
  "FETCH_STEPS_DONE_FAILED";

  export const SET_SHOW_PRODUCT_TOUR = "SET_SHOW_PRODUCT_TOUR";
