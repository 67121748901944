import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utility";
import { activeApplicationEnum } from "../../../helper/enums/activeApplicationEnum";

//Helpers
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";
import { getOrganizationRoleDescription } from "../../../helper/enums/organizationRoleEnum";
import { organizationRoleEnum } from "../../../helper/enums/organizationRoleEnum";

const getUserFromStorage = () => {
  const userLocal = localStorage.getItem("user");
  // Sometime the value is returned as "undefined" as an actual string. This is the reason for the added undefined check in ""
  if (userLocal && userLocal != "undefined") {
    const jwtToken = JSON.parse(userLocal);
    if (jwtToken == null) {
      return null;
    }
    const userJson = jwtToken.profile;
    if (userJson == null) {
      return null;
    }

    const userModel = {
      isImpersonating: userJson.imp_id && userJson.imp_id !== "" ? true : false,
      impersonatorId: userJson.imp_id && userJson.imp_id !== "" ? parseInt(userJson.imp_id) : 0,
      impersonatorFullName: userJson.imp_f_n,
      email: userJson.email,
      firstName: userJson.fin,
      lastName: userJson.lan,
      imageUrl: userJson.iu,
      jobTitle: userJson.jt,
      totalOrganizations: userJson.to,
      location: userJson.l,
      id: parseInt(userJson.sub),
      application: userJson.application && userJson.application !== "" ? userJson.application : activeApplicationEnum.Outbase,
      role: userJson.role,
      userPermissions: userJson.permission,
      adminOrganization: userJson.adminOrganization,
      adminOrganizationLoading: userJson.adminOrganizationLoading,
      userAccessRemoved: userJson.uar !== "" ? true : false,
      lastLoginDate: userJson.lld,
      createdDate: userJson.cd,
      createdDateUnix: userJson.cdu ? parseInt(userJson.cdu) : 0,
      hasPendingInvites: userJson.has_pending_invites === "True",
      isCreatingOrganization: userJson.is_creating_org !== "" ? true : false,
    };

    if (userJson.organization != null) {
      let organizations = JSON.parse(userJson.organization);
      if (organizations != null) {
        userModel.organizations = organizations.map((org) => {
          let newOrg = {
            userOrganizationId: org.uoi,
            id: org.oi,
            name: org.on,
            imageUrl: org.oiu,
            role: org.or,
            roleName: getOrganizationRoleDescription(org.or),
            domain: org.od,
            industry: "",
            companySize: "",
            createdDateTime: org.ocd,
            location: org.ol,
            timeFormat: org.otf,
            dateFormat: org.odf,
            organizationStatus: org.os,
            isInTestMode: org.oiitm,
            organizationAccessRemoved: org.oar,
            maxUsers: org.mu,
            features: org.fe,
          };

          return newOrg;
        });
      }
    }

    return userModel;
  }

  return null;
};

const localUser = getUserFromStorage();
const initialState =
  localUser != null
    ? localUser
    : {
        isImpersonating: false,
        impersonatorId: 0,
        impersonatorFullName: "",
        email: "",
        firstName: "",
        lastName: "",
        imageUrl: "",
        jobTitle: "",
        location: "",
        id: 0,
        organizations: [],
        organization: {},
        organizationRoles: [],
        application: activeApplicationEnum.Outbase,
        role: "",
        userPermissions: [],
        adminOrganization: {},
        adminOrganizationLoading: false,
        userAccessRemoved: false,
        lastLoginDate: "",
      };

const loadUser = (state, action) => {
  const updatedState = state;
  return updateObject(state, { results: updatedState });
};

const loadUserFromStorage = (state, action) => {
  let user = getUserFromStorage();

  if (user != null) {
    const newState = { ...user };
    return updateObject(state, newState);
  }

  return state;
};

const changeOrganization = (state, action) => {
  const orgId = action.result;
  const organization = state.organizations.find((org) => org.id === orgId);
  let hasViewAllPermission = globalPermissionHelper(state.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll);

  // If user is on a page with no orgId, we should delete the last organization from state.
  if (orgId == null && state.organization != null) {
    const newState = { ...state };
    newState.organization = null;
    return updateObject(state, newState);
  }

  // If organization is found in the list of organizations, we should update the user.Organization state.
  if (organization != null) {
    if (state.organization != null && state.organization.id === organization.id) return state;

    if (hasViewAllPermission) organization.role = organizationRoleEnum.Owner;

    const newState = { ...state };
    newState.organization = organization;
    return updateObject(state, newState);
  } else {
    if (state.organization != null && state.organization.id === organization.id) return state;

    if (hasViewAllPermission && state.adminOrganization) {
      if (state.adminOrganization.organizationId === orgId) {
        const newState = { ...state };
        newState.organization = newState.adminOrganization;
        return updateObject(state, newState);
      }
    }
  }
  return state;
};

const changeUserInfo = (state, action) => {
  const newState = { ...state };
  newState.firstName = action.result.FirstName;
  newState.lastName = action.result.LastName;
  newState.jobTitle = action.result.JobTitle;
  newState.location = action.result.Location;
  return updateObject(state, newState);
};

const updateOrganizationSettingsSucceded = (state, action) => {
  var updatedOrganization = action.result;
  const orgIndex = state.organizations.findIndex((o) => o.id === updatedOrganization.id);
  const newState = { ...state };

  if (updatedOrganization != null) {
    newState.organization.name = updatedOrganization.name;
    newState.organization.domain = updatedOrganization.domain;
    newState.organization.industry = updatedOrganization.industry;
    newState.organization.companySize = updatedOrganization.companySize;
    newState.organization.imageUrl = updatedOrganization.imageUrl;
    newState.organization.location = updatedOrganization.location;
    newState.organization.timeFormat = updatedOrganization.timeFormat;
    newState.organization.dateFormat = updatedOrganization.dateFormat;

    newState.organizations[orgIndex].name = updatedOrganization.name;
    newState.organizations[orgIndex].domain = updatedOrganization.domain;
    newState.organizations[orgIndex].industry = updatedOrganization.industry;
    newState.organizations[orgIndex].companySize = updatedOrganization.companySize;
    newState.organizations[orgIndex].imageUrl = updatedOrganization.imageUrl;
    newState.organizations[orgIndex].location = updatedOrganization.location;
  }

  return updateObject(state, newState);
};

const deleteLeaveOrganizationSuccess = (state, action) => {
  const newState = { ...state };
  const newOrganizations = [...newState.organizations];
  const organization = newOrganizations.find((org) => org.id === action.result);

  if (organization != null) {
    let orgIndex = newOrganizations.indexOf(organization);
    newOrganizations.splice(orgIndex, 1);
    newState.organizations = newOrganizations;
    return newState;
  } else {
    return state;
  }
};

const deleteLeaveOrganizationFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

export const changeOrganizationDateFormat = (state, action) => {
  const newState = { ...state };
  let newOrganization = { ...state.organization };
  newOrganization.dateFormat = action.result;
  newState.organization = newOrganization;
  return newState;
};

export const changeOrganizationTimeFormat = (state, action) => {
  const newState = { ...state };
  let newOrganization = { ...state.organization };
  newOrganization.timeFormat = action.result;
  newState.organization = newOrganization;
  return newState;
};

const changeApplication = (state, action) => {
  let newApplication = action.result;
  if (state.application != null && state.application === newApplication) return state;

  const newState = { ...state };

  if (newApplication === activeApplicationEnum.Outboss) {
    newState.adminOrganization = null;
    newState.impersonatorFullName = null;
    newState.impersonatorId = null;
  }
  newState.application = newApplication;
  return updateObject(state, newState);
};

const getAdminOrganizationRequest = (state, action) => {
  const newState = { ...state };
  newState.adminOrganizationLoading = true;
  return updateObject(state, newState);
};

const getAdminOrganizationSuccess = (state, action) => {
  const organizationDetails = action.result;

  if (organizationDetails != null && organizationDetails.oi > 0) {
    const newState = { ...state };
    newState.adminOrganization = {
      userOrganizationId: organizationDetails.uoi,
      id: organizationDetails.oi,
      name: organizationDetails.on,
      imageUrl: organizationDetails.oiu,
      role: organizationDetails.or,
      roleName: organizationDetails.orn,
      domain: organizationDetails.od,
      industry: organizationDetails.oin,
      companySize: organizationDetails.ocz,
      createdDateTime: organizationDetails.ocd,
      location: organizationDetails.ol,
      timeFormat: organizationDetails.otf,
      dateFormat: organizationDetails.odf,
      organizationStatus: organizationDetails.os,
      isInTestMode: organizationDetails.oiitm,
      organizationAccessRemoved: organizationDetails.oar,
      maxUsers: organizationDetails.mu,
      features: organizationDetails.fe,
    };
    newState.organization = newState.adminOrganization;
    newState.application = activeApplicationEnum.Outbase;
    newState.adminOrganizationLoading = false;
    return updateObject(state, newState);
  } else {
    return state;
  }
};

const getAdminOrganizationFailed = (state, action) => {
  const newState = { ...state };
  newState.adminOrganization = null;
  newState.adminOrganizationLoading = false;
  return updateObject(state, newState);
};

const updateOrganizationNumberOfUsers = (state, action) => {
  let newNumberOfUsers = action.result;
  if (newNumberOfUsers === null) {
    const newState = { ...state };
    newState.organization.maxUsers = -1;
    return updateObject(state, newState);
  } else {
    if (newNumberOfUsers > state.organization.maxUsers) {
      const newState = { ...state };
      newState.organization.maxUsers = newNumberOfUsers;
      return updateObject(state, newState);
    } else {
      return state;
    }
  }
};

const updateOrganizationAccessRemoved = (state, action) => {
  let organizationId = action.result.id;
  let accessRemoved = action.result.accessRemoved;
  const newState = { ...state };
  if (newState.organization) {
    newState.organization.organizationAccessRemoved = accessRemoved;
  }

  if (newState.organizations) {
    newState.organizations.map((org) => {
      if (org.id === organizationId) org.organizationAccessRemoved = accessRemoved;
    });
  }

  return updateObject(state, newState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_USER:
      return loadUser(state, action);
    case actionTypes.LOAD_USER_FROM_STORAGE:
      return loadUserFromStorage(state, action);
    case actionTypes.CHANGE_ORGANIZATION_SUCCESS:
      return changeOrganization(state, action);
    case actionTypes.CHANGE_USER_INFO_SUCCESS:
      return changeUserInfo(state, action);
    case actionTypes.UPDATE_ORGANIZATION_GENERAL_SETTINGS_SUCCEEDED:
      return updateOrganizationSettingsSucceded(state, action);
    case actionTypes.LEAVE_ORGANIZATION_SUCCESS:
      return deleteLeaveOrganizationSuccess(state, action);
    case actionTypes.LEAVE_ORGANIZATION_FAILED:
      return deleteLeaveOrganizationFailed(state, action);
    case actionTypes.DELETE_ORGANIZATION_SUCCESS:
      return deleteLeaveOrganizationSuccess(state, action);
    case actionTypes.DELETE_ORGANIZATION_FAILED:
      return deleteLeaveOrganizationFailed(state, action);
    case actionTypes.CHANGE_ORGANIZATION_DATE_FORMAT:
      return changeOrganizationDateFormat(state, action);
    case actionTypes.CHANGE_ORGANIZATION_TIME_FORMAT:
      return changeOrganizationTimeFormat(state, action);
    case actionTypes.CHANGE_APPLICATION:
      return changeApplication(state, action);
    case actionTypes.GET_ADMIN_ORGANIZATION_REQUEST:
      return getAdminOrganizationRequest(state, action);
    case actionTypes.GET_ADMIN_ORGANIZATION_SUCCESS:
      return getAdminOrganizationSuccess(state, action);
    case actionTypes.GET_ADMIN_ORGANIZATION_FAILED:
      return getAdminOrganizationFailed(state, action);
    case actionTypes.UPDATE_ORGANIZATION_NUMBER_OF_USERS:
      return updateOrganizationNumberOfUsers(state, action);
    case actionTypes.UPDATE_ORGANIZATION_ACCESS_REMOVED:
      return updateOrganizationAccessRemoved(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
