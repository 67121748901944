import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

//helper
import api, { API, formUrl } from "../../../../helper/api/api";
import { errorResponseToList } from "../../../../helper/errorHelper";

//Styles
import styles from "./CouponField.module.scss";

//Images & Icons
import CheckLineIcon from "remixicon-react/CheckLineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";

const CouponField = (props) => {
  const { setCoupon, coupon, appliesTo, period, typeOfBilling, isFromOrderSummary, hasRedirect, showModal } = props;
  const [showText, setShowText] = useState(coupon != null);
  const [showValidation, setShowValidation] = useState(false);
  const [discountText, setDiscountText] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    if (coupon == null) {
      setCoupon(null);
      setShowText(false);
      setShowValidation(false);
    }
    if (coupon != null) {
      setShowValidation(true);
    }
  }, [coupon]);

  useEffect(() => {
    if (coupon != null && !isFromOrderSummary) {
      if (hasRedirect && isFromOrderSummary != null && showModal) return;

      if (hasRedirect === undefined && isFromOrderSummary === undefined && showModal === undefined) return;

      if (showModal && !hasRedirect && !isFromOrderSummary) return;

      setCoupon(null);
      setShowText(false);
      setShowValidation(false);
    }
  }, [period, typeOfBilling]);

  const showField = () => setShowText(true);
  const hideField = (setFieldValue) => {
    setShowText(false);
    setFieldValue("text", "");
    setCoupon(null);
    setShowValidation(false);
  };

  const handleKeyUp = (e, handleSubmit) => {
    if (e != null && e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className={`${styles.promoCodeWraFieldWrapper} ${props.promoWrapperClass}`}>
      <Formik
        initialValues={{
          text: coupon != null ? coupon?.code : "",
        }}
        validationSchema={Yup.object({
          // text: Yup.string().required("Required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          api(formUrl(API.coupon.validate, { code: values.text, appliesTo: appliesTo, term: period, isAnnual: typeOfBilling }))
            .then((res) => {
              setCoupon(res.data);
              setDiscountText(res.data.discountText);
              setShowValidation(true);
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);
              if (errorList.length > 0) {
                errorList.forEach((e) => actions.setFieldError(e.field, e.message));
              } else {
                toast.error(error.message, { autoClose: false });
              }

              setShowValidation(false);
              setCoupon(null);
              setDiscountText("");
              actions.setSubmitting(false);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, setFieldValue } = formikProps;
          return (
            <div className={`${styles.promoCodeField} ${styles.promoCodeVerified} ${styles.invalidCoupon}`}>
              {/* <div className={`${styles.formikInputWrapper} ${props.formikInputWrapperClass}`}>
                {showText ? (
                  !showValidation ? (
                    <p className={`${styles.textLabel} ${styles.inputLabel} ${props.textLabelClass}`}>
                      Press <b>Enter</b> to apply it.
                    </p>
                  ) : null
                ) : props.hideTextLabel ? null : (
                  <p className={`${styles.textLabel} mR10 flex justify_end`}>Do you have a coupon? Add it here. </p>
                )}

                {showText ? (
                  <div className="flex flexCol width_100 relative mT5">
                    <p className={styles.couponText}>
                      Coupon <CloseLineIcon size={20} className="iconGray400 cursor_pointer" onClick={() => hideField(setFieldValue)} />
                    </p>
                    <FormikInput
                      onKeyUp={(e) => handleKeyUp(e, handleSubmit)}
                      name="text"
                      value={values.text}
                      placeholder="Promo code"
                      inputclass={showValidation ? styles.promoCodeInputValid : ""}
                      errorinputclass={styles.errorMessage}
                      inputGroupClass="m0"
                    />
                    {showValidation ? (
                      <>
                        <CheckLineIcon size={18} color={`var(--success-color)`} className={styles.checkIcon} />
                        <span className={styles.successMessage}>{discountText}</span>{" "}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <p className={`cursor_pointer ${styles.applyCouponText}`} onClick={showField}>
                    + Apply Coupon
                  </p>
                )}
              </div> */}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default CouponField;
