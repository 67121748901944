import React, { useEffect } from "react";

//Style
import styles from "./RulsetCard.module.scss";

//Components
import Button from "../../../../../components/UI/Button/Button";
import IconContactBook from "../../../../../assets/Icons/IconContactBook/IconContactBook";
import { addHubspotRulesetRequest } from "../../../../../store/CRM-Integrations/HubSpot/Global/actions/hubspot-integration";

const RulsetCard = (props) => {
  // const implementThisFunction = () => {
  //     console.error("not implemented")
  // }

  // Implement the function for rendering a description of each ruleset
  // Best way is to defined description for each card and split by spacial character ("|")
  // let description = null;
  // const splitDescription = (props.description) => {
  //     split -> map -> each list items (assign all of that to description)
  // }

  return (
    <div className={styles.templateCard}>
      <div className={styles.cardHeader}>
        <div className={styles.icon}>
          <div className={styles.iconWrapper}>
            <IconContactBook />
          </div>
          <a onClick={props.onClick} className={styles.templateName}>
            {props.title}
          </a>
        </div>
        <Button
          id="useRuleset"
          onClick={() => {
            props.rulesetSelected(props.rulesetId);
          }}
          type="button"
          variant="secondary"
          classes={styles.useRulesetButton}
        >
          Use Ruleset
        </Button>
      </div>
      <div className={styles.templateList}>
        <ul>
          {props.ruleset
            ? props.ruleset.map((element, index) => {
                return <li key={index}>{element.actionCodeDescription + " on " + element.eventTypeDescription}</li>;
              })
            : null}
        </ul>
      </div>
    </div>
  );
};

export default RulsetCard;
