export const getCampaignStatusData = (statusData, campaignSetupProgressModel) => {
  let status = null;
  let statusVariant = null;
  let tooltipText = null;
  let stepsDone, maxSteps;

  if (campaignSetupProgressModel) {
    ({ stepsDone, maxSteps } = campaignSetupProgressModel);
  } else {
    stepsDone = 0;
    maxSteps = 0;
  }

  if (statusData === "Setup" || statusData === "Paused") {
    if (stepsDone === maxSteps) {
      status = statusData === "Paused" ? `Paused` : `Ready to Launch`;
      statusVariant = statusData === "Paused" ? "paused" : "draftFinished";
      tooltipText = statusData === "Paused" ? "The campaign is paused, and all sending activities to prospects have been stopped." : "";
    } else {
      status = `Draft ${stepsDone}/${maxSteps}`;
      statusVariant = "draft";
      tooltipText =
        "The campaign has Draft status when the campaign is in setup process, and there are mandatory steps that need to be configured.";
    }
  } else if (statusData === "Active") {
    status = `Active`;
    statusVariant = "active";
    tooltipText =
      "The Campaign is active, there is an ongoing process of sending emails to prospects and there are prospects that wait to be engaged.";
  } else if (statusData === "Archived") {
    status = `Archived`;
    statusVariant = "archived";
    tooltipText = "The campaign is archived and it can no longer be used for prospecting activities until it has been restored.";
  } else {
    status = statusData;
    statusVariant = statusData.toLowerCase();
  }

  let campaignStatusObject = {
    status: status,
    statusVariant: statusVariant,
    tooltipText: tooltipText,
  };
  return campaignStatusObject;
};
