import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import _ from "lodash";

//Redux
import { fetchOrganizationEmailAccountsList } from "../../../../store/EmailAccounts/Global/actions/emailAccounts";

//Helpers
import api, { API } from "../../../../helper/api/api";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import { findBasicAndCustomVariables } from "../../../../helper/findBasicAndCustomVariablesHelper";
import { setDictionaryValuesForForm, setDictionaryValuesForSubjects } from "../../../../helper/setDictionaryValuesForSendTestMailsHelper";
import { splitEmailTemplateVariables } from "../../../../utils/splitEmailTemplateVariables";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import HorizontalStepper from "../../../../components/UI/HorizontalStepper/HorizontalStepper";
import TestInfoStep from "./TestInfoStep";
import TestVariablesStep from "../TestVariablesStep/TestVariablesStep";

//Hooks
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const TestSequenceForm = (props) => {
  const { emailTemplatesRows,templatesState } = props;
  
  const [activeStep, setActiveStep] = useState(0);
  const [emailAccountId, setEmailAccountId] = useState(0);
  const [variables, setVariables] = useState([]);
  const [dictionary, setDictionary] = useState({});
  const [dictionarySubject, setDictionarySubject] = useState({});
  const [basicVariables, setBasicVariables] = useState(null);
  const [customVariables, setCustomVariables] = useState(null);

  // Queries
  const { data: emailTemplateVariables, isSuccess } = useGetTemplateVariablesQuery();

  var uniqueTemplateIds = templatesState?.rows?.map((x) => x.id);
  var emailTemplatesRow = templatesState?.rows ?? [];

  const steps = [
    {
      label: "Test Info",
    },
    {
      label: "Test Variables",
    },
  ];

  useEffect(() => {
    props.fetchOrganizationEmailAccountsList();
  }, []);

  useEffect(() => {
    setDictionaryValuesForSubjects(emailTemplatesRow, dictionarySubject, setDictionarySubject);
  },[uniqueTemplateIds]);


  const handleCloseModal = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      handleHideModal();
    }
  };

  const handleHideModal = () => {
    setActiveStep(0);
    props.handleClose();
  };

  const setCurrentEmailTemplateSequenceVariables = () => {
    let variablesEmailTextTemp = [];
    let variablesEmailSubjectTemp = [];

    templatesState?.rows?.forEach((element) => {
      if (!_.isEmpty(element.text)) {
        let variablesEmailTextTemp1 = splitEmailTemplateVariables(element.text);
        variablesEmailTextTemp.push(...variablesEmailTextTemp1);
      }
    });
      setVariables(variablesEmailTextTemp);
  };

  useEffect(() => {
    if (activeStep === 1) {
      setCurrentEmailTemplateSequenceVariables();
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 1 && isSuccess) {
      setDictionaryValuesForForm(variables, dictionary, setDictionary, emailTemplateVariables);
      findBasicAndCustomVariables(setBasicVariables, setCustomVariables, variables, emailTemplateVariables);
    }
  }, [variables, isSuccess]);

  return (
    <Formik
      initialValues={{
        subject: dictionarySubject,
        sendTo: props.userEmail,
        emailAccountId: emailAccountId,
        dictionary: dictionary,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        emailAccountId: Yup.number().moreThan(0, "Required").required("Required"),
        sendTo: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        let continueVar = true;
        let continueSubjVar = true;
        variables.forEach((variable) => {
          if (values.dictionary[variable] === "") {
            continueVar = false;
            actions.setFieldError("dictionary[" + variable + "]", "Required");
            actions.setSubmitting(false);
            return;
          }
        });
        uniqueTemplateIds?.forEach((name) => {
          if (values.subject[name] === "") {
            continueSubjVar = false;
            actions.setFieldError("subject[" + name + "]", "Required");
            actions.setSubmitting(false);
            return;
          }
        });

        if (continueVar && continueSubjVar) {
          let sequenceTestData = { ...values };
          var data = {
            subjectDictionary: sequenceTestData.subject, 
            emailAccountId: sequenceTestData.emailAccountId,
            sendTo: sequenceTestData.sendTo,
            campaignId: props.campaignId,
            dictionaryOfVariable: sequenceTestData.dictionary,
          };

          api(API.emailTemplates.sendTestEmailSequence, data)
            .then((res) => {
              if (res.data.success) toast.success(toastMessages.emailTemplate.successSendTestEmail);

              if (!res.data.success) toast.error(<p>{res.data.errorMessage}</p>);
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);
              if (errorList.length > 0) {
                errorList.forEach((e) => actions.setFieldError(e.field, e.message));
              } else {
                toast.error(error.message);
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
              props.handleClose();
            });
        }
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, isSubmitting, setFieldValue } = formikProps;
        return (
          <Modal
            title="Test Campaign Sequence"
            handleClose={handleCloseModal}
            handleHideModal={handleHideModal}
            handleSave={() => {
              if (activeStep === 0) {
                setActiveStep(activeStep + 1);
              } else {
                setActiveStep(activeStep - 1);
                handleSubmit();
              }
            }}
            show={props.showModal}
            closeButtonText={activeStep === 0 ? "Cancel" : "Back"}
            saveButtonText={activeStep === 0 ? "Next" : "I'm done"}
            saveButtonDisabled={isSubmitting}
            btnType="submit"
            backdrop={true}
          >
            <Form>
              <HorizontalStepper steps={steps} orientation="horizontal" setActiveStep={setActiveStep} activeStep={activeStep} />
              {activeStep === 0 && (
                <TestInfoStep
                  formikProps={formikProps}
                  sendToValue={values.sendTo}
                  emailAccountIdValue={values.emailAccountId}
                  subject="subject"
                  sendTo="sendTo"
                  emailAccountId="emailAccountId"
                  setEmailAccountId={setEmailAccountId}
                  uniqueTemplateIds={uniqueTemplateIds}
                  subjectValues={values.subject}
                />
              )}
              {activeStep === 1 && (
                <TestVariablesStep
                  formikProps={formikProps}
                  dictionary="dictionary"
                  dictionaryValues={values.dictionary}
                  basicVariables={basicVariables}
                  customVariables={customVariables}
                />
              )}
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.user.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizationEmailAccountsList: () => dispatch(fetchOrganizationEmailAccountsList()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TestSequenceForm);
