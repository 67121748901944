export const columns = [
  {
    label: "Notification Name",
    field: "notificationName",
    type: "text",
    sort: "asc",
  },
  {
    label: "Category",
    field: "category",
    type: "text",
    sort: "asc",
  },
  {
    label: "Sent From",
    field: "sentFrom",
    type: "text",
    sort: "asc",
  },
  {
    label: "Sent To",
    field: "sentTo",
    type: "date",
    sort: "asc",
  },
  {
    label: "CC",
    field: "cc",
    type: "text",
    sort: "asc",
  },
  {
    label: "Bcc",
    field: "bcc",
    type: "text",
    sort: "asc",
  },
  {
    label: "Active",
    field: "active",
    type: "actions",
    sort: "asc",
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    sort: "false",
  },
];
