import React from "react";

const SignedOrder = (props) => {
  return (
    <svg
      width="212"
      height="72"
      viewBox="0 0 212 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M212 0H0L19 72H193L212 0Z" fill="#21C4A8" />
    </svg>
  );
};

export default SignedOrder;
