import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { connect } from "react-redux";
import { debounce, isEmpty } from "lodash";

// Styles
import styles from "./ResponderInbox.module.scss";

//Actions
import { setFilter, clearFilter, setSelectedRow, clearInitiated } from "../../store/ResponderInbox/Table/actions/responderInboxTable";
import { fetchTotalNumberOfEmails, setActiveTab, setFilterForReview } from "../../store/ResponderInbox/actions/responderInbox";
import { fetchSettings } from "../../store/OrganizationSettings/General/actions/general";

// Helpers
import { filterData } from "../../helper/tableConstants";
import { responseTypeOptions } from "./helpers/responseTypeOptions";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";

//Images & Icons
import EmptyTemplatesImage from "../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

// Components
import MessageList from "./MessageList/MessageList";
import MessagePreview from "./MessagePreview/MessagePreview";
import ResponseType from "./ResponseType/ResponseType";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import InboxFilter from "./InboxFilter/InboxFilter";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import Spinner from "../../components/UI/Spinner/Spinner";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import AntSwitch from "../../components/UI/AntSwitch/Switch";
import api, { API } from "../../helper/api/api";
import { toast } from "react-toastify";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import { useParams } from "react-router-dom";

const ResponderInbox = (props) => {
  const { activeTab, messagePreview, isLoaded, clearInitiated, isMessageLoaded, isMessageLoading, rows } = props;
  const { campaignId } = useParams();
  const [campaignValue, setCampaignValue] = useState([]);
  const [emailAccountValue, setEmailAccountValue] = useState([]);
  const [responseTypeValue, setResponseTypeValue] = useState([]);

  const [messagesListClass, setMessagesListClass] = useState(styles.messagesListSection);
  const [messagePreviewClass, setMessagePreviewClass] = useState(styles.messagePreviewSection);
  const [messageListClosed, setMessageListClosed] = useState(false);
  const [messagePreviewClosed, setMessagePreviewClosed] = useState(false);
  const [responseAdminClass, setResponseAdminClass] = useState(styles.responseAdminSection);
  const [responseAdminClose, setResponseAdminClose] = useState(false);

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);
  const { data: emailAccountsList } = useSelectList(SelectListEnum.EmailAccounts);

  useEffect(() => {
    adjustSections();
  }, [messageListClosed, messagePreviewClosed, responseAdminClose]);

  const adjustSections = () => {
    if (!messageListClosed && !messagePreviewClosed && !responseAdminClose) {
      setMessagesListClass(styles.messagesListSection);
      setMessagePreviewClass(styles.messagePreviewSection);
      setResponseAdminClass(styles.responseAdminSection);
      setMessageListClosed(false);
      setMessagePreviewClosed(false);
      setResponseAdminClose(false);
    }
    if (messageListClosed && !messagePreviewClosed && !responseAdminClose) {
      setMessagesListClass(styles.messagesListSectionClosed);
      setMessagePreviewClass(styles.messagePreviewSectionFullyOpen);
      setResponseAdminClass(styles.responseAdminSection);
    }

    if (messageListClosed && !messagePreviewClosed && responseAdminClose) {
      setMessagesListClass(`${styles.messagesListSectionClosed}`);
      setMessagePreviewClass(styles.messagePreviewSectionFullyOpen100);
      setResponseAdminClass(styles.responseAdminClosed);
    }

    if (!messageListClosed && messagePreviewClosed && !responseAdminClose) {
      setMessagesListClass(styles.messagesListSectionFullyOpen);
      setMessagePreviewClass(styles.messagePreviewSectionClosed);
      setResponseAdminClass(styles.responseAdminSection);
    }

    if (!messageListClosed && !messagePreviewClosed && responseAdminClose) {
      setMessagesListClass(styles.messagesListSection);
      setMessagePreviewClass(styles.messagePreviewSectionFullyOpen);
      setResponseAdminClass(styles.responseAdminClosed);
    }

    if (messageListClosed && messagePreviewClosed && !responseAdminClose) {
      setMessagesListClass(styles.messagesListSection);
      setMessagePreviewClass(styles.messagePreviewSection);
      setResponseAdminClass(styles.responseAdminSection);
      setMessageListClosed(false);
      setMessagePreviewClosed(false);
    }

    if (messageListClosed && messagePreviewClosed && responseAdminClose) {
      setMessagesListClass(styles.messagesListSection);
      setMessagePreviewClass(styles.messagePreviewSection);
      setResponseAdminClass(styles.responseAdminClosed);
      setMessageListClosed(false);
      setMessagePreviewClosed(false);
    }

    if (!messageListClosed && messagePreviewClosed && responseAdminClose) {
      setMessagesListClass(styles.messagesListSection94);
      setMessagePreviewClass(styles.messagePreviewSectionClosed);
      setResponseAdminClass(styles.responseAdminClosed);
      setMessageListClosed(false);
    }
  };
  const icon = (
    <InfoIconTooltip
      tooltipBody={"Use the Inbox to view and categorize responses across your campaigns."}
      href={"https://help.outbase.com/user-guide/inbox"}
      linkText="Read More"
      buttonId="startResponderInboxTour"
      disableStartTourButton
    />
  );

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, [props.isInCampaignSettings]);

  useEffect(() => {
    props.fetchSettings();
    props.setFilterForReview(false);
  }, []);

  useEffect(() => {
    if (props.isOrganizationSettingsLoaded && props.isAutoResolveResponse) {
      props.setActiveTab(1);
    } else {
      props.setActiveTab(0);
    }
  }, [props.isAutoResolveResponse, props.isOrganizationSettingsLoaded]);

  const isInCampaignSettings = props.isInCampaignSettings != null ? props.isInCampaignSettings : false;
  let inboxFilter = <InboxFilter resolved={props.resolved} unresolved={props.unresolved} />;

  const handleAutoResolve = (val) => {
    api(API.organization.settings.updateAutoResponseResolver, { isAutoResolveResponse: !props.isAutoResolveResponse })
      .then((res) => {
        props.fetchSettings();
      })
      .catch((error) => {
        toast.error(error.message);
      });
    //(state => !state);
  };

  let selectRowOptions = null;
  selectRowOptions = [
    {
      option: emailAccountsList,
      change: (ea) => {
        filterData("EmailAccount", ea, props.setFilter);
        setEmailAccountValue(ea);
      },
      placeholder: "Email Account",
      value: emailAccountValue,
    },
  ];

  if (isInCampaignSettings === false) {
    selectRowOptions.push({
      option: campaignsList,
      change: (c) => {
        filterData("Campaign", c, props.setFilter);
        setCampaignValue(c);
      },
      placeholder: "Campaigns",
      value: campaignValue,
    });
  }

  if (activeTab === 1) {
    selectRowOptions.push({
      option: responseTypeOptions,
      change: debounce((rt) => {
        filterData("ResponseType", rt, props.setFilter);
        setResponseTypeValue(rt);
      }, 250),
      placeholder: "Response Type",
      value: responseTypeValue,
    });
  }

  let image = <EmptyTemplatesImage className="emptyStateImage" />;
  let inboxMessageContent = (
    <div className={styles.emptyStateWrapper}>
      <EmptyState image={image} />
    </div>
  );

  if (isLoaded) {
    if (isMessageLoading) {
      inboxMessageContent = <Spinner spinnerClass="marginAuto" />;
    } else if (isMessageLoaded && !isEmpty(messagePreview) && !isEmpty(rows)) {
      inboxMessageContent = (
        <>
          <div id="messagePreviewComponent" className={messagePreviewClass}>
            <MessagePreview
              messageListClosed={messageListClosed}
              setMessageListClosed={setMessageListClosed}
              messagePreviewClosed={messagePreviewClosed}
              setMessagePreviewClosed={setMessagePreviewClosed}
              responseAdminClose={responseAdminClose}
              setResponseAdminClose={setResponseAdminClose}
            />
          </div>
          <div id="responseAdminComponent" className={responseAdminClass}>
            <ResponseType
              responseAdminClose={responseAdminClose}
              setResponseAdminClose={setResponseAdminClose}
              messagePreviewClosed={messagePreviewClosed}
              setMessagePreviewClosed={setMessagePreviewClosed}
              messageListClosed={messageListClosed}
              setMessageListClosed={setMessageListClosed}
            />
          </div>
        </>
      );
    }
  }

  return (
    <>
      {props.isOrganizationSettingsLoaded ? (
        <PageContentWrapper className={`${styles.responderWrapper} contentHeight`}>
          {campaignId ? null : <HeaderTitle icon={icon} title="Responder Inbox" />}
          <div className="d-flex space_between pB10">
            <div className={`${styles.switchButtonWrapper} flex_center`}>
              <AntSwitch checked={props.isAutoResolveResponse} onChange={handleAutoResolve} />
              <p>Auto-Resolve</p>
            </div>

            <TableFiltersRow
              headerClassName={styles.headerFiltersWrapper}
              rightContent={inboxFilter}
              selects={selectRowOptions}
              showPerPage={false}
            />
          </div>
          <div className={`${styles.contentPageWrapper} flex`}>
            <div id="messagesList" className={messagesListClass}>
              <MessageList
                responseAdminClose={responseAdminClose}
                setResponseAdminClose={setResponseAdminClose}
                messageListClosed={messageListClosed}
                setMessageListClosed={setMessageListClosed}
                messagePreviewClosed={messagePreviewClosed}
                setMessagePreviewClosed={setMessagePreviewClosed}
                delayedWrapperClass={messagePreviewClosed ? styles.searchFieldFix : styles.searchFieldMinWidth}
                isInCampaignSettings={isInCampaignSettings}
                forReviewCount={props.forReviewCount}
              />
            </div>
            {inboxMessageContent}
          </div>
        </PageContentWrapper>
      ) : (
        <Spinner spinnerClass="marginAuto" />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.responderInbox.activeTab,
    messagePreview: state.responderInbox.messagePreview,
    isMessageLoaded: state.responderInbox.isMessageLoaded,
    isMessageLoading: state.responderInbox.isMessageLoading,
    isLoaded: state.responderInboxTable.isLoaded,
    isLoading: state.responderInboxTable.isLoading,
    rows: state.responderInboxTable.rows,
    unresolved: state.responderInboxTable.unresolved,
    resolved: state.responderInboxTable.resolved,
    forReviewCount: state.responderInboxTable.forReviewCount,
    campaignLocation: state.responderInbox.campaignLocation,
    isAutoResolveResponse: state.organizationSettingsGeneral.settings.isAutoResolveResponse,
    isOrganizationSettingsLoaded: state.organizationSettingsGeneral.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTotalNumberOfEmails: () => dispatch(fetchTotalNumberOfEmails()),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    setSelectedRow: (obj) => dispatch(setSelectedRow(obj)),
    clearInitiated: () => dispatch(clearInitiated()),
    setActiveTab: (value) => dispatch(setActiveTab(value)),
    setFilterForReview: (value) => dispatch(setFilterForReview(value)),
    fetchSettings: () => dispatch(fetchSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponderInbox);
