import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

//Redux
import { fetchBasicEmailTestDetails } from "../../../../../store/EmailDeliverabilityTest/Global/actions/basicEmailTest";
import { fetchEmailAccount } from "../../../../../store/EmailAccounts/EmailAccountSettings/actions/emailAccounts";

//Helpers
import { useOrgDateTimeFormat } from "../../../../../helper/hooks/useOrgDateTimeFormat";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

// Styles
import styles from "./ConfigurationTest.module.scss";

//Images & Icons
import EmptyDeliverabilityTestImage from "../../../../../assets/Images/EmptyDeliverabilityTestImage/EmptyDeliverabilityTestImage";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";

// Components
import ConfigurationTestDetail from "../ConfigurationTestDetail/ConfigurationTestDetail";
import EmptyState from "../../../../../components/UI/EmptyState/EmptyState";
import ConnectedTestEmailForm from "../../ConnectedTestEmailForm";

const ConfigurationTest = (props) => {
  const {
    emailAccountId,
    fetchBasicEmailTestDetails,
    basicTestDetails,
    showTestEmailFormModal,
    receiveStatus,
    sendStatus,
    handleTestMailFormClose,
    emailAccountType,
    errorSendTest,
    isLoadedSendTest,
    emailAccount,
  } = props;
  const { status } = basicTestDetails;
  let isConnected = emailAccount?.isConnected;

  let dateFormat = useOrgDateTimeFormat();

  useEffect(() => {
    fetchBasicEmailTestDetails(emailAccountId);
  }, []);

  useEffect(() => {
    if (isLoadedSendTest) {
      if (errorSendTest !== "") toast.error(errorSendTest);
      else toast.info(toastMessages.emailAccount.successSendTestInfo);
    }
  }, [isLoadedSendTest]);

  useEffect(() => {
    if (status === 400) {
      const interval = setInterval(() => {
        fetchBasicEmailTestDetails(emailAccountId);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [status]);

  let content = null;

  let spfIcon = null;
  let dkimIcon = null;
  let dmarcIcon = null;
  let blacklistsIcon = null;
  let spfResult = "";
  let dkimResult = "";
  let dmarcResult = "";
  //let blacklistsResult = "";
  let spfDescription = "";
  let dkimDescription = "";
  let dmarcDescription = "";
  //let blacklistsDescription = "";
  let spfPass = 0;
  let dkimPass = 0;
  let dmarcPass = 0;
  //let blacklistsPass = 0;

  if (
    status === 200 &&
    !_.isUndefined(basicTestDetails.responseModel) &&
    !_.isEmpty(basicTestDetails.responseModel.signature) &&
    !_.isEmpty(basicTestDetails.responseModel.signature.Subtests) &&
    !_.isEmpty(basicTestDetails.responseModel.blacklists)
  ) {
    if (!_.isEmpty(basicTestDetails.responseModel.signature.Subtests.spf)) {
      spfIcon =
        basicTestDetails.responseModel.signature.Subtests.spf.status === "pass" ? (
          <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" />
        ) : (
          <CloseCircleFillIcon size={22} color="var(--primary-color)" />
        );
      spfResult = basicTestDetails.responseModel.signature.Subtests.spf.status === "pass" ? "PASS" : "FAIL";
      spfPass = basicTestDetails.responseModel.signature.Subtests.spf.status === "pass" ? 1 : 0;

      if (
        !_.isEmpty(basicTestDetails.responseModel.spamAssassin) &&
        !_.isEmpty(basicTestDetails.responseModel.spamAssassin.Rules) &&
        !_.isEmpty(basicTestDetails.responseModel.spamAssassin.Rules.SPF_PASS)
      ) {
        spfDescription =
          basicTestDetails.responseModel.signature.Subtests.spf.status === "pass" ? (
            <>
              {basicTestDetails.responseModel.spamAssassin.Rules.SPF_PASS.solution}.{" "}
              {basicTestDetails.responseModel.spamAssassin.Rules.SPF_PASS.description}.{" "}
              {basicTestDetails.responseModel.signature.Subtests.spf.title.replaceAll("<b>", "").replaceAll("</b>", "")}.{" "}
              {
                <a
                  href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/spf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read more...
                </a>
              }
            </>
          ) : basicTestDetails.responseModel.signature.Subtests.spf.status === "warning" ||
            basicTestDetails.responseModel.signature.Subtests.spf.status === "neutral" ? (
            <>
              We have retained an SPF record, but we can't verify if the SPF record is good. See{" "}
              {
                <a
                  href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/spf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
              }{" "}
              what else you can do.
            </>
          ) : (
            <>
              {basicTestDetails.responseModel.signature.Subtests.spf.title.replaceAll("<b>", "").replaceAll("</b>", "")}. Check{" "}
              {
                <a
                  href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/spf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
              }{" "}
              how to solve this.
            </>
          );
      }
    }

    if (!_.isEmpty(basicTestDetails.responseModel.signature.Subtests.dkim)) {
      dkimIcon =
        basicTestDetails.responseModel.signature.Subtests.dkim.status === "pass" ? (
          <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" />
        ) : (
          <CloseCircleFillIcon size={22} color="var(--primary-color)" />
        );
      dkimResult = basicTestDetails.responseModel.signature.Subtests.dkim.status === "pass" ? "PASS" : "FAIL";
      dkimPass = basicTestDetails.responseModel.signature.Subtests.dkim.status === "pass" ? 1 : 0;

      if (
        !_.isEmpty(basicTestDetails.responseModel.spamAssassin) &&
        !_.isEmpty(basicTestDetails.responseModel.spamAssassin.Rules) &&
        !_.isEmpty(basicTestDetails.responseModel.spamAssassin.Rules.DKIM_VALID)
      ) {
        dkimDescription =
          basicTestDetails.responseModel.signature.Subtests.dkim.status === "pass" ? (
            <>
              {basicTestDetails.responseModel.spamAssassin.Rules.DKIM_VALID.solution}.{" "}
              {basicTestDetails.responseModel.spamAssassin.Rules.DKIM_VALID.description}{" "}
              {
                <a
                  href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/dkim"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read more...
                </a>
              }
            </>
          ) : (
            <>
              {basicTestDetails.responseModel.spamAssassin.Rules.DKIM_VALID.solution}. Check{" "}
              {
                <a
                  href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/dkim"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
              }{" "}
              how to solve this.
            </>
          );
      }
    }

    if (!_.isEmpty(basicTestDetails.responseModel.signature.Subtests.dmarc)) {
      dmarcIcon =
        basicTestDetails.responseModel.signature.Subtests.dmarc.status === "pass" ? (
          <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" />
        ) : (
          <CloseCircleFillIcon size={22} color="var(--primary-color)" />
        );
      dmarcResult = basicTestDetails.responseModel.signature.Subtests.dmarc.status === "pass" ? "PASS" : "FAIL";
      dmarcPass = basicTestDetails.responseModel.signature.Subtests.dmarc.status === "pass" ? 1 : 0;

      dmarcDescription =
        basicTestDetails.responseModel.signature.Subtests.dmarc.status === "pass" ? (
          <>
            {basicTestDetails.responseModel.signature.Subtests.dmarc.messages
              .split(":")[0]
              .replace("<p>", ". ")
              .replace("<b>", "")
              .replace("</b>", "")}{" "}
            {
              <a
                href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/dmarc"
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more...
              </a>
            }
          </>
        ) : (
          <>
            {basicTestDetails.responseModel.signature.Subtests.dmarc.title}. Check{" "}
            {
              <a
                href="https://help.outbase.com/prospecting-tips-and-tricks/email-authentication/dmarc"
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </a>
            }{" "}
            how to solve this.
          </>
        );
    }

    // blacklistsIcon =
    //   basicTestDetails.responseModel.blacklists.statusClass.split(" ")[0] === "success" ? (
    //     <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" />
    //   ) : (
    //     <CloseCircleFillIcon size={22} color="var(--primary-color)" />
    //   );
    // blacklistsResult = basicTestDetails.responseModel.blacklists.statusClass.split(" ")[0] === "success" ? "PASS" : "FAIL";
    // blacklistsPass = basicTestDetails.responseModel.blacklists.statusClass.split(" ")[0] === "success" ? 1 : 0;

    // blacklistsDescription =
    //   basicTestDetails.responseModel.blacklists.statusClass.split(" ")[0] === "success"
    //     ? `${basicTestDetails.responseModel.blacklists.title}. ${basicTestDetails.responseModel.blacklists.description
    //         .replace("<b>", "")
    //         .replace("</b>", "")}`
    //     : `${basicTestDetails.responseModel.blacklists.title}`;
  }

  let totalPass = spfPass + dkimPass + dmarcPass;
  let image = <EmptyDeliverabilityTestImage className={styles.preperingImg} />;

  if (
    status === 200 &&
    !_.isUndefined(basicTestDetails.responseModel) &&
    !_.isEmpty(basicTestDetails.responseModel.signature.Subtests) &&
    !_.isEmpty(basicTestDetails.responseModel.blacklists.statusClass) &&
    !_.isEmpty(basicTestDetails.responseModel.spamAssassin.Rules)
  ) {
    content = (
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <div className={styles.score}>
            <p>Configuration Test Score</p>
            <p className={`${styles.percentage} ${styles.success}`}>{Math.round((totalPass / 3) * 100)}%</p>
          </div>
          <div className={styles.detailsWrapper}>
            <div className={styles.status}>
              <p>Status:</p>
              <div className={styles.statusInfo}>
                {basicTestDetails.status === 200 ? (
                  <>
                    <span className={`${styles.circle} ${styles.success}`} />
                    <p>Completed</p>
                  </>
                ) : (
                  <>
                    <span className={`${styles.circle} ${styles.success}`} />
                    <p>Pending</p>
                  </>
                )}
              </div>
            </div>
            <div className={styles.date}>
              <p className={styles.dateLabel}>Date Received:</p>
              <p>{moment(basicTestDetails.date).format(dateFormat)}</p>
            </div>
          </div>
        </div>
        <div className={styles.statusLists}>
          <ConfigurationTestDetail
            icon={spfIcon}
            testName="SPF check"
            result={spfResult}
            description={spfDescription}
            infoIcon={<InformationFillIcon className="iconGray" size={16} />}
            info={
              !_.isUndefined(basicTestDetails.responseModel) && !_.isEmpty(basicTestDetails.responseModel.signature.Subtests)
                ? basicTestDetails.responseModel.signature.Subtests.spf.description
                : null
            }
          />

          <ConfigurationTestDetail
            icon={dkimIcon}
            testName="DKIM signature"
            result={dkimResult}
            description={dkimDescription}
            infoIcon={<InformationFillIcon className="iconGray" size={16} />}
            info={
              !_.isUndefined(basicTestDetails.responseModel) && !_.isEmpty(basicTestDetails.responseModel.signature.Subtests)
                ? basicTestDetails.responseModel.signature.Subtests.dkim.description
                : null
            }
          />

          <ConfigurationTestDetail
            icon={dmarcIcon}
            testName="DMARC test"
            result={dmarcResult}
            description={dmarcDescription}
            infoIcon={<InformationFillIcon className="iconGray" size={16} />}
            info={
              !_.isUndefined(basicTestDetails.responseModel) && !_.isEmpty(basicTestDetails.responseModel.signature.Subtests)
                ? basicTestDetails.responseModel.signature.Subtests.dmarc.description
                : null
            }
          />
          {/* <ConfigurationTestDetail
            icon={blacklistsIcon}
            testName="Blacklisted"
            result={blacklistsResult}
            description={blacklistsDescription}
            infoIcon={<InformationFillIcon className="iconGray" size={16} />}
            info={
              !_.isUndefined(basicTestDetails.responseModel) && !_.isNull(basicTestDetails.responseModel.blacklists)
                ? `${basicTestDetails.responseModel.blacklists.description.replace("<b>", "").replace("</b>", "")}`
                : null
            }
          /> */}
        </div>
      </div>
    );
  } else if (status === 0) {
    content = (
      <EmptyState
        image={image}
        title="No configuration test found"
        content="Configuration test for this email account is not generated"
        description='Click on the “Configuration test” button."'
      />
    );
  } else {
    content = (
      <EmptyState
        image={image}
        content={
          isConnected ? (
            <span>Kindly hold on while we retrieve and present your results.</span>
          ) : (
            <>
              <span>
                The system no longer has access to the email account. <br />
                Please check the{" "}
              </span>
              <Link className="primary_link f12" to={props.link}>
                email settings.
              </Link>
            </>
          )
        }
      />
    );
  }

  return (
    <>
      {content}

      <ConnectedTestEmailForm
        handleClose={handleTestMailFormClose}
        showModal={showTestEmailFormModal}
        sendStatus={sendStatus}
        receiveStatus={receiveStatus}
        emailAccountId={emailAccountId}
        emailAccountType={emailAccountType}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    basicTestDetails: state.basicEmailDeliverabilityTest.basicTestDetails,
    errorSendTest: state.basicEmailDeliverabilityTest.errorSendTest,
    isLoadedSendTest: state.basicEmailDeliverabilityTest.isLoadedSendTest,
    emailAccount: state.emailAccountSettings.emailAccount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchBasicEmailTestDetails: (emailAccountId) => dispatch(fetchBasicEmailTestDetails(emailAccountId)),
    fetchEmailAccount: (data) => dispatch(fetchEmailAccount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationTest);
