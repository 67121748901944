import { bool } from "yup";
import { emailConfirmedDropdown } from "../../../helper/tableConstants";
import { outbossUserTypeOptions } from "../../../helper/enums/outbossUserTypeEnum";

export const columns = [
  {
    enumId: 0,
    label: "User Name",
    field: "fullName",
    type: "text",
    searchable: true,
    searchType: "text",
    sort: "asc",
    showTooltip: true,
    flex: 1,
  },
  {
    enumId: 1,
    label: "Email Address",
    field: "email",
    type: "text",
    searchable: true,
    searchType: "text",
    sort: "asc",
    showTooltip: true,
    flex: 1,
  },
  {
    enumId: 2,
    label: "Registration Date",
    field: "registrationDate",
    type: "date",
    searchable: true,
    searchType: "date",
    sort: "asc",
    flex: 1,
  },
  {
    enumId: 3,
    label: "Logins",
    field: "totalLogins",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    sort: "asc",
    flex: 1,
  },
  {
    enumId: 4,
    label: "Last Login",
    field: "lastLogin",
    type: "date",
    searchable: true,
    searchType: "date",
    sort: "asc",
    flex: 1,
  },
  {
    enumId: 5,
    label: "Organizations",
    field: "numberOfOrganizations",
    type: "number",
    searchable: true,
    searchType: "range",
    class: "textRight",
    sort: "asc",
    flex: 1,
  },
  {
    enumId: 7,
    label: "Verified",
    field: "emailConfirmed",
    type: "boolean",
    searchable: true,
    searchType: "select",
    option: emailConfirmedDropdown,
    placeholder: "Choose...",
    class: "textRight",
    flex: 1,
  },
  {
    enumId: 8,
    label: "User Type",
    field: "userType",
    type: "text",
    searchable: true,
    searchType: "select",
    option: outbossUserTypeOptions,
    placeholder: "Choose...",
    class: "textRight",
    sort: "asc",
    flex: 1,
  },
  {
    enumId: 6,
    label: "Actions",
    field: "actions",
    type: "actions",
    sort: false,
    minimal: "sm",
    flex: 1,
  },
];
