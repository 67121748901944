import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import _ from "lodash";
import Loading from "../Loading/Loading";

const RouteResolver = (props) => {
  const auth = useAuth();
  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.isAuthenticated) {
    const user = props.user;
    if (user != null && user.email != null && user.email.length > 0) {
      if (user.organization == null || _.isEmpty(user.organization) || !_.isPositive(user.organization.id)) {
        if (user.organizations.length == 0 && !user.hasPendingInvites) return <Redirect to="/register-organization" />;
        else return <Redirect to="/my-organizations" />;
      }

      return <Redirect to={`/${props.user.organization.id}/dashboard`} />;
    } else {
      return <Redirect to="/logout" />;
    }
  }

  return <Redirect to="/login" />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(RouteResolver);
