import { configureStore } from "@reduxjs/toolkit";

import user from "./global/reducers/user";
import invites from "./global/reducers/invites";
import organizationSettingsUsers from "./OrganizationSettings/Users/reducers/users";
import organizationSettingsGeneral from "./OrganizationSettings/General/reducers/general";
import dealNavigator from "./DealNavigator/reducers/dealNavigator";
import global from "./global/reducers/global";
import globalCompanies from "./Companies/Global/reducers/companies";
import globalProspects from "./Prospects/Global/reducers/prospects";
import salesDocuments from "./SalesDocuments/reducer/salesDocuments";
import globalSearh from "./GlobalSearch/reducers/globalSearch";
import globalEmailAccounts from "./EmailAccounts/Global/reducers/emailAccounts";
import dataTableReducer from "./hor/dataTableReducer";
import workflowSteps from "./WorkflowSteps/reducers/workflowSteps";
import emailTemplate from "./EmailTemplates/Global/reducers/emailTemplates";
import deals from "./Deals/Table/reducers/deals";
import globalAudiences from "./Audiences/Global/reducers/audiences";
import supportCenterQA from "./ComplianceSupportCenter/reducers/supportCenterQA";
import companyActivityOverview from "./CompanyActivityOverview/reducers/companyActivityOverview";
import sendingSchedule from "./SendingSchedule/reducer/sendingSchedule";
import emailAccountSettings from "./EmailAccounts/EmailAccountSettings/reducers/emailAccounts";
import responderInbox from "./ResponderInbox/reducers/responderInbox";
import prospectActivityOverview from "./ProspectActivityOverview/reducers/prospectActivityOverview";
import conversation from "./Conversation/reducer/conversation";
import reports from "./Reports/Global/reducers/reports";
import globalSendReports from "./SendReports/Global/reducer/sendReports";

import inAppNotifications from "./global/reducers/inAppNotifications";
import basicEmailDeliverabilityTest from "./EmailDeliverabilityTest/Global/reducers/basicEmailTest";
import advanceEmailDeliverabilityTest from "./EmailDeliverabilityTest/Global/reducers/advanceEmailTest";
import dashboard from "./Dashboard/Global/reducers/dashboard";
import responderInboxTable from "./ResponderInbox/Table/reducers/responderInboxTable";
import globalIntegrations from "./CRM-Integrations/Global/reducer/globalIntegrationReducer";
import hubspotIntegration from "./CRM-Integrations/HubSpot/Global/reducer/hubspot-integration";
import billingAndPayment from "./BillingAndPayment/reducer/billingAndPayment";
import prospectImportProcesses from "./ProspectImportProcesses/reducers/importProcesses";
import outbossUserDetails from "./Outboss/Users/Global/reducers/user";
import outbossOrganization from "./Outboss/Organizations/Global/reducers/organizations";
import purchaseTemplate from "./EmailTemplates/Global/reducers/purchaseTemplates";
import signal from "./Signal/reducers/signal";
import outbossUserActivityLogs from "./Outboss/UserActivityLogs/Global/reducers/userActivityLogs";
import { api } from "../api/api";
import { notificationsReducer } from "./global/notificationsSlice";
import {
  campaignsDataTable,
  explorerCompaniesDataTable,
  explorerContactsDataTable,
  questionsDataTable,
  templatesDataTable,
} from "./registerDataTables";
import campaignConfigSlice from "./Campaigns/campaignConfigSlice";
import audienceExplorerSlice from "./AudiencesExplorer/audienceExplorerSlice";
import assistantSlice from "./Assistant/assistantSlice";
import emailTemplateSlice from "./EmailTemplates/emailTemplateSlice";

export const store = configureStore({
  reducer: {
    assistant: assistantSlice.reducer,
    user: user,
    organizationSettingsUsers: organizationSettingsUsers,
    organizationSettingsGeneral: organizationSettingsGeneral,
    global: global,
    invites: invites,
    dealNavigator: dealNavigator,
    salesDocuments: salesDocuments,
    complianceSupportCenterQA: supportCenterQA,
    salesDocumentsTable: dataTableReducer("SALES_DOCUMENTS_TABLE"),
    campaigns: dataTableReducer("CAMPAIGNS"),
    prospects: dataTableReducer("PROSPECTS"),
    globalCompanies: globalCompanies,
    globalProspects: globalProspects,
    companies: dataTableReducer("COMPANIES"),
    emailAccounts: dataTableReducer("EMAIL_ACCOUNTS"),
    emailAccountSettings: emailAccountSettings,
    globalSearh: globalSearh,
    emailExclusions: dataTableReducer("EMAIL_EXCLUSIONS"),
    domainExclusions: dataTableReducer("DOMAIN_EXCLUSIONS"),
    responderExclusions: dataTableReducer("RESPONDER_EXCLUSIONS"),
    unsubscribesExclusions: dataTableReducer("UNSUBSCRIBES_EXCLUSIONS"),
    globalEmailAccounts: globalEmailAccounts,
    emailTemplates: dataTableReducer("EMAIL_TEMPLATES"),
    workflowSteps: workflowSteps,
    globalEmailTemplate: emailTemplate,
    domains: dataTableReducer("DOMAINS"),
    inactiveDomains: dataTableReducer("INACTIVE_DOMAINS"),
    sendingSchedule: sendingSchedule,
    deferredIntrestDeals: dataTableReducer("DEFERRED_INTREST_DEALS"),
    positiveResponsesDeals: dataTableReducer("POSITIVE_RESPONSES_DEALS"),
    referralsDeals: dataTableReducer("REFERRALS_DEALS"),
    deals: deals,
    audiences: dataTableReducer("AUDIENCES"),
    globalAudiences: globalAudiences,
    companyActivityOverview: companyActivityOverview,
    companyActivityOverviewTable: dataTableReducer("COMPANY_ACTIVITY_OVERVIEW"),
    responderInbox: responderInbox,
    responderInboxTable: responderInboxTable,
    prospectActivityOverview: prospectActivityOverview,
    conversation: conversation,
    conversationTable: dataTableReducer("CONVERSATION"),
    reports: reports,
    inAppNotifications: inAppNotifications,
    basicEmailDeliverabilityTestTable: dataTableReducer("BASIC_EMAIL_TEST"),
    advanceEmailDeliverabilityTestTable: dataTableReducer("ADVANCE_EMAIL_TEST"),
    advanceEmailDeliverabilityTest: advanceEmailDeliverabilityTest,
    basicEmailDeliverabilityTest: basicEmailDeliverabilityTest,
    dashboard: dashboard,
    campaignPerformanceByCamapaignTable: dataTableReducer("CAMPAIGN_PERFORMANCE_BY_CAMPAING"),
    dataAndAudienceByCamapignTable: dataTableReducer("DATA_AND_AUDIENCE_BY_CAMPAING"),
    responsesByCampaignTable: dataTableReducer("RESPONSES_BY_CAMPAING"),
    runRatesByCampaignTable: dataTableReducer("RUN_RATES_BY_CAMPAING"),
    audienceContacts: dataTableReducer("AUDIENCE_CONTACTS"),
    globalIntegrations: globalIntegrations,
    hubspotIntegration: hubspotIntegration,
    billingAndPayment: billingAndPayment,
    audienceExplorerCompanies: dataTableReducer("AE_COMPANIES"),
    audienceExplorerProspects: dataTableReducer("AE_PROSPECTS"),
    invoices: dataTableReducer("INVOICES"),
    prospectImportProcesses: prospectImportProcesses,
    outbossOrganizations: dataTableReducer("OUTBOSS_ORGANIZATIONS"),
    outbossUsers: dataTableReducer("OUTBOSS_USERS"),
    outbossUserDetailsDataTable: dataTableReducer("OUTBOSS_USER_DETAILS"),
    outbossUserDetails: outbossUserDetails,
    outbossOrganizationCreditAdjustments: dataTableReducer("OUTBOSS_ORGANIZATION_CREDIT_ADJUSTMENTS"),
    outbossOrganization: outbossOrganization,
    outbossEmailAccounts: dataTableReducer("OUTBOSS_EMAIL_ACCOUNTS"),
    outbossSyncLogs: dataTableReducer("OUTBOSS_SYNC_LOGS"),
    purchaseTemplateTable: dataTableReducer("PURCHASE_TEMPLATE_TABLE"),
    purchaseTemplate: purchaseTemplate,
    signal: signal,
    outbossUserActivityLogsDataTable: dataTableReducer("OUTBOSS_USER_ACTIVITY_LOGS"),
    outbossUserActivityLogs: outbossUserActivityLogs,
    sendReports: dataTableReducer("SEND_REPORTS"),
    globalSendReports: globalSendReports,
    [api.reducerPath]: api.reducer,
    questionsDataTable: questionsDataTable.reducer,
    campaignsDataTable: campaignsDataTable.reducer,
    templatesDataTable: templatesDataTable.reducer,
    explorerContactsDataTable: explorerContactsDataTable.reducer,
    explorerCompaniesDataTable: explorerCompaniesDataTable.reducer,
    notifications: notificationsReducer,
    campaign: campaignConfigSlice.reducer,
    explorer: audienceExplorerSlice.reducer,
    emailTemplatesSlice: emailTemplateSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  // enhancers: [composeEnhancers(applyMiddleware(logger, thunk))],
});

export type RootState = ReturnType<typeof store.getState>;
