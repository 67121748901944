import { useFormikContext } from "formik";
import moment from "moment";

// Helpers
import { useGetFundingTypesQuery } from "@api/audienceExplorerApi";
import { inputFieldSymbolPlaceEnum } from "@helper/enums/inputFieldSymbolPlaceEnum";
import { dateToUTC } from "@helper/dateHelper";

//Styles
import styles from "../Filters.module.scss";

//Components
import FormikSelect from "@ui/Formik/FormikSelect";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import FormikInput from "@ui/Formik/FormikInput";
import FormikDatePicker from "@ui/Formik/FormikDatePicker";

const FilterCompanyFunding = () => {
  const {
    values: {
      company: {
        companyFunding: {
          min: companyFundingMin,
          max: companyFundingMax,
          lastFundingRoundType,
          dateFrom: companyFundingDateFrom,
          dateTo: companyFundingDateTo,
        },
      },
    },
  } = useFormikContext<SearchModel>();
  const fundingTypes = useGetFundingTypesQuery();

  return (
    <>
      <div className={styles.filterBox}>
        <p className="mB5">Last total funding amount</p>
        <div className="flex align-flex-start">
          <FormikInput
            inputGroupClass="m0"
            type="numberWithSymbol"
            name="company.companyFunding.min"
            value={companyFundingMin}
            placeholder="0"
            inputclass="width_100"
            errorinputclass={styles.error}
            inputSymbol="$"
            inputSymbolPosition={inputFieldSymbolPlaceEnum.Prefix}
          />
          &nbsp; &nbsp; &nbsp;
          <p className="mT5">
            <b>-</b>
          </p>
          &nbsp; &nbsp; &nbsp;
          <FormikInput
            inputGroupClass="m0"
            type="numberWithSymbol"
            name="company.companyFunding.max"
            value={companyFundingMax}
            placeholder="0"
            inputclass="width_100"
            inputSymbol="$"
            inputSymbolPosition={inputFieldSymbolPlaceEnum.Prefix}
          />
        </div>
      </div>
      <div className={styles.filterBox + " mT10"}>
        <p className="mB5">Last funding round type</p>
        <FormikSelect
          showValuesOutside={true}
          isMulti={true}
          name="company.companyFunding.lastFundingRoundType"
          value={lastFundingRoundType}
          options={fundingTypes && fundingTypes.data ? fundingTypes.data : []}
          displayName="Select funding type"
          placeholder="Select funding type"
          isSearchable={true}
          enableReinitialize={true}
          dropdownButtonClass={"mB0"}
        />
      </div>

      <div className={styles.filterBox + " mT10"}>
        <p className="mB5">Last funding date range</p>
        <div className="flex align-center">
          <FormikDatePicker
            errorClass={styles.error}
            formgroupclass="m0"
            datePickerClass={styles.formInput}
            placeholderText="From"
            name="company.companyFunding.dateFrom"
            selected={companyFundingDateFrom ? dateToUTC(companyFundingDateFrom) : companyFundingDateFrom}
            value={companyFundingDateFrom ? dateToUTC(companyFundingDateFrom) : companyFundingDateFrom}
            dateFormat="dd MMM yyyy"
            maxDate={moment().toDate()}
          />

          <p className="mL5 mR5">
            <b>-</b>
          </p>

          <FormikDatePicker
            errorClass={styles.error}
            formgroupclass="m0"
            datePickerClass={styles.formInput}
            placeholderText="To"
            name="company.companyFunding.dateTo"
            selected={companyFundingDateFrom ? dateToUTC(companyFundingDateFrom) : companyFundingDateFrom}
            value={companyFundingDateTo ? dateToUTC(companyFundingDateTo) : companyFundingDateTo}
            dateFormat="dd MMM yyyy"
            maxDate={moment().toDate()}
          />
        </div>
      </div>
    </>
  );
};

export default FilterCompanyFunding;
