import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

//Redux
import { deleteHubspotRule } from "../../../../../store/CRM-Integrations/HubSpot/Global/actions/hubspot-integration";

//Style
import styles from "./RulesCard.module.scss";

//Icons and Images
import BrandMarkLogoDark from "../../../../../assets/Logo/BrandMarkLogoDark/BrandMarkLogoDark";
import HubSpotBrandMarkLogo from "../../../../../assets/Images/CRM/HubSpotBrandMarkLogo/HubSpotBrandMarkLogo";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const RulesCard = (props) => {
  const { setIsEdittingRule, eventTitle, actionTitle, deleteHubspotRule, setEditCustomRuleId, ruleId, isLoading } = props;

  const [showModal, setShowModal] = useState(false);

  const handleDeleteRule = (id) => {
    deleteHubspotRule(ruleId);
  };

  useEffect(() => {
    if (showModal && !isLoading) {
      setShowModal(false);
    }
  }, [isLoading]);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.trigger}>
        <div className={styles.iconWrapper}>
          <BrandMarkLogoDark />
        </div>
        <div className={styles.dataWrapper}>
          <p className={styles.title}>Trigger Event</p>
          <p className={styles.selectedEvent}>{eventTitle}</p>
        </div>
      </div>
      <div className={`${styles.action} actionsContainer`}>
        <div className={styles.iconWrapper}>
          <HubSpotBrandMarkLogo />
        </div>
        <div className={styles.dataWrapper}>
          <p className={styles.title}>Action</p>
          <p className={styles.selectedEvent}>{actionTitle}</p>
        </div>
      </div>
      {props.showActionButtons ? (
        <div className={styles.actionsWrapper}>
          <span
            onClick={() => {
              setIsEdittingRule(true);
              setEditCustomRuleId(ruleId);
            }}
          >
            <Edit2LineIcon className="iconGray" size={15} />
          </span>
          <span onClick={() => setShowModal(true)}>
            <DeleteBin6LineIcon className="iconGray" size={15} />
          </span>
        </div>
      ) : null}

      <Modal
        title={"Delete rule"}
        handleClose={() => setShowModal(false)}
        handleSave={() => handleDeleteRule(ruleId)}
        show={showModal}
        closeButtonText="No"
        saveButtonText="Yes"
        btnType="submit"
        isLoading={isLoading}
        saveButtonDisabled={isLoading}
      >
        <Container>
          <Row>
            <Col xl={12}>
              <p>
                Are you sure you want to delete the selected rule: {eventTitle} - {actionTitle}?
              </p>
            </Col>
          </Row>
        </Container>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.hubspotIntegration.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteHubspotRule: (id) => dispatch(deleteHubspotRule(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RulesCard);
