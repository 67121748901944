import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const fetchAudiencesRequest = () => {
  return {
    type: actionTypes.FETCH_AUDIENCES_REQUEST,
  };
};

export const fetchAudiencesSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_AUDIENCES_SUCCEEDED,
    result: settings,
  };
};

export const fetchAudiencesFailed = (error) => {
  return {
    type: actionTypes.FETCH_AUDIENCES_FAILED,
    result: error,
  };
};

export const fetchAudiences = () => {
  return (dispatch) => {
    dispatch(fetchAudiencesRequest());
    return api(formUrl(API.audience.listAudiences))
      .then((response) => {
        dispatch(fetchAudiencesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAudiencesFailed(error.message));
      });
  };
};

export const fetchAudienceDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_AUDIENCE_DETAILS_REQUEST,
  };
};

export const fetchAudienceDetailsSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_AUDIENCE_DETAILS_SUCCEEDED,
    result: settings,
  };
};

export const fetchAudienceDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_AUDIENCE_DETAILS_FAILED,
    result: error,
  };
};

export const fetchAudienceDetails = (audienceId) => {
  return (dispatch) => {
    dispatch(fetchAudienceDetailsRequest());
    let data = {
      audienceId: audienceId,
    };

    return api(formUrl(API.audience.audienceDetails, data))
      .then((response) => {
        dispatch(fetchAudienceDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAudienceDetailsFailed(error.message));
      });
  };
};

export const audienceDetailsUpdate = (progressUpdate) => {
  return {
    type: actionTypes.AUDIENCE_DETAILS_UPDATE,
    result: progressUpdate,
  };
};

export const audienceStatsUpdate = (updatedStats) => {
  return {
    type: actionTypes.AUDIENCE_STATS_UPDATE,
    result: updatedStats,
  };
};
