import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNAL_RECEIVED:
      const signal = action.result;

      return signal;
    default:
      break;
  }

  return state;
};

export default reducer;
