import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const setSearchValue = (search) => {
  return {
    type: actionTypes.SET_SEARCH_VALUE,
    result: search,
  };
};

export const fetchSupportCenterComplianceSettingsRequest = () => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_REQUEST,
  };
};

export const fetchSupportCenterComplianceSettingsSuccess = (complianceSettings) => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_SUCCEEDED,
    result: complianceSettings,
  };
};

export const fetchSupportCenterComplianceSettingsFailed = (error) => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_FAILED,
    result: error,
  };
};

export const fetchSupportCenterComplianceSettings = () => {
  return (dispatch) => {
    dispatch(fetchSupportCenterComplianceSettingsRequest());
    return api(API.complianceSupportCenter.getComplianceSettings)
      .then((response) => {
        dispatch(fetchSupportCenterComplianceSettingsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSupportCenterComplianceSettingsFailed(error.message));
      });
  };
};

export const fetchSupportCenterRTERequest = () => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_RTE_REQUEST,
  };
};

export const fetchSupportCenterRTESuccess = (rte) => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_RTE_SUCCEEDED,
    result: rte,
  };
};

export const fetchSupportCenterRTEFailed = (error) => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_RTE_FAILED,
    result: error,
  };
};

export const fetchSupportCenterRTE = (email) => {
  return (dispatch) => {
    dispatch(fetchSupportCenterRTERequest());
    return api(formUrl(API.complianceSupportCenter.getRTE, { email }))
      .then((response) => {
        dispatch(fetchSupportCenterRTESuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSupportCenterRTEFailed(error.message));
      });
  };
};

export const fetchSupportCenterComplianceSentencesRequest = () => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_REQUEST,
  };
};

export const fetchSupportCenterComplianceSentencesSuccess = (complianceSentences) => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_SUCCEEDED,
    result: complianceSentences,
  };
};

export const fetchSupportCenterComplianceSentencesFailed = (error) => {
  return {
    type: actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_FAILED,
    result: error,
  };
};

export const fetchSupportCenterComplianceSentences = () => {
  return (dispatch) => {
    dispatch(fetchSupportCenterComplianceSentencesRequest());
    return api(API.complianceSupportCenter.getComplianceSentences)
      .then((response) => {
        dispatch(fetchSupportCenterComplianceSentencesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSupportCenterComplianceSentencesFailed(error.message));
      });
  };
};
//#endregion

//#region ADD
export const addSupportCenterQASuccess = (qa) => {
  return {
    type: actionTypes.ADD_SUPPORT_CENTER_QA_SUCCEEDED,
    result: qa,
  };
};

export const addSupportCenterQAFailed = (error) => {
  return {
    type: actionTypes.ADD_SUPPORT_CENTER_QA_FAILED,
    result: error,
  };
};
//#endregion

//#region CHANGE
export const changeSupportCenterQASuccess = (qa) => {
  return {
    type: actionTypes.CHANGE_SUPPORT_CENTER_QA_SUCCEEDED,
    result: qa,
  };
};

export const changeSupportCenterQAFailed = (error) => {
  return {
    type: actionTypes.CHANGE_SUPPORT_CENTER_QA_FAILED,
    result: error,
  };
};

export const changeSupportCenterEmailNotificationsSuccess = (emailNotificationsModel) => {
  return {
    type: actionTypes.CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_SUCCEEDED,
    result: emailNotificationsModel,
  };
};

export const changeSupportCenterEmailNotificationsFailed = (error) => {
  return {
    type: actionTypes.CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_FAILED,
    result: error,
  };
};

export const changeSupportCenterExternalServiceSuccess = (externalServiceModel) => {
  return {
    type: actionTypes.CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_SUCCEEDED,
    result: externalServiceModel,
  };
};

export const changeSupportCenterExternalServiceFailed = (error) => {
  return {
    type: actionTypes.CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_FAILED,
    result: error,
  };
};
//#endregion

//#region REMOVE
export const removeSupportCenterQARequest = () => {
  return {
    type: actionTypes.REMOVE_SUPPORT_CENTER_QA_REQUEST,
  };
};

export const removeSupportCenterQASuccess = (qa) => {
  return {
    type: actionTypes.REMOVE_SUPPORT_CENTER_QA_SUCCEEDED,
    result: qa,
  };
};

export const removeSupportCenterQAFailed = (error) => {
  return {
    type: actionTypes.REMOVE_SUPPORT_CENTER_QA_FAILED,
    result: error,
  };
};

export const removeSupportCenterQA = (id) => {
  return (dispatch) => {
    dispatch(removeSupportCenterQARequest());
    return api(formUrl(API.complianceSupportCenter.removeQA, { id: id }))
      .then((response) => {
        dispatch(removeSupportCenterQASuccess(id));
      })
      .catch((error) => {
        dispatch(removeSupportCenterQAFailed(error.message));
      });
  };
};

export const removeSupportCenterRTERequest = () => {
  return {
    type: actionTypes.REMOVE_SUPPORT_CENTER_RTE_REQUEST,
  };
};

export const removeSupportCenterRTESuccess = (rte) => {
  return {
    type: actionTypes.REMOVE_SUPPORT_CENTER_RTE_SUCCEEDED,
    result: rte,
  };
};

export const removeSupportCenterRTEFailed = (error) => {
  return {
    type: actionTypes.REMOVE_SUPPORT_CENTER_RTE_FAILED,
    result: error,
  };
};

export const removeSupportCenterRTE = (email) => {
  return (dispatch) => {
    dispatch(removeSupportCenterRTERequest());
    return api(formUrl(API.complianceSupportCenter.removeRTE, { email: email }))
      .then((response) => {
        dispatch(removeSupportCenterRTESuccess(response.data));
      })
      .catch((error) => {
        dispatch(removeSupportCenterRTEFailed(error.message));
      });
  };
};
//#endregion
