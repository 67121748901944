import React, { useEffect, useState, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import EmailExclusions from "./EmailExclusions/EmailExclusions";
import { connect } from "react-redux";

//Redux
import {
  fetchEmailExclusions,
  searchRecords as searchEmailExclusions,
  setFilter as setEmailExclusionsFilter,
} from "../../store/Exclusions/Table/actions/emailExclusions";
import {
  fetchDomainExclusions,
  searchRecords as searchDomainExclusions,
  setFilter as setDomainExclusionsFilter,
} from "../../store/Exclusions/Table/actions/domainExclusions";
import {
  searchRecords as searchUnsubscribeExclusions,
  setFilter as setUnsubscribeExclusionsFilter,
} from "../../store/Exclusions/Table/actions/unsubscribesExclusions";
import {
  searchRecords as searchResponderExclusions,
  setFilter as setResponderExclusionsFilter,
} from "../../store/ResponderInbox/Table/actions/responderExclusions";

//Styles
import styles from "./Exclusions.module.scss";

//Components
import DomainExclusions from "./DomainExclusions/DomainExclusions";
import ResponderExclusions from "./ResponderExclusions/ResponderExclusions";
import UnsubscribesExclusions from "./UnsubscribeExclusions/UnsubscribesExclusions";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import NewExclusionsForm from "./NewExclusionsForm";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const Exclusions = (props) => {
  const [height, setHeight] = useState(0);
  const headerContainerRef = useRef();

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Keep your campaigns compliant, by adding any email addresses or domains that you don't want to send emails to."}
      href={"https://help.outbase.com/user-guide/all-prospects/exclusions"}
      linkText="Read More"
      buttonId="startExclusionsTour"
      disableStartTourButton
    />
  );

  useEffect(() => {
    let topHeight = headerContainerRef.current.offsetHeight;
    setHeight(window.innerHeight - topHeight);
  }, []);

  const [showNewExclusionsModal, setShowNewExclusionsModal] = useState(false);
  const handleNewExclusionsClose = () => {
    props.fetchEmailExclusions(paramsEmailExclusions);
    props.fetchDomainExclusions(paramsDomainExclusions);
    setShowNewExclusionsModal(false);
  };
  const handleNewExclusionsShow = () => {
    setShowNewExclusionsModal(true);
  };

  const setParamsForExclusions = (emailExclusionsParams = null, domainExclusionsParams = null) => {
    if (emailExclusionsParams !== null) {
      setParamsEmailExclusions(emailExclusionsParams);
    }

    if (domainExclusionsParams !== null) {
      setParamsDomainExclusions(domainExclusionsParams);
    }
  };

  const [paramsEmailExclusions, setParamsEmailExclusions] = useState(null);
  const [paramsDomainExclusions, setParamsDomainExclusions] = useState(null);

  const handleSearchExclusionsChange = (searchText) => {
    props.searchEmailExclusions(searchText);
    props.searchDomainExclusions(searchText);
    props.searchUnsubscribeExclusions(searchText);
    props.searchResponderExclusions(searchText);
  };

  return (
    <PageContentWrapper noPadding className={`${styles.exclusionsWrapper} contentHeight`}>
      <div ref={headerContainerRef}>
        <HeaderTitle
          title={"Exclusions"}
          icon={icon}
          showSearch={true}
          searchPlaceholder={"Search exclusions"}
          textChanged={handleSearchExclusionsChange}
          className={styles.headerTitleWrapper}
        />
      </div>
      <div className="flex m0 space_between width_100 height_100">
        <EmailExclusions
          titleHeight={height}
          setParamsForExclusions={setParamsForExclusions}
          handleNewExclusionsShow={handleNewExclusionsShow}
        />
        <DomainExclusions
          titleHeight={height}
          setParamsForExclusions={setParamsForExclusions}
          handleNewExclusionsShow={handleNewExclusionsShow}
        />
        <ResponderExclusions titleHeight={height} />
        <UnsubscribesExclusions titleHeight={height} />
      </div>

      <NewExclusionsForm handleClose={handleNewExclusionsClose} showModal={showNewExclusionsModal} organizationId={props.organizationId} />
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchEmailExclusions: (value) => dispatch(searchEmailExclusions(value)),
    searchDomainExclusions: (value) => dispatch(searchDomainExclusions(value)),
    searchUnsubscribeExclusions: (value) => dispatch(searchUnsubscribeExclusions(value)),
    searchResponderExclusions: (value) => dispatch(searchResponderExclusions(value)),
    setEmailExclusionsFilter: (type, value) => dispatch(setEmailExclusionsFilter(type, value)),
    setDomainExclusionsFilter: (type, value) => dispatch(setDomainExclusionsFilter(type, value)),
    setUnsubscribeExclusionsFilter: (type, value) => dispatch(setUnsubscribeExclusionsFilter(type, value)),
    setResponderExclusionsFilter: (type, value) => dispatch(setResponderExclusionsFilter(type, value)),
    fetchEmailExclusions: (param) => dispatch(fetchEmailExclusions(param)),
    fetchDomainExclusions: (param) => dispatch(fetchDomainExclusions(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exclusions);
