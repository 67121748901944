import React from "react";

const IconArchived = (props) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 0L20 4V17C20 17.5523 19.5523 18 19 18H1C0.44772 18 0 17.5523 0 17V4.00353L2 0H18ZM18 6H2V16H18V6ZM11 7V11H14L10 15L6 11H9V7H11ZM16.7639 2H3.23656L2.23744 4H17.7639L16.7639 2Z"
      fill="#5C656F"
    />
  </svg>
);

export default IconArchived;
