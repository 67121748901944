import React from "react";
import { Link } from "react-router-dom";

//Style
import styles from "./NotificationUpdatePayment.module.scss";

//Components
import AlertNotification from "../../../../../components/UI/AlertNotification/AlertNotification";

const NotificationUpdatePayment = (props) => {
  return (
    <AlertNotification notificationWrapperClass={styles.wrapper} bodyClassName="flex" variant="danger_alert" dismissible="dismissible">
      <div className={`${styles.alertBody} flex`}>
        {props.orgId ? (
          <p className={styles.infoText}>
            There is a pending open Invoice. Please go to
            <Link className={styles.noEmailAccountModalLink} to={`/${props.orgId}/organization-settings/billing-and-payment`}>
              {" "}
              Billing and Payment{" "}
            </Link>
            to process your payment
          </p>
        ) : (
          <p className={styles.infoText}>
            We were unable to process payment from your credit card. Click the PDF on your open invoice to make payment and resume service.{" "}
            <br />
            Need to update future payments? Edit your payment method on the right.
          </p>
        )}
      </div>
    </AlertNotification>
  );
};

export default NotificationUpdatePayment;
