import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  conversationPreview: {},
  prospectDetails: {},
  prospectId: 0,
  isLoading: false,
  isLoaded: false,
  error: "",
};

//#region FETCH
const fetchProspectDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchProspectDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let prospect = { ...state.prospectDetails };
  prospect = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.prospectDetails = prospect;
  return newState;
};

const fetchProspectDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchConversationDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchConversationDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let conversationPreview = { ...state.conversationPreview };
  conversationPreview = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.conversationPreview = conversationPreview;
  return newState;
};

const fetchConversationDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROSPECT_DETAILS_REQUEST:
      return fetchProspectDetailsRequest(state, action);

    case actionTypes.FETCH_PROSPECT_DETAILS_SUCCEEDED:
      return fetchProspectDetailsSuccess(state, action);

    case actionTypes.FETCH_PROSPECT_DETAILS_FAILED:
      return fetchProspectDetailsFailed(state, action);

    case actionTypes.FETCH_CONVERSATION_PREVIEW_REQUEST:
      return fetchConversationDetailsRequest(state, action);

    case actionTypes.FETCH_CONVERSATION_PREVIEW_SUCCEEDED:
      return fetchConversationDetailsSuccess(state, action);

    case actionTypes.FETCH_CONVERSATION_PREVIEW_FAILED:
      return fetchConversationDetailsFailed(state, action);

    default:
      break;
  }

  return state;
};

export default reducer;
