import React, {useState} from "react";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../../../helper/api/api";
import { fileDownload } from "../../../../helper/fileDownloadHelper";

//Icons
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

//Styles
import styles from "./Steps.module.scss";

// Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { cs } from "date-fns/locale";
import UploadErrorMessages from "./UploadErrorMessages";

const FirstStep = (props) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const handleUploadCSVResponse = (res, csvFile) => {
    if (!res.data || res.data.unexpectedError) {
      props.setUnexpectedCSVError(true);
      setFileErrorTrue();
    }
    else{
      setErrorMessage(null);
    }
    if (res.data.limitExceeded) {
      if(res?.data?.errorMessage){
        setErrorMessage(res.data.errorMessage);
      }
      setFileErrorTrue();
      props.setCSVLimitExceeded(true);
    } else {
      props.formikProps.setFieldValue("csvFile", csvFile);
      props.formikProps.setFieldValue("csvFileLink", res.data.csvFileUri);
      props.formikProps.setFieldValue("csvFileColumns", res.data.columnsNames);
      props.formikProps.setFieldValue("csvFileCustomColumns", res.data.customColumnsNames);
      props.setIsFileError(false);
      props.setCSVLimitExceeded(false);
      props.setWrongTypeError(false);
    }
    props.setIsSubmitting(false);
  };

  const setFileErrorTrue = () => {
    props.setPercentCompleted(0);
    props.setCSVFileName("");
    props.setShowButton(false);
    props.formikProps.setFieldValue("csvFile", "");
    props.formikProps.setFieldValue("csvFileLink", "");
    props.formikProps.setFieldValue("csvFileColumns", "");
    props.formikProps.setFieldValue("csvFileCustomColumns", "");
    props.setIsFileError(true);
  };

  const uploadCSVFile = (csvFile, actions) => {
    if (csvFile !== undefined && (csvFile.type === "text/csv" || csvFile.type === "application/vnd.ms-excel")) {
      props.setIsSubmitting(true);
      var data = new FormData();
      props.setShowButton(true);
      props.setCustomBodyClass();
      let audienceId = 0;
      if(props.audienceId)audienceId = props.audienceId;
      data.append("csvFile", csvFile);
      data.append("audienceId", audienceId);

      let onUploadProgress = function (progressEvent) {
        props.setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      };
      api(API.audience.uploadCSV, data, null, onUploadProgress)
        .then((res) => {
          handleUploadCSVResponse(res, csvFile);
        })
        .catch((error) => {
          const errorList = errorResponseToList(error);
          if (errorList.length > 0) {
            errorList.forEach((e) => (e.field === "csvFile" ? toast.error(e.message) : null));
          } else {
            setFileErrorTrue();
            props.setUnexpectedCSVError(true);
            props.setIsSubmitting(false);
          }
        })
        .finally(() => {});

      props.setCSVFileName(csvFile.name);
      props.setCSVLimitExceeded(false);
      props.setWrongTypeError(false);
    } else {
      setFileErrorTrue();
      props.setWrongTypeError(true);
    }
  };

  const downloadCSVTemplate = () => {
    api(API.audience.downloadCSVTemplate, null, "blob")
      .then((res) => {
        fileDownload("List.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <Container>
      <Row>
        <div className="mT15 width_100 flex">
          <Col xl={12}>
            <FormikInput
              label="List Name"
              id="audienceName"
              readOnly={props.formikProps.values.audienceId > 0 ? true : false}
              name={props.audienceName}
              value={props.audienceValue}
              placeholder={"Add name for your list"}
            />
          </Col>
        </div>
        <Col xl={12} className="mB5">
          <div>
            <Dropzone
              accept=".csv"
              multiple={false}
              onDrop={(acceptedFiles, fileRejections, event) => uploadCSVFile(acceptedFiles[0], event.actions)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className: props.isFileError ? styles.fileError : styles.uploadCSVFile,
                    })}
                  >
                    <input id="uploadFile" {...getInputProps()} />
                    <p className="f12 m0" name={props.csvFileLink}>
                      <UploadCloud2LineIcon size={18} className={styles.uploadIcon} />
                      Drop your csv file here or{" "}
                      <span id="browseLink" className={styles.orangeText}>
                        browse
                      </span>
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </Col>
        <Col xl={12} className={styles.csvUploadInformationDiv}>
          <p>
            Your file must include an <b>email address</b> column.
          </p>
          &nbsp;&nbsp;&nbsp;
          <a id="downloadCSV" href={null} className={styles.downloadCSVTemplate} onClick={downloadCSVTemplate}>
            Download csv template
          </a>
        </Col>

        {props.showButton ? (
          <div className="flex_center width_100 mT15 mB10">
            <Col xl={6}>
              <p className={styles.csvFileName}>{props.CSVFileName}</p>
            </Col>

            <Col xl={6} className="flex_center">
              <>
                <div
                  className="flex_center"
                  id="deleteFileButton"
                  onClick={() => {
                    props.setPercentCompleted(0);
                    props.setCSVFileName("");
                    props.setShowButton(false);
                    props.formikProps.setFieldValue("csvFile", "");
                    props.formikProps.setFieldValue("csvFileLink", "");
                    props.formikProps.setFieldValue("csvFileColumns", "");
                    props.formikProps.setFieldValue("csvFileCustomColumns", "");
                    props.setIsFileError(false);
                  }}
                >
                  <DeleteBin6LineIcon size={18} className={`mR15 cursor_pointer ${styles.binIcon}`} />
                </div>
                <label className={`${styles.choseAnotherFile} flex_center`}>
                  Chose another file
                  <input
                    id="csvFile"
                    name="csvFile"
                    type="file"
                    accept=".csv"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      uploadCSVFile(event.currentTarget.files[0], event.actions);
                    }}
                    className="form-control"
                  />
                </label>
              </>
            </Col>
          </div>
        ) : null}
        {props.percentCompleted > 0 || props.isFileError ? (
          <>
            <Col xl={12} className="mB10">
              <ProgressBar
                className={props.percentCompleted ? styles.uploadFileProgressBar : styles.grayProgressBar}
                now={props.percentCompleted}
                max={100}
                min={1}
              />
            </Col>

            <Col xl={6} className={`textLeft ${styles.uploadFileProgressInfo}`}>
              {props.isFileError && props.unexpectedCSVError ? <p className={styles.uploadFileError}>File upload has failed.</p> : null}
              {props.percentCompleted >= 100 ? "Done" : ""}
            </Col>

            <Col xl={6} className={`textRight ${styles.uploadFileProgressInfo}`}>
              {props.percentCompleted} %
            </Col>
          </>
        ) : null}
        <Col xl={12}>
          {props.isFileError ? <UploadErrorMessages {...props} errorMessage={errorMessage} /> : null}
          <p>{props.uploadCounter}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default FirstStep;
