import React from "react";
import { toast } from "react-toastify";

import Button from "../../../components/UI/Button/Button";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";

const clickHandler = (e) => {
  toast.success("Clicked!");
};

const ButtonsSandbox = () => {
  return (
    <>
      <SectionTitle title="<Button>" />

      <div className="flex">
        <Button id="primary" variant="primary" onClick={clickHandler}>
          primary
        </Button>
        <Button id="primaryDisabled" variant="primary" onClick={clickHandler} disabled>
          primary disabled
        </Button>
      </div>
      <div className="flex">
        <Button id="danger" variant="danger" onClick={clickHandler}>
          danger
        </Button>
        <Button id="dangerDisabled" variant="danger" onClick={clickHandler} disabled>
          danger disabled
        </Button>
      </div>
      <div className="flex">
        <Button id="success" variant="success" onClick={clickHandler}>
          success
        </Button>
        <Button id="successDisabled" variant="success" onClick={clickHandler} disabled>
          success disabled
        </Button>
      </div>
      <div className="flex">
        <Button id="secondary" variant="secondary" onClick={clickHandler}>
          secondary
        </Button>
        <Button id="secondaryDisabled" variant="secondary" onClick={clickHandler} disabled>
          secondary disabled
        </Button>
      </div>
      <div className="flex">
        <Button id="editButton" variant="editButton" onClick={clickHandler}>
          editButton
        </Button>
        <Button id="editButtonDisabled" variant="editButton" onClick={clickHandler} disabled>
          editButton disabled
        </Button>
      </div>
      <div style={{ display: "flex", marginTop: "15px" }}>
        <div style={{ marginRight: "20px" }}>
          <p className="m0">rounded-l</p>
          <Button id="roundedSandbox" variant="rounded-l" onClick={clickHandler}></Button>
        </div>
        <div className="mR10">
          <p className="m0">rounded-l disabled</p>
          <Button variant="rounded-l" onClick={clickHandler} disabled></Button>
        </div>
        <div className="mR10">
          <p className="m0">rounded-s</p>
          <Button id="roundedS" variant="rounded-s" onClick={clickHandler}></Button>
        </div>
        <div>
          <p className="m0">rounded-s disabled</p>
          <Button id="roundedDisabled" variant="rounded-s" onClick={clickHandler} disabled></Button>
        </div>
      </div>
      <div className="flex">
        <div style={{ marginRight: "20px" }}>
          <p>Rounded button</p>
          <Button id="roundedBtn" variant="roundedButton" onClick={clickHandler}></Button>
        </div>
        <div>
          <p>Rounded button disabled</p>
          <Button id="roundedDisabledVar" variant="roundedButton" onClick={clickHandler} disabled></Button>
        </div>
      </div>
      <div className="flex">
        <Button id="plan" variant="plain" onClick={clickHandler}>
          plain
        </Button>
        <Button id="planDisabled" variant="plain" onClick={clickHandler} disabled>
          plain disabled
        </Button>
      </div>
      <div className="flex">
        <Button id="roundedDark" variant="dark" onClick={clickHandler}>
          dark
        </Button>
        <Button id="roundedDisabledDark" variant="dark" onClick={clickHandler} disabled>
          dark disabled
        </Button>
      </div>
      <div className="flex">
        <Button id="roundedDisabledWhite" variant="white_bordered" onClick={clickHandler}>
          white_bordered
        </Button>
        <Button id="roundedDisabledWhite" variant="white_bordered" onClick={clickHandler} disabled>
          white_bordered disabled
        </Button>
      </div>
    </>
  );
};

export default ButtonsSandbox;
