import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllInAppNotificationsModel } from "../../models/notifications/AllInAppNotificationsModel";
import { notificationApi } from "../../api/notificationApi";

// Initial state
const initialState: AllInAppNotificationsModel = {
  notifications: [],
  olderNotifications: [],

  unreadNotificationsCount: 0,
  unreadOlderNotificationsCount: 0,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotifications: (state, action: PayloadAction<AllInAppNotificationsModel>) => {
      state.notifications = [...action.payload.notifications, ...state.notifications];
      state.unreadNotificationsCount = action.payload.unreadNotificationsCount;
      state.unreadOlderNotificationsCount = action.payload.unreadOlderNotificationsCount;
    },
    markNotificationAsRead: (state, action: PayloadAction<number>) => {
      const notification = state.notifications.find((notification) => notification.id === action.payload);
      if (notification) {
        notification.isInAppNotificationRead = true;
        state.unreadNotificationsCount = state.notifications.filter((notification) => !notification.isInAppNotificationRead).length;
      }

      const olderNotification = state.olderNotifications.find((notification) => notification.id === action.payload);
      if (olderNotification) {
        olderNotification.isInAppNotificationRead = true;
        state.unreadOlderNotificationsCount = state.olderNotifications.filter((notification) => !notification.isInAppNotificationRead).length;
      }
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
      state.unreadNotificationsCount = state.notifications.filter((notification) => !notification.isInAppNotificationRead).length;

      state.olderNotifications = state.olderNotifications.filter((notification) => notification.id !== action.payload);
      state.unreadOlderNotificationsCount = state.olderNotifications.filter((notification) => !notification.isInAppNotificationRead).length;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(notificationApi.endpoints.getNotifications.matchFulfilled, (state, action) => {
      state.notifications = action.payload.notifications;
      state.olderNotifications = action.payload.olderNotifications;
      state.unreadNotificationsCount = action.payload.unreadNotificationsCount;
      state.unreadOlderNotificationsCount = action.payload.unreadOlderNotificationsCount;
    });
  },
});

export const { addNotifications, markNotificationAsRead, removeNotification } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;

export default notificationsSlice;
