export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCEEDED = "FETCH_USERS_SUCCEEDED";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const ADD_USER_SUCCEEDED = "ADD_USER_SUCCEEDED";

export const REMOVE_USER_REQUEST = "REMOVE_USER_REQUEST";
export const REMOVE_USER_SUCCEEDED = "REMOVE_USER_SUCCEEDED";
export const REMOVE_USER_FAILED = "REMOVE_USER_FAILED";

export const CHANGE_ROLE_REQUEST = "CHANGE_ROLE_REQUEST";
export const CHANGE_ROLE_SUCCEEDED = "CHANGE_ROLE_SUCCEEDED";
export const CHANGE_ROLE_FAILED = "CHANGE_ROLE_FAILED";

export const FETCH_LIST_USERS_REQUEST = "FETCH_LIST_USERS_REQUEST";
export const FETCH_LIST_USERS_SUCCEEDED = "FETCH_LIST_USERS_SUCCEEDED";
export const FETCH_LIST_USERS_FAILED = "FETCH_LIST_USERS_FAILED";
