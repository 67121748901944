import React, { useState } from "react";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import SectionTitle from "../../components/UI/SectionTitle/SectionTitle";
import { Tabs, Tab } from "react-bootstrap";
import styles from "./Sandbox.module.scss";
import { ToastContainer } from "react-toastify";

import ButtonsSandbox from "./components/ButtonsSandbox";
import FormsSandbox from "./components/FormsSandbox";
import FormikTest from "./components/FormikTest";
import FormikSandbox from "./components/FormikSandbox";
import RandomSandbox from "./components/RandomSandbox";
import UserPhotoSandbox from "./components/UserPhotoSandbox";
import ProgressBarSandbox from "./components/ProgressBarSandbox";
import ChartSandbox from "./components/ChartSandbox";
import SectionInbox from "../../assets/Icons/SectionInbox/SectionInbox";
import IconsSandbox from "./components/IconsSandbox";
import SkeletonSandbox from "./components/SkeletonComponents";
import EmptyStateImages from "./components/EmptyStateImages";
import StepperComponentSandbox from "./components/StepperComponentSandbox";
import StatusCard from "@ui/StatusCard/StatusCard";
import useModal from "@ui/Modal/useModal";

const Sandbox = () => {
  const [key, setKey] = useState(1);
  const readMoreModal = useModal();
  return (
    <div className={`${styles.wrap} sandboxComponentWrapper`}>
      <h1 className="textCenter">Welcome to the Sandbox</h1>
      <Tabs className="custom_tabs sandbox_custom_tabs" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="1" title="Typography">
          <HeaderTitle title="Titles" />
          <HeaderTitle title="<HeaderTitle>" />
          <hr />
          <HeaderTitle title="Section Title" />
          <SectionTitle title="<SectionTitle>" icon={<SectionInbox />} />
          <hr />
          <HeaderTitle title="Profile/Company Images" />
          <UserPhotoSandbox />
        </Tab>
        <Tab eventKey="2" title="Buttons">
          <ButtonsSandbox />
        </Tab>
        <Tab eventKey="3" title="Charts">
          <HeaderTitle title="Charts" />
          <ChartSandbox />
          <HeaderTitle title="Progress Bar" />
          <ProgressBarSandbox />
        </Tab>
        <Tab eventKey="4" title="Forms">
          <HeaderTitle title="Forms" />
          IGOR
          <FormikTest />
          <FormsSandbox />
          <HeaderTitle title="Random stuff" />
          <HeaderTitle title="Formik" />
          <FormikSandbox />
          <RandomSandbox />
        </Tab>
        <Tab eventKey="5" title="Stepper component">
          <StepperComponentSandbox />
        </Tab>
        <Tab eventKey="6" title="Icons">
          <IconsSandbox />
        </Tab>
        <Tab eventKey="7" title="Skeletons">
          <SkeletonSandbox />
        </Tab>
        <Tab eventKey="8" title="Empty state images">
          <EmptyStateImages />
        </Tab>
      </Tabs>
      <ToastContainer position={"bottom-right"} />
    </div>
  );
};

export default Sandbox;
