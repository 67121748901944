import { organizationTypeOptions } from "../../../helper/enums/organizationTypeEnum";
import { organizationPlanOptions } from "../../../helper/enums/pricingPlanStatusEnum";
export const columns = [
  {
    label: "Id",
    field: "id",
    type: "number",
    searchable: true,
    searchType: "number",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Organization",
    field: "name",
    type: "text",
    searchable: true,
    searchType: "text",
    minWidth: 150,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Type",
    field: "type",
    type: "text",
    sort: "asc",
    searchable: true,
    searchType: "select",
    option: organizationTypeOptions,
    placeholder: "Type",
    flex: 1,
  },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    searchable: true,
    searchType: "date",
    flex: 1,
  },
  {
    label: "Domain",
    field: "domain",
    type: "text",
    searchable: true,
    searchType: "text",
    flex: 1,
    sort: "asc",
    showTooltip: true,
  },
  {
    label: "Total Logins",
    field: "totalLogin",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Last login",
    field: "lastLogin",
    type: "date",
    sort: "asc",
    searchable: true,
    searchType: "date",
    flex: 1,
  },
  {
    label: "Plan",
    field: "plan",
    type: "text",
    sort: "asc",
    searchable: true,
    option: organizationPlanOptions,
    searchType: "select",
    placeholder: "Plan",
    flex: 1,
  },
  {
    label: "Renewal date",
    field: "renewalDate",
    type: "date",
    searchable: true,
    searchType: "date",
    futureDate: true,
    flex: 1,
    sort: "asc",
    // isHidden: true
  },
  {
    label: "Credit balance",
    field: "creditBalance",
    type: "text",
    flex: 1,
    sort: "disabled",
  },
  {
    label: "No. of users",
    field: "numberOfUsers",
    type: "text",
    sort: "disabled",
    flex: 1,
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    minimal: "sm",
    sort: "disabled",
    flex: 1,
  },
];
