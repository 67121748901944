import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";

//Redux
import { fetchGlobalSettings } from "@store/global/actions/index";
import { fetchSettings } from "@store/OrganizationSettings/General/actions/general";

//Style
import styles from "./UserSettings.module.scss";

//Components
import Account from "./components/Account/Account";
import Tabs from "@components/UI/Tabs/Tabs";
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import Spinner from "@components/UI/Spinner/Spinner";
import Password from "./components/Password/Password";
import PageContentWrapper from "@components/PageContentWrapper/PageContentWrapper";

const UserSettings = (props) => {
  useEffect(() => {
    props.fetchSettings();
    props.fetchGlobalSettings();
  }, []);

  const [updateAccount, setUpdateAccount] = useState(null);
  const [updateAccountImage, setUpdateAccountImage] = useState(false);

  const [updatePassword, setUpdatePassword] = useState(null);

  const [updateNotifications, setUpdateNotifications] = useState(null);

  const tabs = [
    {
      link: "account",
      component: <Account setUpdateAccount={setUpdateAccount} setUpdateAccountImage={setUpdateAccountImage} />,
      title: "General Info",
    },
    {
      link: "password",
      component: <Password setUpdatePassword={setUpdatePassword} />,
      title: "Password",
    },
  ];

  let config = null;

  if (props.isLoading) {
    config = <Spinner spinnerClass="absolute transform-y-50" />;
  }

  if (props.error) {
    config = <p>Error loading settings.</p>;
  }

  if (props.isLoaded) {
    config = <Tabs tabContentClass={styles.myAccountTabs} {...props} tabs={tabs} />;
  }

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title="My Account" />
      {config}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationName: state.user.organization.name,
    isLoading: state.organizationSettingsGeneral.isLoading,
    isLoaded: state.organizationSettingsGeneral.isLoaded,
    error: state.organizationSettingsGeneral.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
