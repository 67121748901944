import React from 'react';

//Styles
import styles from './AISuggestion.module.scss';

//helpers
import { responseTypeStringEnum } from '../../../helper/enums/responseTypeEnum';

//Images & Icons
import IconArtificialIntelligence from '../../../assets/Icons/IconArtificialIntelligence/IconArtificialIntelligence';

//components
import ResponseTypePill from '../MessageList/ResponseType/ResponseTypePill';

const AISuggestion = (props) => {
    const {messagePreview} = props;
    return(
        messagePreview?.autoresolveResponseType ? <div className={styles.suggestionWrapper}>
            <IconArtificialIntelligence imgStyle="mR5" />
            <p>AI Suggestion</p>
            <ResponseTypePill type={responseTypeStringEnum[messagePreview.autoresolveResponseType]} />
        </div> : null
    )
};

export default AISuggestion;