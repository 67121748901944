import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import _ from "lodash";

//Redux
import { useParams, Prompt } from "react-router-dom";
import {
  createWorkflowSteps,
  fetchWorkflowStepsList,
  createSequence,
  setActivePage,
  setActiveStep,
  checkSequenceForId,
  removeStep,
  checkSequenceForErrors,
  clearInitiated,
  deleteWorkflowSteps,
} from "../../../../store/WorkflowSteps/actions/workflowSteps";
import { fetchEmailTemplate } from "../../../../store/EmailTemplates/Global/actions/emailTemplates";

//Helpers
import { actionTypeEnum } from "../../../../helper/enums/actionType";
import { actionPageTypeEnum } from "../../../../helper/enums/actionPageTypes";
import { emailTemplateTypeEnum } from "../../../../helper/enums/emailTemplateTypeEnum";
import { workflowStepConfigurationErrorEnum } from "../../../../helper/enums/workflowStep/workflowStepConfigurationErrorEnum";
import { emailTemplateStatusEnum } from "../../../../helper/enums/emailTemplateStatusEnum";
import { campaignStatusEnum } from "../../../../helper/enums/campaignStatusEnum";

//Styles
import styles from "./CampaignSequence.module.scss";

//Components
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Wait from "./Wait/Wait";
import EditEmailTemplatesTabs from "../../../../pages/EmailTemplates/EditEmailTemplates/EditEmailTemplatesTabs";
import CancelWarningForm from "../../../../components/CancelWarningForm/CancelWarningForm";
import SaveChangesFooter from "../../../../components/SaveChangesFooter/SaveChangesFooter";
import DeleteStepTemplateForm from "./DeleteStepForm/DeleteStepForm";
import SaveSequenceWarningForm from "./SaveSequenceWarningForm/SaveSequenceWarningForm";
import TemplatePage from "./TemplatePage/TemplatePage";
import TopBannerCampaignSettings from "../../TopBannerCampaignSettings";
import SequenceSteps from "./SequenceSteps/SequenceSteps";
import DraftEmailTemplateWarningModal from "./TemplatePage/DraftEmailTemplateWarningModal/DraftEmailTemplateWarningModal";
import ErrorVariablesModal from "../../../../pages/EmailTemplates/ErrorVariablesModal/ErrorVariablesModal";
import SequenceHasDraftNotification from "./SequenceHasDraftNotification/SequenceHasDraftNotification";
import PageContentWrapper from "../../../../components/PageContentWrapper/PageContentWrapper";
import { useSlice } from "@hooks/useSlice";

const CampaignSequence = (props) => {
  const { fetchWorkflowStepsList, listWorkflowSteps, clearInitiated, fetchEmailTemplate } = props;
  const [typeActiveStep, setTypeActiveStep] = useState(emailTemplateTypeEnum.InitialEmail);
  const [firstStep, setFirstStep] = useState(listWorkflowSteps.length === 1);
  const [stepId, setStepId] = useState(0);
  const [hideSequenceFlow, setHideSequenceFlow] = useState(false);

  const [showDeleteStepModal, setShowDeleteStepModal] = useState(false);
  const handleDeleteStepClose = () => setShowDeleteStepModal(false);

  const [templateId, setTemplateId] = useState(null);
  const [waitDaysValue, setWaitDaysValue] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [showErrorVariablesModal, setShowErrorVariablesModal] = useState(false);
  const [openTemplateCard, setOpenTemplateCard] = useState(true);
  const { campaignId } = useParams();
  const campaign = useSlice((state) => state.campaign.campaign);

  const handleCloseErrorVariablesModal = () => {
    setShowErrorVariablesModal(false);
    setOpenTemplateCard(true);
  };

  let currentPage = null;
  let sequence = null;
  const handleDeleteStepShow = (stepId) => {
    setStepId(stepId);
    setShowDeleteStepModal(true);
    fetchEmailTemplate(templateId);
  };

  const [configurationSaveErrorType, setConfigurationSaveErrorType] = useState(null);
  const [showSaveSequenceWarningModal, setShowSaveSequenceWarningModal] = useState(false);
  const handleSaveSequenceWarningClose = () => {
    setShowSaveSequenceWarningModal(false);
    setConfigurationSaveErrorType(null);
  };

  const [showCancelSequenceWarningModal, setShowCancelSequenceWarningModal] = useState(false);
  const handleCancelSequenceWarningClose = () => {
    setShowCancelSequenceWarningModal(false);
  };
  const [pathname, setPathname] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [showDraftEmailTemplateWarningModel, setShowDraftEmailTemplateWarningModel] = useState(false);
  useEffect(() => {
    setSaveButtonDisabled(false);
  }, []);

  useEffect(() => {
    return async () => {
      await clearInitiated();
      await props.setActivePage(actionPageTypeEnum.startPage);
    };
  }, []);

  useEffect(() => {
    if (templateId !== null) {
      fetchEmailTemplate(templateId);
    }
  }, [templateId, fetchEmailTemplate]);

  useEffect(() => {
    let activeStepIndex = listWorkflowSteps.findIndex((s) => s.active);
    let draftStepIndex = listWorkflowSteps.findIndex((s) => s.status === emailTemplateStatusEnum.Draft);

    if (draftStepIndex !== -1 && activeStepIndex === -1) {
      // If there is a step with draft status make it active step
      let draftStep = listWorkflowSteps[draftStepIndex];
      props.setActiveStep(draftStep.id);
      setTypeActiveStep(draftStep.type);
      setTemplateId(draftStep.templateId);
      props.setActivePage(actionPageTypeEnum.templatePage);
    } else if (activeStepIndex === -1 && listWorkflowSteps.length > 1) {
      // Make first available step active step
      let firstStep = listWorkflowSteps[0];
      props.setActiveStep(firstStep.id);
      setTypeActiveStep(firstStep.type);
      setTemplateId(firstStep.templateId);
      props.setActivePage(actionPageTypeEnum.templatePage);
    } else if (activeStepIndex !== -1 && listWorkflowSteps.length > 0) {
      let activeStep = listWorkflowSteps[activeStepIndex];
      setTemplateId(activeStep.templateId);
    }
  }, [listWorkflowSteps]);

  useEffect(() => {
    if (props.isLoadedCreateWorkflowSteps) {
      if (props.errorCreateWorkflowSteps) {
        var error = props.errorCreateWorkflowSteps;
        if (error) {
          setConfigurationSaveErrorType(error);
        }
      } else {
        fetchWorkflowStepsList(campaignId);
        props.setActivePage(actionPageTypeEnum.startPage);
        props.history.push("/" + props.orgId + "/campaigns/" + campaignId + "/overview");
      }
    }
  }, [props.isLoadedCreateWorkflowSteps]);

  useEffect(() => {
    if (configurationSaveErrorType) {
      props.checkSequenceForErrors();
      setShowSaveSequenceWarningModal(true);
      setSaveButtonDisabled(false);
    }
  }, [configurationSaveErrorType]);

  const updateChanges = async () => {
    setHasChanges(false);
  };

  const handleCancelSequenceWarningSave = async () => {
    await updateChanges();
    setShowCancelSequenceWarningModal(false);
    props.history.push({
      pathname: pathname,
      state: true,
    });
    fetchEmailTemplate(templateId);
  };

  const handleCancelModal = (location) => {
    setPathname(location.pathname);
    setShowCancelSequenceWarningModal(true);
    return false;
  };

  const handleStepButtonClick = (id, type, templateId, waitDays) => {
    setHasChanges(true);
    let newPage = null;

    if (type === undefined) {
      let index = listWorkflowSteps.findIndex((seq) => seq.id === id);
      if (index === 0) {
        newPage = actionPageTypeEnum.selection;
      } else {
        type = listWorkflowSteps[index - 1].type;
        if (type === actionTypeEnum.Email) {
          newPage = actionPageTypeEnum.wait;
        } else if (type === actionTypeEnum.Wait) {
          newPage = actionPageTypeEnum.templatePage;
        }
      }
    } else {
      if (type === actionTypeEnum.Email) {
        newPage = actionPageTypeEnum.templatePage;
      } else if (type === actionTypeEnum.Wait) {
        newPage = actionPageTypeEnum.wait;
      }
    }

    props.setActivePage(newPage);
    props.setActiveStep(id);
    setTemplateId(templateId);
    if (newPage === actionTypeEnum.wait && !waitDays) waitDays = 6;
    setWaitDaysValue(waitDays);
  };

  const removeStepHandler = (stepId) => {
    setHasChanges(true);
    if (listWorkflowSteps.length === 1) {
      setFirstStep(true);
      setActiveStep(-1);
      setTemplateId(null);
    }
    props.removeStep(stepId);
    props.setActivePage(actionPageTypeEnum.startPage);
    setShowDeleteStepModal(false);
  };

  const addStepBetwenSteps = (type, id) => {
    setHasChanges(true);
    props.checkSequenceForId(type, id);
  };

  const addEmailTemplate = () => {
    props.setActivePage(actionPageTypeEnum.addTemplate);
    setHideSequenceFlow(true);
  };
  const editEmailTemplate = () => {
    setHasChanges(false);
    fetchEmailTemplate(templateId);
    props.setActivePage(actionPageTypeEnum.editTemplate);
    setHideSequenceFlow(false);
  };
  const showTemplates = (lastStep, id) => {
    props.setActivePage(actionPageTypeEnum.templatePage);
    if (lastStep) {
      addStep(actionTypeEnum.Email);
    } else {
      addStepBetwenSteps(actionTypeEnum.Email, id);
    }
  };

  const showWait = (lastStep, id) => {
    props.setActivePage(actionPageTypeEnum.wait);
    if (lastStep) {
      addStep(actionTypeEnum.Wait);
    } else {
      addStepBetwenSteps(actionTypeEnum.Wait, id);
    }
    setWaitDaysValue(6);
  };

  const addStep = (actionType) => {
    setHasChanges(true);
    if (firstStep) {
      setFirstStep(false);
    }
    props.checkSequenceForId(actionType);
  };

  const selectAddedEmail = (templateId, stepId, templateName, templateType, templateStatus, templateSubject, templateHtml) => {
    if (props.campaignStatus === campaignStatusEnum.active && templateStatus === emailTemplateStatusEnum.Draft) {
      setShowDraftEmailTemplateWarningModel(true);
      return;
    }
    props.setActivePage(actionPageTypeEnum.templatePage);
    handleEmail(templateId, stepId, templateName, templateType, templateStatus, templateSubject, templateHtml);
  };

  const handleEmail = (templateId, stepId, templateName, templateType, templateStatus, templateSubject, templateHtml) => {
    if (firstStep) {
      setFirstStep(false);
    }

    const emailStep = {
      templateId: templateId,
      templateName: templateName,
      campaignId: campaignId,
      templateType: templateType,
      templateStatus: templateStatus,
      templateSubject: templateSubject,
      templateHtml: templateHtml,
    };

    props.createSequence(actionTypeEnum.Email, stepId, emailStep);
  };

  const handleWait = (obj) => {
    let stepId = obj.id;
    obj.campaignId = campaignId;
    props.createSequence(actionTypeEnum.Wait, stepId, obj);
  };

  const [statusDraft, setStatusDraft] = useState(false);

  const handleSave = async () => {
    setSaveButtonDisabled(true);
    let send = true;
    let newSequence = [...listWorkflowSteps];
    let lastEntry = listWorkflowSteps[listWorkflowSteps.length - 1].type === undefined;
    let firstStepInSequence = { ...newSequence[0] };
    let lastStepInSequence = { ...newSequence[newSequence.length - 1] };
    let emptyStep = listWorkflowSteps.filter((s) => s.type === actionTypeEnum.Email && s.templateId === undefined);
    let initStepsInSequenceCount = newSequence.filter((s) => s.templateType === emailTemplateTypeEnum.InitialEmail).length;
    var sequentialStepsAreTheSameError = sequentialStepsAreTheSame(newSequence);
    let arrayTemplates = newSequence.filter((s) => s.type === actionTypeEnum.Email);
    let idTemplates = Array.from(arrayTemplates, (x) => x.templateId);
    let uniqTemplateIdArray = _.uniq(idTemplates);
    fetchEmailTemplate(templateId);
    //if we have a object with Id and no other data
    if (lastEntry) {
      let index = newSequence.length - 1;
      newSequence.splice(index, 1);
      send = false;
      if (newSequence.length === 0) {
        await props.deleteWorkflowSteps(campaignId);
        await props.setActivePage(actionPageTypeEnum.startPage);
        props.history.push("/" + props.orgId + "/campaigns/" + campaignId + "/overview");
      } else {
        await props.checkSequenceForErrors();
      }
    }
    ////CHECK IF THE FIRST ELEMENT IS A WAIT ACTION
    else if (firstStepInSequence.type === actionTypeEnum.Wait) {
      let indexActive = newSequence.findIndex((seq) => seq.active === true);
      let newObj = { ...newSequence[indexActive] };
      newObj.active = false;
      newSequence[indexActive] = newObj;

      send = false;
      firstStepInSequence.activeError = true;
      newSequence[0] = firstStepInSequence;
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.FirstStepWaitActionError);
    }
    ////CHECK IF THE LAST ELEMENT IS A WAIT ACTION
    else if (lastStepInSequence.type === actionTypeEnum.Wait) {
      let indexActive = newSequence.findIndex((seq) => seq.active === true);
      let newObj = { ...newSequence[indexActive] };
      newObj.active = false;
      newSequence[indexActive] = newObj;

      send = false;
      lastStepInSequence.activeError = true;
      newSequence[newSequence.length - 1] = lastStepInSequence;
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.LastStepWaitActionError);
    }
    ///////////CHECK IF TWO STEPS NEXT TO EACH OTHER ARE THE SAME
    else if (sequentialStepsAreTheSameError && sequentialStepsAreTheSameError.hasError) {
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.SequentialStepsAreTheSameTypeError);
      newSequence = sequentialStepsAreTheSameError.newSequence;
      send = false;
    }
    //////CHECK FOR A EMAIL STEP WITHOUT SELECTED TEMPLATE ID
    else if (emptyStep.length !== 0) {
      send = false;
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.StepWithoutEmailTemplateError);
    }
    ////CHECK IF THERE ARE TWO INIT STEPS IN THE SEQUENCE
    else if (initStepsInSequenceCount > 1) {
      send = false;
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.TwoInitialEmailTemplateStepsError);
    }
    ////CHECK IF THERE ARE TWO EMAIL STEPS WITH THE SAME TEMPLATE IN THE SEQUENCE
    else if (idTemplates.length !== uniqTemplateIdArray.length) {
      send = false;
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.TwoStepsWithTheSameEmailTemplateError);
    }
    //CHECK IF THE FIRST STEP HAS A FOLLOW UP EMAIL TEMPLATE
    if (firstStepInSequence && firstStepInSequence.templateType === emailTemplateTypeEnum.FollowUp) {
      send = false;
      setConfigurationSaveErrorType(workflowStepConfigurationErrorEnum.FirstStepFollowUpTemplateError);
    }

    if (send) {
      const newListWorkflowSteps = listWorkflowSteps.map((step, index) => ({ ...step, Order: index }));
      await setHasChanges(false);
      await props.createWorkflowSteps(newListWorkflowSteps);
    }
  };

  const handleCancel = async () => {
    setSaveButtonDisabled(false);
    await props.setActivePage(actionPageTypeEnum.startPage);
    props.history.push("/" + props.orgId + "/campaigns/" + campaignId + "/overview");
    fetchEmailTemplate(templateId);
  };

  switch (props.currentPage) {
    case actionPageTypeEnum.startPage:
      currentPage =
        listWorkflowSteps.length > 1 ? (
          <TemplatePage
            {...props}
            addEmailTemplate={addEmailTemplate}
            editEmailTemplate={editEmailTemplate}
            addEmail={handleEmail}
            steps={listWorkflowSteps}
            templateId={templateId}
            changed={handleStepButtonClick}
            setTypeActiveStep={setTypeActiveStep}
            typeActiveStep={typeActiveStep}
            setShowErrorVariablesModal={setShowErrorVariablesModal}
            setTemplateId={setTemplateId}
            setStatusDraft={setStatusDraft}
            selectEmails={selectAddedEmail}
            open={openTemplateCard}
            setOpen={setOpenTemplateCard}
          />
        ) : null;
      break;
    case actionPageTypeEnum.templatePage:
      currentPage = (
        <TemplatePage
          {...props}
          addEmailTemplate={addEmailTemplate}
          editEmailTemplate={editEmailTemplate}
          addEmail={handleEmail}
          steps={listWorkflowSteps}
          templateId={templateId}
          changed={handleStepButtonClick}
          setTypeActiveStep={setTypeActiveStep}
          typeActiveStep={typeActiveStep}
          setTemplateId={setTemplateId}
          setShowErrorVariablesModal={setShowErrorVariablesModal}
          setStatusDraft={setStatusDraft}
          selectEmails={selectAddedEmail}
          open={openTemplateCard}
          setOpen={setOpenTemplateCard}
        />
      );
      break;
    case actionPageTypeEnum.wait:
      currentPage = <Wait waitDays={waitDaysValue} addWait={handleWait} />;
      break;
    case actionPageTypeEnum.addTemplate:
      currentPage = (
        <div className={`${styles.emailAwesomeWrapper} sequenceEmailTemplates`}>
          <EditEmailTemplatesTabs
            {...props}
            fromCampaignSequence
            templateId={templateId}
            selectEmails={selectAddedEmail}
            typeActiveStep={typeActiveStep}
            handleHideSequenceFlow={(value) => setHideSequenceFlow(value)}
            typeActivePage={actionPageTypeEnum.addTemplate}
          />
        </div>
      );
      break;
    case actionPageTypeEnum.editTemplate:
      currentPage = (
        <div className={`${styles.emailAwesomeWrapper} sequenceEmailTemplates`}>
          <EditEmailTemplatesTabs
            {...props}
            fromCampaignSequence
            templateId={templateId}
            selectEmails={selectAddedEmail}
            typeActiveStep={typeActiveStep}
            handleHideSequenceFlow={() => setHideSequenceFlow(false)}
            typeActivePage={actionPageTypeEnum.editTemplate}
          />
        </div>
      );
      break;
    default:
      currentPage = null;
      break;
  }

  const sequentialStepsAreTheSame = (newSequence) => {
    var result = null;
    if (newSequence) {
      if (newSequence.length >= 2) {
        for (let index = 0; index < newSequence.length; index++) {
          let stepOne = { ...newSequence[index] };
          let stepTwo = { ...newSequence[index + 1] };

          if (stepOne.type === stepTwo.type) {
            let indexActive = newSequence.findIndex((seq) => seq.active === true);
            let newObj = { ...newSequence[indexActive] };
            newObj.active = false;
            newSequence[indexActive] = newObj;

            stepOne.activeError = true;
            stepTwo.activeError = true;

            newSequence[index] = stepOne;
            newSequence[index + 1] = stepTwo;
            result = {
              hasError: true,
              newSequence: newSequence,
            };
          }
        }
      }
    }

    return result;
  };

  const changeCampaign = (val) => {
    props.history.push("/" + props.orgId + "/campaigns/" + val + "/campaign-sequence");
  };

  if (props.isLoading) {
    sequence = <Spinner spinnerClass="absolute transform-y-50" />;
  }

  if (props.isLoaded) {
    sequence = (
      <SequenceSteps
        handleHideSequenceFlow={(value) => setHideSequenceFlow(value)}
        hideSequenceFlow={hideSequenceFlow}
        showTemplates={showTemplates}
        showWait={showWait}
        steps={listWorkflowSteps}
        deleteStep={handleDeleteStepShow}
        stepClicked={handleStepButtonClick}
        showIcon={props.currentPage !== actionPageTypeEnum.startPage || listWorkflowSteps.length > 2}
        timelineWrapperClass={props.currentPage === actionPageTypeEnum.addTemplate ? styles.editMode : ""}
        setStatusDraft={setStatusDraft}
        // addFirstStep={addFirstStep}
      />
    );
  }

  const breadcrumbs = [
    {
      href: "/" + props.orgId + "/campaigns",
      text: "Campaigns",
    },
    {
      href: "/" + props.orgId + "/campaigns/" + campaignId + "/overview",
      text: campaign.name,
    },
    { active: true, text: "Configure" },
  ];

  return (
    <PageContentWrapper>
      <Prompt when={hasChanges} message={(location) => handleCancelModal(location)} />
      <TopBannerCampaignSettings
        title={"Campaign Sequence & Messaging"}
        rightContent={statusDraft ? <SequenceHasDraftNotification /> : null}
        breadcrumbs={breadcrumbs}
        remove={true}
        changeCampaign={changeCampaign}
        {...props}
      />
      {props.isLoaded ? (
        <Container className={`${styles.campSequenceWrapper} flex p0`} fluid>
          <div className={styles.pageWrapper}>
            {sequence}
            {currentPage}
          </div>
          {props.currentPage === actionPageTypeEnum.addTemplate || props.currentPage === actionPageTypeEnum.editTemplate ? null : (
            <SaveChangesFooter
              cancelClick={handleCancel}
              saveChangesClick={handleSave}
              cancelButtonText="Back"
              saveButtonText="Save"
              saveButtonDisabled={saveButtonDisabled}
            />
          )}
          <DeleteStepTemplateForm
            handleClose={handleDeleteStepClose}
            showModal={showDeleteStepModal}
            stepId={stepId}
            handleClick={() => removeStepHandler(stepId)}
          />
          <SaveSequenceWarningForm
            handleClose={handleSaveSequenceWarningClose}
            showModal={showSaveSequenceWarningModal}
            errorType={configurationSaveErrorType}
          />
          <CancelWarningForm
            handleClose={handleCancelSequenceWarningClose}
            showModal={showCancelSequenceWarningModal}
            handleSave={handleCancelSequenceWarningSave}
            handleClick={() => removeStepHandler(stepId)}
          />
          <DraftEmailTemplateWarningModal
            showModal={showDraftEmailTemplateWarningModel}
            handleSave={() => setShowDraftEmailTemplateWarningModel(false)}
          />
          <ErrorVariablesModal
            handleClose={handleCloseErrorVariablesModal}
            handleSubmit={handleCloseErrorVariablesModal}
            showModal={showErrorVariablesModal}
          />
        </Container>
      ) : null}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.emailTemplates.params,
    orgId: state.user.organization.id,
    listWorkflowSteps: state.workflowSteps.listWorkflowSteps,
    isLoading: state.workflowSteps.isLoading,
    isLoaded: state.workflowSteps.isLoaded,
    activeStepId: state.workflowSteps.activeStepId,
    currentPage: state.workflowSteps.currentPage,
    rows: state.emailTemplates.rows,
    isLoadingCreateWorkflowSteps: state.workflowSteps.isLoadingCreateWorkflowSteps,
    isLoadedCreateWorkflowSteps: state.workflowSteps.isLoadedCreateWorkflowSteps,
    errorCreateWorkflowSteps: state.workflowSteps.errorCreateWorkflowSteps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailTemplate: (data) => dispatch(fetchEmailTemplate(data)),
    fetchWorkflowStepsList: (campaignId) => dispatch(fetchWorkflowStepsList(campaignId)),
    checkSequenceForId: (actionType, index) => dispatch(checkSequenceForId(actionType, index)),
    createWorkflowSteps: (data) => dispatch(createWorkflowSteps(data)),
    createSequence: (type, stepId, obj) => dispatch(createSequence(type, stepId, obj)),
    setActivePage: (page) => dispatch(setActivePage(page)),
    setActiveStep: (stepId) => dispatch(setActiveStep(stepId)),
    removeStep: (stepId) => dispatch(removeStep(stepId)),
    checkSequenceForErrors: () => dispatch(checkSequenceForErrors()),
    clearInitiated: () => dispatch(clearInitiated()),
    deleteWorkflowSteps: (camId) => dispatch(deleteWorkflowSteps(camId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSequence);
