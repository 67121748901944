import React from "react";
import { connect } from "react-redux";

//Redux
import { searchRecords } from "../../store/SalesDocuments/Table/actions/salesDocuments";
import { setSearchValue } from "../../store/SalesDocuments/actions/salesDocuments";

//Components
import HeaderTitle from "./../../components/UI/HeaderTitle/HeaderTitle";
import Videos from "./components/Videos/Videos";
import DocumentsTable from "./components/Documents/DocumentsTable";
import Images from "./components/Images/Images";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import Accordion from "../../components/UI/Accordion/Accordion";

const SalesDocuments = (props) => {
  const handleSearchChange = (text) => {
    props.searchRecords(text);
    props.setSearchValue(text);
  };

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Add useful documents to give your team easy access to all the files they need."}
      href={"https://help.outbase.com/user-guide/sales/sales-resources"}
      linkText="Read More"
      buttonId="startSalesDocumentTour"
      disableStartTourButton
    />
  );

  return (
    <PageContentWrapper>
      <HeaderTitle title="Resources" icon={icon} showSearch={true} searchPlaceholder={"Search..."} textChanged={handleSearchChange} />
      <Accordion
        variant="whiteTransparent"
        header="Documents"
        body={<DocumentsTable />}
        eventKey={true}
        noCaret={true}
        accordionWrapperClass="relative"
      />
      <Accordion variant="whiteTransparent" header="Images" body={<Images />} eventKey="Images" noCaret={true} />
      <Accordion variant="whiteTransparent" header="Videos" body={<Videos />} eventKey="Videos" noCaret={true} />
    </PageContentWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRecords: (search) => dispatch(searchRecords(search)),
    setSearchValue: (search) => dispatch(setSearchValue(search)),
  };
};

export default connect(null, mapDispatchToProps)(SalesDocuments);
