import React, { useState } from "react";

//Images
import EmptyData from "../../../assets/Images/EmptyData/EmptyData";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import EmptyEmailAccount from "../../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";
import EmptyCampaignList from "../../../assets/Images/EmptyCampaignList/EmptyCampaignList";

//Components
import StepperComponent from "../../../components/UI/StepperComponent/StepperComponent";

const StepperComponentSandbox = () => {
  let email = <EmptyEmailAccount />;
  let campaign = <EmptyCampaignList />;
  let emptyData = <EmptyData />;

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      label: "Step 1",
      description: "This will be description for step 1",
    },
    {
      label: "Step 2",
      description: "This will be description for step 2",
    },
    {
      label: "Step 3",
      description: "This will be description for step 3",
    },
  ];

  return (
    <>
      <h1
        style={{
          fontSize: "1.5rem",
          textAlign: "center",
          margin: "20px auto 10px auto",
        }}
      >
        Vertical stepper
      </h1>
      <div className="flex" style={{ background: "white", padding: "20px" }}>
        <StepperComponent steps={steps} orientation="vertical" setActiveStep={setActiveStep} activeStep={activeStep} />
        <div style={{ margin: "auto" }}>
          {activeStep === 0 && (
            <div>
              <EmptyState image={email} title="Step 1 content" description="" />
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <EmptyState image={campaign} title="Step 2 content" description="" />
            </div>
          )}
          {activeStep === 2 && (
            <div>
              <EmptyState image={emptyData} title="Step 3 content" description="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StepperComponentSandbox;
