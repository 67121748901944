import React from "react";
import { FieldArray } from "formik";
import _ from "lodash";

//Styles
import styles from "./TestVariablesStep.module.scss";

//Components
import FormikInput from "../../../../../components/UI/Formik/FormikInput";

const TestVariablesStep = (props) => {
  const { variables } = props;
  return (
    <div className={styles.test}>
      <div>
        <FieldArray
          name="dictionary"
          render={(arrayHelpers) => (
            <>
              {!_.isEmpty(variables)
                ? variables.map((variable, index) => {
                    return (
                      <div key={index} className="mB5">
                        <label>{variable}</label>
                        <FormikInput type="text" name={`dictionary[${variable}]`} value={props.dictionaryValues[variable]} />
                      </div>
                    );
                  })
                : null}
            </>
          )}
        />
      </div>
    </div>
  );
};

export default TestVariablesStep;
