import * as actionTypes from "../actions/actionTypes";
import { dealNavigatorStatusEnum } from "../../../helper/enums/dealNavigatorStatusEnum";

// export type DocumentState = {
//   Id: number,
//   OrganizationId: number,
//   Name: string,
//   Type: number,
//   Extention: string,
//   Size: number,
//   UploadedBy: number,
//   Tags: string,
//   Comment: string,
//   Url: string,
//   ThumbnailUrl: string
// };

// export type DealNavigatorElementState = {
//   id: number,
//   dealNavigatorCardId: number,
//   type: number,
//   content: string,
//   document: any,
// };

// export type DealNavigatorCardState = {
//   id: number,
//   dealNavigatorStageId: number,
//   order: number,
//   position: number,
//   offset: number,
//   elements: Array<DealNavigatorElementState>,
// };

// export type DealNavigatorStageState = {
//   id: number,
//   dealNavigatorId: number,
//   title: string,
//   order: number,
//   cards: Array<DealNavigatorCardState>,
// };

// export type DealNavigatorState = {
//   id: number,
//   organizationId: number,
//   stages: Array<DealNavigatorStageState>,
// };

export const INITIAL_STATE = {
  id: 0,
  organizationId: 0,
  stages: [
    {
      id: 0,
      dealNavigatorId: 0,
      title: "",
      order: 0,
      cards: [
        {
          id: 0,
          stageId: 0,
          order: 0,
          position: 0,
          offset: 0,
          elements: [
            {
              id: 0,
              cardId: 0,
              stageId: 0,
              type: 0,
              content: "",
              header: "",
              document: {},
            },
          ],
        },
      ],
    },
  ],
  status: 0,
  isLoading: false,
  isLoaded: false,
  isInEditMode: false,
  error: "",
  lastAddedCardId: 0,
  isLoadingTemplate: false,
  isLoadedTemplate: false,
  isInTemplateMode: false,
  templates: [],
  isAddingCard: false,
};

// #region ADD
const addDealNavigatorStagesRequested = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const addDealNavigatorCardsRequested = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isAddingCard: true,
  };
};

const addDealNavigatorElementsSuccess = (state, action) => {
  const newState = { ...state };
  const cardNextState = action.result;

  const stageIndex = newState.stages.findIndex((s) => s.id === cardNextState.stageId);
  const stage = { ...newState.stages[stageIndex] };
  const cards = [...stage.cards];

  const cardIndex = cards.findIndex((c) => c.id === cardNextState.id);

  const card = { ...cards[cardIndex] };

  const newElements = cardNextState.elements;
  const oldElements = [...card.elements];

  oldElements.splice(0, Infinity, ...newElements);

  card.elements = oldElements;
  cards[cardIndex] = card;
  stage.cards = cards;
  newState.stages[stageIndex] = stage;

  return newState;
};

// SUCCEEDED
function addDealNavigatorStagesSucceeded(state, action) {
  const { id, dealNavigatorId, organizationId, title, order, cards } = action.result;
  return {
    ...state,
    stages: [...state.stages, { id, dealNavigatorId, organizationId, title, order, cards }],
  };
}

const addDealNavigatorCardsSucceeded = (state, action) => {
  const newState = { ...state };
  const card = action.result;

  const stageIndex = newState.stages.findIndex((s) => s.id === card.stageId);
  const stage = { ...newState.stages[stageIndex] };
  stage.cards = [...stage.cards, card];
  newState.lastAddedCardId = card.id;
  newState.stages[stageIndex] = stage;
  newState.isAddingCard = false;
  return newState;
};

// FAILED
const addDealNavigatorStagesFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};

const addDealNavigatorCardsFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    isAddingCard: false,
    error: action.result,
  };
};

const addDealNavigatorElementsFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};
// #endregion

// #region CHANGE
// SUCCEEDED
const changeDealNavigatorStagesSuccess = (state, action) => {
  const newState = { ...state };
  const stageNextState = action.result;

  const index = newState.stages.findIndex((s) => s.id === stageNextState.id);
  const stage = { ...newState.stages[index] };
  stage.id = stageNextState.id;
  stage.title = stageNextState.name;
  newState.stages[index] = stage;

  return newState;
};

const changeDealNavigatorCardsSucceeded = (state, action) => {
  const newState = { ...state };
  const cardNextState = action.result;

  const index = newState.stages[cardNextState.stageId].findIndex((c) => c.id === cardNextState.id);
  const card = { ...newState.stages[cardNextState.stageId].cards[index] };
  card.id = cardNextState.id;
  card.order = cardNextState.order;
  card.position = cardNextState.position;
  card.offset = cardNextState.offset;
  newState.stages[cardNextState.stageId].cards[index] = card;

  return newState;
};

const changeDealNavigatorElementsSucceeded = (state, action) => {
  const newState = { ...state };
  const elementNextState = action.result;

  const index = newState.stages[elementNextState.stageId].cards[elementNextState.cardId].findIndex((e) => e.id === elementNextState.id);
  const element = {
    ...newState.stages[elementNextState.stageId].cards[elementNextState.cardId].elements[index],
  };
  element.type = elementNextState.type;
  element.content = elementNextState.content;

  newState.stages[elementNextState.stageId].cards[elementNextState.cardId].elements[index] = element;

  return newState;
};

// FAILED
const changeDealNavigatorStagesFailed = (state, action) => {
  const newState = { ...state };
  const stageState = action.result.prevState;

  const index = newState.stages.findIndex((s) => s.id === stageState.id);
  const stage = { ...newState.stages[index] };
  stage.id = stageState.id;
  stage.title = stageState.title;
  newState.stages[index] = stage;

  return newState;
};

const changeDealNavigatorCardsFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};

const changeDealNavigatorElementsFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};
// #endregion

// #region REMOVE
// SUCCEEDED
const removeDealNavigatorStagesSucceeded = (state, action) => {
  const newState = { ...state };
  const stageId = action.result;
  const index = newState.stages.findIndex((s) => s.id === stageId);

  newState.stages.splice(index, 1);

  for (let index = 0; index < newState.stages.length; index++) {
    newState.stages[index].order = index + 1;
  }

  return newState;
};

const removeDealNavigatorCards = (state, action) => {
  const newState = { ...state };
  const cardNextState = action.result;

  const stageIndex = newState.stages.findIndex((s) => s.id === cardNextState.stageId);
  const stage = { ...newState.stages[stageIndex] };
  const cards = [...stage.cards];

  const cardIndex = cards.findIndex((c) => c.id === cardNextState.id);

  cards.splice(cardIndex, 1);

  for (let index = cardIndex; index < cards.length; index++) {
    cards[index].order -= 1;
    cards[index].position = cards[index].position == 1 ? 3 : 1;
  }

  stage.cards = cards;
  newState.stages[stageIndex] = stage;
  return newState;
};

const removeDealNavigatorElementsSucceeded = (state, action) => {
  const newState = { ...state };
  const stageId = action.result.stageId;
  const cardId = action.result.cardId;
  const elementId = action.result.elementId;
  const index = newState.stages[stageId].cards[cardId].elements.findIndex((e) => e.id === elementId);

  if (index > 0) {
    newState.stages[stageId].cards[cardId].elements.splice(index, 1);
    return newState;
  }

  return state;
};
// #endregion

// #region REORDER & SWAP
// SUCCEEDED
const reorderDealNavigatorStage = (state, action) => {
  const newState = { ...state };
  const stageNextState = action.result;

  const index = newState.stages.findIndex((s) => s.id === stageNextState.id);
  const newStage = { ...newState.stages[index] };
  const oldStage = { ...newState.stages[index] };

  const firstStage = newState.stages[0];
  const lastStage = newState.stages[newState.stages.length - 1];

  if (firstStage.order > stageNextState.order || lastStage.order < stageNextState.order) {
    return newState;
  }

  newStage.organizationId = stageNextState.organizationId;
  newStage.id = stageNextState.id;
  newStage.order = stageNextState.order;
  newState.stages.forEach((stage) => {
    if (stage.order === newStage.order) {
      if (newStage.order > oldStage.order) {
        stage.order -= 1;
      } else {
        stage.order += 1;
      }
    }
  });
  newState.stages[index] = newStage;
  newState.stages.sort((a, b) => a.order - b.order);

  return newState;
};

const reorderDealNavigatorCard = (state, action) => {
  const newState = { ...state };
  const cardNextState = action.result;

  const stageIndex = newState.stages.findIndex((s) => s.id === cardNextState.stageId);
  const stage = { ...newState.stages[stageIndex] };
  const stageCards = [...stage.cards];

  if (cardNextState.order == 0) {
    return MoveUpCardToPreviousStage(state, action);
  }

  if (cardNextState.order > stageCards.length) {
    return MoveCardToNextStage(state, action);
  }

  return MoveInTheStage(state, action);
};

//#region REORDER HANDLERS
const MoveUpCardToPreviousStage = (state, action) => {
  const newState = { ...state };

  let allStages = [...newState.stages];
  allStages.sort((a, b) => a.order - b.order);

  const currentStageIndex = newState.stages.findIndex((s) => s.id === action.result.stageId);

  if (currentStageIndex == 0) return newState;

  let currentStage = { ...allStages[currentStageIndex] };

  let previousStage = { ...allStages[currentStageIndex - 1] };

  let currentStageCards = [...currentStage.cards];

  const indexOfCardForReorder = currentStageCards.findIndex((s) => s.id === action.result.id);

  let cardForReorder = { ...currentStageCards[indexOfCardForReorder] };

  if (cardForReorder == null) return newState;

  currentStageCards.splice(indexOfCardForReorder, 1);

  currentStageCards.forEach((card) => {
    card.order -= 1;
  });

  currentStage.cards = currentStageCards;
  allStages[currentStageIndex] = currentStage;

  let previousStageCards = [...previousStage.cards];

  previousStageCards.sort((a, b) => a.order - b.order);
  let lastCardInPreviousStage = {
    ...previousStageCards[previousStageCards.length - 1],
  };

  cardForReorder.order = IsEmpty(lastCardInPreviousStage) ? 1 : lastCardInPreviousStage.order + 1;
  cardForReorder.position = IsEmpty(lastCardInPreviousStage) ? cardForReorder.position : lastCardInPreviousStage.position == 1 ? 3 : 1;
  cardForReorder.stageId = previousStage.id;

  previousStageCards.push(cardForReorder);
  previousStageCards.sort((a, b) => a.order - b.order);
  previousStage.cards = previousStageCards;
  allStages[currentStageIndex - 1] = previousStage;
  newState.stages = allStages;
  return newState;
};

const IsEmpty = (obj) => {
  return obj === null || obj === undefined || (Object.keys(obj).length === 0 && obj.constructor === Object);
};

const MoveCardToNextStage = (state, action) => {
  const newState = { ...state };

  let allStages = [...newState.stages];

  const indexOfCurrentStage = allStages.findIndex((s) => s.id === action.result.stageId);

  let currentStage = { ...allStages[indexOfCurrentStage] };

  if (currentStage == null) return newState;

  let currentStageCards = [...currentStage.cards];

  const indexOfCardForReorder = currentStageCards.findIndex((c) => c.id === action.result.id);

  let cardForReorder = { ...currentStageCards[indexOfCardForReorder] };

  const indexOfNextStage = indexOfCurrentStage + 1;

  if (indexOfNextStage === allStages.length) return newState;

  let nextStage = { ...allStages[indexOfNextStage] };

  let nextStageCards = [...nextStage.cards];

  currentStageCards.splice(indexOfCardForReorder, 1);
  currentStage.cards = currentStageCards;
  allStages[indexOfCurrentStage] = currentStage;

  nextStageCards.forEach((card) => {
    card.order += 1;
  });

  const firstCardInNextStage = nextStageCards.find((card) => card);
  cardForReorder.order = 1;
  cardForReorder.position = IsEmpty(firstCardInNextStage) ? cardForReorder.position : firstCardInNextStage.position == 1 ? 3 : 1;
  cardForReorder.stageId = nextStage.id;

  nextStageCards = [cardForReorder].concat(...nextStageCards);

  nextStageCards.sort((a, b) => a.order - b.order);
  nextStage.cards = nextStageCards;
  allStages[indexOfNextStage] = nextStage;
  newState.stages = allStages;
  return newState;
};

const MoveInTheStage = (state, action) => {
  const newState = { ...state };
  const cardNextState = action.result;

  let allStages = [...newState.stages];

  const indexOfCurrentStage = allStages.findIndex((s) => s.id === cardNextState.stageId);

  let currentStage = { ...allStages[indexOfCurrentStage] };
  if (currentStage == null) return newState;
  let currentStageCards = [...currentStage.cards];

  const indexOfCardForReorder = currentStageCards.findIndex((c) => c.id === cardNextState.id);

  let cardForReorder = { ...currentStageCards[indexOfCardForReorder] };

  if (cardForReorder == null) return newState;

  if (cardForReorder.order > cardNextState.order) {
    let previousCard = { ...currentStageCards[indexOfCardForReorder - 1] };
    previousCard.position = previousCard.position === 1 ? 3 : 1;
    previousCard.order += 1;
    currentStageCards[indexOfCardForReorder - 1] = { ...previousCard };
  } else {
    let nextCard = { ...currentStageCards[indexOfCardForReorder + 1] };
    nextCard.position = nextCard.position === 1 ? 3 : 1;
    nextCard.order -= 1;
    currentStageCards[indexOfCardForReorder + 1] = { ...nextCard };
  }

  cardForReorder.order = cardNextState.order;
  cardForReorder.position = cardForReorder.position === 1 ? 3 : 1;
  currentStageCards[indexOfCardForReorder] = cardForReorder;
  currentStageCards.sort((a, b) => a.order - b.order);
  currentStage.cards = currentStageCards;
  allStages[indexOfCurrentStage] = currentStage;
  newState.stages = allStages;
  return newState;
};
//#endregion

const swapDealNavigatorCard = (state, action) => {
  const newState = { ...state };
  const cardNextState = action.result;

  const stageIndex = newState.stages.findIndex((s) => s.id === cardNextState.stageId);
  const stage = { ...newState.stages[stageIndex] };
  const cards = [...stage.cards];

  const cardIndex = cards.findIndex((c) => c.id === cardNextState.id);
  const newCard = { ...cards[cardIndex] };

  newCard.id = cardNextState.id;
  newCard.stageId = cardNextState.stageId;
  newCard.position = cardNextState.position;
  cards.forEach((card, index) => {
    if (index !== cardIndex) {
      if (card.position === 1) {
        card.position = 3;
      } else {
        card.position = 1;
      }
    }
  });
  cards[cardIndex] = newCard;
  stage.cards = cards;
  newState.stages[stageIndex] = stage;
  newState.stages[stageIndex].cards.sort((a, b) => a.order - b.order);
  return newState;
};

// FAILED
const reorderDealNavigatorStagesFailed = (state, action) => {
  const newState = { ...state };
  return newState;
};

const reorderDealNavigatorCardsFailed = (state, action) => {
  const newState = { ...state };
  return newState;
};

const swapDealNavigatorCardsFailed = (state, action) => {
  const newState = { ...state };
  return newState;
};
// #endregion

//#region TEMPLATE
const fetchDealNavigatorTemplateSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.templates };
  list = action.result;
  newState.isLoadedTemplate = true;
  newState.isLoadingTemplate = false;
  newState.templates = list;
  return newState;
};

const fetchDealNavigatorTemplateFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoadingTemplate = false;
  return newState;
};
//#endregion TEMPLATE

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // #region FETCH
    // DEAL NAVIGATOR
    case actionTypes.FETCH_DEAL_NAVIGATOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        ...action.result,
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };
    case actionTypes.SET_EDIT_MODE_DEAL_NAVIGATOR:
      return {
        ...state,
        isInEditMode: action.result,
      };

    case actionTypes.PUBLISH_DEAL_NAVIGATOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.PUBLISH_DEAL_NAVIGATOR_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        ...action.result,
        error: "",
        isInEditMode: false,
        isInTemplateMode: false,
      };
    case actionTypes.PUBLISH_DEAL_NAVIGATOR_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
        isInEditMode: false,
      };

    case actionTypes.DISCARD_DEAL_NAVIGATOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.DISCARD_DEAL_NAVIGATOR_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        ...action.result,
        error: "",
        isInEditMode: false,
      };
    case actionTypes.DISCARD_DEAL_NAVIGATOR_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
        isInEditMode: false,
      };

    // DEAL NAVIGATOR STAGES
    case actionTypes.FETCH_DEAL_NAVIGATOR_STAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_STAGES_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        stages: [
          ...state.stages,
          {
            [action.result.stageId]: [...state.stages[action.result.stageId].cards, action.result],
          },
        ],
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_STAGES_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };

    // DEAL NAVIGATOR CARDS
    case actionTypes.FETCH_DEAL_NAVIGATOR_CARDS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_CARDS_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        stages: [
          ...state.stages,
          {
            [action.result.stageId]: [
              ...state.stages[action.result.stageId].cards,
              {
                [action.result.cardId]: [...state.stages[action.result.stageId].cards[action.result.cardId].elements, action.result],
              },
            ],
          },
        ],
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_CARDS_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };

    // DEAL NAVIGATOR ELEMENTS
    case actionTypes.FETCH_DEAL_NAVIGATOR_ELEMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        stages: [
          ...state.stages,
          {
            [action.result.stageId]: [
              ...state.stages[action.result.stageId].cards,
              {
                [action.result.cardId]: [
                  ...state.stages[action.result.stageId].cards[action.result.cardId].elements,
                  {
                    [action.result.elementId]: [
                      ...state.stages[action.result.stageId].cards[action.result.cardId].elements[action.result.elementId],
                      action.result,
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_ELEMENTS_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };

    // // DEAL NAVIGATOR DOCUMENTS
    // case actionTypes.FETCH_DEAL_NAVIGATOR_DOCUMENTS_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isLoaded: false
    //   };
    // case actionTypes.FETCH_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED:
    //   return {
    //     isLoading: false,
    //     isLoaded: true,
    //     error: "",
    //     ...state,
    //     Document: {
    //       ...state,
    //       ...action.result
    //     }
    //   };
    // case actionTypes.FETCH_DEAL_NAVIGATOR_DOCUMENTS_FAILED:
    //   return {
    //     isLoading: false,
    //     isLoaded: false,
    //     ...state,
    //     error: action.result
    //   };
    // #endregion

    //#region DEAL NAVIGATOR TEMPLATE
    case actionTypes.CHOOSE_DEAL_NAVIGATOR_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.CHOOSE_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED:
      return {
        ...state,
        ...action.result,
        isLoading: false,
        isLoaded: true,
        error: "",
        isInEditMode: false,
        isInTemplateMode: action.result.status === dealNavigatorStatusEnum.draft,
      };
    case actionTypes.CHOOSE_DEAL_NAVIGATOR_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.result,
        isInEditMode: false,
      };

    case actionTypes.FETCH_DEAL_NAVIGATOR_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoadingTemplate: true,
        isLoadedTemplate: false,
      };
    case actionTypes.FETCH_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED:
      return fetchDealNavigatorTemplateSuccess(state, action);
    case actionTypes.FETCH_DEAL_NAVIGATOR_TEMPLATE_FAILED:
      return fetchDealNavigatorTemplateFailed(state, action);
    //#endregion

    // #region ADD
    case actionTypes.ADD_DEAL_NAVIGATOR_STAGES_REQUEST:
      return addDealNavigatorStagesRequested(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_CARDS_REQUEST:
      return addDealNavigatorCardsRequested(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED:
      return addDealNavigatorElementsSuccess(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_STAGES_SUCCEEDED:
      return addDealNavigatorStagesSucceeded(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_CARDS_SUCCEEDED:
      return addDealNavigatorCardsSucceeded(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_STAGES_FAILED:
      return addDealNavigatorStagesFailed(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_CARDS_FAILED:
      return addDealNavigatorCardsFailed(state, action);

    case actionTypes.ADD_DEAL_NAVIGATOR_ELEMENTS_FAILED:
      return addDealNavigatorElementsFailed(state, action);
    // #endregion

    // #region CHANGE
    case actionTypes.CHANGE_DEAL_NAVIGATOR_STAGES_SUCCEEDED:
      return changeDealNavigatorStagesSuccess(state, action);

    // case actionTypes.CHANGE_DEAL_NAVIGATOR_CARDS_REQUEST:
    //   return changeDealNavigatorCardsSucceded(state, action);

    case actionTypes.CHANGE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED:
      return changeDealNavigatorElementsSucceeded(state, action);

    case actionTypes.CHANGE_DEAL_NAVIGATOR_STAGES_FAILED:
      return changeDealNavigatorStagesFailed(state, action);

    case actionTypes.CHANGE_DEAL_NAVIGATOR_CARDS_FAILED:
      return changeDealNavigatorCardsFailed(state, action);

    case actionTypes.CHANGE_DEAL_NAVIGATOR_ELEMENTS_FAILED:
      return changeDealNavigatorElementsFailed(state, action);

    // #endregion

    // #region REMOVE

    case actionTypes.REMOVE_DEAL_NAVIGATOR_STAGES_SUCCEEDED:
      return removeDealNavigatorStagesSucceeded(state, action);

    case actionTypes.REMOVE_DEAL_NAVIGATOR_CARDS_REQUEST:
      return removeDealNavigatorCards(state, action);

    case actionTypes.REMOVE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED:
      return removeDealNavigatorElementsSucceeded(state, action);
    // #endregion

    // #region REORDER & SWAP
    case actionTypes.REORDER_DEAL_NAVIGATOR_STAGES_REQUEST:
      return reorderDealNavigatorStage(state, action);

    case actionTypes.REORDER_DEAL_NAVIGATOR_STAGES_FAILED:
      return reorderDealNavigatorStagesFailed(state, action);

    case actionTypes.REORDER_DEAL_NAVIGATOR_CARDS_REQUEST:
      return reorderDealNavigatorCard(state, action);

    case actionTypes.REORDER_DEAL_NAVIGATOR_CARDS_FAILED:
      return reorderDealNavigatorCardsFailed(state, action);

    case actionTypes.SWAP_DEAL_NAVIGATOR_CARDS_REQUEST:
      return swapDealNavigatorCard(state, action);

    case actionTypes.SWAP_DEAL_NAVIGATOR_CARDS_FAILED:
      return swapDealNavigatorCardsFailed(state, action);
    // #endregion

    case actionTypes.SET_LAST_ADDED_CARD_VALUE:
      return {
        ...state,
        lastAddedCardId: 0,
      };
    //#region RESET
    case actionTypes.RESET_DEAL_NAVIGATOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.RESET_DEAL_NAVIGATOR_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        ...action.result,
      };
    case actionTypes.RESET_DEAL_NAVIGATOR_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };
    //#endregion RESET
    default:
      break;
  }

  return state;
};

export default reducer;
