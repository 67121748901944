import { api } from "./api";
import { CSCQuestionsAndAnswersDetailsModel } from "../models/compliance/CSCQuestionsAndAnswersDetailsModel";
import { DTParameters } from "../models/dataTables/DtParameters";
import { DTResult } from "../models/dataTables/DtResult";
import { DropdownModel } from "../models/DropDownModel";
import { CreateCSCQuestionsAndAnswersModel } from "../models/compliance/CreateCSCQuestionsAndAnswersModel";
import { UpdateCSCQuestionsAndAnswersModel } from "../models/compliance/UpdateCSCQuestionsAndAnswersModel";

export const complianceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionsAndAnswers: builder.query<DTResult<CSCQuestionsAndAnswersDetailsModel>, DTParameters>({
      query: (params) => ({
        url: "/compliance/questions-and-answers/get-all",
        method: "POST",
        body: params,
      }),
      providesTags: ["Questions"],
    }),
    getCategories: builder.query<DropdownModel[], void>({
      query: () => "/compliance/categories-list",
      providesTags: ["QuestionCategories"],
    }),
    createQuestion: builder.mutation<CSCQuestionsAndAnswersDetailsModel, CreateCSCQuestionsAndAnswersModel>({
      query: (model) => ({
        url: `/compliance/questions-and-answers`,
        method: "POST",
        body: model,
      }),
      invalidatesTags: ["Questions", "QuestionCategories"],
    }),
    deleteQuestion: builder.mutation<void, number>({
      query: (id) => ({
        url: `/compliance/questions-and-answers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Questions"],
    }),
    updateQuestion: builder.mutation<number, UpdateCSCQuestionsAndAnswersModel>({
      query: (data) => ({
        url: `/compliance/questions-and-answers`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Questions", "QuestionCategories"],
    }),
  }),
});

export const { useGetQuestionsAndAnswersQuery, useGetCategoriesQuery, useCreateQuestionMutation, useDeleteQuestionMutation, useUpdateQuestionMutation } = complianceApi;
