import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Styles
import classes from "./VersionNumber.module.scss";

//Components
import Logo from "../../assets/Logo/Logo";

const VersionNumber = (props) => (
  <div className={classes.loading}>
    <Container>
      <Row>
        <Col lg={10} className="mx-auto text-center text-white">
          <Logo style={{ width: "350px", margin: "50px" }} />
          <h4 style={{ marginTop: "100px" }}>Version:</h4>
          <h1>{import.meta.env.VITE_VERSION}</h1>
        </Col>
      </Row>
    </Container>
  </div>
);

export default VersionNumber;
