import React, { useEffect, useState } from "react";

//Helpers
import { templateVariablesTabs } from "../../helper/enums/templateVariablesTabsEnum";
import { templateVariableInsertToEnum } from "../../helper/enums/templateVariableInsertToEnum";

//Styles
import styles from "./EmailTemplates.module.scss";

//Images & Icons
import SearchLineIcon from "remixicon-react/SearchLineIcon";
import InformationLineIcon from "remixicon-react/InformationLineIcon";

//Components
import AddVariableToEditorForm from "./AddVariableToEditorForm";
import ActionWrapper from "../../components/UI/ActionWrapper/ActionWrapper";
import ActionAddCircle from "../../components/UI/ActionWrapper/Actions/ActionAddCircle";
import ActionCheck from "../../components/UI/ActionWrapper/Actions/ActionCheck";
import AccordionSimple from "../../components/UI/AccordionSimple/AccordionSimple";

//Hooks
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const TemplateVariables = (props) => {
  const {
    tinyMCE,
    emailTemplateHtml,
    emailTemplateSubject,
    organizationCustomColumns,
    templateVariableInsertTo,
    formikProps,
    disableChaserVariables,
  } = props;

  // Queries
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery();
  
  const [variableAdded, setVariableAdded] = useState(false);
  const [activeTab, setActiveTab] = useState(templateVariablesTabs.defaultVariables);
  const [usedVariables, setUsedVariables] = useState([]);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [searchVariablesText, setSearchVariablesText] = useState("");

  const [variableUsedInModal, setVariableUsedInModal] = useState(null);
  const [showAddVariableToEditorFormModal, setShowAddVariableToEditorFormModal] = useState(false);
  const handleAddVariableToEditorFormClose = () => setShowAddVariableToEditorFormModal(false);
  const handleAddVariableToEditorFormShow = (variable) => {
    setVariableUsedInModal(variable);
    setShowAddVariableToEditorFormModal(true);
  };

  useEffect(() => {
    checkForUsedVariables();
  }, []);

  useEffect(() => {
    if (variableAdded) {
      checkForUsedVariables();
      setVariableAdded(false);
    }
  }, [variableAdded]);

  const addVariableToEditor = (variable) => {
    if (variable !== "") {
      if (templateVariableInsertTo === templateVariableInsertToEnum.templateBody) {
        tinyMCE.insertContent(variable);
      }
      if (templateVariableInsertTo === templateVariableInsertToEnum.templateSubject) {
        let subject = emailTemplateSubject;
        subject += variable;
        formikProps.setFieldValue("emailTemplateSubject", subject);
      }

      setVariableAdded(true);
      handleAddVariableToEditorFormClose();
    }
  };

  const checkIfVariableIsUsed = (variable) => {
    if (variable !== "") {
      return (
        emailTemplateHtml.includes(variable) ||
        emailTemplateHtml.includes(variable.replace("}}", "|")) ||
        emailTemplateSubject.includes(variable) ||
        emailTemplateSubject.includes(variable.replace("}}", "|"))
      );
    } else {
      return false;
    }
  };

  const checkIfTabIsActive = (variableTab) => {
    if (variableTab === activeTab) return styles.active;
    else return "";
  };

  const checkForUsedVariables = () => {
    const newUsedVariables = usedVariables;

    emailTemplateVariables.map((type, index) => {
      var typeName = "";
      const variables = [];

      type.emailTemplateVariables.map((variable) => {
        var variableAvailable = true;
        usedVariables.map((usedType) => {
          usedType.emailTemplateVariables.map((usedVariable) => {
            if (usedVariable.text === variable.text) {
              variableAvailable = false;
            }
          });
        });

        if (variableAvailable) {
          if (emailTemplateHtml.includes(variable.text) || emailTemplateSubject.includes(variable.text)) {
            variables.push(variable);
            typeName = type.name;
          }

          var variableWithOptionalValue = variable.text.replace("}}", "|");
          if (emailTemplateHtml.includes(variableWithOptionalValue) || emailTemplateSubject.includes(variableWithOptionalValue)) {
            const optionalVariableRegex = /{{(.*?)\|(.*?)}}/g;
            let regexMatches = emailTemplateHtml.match(optionalVariableRegex);

            if (regexMatches === null) regexMatches = emailTemplateSubject.match(optionalVariableRegex);

            if (regexMatches != null) {
              regexMatches.forEach((match) => {
                var variableSplitted = match.split("|");
                var variableText = variableSplitted[0] + "}}";
                var variableOptionalValue = variableSplitted[1].replace("}}", "");

                if (variableText === variable.text) {
                  var newVariable = {
                    createdDate: variable.createdDate,
                    example: variable.example,
                    id: variable.id,
                    modifiedDate: variable.modifiedDate,
                    name: variable.name,
                    text: variableText,
                    type: variable.type,
                    typeId: variable.typeId,
                    optionalValue: variableOptionalValue,
                  };

                  variables.push(newVariable);
                  typeName = type.name;
                }
              });
            }
          }
        }
      });
      if (typeName !== "") {
        newUsedVariables.push({
          id: index,
          name: typeName,
          emailTemplateVariables: variables,
        });
      }
    });

    setUsedVariables(newUsedVariables);
  };

  var resultDiv = null;

  if (firstTimeLoad) {
    checkForUsedVariables();
    setFirstTimeLoad(false);
  }

  resultDiv = (
    <div id="variablesWrapper" className={styles.templateVariables}>
      <div className={styles.tabsDiv}>
        <p
          id="defaultTab"
          className={checkIfTabIsActive(templateVariablesTabs.defaultVariables)}
          onClick={() => setActiveTab(templateVariablesTabs.defaultVariables)}
        >
          Default
        </p>
        <p
          id="customFields"
          className={checkIfTabIsActive(templateVariablesTabs.customVariables)}
          onClick={() => setActiveTab(templateVariablesTabs.customVariables)}
        >
          Custom Fields
        </p>
      </div>
      <br />
      <div className={styles.searchWrapper}>
        <SearchLineIcon size={14} className={`iconGray ${styles.searchICon}`} />
        <input
          className={styles.headerSearch}
          type="text"
          placeholder="Search"
          tabIndex="-1"
          onChange={(event) => {
            setSearchVariablesText(event.target.value);
          }}
        />
      </div>
      <div
        className={`templateVariablesBodyFromSequence ${styles.templateVariablesBody} ${
          props.templateVariablesBodyClass ? props.templateVariablesBodyClass : ""
        }`}>
        <AccordionSimple
          iconHeader={<InformationLineIcon className="mR5" />}
          header={"Using clean variables"}
          eventKey="companies"
          classes={styles.InfoAccordion}
          className="mB15"
          headTitleClass={styles.headTitle}
          body={
            <div className="pL25">
              <p className="mB10 f14">*We recommend you use variables which are cleaned.</p>
              <p className="mB10 f14">
                <strong> &#123; &#123; COMPANY_CLEAN &#125; &#125;</strong> displays informalized version of the prospect's company name
                (where appropriate) removing abbreviations like ltd, llc, corp etc.
              </p>
              <p className="mB10 f14">
                <strong>Example:</strong> &#123; &#123; COMPANY &#125; &#125; Space Exploration Technologies Corp &#123; &#123;
                COMPANY_CLEAN &#125; &#125; SpaceX
              </p>
              <p className="f14">
                For more information about using variables check out our{" "}
                <a
                  className={styles.documentationLink}
                  rel="noopener noreferrer"
                  href="https://help.outbase.com/user-guide/email-templates/default-variables"
                  target="_blank"
                >
                  User Documentation
                </a>
              </p>
            </div>
          }
        />
        <div className={`${styles.variablesList} ${props.variablesListClass ?? ""}`}>
          {/* Default Variables */}
          {activeTab === templateVariablesTabs.defaultVariables
            ? emailTemplateVariables.map((type, i) => {
                let writeHeader = false;

                const variables = [];
                type.emailTemplateVariables.map((variable, i) => {
                  if (searchVariablesText !== "") {
                    if (variable.text.includes(searchVariablesText.toUpperCase())) {
                      writeHeader = true;

                      variables.push(
                        <div className={styles.variableRow} key={"varKey" + i}>
                          <div className={styles.variableText}>
                            <ActionWrapper>
                              <ActionAddCircle
                                id={variable.text.replace("{{", "").replace("}}", "")}
                                title={"Add Variable"}
                                onClick={() => {
                                  handleAddVariableToEditorFormShow(variable);
                                }}
                              />
                              <ActionCheck
                                title={"Variable Used"}
                                style={
                                  checkIfVariableIsUsed(variable.text)
                                    ? { color: "#FE7904", cursor: "default" }
                                    : {
                                        color: "transparent",
                                        cursor: "default",
                                      }
                                }
                                onClick={() => {
                                  return false;
                                }}
                              />
                            </ActionWrapper>
                            <p>
                              {variable.text}
                              <br />
                              <small>{variable.example}</small>
                            </p>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    writeHeader = true;
                    let isDisabled = false;
                    if (type.name == "Chaser Variables" && disableChaserVariables) isDisabled = true;
                    variables.push(
                      <div disabled={isDisabled} className={styles.variableRow} key={i}>
                        <div className={styles.variableText}>
                          <ActionWrapper>
                            <ActionAddCircle
                              id={variable.text.replace("{{", "").replace("}}", "")}
                              title={"Add Variable"}
                              onClick={() => {
                                handleAddVariableToEditorFormShow(variable);
                              }}
                            />
                            <ActionCheck
                              title={"Variable Used"}
                              style={
                                checkIfVariableIsUsed(variable.text)
                                  ? { color: "#FE7904", cursor: "default" }
                                  : { color: "transparent", cursor: "default" }
                              }
                              onClick={() => {
                                return false;
                              }}
                            />
                          </ActionWrapper>
                          <p>
                            {variable.text}
                            <br />
                            <small>{variable.example}</small>
                          </p>
                        </div>
                      </div>
                    );
                  }
                });

                return (
                  <div key={"wrapperKey" + i}>
                    {writeHeader ? (
                      <div className={styles.variableCategory}>
                        <h1>{type.name}</h1>
                      </div>
                    ) : null}
                    {variables}
                  </div>
                );
              })
            : null}

          {/* Custom Variables */}
          {activeTab === templateVariablesTabs.customVariables ? (
            <>
              <div className={styles.variableCategory}>
                <h1>Prospect Custom Variables</h1>
              </div>

              {organizationCustomColumns.map((variable, i) => {
                if (searchVariablesText !== "") {
                  if (variable.text.toUpperCase().includes(searchVariablesText.toUpperCase())) {
                    return (
                      <div className={styles.variableRow} key={"varIndex" + i}>
                        <div className={styles.variableText}>
                          <ActionWrapper>
                            <ActionAddCircle
                              id={variable.text.replace("{{", "").replace("}}", "")}
                              title={"Add Variable"}
                              onClick={() => {
                                handleAddVariableToEditorFormShow(variable);
                              }}
                            />
                            <ActionCheck
                              title={"Variable Used"}
                              style={
                                checkIfVariableIsUsed(variable.text)
                                  ? { color: "#FE7904", cursor: "default" }
                                  : { color: "transparent", cursor: "default" }
                              }
                              onClick={() => {
                                return false;
                              }}
                            />
                          </ActionWrapper>
                          <p>{variable.text}</p>
                        </div>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div className={styles.variableRow} key={"addedVariable" + i}>
                      <div className={styles.variableText}>
                        <ActionWrapper>
                          <ActionAddCircle
                            id={variable.text.replace("{{", "").replace("}}", "")}
                            title={"Add Variable"}
                            onClick={() => {
                              handleAddVariableToEditorFormShow(variable);
                            }}
                          />
                          <ActionCheck
                            title={"Variable Used"}
                            style={
                              checkIfVariableIsUsed(variable.text)
                                ? { color: "#FE7904", cursor: "default" }
                                : { color: "transparent", cursor: "default" }
                            }
                            onClick={() => {
                              return false;
                            }}
                          />
                        </ActionWrapper>
                        <p>{variable.text}</p>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={`${styles.templateVariablesDiv} templateVariableSequence`}>{resultDiv}</div>

      <AddVariableToEditorForm
        handleClose={handleAddVariableToEditorFormClose}
        showModal={showAddVariableToEditorFormModal}
        variable={variableUsedInModal}
        addVariableToEditor={addVariableToEditor}
      />
    </>
  );
};

export default TemplateVariables;
