import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";

//Helpers
import { GLOBAL_PERMISSIONS } from "../helper/globalUserRolePermissionsConstants";
import { organizationStatusEnum } from "../helper/enums/organizationStatus";
import { organizationRoleEnum } from "../helper/enums/organizationRoleEnum";
import { globalPermissionHelper } from "../helper/globalPermissionHelper";

//Components
import Loading from "../pages/Loading/Loading";
import Permission from "../components/auth/Permission";
import NoPermission from "../pages/NoPermission/NoPermission";
import NoPermissionUpdatePackage from "../pages/NoPermissionUpdatePackage/NoPermissionUpdatePackage";
import NoPermissionPricingPackageLink from "../pages/NoPermissionPricingPackageLink/NoPermissionPricingPackageLink";
import Login from "../components/auth/Login";

const PrivateRouteOutboss = ({ component, layout, permission, user, ...rest }) => {
  const auth = useAuth();

  const renderFn = (Component, Layout, Perm, User) => (props) => {
    if (!!Component && auth.isAuthenticated) {
      if (Perm) {
        let redirectUrl = "/dashboard";

        if (User?.organization) {
          redirectUrl += "/" + User.organization.id;

          let permission = (
            <Permission has={Perm} redirect redirectUrl={redirectUrl}>
              {React.createElement(Layout, props, React.createElement(component, props))}
            </Permission>
          );

          if (User.organization.organizationStatus === organizationStatusEnum.active || User.organization.organizationStatus === organizationStatusEnum.pendingRenewal) {
            return permission;
          } else if (User.organization.organizationStatus === organizationStatusEnum.inactive) {
            // Admins and Owners have access only to billing and payment
            // Outboss users will have access to all the functionalities no matter what their role in the organization is
            if (
              globalPermissionHelper(User.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll) ||
              globalPermissionHelper(User.userPermissions, GLOBAL_PERMISSIONS.outboss.view)
            ) {
              return permission;
            }
          }
        }
      }

      if (
        props.history.action === "POP" &&
        User.organization == null &&
        props.location.pathname !== "/my-organizations" &&
        props.location.pathname !== "/sandbox" &&
        props.location.pathname !== "/register-organization" &&
        !props.location.pathname.startsWith("/outboss")
      ) {
        return React.createElement(NoPermission, props);
      }
      return React.createElement(Layout, props, React.createElement(component, props));
    } else {
      return <Login />;
    }
  };

  return <Route {...rest} render={renderFn(component, layout, permission, user)} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteOutboss);
