export const columns = [
  {
    label: "",
    field: "status",
    type: "action",
    sort: "asc",
    width: 50,
  },
  {
    label: "Email account",
    field: "emailAccount",
    type: "text",
    sort: "asc",
    minWidth: 250,
    flex: 1,
  },
  {
    label: "Name",
    field: "name",
    type: "text",
    sort: "asc",
    width: 150,
    minWidth: 150,
    showTooltip: true,
  },
  {
    label: "Owner",
    field: "owner",
    type: "text",
    width: 150,
    minWidth: 150,
    sort: "asc",
    showTooltip: true,
  },
  {
    label: "Type",
    field: "type",
    type: "text",
    width: 150,
    minWidth: 150,
    sort: "asc",
  },
  {
    label: "Last date used",
    field: "lastDateUsed",
    type: "date",
    width: 150,
    minWidth: 150,
    sort: "asc",
  },
  {
    label: "Created date",
    field: "createdDate",
    width: 150,
    minWidth: 150,
    type: "date",
    sort: "asc",
  },
  {
    label: "No. of campaigns",
    field: "numberOfCampaigns",
    columnClass: "textRight",
    width: 150,
    minWidth: 150,
    class: "textRight",
    sort: "asc",
  },
  {
    label: "Messages per day",
    field: "messagesPerDay",
    columnClass: "textRight",
    class: "textRight",
    width: 150,
    minWidth: 150,
    sort: "asc",
  },
  {
    label: "Total emails sent",
    field: "emailsDelivered",
    columnClass: "textRight",
    class: "textRight",
    width: 150,
    minWidth: 150,
    sort: "asc",
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    width: 150,
  },
];
