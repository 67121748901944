import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

//Styles
import styles from "./ResponseTypeButton.module.scss";

//Images and Icons
import IconPositiveResponse from "../../../../assets/Icons/IconPositiveResponse/IconPositiveResponse";
import IconReferral from "../../../../assets/Icons/IconReferral/IconReferral";
import IconDeferred from "../../../../assets/Icons/IconDeferred/IconDeferred";
import IconDecline from "../../../../assets/Icons/IconDecline/IconDecline";
import IconMoveJob from "../../../../assets/Icons/IconMovedJob/IconMovedJob";
import IconArchived from "../../../../assets/Icons/IconArchived/IconArchived";
import IconUndelivered from "../../../../assets/Icons/IconUndelivered/IconUndelivered";
import { EventBusyOutlined } from "@mui/icons-material";

import { responseTypeEnum } from "../../../../helper/enums/responseTypeEnum";

const ResponseTypeButton = ({
  type,
  index,
  activeTab,
  activeElementIndex,
  setIsReferral,
  setShowAddToExclusion,
  setIsOutOfOffice,
  setChangedResponse,
  setFieldValue,
  setActiveElementIndex,
}) => {
  let className = "";
  let Icon = null;
  let label = "";

  switch (type.value) {
    case responseTypeEnum.PositiveResponse:
      className = styles.positive;
      Icon = <IconPositiveResponse />;
      label = "Positive";
      break;
    case responseTypeEnum.Referral:
      className = styles.referral;
      Icon = <IconReferral />;
      label = "Referral";
      break;
    case responseTypeEnum.DeferredInterest:
      className = styles.deferred;
      Icon = <IconDeferred />;
      label = "Deferred";
      break;
    case responseTypeEnum.Decline:
      className = styles.decline;
      Icon = <IconDecline />;
      label = "Decline";
      break;
    case responseTypeEnum.NoLongerWithBusiness:
      className = styles.decline;
      Icon = <IconMoveJob />;
      label = "Moved jobs";
      break;
    case responseTypeEnum.Undelivered:
      className = styles.decline;
      Icon = <IconUndelivered />;
      label = "Undelivered";
      break;
    case responseTypeEnum.OutOfOffice:
      className = styles.deferred;
      Icon = <EventBusyOutlined />;
      label = "OOO";
      break;
    case responseTypeEnum.Archived:
      className = styles.archive;
      Icon = <IconArchived />;
      label = "Archive";
      break;
    default:
      break;
  }

  const handleClick = () => {
    if (type.value === responseTypeEnum.Referral) setIsReferral(true);
    else setIsReferral(false);
    if (type.value === responseTypeEnum.Decline) setShowAddToExclusion(true);
    else setShowAddToExclusion(false);
    if (type.value === responseTypeEnum.OutOfOffice) setIsOutOfOffice(true);
    else setIsOutOfOffice(false);

    setChangedResponse(true);
    setFieldValue("ResponseType", type.value);
    setActiveElementIndex(index);
  };

  return (
    <div className={`flex_column align-center textCenter ${styles.cubeButtonWrapper}`}>
      <OverlayTrigger
        key={"active"}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-active`}>
            <Popover.Body>{type.label}</Popover.Body>
          </Popover>
        }
      >
        <button
          type="button"
          className={`textTransformInherit ${
            (activeTab !== 0 && activeElementIndex === index) || activeElementIndex === index ? styles.activeButton : null
          } ${className} ${styles.cubeButton}`}
          key={index}
          onClick={handleClick}
        >
          {Icon}
        </button>
      </OverlayTrigger>
      <div className={`cursor_pointer f12 mB10 mT5 ${styles.label}`}>{label}</div>
    </div>
  );
};

export default ResponseTypeButton;
