import { useFormikContext } from "formik";

// Styles
import styles from "./Filters.module.scss";

//Images & Icons
import TeamLineIcon from "remixicon-react/TeamLineIcon";
import Building3LineIcon from "remixicon-react/Building3LineIcon";
import MapPinLineIcon from "remixicon-react/MapPinLineIcon";
import Briefcase4LineIcon from "remixicon-react/Briefcase4LineIcon";
import Key2LineIcon from "remixicon-react/Key2LineIcon";
import MoneyDollarCircleLineIcon from "remixicon-react/MoneyDollarCircleLineIcon";
import ToolsLineIcon from "remixicon-react/ToolsLineIcon";
import Wallet3LineIcon from "remixicon-react/Wallet3LineIcon";
import CalendarEventLineIcon from "remixicon-react/CalendarEventLineIcon";
import LineChartLineIcon from "remixicon-react/LineChartLineIcon";
import GlobalLineIcon from "remixicon-react/GlobalLineIcon";
import BarcodeBoxLineIcon from "remixicon-react/BarcodeBoxLineIcon";

// Components
import AccordionSimple from "../../../../components/UI/AccordionSimple/AccordionSimple";
import FilterCompanyIndustry from "./CompanyFilters/FilterCompanyIndustry";
import FilterCompanySize from "./CompanyFilters/FilterCompanySize";
import FilterCompanyName from "./CompanyFilters/FilterCompanyName";
import FilterCompanyRevenue from "./CompanyFilters/FilterCompanyRevenue";
import FilterCompanyKeywords from "./CompanyFilters/FilterCompanyKeywords";
import FilterCompanyTechnology from "./CompanyFilters/FilterCompanyTechnology";
import FilterCompanyLocation from "./CompanyFilters/FilterCompanyLocation";
import FilterCompanyNaicsAndSicCodes from "./CompanyFilters/FilterCompanyNaicsAndSicCodes";
import FilterCompanyDomain from "./CompanyFilters/FilterCompanyDomain";
import { CompanyRevenueGrowthTimeframeType, NaicsAndSicCodesType, SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import FilterCompanyHeadcountGrowth from "./CompanyFilters/FilterCompanyHeadcountGrowth";
import FilterCompanyFunding from "./CompanyFilters/FilterCompanyFunding";
import FilterCompanyFoundedYear from "./CompanyFilters/FilterCompanyFoundedYear";
import moment from "moment";

const CompanyFilterGroup: React.FC = () => {
  const {
    values: {
      company: {
        sizes,
        industries,
        domains,
        naicsAndSicCodes: { type: codeType, naicsCodes, sicCodes },
        location: {
          type,
          region: { locations },
          range,
        },
        technologies,
        revenue,
        keywords,
        names,
        companyHeadcountGrowth: {
          min: companyHeadcountGrowthMin,
          max: companyHeadcountGrowthMax,
          timeframe: companyHeadcountGrowthTimeframe,
        },
        companyFunding: {
          min: companyFundingMin,
          max: companyFundingMax,
          lastFundingRoundType,
          dateFrom: companyFundingDateFrom,
          dateTo: companyFundingDateTo,
        },
        companyFoundedYear: { dateFrom: companyFoundedYearDateFrom, dateTo: companyFoundedYearDateTo },
      },
    },
  } = useFormikContext<SearchModel>();

  const minDate = moment().add(-1000, "years").calendar();

  const locationFilterCount = (locations.length > 0 ? locations.length : 0) + (range?.location?.name ? 1 : 0);
  const companyHeadcountGrowthUsed: boolean =
    !!companyHeadcountGrowthMax ||
    (companyHeadcountGrowthMin ?? 0) < (companyHeadcountGrowthMax ?? 0) ||
    (companyHeadcountGrowthTimeframe != null && companyHeadcountGrowthTimeframe !== CompanyRevenueGrowthTimeframeType.None);

  const companyFundingUsed: boolean =
    (!!companyFundingMax && (companyFundingMin ?? 0) < companyFundingMax) ||
    (!!companyFundingDateTo && (companyFundingDateFrom ?? minDate) < companyFundingDateTo) ||
    lastFundingRoundType?.length > 0;
  const companyFoundedYearUsed: boolean = !!companyFoundedYearDateTo && (companyFoundedYearDateFrom ?? minDate) < companyFoundedYearDateTo;

  return (
    <>
      <div id="companyFilters">
        <>
          {names && names.length >= 20 ? (
            <>
              <span className={styles.filterErrorMessage}>You can choose up to 20 company filter selections.</span>
              <br />
            </>
          ) : null}

          {technologies && technologies.length >= 20 ? (
            <>
              <span className={styles.filterErrorMessage}>You can choose up to 20 technology filter selections.</span>
              <br />
            </>
          ) : null}
        </>

        <h1 className="f14 mT10 mB10">Select companies by:</h1>

        <AccordionSimple
          icon={<Building3LineIcon size={16} />}
          className="mB10"
          header={"Industries"}
          eventKey="industry"
          rightContent={industries.length}
          defaultOpen={industries.length > 0}
          body={<FilterCompanyIndustry />}
        />
        <AccordionSimple
          icon={<TeamLineIcon size={16} />}
          className="mB10"
          header="Size"
          eventKey="employees"
          rightContent={sizes.length}
          defaultOpen={sizes.length > 0}
          body={<FilterCompanySize />}
        />
        <AccordionSimple
          icon={<MapPinLineIcon size={16} />}
          className="mB10"
          header="Locations"
          eventKey="Geography"
          // headerId="companyLocationAccordion"
          rightContent={locationFilterCount}
          defaultOpen={locationFilterCount > 0}
          body={<FilterCompanyLocation />}
        />
        <AccordionSimple
          icon={<GlobalLineIcon size={16} />}
          className="mB10"
          header="Domains"
          eventKey="domain"
          rightContent={domains.length}
          defaultOpen={domains.length > 0}
          body={<FilterCompanyDomain />}
        />
        <AccordionSimple
          icon={<Briefcase4LineIcon size={16} />}
          header="Name"
          eventKey="name"
          className="mB10"
          rightContent={names.length}
          defaultOpen={names.length > 0}
          body={<FilterCompanyName />}
        />
        <AccordionSimple
          icon={<Key2LineIcon size={16} />}
          className="mB10"
          header="Keyword"
          eventKey="keyword"
          rightContent={keywords.length}
          defaultOpen={keywords.length > 0}
          body={<FilterCompanyKeywords />}
        />
        <AccordionSimple
          icon={<BarcodeBoxLineIcon size={16} />}
          className="mB10"
          header={"NAICS & SIC Industry Codes"}
          eventKey="NaicsAndSicIndustryCodes"
          rightContent={codeType === NaicsAndSicCodesType.Naics ? naicsCodes.length : sicCodes.length}
          defaultOpen={codeType === NaicsAndSicCodesType.Naics ? naicsCodes.length > 0 : sicCodes.length > 0}
          body={<FilterCompanyNaicsAndSicCodes />}
        />
        <AccordionSimple
          icon={<LineChartLineIcon size={16} />}
          className="mB10"
          header="Headcount Growth"
          eventKey="headcountGrowth"
          rightContent={companyHeadcountGrowthUsed ? 1 : 0}
          defaultOpen={companyHeadcountGrowthUsed}
          body={<FilterCompanyHeadcountGrowth />}
        />
        <AccordionSimple
          icon={<MoneyDollarCircleLineIcon size={16} />}
          className="mB10"
          header="Annual revenue"
          eventKey="companyRevenueRanges"
          rightContent={revenue.length}
          defaultOpen={revenue.length > 0}
          body={<FilterCompanyRevenue />}
        />
        <AccordionSimple
          icon={<Wallet3LineIcon size={16} />}
          className="mB10"
          header="Funding"
          eventKey="funding"
          rightContent={companyFundingUsed ? 1 : 0}
          defaultOpen={companyFundingUsed}
          body={<FilterCompanyFunding />}
        />
        <AccordionSimple
          icon={<CalendarEventLineIcon size={16} />}
          className="mB10"
          header="Founded Year"
          eventKey="foundedYear"
          rightContent={companyFoundedYearUsed ? 1 : 0}
          defaultOpen={companyFoundedYearUsed}
          body={<FilterCompanyFoundedYear />}
        />
        <AccordionSimple
          icon={<ToolsLineIcon size={16} />}
          className="mB10"
          header="Technologies used"
          eventKey="technology"
          rightContent={technologies.length}
          defaultOpen={technologies.length > 0}
          body={<FilterCompanyTechnology />}
        />
      </div>
    </>
  );
};

export default CompanyFilterGroup;
