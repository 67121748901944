import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

//Redux
import {
  fetchDomains,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
} from "../../../store/Domains/actions/domains";

//Helpers
import { numberOfRecords, filterData, getFilterValue } from "../../../helper/tableConstants";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { permissionHelper } from "../../../helper/permissionHelper";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";

//Styles
import styles from "./Active.module.scss";

//Icons and images
import EmptyTemplatesImage from "../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

//Components
import UpdateDomainForm from "./UpdateDomainForm";
import UnverifiedDomains from "./UnverifiedDomains";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import { columns } from "./columns";
import Permission from "../../../components/auth/Permission";
import Table from "../../../components/DataTable/Table";
import ActionEdit from "../../../components/UI/ActionWrapper/Actions/ActionEdit";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import { listOfStatusOptions } from "./listOfStatusOptions";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import InfoIconTooltip from "../../../components/UI/CustomTooltip/InfoIconTooltip";

const ActiveDomains = (props) => {
  const { param, rows, fetchDomains, initiate, isInitiated } = props;
  const [tableRows, setTableRows] = useState([]);

  const [height, setHeight] = useState(0);
  const [updateDomain, setUpdateDomain] = useState({});
  const headerRef = useRef();
  let image = <EmptyTemplatesImage />;
  let dateFormat = useOrgDateFormat();

  const [showUpdateDomainModal, setShowUpdateDomainModal] = useState(false);
  const handleUpdateDomainModalClose = () => {
    setUpdateDomain({});
    setShowUpdateDomainModal(false);
    fetchDomains(param);
  };

  const handleUpdateDomainModalShow = (domain) => {
    setUpdateDomain(domain);
    setShowUpdateDomainModal(true);
  };

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Verify your domain to track opens, clicks, and unsubscribes through your hosting provider."}
      href={"https://help.outbase.com/user-guide/email-accounts/domains"}
      linkText="Read More"
      buttonId="startDomainsTour"
      disableStartTourButton
    />
  );

  const records = numberOfRecords[1];

  let selectRowOptions = [
    {
      option: listOfStatusOptions,
      change: (c) => filterData("Status", c, props.setFilter),
      value: getFilterValue(param.filter, "Status"),
      placeholder: "Status",
      showSelectedValues: true,
      isMulti: true,
    },
  ];

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "Status", value: [1] }],
      },
    };
    initiate(state);
    if (permissionHelper(props.userRole, PERMISSIONS.domain.view)) {
      let topHeight = headerRef.current.offsetHeight;
      setHeight(window.innerHeight - topHeight);
    }
  }, [initiate, props.userRole, records.value]);

  useEffect(() => {
    if (isInitiated) {
      fetchDomains(param);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r) => {
      const original = { ...r };

      let active = r.isActive ? (
        <div className={`${styles.status}`}>
          <span className={`${styles.circle} ${styles.active}`} />
          <span>Active</span>
        </div>
      ) : (
        <div className={`${styles.status}`}>
          <span className={`${styles.circle} ${styles.inactive}`} />
          <span>Inactive</span>
        </div>
      );
      r.isActive = active;

      r.dnsVerified = (
        <ActionWrapper>
          {" "}
          {r.dnsVerified ? (
            <CheckboxCircleFillIcon size={20} color="var(--first-stage-color)" />
          ) : (
            <CloseCircleFillIcon size={20} color="var(--danger-color)" />
          )}{" "}
        </ActionWrapper>
      );
      r.trackOpens = (
        <ActionWrapper>
          {" "}
          {r.trackOpens ? (
            <CheckboxCircleFillIcon size={20} color="var(--first-stage-color)" />
          ) : (
            <CloseCircleFillIcon size={20} color="var(--danger-color)" />
          )}{" "}
        </ActionWrapper>
      );
      r.trackClicks = (
        <ActionWrapper>
          {" "}
          {r.trackClicks ? (
            <CheckboxCircleFillIcon size={20} color="var(--first-stage-color)" />
          ) : (
            <CloseCircleFillIcon size={20} color="var(--danger-color)" />
          )}{" "}
        </ActionWrapper>
      );

      let actions = (
        <ActionWrapper>
          <ActionEdit onClick={() => handleUpdateDomainModalShow(original)} />
        </ActionWrapper>
      );
      r.actions = actions;

      r.createdDate = Moment(r.createdDate).format(dateFormat);
      return r;
    });

    setTableRows(tempRows);
  }, [rows]);

  let table = null;

  if (props.error) {
    table = <h2>Error getting Domains</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Domains"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          tableHeight={320}
          variant="small"
          tableClassName={styles.domainsTable}
        />
      ) : (
        <EmptyState image={image} title="No domains found" content="" />
      );
  }

  return (
    <Permission has={PERMISSIONS.domain.view}>
      <div className="contentHeight">
        <div ref={headerRef}>
          <HeaderTitle title="Domains" icon={icon} />

          <TableFiltersRow
            placeholder="domains"
            changeFilter={(text) => props.searchRecords(text)}
            selects={selectRowOptions}
            tableFilterClass={styles.statusFilter}
          />
        </div>

        <Permission has={PERMISSIONS.domain.edit}>
          <UnverifiedDomains currentRows={rows} />
        </Permission>
        {table}

        <Permission has={PERMISSIONS.domain.edit}>
          {!_.isEmpty(updateDomain) && updateDomain.id > 0 ? (
            <UpdateDomainForm domain={updateDomain} handleClose={handleUpdateDomainModalClose} showModal={showUpdateDomainModal} />
          ) : null}
        </Permission>
      </div>
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.domains.params,
    isInitiated: state.domains.isInitiated,
    isLoading: state.domains.isLoading,
    isLoaded: state.domains.isLoaded,
    error: state.domains.error,
    rows: state.domains.rows,
    totalCount: state.domains.totalCount,
    pageCount: state.domains.pageCount,
    orgId: state.user.organization.id,
    userRole: state.user.organization.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDomains: (param) => dispatch(fetchDomains(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDomains);
