import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  error: "",
  companiesProperties: [],
  contactsProperties: [],
  dealsProperties: [],
  customRules: [],
  editCustomRule: {},
  customFields: [],
  dealsPipelines: [],
  dealsPipeline: [],
  globalConfiguration: [],
  globalMappingsCustomFields: [],
};

const mapContactsAndCompaniesPropertiesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const mapContactsAndCompaniesPropertiesSuccess = (state, action) => {
  const newState = { ...state };
  // HERE INSERT CODE
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const mapContactsAndCompaniesPropertiesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const editHubspotIntegrationRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const editHubspotIntegrationSuccess = (state, action) => {
  const newState = { ...state };
  // HERE INSERT CODE
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const editHubspotIntegrationFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const deleteHubspotIntegrationRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const deleteHubspotIntegrationSuccess = (state, action) => {
  const newState = { ...state };
  // HERE INSERT CODE
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const deleteHubspotIntegrationFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const addHubspotRulesetRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const addHubspotRulesetSuccess = (state, action) => {
  const newState = { ...state };
  // HERE INSERT CODE
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const addHubspotRulesetFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const addHubspotRuleRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const addHubspotRuleSuccess = (state, action) => {
  const newState = { ...state };
  // HERE INSERT CODE
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const addHubspotRuleFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const editHubspotRuleRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const editHubspotRuleSuccess = (state, action) => {
  const newState = { ...state };
  // HERE INSERT CODE
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const editHubspotRuleFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const deleteHubspotRuleRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const deleteHubspotRuleSuccess = (state, action) => {
  const newState = { ...state };
  let customRules = [...state.customRules];
  const ruleId = action.result;
  const index = customRules.findIndex((rule) => rule.id === ruleId);
  customRules.splice(index, 1);
  newState.customRules = customRules;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const deleteHubspotRuleFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchHubspotRulesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchHubspotRulesSuccess = (state, action) => {
  const newState = { ...state };
  let customRules = { ...state.customRules };
  customRules = action.result;
  newState.customRules = customRules;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchHubspotRulesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchCompaniesPropertiesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchCompaniesPropertiesSuccess = (state, action) => {
  const newState = { ...state };
  let companiesProperties = { ...state.companiesProperties };
  companiesProperties = action.result;
  newState.companiesProperties = companiesProperties;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchCompaniesPropertiesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchContactsPropertiesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchContactsPropertiesSuccess = (state, action) => {
  const newState = { ...state };
  let contactsProperties = { ...state.contactsProperties };
  contactsProperties = action.result;
  newState.contactsProperties = contactsProperties;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchContactsPropertiesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchDealsPropertiesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchDealsPropertiesSuccess = (state, action) => {
  const newState = { ...state };
  let dealsProperties = { ...state.dealsProperties };
  dealsProperties = action.result;
  newState.dealsProperties = dealsProperties;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchDealsPropertiesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchCustomRuleByIdRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchCustomRuleByIdSuccess = (state, action) => {
  const newState = { ...state };
  let editCustomRule = { ...state.editCustomRule };
  editCustomRule = action.result;
  newState.editCustomRule = editCustomRule;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchCustomRuleByIdFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

//

const fetchRulesCustomFieldsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchRulesCustomFieldsSuccess = (state, action) => {
  const newState = { ...state };
  let customFields = { ...state.customFields };
  newState.dealsPipelines = [];
  newState.dealsPipeline = [];
  customFields = action.result;
  newState.customFields = customFields;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchRulesCustomFieldsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchDealsPipelinesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchDealsPipelinesSuccess = (state, action) => {
  const newState = { ...state };
  let dealsPipelines = { ...state.dealsPipelines };
  newState.customFields = [];
  dealsPipelines = action.result;
  newState.dealsPipelines = dealsPipelines;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchDealsPipelinesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchDealsPipelineByIdRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchDealsPipelineByIdSuccess = (state, action) => {
  const newState = { ...state };
  let dealsPipeline = { ...state.dealsPipeline };
  newState.customFields = [];
  dealsPipeline = action.result;
  newState.dealsPipeline = dealsPipeline;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchDealsPipelineByIdFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchHubSpotConfigurationRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchHubSpotConfigurationSuccess = (state, action) => {
  const newState = { ...state };
  let globalConfiguration = { ...state.globalConfiguration };
  globalConfiguration = action.result;
  newState.globalConfiguration = globalConfiguration;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchHubSpotConfigurationFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fecthGlobalMappingsCustomFieldsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fecthGlobalMappingsCustomFieldsSuccess = (state, action) => {
  const newState = { ...state };
  let globalMappingsCustomFields = { ...state.globalMappingsCustomFields };

  if (
    !_.isEmpty(action.result) &&
    !_.isNull(action.result) &&
    !_.isUndefined(action.result)
  )
    globalMappingsCustomFields = action.result;
  else globalMappingsCustomFields = [];

  newState.globalMappingsCustomFields = globalMappingsCustomFields;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fecthGlobalMappingsCustomFieldsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.MAP_CONTACTS_AND_COMPANIES_PROPERTIES_REQUEST:
      return mapContactsAndCompaniesPropertiesRequest(state, action);

    case actionTypes.MAP_CONTACTS_AND_COMPANIES_PROPERTIES_SUCCEEDED:
      return mapContactsAndCompaniesPropertiesSuccess(state, action);

    case actionTypes.MAP_CONTACTS_AND_COMPANIES_PROPERTIES_FAILED:
      return mapContactsAndCompaniesPropertiesFailed(state, action);

    case actionTypes.EDIT_HUBSPOT_INTEGRATION_REQUEST:
      return editHubspotIntegrationRequest(state, action);

    case actionTypes.EDIT_HUBSPOT_INTEGRATION_SUCCEEDED:
      return editHubspotIntegrationSuccess(state, action);

    case actionTypes.EDIT_HUBSPOT_INTEGRATION_FAILED:
      return editHubspotIntegrationFailed(state, action);

    case actionTypes.DELETE_HUBSPOT_INTEGRATION_REQUEST:
      return deleteHubspotIntegrationRequest(state, action);

    case actionTypes.DELETE_HUBSPOT_INTEGRATION_SUCCEEDED:
      return deleteHubspotIntegrationSuccess(state, action);

    case actionTypes.DELETE_HUBSPOT_INTEGRATION_FAILED:
      return deleteHubspotIntegrationFailed(state, action);

    case actionTypes.ADD_HUBSPOT_RULESET_REQUEST:
      return addHubspotRulesetRequest(state, action);

    case actionTypes.ADD_HUBSPOT_RULESET_SUCCEEDED:
      return addHubspotRulesetSuccess(state, action);

    case actionTypes.ADD_HUBSPOT_RULESET_FAILED:
      return addHubspotRulesetFailed(state, action);

    case actionTypes.ADD_HUBSPOT_RULE_REQUEST:
      return addHubspotRuleRequest(state, action);

    case actionTypes.ADD_HUBSPOT_RULE_SUCCEEDED:
      return addHubspotRuleSuccess(state, action);

    case actionTypes.ADD_HUBSPOT_RULE_FAILED:
      return addHubspotRuleFailed(state, action);

    case actionTypes.EDIT_HUBSPOT_RULE_REQUEST:
      return editHubspotRuleRequest(state, action);

    case actionTypes.EDIT_HUBSPOT_RULE_SUCCEEDED:
      return editHubspotRuleSuccess(state, action);

    case actionTypes.EDIT_HUBSPOT_RULE_FAILED:
      return editHubspotRuleFailed(state, action);

    case actionTypes.DELETE_HUBSPOT_RULE_REQUEST:
      return deleteHubspotRuleRequest(state, action);

    case actionTypes.DELETE_HUBSPOT_RULE_SUCCEEDED:
      return deleteHubspotRuleSuccess(state, action);

    case actionTypes.DELETE_HUBSPOT_RULE_FAILED:
      return deleteHubspotRuleFailed(state, action);

    case actionTypes.FETCH_HUBSPOT_RULES_REQUEST:
      return fetchHubspotRulesRequest(state, action);

    case actionTypes.FETCH_HUBSPOT_RULES_SUCCEEDED:
      return fetchHubspotRulesSuccess(state, action);

    case actionTypes.FETCH_HUBSPOT_RULES_FAILED:
      return fetchHubspotRulesFailed(state, action);

    case actionTypes.FETCH_COMPANIES_PROPERTIES_REQUEST:
      return fetchCompaniesPropertiesRequest(state, action);

    case actionTypes.FETCH_COMPANIES_PROPERTIES_SUCCEEDED:
      return fetchCompaniesPropertiesSuccess(state, action);

    case actionTypes.FETCH_COMPANIES_PROPERTIES_FAILED:
      return fetchCompaniesPropertiesFailed(state, action);

    case actionTypes.FETCH_CONTACT_PROPERTIES_REQUEST:
      return fetchContactsPropertiesRequest(state, action);

    case actionTypes.FETCH_CONTACT_PROPERTIES_SUCCEEDED:
      return fetchContactsPropertiesSuccess(state, action);

    case actionTypes.FETCH_CONTACT_PROPERTIES_FAILED:
      return fetchContactsPropertiesFailed(state, action);

    case actionTypes.FETCH_DEALS_PROPERTIES_REQUEST:
      return fetchDealsPropertiesRequest(state, action);

    case actionTypes.FETCH_DEALS_PROPERTIES_SUCCEEDED:
      return fetchDealsPropertiesSuccess(state, action);

    case actionTypes.FETCH_DEALS_PROPERTIES_FAILED:
      return fetchDealsPropertiesFailed(state, action);

    case actionTypes.GET_CUSTOM_RULE_BY_ID_REQUEST:
      return fetchCustomRuleByIdRequest(state, action);

    case actionTypes.GET_CUSTOM_RULE_BY_ID_SUCCEEDED:
      return fetchCustomRuleByIdSuccess(state, action);

    case actionTypes.GET_CUSTOM_RULE_BY_ID_FAILED:
      return fetchCustomRuleByIdFailed(state, action);

    case actionTypes.GET_RULES_CUSTOM_FIELDS_REQUEST:
      return fetchRulesCustomFieldsRequest(state, action);

    case actionTypes.GET_RULES_CUSTOM_FIELDS_SUCCEEDED:
      return fetchRulesCustomFieldsSuccess(state, action);

    case actionTypes.GET_RULES_CUSTOM_FIELDS_FAILED:
      return fetchRulesCustomFieldsFailed(state, action);

    case actionTypes.GET_DEALS_PIPELINES_REQUEST:
      return fetchDealsPipelinesRequest(state, action);

    case actionTypes.GET_DEALS_PIPELINES_SUCCEEDED:
      return fetchDealsPipelinesSuccess(state, action);

    case actionTypes.GET_DEALS_PIPELINES_FAILED:
      return fetchDealsPipelinesFailed(state, action);

    case actionTypes.GET_DEALS_PIPELINE_BY_ID_REQUEST:
      return fetchDealsPipelineByIdRequest(state, action);

    case actionTypes.GET_DEALS_PIPELINE_BY_ID_SUCCEEDED:
      return fetchDealsPipelineByIdSuccess(state, action);

    case actionTypes.GET_DEALS_PIPELINE_BY_ID_FAILED:
      return fetchDealsPipelineByIdFailed(state, action);

    case actionTypes.GET_CONFIGURATION_REQUEST:
      return fetchHubSpotConfigurationRequest(state, action);

    case actionTypes.GET_CONFIGURATION_SUCCEEDED:
      return fetchHubSpotConfigurationSuccess(state, action);

    case actionTypes.GET_CONFIGURATION_FAILED:
      return fetchHubSpotConfigurationFailed(state, action);

    case actionTypes.GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_REQUEST:
      return fecthGlobalMappingsCustomFieldsRequest(state, action);

    case actionTypes.GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_SUCCEEDED:
      return fecthGlobalMappingsCustomFieldsSuccess(state, action);

    case actionTypes.GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_FAILED:
      return fecthGlobalMappingsCustomFieldsFailed(state, action);

    default:
      break;
  }

  return state;
};

export default reducer;
