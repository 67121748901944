import React from "react";

const CombinationMarkLogoDark = (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 82 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.775 8.81697C17.7752 10.1374 17.4769 11.4407 16.9025 12.6296C16.328 13.8185 15.4922 14.8621 14.4576 15.6825C14.435 15.7059 14.409 15.7257 14.3805 15.7413C14.2991 15.7986 14.2017 15.8288 14.1022 15.8278C14.0016 15.8288 13.9031 15.7986 13.8204 15.7413C12.3587 14.7722 10.6431 14.257 8.88928 14.2605C9.96463 14.2517 11.0133 13.9247 11.9031 13.3208C12.7929 12.7169 13.484 11.8632 13.8894 10.8671C14.2947 9.87106 14.3961 8.77727 14.1808 7.72367C13.9655 6.67006 13.4431 5.70378 12.6794 4.94661C11.9158 4.18944 10.9451 3.67528 9.88969 3.46892C8.8343 3.26256 7.74144 3.37324 6.74886 3.78702C5.75627 4.20081 4.9084 4.89918 4.31209 5.79409C3.71579 6.689 3.39773 7.7404 3.39804 8.81578C3.40504 10.2657 3.98733 11.6536 5.01695 12.6745C6.04657 13.6954 7.43934 14.2658 8.88928 14.2605C7.13446 14.2574 5.41778 14.7725 3.9546 15.7413C3.87317 15.7985 3.77583 15.8287 3.67632 15.8278C3.5757 15.8288 3.47725 15.7986 3.39452 15.7413C2.33786 14.9224 1.4826 13.8725 0.894232 12.6721C0.305861 11.4717 -2.92175e-05 10.1526 2.09315e-09 8.81578C0.019076 6.47112 0.963872 4.229 2.62858 2.57778C4.2933 0.926552 6.54307 0 8.88781 0C11.2326 0 13.4823 0.926552 15.147 2.57778C16.8117 4.229 17.7565 6.47112 17.7756 8.81578L17.775 8.81697Z"
      fill="#FE7904"
    />
    <path
      d="M10.1953 8.83153C10.1953 9.08995 10.1186 9.34259 9.97507 9.55746C9.8315 9.77234 9.62742 9.9398 9.38867 10.0387C9.14992 10.1376 8.88722 10.1635 8.63376 10.1131C8.3803 10.0626 8.14747 9.93819 7.96474 9.75546C7.782 9.57272 7.65755 9.33989 7.60714 9.08643C7.55672 8.83297 7.5826 8.57027 7.6815 8.33152C7.78039 8.09277 7.94785 7.88869 8.16273 7.74512C8.3776 7.60155 8.63024 7.5249 8.88866 7.5249C9.2352 7.5249 9.56755 7.6626 9.81259 7.90764C10.0576 8.15268 10.1953 8.48499 10.1953 8.83153Z"
      fill="#586473"
    />
    <path
      d="M14.0925 19.2158C14.0925 19.4238 14.0098 19.6233 13.8628 19.7704C13.7157 19.9174 13.5162 20 13.3082 20H4.439C4.23101 20 4.03155 19.9174 3.88448 19.7704C3.73742 19.6233 3.65479 19.4238 3.65479 19.2158C3.65479 19.0078 3.73742 18.8084 3.88448 18.6613C4.03155 18.5142 4.23101 18.4316 4.439 18.4316H13.3082C13.5162 18.4316 13.7157 18.5142 13.8628 18.6613C14.0098 18.8084 14.0925 19.0078 14.0925 19.2158Z"
      fill="#586473"
    />
    <path
      d="M31.3958 7.15034C31.0425 6.28562 30.4398 5.5456 29.6646 5.02449C28.8893 4.50339 27.9765 4.22478 27.0424 4.22412C26.3963 4.2235 25.7564 4.35085 25.1598 4.59885C24.5885 4.83923 24.0691 5.18782 23.6302 5.62544C23.1889 6.05576 22.8356 6.56789 22.5901 7.13325C22.3324 7.71607 22.2009 8.3468 22.2042 8.98405C22.2042 9.61563 22.3317 10.2407 22.5789 10.8219C22.8243 11.3874 23.1728 11.9023 23.6067 12.3403C24.0456 12.7782 24.565 13.1272 25.1363 13.3681C25.7245 13.623 26.3589 13.7542 27 13.7535H27.0247C27.8061 13.7458 28.5736 13.5465 29.2601 13.1732C29.9466 12.7999 30.531 12.2639 30.9621 11.6122C31.3933 10.9605 31.658 10.213 31.7331 9.43519C31.8082 8.65739 31.6914 7.87306 31.3928 7.15092L31.3958 7.15034ZM30.2192 8.97995C30.2186 9.40818 30.1368 9.83241 29.978 10.2301C29.7357 10.818 29.3241 11.3206 28.7956 11.674C28.267 12.0274 27.6453 12.2157 27.0094 12.215C26.5791 12.2135 26.1533 12.1272 25.7564 11.9611C25.3595 11.7949 24.9992 11.5521 24.6962 11.2467C24.395 10.9513 24.1556 10.599 23.9918 10.2103C23.828 9.82155 23.743 9.40412 23.742 8.98229C23.7532 8.12882 24.1008 7.31428 24.7092 6.71558C25.0089 6.41319 25.3656 6.17323 25.7587 6.00961C26.1642 5.84257 26.5985 5.75681 27.0371 5.75722H27.0429C27.465 5.75578 27.883 5.83957 28.2719 6.00354C28.6608 6.16751 29.0125 6.40827 29.3061 6.71145C29.9002 7.31625 30.2297 8.13225 30.2221 8.97995H30.2192Z"
      fill="#586473"
    />
    <path
      d="M39.6036 4.35893C39.401 4.36154 39.2074 4.44342 39.0645 4.58705C38.9215 4.73067 38.8405 4.92459 38.8388 5.12724V9.79366C38.8522 10.2751 38.7495 10.7528 38.5393 11.1862C38.3946 11.4567 38.1783 11.6822 37.914 11.838C37.4695 12.0688 36.9726 12.1801 36.4721 12.161C35.9791 12.1792 35.4898 12.0693 35.0519 11.8421C34.7794 11.6874 34.5564 11.4585 34.4089 11.1821C34.1957 10.7492 34.0919 10.2707 34.1065 9.78838V5.12666C34.1065 4.92297 34.0256 4.7276 33.8815 4.58357C33.7375 4.43953 33.5421 4.35864 33.3384 4.35864C33.1347 4.35864 32.9394 4.43953 32.7954 4.58357C32.6514 4.7276 32.5704 4.92297 32.5704 5.12666V9.78249C32.5542 10.5208 32.7203 11.2517 33.054 11.9104C33.3395 12.4374 33.7677 12.8733 34.2894 13.1682C34.9223 13.5159 35.6328 13.6981 36.355 13.6977C36.3977 13.6977 36.4407 13.6977 36.4838 13.6977C38.5111 13.6977 39.4565 12.7258 39.896 11.9069C40.2286 11.2514 40.3939 10.5238 40.3772 9.78895V5.12724C40.376 4.92293 40.2939 4.72743 40.1489 4.58346C40.004 4.43949 39.8079 4.35877 39.6036 4.35893Z"
      fill="#586473"
    />
    <path
      d="M48.6013 5.1261L48.8107 5.11142C48.8079 4.90981 48.7256 4.71743 48.5817 4.57618C48.4378 4.43494 48.244 4.35624 48.0424 4.35719H42.0164C41.8126 4.35719 41.6172 4.43816 41.4731 4.58225C41.329 4.72634 41.248 4.92176 41.248 5.12553C41.248 5.3293 41.329 5.52471 41.4731 5.6688C41.6172 5.81289 41.8126 5.89383 42.0164 5.89383H44.2666V12.8082C44.2691 13.0087 44.3502 13.2002 44.4925 13.3414C44.6347 13.4827 44.8268 13.5625 45.0273 13.5636H45.035C45.2385 13.5628 45.4335 13.4816 45.5774 13.3377C45.7213 13.1938 45.8025 12.9988 45.8033 12.7952V5.89444H48.0571C48.1571 5.89337 48.256 5.87258 48.348 5.83329C48.44 5.79401 48.5234 5.73701 48.5934 5.6655C48.6633 5.59399 48.7186 5.50937 48.7558 5.41652C48.7931 5.32367 48.8118 5.22439 48.8107 5.12434L48.6013 5.1261Z"
      fill="#586473"
    />
    <path
      d="M55.9898 8.62421C56.2662 8.36257 56.4719 8.03528 56.5878 7.67281C56.7037 7.31034 56.7261 6.92444 56.6529 6.551C56.457 5.1579 55.408 4.3584 53.7737 4.3584H50.4651C50.2616 4.35933 50.0667 4.44058 49.9228 4.58447C49.7789 4.72836 49.6977 4.92325 49.6968 5.12674V12.7988C49.7012 13.0045 49.7838 13.2008 49.9279 13.3477C50.0699 13.4854 50.2597 13.5628 50.4574 13.5636H54.3079C55.9181 13.5636 57.0347 12.6135 57.1541 11.1421C57.2213 10.655 57.1474 10.1588 56.941 9.71252C56.7346 9.26619 56.4045 8.88848 55.9898 8.62421ZM54.1708 8.05766L53.3237 8.06883C53.1193 8.07023 52.9239 8.15245 52.7801 8.29752C52.6362 8.4426 52.5556 8.63871 52.5559 8.84302C52.5623 9.04678 52.6472 9.24019 52.7929 9.38274C52.9387 9.5253 53.1339 9.60597 53.3378 9.60785L54.2067 9.59664H54.2402C54.4453 9.57714 54.6522 9.60084 54.8476 9.6662C55.0429 9.73155 55.2224 9.83713 55.3745 9.97611C55.4856 10.1193 55.5651 10.2844 55.6078 10.4605C55.6505 10.6366 55.6554 10.8198 55.6221 10.998L55.6145 11.0762C55.5245 11.7169 55.1074 12.0175 54.3102 12.0175H51.2328V5.89917H53.7713C54.8756 5.89917 55.0768 6.32627 55.1286 6.74396L55.1333 6.76928C55.1712 6.91905 55.1752 7.07538 55.1452 7.22691C55.1151 7.37845 55.0516 7.52136 54.9594 7.6453C54.8671 7.76925 54.7485 7.8711 54.612 7.94344C54.4755 8.01578 54.3245 8.05682 54.1702 8.06355L54.1708 8.05766Z"
      fill="#586473"
    />
    <path
      d="M71.348 8.29934C69.3931 7.81929 68.8242 7.56749 68.8242 6.82858C68.8242 5.76964 70.0914 5.68491 70.4903 5.68491C70.8337 5.66697 71.1774 5.71204 71.5045 5.81787C71.845 5.94244 72.1522 6.14375 72.4023 6.40617C72.4579 6.4602 72.5168 6.51071 72.5788 6.55738L72.6111 6.57738C72.7499 6.64767 72.9087 6.66772 73.0605 6.63415C73.2124 6.60058 73.348 6.51543 73.4442 6.39321C73.5299 6.29438 73.588 6.17465 73.6126 6.04613C73.6372 5.91761 73.6274 5.78489 73.5842 5.66139L73.5753 5.63963C73.4412 5.36255 73.2516 5.11598 73.0182 4.9152C72.7848 4.71442 72.5127 4.56372 72.2187 4.47243C71.6674 4.27012 71.0846 4.16752 70.4973 4.16944C69.8966 4.12155 69.2949 4.25036 68.7665 4.54001C68.238 4.82965 67.8057 5.26747 67.5229 5.79963L67.5141 5.81844C67.3698 6.17293 67.3027 6.55408 67.3173 6.93652C67.3319 7.31896 67.4279 7.69391 67.5988 8.03636L67.6123 8.0593C67.9659 8.58638 68.4825 8.98296 69.0831 9.18826C69.6037 9.39679 70.1434 9.5544 70.6944 9.6589C71.4422 9.81127 72.3287 10.0707 72.5494 10.7237C72.618 10.937 72.6098 11.1676 72.5264 11.3756C72.4268 11.5852 72.2759 11.7664 72.0877 11.9023C71.8995 12.0382 71.6801 12.1246 71.4498 12.1533C70.9863 12.2418 70.5108 12.2486 70.0449 12.1733C69.4784 12.1167 68.9508 11.8592 68.5577 11.4473C68.4837 11.3331 68.3877 11.2347 68.2753 11.1579L68.2506 11.1432C68.1324 11.0845 67.999 11.0633 67.8684 11.0825C67.7378 11.1018 67.6162 11.1605 67.5199 11.2508C67.4019 11.3522 67.3165 11.4863 67.2747 11.6361C67.2328 11.786 67.2362 11.9448 67.2846 12.0927C67.483 12.5744 67.8563 12.9631 68.3294 13.1811C68.7964 13.4301 69.303 13.5965 69.8267 13.6729C70.1293 13.719 70.4348 13.7422 70.7409 13.7423C71.7116 13.7423 73.6395 13.2864 73.9895 11.5597C74.2148 10.4202 73.9413 8.92941 71.348 8.29934Z"
      fill="#586473"
    />
    <path
      d="M81.8083 12.7393C81.7961 12.5444 81.7103 12.3614 81.5683 12.2273C81.4264 12.0932 81.2388 12.018 81.0435 12.0169H76.4689V9.69664H80.4105C80.612 9.6937 80.8042 9.61131 80.9453 9.46744C81.0864 9.32357 81.165 9.12982 81.1641 8.9283C81.1622 8.72553 81.0807 8.53162 80.937 8.3885C80.7934 8.24539 80.5991 8.16458 80.3964 8.16351H76.4694V5.88797H80.9341C81.1378 5.88797 81.3333 5.80699 81.4773 5.6629C81.6214 5.51881 81.7024 5.3234 81.7024 5.11962C81.7024 4.91585 81.6214 4.72044 81.4773 4.57635C81.3333 4.43226 81.1378 4.35132 80.9341 4.35132H75.7023C75.4995 4.35317 75.3056 4.43473 75.1625 4.5784C75.0194 4.72206 74.9386 4.91627 74.9375 5.11905V12.7876C74.9384 12.991 75.0197 13.1859 75.1636 13.3298C75.3075 13.4737 75.5024 13.555 75.7058 13.5559H81.0594C81.2616 13.5534 81.4546 13.4709 81.5962 13.3265C81.7377 13.1821 81.8163 12.9875 81.8148 12.7852L81.8083 12.7393Z"
      fill="#586473"
    />
    <path
      d="M66.3452 12.4671L62.8684 4.79798C62.8071 4.66542 62.7093 4.55304 62.5865 4.47406C62.4636 4.39508 62.3208 4.35277 62.1748 4.35205H62.1506C62.0046 4.35301 61.862 4.39549 61.7392 4.47457C61.6165 4.55364 61.5188 4.66602 61.4576 4.79856L57.9808 12.4694C57.8982 12.6571 57.8926 12.8698 57.9653 13.0615C58.0379 13.2533 58.1829 13.4089 58.369 13.4949C58.5565 13.5741 58.7673 13.5782 58.9577 13.5064C59.1481 13.4346 59.3037 13.2923 59.3921 13.1089L60.1086 11.5205H64.2162L64.9357 13.1148C65.0247 13.2966 65.18 13.4374 65.3697 13.5081C65.5593 13.5788 65.7689 13.5741 65.9552 13.4949L65.9629 13.4913C66.148 13.4047 66.2918 13.2488 66.3633 13.0573C66.4348 12.8658 66.4283 12.6539 66.3452 12.4671ZM60.8022 9.98445L62.1624 6.97058L63.5226 9.98445H60.8022Z"
      fill="#586473"
    />
  </svg>
);

export default CombinationMarkLogoDark;
