import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

//Redux
import { filterData, getFilterValue } from "@helper/tableConstants";
import { setFilter, clearFilter } from "@store/CompanyActivityOverview/Table/actions/companyActivityOverview";
import { fetchProspectsStatuses } from "@store/CompanyActivityOverview/actions/companyActivityOverview";
import { fetchCompanyDetails } from "@store/CompanyActivityOverview/actions/companyActivityOverview";

//Helpers
import useSelectList from "@helper/hooks/query/useSelectList";
import { SelectListEnum } from "@helper/hooks/query/SelectListEnum";

//Style
import style from "./ActivityOverview.module.scss";

//Components
import CompanyDetails from "./components/CompanyDetails";
import StagesStats from "./components/StagesStats";
import EventTimeline from "./components/EventTimeline";
import ProspectsEngaged from "./components/ProspectsEngaged";
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import PageContentWrapper from "@components/PageContentWrapper/PageContentWrapper";

const CompanyActivityOverview = (props) => {
  const { name } = props.companyDetails;
  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  let { companyId } = useParams();
  if (companyId == null) companyId = 0;

  const [activeTab, setActiveTab] = useState(1);
  const [params, setParams] = useState(null);

  useEffect(() => {
    props.fetchCompanyDetails(companyId);
    props.fetchProspectsStatuses(companyId);
  }, [companyId]);

  const stagesStats = <StagesStats setFilter={props.setFilter} clearFilter={props.clearFilter} />;

  let selectRowOptions = [
    {
      option: campaignsList,
      change: (c) => [filterData("Campaign", c, props.setFilter)],
      placeholder: "Campaigns",
      value: getFilterValue(params?.filter, "Campaign"),
    },
  ];

  return (
    <PageContentWrapper className={style.contentWrapper}>
      <HeaderTitle title={name ? name : ""} />
      <TableFiltersRow
        headerClassName={style.headerWrapper}
        rightContent={stagesStats}
        classLeftWrapper={style.selectWrapper}
        classRightWrapper={style.cardsWrapper}
        selects={selectRowOptions}
        showPerPage={false}
      />
      <div className="flex">
        <div className={`${style.tabs} relative`}>
          <Tabs className="custom_tabs" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="1" title="Activity Timeline">
              <EventTimeline setParams={setParams} id={companyId} />
            </Tab>
            <Tab eventKey="2" title="Prospects">
              <ProspectsEngaged id={companyId} />
            </Tab>
          </Tabs>
        </div>
        <div className={`${style.companyDetails}`}>
          <CompanyDetails companyDetails={props.companyDetails} />
        </div>
      </div>
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.companyActivityOverview.statuses,
    companyDetails: state.companyActivityOverview.companyDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    fetchProspectsStatuses: (companyId) => dispatch(fetchProspectsStatuses(companyId)),
    fetchCompanyDetails: (companyId) => dispatch(fetchCompanyDetails(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyActivityOverview);
