import React from "react";
import { Link } from "react-router-dom";

import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
//Styles
import styles from "./EmailAccount.module.scss";

//Images & Icons
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

//Components
import UserPhoto from "../../../../../components/UI/UserPhoto/UserPhoto";

const EmailAccount = (props) => {
  let deleteIcon = null;
  if (props.removeEmailAccount != null) {
    deleteIcon = (
      <span className="flex flex1 justify_end" key={props.key ?? ""}>
        <CloseLineIcon size={14} onClick={props.removeEmailAccount} className="mR5 cursor_pointer" />
      </span>
    );
  }
  let secondNameFirstLetter = "";
  let firstName = "";
  if (props?.userFullName) {
    const fullNameParts = props?.userFullName?.split(" ");
    firstName = fullNameParts[0];
    secondNameFirstLetter = fullNameParts.length > 1 ? fullNameParts[1].charAt(0) : "";
  }

  return (
    <div className={`${styles.detailsWrapper} ${props.cardClass}`}>
      <div className={styles.imageAccount}>
        {props.showUserPhoto && <UserPhoto name={props.fullName} src={props.imageUrl} size="medium" />}
        {props.usedInOtherCampaigns ? <p className={styles.inAnotherCampaign}> used in another campaign</p> : null}
        {deleteIcon}
      </div>
      <div className={styles.accountDetails}>
        <div className="flex">
          <div className={`${styles.userAccountDetails} textOverflow flex2`}>
            <div className="flex align-center mT5">
              <OverlayTrigger
                key={props.emailName}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-active`}>
                    <Popover.Body>{props.isConnected ? "Connected" : "Disconnected"}</Popover.Body>
                  </Popover>
                }
              >
                <span className={`${styles.status} ${props.isConnected ? styles.connectStatus : styles.notConnectedStatus}`}></span>
              </OverlayTrigger>
              <p className={styles.accountOwner}>
                {props.emailName} <span className={styles.ownerEmail}>/ User: {`${firstName} ${secondNameFirstLetter}.`}</span>
              </p>
            </div>
            <p className={styles.ownerEmail}>{props.email}</p>
            {props.isSuspended && (
              <>
                <OverlayTrigger
                  key={"key"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`popover-tooltip`}>
                      <Popover.Body>Your account has been suspended by Outbase. Please contact customer support.</Popover.Body>
                    </Popover>
                  }
                >
                  <Badge pill bg="danger" className="mL10 f12">
                    Suspended
                  </Badge>
                </OverlayTrigger>
              </>
            )}

            {props.emailAccountDetailsView ? (
              <div className={`${styles.usedEmails}`}>
                <p className={`${styles.perDay} ${props.removeEmailAccount != null ? "pR20" : null}`}>
                  <span> New Prospects: </span> {!Number.isNaN(props.messagesPerDay) ? props.messagesPerDay : 0}
                  /day
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {!props.hasSignature ? (
          <div className={styles.signatureWrapper}>
            <Edit2LineIcon color="var(--info-color)" size={18} />
            <Link
              to={{
                pathname: "/" + props.orgId + "/email-accounts/" + props.emailAccountId + "/edit",
              }}
              className={`text_decoration_none ${styles.addSignatureLink}`}
            >
              Add email signature.
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmailAccount;
