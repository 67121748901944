import React from "react";
import Accordion from "../../../components/UI/Accordion/Accordion";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import ActionDelete from "../../../components/UI/ActionWrapper/Actions/ActionDelete";
import ActionEdit from "../../../components/UI/ActionWrapper/Actions/ActionEdit";
import Permission from "../../../components/auth/Permission";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { useDeleteQuestionMutation } from "../../../api/complianceApi";
import styles from "./QuestionsAndAnswers.module.scss";
import { Question } from "../../../models/compliance/Question";
import { Cell } from "styled-css-grid";

interface QuestionAccordionProps {
  question: Question;
  isCustom: boolean;
  updateQuestion?: (question: Question) => void;
  deleteQuestion?: (id: number) => void;
}

const QuestionAccordion: React.FC<QuestionAccordionProps> = ({ question, isCustom, updateQuestion, deleteQuestion }) => {
  const htmlContent = question.answer;

  return (
    <Cell key={question.id} style={{ position: "relative" }}>
      <Accordion
        classes={isCustom ? styles.accordionWrapper : undefined}
        variant="whiteTransparent"
        header={question.question}
        body={
          <div className="mB15">
            <span className={styles.categoryTitle}>
              <p className="font700 mB0 mR5">Category:</p>{" "}
              {question.category.map((c, index) => (
                <span className="mR5" key={index}>
                  {c}
                </span>
              ))}
            </span>{" "}
            <p className={styles.dangerouslySetInnerHTML} dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        }
        eventKey="question"
        noCaret={true}
      />
      {isCustom && (
        <div className={styles.buttonsWrapper}>
          <Permission has={PERMISSIONS.compliance.questionsAndAnswers.edit}>
            <ActionWrapper wrapperClass={styles.actionButton}>
              <ActionEdit onClick={() => updateQuestion && updateQuestion(question)} />
            </ActionWrapper>
          </Permission>

          <Permission has={PERMISSIONS.compliance.questionsAndAnswers.delete}>
            <ActionWrapper wrapperClass={styles.actionButton}>
              <ActionDelete classNameIcon="deleteICon" onClick={() => deleteQuestion && deleteQuestion(question.id)} />
            </ActionWrapper>
          </Permission>
        </div>
      )}
    </Cell>
  );
};

export default QuestionAccordion;
