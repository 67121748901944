import React, { useEffect } from "react";

//Images & Icons
import MailFillIcon from "remixicon-react/MailFillIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";

//Styles
import styles from "./CampaignSummary.module.scss";

const CampaignSummary = (props) => {
  return (
    <div className={`flex_center flexCol ${styles.campaignSummary}`}>
      <h1 className="f16 font700 mB15">Your campaign summary</h1>
      <p className={`f14 secondary_text_color textCenter mB30 ${styles.description}`}>
        We recommend starting with a 4 email sequence - here is one we've created for you:
      </p>
      <div className={`flex ${styles.stepsSequence}`}>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <MailFillIcon size={20} className={styles.emailIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Introduction Email</span>
          <span className={`f13 ${styles.stepDescription}`}>Initial Email</span>
        </p>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <TimeLineIcon size={20} className={styles.waitIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Wait For</span>
          <span className={`f13 ${styles.stepDescription}`}>6 days</span>
        </p>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <MailFillIcon size={20} className={styles.emailIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Follow up 1</span>
          <span className={`f13 ${styles.stepDescription}`}>Quick Nudge</span>
        </p>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <TimeLineIcon size={20} className={styles.waitIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Wait For</span>
          <span className={`f13 ${styles.stepDescription}`}>6 days</span>
        </p>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <MailFillIcon size={20} className={styles.emailIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Follow up 2</span>
          <span className={`f13 ${styles.stepDescription}`}>Referral</span>
        </p>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <TimeLineIcon size={20} className={styles.waitIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Wait For</span>
          <span className={`f13 ${styles.stepDescription}`}>6 days</span>
        </p>
        <p className={`textCenter m0 ${styles.iconStepWrapper}`}>
          <MailFillIcon size={20} className={styles.emailIcon} />
          <span className={`f13 ${styles.stepTitle}`}>Follow up 3</span>
          <span className={`f13 ${styles.stepDescription}`}>Sign-Off</span>
        </p>
      </div>
      <div className={`flex_column center_flex ${styles.moreDetails}`}>
        <p className={`textCenter f14 ${styles.scheduleInfoText}`}>
          Based on our years of prospecting experience we have put together the recommended sending schedule and daily volume of prospects
          for your campaign:
        </p>
        <div className={`flex_column center_flex mB30 ${styles.schedule}`}>
          <p className="mB10 f13">50 new prospects / day</p>
          <hr />
          <p className="m0 f13">Monday to Friday (9AM - 5PM)</p>
        </div>
        <p className={`textCenter f14 ${styles.scheduleInfoText}`}>
          All of these can be edited later, in the configure section of your campaign.
        </p>
      </div>
    </div>
  );
};

export default CampaignSummary;
