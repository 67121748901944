import React from "react";
import Loading from "../../pages/Loading/Loading";
import { useAuth } from "react-oidc-context";
import { useEffectOnce } from "react-use";

// Only showing callback because onSigninCallback event will kick in the redirect
export const Impersonate = (props) => {
  const auth = useAuth();

  useEffectOnce(() => {
    auth.removeUser().then(() => {
      auth.signinSilent().then((user) => {
        props.history.push("/");
      });
    });
  });

  return <Loading></Loading>;
};
