import styles from "../Filters.module.scss";
import { useGetNaicsCodesQuery, useGetSicCodesQuery } from "@api/audienceExplorerApi";
import { NaicsAndSicCodesType, SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import AlertNotification from "@ui/AlertNotification/AlertNotification";
import FormikSelect from "@ui/Formik/FormikSelect";
import RadioButton from "@ui/RadioButton/RadioButton";
import { useFormikContext } from "formik";

const FilterCompanyNaicsAndSicCodes = () => {
  const {
    values: {
      company: {
        naicsAndSicCodes: { type, naicsCodes, sicCodes },
        industries,
      },
    },
  } = useFormikContext<SearchModel>();
  const listNaicsCodes = useGetNaicsCodesQuery();
  const listSicCodes = useGetSicCodesQuery();

  return (
    <>
      {industries.length > 0 && (
        <p>
          <AlertNotification variant="info_alert" alertClassName={styles.info_banner} dismissible="dismissible">
            To continue with searching by NAICS or SIC Codes, please clear the existing Industry filters to ensure compatibility and
            accurate results.
          </AlertNotification>
        </p>
      )}
      <div className="flex">
        <RadioButton
          selectedValue={type}
          id="Naics"
          labelText="NAICS"
          value={NaicsAndSicCodesType.Naics}
          name="company.naicsAndSicCodes.type"
        />
        <RadioButton selectedValue={type} id="Sic" labelText="SIC" value={NaicsAndSicCodesType.Sic} name="company.naicsAndSicCodes.type" />
      </div>
      {type == NaicsAndSicCodesType.Naics ? (
        <FormikSelect
          menuId="naicsCodes"
          name="company.naicsAndSicCodes.naicsCodes"
          value={naicsCodes}
          options={listNaicsCodes && listNaicsCodes.data ? listNaicsCodes.data : []}
          displayName="Select NAICS Codes"
          dropdownButtonClass={styles.selectDropdown}
          disabled={industries.length > 0}
          allowIncludeExclude
          enableReinitialize
          isAutoComplete
          isMulti
          isSearchable
          showValuesOutside
          hideSelectedOptions={false}
        />
      ) : (
        <FormikSelect
          menuId="sicCodes"
          name="company.naicsAndSicCodes.sicCodes"
          value={sicCodes}
          options={listSicCodes && listSicCodes.data ? listSicCodes.data : []}
          displayName="Select SIC Codes"
          dropdownButtonClass={styles.selectDropdown}
          disabled={industries.length > 0}
          allowIncludeExclude
          enableReinitialize
          isAutoComplete
          isMulti
          isSearchable
          showValuesOutside
          hideSelectedOptions={false}
        />
      )}
    </>
  );
};

export default FilterCompanyNaicsAndSicCodes;
