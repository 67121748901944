import React from "react";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./SideMenuContent.module.scss";

//Images & Icons
import { ArrowDropDown } from "@mui/icons-material";

const MenuItem = (props) => {
  let content = (
    <li
      id={"menuItem" + props.title.replaceAll(" ", "")}
      className={`${props.isMenuOpened(props.url) ? styles.dropLiActive : styles.dropLi} dropCloseLi`}
    >
      <NavLink className={styles.navItem} to={props.url}>
        {props.isMenuOpened(props.url) ? props.iconActive : props.iconInactive}
        <span className={`${styles.menuItemName} closeSidebarNone`}>{props.title}</span>
      </NavLink>
    </li>
  );

  if (props.children != null) {
    let classes = `${
      props.isMenuOpened(props.url) ? `${styles.dropLiActive} ${styles.menuDropdownOpened} menuDropdownOpened` : styles.dropLi
    } dropCloseLi`;

    content = (
      <li className={classes} onClick={props.onClick} id={"menuItem" + props.title.replaceAll(" ", "")}>
        <span className={styles.navItem}>
          {props.isMenuOpened(props.url) ? props.iconActive : props.iconInactive}
          <span className={`${styles.menuItemName} closeSidebarNone`}>
            {props.title}
            <ArrowDropDown className={`${styles.caretMenuIcon} ${props.open ? styles.caretMenuIconRotate : ""}`} />
          </span>
        </span>
        <ul className={styles.menuDropdown}>{props.children}</ul>
      </li>
    );
  }

  return content;
};

export default MenuItem;
