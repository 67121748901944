import React from "react";
import { NavLink, useParams } from "react-router-dom";

//Styles
import styles from "./NoPermissionOrganizationAccessRemoved.module.scss";

//Images & Icons
import Logo from "../../assets/Logo/Logo";
import DisabledAccessImage from "../../assets/Images/DisabledAccessImage/DisabledAccessImage";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import LoggedInUser from "../../components/Header/LoggedInUser/LoggedInUser";
import Button from "../../components/UI/Button/Button";
import EmptyState from "../../components/UI/EmptyState/EmptyState";

const NoPermissionOrganizationAccessRemoved = () => {
  let user = <LoggedInUser orgPage={true} />;

  let logo = (
    <div className={styles.logoDetails}>
      <Logo />
    </div>
  );

  let navLinkUrl = "/my-organizations";

  let image = <DisabledAccessImage />;

  let content = (
    <div className="textCenter">
      <p className="mB15">This organization is disabled by the system admin.</p>
      <NavLink className="text_decoration_none" to={navLinkUrl}>
        <Button id="goToOrg" classes={styles.orgBtn} variant="secondary">
          Go to your organizations view
        </Button>
      </NavLink>
    </div>
  );

  return (
    <div>
      <HeaderTitle title={logo} rightContent={user} />
      <div className={styles.organizationPickerWrapper}>
        <div className={styles.wrap}>
          <EmptyState componentClass="positionCenter" image={image} content={content} />
        </div>
      </div>
    </div>
  );
};

export default NoPermissionOrganizationAccessRemoved;
