import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";

//Redux
import {
  fetchDomainExclusions,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
} from "../../../store/Exclusions/Table/actions/domainExclusions";

//Helper
import { PERMISSIONS } from "../../../helper/permissionConstants";
import api, { API } from "../../../helper/api/api";
import { useOrgDateTimeFormat } from "../../../helper/hooks/useOrgDateTimeFormat";
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";
import { numberOfRecords } from "../../../helper/tableConstants";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { toastMessages } from "../../../helper/toastMessagesConstants";

// Style
import styles from "../Exclusions.module.scss";

//Images & Icons
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";
import EmptyData from "../../../assets/Images/EmptyData/EmptyData";

// Components
import Permission from "../../../components/auth/Permission";
import { columns } from "./columns";
import DeleteExclusionsForm from "../DeleteExclusionsForm";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import { exclusionType } from "../exclusionType";
import Table from "../../../components/DataTable/Table";
import Button from "../../../components/UI/Button/Button";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import ActionDelete from "../../../components/UI/ActionWrapper/Actions/ActionDelete";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const DomainExclusions = (props) => {
  let longDateFormat = useOrgDateTimeFormat();
  const { params, rows, fetchDomainExclusions, initiate, isInitiated, clearInitiated } = props;
  const records = numberOfRecords[1];
  const [tableColumns] = useState([...columns]);
  const [tableRows, setTableRows] = useState([]);
  let separators = useGroupDecimalSeparator();
  let totalCountFormatted = formatNumberHelper(props.totalCount, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  let image = <EmptyData />;

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "Type", value: [exclusionType.Domain] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchDomainExclusions(params);
      props.setParamsForExclusions(null, params);
    }
  }, [params, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const exportCSV = () => {
    const exportParams = _.cloneDeep(params);
    exportParams.length = props.totalCount;

    api(API.exclusions.export, exportParams, "blob")
      .then((res) => {
        toast.success(toastMessages.exclusions.successExportDomainExclusions);
        fileDownload("Domain_exclusions.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [showDeleteExclusionsModal, setShowDeleteExclusionsModal] = useState(false);
  const [exclusionDeleteId, setExclusionDeleteId] = useState(0);
  const handleDeleteExclusionsClose = () => setShowDeleteExclusionsModal(false);
  const handleDeleteExclusionsShow = (exclusion) => {
    setExclusionDeleteId(exclusion.id);
    setShowDeleteExclusionsModal(true);
  };

  const [exclusionsSortBy, setExclusionsSort] = useState("Sort By");
  const handleSortExclusionsChange = (eventKey, event) => {
    setExclusionsSort(event.currentTarget.text);

    const sortSplit = eventKey.split(" ");
    const sort = {
      column: sortSplit[0],
      direction: sortSplit[1].toLowerCase(),
    };
    props.sortData(sort);
  };

  let table = null;

  if (props.error) {
    table = <h2>Error getting Exclusions</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      r.domain = (
        <OverlayTrigger
          key={r.domain}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.domain}`}>
              <Popover.Body>{r.domain}</Popover.Body>
            </Popover>
          }
        >
          <div className="textOverflow">{r.domain}</div>
        </OverlayTrigger>
      );
      r.exclusion = r.domain;
      r.actions = (
        <ActionWrapper>
          <Permission has={PERMISSIONS.exclusion.delete}>
            <ActionDelete onClick={() => handleDeleteExclusionsShow(r)} />
          </Permission>
        </ActionWrapper>
      );
      r.createdDate = Moment(r.createdDate).format(longDateFormat);
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  if (props.isLoaded && isInitiated) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"DOMAIN EXCLUSIONS"}
          columns={tableColumns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={params}
          tableClassName={styles.exclusionTable}
          tableFooterClass={styles.tableFooter}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          scrollY={true}
          variant="small"
          tableHeight={390}
          hideLabelShow={true}
        />
      ) : (
        <EmptyState image={image} title="No Domain Exclusions Found" content="" />
      );
  }

  return (
    <div className={`${styles.domainExclusionsPanel} ${styles.panel} exclusionsPanel`}>
      <div className={`${styles.exclusionInfo} flex flexCol flex center_flex`}>
        <h1 className={styles.numberExclusions}>{totalCountFormatted}</h1>
        <p className="flex flex_center">
          <span className={styles.greenCircle} />
          Domain Exclusions
        </p>
      </div>
      <div className={`${styles.buttonsWrapper} justify_end`}>
        <Permission has={PERMISSIONS.exclusion.export}>
          <Button
            id="exportDomainExclusions"
            onClick={exportCSV}
            variant="secondary-m"
            classes="mR10 m-s-min-width-auto"
            disabled={tableRows?.length === 0}
            ariaLabel="Export"
          >
            <ExternalLinkLineIcon size={20} className="iconGray500" />
            <span className="m-s-hide mL5">Export</span>
          </Button>
        </Permission>
        <Permission has={PERMISSIONS.exclusion.create}>
          <Button
            id="addDomainExclusions"
            variant="primary-m"
            classes="m-s-min-width-auto"
            title="Add domain exclusions"
            onClick={() => {
              props.setParamsForExclusions(null, params);
              props.handleNewExclusionsShow();
            }}
          >
            <AddCircleLineIcon className="iconWhite" size={19} />
            <span className="m-s-hide mL5">Add</span>
          </Button>
        </Permission>
      </div>
      {table}

      <DeleteExclusionsForm
        handleClose={handleDeleteExclusionsClose}
        showModal={showDeleteExclusionsModal}
        exclusionType={exclusionType}
        exclusionId={exclusionDeleteId}
        getExclusions={() => fetchDomainExclusions(params)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.domainExclusions.params,
    isInitiated: state.domainExclusions.isInitiated,
    isLoading: state.domainExclusions.isLoading,
    isLoaded: state.domainExclusions.isLoaded,
    error: state.domainExclusions.error,
    rows: state.domainExclusions.rows,
    totalCount: state.domainExclusions.totalCount,
    pageCount: state.domainExclusions.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDomainExclusions: (param) => dispatch(fetchDomainExclusions(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainExclusions);
