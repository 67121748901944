import TableHeaderSort from "@ui/TableHeaderSort/TableHeaderSort";
import { OverlayTrigger, Popover } from "react-bootstrap";
import TableColumnFilters from "./TableColumnFilters/TableColumnFilters";
import Checkbox from "../UI/Checkbox/Checkbox";
import "./Table.scss";

import "./Table.module.scss";

//Images & Icons
import Ascending from "@icons/Ascending/Ascending.svg";
import Descending from "@icons/Descending/Descending.svg";
import NeutralSort from "@icons/NeutralSort/NeutralSort.svg";
import { ColumnDefinition } from "./DataTable";
import { DTSort } from "@models/dataTables/DtSort";
import { DTParameters } from "@models/dataTables/DtParameters";

interface CustomColumnHeaderProps {
  columnDefinition: ColumnDefinition;
  params: DTParameters;

  hideSort?: boolean;
  selectAllRows?: () => void;
  allRowsSelected?: boolean;
  sortHandler?: (field: string) => void;
  sort?: DTSort;
  setFilter: (type: string, value: any[]) => void;
}

const CustomColumnHeader = (props: CustomColumnHeaderProps) => {
  const { hideSort, selectAllRows, allRowsSelected, sortHandler, sort = { column: "", direction: "" }, params } = props;
  const column = props.columnDefinition;

  let className = "width_100 ";
  if (column.type != null) {
    switch (column.type) {
      case "text":
        className += " grid-col-text-default";
        break;
      case "number":
        className += " grid-col-number-default";
        break;
      case "date":
        className += " grid-col-date-default";
        break;
      case "actions":
        className += " grid-col-actions-default";
        break;
      default:
        break;
    }
  }

  if (column.columnClass != null) {
    className += " " + column.columnClass;
  }

  const labelClick = () => {
    if (column.sort != null && sortHandler) {
      sortHandler(column.field);
    }
  };

  let label = (
    <div className={`${className}`}>
      <p className="textOverflow m0"> {column.label}</p>
    </div>
  );

  if (column.sort === "asc" || column.sort === "desc") {
    if (sort != null && sort.column === column.field) {
      if (sort.direction === "asc") {
        label = <TableHeaderSort name={label} clickHandler={labelClick} img={hideSort ? null : Ascending} />;
      } else {
        label = <TableHeaderSort name={label} clickHandler={labelClick} img={hideSort ? null : Descending} />;
      }
    } else {
      label = <TableHeaderSort name={label} clickHandler={labelClick} img={hideSort ? null : NeutralSort} />;
    }
  }
  if (column.searchable) {
    label = (
      <>
        {label}
        <TableColumnFilters setFilter={props.setFilter} column={column} params={params} />
      </>
    );
  }

  if (column.field === "checkbox" && selectAllRows) {
    label = (
      <OverlayTrigger
        key={`tooltip-table-select-all`}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-table-select-all-id`}>
            <Popover.Body>{column.tooltipMessage}</Popover.Body>
          </Popover>
        }
      >
        <div className="width_100 select_all_checkbox_wrapper">
          <Checkbox
            fromDataTable={true}
            onlyCheckbox={true}
            onChange={selectAllRows}
            checked={allRowsSelected}
            checkBoxClass="select_all_checkbox"
          />
        </div>
      </OverlayTrigger>
    );
  }

  return label;
};

export default CustomColumnHeader;
