import React from "react";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./NoPermissionUpdatePackage.module.scss";

//Images & Icons
import Logo from "../../assets/Logo/Logo";
import NoPermissionsImage from "../../assets/Images/NoPermissionsImage/NoPermissionsImage";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import LoggedInUser from "../../components/Header/LoggedInUser/LoggedInUser";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import Button from "../../components/UI/Button/Button";

const NoPermissionUpdatePackage = () => {
  let user = <LoggedInUser orgPage={true} />;

  let navLinkUrl = "/my-organizations";

  let image = <NoPermissionsImage />;

  let content = (
    <div className="textCenter">
      <p className="mB15">Your subscription has expired, contact your admin to renew your subscription</p>
      <NavLink className="text_decoration_none" to={navLinkUrl}>
        <Button id="goToOrgBtn" classes={styles.actionButton} variant="secondary">
          Go to your organizations view
        </Button>
      </NavLink>
    </div>
  );

  let logo = (
    <div className={styles.logoDetails}>
      <Logo />
    </div>
  );

  return (
    <>
      <HeaderTitle title={logo} rightContent={user} />
      <div className={styles.organizationPickerWrapper}>
        <div className={styles.wrap}>
          <EmptyState componentClass="positionCenter" image={image} content={content} />
        </div>
      </div>
    </>
  );
};

export default NoPermissionUpdatePackage;
