import React, { FC } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Action from "../Action";
import CoinsLineIcon from "remixicon-react/CoinsLineIcon";

interface ActionCreditAdjustmentProps {
  className?: string;
  classNameIcon?: string;
  size?: number;
}

const ActionCreditAdjustment: FC<ActionCreditAdjustmentProps> = ({ className, classNameIcon = "iconGray", size = 18, ...props }) => {
  return (
    <Action
      className={className}
      materialIcon={
        <OverlayTrigger
          key={"credit-adjustment"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-credit-adjustment`}>
              <Popover.Body>Credit adjustment</Popover.Body>
            </Popover>
          }
        >
          <div>
            <CoinsLineIcon size={size} className={classNameIcon} />
          </div>
        </OverlayTrigger>
      }
      {...props}
    />
  );
};

export default ActionCreditAdjustment;
