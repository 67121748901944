import React from "react";
import { connect } from "react-redux";

//Styles
import styles from "./LoggedInUser.module.scss";

//Images & Icons
import { ArrowDropDown } from "@mui/icons-material";

//Components
import AccountDetails from "../AccountDetails/AccountDetails";
import UserPhoto from "../../UI/UserPhoto/UserPhoto";

interface User {
  firstName: string;
  imageUrl: string;
}

interface History {
  length: number;
  action: string;
  location: {
    pathname: string;
    search: string;
    hash: string;
    state: any;
    key: string;
  };
}

interface Props {
  user: User;
  orgPage?: boolean;
  history: History;
}

const LoggedInUser: React.FC<Props> = ({ user, orgPage, history }) => {
  const { firstName, imageUrl } = user;
  // const isLongName = firstName && firstName.length > 16;

  const name = orgPage ? (
    <span className={styles.username}>
      Hello, <span className={styles.firstName}>{firstName}</span>
    </span>
  ) : (
    <span className={styles.username}>{firstName}</span>
  );

  return (
    <div className={styles.accountDetails} id="userDropdownMenu">
      <div className={styles.btnAccountDetails}>
        <UserPhoto size="medium" src={imageUrl} alt="profile-img" />

        <span className={styles.userName}>{name}</span>
        <ArrowDropDown />
      </div>

      <AccountDetails customStyle={styles.accountDetailsDropdown} history={history} />
    </div>
  );
};

interface State {
  user: User;
}

const mapStateToProps = (state: State) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(LoggedInUser);
