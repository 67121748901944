import React from "react";
import Moment from "moment";

// Helpers
import { useOrgDateFormat } from "../../../../helper/hooks/useOrgDateFormat";

// State & API
import { useSlice } from "@hooks/useSlice";
import { useGetScheduleQuery } from "@api/sendingScheduleApi";
import { RootState } from "@store/configureStore";
import { useSelector } from "react-redux";

// Images & Icons
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";

// Styles
import styles from "./SendingSchedule.module.scss";

// Components
import SendDays from "./SendDays/SendDays";
import Spinner from "@ui/Spinner/Spinner";

const SendingSchedule: React.FC = () => {
  const campaign = useSlice((state) => state.campaign.campaign);
  const { data: schedule, isLoading, isSuccess } = useGetScheduleQuery(campaign.id, { refetchOnMountOrArgChange: true });
  const dateFormat = useOrgDateFormat();

  const pauseCountries = useSelector((state: RootState) => {
    const countryIds = schedule?.targetedCountries;
    if (countryIds && state.global.globalSettings?.countries) {
      const countryLabels = countryIds
        .map((id: string) => {
          // Explicitly defining the type of 'id' as string
          const country = state.global.globalSettings?.countries.find((c: { value: string }) => c.value === id);
          return country ? country.label : null;
        })
        .filter((label: string | null) => label !== null) as string[];

      return countryLabels.length > 0 ? `${countryLabels.join(", ")}.` : "";
    }
    return "";
  });

  let date = null;

  if (isLoading) {
    date = <Spinner />;
  }

  if (isSuccess && schedule && schedule.startDate != null) {
    date = Moment(schedule.startDate).format(dateFormat);
  }

  return (
    <>
      <div className={styles.scheduleWrapper}>
        <div className={`${styles.scheduleFormWrapper} ${styles.scheduleTimeDate}`}>
          <p className={styles.label}>
            New prospects engaged: <span className={styles.dataStyle}>{schedule?.prospectsPerDay} per day</span>
          </p>
          <p className={styles.lineSeparator}>|</p>
          <p className={styles.label}>
            Max prospects per Company: <span className={styles.dataStyle}>{schedule?.maxProspectsPerCompany} per day</span>
          </p>
        </div>
        <p className={styles.sectionLabel}>Weekly schedule:</p>
        <div className="flex">
          <div className={styles.card}>
            <p className={styles.infoDataLabel}>Schedule Start Date:</p>
            <div className="flex mB15 align-center">
              <CalendarLineIcon className={styles.iconCalendar} size={16} />
              <p className={styles.data}>{date}</p>
            </div>
            <p className={styles.labelTime}>Time Zone:</p>
            <p className={styles.data}>{schedule?.timeZoneModel.name}</p>
          </div>
          <div className={`${styles.card} mL10 mR10 flex`}>{schedule && <SendDays schedule={schedule!} />}</div>
          <div className={styles.card}>
            <p className={styles.infoDataLabel}>Public holidays (current month):</p>
            <p className={styles.data}>
              <b className={styles.countriesList}>{pauseCountries}</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendingSchedule;
