import React from "react";
import { Formik, Form } from "formik";

//Style
import styles from "./SuccessfullyPurchasedTemplateForm.module.scss";

//Images & Icons
import SuccessTemplateImage from "../../../../../assets/Images/SuccessTemplateImage/SuccessTemplateImage";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import EmptyState from "../../../../../components/UI/EmptyState/EmptyState";

const SuccessfullyPurchasedTemplateForm = (props) => {
  let image = <SuccessTemplateImage />;

  return (
    <Modal
      title=""
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton={true}
      saveButtonText="OK"
      dialogClassName={styles.successTemplateModal}
    >
      <EmptyState componentClass={styles.image} image={image} content="" />
      <div className={styles.modalBody}>
        <h1>Order successful!</h1>
        <p>Your order is already winging it’s way to our creative team for them to start carefully creating your template.</p>
        {/* <p>{props.currencySign} {props.price}</p> */}
        <p> We’ll notify you by email as soon as your template is ready – order delivery time is 2 working days.</p>
      </div>
    </Modal>
  );
};

export default SuccessfullyPurchasedTemplateForm;
