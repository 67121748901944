import React, { FC } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./CRMIntegrations.module.scss";

//Components
import AntSwitch from "../../../../components/UI/AntSwitch/Switch";
import useRouteParams from "@hooks/useRouteParams";
import { useToggleCRMMutation } from "@api/campaignApi";
import { ToggleCrmModel } from "@models/campaign/ToggleCrmModel";
import { useSlice } from "@hooks/useSlice";

const CRMIntegrations = () => {
  const { campaignId, organizationId } = useRouteParams();
  const { campaignIntegrations } = useSlice((state) => state.campaign.campaign);
  const [toggleCrm] = useToggleCRMMutation();

  const onChangeIntegrationEnabled = (integrationId: number, value: boolean) => {
    const data: ToggleCrmModel = {
      campaignId: campaignId,
      crmId: integrationId,
      isEnabled: value,
    };

    toggleCrm(data)
      .unwrap()
      .catch((error: Error) => toast.error(error.message));
  };

  return (
    <div className={styles.connectCRMWrapper}>
      <div className={styles.crmList}>
        <span className={styles.itemList}>Connect CRM:</span>
        {campaignIntegrations && campaignIntegrations.length > 0
          ? campaignIntegrations.map((element, index) => {
              return (
                <div className={styles.itemList} key={index}>
                  <NavLink
                    to={{
                      pathname: "/" + organizationId + "/crm",
                    }}
                    className={`${styles.label} f12`}
                  >
                    HubSpot
                  </NavLink>
                  <AntSwitch
                    checked={element.isEnabled}
                    onChange={(e) => onChangeIntegrationEnabled(element.integrationId, e.currentTarget.checked)}
                  />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default CRMIntegrations;
