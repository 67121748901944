import React, { useEffect } from "react";
import { connect } from "react-redux";

//Redux
import {
  fetchCampaigns,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
} from "../../../store/Campaigns/Table/actions/campaigns";

//Images
import EmptyEmailAccount from "../../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";

//Components
import Table from "../../../components/DataTable/Table";
import { columns } from "./columns";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const CampaignTable = (props) => {
  const { param, rows, fetchCampaigns, isInitiated } = props;
  let image = <EmptyEmailAccount />;

  useEffect(() => {
    if (isInitiated) {
      fetchCampaigns(param);
    }
  }, [param]);

  let table = null;

  if (props.error) {
    table = <h2 className="f18 textCenter">Error getting Campaigns</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table =
      rows.length > 0 ? (
        <Table
          tableName={"Campaigns"}
          columns={columns}
          rows={rows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          scrollY={true}
          tableHeight={260}
          variant="small"
        />
      ) : (
        <EmptyState image={image} title="No campaigns found" />
      );
  }
  return <>{table}</>;
};

const mapStateToProps = (state) => {
  return {
    param: state.campaigns.params,
    isInitiated: state.campaigns.isInitiated,
    isLoading: state.campaigns.isLoading,
    isLoaded: state.campaigns.isLoaded,
    error: state.campaigns.error,
    rows: state.campaigns.rows,
    totalCount: state.campaigns.totalCount,
    pageCount: state.campaigns.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaigns: (param) => dispatch(fetchCampaigns(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignTable);
