import { FC, useState } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { useGetTargetingInfoQuery } from "@api/audienceApi";
import { AudienceDetailsModel } from "@models/audience/AudienceDetailsModel";
import { AudienceTypeEnum } from "@models/audience/AudienceTypeEnum";

//Helpers
import { audienceTypeEnum } from "@helper/enums/audienceTypeEnum";
import { useGroupDecimalSeparator } from "@helper/hooks/useGroupDecimalSeparator";
import { useSlice } from "@hooks/useSlice";
import { useOrgDateFormat } from "@hooks/useOrgDateFormat";
import { useOrganizationId } from "@hooks/useOrganizationId";

//Images & Icons
import Focus3LineIcon from "remixicon-react/Focus3LineIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";

//Styles
import styles from "./AudienceCard.module.scss";

//Components
import AudienceTargetingDrawer from "@pages/AudienceContacts/AudienceTargetingDrawer/AudienceTargetingDrawer";
import TargetingCriteriaColumn from "@pages/Audiences/AudiencesTableComponents/TargetingCriteriaColumn/TargetingCriteriaColumn";
import ContactCards from "@pages/AudienceContacts/ContactCards/ContactCards";
import DownloadCloud2LineIcon from "remixicon-react/DownloadCloud2LineIcon";
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import Spinner from "@ui/Spinner/Spinner";
import Button from "@ui/Button/Button";
interface AudienceCardProps {
  audience: AudienceDetailsModel;
  pushToCampaignShow: (audience: AudienceDetailsModel) => void;
  audienceUploadModalShow: (audience: AudienceDetailsModel) => void;
  audienceDownload: (audience: AudienceDetailsModel) => void;
}

const AudienceCard: FC<AudienceCardProps> = ({ audience, audienceUploadModalShow, audienceDownload }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  let separators = useGroupDecimalSeparator();
  const dateFormat = useOrgDateFormat();
  const orgId = useOrganizationId();
  const history = useHistory();
  const campaign = useSlice((state) => state.campaign.campaign);

  // Query
  const { data: audienceTargeting, isLoading } = useGetTargetingInfoQuery(campaign.audienceId || 0, {
    refetchOnMountOrArgChange: true,
    skip: !campaign.audienceId,
  });

  const audienceTargetingDrawer = (
    <AudienceTargetingDrawer
      elementId="targetingCampaignListDrawer"
      audienceName={audience.name}
      isOpen={isDrawerOpen}
      handleCloseDrawer={() => setDrawerOpen(false)}
      searchModel={audience?.searchModel ?? null}
    />
  );

  return (
    <>
      <div className={styles.headerWrapper}>
        <Link
          to={{
            pathname: "/" + orgId + "/list/" + audience.id + "/list-contacts/" + audience.type,
          }}
        >
          <span className={`${styles.listLabel} tableLink`}>{audience.name}</span>
        </Link>
        <div className={styles.actionsWrapper}>
          {audience.type === audienceTypeEnum.Search ? (
            <>
              <Button
                id="edit"
                variant="secondary-l-icon"
                classes={styles.no_border_button}
                title="Edit List"
                onClick={() => history.push(`/${orgId}/search?s=${audience.searchURL}&id=${audience.id}&camId=${audience.campaignId}`)}
              >
                <Edit2LineIcon size={20} className="mR5 iconGray" />
              </Button>
              <Button
                id="targetingIcon"
                variant="secondary-l-icon"
                classes={styles.no_border_button}
                title="Targeting"
                onClick={() => setDrawerOpen(true)}
              >
                <Focus3LineIcon size={20} className="iconGray" />
              </Button>
            </>
          ) : (
            <>
              <Button
                id="upload"
                variant="secondary-l-icon"
                classes={`${styles.no_border_button}`}
                title="Upload"
                onClick={() => audienceUploadModalShow(audience)}
              >
                <UploadCloud2LineIcon size={22} className="iconGray" />
              </Button>
              <Button
                id="download"
                variant="secondary-l-icon"
                classes={styles.no_border_button}
                title="Download"
                onClick={() => {
                  audienceDownload(audience);
                }}
              >
                <DownloadCloud2LineIcon size={22} className="iconGray" />
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="flex">
        <div className={`${styles.actionDetails}`}>
          <div>
            <p className={styles.label}>
              {" "}
              Created by:
              <span>
                {audience.createdBy}{" "}
                {audience.createdDate != null && audience.createdDate !== "" ? moment(audience.createdDate).format(dateFormat) : null}
              </span>
            </p>
            <p className={styles.label}>
              Source:
              <span>
                {audience.type === AudienceTypeEnum.File ? "File" : ""}
                {audience.type === AudienceTypeEnum.Search ? "Search" : ""}
              </span>
            </p>
            <p className={styles.label}>
              Used in Campaigns:
              <span>{audience.campaignName}</span>
            </p>
          </div>
        </div>

        <div className={`${styles.actionDetails} ${styles.usedInCampaignsWrapper} ${styles.targetingWrapper}`}>
          <p className={`${styles.label} ${styles.targetTitle}`}>Target Criteria</p>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <TargetingCriteriaColumn
                targetingWrapper={styles.accordionCard}
                searchModel={audience?.searchModel ?? null}
                maxLength={120}
              />
            </>
          )}
        </div>
        <ContactCards audienceDetails={audience} />
        {audienceTargetingDrawer}
      </div>
    </>
  );
};

export default AudienceCard;
