import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormikInput from "../../../../../components/UI/Formik/FormikInput";

// Redux
import { QueryStatus } from "@reduxjs/toolkit/query";

//Hooks
import { useGetSummaryQuery } from "@api/assistantApi";

//Styles
import styles from "./OrganizationInfoStep.module.scss";
import SearchLineIcon from "remixicon-react/SearchLineIcon";

//Components
import Button from "../../../../../components/UI/Button/Button";
import Spinner from "@ui/Spinner/Spinner";

const OrganizationInfoStep = ({ organizationSettings, setOrganizationSettings, getSummary }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [searching, setSearching] = useState(false);

  let title = "Your company overview";
  let description = (
    <>
      "We weren't able to retrieve information from your website <b>{organizationSettings.companyDescription.websiteURL}</b>. Please provide
      a brief overview of your company and what you do, or search by other domain, so that our AI can craft your personalized email
      templates."
    </>
  );

  if (
    organizationSettings.companyDescription.description ||
    organizationSettings.companyDescription.problemInformation ||
    organizationSettings.companyDescription.productInformation
  ) {
    title = "We have crafted your offering!";
    description = (
      <>
        Using information found on your website <b>{organizationSettings.companyDescription.websiteURL}</b>, we’ve tailored your offering
        overview. <br />
        Review each section before pressing next, or search by different domain.
      </>
    );
  }

  return !searching ? (
    <Formik
      enableReinitialize={true}
      initialValues={{
        websiteURL: organizationSettings?.companyDescription?.websiteURL ?? "",
        description: organizationSettings?.companyDescription?.description ?? "",
        productInformation: organizationSettings?.companyDescription?.productInformation ?? "",
        problemInformation: organizationSettings?.companyDescription?.problemInformation ?? "",
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <div id="orgInfo" className={`flex_center flexCol ${styles.organizationInfo}`}>
              <h1 className="f16 font700 mB15">{title}</h1>
              <p className={`f14 secondary_text_color textCenter ${styles.description}`}>{description}</p>
              <div className={styles.formWrapper}>
                <div className="flex align-center">
                  <FormikInput
                    label="New Website URL:"
                    name="websiteURL"
                    type="text"
                    value={formikProps.values.websiteURL}
                    onChange={(e) => {
                      formikProps.setFieldValue("websiteURL", e.target.value);
                      if (e.target.value != "") setHasChanges(true);
                      else setHasChanges(false);
                    }}
                    inputclass={`${styles.urlInput}`}
                    inputGroupClass="mR15"
                  />
                  <Button
                    id="searchButton"
                    classes={styles.searchButton}
                    type="button"
                    onClick={() => {
                      setSearching(true);
                      getSummary(formikProps.values.websiteURL.replace(/^https?:\/\//i, "")).then(() => {
                        setSearching(false);
                      });
                      setHasChanges(false);
                    }}
                    disabled={!hasChanges}
                  >
                    <SearchLineIcon size={14} color="var(--secondary-color)" />
                  </Button>
                </div>
                <FormikInput
                  label="Description:"
                  name="description"
                  type="textarea"
                  value={formikProps.values.description}
                  onChange={(e) => {
                    formikProps.handleChange(e);
                    setOrganizationSettings({
                      ...organizationSettings,
                      companyDescription: {
                        ...organizationSettings.companyDescription,
                        description: e.target.value,
                      },
                    });
                  }}
                  inputclass={`${styles.description} resizeNone`}
                  inputGroupClass="mB30"
                />
                <div className="flex">
                  <FormikInput
                    label="What is unique about your product or service? "
                    name="productInformation"
                    type="textarea"
                    value={formikProps.values.productInformation}
                    onChange={(e) => {
                      formikProps.handleChange(e);
                      setOrganizationSettings({
                        ...organizationSettings,
                        companyDescription: {
                          ...organizationSettings.companyDescription,
                          productInformation: e.target.value,
                        },
                      });
                    }}
                    inputclass={`${styles.information} resizeNone`}
                    inputGroupClass="mR15"
                  />
                  <FormikInput
                    label="What is the main problem you solve for your customers?"
                    name="problemInformation"
                    type="textarea"
                    value={formikProps.values.problemInformation}
                    onChange={(e) => {
                      formikProps.handleChange(e);
                      setOrganizationSettings({
                        ...organizationSettings,
                        companyDescription: {
                          ...organizationSettings.companyDescription,
                          problemInformation: e.target.value,
                        },
                      });
                    }}
                    inputGroupClass="mL15"
                    inputclass={`${styles.information} resizeNone`}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Spinner
      spinnerClass={styles.spinner}
      description="Sit back and relax as our cutting-edge AI meticulously crafts your captivating website description and creates your compelling unique selling points. This may take up to 30-45 seconds to ensure perfection."
    />
  );
};

export default OrganizationInfoStep;
