import React, { useEffect, useState } from "react";

//Helpers
import api, { API, formUrl } from "../../../helper/api/api";

//Styles
import styles from "./OutbossOrganizationSettings.module.scss";

//Components
import Spinner from "../../../components/UI/Spinner/Spinner";
import Tabs from "../../../components/UI/Tabs/Tabs";
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import NotificationSettings from "./components/NotificationSettings";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";

const OutbossOrganizationSettings = (props) => {
  let orgId = props.match.params.orgId;

  const [data, setData] = useState(null);

  useEffect(() => {
    fetchOrgDetails(orgId);
  }, []);

  const fetchOrgDetails = () => {
    let data = {
      organizationId: orgId,
    };

    api(formUrl(API.organization.getOrganizationDetails, data))
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => console.log(error));
  };

  const tabs = [
    {
      link: "general-settings",
      component: <GeneralSettings {...props} organizationId={orgId} />,
      title: "General",
    },
    {
      link: "team-management",
      title: "Team",
    },
    {
      link: "billing-and-payment",
      title: "Billing & Payment",
    },
    {
      link: "notifications",
      component: <NotificationSettings {...props} organizationId={orgId} />,
      title: "Notifications",
    },
  ];

  let header = data != null ? <HeaderTitle title={"Organization: " + data.on} /> : <Spinner />;

  return (
    <PageContentWrapper className="contentHeight">
      {header}
      <Tabs tabContentClass={styles.organizationTabs} {...props} tabs={tabs} />
    </PageContentWrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default OutbossOrganizationSettings;
