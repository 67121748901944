import { FC } from "react";

//Styles
import styles from "./Sequence.module.scss";

//Images & Icons
import MailFillIcon from "remixicon-react/MailFillIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";
import { CampaignActionTypeEnum } from "@models/workflowSteps/CampaignActionTypeEnum";

interface SequenceStepProps {
  type: CampaignActionTypeEnum;
  details: string;
}

const SequenceStep: FC<SequenceStepProps> = ({ type, details }) => {
  let typeOfStep = null;
  let typeOfStepDescription = null;
  switch (type) {
    case CampaignActionTypeEnum.Email:
      typeOfStep = <MailFillIcon size={30} className={`iconGray500 ${styles.borderSquareIcon}`} />;
      typeOfStepDescription = <span className={styles.typeOfStepDescription}>Email</span>;
      break;
    case CampaignActionTypeEnum.Wait:
      typeOfStep = <TimeLineIcon size={30} className={`iconGray500 ${styles.borderCircleICon}`} />;
      typeOfStepDescription = <span className={styles.typeOfStepDescription}>Wait for:</span>;
      break;
    default:
      break;
  }

  return (
    <div className={styles.fullStepWraper}>
      <div className={styles.stepWraper}>
        <div className={styles.imgWraper}>{typeOfStep}</div>
        <div className={styles.descriptionWraper}>
          {typeOfStepDescription}
          <span className={styles.stepDescription}>{details}</span>
        </div>
      </div>
      {/* <div className={styles.stepLine}></div> */}
    </div>
  );
};

export default SequenceStep;
