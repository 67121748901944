export const dispatchUserReady = (user) => {
  window["outbase"] = window["outbase"] ?? {};
  const event = new Event("outbase.user_ready");
  window["outbase"]["user_meta"] = {
    email: user.email,
    user_id: user.id,
    first_name: user.firstName,
    last_name: user.lastName,
    name: `${user.firstName} ${user.lastName}`,
    job_title: user.jobTitle,
    total_organizations: user.totalOrganizations,
    created_date: user.createdDate,
    version: import.meta.env.VITE_VERSION,
  };
  window.dispatchEvent(event);
};

export const dispatchOrganizationUpdated = (userMetadata) => {
  window["outbase"] = window["outbase"] ?? {};
  const event = new Event("outbase.org_updated");
  window["outbase"]["org_meta"] = userMetadata;
  window.dispatchEvent(event);
};

export const dispatchLogout = () => {
  const event = new Event("outbase.logout");
  window.dispatchEvent(event);
};
