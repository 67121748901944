export const locationRangeOptions = [
  { label: "Specify radius", value: 0 },
  { label: "Within: 1 mi (1.6 km)", value: 1 },
  { label: "Within: 5 mi (8 km)", value: 5 },
  { label: "Within: 10 mi (16 km)", value: 10 },
  { label: "Within: 25 mi (40 km)", value: 25 },
  { label: "Within: 35 mi (56 km)", value: 35 },
  { label: "Within: 50 mi (80 km)", value: 50 },
  { label: "Within: 75 mi (120 km)", value: 75 },
  { label: "Within: 100 mi (160 km)", value: 100 },
];
