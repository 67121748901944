import React from "react";
import { ToastContainer } from "react-toastify";

const EmptyLayout = (props) => {
  return (
    <>
      <main>{props.children}</main>
      <ToastContainer position={"bottom-right"} />
    </>
  );
};

export default EmptyLayout;
