import React from "react";
import svg from "./archive_icon.svg";

const IconArchive = (props) => (
  <svg
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
    className={props.imgStyle}
    width={props.width ? props.width : 20}
    height={props.height ? props.height : 20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill={props.fill ? props.fill : "#0F74D0"}
    />
    <path
      d="M5.95591 7.205L7.2055 5.95542C7.24412 5.91665 7.29004 5.88591 7.3406 5.86495C7.39115 5.844 7.44535 5.83326 7.50008 5.83334H12.5001C12.5548 5.83326 12.609 5.844 12.6596 5.86495C12.7101 5.88591 12.756 5.91665 12.7947 5.95542L14.0442 7.205C14.0832 7.24362 14.1141 7.28958 14.1351 7.34022C14.1561 7.39086 14.1669 7.44517 14.1667 7.5V12.9167C14.1667 13.3763 13.793 13.75 13.3334 13.75H6.66675C6.20716 13.75 5.83342 13.3763 5.83342 12.9167V7.5C5.83329 7.44517 5.84405 7.39086 5.86508 7.34022C5.88611 7.28958 5.91698 7.24362 5.95591 7.205ZM12.3276 6.66667H7.67258L7.25591 7.08334H12.7442L12.3276 6.66667ZM13.3334 12.9167V7.91667H6.66675L6.66591 12.9167H13.3334Z"
      fill="white"
    />
    <path d="M9.16659 8.74999H10.8333V9.99999H12.0833L9.99992 12.0833L7.91659 9.99999H9.16659V8.74999Z" fill="white" />
  </svg>
);

export default IconArchive;
