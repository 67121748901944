import { FC } from "react";
import Modal from "@components/UI/Modal/Modal";

interface Props {
  handleClose: () => void;
  showModal: boolean;
}

const NoResponseModal: FC<Props> = (props) => {
  return (
    <Modal
      title="Phone number reveal"
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton
      saveButtonText="OK"
    >
      <div>
        <p>Sorry! We weren't able to reveal the phone number.</p>
        <p>Note: You will not be charged for this request!</p>
      </div>
    </Modal>
  );
};

export default NoResponseModal;
