export const setDictionaryValuesForForm = (variablesList, dictionary, setDictionary, emailTemplateVariables) => {
  if (variablesList !== null && variablesList.length > 0) {
    variablesList.map((variable) => {
      var variableName = variable;
      var result = "";

      if (variableName !== null) {
        if (emailTemplateVariables !== undefined && emailTemplateVariables.length > 0) {
          emailTemplateVariables.map((type) => {
            type.emailTemplateVariables.map((variable) => {
              if (variableName === variable.name) {
                result = variable.example;
              }
            });
          });

          if (result !== "") {
            dictionary[variableName] = result;
            setDictionary(dictionary);
            return result;
          } else {
            dictionary[variableName] = "{" + variableName + "}";
            setDictionary(dictionary);
            return "{" + variableName + "}";
          }
        } else {
          dictionary[variableName] = "{" + variableName + "}";
          setDictionary(dictionary);
          return "{" + variableName + "}";
        }
      }
    });
  }
};

export const setDictionaryValuesForSubjects = (templatesRows, dictionarySubject, setDictionarySubject) => {
  //Creating dictionary in format {templateName: subject}
  if (templatesRows && templatesRows.length > 0) {
    templatesRows.map((template, index) => {
      var templateId = template.id;
      var result = template.subject;
      dictionarySubject[templateId] = result;
      setDictionarySubject(dictionarySubject);
    });
  }
};
