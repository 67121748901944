import React, { FC } from "react";
import Moment from "moment";
import { format } from "date-fns";

//Styles
import styles from "./GoLiveModal.module.scss";

//Helpers
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";

//Components
import Modal from "../../../components/UI/Modal/Modal";

import { useHistory } from "react-router-dom";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";
import { CampaignScheduleSimpleModel } from "../../../models/campaign/CampaignScheduleSimpleModel";

interface GoLiveModalProps {
  goLive: () => void;
  handleClose: () => void;
  showModal: boolean;
  saveButtonDisabled: boolean;
  id: number;
  schedule: CampaignScheduleSimpleModel;
}

const GoLiveModal: FC<GoLiveModalProps> = ({ goLive, handleClose, showModal, saveButtonDisabled, id, schedule }) => {
  const history = useHistory();
  const orgId = useOrganizationId();

  let dateMoment = Moment(schedule.startDate);
  let today = Moment();

  if (dateMoment.isBefore(today)) {
    dateMoment = today;
  }

  const date = dateMoment.isValid() ? dateMoment.toDate() : new Date();
  const formattedDate = !isNaN(date.getTime()) ? format(date, "dd-MM-yyyy") : "Invalid Date";

  const closeClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    // When "Change date" button is clicked, the event is not null
    if (event) history.push(`/${orgId}/campaigns/${id}/sending-schedule`);
    // When "X" button is clicked, the event is null and we just close the modal
    else handleClose();
  };

  return (
    <Modal
      title={`Your scheduled start date and time zone are`}
      handleClose={(event: React.MouseEvent<HTMLButtonElement>) => closeClicked(event)}
      handleSave={goLive}
      show={showModal}
      closeButtonText="Change date"
      saveButtonText="Keep date & activate"
      idSaveButton="keepDateActive"
      btnType="submit"
      saveButtonDisabled={saveButtonDisabled}
    >
      <div className={styles.infoWraper}>
        <div className={styles.infoLabel}>
          <p>Start date:</p>
          <p>Time zone:</p>
        </div>
        <div>
          <p>
            <span className={styles.dataStyle}>{formattedDate}</span>
          </p>
          <p className={styles.timeZone}>{schedule.timeZoneModel.name}</p>
        </div>
      </div>
    </Modal>
  );
};

export default GoLiveModal;
