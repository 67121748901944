import React from "react";

//Styles
import styles from "./TemplateCard.module.scss";
import { emailTemplateStatusEnum } from "../../../../../../helper/enums/emailTemplateStatusEnum";

const TemplateCard = (props) => {
  return (
    <div onClick={props.onClick} className={`${styles.templateCard} template-card`}>
      <div className={styles.cardHeader}>
        <h1 className={styles.cardTitle}>
          {props.templateName}{" "}
          <span
            className={`f12 ${styles.statusTag} ${
              props.status === emailTemplateStatusEnum.Draft ? styles.draftStatus : styles.publishedStatus
            }`}
          >
            {props.status === emailTemplateStatusEnum.Draft ? "Draft" : "Published"}
          </span>
        </h1>
        <p className={styles.description}>{props.subject}</p>
      </div>
      <div className={styles.cardBody}>
        <div className="flex">
          <div className={styles.dataWrapper}>
            <span className={styles.dataHeader}>Open</span>
            <span className={`${styles.data} ${styles.info}`}>{props.openRate.toFixed(2)}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={styles.dataHeader}>Click</span>
            <span className={`${styles.data} ${styles.warning}`}>{props.clickRate.toFixed(2)}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={styles.dataHeader}>Lead</span>
            <span className={`${styles.data} ${styles.success}`}>{props.leadRate.toFixed(2)}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={styles.dataHeader}>In Use</span>
            <span className={styles.data}>{props.inUse}</span>
          </div>
          <div className={styles.dataWrapper}>
            <span className={styles.dataHeader}>Total Sent</span>
            <span className={styles.data}>{props.emailsDelivered}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
