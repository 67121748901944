import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

//Redux
import {
  searchRecords as peopleSearchRecords,
  numRecords as peoplenumRecords,
  sortData as peopleSortData,
  initiate as peopleInitiate,
} from "../../store/Prospects/Table/actions/prospects";
import {
  searchRecords as companiesSearchRecords,
  numRecords as companiesnumRecords,
  sortData as companiesSortData,
  initiate as companiesInitiate,
} from "../../store/Companies/Table/actions/index";
import {
  searchRecords as campaignsSearchRecords,
  numRecords as campaignsnumRecords,
  sortData as campaignsSortData,
  initiate as campaignsInitiate,
} from "../../store/Campaigns/Table/actions/campaigns";

//Helpers
import { numberOfRecords } from "../../helper/tableConstants";

// Style
import styles from "./GlobalSearch.module.scss";

// Components
import PeopleTable from "./PeopleTable/PeopleTable";
import CompaniesTable from "./CompaniesTable/CompaniesTable";
import CampaignsTable from "./CampaignTable/CampaignTable";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import Button from "../../components/UI/Button/Button";

const GlobalSearch = (props) => {
  const records = numberOfRecords[1];

  const [page, setPage] = useState(props.location?.aboutProps?.page ?? 1);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    let search = values.search;

    const stateProspects = {
      params: {
        length: records.value,
        sort: { column: "lastActivity", direction: "desc" },
        search: search,
      },
    };

    const stateCompanies = {
      params: {
        length: records.value,
        sort: { column: "dateLastProspected", direction: "desc" },
        search: search,
      },
    };

    const stateCampaigns = {
      params: {
        length: records.value,
        sort: { column: "lastActiveDate", direction: "desc" },
        search: search,
      },
    };

    props.peopleInitiate(stateProspects);
    props.companiesInitiate(stateCompanies);
    props.campaignsInitiate(stateCampaigns);
  }, [props.location.search]);

  const pageHandler = (page) => {
    let newPage = page;
    setPage(newPage);
  };

  useEffect(() => {
    let page = props.location?.aboutProps?.page;
    if (page == null) {
      page = 1;
    }
    pageHandler(page);
  }, [props.location.aboutProps]);

  const searchRecords = (val) => {
    props.peopleSearchRecords(val);
    props.companiesSearchRecords(val);
    props.campaignsSearchRecords(val);
  };

  const numOfRecords = (obj) => {
    props.peoplenumRecords(obj);
    props.companiesnumRecords(obj);
    props.campaignsnumRecords(obj);
  };

  let table = null;
  let param = null;
  let totalCount = null;
  let tableName = null;

  switch (page) {
    case 1:
      table = <PeopleTable />;
      param = props.prospectParams;
      totalCount = props.totalCountProspects;
      tableName = "People";
      break;
    case 2:
      table = <CompaniesTable />;
      param = props.companiesParams;
      totalCount = props.totalCountCompanies;
      tableName = "Companies";
      break;
    case 3:
      table = <CampaignsTable />;
      param = props.campaignsParamas;
      totalCount = props.totalCountCampaigns;
      tableName = "Campaigns";
      break;
    default:
      break;
  }

  const buttons = (
    <>
      <Button
        id="peopleBtn"
        variant="editButton"
        classes={`${styles.leftButton} ${page === 1 ? styles.active : null}`}
        onClick={() => pageHandler(1)}
      >
        People
      </Button>
      <Button
        id="companiesBtn"
        variant="editButton"
        classes={`${styles.middleButton} ${page === 2 ? styles.active : null}`}
        onClick={() => pageHandler(2)}
      >
        Companies
      </Button>
      <Button
        id="campaignsBtn"
        variant="editButton"
        classes={`${styles.rightButton} ${page === 3 ? styles.active : null}`}
        onClick={() => pageHandler(3)}
      >
        Campaigns
      </Button>
    </>
  );

  return (
    <div className={`${styles.globalSearch} contentHeight`}>
      <HeaderTitle title={"Search"} />
      <TableFiltersRow rightContent={buttons} placeholder={tableName} changeFilter={(text) => searchRecords(text)} />
      {table}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    prospectParams: state.prospects.params,
    companiesParams: state.companies.params,
    campaignsParamas: state.campaigns.params,
    totalCountProspects: state.prospects.totalCount,
    totalCountCompanies: state.companies.totalCount,
    totalCountCampaigns: state.campaigns.totalCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    peopleSortData: (obj) => dispatch(peopleSortData(obj)),
    companiesSortData: (obj) => dispatch(companiesSortData(obj)),
    campaignsSortData: (obj) => dispatch(campaignsSortData(obj)),
    peopleSearchRecords: (value) => dispatch(peopleSearchRecords(value)),
    companiesSearchRecords: (value) => dispatch(companiesSearchRecords(value)),
    campaignsSearchRecords: (value) => dispatch(campaignsSearchRecords(value)),
    peoplenumRecords: (obj) => dispatch(peoplenumRecords(obj)),
    companiesnumRecords: (obj) => dispatch(companiesnumRecords(obj)),
    campaignsnumRecords: (obj) => dispatch(campaignsnumRecords(obj)),
    peopleInitiate: (state) => dispatch(peopleInitiate(state)),
    companiesInitiate: (state) => dispatch(companiesInitiate(state)),
    campaignsInitiate: (state) => dispatch(campaignsInitiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
