import * as actionTypes from "../actions/actionTypes";

const initialState = {
  prospectsIsLoading: false,
  prospectsIsLoaded: false,
  prospectsError: "",
  companiesIsLoading: false,
  companiesIsLoaded: false,
  companiesError: "",
  campaignsIsLoading: false,
  campaignsIsLoaded: false,
  campaignsError: "",
  search: "",
  listProspects: [],
  listCompanies: [],
  listCampaigns: [],
};

const fetchGlobalSearchProspectsRequest = (state, action) => {
  return {
    ...state,
    prospectsIsLoading: true,
  };
};

const fetchGlobalSearchProspectsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listProspects };
  list = action.result;
  newState.prospectsIsLoaded = true;
  newState.prospectsIsLoading = false;
  newState.listProspects = list;
  return newState;
};

const fetchGlobalSearchProspectsFailed = (state, action) => {
  const newState = { ...state };
  newState.prospectsError = action.result;
  newState.prospectsIsLoading = false;
  return newState;
};

const fetchGlobalSearchCompaniesRequest = (state, action) => {
  return {
    ...state,
    companiesIsLoading: true,
  };
};

const fetchGlobalSearchCompaniesSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listCompanies };
  list = action.result;
  newState.companiesIsLoaded = true;
  newState.companiesIsLoading = false;
  newState.listCompanies = list;
  return newState;
};

const fetchGlobalSearchCompaniesFailed = (state, action) => {
  const newState = { ...state };
  newState.companiesError = action.result;
  newState.companiesIsLoading = false;
  return newState;
};

const fetchGlobalSearchCampaignsRequest = (state, action) => {
  return {
    ...state,
    campaignsIsLoading: true,
  };
};

const fetchGlobalSearchCampaignsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listCampaigns };
  list = action.result;
  newState.campaignsIsLoaded = true;
  newState.campaignsIsLoading = false;
  newState.listCampaigns = list;
  return newState;
};

const fetchGlobalSearchCampaignsFailed = (state, action) => {
  const newState = { ...state };
  newState.campaignsError = action.result;
  newState.campaignsIsLoading = false;
  return newState;
};

export const searchRecords = (state, action) => {
  const newState = { ...state };
  const newSearch = action.result;
  newState.search = newSearch;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GLOBAL_SEARCH_PROSPECTS_REQUEST:
      return fetchGlobalSearchProspectsRequest(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_PROSPECTS_SUCCEEDED:
      return fetchGlobalSearchProspectsSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_PROSPECTS_FAILED:
      return fetchGlobalSearchProspectsFailed(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_COMPANIES_REQUEST:
      return fetchGlobalSearchCompaniesRequest(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_COMPANIES_SUCCEEDED:
      return fetchGlobalSearchCompaniesSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_COMPANIES_FAILED:
      return fetchGlobalSearchCompaniesFailed(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_CAMPAIGNS_REQUEST:
      return fetchGlobalSearchCampaignsRequest(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_CAMPAIGNS_SUCCEEDED:
      return fetchGlobalSearchCampaignsSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_SEARCH_CAMPAIGNS_FAILED:
      return fetchGlobalSearchCampaignsFailed(state, action);
    case actionTypes.GLOBAL_SEARCH_RECORDS:
      return searchRecords(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
