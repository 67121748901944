import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

//Redux
import {
  fetchCompanyActivityOverviewTable,
  changePage,
  sortData,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  numRecords,
} from "../../../../store/CompanyActivityOverview/Table/actions/companyActivityOverview";

//Helpers
import { prospectEngagedTableColumns } from "../helper/prospectEngagedTableColumns";
import { numberOfRecords } from "../../../../helper/tableConstants";
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";

//Style
import styles from "./ProspectsEngaged.module.scss";

//Images & Icons
import IconEmailExcluded from "../../../../assets/Icons/IconEmailExcluded/IconEmailExcluded";
import Message2LineIcon from "remixicon-react/Message2LineIcon";
import EmptyCampaignList from "../../../../assets/Images/EmptyCampaignList/EmptyCampaignList";
import InformationFillIcon from "remixicon-react/InformationFillIcon";

//Components
import StatusBar from "../../../Prospects/StatusBar/StatusBar";
import Table from "../../../../components/DataTable/Table";
import TableOptionsRow from "../../../../components/DataTable/TableOptionsRow/TableOptionsRow";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";

const ProspectsEngaged = (props) => {
  const { params, rows, initiate, isInitiated, fetchCompanyActivityOverviewTable, id } = props;

  let table = null;
  let image = <EmptyCampaignList />;
  let tableOptionsRow = null;

  const organizationId = useOrganizationId();
  const records = numberOfRecords[1];
  const [tableRows, setTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(prospectEngagedTableColumns));

  const [showAll, setShowAll] = useState(false);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: params,
    };
    initiate(updateState);
  };

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
      },
    };

    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchCompanyActivityOverviewTable(params, Number(id), showAll);
    }
  }, [params, id, showAll, isInitiated]);

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r) => {
      let checkBox = <Checkbox />;

      r.checkBox = checkBox;

      let name = (
        <OverlayTrigger
          key={r.name}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.name}`}>
              <Popover.Body>{r.name}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
              }}
            >
              {r.name}
            </Link>
          </div>
        </OverlayTrigger>
      );

      r.name = name;

      let email = (
        <OverlayTrigger
          key={r.email}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.email}`}>
              <Popover.Body>{r.email}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
              }}
            >
              {r.email}
            </Link>
          </div>
        </OverlayTrigger>
      );

      r.email = email;

      if (r.isExcluded) {
        r.isExcluded = (
          <OverlayTrigger
            key={r.isExcluded}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.isExcluded}`}>
                <Popover.Body>This prospect has been excluded</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">
              <IconEmailExcluded />
            </div>
          </OverlayTrigger>
        );
      } else {
        r.isExcluded = null;
      }

      let chat = (
        <Link
          to={{
            pathname: "/" + organizationId + "/conversation/" + r.id,
          }}
        >
          <Message2LineIcon size={16} className="iconGray500" />
        </Link>
      );

      r.chat = <div className="textCenter">{chat}</div>;

      r.currentStatus = (
        <OverlayTrigger
          key={r.currentStatus}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.currentStatus}`}>
              <Popover.Body>
                <StatusBar
                  isDelivered={r.delivered != null}
                  isOpened={r.opened != null}
                  isClicked={r.clicked != null}
                  isResponder={r.responded != null}
                  isLead={r.isLead}
                />
              </Popover.Body>
            </Popover>
          }
        >
          <div className="textOverflow">
            <StatusBar
              isDelivered={r.delivered != null}
              isOpened={r.opened != null}
              isClicked={r.clicked != null}
              isResponder={r.responded != null}
              isLead={r.isLead}
            />
          </div>
        </OverlayTrigger>
      );

      const tooltipContent =
        r.responseType === "Out of office"
          ? "OOO replies adjust the next follow-up to the recipient’s return date. Date can be modified if needed."
          : r.responseType;

      const responseTypeJSX =
        r.responseType === "Out of office" ? (
          <div className="flex align-center">
            <InformationFillIcon size={18} color="var(--icon-color)" className="mR5" /> {r.responseType}
          </div>
        ) : (
          r.responseType
        );

      r.responseType = (
        <OverlayTrigger
          key={r.responseType}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.responseType}`}>
              <Popover.Body>{tooltipContent}</Popover.Body>
            </Popover>
          }
        >
          <div className="max-x-content text-overflow-hidden">{responseTypeJSX}</div>
        </OverlayTrigger>
      );

      return r;
    });

    setTableRows(tempRows);
  }, [rows]);

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Prospects Engaged"}
          columns={dataTableColumns}
          rows={tableRows}
          param={params}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          tableClassName={styles.ProspectsEngaged}
          scrollY={true}
          tableHeight={435}
          variant="small"
          forcePageChanged={true}
        />
      ) : (
        <EmptyState image={image} title="No matching records found" />
      );
  }

  const handleShowAll = () => {
    setShowAll(true);
  };

  tableOptionsRow =
    tableRows.length > 0 ? (
      <TableOptionsRow
        applyChanges={applyDataTableColumnsChange}
        exportTable={null}
        importData={null}
        dataTableColumns={dataTableColumns}
        setDataTableColumns={setDataTableColumns}
        allDataTableColumns={prospectEngagedTableColumns}
        customDropdownClass={`${styles.dropdownColumnsList}`}
        drop="down"
      />
    ) : null;

  return (
    <div className={styles.tableWrapper}>
      {tableOptionsRow}
      {table}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.companyActivityOverviewTable.params,
    isInitiated: state.companyActivityOverviewTable.isInitiated,
    isLoading: state.companyActivityOverviewTable.isLoading,
    isLoaded: state.companyActivityOverviewTable.isLoaded,
    error: state.companyActivityOverviewTable.error,
    rows: state.companyActivityOverviewTable.rows,
    totalCount: state.companyActivityOverviewTable.totalCount,
    pageCount: state.companyActivityOverviewTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyActivityOverviewTable: (params, id, showAll) => dispatch(fetchCompanyActivityOverviewTable(params, id, showAll)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectsEngaged);
