import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonPieChart = (props) => {
  return (
    <div className="skeleton-wrapper flex_center">
      <SkeletonElement type="circle-l" />
      <Shimmer />
    </div>
  );
};

export default SkeletonPieChart;
