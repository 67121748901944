export const formatNumberHelper = (value, groupSeparator, decimalSeparator) => {
  if (value === undefined || value === null || value === 0) {
    return 0;
  } else {
    var parts = value.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;

    return numberPart.replace(thousands, groupSeparator) + (decimalPart ? decimalSeparator + decimalPart : "");
  }
};
