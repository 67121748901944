import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//Helpers
import api, { API, formUrl } from "../../../helper/api/api";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { permissionHelper } from "../../../helper/permissionHelper";
import { SIGNALR_CONSTANTS } from "../../../helper/signalrConstants";
import { emailTemplateStatusEnum } from "../../../helper/enums/emailTemplateStatusEnum";
import { checkForIncorrectVariables } from "../../../helper/variablesHelper";
import { useGetLatestCampaignWizardQuery } from "../../../api/campaignWizardApi";
import { useGetWorkflowStepsQuery } from "@api/workflowStepsApi";
import { useSlice } from "@hooks/useSlice";
import { useGetScheduleQuery } from "@api/sendingScheduleApi";
import { useGetAudienceDetailsQuery } from "@api/audienceApi";
import { useGetCampaignQuery } from "@api/campaignApi";
import { useQuery } from "../../../helper/hooks/useQuery";
import { addEmailAccountContinue } from "@helper/addEmailAccountHelper";
//Icons
import MailSendLineIcon from "remixicon-react/MailSendLineIcon";

//Styles
import styles from "./CampaignConfiguration.module.scss";

//Components
import Sequence from "../Components/Sequence/Sequence";
import Permission from "../../../components/auth/Permission";
import AudiencePushToCampaignForm from "./AudiencePushToCampaignForm/AudiencePushToCampaignForm";
import AudienceUploadForm from "../../Audiences/AudienceUploadForm";
import AudienceContactsExportForm from "../../Audiences/AudienceContactsExportForm";
import CreateNewAudienceForm from "./AudiencePushToCampaignForm/CreateNewAudienceForm";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ErrorVariablesModal from "../../EmailTemplates/ErrorVariablesModal/ErrorVariablesModal";
import TestSequenceForm from "./TestSequenceForm/TestSequenceForm";
import Accordion from "../../../components/UI/Accordion/Accordion";
import AudienceCard from "../Components/Audiences/AudienceCard";
import Drawer from "@ui/Drawer/Drawer";
import useModal from "@ui/Modal/useModal";
import useSignalRHook from "@hooks/useSignalRHook";
import AlertNotification from "@ui/AlertNotification/AlertNotification";
import EmailAccountDetails from "../EditPages/SendingSchedule/EmailAccountDetails/EmailAccountDetails";
import AddSenderAccountDrawer from "../EditPages/SendingSchedule/AddSenderAccountDrawer/AddSenderAccountDrawer";
import CancelWarningForm from "@components/CancelWarningForm/CancelWarningForm";
import AddEmailAccountModal from "@pages/EmailAccounts/AddEmailAccountModal/AddEmailAccountModal";
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const CampaignConfiguration = () => {
  const openPushAudienceModal = useSlice((state) => state.campaign.openPushAudienceModal);

  const { organizationId, campaignId } = useParams();
  let query = useQuery();
  const campaign = useSlice((state) => state.campaign.campaign);
  const audience = useSlice((state) => state.campaign.audience);
  const userRole = useSlice((state) => state.user.organization.role);
  const emailAccounts = useSlice((state) => state.globalEmailAccounts.listEmailAcconts);

  const progress = campaign.campaignSetupProgressModel;

  // Queries
  const campaignQuery = useGetCampaignQuery(campaignId);
  const audienceQuery = useGetAudienceDetailsQuery(campaign.audienceId, {
    refetchOnMountOrArgChange: true,
    skip: !campaign.audienceId,
  });
  const workflowStepsQuery = useGetWorkflowStepsQuery(campaignId, { skip: !campaignId, refetchOnMountOrArgChange: true });
  const scheduleQuery = useGetScheduleQuery(campaignId, { skip: !campaignId, refetchOnMountOrArgChange: true });
  const { data: wizard, ...wizardQuery } = useGetLatestCampaignWizardQuery();

  const templatesState = useSlice((state) => state.templatesDataTable);
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery();

  const deletedTemplates = workflowStepsQuery.data?.find((s) => s.isDeletedTemplate === true);
  const pendingTemplates =
    wizard && !wizardQuery.isLoading && wizard.isInProgress && wizard.campaignId == campaign.id && wizard.campaignId && !wizard.templateIds;

  useSignalRHook(
    SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_TEMPLATES_COMPLETED,
    () => {
      if (campaign.id) {
        wizardQuery.refetch();
        workflowStepsQuery.refetch();
        campaignQuery.refetch();
      }
    },
    [campaign.id]
  );

  useSignalRHook(
    SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_AUDIENCE_COMPLETED,
    () => {
      campaignQuery.refetch();
    },
    [campaign.id]
  );

  useSignalRHook(
    SIGNALR_CONSTANTS.AUDIENCE_INITIATED,
    () => {
      if (campaign.audienceId) {
        audienceQuery.refetch();
      }
    },
    [campaign.id]
  );

  const [showErrorVariablesModal, setShowErrorVariablesModal] = useState(false);
  const handleCloseErrorVariablesModal = () => {
    setShowErrorVariablesModal(false);
  };
  const [showTestSequenceForm, setShowTestSequenceForm] = useState(false);
  const handleTestSequenceFormClose = () => setShowTestSequenceForm(false);
  const handleTestSequenceFormShow = () => {
    if (progress.isDraftInSequence) {
      let combineVariablesArray = emailTemplateVariables?.flatMap((templateVariable) => templateVariable.emailTemplateVariables) ?? [];
      let incorrectVar = 0;
      templatesState.rows?.forEach((template) => {
        if (template.status === emailTemplateStatusEnum.Draft) {
          let htmlAndSubject = template.html.concat(" ", template.subject);
          let incorrect = checkForIncorrectVariables(htmlAndSubject, combineVariablesArray);
          incorrectVar = incorrectVar + incorrect?.length;
        }
      });
      if (incorrectVar > 0) {
        setShowErrorVariablesModal(true);
      } else {
        setShowTestSequenceForm(true);
      }
    } else {
      setShowTestSequenceForm(true);
    }
  };

  const pushToCampaignModal = useModal(openPushAudienceModal === true);
  const audienceUploadModal = useModal();
  const createAudienceModal = useModal();
  const cancelWarningModal = useModal();
  const addEmailAccountModal = useModal();

  const handleCancelWarningSave = () => {
    setHasChanges(false);
    cancelWarningModal.hide();
    setDrawerOpen(false);
  };

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [ignore, setIgnore] = useState(false);
  const [emailAccountAdded, setEmailAccountAdded] = useState(null);
  const hasChangesRef = useRef(hasChanges);
  const ignoreRef = useRef(ignore);

  useEffect(() => {
    if (!isDrawerOpen && query.get("emailAccountAdded") != null) {
      let emailAccountAdded = query.get("emailAccountAdded");
      setEmailAccountAdded(emailAccountAdded);
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    hasChangesRef.current = hasChanges;
  }, [hasChanges]);

  useEffect(() => {
    ignoreRef.current = ignore;
  }, [ignore]);

  const handleCloseDrawer = () => {
    if (hasChangesRef.current && !ignoreRef.current) {
      cancelWarningModal.show();
    } else {
      setDrawerOpen(false);
    }
  };

  const handleSave = () => {
    campaignQuery.refetch();
    setDrawerOpen(false);
  };

  const handleAddEmailAccountContinue = (typeId) => {
    addEmailAccountContinue(typeId, campaign.id, organizationId);
  };

  //Audience
  const linkToSendingSchedule = `/${organizationId}/campaigns/${campaign.id}/campaign-sequence`;
  const emailsInErrors =
    campaign.emailAccounts && campaign.emailAccounts.length > 0 && campaign.emailAccounts.find((s) => s.isConnected === false) != null;

  const [audienceContactsFileData, setAudienceContactsFileData] = useState("");
  const [showAudienceContactsExportFormModal, setAudienceContactsExportFormModal] = useState(false);

  const handleAudienceContactsExportFormModalClose = () => {
    setAudienceContactsFileData("");
    setAudienceContactsExportFormModal(false);
  };

  const handleAudienceContactsExportFormModalShow = (audience) => {
    if (!audience || !audience.id) {
      toast.error("Invalid audience data");
      return;
    }

    setAudienceContactsExportFormModal(true);

    const data = {
      audienceId: audience.id,
    };
    api(formUrl(API.audience.exportAudienceContacts, data, "blob"))
      .then((res) => {
        if (res && res.data) {
          setAudienceContactsFileData(res.data);
        } else {
          toast.error("Unexpected response data");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  let noContactsWarning = null;
  // Only show no available contacts if the audiences is initiated and has no contacts
  // or if there are no audiences and no new prospects
  if ((audience?.isInitiated && audience?.availableContacts == 0) || ((!audience || !audience?.isInitiated) && !progress.hasNewProspects)) {
    noContactsWarning = "No available contacts.";
  }

  let audienceBody = null;
  if (audience) {
    audienceBody = (
      <AudienceCard
        key={audience.id}
        audience={audience}
        pushToCampaignShow={pushToCampaignModal.show}
        audienceUploadModalShow={audienceUploadModal.show}
        audienceDownload={() => handleAudienceContactsExportFormModalShow(audience)}
      />
    );
  } else if (progress.hasNewProspects) {
    audienceBody = <p>This campaign has no list assigned, but it currently has prospects waiting to be engaged.</p>;
  } else {
    audienceBody = <p>This campaign has no list assigned and no prospects waiting to be engaged.</p>;
  }

  return campaign.id > 0 &&
    workflowStepsQuery.isSuccess &&
    scheduleQuery.isSuccess &&
    (audienceQuery.isSuccess || audienceQuery.isUninitialized) ? (
    <div className={styles.configureWrapper}>
      <p className={styles.configureText}>🚀 A world of engagement awaits! Configure your campaign to tap into its potential.</p>
      <AlertNotification hideIcon variant="info_alert_lighter" alertClassName="max-x-content mB15">
        <span className="secondary_text_color f14">
          Default scheduling settings (UTC+00:00) have been applied to this campaign. To review and adjust the schedule, as well as
          additional settings, please visit the{" "}
          <Link
            className="info_link "
            to={{
              pathname: "/" + organizationId + "/campaigns/" + campaignId + "/settings",
            }}
          >
            settings section
          </Link>
          .
        </span>
      </AlertNotification>
      <div id="campaignSettingsWrapper">
        <Accordion
          header="Contact list"
          subHeader="Search and identify prospects, define lists and queue for engagement."
          variant="whiteTransparent"
          eventKey="lists"
          hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
          isChecked={progress.isSetProspectsInCampaign || audience?.isInitiated}
          warningText={noContactsWarning}
          generatingText={audience && !audience.isInitiated ? "Creating your list." : null}
          showConfiguration={!progress.isSetProspectsInCampaign && !audience} // Showing config if no ready prospects and no assigned audience
          configurationId="audiencesStep"
          configurationLabel="Create a list and import prospects"
          configButtonClick={pushToCampaignModal.show}
          openButtonText={audience ? "Change List" : "Add list"}
          openButtonClick={pushToCampaignModal.show}
          body={audienceBody}
          noCaret={true}
        />

        <Accordion
          header="Email account"
          subHeader="Set the active dates and message delivery timings for all campaign activity."
          variant="whiteTransparent"
          eventKey="sending-sequence"
          hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
          isChecked={progress.isEmailAccountConnected}
          isClickable={progress.isEmailAccountConnected}
          warningText={
            emailsInErrors
              ? "Email account(s) issues."
              : campaign && Array.isArray(campaign.emailAccounts) && campaign.emailAccounts.some((account) => !account.hasSignature)
              ? "Add email signature"
              : null
          }
          showConfiguration={!progress.isEmailAccountConnected}
          openButtonClick={() => setDrawerOpen(!isDrawerOpen)}
          configButtonClick={emailAccounts.length > 0 ? () => setDrawerOpen(!isDrawerOpen) : addEmailAccountModal.show}
          configurationId="schedulerStep"
          configurationLabel="Configure your sending schedule"
          body={<EmailAccountDetails />}
          noCaret={true}
        />

        <Accordion
          header="Messaging sequence"
          subHeader="Define campaign messaging sequences and assign templates."
          variant="whiteTransparent"
          eventKey="campaign-sequence"
          hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
          isChecked={progress.isSetWorkflow}
          warningText={
            deletedTemplates ? "Sequence needs to be reviewed." : progress.isDraftInSequence ? "Review and publish messages." : null
          }
          generatingText={pendingTemplates ? "Generating templates..." : null}
          showConfiguration={!progress.isSetWorkflow && !progress.isDraftInSequence && !pendingTemplates} // Only show config if there are neither published nor draft templates
          configurationLabel="Add your messaging sequence"
          configurationId="sequenceStep"
          configButtonUrl={linkToSendingSchedule}
          // infoIconText="Unpublished email templates in this sequence! Review and publish them before activating the campaign"
          // showInfoIcon={isDraftInSequence}
          showActionButton={progress.isDraftInSequence}
          actionButtonText="Review"
          actionButtonUrl={linkToSendingSchedule}
          openButtonUrl={linkToSendingSchedule}
          openSecondButtonIcon={<MailSendLineIcon size={16} />}
          openSecondButtonId="testSequence"
          openSecondButtonText="Test Sequence"
          openSecondButtonClick={handleTestSequenceFormShow}
          body={<Sequence workflowSteps={workflowStepsQuery.data} stepsLoading={workflowStepsQuery.isLoading} />}
          noCaret={true}
        />
      </div>

      <Permission has={PERMISSIONS.campaign.edit}>
        <>
          <AudiencePushToCampaignForm
            handleClose={pushToCampaignModal.hide}
            showModal={pushToCampaignModal.isVisible}
            handleAudienceUploadFormShow={audienceUploadModal.show}
            handleCreateNewAudienceFormShow={createAudienceModal.show}
          />
        </>
      </Permission>
      <TestSequenceForm
        handleClose={handleTestSequenceFormClose}
        showModal={showTestSequenceForm}
        campaignId={campaign.id}
        templatesState={templatesState}
      />
      <ErrorVariablesModal
        handleClose={handleCloseErrorVariablesModal}
        handleSubmit={() => setShowErrorVariablesModal(false)}
        showModal={showErrorVariablesModal}
      />
      <Permission has={PERMISSIONS.campaign.edit}>
        <AudienceUploadForm
          handleClose={audienceUploadModal.hide}
          showModal={audienceUploadModal.isVisible}
          campaignConfigCampaignId={campaign.id}
          audience={audienceUploadModal.data}
          orgId={organizationId}
        />
      </Permission>

      <Permission has={PERMISSIONS.campaign.edit}>
        <CreateNewAudienceForm
          handleAudienceUploadFormShow={audienceUploadModal.show}
          handleClose={createAudienceModal.hide}
          showModal={createAudienceModal.isVisible}
          audience={""}
        />
      </Permission>

      <Permission has={PERMISSIONS.campaign.edit}>
        <>
          {audience && (
            <AudienceContactsExportForm
              handleClose={handleAudienceContactsExportFormModalClose}
              showModal={showAudienceContactsExportFormModal}
              audience={audience}
              audienceContactsFileData={audienceContactsFileData}
            />
          )}
        </>
      </Permission>
      <AddEmailAccountModal
        showModal={addEmailAccountModal.isVisible}
        handleClose={addEmailAccountModal.hide}
        handleSave={handleAddEmailAccountContinue}
      />
      <Drawer
        title="Add Sender Account"
        content={
          <AddSenderAccountDrawer
            handleCloseDrawer={handleCloseDrawer}
            setHasChanges={setHasChanges}
            setDrawerOpen={setDrawerOpen}
            setIgnore={setIgnore}
            handleSave={handleSave}
            emailAccountAdded={emailAccountAdded}
          />
        }
        isOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        showFooter={false}
      />
      <CancelWarningForm
        showModal={cancelWarningModal.isVisible}
        handleClose={cancelWarningModal.hide}
        handleSave={handleCancelWarningSave}
        bodyMessageContent="You are about to leave and any unsaved changes will be discarded. Do you want to continue?"
        closeButtonText="Cancel"
        saveButtonText="Continue"
      />
    </div>
  ) : (
    <Spinner />
  );
};

export default CampaignConfiguration;
