import React, { useState, useEffect, useRef } from "react";

//Components
import Spinner from "../../components/UI/Spinner/Spinner";
import ExternalForm, { useExternalForm } from "./ExternalForm";
import buildConfig from "./buildConfig";

const ChargifyForm = ({ paymentType, getToken, handleReturnToken, creditCardInfo, isSubmitting, setGetToken, setSubmitting }) => {
  const chargifyForm = useRef();
  const chargify = useRef(new window.Chargify());
  const [token, setToken] = useState("");

  let placeholderStyle = creditCardInfo != null && creditCardInfo?.firstName != null ? { color: "#2a3347" } : { color: "#aaadb5" };

  const config = buildConfig(paymentType, creditCardInfo, placeholderStyle);

  useEffect(() => {
    chargify.current.load(config);
    return undefined;
  }, []);

  useEffect(() => {
    chargify.current.load(config);
    return () => {
      chargify.current.unload();
      setToken("");
    };
  }, [chargify, paymentType]);

  useEffect(() => {
    if (getToken) {
      handleSubmit();
    }
  }, [getToken]);

  const handleSubmit = () => {
    chargify.current.token(
      chargifyForm.current,
      (token) => {
        console.log("{host} token SUCCESS - token: ", token);
        setToken(token);
        handleReturnToken(token);
        setGetToken(false);
      },
      (error) => {
        console.log("{host} token ERROR - err: ", error);
        handleReturnToken(error);
        setGetToken(false);
      }
    );
  };

  return (
    <form className="chargifyForm" onSubmit={handleSubmit} ref={chargifyForm}>
      {isSubmitting ? <Spinner /> : <div id="chargify-form"></div>}
    </form>
  );
};

export default ChargifyForm;
