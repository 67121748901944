import React, { ReactNode, FC } from "react";

//Styles
import styles from "./ActionWrapper.module.scss";

interface IActionWrapperProps {
  id?: string;
  wrapperClass?: string;
  children: ReactNode;
}

const ActionWrapper: FC<IActionWrapperProps> = ({ id, wrapperClass = "", children }) => {
  return (
    <div id={id} className={`${styles.actionWrapper} ${wrapperClass}`}>
      {children}
    </div>
  );
};

export default ActionWrapper;
