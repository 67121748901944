import { OverlayTrigger, Popover } from "react-bootstrap";

//Images & Icons
import IconBlueCard from "../../../../assets/Icons/IconBlueCard/IconBlueCard";

//Styles
import styles from "./TemplateNameColumn.module.scss";

const TemplateColumn = (props) => {
  return (
    <>
      <div className={`${styles.templateColumnWrapper} max-x-content `} onClick={props.onClick}>
        <OverlayTrigger
          key={props.id}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${props.templateName}`}>
              <Popover.Body>{props.templateName}</Popover.Body>
            </Popover>
          }
        >
          <div className={`${styles.name}`}>
            {props.templateName}
            {props.purchaseTemplateId && <IconBlueCard />}
          </div>
        </OverlayTrigger>
        <div className={`text-overflow-hidden ${styles.subject}`}>{props.subject}</div>
        <div className={`text-overflow-hidden ${styles.created}`}>
          Created by:{" "}
          {`${props.createdFirstName}
           ${props.createdLastName && props.createdLastName.length > 0 ? props.createdLastName.charAt(0).toUpperCase() : ""}. 
           ${props.dateCreated}`}
        </div>
      </div>
    </>
  );
};

export default TemplateColumn;
