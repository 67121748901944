import React, { FC } from "react";
import Action from "../Action";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "../Action.module.scss";

//Icons
import PhoneLockLineIcon from "remixicon-react/PhoneLockLineIcon";
import SmartphoneLineIcon from "remixicon-react/SmartphoneLineIcon";

interface ActionEnableDisableOrganizationProps {
  className?: string;
  organizationAccessRemoved: boolean;
}

const ActionEnableDisableOrganization: FC<ActionEnableDisableOrganizationProps> = ({
  className = "",
  organizationAccessRemoved,
  ...props
}) => {
  return (
    <Action
      className={className}
      materialIcon={
        <OverlayTrigger
          key={"disable-domain"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-disable-domain`}>
              <Popover.Body>
                {organizationAccessRemoved ? "Enable users' access to the organization" : "Disable users' access to the organization"}
              </Popover.Body>
            </Popover>
          }
        >
          {organizationAccessRemoved ? (
            <div className={styles.enableAccess}>
              <SmartphoneLineIcon size={18} className="iconGray" />
            </div>
          ) : (
            <div className={styles.disableAccess}>
              <PhoneLockLineIcon size={18} className="iconGray" />
            </div>
          )}
        </OverlayTrigger>
      }
      {...props}
    />
  );
};

export default ActionEnableDisableOrganization;
