import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "../../components/UI/Modal/Modal";
import styles from "./CancelWarningForm.module.scss"; 

const CancelWarningForm = (props) => {
  return (
    <Modal
      dialogClassName="customModal"
      title={"Discard Changes"}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText={props.closeButtonText ? props.closeButtonText : "Cancel"}
      saveButtonText={props.saveButtonText ? props.saveButtonText : "Yes"}
      btnType="submit"
      size="md"
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.wizzardContent}>
            {props.bodyMessageContent ? props.bodyMessageContent : <p>Do you want to discard all unsaved changes?</p>}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default CancelWarningForm;
