import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, Formik } from "formik";

//Redux
import {
  fetchCompaniesProperties,
  fetchContactsProperties,
  fetchCustomRuleById,
  fetchDealsPipelineById,
  fetchDealsPipelines,
  fetchDealsProperties,
  fetchRulesCustomFields,
} from "../../../../../store/CRM-Integrations/HubSpot/Global/actions/hubspot-integration";

//Styles
import styles from "../PredefinedRules.module.scss";

//Images & Icons
import HubSpotLogo from "../../../../../assets/Images/CRM/HubSpotLogo/HubSpotLogo";
import CombinationMarkLogoDark from "../../../../../assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";

//Helpers
import api, { API, formUrl } from "../../../../../helper/api/api";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { actionsOptions, actionsOptionsGrouped } from "../../../Trigger-ActionsOptions/actionsOptions";
import { triggerOptions } from "../../../Trigger-ActionsOptions/triggerOptions";

//Components
import Button from "../../../../../components/UI/Button/Button";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";

const EditCustomRule = (props) => {
  const {
    fetchCustomRuleById,
    editCustomRuleId,
    editCustomRule,
    fetchDealsPipelineById,
    fetchDealsPipelines,
    dealsPipelines,
    dealStages,
    isLoading,
  } = props;

  // const breadcrumbs = [
  //   {
  //     text: 'Integration rules'
  //   },
  //   { active: true, text: "Edit" },
  // ];

  const [dealsPipelineState, setDealsPipelineState] = useState([]);
  const [dealStagesState, setDealStagesState] = useState([]);
  const [leadStatusState, setLeadStatusState] = useState([]);
  const [lifecycleStageState, setLifecycleState] = useState([]);
  const [isFinished, setIsFinished] = useState(false);

  const [mappingKey, setMappingKey] = useState("");
  const [description, setDescription] = useState("");
  const [actionName, setActionName] = useState("");
  const [actionCode, setActionCode] = useState(0);
  const [triggerType, setTriggerType] = useState(-1);
  const [pipelineId, setPipelineId] = useState("");
  const [groupedActions, setGroupedActions] = useState([]);

  const [formValues, setFormValues] = useState({
    id: "",
    eventType: "",
    actionName: "",
    actionCode: "",
    actionDescription: "",
    additionalMappingProperties: [],
    dealsProperties: [],
    mappingKey: "",
    // pipeline: "",
    // dealStage: "",
    // leadStatus: "",
    // lifecycleStage: "",
  });

  const selectActionChange = (event) => {
    let action = actionsOptions.find((action) => action.value === event);

    if (action && action.hasOwnProperty("actionRelated")) setMappingKey(action.actionRelated);

    setDescription(action.description);
    setActionCode(action.value);
  };

  const getFieldsState = (key) => {
    api(
      formUrl(API.integrations.hubspot.getRulesCustomFields, {
        key: key,
        fieldName: "hs_lead_status,lifecyclestage",
      })
    ).then((response) => {
      if (response.data) {
        setLeadStatusState(response.data["hs_lead_status"]);
        setLifecycleState(response.data["lifecyclestage"]);
        setIsFinished(true);
      }
    });
  };

  useEffect(() => {
    let list = [];
    let item = actionsOptionsGrouped.find((x) => x.eventValue === triggerType);
    if (item) list = item.actions;
    setGroupedActions(list);
  }, [triggerType]);

  useEffect(() => {
    if (editCustomRuleId > 0) {
      fetchCustomRuleById(editCustomRuleId);
    }
  }, [editCustomRuleId, fetchCustomRuleById]);

  useEffect(() => {
    if (editCustomRule) {
      if (editCustomRule.additionalMappingProperties && editCustomRule.additionalMappingProperties.globalMapping !== "") {
        setMappingKey(editCustomRule.additionalMappingProperties.globalMapping);
      } else if (editCustomRule.dealsProperties) {
        setMappingKey("deals");
      }

      setDescription(editCustomRule.description);
      setActionName(editCustomRule.name);
      setActionCode(editCustomRule.actionCode);
      setTriggerType(editCustomRule.eventType);
    }
  }, [editCustomRule]);

  useEffect(() => {
    if (dealsPipelines && dealsPipelines.length > 0 && mappingKey === "deals") {
      setDealsPipelineState(dealsPipelines);
    }
  }, [dealsPipelines, mappingKey]);

  useEffect(() => {
    if (dealStages && dealStages.length > 0 && mappingKey === "deals") {
      setDealStagesState(dealStages);
      setIsFinished(true);
    }
  }, [dealStages, mappingKey]);

  useEffect(() => {
    if (
      editCustomRule !== null &&
      isFinished &&
      (dealsPipelineState.length > 0 || (lifecycleStageState.length > 0 && leadStatusState.length > 0))
    ) {
      const newFormValues = { ...formValues };
      newFormValues.id = editCustomRuleId;
      newFormValues.eventType = editCustomRule.eventType;
      newFormValues.actionName = editCustomRule.name;
      newFormValues.actionCode = editCustomRule.actionCode;
      newFormValues.actionDescription = editCustomRule.description;
      newFormValues.additionalMappingProperties = editCustomRule.additionalMappingProperties;
      newFormValues.dealsProperties = editCustomRule.dealsProperties;
      newFormValues.mappingKey = mappingKey;

      setFormValues(newFormValues);
    }
  }, [dealsPipelineState, lifecycleStageState, leadStatusState, isFinished]);

  useEffect(() => {
    if (mappingKey !== "") {
      if (mappingKey === "deals") {
        fetchDealsPipelines();
        if (editCustomRule.dealsProperties && editCustomRule.dealsProperties.pipeline !== "") {
          setPipelineId(editCustomRule.dealsProperties.pipeline);
        }
      } else if (mappingKey === "companies") {
        // fetchCompaniesProperties();
        getFieldsState(mappingKey);
      } else if (mappingKey === "contacts") {
        getFieldsState(mappingKey);
      }
    }
  }, [mappingKey]);

  useEffect(() => {
    if (mappingKey === "deals" && pipelineId !== "") {
      fetchDealsPipelineById(pipelineId);
    }
  }, [pipelineId]);

  return (
    <div className={styles.fieldsWrapper}>
      <div className={styles.componentWrapper}>
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          validationSchema={null}
          onSubmit={(values, actions) => {
            if (mappingKey === "deals") values.additionalMappingProperties = null;
            else values.dealsProperties = null;

            let model = {
              id: values.id,
              eventType: triggerType,
              name: actionName,
              actionCode: actionCode,
              description: description,
              additionalMappingProperties: values.additionalMappingProperties,
              dealsProperties: values.dealsProperties,
            };

            if (model.additionalMappingProperties !== null) model.additionalMappingProperties.globalMapping = mappingKey;

            api(API.integrations.hubspot.editCustomRules, model)
              .then((response) => {
                window.location.reload();
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);

                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                } else {
                  let errorMessage = error.message;
                  if (error?.response?.data) errorMessage = error.response.data;
                  toast.error(errorMessage, { autoClose: false });
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formikProps) => {
            const { values, handleSubmit, setFieldValue } = formikProps;

            return (
              <>
                <div className={styles.navWrapper}>
                  <h3>Edit rule</h3>
                  {/* <BreadcrumbElement items={breadcrumbs} /> */}
                  <div className={styles.buttonsWrapper}>
                    <Button id="customRuleCancel" variant="secondary" onClick={() => window.location.reload()}>
                      Cancel
                    </Button>
                    <Button id="customRuleSave" variant="primary" onClick={handleSubmit}>
                      Save rule
                    </Button>
                  </div>
                </div>
                <div className={styles.formsWrapper}>
                  <div className="flex">
                    <Field value={values.id} type="hidden" name={"id"} />
                    <div className={styles.triggerEventForm}>
                      <CombinationMarkLogoDark width="30%" height="100%" />
                      <FormikSelect
                        label="Trigger"
                        name="eventType"
                        enableReinitialize={true}
                        options={triggerOptions}
                        value={values.eventType}
                        formgroupClass="mT20"
                        onChange={(event) => setTriggerType(event)}
                      />
                      <div className={styles.formWrapper}></div>
                    </div>
                    <div className={styles.actionForm}>
                      <HubSpotLogo width="25%" height="100%" />
                      <FormikSelect
                        label="Action"
                        name="name"
                        enableReinitialize={true}
                        options={groupedActions}
                        value={values.actionCode}
                        onChange={selectActionChange}
                        formgroupClass="mT20"
                      />
                    </div>
                  </div>
                  <div>
                    {mappingKey === "contacts" && leadStatusState.length > 0 && lifecycleStageState.length > 0 ? (
                      <>
                        <p className={styles.mappingTitle}>Define mapping for HubSpot action fields</p>

                        <div className={styles.formGroupWrapper}>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lead Status"
                              name="additionalMappingProperties.leadStatus"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={leadStatusState}
                              value={values.additionalMappingProperties?.leadStatus}
                              isSearchable={true}
                            />
                          </div>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lifecycle Stage"
                              name="additionalMappingProperties.lifecycleStage"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={lifecycleStageState}
                              value={values.additionalMappingProperties?.lifecycleStage}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </>
                    ) : mappingKey === "companies" && leadStatusState.length > 0 && lifecycleStageState.length > 0 ? (
                      <>
                        <p className={styles.mappingTitle}>Define mapping for HubSpot action fields</p>

                        <div className={styles.formGroupWrapper}>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lead Status"
                              name="additionalMappingProperties.leadStatus"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={leadStatusState}
                              value={values.additionalMappingProperties?.leadStatus}
                              isSearchable={true}
                            />
                          </div>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Lifecycle Stage"
                              name="additionalMappingProperties.lifecycleStage"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={lifecycleStageState}
                              value={values.additionalMappingProperties?.lifecycleStage}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </>
                    ) : mappingKey === "deals" && dealsPipelineState && dealsPipelineState.length > 0 ? (
                      <>
                        <p className={styles.mappingTitle}>Define mapping for HubSpot action fields</p>
                        <div className={styles.formGroupWrapper}>
                          {/* <div className={styles.formGroup}>
                            <FormikSelect
                              label="Deal Name"
                              name='dealsProperties.dealNameSetup'
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={DealNameOptions}
                              value={values.dealsProperties?.dealNameSetup}
                              isSearchable={true}
                            />
                          </div> */}
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Pipeline"
                              name="dealsProperties.pipeline"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={dealsPipelineState}
                              value={values.dealsProperties?.pipeline}
                              onChange={(event) => {
                                setPipelineId(event);
                              }}
                              isSearchable={true}
                            />
                          </div>
                          <div className={styles.formGroup}>
                            <FormikSelect
                              label="Deal Stage"
                              name="dealsProperties.dealStage"
                              enableReinitialize={true}
                              formgroupClass={`${styles.selectWrapper} m0`}
                              options={dealStagesState}
                              value={values.dealsProperties?.dealStage}
                              isSearchable={true}
                              disabled={dealStagesState.length === 0}
                            />
                          </div>
                        </div>
                      </>
                    ) : isLoading ? (
                      <Spinner />
                    ) : null}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editCustomRule: state.hubspotIntegration.editCustomRule,
    dealsProperties: state.hubspotIntegration.dealsProperties,
    companiesProperties: state.hubspotIntegration.companiesProperties,
    contactsProperties: state.hubspotIntegration.contactsProperties,
    customFields: state.hubspotIntegration.customFields,
    dealsPipelines: state.hubspotIntegration.dealsPipelines,
    dealStages: state.hubspotIntegration.dealsPipeline,
    isLoading: state.hubspotIntegration.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomRuleById: (id) => dispatch(fetchCustomRuleById(id)),
    fetchDealsProperties: () => dispatch(fetchDealsProperties()),
    fetchContactsProperties: () => dispatch(fetchContactsProperties()),
    fetchCompaniesProperties: () => dispatch(fetchCompaniesProperties()),
    fetchDealsPipelines: () => dispatch(fetchDealsPipelines()),
    fetchDealsPipelineById: (id) => dispatch(fetchDealsPipelineById(id)),
    fetchRulesCustomFields: (key, fieldName) => dispatch(fetchRulesCustomFields(key, fieldName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomRule);
