import React, { useState } from "react";

// Styles
import styles from "./ConnectCRM.module.scss";

//Images
import EmptyCRMList from "../../../../assets/Images/CRM/EmptyCRMList/EmptyCRMList";

// Components
import Button from "../../../../components/UI/Button/Button";
import ConnectCRMForm from "../ConnectCRMForm/ConnectCRMForm";

const ConnectCRM = (props) => {
  const [showConnectCrmModal, setShowConnectCrmModal] = useState(false);
  const handleConnectCrmShowModalClose = () => {
    setShowConnectCrmModal(false);
  };
  const handleConnectCrmShow = () => setShowConnectCrmModal(true);

  return (
    <div className={styles.componentWrapper}>
      <EmptyCRMList className={styles.connectCrmImg} />
      <div className={styles.actionWrapper}>
        <p className="mB15">Start by connecting your CRM account</p>
        <Button id="connectCrm" variant="primary" onClick={handleConnectCrmShow}>
          Connect CRM
        </Button>
      </div>
      <ConnectCRMForm handleClose={handleConnectCrmShowModalClose} showModal={showConnectCrmModal} />
    </div>
  );
};

export default ConnectCRM;
