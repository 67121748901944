import React from "react";

const IconCompany = (props) => (
  <svg className={props.imgStyle} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 6.91536C9.47241 6.91536 10.6663 5.72146 10.6663 4.2487C10.6663 2.77594 9.47241 1.58203 7.99967 1.58203C6.52691 1.58203 5.33301 2.77594 5.33301 4.2487C5.33301 5.72146 6.52691 6.91536 7.99967 6.91536ZM3.66634 8.91536C4.58681 8.91536 5.33301 8.16916 5.33301 7.2487C5.33301 6.32822 4.58681 5.58203 3.66634 5.58203C2.74587 5.58203 1.99967 6.32822 1.99967 7.2487C1.99967 8.16916 2.74587 8.91536 3.66634 8.91536ZM13.9997 7.2487C13.9997 8.16916 13.2535 8.91536 12.333 8.91536C11.4125 8.91536 10.6663 8.16916 10.6663 7.2487C10.6663 6.32822 11.4125 5.58203 12.333 5.58203C13.2535 5.58203 13.9997 6.32822 13.9997 7.2487ZM7.99967 7.58203C9.84061 7.58203 11.333 9.07443 11.333 10.9154V14.9154H4.66634V10.9154C4.66634 9.07443 6.15873 7.58203 7.99967 7.58203ZM3.33301 10.9153C3.33301 10.4534 3.40012 10.0071 3.52513 9.58576L3.4121 9.59563C2.24303 9.7223 1.33301 10.7126 1.33301 11.9153V14.9153H3.33301V10.9153ZM14.6663 14.9153V11.9153C14.6663 10.6739 13.6969 9.6589 12.4742 9.58576C12.5992 10.0071 12.6663 10.4534 12.6663 10.9153V14.9153H14.6663Z"
      fill="#CACCD1"
    />
  </svg>
);

export default IconCompany;
