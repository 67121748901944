export const MAP_CONTACTS_AND_COMPANIES_PROPERTIES_REQUEST =
  "MAP_CONTACTS_AND_COMPANIES_PROPERTIES_REQUEST";
export const MAP_CONTACTS_AND_COMPANIES_PROPERTIES_SUCCEEDED =
  "MAP_CONTACTS_AND_COMPANIES_PROPERTIES_SUCCEEDED";
export const MAP_CONTACTS_AND_COMPANIES_PROPERTIES_FAILED =
  "MAP_CONTACTS_AND_COMPANIES_PROPERTIES_FAILED";

export const EDIT_HUBSPOT_INTEGRATION_REQUEST =
  "EDIT_HUBSPOT_INTEGRATION_REQUEST";
export const EDIT_HUBSPOT_INTEGRATION_SUCCEEDED =
  "EDIT_HUBSPOT_INTEGRATION_SUCCEEDED";
export const EDIT_HUBSPOT_INTEGRATION_FAILED =
  "EDIT_HUBSPOT_INTEGRATION_FAILED";

export const DELETE_HUBSPOT_INTEGRATION_REQUEST =
  "DELETE_HUBSPOT_INTEGRATION_REQUEST";
export const DELETE_HUBSPOT_INTEGRATION_SUCCEEDED =
  "DELETE_HUBSPOT_INTEGRATION_SUCCEEDED";
export const DELETE_HUBSPOT_INTEGRATION_FAILED =
  "DELETE_HUBSPOT_INTEGRATION_FAILED";

export const ADD_HUBSPOT_RULESET_REQUEST = "ADD_HUBSPOT_RULESET_REQUEST";
export const ADD_HUBSPOT_RULESET_SUCCEEDED = "ADD_HUBSPOT_RULESET_SUCCEEDED";
export const ADD_HUBSPOT_RULESET_FAILED = "ADD_HUBSPOT_RULESET_FAILED";

export const ADD_HUBSPOT_RULE_REQUEST = "ADD_HUBSPOT_RULE_REQUEST";
export const ADD_HUBSPOT_RULE_SUCCEEDED = "ADD_HUBSPOT_RULE_SUCCEEDED";
export const ADD_HUBSPOT_RULE_FAILED = "ADD_HUBSPOT_RULE_FAILED";

export const EDIT_HUBSPOT_RULE_REQUEST = "EDIT_HUBSPOT_RULE_REQUEST";
export const EDIT_HUBSPOT_RULE_SUCCEEDED = "EDIT_HUBSPOT_RULE_SUCCEEDED";
export const EDIT_HUBSPOT_RULE_FAILED = "EDIT_HUBSPOT_RULE_FAILED";

export const DELETE_HUBSPOT_RULE_REQUEST = "DELETE_HUBSPOT_RULE_REQUEST";
export const DELETE_HUBSPOT_RULE_SUCCEEDED = "DELETE_HUBSPOT_RULE_SUCCEEDED";
export const DELETE_HUBSPOT_RULE_FAILED = "DELETE_HUBSPOT_RULE_FAILED";

export const FETCH_HUBSPOT_RULES_REQUEST = "FETCH_HUBSPOT_RULES_REQUEST";
export const FETCH_HUBSPOT_RULES_SUCCEEDED = "FETCH_HUBSPOT_RULES_SUCCEEDED";
export const FETCH_HUBSPOT_RULES_FAILED = "FETCH_HUBSPOT_RULES_FAILED";

export const FETCH_CONTACT_PROPERTIES_REQUEST =
  "FETCH_CONTACT_PROPERTIES_REQUEST";
export const FETCH_CONTACT_PROPERTIES_SUCCEEDED =
  "FETCH_CONTACT_PROPERTIES_SUCCEEDED";
export const FETCH_CONTACT_PROPERTIES_FAILED =
  "FETCH_CONTACT_PROPERTIES_FAILED";

export const FETCH_COMPANIES_PROPERTIES_REQUEST =
  "FETCH_COMPANIES_PROPERTIES_REQUEST";
export const FETCH_COMPANIES_PROPERTIES_SUCCEEDED =
  "FETCH_COMPANIES_PROPERTIES_SUCCEEDED";
export const FETCH_COMPANIES_PROPERTIES_FAILED =
  "FETCH_COMPANIES_PROPERTIES_FAILED";

export const FETCH_DEALS_PROPERTIES_REQUEST = "FETCH_DEALS_PROPERTIES_REQUEST";
export const FETCH_DEALS_PROPERTIES_SUCCEEDED =
  "FETCH_DEALS_PROPERTIES_SUCCEEDED";
export const FETCH_DEALS_PROPERTIES_FAILED = "FETCH_DEALS_PROPERTIES_FAILED";

export const GET_CUSTOM_RULE_BY_ID_REQUEST = "GET_CUSTOM_RULE_BY_ID_REQUEST";
export const GET_CUSTOM_RULE_BY_ID_SUCCEEDED =
  "GET_CUSTOM_RULE_BY_ID_SUCCEEDED";
export const GET_CUSTOM_RULE_BY_ID_FAILED = "GET_CUSTOM_RULE_BY_ID_FAILED";

export const GET_RULES_CUSTOM_FIELDS_REQUEST =
  "GET_RULES_CUSTOM_FIELDS_REQUEST";
export const GET_RULES_CUSTOM_FIELDS_SUCCEEDED =
  "GET_RULES_CUSTOM_FIELDS_SUCCEEDED";
export const GET_RULES_CUSTOM_FIELDS_FAILED = "GET_RULES_CUSTOM_FIELDS_FAILED";

export const GET_DEALS_PIPELINES_REQUEST = "GET_DEALS_PIPELINES_REQUEST";
export const GET_DEALS_PIPELINES_SUCCEEDED = "GET_DEALS_PIPELINES_SUCCEEDED";
export const GET_DEALS_PIPELINES_FAILED = "GET_DEALS_PIPELINES_FAILED";

export const GET_DEALS_PIPELINE_BY_ID_REQUEST =
  "GET_DEALS_PIPELINE_BY_ID_REQUEST";
export const GET_DEALS_PIPELINE_BY_ID_SUCCEEDED =
  "GET_DEALS_PIPELINE_BY_ID_SUCCEEDED";
export const GET_DEALS_PIPELINE_BY_ID_FAILED =
  "GET_DEALS_PIPELINE_BY_ID_FAILED";

export const GET_CONFIGURATION_REQUEST = "GET_CONFIGURATION_REQUEST";
export const GET_CONFIGURATION_SUCCEEDED = "GET_CONFIGURATION_SUCCEEDED";
export const GET_CONFIGURATION_FAILED = "GET_CONFIGURATION_FAILED";

export const GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_REQUEST =
  "GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_REQUEST";
export const GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_SUCCEEDED =
  "GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_SUCCEEDED";
export const GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_FAILED =
  "GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_FAILED";
