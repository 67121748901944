import React, { useEffect, useState, useRef, useMemo } from "react";
import { Container, OverlayTrigger, Row, Popover } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import _, { debounce } from "lodash";
import { AgGridReact } from "ag-grid-react";

//Helpers
import { numberOfRecords } from "../../helper/tableConstants";

//Styles
import "./Table.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

//Components
import ShowingPerPage from "./ShowingPerPage/ShowingPerPage";
import CustomColumnHeader from "./CustomColumnHeader";

const Table = (props) => {
  const {
    param = { sort: { column: "", direction: "" }, page: 0, length: 10, filter: [] },
    totalCount = 10,
    filters,
    showPagination,
    showPerPageContent,
    hideSort,
    disabledRows,
  } = props;

  let showStart = param.start + 1;
  let showEnd = param.start + param.length;

  let gridClassName = props.wrapperTableClass ? `${props.wrapperTableClass} ` : ""; // Add props.wrapperTableClass if defined
  gridClassName += props.outBoss ? "outBoss" : props.nestedHeader ? "nestedHeader" : "outbaseTable"; // Add outBoss or outbaseTable class

  let classes = `defaultStyle`;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const gridApiRef = useRef(null);

  const [rows, setRows] = useState([]);
  const columns = useMemo(() => {
    return _.cloneDeep(props.columns);
  }, [props.columns]);
  // const [columns, setColumns] = useState([]);

  const debouncedSortHandler = useMemo(
    () =>
      debounce((column) => {
        if (!column) return;

        const currentSort = param.sort || { column: "", direction: "" };

        let newObj = { column: column, direction: "asc" };
        if (currentSort.column === column && currentSort.direction === "asc") {
          newObj.direction = "desc";
        }

        if (props.parentSort) props.parentSort(newObj);
      }, 300),
    [param.sort, props.parentSort]
  );

  useEffect(() => {
    return () => {
      debouncedSortHandler.cancel();
    };
  }, [debouncedSortHandler]);

  const [pageChangeActive, setPageChangeActive] = useState(false);

  const hasSearchable = columns.find((x) => x.searchable === true) != null;
  if (!_.isEmpty(props.tableClassName)) classes += " " + props.tableClassName;
  if (!_.isEmpty(props.variant)) classes += " " + [props.variant];
  if (hasSearchable) classes += " " + "searchableTable";
  if (showEnd > totalCount) {
    showEnd = totalCount;
  }

  let showingPerPage = true;
  if (!showPerPageContent && showPerPageContent !== undefined) {
    showingPerPage = false;
  }
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (gridApiRef?.current?.ensureIndexVisible) {
      gridApiRef.current.ensureIndexVisible(0, "top");
    }
  }, [param.page]);

  useEffect(() => {
    let rowsSet = false;
    const rowsTemp = _.cloneDeep(props.rows);

    const addTooltipsToColumns = (row, columns, index) => {
      columns.forEach((column) => {
        const prop = column.field;
        let key = 0;

        if (Object.prototype.hasOwnProperty.call(row, prop)) {
          let className = "grid-default";
          let showTooltip = false;

          if (column.type != null) {
            switch (column.type) {
              case "text":
                className = "grid-text-default text-overflow-hidden";
                break;
              case "number":
                className = "grid-number-default text-overflow-hidden";
                break;
              case "date":
                className = "grid-date-default ";
                break;
              case "actions":
                className = "grid-actions-default";
                break;
              default:
                break;
            }
          }

          if (column.class != null) {
            className += " " + column.class;
          }

          if (column.showTooltip && column.showTooltip === true) {
            showTooltip = true;
          }

          if (disabledRows && disabledRows.length > 0) {
            if (disabledRows.some((x) => x === index)) {
              className += " " + "rowDisabled";
            }
          }

          if (showTooltip === true) {
            row[prop] = (
              <div className="text-overflow-hidden">
                <OverlayTrigger
                  key={"key"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`popover-tooltip`}>
                      <Popover.Body>{row[prop]}</Popover.Body>
                    </Popover>
                  }
                >
                  <div className="max-x-content">
                    <div key={key} className={className}>
                      {row[prop]}
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            );
          } else {
            row[prop] = (
              <div key={key} className={className}>
                {row[prop]}
              </div>
            );
          }

          key++;
        }

        if (column.children && column.children.length > 0) {
          addTooltipsToColumns(row, column.children, index);
        }
      });
    };

    rowsTemp.forEach((row, index) => {
      addTooltipsToColumns(row, props.columns, index);
    });

    if (!rowsSet) setRows(rowsTemp);
    setPageChangeActive(false);
  }, [props.rows, props.columns, disabledRows]);

  const pageHandler = (selected) => {
    props.parentCallback(selected.selected);
  };

  const sortHandler = (column) => {
    if (!column) {
      return;
    }

    const currentSort = param.sort || { column: "", direction: "" };

    let newObj = { column: column, direction: "asc" };
    if (currentSort.column === column && currentSort.direction === "asc") {
      newObj.direction = "desc";
    }

    if (props.parentSort) props.parentSort(newObj);
  };

  let selects = null;
  if (filters !== undefined) {
    selects = props.filters.map((f, index) => {
      return (
        <div key={`${index}-${f.selectName}`}>
          <Select isMulti name={f.selectName} options={f.selectOptions} className="basic-multi-select" classNamePrefix="select" />
        </div>
      );
    });
  }

  let pagination = null;
  if (showPagination !== false && totalCount) {
    pagination = (
      <div className={`tableFooter ${props.tableFooterClass}`}>
        <div className="elementsWrapper">
          {showingPerPage ? (
            <div>
              <ShowingPerPage
                numberOfRecords={numberOfRecords}
                change={props.changeNumRecords}
                defaultValue={
                  props.showPerPageValue != null
                    ? numberOfRecords.find((n) => n.value === props.showPerPageValue)
                    : numberOfRecords.find((n) => n.value === props.param.length)
                }
                {...props}
              />
            </div>
          ) : null}
          <div
            className={`
           ${props.hideLabelShow ? "hiddenLabel" : "paginationLabel"} 
          ${pageChangeActive ? "disablePagination" : ""}
          paginationWrapper`}
          >
            <ReactPaginate
              disabledClassName={"pageHandlerDisabled"}
              pageCount={Math.ceil(props.pageCount)}
              pageRangeDisplayed={props.pageRangeDisplayed !== undefined ? props.pageRangeDisplayed : 5}
              marginPagesDisplayed={props.marginPagesDisplayed !== undefined ? props.marginPagesDisplayed : 2}
              nextLabel={props.nextLabel !== undefined ? props.nextLabel : ">"}
              previousLabel={props.previousLabel !== undefined ? props.previousLabel : "<"}
              containerClassName={`pagination pagesList`}
              subContainerClassName={"pages pagination"}
              activeClassName="active"
              onPageChange={(selected) => {
                if (!pageChangeActive) setPageChangeActive(true);
                pageHandler(selected);
              }}
              pageClassName={props.pageClassName !== undefined ? props.pageClassName : "mx-2"}
              previousClassName="paginationPrevious"
              nextClassName="paginationNext"
              forcePage={props.forcePageChanged ? param.page : null}
            />

            {!props.showingOf ? null : (
              <p className="totalCount">
                {showStart}-{showEnd} of {totalCount}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  const colDefs = _.cloneDeep(props.columns).map(({ ...column }) => ({
    field: column.field,
    type: column.type,
    width: column.width,
    minWidth: column.minWidth,
    flex: column.flex,
    headerName: column.headerName,
    pinned: column.pinned,
    cellClass: column.cellClass,
    headerClass: column.headerClass,
    rowSpan: (params) => {
      // Return the number of columns that the cell should span
      // This can be a fixed number, or it can depend on the `params` object
      return column.rowSpan ? column.rowSpan : 1;
    },
    cellRenderer: (params) => {
      return <>{params.value}</>;
    },
    valueFormatter: (params) => {
      return params.value?.toString();
    },
    headerComponent: "customColumnHeader",
    headerComponentParams: {
      columnDefinition: column,
      params: param,
      selectAllRows: props.selectAllRows,
      allRowsSelected: props.allRowsSelected,
      setFilter: props.setFilter,
      filterData: props.filterData,
      parentCallback: props.parentCallback,
      parentSort: props.sortData,
      hideSort: hideSort,
      sortHandler: debouncedSortHandler,
      sort: param.sort,
    },
    autoHeight: true,
    sortable: column.sort !== "disabled",
    lockPosition: true,
    resizable: false,
    // children: column.children,
    headerTooltip: column.label,
    headerGroupComponent: column.children ? "customHeaderGroupComponent" : undefined,
    headerGroupComponentParams: column.children
      ? {
          columnDefinition: column,
          params: param,
          selectAllRows: props.selectAllRows,
          allRowsSelected: props.allRowsSelected,
          setFilter: props.setFilter,
          filterData: props.filterData,
          parentCallback: props.parentCallback,
          parentSort: props.sortData,
          hideSort: hideSort,
          sortHandler: sortHandler,
          sort: param.sort,
        }
      : undefined,
    children: column.children?.map((childColumn) => ({
      field: childColumn.field,
      type: childColumn.type,
      width: childColumn.width,
      minWidth: childColumn.minWidth,
      flex: childColumn.flex,
      headerName: childColumn.headerName,
      pinned: childColumn.pinned,
      cellClass: childColumn.cellClass,
      headerClass: childColumn.headerClass,
      wrapHeaderText: childColumn.wrapHeaderText,
      autoHeaderHeight: true,
      headerTooltip: childColumn.label,
      cellRenderer: (params) => {
        return <>{params.value}</>;
      },
      headerComponent: "customColumnHeader",
      headerComponentParams: {
        columnDefinition: childColumn,
        params: param,
        selectAllRows: props.selectAllRows,
        allRowsSelected: props.allRowsSelected,
        setFilter: props.setFilter,
        filterData: props.filterData,
        parentCallback: props.parentCallback,
        parentSort: props.sortData,
        hideSort: hideSort,
        sortHandler: sortHandler,
        sort: param.sort,
      },
      autoHeight: true,
      sortable: childColumn.sort !== "disabled",
      lockPosition: true,
      resizable: false,
    })),
  }));

  const gridOptions = {
    columnDefs: colDefs,
    suppressAnimationFrame: true,
    suppressRowTransform: true,
    animateRows: false,
    columnTypes: {
      action: {
        cellRenderer: (params) => {
          return <>{params.value}</>;
        },
        cellClass: "grid-actions-default",
      },
      text: {},
      actions: {},
      number: {},
      date: {},
      checkbox: {},
    },
    components: {
      customColumnHeader: CustomColumnHeader,
      customHeaderGroupComponent: CustomColumnHeader,
    },
    ...props.gridOptions,
  };

  const gridOptionsMap = {
    large: {
      rowHeight: 65,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    },
    medium: {
      rowHeight: 100,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    },
    small: {
      rowHeight: 40,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    },
  };

  const variantOptions = gridOptionsMap[props.variant] || {};
  const mergedGridOptions = {
    ...gridOptions,
    ...variantOptions,
  };

  return (
    <>
      <div
        style={{
          height: props.tableHeight ? windowHeight - props.tableHeight + "px" : "100%",
        }}
      >
        <Container>
          <Row>{selects}</Row>
        </Container>

        <div
          className="ag-theme-quartz"
          style={{
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <AgGridReact
            ref={gridApiRef}
            className={gridClassName.trim()}
            rowClass={classes}
            rowData={rows}
            gridOptions={mergedGridOptions}
            columnDefs={colDefs}
            onGridReady={(params) => {
              gridApiRef.current = params.api;
            }}
            suppressScrollOnNewData={true}
          />
        </div>
        {pagination}
      </div>
    </>
  );
};

export default Table;
