import React from "react";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Helper
import { isIt } from "../../../helper/objectHelper";

//Style
import styles from "./FormikInput.module.scss";

//Images & Icons
import IconIncludeValue from "../../../assets/Icons/IconIncludeValue/IconIncludeValue";
import IconExcludeValue from "../../../assets/Icons/IconExcludeValue/IconExcludeValue";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

const FormikSelectValuesWrapper = (props) => {
  const { values, allowIncludeExclude } = props;
  const deleteSelectedOption = (deleteOption) => {
    if (isIt.object(deleteOption) && !isIt.string(deleteOption)) {
      const deleteValue = deleteOption.value;
      const updateValues = (values, changeHandler) => {
        const valuesIndex = values.findIndex((item) => item.value === deleteValue);
        if (valuesIndex !== -1) {
          const newValues = [...values];
          newValues.splice(valuesIndex, 1);
          changeHandler(newValues, true);
        }
      };

      if (values) {
        updateValues(values, props.changeHandler);
      }
    } else if (isIt.string(deleteOption) || isIt.number(deleteOption)) {
      const updateValues = (values, changeHandler) => {
        const valuesIndex = values.findIndex((item) => item === deleteOption);
        if (valuesIndex !== -1) {
          const newValues = [...values];
          newValues.splice(valuesIndex, 1);
          changeHandler(newValues, true);
        }
      };

      if (values) {
        updateValues(values, props.changeHandler);
      }
    }
  };

  const includeExcludeValue = (includeExcludeValue) => {
    if (values && values.some((item) => item.value === includeExcludeValue.value) === true) {
      const updatedIncludeExcludeValues = values.map((item) =>
        item.value === includeExcludeValue.value ? { ...item, included: !item.included } : item
      );

      props.changeHandler(updatedIncludeExcludeValues);
    }
  };

  const generateListItem = (val) => {
    const label = val.label
      ? val.label
      : props.options && props.options.length > 0
      ? props.options.find((option) => option.value === val)?.label
      : val;

    return (
      <li className={val.included === undefined ? styles.fieldValue : val.included === true ? styles.includedValue : styles.excludedValue}>
        {allowIncludeExclude && val.included != undefined && (
          <div className={styles.includeExcludeOption} onClick={() => includeExcludeValue(val)}>
            {/* We are checking true/false here on purpose, because we want to display nothing in case the value is undefined. */}
            {val.included === true && <IconExcludeValue />}
            {val.included === false && <IconIncludeValue />}
          </div>
        )}
        <OverlayTrigger
          key={val}
          placement={"bottom"}
          overlay={
            <Popover id={`value-tooltip`}>
              <Popover.Body>{label}</Popover.Body>
            </Popover>
          }
        >
          <div className="textOverflow">
            <span className={styles.pillsLabel}>{label}</span>
          </div>
        </OverlayTrigger>
        <div>
          <span className={styles.deleteOption} onClick={() => deleteSelectedOption(val)}>
            <CloseLineIcon />
          </span>
        </div>
      </li>
    );
  };

  if (!values || values.length == 0) {
    return null;
  }

  if (allowIncludeExclude) {
    return (
      <>
        <label className="mT10">Included</label>
        <ul className={styles.chosenValues}>
          {values
            .filter((val) => val.included === undefined || val.included === true)
            .map((val, index) => {
              return generateListItem(val);
            })}
        </ul>
        <label className="mT10">Excluded</label>
        <ul className={styles.chosenValues}>
          {values
            .filter((val) => val.included === false)
            .map((val, index) => {
              return generateListItem(val);
            })}
        </ul>
      </>
    );
  }

  return (
    <>
      <ul className={`${styles.chosenValues} mT10`}>
        { values && Array.isArray(values) && values.length > 0 && values.map((val, index) => {
          return generateListItem(val);
        })}
      </ul>
    </>
  );
};

export default FormikSelectValuesWrapper;
