import React from "react";

//Styles
import styles from "./ResetAIWriterForm.module.scss";

//Components

import Modal from "../../../../components/UI/Modal/Modal";

const ResetAIWriterForm = (props) => {
  return (
    <Modal
      title={"Reset AI Template"}
      handleClose={props.handleClose}
      handleSave={props.resetAIPrompt}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Continue"
      btnType="submit"
    >
      <>
        <p className="f13 secondary_text_color">If you proceed, the system will start a new AI request.</p>
        <p className="f13 secondary_text_color">
          <strong>Note:</strong> Beware all unsaved AI responses will be lost.
        </p>
      </>
    </Modal>
  );
};

export default ResetAIWriterForm;
