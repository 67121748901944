import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utility";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  orgInvities: [],
};

const loadOrganizationInvities = (state, action) => {
  const newState = { ...state };
  newState.isLoading = false;
  newState.isLoaded = true;
  newState.orgInvities = action.result;
  return updateObject(state, newState);
};

const acceptInviteRequest = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = false;
  newState.isLoading = true;
  return newState;
};

const acceptInviteFailed = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.error = action.result;
  return newState;
};

const declineInviteRequest = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = false;
  newState.isLoading = true;
  return newState;
};

const declineInviteFailed = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.error = action.result;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORGANIZATION_INVITES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_ORGANIZATION_INVITES_SUCCESS:
      return loadOrganizationInvities(state, action);
    case actionTypes.FETCH_ORGANIZATION_INVITES_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        orgInvities: [],
        error: action.result,
      };
    case actionTypes.ACCEPT_INVITE_REQUEST:
      return acceptInviteRequest(state, action);
    case actionTypes.ACCEPT_INVITE_FAILED:
      return acceptInviteFailed(state, action);
    case actionTypes.DECLINE_INVITE_REQUEST:
      return declineInviteRequest(state, action);
    case actionTypes.DECLINE_INVITE_FAILED:
      return declineInviteFailed(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
