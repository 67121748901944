import React from "react";
import svg from "./funnel_stage4.svg";

const Stage4 = (props) => (
  <svg width="172" height="63" viewBox="0 0 172 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M171.501 0.949432H0L12.0654 63H159.004L171.501 0.949432Z" fill="#21C4A8" />
  </svg>
);

export default Stage4;
