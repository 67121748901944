import { OverlayTrigger, Popover } from "react-bootstrap";
import { useFormikContext } from "formik";

// Enums
import { companyRevenueGrowthTimeframeOptions } from "@helper/enums/companyReveneueGrowthTimeframeOptions";
import { inputFieldSymbolPlaceEnum } from "@helper/enums/inputFieldSymbolPlaceEnum";

// Styles
import styles from "../Filters.module.scss";

// Icons
import InformationFillIcon from "remixicon-react/InformationFillIcon";

// Components
import FormikSelect from "@ui/Formik/FormikSelect";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import FormikInput from "@ui/Formik/FormikInput";

const FilterCompanyHeadcountGrowth = () => {
  const {
    values: {
      company: {
        companyHeadcountGrowth: {
          min: companyHeadcountGrowthMin,
          max: companyHeadcountGrowthMax,
          timeframe: companyHeadcountGrowthTimeframe,
        },
      },
    },
  } = useFormikContext<SearchModel>();
  const timeframeOptions = companyRevenueGrowthTimeframeOptions;

  return (
    <>
      <div className="flex">
        <p className="mB5">Growth percentage Range</p>
        <div className={styles.infoIconTooltipNumber}>
          <OverlayTrigger
            key={"key"}
            placement={"right"}
            overlay={
              <Popover id={`popover-tooltip`}>
                <Popover.Body>
                  <p className="m0">
                    For positive growth, enter values like "5%" to "25%"
                    <br />
                    For negative growth, use values such as "-50%" to "-10%"
                    <br />
                    <small>Always put the lower number first.</small>
                  </p>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="max-x-content flex_center">
              <InformationFillIcon size={18} />
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div className="flex align-flex-start">
        <FormikInput
          allowNegative
          inputGroupClass="m0"
          type="numberWithSymbol"
          name="company.companyHeadcountGrowth.min"
          value={companyHeadcountGrowthMin}
          placeholder="0"
          inputclass="width_100"
          errorinputclass={styles.error}
          inputSymbol="%"
          inputSymbolPosition={inputFieldSymbolPlaceEnum.Suffix}
        />
        &nbsp; &nbsp; &nbsp;
        <p className="mT5">
          <b>-</b>
        </p>
        &nbsp; &nbsp; &nbsp;
        <FormikInput
          allowNegative
          inputGroupClass="m0"
          type="numberWithSymbol"
          name="company.companyHeadcountGrowth.max"
          value={companyHeadcountGrowthMax}
          placeholder="0"
          inputclass="width_100"
          errorinputclass={styles.error}
          inputSymbol="%"
          inputSymbolPosition={inputFieldSymbolPlaceEnum.Suffix}
        />
      </div>
      <p className="mB5">Timeframe</p>
      <FormikSelect
        showValuesOutside={false}
        isMulti={false}
        name="company.companyHeadcountGrowth.timeframe"
        value={companyHeadcountGrowthTimeframe}
        options={timeframeOptions}
        displayName="Select timeframe"
        isSearchable={true}
        enableReinitialize={true}
        dropdownButtonClass={styles.selectDropdown}
      />
    </>
  );
};

export default FilterCompanyHeadcountGrowth;
