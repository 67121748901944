export const FETCH_LIST_RESPONSE_TYPES_REQUEST = "FETCH_LIST_RESPONSE_TYPES_REQUEST";
export const FETCH_LIST_RESPONSE_TYPES_SUCCEEDED = "FETCH_LIST_RESPONSE_TYPES_SUCCEEDED";
export const FETCH_LIST_RESPONSE_TYPES_FAILED = "FETCH_LIST_RESPONSE_TYPES_FAILED";

export const FETCH_STATUS_COUNT_REQUEST = "FETCH_STATUS_COUNT_REQUEST";
export const FETCH_STATUS_COUNT_SUCCEEDED = "FETCH_STATUS_COUNT_SUCCEEDED";
export const FETCH_STATUS_COUNT_FAILED = "FETCH_STATUS_COUNT_FAILED";

export const FETCH_LIST_EMAIL_ACCOUNT_REQUEST = "FETCH_LIST_EMAIL_ACCOUNT_REQUEST";
export const FETCH_LIST_EMAIL_ACCOUNT_SUCCEEDED = "FETCH_LIST_EMAIL_ACCOUNT_SUCCEEDED";
export const FETCH_LIST_EMAIL_ACCOUNT_FAILED = "FETCH_LIST_EMAIL_ACCOUNT_FAILED";
