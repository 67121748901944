import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";

//Styles
import styles from "./AudienceColumn.module.scss";

const AudienceColumn = (props) => {
  let fullNameArr = props.createdByName != null ? props.createdByName.split(" ") : [];
  let nameCreatedBy = fullNameArr[0] ?? "";
  let initialSurnameCreatedBy = fullNameArr[1]?.charAt(0).toUpperCase() ?? "";

  let nameDisplay = "";
  if (fullNameArr.length > 0) {
    nameDisplay = `${nameCreatedBy} ${initialSurnameCreatedBy};`;
  }
  return (
    <>
      <OverlayTrigger
        key={props.name}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-${props.name}`}>
            <Popover.Body>{props.name}</Popover.Body>
          </Popover>
        }
      >
        <div className={props.isInitiated ? "text-overflow-hidden max-x-content" : styles.notInitiated}>
          <Link to={props.link}>
            <span className={`${styles.nameLink} tableLink f14 font700 textOverflow`}>{props.name}</span>
          </Link>
        </div>
      </OverlayTrigger>
      <div className={`${styles.detailsWrapper} ${props.isInitiated ? "" : styles.notInitiated}`}>
        <div className="flex">
          <p className="m0 pR5">{nameDisplay}</p> <p className="f12 m0">{props.date}</p>
        </div>
        <p className="m0">Source: {props.source}</p>
      </div>
    </>
  );
};

export default AudienceColumn;
