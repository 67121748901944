import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const fetchListOfCampaignsRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_OF_CAMPAIGNS_REQUEST,
  };
};

export const fetchListOfCampaignsSuccess = (campaigns) => {
  return {
    type: actionTypes.FETCH_LIST_OF_CAMPAIGNS_SUCCEEDED,
    result: campaigns,
  };
};

export const fetchListOfCampaignsFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_OF_CAMPAIGNS_FAILED,
    result: error,
  };
};

export const fetchListOfCampaigns = (prospectId) => {
  return (dispatch) => {
    dispatch(fetchListOfCampaignsRequest());
    return api(
      formUrl(API.prospectActivityOverview.getListOfCampaings, {
        prospectId: prospectId,
      })
    )
      .then((response) => {
        dispatch(fetchListOfCampaignsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListOfCampaignsFailed(error.message));
      });
  };
};

export const fetchProspectDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_PROSPECT_DETAILS_REQUEST,
  };
};

export const fetchProspectDetailsSuccess = (responseDetails) => {
  return {
    type: actionTypes.FETCH_PROSPECT_DETAILS_SUCCEEDED,
    result: responseDetails,
  };
};

export const fetchProspectDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_PROSPECT_DETAILS_FAILED,
    result: error,
  };
};

export const fetchProspectDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchProspectDetailsRequest());
    return api(
      formUrl(API.prospectActivityOverview.getProspectDetails, { id: id })
    )
      .then((response) => {
        dispatch(fetchProspectDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProspectDetailsFailed(error.message));
      });
  };
};

export const fetchTimelineResponsesRequest = () => {
  return {
    type: actionTypes.FETCH_TIMELINE_RESPONSES_REQUEST,
  };
};

export const fetchTimelineResponsesSuccess = (responses) => {
  return {
    type: actionTypes.FETCH_TIMELINE_RESPONSES_SUCCEEDED,
    result: responses,
  };
};

export const fetchTimelineResponsesFailed = (error) => {
  return {
    type: actionTypes.FETCH_TIMELINE_RESPONSES_FAILED,
    result: error,
  };
};

export const fetchTimelineResponses = (params, id) => {
  const data = {
    param: params,
    id: id,
  };

  return (dispatch) => {
    dispatch(fetchTimelineResponsesRequest());
    return api(API.prospectActivityOverview.getTimeline, data)
      .then((response) => {
        dispatch(fetchTimelineResponsesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTimelineResponsesFailed(error.message));
      });
  };
};

export const setFilter = (type, value) => {
  return {
    type: actionTypes.SET_FITLER,
    filterType: type,
    filterValue: value,
  };
};

export const clearFilter = (type) => {
  return {
    type: actionTypes.CLEAR_FILTER,
    filterType: type,
  };
};

export const fetchProspectStatuesesRequest = () => {
  return {
    type: actionTypes.FETCH_PROSPECT_STATUSES_REQUEST,
  };
};

export const fetchProspectStatuesesSuccess = (statuses) => {
  return {
    type: actionTypes.FETCH_PROSPECT_STATUSES_SUCCEEDED,
    result: statuses,
  };
};

export const fetchProspectStatuesesFailed = (error) => {
  return {
    type: actionTypes.FETCH_PROSPECT_STATUSES_FAILED,
    result: error,
  };
};

export const fetchProspectStatueses = (prospectId) => {
  return (dispatch) => {
    dispatch(fetchProspectStatuesesRequest());
    return api(
      formUrl(API.prospectActivityOverview.getProspectStatuses, {
        prospectId: prospectId,
      })
    )
      .then((response) => {
        dispatch(fetchProspectStatuesesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProspectStatuesesFailed(error.message));
      });
  };
};
