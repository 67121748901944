import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import Modal from "../../../components/UI/Modal/Modal";

//Styles

const ResetSyncModal = (props) => {
  return (
    <Modal
      title="Reset sync"
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>
              Are you sure you want to reset the sync for <b>{props.email}</b>?<br />
              This action will set LastSync to null, IsInProgress to false, IsQueued to false and Errors to 0.
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ResetSyncModal;
