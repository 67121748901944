import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";

//Redux
import { fetchSupportCenterRTE } from "../../../../store/ComplianceSupportCenter/actions/supportCenterQA";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

// Styles
import styles from "./RTE.module.scss";

//Images & Icons
import SectionInbox from "../../../../assets/Icons/SectionInbox/SectionInbox";

// Components
import RTEDataContent from "./RTEDataContent";
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import Button from "../../../../components/UI/Button/Button";
import Permission from "../../../../components/auth/Permission";
import FormikInput from "../../../../components/UI/Formik/FormikInput";

const RTE = (props) => {
  const { fetchSupportCenterRTE } = props;
  const { prospects, totalProspects, prospectActivityLog, totalProspectActivityLog } = props.rte;

  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchSupportCenterRTE(email);
  }, [email]);

  let rteData = null;
  if (!_.isEmpty(email)) {
    rteData = (
      <RTEDataContent
        prospects={prospects}
        email={email}
        setEmail={setEmail}
        totalProspects={totalProspects}
        prospectActivityLog={prospectActivityLog}
        totalProspectActivityLog={totalProspectActivityLog}
      />
    );
  }

  return (
    <>
      <SectionTitle icon={<SectionInbox />} title="RIGHT TO ERASURE (RTE) AND SUBJECT ACCESS REQUESTS (SAR)" />
      <span className={`${styles.infoDetails} f14`}>
        Received a request to supply a copy of (or erase) personal data stored in connection with a prospect? Use our handy tool to download
        and/or remove Personally Identifiable Information (PII) from our systems
      </span>
      <div className={styles.RTE}>
        <Formik
          initialValues={{
            email: email,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            email: Yup.string().email().required("Required"),
          }).required("Required")}
          onSubmit={(values, actions) => {
            setEmail(values.email);
          }}
        >
          {(formikProps) => {
            const { handleSubmit, isSubmitting, handleChange } = formikProps;

            return (
              <>
                <Form className="m0 width_400">
                  <div className="flex mT20">
                    <Field name="email" type="email" onChange={handleChange} placeholder="example@company.com" as={FormikInput} />
                    <Permission has={PERMISSIONS.compliance.rightToErase.view}>
                      <Button id="searchRte" disabled={isSubmitting} type="submit" onClick={handleSubmit} variant="primary" classes="mL10">
                        Search
                      </Button>
                    </Permission>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>

      {rteData}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rte: state.complianceSupportCenterQA.rte,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSupportCenterRTE: (email) => dispatch(fetchSupportCenterRTE(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RTE);
