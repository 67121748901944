import React, { useState } from "react";
import { Formik } from "formik";

//Helpers
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";
import { integrationsEnum } from "../../../../helper/enums/integrationsEnum";
import { FormikHelpers } from "formik";

//Style
import styles from "./ConnectCRMForm.module.scss";

//Component
import SelectCRMModal from "../SelectCRMModal/SelectCRMModal";
import Modal from "../../../../components/UI/Modal/Modal";
import AllowSelectedCRM from "../AllowSelectedCRM/AllowSelectedCRM";
import MappingOutbaseFields from "../MappingOutbaseFields/MappingOutbaseFields";

interface ConnectCRMFormProps {
  handleClose: () => void;
  showModal: boolean;
}

const ConnectCRMForm: React.FC<ConnectCRMFormProps> = (props) => {
  let renderPage: JSX.Element | null = null;
  const [page, setPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [integrationType, setIntegrationType] = useState(0);
  const [oAuthUrl, setOAuthUrl] = useState("");
  const [isHubSpotSelected, setIsHubSpotSelected] = useState(false);

  const organizationId = useOrganizationId();

  const connectToIntegration = (id: number, url: string) => {
    if (id === integrationsEnum.HubSpot) {
      connectToHubspot(url + `&state=${organizationId}`);
    } else {
      console.error("NOT IMPLEMENTED YET");
    }
  };

  const connectToHubspot = (url: string) => {
    window.location.replace(url);
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values: {}, actions: FormikHelpers<{}>) => {
        if (page === 1) {
          connectToIntegration(integrationType, oAuthUrl);
          setIsSubmitting(false);
          setDisableSaveButton(false);
        }
      }}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        switch (page) {
          case 1:
            renderPage = (
              <SelectCRMModal
                setIntegrationType={setIntegrationType}
                setOAuthUrl={setOAuthUrl}
                setIsHubSpotSelected={setIsHubSpotSelected}
                isIntegrationSelected={isHubSpotSelected}
              />
            );
            break;
          case 2:
            renderPage = <AllowSelectedCRM />;
            break;
          case 3:
            renderPage = <MappingOutbaseFields />;
            break;
          default:
            break;
        }
        return (
          <Modal
            dialogClassName={styles.crmModal}
            title={"Connect CRM"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText={page === 1 ? "Cancel" : "Back"}
            saveButtonText="Continue"
            saveButtonDisabled={page === 1 ? !isHubSpotSelected : disableSaveButton}
            btnType="submit"
            backdrop="static"
          >
            {renderPage}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ConnectCRMForm;
