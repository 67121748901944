import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _, { set } from "lodash";
import { toast } from "react-toastify";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

//Redux
import {
  fetchResponderInbox,
  initiate,
  searchRecords,
  sortData,
  setSelectedRow,
  clearInitiated,
  fetchResponderInboxSuccess,
  fetchResponderInboxFailed,
  changePage,
  setFilter,
  clearFilter,
} from "../../../store/ResponderInbox/Table/actions/responderInboxTable";
import { fetchMailDetails, confirmAllAutoResolved, setFilterForReview } from "../../../store/ResponderInbox/actions/responderInbox";

//Helpers
import { filterData } from "../../../helper/tableConstants";
import api, { API } from "../../../helper/api/api";
import useRouteParams from "@hooks/useRouteParams";

//Styles
import styles from "./MessageList.module.scss";

//Images & Icons
import EmptyCampaignList from "../../../assets/Images/EmptyCampaignList/EmptyCampaignList";
import SectionInbox from "../../../assets/Icons/SectionInbox/SectionInbox";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";

//Components
import DelayedInput from "../../../components/UI/DelayedInput/DelayedInput";
import SkeletonThreeRowCard from "../../../components/UI/Skeletons/components/SkeletonThreeRowCard/SkeletonThreeRowCard";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import ReviewFilter from "./ReviewFilter/ReviewFilter";
import MessageCard from "./MessageCard/MessageCard";

const Message = (props) => {
  const {
    params,
    rows,
    fetchResponderInbox,
    activeTab,
    filterForReview,
    selectedRow,
    isInitiated,
    isLoaded,
    isLoading,
    clearInitiated,
    initiate,
    messagePreview,
    totalCount,
    fetchResponderInboxSuccess,
    fetchResponderInboxFailed,
    changePage,
  } = props;
  const { campaignId } = useRouteParams();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [tableRows, setTableRows] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [selectNextRow, setSelectNextRow] = useState(false);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  let image = <EmptyCampaignList className="emptyStateImage" />;

  useEffect(() => {
    const newParam = _.cloneDeep(params);
    if (props.isInCampaignSettings && campaignId != null && campaignId !== "") {
      if (params.filter.length === 0) {
        let updateState = {
          params: {
            filter: [{ type: "Campaign", value: [campaignId] }],
            start: 0,
            length: 10,
            page: 0,
            sort: {
              column: "createdDate",
              direction: "desc",
            },
          },
        };
        initiate(updateState);
      } else {
        initiate(newParam);
      }
    } else {
      let updateState = {
        params: {
          filter: [],
          start: 0,
          length: 10,
          page: 0,
          sort: {
            column: "createdDate",
            direction: "desc",
          },
        },
      };
      initiate(updateState);
    }

    return () => {
      clearInitiated();
    };
  }, [campaignId]);

  useEffect(() => {
    if (isInitiated) {
      let filters = [...params.filter];
      let campaignFilter = filters.find((x) => x.type === "Campaign") || {};
      let value = [];
      if (campaignFilter.type !== undefined) {
        value = [...campaignFilter.value];
      }

      if (props.isInCampaignSettings) {
        if (params.filter.length > 0 && value.length > 0 && props.isInCampaignSettings && campaignId != null && campaignId !== "") {
          props.fetchResponderInbox(params, activeTab);
        }
      } else if (!campaignId) {
        props.fetchResponderInbox(params, activeTab);
      }
    }
  }, [params, isInitiated, activeTab, campaignId, props.isInCampaignSettings]);

  useEffect(() => {
    if (rows != null && selectedRowIndex === -1 && rows.length > 0) {
      if (!messagePreview || messagePreview.id !== rows[0].id) {
        props.setSelectedRow(rows[0]);
        props.fetchMailDetails(rows[0].id);
        handleMessageClick(rows[0], -1);
      }
    } else if (selectNextRow) {
      const nextRow = selectedRowIndex + 1;
      if (selectedRowIndex !== nextRow - 1) {
        return;
      }

      props.setSelectedRow(rows[nextRow]);
      if (rows[nextRow]) props.fetchMailDetails(rows[nextRow].id);
      handleMessageClick(rows[selectedRowIndex], nextRow);
      setSelectNextRow(false);
    }
  }, [activeTab, rows]);

  const handleSearchChange = (text) => {
    props.searchRecords(text);
  };

  const handleMessageClick = (message, index) => {
    if (message) {
      setSelectedRowIndex(index);
      props.setSelectedRow(message);
      props.fetchMailDetails(message.id);

      setSelectNextRow(true);

      document.getElementById("message-0")?.classList.remove(styles.activeRow);

      document.getElementById(`message-${index === -1 ? "0" : index}`)?.classList.add(styles.activeRow);
    }
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleConfirmModalShow = () => setShowConfirmModal(true);
  const handleConfirmModalClose = () => setShowConfirmModal(false);

  const applyForReviewFalse = () => {
    filterData("ForReview", false, props.setFilter);
    props.setFilterForReview(false);
  };

  const handleClickFilterForReview = () => {
    if (filterForReview) {
      applyForReviewFalse();
      changePage(0);
    } else {
      filterData("ForReview", true, props.setFilter);
      props.setFilterForReview(true);
      changePage(0);
    }
  };

  const handleConfirmModalSave = () => {
    props
      .confirmAllAutoResolved()
      .then((res) => {
        applyForReviewFalse();
        handleConfirmModalClose();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let table = null;

  useEffect(() => {
    const rowsTemp = _.cloneDeep(props.rows);
    let sortedRows = [...rowsTemp].sort((a, b) => new Date(a.date) - new Date(b.date));
    if (params?.sort?.direction === "desc") {
      sortedRows = sortedRows.reverse();
    }

    const updatedRows = sortedRows.map((r, index) => {
      let classes = null;
      if (index === selectedRowIndex) {
        classes = styles.activeRow;
      }

      const inboxMessage = (
        <MessageCard
          key={index}
          index={index}
          classes={classes}
          r={r}
          activeTab={activeTab}
          handleMessageClick={() => handleMessageClick(r, index)}
        />
      );
      return { ...r, inboxMessage };
    });

    setTableRows(updatedRows);
  }, [rows, selectedRowIndex, activeTab]);

  useEffect(() => {
    changePage(0);
  }, [activeTab]);

  if (props.error) {
    table = <h2>Error getting Inbox messages</h2>;
  }

  const reloadData = (newParams) => {
    let data = {
      param: newParams,
      isResolvedTabActivated: activeTab,
    };

    api(API.responderInbox.getInboxResponses, data)
      .then((response) => {
        fetchResponderInboxSuccess(response.data);
      })
      .catch((error) => {
        fetchResponderInboxFailed(error.message);
      });
  };

  const fetchMoreData = () => {
    setSelectNextRow(false);
    let newParams = _.cloneDeep(params);
    newParams.start = params.start + 10;
    changePage(newParams.page + 1);

    if (props.isInCampaignSettings) {
      reloadData(newParams);
    }
  };

  let loader = (
    <div className={`${styles.loadingSpinnerMessage} flex_center`}>
      <Spinner size="0.813rem" /> <p> Loading more messages...</p>
    </div>
  );

  if (props.isLoading) {
    table = (
      <>
        {[1, 2, 3, 4].map((n) => (
          <SkeletonThreeRowCard key={n} />
        ))}{" "}
      </>
    );
  }
  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <InfiniteScroll
          dataLength={tableRows.length}
          next={() => fetchMoreData()}
          hasMore={totalCount > tableRows.length}
          loader={loader}
          height={windowHeight - 290}
        >
          {tableRows?.map((row, index) => {
            return <div key={index}>{row.inboxMessage}</div>;
          })}
        </InfiniteScroll>
      ) : (
        <EmptyState image={image} title="No messages found" />
      );
  }

  const [sortBy, setSortBy] = useState("Sort By");
  const handleSortChange = (eventKey, event) => {
    setSortBy(event.currentTarget.text);

    const sortSplit = eventKey.split(" ");
    const sort = {
      column: sortSplit[0],
      direction: sortSplit[1].toLowerCase(),
    };
    props.sortData(sort);
  };

  let sortSection = null;
  sortSection = (
    <>
      <div className={`${styles.sortWrapper} ${activeTab === 0 ? styles.end : styles.spaceBetween}`}>
        {activeTab === 1 ? (
          <ReviewFilter
            filterForReview={filterForReview}
            onClick={handleConfirmModalShow}
            handleClickFilterForReview={handleClickFilterForReview}
            forReviewCount={props.forReviewCount}
          />
        ) : null}
        <Dropdown className={styles.sort} onSelect={handleSortChange}>
          <Dropdown.Toggle id="dropdown-menu-align-right">{sortBy}</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="createdDate ASC" href="#">
              Sort Oldest to Newest
            </Dropdown.Item>
            <Dropdown.Item eventKey="createdDate DESC" href="#">
              Sort Newest to Oldest
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );

  return (
    <div className={styles.messageWrapper}>
      {props.messageListClosed ? (
        <div className="relative">
          <p className={styles.sectionTitle}>Inbox</p>
          <div className="flex flexCol space_between width_100">
            <ArrowLeftSLineIcon
              className={`circleToggleICon cursor_pointer iconGray400 ${styles.transformRight}`}
              onClick={() => props.setMessageListClosed(!props.messageListClosed)}
            />
            <SectionInbox imgStyle="mT5" />
          </div>
        </div>
      ) : (
        <>
          <div className="flex align-flex-start">
            <div className={styles.searchWrapper}>
              <DelayedInput
                showSearchIcon={true}
                type="text"
                placeholder={"Search for message"}
                delayedWrapperClass={`${props.delayedWrapperClass} ${styles.searchMessagesInput} width_100`}
                textChanged={handleSearchChange}
                ariaLabel="Search"
              />
            </div>
            <div className="iconWrapper">
              <ArrowLeftSLineIcon
                className={`circleToggleICon cursor_pointer iconGray400 ${styles.arrowIcon}`}
                onClick={() => props.setMessageListClosed(!props.messageListClosed)}
              />
            </div>
          </div>
          {sortSection}
          {table}
        </>
      )}
      <ConfirmModal handleSave={handleConfirmModalSave} handleClose={handleConfirmModalClose} showModal={showConfirmModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.responderInboxTable.params,
    isInitiated: state.responderInboxTable.isInitiated,
    isLoading: state.responderInboxTable.isLoading,
    isLoaded: state.responderInboxTable.isLoaded,
    error: state.responderInboxTable.error,
    rows: state.responderInboxTable.rows,
    activeTab: state.responderInbox.activeTab,
    filterForReview: state.responderInbox.filterForReview,
    selectedRow: state.responderInboxTable.selectedRow,
    messagePreview: state.responderInbox.messagePreview,
    totalCount: state.responderInboxTable.totalCount,
    pageCount: state.responderInboxTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    setFilterForReview: (value) => dispatch(setFilterForReview(value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    fetchResponderInbox: (param, activeTab) => dispatch(fetchResponderInbox(param, activeTab)),
    initiate: (state) => dispatch(initiate(state)),
    fetchMailDetails: (id) => dispatch(fetchMailDetails(id)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    sortData: (obj) => dispatch(sortData(obj)),
    setSelectedRow: (obj) => dispatch(setSelectedRow(obj)),
    clearInitiated: () => dispatch(clearInitiated()),
    fetchResponderInboxSuccess: (val) => dispatch(fetchResponderInboxSuccess(val)),
    fetchResponderInboxFailed: (val) => dispatch(fetchResponderInboxFailed(val)),
    changePage: (val) => dispatch(changePage(val)),
    confirmAllAutoResolved: () => dispatch(confirmAllAutoResolved()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
