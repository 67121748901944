import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "moment";
import _ from "lodash";

//Redux
import * as actions from "../../store/Companies/Table/actions/index";
import { fetchStatusCountForCompanies, fetchIndustryList, fetchSizeList } from "../../store/Companies/Global/actions/companies";

//Helpers
import api, { API } from "../../helper/api/api";
import { fileDownload } from "../../helper/fileDownloadHelper";
import { filterData, getFilterValue, filtersToQueryString, filterSingle } from "../../helper/tableConstants";
import { useQueryTableFilters } from "../../helper/hooks/useQueryTableFilters";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";
import { toastMessages } from "../../helper/toastMessagesConstants";

// Styles
import styles from "./Companies.module.scss";

//Images & Icons
import EmptyEmailAccount from "../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";
import IconAudienceExplorer from "../../assets/Icons/IconAudienceExplorer/IconIncludeContact";
import NewAudienceIcon from "../../assets/Icons/NewAudienceIcon/NewAudienceIcon";

// Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import Table from "../../components/DataTable/Table";
import { columns } from "./columns";
import StatusCountRow from "../../components/DataTable/StatusCountRow/StatusCountRow";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import SkeletonTableFilterRow from "../../components/UI/Skeletons/components/SkeletonTableFilterRow/SkeletonTableFilterRow";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import SkeletonTwoRowsCard from "../../components/UI/Skeletons/components/SkeletonTwoRowsCard/SkeletonTwoRowsCard";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import Button from "../../components/UI/Button/Button";
import TableOptionsRow from "../../components/DataTable/TableOptionsRow/TableOptionsRow";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const Companies = (props) => {
  let dateFormat = useOrgDateFormat();
  const { param, rows, fetchCompanies, initiate, isInitiated, clearInitiated, campaignId } = props;
  let image = <EmptyEmailAccount />;
  const [tableRows, setTableRows] = useState([]);
  let filterTypes = ["Campaign", "Industry", "Size", "Status"];
  let state = useQueryTableFilters(filterTypes, campaignId);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  const [activeStatus, setActiveStatus] = useState(null);
  const icon = (
    <InfoIconTooltip
      tooltipBody={"Details of all companies included in your campaign activity."}
      href={"https://help.outbase.com/user-guide/all-prospects/companies"}
      linkText="Read More"
      disableStartTourButton
    />
  );
  useEffect(() => {
    if (campaignId !== "") {
      props.setFilter("Campaign", [campaignId]);
    } else {
      props.clearFilter();
    }
  }, [campaignId]);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
      start: 0,
      page: 0,
      length: 10,
      sort: {},
      search: "",
    };
    initiate(updateState);
  };

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  useEffect(() => {
    props.fetchIndustryList();
    props.fetchSizeList();
    if (state.params && state.params.filter && state.params.filter.length > 0) {
      state.params.filter.map((filter) => {
        if (filter.type === "Status") {
          setActiveStatus(parseInt(filter.value));
        }
      });
    }

    initiate(state);
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const exportCompaniesData = () => {
    let isExport = true;
    let exportParam = {
      filter: [...param.filter, { type: "IsExport", value: [isExport] }],
    };
    let chColumns = dataTableColumns.map((column) => column.field);
    let data = {
      chosenColumns: chColumns,
      param: exportParam,
    };
    api(API.companies.exportCompanies, data, "blob")
      .then((res) => {
        fileDownload("_allCompanies.csv", res.data);
        toast.success(toastMessages.companies.exportFileFinishedDownloading);
        setExportButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (isInitiated) {
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
      fetchCompanies(param);
      let filters = [...param.filter];
      props.fetchStatusCountForCompanies(filters);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      r.checkBox = <Checkbox />;

      if (r.dateLastProspected != null && r.dateLastProspected !== "") {
        r.dateLastProspected = Moment(r.dateLastProspected).format(dateFormat);
      } else {
        r.dateLastProspected = "-";
      }

      if (r.companyName !== "(No company)") {
        r.companyName = (
          <OverlayTrigger
            key={r.companyName}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.companyName}`}>
                <Popover.Body>{r.companyName}</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden max-x-content">
              <Link
                className="tableLink"
                to={{
                  pathname: "/" + props.orgId + "/company-activity-overview/" + r.id,
                }}
              >
                {r.companyName}
              </Link>
            </div>
          </OverlayTrigger>
        );
      }

      r.createdDate = Moment(r.createdDate).format(dateFormat);
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  const buttons = (
    <div className="flex">
      <Button id="companiesUploadAudience" isLink={true} variant="secondary" link={"audiences"}>
        <NewAudienceIcon imgStyle="mR5" /> Upload list
      </Button>
      <Button id="companiesAudienceBuilder" isLink={true} variant="primary" link={"search"}>
        <IconAudienceExplorer imgStyle="mR5" /> Search contacts
      </Button>
    </div>
  );

  let selects = null;
  let statusContRow = null;
  let table = null;
  let tableOptionsRow = null;

  let selectRowOptions = null;
  props.campaignId
    ? (selectRowOptions = [
        {
          option: props.listIndustry,
          change: (i) => filterData("Industry", i, props.setFilter),
          placeholder: "Industry",
          value: getFilterValue(param.filter, "Industry"),
        },
        {
          option: props.listSize,
          change: (s) => filterData("Size", s, props.setFilter),
          placeholder: "Size",
          value: getFilterValue(param.filter, "Size"),
        },
      ])
    : (selectRowOptions = [
        {
          option: campaignsList,
          change: (c) => filterData("Campaign", c, props.setFilter),
          placeholder: "Campaigns",
          value: getFilterValue(param.filter, "Campaign"),
        },
        {
          option: props.listIndustry,
          change: (i) => filterData("Industry", i, props.setFilter),
          placeholder: "Industry",
          value: getFilterValue(param.filter, "Industry"),
        },
        {
          option: props.listSize,
          change: (s) => filterData("Size", s, props.setFilter),
          placeholder: "Size",
          value: getFilterValue(param.filter, "Size"),
        },
      ]);

  const filterStatus = (status) => {
    props.changePage(0);
    filterSingle("Status", status, param, props.setFilter, props.clearFilter);
  };

  if (props.error) {
    table = <h2>Error getting Companies</h2>;
  }

  if (props.isLoadinglistCampaigns) {
    selects = <SkeletonTableFilterRow />;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
    statusContRow = (
      <div className="flex w-5 mT10 mB30">
        {[1, 2, 3, 4, 5, 6].map((n) => (
          <SkeletonTwoRowsCard key={n} />
        ))}
      </div>
    );
  }

  if (props.isLoaded && props.statusCount) {
    statusContRow = <StatusCountRow statusCount={props.statusCount} click={filterStatus} activeStatus={activeStatus} />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Companies"}
          columns={dataTableColumns}
          rows={tableRows}
          param={param}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          scrollY={true}
          variant="small"
          tableClassName={styles.companyTable}
          tableHeight={350}
          forcePageChanged={true}
        />
      ) : (
        <EmptyState image={image} title="No companies found" />
      );

    if (props.isLoadedlistCampaigns) {
      props.campaignId
        ? (selectRowOptions = [
            {
              option: props.listIndustry,
              change: (i) => filterData("Industry", i, props.setFilter),
              placeholder: "Industry",
              value: getFilterValue(param.filter, "Industry"),
            },
            {
              option: props.listSize,
              change: (s) => filterData("Size", s, props.setFilter),
              placeholder: "Size",
              value: getFilterValue(param.filter, "Size"),
            },
          ])
        : (selectRowOptions = [
            {
              option: campaignsList,
              change: (c) => filterData("Campaign", c, props.setFilter),
              placeholder: "Campaigns",
              value: getFilterValue(param.filter, "Campaign"),
            },
            {
              option: props.listIndustry,
              change: (i) => filterData("Industry", i, props.setFilter),
              placeholder: "Industry",
              value: getFilterValue(param.filter, "Industry"),
            },
            {
              option: props.listSize,
              change: (s) => filterData("Size", s, props.setFilter),
              placeholder: "Size",
              value: getFilterValue(param.filter, "Size"),
            },
          ]);
    }
    selects = <TableFiltersRow selects={selectRowOptions} placeholder="companies" changeFilter={(text) => props.searchRecords(text)} />;

    tableOptionsRow =
      tableRows.length > 0 ? (
        <TableOptionsRow
          applyChanges={applyDataTableColumnsChange}
          exportTable={exportCompaniesData}
          exportButtonDisabled={exportButtonDisabled}
          setExportButtonDisabled={setExportButtonDisabled}
          importData={null}
          dataTableColumns={dataTableColumns}
          setDataTableColumns={setDataTableColumns}
          allDataTableColumns={columns}
        />
      ) : null;
  }

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title={"Companies"} icon={icon} rightContent={buttons} />
      {selects}
      {statusContRow}
      {tableOptionsRow}
      {table}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.companies.params,
    isInitiated: state.companies.isInitiated,
    isLoading: state.companies.isLoading,
    isLoaded: state.companies.isLoaded,
    error: state.companies.error,
    rows: state.companies.rows,
    totalCount: state.companies.totalCount,
    pageCount: state.companies.pageCount,
    listSize: state.globalCompanies.size,
    listIndustry: state.globalCompanies.listIndustry,
    statusCount: state.globalCompanies.statusCount,
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIndustryList: () => dispatch(fetchIndustryList()),
    fetchSizeList: () => dispatch(fetchSizeList()),
    fetchCompanies: (param) => dispatch(actions.fetchCompanies(param)),
    fetchStatusCountForCompanies: (obj) => dispatch(fetchStatusCountForCompanies(obj)),
    changePage: (page) => dispatch(actions.changePage(page)),
    sortData: (obj) => dispatch(actions.sortData(obj)),
    numRecords: (obj) => dispatch(actions.numRecords(obj)),
    searchRecords: (value) => dispatch(actions.searchRecords(value)),
    setFilter: (type, value) => dispatch(actions.setFilter(type, value)),
    clearFilter: (type) => dispatch(actions.clearFilter(type)),
    initiate: (state) => dispatch(actions.initiate(state)),
    clearInitiated: () => dispatch(actions.clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
