import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Redux
import { fetchGlobalSearchCampaigns } from "../../../../store/GlobalSearch/actions/globalSearch";

//Hooks
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";

//Styles
import styles from "./CampaignSearch.module.scss";

//Components
import CampaignsSearchRow from "./CampaignSearchRow/CampaignSearchRow";
import SkeletonAvatarText from "../../../UI/Skeletons/components/SkeletonAvatarText/SkeletonAvatarText";

const CampaignSearch = (props) => {
  const organizationId = useOrganizationId();
  const { param } = props;

  let list = null;

  if (props.error) {
    list = <h2 className="f18 textCenter">Error getting Campaigns</h2>;
  }

  if (props.isLoading) {
    list = <SkeletonAvatarText />;
  }

  if (props.isLoaded) {
    list =
      props.listCampaigns.length > 0 ? (
        props.listCampaigns.map((cam, index) => (
          <Link
            key={index}
            to={{ pathname: "/" + organizationId + "/campaigns/" + cam.id + "/overview" }}
            onClick={props.hrefClicked}
            className="text_decoration_none"
          >
            <CampaignsSearchRow name={cam.name} status={cam.status} />
          </Link>
        ))
      ) : (
        <p className={styles.noResultMessage}>No results found</p>
      );
  }

  return (
    <>
      <div className={styles.titleWrapper}>
        <h5 className={styles.title}>Campaigns</h5>
        {props.listCampaigns.length > 0 ? (
          <Link
            to={{
              pathname: "/" + props.organizationId + "/globalsearch",
              search: "search=" + props.param,
              aboutProps: {
                page: 3,
              },
            }}
          >
            <p onClick={props.hrefClicked} className={styles.link}>
              Show all
            </p>
          </Link>
        ) : null}
      </div>
      {list}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.globalSearh.search,
    isLoading: state.globalSearh.campaignsIsLoading,
    isLoaded: state.globalSearh.campaignsIsLoaded,
    error: state.globalSearh.campaignsError,
    listCampaigns: state.globalSearh.listCampaigns,
    organizationId: state.user.organization?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalSearchCampaigns: (param) => dispatch(fetchGlobalSearchCampaigns(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSearch);
