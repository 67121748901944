import React, { useEffect, useState } from "react";

//Styles
import styles from "./DelayedInput.module.scss";

//Images & Icons
import SearchLineIcon from "remixicon-react/SearchLineIcon";

interface DelayedInputProps {
  value?: string;
  searchOnFirstCharacter?: boolean;
  textChanged: (text: string) => void;
  showSearchIcon?: boolean;
  delayedWrapperClass?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  ariaLabel?: string;
}

const DelayedInput: React.FC<DelayedInputProps> = (props) => {
  let timeout: NodeJS.Timeout | null = null;
  const [text, setText] = useState("");
  const [lastText, setLastText] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.value && inputValue === "") {
      setInputValue(props.value);
      setText(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (text !== lastText && (text === "" || (props.searchOnFirstCharacter ? text.length >= 1 : text.length >= 3))) {
      setLastText(text);
      props.textChanged(text);
    }
  }, [text]);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setText(value);
    }, 500);
  };

  const findInputSize = (placeholder: string) => {
    var size = placeholder.length;
    if (placeholder.length > 20) size += 3;
    if (placeholder.length > 12 && placeholder.length <= 20) size += 1;

    return size;
  };

  let showSearchIcon = props.showSearchIcon ?? false;

  return (
    <div className={`${styles.wrapper} ${props.delayedWrapperClass ?? " "}`}>
      {showSearchIcon ? <SearchLineIcon size={12} className={`iconGray400 ${styles.searchICon}`} /> : null}
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={`${props.className} ${styles.input}`}
        onKeyUp={onKeyUpHandler}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        size={findInputSize(props.placeholder ?? "")}
        aria-label={props.ariaLabel}
      />
    </div>
  );
};

export default DelayedInput;
