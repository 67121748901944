import React from "react";

const Dropdown = (props) => (
  <svg className={props.imgStyle} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="icn_navigator_card_dropdown" transform="translate(-27.5 -11)">
      <rect id="Rectangle_1032" dataName="Rectangle 1032" width="24" height="24" transform="translate(27.5 11)" fill="none" />
      <g id="noun_dropdown_list_822170" dataName="noun_dropdown list_822170" transform="translate(90.5 66)">
        <path
          id="Path_1327"
          dataName="Path 1327"
          d="M311.484,64.1h5.161a1.293,1.293,0,0,0,1.29-1.293v-4.51A1.292,1.292,0,0,0,316.645,57h-5.161Zm2.3-2.353a.323.323,0,0,1-.459,0l-.452-.452a.323.323,0,0,1,0-.458l1.385-1.385a.645.645,0,0,1,.912,0l1.385,1.385a.322.322,0,0,1,0,.458l-.452.452a.325.325,0,0,1-.459,0l-.928-.928Zm-13.173,8.8a.553.553,0,0,0,.553-.553V67.23a.553.553,0,0,0-.553-.553h-2.765a.553.553,0,0,0-.553.553V70a.553.553,0,0,0,.553.553Zm0,6.452a.553.553,0,0,0,.553-.553V73.682a.553.553,0,0,0-.553-.553h-2.765a.553.553,0,0,0-.553.553v2.765a.553.553,0,0,0,.553.553Zm2.166-8.068a.323.323,0,0,0,.32.326h8.069a.321.321,0,0,0,.32-.326v-.639a.323.323,0,0,0-.32-.326h-8.069a.321.321,0,0,0-.32.326Zm0,6.452a.326.326,0,0,0,.323.325h4.839a.322.322,0,0,0,.323-.325v-.639a.326.326,0,0,0-.323-.325H303.1a.322.322,0,0,0-.323.325ZM296,62.8a1.292,1.292,0,0,0,1.291,1.293h12.9V57h-12.9A1.293,1.293,0,0,0,296,58.293Zm1.29-1.935a.324.324,0,0,0,.323.326h7.1a.323.323,0,0,0,.323-.326v-.639a.324.324,0,0,0-.323-.326h-7.1a.323.323,0,0,0-.323.326Z"
          transform="translate(-358 -110)"
          fill="#586473"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default Dropdown;
