export const columns = [
  {
    label: "List",
    field: "audienceName",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 150,
    minWidth: 150,
    maxWidth: 250,
    flex: 1,
  },
  {
    label: "Size",
    field: "audienceSizeProgressBar",
    type: "actions",
    class: "line_height_1",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 300,
    minWidth: 300,
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 180,
    minWidth: 180,
  },
  {
    label: "Targeting Criteria",
    field: "targetingCriteria",
    type: "text",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 350,
    minWidth: 350,
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    sort: "disabled",
    minimal: "sm",
    width: 100,
    minWidth: 100,
    cellClass: "overflow-visible-action",
  },
];
