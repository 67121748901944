import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { columns } from "./columns";

//Redux
import { fetchOrganizationCreditInfo } from "../../../../../store/Outboss/Organizations/Global/actions/organizations";
import {
  fetchOrganizationCreditAdjustments,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../../../store/Outboss/Organizations/Table/actions/organizationCreditAdjustments";

//Helpers
import api, { API } from "../../../../../helper/api/api";
import { adjustCreditsReasonOptions } from "../../../../../helper/enums/adjustCreditsReasonEnum";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";

//Styles
import styles from "./CreditAdjustmentModal.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";
import Table from "../../../../../components/DataTable/Table";

const CreditAdjustmentModal = (props) => {
  const {
    fetchOrganizationCreditInfo,
    creditInfo,
    param,
    rows,
    fetchOrganizationCreditAdjustments,
    isInitiated,
    initiate,
    clearInitiated,
  } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  const [tableRows, setTableRows] = useState([]);
  const { currentCredits, monthlyCredits, plan } = creditInfo;
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (props.organizationId && props.organizationId !== null) {
      fetchOrganizationCreditInfo(props.organizationId);
    }
  }, [props.organizationId]);

  useEffect(() => {
    if (props.organizationId != null && props.organizationId > 0) {
      let state = {
        params: {
          filter: [{ type: "organization", value: [props.organizationId] }],
          start: 0,
          page: 0,
          length: 10,
          sort: { column: "createdDate", direction: "desc" },
          search: "",
        },
      };
      initiate(state);
    }
  }, [props.organizationId]);

  useEffect(() => {
    if (isInitiated) {
      fetchOrganizationCreditAdjustments(param);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, [props.organizationId]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r) => {
      r.createdDate = <span>{Moment(r.createdDate).format(dateFormat)}</span>;
      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  const organizationName = (
    <OverlayTrigger
      key={"active"}
      placement={"bottom"}
      overlay={
        <Popover id={`tooltip-active`}>
          <Popover.Body>{props.organizationName}</Popover.Body>
        </Popover>
      }
    >
      <div className="textOverflow">{props.organizationName}</div>
    </OverlayTrigger>
  );

  if (props.isTableLoaded) {
    table = (
      <div className={styles.testTable}>
        <Table
          tableName={"Users"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          scrollY={true}
          scrollX={true}
          tableClassName="filtersTable"
          variant="small"
          menuPlacement="top"
          tableFooterClass={"relative p0"}
        />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        creditAdjustment: 0,
        reason: 0,
      }}
      validationSchema={Yup.object({
        creditAdjustment: Yup.number("Enter valid Number")
          .notOneOf([0], "Choose a number other than 0")
          .integer("enter valid Number")
          .required("Required"),
        reason: Yup.number().required("Required").positive("Choose a reason"),
      })}
      onSubmit={(values, actions) => {
        let data = {
          organizationId: props.organizationId,
          credits: values.creditAdjustment,
          reason: values.reason,
        };

        api(API.OUTBOSS.organizations.adjustCreditsForOrganization, data)
          .then((res) => {
            toast.success(toastMessages.outboss.organizations.successCreditsAdjustment);
            props.fetchOrganizations();
            props.handleClose();
            setSaveButtonDisabled(false);
          })
          .catch((error) => {
            toast.error(error.message);
            setSaveButtonDisabled(false);
          });

        actions.resetForm();
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldError } = formikProps;
        return (
          <Modal
            title="Credit adjustment"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            btnType="submit"
            saveButtonText="Adjust"
            closeButtonText="Cancel"
            size="lg"
            saveButtonDisabled={saveButtonDisabled}
          >
            <>
              <Form>
                <div className={`${styles.contentBody} flex`}>
                  <div className={styles.leftForm}>
                    <p className={styles.details}>
                      <span className={styles.label}>Organization: </span>
                      <span className={styles.dataDetails}>{organizationName}</span>
                    </p>
                    <p className={styles.details}>
                      <span className={styles.label}>Subscription Plan:</span> <span className={styles.dataDetails}>{plan}</span>
                    </p>
                  </div>
                  <div className={styles.variablesWrapperForm}>
                    <p className={styles.details}>
                      <span className={styles.label}>Monthly Credits:</span> <span className={styles.dataDetails}>{monthlyCredits}</span>
                    </p>
                    <p className={styles.details}>
                      <span className={styles.label}>Credits Available: </span>
                      <span className={styles.dataDetails}>{currentCredits}</span>
                    </p>
                    <p className={styles.details}>
                      <span className={styles.label}>Credits Returned: </span>
                      <span className={styles.dataDetails}>{creditInfo?.returnedUndeliveredCredits}</span>
                    </p>
                  </div>
                </div>
                <div className={`${styles.actionBody} flex`}>
                  <div className={styles.creditAdjustmentDiv}>
                    <FormikInput
                      type="number"
                      label="Credit Adjustment"
                      name="creditAdjustment"
                      value={values.creditAdjustment}
                      placeholder="0"
                      isMenuPortalTargetDisabled={true}
                      onBlur={(e) => {
                        let inputValue = e.currentTarget.defaultValue;
                        setSaveButtonDisabled(true);

                        if (inputValue !== "") {
                          if (parseInt(inputValue) !== 0) {
                            setSaveButtonDisabled(false);
                          }
                        }
                      }}
                    />
                  </div>
                  <div className={styles.reasonDiv}>
                    <FormikSelect
                      label="Reason"
                      name="reason"
                      options={adjustCreditsReasonOptions}
                      value={values.reason}
                      isMenuPortalTargetDisabled={true}
                    />
                  </div>
                </div>
              </Form>
              {rows.length > 0 ? <div className={styles.tableWrapper}>{table}</div> : null}
            </>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoaded: state.outbossOrganization.isLoaded,
    creditInfo: state.outbossOrganization.creditInfo,
    param: state.outbossOrganizationCreditAdjustments.params,
    isInitiated: state.outbossOrganizationCreditAdjustments.isInitiated,
    isTableLoading: state.outbossOrganizationCreditAdjustments.isLoading,
    isTableLoaded: state.outbossOrganizationCreditAdjustments.isLoaded,
    error: state.outbossOrganizationCreditAdjustments.error,
    rows: state.outbossOrganizationCreditAdjustments.rows,
    totalCount: state.outbossOrganizationCreditAdjustments.totalCount,
    pageCount: state.outbossOrganizationCreditAdjustments.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizationCreditInfo: (organizationId) => dispatch(fetchOrganizationCreditInfo(organizationId)),
    fetchOrganizationCreditAdjustments: (param) => dispatch(fetchOrganizationCreditAdjustments(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditAdjustmentModal);
