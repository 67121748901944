import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import DropdownItem from "./DropdownItem/DropdownItem";

// Helpers
import { campaignStatusEnum } from "../../../helper/enums/campaignStatusEnum";

// Style
import styles from "./ActionAndIconCol.module.scss";

// Icons
import IconUnArchive from "../../../assets/Icons/IconUnArchive/IconUnArchive";
import PlayCircleFillIcon from "remixicon-react/PlayCircleFillIcon";
import InboxArchiveLineIcon from "remixicon-react/InboxArchiveLineIcon";
import PauseCircleFillIcon from "remixicon-react/PauseCircleFillIcon";
import More2FillIcon from "remixicon-react/More2FillIcon";
import ActionClone from "../../UI/ActionWrapper/Actions/ActionClone";

const ActionAndCol = (props) => {
  const [disabled, setDisabled] = useState(false);
  let primaryIcon = null;
  let cloneIcon = null;
  let itemIcon = null;

  const handleClick = (status, id) => {
    setDisabled(true);
    props.changeStatus(status, id, toggleButton);
  };

  const toggleButton = () => {
    setDisabled((prev) => !prev);
  };

  useEffect(() => {
    setDisabled(false);
  }, [props.status]);

  const getButtonId = (baseId) => `${baseId}-${props.iterationId + 1}`;

  switch (props.status) {
    case "Setup":
      if (props.setup === props.maxSteps) {
        primaryIcon = (
          <OverlayTrigger
            key={"active"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-active`}>
                <Popover.Body>Activate</Popover.Body>
              </Popover>
            }
          >
            <button id={getButtonId("setupActive")} className={styles.iconWrapper} onClick={() => props.openActivationModal(toggleButton)}>
              <PlayCircleFillIcon size={20} className={`${styles.playSuccess} ${styles.iconHover}`} />
            </button>
          </OverlayTrigger>
        );
      } else {
        primaryIcon = (
          <OverlayTrigger
            key={"notActive"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-notActive`}>
                <Popover.Body>Activate</Popover.Body>
              </Popover>
            }
          >
            <button
              id={getButtonId("setupActivate")}
              className={styles.iconWrapper}
              onClick={() => props.openActivationModal(toggleButton)}
            >
              <PlayCircleFillIcon size={20} className={`${styles.playSuccess} ${styles.notActive}`} />
            </button>
          </OverlayTrigger>
        );
      }
      cloneIcon = (
        <OverlayTrigger
          key={"cloneCampaign"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-active`}>
              <Popover.Body>Clone campaign</Popover.Body>
            </Popover>
          }
        >
          <button id={getButtonId("setupClone")} className={styles.iconWrapper}>
            <ActionClone onClick={props.openCloneModal} />
          </button>
        </OverlayTrigger>
      );
      itemIcon = (
        <DropdownItem
          onClick={() => {
            props.openArchiveModel();
          }}
          itemStyle={styles.dropdownItem}
          icon={
            <OverlayTrigger
              key={"archive"}
              placement={"bottom"}
              overlay={
                <Popover id={`tooltip-archive`}>
                  <Popover.Body>Archive</Popover.Body>
                </Popover>
              }
            >
              <button id={getButtonId("setupArchive")} className={styles.iconWrapper}>
                <InboxArchiveLineIcon className={`iconWhite ${styles.archiveIcon}`} size={20} />
              </button>
            </OverlayTrigger>
          }
        />
      );
      break;
    case "Active":
      primaryIcon = (
        <OverlayTrigger
          key={"pause"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-pause`}>
              <Popover.Body>Pause</Popover.Body>
            </Popover>
          }
        >
          <button
            id={getButtonId("activePause")}
            className={styles.iconWrapper}
            onClick={() => handleClick(campaignStatusEnum.paused, props.id)}
            disabled={disabled}
          >
            <PauseCircleFillIcon size={20} className={`${styles.iconHover} ${styles.pauseIcon}`} />
          </button>
        </OverlayTrigger>
      );
      itemIcon = (
        <DropdownItem
          onClick={() => {
            props.openArchiveModel();
          }}
          itemStyle={styles.dropdownItem}
          icon={
            <OverlayTrigger
              key={"archiveActive"}
              placement={"bottom"}
              overlay={
                <Popover id={`tooltip-archiveActive`}>
                  <Popover.Body>Archive</Popover.Body>
                </Popover>
              }
            >
              <button id={getButtonId("activeArchive")} className={styles.iconWrapper}>
                <InboxArchiveLineIcon className={`iconWhite ${styles.archiveIcon}`} size={20} />
              </button>
            </OverlayTrigger>
          }
        />
      );
      cloneIcon = (
        <OverlayTrigger
          key={"cloneCampaign"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-active`}>
              <Popover.Body>Clone campaign</Popover.Body>
            </Popover>
          }
        >
          <button id={getButtonId("activeClone")} className={styles.iconWrapper}>
            <ActionClone onClick={props.openCloneModal} />
          </button>
        </OverlayTrigger>
      );
      break;
    case "Scheduled":
      primaryIcon = (
        <OverlayTrigger
          key={"pause"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-pause`}>
              <Popover.Body>Pause</Popover.Body>
            </Popover>
          }
        >
          <button
            id={getButtonId("schedulePause")}
            className={styles.iconWrapper}
            onClick={() => handleClick(campaignStatusEnum.paused, props.id)}
            disabled={disabled}
          >
            <PauseCircleFillIcon size={20} className={`${styles.iconHover} ${styles.pauseIcon}`} />
          </button>
        </OverlayTrigger>
      );
      itemIcon = (
        <DropdownItem
          onClick={() => {
            props.openArchiveModel();
          }}
          itemStyle={styles.dropdownItem}
          icon={
            <OverlayTrigger
              key={"archiveActive"}
              placement={"bottom"}
              overlay={
                <Popover id={`tooltip-archiveActive`}>
                  <Popover.Body>Archive</Popover.Body>
                </Popover>
              }
            >
              <button id={getButtonId("scheduleArchive")} className={styles.iconWrapper}>
                <InboxArchiveLineIcon className={`iconWhite ${styles.archiveIcon}`} />
              </button>
            </OverlayTrigger>
          }
        />
      );
      cloneIcon = (
        <OverlayTrigger
          key={"cloneCampaign"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-active`}>
              <Popover.Body>Clone campaign</Popover.Body>
            </Popover>
          }
        >
          <button id={getButtonId("scheduleClone")} className={styles.iconWrapper}>
            <ActionClone onClick={props.openCloneModal} />
          </button>
        </OverlayTrigger>
      );
      break;
    case "Paused":
      primaryIcon = (
        <OverlayTrigger
          key={"activePause"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-activePause`}>
              <Popover.Body>Activate</Popover.Body>
            </Popover>
          }
        >
          <button id={getButtonId("pausedPlay")} className={styles.iconWrapper} onClick={() => props.openActivationModal(toggleButton)}>
            <PlayCircleFillIcon size={20} className={`${styles.playSuccess}`} />
          </button>
        </OverlayTrigger>
      );
      itemIcon = (
        <DropdownItem
          onClick={() => {
            props.openArchiveModel();
          }}
          itemStyle={styles.dropdownItem}
          icon={
            <OverlayTrigger
              key={"archivePause"}
              placement={"bottom"}
              overlay={
                <Popover id={`tooltip-archivePause`}>
                  <Popover.Body>Archive</Popover.Body>
                </Popover>
              }
            >
              <button id={getButtonId("pausedArchive")} className={styles.iconWrapper}>
                <InboxArchiveLineIcon className={`iconWhite ${styles.archiveIcon}`} size={20} />
              </button>
            </OverlayTrigger>
          }
        />
      );
      cloneIcon = (
        <OverlayTrigger
          key={"cloneCampaign"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-active`}>
              <Popover.Body>Clone campaign</Popover.Body>
            </Popover>
          }
        >
          <button id={getButtonId("pausedClone")} className={styles.iconWrapper}>
            <ActionClone onClick={props.openCloneModal} />
          </button>
        </OverlayTrigger>
      );
      break;
    case "Archived":
      primaryIcon = (
        <OverlayTrigger
          key={"unArchive"}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-unArchive`}>
              <Popover.Body>Unarchive</Popover.Body>
            </Popover>
          }
        >
          <button
            id={getButtonId("archivedArchive")}
            className={styles.iconWrapper}
            onClick={() => handleClick(campaignStatusEnum.setup, props.id)}
          >
            <IconUnArchive />
          </button>
        </OverlayTrigger>
      );
      break;
    default:
      break;
  }

  return (
    <div id="actionWrapper" className={`${styles.columnWrapper} actionWrapper`}>
      {primaryIcon}
      {cloneIcon}
      {itemIcon ? (
        <Dropdown id={getButtonId("dropdownButton")}>
          <Dropdown.Toggle id={props.buttonId} aria-label="action" className={`${styles.dropdownButton} flex flex_center`}>
            <More2FillIcon size={18} className="iconGray" />
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.actionList} id="dropdownList">
            {itemIcon}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </div>
  );
};

export default ActionAndCol;
