import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";

// Style
import styles from "./NumberAndPercentageCol.module.scss";

const NumberAndPercentageCol = (props) => {
  let separators = useGroupDecimalSeparator();
  let totalNumberFormatted = formatNumberHelper(props.total, separators.numberGroupSeperator, separators.numberDecimalSeperator);

  let classes = styles.dataStyle;

  if (props.variant != null) {
    classes += " " + styles[props.variant];
  }

  if (props.classes != null) {
    classes += " " + props.classes;
  }

  let percentage = props.percentage + "%";
  if (props.customText != null) {
    percentage = (
      <>
        {props.percentage} {props.customText}
      </>
    );
  }

  let text = (
    <p className={classes} style={props.style}>
      {totalNumberFormatted} <span>({percentage})</span>
    </p>
  );

  if (props.tooltip != null) {
    text = (
      <div className="max-x-content">
        <OverlayTrigger
          key={`audience-size-${props.id}`}
          placement={"bottom"}
          overlay={
            <Popover id={`audience-size-tooltip-${props.id}`}>
              <Popover.Body className={"campaignAudienceTooltip"}>{props.tooltip}</Popover.Body>
            </Popover>
          }
        >
          {text}
        </OverlayTrigger>
      </div>
    );
  }

  return text;
};
export default NumberAndPercentageCol;
