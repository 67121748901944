import React from "react";

//Images & Icons
import Campaigns from "../../assets/Icons/SidebarIcons/Campaigns/Campaigns";

//Styles
import styles from "./CampaignLabel.module.scss";

const CampaignLabel = (props) => {
  let icon = <Campaigns />;

  return (
    <div className={styles.wrap}>
      <div className={styles.flexWrap}>
        {icon} <span className={styles.title}>{props.title}</span>
      </div>
    </div>
  );
};

export default CampaignLabel;
