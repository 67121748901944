import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonCampaignsInfo = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="flex_end width_100 mT10">
        <SkeletonElement type="text-26" />
        <SkeletonElement type="text-15" />
        <SkeletonElement type="text-26" />
        <SkeletonElement type="text-15" />
        <SkeletonElement type="text-26" />
        <SkeletonElement type="text-15" />
      </div>
      <div className="flex width_100 mT20">
        <SkeletonElement type="text-15" />
        <SkeletonElement type="text-15" />
        <SkeletonElement type="text-15" />
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonCampaignsInfo;
