import { api } from "./api";
import { CalendarEventModel } from "../models/calendar/CalendarEventModel";
import { CalendarEventsRequest } from "../models/calendar/CalendarEventRequest";
import { CalendarPostEventModel } from "@models/calendar/CalendarPostEventModel";
import { CalendarEventDetailsModel } from "@models/calendar/CalendarEventDetailsModel";

const calendarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalendarEvents: builder.query<CalendarEventModel[], CalendarEventsRequest>({
      query: (params) => ({
        url: "/calendar/calendar-events",
        method: "POST",
        body: params,
      }),
    }),
    getCalendarEventDetails: builder.query<CalendarEventDetailsModel, CalendarPostEventModel>({
      query: (params) => ({
        url: "/calendar/calendar-event-details",
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const { useGetCalendarEventsQuery, useGetCalendarEventDetailsQuery } = calendarApi;
