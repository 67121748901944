import { Formik } from "formik";
import React, { useEffect } from "react";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

//Redux
import { connect } from "react-redux";
import {
  fecthGlobalMappingsCustomFields,
  fetchCompaniesProperties,
  fetchContactsProperties,
  fetchHubSpotConfiguration,
  mapContactsAndCompaniesProperties,
} from "../../../../../store/CRM-Integrations/HubSpot/Global/actions/hubspot-integration";

//Helpers
import { errorResponseToList } from "../../../../../helper/errorHelper";

//Styles
import styles from "../../ConnectCRMForm/ConnectCRMForm.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import MappingOutbaseFields from "../../MappingOutbaseFields/MappingOutbaseFields";

const EditHubSpotGlobalMappings = (props) => {
  const {
    fetchHubSpotConfiguration,
    globalConfiguration,
    fetchCompaniesProperties,
    fetchContactsProperties,
    companiesProperties,
    contactsProperties,
    mapContactsAndCompaniesProperties,
    fecthGlobalMappingsCustomFields,
    globalMappingsCustomFields,
    isLoading,
  } = props;

  useEffect(() => {
    fetchHubSpotConfiguration();
    fetchCompaniesProperties();
    fetchContactsProperties();
  }, []);

  let companiesMappings = null;
  let contactsMappings = null;

  if (!_.isNull(globalConfiguration) && !_.isUndefined(globalConfiguration)) {
    companiesMappings = globalConfiguration["companies"];
    contactsMappings = globalConfiguration["contacts"];
  }

  return (
    <Formik
      initialValues={{
        globalConfiguration: !_.isNull(globalConfiguration) && !_.isUndefined(globalConfiguration) ? globalConfiguration : null,
      }}
      onSubmit={(values, actions) => {
        let data = {
          contactsProperties: globalConfiguration.contacts,
          companiesProperties: globalConfiguration.companies,
        };

        if (!_.isEmpty(values.globalMappingsContacts)) {
          for (var contactsKey in values.globalMappingsContacts) {
            //if (data.contactsProperties.hasOwnProperty(contactsKey)) {
            data.contactsProperties[contactsKey] = values.globalMappingsContacts[contactsKey];
            //
          }
        }

        if (!_.isEmpty(values.globalMappingsCompanies)) {
          for (var companiesKey in values.globalMappingsCompanies) {
            //if (data.companiesProperties.hasOwnProperty(companiesKey)) {
            data.companiesProperties[companiesKey] = values.globalMappingsCompanies[companiesKey];
            //}
          }
        }

        mapContactsAndCompaniesProperties(data)
          .then((res) => {
            props.handleClose();
            props.fetchHubspotRules();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message, { autoClose: false });
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit } = formikProps;

        return companiesProperties && companiesProperties.length > 0 && contactsProperties && contactsProperties.length > 0 ? (
          <Modal
            dialogClassName={styles.crmModal}
            customBodyClass={`${styles.crmModalBody}`}
            title={"Configure HubSpot Mapping"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText={"Cancel"}
            saveButtonText={"Save"}
            btnType="submit"
            backdrop="static"
          >
            <MappingOutbaseFields
              companiesMappings={companiesMappings}
              contactsMappings={contactsMappings}
              companiesProperties={companiesProperties}
              contactsProperties={contactsProperties}
              fecthGlobalMappingsCustomFields={fecthGlobalMappingsCustomFields}
              globalMappingsCustomFields={globalMappingsCustomFields}
              isLoading={isLoading}
            />
          </Modal>
        ) : (
          <Spinner />
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    globalConfiguration: state.hubspotIntegration.globalConfiguration,
    companiesProperties: state.hubspotIntegration.companiesProperties,
    contactsProperties: state.hubspotIntegration.contactsProperties,
    globalMappingsCustomFields: state.hubspotIntegration.globalMappingsCustomFields,
    isLoading: state.hubspotIntegration.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHubSpotConfiguration: () => dispatch(fetchHubSpotConfiguration()),
    fetchCompaniesProperties: () => dispatch(fetchCompaniesProperties()),
    fetchContactsProperties: () => dispatch(fetchContactsProperties()),
    mapContactsAndCompaniesProperties: (data) => dispatch(mapContactsAndCompaniesProperties(data)),
    fecthGlobalMappingsCustomFields: (key) => dispatch(fecthGlobalMappingsCustomFields(key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHubSpotGlobalMappings);
