import React from "react";
import svg from "./ai.svg";

interface AIIllustrationProps {
  imgStyle?: string;
}

const AIIllustration: React.FC<AIIllustrationProps> = ({ imgStyle }) => <img src={svg} className={imgStyle} alt="Arrow to the top" />;

export default AIIllustration;
