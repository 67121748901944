import * as actionTypes from "../actions/actionTypes";
import update from "immutability-helper";
import { actionTypeEnum } from "../../../helper/enums/actionType";
import { actionPageTypeEnum } from "../../../helper/enums/actionPageTypes";
import { errorResponseToList } from "../../../helper/errorHelper";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  listWorkflowSteps: [],
  campaignId: "",
  currentPage: actionPageTypeEnum.startPage,
  activeStepId: "",
  isLoadingCreateWorkflowSteps: false,
  isLoadedCreateWorkflowSteps: false,
  errorCreateWorkflowSteps: "",
};

const createWorkflowStepsRequest = (state, action) => {
  return {
    ...state,
    isLoadingCreateWorkflowSteps: true,
    isLoadedCreateWorkflowSteps: false,
  };
};

const createWorkflowStepsSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoadedCreateWorkflowSteps = true;
  newState.isLoadingCreateWorkflowSteps = false;
  return newState;
};

const createWorkflowStepsFailed = (state, action) => {
  const newState = { ...state };
  newState.isLoadedCreateWorkflowSteps = true;
  const errorList = errorResponseToList(action.result);
  var errorType = 0;
  if (errorList.length > 0) {
    errorType = errorList[0].field;
  }
  newState.errorCreateWorkflowSteps = errorType;
  newState.isLoadingCreateWorkflowSteps = false;
  return newState;
};

const deleteWorkflowStepsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const deleteWorkflowStepsSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const deleteWorkflowStepsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchWorkflowStepsListSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  let steps = null;
  if (action.result.steps.length === 0) {
    steps = [{ id: 0, type: actionTypeEnum.Email }, 
      { id: 1, waitDays: 6, active: false, campaignId: action.result.campaignId, type: actionTypeEnum.Wait, details: "6 Days"},
      { id: 2, active: false, type: actionTypeEnum.Email },
      { id: 3, waitDays: 6, active: false, campaignId: action.result.campaignId, type: actionTypeEnum.Wait, details: "6 Days"},
      { id: 4, active: false, type: actionTypeEnum.Email },
      { id: 5, waitDays: 6, active: false, campaignId: action.result.campaignId, type: actionTypeEnum.Wait, details: "6 Days"},
      { id: 6, active: false, type: actionTypeEnum.Email }
    ];
  } else {
    steps = action.result.steps.map((s, i) => {
      return update(s, { $merge: { id: i } });
    });
  }
  newState.listWorkflowSteps = steps;
  return newState;
};

const fetchWorkflowStepsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoadingCreateWorkflowSteps: false,
    isLoadedCreateWorkflowSteps: false,
    errorCreateWorkflowSteps: "",
  };
};

const fetchWorkflowStepsListFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const createWorkflowStepsSequence = (state, action) => {
  let index = state.listWorkflowSteps.findIndex((seq) => seq.id === action.stepId);

  if (index !== undefined) {
    const newState = { ...state };
    let newSequence = [...state.listWorkflowSteps];
    let newObj = { ...newSequence[index] };

    newObj.type = action.actionType;
    newObj.campaignId = state.campaignId;

    if (action.actionType === actionTypeEnum.Wait) {
      const days = action.obj.days === "" ? 0 : parseInt(action.obj.days);
      // const hours = action.obj.hours === "" ? 0 : parseInt(action.obj.hours);
      // const minutes = action.obj.minutes === "" ? 0 : parseInt(action.obj.minutes);

      let numberOfDays = days === 0 ? "" : days === 1 ? days + " Day" : days + " Days";
      // let numberOfHours = hours === 0 ? "" : hours === 1 ? hours + " Hour" : hours + " Hours";
      // let numberOfMinutes = minutes === 0 ? "" : minutes === 1 ? minutes + " Minute" : minutes + " Minutes";

      // const numberString = [numberOfDays, numberOfHours, numberOfMinutes].join(" ");
      const numberString = [numberOfDays].join(" ");

      newObj.waitDays = days;
      // newObj.waitHours = hours;
      // newObj.waitMinutes = minutes;
      newObj.details = numberString;
      newObj.campaignId = action.obj.campaignId;
      newSequence[index] = newObj;
    } else if (action.actionType === actionTypeEnum.Email) {
      newObj.templateId = action.obj.templateId;
      newObj.details = action.obj.templateName;
      newObj.campaignId = action.obj.campaignId;
      newObj.templateType = action.obj.templateType;
      newObj.status = action.obj.templateStatus;
      newObj.subject = action.obj.templateSubject;
      newObj.html = action.obj.templateHtml;
      newObj.active = true;
    }

    newSequence[index] = newObj;
    newState.listWorkflowSteps = newSequence;
    return newState;
  }
};

const checkSequenceForId = (state, action) => {
  const newState = { ...state };
  let newSequence = [...state.listWorkflowSteps];
  newSequence.forEach(function (v) {
    delete v.activeError;
  });

  //if the users clicks two times on addStep not to put two ids
  let idArray = Array.from(newSequence, (x) => x.id);
  let lastId = Math.max.apply(Math, idArray);
  let indexLastId = newSequence.findIndex((seq) => seq.id === lastId);
  let lastEntry = newSequence[indexLastId].type !== undefined;

  if (lastEntry) {
    let index = newSequence.findIndex((seq) => seq.active === true);
    let newObj = { ...newSequence[index] };
    newObj.active = false;
    newSequence[index] = newObj;

    let newId = newSequence[indexLastId].id + 1;
    const newStep = { id: newId, active: true, type: action.actionType };
    newState.activeStepId = newId;
    if (action.index !== undefined) {
      let newPositionIndex = newSequence.findIndex((seq) => seq.id === action.index);
      newSequence.splice(newPositionIndex + 1, 0, newStep);
    } else {
      newSequence.push(newStep);
    }
    newState.listWorkflowSteps = newSequence;
    return newState;
  } else if (indexLastId === 0) {
    newState.activeStepId = indexLastId;
    return newState;
  }
  return state;
};

const setActivePage = (state, action) => {
  const newState = { ...state };
  newState.currentPage = action.page;
  return newState;
};

const setActiveStep = (state, action) => {
  const newState = { ...state };
  newState.activeStepId = action.stepId;

  let newSequence = [...state.listWorkflowSteps];
  let index = newSequence.findIndex((seq) => seq.id === action.stepId);

  // remove error class from save function for two steps of the same type
  newSequence.forEach(function (v) {
    delete v.activeError;
  });

  //remove the active class from the previos active step
  let activeIndex = newSequence.findIndex((seq) => seq.active === true);
  let activeObj = { ...newSequence[activeIndex] };
  activeObj.active = false;
  newSequence[activeIndex] = activeObj;

  //add active class to the current clicked step
  let newObj = { ...newSequence[index] };
  newObj.active = true;
  newSequence[index] = newObj;

  newState.listWorkflowSteps = newSequence;

  return newState;
};

const removeStep = (state, action) => {
  const newState = { ...state };
  let newSequence = [...state.listWorkflowSteps];
  let index = newSequence.findIndex((seq) => seq.id === action.stepId);
  newSequence.splice(index, 1);

  if (newSequence.length === 0) {
    newSequence.push({ id: 0 });
    newState.currentPage = actionPageTypeEnum.startPage;
  }

  newState.listWorkflowSteps = newSequence;
  return newState;
};

const checkSequenceForErrorsRequest = (state, action) => {
  const newState = { ...state };
  newState.isLoading = true;
  newState.isLoaded = false;
  return newState;
};

const checkSequenceForErrorsSuccess = (state, action) => {
  const newState = { ...state };
  let newSequence = [...state.listWorkflowSteps];

  let lastEntry = newSequence[newSequence.length - 1].type === undefined;
  if (lastEntry) {
    let index = newSequence.length - 1;
    newSequence.splice(index, 1);
  }

  for (let index = 0; index < newSequence.length; index++) {
    let stepOne = { ...newSequence[index] };
    let stepTwo = { ...newSequence[index + 1] };

    if (stepOne.type === stepTwo.type) {
      let indexActive = newSequence.findIndex((seq) => seq.active === true);
      let newObj = { ...newSequence[indexActive] };
      newObj.active = false;
      newSequence[indexActive] = newObj;

      newState.error = "sendError";
      stepOne.activeError = true;
      stepTwo.activeError = true;

      newSequence[index] = stepOne;
      newSequence[index + 1] = stepTwo;
    }
  }
  newState.listWorkflowSteps = newSequence;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const clearInitiated = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = false;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_WORKFLOW_STEPS_LIST_REQUEST:
      return fetchWorkflowStepsListRequest(state, action);
    case actionTypes.FETCH_WORKFLOW_STEPS_LIST_SUCCEEDED:
      return fetchWorkflowStepsListSuccess(state, action);
    case actionTypes.FETCH_WORKFLOW_STEPS_LIST_FAILED:
      return fetchWorkflowStepsListFailed(state, action);
    case actionTypes.CREATE_WORKFLOW_STEPS_REQUEST:
      return createWorkflowStepsRequest(state, action);
    case actionTypes.CREATE_WORKFLOW_STEPS_SUCCEEDED:
      return createWorkflowStepsSuccess(state, action);
    case actionTypes.CREATE_WORKFLOW_STEPS_FAILED:
      return createWorkflowStepsFailed(state, action);
    case actionTypes.DELETE_WORKFLOW_STEPS_REQUEST:
      return deleteWorkflowStepsRequest(state, action);
    case actionTypes.DELETE_WORKFLOW_STEPS_SUCCEEDED:
      return deleteWorkflowStepsSuccess(state, action);
    case actionTypes.DELETE_WORKFLOW_STEPS_FAILED:
      return deleteWorkflowStepsFailed(state, action);
    case actionTypes.CREATE_WORKFLOW_STEPS_SEQUENSE:
      return createWorkflowStepsSequence(state, action);
    case actionTypes.CHECK_SEQUENCE_FOR_ID:
      return checkSequenceForId(state, action);
    case actionTypes.SET_ACTIVE_PAGE:
      return setActivePage(state, action);
    case actionTypes.SET_ACTIVE_STEP:
      return setActiveStep(state, action);
    case actionTypes.REMOVE_STEP:
      return removeStep(state, action);
    case actionTypes.CHECK_SEQUENCE_FOR_ERRORS_REQUEST:
      return checkSequenceForErrorsRequest(state, action);
    case actionTypes.CHECK_SEQUENCE_FOR_ERRORS_SUCCESS:
      return checkSequenceForErrorsSuccess(state, action);
    case actionTypes.SEQUENCE_CLEAR_INITIATED:
      return clearInitiated(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
