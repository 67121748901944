import React from "react";
import { Breadcrumb } from "react-bootstrap";

// Style
import "./BreadcrumbElement.scss";

interface BreadcrumbItem {
  active: boolean;
  href?: string | null;
  text: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const BreadcrumbElement: React.FC<BreadcrumbProps> = (props) => {
  const listItems = props.items.map((item, index) => (
    <Breadcrumb.Item active={item.active} href={item.href || undefined} key={index}>
      {item.text}
    </Breadcrumb.Item>
  ));

  return <Breadcrumb>{listItems}</Breadcrumb>;
};

export default BreadcrumbElement;
