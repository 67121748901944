import React from "react";

//Styles
import styles from "./ResponseTypeIcon.module.scss";

const ResponseTypeIcon = (props) => {
  let circleIcon = null;

  switch (props.type) {
    case "Positive response":
      circleIcon = <div className={`${styles.circle} ${styles.positiveResponse}`} />;
      break;
    case "Referral":
      circleIcon = <div className={`${styles.circle} ${styles.referral}`} />;
      break;
    case "Deferred interest":
      circleIcon = <div className={`${styles.circle} ${styles.deferredInterest}`} />;
      break;
    case "Decline":
      circleIcon = <div className={`${styles.circle} ${styles.decline}`} />;
      break;
    default:
      break;
  }

  return <>{circleIcon}</>;
};

export default ResponseTypeIcon;
