import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

//Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import GitRepositoryLineIcon from "remixicon-react/GitRepositoryLineIcon";
import MovieLineIcon from "remixicon-react/MovieLineIcon";

//Styles
import styles from "./CheckList.module.scss";

const AccordionHeader = ({ step, index, open, handleOpen }) => {
  const toggleAccordion = () => {
    if (!step.completed) {
      handleOpen(index);
    }
  };

  const history = useHistory();

  const handleButtonClick = () => {
    history.push({ pathname: step.to.pathname, search: "?tour=true" });
  };

  return (
    <Accordion.Header
      className={styles.accordionCardHeader}
      as={Card.Header}
      eventKey={index.toString()}
      onClick={toggleAccordion}
      disabled={step.completed}
    >
      <>
        <div>
          <div className="flex align-center">
            {step.completed ? (
              <CheckboxCircleFillIcon className="mL5 mR5" color="var(--first-stage-color)" size={20} />
            ) : (
              <ArrowDownSLineIcon size={20} className={`iconGray mL5 mR5 ${open ? styles.rotateIcon : ""}`} />
            )}
            <h1 className={`f16 font500 m0 ${step.completed ? styles.lineThrough : ""}`}>{step.label}</h1>
            {step.subSteps && (
              <span className={`mL5 ${styles.subStepCounter}`}>
                ({step.subStepsCompleted}/{step.subSteps} steps)
              </span>
            )}
          </div>
          {!step.completed ? <p className={`f14 m0 ${styles.description}`}>{step.description}</p> : null}
        </div>
        {!step.completed ? (
          <a
            className={`mR15 ${styles.stepButton}`}
            id={step.buttonText ? step.buttonText.replaceAll(" ", "") : ""}
            variant="primary"
            onClick={handleButtonClick}
          >
            {step.buttonText}
          </a>
        ) : null}
      </>
    </Accordion.Header>
  );
};

const AccordionContent = ({ step, index, openIndex }) => {
  return (
    <Accordion.Body className={styles.cardBody}>
      {/* <Card.Body> */}
      <>
        {React.isValidElement(step.body) ? <>{step.body}</> : <p>{step.body}</p>}
        <>
          <p className="flex align-center m0">
            {step.linkText ? (
              <>
                <GitRepositoryLineIcon size={14} color="var(--info-color)" />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={step.learnMoreLink}
                  className={`mL5 mR10 cursor_pointer ${styles.accordionLink}`}
                >
                  {step.linkText}
                </a>
              </>
            ) : null}
            {step.watchVideo ? (
              <>
                <MovieLineIcon
                  size={14}
                  color="var(--info-color)"
                  onClick={() => {
                    step.setDisplayVideo(step.id);
                    step.setShowVideoModal(true);
                  }}
                />
                <p
                  className={`mL5 cursor_pointer ${styles.accordionLink}`}
                  onClick={() => {
                    step.setDisplayVideo(step.id);
                    step.setShowVideoModal(true);
                  }}
                >
                  Watch video
                </p>
              </>
            ) : null}
          </p>
        </>
        {/* {step.readMoreText && step.completed ? (
          <p className={`f12 mT15 ${styles.readMoreText}`}>
            Read more about {step.readMoreAbout}
            <a className={styles.accordionLink} rel="noopener noreferrer" target="_blank" href={step.readMoreLink}>
              {step.readMoreText}
            </a>
            .
          </p>
        ) : null} */}
        {/* </Card.Body> */}
      </>
    </Accordion.Body>
  );
};

const StepItem = ({ step, index, openIndex, handleOpen }) => (
  <Accordion.Item className={styles.accordionCard} eventKey={index}>
    <AccordionHeader step={step} index={index} open={openIndex === index} handleOpen={handleOpen} />
    <AccordionContent step={step} index={index} openIndex={openIndex} />
  </Accordion.Item>
);

const CheckList = (props) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index);

    if (props.isAccordionOpen != null) {
      props.isAccordionOpen(openIndex === index);
    }

    if (props.sendApiCall) {
      props.sendApiCall();
    }
  };

  const sortedSteps = [...props.steps].sort((a, b) => (a.completed === b.completed ? 0 : a.completed ? -1 : 1));

  return (
    <div className={`flex_column ${styles.checkListComponent} ${props.className}`}>
      <Accordion className={`mT20 ${styles.accordion}`}>
        {sortedSteps.map((step, index) => (
          <StepItem key={index} step={step} index={index} openIndex={openIndex} handleOpen={handleOpen} />
        ))}
      </Accordion>
    </div>
  );
};

export default CheckList;
