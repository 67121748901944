import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Components
import Modal from "../../../../components/UI/Modal/Modal";

const PlanNotPermittedModal = (props) => {
  return (
    <Modal
      title={"Plan not valid"}
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>You are not allowed to upgrade/downgrade to this plan.</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default PlanNotPermittedModal;
