import React from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";

//Styles
import "./MappingOutbaseFields.scss";

//Images & Icons
import IconCheckMark from "../../../../assets/Icons/IconCheckMark/IconCheckMark";

//Components
import Contact from "./Contact/Contact";
import Companies from "./Compaines/Companies";

const MappingOutbaseFields = (props) => {
  const {
    companiesMappings,
    contactsMappings,
    companiesProperties,
    contactsProperties,
    fecthGlobalMappingsCustomFields,
    globalMappingsCustomFields,
  } = props;

  const listOfSocialNetworks = [
    { label: "Facebook ID", value: "hs_facebookid" },
    { label: "Googleplus ID", value: "hs_googleplusid" },
    { label: "Linkedin ID", value: "hs_linkedinid" },
    { label: "Twitter ID", value: "hs_twitterid" },
  ];

  return (
    <div className="componentWrapper">
      <Tab.Container id="sideTabs" defaultActiveKey="first">
        <Row>
          <Col sm={12} className="description">
            <p className="mB15">Map Outbase fields with your HubSpot fields</p>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first" className="navTabs">
                  <IconCheckMark iconClass="checkIcon" width="15" height="100%" fill="#faac14" />
                  Contact Properties
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" className="navTabs secondItem">
                  <IconCheckMark iconClass="checkIcon" width="15" height="100%" fill="#faac14" />
                  Company Properties
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Contact
                  contactsMappings={contactsMappings}
                  contactsProperties={contactsProperties}
                  listOfSocialNetworks={listOfSocialNetworks}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Companies
                  companiesMappings={companiesMappings}
                  companiesProperties={companiesProperties}
                  fecthGlobalMappingsCustomFields={fecthGlobalMappingsCustomFields}
                  globalMappingsCustomFields={globalMappingsCustomFields}
                  listOfSocialNetworks={listOfSocialNetworks}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default MappingOutbaseFields;
