export const actionsOptions = [
  {
    label: "Create Contact",
    value: 4,
    actionRelated: "contacts",
    description: "Create Contact in HubSpot",
  },
  {
    label: "Create or Update Contact",
    value: 5,
    actionRelated: "contacts",
    description: "Create or Update Contact in HubSpot",
  },
  // { label: 'Update Contact', value: 6, actionRelated: "contacts", description: 'Update Contact in HubSpot' },
  {
    label: "Create Company",
    value: 1,
    actionRelated: "companies",
    description: "Create Company in HubSpot",
  },
  {
    label: "Create or Update Company",
    value: 2,
    actionRelated: "companies",
    description: "Create or Update Company in HubSpot",
  },
  // { label: 'Update Company', value: 3, actionRelated: "companies", description: 'Update Company in HubSpot' },
  {
    label: "Create Deal",
    value: 7,
    actionRelated: "deals",
    description: "Create Deal in HubSpot",
  },
  // { label: 'Create or Update Deal', value: 8, actionRelated: "deals", description: 'Create or Update Deal in HubSpot' },
  // { label: 'Update Deal', value: 9, actionRelated: "deals", description: 'Update Deal in HubSpot' },
];

export const actionsOptionsGrouped = [
  {
    eventValue: 1,
    actions: [
      //EmailSent
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
      {
        label: "Create Company",
        value: 1,
        actionRelated: "companies",
        description: "Create Company in HubSpot",
      },
      {
        label: "Create or Update Company",
        value: 2,
        actionRelated: "companies",
        description: "Create or Update Company in HubSpot",
      },
      {
        label: "Create Deal",
        value: 7,
        actionRelated: "deals",
        description: "Create Deal in HubSpot",
      },
    ],
  },
  {
    eventValue: 2,
    actions: [
      //EmailOpened
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
    ],
  },
  {
    eventValue: 3,
    actions: [
      //LinkClicked
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
    ],
  },
  {
    eventValue: 4,
    actions: [
      //Decline
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
      {
        label: "Create Company",
        value: 1,
        actionRelated: "companies",
        description: "Create Company in HubSpot",
      },
      {
        label: "Create or Update Company",
        value: 2,
        actionRelated: "companies",
        description: "Create or Update Company in HubSpot",
      },
      {
        label: "Create Deal",
        value: 7,
        actionRelated: "deals",
        description: "Create Deal in HubSpot",
      },
    ],
  },
  {
    eventValue: 5,
    actions: [
      //DefferedInterest
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
      {
        label: "Create Company",
        value: 1,
        actionRelated: "companies",
        description: "Create Company in HubSpot",
      },
      {
        label: "Create or Update Company",
        value: 2,
        actionRelated: "companies",
        description: "Create or Update Company in HubSpot",
      },
      {
        label: "Create Deal",
        value: 7,
        actionRelated: "deals",
        description: "Create Deal in HubSpot",
      },
    ],
  },
  {
    eventValue: 6,
    actions: [
      //ReferealResponse
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
      {
        label: "Create Company",
        value: 1,
        actionRelated: "companies",
        description: "Create Company in HubSpot",
      },
      {
        label: "Create or Update Company",
        value: 2,
        actionRelated: "companies",
        description: "Create or Update Company in HubSpot",
      },
      {
        label: "Create Deal",
        value: 7,
        actionRelated: "deals",
        description: "Create Deal in HubSpot",
      },
    ],
  },
  {
    eventValue: 7,
    actions: [
      //PositiveResponse
      {
        label: "Create Contact",
        value: 4,
        actionRelated: "contacts",
        description: "Create Contact in HubSpot",
      },
      {
        label: "Create or Update Contact",
        value: 5,
        actionRelated: "contacts",
        description: "Create or Update Contact in HubSpot",
      },
      {
        label: "Create Company",
        value: 1,
        actionRelated: "companies",
        description: "Create Company in HubSpot",
      },
      {
        label: "Create or Update Company",
        value: 2,
        actionRelated: "companies",
        description: "Create or Update Company in HubSpot",
      },
      {
        label: "Create Deal",
        value: 7,
        actionRelated: "deals",
        description: "Create Deal in HubSpot",
      },
    ],
  },
];
//values: IntegrationActionEnum

// export const triggerOptions = [
//   { label: 'Email Sent', value: 1 },
//   { label: 'Email Opened', value: 2 },
//   { label: 'Link Clicked', value: 3 },
//   { label: 'Decline Response', value: 4 },
//   { label: 'Deferred Interest Response', value: 5 },
//   { label: 'Referral Response', value: 6 },
//   { label: 'Positive Response', value: 7 }
// ]
