import React, { useState, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import semver from "semver";

//Helpers
import { activeApplicationEnum } from "../../helper/enums/activeApplicationEnum";

//Styles
import classes from "./Sidebar.module.scss";

//Components
import SideMenuContent from "./Content/SideMenuContent";
import SidebarHeader from "./Header/Header";

const Sidebar = (props) => {
  const [isExpanded, setExpanded] = useLocalStorage("sidebar", false);
  const [isOpen, setOpen] = useState(isExpanded);
  const testMode = props.testMode;

  useEffect(() => {
    if (props.currentApplication !== activeApplicationEnum.Outbase) {
      document.body.classList.add("outbossTheme");
    } else {
      document.body.classList.remove("outbossTheme");
    }
  }, [props.currentApplication]);

  useEffect(() => {
    if (isExpanded) {
      document.body.classList.add("sidebarExpanded");
      open();
    }
  }, [isExpanded]);

  const open = () => {
    document.body.classList.remove("sidebarClosed");
    document.body.classList.add("sidebarOpened");
    document.body.classList.remove("cursorNone");
    setOpen(true);
  };

  const close = () => {
    document.body.classList.remove("sidebarOpened");
    document.body.classList.add("sidebarClosed");
    document.body.classList.add("cursorNone");
    setOpen(false);
  };

  const sideBarHover = () => {
    if (!isOpen) {
      open();
    }
  };

  const sideBarLeave = () => {
    if (!isExpanded) {
      close();
    }
  };

  const sideBarExpanded = () => {
    if (isExpanded) {
      document.body.classList.remove("sidebarExpanded");
      document.body.classList.remove("sidebarOpened");
      document.body.classList.add("sidebarClosed");
      setExpanded(false);
    } else {
      document.body.classList.add("sidebarExpanded");
      setExpanded(true);
    }
  };

  let version = semver.coerce(import.meta.env.VITE_VERSION);

  let versionTitle = version.toString();
  let environment = "";
  let fullVersion = import.meta.env.VITE_VERSION;

  if (import.meta.env.VITE_ENV === "development") {
    versionTitle += "D";
    environment += "Development";
  }
  if (import.meta.env.VITE_ENV === "staging") {
    versionTitle += "S";
    environment += "Staging";
  }

  let testModeText = "";
  if (testMode === true) {
    testModeText = "Test mode";
  }

  return (
    <div
      id="sideMenu"
      className={
        props.currentApplication === activeApplicationEnum.Outboss
          ? `${classes.sideMenu} ${classes.lightMenu} sideMenu outbOssMenu`
          : `${classes.sideMenu} ${classes.darkMenu} sideMenu`
      }
      onMouseOver={sideBarHover}
      onMouseLeave={sideBarLeave}
    >
      <SidebarHeader isOpen={isOpen} sideBarToggle={sideBarExpanded} />
      <OverlayTrigger
        key={"version"}
        placement={"right"}
        overlay={
          <Popover id={`tooltip`}>
            <Popover.Body>
              v{fullVersion} {environment}
            </Popover.Body>
          </Popover>
        }
      >
        <p className={`${classes.currentVersion} currentVersionClose`}>
          {testModeText} v{versionTitle}
        </p>
      </OverlayTrigger>

      <SideMenuContent props={props.props} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    testMode: state?.user?.organization?.isInTestMode ?? false,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
  };
};

export default connect(mapStateToProps)(Sidebar);
