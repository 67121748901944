import _ from "lodash";
import { useStore } from "react-redux";

export const useGroupDecimalSeparator = () => {
  const store = useStore();
  let organization = store.getState().organizationSettingsGeneral?.settings;
  let defaultGroupSeparatorValue = ",";
  let defaultDecimalSeparatorValue = ".";

  if (!_.isEmpty(organization)) {
    let { numberGroupSeperator, numberDecimalSeperator } = organization;

    if (_.isNull(numberGroupSeperator) || _.isUndefined(numberGroupSeperator) || _.isEmpty(numberGroupSeperator)) {
      numberGroupSeperator = defaultGroupSeparatorValue;
    }

    if (_.isNull(numberDecimalSeperator) || _.isUndefined(numberDecimalSeperator) || _.isEmpty(numberDecimalSeperator)) {
      numberDecimalSeperator = defaultDecimalSeparatorValue;
    }

    return { numberGroupSeperator, numberDecimalSeperator };
  } else {
    return {
      numberGroupSeperator: defaultGroupSeparatorValue,
      numberDecimalSeperator: defaultDecimalSeparatorValue,
    };
  }
};
