import React, { useState } from "react";

// Components
import AntSwitch from "../../../../components/UI/AntSwitch/Switch";

const NotificationSwitch = (props) => {
  const { title, isEnabled } = props;
  const [enabled, setEnabled] = useState(isEnabled);

  const onChange = (event) => {
    setEnabled(!enabled);
    props.onChange(event);
  };

  return (
    <div>
      <h5>{title}</h5>
      <AntSwitch id={props.key} onChange={(e) => onChange(e)} checked={enabled} name={title} />
    </div>
  );
};

export default NotificationSwitch;
