import React, { useState, useEffect } from "react";
import _ from "lodash";

//Components
import InfoRow from "../../../components/UI/InfoRow/InfoRow";

const UnverifiedDomains = (props) => {
  const { currentRows } = props;
  let currentDnsVerifiedCounter = 0;

  const [unverifiedCount, setUnverifiedCount] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(currentRows)) {
      currentRows.forEach((domain) => {
        if (!domain.dnsVerified) {
          currentDnsVerifiedCounter++;
        }
      });
    }

    setUnverifiedCount(currentDnsVerifiedCounter);
  }, [currentRows]);

  if (unverifiedCount === 0) return null;

  const text = unverifiedCount + " domain(s) not verified. Click the edit button (pencil icon) to add and verify the domain.";
  return <InfoRow text={text} />;
};

export default UnverifiedDomains;
