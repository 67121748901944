import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  isLoaded: false,
  error: "",
  listAudiences: [],
  audienceDetails: "",
};

const fetchAudiencesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchAudiencesSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listAudiences };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listAudiences = list;
  return newState;
};

const fetchAudiencesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchAudienceDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    audienceDetails: "",
  };
};

const fetchAudienceDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let audienceDetails = { ...state.audienceDetails };
  audienceDetails = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.audienceDetails = audienceDetails;
  return newState;
};

const fetchAudienceDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const audienceDetailsUpdate = (state, action) => {
  const newState = { ...state };
  let audienceDetails = { ...state.audienceDetails };

  audienceDetails.availableContacts = action.result.audienceAvailable;
  audienceDetails.audienceSize = action.result.audienceSize;
  audienceDetails.prospectsImported = action.result.audienceImported;
  audienceDetails.prospectsNotImported = action.result.audienceNotImported;
  audienceDetails.campaignId = action.result.campaignId;
  audienceDetails.campaignName = action.result.campaignName;

  newState.audienceDetails = audienceDetails;
  return newState;
};

const audienceStatsUpdate = (state, action) => {
  const newState = { ...state };
  let audienceDetails = { ...state.audienceDetails };

  audienceDetails.audienceSize = action.result.audienceSize;

  audienceDetails.availableContacts = action.result.availableContacts;
  audienceDetails.dontImportContacts = action.result.dontImportContacts;
  audienceDetails.prospectsImported = action.result.prospectsImported;
  audienceDetails.prospectsNotImported = action.result.prospectsNotImported;

  newState.audienceDetails = audienceDetails;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_AUDIENCES_REQUEST:
      return fetchAudiencesRequest(state, action);
    case actionTypes.FETCH_AUDIENCES_SUCCEEDED:
      return fetchAudiencesSuccess(state, action);
    case actionTypes.FETCH_AUDIENCES_FAILED:
      return fetchAudiencesFailed(state, action);
    case actionTypes.FETCH_AUDIENCE_DETAILS_REQUEST:
      return fetchAudienceDetailsRequest(state, action);
    case actionTypes.FETCH_AUDIENCE_DETAILS_SUCCEEDED:
      return fetchAudienceDetailsSuccess(state, action);
    case actionTypes.FETCH_AUDIENCE_DETAILS_FAILED:
      return fetchAudienceDetailsFailed(state, action);
    case actionTypes.AUDIENCE_DETAILS_UPDATE:
      return audienceDetailsUpdate(state, action);
    case actionTypes.AUDIENCE_STATS_UPDATE:
      return audienceStatsUpdate(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
