import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";

//Styles
import styles from "./AudienceContacts.module.scss";

//Images & Icons
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";

// Components
import Modal from "../../components/UI/Modal/Modal";
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";
import Spinner from "@ui/Spinner/Spinner";
import { useGetContactByIdQuery, useGetContactDetailsQuery } from "@api/audienceExplorerApi";

interface DetailsFormProps {
  showModal: boolean;
  handleClose: () => void;
  contactId?: number | null;
  organizationSettings?: any;
}

const ContactDetailsForm: React.FC<DetailsFormProps> = (props) => {
  const queryResult =
    props.contactId && props.contactId !== 0
      ? props.organizationSettings
        ? useGetContactByIdQuery(props.contactId)
        : useGetContactDetailsQuery(props.contactId)
      : { data: null, isLoading: false };

  const { data: contact, isLoading } = queryResult;

  return (
    <Modal
      title={"Contact Details"}
      handleSave={props.handleClose}
      handleClose={() => props.handleClose()}
      show={props.showModal}
      oneButton={true}
      saveButtonText="Close"
      btnType="submit"
    >
      <>
        {isLoading && <Spinner />}
        {contact && (
          <Container>
            <div className={styles.profileDetails}>
              <div className={styles.profileImage}>
                <UserPhoto alt="org-photo" isCompanyImage={false} size="medium-l" className="m0" />
              </div>
              <div xl={9} className={styles.detailsInfo}>
                <h4 className={styles.headerName}>{contact.fullName}</h4>
                {!_.isEmpty(contact.locationCountry) ? (
                  <span className={styles.location}>
                    <MapPin2FillIcon size={16} className="iconAccent mR5" />
                    {contact.locationCountry}
                  </span>
                ) : null}
              </div>
            </div>
            <Row>
              <Col xl={12}>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col xl={4}>
                <span className={styles.informationType}>Company:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {contact.companyName && contact.companyName !== "" ? contact.companyName : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Job position:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>{contact.title && contact.title !== "" ? contact.title : "No Info"}</span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Industry:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>{contact.industry && contact.industry !== "" ? contact.industry : "No Info"}</span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Company size:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {contact.companySize && contact.companySize !== "" ? contact.companySize : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Revenue:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>{contact.revenue ? contact.revenueRange : "No Info"}</span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Website:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {contact.companyWebsite && contact.companyWebsite !== "" ? contact.companyWebsite : "No Info"}
                </span>
              </Col>
              <Col xl={12} className={styles.socialIconsWrapper}>
                <div className={styles.text}>
                  <div>
                    <h4 className={styles.sectionName}>Social Media</h4>
                  </div>
                </div>
                <div className={styles.socialIcons}>
                  {contact.linkedInUrl && contact.linkedInUrl !== "" ? (
                    <div>
                      <a
                        rel="noopener noreferrer"
                        href={contact.linkedInUrl.includes("https") ? contact.linkedInUrl : "//" + contact.linkedInUrl}
                        target="_blank"
                        className=""
                      >
                        <LinkedinBoxFillIcon color="#0077B5" />
                      </a>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </>
    </Modal>
  );
};

export default ContactDetailsForm;
