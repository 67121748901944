import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Redux
import { fetchGlobalSearchCompanies } from "../../../../store/GlobalSearch/actions/globalSearch";

//Hooks
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";

//Style
import styles from "./CompaniesSearch.module.scss";

// Components
import CompaniesSearchRow from "./CompaniesSearchRow/CompaniesSearchRow";
import SkeletonAvatarText from "../../../UI/Skeletons/components/SkeletonAvatarText/SkeletonAvatarText";

const CompaniesSearch = (props) => {
  const organizationId = useOrganizationId();
  const { param } = props;

  let list = null;

  if (props.error) {
    list = <h2>Error getting Companies</h2>;
  }

  if (props.isLoading) {
    list = <SkeletonAvatarText />;
  }

  if (props.isLoaded) {
    list =
      props.listCompanies.length > 0 ? (
        props.listCompanies.map((com, index) => (
          <Link
            key={index}
            to={{
              pathname: "/" + organizationId + "/company-activity-overview/" + com.id,
            }}
            className="text_decoration_none"
            onClick={props.hrefClicked}
          >
            <CompaniesSearchRow name={com.name} industry={com.industry} country={com.country} />
          </Link>
        ))
      ) : (
        <p className={styles.noResultMessage}>No results found</p>
      );
  }

  return (
    <>
      <div className={styles.titleWrapper}>
        <h5 className={styles.title}>Companies</h5>
        {props.listCompanies.length > 0 ? (
          <Link
            to={{
              pathname: "/" + props.organizationId + "/globalsearch",
              search: "search=" + props.param,
              aboutProps: {
                page: 2,
              },
            }}
          >
            <p onClick={props.hrefClicked} className={styles.link}>
              Show all
            </p>
          </Link>
        ) : null}
      </div>
      {list}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.globalSearh.search,
    isLoading: state.globalSearh.companiesIsLoading,
    isLoaded: state.globalSearh.companiesIsLoaded,
    error: state.globalSearh.companiesError,
    listCompanies: state.globalSearh.listCompanies,
    organizationId: state.user.organization?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalSearchCompanies: (param) => dispatch(fetchGlobalSearchCompanies(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesSearch);
