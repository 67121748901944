import React from "react";

const IconFilePpt = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667Z"
      fill="#F57E00"
    />
    <path
      d="M23.9667 7.99999C23.9333 7.86666 23.9 7.76666 23.8333 7.66666L17.3333 0.399994V7.99999H23.9667Z"
      fill="#F57E00"
    />
    <path
      d="M2 13.6667H22V30H2V13.6667ZM2.2 2.13333H3.7C4.56667 2.13333 5.16667 2.8 5.16667 3.76666C5.16667 4.73333 4.6 5.43333 3.7 5.43333H3.3V6.9H2.2V2.13333V2.13333ZM3.5 4.46666C3.83333 4.46666 4.03333 4.16666 4.03333 3.76666C4.03333 3.36666 3.8 3.1 3.5 3.1H3.3V4.46666H3.5ZM6.13333 2.13333H7.63333C8.5 2.13333 9.1 2.8 9.1 3.76666C9.1 4.73333 8.53333 5.43333 7.63333 5.43333H7.23333V6.9H6.13333V2.13333V2.13333ZM7.43333 4.46666C7.76667 4.46666 7.96667 4.16666 7.96667 3.76666C7.96667 3.36666 7.73333 3.1 7.43333 3.1H7.2V4.46666H7.43333ZM10.7667 3.1H9.73333V2.13333H12.9333V3.1H11.8667V6.9H10.7667V3.1V3.1Z"
      fill="white"
    />
    <path
      d="M17.6667 15.9H6.33334C5.76667 15.9 5.3 16.3667 5.3 16.9333V24.2C5.3 24.7667 5.76667 25.2333 6.33334 25.2333H11.0667L10.6 27H10.0667C9.83334 27 9.66667 27.1667 9.66667 27.4C9.66667 27.6333 9.83334 27.8 10.0667 27.8H13.9C14.1333 27.8 14.3 27.6333 14.3 27.4C14.3 27.1667 14.1333 27 13.9 27H13.3333L12.8667 25.2H17.6667C18.2333 25.2 18.7 24.7333 18.7 24.1667V16.9333C18.7 16.3667 18.2333 15.9 17.6667 15.9V15.9ZM17.7667 24.2667H6.23334V16.8667H17.7333L17.7667 24.2667V24.2667Z"
      fill="#F57E00"
      stroke="#F57E00"
      strokeMiterlimit="10"
    />
    <path
      d="M9.20001 21.3H10.1333V23.1667H9.20001V21.3ZM10.7667 20.7H11.7V23.1667H10.7667V20.7ZM12.3 20.0667H13.2333V23.1667H12.3V20.0667ZM13.8333 19.4667H14.7667V23.1667H13.8333V19.4667ZM13.7667 18.1L12.1333 19L10.9333 18.6L8.90001 19.7L9.20001 20.0333L11 19.1L12.2 19.5L14.0667 18.4667L14.3667 18.8L15.0667 17.6333L13.4667 17.7333L13.7667 18.1Z"
      fill="#F57E00"
      stroke="#F57E00"
      strokeWidth="0.2"
      strokeMiterlimit="10"
    />
  </svg>
);
export default IconFilePpt;
