import _ from "lodash";
import { useSlice } from "./useSlice";

const defaultDateTimeFormat = "DD MMM YYYY HH:mm";

export const useOrgDateTimeFormat = (): string => {
  const organization = useSlice((state) => state.organizationSettingsGeneral)?.settings;
  const userOrganization = useSlice((state) => state.user)?.organization;

  if (organization && !_.isEmpty(organization) && organization.dateFormat && organization.timeFormat) {
    return `${organization.dateFormat} ${organization.timeFormat}`;
  }

  if (userOrganization && !_.isEmpty(userOrganization) && userOrganization.dateFormat && userOrganization.timeFormat) {
    return `${userOrganization.dateFormat} ${userOrganization.timeFormat}`;
  }

  return defaultDateTimeFormat;
};
