import React from "react";
import _ from "lodash";

// Styles
import styles from "./ListEmailAccounts.module.scss";

// Components
import Checkbox from "../../../../../components/UI/Checkbox/Checkbox";
import UserPhoto from "../../../../../components/UI/UserPhoto/UserPhoto";

const ListEmailAccounts = (props) => {
  let checkBox = null;
  let activityWeight = null;

  props.checkBox
    ? (checkBox = <Checkbox onChange={props.handleCheckBox} checked={props.usedInCampaign} />)
    : (activityWeight = <p className={styles.totalUsed}>{props.activityWeight}</p>);

  let messagesPerDay = null;
  if (!_.isEmpty(props.messagesPerDay)) {
    messagesPerDay = <p className={styles.ownerDayLimit}> Messages per day: {props.messagesPerDay}</p>;
  }

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.checkBoxWrapper}>{checkBox}</div>
      <div className={styles.imageAccount}>
        <UserPhoto src={props.imageUrl} name={props.emailName} size="medium" />
      </div>
      <div className={styles.accountDetails}>
        <p className={styles.accountOwner}>{props.emailName}</p>
        <p className={styles.ownerEmail}>{props.email}</p>
        {messagesPerDay}
        <p className={styles.ownerEmail}>User: {props.userFullName}</p>
        {props.usedInOtherCampaigns ? <p className={styles.inAnotherCampaign}> in another campaign</p> : null}
      </div>
      {/* <div className={styles.usedEmails}>
                {activityWeight}
            </div> */}
    </div>
  );
};

export default ListEmailAccounts;
