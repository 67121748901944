import React, { useState, useContext } from "react";
import { Prompt } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";

//Redux
import { updateOrganizationSettingsSuccess } from "../../../store/global/actions/user";
import { fetchSettings } from "../../../store/OrganizationSettings/General/actions/general";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import api, { API } from "../../../helper/api/api";
import { dateFormatOptions } from "../helpers/dateFormatOptions";
import { timeFormatOptions } from "../helpers/timeFormatOptions";
import { numberFormatOptions } from "../helpers/numberFormatOptions";
import { DOMAIN_URL_CHECK } from "../../../helper/validationConstants";
import { toastMessages } from "../../../helper/toastMessagesConstants";

//Styles
import styles from "./OptionalSettings.module.scss";

//Components
import SaveChangesFooter from "../../../components/SaveChangesFooter/SaveChangesFooter";
import FormikEffect from "../../../components/UI/Formik/FormikEffect";
import Permission from "../../../components/auth/Permission";
import FormikSelect from "../../../components/UI/Formik/FormikSelect";
import CancelWarningForm from "../../../components/CancelWarningForm/CancelWarningForm";

const OptionalSettings = (props) => {
  const auth = useAuth();
  const [hasChanges, setHasChanges] = useState(false);
  const [removePrompt, setRemovePrompt] = useState(false);

  const onChange = (values) => {
    setHasChanges(true);
    props.setUpdateOptionalInfo(values);
  };

  const [showCancelWarningModal, setShowCancelWarningModal] = useState(false);
  const handleCancelWarningClose = () => {
    setShowCancelWarningModal(false);
  };

  const [pathname, setPathname] = useState(null);

  const updateChanges = async () => {
    setRemovePrompt(true);
    setHasChanges(false);
  };

  const handleCancelWarningSave = async () => {
    await updateChanges();
    setShowCancelWarningModal(false);
    props.history.push({
      pathname: pathname,
      state: true,
    });
  };

  const handleCancelModal = (location) => {
    if (removePrompt) {
      return true;
    }
    setPathname(location.pathname);
    setShowCancelWarningModal(true);
    return false;
  };

  let countryLocation = "";
  if (!_.isEmpty(props.globalSettings.countries) && !_.isEmpty(props.organization.location)) {
    countryLocation = props.globalSettings.countries.find((x) => x.value === props.settings.location).label;
  }

  return (
    <Permission has={PERMISSIONS.organization.settings.view} redirect>
      <Formik
        initialValues={{
          currency: props.settings.currency,
          language: props.settings.language,
          timeZone: props.settings.timeZone,
          timeFormat: props.settings.timeFormat,
          dateFormat: props.settings.dateFormat,
          name: props.settings.name,
          domain: props.settings.domain,
          organizationType: props.settings.type,
          industry: props.settings.industry || "",
          companySize: props.settings.companySize || "",
          image: props.settings.imageUrl,
          hasNewImage: false,
          location: props.settings.location,
          numberDecimalSeperator: props.settings.numberDecimalSeperator,
          numberGroupSeperator: props.settings.numberGroupSeperator,
        }}
        validationSchema={Yup.object({
          currency: Yup.string().required("Required"),
          language: Yup.string().required("Required"),
          timeZone: Yup.string().required("Required"),
          timeFormat: Yup.string().required("Required"),
          numberDecimalSeperator: Yup.string().required("Required"),
          numberGroupSeperator: Yup.string().required("Required"),
          dateFormat: Yup.string().required("Required"),
          name: Yup.string().required("Required"),
          domain: Yup.string().required("Required").matches(DOMAIN_URL_CHECK, "Must be Valid Domain Or Website Url"),
        })}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let data = new FormData();
          data.append("name", values.name);
          data.append("domain", values.domain);
          data.append("industry", values.industry);
          data.append("companySize", values.companySize);
          data.append("image", values.image);
          data.append("currency", values.currency);
          data.append("language", values.language);
          data.append("timeZone", values.timeZone);
          data.append("timeFormat", values.timeFormat);
          data.append("numberDecimalSeperator", values.numberDecimalSeperator);
          data.append("numberGroupSeperator", values.numberGroupSeperator);
          data.append("dateFormat", values.dateFormat);
          data.append("location", values.location);
          data.append("organizationType", values.organizationType);

          api(API.organization.settings.update, data)
            .then((response) => {
              props.fetchSettings();
              auth.signinSilent();
              toast.success(toastMessages.settings.successOptionalSettingsUpdate);
              setHasChanges(false);
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);

              if (errorList.length > 0) {
                errorList.forEach((e) => actions.setFieldError(e.field, e.message));
              } else {
                toast.error(error.message);
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        onReset={() => {
          setHasChanges(false);
        }}
      >
        {(formikProps) => {
          const { values, setFieldValue } = formikProps;

          const handleChange = (sign, field, setField) => {
            if (sign === ",") {
              setField(field, ".");
            }
            if (sign === ".") {
              setField(field, ",");
            }
          };
          return (
            <Form>
              <Prompt when={hasChanges} message={(location) => handleCancelModal(location)} />
              <FormikEffect onChange={() => onChange(values)} />
              <div className={`${styles.formWrapper} flex `}>
                <div className={styles.formSection}>
                  <div className={styles.inputGroup}>
                    <label className={styles.locationlabel}>
                      Location: <span>{countryLocation}</span>
                    </label>
                    <FormikSelect
                      label="Time Zone"
                      name="timeZone"
                      value={values.timeZone}
                      options={props.globalSettings.timeZones}
                      placeholder="Select one..."
                      isSearchable={true}
                    />
                    <FormikSelect
                      label="Time Format"
                      name="timeFormat"
                      value={values.timeFormat}
                      options={timeFormatOptions}
                      placeholder="Select one..."
                      isSearchable={true}
                    />
                    <FormikSelect
                      label="Date format"
                      name="dateFormat"
                      value={values.dateFormat}
                      options={dateFormatOptions}
                      placeholder="Select one..."
                      isSearchable={true}
                    />
                    <FormikSelect
                      label="Language"
                      name="language"
                      value={values.language}
                      options={props.globalSettings.languages}
                      placeholder="Select one..."
                      isSearchable={true}
                    />
                    <FormikSelect
                      label="Currency"
                      name="currency"
                      value={values.currency.toString()}
                      options={props.globalSettings.currencies}
                      placeholder="Select one..."
                      isSearchable={true}
                    />
                    <FormikSelect
                      label="Number Group Seperator"
                      name="numberGroupSeperator"
                      value={values.numberGroupSeperator}
                      options={numberFormatOptions}
                      placeholder="Select one..."
                      isSearchable={true}
                      onChange={(e) => handleChange(e, "numberDecimalSeperator", setFieldValue)}
                      enableReinitialize={true}
                    />
                    <FormikSelect
                      label="Number Decimal Seperator"
                      name="numberDecimalSeperator"
                      value={values.numberDecimalSeperator}
                      options={numberFormatOptions}
                      placeholder="Select one..."
                      isSearchable={true}
                      onChange={(e) => handleChange(e, "numberGroupSeperator", setFieldValue)}
                      enableReinitialize={true}
                    />
                  </div>
                </div>
              </div>
              <Container className={styles.general}>
                <Row>
                  <Col className={styles.changesFooter} xl={12} lg={12} sm={12} xs={12}>
                    {hasChanges ? (
                      <SaveChangesFooter
                        cancelClick={formikProps.handleReset}
                        saveChangesClick={formikProps.handleSubmit}
                        cancelButtonDisabled={formikProps.isSubmitting}
                        saveButtonDisabled={formikProps.isSubmitting}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
      <CancelWarningForm showModal={showCancelWarningModal} handleClose={handleCancelWarningClose} handleSave={handleCancelWarningSave} />
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.organizationSettingsGeneral.isLoading,
    isLoaded: state.organizationSettingsGeneral.isLoaded,
    settings: state.organizationSettingsGeneral.settings,
    error: state.organizationSettingsGeneral.error,
    organization: state.user.organization,
    organizations: state.user.organizations,
    globalSettings: state.global.globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrganizationSettingsSuccess: (updatedSettings) => dispatch(updateOrganizationSettingsSuccess(updatedSettings)),
    fetchSettings: () => dispatch(fetchSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionalSettings);
