import noop from "lodash/noop";
import { useEffect } from "react";
import usePrevious from "react-use/lib/usePrevious";
import { useFormikContext } from "formik";

const FormikEffect = ({ onChange = noop }) => {
  const formik = useFormikContext();

  if (!formik) return null;

  const { values, initialValues } = formik;
  const prevValues = usePrevious(values);

  useEffect(() => {
    // Don't run effect on form init
    // Don't send on change on reset
    if (JSON.stringify(values) === JSON.stringify(initialValues)) return;

    if (prevValues) {
      onChange({ prevValues, nextValues: values, values });
    }
  }, [values]);

  return null;
};

export default FormikEffect;
