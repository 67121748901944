import React, { useState, useEffect, useRef } from "react";
import { Send } from "@mui/icons-material";

//Styles
import styles from "./ChatBox.module.scss";

//Components
import Button from "./../../../../components/UI/Button/Button";
import AutoMessage from "../AutoMessage/AutoMessage";
import TypingAnimation from "../../../../components/TypingAnimation/TypingAnimation";

const ChatBox = (props) => {
  const { tinyMCE } = props;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showTypingAnimation, setShowTypingAnimation] = useState(false);
  const [templateAddedToEditor, setTemplateAddedToEditor] = useState(false);
  const [lastAIMessage, setLastAIMessage] = useState("");
  const [lastAISubject, setLastAISubject] = useState("");
  const typingTimeoutId = useRef(null);
  const messagesContainerRef = useRef(null); // Create a ref for the messages container

  const addTemplateToEditor = (text, subject) => {
    if (text !== "") {
      let newText = text.replaceAll("\n", "<br>");
      tinyMCE.setContent(newText);
      props.formikProps.setFieldValue("emailTemplateSubject", subject);
      props.setEmailTemplateSubjectBackup(subject);
      setTemplateAddedToEditor(true);
    }
  };

  useEffect(() => {
    if (props.smartEdit && !props.reopenAIPromptOnChatBox) {
      let subjectFromTemplate = props.formikProps.values.emailTemplateSubject;
      let textFromEditor = tinyMCE.getContent().replace(/(<([^>]+)>)/gi, "");
      setLastAIMessage(textFromEditor);
      setLastAISubject(subjectFromTemplate);
    }
  }, []);

  useEffect(() => {
    // let timeoutId: ReturnType<typeof setTimeout>;
    let timeoutId;

    if (!props.reopenAIPromptOnChatBox) {
      if (props.templateText !== "") {
        const autoMessage = {
          subject: props.subjectText ? props.subjectText : "",
          text: props.templateText,
          isUserMessage: false,
        };

        timeoutId = setTimeout(() => {
          setMessages((prevMessages) => [...prevMessages, autoMessage]);
          setShowTypingAnimation(false);
          setLastAIMessage(autoMessage.text);
          setLastAISubject(autoMessage.subject);
        }, 1000);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [props.templateText]);

  useEffect(() => {
    if (props.allAIRequestLogs.length !== 0 && props.allAIRequestLogs !== undefined) {
      if (props.reopenAIPromptOnChatBox) {
        props.allAIRequestLogs.forEach((element) => {
          if (element.emailTemplate !== "") {
            const autoMessage = {
              subject: element.emailTemplateSubject ? element.emailTemplateSubject : "",
              text: element.emailTemplate,
              isUserMessage: false,
            };
            setMessages((prevMessages) => [...prevMessages, autoMessage]);
          }
          if (element.tweakRequest !== "") {
            const autoMessage = {
              subject: "",
              text: element.tweakRequest,
              isUserMessage: true,
            };
            setMessages((prevMessages) => [...prevMessages, autoMessage]);
          }
        });
        let lastElement = props.allAIRequestLogs.slice(-1).pop();
        let lastText = lastElement.emailTemplate;
        let lastSubject = lastElement.emailTemplateSubject ? lastElement.emailTemplateSubject : "";
        setLastAIMessage(lastText);
        setLastAISubject(lastSubject);
      }
      props.setReopenAIPromptOnChatBox(false);
    }
  }, [props.allAIRequestLogs]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = {
        subject: "",
        text: inputValue.trim(),
        isUserMessage: true,
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
      setShowTypingAnimation(true);
      clearTimeout(typingTimeoutId.current);
      props.tweakTemplate(newMessage.text, lastAIMessage, lastAISubject);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleTyping = () => {
    setIsTyping(true);
    if (typingTimeoutId.current) {
      clearTimeout(typingTimeoutId.current);
    }

    if (inputValue.trim() === "") {
      typingTimeoutId.current = setTimeout(() => {
        setIsTyping(false);
        setShowTypingAnimation(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isTyping) {
      const timeoutId = setTimeout(() => {
        setIsTyping(false);
      }, 1000);
      typingTimeoutId.current = timeoutId;
    }
  }, [isTyping]);

  const isInputEmpty = inputValue.trim() === "";
  const isSendButtonDisabled = isInputEmpty && !showTypingAnimation;

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className={`${styles.chatBox} ${props.chatBoxClass ? props.chatBoxClass : ""}`}>
      <div
        className={`${styles.messageContainer} ${props.messageContainerClass ? props.messageContainerClass : ""}`}
        ref={messagesContainerRef}
      >
        {messages.length !== 0
          ? messages.map((message, index) => (
              <AutoMessage
                addTemplateToEditor={addTemplateToEditor}
                key={index}
                subject={message.subject}
                text={message.text}
                isUserMessage={message.isUserMessage}
                messageClassName={styles.message}
                setSaveAsNew={props.setSaveAsNew}
                setNewTextTemplate={props.setNewTextTemplate}
                setNewSubjectTemplate={props.setNewSubjectTemplate}
                orgId={props.orgId}
                hasChangesFromCampaignSequence={props.hasChangesFromCampaignSequence}
              />
            ))
          : null}
      </div>
      <div className={`flex ${styles.typingAnimationContainer}`}>{showTypingAnimation && <TypingAnimation />}</div>
      <div className={styles.footer}>
        <textarea
          placeholder="Want to refine the template? Simply enter your requirements here e.g. Make the content of the message shorter and funnier."
          className={styles.textArea}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <Button
          id="sendButton"
          variant="roundedButton"
          classes={styles.sendButton}
          onClick={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          disabled={isSendButtonDisabled}
        >
          <Send className="f16" />
        </Button>
      </div>
    </div>
  );
};

export default ChatBox;
