export const filterOptionsFromValues = (options: { label: string }[], values: { label: string }[]) => {
  if (!options) return [];

  // Convert values to a Set for efficient lookup
  let valueLabels = new Set();
  if (Array.isArray(values)) {
    valueLabels = new Set(
      values?.map((item) => {
        if (!item.label) return item.label.toLowerCase();
      })
    );
  } else if (values) {
    valueLabels = new Set(values.label.toLowerCase());
  }

  // Filter options
  const result = options.filter((option) => {
    if (!option || !option.label) return false;

    // If values is not an array or the option's label is not in values, include the option
    if (!Array.isArray(values) || !valueLabels.has(option.label.toLowerCase())) {
      return true;
    }

    return false;
  });

  return result;
};
