import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

//Helpers
import { companySizeEnum } from "../../../../../helper/enums/companySizeEnum";
import { industryEnum } from "../../../../../helper/enums/industryEnum";
import { fetchGlobalSettings } from "../../../../../store/global/actions/index";
import { organizationTypeOptions } from "../../../../../helper/enums/organizationTypeEnum";
import { globalPermissionHelper } from "../../../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../../../helper/globalUserRolePermissionsConstants";
import { mimeFileTypesEnum } from "../../../../../helper/enums/mimeFileTypesEnum";

//Images & Icons
import ImageAddFillIcon from "remixicon-react/ImageAddFillIcon";
import PencilLineIcon from "remixicon-react/PencilLineIcon";

// Styles
import styles from "./Info.module.scss";

// Components
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";
import Button from "../../../../../components/UI/Button/Button";
import Spinner from "../../../../../components/UI/Spinner/Spinner";

const RegistrationStepInfo = (props) => {
  const [imageName, setImageName] = useState(null);
  const [divClass, setDivClass] = useState(styles.uploadImage);

  const { isFormValid, imageValue, setHasNewImage, imageUrlOrganizationDetails, orgDetailsWebsite, websiteValue } = props;

  useEffect(() => {
    props.fetchGlobalSettings();
  }, []);

  useEffect(() => {
    if (imageValue) {
      setImageName(URL.createObjectURL(imageValue));
    } else if (orgDetailsWebsite === websiteValue && imageUrlOrganizationDetails) {
      setImageName(imageUrlOrganizationDetails);
    }

    if (!props.websiteValue) {
      setImageName(null);
      setDivClass(styles.uploadImage);
    }
  }, [imageValue, imageUrlOrganizationDetails, websiteValue]);

  let form = null;

  const industries = Object.values(industryEnum).map((x) => ({
    label: x,
    value: x,
  }));

  const companySizes = Object.values(companySizeEnum).map((x) => ({
    label: x,
    value: x,
  }));

  if (props.isLoading) {
    form = <Spinner />;
  }

  if (props.isLoaded) {
    form = (
      <>
        <fieldset className={`height_100 flex_column ${styles.fieldsWrapper}`}>
          <div className={styles.formWrapper}>
            <div className={styles.detailsInfoStep}>
              <h1 className="f16 font700 textCenter">Your company details</h1>
              <p className={`f14 textCenter ${styles.stepInfo}`}>
                {orgDetailsWebsite
                  ? " Are the details bellow correct?"
                  : "Add your company details below, so we can help you to create your tailored prospecting campaign"}
              </p>
              <div title="Upload Logo" className={divClass}>
                <label>
                  {imageName == null ? (
                    <ImageAddFillIcon alt="upload" color="#99A1B5" className="cursor_pointer" />
                  ) : (
                    <img alt="img" src={imageName} className={styles.imageResponsive} />
                  )}
                  <input
                    id="image"
                    name="image"
                    type="file"
                    style={{ display: "none" }}
                    accept={mimeFileTypesEnum.imagesAll}
                    onChange={(event) => {
                      props.formikProps.setFieldValue("image", event.currentTarget.files[0]);
                      var file = event.currentTarget.files[0];
                      var reader = new FileReader();
                      if (file != null) {
                        var url = reader.readAsDataURL(file);
                      }
                      reader.onloadend = function (e) {
                        let isImageType = file?.type.includes("image");
                        if (!isImageType) return;
                        setImageName([reader.result]);
                        setHasNewImage(true);
                        setDivClass(styles.organizationLogo);
                      };
                    }}
                    className="form-control"
                  />
                </label>
              </div>
              <label className={`flex_center cursor_pointer ${styles.editIconWrapper}`}>
                {imageUrlOrganizationDetails ? (
                  <>
                    <PencilLineIcon size={16} className={`mR5 ${styles.editIcon}`} />
                    update logo image
                  </>
                ) : null}
                <input
                  id="image"
                  name="image"
                  type="file"
                  style={{ display: "none" }}
                  accept={mimeFileTypesEnum.imagesAll}
                  onChange={(event) => {
                    props.formikProps.setFieldValue("image", event.currentTarget.files[0]);
                    var file = event.currentTarget.files[0];
                    var reader = new FileReader();
                    if (file != null) {
                      var url = reader.readAsDataURL(file);
                    }
                    reader.onloadend = function (e) {
                      let isImageType = file?.type.includes("image");
                      if (!isImageType) return;
                      setImageName([reader.result]);
                      setHasNewImage(true);
                      setDivClass(styles.organizationLogo);
                    };
                  }}
                  className="form-control"
                />
              </label>
              {/* </div> */}

              <div className={styles.inputsWrapper}>
                <Row>
                  <Col md={6}>
                    <FormikInput
                      label="Website (*)"
                      name={props.domainName}
                      value={props.websiteValue}
                      placeholder="e.g. https://www.company.io"
                      errorinputclass="absolute"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikSelect
                      label="Industry (*)"
                      placeholder={"Select Industry"}
                      name="industry"
                      options={industries}
                      value={props.industryValue}
                      isSearchable={true}
                      isMenuPortalTargetDisabled
                    />
                  </Col>
                  <Col md={6}>
                    <FormikInput
                      label="Company name (*)"
                      placeholder="Your company name"
                      name={props.name}
                      value={props.nameValue}
                      errorinputclass="absolute"
                    />
                  </Col>
                  <Col md={6}>
                    <FormikSelect
                      label="Company size (*)"
                      placeholder={"Select company size"}
                      name="companySize"
                      options={companySizes}
                      value={props.companySizeValue}
                      isSearchable={true}
                      isMenuPortalTargetDisabled
                    />
                  </Col>
                  <Col md={6}>
                    <FormikSelect
                      label="Location (*)"
                      name="location"
                      options={props.countries}
                      value={props.locationValue}
                      placeholder={"Select Location *"}
                      isSearchable={true}
                      isMenuPortalTargetDisabled
                    />
                  </Col>
                  {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.view) ? (
                    <Col md={6}>
                      <FormikSelect
                        isMenuPortalTargetDisabled
                        label="Organization type (*)"
                        placeholder={"Select organization type"}
                        name="organizationType"
                        options={organizationTypeOptions}
                        value={props.organizationTypeValue}
                        isSearchable={true}
                      />
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>
          </div>
          <div className={styles.actionWrapper}>
            <div className={styles.actionWrapperInner}>
              <Button id="cancel" isLink={true} link="my-organizations" variant="link_button">
                Cancel
              </Button>
              <Button id="continue" variant="primary" type="submit" onClick={props.click} disabled={!isFormValid}>
                Continue
              </Button>
            </div>
          </div>
        </fieldset>
      </>
    );
  }

  return <div className={`width_100 ${styles.stepWrapper}`}>{form}</div>;
};
const mapStateToProps = (state) => {
  return {
    countries: state.global.globalSettings.countries,
    isLoading: state.global.isLoading,
    isLoaded: state.global.isLoaded,
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStepInfo);
