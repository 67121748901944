import React, { useEffect, useState } from "react";
import _ from "lodash";
import { columns } from "./columns";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";

//Redux
import {
  changePage,
  fetchRunRatesByCampaign,
  initiate,
  numRecords,
} from "../../../../store/Dashboard/Tables/actions/RunRatesCampaignView/RunRatesByCampaign";

//Helpers
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";
import { addOffsetToDate } from "../../../../helper/dateHelper";

// Styles
import styles from "./RunRatesByCampaign.module.scss";

//Components
import Table from "../../../../components/DataTable/Table";
import StackedBarChart from "../../../../components/StackedBarChart/StackedBarChart";

const RunRatesByCampaign = (props) => {
  const { params, isInitiated, isLoading, isLoaded, error, rows, totalCount, pageCount, startDate, endDate, filterBy, bar } = props;

  const [tableRows, setTableRows] = useState([]);
  const organizationId = useOrganizationId();
  let table = null;
  const shouldShowPagination = totalCount > 5;

  useEffect(() => {
    let state = {
      params: {
        length: 5,
      },
    };

    props.initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      props.fetchRunRatesByCampaign(addOffsetToDate(startDate), addOffsetToDate(endDate), filterBy, params);
    }
  }, [params, isInitiated, startDate, endDate, filterBy]);

  useEffect(() => {
    if (!_.isNull(rows)) {
      const tempRows = _.cloneDeep(rows);
      tempRows.map((r, index) => {
        r.data = (
          <div key={index} className={`${styles.campaignChartWrapper} campaignChartWrapper`}>
            <div className={styles.detailsLink}>
              <NavLink className="tableLink" to={`/${organizationId}/campaigns/${r.id}/overview`}>
                <span className={styles.title}>{r.campaignName}</span>
              </NavLink>
            </div>
            <StackedBarChart data={r.dashboardRunRates} bar={bar} />
          </div>
        );

        return r;
      });

      setTableRows(tempRows);
    }
  }, [rows]);

  table = (
    <Table
      tableName={"Campaign view"}
      columns={columns}
      rows={tableRows}
      pageCount={pageCount}
      totalCount={shouldShowPagination}
      parentCallback={props.changePage}
      param={params}
      changeNumRecords={props.numRecords}
      tableClassName={styles.accordionTable}
      tableFooterClass={styles.stylePagination}
      scrollY={false}
      wrapperTableClass="no-header-table"
      variant="tableWithComponents"
      showPerPageContent={false}
      noRecordsFoundLabel={
        <span>
          No campaign information to display.{" "}
          <Link className="tableLink" to={{ pathname: "/" + organizationId + "/campaigns" }}>
            Click here to view or add a new campaign
          </Link>
        </span>
      }
    />
  );
  return <div className={styles.accordionWrapper}>{table}</div>;
};

const mapStateToProps = (state) => {
  return {
    params: state.runRatesByCampaignTable.params,
    isInitiated: state.runRatesByCampaignTable.isInitiated,
    isLoading: state.runRatesByCampaignTable.isLoading,
    isLoaded: state.runRatesByCampaignTable.isLoaded,
    error: state.runRatesByCampaignTable.error,
    rows: state.runRatesByCampaignTable.rows,
    totalCount: state.runRatesByCampaignTable.totalCount,
    pageCount: state.runRatesByCampaignTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRunRatesByCampaign: (startDate, endDate, filterBy, params) =>
      dispatch(fetchRunRatesByCampaign(startDate, endDate, filterBy, params)),
    initiate: (state) => dispatch(initiate(state)),
    changePage: (page) => dispatch(changePage(page)),
    numRecords: (obj) => dispatch(numRecords(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RunRatesByCampaign);
