// Import necessary dependencies
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the API service
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as any;
      const orgId = state.user?.organization?.id;

      // Add the header
      if (orgId) {
        headers.set("OrganizationId", orgId);
      }

      // Set default headers
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");

      return headers;
    },
    credentials: "include",
  }),
  endpoints: () => ({}), // No endpoints defined here
  tagTypes: [
    // Assistant
    "AssistantExecution",

    // Compliance
    "Questions",
    "QuestionCategories",

    // Campaign
    "Campaign",
    "Campaigns",
    "CampaignStatuses",
    "CampaignExist",

    // Exclusions
    "ExclusionCount",

    // Email Account
    "EmailAccountsSelectList",
    "EmailAccountsForCampaigns",
    "EmailAccountsList",

    // Email Template
    "EmailTemplates",
    "CreateFromThirdParty",
    "EmailTemplatesVariables",

    // Audience
    "AudiencesForCampaign",
    "AudienceDetails",

    "AudienceContactsCompanyIndustries",
    "AudienceContactsCompanySizes",
    "AudienceContactsCountries",

    // Prospects
    "ProspectDetails",

    // Audience explorer
    "AudienceExplorerSearch",
    "AudienceExplorerSearchUrl",
    "AudienceExplorerContacts",
    "AudienceExplorerContactDetails",
    "AudienceExplorerCompanies",
    "AudienceExplorerCompanyDetails",

    "AudienceExplorerIndustries",
    "AudienceExplorerDomains",
    "AudienceExplorerNaicsCodes",
    "AudienceExplorerSicCodes",
    "AudienceExplorerCompanySizes",
    "AudienceExplorerCompanyNames",
    "AudienceExplorerCompanyRevenueRanges",
    "AudienceExplorerCompanyTechnology",
    "AudienceExplorerCompanyLocations",
    "AudienceExplorerCompanyLocationsRange",
    "AudienceExplorerProspectLocations",
    "AudienceExplorerProspectLocationsRange",
    "AudienceExplorerJobTitleSeniorities",
    "AudienceExplorerJobTitleDepartments",
    "AudienceExplorerJobTitles",
    "AudienceExplorerCountries",
    "AudienceExplorerFundingTypes",

    // Prospects
    "ProspectDetails",

    // Notifications
    "Notifications",

    // Organization
    "OrganizationSettings",

    // Workflow steps
    "WorkflowSteps",

    // Schedule
    "SendingSchedule",

    // Optimization
    "Optimizations",

    // Outboss
    "OutbossEmailAccounts",
  ],
});
