export const CREATE_WORKFLOW_STEPS_REQUEST = "CREATE_WORKFLOW_STEPS_REQUEST";
export const CREATE_WORKFLOW_STEPS_SUCCEEDED = "CREATE_WORKFLOW_STEPS_SUCCEEDED";
export const CREATE_WORKFLOW_STEPS_FAILED = "CREATE_WORKFLOW_STEPS_FAILED";

export const DELETE_WORKFLOW_STEPS_REQUEST = "DELETE_WORKFLOW_STEPS_REQUEST";
export const DELETE_WORKFLOW_STEPS_SUCCEEDED = "DELETE_WORKFLOW_STEPS_SUCCEEDED";
export const DELETE_WORKFLOW_STEPS_FAILED = "DELETE_WORKFLOW_STEPS_FAILED";

export const FETCH_WORKFLOW_STEPS_LIST_REQUEST = "FETCH_WORKFLOW_STEPS_LIST_REQUEST";
export const FETCH_WORKFLOW_STEPS_LIST_SUCCEEDED = "FETCH_WORKFLOW_STEPS_LIST_SUCCEEDED";
export const FETCH_WORKFLOW_STEPS_LIST_FAILED = "FETCH_WORKFLOW_STEPS_LIST_FAILED";

export const CREATE_WORKFLOW_STEPS_SEQUENSE = "CREATE_WORKFLOW_STEPS_SEQUENSE";
export const CHECK_SEQUENCE_FOR_ID = "CHECK_SEQUENCE_FOR_ID";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const REMOVE_STEP = "REMOVE_STEP";
//export const CHECK_SEQUENCE_FOR_ERRORS = "CHECK_SEQUENCE_FOR_ERRORS";
export const CHECK_SEQUENCE_FOR_ERRORS_REQUEST = "CHECK_SEQUENCE_FOR_ERRORS_REQUEST";
export const CHECK_SEQUENCE_FOR_ERRORS_SUCCESS = "CHECK_SEQUENCE_FOR_ERRORS_SUCCESS";
export const SEQUENCE_CLEAR_INITIATED = "SEQUENCE_CLEAR_INITIATED";
