import api, { API, formUrl } from "./api/api";

export const createImageUrl = (image) => {
  let baseUrl = import.meta.env.VITE_SERVER_URL;
  let apiUrl = API.salesDocument.getImage[1].replace("{id}", image.encryptedId);
  return baseUrl + "/" + apiUrl;
};

export const createDocumentUrl = (document) => {
  let baseUrl = import.meta.env.VITE_SERVER_URL;
  let apiUrl = API.salesDocument.downloadDocument[1].replace("{id}", document.encryptedId);
  return baseUrl + "/" + apiUrl;
};
