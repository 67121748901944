import React, { useState, useEffect } from "react";

//Styles
import styles from "./Exclusions.module.scss";

//Components
import ExclusionBox from "./ExclusionBox/ExclusionBox";
import { useSlice } from "@hooks/useSlice";
import useRouteParams from "@hooks/useRouteParams";
import { useGetExclusionCountQuery } from "@api/exclusionApi";

const Exclusions: React.FC = () => {
  const { campaignId } = useRouteParams();
  const { campaign } = useSlice((state) => state.campaign);
  const { responderExclusionsCrossCampaigns } = campaign;

  const [crossCampaign, setCrossCampaign] = useState<number>(responderExclusionsCrossCampaigns);

  const { data: exclusionCount } = useGetExclusionCountQuery(campaignId, {
    skip: !campaignId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setCrossCampaign(responderExclusionsCrossCampaigns);
  }, [responderExclusionsCrossCampaigns]);

  return (
    <div className={styles.bodyWraper}>
      <p className={styles.label}>
        Responder Exclusions cross other campaigns for: <span className={styles.span}>{crossCampaign} days</span>
      </p>
      <div className={styles.exclusionsWraper}>
        <ExclusionBox count={exclusionCount?.emailExclusion} type={"Email Exclusion"} owner={"(Client Exclusions)"} />
        <ExclusionBox count={exclusionCount?.domainExclusion} type={"Domain Exclusions"} owner={"(Client Exclusions)"} />
        <ExclusionBox count={exclusionCount?.responderExclusion} type={"Responder Exclusion"} owner={"(Campaign Exclusions)"} />
        <ExclusionBox count={exclusionCount?.unsubscribers} type={"Unsubscribers"} owner={"(Client Exclusions)"} />
        <ExclusionBox
          count={exclusionCount?.crossCampaignResponderExclusion}
          type={"Cross-campaign Responder Exclusions"}
          withoutLink={true}
        />
      </div>
    </div>
  );
};

export default Exclusions;
