import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Styles
import styles from "./AddCreditCardModalWarning.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const AddCreditCardModalWarning = (props) => {
  return (
    <Modal
      dialogClassName="customModal"
      title={props.title ? props.title : "Enter billing address"}
      handleClose={props.handleClose}
      handleSave={props.text ? props.handleClose : props.handleSave}
      show={props.showModal}
      closeButtonText={"Cancel"}
      saveButtonText={props.text ? "Ok" : "Yes"}
      btnType="submit"
      size="md"
      oneButton={props.text ? true : null}
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.wizzardContent}>
            <p>{props.text ? props.text : "Enter billing address first to add your credit card info."}</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default AddCreditCardModalWarning;
