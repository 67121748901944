import React from "react";

//Component
import ActiveDomains from "./Active/ActiveDomains";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const Domains = () => {
  return (
    <PageContentWrapper className="contentHeight">
      <ActiveDomains />
      {/* In case in future somebody ask for Inactive Domains - uncomment code below */}
      {/* <Accordions variant="white" header={"Inactive domains"} body={<InactiveDomains />} /> */}
    </PageContentWrapper>
  );
};

export default Domains;
