import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

//Redux
import { fetchUsers, removeUser } from "../../store/OrganizationSettings/Users/actions/users";

//Helpers
import api, { API } from "../../helper/api/api";
import { GLOBAL_PERMISSIONS } from "../../helper/globalUserRolePermissionsConstants";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { globalPermissionHelper } from "../../helper/globalPermissionHelper";
import { toastMessages } from "../../helper/toastMessagesConstants";

//Style
import styles from "./TeamManagement.module.scss";

//Images & Icons
import CircleSuccess from "../../assets/Icons/CircleSuccess/CircleSuccess";
import CircleWarning from "../../assets/Icons/CircleWarning/CircleWarning";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import UserAddLineIcon from "remixicon-react/UserAddLineIcon";
import EmptyAudiencesList from "../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";

// Components
import NewUserForm from "./NewUserForm";
import ReassignOwnerForm from "./ReassignOwnerForm";
import Button from "../../components/UI/Button/Button";
import ChangeOrgPhotoForm from "./ChangeOrgPhotoForm";
import Permission from "../../components/auth/Permission";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";
import ChangeUserRole from "./User/ChangeUserRole";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../components/UI/EmptyState/EmptyState";

const TeamManagement = (props) => {
  useEffect(() => {
    props.fetchUsers();
  }, []);

  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const handleNewUserClose = () => setShowNewUserModal(false);
  const handleNewUserShow = () => setShowNewUserModal(true);

  const [showChangePhotoModal, setShowChangePhotoModal] = useState(false);
  const handleChangePhotoClose = () => setShowChangePhotoModal(false);
  const handleChangePhotoShow = () => setShowChangePhotoModal(true);

  const [showReassignOwnerModal, setShowReassignOwnerModal] = useState(false);
  const handleReassignOwnerClose = () => setShowReassignOwnerModal(false);
  const handleReassignOwnerShow = () => setShowReassignOwnerModal(true);

  let users = null;
  let buttons = null;
  let emailAccounts = props.users.map((user) => user.email);
  let userOwner = props.users.find((user) => user.role === 1);

  const resendInviteHandler = (email) => {
    var userEmail = {
      email: email,
    };
    return api(API.users.resendInvite, userEmail)
      .then((response) => {
        toast.success(toastMessages.users.successResendInvite);
      })
      .catch((error) => {
        toast.error(toastMessages.users.errorResendInvite);
      });
  };

  const removeUserHandler = (userId, isInvite) => {
    var user = {
      id: userId,
      isInvite: isInvite,
      organizationId: props.organizationId,
    };

    props.removeUser(user);
  };

  if (props.error) {
    users = <h2>Error getting users</h2>;
  }

  if (props.isLoading) {
    users = <SkeletonTable />;
  }

  if (props.isLoaded) {
    if (props.users.length > 0) {
      users = (
        <div className={styles.tableWrap}>
          <table className={styles.organizationTable}>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th className={styles.thAction}></th>
              </tr>
            </thead>
            <tbody>
              {props.users.map((user, index) => {
                let isOwner = props.currentUserRole === 1;
                let isAdmin = props.currentUserRole === 2;
                let actionButtons = null;

                if (user.isInvite && user.role !== 1) {
                  actionButtons = (
                    <div className="flex_center mR15">
                      <Permission has={PERMISSIONS.organization.users.edit}>
                        <button className={styles.actionTableButton} onClick={(email) => resendInviteHandler(user.email)}>
                          Resend
                        </button>
                      </Permission>
                      <Permission has={PERMISSIONS.organization.users.delete}>
                        <Button
                          id={"deleteButton" + index}
                          variant="rounded-white-m"
                          classes="p0"
                          onClick={(userId, isInvite) => removeUserHandler(user.userId, user.isInvite)}
                        >
                          <DeleteBin6LineIcon size={14} className="iconGray" />
                        </Button>
                      </Permission>
                    </div>
                  );
                } else if (user.role !== 1) {
                  //isInvite = false
                  actionButtons = (
                    <Permission has={PERMISSIONS.organization.users.delete}>
                      <div className=" float-right mR15">
                        <Button
                          id="deleteButton"
                          variant="rounded-white-m"
                          classes="p0"
                          onClick={(userId, isInvite) => removeUserHandler(user.userId, user.isInvite)}
                        >
                          <DeleteBin6LineIcon size={14} className="iconGray" />
                        </Button>
                      </div>
                    </Permission>
                  );
                }
                if (
                  user.role === 1 &&
                  emailAccounts.length > 1 &&
                  globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll)
                ) {
                  actionButtons = (
                    <div className="flex_center mR15">
                      <button className={styles.actionTableButton} onClick={handleReassignOwnerShow}>
                        Reassign
                      </button>
                    </div>
                  );
                }

                return (
                  <tr key={index}>
                    <td>
                      <UserPhoto src={user.imageUrl} name={user.fullName} alt={user.fullName} size="medium" />
                    </td>
                    <td>{user.fullName}</td>
                    <td>{user.email}</td>
                    <td className={styles.roles}>
                      {!_.isEmpty(user.roleName) ? (
                        isOwner ? (
                          <Permission has={PERMISSIONS.organization.users.edit}>
                            {user.role === 1 ? <span>{user.roleName}</span> : <ChangeUserRole user={user} />}
                          </Permission>
                        ) : isAdmin ? (
                          <Permission has={PERMISSIONS.organization.users.edit}>
                            {user.role === 1 ? <span>{user.roleName}</span> : <ChangeUserRole user={user} />}
                          </Permission>
                        ) : (
                          <span>{user.roleName}</span>
                        )
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {!user.isInvite ? (
                        <span className="active flex align-center">
                          <CircleSuccess imgStyle="mR5" />
                          Active
                        </span>
                      ) : (
                        <span className="flex align-center">
                          {" "}
                          <CircleWarning imgStyle="mR5" />
                          Pending
                        </span>
                      )}
                    </td>
                    <td className={styles.actionButtons}>{actionButtons}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      users = <EmptyState componentClass="absolute transform-y-50 width_100 mT20" image={<EmptyAudiencesList />} title="No users" />;
    }
    buttons = (
      <>
        <Permission has={PERMISSIONS.organization.users.create} redirect>
          <p className={styles.upgradeLink}>
            Need more users? Remove existing users or
            <Link
              to={{
                pathname: "/" + props.orgId + "/pricing/",
              }}
              className="mL5 primary_link underline"
            >
              upgrade
            </Link>
            .
          </p>

          <Button
            id="addMember"
            variant="primary"
            onClick={handleNewUserShow}
            disabled={props.maxUsers > 0 && props.maxUsers - props.users.length <= 0 ? true : false}
          >
            <UserAddLineIcon size={14} className="mR5 iconWhite" />
            Add teammate
          </Button>
        </Permission>
      </>
    );
  }

  return (
    <>
      <HeaderTitle title="" rightContent={buttons} />
      {users}
      <Permission has={PERMISSIONS.organization.users.edit} redirect>
        <ReassignOwnerForm
          handleClose={handleReassignOwnerClose}
          showModal={showReassignOwnerModal}
          emailAccounts={emailAccounts}
          userOwner={userOwner}
          orgId={props.organizationId}
          fetchUsers={props.fetchUsers}
        />
      </Permission>
      <Permission has={PERMISSIONS.organization.users.create} redirect>
        <NewUserForm handleClose={handleNewUserClose} showModal={showNewUserModal} />
        <ChangeOrgPhotoForm handleClose={handleChangePhotoClose} show={showChangePhotoModal} />
      </Permission>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.organizationSettingsUsers.isLoading,
    isLoaded: state.organizationSettingsUsers.isLoaded,
    users: state.organizationSettingsUsers.users,
    userPermissions: state.user.userPermissions,
    error: state.organizationSettingsUsers.error,
    orgImageUrl: state.user.organization.imageUrl,
    currentUserRole: state.user.organization.role,
    organizationId: state.user.organization.id,
    maxUsers: state.user.organization.maxUsers,
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    removeUser: (user) => dispatch(removeUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManagement);
