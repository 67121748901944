import React from "react";

const IconPositiveResponse = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.45455 16L0 19.5V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V15C20 15.5523 19.5523 16 19 16H4.45455ZM3.76282 14H18V2H2V15.3851L3.76282 14ZM9.2929 9.1213L13.5355 4.87868L14.9497 6.29289L9.2929 11.9497L5.40381 8.0607L6.81802 6.64645L9.2929 9.1213Z"
      fill="#5C656F"
    />
  </svg>
);

export default IconPositiveResponse;
