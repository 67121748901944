export const columns = [
  {
    label: "Notification Name",
    field: "notificationName",
    searchable: true,
    type: "text",
    width: 180,
    minWidth: 180,
    sort: "asc",
  },
  {
    label: "Category",
    field: "category",
    searchable: true,
    type: "text",
    width: 180,
    minWidth: 180,
    sort: "asc",
  },
  {
    label: "Organization Name",
    field: "organizationName",
    searchable: true,
    type: "text",
    width: 180,
    minWidth: 180,
    sort: "asc",
  },
  {
    label: "Sent From",
    field: "sentFrom",
    type: "text",
    width: 100,
    minWidth: 100,
    sort: "asc",
  },
  {
    label: "Sent To",
    field: "sentTo",
    type: "date",
    width: 100,
    minWidth: 100,
    sort: "asc",
  },
  {
    label: "Log Date",
    field: "logDate",
    type: "date",
    width: 100,
    minWidth: 100,
    sort: "asc",
  },
  {
    label: "Trigger",
    field: "trigger",
    type: "text",
    width: 100,
    minWidth: 100,
    sort: "asc",
  },
  {
    label: "Message content",
    field: "messageContent",
    type: "actions",
    sort: "asc",
    minimal: "sm",
    width: 100,
    minWidth: 100,
  },
];
