import { api } from "./api";
import { DropDownLocationModel, DropdownModel } from "../models/DropDownModel";
import { DepartmentModel } from "@models/audienceExplorer/DepartmentModel";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import { ContactDetails } from "@models/audienceExplorer/ContactDetails";
import { TemplatesDataTableModel } from "@models/emailTemplate/TemplatesDataTableModel";
import { DTResult } from "@models/dataTables/DtResult";
import { DTParameters } from "@models/dataTables/DtParameters";
import { ExplorerContactDataTableModel } from "@models/audienceExplorer/ExplorerContactDataTableModel";
import { ExplorerCompanyDataTableModel } from "@models/audienceExplorer/ExplorerCompanyDataTableModel";
import { ExplorerCompanyDetails } from "@models/audienceExplorer/ExplorerCompanyDetails";

export const audienceExplorerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSearchUrl: builder.query<string, string>({
      query: (searchId) => `/audience-explorer/get-url-for-search?searchId=${searchId}`,
      providesTags: ["AudienceExplorerSearchUrl"],
    }),
    saveSearch: builder.mutation<string, SearchModel>({
      query: (search) => ({
        url: "/audience-explorer/save-search",
        method: "POST",
        body: search,
      }),
    }),
    getSearch: builder.query<SearchModel, string>({
      query: (searchId) => `/audience-explorer/get-search?searchId=${searchId}`,
      providesTags: ["AudienceExplorerSearch"],
    }),

    getContactDetails: builder.query<ContactDetails, number>({
      query: (experienceId) => `/audience-explorer/get-audience-explorer-contact?experienceId=${experienceId}`,
      providesTags: ["AudienceExplorerContactDetails"],
    }),

    getContactById: builder.query<ContactDetails, number>({
      query: (contactId) => `/audience-explorer/get-audience-contact-by-id?audienceDetailsId=${contactId}`,
      providesTags: ["AudienceExplorerContactDetails"],
    }),

    getCompanyDetails: builder.query<ExplorerCompanyDetails, number>({
      query: (companyId) => `/audience-explorer/get-company-by-id?companyId=${companyId}`,
      providesTags: ["AudienceExplorerCompanyDetails"],
    }),

    getContacts: builder.query<DTResult<ExplorerContactDataTableModel>, DTParameters>({
      query: (params) => ({
        url: "/audience-explorer/prospect-search-with-key",
        method: "POST",
        body: params,
      }),
      providesTags: ["AudienceExplorerContacts"],
      keepUnusedDataFor: 0,
    }),

    getCompanies: builder.query<DTResult<ExplorerCompanyDataTableModel>, DTParameters>({
      query: (params) => ({
        url: "/audience-explorer/company-search-with-key",
        method: "POST",
        body: params,
      }),
      providesTags: ["AudienceExplorerCompanies"],
      keepUnusedDataFor: 0,
    }),

    getIndustries: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-industries",
      providesTags: ["AudienceExplorerIndustries"],
    }),
    getCompanyLocationsAutoComplete: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-company-location-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerCompanyLocations"],
    }),
    getNaicsCodes: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-naics-codes",
      providesTags: ["AudienceExplorerNaicsCodes"],
    }),
    getSicCodes: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-sic-codes",
      providesTags: ["AudienceExplorerSicCodes"],
    }),
    getCompanySizes: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-company-sizes",
      providesTags: ["AudienceExplorerCompanySizes"],
    }),
    getCompanyNamesAutoComplete: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-company-name-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerCompanyNames"],
    }),
    getCompanyRevenueRanges: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-revenue-ranges",
      providesTags: ["AudienceExplorerCompanyRevenueRanges"],
    }),
    getTechnologies: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-technologies-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerCompanyTechnology"],
    }),
    getDomainsAutoComplete: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-domains-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerDomains"],
    }),
    getCompanyLocationsRangeAutoComplete: builder.query<DropDownLocationModel[], string>({
      query: (search) => `/audience-explorer/select-list-company-location-range-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerCompanyLocationsRange"],
    }),
    getProspectLocationsAutoComplete: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-standard-location-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerProspectLocations"],
    }),
    getProspectLocationsRangeAutoComplete: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-prospect-location-range-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerProspectLocationsRange"],
    }),
    getJobTitles: builder.query<DropdownModel[], string>({
      query: (search) => `/audience-explorer/select-list-job-titles-auto-complete?searchWord=${search}`,
      providesTags: ["AudienceExplorerJobTitles"],
    }),
    getJobTitleSeniorities: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-job-title-seniorities",
      providesTags: ["AudienceExplorerJobTitleSeniorities"],
    }),
    getJobTitleDepartments: builder.query<DepartmentModel[], void>({
      query: () => "/audience-explorer/select-list-job-title-departments",
      providesTags: ["AudienceExplorerJobTitleDepartments"],
    }),
    getCountries: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-countries",
      providesTags: ["AudienceExplorerCountries"],
    }),
    getFundingTypes: builder.query<DropdownModel[], void>({
      query: () => "/audience-explorer/select-list-funding-types",
      providesTags: ["AudienceExplorerFundingTypes"],
    }),
  }),
});

export const {
  useGetSearchUrlQuery,
  useGetSearchQuery,
  useSaveSearchMutation,
  useGetContactsQuery,
  useGetContactDetailsQuery,
  useGetContactByIdQuery,
  useGetCompaniesQuery,
  useGetCompanyDetailsQuery,

  useGetIndustriesQuery,
  useGetDomainsAutoCompleteQuery,
  useGetNaicsCodesQuery,
  useGetSicCodesQuery,
  useGetCompanySizesQuery,
  useGetCompanyNamesAutoCompleteQuery,
  useGetCompanyRevenueRangesQuery,
  useGetTechnologiesQuery,
  useGetCompanyLocationsAutoCompleteQuery,
  useGetCompanyLocationsRangeAutoCompleteQuery,
  useGetProspectLocationsAutoCompleteQuery,
  useGetProspectLocationsRangeAutoCompleteQuery,
  useGetJobTitlesQuery,
  useGetJobTitleSenioritiesQuery,
  useGetJobTitleDepartmentsQuery,
  useGetCountriesQuery,
  useGetFundingTypesQuery,
} = audienceExplorerApi;
