export const columns = [
  {
    label: "Email",
    field: "exclusion",
    sort: "asc",
    flex: 2,
    minimal: "lg",
    class: "textOverflow",
  },
  {
    label: "Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    flex: 1,
    minimal: "lg",
    width: 140,
    minWidth: 140,
    class: "textOverflow",
    showTooltip: true,
  },
  {
    label: "",
    field: "actions",
    sort: "false",
    flex: 1,
    maxWidth: 50,
    minimal: "sm",
  },
];
