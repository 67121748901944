import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const fetchOrganizationInvitesRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_INVITES_REQUEST,
  };
};

export const loadOrganizationInvitesFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_INVITES_FAILED,
    result: error,
  };
};

export const loadOrganizationInvitesSuccess = (invites) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_INVITES_SUCCESS,
    result: invites,
  };
};

export const fetchOrganizationInvites = () => {
  return (dispatch) => {
    dispatch(fetchOrganizationInvitesRequest());
    return api(API.users.invites)
      .then((response) => {
        dispatch(loadOrganizationInvitesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadOrganizationInvitesFailed(error.message));
      });
  };
};

export const acceptInviteRequest = () => {
  return {
    type: actionTypes.ACCEPT_INVITE_REQUEST,
  };
};

export const acceptInviteFailed = (error) => {
  return {
    type: actionTypes.ACCEPT_INVITE_FAILED,
    result: error,
  };
};

export const acceptInvite = (orgId) => {
  return (dispatch) => {
    dispatch(acceptInviteRequest());
    return api(formUrl(API.users.acceptInvite, { Id: orgId }))
      .then((response) => {
        dispatch(loadOrganizationInvitesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(acceptInviteFailed(error.message));
      });
  };
};

export const declineInviteRequest = () => {
  return {
    type: actionTypes.DECLINE_INVITE_REQUEST,
  };
};

export const declineInviteFailed = (error) => {
  return {
    type: actionTypes.DECLINE_INVITE_FAILED,
    result: error,
  };
};

export const declineInvite = (inviteId) => {
  return (dispatch) => {
    dispatch(declineInviteRequest());
    return api(formUrl(API.users.declineInvite, { Id: inviteId }))
      .then((response) => {
        dispatch(fetchOrganizationInvites());
      })
      .catch((error) => {
        dispatch(declineInviteFailed(error.message));
      });
  };
};
