import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { columns } from "./columns";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Redux
import {
  fetchUsers,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../store/Outboss/Users/Table/actions/users";

//Helpers
import api, { API, formUrl } from "../../../helper/api/api";
import { filtersToQueryString, filterData } from "../../../helper/tableConstants";
import { useQueryTableFilters } from "../../../helper/hooks/useQueryTableFilters";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { outbossUserTypeEnum } from "../../../helper/enums/outbossUserTypeEnum";

//Style
import styles from "./Users.module.scss";

//Images & Icons
import PhoneLockLineIcon from "remixicon-react/PhoneLockLineIcon";
import SmartphoneLineIcon from "remixicon-react/SmartphoneLineIcon";
import MailSendLineIcon from "remixicon-react/MailSendLineIcon";

//Components
import ActionImpersonate from "../../../components/UI/ActionWrapper/Actions/ActionImpersonate";
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import Table from "../../../components/DataTable/Table";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import DisableEnableUserAccessForm from "./DisableEnableUserAccessForm";
import TableOptionsRow from "../../../components/DataTable/TableOptionsRow/TableOptionsRow";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import ActionEdit from "../../../components/UI/ActionWrapper/Actions/ActionEdit";
import EditUserInfoModal from "./modals/EditUserInfoModal";
import EmailConfirmationModal from "./modals/EmailConfirmationModal";
import ActionCheck from "../../../components/UI/ActionWrapper/Actions/ActionCheck";
import UserEmailConfirmationModal from "./modals/UserEmailConfirmationModal";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";

const Users = (props) => {
  const { param, rows, fetchUsers, isInitiated, initiate, clearInitiated } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  let filterTypes = ["Campaign", "Type", "Status", "hideInternal"];
  let state = useQueryTableFilters(filterTypes);

  const [tableRows, setTableRows] = useState([]);
  const [hideInternalUsers, setHideInternalUsers] = useState(true);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [userId, setUserId] = useState(0);
  const [userEmail, setUserEmail] = useState(null);
  const [userAccessRemoved, setUserAccessRemoved] = useState("");
  const [showDisableUserAccessModal, setShowDisableUserAccessModal] = useState(false);
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] = useState(false);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [showUserConfirmationModal, setShowUserConfirmationModal] = useState(false);

  let minDate = moment.utc("0001-01-01").format(dateFormat);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  let chosenColumns = dataTableColumns.map((column) => column.label);

  useEffect(() => {
    initiate(state);
  }, []);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
    };
    initiate(updateState);
  };

  useEffect(() => {
    getUserById();
  }, [userId]);

  const hideInternalUsersHandler = (checked) => {
    setHideInternalUsers(checked);
  };

  const handleEditUserModalOpen = (userId) => {
    setUserId(userId);
    setShowEditUserModal(true);
  };

  const handleEditUserModalClose = () => {
    setUserId(0);
    setShowEditUserModal(false);
  };

  const getUserById = () => {
    let data = {
      userId: userId,
    };
    api(formUrl(API.OUTBOSS.users.getUserDetails, data))
      .then((response) => {
        setCurrentUser(response.data);
        setExportButtonDisabled(false);
      })
      .catch((error) => console.log(error));
  };

  const exportAllOutbaseUsers = () => {
    let chColumns = dataTableColumns.map((column) => column.enumId);
    let data = {
      chosenColumns: chColumns,
      param: param,
    };
    api(API.OUTBOSS.users.exportAllOutbaseUsers, data, "blob")
      .then((res) => {
        fileDownload("_allOutbaseUsers.csv", res.data);
        setExportButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (isInitiated) {
      fetchUsers(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    filterData(filterTypes[3], hideInternalUsers, props.setFilter);
  }, [hideInternalUsers]);

  const handleDisableUserAccessClose = () => {
    setUserId(0);
    setUserAccessRemoved("");
    setShowDisableUserAccessModal(false);
  };

  const handleDisableUserAccessShow = (userId, userAccessRemoved) => {
    setUserId(userId);
    setUserAccessRemoved(userAccessRemoved);
    setShowDisableUserAccessModal(true);
  };

  const handleResendVerificationEmail = (userId, email) => {
    setUserId(userId);
    setUserEmail(email);
    setShowEmailConfirmationModal(true);
  };

  const handleResendVerificationEmailClose = () => {
    setUserId(0);
    setUserEmail(null);
    setShowEmailConfirmationModal(false);
  };

  const handleResendVerificationEmailSave = () => {
    const data = { userId: userId };
    setUserId(0);
    setUserEmail(null);
    setShowEmailConfirmationModal(false);

    api(formUrl(API.OUTBOSS.users.resendVerificationEmails, data))
      .then((response) => {
        toast.success("Success");
      })
      .catch((error) => toast.success("Error"));
  };

  const handleConfirmUserEmailOpen = (userId, email) => {
    setUserId(userId);
    setUserEmail(email);
    setShowUserConfirmationModal(true);
  };

  const handleConfirmUserEmailClose = () => {
    setUserId(0);
    setUserEmail(null);
    setShowUserConfirmationModal(false);
  };

  const handleConfirmUserEmail = () => {
    let data = {
      userId: userId,
    };
    api(API.OUTBOSS.users.confirmUserEmail, data)
      .then((response) => {
        toast.success("User confirmed");
        handleConfirmUserEmailClose();
        fetchUsers(param);
      })
      .catch((error) => toast.error(error));
    handleConfirmUserEmailClose();
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r, i) => {
      let actions = (
        <ActionWrapper>
          <div className={styles.iconsWrapper}>
            {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.impersonate.canImpersonate) &&
            r.id !== props.user.id ? (
              <ActionImpersonate key={"impersonate" + i} userId={r.id} history={props.history} />
            ) : null}
            {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.removeAccess.canRemoveAccess) ? (
              <OverlayTrigger
                key={"disable-enable-access"}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-disable-enable-access`}>
                    <Popover.Body>
                      <span>{r.userAccessRemoved ? "Enable access to Outbase" : "Disable access to Outbase"}</span>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  className={r.userAccessRemoved ? styles.enableAccess : styles.disableAccess}
                  onClick={() => handleDisableUserAccessShow(r.id, r.userAccessRemoved)}
                >
                  {r.userAccessRemoved ? (
                    <SmartphoneLineIcon size={16} className="iconGray" />
                  ) : (
                    <PhoneLockLineIcon size={16} className="iconGray" />
                  )}
                </div>
              </OverlayTrigger>
            ) : null}
            {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.removeAccess.canRemoveAccess) ? (
              <OverlayTrigger
                key={"edit-user"}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-edit-user`}>
                    <Popover.Body>
                      <span>{"Edit user"}</span>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className={styles.iconsWrapper}>
                  <ActionEdit size={16} onClick={() => handleEditUserModalOpen(r.id)} />
                </div>
              </OverlayTrigger>
            ) : null}

            {r.emailConfirmed === false && (
              <OverlayTrigger
                key={"resend-verification-email"}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-resend-verification-email`}>
                    <Popover.Body>
                      <span>Resend verification email</span>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div onClick={() => handleResendVerificationEmail(r.id, r.email)}>
                  <MailSendLineIcon size={16} className="iconGray" />
                </div>
              </OverlayTrigger>
            )}
            {r.emailConfirmed === false && globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.users.edit) ? (
              <OverlayTrigger
                key={"confirm-email"}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-confirm-user-email`}>
                    <Popover.Body>
                      <span>Verify account</span>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className={styles.checkIkonWrapper}>
                  <ActionCheck
                    size={16}
                    className="iconGray"
                    style={{ justifyContent: "flex-end" }}
                    onClick={() => handleConfirmUserEmailOpen(r.id, r.email)}
                  />
                </div>
              </OverlayTrigger>
            ) : null}
          </div>
        </ActionWrapper>
      );
      r.actions = actions;

      if (r.registrationDate && r.registrationDate !== null) {
        let regDateFormatted = moment(r.registrationDate).format(dateFormat);
        if (regDateFormatted > minDate) {
          r.registrationDate = regDateFormatted;
        } else {
          r.registrationDate = "NA";
        }
      } else {
        r.registrationDate = "NA";
      }

      if (r.lastLogin && r.lastLogin !== null) {
        let lastDateFormatted = moment(r.lastLogin).format(dateFormat);
        if (lastDateFormatted > minDate) {
          r.lastLogin = lastDateFormatted;
        } else {
          r.lastLogin = "NA";
        }
      } else {
        r.lastLogin = "NA";
      }

      r.fullName = (
        <Link className={styles.link} to={{ pathname: "/outboss/user-details/" + r.id }}>
          {r.fullName}
        </Link>
      );
      r.email = (
        <Link className={styles.link} to={{ pathname: "/outboss/user-details/" + r.id }}>
          {r.email}
        </Link>
      );
      r.numberOfOrganizations = (
        <Link className={styles.link} to={{ pathname: "/outboss/user-details/" + r.id }}>
          {r.numberOfOrganizations}
        </Link>
      );

      if (r.emailConfirmed === true) {
        r.emailConfirmed = "YES";
      } else {
        r.emailConfirmed = "NO";
      }

      if (r.userType === outbossUserTypeEnum.Internal) {
        r.userType = "Internal";
      } else if (r.userType === outbossUserTypeEnum.External) {
        r.userType = "External";
      } else {
        r.userType = "NA";
      }

      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>{props.error}</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  let tableFiltersRow = null;
  let tableOptionsRow = null;

  if (props.isLoaded) {
    table = (
      <Table
        outBoss
        tableName={"Users"}
        columns={dataTableColumns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        variant="extraLarge"
        tableHeight={250}
        filterData={filterData}
        setFilter={props.setFilter}
        tableFooterClass="datePickerTable"
      />
    );

    tableFiltersRow = (
      <div className={styles.hideInternalUsers}>
        <TableFiltersRow
          searchValue={param.search}
          placeholder="user"
          changeFilter={(text) => props.searchRecords(text)}
          searchOnFirstCharacter={true}
        />
        <Checkbox
          onChange={(checked) => hideInternalUsersHandler(checked)}
          checked={hideInternalUsers}
          label={"Hide internal users"}
          checkBoxClass="mB10"
        />
      </div>
    );

    tableOptionsRow = (
      <TableOptionsRow
        applyChanges={applyDataTableColumnsChange}
        exportTable={exportAllOutbaseUsers}
        exportButtonDisabled={exportButtonDisabled}
        setExportButtonDisabled={setExportButtonDisabled}
        importData={null}
        dataTableColumns={dataTableColumns}
        setDataTableColumns={setDataTableColumns}
        allDataTableColumns={columns}
      />
    );
  }

  return (
    <PageContentWrapper className={styles.contentWrapper}>
      <HeaderTitle title="Users" />
      {tableFiltersRow}
      {tableOptionsRow}
      {table}
      <DisableEnableUserAccessForm
        handleClose={handleDisableUserAccessClose}
        showModal={showDisableUserAccessModal}
        userId={userId}
        userAccessRemoved={userAccessRemoved}
        fetchUsers={() => fetchUsers(param)}
      />
      <EditUserInfoModal
        handleClose={handleEditUserModalClose}
        showModal={showEditUserModal}
        user={currentUser}
        userId={userId}
        fetchUsers={() => fetchUsers(param)}
      />
      <EmailConfirmationModal
        handleClose={handleResendVerificationEmailClose}
        handleSave={handleResendVerificationEmailSave}
        showModal={showEmailConfirmationModal}
        userId={userId}
        userEmail={userEmail}
      />
      <UserEmailConfirmationModal
        handleClose={handleConfirmUserEmailClose}
        handleSave={handleConfirmUserEmail}
        showModal={showUserConfirmationModal}
        userEmail={userEmail}
      />
    </PageContentWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    param: state.outbossUsers.params,
    isInitiated: state.outbossUsers.isInitiated,
    isLoading: state.outbossUsers.isLoading,
    isLoaded: state.outbossUsers.isLoaded,
    error: state.outbossUsers.error,
    rows: state.outbossUsers.rows,
    totalCount: state.outbossUsers.totalCount,
    pageCount: state.outbossUsers.pageCount,
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (param) => dispatch(fetchUsers(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
