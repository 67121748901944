import moment from "moment";

export const dateFormatOptions = [
  {
    value: "DD MMM YYYY",
    label: `${moment(new Date()).format("DD MMM YYYY")} ('DD MMM YYYY')`,
  },
  {
    value: "DD MMMM YYYY",
    label: `${moment(new Date()).format("DD MMMM YYYY")} ('DD MMMM YYYY')`,
  },
  {
    value: "MMM. DD, YYYY",
    label: `${moment(new Date()).format("MMM. DD, YYYY")} ('MMM. DD, YYYY')`,
  },
  {
    value: "DD-MMM-YYYY",
    label: `${moment(new Date()).format("DD-MMM-YYYY")} ('DD-MMM-YYYY')`,
  },
  {
    value: "MMMM DD, YYYY",
    label: `${moment(new Date()).format("MMMM DD, YYYY")} ('MMMM DD, YYYY')`,
  },
];
