import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

//Redux
import { fetchInitialEmailTemplates } from "../../../store/EmailTemplates/Global/actions/emailTemplates";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import { toastMessages } from "../../../helper/toastMessagesConstants";
import api, { API, formUrl } from "../../../helper/api/api";
import { aiWriterStepsEnum } from "../../../helper/enums/aiWriterStepsEnum";
import { emailTemplateTypeEnum } from "../../../helper/enums/emailTemplateTypeEnum";

//Icons
import RestartLineIcon from "remixicon-react/RestartLineIcon";

//Styles
import styles from "./AIEmailGenerator.module.scss";

//Components
import FormikEffect from "../../../components/UI/Formik/FormikEffect";
import Button from "../../../components/UI/Button/Button";
import InitialEmailType from "./InitialEmailType/InitialEmailType";
import ResetAIWriterForm from "./ResetAIWriterForm/ResetAIWriterForm";
import ChatBox from "./ChatBox/ChatBox";
import FollowUpEmailType from "./FollowUpEmailType/FollowUpEmailType";

const AIEmailGenerator = (props) => {
  const {
    emailTemplateAIRequest,
    emailTemplateType,
    tinyMCE,
    activeStep,
    setActiveStep,
    fetchInitialEmailTemplates,
    initialEmailTemplates,
  } = props;

  const { hasWebInfoLogs } = emailTemplateAIRequest;
  const [generatedField, setGeneratedFields] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [templateText, setTemplateText] = useState("");
  const [subjectText, setSubjectText] = useState("");
  const [showDeleteStepModal, setShowDeleteStepModal] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
  const [editRequirements, setEditRequirements] = useState(false);
  const [waitingAIResponse, setWaitingAIResponse] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [waitingAIReset, setWaitingAIReset] = useState(false);
  const [initialEmailTemplatesOptions, setInitialEmailTemplatesOptions] = useState([]);
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [allWebsiteInfoLogs, setAllWebsiteInfoLogs] = useState([]);
  const [showDropdownWebsiteLogs, setShowDropdownWebsiteLogs] = useState(false);
  const [showPreviousSearchesButton, setShowPreviousSearchesButton] = useState(false);
  const [websiteInfoLogId, setWebsiteInfoLogId] = useState(0);
  const handleDeleteStepClose = () => setShowDeleteStepModal(false);
  const handleShowDeleteStepForm = () => setShowDeleteStepModal(true);
  const formikRef = useRef();

  useEffect(() => {
    setShowPreviousSearchesButton(hasWebInfoLogs);
  }, [hasWebInfoLogs]);

  useEffect(() => {
    if (activeStep == aiWriterStepsEnum.FollowUpStartingStep && !props.reopenAIPromptOnChatBox && !props.smartEdit) {
      if (parseInt(props.emailTemplateType) === emailTemplateTypeEnum.FollowUp) {
        fetchInitialEmailTemplates();
      }
    }
  }, [props.chaserCheckboxesShow, activeStep]);

  useEffect(() => {
    if (!props.reopenAIPromptOnChatBox && !props.smartEdit) {
      if (props.chaserCheckboxesShow) {
        if (initialEmailTemplates) {
          let initialTemplateOptions = initialEmailTemplates.map((template) => {
            return { label: template.name, value: template.id };
          });
          setInitialEmailTemplatesOptions([...initialTemplateOptions]);
        }
      }
    }
  }, [initialEmailTemplates]);

  useEffect(() => {
    if (props.smartEdit && !props.reopenAIPromptOnChatBox) {
      setActiveStep(aiWriterStepsEnum.ChatBox);
      setShowChatBox(true);
    }
  }, [props.smartEdit]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleGenerate = () => {
    setWaitingAIResponse(false);
    setActiveStep(aiWriterStepsEnum.ChatBox);
    setShowChatBox(true);
  };
  const [formCount, setFormCount] = useState(0);
  const [showSecondUrl, setShowSecondUrl] = useState(false);

  const handleAddForm = () => {
    setShowSecondUrl(true);
    setFormCount(formCount + 1);
  };
  const handleEdit = () => {
    if (!props.chaserCheckboxesShow) {
      setActiveStep(aiWriterStepsEnum.StartingStep);
      if (props.secondUrl !== "") {
        handleAddForm();
      }
      if (websiteInfoLogId > 0) {
        setShowPreviousSearchesButton(true);
        setShowDropdownWebsiteLogs(false);
      }
    } else {
      setActiveStep(aiWriterStepsEnum.FollowUpStartingStep);
    }
    setShowChatBox(false);
    setEditRequirements(true);
    props.setReopenAIPromptOnChatBox(false);
  };

  useEffect(() => {
    if (props.reopenAIPromptOnChatBox) {
      setActiveStep(aiWriterStepsEnum.ChatBox);
      setShowChatBox(true);
    }
  }, [props.allAIRequestLogs]);

  const [hasChanges, setHasChanges] = useState(false);

  var companySizeArray = [];

  const onChange = (values) => {
    setHasChanges(true);
    if (activeStep === aiWriterStepsEnum.StartingStep) {
      if (values.websiteUrl === "") {
        setIsSearchDisabled(true);
        if (!editRequirements) {
          setWebsiteInfoLogId(0);
        }
      } else {
        setIsSearchDisabled(false);
      }
      if (
        values.companyName !== "" ||
        values.websiteUrl !== "" ||
        values.description !== "" ||
        values.USP !== "" ||
        values.painPoint !== ""
      ) {
        props.setIsNextDisabled(true);
      }
      if (
        values.companyName !== "" &&
        values.websiteUrl !== "" &&
        values.description !== "" &&
        values.USP !== "" &&
        values.painPoint !== ""
      ) {
        props.setIsNextDisabled(false);
      }
    }

    if (activeStep === aiWriterStepsEnum.TemplateSettingsStep) {
      if (values.language !== "" && values.companySize !== "" && values.templateGoal !== "" && values.templateTone !== "") {
        setIsGenerateDisabled(false);
      } else {
        setIsGenerateDisabled(true);
      }
    }
    if (activeStep === aiWriterStepsEnum.FollowUpStartingStep) {
      if (values.initialEmailTemplateId !== 0 && values.followUpType !== 0 && values.templateGoal !== "" && values.templateTone !== "") {
        setIsGenerateDisabled(false);
      } else {
        setIsGenerateDisabled(true);
      }
    }
    updateRequestStates(values);
  };

  const updateRequestStates = (values) => {
    let emailTemplateAIRequestTemp = {
      companyName: values.companyName,
      companySizeTargeting: values.companySize,
      templateSettings: values.language,
      templateGoal: values.templateGoal,
      tone: values.templateTone,
      initialEmailId: values.initialEmailTemplateId,
      followUpType: values.followUpType,
      hasWebInfoLogs: values.hasWebInfoLogs,
    };
    props.setInitialPromptInfoResult(emailTemplateAIRequestTemp);
    props.setDescriptionValue(values.description);
    props.setUspValue(values.USP);
    props.setPainPointValue(values.painPoint);
    props.setFirstUrl(values.websiteUrl);
    props.setSecondUrl(values.additionalWebsiteUrl);
    setShowPreviousSearchesButton(values.hasWebInfoLogs);
  };

  const checkWebsiteInfoSummary = (company) => {
    let urlTemp = [];
    urlTemp.push(props.firstUrl);
    if (props.secondUrl !== "") {
      urlTemp.push(props.secondUrl);
    }
    let data = {
      websiteUrls: urlTemp,
      emailTemplateRequestId: props.templateRequestId,
      companyName: company,
    };
    api(API.emailTemplates.checkWebsiteInfoSummary, data)
      .then((res) => {
        setGeneratedFields(true);
        props.setDescriptionValue(res.data.description);
        props.setUspValue(res.data.usp);
        props.setPainPointValue(res.data.painPoint);
        if (company !== "") {
          props.setIsNextDisabled(false);
        }
        if (res.data.success) {
          setShowErrorMessage(false);
        } else {
          setShowErrorMessage(true);
          setErrorMessageText(res.data.errorMessage);
          props.setIsNextDisabled(true);
        }
      })
      .catch((error) => {
        setGeneratedFields(true);
        setShowErrorMessage(false);
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const getAllWebsiteInfoLogs = () => {
    api(API.emailTemplates.getAllWebsiteInfoLogs)
      .then((res) => {
        if (res.data.length > 0) {
          setShowDropdownWebsiteLogs(true);
        }
        let allWebsiteLogs = res.data.map((websiteLog) => {
          return { label: websiteLog.companyName, value: websiteLog };
        });
        setAllWebsiteInfoLogs([...allWebsiteLogs]);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const resetAIPrompt = () => {
    setWaitingAIReset(true);
    let data = {
      emailTemplateRequestId: props.templateRequestId,
    };
    api(API.emailTemplates.resetAIPrompt, data)
      .then((res) => {
        props.setInitialPromptInfoResult(res.data);
        props.setFirstUrl(res.data.website);
        props.setDescriptionValue(res.data.description);
        props.setUspValue(res.data.usp);
        props.setPainPointValue(res.data.painPoint);
        props.setSecondUrl(res.data.secondWebsite);
        if (!props.chaserCheckboxesShow) {
          setActiveStep(aiWriterStepsEnum.StartingStep);
          setShowDropdownWebsiteLogs(false);
          setWebsiteInfoLogId(0);
        } else {
          formikRef.current.setFieldValue("initialEmailTemplateId", 0);
          formikRef.current.setFieldValue("followUpType", 0);
          formikRef.current.setFieldValue("templateGoal", "");
          formikRef.current.setFieldValue("templateTone", "");
          setActiveStep(aiWriterStepsEnum.FollowUpStartingStep);
        }

        handleDeleteStepClose();
        setGeneratedFields(true);
        setShowErrorMessage(false);
        setIsReset(true);
        setIsGenerateDisabled(true);
        setWaitingAIReset(false);
        props.setReopenAIPromptOnChatBox(false);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const tweakTemplate = (request, text, subject) => {
    let templateId = props.emailTemplateId;
    if (props.hasChangesFromCampaignSequence) {
      templateId = props.templateIdFromSequence;
    }
    let data = {
      emailTemplateRequestId: props.templateRequestId,
      emailTemplate: text,
      emailTemplateSubject: subject,
      request: request,
      temperature: 0,
      tweakSettingsId: 1,
      smartEdit: props.smartEdit,
      templateId: templateId,
      smartEditEnabled: props.smartEditEnabled,
    };
    api(API.emailTemplates.tweakTemplate, data)
      .then((res) => {
        setSubjectText(res.data.subject);
        setTemplateText(res.data.template);
        props.setSmartEditEnabled(false);
        props.setAiWriterEnabled(true);
        props.setDisableAiWriterButton(true);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const changeWebsiteLogHandler = (log) => {
    let newValues = {
      companyName: log.companyName,
      websiteUrl: log.website,
      description: log.description,
      USP: log.usp,
      painPoint: log.painPoint,
      companySize: [],
      language: "",
      templateGoal: "",
      templateTone: "",
      additionalWebsiteUrl: "",
      initialEmailTemplateId: 0,
      followUpType: 0,
      hasWebInfoLogs: true,
    };
    setWebsiteInfoLogId(log.websiteInfoLogId);
    updateRequestStates(newValues);
    setActiveStep(aiWriterStepsEnum.StartingStep);
    setShowDropdownWebsiteLogs(false);
    setShowPreviousSearchesButton(true);
    props.setIsNextDisabled(false);
    setIsSearchDisabled(false);
  };

  return (
    <div className={`width_100 ${styles.AIWriterWrapper}`}>
      <div className={styles.headerTitle}>
        <h1 className="f16 font700 m0">AI Writer</h1>
        <div className="flex">
          {props.emailTemplateId === 0 && (
            <Button id="resetAiGenerator" onClick={handleShowDeleteStepForm} classes={styles.linkButton}>
              <RestartLineIcon size={16} className="mR5" />
              Reset
            </Button>
          )}
          {props.emailTemplateId === 0 && activeStep === aiWriterStepsEnum.ChatBox && showChatBox && (
            <Button id="editRequirements" onClick={handleEdit} variant="secondary" classes="m0">
              Edit requirements
            </Button>
          )}
        </div>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          companyName: emailTemplateAIRequest.companyName,
          websiteUrl: props.firstUrl,
          description: props.descriptionValue,
          USP: props.uspValue,
          painPoint: props.painPointValue,
          companySize: emailTemplateAIRequest.companySizeTargeting,
          language: emailTemplateAIRequest.templateSettings,
          templateGoal: emailTemplateAIRequest.templateGoal,
          templateTone: emailTemplateAIRequest.tone,
          additionalWebsiteUrl: props.secondUrl,
          initialEmailTemplateId: emailTemplateAIRequest.initialEmailId,
          followUpType: emailTemplateAIRequest.followUpType,
          hasWebInfoLogs: emailTemplateAIRequest.hasWebInfoLogs,
        }}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          if (activeStep === aiWriterStepsEnum.StartingStep) {
            actions.setSubmitting(false);
            return;
          } else if (activeStep === aiWriterStepsEnum.TemplateSettingsStep) {
            actions.setSubmitting(true);
            setWaitingAIResponse(true);
            setIsGenerateDisabled(true);
            let templateRequestData = { ...values };
            companySizeArray.push(templateRequestData.companySize);

            var data = {
              id: props.templateRequestId,
              senderName: props.userName,
              companyName: templateRequestData.companyName,
              website: templateRequestData.websiteUrl,
              secondWebsite: templateRequestData.additionalWebsiteUrl ? templateRequestData.additionalWebsiteUrl !== undefined : "",
              description: templateRequestData.description,
              usp: templateRequestData.USP,
              painPoint: templateRequestData.painPoint,
              companySizeTargeting: companySizeArray,
              templateSettings: templateRequestData.language,
              templateGoal: templateRequestData.templateGoal,
              tone: templateRequestData.templateTone,
              emailTemplateType: emailTemplateType,
              templateId: props.emailTemplateId,
              additionalInstructions: "",
              editRequirements: editRequirements,
              isReset: isReset,
              WebsiteInfoLogId: websiteInfoLogId,
            };

            api(API.emailTemplates.generateAITemplate, data)
              .then((res) => {
                if (res.data.success) {
                  setSubjectText(res.data.subject);
                  setTemplateText(res.data.template);
                  setWebsiteInfoLogId(res.data.websiteInfoLogId);
                  setEditRequirements(false);
                  setIsReset(false);
                  handleGenerate();
                }
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);
                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                } else {
                  toast.error(toastMessages.emailTemplate.aiWriterErrorMessage);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else if (activeStep === aiWriterStepsEnum.FollowUpStartingStep) {
            actions.setSubmitting(true);
            setWaitingAIResponse(true);
            setIsGenerateDisabled(true);
            let templateRequestData = { ...values };
            var data = {
              id: props.templateRequestId,
              followUpSettingId: templateRequestData.followUpType,
              initialTemplateId: templateRequestData.initialEmailTemplateId,
              templateGoal: templateRequestData.templateGoal,
              tone: templateRequestData.templateTone,
              temperature: 1,
              editRequirements: editRequirements,
              isReset: isReset,
            };

            api(API.emailTemplates.generateAIFollowUpTemplate, data)
              .then((res) => {
                setSubjectText("FW: {{INITIAL_EMAIL_SUBJECT}}");
                setTemplateText(res.data.template);
                setEditRequirements(false);
                setIsReset(false);
                handleGenerate();
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);
                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                } else {
                  toast.error(toastMessages.emailTemplate.aiWriterErrorMessage);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, isSubmitting, setFieldValue, setSubmitting } = formikProps;
          return (
            <Form>
              <FormikEffect onChange={() => onChange(values)} />
              {activeStep === aiWriterStepsEnum.StartingStep && (
                <InitialEmailType
                  stepperContentClass={props.stepperContentClass}
                  checkWebsiteInfoSummary={checkWebsiteInfoSummary}
                  additionalWebsiteUrl="additionalWebsiteUrl"
                  websiteValue={values.websiteUrl}
                  companyNameValue={values.companyName}
                  valueDescription={values.description}
                  valueAbout={values.USP}
                  valuePainPoint={values.painPoint}
                  additionalWebsiteUrlValue={values.additionalWebsiteUrl}
                  setSecondUrl={props.setSecondUrl}
                  stepsWrapperClass={props.stepsWrapperClass}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  setSubmitting={setSubmitting}
                  generatedField={generatedField}
                  setGeneratedFields={setGeneratedFields}
                  showErrorMessage={showErrorMessage}
                  errorMessageText={errorMessageText}
                  handleAddForm={handleAddForm}
                  formCount={formCount}
                  setFormCount={setFormCount}
                  showSecondUrl={showSecondUrl}
                  setShowSecondUrl={setShowSecondUrl}
                  isSearchDisabled={isSearchDisabled}
                  setIsNextDisabled={props.setIsNextDisabled}
                  hasWebInfoLogs={showPreviousSearchesButton}
                  getAllWebsiteInfoLogs={getAllWebsiteInfoLogs}
                  showDropdownWebsiteLogs={showDropdownWebsiteLogs}
                  allWebsiteInfoLogs={allWebsiteInfoLogs}
                  changeWebsiteLogHandler={changeWebsiteLogHandler}
                />
              )}
              {activeStep === aiWriterStepsEnum.TemplateSettingsStep && (
                <InitialEmailType
                  stepperContentClass={props.stepperContentClass}
                  companySizeValue={values.companySize}
                  languageValue={values.language}
                  templateGoalValue={values.templateGoal}
                  templateToneValue={values.templateTone}
                  additionalWebsiteUrlValue={values.additionalWebsiteUrl}
                  stepsWrapperClass={props.stepsWrapperClass}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  waitingAIResponse={waitingAIResponse}
                  setWaitingAIResponse={setWaitingAIResponse}
                />
              )}
              {activeStep === aiWriterStepsEnum.FollowUpStartingStep && (
                <FollowUpEmailType
                  stepperContentClass={props.stepperContentClass}
                  formEmailWrapperClass={props.formEmailWrapperClass}
                  waitingAIResponse={waitingAIResponse}
                  initialEmailTemplatesOptions={initialEmailTemplatesOptions}
                  initialEmailTemplateId={values.initialEmailTemplateId}
                  followUpSettingId={values.followUpType}
                  templateGoalValue={values.templateGoal}
                  templateToneValue={values.templateTone}
                  setWaitingAIResponse={setWaitingAIResponse}
                  waitingAIReset={waitingAIReset}
                />
              )}
              {activeStep === aiWriterStepsEnum.ChatBox && showChatBox && (
                <ChatBox
                  chatBoxClass={props.chatBoxClass}
                  templateText={templateText}
                  setTemplateText={setTemplateText}
                  subjectText={subjectText}
                  setSubjectText={setSubjectText}
                  tinyMCE={tinyMCE}
                  tweakTemplate={tweakTemplate}
                  allAIRequestLogs={props.allAIRequestLogs}
                  reopenAIPromptOnChatBox={props.reopenAIPromptOnChatBox}
                  setReopenAIPromptOnChatBox={props.setReopenAIPromptOnChatBox}
                  formikProps={props.formikProps}
                  setSaveAsNew={props.setSaveAsNew}
                  setNewTextTemplate={props.setNewTextTemplate}
                  setNewSubjectTemplate={props.setNewSubjectTemplate}
                  orgId={props.orgId}
                  setEmailTemplateSubjectBackup={props.setEmailTemplateSubjectBackup}
                  hasChangesFromCampaignSequence={props.hasChangesFromCampaignSequence}
                  smartEdit={props.smartEdit}
                  messageContainerClass={`${styles.chatBoxWrapper} ${
                    props.createUpdateTemplateHasChanges
                      ? styles.footerVisible
                      : props.hasChangesFromCampaignSequence
                      ? styles.footerVisibleFromSequence
                      : "removeClass"
                  }`}
                />
              )}

              {(activeStep === aiWriterStepsEnum.StartingStep ||
                activeStep === aiWriterStepsEnum.TemplateSettingsStep ||
                activeStep === aiWriterStepsEnum.FollowUpStartingStep) && (
                <div className={`${styles.buttonFooter} d-flex justify_end`}>
                  {activeStep !== aiWriterStepsEnum.StartingStep && activeStep !== aiWriterStepsEnum.FollowUpStartingStep && (
                    <Button id="back" onClick={handleBack} variant="secondary">
                      Back
                    </Button>
                  )}
                  {activeStep === aiWriterStepsEnum.StartingStep && (
                    <Button id="next" onClick={handleNext} variant="primary" disabled={props.isNextDisabled}>
                      Next
                    </Button>
                  )}
                  {(activeStep === aiWriterStepsEnum.TemplateSettingsStep || activeStep === aiWriterStepsEnum.FollowUpStartingStep) && (
                    <Button
                      id="generateBtn"
                      type={"button"}
                      onClick={() => {
                        handleSubmit();
                      }}
                      btnType="submit"
                      variant="primary"
                      disabled={isGenerateDisabled}
                    >
                      Generate
                    </Button>
                  )}
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
      <ResetAIWriterForm handleClose={handleDeleteStepClose} showModal={showDeleteStepModal} resetAIPrompt={resetAIPrompt} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.globalEmailTemplate.isLoading,
    isLoaded: state.globalEmailTemplate.isLoaded,
    error: state.globalEmailTemplate.error,
    orgId: state.user.organization.id,
    userName: state.user.firstName,
    initialEmailTemplates: state.globalEmailTemplate.initialEmailTemplates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInitialEmailTemplates: () => dispatch(fetchInitialEmailTemplates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AIEmailGenerator);
