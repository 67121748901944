import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";

// Styles
import styles from "./DepartmentsAndSeniority.module.scss";

// Components
import MultiLevelDropdown from "../../../../../components/MultiLevelDropdown/MultiLevelDropdown";
import { CompanySizeEnum } from "@models/enums/CompanySizeEnum";
import InfoIconTooltip from "../../../../../components/UI/CustomTooltip/InfoIconTooltip";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Select from "../../../../../components/UI/Select/Select";
import _ from "lodash";

const DepartmentsAndSeniority = (props) => {
  const { organizationSettings, handleUpdateObjectAction, setCreateSearchKey } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCount, setSelectedCount] = useState(props.organizationSettings.audience.departments.length);
  const [description, setDescription] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [seniority, setSeniority] = useState([]);

  const assignDefaultValues = () => {
    let description = (
      <p className="f14 secondary_text_color textCenter">
        Select departments and seniority levels to hone in on who you should be reaching with your prospecting campaign.
      </p>
    );

    if (organizationSettings.settings.hasChanges) {
      setDescription(description);
      setDepartments(organizationSettings.audience.departments);
      setSeniority(organizationSettings.audience.seniority);
      return;
    } else {
      const getKeyByValue = () => {
        const value = organizationSettings.audience.companySize.join("/");
        return Object.keys(CompanySizeEnum).find((key) => CompanySizeEnum[key] === value);
      };

      const selectedCompanySize = getKeyByValue();
      let departmentsValues = [];
      let seniorityValues = [];

      switch (selectedCompanySize) {
        case "SMALL":
          description = (
            <p className="f14 secondary_text_color textCenter">
              Since you're targeting smaller company sizes, we've preselected executive roles like CEOs, Owners, and Founders for your
              campaign and some key seniorities. Please select the departments to target alongside these positions.
            </p>
          );
          departmentsValues = ["41", "140"];
          seniorityValues = ["Head", "Director", "Chief", "Manager"];
          break;
        case "MEDIUM":
          description = (
            <p className="f14 secondary_text_color textCenter">
              Since you're focusing on medium-sized companies, we've automatically selected key seniorities. <br /> Now select the
              departments that match your targeting criteria.
            </p>
          );
          departmentsValues = [];
          seniorityValues = ["Head", "Director", "Chief", "Manager"];
          break;
        case "LARGE":
          description = (
            <p className="f14 secondary_text_color textCenter">
              Since you're focusing on large companies, we've automatically selected key seniorities.
              <br /> Now select the departments that match your targeting criteria.
            </p>
          );
          departmentsValues = [];
          seniorityValues = ["Head", "Director", "Chief", "Manager"];
          break;
      }

      handleUpdateObjectAction({
        ...organizationSettings,
        audience: { ...organizationSettings.audience, departments: departmentsValues, seniority: seniorityValues },
      });

      if (departmentsValues.length > 0) setSelectedCount(departmentsValues.length);

      setDescription(description);
      setDepartments(departmentsValues);
      setSeniority(seniorityValues);
    }
  };

  useEffect(() => {
    assignDefaultValues();
  }, []);

  useEffect(() => {
    if (!!props.listJobTitleDepartments && !!props.listJobTitleSeniorities && !!props.listJobTitleDepartments.length > 0) {
      setIsLoading(false);
    }
  }, [props.listJobTitleSeniorities, props.listJobTitleDepartments]);

  const changeDepartments = (values) => {
    handleUpdateObjectAction({
      ...organizationSettings,
      audience: { ...organizationSettings.audience, departments: values },
      settings: { ...organizationSettings.settings, hasChanges: true },
    });
    setSelectedCount(values.length);
  };

  const changeSeniorities = (values) => {
    handleUpdateObjectAction({
      ...organizationSettings,
      audience: { ...organizationSettings.audience, seniority: [...values] },
      settings: { ...organizationSettings.settings, hasChanges: true },
    });
  };

  useEffect(() => {
    setSelectedCount(organizationSettings.audience.departments.length ?? 0);
  }, [organizationSettings.audience.departments]);

  return departments && seniority ? (
    <div>
      <Formik
        initialValues={{
          jobTitleDepartments: departments,
          seniorityLevel: seniority,
        }}
        enableReinitialize={true}
      >
        {(formikProps) => {
          const { values, setFieldValue } = formikProps;
          return (
            <Form>
              <div className={`flex_center flexCol ${styles.departmentsAndSeniority}`}>
                <h1 className="f16 font700 mB15">Who do you normally sell to?</h1>
                {description}
                <div className={styles.selectsWrapper}>
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div id="department" className={`flex1 ${styles.dropdownForm}`}>
                        <p className="f12">
                          Departments & Functions{" "}
                          {selectedCount === 0 ? (
                            "(max. 20 selections)"
                          ) : (
                            <span className={styles.infoSpan}>(Selected {selectedCount} / 20)</span>
                          )}
                        </p>
                        <MultiLevelDropdown
                          title="e.g. Marketing, Digital"
                          name="jobTitleDepartments"
                          data={_.cloneDeep(props.listJobTitleDepartments)}
                          selectedValues={values.jobTitleDepartments}
                          onChange={(newValue) => {
                            setFieldValue("jobTitleDepartments", newValue);
                            changeDepartments(newValue);
                          }}
                          maxOptions={20}
                          showErrorMessage={true}
                        />
                      </div>
                      <div id="seniority" className={`flex1 ${styles.dropdownForm}`}>
                        <div className={`flex mB15 ${styles.infoIconWrapper}`}>
                          <span className="f12">Seniority level</span>
                          <InfoIconTooltip
                            tooltipBody="Search for individual prospects based on their job titles and level of seniority."
                            disableStartTour
                          />
                        </div>
                        <Select
                          menuIsOpen={true}
                          singleSelect
                          isSearchable
                          controlShouldRenderValue={false}
                          placeholder="e.g. Manager, lead, head"
                          title="Seniority level"
                          isMulti={true}
                          name="seniorityLevel"
                          options={props.listJobTitleSeniorities}
                          value={values.seniorityLevel}
                          onChange={(newValue) => {
                            if (!newValue || newValue?.length === 0) newValue = [];
                            setFieldValue("seniorityLevel", newValue);
                            changeSeniorities(newValue);
                            setCreateSearchKey(true);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <Spinner />
  );
};

export default DepartmentsAndSeniority;
