export const companySizeEnum = {
  SelfEmployed: "Self-employed",
  Employees1To10: "1-10 employees",
  Employees11To50: "11-50 employees",
  Employees51To200: "51-200 employees",
  Employees201To500: "201-500 employees",
  Employees501To1000: "501-1000 employees",
  Employees1001To5000: "1001-5000 employees",
  Employees5001To10000: "5001-10,000 employees",
  Employees10000Plus: "10,001+ employees",
};

export const companySizeDropDown = Object.values(companySizeEnum).map((x) => ({
  label: x,
  value: x,
}));
