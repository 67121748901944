import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";

//Redux
import { leaveOrganization, deleteOrganization } from "@store/global/actions/user";

//Components
import Modal from "@components/UI/Modal/Modal";
import { organizationModalActionType } from "./organizationModalActionType";
import { OrganizationDeleteStatusEnum } from "../../models/organization/OrganizationDeleteStatusEnum";

const DeleteLeaveOrganizationForm = (props) => {
  const { deleteStatus, setShowDeleteConfirmModal, handleClose } = props;
  const auth = useAuth();

  const handleSubmit = () => {
    if (deleteStatus === OrganizationDeleteStatusEnum.FreeTrialDelete) {
      setShowDeleteConfirmModal();
      handleClose();
      return;
    }
    if (props.modalActionType === organizationModalActionType.Leave) {
      let user = {
        id: props.user.id,
        isInvite: false,
        organizationId: props.organizationId,
        isLeave: true,
      };
      props.leaveOrganization(user);
      auth.signinSilent();
      props.handleClose();
    } else {
      let data = {
        organizationId: props.organizationId,
      };
      props.deleteOrganization(data);
      auth.signinSilent();
      props.handleClose();
    }
  };

  const saveButtonText =
    props.modalActionType === organizationModalActionType.Leave
      ? "Leave"
      : deleteStatus === OrganizationDeleteStatusEnum.FreeTrialDelete
      ? "I'm Sure"
      : "Delete";

  return (
    <Modal
      title={props.modalActionType === organizationModalActionType.Delete ? "Delete Organization" : "Leave Organization"}
      handleClose={props.handleClose}
      handleSave={handleSubmit}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText={saveButtonText}
      btnType="submit"
      variant="danger"
    >
      <Container>
        <Row>
          <Col xl={12}>
            {props.modalActionType === organizationModalActionType.Delete ? (
              <>
                <p>Are you sure you want to proceed with deleting this Organization?</p>
                <p>Once you delete your organization, you will no longer be able to access any of its data.</p>
              </>
            ) : (
              "Are you sure you want to leave this Organization?"
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    leaveOrganization: (user) => dispatch(leaveOrganization(user)),
    deleteOrganization: (organizationId) => dispatch(deleteOrganization(organizationId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLeaveOrganizationForm);
