export enum OptimizationPriorityEnum {
  High = 10,
  Medium = 20,
  Low = 30,
}

export function optimizationPriorityToString(type: OptimizationPriorityEnum): string {
  switch (type) {
    case OptimizationPriorityEnum.High:
      return "High";
    case OptimizationPriorityEnum.Medium:
      return "Medium";
    case OptimizationPriorityEnum.Low:
      return "Low";
    default:
      return "Unknown";
  }
}
