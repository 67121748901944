import React, { useEffect, useState } from "react";
import _ from "lodash";

//Style
import styles from "./Companies.module.scss";

//Components
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";
import HUBSPOT_DEFAULTS from "../HubspotCompanyContactDefaults";

const Companies = (props) => {
  const { companiesMappings, companiesProperties } = props;

  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    setCompanyOptions([{ value: "", label: "None" }].concat(companiesProperties));
    if (
      companiesProperties &&
      companiesProperties.length > 0 &&
      companiesMappings &&
      !companiesMappings.CompanyName &&
      !companiesMappings.Website &&
      !companiesMappings.Industry &&
      // && !companiesMappings.CompanySize
      !companiesMappings.CountryLocation //object is empty
    ) {
      let tmp = companiesProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Company.CompanyName);
      if (tmp) {
        companiesMappings.CompanyName = tmp.value;
      }

      tmp = companiesProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Company.Website);
      if (tmp) companiesMappings.Website = tmp.value;

      tmp = companiesProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Company.Industry);
      if (tmp) companiesMappings.Industry = tmp.value;

      // tmp = companiesProperties.find(x => x.value === HUBSPOT_DEFAULTS.Company.CompanySize);
      // if (tmp)
      //   companiesMappings.CompanySize = tmp.value;

      tmp = companiesProperties.find((x) => x.value === HUBSPOT_DEFAULTS.Company.CountryLocation);
      if (tmp) {
        companiesMappings.CountryLocation = tmp.value;
      }
    }
  }, [companiesProperties]);

  return companyOptions.length > 0 ? (
    <div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Company Name</p>
        <FormikSelect
          name="globalMappingsCompanies.CompanyName"
          options={companyOptions}
          isMenuPortalTargetDisabled={true}
          isSearchable={true}
          value={
            !_.isUndefined(companiesMappings) &&
            !_.isNull(companiesMappings) &&
            !_.isEmpty(companiesProperties) &&
            companiesProperties.some((x) => x.value === companiesMappings.CompanyName)
              ? companiesProperties.find((x) => x.value === companiesMappings.CompanyName).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Website</p>
        <FormikSelect
          name="globalMappingsCompanies.Website"
          disabled={true}
          options={companyOptions}
          isSearchable={true}
          isMenuPortalTargetDisabled={true}
          value={
            !_.isUndefined(companiesMappings) &&
            !_.isNull(companiesMappings) &&
            !_.isEmpty(companiesProperties) &&
            companiesProperties.some((x) => x.value === companiesMappings.Website)
              ? companiesProperties.find((x) => x.value === companiesMappings.Website).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>Industry</p>
        <FormikSelect
          name="globalMappingsCompanies.Industry"
          options={companyOptions}
          isSearchable={true}
          isMenuPortalTargetDisabled={true}
          value={
            !_.isUndefined(companiesMappings) &&
            !_.isNull(companiesMappings) &&
            !_.isEmpty(companiesProperties) &&
            companiesProperties.some((x) => x.value === companiesMappings.Industry)
              ? companiesProperties.find((x) => x.value === companiesMappings.Industry).value
              : ""
          }
        />
      </div>
      {/* <div className={styles.formWrapper}>
          <p className={styles.label}>Company Size</p>
          <FormikSelect
            name="globalMappingsCompanies.CompanySize"
            options={companyOptions}
            isSearchable={true}
            isMenuPortalTargetDisabled={true}
            value={
              !_.isUndefined(companiesMappings) && !_.isNull(companiesMappings) && !_.isEmpty(companiesProperties) && companiesProperties.some(x => x.value === companiesMappings.CompanySize) ?
                companiesProperties.find(x => x.value === companiesMappings.CompanySize).value : ""
            }
          />
        </div> */}
      <div className={styles.formWrapper}>
        <p className={styles.label}>Country Location</p>
        <FormikSelect
          name="globalMappingsCompanies.CountryLocation"
          options={companyOptions}
          isSearchable={true}
          isMenuPortalTargetDisabled={true}
          value={
            !_.isUndefined(companiesMappings) &&
            !_.isNull(companiesMappings) &&
            !_.isEmpty(companiesProperties) &&
            companiesProperties.some((x) => x.value === companiesMappings.CountryLocation)
              ? companiesProperties.find((x) => x.value === companiesMappings.CountryLocation).value
              : ""
          }
        />
      </div>
      <div className={styles.formWrapper}>
        <p className={styles.label}>LinkedIn Url</p>
        <FormikInput type="text" name="globalMappingsCompanies.SocialNetworks" />
      </div>
    </div>
  ) : null;
};

export default Companies;
