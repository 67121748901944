import React, { FC } from "react";
import Action from "../Action";
import BroadcastLineIcon from "remixicon-react/BroadcastLineIcon";

interface ActionConversionProps {
  size?: number;
  classNameIcon?: string;
}

const ActionConversion: FC<ActionConversionProps> = ({ size = 20, classNameIcon = "iconGray", ...props }) => {
  return <Action id="conversion" svgIcon={<BroadcastLineIcon size={size} className={classNameIcon} />} {...props} />;
};

export default ActionConversion;
