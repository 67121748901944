import React, { FC } from "react";
import { RadioGroup } from "@mui/material";

//Helpers
import { checkIfIsChecked } from "@helper/checkIfIsChecked";
import { ResponseOriginTypeEnum } from "@models/enums/ResponseTypeEnum";

//Styles
import styles from "./ManuallyCategorizeButtons.module.scss";

//Images and Icons
import MailLineIcon from "remixicon-react/MailLineIcon";
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

//Components
import FormikRadio from "@components/UI/Formik/FormikRadio";

interface ManuallyCategorizeButtonsProps {
  title?: string;
  onActivityTypeChange: (selectedType: ResponseOriginTypeEnum) => void;
  values: { [key: string]: any };
  setFieldValue: (field: string, value: any) => void;
  name: string;
}

const ManuallyCategorizeButtons: FC<ManuallyCategorizeButtonsProps> = ({ title, onActivityTypeChange, values, setFieldValue, name }) => {
  const handleIconClick = (selectedType: ResponseOriginTypeEnum) => {
    setFieldValue(name, selectedType);
    onActivityTypeChange(selectedType);
  };

  return (
    <>
      {title && <p>{title}</p>}
      <div className={styles.categoryIcons}>
        <RadioGroup
          id={name}
          className={`${styles.categoryIconsWrapper} flex-row`}
          name={name}
          onChange={(e) => {
            handleIconClick(e.target.value as unknown as ResponseOriginTypeEnum);
          }}
          value={values[name]}
        >
          <FormikRadio
            id="mail"
            name={name}
            labelstyle={`${styles.mailIcon} ${styles.icon}`}
            labelText="Email"
            icon={MailLineIcon}
            type="radio"
            onClick={() => handleIconClick(ResponseOriginTypeEnum.Email)}
            checked={checkIfIsChecked(values[name], ResponseOriginTypeEnum.Email)}
          />
          <FormikRadio
            id="linkedin"
            name={name}
            labelstyle={`${styles.linkedinIcon} ${styles.icon}`}
            labelText="LinkedIn"
            icon={LinkedinBoxFillIcon}
            type="radio"
            onClick={() => handleIconClick(ResponseOriginTypeEnum.LinkedIn)}
            checked={checkIfIsChecked(values[name], ResponseOriginTypeEnum.LinkedIn)}
          />
          <FormikRadio
            id="task"
            name={name}
            labelstyle={`${styles.taskIcon} ${styles.icon}`}
            labelText="Meeting"
            icon={EventAvailableOutlinedIcon}
            type="radio"
            onClick={() => handleIconClick(ResponseOriginTypeEnum.Task)}
            checked={checkIfIsChecked(values[name], ResponseOriginTypeEnum.Task)}
          />
          <FormikRadio
            id="call"
            name={name}
            labelstyle={`${styles.callIcon} ${styles.icon}`}
            labelText="Call"
            icon={CallOutlinedIcon}
            type="radio"
            onClick={() => handleIconClick(ResponseOriginTypeEnum.PhoneCall)}
            checked={checkIfIsChecked(values[name], ResponseOriginTypeEnum.PhoneCall)}
          />
          <FormikRadio
            id="other"
            name={name}
            labelstyle={`${styles.otherIcon} ${styles.icon}`}
            labelText="Other"
            icon={MoreHorizOutlinedIcon}
            type="radio"
            onClick={() => handleIconClick(ResponseOriginTypeEnum.Other)}
            checked={checkIfIsChecked(values[name], ResponseOriginTypeEnum.Other)}
          />
        </RadioGroup>
      </div>
    </>
  );
};

export default ManuallyCategorizeButtons;
