import React from "react";

//Styles
import style from "./StatusBar.module.scss";

//Images & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const StatusBar = (props) => {
  const { isDelivered, isOpened, isClicked, isResponder, isLead } = {
    ...props,
  };

  return (
    <div className={`${style.statusBarContainer} textOverflow`}>
      <FontAwesomeIcon
        style={isDelivered === false ? { color: "#FCCD72" } : null}
        className={style.statusBar}
        icon={faCircle}
      />
      <FontAwesomeIcon
        style={isDelivered ? { color: "#F58383" } : null}
        className={style.statusBar}
        icon={faCircle}
      />
      <FontAwesomeIcon
        style={isOpened ? { color: "#E86FA9" } : null}
        className={style.statusBar}
        icon={faCircle}
      />
      <FontAwesomeIcon
        style={isClicked ? { color: "#6A45A0" } : null}
        className={style.statusBar}
        icon={faCircle}
      />
      <FontAwesomeIcon
        style={isResponder ? { color: "#369AF7" } : null}
        className={style.statusBar}
        icon={faCircle}
      />
      <FontAwesomeIcon
        style={isLead ? { color: "#76DCAA" } : null}
        className={style.statusBar}
        icon={faCircle}
      />
    </div>
  );
};

export default StatusBar;
