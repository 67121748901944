import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Redux
import { fetchEmailAccounts } from "../../../store/EmailAccounts/Table/actions/emailAccounts";

//Helpers
import { toastMessages } from "../../../helper/toastMessagesConstants";
import api, { API, formUrl } from "../../../helper/api/api";

// Styles
import styles from "./DeleteEmailAccountForm.module.scss";

//Components
import Modal from "../../../components/UI/Modal/Modal";

const DeleteEmailAccountForm = (props) => {
  const { fetchEmailAccounts, param, emailAccount } = props;

  const deleteEmailAccount = () => {
    api(formUrl(API.emailAccounts.delete, { id: emailAccount.id }))
      .then((response) => {
        if (props.getEmailAccounts) props.getEmailAccounts(param);
        toast.success(toastMessages.emailAccount.successDeleteEmailAccount);
      })
      .catch((error) => {
        toast.error(toastMessages.emailAccount.errorDeleteEmailAccount);
      });
    props.handleClick();
  };
  return (
    <Modal
      title={"Delete Email Account"}
      handleClose={props.handleClose}
      handleSave={deleteEmailAccount}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Yes"
      btnType="submit"
      size="md"
    >
      <Container>
        <Row className={styles.modalBodyScroll}>
          <Col xl={12} className={styles.textWrapper}>
            <p>
              The following email account <span className="primary_text_color">{emailAccount.emailAccount}</span> will be deleted.{" "}
            </p>

            {emailAccount.domainAssociatedAccounts === 1 ? (
              <>
                <p>
                  The root domain <span className="primary_text_color f14">{emailAccount.domain}</span> will be set as inactive, because
                  there is no other existing email account associated with that domain.
                </p>
                <p>Do you want to continue? </p>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    param: state.emailAccounts.params,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailAccounts: (param) => dispatch(fetchEmailAccounts(param)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteEmailAccountForm);
