import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DropdownButton } from "react-bootstrap";
import Moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import _ from "lodash";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { campaignStatusEnum } from "../../helper/enums/campaignStatusEnum";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";
import { dealsType } from "../../helper/dealsType";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { getCampaignStatusData } from "../../helper/campaignHelpers";
import { errorResponseToList } from "../../helper/errorHelper";
import { deletedTemplate, draftTemplates, noEmailAccountConnected } from "../../helper/campaignGoLiveErrorConstant";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";

//Redux
import { setFilter as setFilterInbox } from "../../store/ResponderInbox/Table/actions/responderInboxTable";
import { setLocation } from "../../store/ResponderInbox/actions/responderInbox";
import { setFilter as setFilterProspects } from "../../store/Prospects/Table/actions/prospects";
import { setFilter as setFilterCompanies } from "../../store/Companies/Table/actions/companies";
import { setFilter as filterPositiveResponses } from "../../store/Deals/Table/actions/positiveResponsesDeals";
import { setFilter as filterReferalResponses } from "../../store/Deals/Table/actions/referralsDeals";
import { setFilter as filterDeferredInterestResponses } from "../../store/Deals/Table/actions/deferredInterestDeals";
import { setFilterReport, clearInitiatedReport } from "../../store/Reports/Global/actions/reports";

//Components
import EditCampaignNameModal from "./Modal/EditCampaignNameModal";
import GoLiveModal from "./Modal/GoLiveModal";

//Styles
import styles from "./CampaignSettings.module.scss";

//Images & Icons
import RemixIConRename from "../../assets/Icons/RemixIConRename/RemixIConRename";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";

// Components
import Permission from "../../components/auth/Permission";
import StatusIcon from "./Components/StatusIcon/StatusIcon";
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";
import Select from "../../components/UI/Select/Select";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import BreadcrumbElement from "../../components/UI/BreadcrumbElement/BreadcrumbElement";
import StatusCol from "../../components/DataTable/StatusCol/StatusCol";
import DeletedTemplateModal from "../Campaigns/DeletedTemplateModal";
import CampaignActivatedModal from "../Campaigns/CampaignActivatedModal";
import NoEmailAccountConnectedModal from "../Campaigns/NoEmailAccountConnectedModal";
import ProductTourLinks from "./ProductTourLinks/ProductTourLinks";
import VideoModal from "../Dashboard/Components/WelcomeModal/VideoModal/VideoModal";
import ActionClone from "../../components/UI/ActionWrapper/Actions/ActionClone";
import CloneCampaignModal from "../Campaigns/CloneCampaignModal";
import DraftTemplateModal from "../Campaigns/DraftTemplateModal";
import useModal from "@components/UI/Modal/useModal";

// Api
import { useSlice } from "@helper/hooks/useSlice";
import { useGetCampaignQuery } from "@api/campaignApi";
import { useGetScheduleQuery } from "@api/sendingScheduleApi";
import useRouteParams from "@hooks/useRouteParams";
import { fetchWorkflowStepsList } from "@store/WorkflowSteps/actions/workflowSteps";
import { fetchSendingSchedule } from "@store/SendingSchedule/actions/sendingShedule";

const TopBannerCampaignSettings = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  let dateFormat = useOrgDateFormat();
  const { campaignId, organizationId } = useRouteParams();

  const { refetch: refetchCampaign } = useGetCampaignQuery(campaignId, { refetchOnMountOrArgChange: true });
  useGetScheduleQuery(campaignId, { refetchOnMountOrArgChange: true });

  const { campaign, sendingSchedule } = useSlice((state) => state.campaign);
  const history = useHistory();

  const displayVideoModal = useModal();
  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  const setFilters = async () => {
    await props.setLocation(true);
    await props.setFilterInbox("Campaign", [campaignId]);
    await props.setFilterProspects("Campaign", [campaignId]);
    await props.setFilterCompanies("Campaign", [campaignId]);
    await props.filterPositiveResponses("Campaign", [campaignId]);
    await props.filterPositiveResponses("Type", [dealsType.PositiveResponses]);
    await props.filterReferalResponses("Campaign", [campaignId]);
    await props.filterReferalResponses("Type", [dealsType.Referrals]);
    await props.filterDeferredInterestResponses("Campaign", [campaignId]);
    await props.filterDeferredInterestResponses("Type", [dealsType.DeferredInterest]);
    await props.setFilterReport("Campaign", [campaignId]);
  };

  const getAllSettings = async () => {
    await props.fetchWorkflowStepsList(campaignId);
    await props.fetchSendingSchedule(campaignId);
  };

  useEffect(() => {
    getAllSettings();
    setFilters();
  }, [campaignId]);

  useEffect(() => {
    return () => {
      props.setFilterReport("Campaign", []);
    };
  }, []);

  const editNameModal = useModal();
  const editStatusModal = useModal();
  const cloneModal = useModal();
  const deletedTemplateModal = useModal();
  const draftTemplateModal = useModal();
  const campaignActivatedModal = useModal();
  const noEmailAccountModal = useModal();

  const handleChangeCampaignStatusModalClose = (e) => {
    editStatusModal.hide();

    if (e !== undefined) {
      history.push("/" + organizationId + "/campaigns/" + campaignId + "/sending-schedule");
    }
  };

  const handleChangeStatus = (status) => {
    setIsDisabled(true);
    api(
      formUrl(API.campaigns.editStatus, {
        campaignId: campaignId,
        status: status,
      })
    )
      .then((res) => {
        refetchCampaign();
        if (res != null && res.data != null && res.data.status === 1) {
          let emailAddresses = "";
          if (res.data.disconnectedAccounts != null && res.data.disconnectedAccounts.length > 0) {
            emailAddresses = _.join(res.data.disconnectedAccounts, ", ");
          }

          campaignActivatedModal.show(emailAddresses);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        const errorList = errorResponseToList(err);
        if (errorList.length > 0) {
          if (errorList[0].message === deletedTemplate) {
            deletedTemplateModal.show();
          } else if (errorList[0].message === noEmailAccountConnected) {
            noEmailAccountModal.show();
          } else if (errorList[0].message === draftTemplates) {
            draftTemplateModal.show();
          }
        } else {
          toast.error(toastMessages.campaign.errorChangeStatusFailed);
        }
        setIsDisabled(false);
      });

    editStatusModal.hide(false);
  };

  let lastActiveDate =
    campaign.lastActiveDate == null || campaign.lastActiveDate === "" ? "N/A" : Moment(campaign.lastActiveDate).format(dateFormat);
  let lastEmailSent =
    campaign.lastSendDate === null || campaign.lastSendDate === "" ? "N/A" : Moment(campaign.lastSendDate).format(dateFormat);
  let nextSendDate =
    campaign.nextSendDate === null || campaign.nextSendDate === "" ? "N/A" : Moment(campaign.nextSendDate).format(dateFormat);

  let campaignStatusData = getCampaignStatusData(campaign.statusName, campaign.campaignSetupProgressModel);

  let statusInfo = (
    <StatusCol
      status={campaignStatusData.status}
      time={null}
      variant={campaignStatusData.statusVariant}
      tooltipText={campaignStatusData.tooltipText}
      wrapperClass="border_none"
    />
  );

  return (
    <div className={styles.campaignWrapper}>
      <div className={styles.campaignInfo}>
        <div className={styles.campaignDetails}>
          <div className={styles.campaignHistory}>
            <BreadcrumbElement items={props.breadcrumbs} />
            {props.remove ? (
              <div className="flex align-center width_100">
                <HeaderTitle className={styles.pageTitle} title={props.title} rightContent={props.rightContent} />
                {props.videoModal ? (
                  <ProductTourLinks
                    labelTour={props.labelTour}
                    tourButton={props.tourButton}
                    id={props.tourId}
                    show={displayVideoModal.show}
                    href={props.videoLinkHref}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className={styles.campaignNameWrapper}>
                <h1 className={styles.campaignTitle}>{campaign.name}</h1>
                <Permission has={PERMISSIONS.campaign.edit}>
                  <div onClick={() => editNameModal.show()} className={`cursor_pointer ${styles.editCampaignActionButton}`}>
                    <RemixIConRename />
                  </div>
                </Permission>
                <Permission has={PERMISSIONS.campaign.create}>
                  <div className={styles.editCampaignActionButton}>
                    <ActionClone onClick={() => cloneModal.show(campaign.id)} />
                  </div>
                </Permission>
                <DropdownButton className={styles.dropdownButton} aria-label="dropdown" title="">
                  <Select
                    options={campaignsList}
                    onChange={(c) => props.changeCampaign(c)}
                    isSearchable={true}
                    menuIsOpen={true}
                    multiDropdown={true}
                  />
                </DropdownButton>
                <ProductTourLinks
                  labelTour="How to activate your campaign"
                  documentationLink
                  show={displayVideoModal.show}
                  id="createCampaign"
                  href="https://help.outbase.com/getting-started/your-first-campaign"
                />
              </div>
            )}
            <div className={`${styles.actionDetails} ${props.remove ? styles.none : null}`}>
              <div className={`${styles.felx} ${styles.spaceBetween}`}>
                <span className={styles.spanTitle}>Created by:</span>
                <span className={styles.spanDataInfo}>
                  <UserPhoto name={campaign.createdBy} size="small" />
                </span>
                <span className={`${styles.spanDataInfo} mL10`}>
                  <p>{campaign.createdBy}</p>
                </span>
              </div>
              <div className={`${styles.felx} ${styles.spaceBetween}`}>
                <span className={styles.spanTitle}>Created:</span>
                <span className={`${styles.spanDataInfo} mR5`}>
                  <CalendarLineIcon size={18} className="iconGray" />
                </span>
                <span className={styles.spanDataInfo}>
                  <p>{campaign.created != null && campaign.created !== "" ? Moment(campaign.created).format(dateFormat) : null}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="statusDetails" className={`${styles.rightSideWrapper} ${props.remove ? styles.none : null} statusDetails`}>
          <div className={styles.campaignCreator}>
            <div className={styles.creatorInfo}>
              <div className={styles.status}>
                <p>Status:</p>
                <p>Last Active:</p>
                <p>Last Email Sent:</p>
                <p>Next Send:</p>
              </div>
              <div className={styles.statusInfo}>
                <div className="flex">{statusInfo}&nbsp;</div>
                <p>{lastActiveDate}&nbsp;</p>
                <p>{lastEmailSent}&nbsp;</p>
                <p>{nextSendDate}&nbsp;</p>
              </div>
            </div>
          </div>
          <StatusIcon
            isSubmitting={isDisabled}
            canGoLive={campaign.campaignSetupProgressModel?.canGoLive}
            changeStatus={handleChangeStatus}
            status={campaign.status}
            openActivationModal={editStatusModal.show}
          />
        </div>
      </div>
      {editNameModal.isVisible && (
        <Permission has={PERMISSIONS.campaign.edit}>
          <EditCampaignNameModal
            handleClose={editNameModal.hide}
            showModal={editNameModal.isVisible}
            id={campaignId}
            name={campaign.name}
          />
        </Permission>
      )}
      {editStatusModal.isVisible && sendingSchedule && (
        <Permission has={PERMISSIONS.campaign.edit}>
          <GoLiveModal
            handleClose={(e) => handleChangeCampaignStatusModalClose(e)}
            showModal={editStatusModal.isVisible}
            id={campaignId}
            goLive={() => handleChangeStatus(campaignStatusEnum.active)}
            schedule={sendingSchedule}
          />
        </Permission>
      )}
      <DeletedTemplateModal
        showModal={deletedTemplateModal.isVisible}
        handleSave={deletedTemplateModal.hide}
        handleClose={deletedTemplateModal.hide}
      />
      <DraftTemplateModal
        showModal={draftTemplateModal.isVisible}
        handleSave={draftTemplateModal.hide}
        handleClose={draftTemplateModal.hide}
      />
      <CampaignActivatedModal
        showModal={campaignActivatedModal.isVisible}
        handleSave={campaignActivatedModal.hide}
        handleClose={campaignActivatedModal.hide}
        emailAddress={campaignActivatedModal.data}
        campaignName={campaign.name}
      />
      <NoEmailAccountConnectedModal
        showModal={noEmailAccountModal.isVisible}
        handleSave={noEmailAccountModal.hide}
        handleClose={noEmailAccountModal.hide}
        campaignName={campaign.name}
        campaignId={campaignId}
        organizationId={organizationId}
      />
      <VideoModal modal={displayVideoModal} />

      {cloneModal.data && (
        <CloneCampaignModal showModal={cloneModal.isVisible} campaignId={cloneModal.data} handleClose={cloneModal.hide} redirect />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWorkflowStepsList: (campaignId) => dispatch(fetchWorkflowStepsList(campaignId)),
    fetchSendingSchedule: (campaignId) => dispatch(fetchSendingSchedule(campaignId)),
    setFilterInbox: (type, value) => dispatch(setFilterInbox(type, value)),
    setFilterProspects: (type, value) => dispatch(setFilterProspects(type, value)),
    setFilterCompanies: (type, value) => dispatch(setFilterCompanies(type, value)),
    filterPositiveResponses: (type, value) => dispatch(filterPositiveResponses(type, value)),
    filterReferalResponses: (type, value) => dispatch(filterReferalResponses(type, value)),
    filterDeferredInterestResponses: (type, value) => dispatch(filterDeferredInterestResponses(type, value)),
    setLocation: (val) => dispatch(setLocation(val)),
    setFilterReport: (type, value) => dispatch(setFilterReport(type, value)),
    clearInitiatedReport: () => dispatch(clearInitiatedReport()),
  };
};

export default connect(null, mapDispatchToProps)(TopBannerCampaignSettings);
