import React from "react";
import svg from "./Person.svg";

const Person = (props) => (
  <svg className={props.imgStyle} xmlns="http://www.w3.org/2000/svg" width="14.08" height="16" viewBox="0 0 14.08 16">
    <g transform="translate(-7 -6.5)">
      <circle className="a" fill="#fc8f12" cx="4" cy="4" r="4" transform="translate(10 6.5)" />
      <path
        fill="#586473"
        className="b"
        d="M17.06,14H10.02A3.523,3.523,0,0,0,6.5,17.52v1.92a.96.96,0,0,0,.96.96H19.62a.96.96,0,0,0,.96-.96V17.52A3.523,3.523,0,0,0,17.06,14Z"
        transform="translate(0.5 2.1)"
      />
    </g>
  </svg>
);

export default Person;
