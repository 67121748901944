import React from "react";

//Helpers
import { useGroupDecimalSeparator } from "../../../../helper/hooks/useGroupDecimalSeparator";
import { formatNumberHelper } from "../../../../helper/formatNumberHelper";

//Styles
import styles from "./GlobalAverageRate.module.scss";

//Components
import ProgressBar from "../../../../components/UI/ProgressBar/ProgressBar";

const GlobalAvarageRate = (props) => {
  let separators = useGroupDecimalSeparator();

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.cardWrapper}>
        {props.now > props.globalRate ? (
          <ProgressBar variant="progress-s" now={props.now} ariaLabel={props.ariaLabel} />
        ) : props.now < props.globalRate ? (
          <ProgressBar variant="progress-s" now={props.now} color={"danger"} />
        ) : null}
        <div className={styles.progressRate} style={{ left: props.globalRate + "%" }}>
          <hr />
          <p>
            {formatNumberHelper(props.globalRate, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
            <span>%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GlobalAvarageRate;
