import React, { FC } from "react";
import Spinner from "react-bootstrap/Spinner";

//Styles
import styles from "./Sequence.module.scss";

//Components
import SequenceStep from "./SequenceStep";
import TemplatesTable from "./TemplatesTable/TemplatesTable";
import { WorkflowStepDetails } from "@models/workflowSteps/WorkflowStepDetails";

interface SequenceProps {
  workflowSteps: WorkflowStepDetails[];
  stepsLoading: boolean;
}

const Sequence: FC<SequenceProps> = ({ workflowSteps, stepsLoading }) => {
  if (stepsLoading) return <Spinner />;

  return (
    <>
      <div className={styles.sequenceMessgWrapper}>
        <div className={styles.sequenceWrapper}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.headTitle}>Sequence</h1>
          </div>
          <div className={styles.sequenceSteps}>
            {workflowSteps &&
              workflowSteps.map((s) => {
                return <SequenceStep key={s.workflowStepId} type={s.type} details={s.details} />;
              })}
          </div>
          <div className={`${styles.titleWrapper} mT10`}>
            <h1 className={styles.headTitle}>Messaging</h1>
          </div>
          <TemplatesTable />
        </div>
      </div>
    </>
  );
};

export default Sequence;
