import React from "react";

//Styles
import styles from "./ResponsesLegend.module.scss";

const ResponsesLegend = () => {
  return (
    <div className="mT15">
      <div className="flex">
        <div className="flex align-center flex1 mR10">
          <div className={`${styles.circle} ${styles.positiveResponse}`}></div>
          <p className={styles.responseStatus}>Positive response</p>
        </div>
        <div className="flex align-center flex1">
          <div className={`${styles.circle} ${styles.referral}`}></div>
          <p className={styles.responseStatus}>Referral</p>
        </div>
      </div>
      <div className="flex">
        <div className="flex align-center flex1 mR10">
          <div className={`${styles.circle} ${styles.deferredInterest}`}></div>
          <p className={styles.responseStatus}>Deferred Interest</p>
        </div>
        <div className="flex align-center flex1">
          <div className={`${styles.circle} ${styles.decline}`}></div>
          <p className={styles.responseStatus}>Decline</p>
        </div>
      </div>
    </div>
  );
};

export default ResponsesLegend;
