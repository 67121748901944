import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";

//Helper
import { calculatePercentageFromNumber } from "../../../helper/calculatePercentageHelper";
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";

// Styles
import styles from "./CampaignInfoCol.module.scss";
import "./CampaignInfoStyle.scss";

//Components
import ProgressLine from "../../UI/ProgressLine/ProgressLine";

const CampaignInfoCol = (props) => {
  let fullNameArr = props.createdBy.split(" ");
  let nameCreatedBy = fullNameArr[0];
  let initialSurnameCreatedBy = fullNameArr[1]?.charAt(0).toUpperCase() + ".";
  let separators = useGroupDecimalSeparator();
  let availableFormatted = formatNumberHelper(props.available, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  let engagedFormatted = formatNumberHelper(props.engaged, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  let importedFormatted = formatNumberHelper(props.imported, separators.numberGroupSeperator, separators.numberDecimalSeperator);

  let audienceData = [];
  audienceData.push({
    color: "#1ac472",
    percentage: calculatePercentageFromNumber(props.available, props.imported),
  });
  audienceData.push({
    color: "#fe7904",
    percentage: calculatePercentageFromNumber(props.engaged, props.imported),
  });
  return (
    <div className={`${styles.columnWrapper} flex`}>
      <div id={props.id} className={styles.details}>
        <OverlayTrigger
          key={props.key}
          placement={"bottom"}
          overlay={
            <Popover id={`popover-${props.name}`}>
              <Popover.Body>{props.name}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + props.orgId + "/campaigns/" + props.campaignId + "/overview",
              }}
            >
              {props.name}
            </Link>
          </div>
        </OverlayTrigger>
        <p className={`${styles.creatorDate} textOverflow`}>
          <span>{nameCreatedBy + " " + initialSurnameCreatedBy}</span>
          <span>{props.createdDate}</span>
        </p>
      </div>
      <OverlayTrigger
        key={"audience-size"}
        placement={"bottom"}
        overlay={
          <Popover id={`audience-size-tooltip`}>
            <Popover.Body className="campaignAudienceTooltip">
              <span>Awaiting Engagement / Engaged / Total</span>
            </Popover.Body>
          </Popover>
        }
      >
        <div className={styles.progressBarDetails}>
          <p className={styles.labelsWrapper}>
            <span className={styles.available}>{availableFormatted} </span>/<span className={styles.engaged}> {engagedFormatted} </span>/
            <span className={styles.imported}> {importedFormatted}</span>
          </p>
          <ProgressLine hideLabelStyle={true} data={audienceData} />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default CampaignInfoCol;
