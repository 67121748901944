import React from "react";
import svg from "./Expand.svg";

const Expand = (props) => (
  <svg
    onClick={props.onClick}
    className={props.imgStyle}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4288 19.13C3.94601 18.2726 2.71792 17.0368 1.86923 15.549C0.965588 14.0207 0.492381 12.2765 0.500093 10.5011C0.492898 8.72546 0.966062 6.98108 1.86923 5.45228C2.73327 3.96845 3.96793 2.73443 5.45224 1.87122C6.98046 0.967415 8.72463 0.493898 10.5001 0.501107C12.283 0.491923 14.0354 0.966129 15.5704 1.87318C17.0542 2.71868 18.2832 3.94726 19.13 5.43031C20.036 6.966 20.5095 8.7181 20.5001 10.5011C20.5106 12.2844 20.0374 14.0371 19.131 15.5729C18.2856 17.057 17.0562 18.2859 15.5714 19.13C14.0365 20.038 12.2834 20.512 10.5001 20.5011C8.71678 20.5119 6.96375 20.0379 5.4288 19.13V19.13ZM6.46103 3.57826C5.26201 4.26899 4.26699 5.264 3.57626 6.46302C2.84886 7.68404 2.46645 9.07985 2.4708 10.5011C2.46565 11.9225 2.84812 13.3185 3.57626 14.5392C4.26562 15.7393 5.26096 16.7346 6.46103 17.424C7.68263 18.1517 9.07913 18.5336 10.5011 18.5289C11.9222 18.5326 13.3179 18.1507 14.5392 17.424C15.739 16.7343 16.7343 15.7391 17.4239 14.5392C18.1521 13.3185 18.5345 11.9225 18.5294 10.5011C18.5337 9.07985 18.1513 7.68404 17.4239 6.46302C16.7329 5.2642 15.738 4.26925 14.5392 3.57826C13.3173 2.8528 11.922 2.47103 10.5011 2.47328C9.07933 2.47002 7.6832 2.85185 6.46103 3.57826V3.57826ZM9.51376 15.526V11.4879H5.47568V9.51527H9.51376V5.47718H11.4874V9.51527H15.5255V11.4879H11.4874V15.526H9.51376Z"
      fill="#AEBAC4"
    />
  </svg>
);

export default Expand;
