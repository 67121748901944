import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchTotalCampaignsPerformance } from "../../../../store/Dashboard/Global/actions/dashboard";
import _ from "lodash";

//helpers
import { addOffsetToDate } from "../../../../helper/dateHelper";

//Styles
import styles from "../../Components/DashboardDetailsView/DashboardDetailsView.module.scss";

//Components
import RateWidget from "../RateWidget/RateWidget";
import StatisticWidget from "../StatisticWidget/StatisticWidget";
import SkeletonCampaignPerformance from "../../../../components/UI/Skeletons/components/SkeletonCampaignPerformance/SkeletonCampaignPerformance";

const TotalCampaignPerformance = (props) => {
  const {
    fetchTotalCampaignsPerformance,
    isTotalCampaignsPerformanceLoading,
    isTotalCampaignsPerformanceLoaded,
    errorTotalCampaignsPerformance,
    totalCampaignsPerformance,
    startDate,
    endDate,
    setGlobalAverageRateOpen,
    setGlobalAverageRateClick,
    setGlobalAverageRateResponse,
    setGlobalAverageRateLead,
    setGlobalAverageUniqueRateOpen,
    setGlobalAverageUniqueRateClick,
    uniqueToggle,
  } = props;
  useEffect(() => {
    fetchTotalCampaignsPerformance(addOffsetToDate(startDate), addOffsetToDate(endDate));
  }, [startDate, endDate]);

  let content = null;

  useEffect(() => {
    if (!_.isNull(totalCampaignsPerformance.openRate)) {
      setGlobalAverageRateOpen(totalCampaignsPerformance.openRate);
    }
    if (!_.isNull(totalCampaignsPerformance.uniqueOpenRate)) {
      setGlobalAverageUniqueRateOpen(totalCampaignsPerformance.uniqueOpenRate);
    }
    if (!_.isNull(totalCampaignsPerformance.clickRate)) {
      setGlobalAverageRateClick(totalCampaignsPerformance.clickRate);
    }
    if (!_.isNull(totalCampaignsPerformance.uniqueClickRate)) {
      setGlobalAverageUniqueRateClick(totalCampaignsPerformance.uniqueClickRate);
    }
    if (!_.isNull(totalCampaignsPerformance.responseRate)) {
      setGlobalAverageRateResponse(totalCampaignsPerformance.responseRate);
    }
    if (!_.isNull(totalCampaignsPerformance.leadsRate)) {
      setGlobalAverageRateLead(totalCampaignsPerformance.leadsRate);
    }
  }, [totalCampaignsPerformance]);

  if (isTotalCampaignsPerformanceLoading) {
    content = (
      <div className="flex width_100 mB20">
        {[1, 2, 3, 4, 5].map((n) => (
          <SkeletonCampaignPerformance key={n} />
        ))}
      </div>
    );
  }

  if (isTotalCampaignsPerformanceLoaded) {
    content = (
      <>
        <StatisticWidget
          title={"Engagements"}
          total={totalCampaignsPerformance.prospectsEngaged}
          emailsSent={totalCampaignsPerformance.emailsSent}
        />
        <div className={styles.widgetsContainer}>
          <div className={styles.widgetContentWrapper}>
            {uniqueToggle === 0 ? (
              <RateWidget
                width={60}
                height={30}
                classRate="openRate"
                name={"Total Open Rate"}
                tooltipContent={
                  <>
                    Number of emails opened, shown as percentage
                    <br />
                    <b>Formula:</b> Total emails open/Total emails delivered
                  </>
                }
                rate={totalCampaignsPerformance.openRate}
                totalNumber={totalCampaignsPerformance.opened}
                dataAreaLine={totalCampaignsPerformance.openRateChartModel}
                trend={totalCampaignsPerformance.openRateTrend}
                globalRate={props.openRate}
                openRate={props.globalAverageRateOpen}
              />
            ) : (
              <RateWidget
                width={60}
                height={30}
                classRate="openRate"
                name={"Unique Open Rate"}
                tooltipContent={
                  <>
                    Number of campaign engagements that opened at least one email in your campaign(s), shown as percentage
                    <br />
                    <b>Formula:</b> Unique opens/total engagements
                  </>
                }
                rate={totalCampaignsPerformance.uniqueOpenRate}
                totalNumber={totalCampaignsPerformance.uniqueOpened}
                dataAreaLine={totalCampaignsPerformance.uniqueOpenRateChartModel}
                trend={totalCampaignsPerformance.uniqueOpenRateTrend}
                globalRate={props.uniqueOpenRate}
                openRate={props.globalAverageUniqueRateOpen}
              />
            )}
          </div>
          <div className={styles.widgetContentWrapper}>
            {uniqueToggle === 0 ? (
              <RateWidget
                width={60}
                height={30}
                classRate="clickRate"
                name={"Total Click Rate"}
                tooltipContent={
                  <>
                    Number of emails clicked, shown as percentage - excludes unsubscribe link
                    <br />
                    <b>Formula:</b> Links clicked/emails delivered
                  </>
                }
                rate={totalCampaignsPerformance.clickRate}
                totalNumber={totalCampaignsPerformance.clicked}
                dataAreaLine={totalCampaignsPerformance.clickRateChartModel}
                trend={totalCampaignsPerformance.clickRateTrend}
                globalRate={props.clickRate}
                clickRate={props.globalAverageRateClick}
              />
            ) : (
              <RateWidget
                width={60}
                height={30}
                classRate="clickRate"
                name={"Unique Click Rate"}
                tooltipContent={
                  <>
                    Number of campaign engagements that clicked on at least one link in your email(s), shown as percentage
                    <br />
                    <b>Formula:</b> Unique clicks/total engagements
                  </>
                }
                rate={totalCampaignsPerformance.uniqueClickRate}
                totalNumber={totalCampaignsPerformance.uniqueClicked}
                dataAreaLine={totalCampaignsPerformance.uniqueClickRateChartModel}
                trend={totalCampaignsPerformance.uniqueClickRateTrend}
                globalRate={props.uniqueClickRate}
                clickRate={props.globalAverageUniqueRateClick}
              />
            )}
          </div>
          <div className={styles.widgetContentWrapper}>
            <RateWidget
              width={60}
              height={30}
              classRate="responseRate"
              name={"Response Rate"}
              tooltipContent={
                <>
                  Percentage of engagements that have replied to your email(s), including leads and decline
                  <br />
                  <b>Formula:</b> (leads + declines)/total engagements
                </>
              }
              rate={totalCampaignsPerformance.responseRate}
              totalNumber={totalCampaignsPerformance.responded}
              dataAreaLine={totalCampaignsPerformance.responseRateChartModel}
              trend={totalCampaignsPerformance.responseRateTrend}
              globalRate={props.responseRate}
              responsesRate={props.globalAverageRateResponse}
            />
          </div>
          <div className={styles.widgetContentWrapper}>
            <RateWidget
              width={60}
              height={30}
              classRate="leadRate"
              name={"Lead Rate"}
              tooltipContent={
                <>
                  Percentage of prospects that have become leads
                  <br />
                  <b>Formula:</b> leads/total engagements
                </>
              }
              rate={totalCampaignsPerformance.leadsRate}
              totalNumber={totalCampaignsPerformance.lead}
              dataAreaLine={totalCampaignsPerformance.leadRateChartModel}
              trend={totalCampaignsPerformance.leadsRateTrend}
              globalRate={props.leadRate}
              leadsRate={props.globalAverageRateLead}
            />
          </div>
        </div>
      </>
    );
  }

  return <>{content}</>;
};

const mapStateToProps = (state) => {
  return {
    isTotalCampaignsPerformanceLoading: state.dashboard.isTotalCampaignsPerformanceLoading,
    isTotalCampaignsPerformanceLoaded: state.dashboard.isTotalCampaignsPerformanceLoaded,
    errorTotalCampaignsPerformance: state.dashboard.errorTotalCampaignsPerformance,
    totalCampaignsPerformance: state.dashboard.totalCampaignsPerformance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTotalCampaignsPerformance: (startDate, endDate) => dispatch(fetchTotalCampaignsPerformance(startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCampaignPerformance);
