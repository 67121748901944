import React from "react";

//Components
import Modal from "../../components/UI/Modal/Modal";

//Styles
import styles from "./ExportProspectsWarningForm.module.scss";

const ExportProspectsWarningForm = (props) => {
  props.setExportButtonDisabled(false);
  return (
    <Modal
      dialogClassName="customModal"
      title={"Export Prospects"}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText={"Cancel"}
      saveButtonText="Ok"
      btnType="submit"
      size="md"
    >
      <>
        <p className={styles.textWrapper}>
          You cannot export more than 50,000 prospects. Narrow down your search results to be able to export a specific Prospects' list.{" "}
        </p>
      </>
    </Modal>
  );
};

export default ExportProspectsWarningForm;
