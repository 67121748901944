import React, { useEffect, useState } from "react";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import { Formik, Form } from "formik";
import _ from "lodash";
import * as Yup from "yup";

//Redux

//Helpers
import { useOrgNumberFormatting } from "../../helper/hooks/useOrgNumberFormatting";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { useOrganizationId } from "../../helper/hooks/useOrganizationId";
import { onAudienceExplorerFormChange } from "./helpers/audienceExplorerHelperMethods";

//Styles
import styles from "./AudiencesExplorer.module.scss";

//Images & Icons
import NewAudienceIcon from "../../assets/Icons/NewAudienceIcon/NewAudienceIcon";

//Components
import Button from "../../components/UI/Button/Button";
import Filters from "./components/Filters/Filters";
import CreateAudienceForm from "./CreateAudienceForm";
import Companies from "./components/Companies/Companies";
import People from "./components/People/People";
import FormikEffect from "../../components/UI/Formik/FormikEffect";
import Permission from "../../components/auth/Permission";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import EditAudienceForm from "./EditAudienceForm";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import { locationFilterTypeEnum } from "@helper/enums/audienceBuilderActiveLocationFilterEnum";
import { useGetSearchQuery, useSaveSearchMutation } from "@api/audienceExplorerApi";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useGetAudienceDetailsQuery } from "@api/audienceApi";
import { useSlice } from "@hooks/useSlice";
import { ViewMode } from "@store/AudiencesExplorer/audienceExplorerSlice";
import { CompanyRevenueGrowthTimeframeType, NaicsAndSicCodesType, SearchModel } from "./helpers/SearchModel";

interface RouteParams {
  id?: string;
  camId?: string;
}

const AudiencesExplorer = () => {
  //constants
  const organizationId = useOrganizationId();

  const history = useHistory();
  const location = useLocation();

  const audienceExplorerSlice = useSlice((state) => state.explorer);
  const { peopleCount, viewMode } = audienceExplorerSlice;
  const peopleCountFormatted = useOrgNumberFormatting(peopleCount);

  const queryParams = new URLSearchParams(location.search);
  const searchIdFromParams = queryParams.get("s");

  const { id: audienceId, camId: campaignId } = useParams<RouteParams>();

  const [searchId, setSearchId] = useState<string | null>(null);
  const searchQuery = useGetSearchQuery(searchId ?? "", { skip: !searchId, refetchOnMountOrArgChange: true });
  const [saveSearch, { data: saveSearchId }] = useSaveSearchMutation();

  const defaultSearchModel: SearchModel = {
    version: 1,
    searchId: searchId || "",
    company: {
      names: [],
      keywords: [],
      sizes: [],
      industries: [],
      domains: [],
      naicsAndSicCodes: {
        type: NaicsAndSicCodesType.Naics,
        naicsCodes: [],
        sicCodes: [],
      },
      technologies: [],
      revenue: [],
      location: {
        type: locationFilterTypeEnum.Region,
        region: { locations: [] },
        range: {
          location: null,
          rangeMiles: -1, // 0 is used for "Specify range"
        },
      },
      companyHeadcountGrowth: {
        min: null,
        max: null,
        timeframe: null,
      },
      companyFunding: {
        min: null,
        max: null,
        lastFundingRoundType: [],
        dateFrom: null,
        dateTo: null,
      },
      companyFoundedYear: {
        dateFrom: null,
        dateTo: null,
      },
    },
    contact: {
      names: [],
      jobTitles: [],
      departments: [],
      seniorities: [],
      location: {
        type: locationFilterTypeEnum.Region,
        region: { locations: [] },
        range: {
          location: null,
          rangeMiles: -1, // 0 is used for "Specify range"
        },
      },
    },
  };

  const peopleViewModeStyles = {
    [ViewMode.Contacts]: styles.peopleSectionFullyOpen,
    [ViewMode.Companies]: styles.peopleSectionClosed,
    [ViewMode.Split]: styles.peopleSection,
  };
  const peopleClass = peopleViewModeStyles[viewMode];

  const companiesViewModeStyles = {
    [ViewMode.Contacts]: styles.companiesSectionClosed,
    [ViewMode.Companies]: styles.companiesSectionFullyOpen,
    [ViewMode.Split]: styles.companiesSection,
  };
  const companiesClass = companiesViewModeStyles[viewMode];

  const [filterOptions, setFilterOptions] = useState(null);
  const [filtersCount, setFiltersCount] = useState(0);

  const [showCreateAudienceFormModal, setShowCreateAudienceFormModal] = useState(false);
  const [showEditAudienceFormModal, setShowEditAudienceFormModal] = useState(false);

  const { data: audience } = useGetAudienceDetailsQuery(audienceId ? Number(audienceId) : 0, { skip: audienceId == null });

  useEffect(() => {
    if (saveSearchId) {
      setSearchId(saveSearchId);
    }
  }, [saveSearchId]);

  useEffect(() => {
    if (searchQuery && searchQuery.data) {
      onAudienceExplorerFormChange(searchQuery.data, setFiltersCount);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchIdFromParams && searchIdFromParams !== "") {
      setSearchId(searchIdFromParams);
    }
  }, [searchIdFromParams]);

  useEffect(() => {
    if (searchId && searchId !== null && searchId !== "") {
      const params = new URLSearchParams();
      params.append("s", searchId);

      if (audienceId != null) {
        params.append("id", audienceId);
      }

      if (campaignId != null) {
        params.append("camId", campaignId);
      }

      history.replace(`${location.pathname}?${params.toString()}`);
    }
  }, [searchId, audienceId, campaignId]);

  const isButtonDisabled = peopleCount <= 0 || audienceExplorerSlice.excludedContacts.length === peopleCount;
  const emailSourcedFormatted = useOrgNumberFormatting(Math.round(peopleCount * 0.7));
  const approxAvailableContactsFormatted = useOrgNumberFormatting(Math.round(peopleCount * 0.7));

  const handleCreateAudienceFormClose = () => {
    setShowCreateAudienceFormModal(false);
    setShowEditAudienceFormModal(false);
  };
  const handleCreateAudienceFormShow = () => {
    if (audienceId != null) {
      setShowEditAudienceFormModal(true);
    } else setShowCreateAudienceFormModal(true);
  };

  const icon = (
    <InfoIconTooltip
      tooltipBody="Find and target the ideal list for your campaign, based on company and contact criteria."
      href={"https://help.outbase.com/user-guide/data-and-targeting/audience-builder"}
      helpText="Need help defining list?"
      linkText="Read More"
      buttonId="startCampaignTour"
      to={"/" + organizationId + "/search/?tour=true"}
    />
  );

  const tooltipBody = (
    <>
      <span>Email find rates vary based on several factors:</span>
      <ul className={styles.audience_contacts_available_list}>
        <li>
          <span className={styles.listLabel}>Total contacts: </span>
          <span>{peopleCountFormatted}</span>
        </li>
        <li>
          <span className={styles.listLabel}>Email sourced (≈70%): </span>
          <span>≈ {emailSourcedFormatted}</span>
        </li>
        <li className={styles.approx_item}>
          <span className={styles.listLabel}>Approx. contact available: </span>
          <span>≈ {approxAvailableContactsFormatted}</span>
        </li>
      </ul>
    </>
  );

  const onKeyDown = (keyEvent: React.KeyboardEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  let audiencesExplorer = null;
  let createAudienceForm = null;
  let editAudienceForm = null;

  const clearSearchId = () => {
    const url = new URL(location.pathname, window.location.origin);
    const params = new URLSearchParams(url.search);

    params.delete("s");

    url.search = params.toString();

    history.replace(url.pathname + url.search);
  };

  // if (searchQuery.isLoading) return <Spinner />;

  // Load only if searchId is missing (empty form) or model is loaded
  // if (!searchId || searchQuery.data) {
  const initialValues: SearchModel = searchQuery?.data ? searchQuery.data : defaultSearchModel;

  audiencesExplorer = (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object({
        company: Yup.object({
          companyHeadcountGrowth: Yup.lazy((values) => {
            if (!values.min && !values.max && !values.timeframe) {
              return Yup.mixed().notRequired();
            }
            let schema = Yup.object().shape({
              min: Yup.string()
                .required("")
                .test("is-greater-than-zero", "Ensure min is less than max", (value, context) => {
                  const numberValue = Number(value);
                  const maxValue = Number(context.parent.max);
                  return numberValue < maxValue;
                }),
              max: Yup.string()
                .required(" ")
                .test("is-greater-than-zero", "Ensure max is greater than min", (value, context) => {
                  const numberValue = Number(value);
                  const minValue = Number(context.parent.min);
                  return numberValue > minValue;
                }),
              timeframe: Yup.number().required("Please select a timeframe").moreThan(0, "Please select a timeframe"),
            });

            return schema;
          }),
          companyFunding: Yup.lazy((values) => {
            if (values.min && values.max) {
              let schema = Yup.object().shape({
                min: Yup.number().required("").lessThan(Yup.ref("max"), "Please ensure that min is less than max."),
                max: Yup.number().required(" ").moreThan(Yup.ref("min"), " "),
              });

              return schema;
            } else if (values.dateFrom && values.dateTo) {
              let schema = Yup.object().shape({
                dateFrom: Yup.date().required("").max(Yup.ref("dateTo"), "Please ensure that date from is smaller than date to."),
                dateTo: Yup.date().required(" ").min(Yup.ref("dateFrom"), " "),
              });

              return schema;
            } else {
              return Yup.mixed().notRequired();
            }
          }),
          companyFoundedYear: Yup.lazy((values) => {
            if (values.dateFrom && values.dateTo) {
              let schema = Yup.object().shape({
                dateFrom: Yup.date().required("").max(Yup.ref("dateTo"), "Please ensure that date from is smaller than date to."),
                dateTo: Yup.date().required(" ").min(Yup.ref("dateFrom"), " "),
              });

              return schema;
            } else {
              return Yup.mixed().notRequired();
            }
          }),
        }),
      })}
      onSubmit={(values, { setSubmitting }) => {
        event.preventDefault();
        if (filtersCount > 0) {
          clearSearchId();
          saveSearch(values);
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, dirty, resetForm } = formikProps;

        const resetFormOverride = () => {
          clearSearchId();
          setFiltersCount(0);
          resetForm({ values: defaultSearchModel });
        };

        return (
          <>
            <FormikEffect onChange={() => onAudienceExplorerFormChange(values, setFiltersCount)} />
            <Form onKeyDown={onKeyDown} className="height_100">
              <PageContentWrapper noPadding className="contentHeight">
                <HeaderTitle
                  title={audience ? `Search contacts: ${audience.name}` : "Search"}
                  icon={icon}
                  className={styles.sectionTitle}
                  secondTitle={
                    <div className="flex align-center">
                      {peopleCount > 0 ? (
                        <div className={styles.contactsWrapper}>
                          <label className={styles.totalPeopleCount}>
                            Approx. Available Contacts: <span> ≈{approxAvailableContactsFormatted}</span>
                          </label>
                          <span className={styles.tooltipContainer}>
                            <InfoIconTooltip
                              tooltipClass={styles.approximateTooltip}
                              disableStartTour
                              tooltipBody={tooltipBody}
                              id="approxAvailableContacts"
                            />
                          </span>
                        </div>
                      ) : null}
                      <Permission has={PERMISSIONS.audience.create}>
                        <Button
                          id="createAudience"
                          disabled={isButtonDisabled}
                          variant="primary"
                          onClick={(event) => {
                            event.preventDefault();
                            handleCreateAudienceFormShow();
                          }}
                        >
                          <NewAudienceIcon imgStyle="mR5" /> Save List
                        </Button>
                      </Permission>
                    </div>
                  }
                />

                <div className={`${styles.audiencesExplorer} height_100`}>
                  <div className={styles.filtersSection}>
                    {
                      // showOptionsResetSpinner ? (
                      //   <Spinner />
                      // ) :
                      <>
                        <Filters filtersIncluded={filtersCount} resetForm={resetFormOverride} />

                        <div className={styles.filterFooter}>
                          {audienceId && (
                            <Button
                              id="cancel"
                              onClick={() => {
                                history.push("/" + organizationId + "/lists");
                              }}
                              type={"button"}
                              variant="secondary-s"
                              classes={`min_width_auto ${styles.cancelBtn}`}
                            >
                              Cancel
                            </Button>
                          )}
                          <Button
                            id="applyFilters"
                            disabled={!dirty}
                            onClick={() => {
                              handleSubmit();
                            }}
                            type={"button"}
                            variant="primary-s"
                            classes="min_width_auto"
                          >
                            Search
                          </Button>
                        </div>
                      </>
                    }
                  </div>
                  <div className={companiesClass} id="companiesComponent">
                    <Companies />
                  </div>
                  <div className={peopleClass} id="contactsComponent">
                    <People />
                  </div>
                </div>
              </PageContentWrapper>
            </Form>
          </>
        );
      }}
    </Formik>
  );

  createAudienceForm = (
    <CreateAudienceForm
      handleClose={handleCreateAudienceFormClose}
      showModal={showCreateAudienceFormModal}
      peopleCount={_.cloneDeep(peopleCount)}
      orgId={organizationId}
      searchId={searchId}
      setSearchId={setSearchId}
      setFilterOptions={setFilterOptions}
    />
  );
  editAudienceForm =
    audience != null && audience.campaignIds != null ? (
      <EditAudienceForm
        handleClose={handleCreateAudienceFormClose}
        showModal={showEditAudienceFormModal}
        peopleCount={_.cloneDeep(peopleCount)}
        audienceName={audience?.name}
        audienceId={audienceId}
        campaignIds={audience.campaignIds}
        orgId={organizationId}
        searchId={searchId}
        setSearchId={setSearchId}
        setFilterOptions={setFilterOptions}
      />
    ) : null;

  return (
    <>
      {audiencesExplorer}
      {createAudienceForm}
      {editAudienceForm}
    </>
  );
};

export default AudiencesExplorer;
