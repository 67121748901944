import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Cell } from "styled-css-grid";
import { Accordion } from "react-bootstrap";

//Redux
import {
  removeDealNavigatorStages,
  addDealNavigatorCards,
  reorderDealNavigatorStage,
} from "../../../../store/DealNavigator/actions/dealNavigator";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Style
import styles from "./Stage.module.scss";

//Images & Icons
import AddLineIcon from "remixicon-react/AddLineIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import ArrowUpLineIcon from "remixicon-react/ArrowUpLineIcon";
import ArrowDownLineIcon from "remixicon-react/ArrowDownLineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import StagePic from "../../../../assets/DealNavigator/StagePic";

//Components
import Card from "../Cards/Card";
import Button from "../../../../components/UI/Button/Button";
import EditStageForm from "./EditStageForm";
import Permission from "../../../../components/auth/Permission";

const Stage = (stage) => {
  const { id, cards, title, order, organizationId, edit, isAddingCard } = stage;
  const [open, setOpen] = useState(false);

  let lastCardOrder = 0;

  if (cards.length > 0) {
    lastCardOrder = cards[cards.length - 1].order + 1;
  }

  const [showEditStageModal, setShowEditStageModal] = useState(false);

  const handleEditStageClose = () => setShowEditStageModal(false);
  const handleEditStageShow = () => setShowEditStageModal(true);

  //#region functions
  const addCard = (e) => {
    let newCardOrder = null;
    let newCardPosition = 1;
    let newCardOffset = 0;
    let newCardElements = [];
    let card = {};

    if (cards.length > 0) {
      let lastCard = cards[cards.length - 1];
      newCardOrder = lastCard.order + 1;
      if (lastCard.position === 1) {
        newCardPosition = 3;
      }
    } else {
      newCardOrder = 1;
    }

    card = {
      stageId: id,
      order: newCardOrder,
      position: newCardPosition,
      offset: newCardOffset,
      elements: newCardElements,
    };

    stage.addDealNavigatorCards(card);
  };

  const moveStageUp = (stage) => (e) => {
    const newStage = {
      organizationId: organizationId,
      id: id,
      order: order - 1,
    };

    const oldStage = {
      organizationId: organizationId,
      id: id,
      order: order,
    };

    stage.reorderDealNavigatorStage(newStage, oldStage);
  };

  const moveStageDown = (stage) => (e) => {
    let newStage = {
      organizationId: organizationId,
      id: id,
      order: order + 1,
    };

    const oldStage = {
      organizationId: organizationId,
      id: id,
      order: order,
    };

    stage.reorderDealNavigatorStage(newStage, oldStage);
  };

  const removeStage = (e) => {
    stage
      .removeDealNavigatorStages(id)
      .then((res) => {
        stage.adjustScale();
      })
      .catch((err) => err);
  };
  //#endregion

  return (
    <>
      <Accordion.Item eventKey={id} className={styles.PitchsWrapper}>
        <div className={styles.pitchLevelImg}>
          <div onClick={() => setOpen(!open)} className={` ${styles.StyledAccordionToggle}`}>
            <StagePic />
            <span className={`${styles.SpanPitchLabel}`}>{title}</span>
          </div>
          {edit && (
            <Permission has={PERMISSIONS.dealNavigator.edit}>
              <div className={`${styles.editStageButtons} custmPosition absolute`}>
                <button className={`${styles.stage_edit_btn} flex_center btn btn-sm`} onClick={() => handleEditStageShow()}>
                  <Edit2LineIcon className="iconGray" size={18} />
                </button>
                <button className={`${styles.stage_edit_btn} flex_center btn btn-sm`} onClick={moveStageUp(stage)}>
                  <ArrowUpLineIcon className="iconGray" size={18} />
                </button>
                <button className={`${styles.stage_edit_btn} flex_center btn btn-sm`} onClick={moveStageDown(stage)}>
                  <ArrowDownLineIcon className="iconGray" size={18} />
                </button>
                <button className={`${styles.stage_edit_btn} flex_center btn stage_btn_dng btn-sm`} onClick={removeStage}>
                  <DeleteBin6LineIcon className="iconGray" size={18} />
                </button>
              </div>
            </Permission>
          )}
        </div>
        <Accordion.Collapse in={open} className={styles.StyledAccordionCollapse} eventKey={id}>
          <>
            <Grid columns="40% 10% 40%" alignContent="center" justifyContent="center" style={{ gridGap: "0" }}>
              {cards.map((card) => (
                <Card key={card.id} {...card} />
              ))}
              <Cell
                style={{
                  gridGap: "0",
                  paddingTop: "7px",
                  paddingBottom: "10px",
                }}
                left={2}
                top={lastCardOrder}
                center
                middle
              >
                <div>
                  {edit && (
                    <Permission has={PERMISSIONS.dealNavigator.edit}>
                      <Button
                        id="addStageButton"
                        variant="rounded-s"
                        disabled={isAddingCard}
                        classes={styles.addCardButton}
                        onClick={addCard}
                      >
                        <AddLineIcon size={20} />
                      </Button>
                    </Permission>
                  )}
                </div>
              </Cell>
            </Grid>
          </>
        </Accordion.Collapse>
      </Accordion.Item>

      {edit && (
        <Permission has={PERMISSIONS.dealNavigator.edit}>
          <EditStageForm handleClose={handleEditStageClose} showModal={showEditStageModal} stageTitle={title} stageId={id} />
        </Permission>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.user.organization.id,
    edit: state.dealNavigator.isInEditMode,
    isAddingCard: state.dealNavigator.isAddingCard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeDealNavigatorStages: (id) => dispatch(removeDealNavigatorStages(id)),
    addDealNavigatorCards: (card) => dispatch(addDealNavigatorCards(card)),
    reorderDealNavigatorStage: (newStage, oldStage) => dispatch(reorderDealNavigatorStage(newStage, oldStage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stage);
