import api, { API } from "@helper/api/api";
import { errorResponseToList } from "./errorHelper";
import { toast } from "react-toastify";

export const addEmailAccountContinue = (typeId, campaignId, organizationId) => {
  if (typeId === "SmtpImap") {
    window.location = "/" + organizationId + "/email-accounts/" + typeId + "/create?campaignId=" + campaignId + "&callback=true";
  } else {
    api(API.emailAccounts.createThirdParty, {
      messagesPerDay: 500,
      averageDelay: 180,
      emailAccountType: typeId,
      campaignConfigCallback: true,
      campaignId: campaignId,
    })
      .then((response) => {
        if (response.data && response.data.success) {
          if (response.data.redirectUrl) {
            window.location = response.data.redirectUrl;
          }
        } else {
          toast.error(response.data.errorMessage);
        }
      })
      .catch((error) => {
        const errorList = errorResponseToList(error);
        if (errorList.length > 0) {
          errorList.forEach((err) => toast.error(err.message));
        } else {
          toast.error(error.message);
        }
      });
  }
};
