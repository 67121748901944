import React from "react";
import { Formik, Field, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";

//Redux
import {
  changeSupportCenterExternalServiceSuccess,
  changeSupportCenterExternalServiceFailed,
} from "../../../store/ComplianceSupportCenter/actions/supportCenterQA";

//Helpers
import api, { API } from "../../../helper/api/api";
import { errorResponseToList } from "../../../helper/errorHelper";
import { PERMISSIONS } from "../../../helper/permissionConstants";

//Styles
import styles from "./UnsubscribeSettings.module.scss";

//Components
import AntSwitch from "../../../components/UI/AntSwitch/Switch";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import Permission from "../../../components/auth/Permission";
import Button from "../../../components/UI/Button/Button";

const ExternalUnsubscribeService = (props) => {
  return (
    <Formik
      initialValues={{
        unsubscribeExternalService: props.unsubscribeExternalService,
        unsubscribeExternalUrl: props.unsubscribeExternalUrl,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        unsubscribeExternalService: Yup.boolean(),
        unsubscribeExternalUrl: Yup.string().url().required("Required"),
      }).required("Required")}
      onSubmit={(values, actions) => {
        api(API.complianceSupportCenter.updateUpdateSettingsExternalSeervice, values)
          .then((response) => {
            props.changeSupportCenterExternalServiceSuccess(values);
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              props.changeSupportCenterExternalServiceFailed(error);
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, handleChange, values } = formikProps;

        return (
          <>
            <div className={styles.unsubscribeExternalService}>
              <span className="f14 font500 secondary_text_color">
                If you already have an unsubscribe URL that you use to keep your Organization's unsubscribe list up to date, copy and paste
                the URL below and Outbase will automatically send any opt-out requests to this via HTTP.
              </span>
              <Form className={styles.switchButtonWrapper}>
                <Grid component="label" container alignItems="center" spacing={1} className={styles.buttonLabel}>
                  <Grid item className="f14 font600 secondary_text_color">
                    Disable
                  </Grid>
                  <Grid item>
                    <AntSwitch
                      checked={values.unsubscribeExternalService === true ? true : false}
                      onChange={handleChange}
                      name="unsubscribeExternalService"
                    />
                  </Grid>
                  <Grid item className="f14 font600 secondary_text_color">
                    Enable
                  </Grid>
                </Grid>
                <div className={`${styles.horizontalFormInput} flex`}>
                  <div className="width_400">
                    <label className="f14 font600 secondary_text_color mB5">URL: </label>
                    <Field
                      name="unsubscribeExternalUrl"
                      type="url"
                      value={_.isEmpty(values.unsubscribeExternalUrl) ? "" : values.unsubscribeExternalUrl}
                      onChange={handleChange}
                      placeholder="Your URL"
                      as={FormikInput}
                      disabled={values.unsubscribeExternalService === true ? false : true}
                    />
                  </div>
                  <span className="f14 font600 secondary_text_color">
                    Your final URL will look like this:{" "}
                    <span className="fontNormal f14">
                      {values.unsubscribeExternalService === true ? values.unsubscribeExternalUrl : ""}
                    </span>
                  </span>
                </div>
                <Permission has={PERMISSIONS.compliance.unsubscribeSettings.edit}>
                  <Button id="saveChanges" disabled={isSubmitting} type="submit" onClick={handleSubmit} variant="primary">
                    Save changes
                  </Button>
                </Permission>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSupportCenterExternalServiceSuccess: (externalServiceModel) =>
      dispatch(changeSupportCenterExternalServiceSuccess(externalServiceModel)),
    changeSupportCenterExternalServiceFailed: (error) => dispatch(changeSupportCenterExternalServiceFailed(error)),
  };
};

export default connect(null, mapDispatchToProps)(ExternalUnsubscribeService);
