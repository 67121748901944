import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//Styles
import styles from "./Steps.module.scss";

//Components
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";

const SecondStep = (props) => {
  const csvFileColumns = props.formikProps.values.csvFileColumns;
  const optionalCSVFileColumns = [{ label: "None", value: "" }, ...props.formikProps.values.csvFileColumns];
  const requiredFieldNames = ["Email Address"];
  const optionalFieldNames = [
    "First Name",
    "Last Name",
    "Job Title",
    "Contact Country",
    "Company",
    "Company Website",
    "Company Industry",
    "Company Size",
  ];

  const trimField = (field) => {
    var fieldTrimmed = field.replace(" ", "");
    return fieldTrimmed.charAt(0).toLowerCase() + fieldTrimmed.slice(1);
  };

  const adjustFieldForValidation = (field) => {
    return "csvMappedColumns." + trimField(field);
  };

  const adjustMappedFieldsDictionary = (field, value) => {
    var fieldsMatchedDictionary = props.fieldsMatchedDictionary;
    var fieldIsFound = false;

    fieldsMatchedDictionary.map((mappedField) => {
      if (mappedField.key === field) {
        fieldsMatchedDictionary[mappedField.key] = value;
        fieldIsFound = true;
      }
    });

    if (fieldIsFound === false) {
      fieldsMatchedDictionary = [
        ...fieldsMatchedDictionary,
        {
          key: field,
          value: value,
        },
      ];
    }

    props.setFieldsMatchedDictionary(fieldsMatchedDictionary);
  };

  const getColumnValueFromKeyWord = (keyword) => {
    let columnValue = "";
    if (csvFileColumns.some((column) => column.value.toLowerCase() === keyword.toLowerCase())) {
      csvFileColumns.map((column) => {
        if (column.value.toLowerCase() === keyword.toLowerCase()) columnValue = column.value;
      });
    }
    return columnValue;
  };

  const matchFieldIfPossible = (field, shouldUpdateFormValues = false) => {
    var result = "";
    var trimmedField = field.replace(" ", "");
    var fieldMatched = false;
    var fieldMatchedFromDictionary = false;

    csvFileColumns.map((column) => {
      props.fieldsMatchedDictionary.map((mappedField) => {
        if (mappedField.key === field) {
          result = mappedField.value;
          fieldMatchedFromDictionary = true;
        }
      });

      if (fieldMatchedFromDictionary === false) {
        if (column.value.replace(" ", "").toLowerCase() === trimmedField.toLowerCase()) {
          result = column.value;
          if (shouldUpdateFormValues) {
            let fileName = adjustFieldForValidation(field);
            props.formikProps.setFieldValue(fileName, result !== "" ? result : null);
          }
          fieldMatched = true;
        }

        if (fieldMatched === false && column.value.replace(" ", "").toLowerCase().indexOf(trimmedField.toLowerCase()) !== -1) {
          result = column.value;
          if (shouldUpdateFormValues) {
            let fileName = adjustFieldForValidation(field);
            props.formikProps.setFieldValue(fileName, result !== "" ? result : null);
          }
          fieldMatched = true;
        }
      }
    });

    if (fieldMatched === false) {
      switch (field) {
        case "Email Address":
          let eaValue = getColumnValueFromKeyWord("email");
          if (eaValue !== "") {
            if (shouldUpdateFormValues) {
              let fileName = adjustFieldForValidation(field);
              props.formikProps.setFieldValue(fileName, eaValue);
            }
            result = eaValue;
            fieldMatched = true;
          }
          break;
        case "Job Title":
          let tValue = getColumnValueFromKeyWord("title");
          if (tValue !== "") {
            if (shouldUpdateFormValues) {
              let fileName = adjustFieldForValidation(field);
              props.formikProps.setFieldValue(fileName, tValue);
            }
            result = tValue;
            fieldMatched = true;
          }
          break;
        case "Company":
          let cValue = getColumnValueFromKeyWord("companyname");
          if (cValue !== "") {
            if (shouldUpdateFormValues) {
              let fileName = adjustFieldForValidation(field);
              props.formikProps.setFieldValue(fileName, cValue);
            }
            result = cValue;
            fieldMatched = true;
          }
          break;
        case "Company Website":
          let cwValue = getColumnValueFromKeyWord("domain");
          if (cwValue === "") cwValue = getColumnValueFromKeyWord("companyDomain");
          if (cwValue !== "") {
            if (shouldUpdateFormValues) {
              let fileName = adjustFieldForValidation(field);
              props.formikProps.setFieldValue(fileName, cwValue);
            }
            result = cwValue;
            fieldMatched = true;
          }
          break;
        case "Company Industry":
          let ciValue = getColumnValueFromKeyWord("industry");
          if (ciValue !== "") {
            if (shouldUpdateFormValues) {
              let fileName = adjustFieldForValidation(field);
              props.formikProps.setFieldValue(fileName, ciValue);
            }
            result = ciValue;
            fieldMatched = true;
          }
          break;
        case "Contact Country":
          let ccValue = getColumnValueFromKeyWord("country");
          if (ccValue === "") ccValue = getColumnValueFromKeyWord("location");
          if (ccValue !== "") {
            if (shouldUpdateFormValues) {
              let fileName = adjustFieldForValidation(field);
              props.formikProps.setFieldValue(fileName, ccValue);
            }
            result = ccValue;
            fieldMatched = true;
          }
          break;
        default:
          break;
      }
    }

    if (!shouldUpdateFormValues) return result;
  };

  const matchFieldsOnStart = () => {
    if (!props.fieldsMatched) {
      requiredFieldNames.map((field) => {
        matchFieldIfPossible(field, true);
      });

      optionalFieldNames.map((field) => {
        matchFieldIfPossible(field, true);
      });
      props.setFieldsMatched(true);
    }
  };

  matchFieldsOnStart();

  return (
    <Container>
      <Row>
        <Col xl={12}>
          <p>Map Outbase contact fields with your custom fields:</p>
        </Col>
      </Row>

      <Row className={styles.mappingFieldsHeader}>
        <Col xl={5}>
          <p>Outbase fields</p>
        </Col>
        <Col xl={7}>
          <p>File fields</p>
        </Col>
      </Row>

      {requiredFieldNames.map((field, index) => {
        return (
          <Row>
            <Col xl={5}>
              <p className={styles.secondStepP}>
                {field}
                {index < 6 ? <span className={styles.requiredField}>*</span> : null}
              </p>
            </Col>
            <Col xl={7}>
              <div className={styles.selectDiv}>
                <FormikSelect
                  showBorder={true}
                  name={adjustFieldForValidation(field)}
                  value={props.formikProps.values.firstName}
                  placeholder={matchFieldIfPossible(field)}
                  onChange={(event) => {
                    adjustMappedFieldsDictionary(field, event);
                    props.formikProps.setFieldValue(adjustFieldForValidation(field), event);
                  }}
                  options={csvFileColumns != null ? csvFileColumns : null}
                  isSearchable={true}
                  formgroupClass={styles.fromGroupClass}
                />
              </div>
            </Col>
          </Row>
        );
      })}

      {optionalFieldNames.map((field, index) => {
        return (
          <Row>
            <Col xl={5}>
              <p className={styles.secondStepP}>{field}</p>
            </Col>
            <Col xl={7}>
              <div className={styles.selectDiv}>
                <FormikSelect
                  showBorder={true}
                  name={adjustFieldForValidation(field)}
                  value={props.formikProps.values.firstName}
                  placeholder={matchFieldIfPossible(field)}
                  isMenuPortalTargetDisabled={true}
                  onChange={(event) => {
                    adjustMappedFieldsDictionary(field, event);
                    props.formikProps.setFieldValue(adjustFieldForValidation(field), event);
                  }}
                  options={optionalCSVFileColumns != null ? optionalCSVFileColumns : null}
                  isSearchable={true}
                  formgroupClass={styles.fromGroupClass}
                />
              </div>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default SecondStep;
