// #region FETCH
export const FETCH_DEAL_NAVIGATOR_REQUEST = "FETCH_DEAL_NAVIGATOR_REQUEST";
export const FETCH_DEAL_NAVIGATOR_SUCCEEDED = "FETCH_DEAL_NAVIGATOR_SUCCEEDED";
export const FETCH_DEAL_NAVIGATOR_FAILED = "FETCH_DEAL_NAVIGATOR_FAILED";

export const FETCH_DEAL_NAVIGATOR_STAGES_REQUEST =
  "FETCH_DEAL_NAVIGATOR_STAGES_REQUEST";
export const FETCH_DEAL_NAVIGATOR_STAGES_SUCCEEDED =
  "FETCH_DEAL_NAVIGATOR_STAGES_SUCCEEDED";
export const FETCH_DEAL_NAVIGATOR_STAGES_FAILED =
  "FETCH_DEAL_NAVIGATOR_STAGES_FAILED";

export const FETCH_DEAL_NAVIGATOR_CARDS_REQUEST =
  "FETCH_DEAL_NAVIGATOR_CARDS_REQUEST";
export const FETCH_DEAL_NAVIGATOR_CARDS_SUCCEEDED =
  "FETCH_DEAL_NAVIGATOR_CARDS_SUCCEEDED";
export const FETCH_DEAL_NAVIGATOR_CARDS_FAILED =
  "FETCH_DEAL_NAVIGATOR_CARDS_FAILED";

export const FETCH_DEAL_NAVIGATOR_ELEMENTS_REQUEST =
  "FETCH_DEAL_NAVIGATOR_ELEMENTS_REQUEST";
export const FETCH_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED =
  "FETCH_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED";
export const FETCH_DEAL_NAVIGATOR_ELEMENTS_FAILED =
  "FETCH_DEAL_NAVIGATOR_ELEMENTS_FAILED";

export const SET_EDIT_MODE_DEAL_NAVIGATOR = "SET_EDIT_MODE_DEAL_NAVIGATOR";

// #endregion

// #region ADD
export const ADD_DEAL_NAVIGATOR_STAGES_REQUEST =
  "ADD_DEAL_NAVIGATOR_STAGES_REQUEST";
export const ADD_DEAL_NAVIGATOR_STAGES_SUCCEEDED =
  "ADD_DEAL_NAVIGATOR_STAGES_SUCCEEDED";
export const ADD_DEAL_NAVIGATOR_STAGES_FAILED =
  "ADD_DEAL_NAVIGATOR_STAGES_FAILED";

export const ADD_DEAL_NAVIGATOR_CARDS_REQUEST =
  "ADD_DEAL_NAVIGATOR_CARDS_REQUEST";
export const ADD_DEAL_NAVIGATOR_CARDS_SUCCEEDED =
  "ADD_DEAL_NAVIGATOR_CARDS_SUCCEEDED";
export const ADD_DEAL_NAVIGATOR_CARDS_FAILED =
  "ADD_DEAL_NAVIGATOR_CARDS_FAILED";

export const ADD_DEAL_NAVIGATOR_ELEMENTS_REQUEST =
  "ADD_DEAL_NAVIGATOR_ELEMENTS_REQUEST";
export const ADD_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED =
  "ADD_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED";
export const ADD_DEAL_NAVIGATOR_ELEMENTS_FAILED =
  "ADD_DEAL_NAVIGATOR_ELEMENTS_FAILED";

// export const ADD_DEAL_NAVIGATOR_DOCUMENTS_REQUEST =
//   "ADD_DEAL_NAVIGATOR_DOCUMENTS_REQUEST";
// export const ADD_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED =
//   "ADD_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED";
// export const ADD_DEAL_NAVIGATOR_DOCUMENTS_FAILED =
//   "ADD_DEAL_NAVIGATOR_DOCUMENTS_FAILED";

// #endregion

// #region CHANGE
export const CHANGE_DEAL_NAVIGATOR_STAGES_REQUEST =
  "CHANGE_DEAL_NAVIGATOR_STAGES_REQUEST";
export const CHANGE_DEAL_NAVIGATOR_STAGES_SUCCEEDED =
  "CHANGE_DEAL_NAVIGATOR_STAGES_SUCCEEDED";
export const CHANGE_DEAL_NAVIGATOR_STAGES_FAILED =
  "CHANGE_DEAL_NAVIGATOR_STAGES_FAILED";

export const CHANGE_DEAL_NAVIGATOR_CARDS_REQUEST =
  "CHANGE_DEAL_NAVIGATOR_CARDS_REQUEST";
export const CHANGE_DEAL_NAVIGATOR_CARDS_SUCCEEDED =
  "CHANGE_DEAL_NAVIGATOR_CARDS_SUCCEEDED";
export const CHANGE_DEAL_NAVIGATOR_CARDS_FAILED =
  "CHANGE_DEAL_NAVIGATOR_CARDS_FAILED";

export const CHANGE_DEAL_NAVIGATOR_ELEMENTS_REQUEST =
  "CHANGE_DEAL_NAVIGATOR_ELEMENTS_REQUEST";
export const CHANGE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED =
  "CHANGE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED";
export const CHANGE_DEAL_NAVIGATOR_ELEMENTS_FAILED =
  "CHANGE_DEAL_NAVIGATOR_ELEMENTS_FAILED";

// export const CHANGE_DEAL_NAVIGATOR_DOCUMENTS_REQUEST =
//   "CHANGE_DEAL_NAVIGATOR_DOCUMENTS_REQUEST";
// export const CHANGE_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED =
//   "CHANGE_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED";
// export const CHANGE_DEAL_NAVIGATOR_DOCUMENTS_FAILED =
//   "CHANGE_DEAL_NAVIGATOR_DOCUMENTS_FAILED";

// #endregion

// #region REMOVE
export const REMOVE_DEAL_NAVIGATOR_STAGES_REQUEST =
  "REMOVE_DEAL_NAVIGATOR_STAGES_REQUEST";
export const REMOVE_DEAL_NAVIGATOR_STAGES_SUCCEEDED =
  "REMOVE_DEAL_NAVIGATOR_STAGES_SUCCEEDED";
export const REMOVE_DEAL_NAVIGATOR_STAGES_FAILED =
  "REMOVE_DEAL_NAVIGATOR_STAGES_FAILED";

export const REMOVE_DEAL_NAVIGATOR_CARDS_REQUEST =
  "REMOVE_DEAL_NAVIGATOR_CARDS_REQUEST";
export const REMOVE_DEAL_NAVIGATOR_CARDS_SUCCEEDED =
  "REMOVE_DEAL_NAVIGATOR_CARDS_SUCCEEDED";
export const REMOVE_DEAL_NAVIGATOR_CARDS_FAILED =
  "REMOVE_DEAL_NAVIGATOR_CARDS_FAILED";

export const REMOVE_DEAL_NAVIGATOR_ELEMENTS_REQUEST =
  "REMOVE_DEAL_NAVIGATOR_ELEMENTS_REQUEST";
export const REMOVE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED =
  "REMOVE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED";
export const REMOVE_DEAL_NAVIGATOR_ELEMENTS_FAILED =
  "REMOVE_DEAL_NAVIGATOR_ELEMENTS_FAILED";

// export const REMOVE_DEAL_NAVIGATOR_DOCUMENTS_REQUEST =
//   "REMOVE_DEAL_NAVIGATOR_DOCUMENTS_REQUEST";
// export const REMOVE_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED =
//   "REMOVE_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED";
// export const REMOVE_DEAL_NAVIGATOR_DOCUMENTS_FAILED =
//   "REMOVE_DEAL_NAVIGATOR_DOCUMENTS_FAILED";

// #endregion

// #region REORDER & SWAP
export const REORDER_DEAL_NAVIGATOR_STAGES_REQUEST =
  "REORDER_DEAL_NAVIGATOR_STAGES_REQUEST";
export const REORDER_DEAL_NAVIGATOR_STAGES_SUCCEEDED =
  "REORDER_DEAL_NAVIGATOR_STAGES_SUCCEEDED";
export const REORDER_DEAL_NAVIGATOR_STAGES_FAILED =
  "REORDER_DEAL_NAVIGATOR_STAGES_FAILED";

export const REORDER_DEAL_NAVIGATOR_CARDS_REQUEST =
  "REORDER_DEAL_NAVIGATOR_CARDS_REQUEST";
export const REORDER_DEAL_NAVIGATOR_CARDS_SUCCEEDED =
  "REORDER_DEAL_NAVIGATOR_CARDS_SUCCEEDED";
export const REORDER_DEAL_NAVIGATOR_CARDS_FAILED =
  "REORDER_DEAL_NAVIGATOR_CARDS_FAILED";

export const SWAP_DEAL_NAVIGATOR_CARDS_REQUEST =
  "SWAP_DEAL_NAVIGATOR_CARDS_REQUEST";
export const SWAP_DEAL_NAVIGATOR_CARDS_SUCCEEDED =
  "SWAP_DEAL_NAVIGATOR_CARDS_SUCCEEDED";
export const SWAP_DEAL_NAVIGATOR_CARDS_FAILED =
  "SWAP_DEAL_NAVIGATOR_CARDS_FAILED";
// #endregion

// #region PUBLISH or DISCARD
export const PUBLISH_DEAL_NAVIGATOR_REQUEST = "PUBLISH_DEAL_NAVIGATOR_REQUEST";
export const PUBLISH_DEAL_NAVIGATOR_SUCCEEDED =
  "PUBLISH_DEAL_NAVIGATOR_SUCCEEDED";
export const PUBLISH_DEAL_NAVIGATOR_FAILED = "PUBLISH_DEAL_NAVIGATOR_FAILED";

export const DISCARD_DEAL_NAVIGATOR_REQUEST = "DISCARD_DEAL_NAVIGATOR_REQUEST";
export const DISCARD_DEAL_NAVIGATOR_SUCCEEDED =
  "DISCARD_DEAL_NAVIGATOR_SUCCEEDED";
export const DISCARD_DEAL_NAVIGATOR_FAILED = "DISCARD_DEAL_NAVIGATOR_FAILED";
//#endregion

export const SET_LAST_ADDED_CARD_VALUE = "SET_LAST_ADDED_CARD_VALUE";

//#region  TEMPLATE
export const CHOOSE_DEAL_NAVIGATOR_TEMPLATE_REQUEST =
  "CHOOSE_DEAL_NAVIGATOR_TEMPLATE_REQUEST";
export const CHOOSE_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED =
  "CHOOSE_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED";
export const CHOOSE_DEAL_NAVIGATOR_TEMPLATE_FAILED =
  "CHOOSE_DEAL_NAVIGATOR_TEMPLATE_FAILED";

export const FETCH_DEAL_NAVIGATOR_TEMPLATE_REQUEST =
  "FETCH_DEAL_NAVIGATOR_TEMPLATE_REQUEST";
export const FETCH_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED =
  "FETCH_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED";
export const FETCH_DEAL_NAVIGATOR_TEMPLATE_FAILED =
  "FETCH_DEAL_NAVIGATOR_TEMPLATE_FAILED";
//#endregion_TEMPLATE

//#region RESET
export const RESET_DEAL_NAVIGATOR_REQUEST = "RESET_DEAL_NAVIGATOR_REQUEST";
export const RESET_DEAL_NAVIGATOR_SUCCEEDED = "RESET_DEAL_NAVIGATOR_SUCCEEDED";
export const RESET_DEAL_NAVIGATOR_FAILED = "RESET_DEAL_NAVIGATOR_FAILED";
//#endregion
