import React from "react";

const IconIndustry = (props) => (
  <svg className={props.imgStyle} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_19607_60807)">
      <path d="M6.66667 6.99204V0.917969L14 4.91797V14.2513H2V4.91797L6.66667 6.99204Z" fill="#CACCD1" />
    </g>
    <defs>
      <clipPath id="clip0_19607_60807">
        <rect width="16" height="16" fill="white" transform="translate(0 0.25)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconIndustry;
