import React, { FC, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

//Redux
import { useDispatch } from "react-redux";

//Helpers
import { errorResponseToList } from "../../../../helper/errorHelper";
import { toastMessages } from "../../../../helper/toastMessagesConstants";

//Styles
import styles from "./Audiences.module.scss";

//Icons
import IconAudienceExplorer from "../../../../assets/Icons/IconAudienceExplorer/IconIncludeContact";
import NewAudienceIcon from "../../../../assets/Icons/NewAudienceIcon/NewAudienceIcon";
import Expand from "../../../../assets/Icons/Expand/Expand";

//Components
import FormikSelect from "@components/UI/Formik/FormikSelect";
import Modal from "@components/UI/Modal/Modal";
import Button from "@components/UI/Button/Button";

// API
import {
  useGetAudiencesForCampaignNotInitiatedQuery,
  useGetAudiencesForCampaignQuery,
  useSetAudienceCampaignsMutation,
} from "@api/audienceApi";

import { useHistory } from "react-router-dom";
import { useSlice } from "@hooks/useSlice";
import campaignConfigSlice from "@store/Campaigns/campaignConfigSlice";
import { AssignAudienceCampaignsModel } from "@models/audience/AssignAudienceCampaignsModel";
import useRouteParams from "@hooks/useRouteParams";

interface Props {
  handleAudienceUploadFormShow: () => void;
  handleClose: () => void;
  handleCreateNewAudienceFormShow: () => void;
  getAudiences?: () => void;
  showModal: boolean;
}

interface MappedAudience {
  value: string;
  label: JSX.Element;
  labelShow: string;
}

const AudiencePushToCampaignForm: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const orgId = useSlice((state) => state.user.organization.id);
  const openPushAudienceModal = useSlice((state) => state.campaign.openPushAudienceModal);

  const { campaignId } = useRouteParams();

  const audiencesQuery = useGetAudiencesForCampaignQuery(campaignId, { refetchOnMountOrArgChange: true });
  const audiencesNotInitiatedQuery = useGetAudiencesForCampaignNotInitiatedQuery(campaignId, { refetchOnMountOrArgChange: true });
  const waitingForAudienceToBeCreated =
    audiencesNotInitiatedQuery.data && audiencesNotInitiatedQuery.data?.length >= 1 && openPushAudienceModal;

  const [setAudienceCampaigns] = useSetAudienceCampaignsMutation();

  const history = useHistory();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (waitingForAudienceToBeCreated) {
      timeoutId = setTimeout(() => {
        audiencesQuery.refetch();
        audiencesNotInitiatedQuery.refetch();
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [waitingForAudienceToBeCreated, audiencesQuery.refetch, audiencesNotInitiatedQuery.refetch]);

  let mapAudienceList: MappedAudience[] = [];
  if (audiencesQuery.data && audiencesQuery.data?.length > 0) {
    const mappedAudiences: MappedAudience[] = audiencesQuery.data.map((e) => {
      return {
        value: e.id?.toString(),
        label: (
          <span className="flex">
            {e.name} {e.isAssigned ? null : <span style={{ color: "gray" }}>(Unassigned)</span>}{" "}
            <span className="textOverflow" style={{ color: "green", float: "right" }}>
              &nbsp; &nbsp; {e.availableContacts} contacts available
            </span>
          </span>
        ),
        labelShow: e.name,
      };
    });

    mapAudienceList = [
      ...mappedAudiences,
      {
        label: (
          <div className={styles.buttonWrapper}>
            <Button id="createNewList" classes={styles.createAudienceButton} onClick={() => {}}>
              <Expand imgStyle={styles.createNewAudienceOptions} />
              Create new List{" "}
            </Button>
          </div>
        ),
        value: "0",
        labelShow: "",
      },
    ];
  }

  const handleOnChange = (audienceId: string, formikProps: any) => {
    formikProps.setFieldValue("audienceId", parseInt(audienceId));
  };

  const handleAudienceBuilder = () => {
    history.push("/" + orgId + "/search?camId=" + campaignId);
  };

  const handleUploadAudience = () => {
    props.handleAudienceUploadFormShow();
    props.handleClose();
  };

  const filterLabel = (option: any, inputValue: string) => {
    return option.data.labelShow?.toLowerCase().includes(inputValue.toLowerCase()) && option;
  };

  return (
    <Formik
      initialValues={{
        audienceId: 0,
        campaignId: [campaignId],
        dontUnassignCampaigns: true,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        audienceId: Yup.number().typeError("Enter valid number.").positive("Please choose a list.").required("Required"),
      })}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);

        const data: AssignAudienceCampaignsModel = {
          audienceId: values.audienceId,
          campaignIds: [campaignId],
          dontUnassignCampaigns: true,
        };

        setAudienceCampaigns(data)
          .unwrap()
          .then(() => {
            toast.success(toastMessages.audience.setAudienceImportSettings.importStarted);
            props.handleClose();
            // TO DO: Leaving it like this until we switch Audiences to RTK Query. Need to use InvalidateTags afterwards
            if (props.getAudiences) props.getAudiences();
            actions.resetForm();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps;
        return (
          <Modal
            title={"Assign list"}
            removeFooter={mapAudienceList.length === 0}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText={"I'm done"}
            idSaveButton="done"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
            customBodyClass={mapAudienceList.length === 0 ? styles.noFooterModal : ""}
          >
            <Container>
              <Row className={styles.audienceEditForm} id="audienceForm">
                {waitingForAudienceToBeCreated ? (
                  <>
                    <p>We are creating your list. Please wait.</p>
                  </>
                ) : mapAudienceList.length === 0 ? (
                  <>
                    <div className="width_100 textLeft">
                      <h1 className="f12 font700">You have no existing lists. </h1>
                      <p className="mB5">There are two ways to create a list:</p>
                    </div>
                    <ul className={styles.listStyle}>
                      <li>Using Outbase's advanced Search filters - our database of hundreds of millions of professional profiles.</li>
                      <li>By uploading a CSV file with your own contact information to create a file-based list.</li>
                    </ul>
                    <div className={`${styles.uploadButtonsWrapper} flex flex1`}>
                      <Button
                        id="campaignConfigurationUploadAudience"
                        onClick={handleUploadAudience}
                        variant="secondary"
                        link={"audiences"}
                      >
                        <NewAudienceIcon imgStyle="mR5" /> Upload list
                      </Button>
                      <Button id="campaignConfigurationAudienceBuilder" onClick={handleAudienceBuilder} variant="primary" link={"search"}>
                        <IconAudienceExplorer imgStyle="mR5" /> Search contacts
                      </Button>
                    </div>
                  </>
                ) : (
                  <Col xl={12}>
                    <div className={styles.selectDiv}>
                      <FormikSelect
                        label="Select list"
                        name="audienceId"
                        placeholder={"Select list"}
                        showBorder={true}
                        options={mapAudienceList}
                        isSearchable={true}
                        filterOption={filterLabel}
                        isMenuPortalTargetDisabled={true}
                        onChange={(val: string) => {
                          if (val === "0") {
                            props.handleClose();
                            props.handleCreateNewAudienceFormShow();
                          } else {
                            handleOnChange(val.toString(), formikProps);
                          }
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AudiencePushToCampaignForm;
