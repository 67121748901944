import React, { useEffect, useState, useContext } from "react";

//Redux
import { connect } from "react-redux";
import { fetchBillingInfo } from "../../../../store/BillingAndPayment/actions/billingAndPayment";
import { fetchInvoices, fetchInvoicesSuccess } from "../../../../store/BillingAndPayment/InvoicesTable/actions/invoices";
import { signalReceived } from "../../../../store/Signal/actions/signal";

//Helpers
import { currencySign } from "../../../../helper/pricingPlanHelper";
import { termsAndPaymentStatusEnum } from "../../../../helper/enums/contractStatusEnum";
import { pricingPlanEnum } from "../../../../helper/pricingPlanHelper";
import SignalRContext from "../../../../helper/SignalRContext";
import { SIGNALR_CONSTANTS } from "../../../../helper/signalrConstants";

//Styles
import styles from "./BillingAndPayment.module.scss";

// Components
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import CurrentPlan from "./CurrentPlan/CurrentPlan";
import Usage from "./Usage/Usage";
import InvoicesTable from "./InvoicesTable/InvoicesTable";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import BillingContacts from "./BillingContacts/BillingContacts";
import WrittenAgreement from "./WrittenAgreement/WrittenAgreement";
import NotificationUpdatePayment from "./NotificationUpdatePayment/NotificationUpdatePayment";
import SkeletonTable from "../../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import CheckoutModal from "./CheckoutModal/CheckoutModal";
import AddCreditCardModalWarning from "./AddCreditCardModalWarning/AddCreditCardModalWarning";

const BillingAndPayments = (props) => {
  const {
    isLoading,
    isLoaded,
    currencyId,
    contractStatus,
    waitingDowngrade,
    nextBillingDate,
    nextPlanName,
    inValidPayment,
    ccEmails,
    billingInfoEmail,
    fetchBillingInfo,
    creditCardInfo,
    planInfoCheckout,
    billingInfo,
    plan,
    fetchInvoicesSuccess,
    organizationContractUrl,
  } = props;
  let listInvoices = null;
  let billingContacts = null;

  const [buttonCurrentPlanText, setButtonCurrentPlanText] = useState("Upgrade now");

  useEffect(() => {
    setCheckoutModal(checkoutModal);
  }, [plan, inValidPayment]);

  const [checkoutModal, setCheckoutModal] = useState(false);
  const handleCheckoutModalClose = () => {
    fetchBillingInfo();
    setCheckoutModal(false);
  };

  const [showAddCreditCardModalWarning, setShowAddCreditCardModalWarning] = useState(false);

  const handleAddCreditCardModalCloseWarning = () => {
    setShowAddCreditCardModalWarning(false);
  };
  const signalRConnection = useContext(SignalRContext);

  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.INVOICE_ADDED, (data) => {
        fetchInvoicesSuccess(data);
        signalReceived(SIGNALR_CONSTANTS.INVOICE_ADDED, data);
      });
    }

    return () => {
      if (signalRConnection != null) {
        try {
          signalRConnection.off(SIGNALR_CONSTANTS.INVOICE_ADDED);
        } catch {}
      }
    };
  }, [signalRConnection]);

  useEffect(() => {
    if (contractStatus === termsAndPaymentStatusEnum.awaitingPaymentConfirmation) {
      setButtonCurrentPlanText("Add Billing Info");
    } else if (contractStatus !== termsAndPaymentStatusEnum.awaitingPaymentConfirmation && contractStatus !== "") {
      if (contractStatus === termsAndPaymentStatusEnum.accepted && plan === pricingPlanEnum.trial) {
        setButtonCurrentPlanText("Add Billing Info");
      } else {
        setButtonCurrentPlanText("Upgrade now");
      }
    }
  }, [contractStatus, plan, inValidPayment]);

  if (isLoading) {
    listInvoices = <SkeletonTable />;
  }

  if (isLoaded) {
    listInvoices = (
      <InvoicesTable
        contractStatus={contractStatus}
        waitingDowngrade={waitingDowngrade}
        nextBillingDate={nextBillingDate}
        nextPlanName={nextPlanName}
        currencySign={currencySign[currencyId]}
        fetchBillingInfo={fetchBillingInfo}
      />
    );
    billingContacts = <BillingContacts ccEmails={ccEmails} billingInfoEmail={billingInfoEmail} />;
  }

  return (
    <>
      <div className={`${styles.layoutWrapper}`}>
        <div className={`${styles.groupWrapper} flex`}>
          <div className={styles.currentPlanWrapper}>
            <CurrentPlan
              {...props}
              orgId={props.organizationId}
              buttonText={buttonCurrentPlanText}
              contractStatus={contractStatus}
              setCheckoutModal={setCheckoutModal}
              setShowAddCreditCardModalWarning={setShowAddCreditCardModalWarning}
              creditCardInfo={creditCardInfo}
            />
          </div>
          <div className={styles.usageWrapper}>
            <Usage />
          </div>
        </div>
        <div className={`${styles.paymentsWrapper} flex`}>
          <div className={styles.historyPaymentWrapper}>
            <SectionTitle title="Payment History" />
            <div className={styles.componentWrapper}>
              {inValidPayment ? <NotificationUpdatePayment /> : null}
              {listInvoices}
            </div>
          </div>
          <div className={styles.billingDetails}>
            {organizationContractUrl && organizationContractUrl !== null ? (
              <div className={styles.agreementWrapper}>
                <SectionTitle title="Agreement" />
                <WrittenAgreement documentName={"Agreement"} contractStatus={contractStatus} url={organizationContractUrl} />
              </div>
            ) : null}
            <PaymentMethod />
            {billingContacts}
            {planInfoCheckout ? (
              <CheckoutModal
                {...props}
                showModal={checkoutModal}
                handleClose={handleCheckoutModalClose}
                creditCardInfo={creditCardInfo}
                planId={planInfoCheckout.planId}
                pricePointId={planInfoCheckout.pricePointId}
                planChargifyHandle={planInfoCheckout.planChargifyHandle}
                pricePointChargifyHandle={planInfoCheckout.pricePointChargifyHandle}
                billingPeriod={planInfoCheckout.billingPeriod}
                currentPlan={planInfoCheckout.currentPlan}
                credits={planInfoCheckout.credits}
                users={planInfoCheckout.users}
                typeOfBilling={planInfoCheckout.typeOfBilling}
                price={planInfoCheckout.price}
                fullPrice={planInfoCheckout.fullPrice}
                period={planInfoCheckout.period}
                currencySign={planInfoCheckout.currencySign}
                orgId={props.orgId}
                billingInfo={billingInfo}
                coupon={planInfoCheckout.coupon}
              />
            ) : null}
            <AddCreditCardModalWarning
              handleClose={handleAddCreditCardModalCloseWarning}
              showModal={showAddCreditCardModalWarning}
              text={"We are processing the payment and your information will be updated shortly"}
              title={"Processing payment"}
              //  handleSave={handleAddCreditCardModalCloseBilling}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    organizationId: state.user.organization.id,
    currencyId: state.organizationSettingsGeneral.settings.currency,
    isLoading: state.billingAndPayment.isLoading,
    isLoaded: state.billingAndPayment.isLoaded,
    invoices: state.billingAndPayment.invoices,
    ccEmails: state.billingAndPayment.billingInfo?.ccEmails,
    param: state.billingAndPayment.invoicesParams,
    contractStatus: state.billingAndPayment.contractStatus,
    waitingDowngrade: state.billingAndPayment.waitingDowngrade,
    nextBillingDate: state.billingAndPayment.nextBillingDate,
    nextPlanName: state.billingAndPayment.nextPlanName,
    inValidPayment: state.billingAndPayment.inValidPayment,
    billingInfoEmail: state.billingAndPayment.billingInfo?.email,
    params: state.invoices.params,
    creditCardInfo: state.billingAndPayment.creditCardInfo,
    planInfoCheckout: state.billingAndPayment.planInfoCheckout,
    billingInfo: state.billingAndPayment.billingInfo,
    plan: state.billingAndPayment.creditInfo.plan,
    organizationContractUrl: state.billingAndPayment.organizationContractUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBillingInfo: () => dispatch(fetchBillingInfo()),
    fetchInvoices: (params) => dispatch(fetchInvoices(params)),
    fetchInvoicesSuccess: (data) => dispatch(fetchInvoicesSuccess(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAndPayments);
