import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "moment";
import _ from "lodash";

//helpers
import { pricingPlanEnum } from "@helper/pricingPlanHelper";

// Images & Icons
import IconEmailExcluded from "@assets/Icons/IconEmailExcluded/IconEmailExcluded";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";
import Message2LineIcon from "remixicon-react/Message2LineIcon";
import GlobalFillIcon from "remixicon-react/GlobalFillIcon";
import PhoneLineIcon from "remixicon-react/PhoneLineIcon";
import MailLineIcon from "remixicon-react/MailLineIcon";

// Style
import styles from "./ProspectRow.module.scss";

// Components
import UserPhoto from "@components/UI/UserPhoto/UserPhoto";
import StatusBar from "./../StatusBar/StatusBarProspect";
import Spinner from "@components/UI/Spinner/Spinner";

const formatUrl = (url) => {
  return url.includes("https") ? url : "//" + url;
};

const ProspectRow = ({
  row,
  organizationId,
  setIsRevealPhoneLoading,
  revealPhoneModal,
  isRevealPhoneLoading,
  creditsChargeAmountPhoneReveal,
  categorizeModal,
  dateFormat,
  plan,
  upgradeToPaidPlanModal,
}) => {
  const r = _.cloneDeep(row);

  r.name = (
    <div className={styles.nameColumn}>
      <div className={`${styles.prospectName} text-overflow-hidden`}>
        {r.prospectLinkedIn && (
          <span className="mR5">
            <a rel="noopener noreferrer" href={formatUrl(r.prospectLinkedIn)} target="_blank">
              <LinkedinBoxFillIcon size={16} className="iconGray" />
            </a>
          </span>
        )}
        <OverlayTrigger
          key={r.name}
          placement={"bottom"}
          overlay={
            <Popover id={r.name}>
              <Popover.Body>{r.name}</Popover.Body>
            </Popover>
          }
        >
          <span>
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
              }}
            >
              {r.name}
            </Link>
          </span>
        </OverlayTrigger>

        <div className={styles.jobTitle}>{r.jobTitle && r.jobTitle !== "" ? r.jobTitle : null}</div>
      </div>
    </div>
  );

  const isFreemiumOrTrial = [pricingPlanEnum.freemium, pricingPlanEnum.trial].includes(plan);

  r.contactInfo = (
    <div className={styles.phoneEmail}>
      {!r.isPhoneNumberAvailable && (r.mobileNumber || r.phoneNumber) ? (
        <div className={`${styles.phoneRevealed} text-overflow-hidden`}>
          <div className="text-overflow-hidden">
            <MailLineIcon size={14} className="mR5" />
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
              }}
            >
              {r.email}
            </Link>
          </div>
          <div className="text-overflow-hidden">
            <PhoneLineIcon size={14} className="mR5" />
            {r.mobileNumber || r.phoneNumber}
          </div>
        </div>
      ) : r.isPhoneNumberAvailable ? (
        <>
          <div className="text-overflow-hidden">
            <MailLineIcon size={14} className="mR5" />
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
              }}
            >
              {r.email}
            </Link>
          </div>
          <OverlayTrigger
            key={`${r.id}true`}
            placement={"bottom"}
            overlay={
              isFreemiumOrTrial ? <></> : <Popover id={`tooltip-${r.id}true`}>
                <Popover.Body>
                  &bull;&bull;&bull; &bull;&bull;&bull; &bull;&bull;&bull; ({creditsChargeAmountPhoneReveal} Credits)
                </Popover.Body>
              </Popover>
            }
          >
            <div
              onClick={() => {
                if(isFreemiumOrTrial){
                  upgradeToPaidPlanModal.show();
                }
                else{
                  setIsRevealPhoneLoading(true);
                  revealPhoneModal.show(r.id);
                }
              }}
              className={styles.phoneReveal}
            >
              {isRevealPhoneLoading ? (
                <div className="flex">
                  <Spinner size="14px" spinnerClass={styles.spinner} description="Searching number" />
                </div>
              ) : (
                <div>
                  <PhoneLineIcon size={14} className="mR5" />
                  {"Reveal Phone"}
                </div>
              )}
            </div>
          </OverlayTrigger>
        </>
      ) : (
        <div className="text-overflow-hidden">
          <MailLineIcon size={14} className="mR5" />
          <Link
            className="tableLink"
            to={{
              pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
            }}
          >
            {r.email}
          </Link>
        </div>
      )}
    </div>
  );

  if (r.isExcluded) {
    r.isExcluded = (
      <OverlayTrigger
        key={r.isExcluded}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-${r.isExcluded}`}>
            <Popover.Body>This prospect has been excluded</Popover.Body>
          </Popover>
        }
      >
        <div className="text-overflow-hidden">
          <IconEmailExcluded />
        </div>
      </OverlayTrigger>
    );
  } else {
    r.isExcluded = null;
  }

  r.companyName = (
    <div className={styles.companyInfo}>
      <UserPhoto isCompanyImage={true} src={r.companyLogo} size="small" />
      <div className={styles.companyName}>
        {r.cleanCompanyName ? r.cleanCompanyName : r.companyName}
        <br />
        {r.companyDomain && (
          <OverlayTrigger
            key={`${r.id}${r.companyDomain}`}
            placement={"bottom"}
            overlay={
              <Popover id={`${r.id}${r.companyDomain}`}>
                <Popover.Body>{r.companyDomain}</Popover.Body>
              </Popover>
            }
          >
            <span>
              <a rel="noopener noreferrer" href={formatUrl(r.companyDomain)} target="_blank">
                <GlobalFillIcon size={16} className="iconGray" />
              </a>
            </span>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );

  if (r.firstActivity != null && r.firstActivity !== "") {
    r.firstActivity = Moment(r.firstActivity).format(dateFormat);
  } else {
    r.firstActivity = "-";
  }

  if (r.lastActivity != null && r.lastActivity !== "") {
    r.lastActivity = Moment(r.lastActivity).format(dateFormat);
  } else {
    r.lastActivity = "-";
  }

  if (r.lastSend != null && r.lastSend !== "") {
    r.lastSend = Moment(r.lastSend).format(dateFormat);
  } else {
    r.lastSend = "-";
  }

  r.conversations = (
    <Link
      to={{
        pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
      }}
    >
      <Message2LineIcon size={16} className="iconGray500" />
    </Link>
  );

  r.currentStatus = (
    <StatusBar
      isDelivered={r.delivered != null}
      isOpened={r.opened != null}
      isClicked={r.clicked != null}
      isResponder={r.isResponder}
      isLead={r.isLead}
    />
  );

  const tooltipContent =
    r.responseType === "Out of office"
      ? "OOO replies delay the next email by 10 days by default. Adjust this by resolving the reply and setting a new delay"
      : r.responseType;

  const responseTypeJSX =
    r.responseType === "Out of office" ? (
      <>
        <span>{r.responseType}</span>
        {r.isManualCategorizedProspect ? (
          <>
            <a
              className={styles.linkPrimary}
              onClick={() => {
                categorizeModal.show(r.id);
              }}
            >
              Change response type
            </a>
          </>
        ) : (
          <InformationFillIcon size={18} color="var(--icon-color)" className="mR5" />
        )}
      </>
    ) : r.responseType ? (
      <>
        <span>{r.responseType}</span>
        {r.isManualCategorizedProspect ? (
          <>
            <a
              className={styles.linkPrimary}
              onClick={() => {
                categorizeModal.show(r.id);
              }}
            >
              Change response type
            </a>
          </>
        ) : null}
      </>
    ) : r.delivered && !r.isResponder ? (
      <a
        className={styles.linkPrimary}
        onClick={() => {
          categorizeModal.show(r.id);
        }}
      >
        + Set response type
      </a>
    ) : null;

  r.responseType =
    r.responseType === "Out of office" ? (
      <OverlayTrigger
        key={r.responseType}
        placement={"bottom"}
        overlay={
          <Popover id={`tooltip-${r.responseType}`}>
            <Popover.Body>{tooltipContent}</Popover.Body>
          </Popover>
        }
      >
        <div className={`${styles.responseType} ${!r.isManualCategorizedProspect ? styles.outOffice : ""}`}> {responseTypeJSX}</div>
      </OverlayTrigger>
    ) : (
      <div className={styles.responseType}>{responseTypeJSX}</div>
    );

  r.createdDate = Moment(r.createdDate).format(dateFormat);
  return r;
};

export default ProspectRow;
