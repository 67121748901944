import React, { useState, useEffect } from "react";
import { FormLabel, FormGroup, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import _ from "lodash";

//Styles
import styles from "./FormikInput.module.scss";

//Components
import Select from "../Select/Select";
import SelectMulti from "../Select/SelectMulti";
import FormikSelectValuesWrapper from "./FormikSelectValuesWrapper";

const FormikSelect = (props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  let errorElement = null;
  let errorSelect = null;
  let labelElement = null;
  const [value, setValue] = useState(props.value);

  if (meta.touched && (typeof meta.error === "string" || meta.error instanceof String)) {
    errorElement = <div className={props.errorinputclass + " " + styles.error}>{meta.error}</div>;
    errorSelect = styles.errorInput;
  }

  if (props.label || props.showEmptyLabel) {
    labelElement = (
      <FormLabel className={props.labelclass + " " + styles.label} htmlFor={props.id || props.name}>
        {props.label ? props.label : <span>&nbsp;</span>}
      </FormLabel>
    );
  }

  useEffect(() => {
    if (props.clearSelected) {
      setValue([]);
    }
  }, [props.clearSelected]);

  useEffect(() => {
    if (props.enableReinitialize) setValue(props.value);
  }, [props.value, props.enableReinitialize]);

  const changeHandler = (event) => {
    setFieldValue(field.name, event);
    setValue(_.cloneDeep(event));

    if (props.onChange != null) {
      props.onChange(event);
    }
  };

  const commonProps = {
    name: props.name,
    id: props.id || (props.name?.replace(/\./g, "") ?? ""),
    menuId: props.menuId ? props.menuId : "",
    className: `${errorSelect} mb5 ${props.selectClass}`,
    isMenuPortalTargetDisabled: props.isMenuPortalTargetDisabled,
    isMulti: props.isMulti,
    options: props.options,
    onChange: (event) => changeHandler(event),
    placeholder: props.placeholder,
    value: value,
    defaultValue: props.defaultValue,
    isSearchable: props.isSearchable,
    filterOption: props.filterOption,
    disabled: props.disabled,
    onMenuOpen: () => {
      if (props.onMenuOpen) props.onMenuOpen();
    },
    onMenuClose: () => {
      if (props.onMenuClose) props.onMenuClose();
    },
    onInputChange: props.onInputChange,
    hideNoOptions: props.hideNoOptions,
    requireInputToShowOptions: props.requireInputToShowOptions,
    isAutoComplete: props.isAutoComplete,
    onChangeSelectedOption: props.onChangeSelectedOption,
    hideSelectedOptions: props.hideSelectedOptions,
  };

  return (
    <FormGroup className={styles.group + " " + props.formgroupClass}>
      {labelElement}
      <InputGroup className={styles.inputGroup + " " + props.inputGroupClass}>
        {props.showValuesOutside ? (
          <SelectMulti
            {...commonProps}
            displayName={props.displayName ? props.displayName : props.placeholder}
            allowCustomValues={props.allowCustomValues}
            allowIncludeExclude={props.allowIncludeExclude}
            allowIncludeExcludeForCustomValues={props.allowIncludeExcludeForCustomValues}
          />
        ) : (
          <Select {...commonProps} tabIndex={props.tabIndex} inputId={props.name?.replace(/[^a-zA-Z0-9]/g, "")} />
        )}
      </InputGroup>

      {props.showValuesOutside ? (
        <>
          {props.validationErrorMessage && <p className={`m0 ${styles.error}`}>{props.validationErrorMessage}</p>}
          {props.infoMessage && <p className={`m0 ${styles.infoMessage}`}>{props.infoMessage}</p>}

          {props.showValuesOutside && (
            <FormikSelectValuesWrapper
              allowIncludeExclude={props.allowIncludeExclude}
              values={value}
              changeHandler={changeHandler}
              options={props.options}
            />
          )}
        </>
      ) : null}

      {errorElement}
    </FormGroup>
  );
};

export default FormikSelect;
