//#region Deferred Interest Deals
export const FETCH_DEFERRED_INTEREST_DEALS_REQUEST =
  "FETCH_DEFERRED_INTEREST_DEALS_REQUEST";
export const FETCH_DEFERRED_INTEREST_DEALS_SUCCEEDED =
  "FETCH_DEFERRED_INTEREST_DEALS_SUCCEEDED";
export const FETCH_DEFERRED_INTEREST_DEALS_FAILED =
  "FETCH_DEFERRED_INTEREST_DEALS_FAILED";

export const DEFERRED_INTEREST_DEALS_CHANGE_PAGE =
  "DEFERRED_INTEREST_DEALS_CHANGE_PAGE";
export const DEFERRED_INTEREST_DEALS_SORT_DATA =
  "DEFERRED_INTEREST_DEALS_SORT_DATA";
export const DEFERRED_INTEREST_DEALS_NUM_RECORDS =
  "DEFERRED_INTEREST_DEALS_NUM_RECORDS";
export const DEFERRED_INTEREST_DEALS_SEARCH_RECORDS =
  "DEFERRED_INTEREST_DEALS_SEARCH_RECORDS";
export const DEFERRED_INTEREST_DEALS_FILTER_RECORDS =
  "DEFERRED_INTEREST_DEALS_FILTER_RECORDS";
export const DEFERRED_INTEREST_DEALS_SET_FILTER =
  "DEFERRED_INTEREST_DEALS_SET_FILTER";
export const DEFERRED_INTEREST_DEALS_CLEAR_FILTER =
  "DEFERRED_INTEREST_DEALS_CLEAR_FILTER";
export const DEFERRED_INTEREST_DEALS_INITIATE =
  "DEFERRED_INTEREST_DEALS_INITIATE";
//#endregion

//#region Positive Responses Deals
export const FETCH_POSITIVE_RESPONSES_DEALS_REQUEST =
  "FETCH_POSITIVE_RESPONSES_DEALS_REQUEST";
export const FETCH_POSITIVE_RESPONSES_DEALS_SUCCEEDED =
  "FETCH_POSITIVE_RESPONSES_DEALS_SUCCEEDED";
export const FETCH_POSITIVE_RESPONSES_DEALS_FAILED =
  "FETCH_POSITIVE_RESPONSES_DEALS_FAILED";

export const POSITIVE_RESPONSES_DEALS_CHANGE_PAGE =
  "POSITIVE_RESPONSES_DEALS_CHANGE_PAGE";
export const POSITIVE_RESPONSES_DEALS_SORT_DATA =
  "POSITIVE_RESPONSES_DEALS_SORT_DATA";
export const POSITIVE_RESPONSES_DEALS_NUM_RECORDS =
  "POSITIVE_RESPONSES_DEALS_NUM_RECORDS";
export const POSITIVE_RESPONSES_DEALS_SEARCH_RECORDS =
  "POSITIVE_RESPONSES_DEALS_SEARCH_RECORDS";
export const POSITIVE_RESPONSES_DEALS_FILTER_RECORDS =
  "POSITIVE_RESPONSES_DEALS_FILTER_RECORDS";
export const POSITIVE_RESPONSES_DEALS_SET_FILTER =
  "POSITIVE_RESPONSES_DEALS_SET_FILTER";
export const POSITIVE_RESPONSES_DEALS_CLEAR_FILTER =
  "POSITIVE_RESPONSES_DEALS_CLEAR_FILTER";
export const POSITIVE_RESPONSES_DEALS_INITIATE =
  "POSITIVE_RESPONSES_DEALS_INITIATE";
//#endregion

//#region Referrals Deals
export const FETCH_REFERRALS_DEALS_REQUEST = "FETCH_REFERRALS_DEALS_REQUEST";
export const FETCH_REFERRALS_DEALS_SUCCEEDED =
  "FETCH_REFERRALS_DEALS_SUCCEEDED";
export const FETCH_REFERRALS_DEALS_FAILED = "FETCH_REFERRALS_DEALS_FAILED";

export const REFERRALS_DEALS_CHANGE_PAGE = "REFERRALS_DEALS_CHANGE_PAGE";
export const REFERRALS_DEALS_SORT_DATA = "REFERRALS_DEALS_SORT_DATA";
export const REFERRALS_DEALS_NUM_RECORDS = "REFERRALS_DEALS_NUM_RECORDS";
export const REFERRALS_DEALS_SEARCH_RECORDS = "REFERRALS_DEALS_SEARCH_RECORDS";
export const REFERRALS_DEALS_FILTER_RECORDS = "REFERRALS_DEALS_FILTER_RECORDS";
export const REFERRALS_DEALS_SET_FILTER = "REFERRALS_DEALS_SET_FILTER";
export const REFERRALS_DEALS_CLEAR_FILTER = "REFERRALS_DEALS_CLEAR_FILTER";
export const REFERRALS_DEALS_INITIATE = "REFERRALS_DEALS_INITIATE";
//#endregion

//#region Rest actions
export const FETCH_RESPONSE_DETAILS_REQUEST = "FETCH_RESPONSE_DETAILS_REQUEST";
export const FETCH_RESPONSE_DETAILS_SUCCEEDED =
  "FETCH_RESPONSE_DETAILS_SUCCEEDED";
export const FETCH_RESPONSE_DETAILS_FAILED = "FETCH_RESPONSE_DETAILS_FAILED";

export const FETCH_SENT_EMAIL_DETAILS_REQUEST =
  "FETCH_SENT_EMAIL_DETAILS_REQUEST";
export const FETCH_SENT_EMAIL_DETAILS_SUCCEEDED =
  "FETCH_SENT_EMAIL_DETAILS_SUCCEEDED";
export const FETCH_SENT_EMAIL_DETAILS_FAILED =
  "FETCH_SENT_EMAIL_DETAILS_FAILED";
//#endregion
