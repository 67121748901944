import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { addSalesDocumentsImageSuccess } from "../../../store/SalesDocuments/actions/salesDocuments";

// Helper
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";

// Icons
import FileUploadLineIcon from "remixicon-react/FileUploadLineIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";

// Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import ImageUploader from "../../../components/UI/FileUploader/ImageUploader";

const AddImageForm = (props) => {
  return (
    <Formik
      initialValues={{
        image: null,
        description: "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        image: Yup.mixed().required("Please upload an image"),
        description: Yup.string().required("Please enter image title"),
      })}
      onSubmit={(values, actions) => {
        const formData = new FormData();
        formData.append("image", values.image);
        formData.append("description", values.description);

        api(API.salesDocument.createImage, formData)
          .then((response) => {
            props.addSalesDocumentsImageSuccess(response.data);
            actions.resetForm();
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, values } = formikProps;

        const hasImage = !!values.image;

        const saveDisabled = !(hasImage && values.description);

        const handleDeleteImage = () => {
          setFieldValue("image", null);
        };

        return (
          <Modal
            title="Add new image"
            handleClose={() => {
              formikProps.resetForm();
              props.handleClose();
            }}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={saveDisabled || isSubmitting}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <div className="flex align-center">
                <ImageUploader
                  buttonText={
                    <>
                      <FileUploadLineIcon size={16} className="mR5" /> Upload image
                    </>
                  }
                  variant="primary"
                  handleFile={(file) => {
                    setFieldValue("image", file);
                  }}
                />
                {hasImage && (
                  <div className="flex mL10">
                    <span> {values.image.name}</span>

                    <DeleteBinLineIcon size={16} className="iconGray cursor_pointer mL10" onClick={handleDeleteImage} />
                  </div>
                )}
              </div>
              <FormikInput label="Title: " name="description" type="text" placeholder="Title" labelclass="mT20" />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSalesDocumentsImageSuccess: (image) => {
      dispatch(addSalesDocumentsImageSuccess(image));
    },
  };
};

export default connect(null, mapDispatchToProps)(AddImageForm);
