import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssistantExecutionModel, CampaignObjectModel, LocationType } from "@models/assistant/AssistantModels";
import { EmailTemplateTone } from "@helper/enums/emailTemplateTypeEnum";
import { assistantApi } from "@api/assistantApi";

interface AssistantState {
  campaignObject: CampaignObjectModel;
  executionId: number;
  currentStep: number;
  totalSteps: number;
}

const initialState: AssistantState = {
  campaignObject: {
    audience: {
      tags: [],
      companySize: [],
      companyLocations: {
        country: [],
        region: [],
        zipCode: [],
      },
      contactLocations: {
        country: [],
        region: [],
        zipCode: [],
      },
      departments: [],
      seniority: [],
      searchKey: "",
    },
    tone: EmailTemplateTone.InformalTone,
    companyDescription: {
      description: "",
      productInformation: "",
      problemInformation: "",
    },
    settings: {
      hasChanges: false,
      locationType: LocationType.Country,
    },
    emailAccounts: [],
    emailAccount: "",
    emailAccountId: null,
    signature: "",
  },
  executionId: 0,
  currentStep: 1,
  totalSteps: 0,
};

const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    updateCampaignObject: (state, action: PayloadAction<CampaignObjectModel>) => {
      state.campaignObject = action.payload;
    },
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    // Reset state logic for reset action
    clearState: (state) => {
      const newState = initialState;
      Object.assign(state, newState);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(assistantApi.endpoints.startExecution.matchFulfilled, (state, action: PayloadAction<AssistantExecutionModel>) => {
      const data: CampaignObjectModel = action.payload.data;
      if (data && Object.keys(data).length > 0) {
        state.campaignObject = action.payload.data;
      }
      state.executionId = action.payload.id;
      state.totalSteps = action.payload.totalSteps;
    });
    builder.addMatcher(assistantApi.endpoints.saveData.matchFulfilled, (state) => {
      state.currentStep += 1;
    });
  },
});

export const { clearState, updateCampaignObject, updateCurrentStep } = assistantSlice.actions;
export default assistantSlice;
