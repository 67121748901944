import styled from "styled-components";
import { Grid } from "styled-css-grid";
import { Button } from "react-bootstrap";

export const StyledSignedOrderSpan = styled.span`
  font-weight: bold;
  color: white;
  position: relative;
  z-index: 5;
  right: 22%;
`;

export const StyledPQuoteText = styled.p`
  padding: 15px;
  background-color: #f4f5f6;
  border-radius: 5px;
  font-style: italic;
  color: #2a3347;
  font-size: 0.875rem;
  clear: both;
`;

export const StylePrePatchImg = styled.img`
  z-index: 4;
  width: 100%;
  position: relative;
`;

export const StyleThePatchImg = styled.img`
  width: 80%;
  margin-top: -2rem;
  z-index: 3;
  position: relative;
  left: 10%;
`;

export const StylePostPatchImg = styled.img`
  width: 60%;
  margin-top: -0.5rem;
  z-index: 2;
  position: relative;
  left: 10%;
`;

export const StyleSignedOrderImg = styled.img`
  width: 45%;
  margin-top: -0.5rem;
  z-index: 1;
  position: relative;
  left: 13%;
`;

export const StyledGrid = styled(Grid)`
  padding: 0 auto;
  grid-gap: 0;
  height: auto;
  margin: 1rem auto;
`;

export const StyledEditButton = styled(Button)`
  position: relative;
  left: 75%;
`;
