const Error = "Error";
const successExportExclusions = {
  global: "Exclusion file export complete",
  email: "Email exclusion file export complete",
  responder: "Responder exclusion file export complete",
  domain: "Domain exclusion file export complete",
  unsubscribe: "Unsubscribe exclusion file export complete",
};
const generalSettingsSaved = "Settings updated";
const couldNotRemove = "Unable to remove";

export const toastMessages = {
  fileUploader: {
    errorFileNotImage: "File is not an image. Supported types are jpg and png",
  },
  audience: {
    listUpdated: "List updated.",
    successRestoreAudience: "List restored",
    successArchiveAudience: "List archived",
    setAudienceImportSettings: {
      updateSuccessful: "Update successful",
      soProData0Contacts: "No contacts returned - your list may still be bering created",
      soProDataFailed: "Error retrieving data. Please try again",
      soProDataError: "Error retrieving data. Please try again",
      updateError: "Error during update. Please try again",
      importStarted: "List assigned",
      importError: "Error during import",
      importFinished: "Import finished",
    },
  },
  audienceExplorer: {
    successCreateAudience: "List created",
  },
  campaign: {
    errorCampaignNameAllReadyTaken: "Name is already in use",
    errorChangeStatusFailed: Error,
    prospectMovedSuccessfully: "Prospect(s) moved successfully to the new Campaign.",
  },
  exclusions: {
    successEditResponderExclusions: "Responder exclusions updated",
    errorEditResponderExclusions: Error,
    successDeleteExclusion: "Exclusion deleted",
    successExportDomainExclusions: successExportExclusions.domain,
    successExportEmailExclusions: successExportExclusions.email,
    successExportResponderExclusions: successExportExclusions.responder,
    successExportUnsubscribeExclusions: successExportExclusions.unsubscribe,
    errorGetCrossCampaignDaysResponderExclusions: Error,
  },
  domains: {
    successEditTracking: "Tracking settings updated.",
    errorEditTracking: Error,
    successDeleteDomain: "Domain deleted",
  },
  sendingSchedule: {
    successCreateEdit: "Schedule updated",
  },
  rteDataContent: {
    successHandleDataRemoval: "Data removal request submitted",
  },
  dealNavigator: {
    successPublishDraftVersion: "Changes published",
    infoEnterEditMode: "Switched to edit mode",
    successDiscardDraftModel: "Changes discarded",
    errorAddingStage: "Error adding stage",
    errorAddingCard: "Error adding card",
    errorAddingElement: "Error adding element",
    errorChangeCard: "error changing card",
    errorChangeElement: "Error changing element",
    errorRemoveStage: "Error removing stage",
    errorRemoveCard: "Error removing card",
    errorResetValue: "Error reseting , type RESET in the box and then click Confirm.",
  },
  emailAccount: {
    successDeleteEmailAccount: "Email Account Deleted",
    errorDeleteEmailAccount: Error,
    successSendTestInfo: "Configuration test started. Your results will be visible here shortly.",
    successAddEmailAccount: "Email account is successfully added.",
    successConnectEmailAccount: "Your email account is connected.",
    errorConnectEmailAccount: "Your email account is not connected.",
    successChangePassword: "Password successfully updated",
    successSavedEmailAccount: "Email account settings have been successfully saved.",
  },
  emailTemplate: {
    errorCreateTemplate: "Error saving template",
    successDeleteTemplate: "Template deleted",
    successPublishTemplate: "Template successfully published",
    errorSendTestEmail: "Please complete the email text",
    successSendTestEmail: "Email sent",
    awesomenessEmptyTextError: "Please write the email text before trying to check the wording.",
    noPreviousDeliverabilityTestReport: "There are no previous deliverability test reports.",
    noNextDeliverabilityTestReport: "There are no next deliverability test reports.",
    aiWriterErrorMessage: "Sorry, our AI writer is currently experiencing some downtime. Please try again later.",
  },
  settings: {
    successGeneralSettingsUpdate: generalSettingsSaved,
    successOptionalSettingsUpdate: generalSettingsSaved,
    successChangeOrganizationPhoto: "Photo updated",
    successChangePassword: "Password updated",
  },
  billing: {
    successCreateUpdateBillingInfo: "Billing details updated",
    successAddBillingEmailAddress: "Billing address added",
    successDeletingEmailAddress: "Billing address deleted",
    successUpdateAutoRenewal: "Auto renewal updated",
    successDeleteBillingInfo: "Billing details deleted",
    successRequestReminderForSignedContract: "Reminder sent",
    successUpgradePricingPlan: "Pricing plan updated",
    warnNoPlansForSelectedPeriod: "No plans exist for the selected agreement period and billing period",
    warnNotEnoughCredits: "Not enough credits",
    warnNoBillingIfo: "Please add billing info first",
    warnNoEmailData: "Please add a valid email",
    successUpgradePricingPlanCall: "Pricing plan update in progress",
    cancelContractPending: "Request to cancel contract is sent",
    cancelScheduledDowngradeSuccess: "Scheduled changes successfully cancelled.",
  },
  users: {
    errorUserExists: "You are already a member",
    successResendInvite: "Invite resent",
    errorResendInvite: "Error inviting user",
    successEditUser: "User details updated",
    successLeaveOrganization: "You have left the organization",
    successDeleteOrganization: "Organization deleted",
    errorChangeUserRole: "Error updating role",
    errorRemoveUser: "error deleting user",
    //NewUserForm component ---- Success added user
    // toast.success("Successfully added " + values.email + ".", { autoClose: 30000 });
  },
  salesDocuments: {
    errorRemoveDocuments: couldNotRemove,
    errorRemoveImage: couldNotRemove,
    errorRemoveSalesDocuments: couldNotRemove,
  },
  prospects: {
    exportFileFinishedDownloading: "Prospects export file is completed.",
  },
  companies: {
    exportFileFinishedDownloading: "Companies export file is completed",
  },
  outboss: {
    organizations: {
      successCreditsAdjustment: "Credits successfully adjusted",
      successDisableAccess: "Access successfully disabled",
      successEnableAccess: "Access successfully enabled",
    },
    user: {
      successRemoveAccess: "Access successfully removed",
      successDisableAccess: "Access successfully disabled",
      successEnableAccess: "Access successfully enabled",
    },
  },
};
