export const emailAccountTypeEnum = {
  SmtpImap: 0,
  Gmail: 1,
  Microsoft: 2,
  Test: 100,
};

export const emailAccountTypeOptions = [
  { value: 0, label: "Smtp/Imap" },
  { value: 1, label: "Gmail" },
  { value: 2, label: "Microsoft" },
  { value: 100, label: "Test" },
];

export const emailAccountTypeOptionsString = [
  { value: "0", label: "Smtp/ Imap" },
  { value: "1", label: "Gmail" },
  { value: "2", label: "Microsoft" },
  { value: "100", label: "Test" },
];