import React from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

// Style
import styles from "./TestVariableStep.module.scss";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";

const TestVariableStep = (props) => {
  const { basicVariables, initialEmailVariables, customVariables } = props;
  return (
    <>
      {!_.isEmpty(basicVariables) ? (
        <Row>
          <Col xl={12}>
            <>
              <h6 className={styles.variablesTypeText}>Set how variables will display (optional):</h6>
              {basicVariables.map((variable, index) => {
                return (
                  <>
                    <label className="f12 mB5">{variable}</label>
                    <FormikInput
                      value={props.dictionaryValues[variable]}
                      placeholder={variable}
                      type="text"
                      name={"dictionary[" + variable + "]"}
                    />
                  </>
                );
              })}
            </>
          </Col>
        </Row>
      ) : null}

      {!_.isEmpty(initialEmailVariables) ? (
        <Row>
          <Col xl={12}>
            <>
              <h6 className={styles.variablesTypeText}>Set Initial Email Template Variables (optional):</h6>
              {initialEmailVariables.map((variable, index) => {
                return (
                  <>
                    <label className="f12 mB5">{variable}</label>
                    <FormikInput
                      value={props.initialEmailTemplateDictionaryValues[variable]}
                      placeholder={props.initialEmailTemplateDictionaryValues[variable]}
                      type="text"
                      name={"initialEmailTemplateDictionary[" + variable + "]"}
                    />
                  </>
                );
              })}
            </>
          </Col>
        </Row>
      ) : null}

      {!_.isEmpty(customVariables) ? (
        <Row>
          <Col xl={12}>
            <h6 className={styles.variablesTypeText}>Set Custom Variables (optional):</h6>
            {customVariables.map((variable, index) => {
              return (
                <>
                  <label className="f12 mB5">{variable}</label>
                  <FormikInput
                    value={props.dictionaryValues[variable]}
                    placeholder={variable}
                    type="text"
                    name={"dictionary[" + variable + "]"}
                  />
                </>
              );
            })}
          </Col>
        </Row>
      ) : null}

      {_.isEmpty(basicVariables || initialEmailVariables || customVariables) ? (
        <p className="f12 mT15">You have no variables used in the subject and your message body.</p>
      ) : (
        ""
      )}
    </>
  );
};

export default TestVariableStep;
