import React from "react";

// Styles
import styles from "./SingleCard.module.scss";

//Images & Icons
import GraphUp from "../../../assets/Icons/GraphUp/GraphUp";
import GraphDown from "../../../assets/Icons/GraphDown/GraphDown";

const SingleCard = (props) => {
  let icon = props.icon;
  let graph = null;
  let trend = Math.sign(props.trendValue);

  switch (trend) {
    case 1:
      graph = (
        <div className={styles.graphUp}>
          <GraphUp />
          <span>{props.trendValue}%</span>
        </div>
      );
      break;
    case -1:
      graph = (
        <div className={styles.graphDown}>
          <GraphDown />
          <span>{props.trendValue}%</span>
        </div>
      );
      break;
    default:
      break;
  }
  return (
    <div className={`${styles.cardWrapper} flex_center`}>
      {icon}
      <p className={styles.counter}>{props.value}</p>
      <p className={styles.description}>{props.name}</p>
      {graph}
      {/* <AreaLineChart
                chartWidth={150}
                chartHeight={50}
                data={props?.data}
            /> */}
    </div>
  );
};

export default SingleCard;
