import React from "react";

// Components
import Modal from "../../components/UI/Modal/Modal";
import TestResultsMessage from "./EmailAccountSettings/TestResultsMessage/TestResultsMessage";
import AlertLineIcon from "remixicon-react/AlertLineIcon";

const EmailExistsForm = (props) => {

    let messageLabel = null;
    messageLabel = (
        <b>Email account already exists</b>
    );
    let sendContent = null;
    sendContent = (
        <TestResultsMessage icon={<AlertLineIcon color='var(--danger-color)'/>} messageLabel={messageLabel} messageDetails={"This email account has already been added in Outbase. To review and edit account settings, click on the email account in the Email Account Manager."} />
      );
    return(
        <Modal 
        title="Email account connection"
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        show={props.showModal}
        oneButton={true}
        saveButtonText="OK"
        btnType="submit"
        backdrop={true}
        >{sendContent}
        </Modal>
    )
};

export default EmailExistsForm;