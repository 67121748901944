const excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
const word = ".doc, .docx";
const images = ".jpeg,.jpg,.png,.gif";

export const mimeFileTypesEnum = {
    imagesAll: images,
    videosAll: "video/*",
    excel: excel,
    word: word,
    pdf: ".pdf",
    documentsAll: [word, excel, ".pdf", ".ppt,.pptx,.odt,.txt", images].concat(","),
}