import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import Moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { useQuery } from "react-query";
import moment from "moment";

//Redux
import {
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../../store/Outboss/EmailAccounts/SyncLog/syncLog";
import { fetchSyncLogs } from "../../../../store/Outboss/EmailAccounts/SyncLog/syncLog";

//Helpers
import { numberOfRecords, filtersToQueryString, filterData } from "../../../../helper/tableConstants";
import { useOrgDateFormat } from "../../../../helper/hooks/useOrgDateFormat";

import api, { API, formUrl } from "../../../../helper/api/api";
//Style
import styles from "./EmailAccountDetails.module.scss";

//Images & Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

//Components
import TableFiltersRow from "../../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Table from "../../../../components/DataTable/Table";
import SkeletonTable from "../../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import TopBannerEmailAccount from "../../TopBannerUser/TopBannerEmailAccount";
import PageContentWrapper from "../../../../components/PageContentWrapper/PageContentWrapper";

const EmailAccountDetails = (props) => {
  const { param, rows, isInitiated, initiate, clearInitiated, fetchSyncLogs } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  const records = numberOfRecords[1];
  const [tableRows, setTableRows] = useState([]);
  let minDate = Moment.utc("0001-01-01").format(dateFormat);

  let emailAccountId = props.match.params.emailAccountId;
  const apiEndpoint = API.OUTBOSS.emailAccounts.emailAccountDetails;
  const url = formUrl(apiEndpoint, { id: emailAccountId });
  const { isLoading, error, data, isFetching } = useQuery([apiEndpoint[1]], () => api(url).then((response) => response.data));

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "EmailAccount", value: [emailAccountId] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchSyncLogs(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r, i) => {
      if (r.start && r.start !== null) {
        let startFormatted = moment(r.start).format("DD MMM YYYY");
        if (startFormatted > minDate) {
          r.start = (
            <span className="textLeft">
              {moment.utc(r.start).local().format("DD MMM YYYY HH:mm:ss")} (<i>{moment.utc(r.start).local().fromNow()}</i>)
            </span>
          );
        } else {
          r.start = "-";
        }
      } else {
        r.start = "-";
      }

      if (r.end && r.end !== null) {
        let endFormatted = moment(r.end).format("DD MMM YYYY");
        if (endFormatted > minDate) {
          r.end = (
            <span className="textLeft">
              {moment.utc(r.end).local().format("DD MMM YYYY HH:mm:ss")} (<i>{moment.utc(r.end).local().fromNow()}</i>)
            </span>
          );
        } else {
          r.end = "-";
        }
      } else {
        r.end = "-";
      }

      if (r.syncFromDate && r.syncFromDate !== null) {
        let syncFromDateFormatted = moment(r.syncFromDate).format("DD MMM YYYY");
        if (syncFromDateFormatted > minDate) {
          r.syncFromDate = (
            <span className="textLeft">
              {moment.utc(r.syncFromDate).local().format("DD MMM YYYY HH:mm:ss")} (<i>{moment.utc(r.syncFromDate).local().fromNow()}</i>)
            </span>
          );
        } else {
          r.syncFromDate = "-";
        }
      } else {
        r.syncFromDate = "-";
      }

      r.emailsLoadedError = (
        <span style={{ color: r.emailsLoadedError > 0 ? "#D50000" : "#CCC", fontWeight: "bold" }}>{r.emailsLoadedError}</span>
      );
      r.emailsProcessedError = (
        <span style={{ color: r.emailsProcessedError > 0 ? "#D50000" : "#CCC", fontWeight: "bold" }}>{r.emailsProcessedError}</span>
      );
      r.emailsStoredError = (
        <span style={{ color: r.emailsStoredError > 0 ? "#D50000" : "#CCC", fontWeight: "bold" }}>{r.emailsStoredError}</span>
      );

      r.isSuccess = r.isSuccess === true ? <CheckboxCircleFillIcon size={20} color="var(--first-stage-color)" /> : "";

      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>{props.error}</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table = (
      <Table
        outBoss
        tableName={"Sync log"}
        tableClassName={`${styles.userDetailsTable} orgFiltersTable`}
        columns={columns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        tableFooterClass="datePickerTable"
        scrollY={true}
        variant="medium"
        tableHeight={260}
        filterData={filterData}
        setFilter={props.setFilter}
      />
    );
  }

  const breadcrumbs = [
    {
      href: "/outboss/email-accounts",
      text: "Email accounts",
    },
    { active: true, text: data?.name },
  ];

  return (
    <PageContentWrapper className={styles.contentWrapper}>
      <TopBannerEmailAccount
        breadcrumbs={breadcrumbs}
        emailAccountName={data?.name}
        emailAccountEmail={data?.email}
        emailAccountId={data?.id}
      />
      <TableFiltersRow placeholder="organization" changeFilter={(text) => props.searchRecords(text)} searchOnFirstCharacter={true} />
      {table}
    </PageContentWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    userPermissions: state.user.userPermissions,
    param: state.outbossSyncLogs.params,
    isInitiated: state.outbossSyncLogs.isInitiated,
    isLoading: state.outbossSyncLogs.isLoading,
    isLoaded: state.outbossSyncLogs.isLoaded,
    error: state.outbossSyncLogs.error,
    rows: state.outbossSyncLogs.rows,
    totalCount: state.outbossSyncLogs.totalCount,
    pageCount: state.outbossSyncLogs.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSyncLogs: (param) => dispatch(fetchSyncLogs(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAccountDetails);
