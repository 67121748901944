import React from "react";
// import svg from "./archive_icon.svg";

const IconContactBook = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 0.666748H13.8375C14.7558 0.666748 15.5 1.41508 15.5 2.32508V15.6751C15.5 16.5909 14.7558 17.3334 13.8375 17.3334H0.5V0.666748ZM3.83333 2.33341H2.16667V15.6667H3.83333V2.33341ZM5.5 15.6667H13.8333V2.33341H5.5V15.6667ZM7.16667 12.3334C7.16667 11.6704 7.43006 11.0345 7.8989 10.5656C8.36774 10.0968 9.00362 9.83342 9.66667 9.83342C10.3297 9.83342 10.9656 10.0968 11.4344 10.5656C11.9033 11.0345 12.1667 11.6704 12.1667 12.3334H7.16667ZM9.66667 9.00008C9.22464 9.00008 8.80072 8.82449 8.48816 8.51193C8.17559 8.19937 8 7.77544 8 7.33342C8 6.89139 8.17559 6.46746 8.48816 6.1549C8.80072 5.84234 9.22464 5.66675 9.66667 5.66675C10.1087 5.66675 10.5326 5.84234 10.8452 6.1549C11.1577 6.46746 11.3333 6.89139 11.3333 7.33342C11.3333 7.77544 11.1577 8.19937 10.8452 8.51193C10.5326 8.82449 10.1087 9.00008 9.66667 9.00008ZM16.3333 4.00008H18V7.33342H16.3333V4.00008ZM16.3333 9.00008H18V12.3334H16.3333V9.00008Z"
      fill="#FE7904"
    />
  </svg>
);

export default IconContactBook;
