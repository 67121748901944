import React, { Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

// Styles
import styles from "./ConversationPreview.module.scss";

//Images & Icons
import MessagePreview from "../../../../assets/Icons/SectionMessagePreview/MessagePreview";
import EmptyTemplatesImage from "../../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

//Components
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import SectionPreview from "../SectionPreview/SectionPreview";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";

const ConversationPreview = (props) => {
  const { conversationPreview } = props;
  let image = <EmptyTemplatesImage />;

  return (
    <>
      <div className={styles.conversationPreviewWrapper}>
        <SectionTitle title="CONVERSATION PREVIEW" icon={<MessagePreview />} />
        <div className={styles.selectedConversation}>
          {!_.isEmpty(conversationPreview) ? (
            conversationPreview.map((c, index) => (
              <Fragment key={index}>
                <SectionPreview sender={c.key} responder={c.value} />
              </Fragment>
            ))
          ) : (
            <EmptyState
              image={image}
              title="No message selected yet"
              description="Please select a message in the list to the left"
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    conversationPreview: state.conversation.conversationPreview,
  };
};

export default connect(mapStateToProps)(ConversationPreview);
