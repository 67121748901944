import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Style
import styles from "./OrganizationInfoColOutbOss.module.scss";

const OrganizationInfoColOutbOss = (props) => {
  return (
    <div className={styles.componentWrapper}>
      <OverlayTrigger
        key={props.key}
        placement={"bottom"}
        overlay={
          <Popover id={`popover-${props.name}`}>
            <Popover.Body>{props.name}</Popover.Body>
          </Popover>
        }
      >
        <div className="text-overflow-hidden max-x-content">
          <span className="tableLink">
            <b>{props.name}</b>
          </span>
        </div>
      </OverlayTrigger>
      <div className={`${styles.orgDetails} flex`}>
        <p>By {props.owner}</p>
      </div>
    </div>
  );
};

export default OrganizationInfoColOutbOss;
