import React from "react";

//Styles
import styles from "./EnterPrise.module.scss";

//Components
import Button from "../../../../components/UI/Button/Button";

const EnterPrise = (props) => {
  const handleRedirect = (e) => {
    e.preventDefault();
    window.location.href = "https://outbase.com/contact/";
  };
  return (
    <div className={`${styles.enterPriceComponentWrapper} flex`}>
      <div className={styles.info}>
        <h1>Enterprise</h1>
        <p className={styles.subtitle}>Keep 10+ sales people busy</p>
        <p className={`${styles.InfoDetails} m0`}>
          Outbase has been designed to add ease, value and results to every aspect of your prospecting.
        </p>
      </div>
      <div className={styles.details}>
        <ul>
          <li>Dedicated support</li>
          <li>Source unlimited prospects</li>
          <li>Send unlimited emails</li>
        </ul>
      </div>
      <div className={styles.details}>
        <ul>
          <li>Reveal phone numbers</li>
          <li>Unlimited user accounts</li>
        </ul>
      </div>
      <div className={`${styles.buttonWrapper} flex_center`}>
        <Button id="callUsNow" onClick={(e) => handleRedirect(e)} variant="dark">
          Call us now
        </Button>
      </div>
    </div>
  );
};

export default EnterPrise;
