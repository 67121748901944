import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

//Redux
import { setDictionaryValues } from "@store/EmailTemplates/Global/actions/emailTemplates";
import { fetchOrganizationEmailAccountsList } from "@store/EmailAccounts/Global/actions/emailAccounts";
import {
  fetchEmailTemplates,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "@store/EmailTemplates/Table/actions/emailTemplates";

//Helpers
import { useOrgDateFormat } from "@helper/hooks/useOrgDateFormat";
import { PERMISSIONS } from "@helper/permissionConstants";
import { permissionHelper } from "@helper/permissionHelper";
import api, { API, formUrl } from "@helper/api/api";
import { formatNumberHelper } from "@helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "@helper/hooks/useGroupDecimalSeparator";
import useSelectList from "@helper/hooks/query/useSelectList";
import { SelectListEnum } from "@helper/hooks/query/SelectListEnum";
import { useQueryTableFilters } from "@helper/hooks/useQueryTableFilters";
import { filtersToQueryString } from "@helper/tableConstants";
import { checkForIncorrectVariables } from "@helper/variablesHelper";
import { emailTemplateStatusEnum } from "@helper/enums/emailTemplateStatusEnum";
import { toastMessages } from "@helper/toastMessagesConstants";

//Images & Icons
import EmptyTemplatesImage from "@assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

//Components
import { columns } from "../columns";
import { filterData, getFilterValue } from "@helper/tableConstants";
import DeleteEmailTemplateForm from "../DeleteEmailTemplateForm";
import PreviewEmailTemplate from "../PreviewEmailTemplate";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import Permission from "@components/auth/Permission";
import Table from "@components/DataTable/Table";
import ActionDelete from "@components/UI/ActionWrapper/Actions/ActionDelete";
import ActionEdit from "@components/UI/ActionWrapper/Actions/ActionEdit";
import ActionWrapper from "@components/UI/ActionWrapper/ActionWrapper";
import SendTestEmailForm from "../SendTestEmailForm";
import SkeletonTable from "@components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "@components/UI/EmptyState/EmptyState";
import TemplateNameColumn from "./TemplateNameColumn/TemplateNameColumn";
import ErrorVariablesModal from "../ErrorVariablesModal/ErrorVariablesModal";
import StatusColumn from "./StatusColumn/StatusColumn";

//Hooks
import { useQuery } from "@helper/hooks/useQuery";
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const Templates = (props) => {
  let dateFormat = useOrgDateFormat();
  let separators = useGroupDecimalSeparator();

  const { param, rows, fetchEmailTemplates, isInitiated, initiate, fetchEmailTemplateTypes, clearInitiated } = props;
  const [tableRows, setTableRows] = useState([]);
  const [countActiveCampaigns, setCountActiveCampaigns] = useState(0);
  const [listCampaigns, setListCampaigns] = useState([]);

  const { data: listEmailTemplateTypes } = useSelectList(SelectListEnum.ListEmailTemplateTypes);
  const { data: listEmailTemplatesStatus } = useSelectList(SelectListEnum.ListEmailTemplateStatus);

  var hasEditPermission = permissionHelper(props.userRole, PERMISSIONS.emailTemplates.edit) || permissionHelper(props.userRole, PERMISSIONS.emailTemplates.create);
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery({ skip: !hasEditPermission});
  let table = null;

  let image = <EmptyTemplatesImage />;
  let filterTypes = ["Type", "Status"];
  var state = useQueryTableFilters(filterTypes);
  
  useEffect(() => {
    initiate(state);
    return () => {
      clearInitiated();
    };
  }, []);

  let query = useQuery();
  useEffect(() => {
    if (isInitiated) {
      fetchEmailTemplates(param);
      if (permissionHelper(props.userRole, PERMISSIONS.emailAccounts.view)) props.fetchOrganizationEmailAccountsList(0);

      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param, null, query);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  const [showDeleteEmailTemplateModal, setShowDeleteEmailTemplateModal] = useState(false);
  const [emailTemplateId, setEmailTemplateId] = useState(0);
  const [emailTemplateName, setEmailTemplateName] = useState("");
  const handleDeleteEmailTemplateClose = () => setShowDeleteEmailTemplateModal(false);
  const handleDeleteEmailTemplateShow = (emailTemplate) => {
    let emailTemplateId = emailTemplate.id;
    api(
      formUrl(API.emailTemplates.getDeleteDetails, {
        emailTemplateId: emailTemplateId,
      })
    )
      .then((res) => {
        setCountActiveCampaigns(res.data.activeCampaigns);
        setListCampaigns(res.data.campaigns);
        setEmailTemplateName(emailTemplate.name.props.templateName);
        setEmailTemplateId(emailTemplateId);
        setShowDeleteEmailTemplateModal(true);
      })
      .catch((error) => {});
  };

  const [showPreviewEmailTemplateModal, setShowPreviewEmailTemplateModal] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState({
    emailTemplateHtml: "",
    emailTemplateText: "",
    emailTemplateSubject: "",
    emailTemplateName: "",
    emailTemplateType: "",
    emailTemplateStatus: 0,
  });
  const handlePreviewEmailTemplateClose = () => setShowPreviewEmailTemplateModal(false);
  const handlePreviewEmailTemplateShow = (emailTemplate) => {
    let templateTemp = {
      emailTemplateHtml: emailTemplate.html,
      emailTemplateText: emailTemplate.text,
      emailTemplateSubject: emailTemplate.subject,
      emailTemplateName: emailTemplate.name.props.templateName,
      emailTemplateType: emailTemplate.type,
      emailTemplateStatus: emailTemplate.status.props.status,
    };
    setEmailTemplateId(emailTemplate.id);
    setEmailTemplate(templateTemp);
    setShowPreviewEmailTemplateModal(true);
  };

  const [showTestEmailFormModal, setShowTestEmailFormModal] = useState(false);
  const [showErrorVariablesModal, setShowErrorVariablesModal] = useState(false);
  const combineVariablesArray = emailTemplateVariables?.flatMap((templateVariable) => templateVariable.emailTemplateVariables) ?? [];
  const handleCloseErrorVariablesModal = () => {
    setShowErrorVariablesModal(false);
  };
  const handleTestMailFormClose = () => setShowTestEmailFormModal(false);
  const handleTestEmailFormShow = (emailTemplate) => {
    handlePreviewEmailTemplateClose();
    if (emailTemplate.emailTemplateStatus === emailTemplateStatusEnum.Draft) {
      let htmlAndSubject = emailTemplate.emailTemplateHtml.concat(" ", emailTemplate.emailTemplateSubject);
      let incorrect = checkForIncorrectVariables(htmlAndSubject, combineVariablesArray);
      if (incorrect?.length > 0) {
        setShowErrorVariablesModal(true);
      } else {
        setShowTestEmailFormModal(true);
      }
    } else {
      setShowTestEmailFormModal(true);
    }
  };

  const editTemplate = (emailTemplateId) => {
    props.history.push("/" + props.orgId + "/email-template/" + emailTemplateId + "/edit");
  };

  const handlePublish = (emailTemplate) => {
    let data = {
      id: emailTemplateId,
      organizationId: props.orgId,
      name: emailTemplate.emailTemplateName,
      subject: emailTemplate.emailTemplateSubject,
      text: emailTemplate.emailTemplateText,
      html: emailTemplate.emailTemplateHtml,
      type: emailTemplate.emailTemplateType === "Follow Up" ? 2 : 1,
      status: 1,
    };
    api(API.emailTemplates.create, data)
      .then(() => {
        handlePreviewEmailTemplateClose();
        fetchEmailTemplates(param);
        toast.success(toastMessages.emailTemplate.successPublishTemplate);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);

    tempRows.map((r, index) => {
      let actions = (
        <Permission has={PERMISSIONS.emailTemplates.delete}>
          <ActionWrapper>
            <ActionEdit key={"edit" + index} onClick={() => editTemplate(r.id)} spanClass={r.name.replaceAll(" ", "")} />
            <ActionDelete key={"delete" + index} onClick={() => handleDeleteEmailTemplateShow(r)} />
          </ActionWrapper>
        </Permission>
      );

      r.actions = actions;

      r.name = (
        <TemplateNameColumn
          id={r.id}
          templateName={r.name}
          subject={r.subject}
          createdByName={r.createdBy}
          createdFirstName={r.createdByFirstName}
          createdLastName={r.createdByLastName}
          dateCreated={Moment(r.dateCreated).format(dateFormat)}
          purchaseTemplateId={r.purchaseTemplateId}
          onClick={() => handlePreviewEmailTemplateShow(r)}
        />
      );

      r.status = <StatusColumn status={r.status} />;

      r.openRate = r.emailsDelivered > 0 ? r.openRate.toFixed(2) + "%" : "-";
      r.clickRate = r.emailsDelivered > 0 ? r.clickRate.toFixed(2) + "%" : "-";
      r.leadRate = r.emailsDelivered > 0 ? r.leadRate.toFixed(2) + "%" : "-";

      let emailsDelivered = formatNumberHelper(r.emailsDelivered, separators.numberGroupSeperator, separators.numberDecimalSeperator);
      r.emailsDelivered = emailsDelivered;

      r.dateCreated = Moment(r.dateCreated).format(dateFormat);

      r.lastUsed = r.lastUsed != null && r.lastUsed !== "" ? <span>{Moment(r.lastUsed).format(dateFormat)}</span> : "/";

      r.dateModified =
        r.dateModified && r.modifiedByFirstName && r.modifiedByLastName ? (
          <div className="text-overflow-hidden">
            <span>
              {" "}
              {`${r.modifiedByFirstName}
              ${r.modifiedByLastName.charAt(0).toUpperCase()}.`}{" "}
            </span>
            <span>{Moment(r.dateModified).format(dateFormat)}</span>
          </div>
        ) : (
          "/"
        );

      return r;
    });

    setTableRows(tempRows);
  }, [rows]);

  if (props.error) {
    table = <h2>Error loading Email Templates</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Email Templates"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          scrollY={true}
          scrollX={true}
          tableHeight={290}
          variant="large"
        />
      ) : (
        <EmptyState
          componentClass="positionCenter"
          image={image}
          title="No email templates found"
          content="Let's start with creating your first email template."
          description='Click on the "New Template" button above.'
        />
      );
  }

  let selectRowOptions = [
    {
      option: listEmailTemplateTypes,
      change: (c) => filterData("Type", c, props.setFilter),
      placeholder: "Type of Email",
      value: getFilterValue(param.filter, "Type"),
    },
    // Placeholder for Status filter, currently does not exist in the object, for now fetches the same options as Type
    {
      option: listEmailTemplatesStatus,
      change: (c) => filterData("Status", c, props.setFilter),
      placeholder: "Status",
      value: getFilterValue(param.filter, "Status"),
    },
  ];

  return (
    <>
      <TableFiltersRow
        selects={selectRowOptions}
        placeholder="message templates"
        changeFilter={(text) => props.searchRecords(text)}
        searchValue={param.search}
      />
      {table}
      <DeleteEmailTemplateForm
        handleClose={handleDeleteEmailTemplateClose}
        showModal={showDeleteEmailTemplateModal}
        emailTemplateId={emailTemplateId}
        getEmailTemplates={() => fetchEmailTemplates(param)}
        countActiveCampaigns={countActiveCampaigns}
        listCampaigns={listCampaigns}
        emailTemplateName={emailTemplateName}
      />
      <PreviewEmailTemplate
        handleClose={handlePreviewEmailTemplateClose}
        handleEdit={() => editTemplate(emailTemplateId)}
        handlePublish={() => handlePublish(emailTemplate)}
        showModal={showPreviewEmailTemplateModal}
        emailTemplateId={emailTemplateId}
        orgId={props.orgId}
        emailTemplate={emailTemplate}
        handleTestEmailFormShow={handleTestEmailFormShow}
        saveButtonPermission={PERMISSIONS.emailTemplates.edit}
        thirdButtonPermission={PERMISSIONS.emailTemplates.sendTestEmail}
      />
      <Permission has={PERMISSIONS.emailTemplates.sendTestEmail}>
        <SendTestEmailForm
          handleClose={handleTestMailFormClose}
          showModal={showTestEmailFormModal}
          userEmail={props.userEmail}
          emailTemplate={emailTemplate}
          emailAccounts={props.emailAccounts}
          templateDictionary={props.templateDictionary}
          setDictionaryValues={props.setDictionaryValues}
        />
      </Permission>
      <ErrorVariablesModal
        handleClose={handleCloseErrorVariablesModal}
        handleSubmit={() => setShowErrorVariablesModal(false)}
        showModal={showErrorVariablesModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.emailTemplates.params,
    isInitiated: state.emailTemplates.isInitiated,
    isLoading: state.emailTemplates.isLoading,
    isLoaded: state.emailTemplates.isLoaded,
    error: state.emailTemplates.error,
    rows: state.emailTemplates.rows,
    totalCount: state.emailTemplates.totalCount,
    pageCount: state.emailTemplates.pageCount,
    orgId: state.user.organization.id,
    userEmail: state.user.email,
    emailAccounts: state.globalEmailAccounts.listEmailAcconts,
    templateDictionary: state.globalEmailTemplate.dictionary,
    userRole: state.user.organization !== undefined ? state.user.organization.role : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailTemplates: (param) => dispatch(fetchEmailTemplates(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
    fetchOrganizationEmailAccountsList: () => dispatch(fetchOrganizationEmailAccountsList()),
    setDictionaryValues: (dictionary) => dispatch(setDictionaryValues(dictionary)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
