import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { connect } from "react-redux";
import _ from "lodash";
import { NavLink, Link } from "react-router-dom";

//Redux
import {
  changePage,
  fetchCampaignPerformanceByCampaign,
  initiate,
  numRecords,
} from "../../../../store/Dashboard/Tables/actions/CampaignPerformanceCampaignView/campaignPerformanceByCampaign";

//Helpers
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";
import { addOffsetToDate } from "../../../../helper/dateHelper";

//Styles
import styles from "./CampaignPerformance.module.scss";

//Components
import Table from "../../../../components/DataTable/Table";
import StatisticWidget from "../../Components/StatisticWidget/StatisticWidget";
import RateWidget from "../../Components/RateWidget/RateWidget";

const CampaignPerformance = (props) => {
  const { params, isInitiated, rows, totalCount, pageCount, startDate, endDate, totalCountCampaigns, uniqueToggle } = props;

  const [tableRows, setTableRows] = useState([]);
  const organizationId = useOrganizationId();
  let table = null;
  const shouldShowPagination = totalCount > 5;

  useEffect(() => {
    let state = {
      params: {
        length: 5,
      },
    };

    props.initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      props.fetchCampaignPerformanceByCampaign(addOffsetToDate(startDate), addOffsetToDate(endDate), params);
    }
  }, [params, isInitiated, startDate, endDate]);

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r, index) => {
      r.data = (
        <div className={styles.dataWrapper} key={index}>
          <div className={styles.detailsLink}>
            <NavLink className="tableLink" to={`/${organizationId}/campaigns/${r.id}/overview`}>
              <span className={styles.title}>{r.campaignName}</span>
            </NavLink>
          </div>
          <div className={styles.statisticsWrapper}>
            <div className={styles.statistics}>
              <div className={styles.component}>
                <StatisticWidget
                  title="Engagements"
                  total={r.campaignPerformanceModel.prospectsEngaged}
                  emailsSent={r.campaignPerformanceModel.emailsSent}
                />
              </div>
              <div className={styles.component}>
                {uniqueToggle === 0 ? (
                  <RateWidget
                    width={60}
                    height={30}
                    classRate="openRate"
                    name={"Total Open Rate"}
                    tooltipContent={
                      <>
                        Number of emails opened, shown as percentage
                        <br />
                        <b>Formula:</b> Total emails open/Total emails delivered
                      </>
                    }
                    rate={r.campaignPerformanceModel.openRate}
                    totalNumber={r.campaignPerformanceModel.opened}
                    dataAreaLine={r.campaignPerformanceModel.openRateChartModel}
                    trend={r.campaignPerformanceModel.openRateTrend}
                    globalRate={props.openRate}
                  />
                ) : (
                  <RateWidget
                    width={60}
                    height={30}
                    classRate="openRate"
                    name={"Unique Open Rate"}
                    tooltipContent={
                      <>
                        Number of campaign engagements that opened at least one email in your campaign(s), shown as percentage
                        <br />
                        <b>Formula:</b> Unique opens/total engagements
                      </>
                    }
                    rate={r.campaignPerformanceModel.uniqueOpenRate}
                    totalNumber={r.campaignPerformanceModel.uniqueOpened}
                    dataAreaLine={r.campaignPerformanceModel.uniqueOpenRateChartModel}
                    trend={r.campaignPerformanceModel.uniqueOpenRateTrend}
                    globalRate={props.uniqueOpenRate}
                  />
                )}
              </div>
              <div className={styles.component}>
                {uniqueToggle === 0 ? (
                  <RateWidget
                    width={60}
                    height={30}
                    classRate="clickRate"
                    name={"Total Click Rate"}
                    tooltipContent={
                      <>
                        Number of emails clicked, shown as percentage - excludes unsubscribe link
                        <br />
                        <b>Formula:</b> Links clicked/emails delivered
                      </>
                    }
                    rate={r.campaignPerformanceModel.clickRate}
                    totalNumber={r.campaignPerformanceModel.clicked}
                    dataAreaLine={r.campaignPerformanceModel.clickRateChartModel}
                    trend={r.campaignPerformanceModel.clickRateTrend}
                    globalRate={props.clickRate}
                  />
                ) : (
                  <RateWidget
                    width={60}
                    height={30}
                    classRate="clickRate"
                    name={"Unique Click Rate"}
                    tooltipContent={
                      <>
                        Number of campaign engagements that clicked on at least one link in your email(s), shown as percentage
                        <br />
                        <b>Formula:</b> Unique clicks/total engagements
                      </>
                    }
                    rate={r.campaignPerformanceModel.uniqueClickRate}
                    totalNumber={r.campaignPerformanceModel.uniqueClicked}
                    dataAreaLine={r.campaignPerformanceModel.uniqueClickRateChartModel}
                    trend={r.campaignPerformanceModel.uniqueClickRateTrend}
                    globalRate={props.uniqueClickRate}
                  />
                )}
              </div>
              <div className={styles.component}>
                <RateWidget
                  width={60}
                  height={30}
                  classRate="responseRate"
                  name={"Response Rate"}
                  tooltipContent={
                    <>
                      Percentage of engagements that have replied to your email(s), including leads and decline
                      <br />
                      <b>Formula:</b> (leads + declines)/total engagements
                    </>
                  }
                  rate={r.campaignPerformanceModel.responseRate}
                  totalNumber={r.campaignPerformanceModel.responded}
                  dataAreaLine={r.campaignPerformanceModel.responseRateChartModel}
                  trend={r.campaignPerformanceModel.responseRateTrend}
                  globalRate={props.responseRate}
                />
              </div>
              <div className={styles.component}>
                <RateWidget
                  width={60}
                  height={30}
                  classRate="leadRate"
                  name={"Lead Rate"}
                  tooltipContent={
                    <>
                      Percentage of prospects that have become leads
                      <br />
                      <b>Formula:</b> leads/total engagements
                    </>
                  }
                  rate={r.campaignPerformanceModel.leadsRate}
                  totalNumber={r.campaignPerformanceModel.lead}
                  dataAreaLine={r.campaignPerformanceModel.leadRateChartModel}
                  trend={r.campaignPerformanceModel.leadsRateTrend}
                  globalRate={props.leadRate}
                />
              </div>
            </div>
          </div>
        </div>
      );

      return r;
    });

    setTableRows(tempRows);
  }, [rows, totalCountCampaigns, uniqueToggle]);

  table = (
    <Table
      tableName={"Campaign view"}
      columns={columns}
      rows={tableRows}
      pageCount={pageCount}
      totalCount={shouldShowPagination}
      parentCallback={props.changePage}
      param={params}
      changeNumRecords={props.numRecords}
      tableClassName={styles.accordionTable}
      tableFooterClass={styles.stylePagination}
      scrollY={false}
      wrapperTableClass="no-header-table"
      variant="tableWithComponents"
      showPerPageContent={false}
      noRecordsFoundLabel={
        <span>
          No campaign information to display.{" "}
          <Link className="tableLink" to={{ pathname: "/" + organizationId + "/campaigns" }}>
            Click here to view or add a new campaign
          </Link>
        </span>
      }
    />
  );
  return <div className={styles.accordionWrapper}>{table}</div>;
};

const mapStateToProps = (state) => {
  return {
    params: state.campaignPerformanceByCamapaignTable.params,
    isLoaded: state.campaignPerformanceByCamapaignTable.isLoaded,
    isInitiated: state.campaignPerformanceByCamapaignTable.isInitiated,
    rows: state.campaignPerformanceByCamapaignTable.rows,
    totalCount: state.campaignPerformanceByCamapaignTable.totalCount,
    pageCount: state.campaignPerformanceByCamapaignTable.pageCount,
    totalCountCampaigns: state.dashboard.campaignsInfo.totalCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaignPerformanceByCampaign: (startDate, endDate, params) =>
      dispatch(fetchCampaignPerformanceByCampaign(startDate, endDate, params)),
    initiate: (state) => dispatch(initiate(state)),
    changePage: (page) => dispatch(changePage(page)),
    numRecords: (obj) => dispatch(numRecords(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPerformance);
