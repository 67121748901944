import React, { useState, useEffect } from "react";

//Redux
import { connect } from "react-redux";
import { fetchDealNavigatorTemplate } from "../../../../../store/DealNavigator/actions/dealNavigator";

//Style
import styles from "./EmptyDealNavigatorModal.module.scss";

//Icons
import AddLineIcon from "remixicon-react/AddLineIcon";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import Spinner from "../../../../../components/UI/Spinner/Spinner";

const EmptyDealNavigatorModal = (props) => {
  const { handleSubmit, fetchDealNavigatorTemplate, isLoadingTemplate, isLoadedTemplate, templates, orgId } = props;
  const [templateId, setTemplateId] = useState(null);

  useEffect(() => {
    fetchDealNavigatorTemplate();
  }, []);

  let listTemplates = null;

  if (isLoadingTemplate) {
    listTemplates = <Spinner />;
  }

  if (isLoadedTemplate) {
    listTemplates = templates.map((temp) => {
      return (
        <div className={styles.templateDetails} key={temp.id}>
          <button onClick={() => setTemplateId(temp.id)} className={styles.navigatorTemplateButton}>
            {temp.imageUrl == null ? <AddLineIcon size={25} className="iconGray500" /> : <img src={temp.imageUrl} alt="template" />}
          </button>
          <>
            <h3>{temp.name}</h3>
            <p>{temp.description}</p>
          </>
        </div>
      );
    });
  }

  return (
    <Modal
      title="Deal Navigator"
      handleClose={props.handleClose}
      handleSave={() => handleSubmit(templateId)}
      show={props.showModal}
      oneButton={true}
      saveButtonText={orgId === 1 ? "Edit template" : " Continue"}
      // saveButtonDisabled={isSubmitting}
      btnType="submit"
      size="lg"
    >
      <div>
        <h3 className={styles.modalHeader}>Select an existing Deal Navigator sample or create a new one from scratch</h3>
        <div className={styles.modalContent}>{listTemplates}</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
    templates: state.dealNavigator.templates,
    isLoadingTemplate: state.dealNavigator.isLoadingTemplate,
    isLoadedTemplate: state.dealNavigator.isLoadedTemplate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDealNavigatorTemplate: () => dispatch(fetchDealNavigatorTemplate()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyDealNavigatorModal);
