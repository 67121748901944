import { DropDownIncludeModel } from "@models/DropDownIncludeModel";
import { DropdownModel } from "@models/DropDownModel";

export interface SearchModel {
  searchId: string;
  version: number;
  contact: ContactFilters;
  company: CompanyFilters;
}

export interface ContactFilters {
  names: string[];
  jobTitles: DropDownIncludeModel[];
  departments: string[];
  seniorities: string[];
  location: LocationFilter;
}

export interface CompanyFilters {
  names: DropDownIncludeModel[];
  keywords: DropDownIncludeModel[];
  sizes: string[];
  industries: DropDownIncludeModel[];
  domains: DropDownIncludeModel[];
  naicsAndSicCodes: NaicsAndSicCodesFilter;
  technologies: DropdownModel[];
  revenue: string[];
  location: LocationFilter;
  companyHeadcountGrowth: CompanyHeadcountGrowthFilter;
  companyFunding: CompanyFundingFilter;
  companyFoundedYear: CompanyFoundedYearFilter;
}

export interface LocationFilter {
  type: LocationFilterType;
  region: LocationFilterRegion;
  range: LocationFilterRange;
}

export interface LocationFilterRegion {
  locations: DropDownIncludeModel[];
}

export interface LocationFilterRange {
  location: LocationFilterPlace;
  rangeMiles: number;
}

export interface LocationRangeDropdown {
  label: string;
  value: LocationFilterPlace;
}

export interface LocationFilterPlace {
  name: string;
  longitude: number;
  latitude: number;
  range?: number;
}

export enum LocationFilterType {
  Region,
  Range,
}

export interface NaicsAndSicCodesFilter {
  type: NaicsAndSicCodesType;
  naicsCodes: DropDownIncludeModel[];
  sicCodes: DropDownIncludeModel[];
}

export enum NaicsAndSicCodesType {
  Naics,
  Sic,
}

export interface CompanyHeadcountGrowthFilter {
  min: number | null;
  max: number | null;
  timeframe: CompanyRevenueGrowthTimeframeType | null;
}

export enum CompanyRevenueGrowthTimeframeType {
  None,
  Last6Months,
  Last12Months,
  Last24Months,
}

export interface CompanyFundingFilter {
  min: number | null;
  max: number | null;
  lastFundingRoundType: string[];
  dateFrom: Date | null;
  dateTo: Date | null;
}

export interface CompanyFoundedYearFilter {
  dateFrom: Date | null;
  dateTo: Date | null;
}
