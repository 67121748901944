import { api } from "./api";
import { DTParameters } from "../models/dataTables/DtParameters";
import { DTResult } from "../models/dataTables/DtResult";
import { TemplatesDataTableModel } from "@models/emailTemplate/TemplatesDataTableModel";
import { EmailTemplateVariableTypeModel } from "@models/emailTemplate/EmailTemplateVariableTypeModel";

export const emailTemplateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query<DTResult<TemplatesDataTableModel>, DTParameters>({
      query: (params) => ({
        url: "/email-templates/email-templates",
        method: "POST",
        body: params,
      }),
      providesTags: ["EmailTemplates"],
      keepUnusedDataFor: 0,
    }),
    getTemplateVariables: builder.query<EmailTemplateVariableTypeModel[], void>({
      query: () => ({
        url: "/email-templates/get-email-template-variables",
        method: "GET",
      }),
      providesTags: ["EmailTemplatesVariables"],
    }),
  }),
});

export const { 
useGetTemplatesQuery,
useGetTemplateVariablesQuery
} = emailTemplateApi;
