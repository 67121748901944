import React, { useEffect } from "react";
import { connect } from "react-redux";

//Redux
import { fetchSupportCenterComplianceSettings } from "../../../store/ComplianceSupportCenter/actions/supportCenterQA";

//Images & Icons
import SectionInbox from "../../../assets/Icons/SectionInbox/SectionInbox";

//Components
import UnsubscribeEmailNotifications from "./UnsubscribeEmailNotifications";
import ExternalUnsubscribeService from "./ExternalUnsubscribeService";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";

const UnsubscribeSettings = (props) => {
  const { fetchSupportCenterComplianceSettings } = props;
  const {
    id,
    unsubscribeExternalService,
    unsubscribeExternalUrl,
    unsubscribeNotificationType,
    unsubscribeEmail,
    unsubscribePassword,
  } = props.unsubscribeSettings;

  useEffect(() => {
    fetchSupportCenterComplianceSettings();
  }, []);

  let emailNotifications = (
    <UnsubscribeEmailNotifications
      unsubscribeNotificationType={unsubscribeNotificationType}
      unsubscribeEmail={unsubscribeEmail}
      unsubscribePassword={unsubscribePassword}
    />
  );

  let externalServiceJSX = (
    <ExternalUnsubscribeService
      unsubscribeExternalService={unsubscribeExternalService}
      unsubscribeExternalUrl={unsubscribeExternalUrl}
    />
  );

  return (
    <>
      <div>
        <SectionTitle
          icon={<SectionInbox />}
          title="Unsubscribe email notifications"
        />
        {emailNotifications}
      </div>

      <div>
        <SectionTitle title="External Unsubscribe Service" />
        {externalServiceJSX}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    unsubscribeSettings: state.complianceSupportCenterQA.unsubscribeSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSupportCenterComplianceSettings: () =>
      dispatch(fetchSupportCenterComplianceSettings()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeSettings);
