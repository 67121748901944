import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Moment from "moment";
import _ from "lodash";

//Redux
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  fetchProspects,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
  phoneFilterValues,
} from "@store/Prospects/Table/actions/prospects";
import { fetchCompaniesListAutoComplete } from "@store/Companies/Global/actions/companies";
import { fetchResponseTypes, fetchStatusCount, fetchSenderEmailAccounts } from "@store/Prospects/Global/actions/prospects";
import ManuallyCategorizeProspectModal from "../../Prospects/Modal/ManuallyCategorizeProspectModal";

//Helpers
import api, { API } from "@helper/api/api";
import { fileDownload } from "@helper/fileDownloadHelper";
import { filterData, getFilterValue, filtersToQueryString, filterSingle } from "@helper/tableConstants";
import { useQueryTableFilters } from "@helper/hooks/useQueryTableFilters";
import { useOrgDateFormat } from "@helper/hooks/useOrgDateFormat";
import { toastMessages } from "@helper/toastMessagesConstants";
import { PERMISSIONS } from "@helper/permissionConstants";
import { permissionHelper } from "@helper/permissionHelper";
import { responseTypeEnum } from "@helper/enums/responseTypeEnum";
import { pricingPlanEnum } from "@helper/pricingPlanHelper";

//Style
import styles from "./Prospects.module.scss";

//Icons & images
import IconEmailExcluded from "@assets/Icons/IconEmailExcluded/IconEmailExcluded";
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import UserShared2LineIcon from "remixicon-react/UserShared2LineIcon";
import UserSearchLineIcon from "remixicon-react/UserSearchLineIcon";
import EmptyAudiencesList from "@assets/Images/EmptyAudiencesList/EmptyAudiencesList";
import Message2LineIcon from "remixicon-react/Message2LineIcon";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import PhoneLineIcon from "remixicon-react/PhoneLineIcon";
import MailLineIcon from "remixicon-react/MailLineIcon";
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";
import GlobalFillIcon from "remixicon-react/GlobalFillIcon";

//Components
import { columns as originalColumns } from "./columns";
import Table from "@components/DataTable/Table";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import StatusCountRow from "@components/DataTable/StatusCountRow/StatusCountRow";
import SkeletonTableFilterRow from "@components/UI/Skeletons/components/SkeletonTableFilterRow/SkeletonTableFilterRow";
import SkeletonTwoRowsCard from "@components/UI/Skeletons/components/SkeletonTwoRowsCard/SkeletonTwoRowsCard";
import SkeletonTable from "@components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import Button from "@components/UI/Button/Button";
import EmptyState from "@components/UI/EmptyState/EmptyState";
import TableOptionsRow from "@components/DataTable/TableOptionsRow/TableOptionsRow";
import ExportProspectsWarningForm from "@components/ExportProspectsWarningForm/ExportProspectsWarningForm";
import Checkbox from "@components/UI/Checkbox/Checkbox";
import StatusBar from "@pages/Prospects/StatusBar/StatusBarProspect";
import MoveProspectsToCampaignForm from "./MoveProspectsToCampaignForm/MoveProspectsToCampaignForm";
import Permission from "@components/auth/Permission";
import useRouteParams from "@hooks/useRouteParams";
import AudienceUploadForm from "@pages/Audiences/AudienceUploadForm";
import useModal from "@ui/Modal/useModal";
import Spinner from "@components/UI/Spinner/Spinner";
import PhoneRevealModal from "@pages/Prospects/Modal/PhoneRevealModal";
import NoCreditsModal from "@pages/Prospects/Modal/NoCreditsModal";
import NoResponseModal from "@pages/Prospects/Modal/NoResponseModal";
import UserOptedOutModal from "@pages/Prospects/Modal/UserOptedOutModal";
import UserPhoto from "@components/UI/UserPhoto/UserPhoto";
import UpgradeToPaidPlanModal from "@pages/Prospects/Modal/UpgradeToPaidPlanModal";

const Prospects = (props) => {
  const revealPhoneModal = useModal();
  const noCreditsModal = useModal();
  const noResponseModal = useModal();
  const userOptedOutModal = useModal();
  const categorizeModal = useModal();
  const upgradeToPaidPlanModal = useModal();

  let dateFormat = useOrgDateFormat();
  const location = useLocation();
  const history = useHistory();
  const { param, rows, fetchProspects, isInitiated, initiate, clearInitiated, creditsChargeAmountPhoneReveal, plan } = props;

  const { campaignId, organizationId } = useRouteParams();

  let filterTypes = ["Companies", "ResponseType", "Status"];
  let state = useQueryTableFilters(filterTypes);
  const [tableRows, setTableRows] = useState([]);
  let originalRender = true;
  const [listCompaniesAutoComplete, setListCompaniesAutoComplete] = useState([]);
  const [activeStatus, setActiveStatus] = useState(null);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isRevealPhoneLoading, setIsRevealPhoneLoading] = useState(false);

  const handlePhoneReveal = (phoneNumber, isLoading) => {
    if (!isLoading) {
      setIsRevealPhoneLoading(false);
      fetchProspects(param);
    }
  };

  const formatUrl = (url) => {
    return url.includes("https") ? url : "//" + url;
  };

  const filterColumnsBasedOnPermission = () => {
    let result = [];
    if (permissionHelper(props.userRole, PERMISSIONS.campaign.moveProspectsToCampaign)) result = originalColumns;
    else {
      originalColumns.map((item) => {
        if (item.field !== "checkbox") result.push(item);
      });
    }

    return result;
  };

  const columns = filterColumnsBasedOnPermission();
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));

  const handleWarningClose = () => {
    setShowWarningModal(false);
  };
  const handleWarningSave = async () => {
    setShowWarningModal(false);
  };

  const audienceUploadModal = useModal();

  useEffect(() => {
    originalRender = false;
  }, [campaignId]);

  const reRenderDataTable = () => {
    setAllProspectsSelected(_.cloneDeep(false));
    setMarkedProspects([]);
    props.changePage(0);
  };

  const exportProspectsData = () => {
    let isExport = true;
    let exportParam = {
      filter: [...param.filter, { type: "IsExport", value: [isExport] }],
    };
    let totalCountProspects = props.totalCount;
    if (totalCountProspects > 50000) {
      setShowWarningModal(true);
      setExportButtonDisabled(false);
    } else {
      let chColumns = dataTableColumns
        .filter((x) => {
          if (x.label !== "") return true;
        })
        .map((column) => column.field);
      chColumns.push("prospectActivityLink");
      let data = {
        chosenColumns: chColumns,
        param: exportParam,
      };
      api(API.prospects.exportProspectsData, data, "blob")
        .then((res) => {
          fileDownload("_allProspects.csv", res.data);
          toast.success(toastMessages.prospects.exportFileFinishedDownloading);
          setExportButtonDisabled(false);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    props.fetchSenderEmailAccounts();
    if (param.filter.length === 0) {
      let updateState = {
        params: {
          filter: [
            { type: "Campaign", value: [campaignId] },
            { type: "fromcampaign", value: [true] },
          ],
          start: 0,
          page: 0,
          length: 10,
          sort: {},
          search: "",
        },
      };

      if (state.params && state.params.filter && state.params.filter.length > 0) {
        state.params.filter.map((filter) => {
          if (filter.type === "Companies") {
            searchCompanies("", filter.value);
          }
          if (filter.type === "Status") {
            setActiveStatus(parseInt(filter.value));
          }
        });
      }
      initiate(updateState);
      props.fetchResponseTypes();
    } else {
      if (state.params && state.params.filter) {
        if (state.params.filter.length > 0) {
          state.params.filter.map((filter) => {
            if (filter.type === "Companies") {
              searchCompanies("", filter.value);
            }
            if (filter.type === "Status") {
              setActiveStatus(parseInt(filter.value));
            }
          });
        }
        if (!state.params.filter.find((n) => n.type === "Campaign")) state.params.filter.push({ type: "Campaign", value: [campaignId] });
        if (!state.params.filter.find((n) => n.type === "fromcampaign")) state.params.filter.push({ type: "fromcampaign", value: [true] });
      }

      initiate(state);
      props.fetchResponseTypes();
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const [allProspectsSelected, setAllProspectsSelected] = useState(false);
  //NOTE:
  //chosenProspects can be the ones selected for transfer or the ones left out of the transfer. Depending on AllProspectsSelected.
  const [markedProspects, setMarkedProspects] = useState([]);
  const selectAllProspectsForTransfer = (event) => {
    setMarkedProspects([]);
    setAllProspectsSelected(event);
  };

  const prospectSelected = (prospect) => {
    let cannotBeChecked =
      prospect.campaignId !== campaignId ||
      prospect.isResponder ||
      prospect.responseTypeId === responseTypeEnum.Undelivered ||
      prospect.responseTypeId === responseTypeEnum.OutOfOffice;
    prospect.responseTypeId === responseTypeEnum.NoLongerWithBusiness || prospect.isExcluded;
    if (cannotBeChecked) return false;

    let prospectId = prospect.id;
    let prospectMarked = markedProspects.some((x) => x == prospectId);
    if (allProspectsSelected && prospectMarked) return false;
    if (allProspectsSelected && !prospectMarked) return true;
    if (!allProspectsSelected && prospectMarked) return true;
    else return false;
  };

  const markProspect = (prospectId) => {
    if (markedProspects.some((x) => x == prospectId)) {
      let deleteProspectIndex = 0;
      markedProspects.map((value, valueIndex) => {
        if (value === prospectId) {
          deleteProspectIndex = valueIndex;
        }
      });

      markedProspects.splice(deleteProspectIndex, 1);
    } else {
      markedProspects.push(prospectId);
    }

    setMarkedProspects(_.cloneDeep(markedProspects));
  };

  useEffect(() => {
    if (isInitiated) {
      let campaign = param.filter.find((x) => x.type === "Campaign");
      if (originalRender && param.filter.length > 0 && campaign?.value?.length > 0 && campaign?.value[0] != "") {
        fetchProspects(param);
        const querystring = location.search;
        let excludedFilter = ["Campaign"];
        const newQuerystring = filtersToQueryString(param, excludedFilter);

        if (querystring !== newQuerystring) {
          history.replace(location.pathname + newQuerystring);
        }
      }
    }
  }, [param]);

  // Until final or better solution.
  // This is the flicker table fix on the checkboxes.
  const debouncedMarkProspect = useCallback(
    _.debounce((id) => {
      markProspect(id);
    }, 10),
    []
  );

  const debouncedSelectAllProspectsForTransfer = useCallback(
    _.debounce((event) => {
      selectAllProspectsForTransfer(event);
    }, 50),
    []
  );

  useEffect(() => {
    let filters = [...param.filter];
    let campaigns = filters.find((x) => x.type === "Campaign") || {};
    let campaignIds = [];

    if (campaigns.type !== undefined) {
      campaignIds = [...campaigns.value];
    }
    let data = {
      campaignId: campaignIds,
      isFromCampaign: true,
    };
    if (campaignIds.length > 0) props.fetchStatusCount(data);
  }, [param]);

  const [disabledRows, setDisabledRows] = useState(null);

  const isFreemiumOrTrial = [pricingPlanEnum.freemium, pricingPlanEnum.trial].includes(plan);
  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    let disabledRowsTemp = _.cloneDeep([]);
    rowsTemp.map((r, index) => {
      let moveNotAllowed = false;
      if (campaignId && campaignId !== "" && disabledRows && disabledRows.length >= 0) {
        moveNotAllowed = r.campaignId !== campaignId;
        if (moveNotAllowed) {
          disabledRowsTemp.push(index);
        }
      }

      r.name = (
        <div className={styles.nameColumn}>
          <div className={styles.prospectName}>
            {r.prospectLinkedIn && (
              <span className="mR5">
                <a rel="noopener noreferrer" href={formatUrl(r.prospectLinkedIn)} target="_blank">
                  <LinkedinBoxFillIcon size={16} className="iconGray" />
                </a>
              </span>
            )}

            <OverlayTrigger
              key={r.name}
              placement={"bottom"}
              overlay={
                <Popover id={r.name}>
                  <Popover.Body>{r.name}</Popover.Body>
                </Popover>
              }
            >
              <span>
                <Link
                  className="tableLink"
                  to={{
                    pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
                  }}
                >
                  {r.name}
                </Link>
              </span>
            </OverlayTrigger>

            <div className={styles.jobTitle}>{r.jobTitle && r.jobTitle !== "" ? r.jobTitle : null}</div>
          </div>
        </div>
      );

      r.contactInfo = (
        <div className={styles.phoneEmail}>
          {!r.isPhoneNumberAvailable && (r.mobileNumber || r.phoneNumber) ? (
            <div className={`${styles.phoneRevealed} text-overflow-hidden`}>
              <div className="text-overflow-hidden">
                <MailLineIcon size={14} className="mR5" />
                <Link
                  className="tableLink"
                  to={{
                    pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
                  }}
                >
                  {r.email}
                </Link>
              </div>
              <div className="text-overflow-hidden">
                <PhoneLineIcon size={14} className="mR5" />
                {r.mobileNumber || r.phoneNumber}
              </div>
            </div>
          ) : r.isPhoneNumberAvailable ? (
            <>
              <div className="text-overflow-hidden">
                <MailLineIcon size={14} className="mR5" />
                <Link
                  className="tableLink"
                  to={{
                    pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
                  }}
                >
                  {r.email}
                </Link>
              </div>
              <OverlayTrigger
                key={`${r.id}true`}
                placement={"bottom"}
                overlay={
                  isFreemiumOrTrial ? <></> :<Popover id={`tooltip-${r.id}true`}>
                    <Popover.Body>
                      &bull;&bull;&bull; &bull;&bull;&bull; &bull;&bull;&bull; ({creditsChargeAmountPhoneReveal} Credits)
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  onClick={() => {
                    if(isFreemiumOrTrial){
                      upgradeToPaidPlanModal.show();
                    }
                    else{
                      setIsRevealPhoneLoading(true);
                      revealPhoneModal.show(r.id);
                    }
                  }}
                  className={styles.phoneReveal}
                >
                  {isRevealPhoneLoading ? (
                    <div className="flex">
                      <Spinner size="14px" spinnerClass={styles.spinner} description="Searching number" />
                    </div>
                  ) : (
                    <div>
                      <PhoneLineIcon size={14} className="mR5" />
                      {"Reveal Phone"}
                    </div>
                  )}
                </div>
              </OverlayTrigger>
            </>
          ) : (
            <div className="text-overflow-hidden">
              <MailLineIcon size={14} className="mR5" />
              <Link
                className="tableLink"
                to={{
                  pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
                }}
              >
                {r.email}
              </Link>
            </div>
          )}
        </div>
      );

      if (r.companyName !== "(No company)") {
        r.companyName = (
          <div className={styles.companyInfo}>
            <UserPhoto isCompanyImage={true} src={r.companyLogo} size="small" />
            <div className={styles.companyName}>
              {r.cleanCompanyName ? r.cleanCompanyName : r.companyName}
              <br />
              {r.companyDomain && (
                <OverlayTrigger
                  key={`${r.id}${r.companyDomain}`}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`${r.id}${r.companyDomain}`}>
                      <Popover.Body>{r.companyDomain}</Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <a rel="noopener noreferrer" href={formatUrl(r.companyDomain)} target="_blank">
                      <GlobalFillIcon size={16} className="iconGray" />
                    </a>
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </div>
        );
      }

      if (r.firstActivity != null && r.firstActivity !== "") {
        r.firstActivity = Moment(r.firstActivity).format(dateFormat);
      } else {
        r.firstActivity = "-";
      }

      if (r.lastActivity != null && r.lastActivity !== "") {
        r.lastActivity = Moment(r.lastActivity).format(dateFormat);
      } else {
        r.lastActivity = "-";
      }

      if (r.lastSend != null && r.lastSend !== "") {
        r.lastSend = Moment(r.lastSend).format(dateFormat);
      } else {
        r.lastSend = "-";
      }

      r.conversations = (
        <Link
          to={{
            pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
          }}
        >
          <Message2LineIcon size={16} className="iconGray500" />
        </Link>
      );

      r.currentStatus = (
        <StatusBar
          isDelivered={r.delivered != null}
          isOpened={r.opened != null}
          isClicked={r.clicked != null}
          isResponder={r.isResponder}
          isLead={r.isLead}
        />
      );

      const tooltipContent =
        r.responseType === "Out of office"
          ? "OOO replies adjust the next follow-up to the recipient’s return date. Date can be modified if needed."
          : r.responseType;

      const responseTypeJSX =
        r.responseType === "Out of office" ? (
          <>
            <span>{r.responseType}</span>
            {r.isManualCategorizedProspect ? (
              <>
                <a
                  className={styles.linkPrimary}
                  onClick={() => {
                    categorizeModal.show(r.id);
                  }}
                >
                  Change response type
                </a>
              </>
            ) : (
              <InformationFillIcon size={18} color="var(--icon-color)" className="mR5" />
            )}
          </>
        ) : r.responseType ? (
          <>
            <span>{r.responseType}</span>
            {r.isManualCategorizedProspect ? (
              <>
                <a
                  className={styles.linkPrimary}
                  onClick={() => {
                    categorizeModal.show(r.id);
                  }}
                >
                  Change response type
                </a>
              </>
            ) : null}
          </>
        ) : r.delivered && !r.isResponder ? (
          <a
            className={styles.linkPrimary}
            onClick={() => {
              categorizeModal.show(r.id);
            }}
          >
            + Set response type
          </a>
        ) : null;

      r.responseType =
        r.responseType === "Out of office" ? (
          <OverlayTrigger
            key={r.responseType}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.responseType}`}>
                <Popover.Body>{tooltipContent}</Popover.Body>
              </Popover>
            }
          >
            <div className={`${styles.responseType} ${!r.isManualCategorizedProspect ? styles.outOffice : ""}`}> {responseTypeJSX}</div>
          </OverlayTrigger>
        ) : (
          <div className={styles.responseType}>{responseTypeJSX}</div>
        );

      if (r.isExcluded) {
        r.isExcluded = (
          <OverlayTrigger
            key={r.isExcluded}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.isExcluded}`}>
                <Popover.Body>This prospect has been excluded</Popover.Body>
              </Popover>
            }
          >
            <div className={styles.responseType}>
              <IconEmailExcluded />
            </div>
          </OverlayTrigger>
        );
      } else {
        r.isExcluded = null;
      }

      r.createdDate = Moment(r.createdDate).format(dateFormat);
      let checkboxDisabled =
        r.isResponder ||
        r.responseTypeId === responseTypeEnum.Undelivered ||
        r.responseTypeId === responseTypeEnum.OutOfOffice ||
        r.responseTypeId === responseTypeEnum.NoLongerWithBusiness ||
        r.isExcluded;

      let checkbox = null;
      if (permissionHelper(props.userRole, PERMISSIONS.campaign.moveProspectsToCampaign)) {
        checkbox = (
          <div>
            {checkboxDisabled ? (
              <Checkbox
                onlyCheckbox={true}
                checked={prospectSelected(r)}
                name={"excludeDuplicateProspects"}
                fromDataTable={true}
                onChange={(e) => debouncedMarkProspect(r.id)}
                disabled={checkboxDisabled}
              />
            ) : (
              <OverlayTrigger
                key="campaignInUse"
                placement="bottom"
                overlay={
                  <Popover id={`tooltip-campaignInUse`}>
                    <Popover.Body>Select this Prospect for transfer</Popover.Body>
                  </Popover>
                }
              >
                <Checkbox
                  onlyCheckbox={true}
                  checked={prospectSelected(r)}
                  name="excludeDuplicateProspects"
                  fromDataTable={true}
                  onChange={(e) => debouncedMarkProspect(r.id)}
                />
              </OverlayTrigger>
            )}
          </div>
        );

        r.checkbox = checkbox;
      }

      return r;
    });

    setTableRows(rowsTemp);
    setDisabledRows(disabledRowsTemp);
  }, [rows, allProspectsSelected, markedProspects]);

  useEffect(() => {
    setListCompaniesAutoComplete(props.listCompaniesAutoComplete);
  }, [props.listCompaniesAutoComplete]);

  //MoveProspectsToCampaign
  const checkMoveButtonDisabled = () => {
    if (!allProspectsSelected && !allProspectsSelected && markedProspects.length === 0) return true;
    else return false;
  };
  const [showMoveProspectsToCampaignForm, setShowMoveProspectsToCampaignForm] = useState(false);
  const handleMoveProspectsToCampaignFormOpen = () => {
    var checkIsDisabled = checkMoveButtonDisabled();
    if (!checkIsDisabled) setShowMoveProspectsToCampaignForm(true);
  };
  const handleMoveProspectsToCampaignFormClose = () => {
    setShowMoveProspectsToCampaignForm(false);
    reRenderDataTable();
  };
  const handleMoveProspectsToCampaignFormSave = () => {
    setShowMoveProspectsToCampaignForm(false);
  };

  let table = null;
  let statusCountRow = null;
  let selects = <SkeletonTableFilterRow />;
  let image = <EmptyAudiencesList />;
  let tableOptionsRow = null;
  let moveProspectsToCampaignForm = null;

  const filterStatus = (status) => {
    props.changePage(0);
    filterSingle("Status", status, param, props.setFilter);
  };

  const debouncedFilterStatus = useCallback(
    _.debounce((status) => {
      filterStatus(status);
    }, 300),
    []
  );

  const searchCompanies = (searchWord, selectedValues) => {
    if ((searchWord !== "" && searchWord.length > 2) || (selectedValues !== null && selectedValues.length > 0)) {
      var data = {
        selectedValues: selectedValues,
        searchWord: searchWord,
      };

      props.fetchCompaniesListAutoComplete(data);
    }
  };

  const companiesFilterOnMenuClose = (selectedValues) => {
    if (selectedValues !== null && selectedValues.length === 0) setListCompaniesAutoComplete([]);
  };

  const handleAudienceBuilder = () => {
    history.push(`/${organizationId}/search?camId=${campaignId}`);
  };

  const buttons = (
    <div className="flex">
      {rows && rows.length > 0 && (
        <Permission has={PERMISSIONS.campaign.moveProspectsToCampaign}>
          <OverlayTrigger
            key={`tooltip-move-prospects`}
            placement={!allProspectsSelected && !allProspectsSelected && markedProspects.length === 0 ? "bottom" : "hidden"}
            overlay={
              <Popover id={`tooltip-move-prospects-id`}>
                <Popover.Body>Please select Prospect(s) for transfer</Popover.Body>
              </Popover>
            }
          >
            <div>
              <Button
                id="campaignSettingsProspectMoveProspects"
                onClick={() => handleMoveProspectsToCampaignFormOpen()}
                variant="secondary"
                classes="m0"
              >
                <UserShared2LineIcon size={16} className="mR5" /> Move
              </Button>
            </div>
          </OverlayTrigger>
        </Permission>
      )}
      <Button id="campaignSettingsProspectUploadAudience" onClick={audienceUploadModal.show} variant="secondary" link={"audiences"}>
        <UploadCloud2LineIcon size={16} className="mR5" /> Upload list
      </Button>
      <Button id="campaignSettingsProspectsAudienceBuilder" onClick={handleAudienceBuilder} variant="primary" link={"search"}>
        <UserSearchLineIcon size={16} className="mR5 iconWhite" /> Search contacts
      </Button>
    </div>
  );

  if (props.error) {
    table = <h2 className="f12">Error getting Prospects</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
    statusCountRow = (
      <div className="flex w-5 mT10 mB30">
        {[1, 2, 3, 4, 5, 6].map((n) => (
          <SkeletonTwoRowsCard key={n} />
        ))}
      </div>
    );
  }

  if (props.isLoaded && disabledRows && props.statusCount) {
    statusCountRow = (
      <StatusCountRow
        statusCount={props.statusCount}
        click={debouncedFilterStatus}
        changeFilter={(text) => props.searchRecords(text)}
        activeStatus={activeStatus}
      />
    );

    const additionalGridOptions = {
      rowHeight: 65,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    };

    table =
      tableRows.length > 0 ? (
        <>
          <Table
            tableName={"Prospects"}
            columns={dataTableColumns}
            rows={tableRows}
            param={param}
            parentSort={props.sortData}
            parentCallback={props.changePage}
            pageCount={props.pageCount}
            totalCount={props.totalCount}
            changeNumRecords={props.numRecords}
            tableClassName={`${styles.prospectsTable} m-max-height-none`}
            scrollY={true}
            tableHeight={490}
            variant={"large"}
            gridOptions={additionalGridOptions}
            forcePageChanged={true}
            selectAllRows={debouncedSelectAllProspectsForTransfer}
            allRowsSelected={allProspectsSelected}
            disabledRows={disabledRows}
          />
          <ManuallyCategorizeProspectModal
            showModal={categorizeModal.isVisible}
            prospectId={categorizeModal.data}
            handleClose={categorizeModal.hide}
            refreshData={() => fetchProspects(param)}
          />
        </>
      ) : (
        <EmptyState image={image} title="No prospects found" />
      );

    let selectRowOptions = [
      {
        option: listCompaniesAutoComplete,
        change: (i) => filterData("Companies", i, props.setFilter),
        onInputChange: (i) => searchCompanies(i, getFilterValue(param.filter, "Companies")),
        onMenuClose: () => companiesFilterOnMenuClose(getFilterValue(param.filter, "Companies")),
        placeholder: "Companies",
        value: getFilterValue(param.filter, "Companies"),
      },
      {
        option: props.listResponseTypes,
        change: (s) => filterData("ResponseType", s, props.setFilter),
        placeholder: "Response Types",
        value: getFilterValue(param.filter, "ResponseType"),
      },
      {
        option: props.listSenderEmailAccounts,
        change: (s) => filterData("EmailAccount", s, props.setFilter),
        placeholder: "Email Account",
        value: getFilterValue(param.filter, "EmailAccount"),
      },
      {
        option: phoneFilterValues,
        change: (s) => filterData("PhoneNumber", s, props.setFilter),
        placeholder: "Phone Number",
        value: getFilterValue(param.filter, "PhoneNumber"),
      },
    ];

    selects = (
      <div className={styles.filterRow}>
        <TableFiltersRow selects={selectRowOptions} placeholder="prospects" changeFilter={(text) => props.searchRecords(text)} />
        {buttons}
      </div>
    );

    tableOptionsRow =
      tableRows.length > 0 ? (
        <TableOptionsRow
          applyChanges={reRenderDataTable}
          exportTable={exportProspectsData}
          exportButtonDisabled={exportButtonDisabled}
          setExportButtonDisabled={setExportButtonDisabled}
          importData={null}
          dataTableColumns={dataTableColumns}
          setDataTableColumns={setDataTableColumns}
          allDataTableColumns={columns}
        />
      ) : null;

    if (permissionHelper(props.userRole, PERMISSIONS.campaign.moveProspectsToCampaign)) {
      moveProspectsToCampaignForm = (
        <MoveProspectsToCampaignForm
          showModal={showMoveProspectsToCampaignForm}
          handleClose={handleMoveProspectsToCampaignFormClose}
          handleSave={handleMoveProspectsToCampaignFormSave}
          allProspectsSelected={allProspectsSelected}
          markedProspects={markedProspects}
          campaignId={campaignId}
          param={param}
        />
      );
    }
  }

  return (
    <>
      {selects}
      {statusCountRow}
      {tableOptionsRow}
      {table}
      {moveProspectsToCampaignForm}
      <ExportProspectsWarningForm
        showModal={showWarningModal}
        handleClose={handleWarningClose}
        handleSave={handleWarningSave}
        setExportButtonDisabled={setExportButtonDisabled}
      />

      <Permission has={PERMISSIONS.campaign.edit}>
        <AudienceUploadForm
          handleClose={audienceUploadModal.hide}
          showModal={audienceUploadModal.isVisible}
          campaignConfigCampaignId={campaignId}
          audience={audienceUploadModal.data}
          orgId={props.orgId}
        />
      </Permission>

      {revealPhoneModal.isVisible && (
        <PhoneRevealModal
          showModal={revealPhoneModal.isVisible}
          handleSave={handlePhoneReveal}
          handleClose={revealPhoneModal.hide}
          prospectId={revealPhoneModal.data}
          handlePhoneReveal={handlePhoneReveal}
          handleNoCredits={noCreditsModal.show}
          handleError={noResponseModal.show}
          handleOptedOut={userOptedOutModal.show}
          creditsChargeAmountPhoneReveal={creditsChargeAmountPhoneReveal}
        />
      )}
      {noCreditsModal.isVisible && (
        <NoCreditsModal showModal={noCreditsModal.isVisible} handleClose={noCreditsModal.hide} organizationId={organizationId} />
      )}
       {upgradeToPaidPlanModal.isVisible && (
        <UpgradeToPaidPlanModal showModal={upgradeToPaidPlanModal.isVisible} handleClose={upgradeToPaidPlanModal.hide} />
      )}
      {noResponseModal.isVisible && <NoResponseModal showModal={noResponseModal.isVisible} handleClose={noResponseModal.hide} />}
      {userOptedOutModal.isVisible && <UserOptedOutModal showModal={userOptedOutModal.isVisible} handleClose={userOptedOutModal.hide} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.prospects.params,
    isInitiated: state.prospects.isInitiated,
    isLoading: state.prospects.isLoading,
    isLoaded: state.prospects.isLoaded,
    error: state.prospects.error,
    rows: state.prospects.rows,
    totalCount: state.prospects.totalCount,
    pageCount: state.prospects.pageCount,

    listResponseTypes: state.globalProspects.listResponseTypes,
    isLoadingListResponseTypes: state.globalProspects.isLoading,
    isLoadedListResponseTypes: state.globalCompanies.isLoaded,

    listCompaniesAutoComplete: state.globalCompanies.listCompaniesAutoComplete,
    isLoadingListCompaniesAutoComplete: state.globalCompanies.isLoading,
    isLoadedListCompaniesAutoComplete: state.globalCompanies.isLoaded,
    orgId: state.user.organization.id,
    statusCount: state.globalProspects.statusCount,

    listSenderEmailAccounts: state.globalProspects.listSenderEmailAccounts,
    userRole: state?.user?.organization?.role ?? 0,
    plan: state.billingAndPayment.creditInfo.plan,
    creditsChargeAmountPhoneReveal: state.billingAndPayment.creditInfo.creditsChargeAmountPhoneReveal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompaniesListAutoComplete: (data) => dispatch(fetchCompaniesListAutoComplete(data)),
    fetchResponseTypes: () => dispatch(fetchResponseTypes()),
    fetchProspects: (param) => dispatch(fetchProspects(param)),
    fetchStatusCount: (obj) => dispatch(fetchStatusCount(obj)),
    fetchSenderEmailAccounts: () => dispatch(fetchSenderEmailAccounts()),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prospects);
