import React from "react";
import svg from "./funnel_stage1.svg";

const Stage1 = (props) => (
  <svg width="268" height="63" viewBox="0 0 268 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M267.593 0H0L14.2199 62.0505H254.666L267.593 0Z" fill="#D80F6F" />
  </svg>
);

export default Stage1;
