import React, { useState, useEffect } from "react";

//Images & Icons
import svgFinished from "./svg/preparing-timed.svg";
import svgAnimated from "./svg/preparing-animated.svg";

//Styles
import styles from "./Preparing.module.scss";

//Components
import ProgressBar from "../../../../src/components/ProgressBar/ProgressBar";

const loadingText = ["Building your list", "Loading contacts"];

const Preparing = ({ animate, topLabelText }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex === loadingText.length - 1) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 2300);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const svgInfinite = `${animate ? styles.fadeIn : styles.fadeOut}`;
  const svgTimed = `${animate ? styles.fadeOut : styles.fadeIn}`;

  return (
    <div className={styles.animatedProgress}>
      <div className={svgInfinite}>
        <object aria-label="progress-animation" data={svgAnimated} type="image/svg+xml"></object>
      </div>
      <div className={svgTimed}>
        <object aria-label="animation" data={svgFinished} type="image/svg+xml"></object>
      </div>
      <div className={styles.animatedBar}>
        <ProgressBar
          topLabelText={topLabelText}
          now={!animate ? 100 : (currentIndex + 1) * 30}
          max={100}
          min={0}
          showLabel
          // labelText={!animate ? "We are finished!" : loadingText[currentIndex]}
        />
      </div>
    </div>
  );
};

export default Preparing;
