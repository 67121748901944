import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  listCompanies: [],
  listCompaniesAutoComplete: [],
  statusCount: {},
  listIndustry: [],
  size: [],
};

const fetchListCampaignsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListCampaignsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listCompanies };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listCompanies = list;
  return newState;
};

const fetchListCampaignsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchListCampaignsAutoCompleteRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListCampaignsAutoCompleteSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listCompaniesAutoComplete };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listCompaniesAutoComplete = list;
  return newState;
};

const fetchListCampaignsAutoCompleteFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchStatusCountRequestForCompanies = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchStatusCountSuccessForCompanies = (state, action) => {
  const newState = { ...state };
  let list = { ...state.statusCount };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.statusCount = list;
  return newState;
};

const fetchStatusCountFailedForCompanies = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchIndustryListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchIndustryListSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listIndustry };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listIndustry = list;
  return newState;
};

const fetchIndustryListFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchSizeListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchSizeListSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.size };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.size = list;
  return newState;
};

const fetchSizeListFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST_COMPANIES_REQUEST:
      return fetchListCampaignsRequest(state, action);
    case actionTypes.FETCH_LIST_COMPANIES_SUCCEEDED:
      return fetchListCampaignsSuccess(state, action);
    case actionTypes.FETCH_LIST_COMPANIES_FAILED:
      return fetchListCampaignsFailed(state, action);
    case actionTypes.FETCH_LIST_COMPANIES_AUTOCOMPLETE_REQUEST:
      return fetchListCampaignsAutoCompleteRequest(state, action);
    case actionTypes.FETCH_LIST_COMPANIES_AUTOCOMPLETE_SUCCEEDED:
      return fetchListCampaignsAutoCompleteSuccess(state, action);
    case actionTypes.FETCH_LIST_COMPANIES_AUTOCOMPLETE_FAILED:
      return fetchListCampaignsAutoCompleteFailed(state, action);
    case actionTypes.FETCH_STATUS_COUNT_COMPANIES_REQUEST:
      return fetchStatusCountRequestForCompanies(state, action);
    case actionTypes.FETCH_STATUS_COUNT_COMPANIES_SUCCEEDED:
      return fetchStatusCountSuccessForCompanies(state, action);
    case actionTypes.FETCH_STATUS_COUNT_COMPANIES_FAILED:
      return fetchStatusCountFailedForCompanies(state, action);
    case actionTypes.FETCH_LIST_INDUSTRY_REQUEST:
      return fetchIndustryListRequest(state, action);
    case actionTypes.FETCH_LIST_INDUSTRY_SUCCEEDED:
      return fetchIndustryListSuccess(state, action);
    case actionTypes.FETCH_LIST_INDUSTRY_FAILED:
      return fetchIndustryListFailed(state, action);
    case actionTypes.FETCH_LIST_SIZE_REQUEST:
      return fetchSizeListRequest(state, action);
    case actionTypes.FETCH_LIST_SIZE_SUCCEEDED:
      return fetchSizeListSuccess(state, action);
    case actionTypes.FETCH_LIST_SIZE_FAILED:
      return fetchSizeListFailed(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
