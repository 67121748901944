import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

//Email Template
export const fetchEmailTemplateRequest = () => {
  return {
    type: actionTypes.FETCH_EMAIL_TEMPLATE_REQUEST,
  };
};

export const fetchEmailTemplateSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_EMAIL_TEMPLATE_SUCCEEDED,
    result: settings,
  };
};

export const fetchEmailTemplateFailed = (error) => {
  return {
    type: actionTypes.FETCH_EMAIL_TEMPLATE_FAILED,
    result: error,
  };
};

export const fetchEmailTemplate = (data) => {
  return (dispatch) => {
    dispatch(fetchEmailTemplateRequest());
    return api(formUrl(API.emailTemplates.get, { emailTemplateId: data }))
      .then((response) => {
        dispatch(fetchEmailTemplateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEmailTemplateFailed(error.message));
      });
  };
};

//Organization Custom Columns
export const fetchOrganizationCustomColumnsRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_CUSTOM_COLUMNS_REQUEST,
  };
};

export const fetchOrganizationCustomColumnsSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_CUSTOM_COLUMNS_SUCCEEDED,
    result: settings,
  };
};

export const fetchOrganizationCustomColumnsFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_CUSTOM_COLUMNS_FAILED,
    result: error,
  };
};

export const fetchOrganizationCustomColumns = () => {
  return (dispatch) => {
    dispatch(fetchOrganizationCustomColumnsRequest());
    return api(formUrl(API.emailTemplates.listOrganizationCustomColumns))
      .then((response) => {
        dispatch(fetchOrganizationCustomColumnsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchOrganizationCustomColumnsFailed(error.message));
      });
  };
};

export const setDictionaryValues = (dictionary) => {
  return {
    type: actionTypes.SET_DICTIONARY_VALUES,
    result: dictionary,
  };
};

//Initial Email Templates
export const fetchInitialEmailTemplatesRequest = () => {
  return {
    type: actionTypes.FETCH_INITIAL_EMAIL_TEMPLATES_REQUEST,
  };
};

export const fetchInitialEmailTemplatesSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_INITIAL_EMAIL_TEMPLATES_SUCCEEDED,
    result: settings,
  };
};

export const fetchInitialEmailTemplatesFailed = (error) => {
  return {
    type: actionTypes.FETCH_INITIAL_EMAIL_TEMPLATES_FAILED,
    result: error,
  };
};

export const fetchInitialEmailTemplates = () => {
  return (dispatch) => {
    dispatch(fetchInitialEmailTemplatesRequest());
    return api(API.emailTemplates.getInitialEmailTemplates)
      .then((response) => {
        dispatch(fetchInitialEmailTemplatesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchInitialEmailTemplatesFailed(error.message));
      });
  };
};
