import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import { useEditCampaignStatusMutation } from "../../../../api/campaignApi";
import { CampaignStatusEnum } from "../../../../models/enums/CampaignStatusEnum";

interface ArchiveConfirmModalProps {
  campaign: {
    id: number;
    name: string;
  };
  showModal: boolean;
  handleClose: () => void;
}

const ArchiveConfirmModal: FC<ArchiveConfirmModalProps> = ({ campaign, showModal, handleClose }) => {
  const [changeStatus, { isLoading }] = useEditCampaignStatusMutation();

  const handleClick = () => {
    const data = {
      campaignId: campaign.id,
      status: CampaignStatusEnum.Archived,
    };

    changeStatus(data)
      .unwrap()
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Modal
      title={`Archive ${campaign.name} campaign`}
      handleClose={handleClose}
      handleSave={handleClick}
      show={showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      btnType="submit"
      saveButtonDisabled={isLoading}
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>Are you sure you would like to archive {campaign.name} campaign?</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ArchiveConfirmModal;
