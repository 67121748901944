import React from "react";
// import { ExceptionlessClient } from "exceptionless/dist/exceptionless";
import { connect } from "react-redux";
import ErrorPage from "../../../pages/ErrorPage/ErrorPage";
import { withRouter } from "react-router-dom";
import { Exceptionless } from "@exceptionless/react";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      organizationId: null,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    error = error ?? new Error("Unknown error");

    Sentry.captureException(error);

    let exception = Exceptionless.createException(error);

    // if (this != null && this.props != null && this.props.user != null) {
    //   exception.setUserIdentity(this.props.user.email);
    // }

    const { location } = this.props;
    if (location != null) {
      var orgMatch = location.pathname.match(/\/(\d+)\//);
      if (orgMatch && orgMatch[1]) {
        let organizationId = parseInt(orgMatch[1]);
        exception.setProperty("organizationId", organizationId);
      }
    }

    if (errorInfo) {
      exception.setProperty("errorInfo", errorInfo);
    }
    exception.setProperty("errorMessage", error?.message);

    (async () => {
      await Exceptionless.submitException(exception);
    })();
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage location={this.props.location} error={this.state.error} />;
    } else {
      return this.props.children;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorBoundary));
// export default ErrorBoundary;
