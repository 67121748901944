import React from "react";

//Components
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import ElementCycle from "../../../components/UI/ElementCycle/ElementCycle";

const RandomSandbox = () => {
  return (
    <>
      <SectionTitle title="<ElementCycle>" />

      <ElementCycle
        interval={"a"}
        objects={["Sentence A", "Sentence B", "Sentence C"]}
      />

      <SectionTitle title="<ElementCycle> endless cycle" />

      <ElementCycle
        delay={1500}
        objects={["Sentence A", "Sentence B", "Sentence C"]}
        endlessCycle={true}
      />
    </>
  );
};

export default RandomSandbox;
