import React, { useState } from "react";

//Style
import styles from "./RulsetTemplates.module.scss";

//Components
import Button from "../../../../components/UI/Button/Button";
import RulsetCard from "./RulsetCard/RulesetCard";
import RulesetTemplateModal from "./RulesetTemplateModal/RulesetTemplateModal";

const RulesetTemplates = (props) => {
  const { setIsAddingRule } = props;
  const [selectedRulesetId, setSelectedRulesetId] = useState(null);
  const [selectedRuleset, setSelectedRuleset] = useState([]);

  const [showRulesetTemplateModal, setShowRulesetTemplateModal] = useState(false);
  const handleViewRuleSetTemplateClose = () => setShowRulesetTemplateModal(false);
  const handleViewRuleSetTemplateShow = (rulesetId, ruleset) => {
    setSelectedRulesetId(rulesetId);
    setSelectedRuleset(ruleset);
    setShowRulesetTemplateModal(true);
  };

  const handleTemplateSelected = (id) => {
    setShowRulesetTemplateModal(false);
    if (props.rulesetSelected) props.rulesetSelected(id);
  };

  // const changeUrl = () => {
  //   props.rulesetTemplate();
  // };

  // const breadcrumbs = [
  //   {
  //     text: 'Integration rules'
  //   },
  //   { active: true, text: "Add new" },
  // ];

  if (!props.data || props.data === null || props.data.length === 0) return <></>;

  return (
    <div className={styles.componentWrapper}>
      {/* <div className={styles.breadCrumbsButtonsWrapper}>
        <BreadcrumbElement items={breadcrumbs} />
      </div> */}
      <div className={styles.cardsWrapper}>
        <h3 className={styles.title}>Rulset Templates</h3>
        <div className={styles.listWrapper}>
          {props.data.map((element, index) => {
            return (
              <RulsetCard
                title={element.rulesetId}
                ruleset={element.ruleset}
                rulesetId={element.rulesetId}
                onClick={() => handleViewRuleSetTemplateShow(element.rulesetId, element.ruleset)}
                useTemplate={() => handleTemplateSelected(element.rulesetId)}
                rulesetSelected={props.rulesetSelected}
                key={index}
              />
            );
          })}
          <RulesetTemplateModal
            handleClose={handleViewRuleSetTemplateClose}
            showModal={showRulesetTemplateModal}
            rulesetSelected={(id) => handleTemplateSelected(id)}
            rulesetId={selectedRulesetId}
            ruleset={selectedRuleset}
          />
        </div>
      </div>
      <div className="flex_center flexCol mT20">
        <p>Or set</p>
        <Button id="customRules" variant="primary" onClick={() => setIsAddingRule(true)}>
          Custom Rules
        </Button>
      </div>
    </div>
  );
};

export default RulesetTemplates;
