import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";

// Style
import "./MultiLevelDropdown.css";
import "react-dropdown-tree-select/dist/styles.css";

const MultiLevelDropdown = ({
  title,
  name,
  id,
  selectedValues,
  data,
  onChange,
  maxOptions = 50,
  showErrorMessage = false,
  enableScrollToSelection = true,
}) => {
  const { setFieldValue } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const dropdownSelect = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [lastSelectedValue, setLastSelectedValue] = useState(null);

  const scrollToSibling = (element, numberOfSiblingsToSkip) => {
    let nextSibling = element;

    for (let i = 0; i < numberOfSiblingsToSkip; i++) {
      nextSibling = nextSibling.nextElementSibling;
      if (!nextSibling) break;
    }

    if (nextSibling) {
      nextSibling.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    setTreeData(prepareData(data, selectedValues));
    setHasMounted(true);
  }, []);

  useEffect(() => {
    setTreeData(prepareData(data, selectedValues));
  }, [selectedValues, data, searchTerm]);

  useEffect(() => {
    if (hasMounted) {
      if (lastSelectedValue) {
        scrollToLastAddedItem();
      } else {
        scrollToFocusedItem();
      }
    }
  }, [treeData]);

  const scrollToFocusedItem = () => {
    if (dropdownSelect.current) {
      const node = ReactDOM.findDOMNode(dropdownSelect.current);
      const focusedItem = node.querySelector(".focused-item");
      if (focusedItem) {
        scrollToSibling(focusedItem, 3); // 4 siblings to skip for optimal view
      }
    }
  };

  const scrollToLastAddedItem = () => {
    if (dropdownSelect.current && lastSelectedValue) {
      const node = ReactDOM.findDOMNode(dropdownSelect.current);
      const lastAddedItem = node.querySelector(`[data-value="${lastSelectedValue}"]`);
      if (lastAddedItem) {
        lastAddedItem.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const prepareData = (originalData, selectedValues) => {
    if (!originalData || originalData.length === 0) return [];

    let data = [...originalData];
    const hasChildren = data[0] != null && data[0].children;

    if (searchTerm !== "" || (selectedValues && selectedValues.length > 0)) {
      data = checkDataWithSearchTerm(data);
    }

    // Clean the data from checked values
    data.forEach((item) => {
      if (hasChildren) {
        item.children.forEach((child) => delete child["checked"]);
        delete item["checked"];
        delete item["_children"];
        delete item["_depth"];
        delete item["_focused"];
        delete item["_id"];
        delete item["expanded"];
      } else {
        delete item["checked"];
      }
    });

    if (selectedValues) {
      selectedValues.forEach((selectedItem) => {
        data = data.map((item) => {
          if (hasChildren) {
            return {
              ...item,
              children: item.children.map((child) => (child.value === selectedItem ? { ...child, checked: true, _focused: true } : child)),
            };
          } else {
            return item.value === selectedItem ? { ...item, checked: true, _focused: true, className: "focused-item" } : item;
          }
        });
      });
    }

    if (hasChildren) {
      data = data.map((item, index) => {
        const allChildrenChecked = item.children.every((el) => el.checked === true);
        const someChildrenChecked = item.children.some((el) => el.checked === true);

        if (allChildrenChecked || someChildrenChecked) {
          const id = "rdts2-" + (index + 1);
          const children = item.children.map((ch, chIndex) => id + "-" + chIndex);
          const newData = {
            ...item,
            _children: children,
            _depth: 0,
            _focused: someChildrenChecked || allChildrenChecked,
            _id: id,
            checked: allChildrenChecked,
            expanded: someChildrenChecked && !allChildrenChecked,
            className: someChildrenChecked || allChildrenChecked ? "focused-item" : "",
          };
          return newData;
        }
        return item;
      });
    }

    return data;
  };

  const checkDataWithSearchTerm = (data) => {
    const checkWords = selectedValues;
    const searchLower = searchTerm.toLowerCase();

    return data.filter((option) => {
      const checkOption = option.label && option.label.toLowerCase().includes(searchLower);
      const checkChildren =
        option.children &&
        option.children.some((x) => checkWords.includes(x.value) || (x.label && x.label.toLowerCase().includes(searchLower)));

      return checkOption || checkChildren || searchTerm === "";
    });
  };

  const handleChange = (e, value) => {
    let newValue = [];
    const selectedCount = value.reduce((count, item) => count + (item._children ? item._children.length : 1), 0);

    if (selectedCount <= maxOptions) {
      if (e && e.value === "selectAll" && e.checked) {
        newValue = [
          ...data.map((item) => {
            item._depth === 0 && item._children ? item.children.map((x) => x.value) : item.value;
          }),
        ];
      } else {
        value.forEach((val) => {
          if (val._depth === 0 && val._children) {
            const item = data.find((dat) => dat.label === val.label);
            newValue = [...newValue, ...(item ? item.children.map((x) => x.value) : [])];
          } else {
            newValue.push(val.value);
          }
        });
      }
      if (onChange) {
        onChange(Array.from(new Set(newValue)));
      }
      setLastSelectedValue(newValue[newValue.length - 1]);
    } else {
      if (onChange) {
        onChange(selectedValues);
      }
      setFieldValue(name, selectedValues);
      if (showErrorMessage) {
        setErrorMessage(true);
      } else toast.error(`You cannot choose more than ${maxOptions} fields`);
    }
  };

  useEffect(() => {
    if (dropdownSelect.current) {
      const element = dropdownSelect.current;
      element.searchInput.addEventListener("keyup", (e) => {
        setSearchTerm(element.searchInput.value);
      });
    }
  }, []);

  useEffect(() => {
    if (enableScrollToSelection && hasMounted) {
      if (lastSelectedValue) {
        scrollToLastAddedItem();
      } else {
        scrollToFocusedItem();
      }
    }
  }, [treeData]);

  return (
    !!treeData.length && (
      <div>
        <DropdownTreeSelect
          ref={dropdownSelect}
          texts={{ placeholder: title }}
          keepOpenOnSelect={true}
          keepDropdownActive={true}
          className="custom_dropdownTree"
          data={treeData}
          onChange={handleChange}
          showDropdown={"always"}
          name={name}
          keepTreeOnSearch
          id={id ? id : name}
        />
        {showErrorMessage && errorMessage && <p style={{ color: "red" }}>{`You cannot choose more than ${maxOptions} fields`}</p>}
      </div>
    )
  );
};

export default MultiLevelDropdown;
