import React from "react";
import { connect } from "react-redux";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import AddEmailAccount from "../../../components/AddEmailAccount/AddEmailAccount";

const AddEmailAccountModal = (props) => {
  return (
    <Modal
      oneButton
      title="Connect Email Account"
      show={props.showModal}
      saveButtonText="Cancel"
      btnType="submit"
      handleSave={props.handleClose}
      closeButton={false}
      handleClose={props.handleClose}
    >
      <AddEmailAccount handleSave={props.handleSave} />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    organization: state.user.organization,
  };
};

export default connect(mapStateToProps, null)(AddEmailAccountModal);
