//#region FETCH
export const FETCH_SUPPORT_CENTER_QA_CATEGORIES_REQUEST =
  "FETCH_SUPPORT_CENTER_QA_CATEGORIES_REQUEST";
export const FETCH_SUPPORT_CENTER_QA_CATEGORIES_SUCCEEDED =
  "FETCH_SUPPORT_CENTER_QA_CATEGORIES_SUCCEEDED";
export const FETCH_SUPPORT_CENTER_QA_CATEGORIES_FAILED =
  "FETCH_SUPPORT_CENTER_QA_CATEGORIES_FAILED";

export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

export const FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_REQUEST =
  "FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_REQUEST";
export const FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_SUCCEEDED =
  "FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_SUCCEEDED";
export const FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_FAILED =
  "FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_FAILED";

export const FETCH_SUPPORT_CENTER_RTE_REQUEST =
  "FETCH_SUPPORT_CENTER_RTE_REQUEST";
export const FETCH_SUPPORT_CENTER_RTE_SUCCEEDED =
  "FETCH_SUPPORT_CENTER_RTE_SUCCEEDED";
export const FETCH_SUPPORT_CENTER_RTE_FAILED =
  "FETCH_SUPPORT_CENTER_RTE_FAILED";

export const FETCH_SUPPORT_CENTER_RTE_EXPORT_REQUEST =
  "FETCH_SUPPORT_CENTER_RTE_EXPORT_REQUEST";
export const FETCH_SUPPORT_CENTER_RTE_EXPORT_SUCCEEDED =
  "FETCH_SUPPORT_CENTER_RTE_EXPORT_SUCCEEDED";
export const FETCH_SUPPORT_CENTER_RTE_EXPORT_FAILED =
  "FETCH_SUPPORT_CENTER_RTE_EXPORT_FAILED";

export const FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_REQUEST =
  "FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_REQUEST";
export const FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_SUCCEEDED =
  "FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_SUCCEEDED";
export const FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_FAILED =
  "FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_FAILED";
//#endregion

//#region ADD
export const ADD_SUPPORT_CENTER_QA_SUCCEEDED =
  "ADD_SUPPORT_CENTER_QA_SUCCEEDED";
export const ADD_SUPPORT_CENTER_QA_FAILED = "ADD_SUPPORT_CENTER_QA_FAILED";
//#endregion

//#region CHANGE
export const CHANGE_SUPPORT_CENTER_QA_SUCCEEDED =
  "CHANGE_SUPPORT_CENTER_QA_SUCCEEDED";
export const CHANGE_SUPPORT_CENTER_QA_FAILED =
  "CHANGE_SUPPORT_CENTER_QA_FAILED";

export const CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_REQUEST =
  "CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_REQUEST";
export const CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_SUCCEEDED =
  "CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_SUCCEEDED";
export const CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_FAILED =
  "CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_FAILED";

export const CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_REQUEST =
  "CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_REQUEST";
export const CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_SUCCEEDED =
  "CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_SUCCEEDED";
export const CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_FAILED =
  "CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_FAILED";
//#endregion

//#region REMOVE
export const REMOVE_SUPPORT_CENTER_QA_REQUEST =
  "REMOVE_SUPPORT_CENTER_QA_REQUEST";
export const REMOVE_SUPPORT_CENTER_QA_SUCCEEDED =
  "REMOVE_SUPPORT_CENTER_QA_SUCCEEDED";
export const REMOVE_SUPPORT_CENTER_QA_FAILED =
  "REMOVE_SUPPORT_CENTER_QA_FAILED";

export const REMOVE_SUPPORT_CENTER_RTE_REQUEST =
  "REMOVE_SUPPORT_CENTER_RTE_REQUEST";
export const REMOVE_SUPPORT_CENTER_RTE_SUCCEEDED =
  "REMOVE_SUPPORT_CENTER_RTE_SUCCEEDED";
export const REMOVE_SUPPORT_CENTER_RTE_FAILED =
  "REMOVE_SUPPORT_CENTER_RTE_FAILED";
//#endregion
