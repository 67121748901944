import * as actionTypes from "./actionTypes";
import api, { API } from "../../../helper/api/api";

export const fetchImportProcessesRequest = () => {
  return {
    type: actionTypes.FETCH_IMPORT_PROCESSES_REQUEST,
  };
};

export const fetchImportProcessesSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_IMPORT_PROCESSES_SUCCEEDED,
    result: settings,
  };
};

export const fetchImportProcessesFailed = (error) => {
  return {
    type: actionTypes.FETCH_IMPORT_PROCESSES_FAILED,
    result: error,
  };
};

export const fetchImportProcesses = () => {
  return (dispatch) => {
    dispatch(fetchImportProcessesRequest());
    return api(API.audience.getRunningImportProcesses)
      .then((response) => {
        dispatch(fetchImportProcessesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchImportProcessesFailed(error.message));
      });
  };
};

export const importProcessesUpdateProgress = (progress) => {
  return {
    type: actionTypes.IMPORT_PROCESSES_UPDATE_PROGRESS,
    result: progress,
  };
};
