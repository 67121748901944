import { FC } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import IconStartProductTour from "../../../assets/Icons/IconStartProductTour/IconStartProductTour";

//Icons
import BookReadLineIcon from "remixicon-react/BookReadLineIcon";
import PlayCircleLineIcon from "remixicon-react/PlayCircleLineIcon";

//Styles
import styles from "./ProductTourLinks.module.scss";

interface ProductTourLinksProps {
  linksWrapperClass?: string;
  labelClass?: string;
  labelTour?: string;
  documentationLink?: boolean;
  placement?: string;
  href?: string;
  tourButton?: boolean;
  showVideoButton?: boolean;
  id?: string;
  show?: (data?: string | null) => void;
}

const ProductTourLinks: FC<ProductTourLinksProps> = ({
  linksWrapperClass,
  labelClass,
  labelTour,
  documentationLink,
  placement,
  href,
  tourButton,
  showVideoButton,
  id,
  show,
}) => {
  return (
    <div className={`${styles.linksWrapper} ${linksWrapperClass}`}>
      <p className={labelClass}>{labelTour}</p>
      <div className={styles.buttonsWrapper}>
        {documentationLink ? (
          <OverlayTrigger
            key={"key-guide"}
            placement={placement ? (placement as "bottom") : "bottom"}
            overlay={
              <Popover id={`popover-tooltip-read-guide`}>
                <Popover.Body>Read the guide</Popover.Body>
              </Popover>
            }
          >
            <p className="max-x-content m0">
              <a className="flex" aria-label={`"Read more ${labelTour}"`} rel="noopener noreferrer" target="_blank" href={href}>
                <BookReadLineIcon className="iconAccent cursor_pointer" size={20} />
              </a>
            </p>
          </OverlayTrigger>
        ) : null}

        {tourButton ? (
          <button id={id} className={styles.tourButton} aria-label={labelTour}>
            <OverlayTrigger
              key={"key-tour"}
              placement={placement ? (placement as "bottom") : "bottom"}
              overlay={
                <Popover id={`popover-tooltip-take-tour`}>
                  <Popover.Body>Take the tour</Popover.Body>
                </Popover>
              }
            >
              <p className="max-x-content m0 flex">
                <IconStartProductTour imgStyle={`${styles.productIcon} cursor_pointer`} />
              </p>
            </OverlayTrigger>
          </button>
        ) : null}

        {showVideoButton ? (
          <OverlayTrigger
            key={"key-video"}
            placement={placement ? (placement as "bottom") : "bottom"}
            overlay={
              <Popover id={`popover-tooltip-watch-video`}>
                <Popover.Body>Watch the video</Popover.Body>
              </Popover>
            }
          >
            <div className="flex m0">
              <PlayCircleLineIcon className="iconAccent cursor_pointer" size={20} onClick={() => show && show(id || "")} />
            </div>
          </OverlayTrigger>
        ) : null}
      </div>
    </div>
  );
};

export default ProductTourLinks;
