import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./AutoMessage.module.scss";

const AutoMessage = ({
  text,
  subject,
  isUserMessage,
  addTemplateToEditor,
  setSaveAsNew,
  setNewSubjectTemplate,
  setNewTextTemplate,
  orgId,
  hasChangesFromCampaignSequence,
}) => {
  const newText = text.replaceAll("\n", "<br>");
  const messageClassName = isUserMessage ? `${styles.userMessage} ${styles.message}` : `${styles.autoMessage} ${styles.message}`;
  let history = useHistory();

  return (
    <div className={messageClassName}>
      <p className="m0" id="messageBox">
        {subject !== "" && (
          <div>Subject: {subject}</div>
        )}
        <div dangerouslySetInnerHTML={{ __html: newText }} />
      </p>
      {!isUserMessage && (
        <div className={`flex ${styles.buttonsFooter}`}>
          <button
            onClick={() => {
              addTemplateToEditor(text, subject);
            }}
            className={`flex1 f14 ${styles.white_btn} ${styles.white_btn_add}`}
            id="addToTextEditorBtn"
          >
            Add to text editor
          </button>
          {!hasChangesFromCampaignSequence ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setSaveAsNew(true);
                setNewSubjectTemplate(subject);
                setNewTextTemplate(text);
                history.push("/" + orgId + "/email-templates/create");
              }}
              className={`flex1 f14 ${styles.white_btn} ${styles.white_btn_start}`}
              id="saveAsNew"
            >
              Save as new
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AutoMessage;
