import React, { useState } from "react";
import styles from "./PaymentDetails.module.scss";
import ChargifyForm from "../../../../components/ChargifyForm/ExternalFormDataChargifyForm";
import buildConfig from "../../../../components/ChargifyForm/buildConfig";

const PaymentDetails = (props) => {
  const { isNewCard, setIsNewCard, creditCardInfo, firstCardEver } = props;

  const handleRadioChange = (event) => {
    setIsNewCard(event.target.value === "newCard");
  };

  return (
    <div className="flex_column">
      {!firstCardEver && (
        <>
          <div className={`mB10 flex justify_start align-center ${styles.cardWrapper}`}>
            <label className={`f14 m0 flex_column align-flex-start align-center justify_start ${styles.container}`}>
              <input type="radio" value="existingCard" checked={!isNewCard} onChange={handleRadioChange} />
              <span className={styles.checkmark}></span>
              Credit Card: <br />
              <span className="f14">{creditCardInfo?.number ?? ""}</span>
            </label>
          </div>
          <div className={` flex justify_start align-center ${styles.cardWrapper}`}>
            <label className={`f14 m0 flex align-center justify_start ${styles.container}`}>
              <input type="radio" value="newCard" checked={isNewCard} onChange={handleRadioChange} />
              <span className={styles.checkmark}></span>
              Enter New Card
            </label>
          </div>
        </>
      )}

      {(isNewCard || firstCardEver) && (
        <ChargifyForm
          setGetToken={props.setGetToken}
          getToken={props.getToken}
          isSubmitting={props.isSubmitting}
          handleReturnToken={props.handleReturnToken}
          creditCardInfo={null}
        />
      )}
    </div>
  );
};

export default PaymentDetails;
