import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { changeSalesDocumentsVideoSuccess } from "../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";

const ChangeVideoForm = (props) => {
  return (
    <Formik
      initialValues={{
        description: props.description,
        video: props.videoUrl,
        id: props.id,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        id: Yup.number(),
        video: Yup.string().required("Please enter video embeded code"),
        description: Yup.string().required("Please enter video description"),
      })}
      onSubmit={(values, actions) => {
        api(API.salesDocument.updateVideo, values)
          .then((response) => {
            props.changeSalesDocumentsVideoSuccess(response.data);
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleSubmit, isSubmitting } = formikProps;

        return (
          <Modal
            title="Change Video"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <FormikInput label="Video: " name="video" type="textarea" placeholder="Embedded video code" />
              <label>Title</label>
              <Field name="description" type="text" value={values.description} onChange={handleChange} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSalesDocumentsVideoSuccess: (model) => {
      dispatch(changeSalesDocumentsVideoSuccess(model));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVideoForm);
