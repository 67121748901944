import React, { FC, useState } from "react";
import { Dropdown } from "react-bootstrap";

//Models
import { OptimizationPriorityEnum, optimizationPriorityToString } from "@models/campaign/OptimizationPriorityEnum";

//Icons
import ErrorWarningLineIcon from "remixicon-react/ErrorWarningLineIcon";
import ArrowUpLineIcon from "remixicon-react/ArrowUpLineIcon";
import ArrowDownLineIcon from "remixicon-react/ArrowDownLineIcon";

//Styles
import styles from "./PriorityFilter.module.scss";

//Components
import Checkbox from "@ui/Checkbox/Checkbox";

interface PriorityFilterProps {
  activePriorityFilters: OptimizationPriorityEnum[];
  handlePriorityFilterChange: (priority: OptimizationPriorityEnum) => void;
  validPriorities: OptimizationPriorityEnum[];
}

const PriorityFilter: FC<PriorityFilterProps> = ({ activePriorityFilters, handlePriorityFilterChange, validPriorities }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // const handleCheckboxChange = (priority: OptimizationPriorityEnum, event: React.ChangeEvent<HTMLInputElement>) => {
  //   event.stopPropagation();
  //   handlePriorityFilterChange(priority);
  // };

  const handleCheckboxChange = (priority: OptimizationPriorityEnum) => {
    // Implement your logic here if needed
    handlePriorityFilterChange(priority);
  };

  const selectedCount = activePriorityFilters.length;

  const renderIconAndClassName = (priority: OptimizationPriorityEnum): { icon: React.ReactNode; className: string } => {
    let icon;
    let className;

    switch (priority) {
      case OptimizationPriorityEnum.High:
        icon = <ErrorWarningLineIcon color="var(--danger-color-700)" size={16} />;
        className = "high";
        break;
      case OptimizationPriorityEnum.Medium:
        icon = <ArrowUpLineIcon color="var(--warning-color-700)" size={16} />;
        className = "medium";
        break;
      case OptimizationPriorityEnum.Low:
        icon = <ArrowDownLineIcon color="var(--info-color)" size={16} />;
        className = "low";
        break;
      default:
        icon = null;
        className = "";
        break;
    }

    return { icon, className };
  };

  return (
    <Dropdown className={styles.dropdownComponent} show={showDropdown} onToggle={toggleDropdown}>
      <Dropdown.Toggle className={styles.dropdownBtn} id="priority-filter-dropdown">
        Priority {selectedCount > 0 && <span className={styles.selectedCounter}>{selectedCount}</span>}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        {validPriorities.map((priority) => {
          const { icon, className } = renderIconAndClassName(priority);
          return (
            <Dropdown.Item className={styles.dropdownItem} key={priority} onClick={(e) => e.stopPropagation()}>
              <label className={styles.labelCheckbox} onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checkBoxClass="m0"
                  checked={activePriorityFilters.includes(priority)}
                  onChange={() => handleCheckboxChange(priority)}
                />
                <span className={`${styles.optionIcon} ${styles[className]}`}>
                  {icon}
                  {optimizationPriorityToString(priority)}
                </span>
              </label>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PriorityFilter;
