import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../../../helper/api/api";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { toastMessages } from "../../../../helper/toastMessagesConstants";

// Styles
import styles from "./BillingInfoModal.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import BillingInfo from "../BillingInfo/BillingInfo";
import AlertNotification from "../../../../components/UI/AlertNotification/AlertNotification";
import FormikEffect from "../../../../components/UI/Formik/FormikEffect";
import ReadOnlyInput from "../../../../components/ReadOnlyInput/ReadOnlyInput";

const BillingInfoModal = (props) => {
  const {
    billingInfo,
    planId,
    pricePointId,
    planChargifyHandle,
    pricePointChargifyHandle,
    billingPeriod,
    createBillingInfo,
    orgId,
    coupon,
  } = props;

  let renderPage = null;

  const [updateBillingInfo, setUpdateBillingInfo] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [hasStates, setHasStates] = useState(false);

  let couponInput = showCoupon ? <ReadOnlyInput readOnlyLabelClass="width_50" label="Coupon" value={coupon?.code} /> : null;

  const onChange = () => {
    setUpdateBillingInfo(true);
  };

  const handleModalClose = () => {
    setShowCoupon(false);
    props.handleClose();
  };

  return (
    <Formik
      initialValues={{
        id: billingInfo !== null ? billingInfo?.id : 0,
        firstName: billingInfo !== null ? billingInfo?.firstName : "",
        lastName: billingInfo !== null ? billingInfo?.lastName : "",
        email: billingInfo !== null ? billingInfo?.email : "",
        organizationName: billingInfo !== null ? billingInfo?.organizationName : "",
        address: billingInfo !== null ? billingInfo?.address : "",
        city: billingInfo !== null ? billingInfo?.city : "",
        country: billingInfo !== null ? billingInfo?.country : "",
        state: billingInfo !== null ? billingInfo?.state : "",
        zip: billingInfo !== null ? billingInfo?.zip : "",
        vatNumber: billingInfo !== null ? billingInfo?.vatNumber : "",
      }}
      enableReinitialize={billingInfo !== null}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        organizationName: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        zip: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        if (hasStates && (values.state === "" || values.state === undefined)) {
          actions.setFieldError("state", "Required");
          actions.setSubmitting(false);
          return;
        }
        else if (!hasStates && values.state !== "") {
          values.state = "";
        }
        let obj = {
          billingInfo: values,
          updateBillingInfo: updateBillingInfo,
          planChargifyHandle: planChargifyHandle,
          pricingPointChargifyHandle: pricePointChargifyHandle,
          pricingPlan: planId,
          pricingPoint: pricePointId,
          billingPeriod: billingPeriod,
          createBillingInfo: createBillingInfo,
          couponCode: coupon?.code,
        };

        api(API.billingAndPayment.requestSigning, obj)
          .then((res) => {
            props.handleClose();
            actions.setSubmitting(false);
            toast.success(toastMessages.billing.successUpgradePricingPlanCall);
            props.history.push("/" + props.orgId + "/organization-settings/billing-and-payment");
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            }
            toast.error(error.message, { autoClose: false });
            props.handleClose();
            actions.setSubmitting(false);
          })
          .finally(() => {
            props.handleClose();
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, values, isSubmitting, setFieldValue } = formikProps;

        renderPage = (
          <BillingInfo
            setFieldValue={setFieldValue}
            values={values}
            click={handleSubmit}
            setDisableSaveButton={"Next"}
            setShowCoupon={setShowCoupon}
            coupon={coupon}
            setHasStates={setHasStates}
          />
        );

        return (
          <Modal
            title={"Service Agreement"}
            handleClose={handleModalClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText={"Cancel"}
            saveButtonText={"Send Agreement"}
            btnType="submit"
            backdrop={true}
            dialogClassName={`${styles.paymentModal} customModal`}
            size="lg"
            saveButtonDisabled={isSubmitting}
          >
            <Form>
              <FormikEffect onChange={() => onChange()} />
              <div className={`${styles.modalFormWrapper}`}>
                <div className="flex">
                  <div className={styles.stepperWrapper}>
                    <h1>Service Agreement Info</h1>
                    <AlertNotification variant="info_alert" notificationWrapperClass="mT15 mB15">
                      <p>
                        Please provide the information below so we can email you a copy of your service agreement to sign. <br />
                        When this is signed return to Outbase and add your payment details to complete your upgrade.
                      </p>
                    </AlertNotification>
                    {renderPage}
                  </div>
                </div>
                {couponInput}
                {/* <div className={styles.secureDescriptionWrapper}>
                  <IconLock />
                  <p className={styles.descriptionText}>
                    This is a secured 128-bit SSL encryption payment.
                  </p>
                </div> */}
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default BillingInfoModal;
