import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../../helper/api/api";

export const fetchUserActivityLogDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_USER_ACTIVITY_LOG_DETAILS_REQUEST,
  };
};

export const fetchUserActivityLogDetailsSuccess = (events) => {
  return {
    type: actionTypes.FETCH_USER_ACTIVITY_LOG_DETAILS_SUCCEEDED,
    result: events,
  };
};

export const fetchUserActivityLogDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_USER_ACTIVITY_LOG_DETAILS_FAILED,
    result: error,
  };
};

export const fetchUserActivityLogDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchUserActivityLogDetailsRequest());
    let data = {
      id: id,
    };
    return api(formUrl(API.OUTBOSS.userActivityLog.getUserActivityLogDetails, data))
      .then((response) => {
        dispatch(fetchUserActivityLogDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserActivityLogDetailsFailed(error.message));
      });
  };
};
