import React from "react";

//Styles
import styles from "./AssistantControlPanel.module.scss";

interface AssistantControlPanelProps {
  componentTitle: string;
  description: string;
  startAIImage: JSX.Element;
  startButtonText: string;
  startSubText: string;
  onStartClick: () => void;
  skipStepImg: JSX.Element;
  closeButtonText: string;
  closeSubText: string;
  onCloseViewClick: () => void;
  parentClassName?: string;
}

const AssistantActionBar: React.FC<AssistantControlPanelProps> = ({
  componentTitle,
  description,
  startAIImage,
  startButtonText,
  startSubText,
  onStartClick,
  skipStepImg,
  closeButtonText,
  closeSubText,
  onCloseViewClick,
  parentClassName,
}) => {
  return (
    <div className={parentClassName}>
      <h1 className={styles.componentTitle}>{componentTitle}</h1>
      <span className={styles.titleDescription}>{description}</span>
      <button id={startButtonText ? startButtonText.replaceAll(" ", "") : ""} className={styles.btn} onClick={onStartClick}>
        <div className={styles.content}>
          {startAIImage}
          <div className={styles.btnText}>
            <p className={styles.btnTitle}>{startButtonText}</p>
            <small className={styles.btnDescription}>{startSubText}</small>
          </div>
        </div>
      </button>
      <button id={closeButtonText ? closeButtonText.replaceAll(" ", "") : ""} className={styles.btn} onClick={onCloseViewClick}>
        <div className={styles.content}>
          {skipStepImg}
          <div className={styles.btnText}>
            <p className={styles.btnTitle}>{closeButtonText}</p>
            <small className={styles.btnDescription}>{closeSubText}</small>
          </div>
        </div>
      </button>
    </div>
  );
};

export default AssistantActionBar;
