import React from "react";
import { connect } from "react-redux";

//Helpers
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";

//Styles
import styles from "./TopBannerUser.module.scss";

// Components
import BreadcrumbElement from "../../../components/UI/BreadcrumbElement/BreadcrumbElement";
import Button from "../../../components/UI/Button/Button";

const TopBannerUser = (props) => {
  return (
    <div className={styles.topBannerWrapper}>
      <div className={styles.topBannerHistory}>
        <BreadcrumbElement items={props.breadcrumbs} />
        <div className={styles.userInfoWrapper}>
          <h1 className={styles.title}>
            User details: <span>{props.userName}</span>
          </h1>
          {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.users.edit) ? (
            <Button id="removeAllOrganizations" variant="danger" onClick={() => props.handleRemoveUserAccessShow(props.userId, 0)}>
              Remove All Organizations
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBannerUser);
