import React from "react";

//Styles
import styles from "./ProspectSearchRow.module.scss";

//Components
import UserPhoto from "../../../UI/UserPhoto/UserPhoto";

const ProspectSearchRow = (props) => {
  let fullName = props.name + " " + props.lastName;
  let imageUrl = props.imageUrl;

  return (
    <div className={styles.rowStyle}>
      <div className="flex flex_center">
        <UserPhoto src={imageUrl} name={fullName} size="small" />
      </div>
      <div className={styles.details}>
        <span className={`${styles.name} f14`}>
          {props.name + " "} {props.lastName}
        </span>
        <div className={styles.jobDetails}>
          <span>{props.jobPosition}</span>
          <span>@{props.company}</span>
          <span>| {props.campaign}</span>
        </div>
      </div>
    </div>
  );
};

export default ProspectSearchRow;
