import React from "react";

const IconFileText = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_50_271)">
      <path
        d="M23.7727 9.80387V31.2009H0.227234V0.799316H14.7028"
        stroke="#999999"
        strokeMiterlimit="6.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.4592 10.1149H14.7028C14.5291 10.1149 14.3893 9.97615 14.3893 9.80374V1.1104C14.3893 0.937994 14.5291 0.799194 14.7028 0.799194L23.7727 9.80374C23.7727 9.97615 23.6329 10.1149 23.4592 10.1149Z"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.38345 20.6917H18.9984"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.38345 16H18.9984"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.38345 25.624H18.9984"
        stroke="#999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_50_271">
        <rect width="24" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconFileText;
