import React, { FC } from "react";

//Styles
import styles from "./VideoModal.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import { UseModal } from "@components/UI/Modal/useModal";

const VideoModal: React.FC<{ modal: UseModal<string> }> = ({ modal }) => {
  const videoSrc = () => {
    switch (modal.data) {
      case "addEmailAccount":
        return (
          <iframe
            src="https://player.vimeo.com/video/699341221?h=ce15340b7b"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      case "campaignStep":
        return (
          <iframe
            src="https://player.vimeo.com/video/699371336?h=78ac60fa8f"
            width="690"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      case "sequenceStep":
        return (
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/779672469?h=a98b07aa9c"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        );
      case "schedulerStep":
        return (
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/779672614?h=c43f233f9d"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        );
      case "audiencesStep":
        return (
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/779672814?h=8103b4cf50"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        );
      case "activateStep":
        return (
          <iframe
            src="https://player.vimeo.com/video/699374703?h=23f6417939"
            width="690"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      case "createCampaign":
        return (
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/779264881?h=13394d00a0"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        );
    }
  };

  return (
    <>
      <Modal
        handleSave={modal.hide}
        size="lg"
        oneButton={true}
        show={modal.isVisible}
        saveButtonText="Back"
        btnType="submit"
        customBodyClass={styles.videoModal}
      >
        {videoSrc()}
      </Modal>
    </>
  );
};

export default VideoModal;
