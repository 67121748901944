export const GLOBAL_PERMISSIONS = {
  organization: {
    createMultiple: "Organization.CreateMultiple",
  },

  globalOrganizations: {
    viewAll: "GlobalOrganizations.ViewAll",
    viewDisabled: "GlobalOrganizations.ViewDisabled",
  },

  outboss: {
    view: "Outboss.View",

    impersonate: {
      canImpersonate: "CanImpersonate",
    },

    removeAccess: {
      canRemoveAccess: "CanRemoveAccess",
    },

    users: {
      view: "Outboss.Users.View",
      create: "Outboss.Users.Create",
      edit: "Outboss.Users.Edit",
      delete: "Outboss.Users.Delete",
    },

    organization: {
      view: "Outboss.Organization.View",
      create: "Outboss.Organization.Create",
      edit: "Outboss.Organization.Edit",
      delete: "Outboss.Organization.Delete",
    },

    userActivityLog: {
      view: "Outboss.UserActivityLog.View",
    },

    emailAccounts: {
      view: "Outboss.EmailAccounts.View",
      create: "Outboss.EmailAccounts.Create",
      edit: "Outboss.EmailAccounts.Edit",
      delete: "Outboss.EmailAccounts.Delete",
    },
  },
};
