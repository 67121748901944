import React, { useEffect, useState, useRef, useMemo, ReactNode } from "react";
import { Container, OverlayTrigger, Row, Popover } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import _, { debounce } from "lodash";
import Select from "react-select";

//Helpers
import { numberOfRecords } from "../../helper/tableConstants.ts";

//Styles
// import styles from "./Table.module.scss";
import "./Table.scss";

//Components
import ShowingPerPage from "./ShowingPerPage/ShowingPerPage";
import { UseDataTableReturn } from "../../helper/hooks/useDataTable";
import { DTSort } from "../../models/dataTables/DtSort";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { DropdownModel } from "@models/DropDownModel.ts";
import CustomColumnHeader from "./CustomColumnHeader.tsx";

interface DataTableProps {
  dataTable: UseDataTableReturn<any>;
  columns: any[];
  rows: any[];

  filters?: any[];
  showPagination?: boolean;
  showPerPageContent?: boolean;
  hideSort?: boolean;
  tableClassName?: string;
  wrapperTableClass?: string;
  variant?: string;
  tableFooterClass?: string;
  showPerPageValue?: number;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  nextLabel?: string;
  previousLabel?: string;
  pageClassName?: string;
  hideLabelShow?: boolean;
  forcePageChanged?: boolean;
  scrollY?: boolean;
  tableHeight?: number;
  noRecordsFoundLabel?: string;
  showingOf?: boolean;
  big?: boolean;
  small?: boolean;
}

export interface ColumnDefinition {
  field: string;
  type?: string;
  label: ReactNode;
  columnClass?: string;
  sort?: "asc" | "desc" | "disabled";
  searchable?: boolean;
  tooltipMessage?: string;
  searchType?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  flex?: number;
  minimal?: string;
  cellClass?: string;
  option?: DropdownModel[];
  placeholder?: string;
}

const DataTable: React.FC<DataTableProps> = (props) => {
  const { dataTable, filters, showPagination, showPerPageContent, hideSort } = props;
  const { sortData, changePage, setItemsPerPage, setFilter } = dataTable.actions;
  const { pageCount, totalCount, params } = dataTable.state;

  const showStart = params.start + 1;
  let showEnd = params.start + params.length;

  let classes = `defaultStyle`;
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const scrollToTopRef = useRef<HTMLDivElement>(null);

  const [rows, setRows] = useState<any[]>([]);
  const columns = useMemo(() => {
    return _.cloneDeep(props.columns);
  }, [props.columns]);
  // const [columns, setColumns] = useState<any[]>([]);
  const [pageChangeActive, setPageChangeActive] = useState(false);

  const hasSearchable = columns.find((x) => x.searchable === true) != null;
  if (!_.isEmpty(props.tableClassName)) classes += " " + props.tableClassName;

  const variant = props.variant || "";
  if (!_.isEmpty(variant)) classes += " " + [variant];

  if (hasSearchable) classes += `  searchableTable`;
  if (showEnd > totalCount) {
    showEnd = totalCount;
  }

  let showingPerPage = true;
  if (!showPerPageContent && showPerPageContent !== undefined) {
    showingPerPage = false;
  }
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (scrollToTopRef.current != null) {
      const scroll =
        scrollToTopRef.current?.childNodes[1]?.childNodes[1]?.childNodes[0]?.childNodes[0]?.childNodes[1]?.childNodes[0]?.childNodes[0];
      if (scroll != null && scroll instanceof HTMLElement) {
        scroll.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }
  }, [params.page]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(props.rows);
    rowsTemp.map((row) => {
      let key = 0;
      for (const prop in row) {
        if (Object.prototype.hasOwnProperty.call(row, prop)) {
          let className = "grid-default";
          const column = props.columns.find((x) => x.field === prop);
          let showTooltip = false;
          if (column != null) {
            if (column.type != null) {
              switch (column.type) {
                case "text":
                  className = "grid-text-default text-overflow-hidden";
                  break;
                case "number":
                  className = "grid-number-default text-overflow-hidden";
                  break;
                case "date":
                  className = "grid-date-default ";
                  break;
                case "actions":
                  className = "grid-actions-default";
                  break;
                default:
                  break;
              }
            }

            if (column.class != null) {
              className += " " + column.class;
            }
            if (column.showTooltip && column.showTooltip === true) {
              showTooltip = true;
            }
          }
          if (showTooltip === true) {
            row[prop] = (
              <div className="text-overflow-hidden">
                <OverlayTrigger
                  key={"key"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`popover-tooltip`}>
                      <Popover.Body>
                        <p style={{ color: "#ffff" }}>{row[prop]}</p>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <div className="max-x-content">
                    <div key={key} className={className}>
                      {row[prop]}
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            );
          } else {
            row[prop] = (
              <div key={key} className={className}>
                {row[prop]}
              </div>
            );
          }
        }
        key++;
      }
    });

    setRows(rowsTemp);
    setPageChangeActive(false);
  }, [props.rows, props.columns]);

  const debouncedSortHandler = useMemo(
    () =>
      debounce((column: string) => {
        if (column == null) {
          return;
        }

        const newObj: DTSort = { column: column, direction: "asc" };
        if (params?.sort?.column === column && params?.sort?.direction === "asc") {
          newObj.direction = "desc";
        }

        if (sortData) sortData(newObj);
      }, 300),
    [params.sort, sortData]
  );

  const pageHandler = (selected: number) => {
    changePage(selected);
  };

  const sortHandler = (column: string) => {
    debouncedSortHandler(column);
  };

  let selects = null;
  if (filters) {
    selects = filters.map((f) => {
      return (
        <div>
          <Select
            key={f.selectName}
            isMulti
            name={f.selectName}
            options={f.selectOptions}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      );
    });
  }

  let pagination = null;
  if (showPagination !== false && totalCount > 1) {
    pagination = (
      <div className={`tableFooter tableFooter ${props.tableFooterClass}`}>
        <div className="elementsWrapper">
          {showingPerPage ? (
            <div>
              <ShowingPerPage
                numberOfRecords={numberOfRecords}
                change={(obj: { value: string; label: string }) => {
                  setItemsPerPage(parseInt(obj.value));
                  changePage(0);
                }}
                defaultValue={
                  props.showPerPageValue != null
                    ? numberOfRecords.find((n) => n.value === props.showPerPageValue)
                    : numberOfRecords.find((n) => n.value === params.length)
                }
                {...props}
              />
            </div>
          ) : null}
          <div
            className={`
         
          ${props.hideLabelShow ? "hiddenLabel" : "paginationLabel"} 
          ${pageChangeActive ? "disablePagination" : ""}
          paginationWrapper`}
          >
            <ReactPaginate
              disabledClassName={"pageHandlerDisabled"}
              pageCount={Math.ceil(pageCount)}
              pageRangeDisplayed={props.pageRangeDisplayed !== undefined ? props.pageRangeDisplayed : 5}
              marginPagesDisplayed={props.marginPagesDisplayed !== undefined ? props.marginPagesDisplayed : 2}
              nextLabel={props.nextLabel !== undefined ? props.nextLabel : ">"}
              previousLabel={props.previousLabel !== undefined ? props.previousLabel : "<"}
              containerClassName={`pagination pagesList`}
              // subContainerClassName={"pages pagination"} // Typescript error here. Check if it affects anything
              // activeClassName={styles.active}
              initialPage={params.page}
              activeClassName="active"
              onPageChange={(selectedItem) => {
                if (!pageChangeActive) setPageChangeActive(true);
                pageHandler(selectedItem.selected);
              }}
              pageClassName={props.pageClassName !== undefined ? props.pageClassName : "mx-2"}
              previousClassName="paginationPrevious"
              nextClassName="paginationNext"
              forcePage={props.forcePageChanged ? params.page : undefined}
            />
            {!props.showingOf ? null : (
              <p className="totalCount">
                {showStart}-{showEnd} of {totalCount}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  const colDefs: ColDef[] = _.cloneDeep(props.columns).map(({ ...column }) => ({
    field: column.field,
    type: column.type,
    width: column.width,
    minWidth: column.minWidth,
    flex: column.flex,
    headerName: column.label,
    pinned: column.pinned,
    cellRenderer: (params: unknown) => {
      return <>{params.value}</>;
    },
    valueFormatter: (params) => {
      return params.value?.toString();
    },
    headerComponent: "customColumnHeader",
    headerComponentParams: {
      columnDefinition: column,
      params: params,
      // selectAllRows: selectAllRows,
      // allRowsSelected: allRowsSelected,
      setFilter: setFilter,
      parentSort: sortData,
      hideSort: hideSort,
      sortHandler: sortHandler,
      sort: params.sort,
    },
    autoHeight: true,
    sortable: column.sort !== "disabled",
    lockPosition: true,
    resizable: false,
  }));

  const gridOptions = {
    columnDefs: colDefs,
    suppressAnimationFrame: true,
    suppressRowTransform: true,
    animateRows: false,
    columnTypes: {
      text: {},
      actions: {},
      number: {},
      date: {},
    },
    components: {
      customColumnHeader: CustomColumnHeader,
    },
  };

  const gridOptionsMap = {
    large: {
      rowHeight: 65,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    },
    medium: {
      rowHeight: 100,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    },
    small: {
      rowHeight: 40,
      ensureDomOrder: true,
      domLayout: "autoHeight",
    },
  };

  const variantOptions =
    props.variant && gridOptionsMap[props.variant as keyof typeof gridOptionsMap]
      ? gridOptionsMap[props.variant as keyof typeof gridOptionsMap]
      : {};

  const mergedGridOptions = {
    ...gridOptions,
    ...variantOptions,
  };

  return (
    <>
      <div
        style={{
          height: props.tableHeight ? windowHeight - props.tableHeight + "px" : "100%",
        }}
      >
        <Container>
          <Row>{selects}</Row>
        </Container>

        <div
          className="ag-theme-quartz"
          style={{
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <AgGridReact
            className={`${props.wrapperTableClass} outbaseTable`}
            rowClass={classes}
            rowData={rows}
            gridOptions={mergedGridOptions}
            columnDefs={colDefs}
            suppressScrollOnNewData={true}
          />
        </div>

        {pagination}
      </div>
    </>
  );
};

export default DataTable;
