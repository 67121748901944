export enum OptimizationCategoryEnum {
  Setup = 0,
  Targeting = 1,
  Content = 2,
  Health = 3,
  EngagementRate = 4,
  Technical = 50
}

export function optimizationCategoryToString(type: OptimizationCategoryEnum): string {
  switch (type) {
    case OptimizationCategoryEnum.Targeting:
      return "Targeting";
    case OptimizationCategoryEnum.Content:
      return "Content";
    case OptimizationCategoryEnum.Technical:
      return "Technical";
    case OptimizationCategoryEnum.Health:
      return "Campaign health";
    case OptimizationCategoryEnum.Setup:
      return "Setup";
    case OptimizationCategoryEnum.EngagementRate:
      return "Engagement rate";
    default:
      return "Unknown";
  }
}
