import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";

//Helpers
import { timeToDate, removeSecondsFromTime, dateToTime } from "../../../../../../helper/dateHelper";
import { timePicker } from "../../../../../../helper/tableConstants";
//Styles
import styles from "./DayTimeToSend.module.scss";

//Components
import FormikTimeTextPicker from "../../../../../../components/UI/Formik/FormikTimeTextPicker";

const DayTimeToSend = (props) => {
  const timeIntervalsMinutes = 15;

  let from = removeSecondsFromTime(
    _.isEmpty(props.formikProps.values[props.day].from) ? "09:00" : props.formikProps.values[props.day].from
  );
  let to = removeSecondsFromTime(_.isEmpty(props.formikProps.values[props.day].to) ? "17:00" : props.formikProps.values[props.day].to);

  const [fromMinValue, setFromMinValue] = useState(to);
  const [toMaxValue, setToMaxValue] = useState(from);

  let fromDate = timeToDate(from);
  let toDate = timeToDate(to);

  let fromMaxValue = "";
  if (toDate != null) {
    const fromMaxValueMoment = Moment(toDate).add(timeIntervalsMinutes * -1, "minutes");
    if (fromMaxValueMoment != null) {
      fromMaxValue = dateToTime(fromMaxValueMoment.toDate()) || "";
    }
  }

  let toMinValue = "";
  if (fromDate != null) {
    const toMinValueMoment = Moment(fromDate).add(timeIntervalsMinutes, "minutes");
    if (toMinValueMoment != null) {
      toMinValue = dateToTime(toMinValueMoment.toDate()) || "";
    }
  }

  return (
    <>
      <div className={styles.dayTimeWrapper}>
        <FormikTimeTextPicker
          selectClass={styles.timePicker}
          style={timePicker}
          placeholder={"-- : --"}
          value={from}
          fromMin={fromMinValue}
          setToMaxValue={setToMaxValue}
          disabled={props.disabled}
          name={props.day + ".from"}
          setIsDirty={props.setIsDirty}
          setFieldValue={props.setFieldValue}
        />
      </div>
      <div className={styles.dayTimeWrapper}>
        <FormikTimeTextPicker
          selectClass={styles.timePicker}
          style={timePicker}
          placeholder={"-- : --"}
          value={to}
          toMax={toMaxValue}
          setFromMinValue={setFromMinValue}
          disabled={props.disabled}
          name={props.day + ".to"}
          setIsDirty={props.setIsDirty}
          setFieldValue={props.setFieldValue}
        />
      </div>
    </>
  );
};

export default DayTimeToSend;
