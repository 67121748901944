export const documentsTableColumns = [
  {
    label: "Name",
    field: "name",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Size",
    field: "size",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 150,
    minWidth: 150,
  },
  {
    label: "Creator",
    field: "createdBy",
    type: "text",
    sort: "asc",
    width: 150,
    minWidth: 150,
  },
  {
    label: "Date Added",
    field: "created",
    type: "date",
    sort: "asc",
    width: 150,
    minWidth: 150,
  },
  {
    label: "Tags",
    field: "tags",
    type: "text",
    sort: "asc",
    width: 150,
    minWidth: 150,
  },
  {
    label: "Comment",
    field: "comment",
    sort: "asc",
    width: 150,
    minWidth: 150,
    showTooltip: true,
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    width: 150,
    minWidth: 150,
  },
];
