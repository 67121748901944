import { useState } from "react";

// Components
import { Formik } from "formik";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "../../../../../components/UI/Modal/Modal";
import FormikCheckbox from "../../../../../components/UI/Formik/FormikCheckbox";
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import { cancelledSubscriptionReasonEnum } from "../../../../../helper/enums/cancelationReasonEnym";
import api, { API } from "../../../../../helper/api/api";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { toast } from "react-toastify";

const CancelConfirmModal = (props) => {
  const [listCancelation, setListCancelation] = useState([]);
  const [noButtonClicked, setNoButtonClicked] = useState(true);

  return (
    <Formik
      initialValues={{
        tooExpensive: false,
        lackOfLeads: false,
        techIssues: false,
        otherIssues: false,
        lowQualityOfData: false,
        complianceIssues: false,
        poorUserExperience: false,
        takingBreak: false,
        improve: "",
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        if (listCancelation.length === 0) return;

        const data = {
          reasons: listCancelation,
          toImprove: values.improve,
        };
        api(API.billingAndPayment.cancelSubscription, data)
          .then((response) => {
            props.handleSave();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => {
        const handleCheckBoxSelect = (val, reason) => {
          if (val) {
            setListCancelation((prevState) => [...prevState, reason]);
          } else {
            setListCancelation((listCancelation) => listCancelation.filter((item) => item !== reason));
          }
        };
        if (listCancelation.length > 0) setNoButtonClicked(false);
        else if (listCancelation.length === 0) setNoButtonClicked(true);

        return (
          <Modal
            dialogClassName={"customModal"}
            customBodyClass={"paymentModalBody"}
            title={"We are sorry to see you go"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            saveButtonText="Confirm cancelation"
            btnType="submit"
            variant="danger"
            closeButtonText={"Keep current plan"}
            saveButtonDisabled={noButtonClicked || isSubmitting}
          >
            <div>
              <p>
                <strong>Before you leave, could you let us know a little more about your experience with Outbase?</strong> Any feedback
                would be greatly appreciated and help us to improve Outbase for future users.
              </p>
              <Container>
                <Row>
                  <Col xl={12} className="p-0">
                    <p className="mb-2">Reasons for leaving *</p>
                  </Col>
                  <Col xl={6}>
                    <FormikCheckbox
                      label="Too expensive"
                      name="tooExpensive"
                      checked={values.tooExpensive}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.tooExpensive)}
                    />
                    <FormikCheckbox
                      label="Lack of leads"
                      name="lackOfLeads"
                      checked={values.lackOfLeads}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.lackOfLeads)}
                    />
                    <FormikCheckbox
                      label="Tech issues"
                      name="techIssues"
                      checked={values.techIssues}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.techIssues)}
                    />
                    <FormikCheckbox
                      label="Low quality of data"
                      name="lowQualityOfData"
                      checked={values.lowQualityOfData}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.lowQualityOfData)}
                    />
                  </Col>
                  <Col xl={6}>
                    <FormikCheckbox
                      label="Compliance issues"
                      name="complianceIssues"
                      checked={values.complianceIssues}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.complianceIssues)}
                    />
                    <FormikCheckbox
                      label="Poor user experience"
                      name="poorUserExperience"
                      checked={values.poorUserExperience}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.poorUserExperience)}
                    />
                    <FormikCheckbox
                      label="Taking a break"
                      name="takingBreak"
                      checked={values.takingBreak}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.takingABreak)}
                    />
                    <FormikCheckbox
                      label="Other"
                      name="otherIssues"
                      checked={values.otherIssues}
                      onChange={(val) => handleCheckBoxSelect(val, cancelledSubscriptionReasonEnum.other)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} className="mT10 p-0">
                    <p>What should we improve?</p>
                    <FormikInput
                      label=""
                      name="improve"
                      type="textarea"
                      placeholder="Tell us more"
                      inputGroupClass="mb-2"
                      value={values.improve}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CancelConfirmModal;
