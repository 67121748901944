import React, { useEffect, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import _ from "lodash";

//Styles
import "./ProgressBar.scss";

const ProgressBarUI = (props) => {
  let classes = "progress";
  if (!_.isEmpty(props.variant)) classes += " " + [props.variant];

  const progressBarRef = useRef(null);

  useEffect(() => {
    if (props.now === 100) {
      progressBarRef.current.firstChild.classList.remove("custom_linear_gradient");
      progressBarRef.current.firstChild.classList.add("success_progress");
    } else {
      progressBarRef.current.firstChild.classList.remove("success_progress");
      progressBarRef.current.firstChild.classList.add("custom_linear_gradient");
    }

    if (props.variant === "white_transparent") {
      progressBarRef.current.firstChild.classList.add("custom_linear_gradient");
      progressBarRef.current.firstChild.style.borderRadius = "20px";
    }
    if (props.variant === "success_progress") {
      progressBarRef.current.firstChild.className = "";
      progressBarRef.current.firstChild.style.background = "success_progress";
      progressBarRef.current.firstChild.style.borderRadius = "20px";
    }
  }, [progressBarRef, props.now, props.variant]);

  return (
    <div>
      {props.topLabelText && (
        <p className="f12 custom-progress_label">
          {props.topLabelText} <span className="noWrap custom_current_value">{props.now}%</span>
        </p>
      )}
      <ProgressBar
        className={classes}
        ref={progressBarRef}
        now={props.now}
        max={props.max}
        min={props.min}
        animated={props.animated}
        aria-labelledby={props.ariaLabel}
      />
      {props.showLabel && <p className="f12 noWrap custom-progress_label">{props.labelText}</p>}
    </div>
  );
};

export default ProgressBarUI;
