import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

//Redux
import {
  fetchGlobalAverageRate,
  fetchLeadRate,
  fetchTotalResponses,
  fetchTotalRunRates,
  setPreviousOrganizationId,
  fetchStepsDone,
  setShowProductTour,
} from "../../../../store/Dashboard/Global/actions/dashboard";

//Helpers
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";
import { addOffsetToDate } from "../../../../helper/dateHelper";
import { formatNumberHelper } from "../../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../../helper/hooks/useGroupDecimalSeparator";

// Style
import styles from "./DashboardDetailsView.module.scss";

// Components
import HeaderTitle from "../../../../components/UI/HeaderTitle/HeaderTitle";
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import StackedBarChart from "../../../../components/StackedBarChart/StackedBarChart";
import PieChartAngle from "../../../../components/PieChartAngle/PieChartAngle";
import Button from "../../../../components/UI/Button/Button";
import ShowDateRangePicker from "../../../../components/DataTable/ShowDateRangePicker/ShowDateRangePicker";
import ProspectFunnel from "../../Components/ProspectFunnel/ProspectFunnel";
import CampaignPerformance from "../../AccordionsContent/CampaignPerformance/CampaignPerformance";
import CampaignsInfo from "../../Components/CampaignsInfo/CampaignsInfo";
import { customStaticRanges } from "../../../ActivityOverview/Shared/customDaysRanges";
import TotalCampaignPerformance from "../../Components/TotalCamapignPerformance/TotalCampaignPerformance";
import ProgressLine from "../../../../components/UI/ProgressLine/ProgressLine";
import DashboardResponsesByCampaign from "../../AccordionsContent/ResponsesByCampaign/DashboardResponsesByCampaign";
import RunRatesByCampaign from "../../AccordionsContent/RunRatesByCampaign/RunRatesByCampaign";
import Legend from "../../../../components/UI/MultiProgressBar/Legend/Legend";
import SkeletonPieChart from "../../../../components/UI/Skeletons/components/SkeletonPieChart/SkeletonPieChart";
import AntSwitch from "../../../../components/UI/AntSwitch/Switch";
import PageContentWrapper from "../../../../components/PageContentWrapper/PageContentWrapper";
import Accordion from "../../../../components/UI/Accordion/Accordion";

const Dashboard = (props) => {
  const {
    globalAverageRate,
    fetchGlobalAverageRate,
    previousOrganizationId,
    setPreviousOrganizationId,
    fetchStepsDone,
    stepsDone,
    isStepsDoneLoaded,
    showProductTour,
    setShowProductTour,
  } = props;
  let separators = useGroupDecimalSeparator();
  const [globalAverageRateOpen, setGlobalAverageRateOpen] = useState(0);
  const [globalAverageUniqueRateOpen, setGlobalAverageUniqueRateOpen] = useState(0);
  const [globalAverageRateClick, setGlobalAverageRateClick] = useState(0);
  const [globalAverageUniqueRateClick, setGlobalAverageUniqueRateClick] = useState(0);
  const [globalAverageRateResponse, setGlobalAverageRateResponse] = useState(0);
  const [globalAverageRateLead, setGlobalAverageRateLead] = useState(0);
  const [antCheck, setCheck] = useState(1);

  // welcome modal
  // const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  // Check List modal commented for now
  // const handleWelcomeModalClose = () => {
  //   setShowWelcomeModal(false);
  //   setShowProductTour(false);
  // };

  const handleValue = (e) => {
    setCheck(e.target.checked ? 1 : 0);
  };

  let organizationId = useOrganizationId();

  const [dateRange, setDateRange] = useState({
    startDate: new Date(0),
    endDate: new Date(Date.now()),
  });

  const [filterBy, setFilterBy] = useState("Month");

  useEffect(() => {
    fetchStepsDone();
  }, []);

  // Check List modal commented for now
  // useEffect(() => {
  //   if (isStepsDoneLoaded && !showProductTour) {
  //     if (!stepsDone.allStepsDone) {
  //       setShowWelcomeModal(true);
  //     }
  //   }
  // }, [stepsDone, isStepsDoneLoaded]);

  useEffect(() => {
    if (previousOrganizationId === 0 && organizationId > 0) {
      setPreviousOrganizationId(organizationId);
    } else if (previousOrganizationId !== organizationId && previousOrganizationId > 0) {
      window.location.reload();
    }
  }, [organizationId, previousOrganizationId]);

  const barsList = [
    {
      stackId: "legendOne",
      legendType: "circle",
      barSize: 12,
      name: "New Engagements",
      dataKey: "newEngagements",
      fill: "#FE983E",
    },
    {
      stackId: "legendTwo",
      legendType: "circle",
      barSize: 12,
      name: "Chasers",
      dataKey: "chasers",
      fill: "#D80F6F",
    },
  ];
  const colors = ["#1AC472", "#D80F6F", "#1288F5"];
  const emptyChartColor = ["var(--secondary-color-100)"];

  let pieChart = null;

  if (props.isLeadRateLoading) {
    pieChart = (
      <div className={styles.skeletonPieChartWrapper}>
        <SkeletonPieChart />
      </div>
    );
  }

  const progressBarLegend = [
    {
      text: "Positive",
      variant: "positive",
    },
    {
      text: "Deferred Interest",
      variant: "deferred",
    },
    {
      text: "Referral",
      variant: "referral",
    },
    {
      text: "Decline",
      variant: "decline",
    },
  ];

  let legendsBar = [
    {
      text: `Positive`,
      variant: "positive",
    },
    {
      text: `Deferred Interest`,
      variant: "deferred",
    },
    {
      text: `Referral`,
      variant: "referral",
    },
  ];

  if (props.isLeadRateLoaded) {
    let data = {
      Positive: props.leadRate.positiveResponsePercentage != null ? props.leadRate.positiveResponsePercentage : 0,
      Referral: props.leadRate.referralPercentage != null ? props.leadRate.referralPercentage : 0,
      Deferred: props.leadRate.deferredInterestPercentage != null ? props.leadRate.deferredInterestPercentage : 0,
    };

    legendsBar = [
      {
        text: `Positive (${data.Positive.toFixed(2)}%)`,
        variant: "positive",
      },
      {
        text: `Deferred Interest (${data.Deferred.toFixed(2)}%)`,
        variant: "deferred",
      },
      {
        text: `Referral (${data.Referral.toFixed(2)}%)`,
        variant: "referral",
      },
    ];

    if (data.Positive === 0 && data.Referral === 0 && data.Deferred === 0) {
      pieChart = (
        <PieChartAngle height={180} innerRadius={60} outerRadius={80} numberValue={0} textValue="Total" colors={emptyChartColor} />
      );
    } else {
      pieChart = (
        <PieChartAngle
          height={180}
          innerRadius={60}
          outerRadius={80}
          numberValue={props.leadRate.totalLeads}
          textValue="Total"
          colors={colors}
          data={data}
        />
      );
    }
  }

  useEffect(() => {
    fetchGlobalAverageRate();
  }, []);

  useEffect(() => {
    props.fetchTotalRunRates(addOffsetToDate(dateRange.startDate), addOffsetToDate(dateRange.endDate), filterBy);
  }, [dateRange.startDate, dateRange.endDate, filterBy]);

  useEffect(() => {
    props.fetchLeadRate(addOffsetToDate(dateRange.startDate), addOffsetToDate(dateRange.endDate));
    props.fetchTotalResponses(addOffsetToDate(dateRange.startDate), addOffsetToDate(dateRange.endDate));
  }, [dateRange.startDate, dateRange.endDate]);

  let dateOptions = {
    onChange: (item) => {
      setDateRange(item);
    },
    showSelectionPreview: true,
    key: "selection",
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    editableDateInputs: false,
    maxDate: new Date(Date.now()),
    inputRanges: [],
    staticRanges: customStaticRanges,
  };

  let responseData = [];

  if (props.totalResponses != null) {
    if (!isNaN(props.totalResponses.positiveResponse) && props.totalResponses.positiveResponse !== 0) {
      responseData.push({
        color: `var(--lead-color)`,
        percentage: `${props.totalResponses.positiveResponsePercentage}`,
        label: `${props.totalResponses.positiveResponsePercentage.toFixed(2)}% (${formatNumberHelper(
          props.totalResponses.positiveResponse,
          separators.numberGroupSeperator,
          separators.numberDecimalSeperator
        )})`,
      });
    }

    if (!isNaN(props.totalResponses.deferredInterest) && props.totalResponses.deferredInterest !== 0) {
      responseData.push({
        color: `var(--responded-color)`,
        percentage: `${props.totalResponses.deferredInterestPercentage}`,
        label: `${props.totalResponses.deferredInterestPercentage.toFixed(2)}% (${formatNumberHelper(
          props.totalResponses.deferredInterest,
          separators.numberGroupSeperator,
          separators.numberDecimalSeperator
        )})`,
      });
    }

    if (!isNaN(props.totalResponses.referral) && props.totalResponses.referral !== 0) {
      responseData.push({
        color: `var(--opened-color)`,
        percentage: `${props.totalResponses.referralPercentage}`,
        label: `${props.totalResponses.referralPercentage.toFixed(2)}% (${formatNumberHelper(
          props.totalResponses.referral,
          separators.numberGroupSeperator,
          separators.numberDecimalSeperator
        )})`,
      });
    }

    if (!isNaN(props.totalResponses.decline) && props.totalResponses.decline !== 0) {
      responseData.push({
        color: `var(--engaged-color)`,
        percentage: `${props.totalResponses.declinePercentage}`,
        label: `${props.totalResponses.declinePercentage.toFixed(2)}% (${formatNumberHelper(
          props.totalResponses.decline,
          separators.numberGroupSeperator,
          separators.numberDecimalSeperator
        )})`,
      });
    }
  }

  return (
    <>
      <HeaderTitle
        rightContent={
          <div className={styles.datePicker}>
            <ShowDateRangePicker customDropdownClass={styles.dashboardDatePicker} {...dateOptions} />
          </div>
        }
      />
      <PageContentWrapper className={`${styles.dashboardWrapper} flex`}>
        <div className={styles.performanceWrapper}>
          <div className={styles.performances}>
            <div className="flex">
              <SectionTitle title="Campaign Performance" />
              <div className={styles.switchButtonWrapper}>
                {antCheck === 0 ? (
                  <p className={`${styles.rateLabel} ${antCheck === 0 && styles.rateActive} `}>Switch to unique open/click rate</p>
                ) : (
                  <p className={`${styles.rateLabel} ${antCheck === 1 && styles.rateActive}`}>Switch to total open/click rate</p>
                )}
                <AntSwitch checked={antCheck === 1 ? true : false} onChange={handleValue} aria-label="Search" />
              </div>
            </div>
            <div className={styles.ratesResultsWrapper}>
              <div className={`${styles.rates} flex`}>
                <TotalCampaignPerformance
                  {...dateRange}
                  setGlobalAverageRateLead={setGlobalAverageRateLead}
                  setGlobalAverageRateResponse={setGlobalAverageRateResponse}
                  setGlobalAverageRateClick={setGlobalAverageRateClick}
                  setGlobalAverageRateOpen={setGlobalAverageRateOpen}
                  setGlobalAverageUniqueRateClick={setGlobalAverageUniqueRateClick}
                  setGlobalAverageUniqueRateOpen={setGlobalAverageUniqueRateOpen}
                  openRate={globalAverageRate.openRate}
                  clickRate={globalAverageRate.clickRate}
                  uniqueOpenRate={globalAverageRate.uniqueOpenRate}
                  uniqueClickRate={globalAverageRate.uniqueClickRate}
                  responseRate={globalAverageRate.responseRate}
                  leadRate={globalAverageRate.leadRate}
                  globalAverageRateOpen={globalAverageRateOpen}
                  globalAverageRateClick={globalAverageRateClick}
                  globalAverageUniqueRateOpen={globalAverageUniqueRateOpen}
                  globalAverageUniqueRateClick={globalAverageUniqueRateClick}
                  globalAverageRateResponse={globalAverageRateResponse}
                  globalAverageRateLead={globalAverageRateLead}
                  uniqueToggle={antCheck}
                />
              </div>
              <Accordion
                noShadow
                noCaret
                variant="primary"
                header="Expand to Campaign View"
                headerClassName="fontNormal"
                body={<CampaignPerformance {...dateRange} {...globalAverageRate} uniqueToggle={antCheck} />}
                closeHeader="Collapse “by Campaign View”"
              />
            </div>
          </div>
          <div className={styles.runRatesWrapper}>
            <div className={styles.headerButtonsWrapper}>
              <SectionTitle title="Run rates" />
              <div className={styles.groupButtons}>
                <Button
                  id="month"
                  variant="editButton"
                  classes={`${styles.leftButton} ${filterBy === "Month" ? styles.active : null}`}
                  onClick={() => setFilterBy("Month")}
                >
                  Month
                </Button>
                <Button
                  id="week"
                  variant="editButton"
                  classes={`${styles.middleButton} ${filterBy === "Week" ? styles.active : null}`}
                  onClick={() => setFilterBy("Week")}
                >
                  Week
                </Button>
                <Button
                  id="day"
                  variant="editButton"
                  classes={`${styles.rightButton} ${filterBy === "Day" ? styles.active : null}`}
                  onClick={() => setFilterBy("Day")}
                >
                  Day
                </Button>
              </div>
            </div>
            <div className="mB15">
              {!_.isNull(props.totalRunRates) ? <StackedBarChart data={props.totalRunRates} bar={barsList} /> : null}
            </div>
            <Accordion
              noShadow
              noCaret
              variant="primary"
              header={"Expand to Campaign View"}
              headerClassName="fontNormal"
              body={<RunRatesByCampaign {...dateRange} filterBy={filterBy} bar={barsList} />}
              closeHeader={"Collapse “by Campaign View”"}
            />
          </div>
          <div className={styles.responsesWrapper}>
            <SectionTitle title="Responses" />
            {!_.isNull(props.totalResponses) ? (
              <div className={`${styles.progressBarWrapper} mB15`}>
                <div className={styles.progressBar}>
                  <Legend legend={progressBarLegend} />
                  <ProgressLine data={responseData} />
                </div>
              </div>
            ) : null}
            <Accordion
              noShadow
              noCaret
              variant="primary"
              header={"Expand to Campaign Views"}
              headerClassName="fontNormal"
              body={<DashboardResponsesByCampaign {...dateRange} />}
              closeHeader={"Collapse “by Campaign View”"}
            />
          </div>
        </div>
        <div className={styles.chartsWrapper}>
          <div className={styles.funnelWrapper}>
            <SectionTitle title="Engagement Funnel" />
            <ProspectFunnel {...dateRange} />
          </div>
          <div className={styles.border} />
          <div className={styles.pieChartWrapper}>
            <SectionTitle title="Lead Type" />
            {pieChart}
            <Legend legend={legendsBar} classes={styles.pieChartLegend} />
          </div>
          <div className={styles.campaign}>
            <SectionTitle title="Campaigns" />
            <CampaignsInfo />
          </div>
        </div>
        {/* <WelcomeModal handleClose={handleWelcomeModalClose} showModal={showWelcomeModal} stepsDone={stepsDone} /> */}
      </PageContentWrapper>

      {/* <div className={`${styles.dataCampaignWrapper} flex`}>
        <div className={styles.dataAudience}>
          <SectionTitle title="Data and audience" />
          <TotalDataAndAudience />
        </div>
      </div>
      <div className={styles.accordionWrapper}>
        <Accordion
          variant="primary"
          header={"Expand to Campaign View"}
          body={<DataAndAudience />}
          eventKey={true}
          noCaret={true}
          closeHeader={"Collapse “by Campaign View”"}
        />
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isGlobalAverageRateLoading: state.dashboard.isGlobalAverageRateLoading,
    isGlobalAverageRateLoaded: state.dashboard.isGlobalAverageRateLoaded,
    globalAverageRate: state.dashboard.globalAverageRate,
    errorGlobalAverageRate: state.dashboard.errorGlobalAverageRate,
    isTotalRunRatesLoading: state.dashboard.isTotalRunRatesLoading,
    isTotalRunRatesLoaded: state.dashboard.isTotalRunRatesLoaded,
    totalRunRates: state.dashboard.totalRunRates,
    errorTotalRunRates: state.dashboard.errorTotalRunRates,
    isLeadRateLoading: state.dashboard.isLeadRateLoading,
    isLeadRateLoaded: state.dashboard.isLeadRateLoaded,
    leadRate: state.dashboard.leadRate,
    errorLeadRate: state.dashboard.errorLeadRate,
    isTotalResponsesLoading: state.dashboard.isTotalResponsesLoading,
    isTotalResponsesLoaded: state.dashboard.isTotalResponsesLoaded,
    totalResponses: state.dashboard.totalResponses,
    errorTotalResponses: state.dashboard.errorTotalResponses,
    previousOrganizationId: state.dashboard.previousOrganizationId,
    isStepsDoneLoaded: state.dashboard.isStepsDoneLoaded,
    stepsDone: state.dashboard.stepsDone,
    showProductTour: state.dashboard.showProductTour,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalAverageRate: () => dispatch(fetchGlobalAverageRate()),
    fetchTotalRunRates: (startDate, endDate, filterBy) => dispatch(fetchTotalRunRates(startDate, endDate, filterBy)),
    fetchLeadRate: (startDate, endDate) => dispatch(fetchLeadRate(startDate, endDate)),
    fetchTotalResponses: (startDate, endDate) => dispatch(fetchTotalResponses(startDate, endDate)),
    setPreviousOrganizationId: (organizationId) => dispatch(setPreviousOrganizationId(organizationId)),
    fetchStepsDone: () => dispatch(fetchStepsDone()),
    setShowProductTour: (val) => dispatch(setShowProductTour(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
