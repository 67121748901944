import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

//Redux
import { useEditCampaignStatusMutation, useGetCampaignStatusesQuery, useGetCampaignsQuery } from "../../api/campaignApi";

//Helpers
import { PERMISSIONS } from "../../helper/permissionConstants";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";
import { campaignStatusEnum } from "../../helper/enums/campaignStatusEnum";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { errorResponseToList } from "../../helper/errorHelper";
import { deletedTemplate, draftTemplates, noEmailAccountConnected } from "../../helper/campaignGoLiveErrorConstant";
import { useOrganizationId } from "../../helper/hooks/useOrganizationId";

// Style
import styles from "./Campaigns.module.scss";

//Components
import NewCampaignForm from "./NewCampaignForm";
import { columns } from "./columns";
import Permission from "../../components/auth/Permission";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import Button from "../../components/UI/Button/Button";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import GoLiveModal from "../CampaignSettings/Modal/GoLiveModal";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import DeletedTemplateModal from "./DeletedTemplateModal";
import NoEmailAccountConnectedModal from "./NoEmailAccountConnectedModal";
import CloneCampaignModal from "./CloneCampaignModal";
import DraftEmailTemplatesSequenceModal from "./DraftEmailTemplatesSequenceModal";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import useModal from "../../components/UI/Modal/useModal";
import ArchiveConfirmModal from "../../components/DataTable/ActionAndIconsCol/ArchiveConfirmModal/ArchiveConfirmModal";
import AssistantControlPanel from "../../components/AssistantControlPanel/AssistantControlPanel";
import AIIllustration from "../../assets/Images/AIIllustration/AIIllustration";
import MailMegaphoneIllustration from "../../assets/Images/MailMegaphoneIllustration/MailMegaphoneIlustration";
import DataTable from "../../components/DataTable/DataTable";
import { CampaignStatusEnum } from "../../models/enums/CampaignStatusEnum";
import { CampaignDataTableModel } from "../../models/campaign/CampaignDataTableModel";
import { createCampaignRow } from "./campaignRowHelper";
import { useSlice } from "../../helper/hooks/useSlice";
import { useDataTable } from "../../helper/hooks/useDataTable";
import { campaignsDataTable } from "../../store/registerDataTables";
import { useFilter } from "../../helper/hooks/useFilter";
import EmptyState from "@ui/EmptyState/EmptyState";
import EmptyCampaignList from "@assets/Images/NoResultsFound/NoResultsFound";
import IconSparkling from "@icons/IconSparkling/IconSparkling";

const Campaigns = () => {
  // Hooks
  const organizationId = useOrganizationId();
  const history = useHistory();
  const dateFormat = useOrgDateFormat();

  const [editStatus] = useEditCampaignStatusMutation();

  // State
  const [disabled, setDisabled] = useState(false);

  const userRole = useSlice((state) => state.user.organization.role);

  const campaignsState = useSlice((state) => state.campaignsDataTable);
  const dataTable = useDataTable({
    slice: campaignsState,
    dtActions: campaignsDataTable.actions,
    useQuery: useGetCampaignsQuery,
    filterTypes: ["Status"],
  });
  const {
    state: { rows, params },
    actions: { searchRecords, setFilter },
    query,
  } = dataTable;

  const { data: statusesData } = useGetCampaignStatusesQuery();

  const [lastCampaignIdClicked, setLastCampaignIdClicked] = useState<number | null>(null);

  // Modals
  const newCamaignModal = useModal();
  const archiveModal = useModal<CampaignDataTableModel>();
  const goLiveModal = useModal<CampaignDataTableModel>();
  const deletedTemplateModal = useModal<number>();
  const cloneModal = useModal<number>();
  const noEmailAccountsModal = useModal();
  const draftTemplatesModal = useModal();

  // Handlers
  const handleChangeStatus = (status: CampaignStatusEnum, campaignId: number) => {
    setDisabled(true);

    const requestData = {
      campaignId: campaignId,
      status: status,
    };
    setLastCampaignIdClicked(campaignId);
    editStatus(requestData)
      .unwrap()
      .then((result) => {
        if (result != null && result.status === CampaignStatusEnum.Active) {
          // refetchCampaigns();
          toast.success(`Your campaign ${goLiveModal.data?.name} was activated`);
        }
      })
      .catch((err) => {
        const errorList = err.data; // errorResponseToList(err.data);

        if (errorList.length > 0) {
          if (errorList[0].message === deletedTemplate) {
            deletedTemplateModal.show(campaignId);
          } else if (errorList[0].message === noEmailAccountConnected) {
            noEmailAccountsModal.show();
          } else if (errorList[0].message === draftTemplates) {
            draftTemplatesModal.show();
          }
        } else {
          toast.error(toastMessages.campaign.errorChangeStatusFailed);
        }
      })
      .finally(() => {
        goLiveModal.hide();
        setDisabled(false);
      });
  };

  const handleDeleteTemplateSave = () => history.push(`/${organizationId}/campaigns/${deletedTemplateModal.data}/campaign-sequence`);
  const handleCreateWithAI = () => history.push(`/${organizationId}/assistant/create-campaign`);
  const filters = [useFilter({ name: "Status", data: statusesData, filters: params.filter, setFilter })];

  const tableRows = useMemo(() => {
    if (!rows) return;

    const dataClone = _.cloneDeep(rows);
    const campaignRows = dataClone.map((row, index) =>
      createCampaignRow(
        row as CampaignDataTableModel,
        index,
        userRole,
        organizationId,
        handleChangeStatus,
        goLiveModal.show,
        archiveModal.show,
        cloneModal.show,
        dateFormat
      )
    );

    return campaignRows;
  }, [rows]);

  let table = null;
  const image = <EmptyCampaignList />;

  if (query.error) {
    table = <h2 className="f18 textCenter">Error getting Campaigns</h2>;
  }

  if (query.isLoading) {
    table = <SkeletonTable />;
  }

  const isFilterEmpty =
    params.filter?.length === 0 ||
    (params.filter.length === 1 && params.filter[0]?.type === "Status" && params.filter[0]?.value?.length === 0);

  if (!query.isLoading && !query.error) {
    if (!table && tableRows && tableRows.length === 0) {
      const isSearchEmpty = params.search.trim() === "";
      table =
        isFilterEmpty && isSearchEmpty ? (
          <AssistantControlPanel
            onStartClick={handleCreateWithAI}
            componentTitle="Your first campaign is just a few clicks away!"
            description="Choose from the options below: create with AI or craft from scratch."
            startAIImage={<AIIllustration />}
            startButtonText="Create with AI"
            startSubText="Harness our AI capabilities to build a campaign that delivers results."
            skipStepImg={<MailMegaphoneIllustration />}
            closeButtonText="Create from scratch"
            closeSubText="Know what you're doing? Build your campaign from the ground up."
            onCloseViewClick={newCamaignModal.show}
            parentClassName={styles.assistantControlPanelWrapper}
          />
        ) : (
          <EmptyState
            componentClass={`${styles.emptyStateImage}`}
            image={image}
            title="No campaigns found"
            content="No campaigns to display."
            description="Choose from the options above to create a new campaign or filter the list to find the campaign you are looking for."
          />
        );
    } else if (tableRows && tableRows.length > 0) {
      table = (
        <DataTable
          rows={tableRows}
          columns={columns}
          dataTable={dataTable}
          tableClassName={`${styles.campaignTable}`}
          scrollY={true}
          tableHeight={240}
          variant="large"
        />
      );
    }
  }

  const isTableNotEmpty = tableRows && tableRows.length > 0;
  const showButtons = isTableNotEmpty || !isFilterEmpty;

  const buttons = showButtons && (
    <>
      <Permission has={PERMISSIONS.campaign.create}>
        <Button id="createCampaign" onClick={newCamaignModal.show} variant="secondary">
          Create from scratch
        </Button>
      </Permission>
      <Permission has={PERMISSIONS.campaign.create}>
        <Button id="createCampaignAi" onClick={handleCreateWithAI} variant="primary">
          <IconSparkling imgStyle="mR10" fill="#fff" />
          Create with AI
        </Button>
      </Permission>
    </>
  );

  const icon = (
    <InfoIconTooltip
      tooltipBody="Create, start, pause, edit, and compare your campaigns, all in one place!"
      helpText="Need help starting a campaign?"
      href={"https://help.outbase.com/user-guide/campaigns/campaign-manager"}
      linkText="Read More"
      buttonId="startCampaignTour"
      to={"/" + organizationId + "/campaigns"}
    />
  );

  return (
    <PageContentWrapper className="contentHeight">
      <>
        <HeaderTitle title="Campaigns" icon={icon} rightContent={buttons} />

        <TableFiltersRow
          selects={filters}
          placeholder="campaigns"
          changeFilter={(text: string) => searchRecords(text)}
          searchValue={params.search}
          searchOnFirstCharacter={true}
        />
      </>
      {table}
      <NewCampaignForm handleClose={newCamaignModal.hide} showModal={newCamaignModal.isVisible} />
      {archiveModal.data && (
        <ArchiveConfirmModal showModal={archiveModal.isVisible} handleClose={archiveModal.hide} campaign={archiveModal.data} />
      )}

      {goLiveModal.isVisible && goLiveModal.data && goLiveModal.data.id && (
        <Permission has={PERMISSIONS.campaign.edit}>
          <GoLiveModal
            handleClose={goLiveModal.hide}
            showModal={goLiveModal.isVisible}
            id={goLiveModal.data.id}
            schedule={goLiveModal.data?.schedule}
            goLive={() => {
              if (goLiveModal.data) {
                handleChangeStatus(campaignStatusEnum.active, goLiveModal.data.id);
              }
            }}
            saveButtonDisabled={disabled}
          />
        </Permission>
      )}

      <DeletedTemplateModal
        showModal={deletedTemplateModal.isVisible}
        handleSave={handleDeleteTemplateSave}
        handleClose={deletedTemplateModal.hide}
      />

      <NoEmailAccountConnectedModal
        showModal={noEmailAccountsModal.isVisible}
        handleSave={noEmailAccountsModal.hide}
        handleClose={noEmailAccountsModal.hide}
        campaignName={goLiveModal.data?.name}
        campaignId={lastCampaignIdClicked?.toString() || ""}
        organizationId={organizationId}
      />

      <DraftEmailTemplatesSequenceModal
        showModal={draftTemplatesModal.isVisible}
        handleSave={draftTemplatesModal.hide}
        handleClose={draftTemplatesModal.hide}
        campaignName={goLiveModal.data?.name}
        campaignId={goLiveModal.data?.id}
        organizationId={organizationId}
      />

      {cloneModal.data && (
        <CloneCampaignModal showModal={cloneModal.isVisible} campaignId={cloneModal.data} handleClose={cloneModal.hide} />
      )}
    </PageContentWrapper>
  );
};

export default Campaigns;
