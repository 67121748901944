import * as React from "react";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

interface Step {
  label: string;
  description: string;
}

interface StepperProps {
  steps: Step[];
  activeStep: number;
  setActiveStep: (step: number) => void;
  className?: string;
  id?: string;
  orientation?: "horizontal" | "vertical";
}

const StepperComponent: React.FC<StepperProps> = (props) => {
  const handleStep = (step: number) => () => {
    props.setActiveStep(step);
  };

  const muiTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: "var(--secondary-color-200)",
            "&.Mui-active": {
              color: "var(--primary-color)",
            },
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            outline: "none !important",
            background: "#fff !important",
            "&.Mui-focusVisible": {
              outline: "none !important",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "left",
            cursor: "pointer !important",
          },
          iconContainer: {
            paddingLeft: "8px",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "0.875rem !important",
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            display: "none",
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            marginTop: "0",
            cursor: "pointer",
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            fontSize: "0.75rem !important",
            minHeight: "20px",
            marginLeft: "20px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Stepper
        className={props.className || ""}
        id={props.id || "stepper_root"}
        activeStep={props.activeStep}
        orientation={props.orientation}
      >
        {props.steps.map((step, index) => {
          const style = props.activeStep !== index ? { opacity: 0.5 } : {};
          return (
            <Step
              id={step.label.replace(/\s/g, "")}
              key={index}
              active={props.activeStep === index}
              onClick={handleStep(index)}
              completed={false}
            >
              <StepLabel>{step.label}</StepLabel>
              <StepContent TransitionProps={{ in: true }} style={style}>
                {step.description}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </ThemeProvider>
  );
};

export default StepperComponent;
