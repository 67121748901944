import React from "react";

const IconVariablesActive = ({ imgStyle, stroke = "#586473" }) => (
  <svg className={imgStyle} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.77079 1.00049C4.04163 1.00049 2.9479 1.36507 2.9479 2.82338V6.1046C2.9479 7.19833 1.125 7.92749 1.125 7.92749C1.125 7.92749 2.9479 8.65665 2.9479 9.75039V13.7608C2.9479 15.2191 4.04163 15.5837 4.77079 15.5837"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6045 1C11.3337 1 12.4274 1.36458 12.4274 2.8229V6.10411C12.4274 7.19784 14.2503 7.927 14.2503 7.927C14.2503 7.927 12.4274 8.65616 12.4274 9.7499V13.7603C12.4274 15.2186 11.3337 15.5832 10.6045 15.5832"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconVariablesActive;
