import React from "react";
import _ from "lodash";

//Helpers
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";

// Styles
import styles from "./GroupCard.module.scss";

//Images & Icons
import ReportResponse from "../../../assets/Icons/ReportResponse/ReportResponse";
import GraphUp from "../../../assets/Icons/GraphUp/GraphUp";
import GraphDown from "../../../assets/Icons/GraphDown/GraphDown";

const GroupCard = (props) => {
  let separators = useGroupDecimalSeparator();
  let totalNumberFormatted = formatNumberHelper(props.total, separators.numberGroupSeperator, separators.numberDecimalSeperator);

  let classes = styles.cardWrapper;

  if (!_.isEmpty(props.cardClassName)) classes += " " + props.cardClassName;
  if (!_.isEmpty(props.variant)) classes += " " + styles[props.variant];

  let graph = null;
  let trend = Math.sign(props.trendValue);
  switch (trend) {
    case 1:
      graph = (
        <div className={styles.graphUp}>
          <GraphUp />
          <span>{props.trendValue}%</span>
        </div>
      );
      break;
    case -1:
      graph = (
        <div className={styles.graphDown}>
          <GraphDown />
          <span>{props.trendValue}%</span>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className={`${styles.cardWrapperWhite} ${classes} flex_center`}>
      <div className={styles.counterWrapper}>
        <ReportResponse />
        <p className={styles.counter}>{totalNumberFormatted}</p>
        <p className={`${styles.info} line1`}>{props.name}</p>
      </div>
      <div className={styles.line}></div>
      <div className={styles.percentageWrapper}>
        <p className={styles.counter}>{props.rate}</p>
        <p className={styles.info}>{props.rateName} Rate</p>
        {graph}
        {/* <div className={styles.graphUp}>
                    <GraphUp />
                    <span>20%</span>
                </div> */}
        {/* <div className={styles.graphDown}>
                    <GraphDown />
                    <span>10%</span>
                </div> */}
      </div>
    </div>
  );
};

export default GroupCard;
