import React, { useState } from "react";
import { columns } from "./columns";
import { connect } from "react-redux";

//Style
import styles from "./NotificationActivityLogs.module.scss";

//Components
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Table from "../../../components/DataTable/Table";
import MessagePreviewModal from "./Modal/MessagePreviewModal";

const NotificationActivityLogs = (props) => {
  const [showActivityDetailsModal, setShowActivityDetailsModal] = useState(false);
  const handleActivityDetailsModalClose = () => setShowActivityDetailsModal(false);
  const handleUpdateDomainModalShow = () => setShowActivityDetailsModal(true);

  const param = {
    Start: 0,
    Length: 10,
    Search: "",
    DTSort: {
      Column: "",
      Direction: "",
    },
    Filter: [
      {
        Type: "",
        Value: [{}],
      },
    ],
  };
  const tableRows = [
    {
      notificationName: "Welcome to Outbase",
      category: "User Registration",
      organizationName: "SoPro",
      sentFrom: "example@email.com",
      sentTo: "email@example.com",
      logDate: "03 Feb 2021",
      trigger: "Add New User",
    },
  ];

  // useEffect(() => {
  tableRows.map((r) => {
    let messageContent = (
      <a className="urlLink" onClick={handleUpdateDomainModalShow}>
        View
      </a>
    );
    r.messageContent = messageContent;
    return r;
  });
  // }
  let table = null;
  table = (
    <div className={styles.testTable}>
      <Table
        tableName={"notifications Logs"}
        columns={columns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        variant="small"
        // tableHeight={height - paginationHeight}
      />
    </div>
  );
  return (
    <div className={styles.contentWrapper}>
      <HeaderTitle title="User Logs" />
      <TableFiltersRow
        // selects={selectRowOptions}
        placeholder="logs"
        changeFilter={(text) => props.searchRecords(text)}
      />
      {table}
      <MessagePreviewModal handleClose={handleActivityDetailsModalClose} showModal={showActivityDetailsModal} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationActivityLogs);
