import React, { useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { ManuallyCategorizeProspectModel } from "@models/prospect/ManuallyCategorizeProspectModel";
import { ResponseTypeEnum, ResponseOriginTypeEnum } from "@models/enums/ResponseTypeEnum";
import * as Yup from "yup";
import moment from "moment";

//Helpers
import { useGetProspectCampaignDetailsQuery, useManuallyCategorizeProspectMutation } from "@api/prospectApi";
import { responseTypeEnum } from "@helper/enums/responseTypeEnum";
import { addDays } from "date-fns";
import { dateToUTC } from "@helper/dateHelper";

//Style
import styles from "./ManuallyCategorizeProspectModal.module.scss";

//Icons & Images
import CalendarLineIcon from "remixicon-react/Calendar2LineIcon";

//Components
import Modal from "@components/UI/Modal/Modal";
import FormikSelect from "@components/UI/Formik/FormikSelect";
import ManuallyCategorizeButtons from "../ManuallyCategorizeButtons/ManuallyCategorizeButtons";
import FormikInput from "@ui/Formik/FormikInput";
import FormikCheckbox from "@components/UI/Formik/FormikCheckbox";
import FormikDatePicker from "@components/UI/Formik/FormikDatePicker";

interface ModalProps {
  showModal: boolean;
  prospectId: number;
  handleClose: () => void;
  refreshData: () => void;
}

const ManuallyCategorizeProspectModal: React.FC<ModalProps> = ({ showModal, handleClose, refreshData, prospectId }) => {
  const { data: model, isFetching } = useGetProspectCampaignDetailsQuery(prospectId, {
    skip: !prospectId,
    refetchOnMountOrArgChange: true,
  });

  const [manuallyCategorizeProspect] = useManuallyCategorizeProspectMutation();

  const [selectedActivityType, setSelectedActivityType] = useState<ResponseOriginTypeEnum>(ResponseOriginTypeEnum.Email);

  if (model?.campaigns && model.campaigns.length === 0 && !isFetching) {
    handleClose();
    return null;
  }

  return model?.campaigns && model.campaigns.length > 0 && !isFetching ? (
    <Formik
      initialValues={{
        campaignId: model.campaigns[0]?.value ?? 0,
        responseTypeId: model.responseTypeId ?? 0,
        note: model.note ?? "",
        prospectId: prospectId,
        referralEmail: model.referralEmail ?? "",
        responseOrigin: selectedActivityType,
        addToExclusionList: false,
        sendAfterDateOOO: moment(model.sendAfterDateOOO).isValid()
          ? moment(model.sendAfterDateOOO).format("DD MMM YYYY")
          : moment(addDays(new Date(), 10)).format("DD MMM YYYY"),
      }}
      validationSchema={Yup.object({
        referralEmail: Yup.string().email("Please enter a valid email address."),
        responseTypeId: Yup.number().required("Required").moreThan(0, "Response type is mandatory"),
        note: Yup.string().max(400, "The note exceeds the maximum length (400 characters)"),
      }).required("Required")}
      onSubmit={(values: ManuallyCategorizeProspectModel, actions: FormikHelpers<ManuallyCategorizeProspectModel>) => {
        const data = { ...values };
        if (data.sendAfterDateOOO) {
          const date = dateToUTC(Date.parse(data.sendAfterDateOOO)).format("YYYY-MM-DD");
          data.sendAfterDateOOO = date;
        }
        manuallyCategorizeProspect(data)
          .unwrap()
          .then(() => {
            refreshData();
            handleClose();
          })
          .catch((error) => {
            toast.error(error?.data);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit } = formikProps;
        return (
          <Modal
            show={showModal}
            customBodyClass={styles.modalBody}
            title="Categorize response type"
            closeButtonText="Cancel"
            saveButtonText="Confirm"
            btnType="submit"
            handleClose={handleClose}
            handleSave={handleSubmit}
          >
            <ManuallyCategorizeButtons
              title="Choose activity type"
              name="responseOrigin"
              onActivityTypeChange={setSelectedActivityType}
              values={values}
              setFieldValue={formikProps.setFieldValue}
            />{" "}
            <FormikSelect
              isMenuPortalTargetDisabled
              name="responseTypeId"
              label="Response type"
              options={model.responseTypes ?? []}
              value={values.responseTypeId}
            />
            {values.responseTypeId === ResponseTypeEnum.Referral ? (
              <FormikInput type="text" name="referralEmail" label="Referal email:" value={values.referralEmail} />
            ) : values.responseTypeId === responseTypeEnum.OutOfOffice ? (
              <div className={styles.calendarGroup}>
                <label>Send next email in campaigns sequence after:</label>
                <FormikDatePicker
                  name="sendAfterDateOOO"
                  selected={moment(values.sendAfterDateOOO, "DD MMM YYYY").toDate()}
                  value={values.sendAfterDateOOO}
                  dateFormat="dd MMM yyyy"
                  minDate={new Date()}
                />
                <CalendarLineIcon className={styles.calendarIcon} size={16} />
              </div>
            ) : values.responseTypeId === responseTypeEnum.Decline ? (
              <FormikCheckbox
                className={styles.exclusions}
                label="Add to exclusions"
                name="addToExclusionList"
                checked={values.addToExclusionList}
              />
            ) : null}
            <FormikSelect name="campaignId" label="Campaign" options={model?.campaigns ?? []} value={values.campaignId} />
            <FormikInput type="textarea" rows={4} name="note" value={values.note} label="Note:" />
          </Modal>
        );
      }}
    </Formik>
  ) : null;
};

export default ManuallyCategorizeProspectModal;
