export const adjustCreditsReasonEnum = {
  GoodwillRetention: 1,
  SystemIssue: 2,
  Upsell: 3,
  Other: 4,
};

export const adjustCreditsReasonOptions = [
  { value: 1, label: "Goodwill/Retention" },
  { value: 2, label: "System Issue" },
  { value: 3, label: "Upsell" },
  { value: 4, label: "Other" },
];
