import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Moment from "moment";
import { toast } from "react-toastify";

//Redux
import { connect } from "react-redux";
import { fetchCreditInfo } from "../../../../../store/BillingAndPayment/actions/billingAndPayment";

//Helpers
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";
import api, { API, formUrl } from "../../../../../helper/api/api";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { currencySign, formatDecimalsPrice, pricingPlanEnum } from "../../../../../helper/pricingPlanHelper";
import { useOrgNumberFormatting } from "../../../../../helper/hooks/useOrgNumberFormatting";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";
import { PERMISSIONS } from "../../../../../helper/permissionConstants";
import { termsAndPaymentStatusEnum } from "../../../../../helper/enums/contractStatusEnum";
import { organizationStatusEnum } from "../../../../../helper/enums/organizationStatus";

// Styles
import styles from "./CurrentPlan.module.scss";

// Components
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";
import AntSwitch from "../../../../../components/UI/AntSwitch/Switch";
import Button from "../../../../../components/UI/Button/Button";
import AlertNotification from "../../../../../components/UI/AlertNotification/AlertNotification";
import Permission from "../../../../../components/auth/Permission";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CancelModal from "../CancelModal/CancelModal";
import CancelConfirmModal from "../CancelModal/CancelConfirmModal";

const CurrentPlan = (props) => {
  const { fetchCreditInfo, organizationStatus } = props;

  const {
    plan,
    monthlyCredits,
    fee,
    nextPayment,
    contractRenewal,
    isAutoRenewal,
    paymentCycle,
    numberOfUsers,
    isFirstThreeMonths,
    dateAfterFirst3Months,
    dateAfterFirst12Months,
    isFirstTwoMonths,
    isAnnualPlan,
    isCancelationTriggered,
  } = props.creditInfo;

  const [antCheck, setCheck] = useState(isAutoRenewal);

  const [cancelModal, setCancelModal] = useState(false);
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false);

  const openModal = () => {
    setCancelModal(true);
  };

  const closeFirstOpenSecondModal = () => {
    setCancelConfirmModal(true);
    setCancelModal(false);
  };

  const closeModal = () => {
    setCancelModal(false);
    setCancelConfirmModal(false);
  };

  // Use the confirmation logic on this one after the user confirms the cancelation, second modal confirmation button
  const cancelSubscription = () => {
    setCancelConfirmModal(false);
    fetchCreditInfo();
  };

  useEffect(() => {
    if (isAutoRenewal !== undefined && antCheck === undefined) {
      setCheck(isAutoRenewal);
    }
  }, [isAutoRenewal]);

  let dateFormat = useOrgDateFormat();
  const changeUrl = () => {
    if (plan === pricingPlanEnum.trial && props.contractStatus === termsAndPaymentStatusEnum.accepted) {
      props.setCheckoutModal(true);
      return;
    }

    if (props.contractStatus === termsAndPaymentStatusEnum.awaitingPaymentConfirmation) {
      props.setShowAddCreditCardModalWarning(true);
      return;
    }
    props.history.push("/" + props.orgId + "/pricing");
  };

  const handleReactivation = () => {
    if (organizationStatus == organizationStatusEnum.pendingRenewal || organizationStatus == organizationStatusEnum.inactive) {
      changeUrl();
    } else
      api(API.billingAndPayment.reactivateCancelSubscription)
        .then((res) => {
          fetchCreditInfo();
        })
        .catch((error) => {
          const errorList = errorResponseToList(error);
          if (errorList.length > 0) {
            errorList.forEach((e) => toast.error(e.message));
          } else {
            toast.error(error.message);
          }
        });
  };

  let cancelLink = null;
  if (isFirstThreeMonths && isFirstTwoMonths && plan !== pricingPlanEnum.trial) {
    cancelLink = (
      <OverlayTrigger
        placement={"bottom"}
        overlay={
          <Popover id="ant-disable">
            <Popover.Body>
              We have a 3-month minimum contract to make sure you get the best out of your experience with us. You will be able to cancel
              your subscription after 2 months
            </Popover.Body>
          </Popover>
        }
      >
        <div className="text-overflow-hidden max-x-content">
          {!isCancelationTriggered ? (
            <li className={styles.listItem} disabled={true}>
              <a>
                <span>Cancel subscription</span>
              </a>
            </li>
          ) : null}
        </div>
      </OverlayTrigger>
    );
  } else {
    cancelLink = (
      <>
        {!isCancelationTriggered && plan !== pricingPlanEnum.trial && organizationStatus === organizationStatusEnum.active ? (
          <li className={styles.listItem} disabled={true}>
            <a onClick={openModal}>
              <span>Cancel subscription</span>
            </a>
          </li>
        ) : null}
      </>
    );
  }

  return (
    <div className={styles.componentWrapper}>
      <div className={`${styles.currentPlanWrapper} flex flex-wrap`}>
        <div className="flex align-items-center mb-2">
          <SectionTitle titleClass={styles.subOrder} title="Current Plan" />{" "}
          <h1 className={`mb-0 ml-2 ${styles.planPackage} ${styles.freePlan}`}>{plan}</h1>
        </div>
        {isCancelationTriggered && organizationStatus !== organizationStatusEnum.inactive && plan !== pricingPlanEnum.freemium  ? (
          <>
            <AlertNotification
              variant="info_alert_light"
              notificationWrapperClass="width_100"
              bodyWrapperClass="width_100"
              bodyClassName={styles.bodyContent}
            >
              <span>
               Your {plan} plan has been canceled. Your organization will be downgraded to the Freemium plan with limited features on  {Moment(nextPayment).add(1, "M").format(dateFormat)}{" "}.
              </span>
              <Permission has={PERMISSIONS.billingPayment.create}>
                <Button id="reactiveBtn" onClick={handleReactivation} classes={`ms-auto  ${styles.darkButton}`}>
                  {organizationStatus === organizationStatusEnum.active ? "Reactivate subscription" : "Renew"}
                </Button>
              </Permission>
            </AlertNotification>
          </>
        ) : (
          <div className="ms-auto">
            <Permission has={PERMISSIONS.billingPayment.create}>
              <Button
                id={props.buttonText.replaceAll(" ", "")}
                variant={"primary"}
                onClick={changeUrl}
                classes={`ml-auto ${styles.darkButton}`}
              >
                {props.buttonText}
              </Button>
            </Permission>
          </div>
        )}
      </div>
      <div className={`flex`}>
        <div className={`${styles.detailsList} flex2`}>
          <h2 className={styles.listTitle}>Subscription details</h2>
          <ul className={styles.list}>
            {/* <li className={styles.listItem}>
              <span>Organisational Licence</span>
              <span className={styles.dataItem}>Free</span>
            </li> */}
            <li className={styles.listItem}>
              <span>Monthly Credits</span>
              <span className={styles.dataItem}>{useOrgNumberFormatting(monthlyCredits)}</span>
            </li>
            <li className={styles.listItem}>
              <span>Users</span>
              <span className={styles.dataItem}>{numberOfUsers ?? "Unlimited"}</span>
            </li>
          </ul>
        </div>
        <div className={`${styles.detailsList} flex2`}>
          <h2 className={styles.listTitle}>Billing Details</h2>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <span>Payment Cycle</span>
              <span className={styles.dataItem}>{plan === pricingPlanEnum.trial ? "N/A" : paymentCycle}</span>
            </li>
            {!isCancelationTriggered ? (
              <li className={styles.listItem}>
                <span>Next Payment</span>
                <span className={styles.dataItem}>{plan === pricingPlanEnum.trial ? "N/A" : Moment(nextPayment).format(dateFormat)}</span>
              </li>
            ) : null}
            {isCancelationTriggered ? (
              <li className={styles.listItem}>
                <span>Expiry date</span>
                {isFirstThreeMonths && dateAfterFirst3Months && dateAfterFirst12Months ? (
                  <span className={styles.dataItem}>
                    {plan === pricingPlanEnum.trial
                      ? "N/A"
                      : //   : isAnnualPlan === true
                        //    ? Moment(nextPayment).format(dateFormat)
                        Moment(nextPayment).format(dateFormat)}
                  </span>
                ) : (
                  <span className={styles.dataItem}>
                    {plan === pricingPlanEnum.trial ? "N/A" : Moment(contractRenewal).format(dateFormat)}
                  </span>
                )}
              </li>
            ) : null}
            <Permission has={PERMISSIONS.billingPayment.cancel}>{cancelLink}</Permission>
          </ul>
        </div>
        <div className={`${styles.detailsList} flex1`}>
          <h2 className={`${styles.listTitle} ${styles.feeTitle}`}>FEE</h2>
          <p className={styles.price}>
            <span>{currencySign[props.currencyId]}</span>
            <span>{useOrgNumberFormatting(formatDecimalsPrice(fee))}</span>
            <span>/Mo.</span>
          </p>
        </div>
      </div>
      <CancelModal
        showModal={cancelModal}
        handleSave={closeFirstOpenSecondModal}
        handleClose={closeModal}
        date={Moment(nextPayment).add(1, "M").format(dateFormat)}
      />
      <CancelConfirmModal showModal={cancelConfirmModal} handleSave={cancelSubscription} handleClose={closeModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    creditInfo: state.billingAndPayment.creditInfo,
    currencyId: state.organizationSettingsGeneral.settings.currency,
    organizationStatus: state.user.organization.organizationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCreditInfo: () => dispatch(fetchCreditInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlan);
