import React from "react";
import { ToastContainer } from "react-toastify";

//Style
import styles from "./Layout.module.scss";

//Components
import Header from "../../components/Header/Header";
import HeaderAlertNotification from "../../components/HeaderAlertNotification/HeaderAlertNotification";
import Sidebar from "../../components/Sidebar/Sidebar";

const Layout = (props) => {
  return (
    <div className={`${styles.contentWrapper} layout`}>
      <Sidebar props={props} />
      <div className={`${styles.main} mainWrapper`}>
        <Header {...props} />
        <div className={styles.content}>
          <HeaderAlertNotification />
          <ToastContainer position={"bottom-right"} />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
