import React from "react";
import _ from "lodash";

//Styles
import styles from "./SectionTitle.module.scss";

const SectionTitle = (props) => {
  return (
    <div className={styles.subheaderWrapper + " " + props.titleClass}>
      <div className={styles.title}>
        <div className={`${props.titleWrapperClass ? props.titleWrapperClass + " " : ""}flex noWrap`}>
          {props.icon}
          <h2 className={props.sectionTitleClass ? props.sectionTitleClass : ""}>{props.title}</h2>
        </div>

        {!_.isUndefined(props.description) ? (
          <div className={styles.description}>
            <p className={props.descriptionTextStyle ?? ""}>{props.description}</p>
          </div>
        ) : null}
        {!_.isUndefined(props.numberCounter) ? (
          <div className={styles.numberTag}>
            <span>{props.numberCounter}</span>
          </div>
        ) : null}

        {!_.isUndefined(props.rightContent) ? (
          <div className={`${styles.rightContent} ${props.rightContentClass ?? ""}`}>{props.rightContent}</div>
        ) : null}
      </div>
    </div>
  );
};

export default SectionTitle;
