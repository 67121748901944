export const industryEnum = {
  Accounting: "Accounting",
  AirlinesOrAviation: "Airlines/Aviation",
  AlternativeDisputeResolution: "Alternative Dispute Resolution",
  AlternativeMedicine: "Alternative Medicine",
  Animation: "Animation",
  ApparelAndFashion: "Apparel And Fashion",
  ArchitectureAndPlanning: "Architecture And Planning",
  ArtsandCrafts: "Arts and Crafts",
  Automotive: "Automotive",
  AviationAndAerospace: "Aviation And Aerospace",
  Banking: "Banking",
  Biotechnology: "Biotechnology",
  BroadcastMedia: "Broadcast Media",
  BuildingMaterials: "Building Materials",
  BusinessSuppliesandEquipment: "Business Supplies and Equipment",
  CapitalMarkets: "Capital Markets",
  Chemicals: "Chemicals",
  CivicAndSocialOrganization: "Civic And Social Organization",
  CivilEngineering: "Civil Engineering",
  CommercialRealEstate: "Commercial Real Estate",
  ComputerAndNetworkSecurity: "Computer And Network Security",
  ComputerGames: "Computer Games",
  ComputerHardware: "Computer Hardware",
  ComputerNetworking: "Computer Networking",
  ComputerSoftware: "Computer Software",
  Construction: "Construction",
  ConsumerElectronics: "Consumer Electronics",
  ConsumerGoods: "Consumer Goods",
  ConsumerServices: "Consumer Services",
  Cosmetics: "Cosmetics",
  Dairy: "Dairy",
  DefenseAndSpace: "Defense And Space",
  Design: "Design",
  EducationManagement: "Education Management",
  ELearning: "E-Learning",
  ElectricalOrElectronicManufacturing: "Electrical/Electronic Manufacturing",
  Entertainment: "Entertainment",
  EnvironmentalServices: "Environmental Services",
  EventsServices: "Events Services",
  ExecutiveOffice: "Executive Office",
  FacilitiesServices: "Facilities Services",
  Farming: "Farming",
  FinancialServices: "Financial Services",
  FineArt: "Fine Art",
  Fishery: "Fishery",
  FoodAndBeverages: "Food And Beverages",
  FoodProduction: "Food Production",
  FundRaising: "Fund-Raising",
  Furniture: "Furniture",
  GamblingAndCasinos: "Gambling And Casinos",
  GlassCeramicsAndConcrete: "Glass, Ceramics And Concrete",
  GovernmentAdministration: "Government Administration",
  GovernmentRelations: "Government Relations",
  GraphicDesign: "Graphic Design",
  HealthWellnessandFitness: "Health, Wellness and Fitness",
  HigherEducation: "Higher Education",
  HospitalAndHealthCare: "Hospital And Health Care",
  Hospitality: "Hospitality",
  HumanResources: "Human Resources",
  ImportandExport: "Import and Export",
  IndividualAndFamilyServices: "Individual And Family Services",
  IndustrialAutomation: "Industrial Automation",
  InformationServices: "Information Services",
  InformationTechnologyandServices: "Information Technology and Services",
  Insurance: "Insurance",
  InternationalAffairs: "International Affairs",
  InternationalTradeandDevelopment: "International Trade and Development",
  Internet: "Internet",
  InvestmentBanking: "Investment Banking",
  InvestmentManagement: "Investment Management",
  Judiciary: "Judiciary",
  LawEnforcement: "Law Enforcement",
  LawPractice: "Law Practice",
  LegalServices: "Legal Services",
  LegislativeOffice: "Legislative Office",
  LeisureTravelAndTourism: "Leisure, Travel And Tourism",
  Libraries: "Libraries",
  LogisticsandSupplyChain: "Logistics and Supply Chain",
  LuxuryGoodsAndJewelry: "Luxury Goods And Jewelry",
  Machinery: "Machinery",
  ManagementConsulting: "Management Consulting",
  Maritime: "Maritime",
  MarketResearch: "Market Research",
  MarketingandAdvertising: "Marketing and Advertising",
  MechanicalorIndustrialEngineering: "Mechanical or Industrial Engineering",
  MediaProduction: "Media Production",
  MedicalDevices: "Medical Devices",
  MedicalPractice: "Medical Practice",
  MentalHealthCare: "Mental Health Care",
  Military: "Military",
  MiningАндMetals: "Mining And Metals",
  MotionPicturesandFilm: "Motion Pictures and Film",
  MuseumsandInstitutions: "Museums and Institutions",
  Music: "Music",
  Nanotechnology: "Nanotechnology",
  Newspapers: "Newspapers",
  NonProfitOrganizationManagement: "Non-Profit Organization Management",
  OilAndEnergy: "Oil And Energy",
  OnlineMedia: "Online Media",
  OutsourcingOrOffshoring: "Outsourcing/Offshoring",
  PackageOrFreightDelivery: "Package/Freight Delivery",
  PackagingandContainers: "Packaging and Containers",
  PaperAndForestProducts: "Paper And Forest Products",
  PerformingArts: "Performing Arts",
  Pharmaceuticals: "Pharmaceuticals",
  Philanthropy: "Philanthropy",
  Photography: "Photography",
  Plastics: "Plastics",
  PoliticalOrganization: "Political Organization",
  PrimaryOrSecondaryEducation: "Primary/Secondary Education",
  Printing: "Printing",
  ProfessionalTrainingAndCoaching: "Professional Training And Coaching",
  ProgramDevelopment: "Program Development",
  PublicPolicy: "Public Policy",
  PublicRelationsandCommunications: "Public Relations and Communications",
  PublicSafety: "Public Safety",
  Publishing: "Publishing",
  RailroadManufacture: "Railroad Manufacture",
  Ranching: "Ranching",
  RealEstate: "Real Estate",
  RecreationalFacilitiesandServices: "Recreational Facilities and Services",
  ReligiousInstitutions: "Religious Institutions",
  RenewablesAndEnvironment: "Renewables And Environment",
  Research: "Research",
  Restaurants: "Restaurants",
  Retail: "Retail",
  SecurityandInvestigations: "Security and Investigations",
  Semiconductors: "Semiconductors",
  Shipbuilding: "Shipbuilding",
  SportingGoods: "Sporting Goods",
  Sports: "Sports",
  StaffingandRecruiting: "Staffing and Recruiting",
  Supermarkets: "Supermarkets",
  Telecommunications: "Telecommunications",
  Textiles: "Textiles",
  ThinkTanks: "Think Tanks",
  Tobacco: "Tobacco",
  TranslationandLocalization: "Translation and Localization",
  TransportationOrTruckingOrRailroad: "Transportation/Trucking/Railroad",
  Utilities: "Utilities",
  VentureCapitalAndPrivateEquity: "Venture Capital And Private Equity",
  Veterinary: "Veterinary",
  Warehousing: "Warehousing",
  Wholesale: "Wholesale",
  WineandSpirits: "Wine and Spirits",
  Wireless: "Wireless",
  WritingandEditing: "Writing and Editing",
};

export const industryDropDown = Object.values(industryEnum).map((x) => ({
  label: x,
  value: x,
}));
