import React, { useState } from "react";

//Styles
import styles from "./ReadOnlyInput.module.scss"

const ReadOnlyInput = (props) => {
return (
    <label className={`${styles.readOnlyInput} ${props.readOnlyLabelClass} fontNormal width_100`}>
        {props.label}
        <input value={props.value} type="readonly" name="name" readOnly />
    </label>
    )
}

export default ReadOnlyInput;