import React, { useState } from "react";
import { useParams } from "react-router-dom";

//Helpers
import { PERMISSIONS } from "@helper/permissionConstants";
import { permissionHelper } from "@helper/permissionHelper";
import { useSlice } from "@hooks/useSlice";
import { useGetWorkflowStepsQuery } from "@api/workflowStepsApi";
import { useGetScheduleQuery } from "@api/sendingScheduleApi";
import { useGetAudienceDetailsQuery } from "@api/audienceApi";
import { checkFullArray } from "@helper/arrayHelper";

//Components
import SendingSchedule from "../SendingSchedule/SendingSchedule";
import Permission from "@components/auth/Permission";
import Exclusions from "../Exclusions/Exclusions";
import Accordion from "@ui/Accordion/Accordion";
import Domains from "../Domains/Domains";
import CRMIntegrations from "../CRM-Integrations/CRMIntegrations";
import Drawer from "@ui/Drawer/Drawer";
import SendingSchedulerChild from "@pages/CampaignSettings/EditPages/SendingSchedule/SendingSchedule";

const Settings = () => {
  const { organizationId, campaignId } = useParams();
  const campaign = useSlice((state) => state.campaign.campaign);
  const userRole = useSlice((state) => state.user.organization.role);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const progress = campaign.campaignSetupProgressModel;

  const audienceQuery = useGetAudienceDetailsQuery(campaign.audienceId, {
    refetchOnMountOrArgChange: true,
    skip: !campaign.audienceId,
  });

  let emailSettingsCompleted = checkFullArray(campaign.emailAccounts);

  const workflowStepsQuery = useGetWorkflowStepsQuery(campaignId, { skip: !campaignId, refetchOnMountOrArgChange: true });
  const scheduleQuery = useGetScheduleQuery(parseInt(campaignId), { skip: !campaignId, refetchOnMountOrArgChange: true });
  let arrayDomains;
  if (workflowStepsQuery.isSuccess && scheduleQuery.isSuccess && (audienceQuery.isSuccess || audienceQuery.isUninitialized)) {
    if (emailSettingsCompleted) {
      arrayDomains = campaign?.emailAccounts?.map((a) => a.domainId);
    }
  }

  const handleCloseDrawer = () => {
    scheduleQuery.refetch();
    setDrawerOpen(false);
  };

  return (
    <div>
      <Accordion
        header="Scheduler"
        subHeader="Set the active dates and message delivery timings for all campaign activity."
        variant="whiteTransparent"
        eventKey="sending-sequence"
        hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
        isChecked={progress.isSetSchedule}
        isClickable={progress.isSetSchedule}
        showConfiguration={!progress.isSetSchedule}
        openButtonClick={() => setDrawerOpen(true)}
        configButtonClick={() => setDrawerOpen(!isDrawerOpen)}
        configurationId="schedulerStep"
        configurationLabel="Configure your sending schedule"
        body={<SendingSchedule />}
        noCaret={true}
      />
      <Permission has={PERMISSIONS.exclusion.view}>
        <Accordion
          variant="whiteTransparent"
          header="Exclusions"
          subHeader="Add businesses, individual prospects, or bulk suppression files for exclusion."
          openButtonUrl={`/${organizationId}/exclusions/CampaignId=${campaign.id}`}
          hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
          eventKey="exclusions"
          isChecked={true}
          body={<Exclusions />}
          noCaret={true}
        />
      </Permission>
      <Permission has={PERMISSIONS.domain.view}>
        <Accordion
          variant="whiteTransparent"
          header="Tracking"
          subHeader="Configure campaign tracking to see opens, clicks and response data."
          openButtonUrl={`/${organizationId}/domains`}
          hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
          eventKey="domains"
          isChecked={true}
          body={<Domains arrayDomains={arrayDomains} />}
          noCaret={true}
        />
      </Permission>
      <Permission has={PERMISSIONS.crm.view}>
        <Accordion
          variant="whiteTransparent"
          header="CRM Integration"
          openButtonUrl={`/${organizationId}/crm`}
          hasPermission={permissionHelper(userRole, PERMISSIONS.campaign.edit)}
          eventKey="integrations"
          subHeader="Connect your preferred CRM for real time campaign data sync."
          isChecked={true}
          body={<CRMIntegrations />}
          noCaret={true}
        />
      </Permission>
      <Drawer
        title="Sending Scheduler"
        content={<SendingSchedulerChild handleCloseDrawer={handleCloseDrawer} setDrawerOpen={setDrawerOpen} />}
        isOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        showFooter={false}
        disableCloseOnOutsideClick={true}
      />
    </div>
  );
};

export default Settings;
