import { PERMISSIONS } from "./permissionConstants";

export const ROLE_PERMISSIONS = {
  // Owner permissions
  1: [
    PERMISSIONS.application.view,
    PERMISSIONS.credits.view,
    PERMISSIONS.organization.view,
    PERMISSIONS.organization.create,
    PERMISSIONS.organization.edit,
    PERMISSIONS.organization.delete,
    PERMISSIONS.organization.users.view,
    PERMISSIONS.organization.users.create,
    PERMISSIONS.organization.users.edit,
    PERMISSIONS.organization.users.delete,
    PERMISSIONS.organization.settings.view,
    PERMISSIONS.organization.settings.edit,
    PERMISSIONS.organization.settings.delete,

    PERMISSIONS.compliance.view,

    PERMISSIONS.compliance.questionsAndAnswers.create,
    PERMISSIONS.compliance.questionsAndAnswers.view,
    PERMISSIONS.compliance.questionsAndAnswers.edit,
    PERMISSIONS.compliance.questionsAndAnswers.delete,

    PERMISSIONS.compliance.rightToErase.view,
    PERMISSIONS.compliance.rightToErase.export,
    PERMISSIONS.compliance.rightToErase.delete,

    PERMISSIONS.compliance.unsubscribeSettings.view,
    PERMISSIONS.compliance.unsubscribeSettings.edit,

    PERMISSIONS.campaign.view,
    PERMISSIONS.campaign.create,
    PERMISSIONS.campaign.edit,
    PERMISSIONS.campaign.delete,
    PERMISSIONS.campaign.moveProspectsToCampaign,
    PERMISSIONS.campaign.viewOptimizations,
    PERMISSIONS.campaign.applyOptimizations,

    PERMISSIONS.prospect.view,
    PERMISSIONS.prospect.create,
    PERMISSIONS.prospect.edit,
    PERMISSIONS.prospect.delete,

    PERMISSIONS.reports.view,

    PERMISSIONS.exclusion.view,
    PERMISSIONS.exclusion.create,
    PERMISSIONS.exclusion.delete,
    PERMISSIONS.exclusion.export,
    PERMISSIONS.exclusion.import,

    PERMISSIONS.dealNavigator.view,
    PERMISSIONS.dealNavigator.edit,

    PERMISSIONS.salesDocuments.documents.view,
    PERMISSIONS.salesDocuments.documents.create,
    PERMISSIONS.salesDocuments.documents.edit,
    PERMISSIONS.salesDocuments.documents.delete,

    PERMISSIONS.emailAccounts.view,
    PERMISSIONS.emailAccounts.create,
    PERMISSIONS.emailAccounts.edit,
    PERMISSIONS.emailAccounts.delete,
    PERMISSIONS.emailAccounts.sendTest,
    PERMISSIONS.emailAccounts.assignToUser,

    PERMISSIONS.companies.view,
    PERMISSIONS.companies.create,
    PERMISSIONS.companies.edit,
    PERMISSIONS.companies.delete,

    PERMISSIONS.emailTemplates.view,
    PERMISSIONS.emailTemplates.create,
    PERMISSIONS.emailTemplates.edit,
    PERMISSIONS.emailTemplates.delete,
    PERMISSIONS.emailTemplates.sendTestEmail,
    PERMISSIONS.emailTemplates.sendTestEmailSequence,

    PERMISSIONS.domain.view,
    PERMISSIONS.domain.create,
    PERMISSIONS.domain.edit,
    PERMISSIONS.domain.delete,

    PERMISSIONS.audience.view,
    PERMISSIONS.audience.create,
    PERMISSIONS.audience.edit,
    PERMISSIONS.audience.delete,
    PERMISSIONS.audience.importAudienceContact,
    PERMISSIONS.audience.exportAudienceContact,
    PERMISSIONS.audience.archiveRestoreAudience,
    PERMISSIONS.audience.viewAudienceContacts,

    PERMISSIONS.deals.view,
    PERMISSIONS.deals.export,

    PERMISSIONS.responderInbox.view,
    PERMISSIONS.responderInbox.edit,

    PERMISSIONS.companyActivityOverview.view,

    PERMISSIONS.prospectActivityOverview.view,

    PERMISSIONS.compliance.view,

    PERMISSIONS.conversation.view,

    PERMISSIONS.emailDeliverabilityTest.view,
    PERMISSIONS.emailDeliverabilityTest.send,

    PERMISSIONS.audienceExplorer.view,
    PERMISSIONS.audienceExplorer.create,

    PERMISSIONS.integration.view,
    PERMISSIONS.integration.hubspot.getCompaniesProperties,
    PERMISSIONS.integration.hubspot.getContactsProperties,
    PERMISSIONS.integration.hubspot.getDealsProperties,
    PERMISSIONS.integration.hubspot.mapContactsAndCompaniesProperties,

    PERMISSIONS.crm.view,
    PERMISSIONS.crm.edit,

    PERMISSIONS.billingPayment.view,
    PERMISSIONS.billingPayment.create,
    PERMISSIONS.billingPayment.cancel,

    PERMISSIONS.dashboard.view,

    PERMISSIONS.purchaseTemplate.create,

    PERMISSIONS.contract.cancel,

    PERMISSIONS.calendar.view,
  ],
  // Admin permissions
  2: [
    PERMISSIONS.application.view,
    PERMISSIONS.credits.view,
    PERMISSIONS.organization.view,
    PERMISSIONS.organization.create,
    PERMISSIONS.organization.edit,
    PERMISSIONS.organization.users.view,
    PERMISSIONS.organization.users.create,
    PERMISSIONS.organization.users.edit,
    PERMISSIONS.organization.users.delete,
    PERMISSIONS.organization.settings.view,
    PERMISSIONS.organization.settings.edit,
    PERMISSIONS.organization.settings.delete,

    PERMISSIONS.campaign.view,
    PERMISSIONS.campaign.create,
    PERMISSIONS.campaign.edit,
    PERMISSIONS.campaign.delete,
    PERMISSIONS.campaign.moveProspectsToCampaign,
    PERMISSIONS.campaign.viewOptimizations,
    PERMISSIONS.campaign.applyOptimizations,

    PERMISSIONS.prospect.view,
    PERMISSIONS.prospect.create,
    PERMISSIONS.prospect.edit,
    PERMISSIONS.prospect.delete,

    PERMISSIONS.reports.view,

    PERMISSIONS.exclusion.view,
    PERMISSIONS.exclusion.create,
    PERMISSIONS.exclusion.delete,
    PERMISSIONS.exclusion.export,
    PERMISSIONS.exclusion.import,

    PERMISSIONS.dealNavigator.view,
    PERMISSIONS.dealNavigator.edit,

    PERMISSIONS.salesDocuments.documents.view,
    PERMISSIONS.salesDocuments.documents.create,
    PERMISSIONS.salesDocuments.documents.edit,
    PERMISSIONS.salesDocuments.documents.delete,

    PERMISSIONS.emailAccounts.view,
    PERMISSIONS.emailAccounts.create,
    PERMISSIONS.emailAccounts.edit,
    PERMISSIONS.emailAccounts.delete,
    PERMISSIONS.emailAccounts.sendTest,
    PERMISSIONS.emailAccounts.viewOwnerColumn,
    PERMISSIONS.emailAccounts.assignToUser,

    PERMISSIONS.companies.view,
    PERMISSIONS.companies.create,
    PERMISSIONS.companies.edit,
    PERMISSIONS.companies.delete,

    PERMISSIONS.emailTemplates.view,
    PERMISSIONS.emailTemplates.create,
    PERMISSIONS.emailTemplates.edit,
    PERMISSIONS.emailTemplates.delete,
    PERMISSIONS.emailTemplates.sendTestEmail,
    PERMISSIONS.emailTemplates.sendTestEmailSequence,

    PERMISSIONS.domain.view,
    PERMISSIONS.domain.create,
    PERMISSIONS.domain.edit,
    PERMISSIONS.domain.delete,

    PERMISSIONS.audience.view,
    PERMISSIONS.audience.create,
    PERMISSIONS.audience.edit,
    PERMISSIONS.audience.delete,
    PERMISSIONS.audience.importAudienceContact,
    PERMISSIONS.audience.exportAudienceContact,
    PERMISSIONS.audience.archiveRestoreAudience,
    PERMISSIONS.audience.viewAudienceContacts,

    PERMISSIONS.deals.view,
    PERMISSIONS.deals.export,

    PERMISSIONS.compliance.view,

    PERMISSIONS.compliance.questionsAndAnswers.create,
    PERMISSIONS.compliance.questionsAndAnswers.view,
    PERMISSIONS.compliance.questionsAndAnswers.edit,
    PERMISSIONS.compliance.questionsAndAnswers.delete,

    PERMISSIONS.compliance.rightToErase.view,
    PERMISSIONS.compliance.rightToErase.export,
    PERMISSIONS.compliance.rightToErase.delete,

    PERMISSIONS.compliance.unsubscribeSettings.view,
    PERMISSIONS.compliance.unsubscribeSettings.edit,

    PERMISSIONS.responderInbox.view,
    PERMISSIONS.responderInbox.edit,

    PERMISSIONS.companyActivityOverview.view,

    PERMISSIONS.prospectActivityOverview.view,

    PERMISSIONS.compliance.view,

    PERMISSIONS.conversation.view,

    PERMISSIONS.emailDeliverabilityTest.view,
    PERMISSIONS.emailDeliverabilityTest.send,

    PERMISSIONS.audienceExplorer.view,
    PERMISSIONS.audienceExplorer.create,

    PERMISSIONS.integration.view,
    PERMISSIONS.integration.hubspot.getCompaniesProperties,
    PERMISSIONS.integration.hubspot.getContactsProperties,
    PERMISSIONS.integration.hubspot.getDealsProperties,
    PERMISSIONS.integration.hubspot.mapContactsAndCompaniesProperties,

    PERMISSIONS.crm.view,
    PERMISSIONS.crm.edit,

    PERMISSIONS.dashboard.view,

    PERMISSIONS.billingPayment.view,

    PERMISSIONS.purchaseTemplate.create,

    PERMISSIONS.calendar.view,
  ],
  // Editor permissions
  3: [
    PERMISSIONS.application.view,
    PERMISSIONS.credits.view,

    PERMISSIONS.organization.view,
    // PERMISSIONS.organization.create,
    // PERMISSIONS.organization.users.view,
    // PERMISSIONS.organization.users.create,
    // PERMISSIONS.organization.users.delete,
    // PERMISSIONS.organization.settings.view,
    // PERMISSIONS.organization.settings.edit,
    // PERMISSIONS.organization.settings.delete,

    PERMISSIONS.campaign.view,
    PERMISSIONS.campaign.create,
    PERMISSIONS.campaign.edit,
    PERMISSIONS.campaign.moveProspectsToCampaign,
    PERMISSIONS.campaign.viewOptimizations,
    PERMISSIONS.campaign.applyOptimizations,

    PERMISSIONS.prospect.view,
    PERMISSIONS.prospect.create,
    PERMISSIONS.prospect.edit,
    PERMISSIONS.prospect.delete,

    PERMISSIONS.reports.view,

    PERMISSIONS.exclusion.view,
    PERMISSIONS.exclusion.create,
    PERMISSIONS.exclusion.delete,
    PERMISSIONS.exclusion.export,
    PERMISSIONS.exclusion.import,

    PERMISSIONS.dealNavigator.view,
    PERMISSIONS.dealNavigator.edit,

    PERMISSIONS.salesDocuments.documents.view,
    PERMISSIONS.salesDocuments.documents.create,
    PERMISSIONS.salesDocuments.documents.edit,
    PERMISSIONS.salesDocuments.documents.delete,

    PERMISSIONS.emailAccounts.view,
    PERMISSIONS.emailAccounts.create,
    PERMISSIONS.emailAccounts.edit,
    PERMISSIONS.emailAccounts.delete,
    PERMISSIONS.emailAccounts.sendTest,

    PERMISSIONS.companies.view,

    PERMISSIONS.emailTemplates.view,
    PERMISSIONS.emailTemplates.create,
    PERMISSIONS.emailTemplates.edit,
    PERMISSIONS.emailTemplates.delete,
    PERMISSIONS.emailTemplates.sendTestEmail,
    PERMISSIONS.emailTemplates.sendTestEmailSequence,

    PERMISSIONS.domain.view,
    PERMISSIONS.domain.create,
    PERMISSIONS.domain.edit,

    PERMISSIONS.audience.view,
    PERMISSIONS.audience.create,
    PERMISSIONS.audience.edit,
    PERMISSIONS.audience.delete,
    PERMISSIONS.audience.viewAudienceContacts,
    PERMISSIONS.audience.importAudienceContact,
    PERMISSIONS.audience.exportAudienceContact,

    PERMISSIONS.deals.view,
    PERMISSIONS.deals.export,

    PERMISSIONS.dealNavigator.view,
    PERMISSIONS.dealNavigator.edit,

    PERMISSIONS.compliance.questionsAndAnswers.view,

    // PERMISSIONS.compliance.rightToErase.view,

    PERMISSIONS.compliance.unsubscribeSettings.view,
    PERMISSIONS.compliance.unsubscribeSettings.edit,

    PERMISSIONS.responderInbox.view,
    PERMISSIONS.responderInbox.edit,

    PERMISSIONS.companyActivityOverview.view,

    PERMISSIONS.prospectActivityOverview.view,

    PERMISSIONS.compliance.view,

    PERMISSIONS.conversation.view,

    // PERMISSIONS.emailDeliverabilityTest.view,

    PERMISSIONS.audienceExplorer.view,
    PERMISSIONS.audienceExplorer.create,

    PERMISSIONS.dashboard.view,

    PERMISSIONS.purchaseTemplate.create,

    PERMISSIONS.integration.view,
    PERMISSIONS.integration.hubspot.getCompaniesProperties,
    PERMISSIONS.integration.hubspot.getContactsProperties,
    PERMISSIONS.integration.hubspot.getDealsProperties,
    PERMISSIONS.integration.hubspot.mapContactsAndCompaniesProperties,

    PERMISSIONS.crm.view,

    PERMISSIONS.calendar.view,
  ],
  // Viewer permissions
  4: [
    PERMISSIONS.application.view,
    PERMISSIONS.dashboard.view,
    PERMISSIONS.credits.view,
    PERMISSIONS.organization.view,

    PERMISSIONS.campaign.view,
    PERMISSIONS.campaign.viewOptimizations,

    PERMISSIONS.audience.view,
    PERMISSIONS.audience.viewAudienceContacts,

    PERMISSIONS.exclusion.view,

    PERMISSIONS.emailTemplates.view,

    PERMISSIONS.prospect.view,
    PERMISSIONS.prospectActivityOverview.view,

    PERMISSIONS.reports.view,

    PERMISSIONS.deals.view,
    PERMISSIONS.conversation.view,
    PERMISSIONS.companies.view,

    PERMISSIONS.compliance.view,
    PERMISSIONS.compliance.questionsAndAnswers.view,

    PERMISSIONS.salesDocuments.documents.view,

    PERMISSIONS.dealNavigator.view,
    PERMISSIONS.companyActivityOverview.view,

    PERMISSIONS.calendar.view,
  ],
  //Standard users
  5: [
    PERMISSIONS.application.view,
    PERMISSIONS.dashboard.view,
    PERMISSIONS.credits.view,
    PERMISSIONS.organization.view,

    PERMISSIONS.emailAccounts.view,
    PERMISSIONS.emailAccounts.create,
    PERMISSIONS.emailAccounts.edit,
    PERMISSIONS.emailAccounts.delete,
    PERMISSIONS.emailAccounts.sendTest,

    PERMISSIONS.campaign.view,
    PERMISSIONS.campaign.viewOptimizations,

    PERMISSIONS.audience.view,
    PERMISSIONS.audience.viewAudienceContacts,

    PERMISSIONS.exclusion.view,

    PERMISSIONS.emailTemplates.view,
    PERMISSIONS.emailTemplates.sendTestEmail,
    PERMISSIONS.emailTemplates.sendTestEmailSequence,

    PERMISSIONS.prospect.view,
    PERMISSIONS.prospectActivityOverview.view,

    PERMISSIONS.reports.view,

    PERMISSIONS.responderInbox.view,

    PERMISSIONS.deals.view,
    PERMISSIONS.conversation.view,
    PERMISSIONS.companies.view,

    PERMISSIONS.compliance.view,
    PERMISSIONS.compliance.questionsAndAnswers.view,

    PERMISSIONS.salesDocuments.documents.view,
    PERMISSIONS.salesDocuments.documents.create,

    PERMISSIONS.dealNavigator.view,

    PERMISSIONS.calendar.view,
  ],
};
