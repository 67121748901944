import { useQuery } from "./useQuery";
import { filtersToQueryString, numberOfRecords } from "../tableConstants";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { UseActionsReturn } from "./useActions";
import { DataTableState } from "@store/createDataTableSlice";

export const useDataTableWithQueryParams = <T>(
  slice: DataTableState<T>,
  actions: UseActionsReturn,
  filterTypes: string[],
  useWithQuery = true,
  autoInitiate = true
) => {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();

  const { initiate, clearInitiated } = actions;

  const params = _.cloneDeep(slice.params);

  if (useWithQuery) {
    const showParam: string | null = query.get("show");
    if (showParam != null) {
      const show = parseInt(showParam);
      const records = numberOfRecords.find((n) => n.value === show);
      if (records != null) {
        params.length = records.value;
      }
    }

    const queryParam = query.get("q");
    if (queryParam != null) {
      params.search = queryParam;
    }

    const pageParam = query.get("page");
    if (pageParam != null) {
      const page = parseInt(pageParam) - 1;
      params.page = page;
      params.start = params.length * page;
    }
  }

  filterTypes.forEach((filter) => {
    const filterParam = query.get(filter);
    if (filterParam != null) {
      const newFilter = { type: filter, value: filterParam.split(",") };
      params.filter = [...params.filter, newFilter];
    }
  });

  useEffect(() => {
    if (autoInitiate) {
      initiate(params);
    }
  }, [autoInitiate]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    if (slice.isInitiated && useWithQuery) {
      const querystring = location.search;
      const newQuerystring = filtersToQueryString(slice.params, null, query);

      if (querystring !== newQuerystring) {
        history.replace(location.pathname + newQuerystring);
      }
    }
  }, [slice.params, slice.isInitiated]);

  return params;
};
