import React from "react";
import svg from "./ArchiveIcon.svg";

const ArchiveIcon = (props) => (
  <svg className={props.imgStyle} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M0.205803 2.42568L2.3051 0.215896C2.37 0.147338 2.44713 0.0929679 2.53207 0.0559156C2.617 0.0188633 2.70806 -0.000139594 2.8 7.7197e-07H11.2C11.292 -0.000139594 11.383 0.0188633 11.4679 0.0559156C11.5529 0.0929679 11.63 0.147338 11.6949 0.215896L13.7942 2.42568C13.8596 2.49397 13.9114 2.57525 13.9468 2.6648C13.9821 2.75435 14.0002 2.8504 14 2.94737V12.5263C14 13.339 13.3721 14 12.6 14H1.4C0.627902 14 1.82759e-06 13.339 1.82759e-06 12.5263V2.94737C-0.000209292 2.8504 0.0178728 2.75435 0.0532006 2.6648C0.0885283 2.57525 0.1404 2.49397 0.205803 2.42568ZM10.9102 1.47368H3.0898L2.3898 2.21053H11.6102L10.9102 1.47368ZM12.6 12.5263V3.68421H1.4L1.3986 12.5263H12.6Z"
        fill="#5c656f"
      />
      <path d="M5.6 5.77496H8.4V7.87495H10.5L7 11.375L3.5 7.87495H5.6V5.77496Z" fill="#5c656f" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ArchiveIcon;
