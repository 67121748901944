import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { removeSupportCenterRTE } from "../../../../store/ComplianceSupportCenter/actions/supportCenterQA";

//Helpers
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import { PERMISSIONS } from "../../../../helper/permissionConstants";
import { fileDownload } from "../../../../helper/fileDownloadHelper";
import api, { API, formUrl } from "../../../../helper/api/api";

//Components
import RTEProspect from "./components/RTEProspect";
import RTEProspectActivityLog from "./components/RTEProspectActivityLog";
import Permission from "../../../../components/auth/Permission";
import Button from "../../../../components/UI/Button/Button";

const RTEDataContent = (props) => {
  const { prospects, totalProspects, email, setEmail, removeSupportCenterRTE, totalProspectActivityLog, prospectActivityLog } = props;
  const [key, setKey] = useState(1);

  const handleExportData = () => {
    api(formUrl(API.complianceSupportCenter.getRTEExport, { email: email }, "blob"))
      .then((res) => {
        fileDownload("SAR.csv", res.data);
        setEmail("");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleDataRemoval = () => {
    removeSupportCenterRTE(email)
      .then((res) => {
        toast.success(toastMessages.rteDataContent.successHandleDataRemoval);
        setEmail("");
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      <Tabs className="custom_tabs" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="1" title="Prospects">
          <RTEProspect prospects={prospects} totalProspects={totalProspects} />
        </Tab>
        <Tab eventKey="2" title="Prospect Activity Log">
          <RTEProspectActivityLog prospectActivityLog={prospectActivityLog} totalProspectActivityLog={totalProspectActivityLog} />
        </Tab>
      </Tabs>
      <div className="mTb15 flex justify_end">
        <Permission has={PERMISSIONS.compliance.rightToErase.export}>
          <Button id="exportProspectRte" disabled={prospects.length === 0} onClick={handleExportData} variant="primary">
            Export prospect data (SAR)
          </Button>
        </Permission>
        <Permission has={PERMISSIONS.compliance.rightToErase.delete}>
          <Button id="removeProspectRte" disabled={prospects.length === 0} onClick={handleDataRemoval} variant="danger">
            Remove prospect data (RTE)
          </Button>
        </Permission>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSupportCenterRTE: (email) => dispatch(removeSupportCenterRTE(email)),
  };
};

export default connect(null, mapDispatchToProps)(RTEDataContent);
