import { ProspectGetPhoneResponseModel } from "@models/prospect/ProspectGetPhoneResponseModel";
import { api } from "./api";

export const prospectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProspectPhoneNumber: builder.mutation<ProspectGetPhoneResponseModel, number>({
      query: (prospectId) => ({
        url: `/phone-finder/search-phone?prospectId=${prospectId}`,
        method: "GET",
      }),
      // providesTags: ["ProspectDetails"], 
    }),
  }),
});

export const { useGetProspectPhoneNumberMutation } = prospectsApi;
