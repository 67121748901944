import * as actionTypes from "./actionTypes";
import { toast } from "react-toastify";
import api, { API, formUrl } from "../../../helper/api/api";
import { toastMessages } from "../../../helper/toastMessagesConstants";
// #region FETCH
export const fetchSalesDocumentsRequest = () => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENTS_REQUEST,
  };
};

export const fetchSalesDocumentsSuccess = (salesDocuments) => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENTS_SUCCEEDED,
    result: salesDocuments,
  };
};

export const fetchSalesDocumentsFailed = (error) => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENTS_FAILED,
    result: error,
  };
};

export const fetchSalesDocuments = () => {
  return (dispatch) => {
    dispatch(fetchSalesDocumentsRequest());
    return api(API.salesDocument.getAll)
      .then((response) => {
        dispatch(fetchSalesDocumentsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSalesDocumentsFailed(error.message));
      });
  };
};

export const fetchSalesDocumentRequest = () => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENT_REQUEST,
  };
};

export const fetchSalesDocumentSuccess = (salesDocument) => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENT_SUCCEEDED,
    result: salesDocument,
  };
};

export const fetchSalesDocumentFailed = (error) => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENT_FAILED,
    result: error,
  };
};

export const fetchSalesDocument = (id) => {
  return (dispatch) => {
    dispatch(fetchSalesDocumentRequest());
    return api(formUrl(API.salesDocument.getById, { id: id }))
      .then((response) => {
        dispatch(fetchSalesDocumentSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSalesDocumentFailed(error.message));
      });
  };
};

export const fetchSalesVideosDocumentsRequest = () => {
  return {
    type: actionTypes.FETCH_SALES_VIDEOS_DOCUMENT_REQUEST,
  };
};

export const fetchSalesVideosDocumentsSuccess = (salesDocument) => {
  return {
    type: actionTypes.FETCH_SALES_VIDEOS_DOCUMENT_SUCCEEDED,
    result: salesDocument,
  };
};

export const fetchSalesVideosDocumentsFailed = (error) => {
  return {
    type: actionTypes.FETCH_SALES_VIDEOS_DOCUMENT_FAILED,
    result: error,
  };
};

export const fetchSalesVideosDocuments = (search) => {
  return (dispatch) => {
    dispatch(fetchSalesVideosDocumentsRequest());
    return api(formUrl(API.salesDocument.getAllVideos, { search: search }))
      .then((response) => {
        dispatch(fetchSalesVideosDocumentsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSalesVideosDocumentsFailed(error.message));
      });
  };
};

export const fetchSalesDocumentsImagesRequest = () => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENTS_IMAGES_REQUEST,
  };
};

export const fetchSalesDocumentsImagesSuccess = (salesDocument) => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENTS_IMAGES_SUCCEEDED,
    result: salesDocument,
  };
};

export const fetchSalesDocumentsImagesFailed = (error) => {
  return {
    type: actionTypes.FETCH_SALES_DOCUMENTS_IMAGES_FAILED,
    result: error,
  };
};

export const fetchSalesDocumentsImages = (search) => {
  return (dispatch) => {
    dispatch(fetchSalesDocumentsImagesRequest());
    return api(formUrl(API.salesDocument.getAllImages, { search: search }))
      .then((response) => {
        dispatch(fetchSalesDocumentsImagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSalesDocumentsImagesFailed(error.message));
      });
  };
};

export const setSearchValue = (search) => {
  return {
    type: actionTypes.SET_SEARCH_VALUE,
    result: search,
  };
};
//#endregion

// #region ADD
export const addSalesDocumentsDocumentSuccess = (SalesDocumentsDocument) => {
  return {
    type: actionTypes.ADD_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED,
    result: SalesDocumentsDocument,
  };
};

export const addSalesDocumentsDocumentFailed = (error) => {
  return {
    type: actionTypes.ADD_SALES_DOCUMENTS_DOCUMENT_FAILED,
    result: error,
  };
};

export const addSalesDocumentsImageSuccess = (SalesDocumentsImage) => {
  return {
    type: actionTypes.ADD_SALES_DOCUMENTS_IMAGE_SUCCEEDED,
    result: SalesDocumentsImage,
  };
};

export const addSalesDocumentsImageFailed = (error) => {
  return {
    type: actionTypes.ADD_SALES_DOCUMENTS_IMAGE_FAILED,
    result: error,
  };
};

export const addSalesDocumentsVideoSuccess = (SalesDocumentsVideo) => {
  return {
    type: actionTypes.ADD_SALES_DOCUMENTS_VIDEO_SUCCEEDED,
    result: SalesDocumentsVideo,
  };
};

export const addSalesDocumentsVideoFailed = (error) => {
  return {
    type: actionTypes.ADD_SALES_DOCUMENTS_VIDEO_FAILED,
    result: error,
  };
};
//#endregion

// #region CHANGE
export const changeSalesDocumentsDocumentSuccess = (SalesDocumentsDocument) => {
  return {
    type: actionTypes.CHANGE_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED,
    result: SalesDocumentsDocument,
  };
};

export const changeSalesDocumentsDocumentFailed = (error) => {
  return {
    type: actionTypes.CHANGE_SALES_DOCUMENTS_DOCUMENT_FAILED,
    result: error,
  };
};

export const changeSalesDocumentsImageSuccess = (SalesDocumentsImage) => {
  return {
    type: actionTypes.CHANGE_SALES_DOCUMENTS_IMAGE_SUCCEEDED,
    result: SalesDocumentsImage,
  };
};

export const changeSalesDocumentsImageFailed = (error) => {
  return {
    type: actionTypes.CHANGE_SALES_DOCUMENTS_IMAGE_FAILED,
    result: error,
  };
};

export const changeSalesDocumentsVideoSuccess = (SalesDocumentsVideo) => {
  return {
    type: actionTypes.CHANGE_SALES_DOCUMENTS_VIDEO_SUCCEEDED,
    result: SalesDocumentsVideo,
  };
};

export const changeSalesDocumentsVideoFailed = (error) => {
  return {
    type: actionTypes.CHANGE_SALES_DOCUMENTS_VIDEO_FAILED,
    result: error,
  };
};
// #endregion

// #region DELETE
export const removeSalesDocumentsRequest = () => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_REQUEST,
  };
};

export const removeSalesDocumentsSuccess = (SalesDocumentsVideo) => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_SUCCEEDED,
    result: SalesDocumentsVideo,
  };
};

export const removeSalesDocumentsFailed = (error) => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_FAILED,
    result: error,
  };
};

export const removeSalesDocuments = (id) => {
  return (dispatch) => {
    dispatch(removeSalesDocumentsRequest());
    return api(formUrl(API.salesDocument.remove, { id: id }))
      .then((response) => {
        dispatch(removeSalesDocumentsSuccess(id));
      })
      .catch((error) => {
        dispatch(removeSalesDocumentsFailed(error.message));
        toast.error(toastMessages.salesDocuments.errorRemoveDocuments);
      });
  };
};

export const removeSalesDocumentsDocumentRequest = () => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_DOCUMENT_REQUEST,
  };
};

export const removeSalesDocumentsDocumentSuccess = (SalesDocumentsDocument) => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_DOCUMENT_SUCCESS,
    result: SalesDocumentsDocument,
  };
};

export const removeSalesDocumentsDocumentFailed = (error) => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_DOCUMENT_FAILED,
    result: error,
  };
};

export const removeSalesDocumentsDocument = (id) => {
  return (dispatch) => {
    dispatch(removeSalesDocumentsDocumentRequest());
    return api(formUrl(API.salesDocument.remove, { id: id }))
      .then((response) => {
        dispatch(removeSalesDocumentsDocumentSuccess(id));
      })
      .catch((error) => {
        dispatch(removeSalesDocumentsDocumentFailed(error.message));
        toast.error(toastMessages.salesDocuments.errorRemoveDocuments);
      });
  };
};

export const removeSalesDocumentsImageRequest = () => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_IMAGE_REQUEST,
  };
};

export const removeSalesDocumentsImageSuccess = (image) => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_IMAGE_SUCCESS,
    result: image,
  };
};

export const removeSalesDocumentsImageFailed = (error) => {
  return {
    type: actionTypes.REMOVE_SALES_DOCUMENTS_IMAGE_FAILED,
    result: error,
  };
};

export const removeSalesDocumentsImage = (id) => {
  return (dispatch) => {
    dispatch(removeSalesDocumentsImageRequest());
    return api(formUrl(API.salesDocument.remove, { id: id }))
      .then((response) => {
        dispatch(removeSalesDocumentsImageSuccess(id));
      })
      .catch((error) => {
        dispatch(removeSalesDocumentsImageFailed(error.message));
        toast.error(toastMessages.salesDocuments.errorRemoveImage);
      });
  };
};
// #endregion
