import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuth } from "react-oidc-context";

//Helpers
import { errorResponseToList } from "../../helper/errorHelper";
import { DOMAIN_URL_CHECK, isBusinessEmail } from "../../helper/validationConstants";
import { globalPermissionHelper } from "../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../helper/globalUserRolePermissionsConstants";
import { organizationTypeEnum } from "../../helper/enums/organizationTypeEnum";
import { toastMessages } from "../../helper/toastMessagesConstants";
import api, { API, formUrl } from "../../helper/api/api";

//Styles
import styles from "./RegisterOrganization.module.scss";

//Images & Icons
import DisabledAccessImage from "../../assets/Images/DisabledAccessImage/DisabledAccessImage";

//Components
import RegistrationStepInfo from "./components/Steps/Info/Info";
import PreparingEnvironment from "./components/Steps/PreparingEnvironment/PreparingEnvironment";
import Button from "../../components/UI/Button/Button";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import LoggedInUser from "../../components/Header/LoggedInUser/LoggedInUser";
// import Logo from "../../assets/Logo/Logo";
import Logo from "../../assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";
import Spinner from "../../components/UI/Spinner/Spinner";
import RegistrationStepMembers from "./components/Steps/Members/RegistrationStepMembers";

const RegisterOrganization = (props) => {
  const auth = useAuth();
  let renderPage = null;

  const [page, setPage] = useState(1);
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [orgDetails, setOrgDetails] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hasNewImage, setHasNewImage] = useState(false);

  useEffect(() => {
    api(formUrl(API.organizationDetails.getOrganizationDetails))
      .then((response) => {
        if (response.data != null) {
          setDataLoaded(true);
        }
        setOrgDetails(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const nextPageHandler = () => {
    let newPage = page + 1;
    setPage(newPage);
  };

  const pageHandler = (page) => {
    let newPage = page;
    setPage(newPage);
  };

  let emptyStateContent = (
    <NavLink id="backToYourOrg" className="text_decoration_none" to="my-organizations">
      <Button id="registerGoToOrg" classes={styles.orgBtn} variant="secondary">
        Go to your organizations view
      </Button>
    </NavLink>
  );

  let image = <DisabledAccessImage />;

  const claimsRefreshCompleted = (organizationId) => {
    if (organizationId != null) {
      props.history.push("/" + organizationId + "/assistant/welcome-onboarding");
    } else {
      props.history.push("/my-organizations");
    }
  };

  let schema = Yup.object({
    name: Yup.string().required("Required"),
    organizationType: Yup.string().required("Required"),
    domain: Yup.string().required("Required").matches(DOMAIN_URL_CHECK, "Must be valid domain or website URL"),
    location: Yup.string().required("Required"),
    industry: Yup.string().required("Required"),
    companySize: Yup.string().required("Required"),
  });

  if (page === 2) {
    schema = Yup.object({
      name: Yup.string().required("Required"),
      organizationType: Yup.string().required("Required"),
      domain: Yup.string().required("Required").matches(DOMAIN_URL_CHECK, "Must be valid domain or website URL"),
      location: Yup.string().required("Required"),
      industry: Yup.string().required("Required"),
      companySize: Yup.string().required("Required"),
      invites: Yup.array()
        .of(
          Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .notOneOf([props.userEmail], "You are already a member")
              .test("isBusinessEmail", "Please use a business email address to invite users", isBusinessEmail),
            role: Yup.number().when("email", {
              is: (email) => email == null || email.length === 0,
              then: () => Yup.number(),
              otherwise: () => Yup.number().required("Choose a role"),
            }),
          })
        )
        .required("Must have valid email"),
    });
  }

  let user = <LoggedInUser orgPage={true} history={props.history} />;

  let logo = (
    <div className={styles.logoDetails}>
      <Logo height="30" />
    </div>
  );

  let content =
    dataLoaded && !orgDetails ? (
      <Spinner />
    ) : (
      <div className={`${styles.multiSteps} flex`}>
        <div className={`width_100 ${styles.navigationMenu}`}>
          <HeaderTitle className="p0 width_100" title={logo} rightContent={user} />
        </div>
        <div className={`${styles.stepsField} flex border-8`}>
          <div className={styles.msForm}>
            <Formik
              initialValues={{
                name: orgDetails?.companyName ?? "",
                organizationType: globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.view)
                  ? ""
                  : organizationTypeEnum.Client
                  ? organizationTypeEnum.Client
                  : "",
                domain: orgDetails?.website ?? "",
                industry: orgDetails?.industry ?? "",
                companySize: orgDetails?.companySize ?? "",
                location: orgDetails?.location ?? "",
                image: "",
                invites: [{ email: "", role: "" }],
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                if (page === 1) {
                  nextPageHandler();
                } else {
                  let org = { ...values };
                  if (org?.invites) {
                    org.invites = org.invites.filter((invite) => invite?.email);
                  } else delete org.invites;

                  //validate image file
                  if (org?.image) {
                    const isImageType = org.image.type.includes("image");

                    if (!isImageType) {
                      toast.error(toastMessages.fileUploader.errorFileNotImage);
                      return;
                    }
                  }

                  var data = new FormData();
                  data.append("name", org.name);
                  data.append("domain", org.domain);
                  data.append("industry", org.industry);
                  data.append("companySize", org.companySize);
                  data.append("location", org.location);
                  data.append("hasSalesAgents", org.hasSalesAgents != null ? org.hasSalesAgents : false);
                  data.append("image", org.image);
                  data.append("organizationType", org.organizationType);
                  data.append("hasNewImage", hasNewImage);

                  if (org.invites !== undefined) {
                    for (let i = 0; i < org.invites.length; i++) {
                      data.append(`invites[${i}].email`, org.invites[i].email || "");
                      data.append(`invites[${i}].role`, org.invites[i].role || "");
                    }
                  }

                  setIsCreating(true);
                  api(API.organization.create, data)
                    .then((response) => {
                      auth.signinSilent().then(() => {
                        claimsRefreshCompleted(response.data.id);
                      });
                    })
                    .catch((error) => {
                      const errorList = errorResponseToList(error);

                      if (errorList.length > 0) {
                        errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                        setCreateError(errorList);
                      } else {
                        toast.error(error.message);
                        setPage(1); //failed go to Page1
                        setIsCreating(false);
                      }
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });
                }
              }}
            >
              {(formikProps) => {
                const { values, handleSubmit, errors } = formikProps;
                const formIsValid =
                  Object.keys(errors).length === 0 && Object.keys(values).every((key) => !schema.fields[key]?.required || !!values[key]);
                setIsFormValid(formIsValid);
                switch (page) {
                  case 1:
                    renderPage = (
                      <RegistrationStepInfo
                        click={handleSubmit}
                        formikProps={formikProps}
                        nameValue={values.name}
                        websiteValue={values.domain}
                        industryValue={values.industry}
                        companySizeValue={values.companySize}
                        organizationTypeValue={values.organizationType}
                        locationValue={values.location}
                        imageValue={values.image}
                        imageUrlOrganizationDetails={orgDetails?.imageUrl}
                        setHasNewImage={setHasNewImage}
                        orgDetailsWebsite={orgDetails?.website}
                        name="name"
                        domainName="domain"
                        industryName="industry"
                        companySizeName="companySize"
                        locationName="location"
                        isFormValid={isFormValid}
                      />
                    );
                    break;
                  case 2:
                    const { errors: stepTwoErrors } = formikProps;
                    renderPage = (
                      <RegistrationStepMembers
                        stepTwoErrors={stepTwoErrors}
                        errors={errors}
                        users={values.invites}
                        formikProps={formikProps}
                        click={handleSubmit}
                        backClick={() => pageHandler(1)}
                      />
                    );
                    break;
                  default:
                    break;
                }
                return <Form className={styles.formContainer}>{renderPage}</Form>;
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  if (isCreating) {
    if (createError != null) {
      content = (
        <>
          {createError.map((err) => {
            return (
              <EmptyState
                componentClass="positionCenter"
                contentWrapperClass="col_reverse"
                image={image}
                description={err.message}
                content={emptyStateContent}
              />
            );
          })}
        </>
      );
    } else {
      content = <PreparingEnvironment topLabelText="Creating your organization" />;
    }
  }

  return <>{content}</>;
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.user.email,
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOrganization);
