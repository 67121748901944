import React from "react";

// Components
import Modal from "../../components/UI/Modal/Modal";
import TestResultsMessage from "./EmailAccountSettings/TestResultsMessage/TestResultsMessage";
import AlertLineIcon from "remixicon-react/AlertLineIcon";

interface GenericErrorModalProps {
  handleClose: () => void;
  showModal: boolean;
}

const GenericErrorModal: React.FC<GenericErrorModalProps> = (props) => {
  let messageLabel: JSX.Element | null = null;
  messageLabel = <b>Something went wrong while connecting your email account. Please try again!</b>;
  let messageDetails: JSX.Element | null = null;
  messageDetails = (
    <div>
      <p>
        For more details on email account connectivity, check out <br />
        <a href="https://intercom.help/outbase/en/collections/3718303-email-accounts" target="_blank">
          Email Accounts User documentation.
        </a>
      </p>

      <p>
        Or contact our Support Team via Live chat or at <a href="mailto:support@outbase.com">support@outbase.com</a>
      </p>
    </div>
  );
  let sendContent: JSX.Element | null = null;
  sendContent = (
    <TestResultsMessage icon={<AlertLineIcon color="var(--danger-color)" />} messageLabel={messageLabel} messageDetails={messageDetails} />
  );
  return (
    <Modal
      title="Email account connection"
      handleSave={props.handleClose}
      oneButton={true}
      show={props.showModal}
      saveButtonText="OK"
      btnType="submit"
    >
      {sendContent}
    </Modal>
  );
};

export default GenericErrorModal;