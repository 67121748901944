import React from "react";

const IconFilePdf = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667Z"
      fill="#C11E07"
    />
    <path
      d="M23.9667 7.99999C23.9333 7.86666 23.9 7.76666 23.8333 7.66666L17.3333 0.399994V7.99999H23.9667Z"
      fill="#C11E07"
    />
    <path
      d="M2 13.6667H22V30H2V13.6667ZM2.2 2.13333H3.7C4.56667 2.13333 5.16667 2.8 5.16667 3.76666C5.16667 4.73333 4.6 5.43333 3.7 5.43333H3.3V6.9H2.2V2.13333V2.13333ZM3.5 4.46666C3.83333 4.46666 4.03333 4.16666 4.03333 3.76666C4.03333 3.36666 3.8 3.1 3.5 3.1H3.3V4.46666H3.5ZM6.13333 2.13333H7.53333C8.76667 2.13333 9.5 3 9.5 4.5C9.5 6 8.76667 6.9 7.53333 6.9H6.13333V2.13333V2.13333ZM7.5 5.93333C8.06667 5.93333 8.36667 5.43333 8.36667 4.5C8.36667 3.56666 8.03333 3.1 7.5 3.1H7.23333V5.93333H7.5ZM10.5333 2.13333H12.9V3.1H11.6333V4.13333H12.6667V5.1H11.6333V6.9H10.5333V2.13333V2.13333Z"
      fill="white"
    />
    <path
      d="M11.1667 15.5127C10.4 15.5127 9.80001 16.146 9.80001 16.8793C9.80001 17.8127 10.3333 18.9793 10.8667 20.0793C10.4333 21.4127 9.96668 22.846 9.33334 24.046C8.06668 24.546 6.93334 24.9127 6.26668 25.4793L6.23334 25.5127C6.00001 25.7793 5.83334 26.1127 5.83334 26.4793C5.83334 27.246 6.46668 27.846 7.20001 27.846C7.56668 27.846 7.93334 27.7127 8.16668 27.446C8.16668 27.446 8.20001 27.446 8.20001 27.4127C8.70001 26.8127 9.30001 25.7127 9.83334 24.746C11.0333 24.2793 12.3333 23.7793 13.5333 23.4793C14.4333 24.2127 15.7333 24.6793 16.8 24.6793C17.5667 24.6793 18.1667 24.046 18.1667 23.3127C18.1667 22.546 17.5333 21.946 16.8 21.946C15.9333 21.946 14.7 22.246 13.7667 22.5793C13 21.846 12.3 20.946 11.7667 19.9793C12.1333 18.846 12.5667 17.746 12.5667 16.8793C12.5333 16.1127 11.9333 15.5127 11.1667 15.5127V15.5127ZM11.1667 16.3127C11.4667 16.3127 11.7 16.546 11.7 16.846C11.7 17.246 11.4667 18.0127 11.2333 18.8127C10.9 18.0127 10.6 17.246 10.6 16.846C10.6333 16.5793 10.8667 16.3127 11.1667 16.3127V16.3127ZM11.4 21.0127C11.8 21.6793 12.3 22.2793 12.8333 22.8127C12.0333 23.046 11.2333 23.3127 10.4333 23.6127C10.8333 22.7793 11.1333 21.8793 11.4 21.0127V21.0127ZM16.8 22.7793C17.1 22.7793 17.3333 23.0127 17.3333 23.3127C17.3333 23.6127 17.1 23.846 16.8 23.846C16.1667 23.846 15.3 23.5793 14.6 23.1793C15.4 22.9793 16.3 22.7793 16.8 22.7793V22.7793ZM8.63334 25.2127C8.23334 25.9127 7.86668 26.546 7.60001 26.8793C7.50001 26.9793 7.40001 27.0127 7.23334 27.0127C6.93334 27.0127 6.70001 26.7793 6.70001 26.4793C6.70001 26.346 6.76668 26.1793 6.83334 26.1127C7.13334 25.846 7.83334 25.546 8.63334 25.2127V25.2127Z"
      fill="#C11E07"
      stroke="#C11E07"
      strokeWidth="0.75"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconFilePdf;
