import { FC } from "react";
import Modal from "@components/UI/Modal/Modal";
import { useGetProspectPhoneNumberMutation } from "@api/prospectsApi";

interface Props {
  handleClose: () => void;
  handleError: () => void;
  handleNoCredits: () => void;
  handleOptedOut: () => void;
  handleSave: (phoneNumber: string | undefined, isLoading: boolean, creditsAvailable: boolean | undefined) => void;
  showModal: boolean;
  prospectId: number;
  creditsChargeAmountPhoneReveal: number;
}

const PhoneRevealModal: FC<Props> = (props) => {
  const { creditsChargeAmountPhoneReveal } = props;
  const [phoneNumberReveal, { isLoading }] = useGetProspectPhoneNumberMutation();

  const handleSave = async () => {
    try {
      const res = await phoneNumberReveal(props.prospectId).unwrap();

      if (res.isSuccess) {
        const phoneNumber = res.mobileNumber || res.phoneNumber;
        props.handleSave(phoneNumber, isLoading, res.creditsAvailable);
      } else {
        if (!res.creditsAvailable) {
          props.handleNoCredits();
        } else if (res.isOptedOut) {
          props.handleOptedOut();
        } else {
          props.handleError();
        }
      }
    } catch (error) {
      props.handleError();
    } finally {
      props.handleClose();
    }
  };

  return (
    <Modal
      title={`Phone number reveal`}
      handleClose={props.handleClose}
      handleSave={handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Reveal"
      btnType="submit"
    >
      <div>
        <p>Revealing this phone number will cost {creditsChargeAmountPhoneReveal} credits.</p>
      </div>
    </Modal>
  );
};

export default PhoneRevealModal;
