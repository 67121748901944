import React, { useEffect, useRef, ReactNode } from "react";

//Styles
import styles from "./Drawer.module.scss";

//Components
import Button from "../Button/Button";

interface DrawerProps {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  content: ReactNode;
  title: string;
  elementId?: string;
  showSaveButton?: boolean;
  drawerPanelClass?: string;
  showFooter?: boolean;
  disableCloseOnOutsideClick?: boolean;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  handleCloseDrawer,
  content,
  title,
  elementId,
  drawerPanelClass,
  showFooter = true,
  disableCloseOnOutsideClick = false,
}) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    handleCloseDrawer();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!disableCloseOnOutsideClick && drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
      handleCloseDrawer();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      handleCloseDrawer();
    }
  };

  useEffect(() => {
    const modifyListeners = (action: "addEventListener" | "removeEventListener") => {
      document[action]("mousedown", handleClickOutside as EventListener);
      document[action]("keydown", handleKeyDown as EventListener);
    };
    if (isOpen) {
      modifyListeners("addEventListener");
    } else {
      return () => modifyListeners("removeEventListener");
    }
  }, [isOpen]);

  return (
    <div
      {...(elementId ? { id: elementId } : {})}
      className={`${styles.drawerContainer} ${drawerPanelClass} ${isOpen ? styles.open : ""}`}
      ref={drawerRef}
    >
      <div className={styles.drawerHeader}>
        {title}
        <button onClick={handleCloseClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z" />
          </svg>
        </button>
      </div>
      <div className={styles.drawerContent}>{content}</div>
      {showFooter ? (
        <div className={styles.drawerFooter}>
          <Button
            {...(elementId ? { id: elementId + "Button" } : {})}
            variant="secondary"
            onClick={(event) => handleCloseClick(event as React.MouseEvent<HTMLButtonElement, MouseEvent>)}
            id="closeButton"
          >
            Close
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Drawer;
