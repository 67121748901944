export const FETCH_EMAIL_TEMPLATE_REQUEST = "FETCH_EMAIL_TEMPLATE_REQUEST";
export const FETCH_EMAIL_TEMPLATE_SUCCEEDED = "FETCH_EMAIL_TEMPLATE_SUCCEEDED";
export const FETCH_EMAIL_TEMPLATE_FAILED = "FETCH_EMAIL_TEMPLATE_FAILED";

export const FETCH_EMAIL_TEMPLATE_TYPES_REQUEST = "FETCH_EMAIL_TEMPLATE_TYPES_REQUEST";
export const FETCH_EMAIL_TEMPLATE_TYPES_SUCCEEDED = "FETCH_EMAIL_TEMPLATE_TYPES_SUCCEEDED";
export const FETCH_EMAIL_TEMPLATE_TYPES_FAILED = "FETCH_EMAIL_TEMPLATE_TYPES_FAILED";

export const FETCH_ORGANIZATION_CUSTOM_COLUMNS_REQUEST = "FETCH_ORGANIZATION_CUSTOM_COLUMNS_REQUEST";
export const FETCH_ORGANIZATION_CUSTOM_COLUMNS_SUCCEEDED = "FETCH_ORGANIZATION_CUSTOM_COLUMNS_SUCCEEDED";
export const FETCH_ORGANIZATION_CUSTOM_COLUMNS_FAILED = "FETCH_ORGANIZATION_CUSTOM_COLUMNS_FAILED";

export const SET_DICTIONARY_VALUES = "SET_DICTIONARY_VALUES";
///////// PURCHASE TEMPLATE ///////////////

export const SET_PURCHASE_TEMPLATE_TITLE = "SET_PURCHASE_TEMPLATE_TITLE";

export const FETCH_PURCHASE_TEMPLATE_REQUEST = "FETCH_PURCHASE_TEMPLATE_REQUEST";
export const FETCH_PURCHASE_TEMPLATE_SUCCEEDED = "FETCH_PURCHASE_TEMPLATE_SUCCEEDED";
export const FETCH_PURCHASE_TEMPLATE_FAILED = "FETCH_PURCHASE_TEMPLATE_FAILED";

export const FETCH_JOB_TITLE_LIST_REQUEST = "FETCH_JOB_TITLE_LIST_REQUEST";
export const FETCH_JOB_TITLE_LIST_SUCCEEDED = "FETCH_JOB_TITLE_LIST_SUCCEEDED";
export const FETCH_JOB_TITLE_LIST_FAILED = "FETCH_JOB_TITLE_LIST_FAILED";

export const FETCH_INITIAL_EMAIL_TEMPLATES_REQUEST = "FETCH_INITIAL_EMAIL_TEMPLATES_REQUEST";
export const FETCH_INITIAL_EMAIL_TEMPLATES_SUCCEEDED = "FETCH_INITIAL_EMAIL_TEMPLATES_SUCCEEDED";
export const FETCH_INITIAL_EMAIL_TEMPLATES_FAILED = "FETCH_INITIAL_EMAIL_TEMPLATES_FAILED";

export const FETCH_PURCHASE_TEMPLATE_PRICE_REQUEST = "FETCH_PURCHASE_TEMPLATE_PRICE_REQUEST";
export const FETCH_PURCHASE_TEMPLATE_PRICE_SUCCEEDED = "FETCH_PURCHASE_TEMPLATE_PRICE_SUCCEEDED";
export const FETCH_PURCHASE_TEMPLATE_PRICE_FAILED = "FETCH_PURCHASE_TEMPLATE_PRICE_FAILED";
