import { useState, useCallback } from "react";

export interface UseModal<T> {
  isVisible: boolean;
  show: (data?: T | null) => void;
  hide: () => void;
  toggle: (data?: T | null) => void;
  set: (value: boolean, data?: T | null) => void;
  data: T | null;
  setData: React.Dispatch<React.SetStateAction<T | null>>;
}

const useModal = <T>(initialIsVisible: boolean | null = false): UseModal<T> => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const [data, setData] = useState<T | null>(null);

  const show = useCallback((data: T | null = null) => {
    setData(data);
    setIsVisible(true);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
    setData(null); // clear the extra data when hiding the modal
  }, []);

  const toggle = useCallback(
    (data: T | null = null) => {
      if (isVisible) {
        setData(null); // clear the extra data when hiding the modal
      } else {
        setData(data);
      }

      setIsVisible(!isVisible);
    },
    [isVisible]
  );

  const set = useCallback((value: boolean, data: T | null = null) => {
    if (!value) {
      setData(null); // clear the extra data when hiding the modal
    } else {
      setData(data);
    }

    setIsVisible(value);
  }, []);

  return {
    isVisible,
    show,
    hide,
    toggle,
    set,
    data,
    setData,
  };
};

export default useModal;
