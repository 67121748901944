import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  listOfCampaigns: [],
  prospectDetails: {},
  listOfResponses: [],
  params: {
    filter: [],
  },
  statuses: {},
  isLoading: false,
  isLoaded: false,
  isTimelineLoading: false,
  isTimelineLoaded: false,
  error: "",
};

//#region FETCH
const fetchListOfCampaignsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchListCampaignsSuccess = (state, action) => {
  const newState = { ...state };
  let list = [...state.listOfCampaigns];
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listOfCampaigns = list;
  return newState;
};

const fetchListOfCampaignsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchProspectDetailsRequest = (state, action) => {
  return {
    ...state,
    prospectDetails: {},
    isLoading: true,
    isLoaded: false,
  };
};

const fetchProspectDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let prospect = { ...state.prospectDetails };
  prospect = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.prospectDetails = prospect;
  return newState;
};

const fetchProspectDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchTimelineResponsesRequest = (state, action) => {
  return {
    ...state,
    isTimelineLoading: true,
    isTimelineLoaded: false,
  };
};

const fetchTimelineResponsesSuccess = (state, action) => {
  const newState = { ...state };
  let responses = [...state.listOfResponses];
  responses = action.result;
  newState.isTimelineLoaded = true;
  newState.isTimelineLoading = false;
  newState.listOfResponses = responses;
  return newState;
};

const fetchTimelineResponsesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isTimelineLoading = false;
  newState.isTimelineLoaded = true;
  return newState;
};

const setFilter = (state, action) => {
  const newState = { ...state };
  const params = { ...newState.params };
  const newFilters = [...params.filter];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  newFilters.push({ type: action.filterType, value: action.filterValue });
  params.filter = newFilters;
  newState.params = params;
  return newState;
};

const clearFilter = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newFilters = [...state.params.filter];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  params.filter = newFilters;
  newState.params = params;
  return newState;
};

const fetchProspectStatuesesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchProspectStatuesesSuccess = (state, action) => {
  const newState = { ...state };
  let statuses = { ...state.statuses };
  statuses = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.statuses = statuses;
  return newState;
};

const fetchProspectStatuesesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST_OF_CAMPAIGNS_REQUEST:
      return fetchListOfCampaignsRequest(state, action);

    case actionTypes.FETCH_LIST_OF_CAMPAIGNS_SUCCEEDED:
      return fetchListCampaignsSuccess(state, action);

    case actionTypes.FETCH_LIST_OF_CAMPAIGNS_FAILED:
      return fetchListOfCampaignsFailed(state, action);

    case actionTypes.FETCH_PROSPECT_DETAILS_REQUEST:
      return fetchProspectDetailsRequest(state, action);

    case actionTypes.FETCH_PROSPECT_DETAILS_SUCCEEDED:
      return fetchProspectDetailsSuccess(state, action);

    case actionTypes.FETCH_PROSPECT_DETAILS_FAILED:
      return fetchProspectDetailsFailed(state, action);

    case actionTypes.FETCH_TIMELINE_RESPONSES_REQUEST:
      return fetchTimelineResponsesRequest(state, action);

    case actionTypes.FETCH_TIMELINE_RESPONSES_SUCCEEDED:
      return fetchTimelineResponsesSuccess(state, action);

    case actionTypes.FETCH_TIMELINE_RESPONSES_FAILED:
      return fetchTimelineResponsesFailed(state, action);

    case actionTypes.SET_FITLER:
      return setFilter(state, action);

    case actionTypes.CLEAR_FILTER:
      return clearFilter(state, action);

    case actionTypes.FETCH_PROSPECT_STATUSES_REQUEST:
      return fetchProspectStatuesesRequest(state, action);

    case actionTypes.FETCH_PROSPECT_STATUSES_SUCCEEDED:
      return fetchProspectStatuesesSuccess(state, action);

    case actionTypes.FETCH_PROSPECT_STATUSES_FAILED:
      return fetchProspectStatuesesFailed(state, action);

    default:
      break;
  }

  return state;
};

export default reducer;
