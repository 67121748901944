import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { toastMessages } from "../../helper/toastMessagesConstants";

//Components
import Modal from "../../components/UI/Modal/Modal";

const DeleteEmailTemplateForm = (props) => {
  const { listCampaigns, emailTemplateName, countActiveCampaigns } = props;
  const [showAllCampaigns, setShowAllCampaigns] = useState(false);

  const deleteEmailTemplate = () => {
    let data = { emailTemplateId: props.emailTemplateId };
    api(formUrl(API.emailTemplates.remove, data))
      .then((res) => {
        toast.success(toastMessages.emailTemplate.successDeleteTemplate);
        props.getEmailTemplates();
        props.handleClose();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  let mappedCampaigns = listCampaigns.map((cam, i) => {
    if (i < 5) {
      return <li>{cam}</li>;
    }
  });

  let mappedAllCampaigns = listCampaigns.map((cam, i) => {
    return <li>{cam}</li>;
  });

  return (
    <Modal
      title="Delete Email Template"
      handleClose={props.handleClose}
      handleSave={deleteEmailTemplate}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            {countActiveCampaigns === 0 ? (
              <p>Are you sure you want to delete this Email Template?</p>
            ) : (
              <>
                <p>
                  The email template {emailTemplateName} is used in {countActiveCampaigns} active campaigns. All campaigns where the
                  template is used will be set to Paused status.
                  {/* <p><span>{listCampaigns.join(", ")}</span></p> */}
                  <ul>{showAllCampaigns ? mappedAllCampaigns : mappedCampaigns}</ul>
                  {listCampaigns.length < 6 ? null : showAllCampaigns ? (
                    <span onClick={() => setShowAllCampaigns(false)}>show less</span>
                  ) : (
                    <span onClick={() => setShowAllCampaigns(true)}>show all</span>
                  )}
                </p>
                <p>Do you want to continue? </p>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DeleteEmailTemplateForm;
