const buildConfig = (paymentType, creditCardInfo, placeholderStyle) => ({
  // selector where the iframe will be included in the host's HTML (i.e. '#chargify-form')
  // optional if you have a `selector` on each and every field
  selector: "#chargify-form",

  // (i.e. '1a2cdsdn3lkn54lnlkn')
  publicKey: import.meta.env.VITE_CHARGIFY_PUBLIC_KEY,

  // form type (possible values: 'card' or 'bank')
  type: paymentType || "card",

  // points to your Chargify site
  serverHost: import.meta.env.VITE_CHARGIFY_SERVER_HOST,
  // Enable 3D secure
  threeDSecure: true,
  style: {
    field: {
      borderRadius: "5px",
      padding: "0 1px",
    },

    // `input` is the input HTML element
    input: {
      placeholder: placeholderStyle,
      color: "#2a3347",
    },

    // `label` is the label container
    label: {
      color: "#8a8a8a",
      fontSize: "12px",
      fontWeight: "normal",
    },

    // `message` is the invalid message container
    message: {
      color: "#ee3030",
      paddingTop: "2px",
      paddingBottom: "1px",
    },
  },
  fields: {
    firstName: {
      selector: "#chargify-form",
      label: "Card Holder Name",
      placeholder: creditCardInfo ? creditCardInfo.firstName : "John Doe",
      required: true,
      message: "This field is not valid. Please update it.",
      maxlength: "30",
      value: creditCardInfo ? creditCardInfo.firstName : null,
    },

    number: {
      selector: "#chargify-form",
      label: "Credit card number",
      placeholder: creditCardInfo ? creditCardInfo.number : "xxxx xxxx xxxx xxxx",
      message: "This field is not valid. Please update it.",
    },

    year: {
      selector: "#chargify-form",
      label: "Expiration Year",
      placeholder: "yyyy",
      message: "This field is not valid. Please update it.",
    },

    month: {
      selector: "#chargify-form",
      label: "Expiration Month",
      placeholder: "mm",
      message: "This field is not valid. Please update it.",
    },

    cvv: {
      selector: "#chargify-form",
      label: "CVV code",
      placeholder: "xxx - (three or four digit security code)",
      required: true,
      message: "This field is not valid. Please update it.",
    },
  },
});

export default buildConfig;
