import React, { useEffect, useState } from "react";

//Helper
import { purchaseTemplateStatusText, purchaseTemplateStatusVariant } from "../../../helper/enums/purchaseTemplateStatusEnum";

//Styles
import styles from "./RequestDetail.module.scss";

//Components
import OrderStatusCol from "../MyOrdersTemplates/OrderStatusCol/OrderStatusCol";
import AttachedDocument from "../CreatePurchaseTemplateForm/components/AttachedDocument/AttachedDocument";

const RequestDetail = (props) => {
  const { purchaseTemplate, creditCardInfo, billingInfo } = props;
  const {
    templateName,
    jobTitles,
    targetingLocation,
    industries,
    companySize,
    website,
    textKeyMessagingObjectives,
    textSellingPoints,
    notes,
    status,
    attachments,
  } = purchaseTemplate;

  const [listAttachments, setListAttachments] = useState(null);
  useEffect(() => {
    if (attachments != null) {
      let mapped = attachments.map((attachment, i) => {
        let fileExt = attachment?.name.split(".").pop();
        return (
          <li>
            <span className={styles.data}>
              <AttachedDocument imgStyle="mR10" type={fileExt} />
              {attachment?.name}
            </span>
          </li>
        );
      });
      setListAttachments(mapped);
    }
  }, [attachments]);

  return (
    <>
      <ul className={styles.detailsDataWrapper}>
        <li>
          <span className={styles.title}>Order status</span>
          <span className={styles.data}>
            <OrderStatusCol variant={purchaseTemplateStatusVariant[status]} status={purchaseTemplateStatusText[status]} />
          </span>
        </li>
        <li>
          <span className={styles.title}>Template Name:</span>
          <span className={styles.data}>{templateName}</span>
        </li>

        <li className={styles.titleList}>Contact Criteria</li>

        <li>
          <span className={styles.title}>Job Title:</span>
          <span className={styles.data}>{jobTitles?.join(", ")} </span>
        </li>
        {/* <li>
                    <span className={styles.title}>Department:</span>
                    <span className={styles.data}>IT, Tech</span>
                </li> */}
        <li>
          <span className={styles.title}>Contact Location:</span>
          <span className={styles.data}>{targetingLocation?.join(", ")}</span>
        </li>

        <li className={styles.titleList}>Company Criteria</li>

        <li>
          <span className={styles.title}>Industry:</span>
          <span className={styles.data}>{industries?.join(", ")}</span>
        </li>
        <li>
          <span className={styles.title}>Company Size:</span>
          <span className={styles.data}>{companySize?.join(", ")}</span>
        </li>

        <li className={styles.titleList}>Request Description</li>
        <li>
          <span className={styles.title}>Website:</span>
          <span className={styles.data}>{website}</span>
        </li>

        <li className="flex_column">
          <p className={styles.title}>Organization Information</p>
          <textarea readOnly value={textKeyMessagingObjectives} className={`${styles.textAreaReadOnly} textAreaReadOnly`}></textarea>
        </li>

        <li className="flex_column">
          <p className={styles.title}>Messaging Objectives</p>
          <textarea readOnly value={textSellingPoints} className={`${styles.textAreaReadOnly} textAreaReadOnly`}></textarea>
        </li>

        <li className="flex_column">
          <p className={styles.title}>Notes:</p>
          <textarea value={notes} readOnly className={`${styles.textAreaReadOnly} textAreaReadOnly`}></textarea>
        </li>

        {/* <li className={styles.titleList}>Payment details</li>

                <li>
                    <span className={styles.title}>Card holder name</span>
                    <span className={styles.data}>{creditCardInfo?.firstName}</span></li>

                <li>
                    <span className={styles.title}>Credit Card</span>
                    <span className={styles.data}>{creditCardInfo?.number}</span></li>
                <li>
                    <span className={styles.title}>Expiry Date</span>
                    <span className={styles.data}>
                        {creditCardInfo?.month != null ?
                            Moment.monthsShort("MMM", creditCardInfo?.month - 1) : null} {creditCardInfo?.year}
                    </span>
                </li>

                <li className={styles.titleList}>Payment details</li>

                <li>
                    <span className={styles.title}>Billing address</span>
                    <span className={styles.data}>{billingInfo?.address} {billingInfo?.city} {billingInfo?.country} {billingInfo?.zip}</span>
                </li> */}

        <li className={styles.titleList}>Attachments</li>
        {listAttachments}
      </ul>
    </>
  );
};

export default RequestDetail;
