import React, { useState, useEffect } from "react";
import api, { API } from "../../helper/api/api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../node_modules/react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import { addDays } from "date-fns";

//Helpers
import { dateToUTC } from "../../helper/dateHelper";

//Styles
import styles from "./SingleDatePicker.module.scss";

//Images & Icons
import ArrowLeftSFillIcon from "remixicon-react/ArrowLeftSFillIcon";
import ArrowRightSFillIcon from "remixicon-react/ArrowRightSFillIcon";

export const SingleDatePicker = (props) => {
  const { campaignId, emailAccounts, initialDateEvent, eventExists, campaignStartTime, timeFormat, onEventChange, onEventExistChange } =
    props;

  const [date, setDate] = useState(initialDateEvent);
  const [dateChanged, setDateChanged] = useState(initialDateEvent);
  var startCampaignTime = moment(campaignStartTime).format(timeFormat);

  useEffect(() => {
    if (initialDateEvent !== null && initialDateEvent !== dateChanged) {
      setDate(initialDateEvent);
      onEventExistChange(eventExists);
    }
  }, [initialDateEvent]);

  const changeDate = (newDate) => {
    var newDateToUTC = dateToUTC(new Date(newDate));
    setDate(newDateToUTC);
    let data = { campaignId: campaignId, emailAccounts: emailAccounts, date: newDateToUTC };
    var today = new Date();
    var todayTime = today.getHours() + ":" + today.getMinutes();
    if (data.date > today) {
      var futureEventExists = true;
      const futureEvent = {
        campaignId: data.campaignId,
        date: data.date,
      };
      onEventChange(futureEvent);
      onEventExistChange(futureEventExists);
    } else if (data.date === today && campaignStartTime > todayTime) {
      const todayEvent = {
        campaignId: data.campaignId,
        emailAccounts: data.emailAccounts,
        date: data.date,
      };
      onEventChange(todayEvent);
      onEventExistChange(todayEvent.eventExist);
    } else {
      api(API.calendar.getCalendarEventId, data)
        .then((res) => {
          var eventModel = res.data;
          onEventChange(eventModel);
          onEventExistChange(eventModel.eventExist);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const incrementDate = () => {
    var incrementedDate = addDays(new Date(date), +1);
    setDate(incrementedDate);
    changeDate(incrementedDate);
  };

  const decrementDate = () => {
    var decrementedDate = addDays(new Date(date), -1);
    setDate(decrementedDate);
    changeDate(decrementedDate);
  };

  return (
    <div className={`${styles.pickerComponentWrapper} singleDayPicker flex`}>
      <div className={`${styles.arrowWrapper} mR5 flex_center`} onClick={decrementDate}>
        <ArrowLeftSFillIcon className={styles.arrowStyle} />
      </div>
      <DatePicker
        id={props.id}
        selected={date != null && date != undefined ? new Date(date) : undefined}
        onChange={(val) => {
          changeDate(val);
        }}
        dateFormat={"dd MMM yyyy"}
        showTimeSelectOnly={undefined}
        timeIntervals={undefined}
        timeCaption={undefined}
        placeholderText={undefined}
        minTime={undefined}
        maxTime={undefined}
        className={`${styles.datePickerInput} ${props.inputDatePickerClass ?? ""}`}
      />
      <div className={`${styles.arrowWrapper} mL5 flex_center`} onClick={incrementDate}>
        <ArrowRightSFillIcon className={styles.arrowStyle} />
      </div>
    </div>
  );
};

export default SingleDatePicker;
