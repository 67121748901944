import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { subDays } from "date-fns";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";

//Redux
import { fetchSendingSchedule } from "../../../../store/SendingSchedule/actions/sendingShedule";
import { fetchSettings } from "../../../../store/OrganizationSettings/General/actions/general";
import { fetchGlobalSettings } from "../../../../store/global/actions/index";
import { clearInitiated } from "../../../../store/WorkflowSteps/actions/workflowSteps";
import {
  fetchEmailAccountsList,
  clearInitiated as clearInitiatedEmails,
} from "../../../../store/EmailAccounts/Global/actions/emailAccounts";

//Helpers
import api, { API } from "../../../../helper/api/api";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import { getTimeZoneOffset } from "../../../../helper/dateHelper";
import { dateToUTC } from "../../../../helper/dateHelper";
import useRouteParams from "@hooks/useRouteParams";

//Style
import styles from "./SendingSchedule.module.scss";

//Icons & Images
import CalendarLineIcon from "remixicon-react/Calendar2LineIcon";

//Components
import SaveChangesFooter from "../../../../components/SaveChangesFooter/SaveChangesFooter";
import CancelWarningForm from "../../../../components/CancelWarningForm/CancelWarningForm";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import FormikDatePicker from "../../../../components/UI/Formik/FormikDatePicker";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import FormikCheckbox from "../../../../components/UI/Formik/FormikCheckbox";
import ListDays from "./ListDays/ListDays";
import SelectMulti from "../../../../components/UI/Select/SelectMulti";
import ShowDateRangePicker from "../../../../components/DataTable/ShowDateRangePicker/ShowDateRangePicker";
import InfoIconTooltip from "../../../../components/UI/CustomTooltip/InfoIconTooltip";

const SendingSchedule = (props) => {
  const { schedule, clearInitiated, clearInitiatedEmails } = props;
  const { campaignId, organizationId } = useRouteParams();
  const [isDirty, setIsDirty] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);
  const [removePrompt, setRemovePrompt] = useState(false);
  const [pauseDates, setPauseDates] = useState({
    startDate: schedule.pauseDates != null ? new Date(schedule.pauseDates.startDate) : null,
    endDate: schedule.pauseDates != null ? new Date(schedule.pauseDates.endDate) : null,
  });

  const [pauseCountries, setPauseCountries] = useState("");
  const [blockNavigation, setBlockNavigation] = useState(true);

  const tooltipBody = <>Daily limit of the number of prospects per company that can be imported and engaged in the campaign.</>;

  const fetchAsyncEmails = async () => {
    await props.fetchEmailAccountsList(campaignId);
  };

  useEffect(() => {
    fetchAsyncEmails();
    props.fetchSettings();
    props.fetchGlobalSettings();
    props.fetchSendingSchedule(campaignId);
  }, []);

  useEffect(() => {
    if (schedule.targetedCountries != null && props.globalSettings.countries != null) {
      handleSettingPausedCountries(schedule.targetedCountries);
    }
  }, [schedule.targetedCountries, props.globalSettings.countries]);

  useEffect(() => {
    return () => {
      clearInitiated();
      clearInitiatedEmails();
    };
  }, []);

  const [showCancelWarningModal, setShowCancelWarningModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const history = useHistory();
  const handleCancelWarningClose = () => {
    setShowCancelWarningModal(false);
  };

  const updateChanges = async () => {
    setRemovePrompt(true);
    setHasChanges(false);
    await props.fetchSendingSchedule(campaignId);
    props.handleCloseDrawer(true);
  };

  const handleCancelWarningSave = async () => {
    props.handleCloseDrawer(false);
    setShowCancelWarningModal(false);
    setIsDirty(false);
    setRemovePrompt(true);
    if (nextLocation) {
      history.push(nextLocation.pathname);
    }
  };

  let form = null;

  const handleCancel = () => {
    if (isDirty) {
      setShowCancelWarningModal(true);
      props.setDrawerOpen(true);
    } else {
      props.setDrawerOpen(false);
      setShowCancelWarningModal(false);
    }
  };

  const filterDate = (obj) => {
    setPauseDates(obj);
  };

  const handleNavigation = (location) => {
    if (isDirty) {
      setNextLocation(location);
      setShowCancelWarningModal(true);
      return false;
    }
    return true;
  };

  const handleSettingPausedCountries = (arrayIds) => {
    let countriesNames = props?.globalSettings?.countries.filter((c) => arrayIds.includes(c.value)).map((co) => co.label);

    setPauseCountries(countriesNames.join(", "));
  };

  if (props.isLoading || props.isLoadingSettings || props.globalSettingsIsLoading || props.isLoadingEmailAccounts) {
    form = <Spinner spinnerClass="absolute transform-y-50" />;
  }

  if (props.isLoaded && props.isLoadedSettings && props.globalSettingsIsLoaded && props.isLoadedEmailAccounts) {
    let dateOptions = {
      onChange: (c) => filterDate(c),
      showSelectionPreview: true,
      key: "selection",
      moveRangeOnFirstSelection: false,
      months: 2,
      direction: "horizontal",
      editableDateInputs: false,
      startDate: schedule.pauseDates != null ? new Date(schedule.pauseDates.startDate) : new Date(subDays(Date.now(), 30)),
      endDate: schedule.pauseDates != null ? new Date(schedule.pauseDates.endDate) : new Date(Date.now()),
      appliedStartDate: schedule.pauseDates != null ? new Date(schedule.pauseDates.startDate) : null,
      appliedEndDate: schedule.pauseDates != null ? new Date(schedule.pauseDates.endDate) : null,
      onSubmit: false,
      inputRanges: [],
    };

    let longDate = schedule.startDate === null ? new Date() : new Date(schedule.startDate);
    let date = new Date(longDate.toDateString());
    form = (
      <Formik
        initialValues={{
          id: campaignId,
          prospectsPerDay: schedule.prospectsPerDay ? schedule.prospectsPerDay : "0",
          maxProspectsPerCompany: schedule.maxProspectsPerCompany ? schedule.maxProspectsPerCompany : "",
          startDate: longDate,
          timeZone: schedule.timeZone ? schedule.timeZone : "",
          publicHolidaysExcluded: schedule.publicHolidaysExcluded ? schedule.publicHolidaysExcluded : false,
          emailAccountCountries: schedule.emailAccountCountries ? schedule.emailAccountCountries : "",
          targetedCountries: schedule.targetedCountries ? schedule.targetedCountries : "",
          schedulePause: schedule.pauseDates != null ? true : false,
          utcOffset: 0,
          monday: schedule.monday
            ? schedule.monday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
          tuesday: schedule.tuesday
            ? schedule.tuesday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
          wednesday: schedule.wednesday
            ? schedule.wednesday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
          thursday: schedule.thursday
            ? schedule.thursday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
          friday: schedule.friday
            ? schedule.friday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
          saturday: schedule.saturday
            ? schedule.saturday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
          sunday: schedule.sunday
            ? schedule.sunday
            : {
                enabled: false,
                day: "",
                from: "",
                to: "",
              },
        }}
        validationSchema={Yup.object({
          prospectsPerDay: Yup.number("Enter valid Number").positive("Enter").min(0).integer("enter valid Number").required("Required"),
          maxProspectsPerCompany: Yup.number("Enter valid Number").positive("Enter").integer("enter valid Number").required("Required"),
          startDate: Yup.date().required("Required"),
          timeZone: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          setIsDirty(false);
          let mondayEnabled = values.monday.enabled === true;
          let tuesdayEnabled = values.tuesday.enabled === true;
          let wednesdayEnabled = values.wednesday.enabled === true;
          let thursdayEnabled = values.thursday.enabled === true;
          let fridayEnabled = values.friday.enabled === true;
          let saturdayEnabled = values.saturday.enabled === true;
          let sundayEnabled = values.sunday.enabled === true;
          let validPauseDates = true;

          if (values.schedulePause) {
            if (!pauseDates.startDate && !pauseDates.endDate) {
              validPauseDates = false;
              actions.setFieldError("schedulePause", "You must select dates for pause");
            }
          }

          setBlockNavigation(false);
          if (
            (mondayEnabled || tuesdayEnabled || wednesdayEnabled || thursdayEnabled || fridayEnabled || saturdayEnabled || sundayEnabled) &&
            validPauseDates
          ) {
            const data = { ...values };

            let offSet = getTimeZoneOffset();
            data.utcOffset = offSet;

            data.startDate = dateToUTC(data.startDate);

            if (data.schedulePause) {
              if (pauseDates.startDate && pauseDates.endDate) {
                pauseDates.startDate = dateToUTC(pauseDates.startDate);
                pauseDates.endDate = dateToUTC(pauseDates.endDate);
                data.pauseDates = pauseDates;
              }
            }

            api(API.sendingSchedule.create, data)
              .then((response) => {
                toast.success(toastMessages.sendingSchedule.successCreateEdit);
                setShowCancelWarningModal(false);
                updateChanges();
                // handleCancel();
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);
                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                } else {
                  toast.error(error.message);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else if (validPauseDates) {
            actions.setFieldError("wednesday.from", "You must enable at least one day for sending");
          }
        }}
      >
        {(formikProps) => {
          const { values, errors, setFieldValue, handleChange } = formikProps;
          return (
            <Form>
              <Prompt when={isDirty} message={(location) => handleNavigation(location)} />

              <div className={styles.contentWrapper}>
                <div className={styles.rightContent}>
                  <div className={styles.contentBody}>
                    <div id="dateTimeZone" className={styles.formBody}>
                      <div id="startDate" className={styles.formGroup}>
                        <label>Start date:</label>
                        <div className="relative">
                          <FormikDatePicker
                            {...props}
                            formgroupclass={`${styles.dateForm}`}
                            datePickerClass={styles.formInput}
                            name="startDate"
                            selected={date}
                            value={values.startDate}
                            dateFormat="dd MMM yyyy"
                            minDate={moment().toDate()}
                            setIsDirty={setIsDirty}
                            isDirty={isDirty}
                          />
                          <CalendarLineIcon className={styles.calendarIcon} size={16} />
                        </div>
                      </div>
                      <div id="timeZone" className={styles.formGroup}>
                        <label>Time Zone:</label>
                        <FormikSelect
                          inputGroupClass={styles.countriesField}
                          formgroupClass={styles.timeForm}
                          name="timeZone"
                          options={props.globalSettings.timeZones}
                          value={values.timeZone}
                          isSearchable={true}
                          onChange={(value) => {
                            setFieldValue("timeZone", value);
                            setIsDirty(true);
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.listDays}>
                      <ListDays
                        {...props}
                        errors={errors}
                        formikProps={formikProps}
                        setIsDirty={setIsDirty}
                        isDirty={isDirty}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <div className={styles.formGroupCheckbox}>
                      <FormikCheckbox
                        label="Schedule Pause"
                        name="schedulePause"
                        checked={values.schedulePause}
                        onChange={(value) => {
                          setFieldValue("schedulePause", value);
                          setIsDirty(true);
                        }}
                      />
                      {values.schedulePause ? (
                        <div className={`${styles.countriesSelectWrapper} ${styles.heightWrapper} mB10`}>
                          <ShowDateRangePicker {...dateOptions} variant="styleSidebarExpandedS" />
                        </div>
                      ) : null}
                    </div>

                    <div className={styles.formBody}>
                      <FormikCheckbox
                        label="Don't send on public holidays"
                        name="publicHolidaysExcluded"
                        checked={values.publicHolidaysExcluded}
                        labelstyle="mB5"
                      />
                      <div className={styles.countriesSelectWrapper}>
                        {values.publicHolidaysExcluded ? (
                          <div className={styles.countriesSelect}>
                            <SelectMulti
                              options={props.globalSettings.countries}
                              name={"targetedCountries"}
                              value={values.targetedCountries}
                              placeholder="Select Countries..."
                              displayName="Countries"
                              isMulti={true}
                              isSearchable={true}
                              onChange={(event) => {
                                setFieldValue("targetedCountries", event);
                                handleSettingPausedCountries(event);
                                setIsDirty(true);
                              }}
                              id="countriesList"
                            />
                          </div>
                        ) : null}
                        <div className="mT10">
                          {values.publicHolidaysExcluded ? (
                            <>
                              <label className={styles.agentsLabel}>This campaign observes holidays from these countries</label>
                              <div className={`${styles.inputs} flex`}>
                                <span className={styles.selectedCountry}>
                                  <b>{pauseCountries}</b>
                                </span>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className={styles.formBody}>
                      <p className={styles.titleSection}>Sending volumes</p>
                      <div id="perDay" className={styles.formGroup}>
                        <div className="flex">
                          {" "}
                          <label>Maximum prospects per company </label>
                          <InfoIconTooltip placement="top" tooltipBody={tooltipBody} disableStartTour />
                        </div>

                        <div className="flex align-center">
                          <FormikInput
                            formgroupclass={styles.formInput}
                            type="number"
                            name="maxProspectsPerCompany"
                            inputclass={styles.setValueInput}
                            value={values.maxProspectsPerCompany}
                            ariaLabel="Maximum Prospects Per Company"
                            onBlur={(e) => {
                              handleChange(e, setFieldValue, "maxProspectsPerCompany");
                              setIsDirty(true);
                            }}
                          />
                          <label>per day</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <SaveChangesFooter
                cancelClick={() => {
                  handleCancel();
                }}
                cancelButtonText="Back"
                saveButtonText="Save"
                footerClassName="p0"
              />
              <CancelWarningForm
                showModal={showCancelWarningModal}
                handleClose={handleCancelWarningClose}
                handleSave={handleCancelWarningSave}
                bodyMessageContent="You are about to leave the Schedule settings page. Any unsaved changes will be discarded."
                closeButtonText="Go back"
                saveButtonText="Continue"
              />
            </Form>
          );
        }}
      </Formik>
    );
  }

  return <>{form}</>;
};

const mapStateToProps = (state) => {
  return {
    listEmailAccounts: state.globalEmailAccounts.listEmailAcconts,
    isLoadingEmailAccounts: state.globalEmailAccounts.isLoading,
    isLoadedEmailAccounts: state.globalEmailAccounts.isLoaded,
    isLoading: state.sendingSchedule.isLoading,
    isLoaded: state.sendingSchedule.isLoaded,
    error: state.sendingSchedule.error,
    schedule: state.sendingSchedule.sendingSchedule,
    settings: state.organizationSettingsGeneral.settings,
    isLoadingSettings: state.organizationSettingsGeneral.isLoading,
    isLoadedSettings: state.organizationSettingsGeneral.isLoaded,
    globalSettings: state.global.globalSettings,
    globalSettingsIsLoading: state.global.isLoading,
    globalSettingsIsLoaded: state.global.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSendingSchedule: (campaignId) => dispatch(fetchSendingSchedule(campaignId)),
    fetchSettings: () => dispatch(fetchSettings()),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
    clearInitiated: () => dispatch(clearInitiated()),
    fetchEmailAccountsList: (val) => dispatch(fetchEmailAccountsList(val)),
    clearInitiatedEmails: () => dispatch(clearInitiatedEmails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendingSchedule);
