import React from "react";
import svg from "./WarningIcon.svg";

const WarningIcon = (props) => (
  <svg className={props.imgStyle} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.4641 0.448038L15.2118 12.1356C15.4075 12.4744 15.2914 12.9076 14.9526 13.1031C14.8449 13.1653 14.7228 13.1981 14.5984 13.1981H1.10286C0.711659 13.1981 0.394531 12.8809 0.394531 12.4897C0.394531 12.3653 0.427256 12.2432 0.489427 12.1356L7.2372 0.448038C7.43284 0.109242 7.86599 -0.00683264 8.20478 0.188767C8.31252 0.250937 8.40191 0.340357 8.4641 0.448038ZM7.14228 9.65639V11.0731H8.55895V9.65639H7.14228ZM7.14228 4.69804V8.23973H8.55895V4.69804H7.14228Z" fill="#FAAC14" />
  </svg>
);

export default WarningIcon;
