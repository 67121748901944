import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "../../../components/UI/Modal/Modal.module.scss";
import classes from "./TagsInput.module.scss";

//Icons
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import IconIncludeValue from "../../../assets/Icons/IconIncludeValue/IconIncludeValue";
import IconExcludeValue from "../../../assets/Icons/IconExcludeValue/IconExcludeValue";

const TagsInput = ({ tags, content, addTags, removeTags, allowIncludeExclude, includeOrExcludeTags, type, id, placeholder, error }) => {
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      addTags(event);
    }
  };

  const handleRemove = (index) => {
    removeTags(index);
  };

  const handleIncludeOrExclude = (value) => {
    includeOrExcludeTags(value);
  };

  const generateListItem = (item, tag, index) => {
    const className = item.included === undefined ? classes["includedValue"] : classes[item.included ? "includedValue" : "excludedValue"];

    return (
      <li key={tag} id={tag.replaceAll(" ", "")} className={className}>
        <OverlayTrigger
          key={"tag-pill"}
          placement={"bottom"}
          overlay={
            <Popover id={`tag-pill-tooltip`}>
              <Popover.Body>{tag}</Popover.Body>
            </Popover>
          }
        >
          <div className=" textOverflow flex">
            {allowIncludeExclude && item.included != undefined && (
              <div className={classes.includeExcludeOption} onClick={() => handleIncludeOrExclude(item.value)}>
                {item.included === true && <IconExcludeValue />}
                {item.included === false && <IconIncludeValue />}
              </div>
            )}
            <span className={styles.pillsLabel}>{tag}</span>
          </div>
        </OverlayTrigger>
        <span className={classes.deleteTag} onClick={() => handleRemove(index)}>
          <CloseLineIcon />
        </span>
      </li>
    );
  };

  return (
    <div>
      <span className={classes.labelMessage}>{content}</span>
      <div className={`${styles.ulModalWrapper}`}>
        <input className={styles.modal_input} type={type} onKeyUp={handleKeyUp} id={id || undefined} placeholder={placeholder} />
        <div className={classes.error}>{error}</div>

        {allowIncludeExclude && <label className="mT10">Included</label>}
        <ul className="flex">
          {tags &&
            tags
              .filter((x) => x.included === undefined || x.included)
              .map((item, index) => {
                const tag = allowIncludeExclude ? item.value : item;
                return generateListItem(item, tag, index);
              })}
        </ul>
        {allowIncludeExclude && <label className="mT10">Excluded</label>}
        <ul className="flex">
          {tags &&
            tags
              .filter((x) => x.included !== undefined && !x.included)
              .map((item, index) => {
                const tag = allowIncludeExclude ? item.value : item;
                return generateListItem(item, tag, index);
              })}
        </ul>
      </div>
    </div>
  );
};

export default TagsInput;
