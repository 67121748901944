export const tableColumns = [
  {
    label: "Full Name",
    field: "fullName",
    sort: false,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Email",
    field: "email",
    sort: false,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Job Title",
    field: "jobTitle",
    sort: false,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Prospect Country",
    field: "country",
    sort: false,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Company",
    field: "company",
    sort: false,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Industry",
    field: "industry",
    sort: false,
    width: 150,
    showTooltip: true,
  },
  {
    label: "Company Size",
    field: "companySize",
    sort: false,
    width: 150,
  },
  {
    label: "Date Created",
    field: "dateCreated",
    type: "date",
    sort: false,
    width: 150,
  },
  {
    label: "Campaign Name",
    field: "campaignName",
    sort: false,
    width: 150,
    showTooltip: true,
  },
];
