import React from "react";
import { connect } from "react-redux";

//Hooks
import { useOrganizationId } from "../../helper/hooks/useOrganizationId";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { permissionHelper } from "../../helper/permissionHelper";

//Style
import styles from "./ComplianceSupportCenter.module.scss";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import { GDPRSamplesDocuments } from "./components/GDPRSamplesDocuments";
import UnsubscribeSettings from "./components/UnsubscribeSettings";
import RTE from "./components/RTE/RTE";
import Permission from "../../components/auth/Permission";
import Tabs from "../../components/UI/Tabs/Tabs";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import QuestionsAndAnswers from "./components/QuestionsAndAnswers";

const ComplianceSupportCenter = (props) => {
  const organizationId = useOrganizationId();

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Resources and Q&As to help you make sure your campaigns are compliant."}
      href={"https://help.outbase.com/user-guide/compliance"}
      linkText="Read More"
      buttonId="startComplianceTour"
      disableStartTourButton
    />
  );

  let tabs = [];
  if (permissionHelper(props.userRole, PERMISSIONS.compliance.questionsAndAnswers.view)) {
    tabs.push({
      link: "questions-and-answers",
      component: <QuestionsAndAnswers />,
      title: "Compliance Q&A's",
    });
  }

  if (permissionHelper(props.userRole, PERMISSIONS.compliance.rightToErase.view)) {
    tabs.push({
      link: "sar",
      component: <RTE />,
      title: "Data Export & Removal",
    });
  }

  if (permissionHelper(props.userRole, PERMISSIONS.compliance.unsubscribeSettings.view)) {
    tabs.push({
      link: "unsubscribe-settings",
      component: <UnsubscribeSettings />,
      title: "Unsubscribe Settings",
    });
  }

  let config = <Tabs tabContentClass={styles.complianceTabs} {...props} tabs={tabs} />;

  if (props.location.pathname === `/${organizationId}/compliance`)
    props.history.push(`/${organizationId}/compliance/questions-and-answers`);

  return (
    <Permission has={PERMISSIONS.compliance.view}>
      <PageContentWrapper>
        <HeaderTitle title="Compliance Support Center" icon={icon} />
        <div className={styles.contentWrapper}>
          <div className={styles.tabsContent}>{config}</div>
          <div className={styles.samplesDocument}>
            <GDPRSamplesDocuments />
          </div>
        </div>
      </PageContentWrapper>
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.user.organization.role,
  };
};

export default connect(mapStateToProps)(ComplianceSupportCenter);

// export default ComplianceSupportCenter;
