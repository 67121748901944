import React from "react";

//Style
import "./SkeletonTwoRowsCard.scss";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonTwoRowsCard = (props) => {
  return (
    <div className="skeleton-wrapper twoRowCard">
      <SkeletonElement type="text-26 w-6" />
      <SkeletonElement type="text-8" />
      <Shimmer />
    </div>
  );
};

export default SkeletonTwoRowsCard;
