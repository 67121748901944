import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Moment from "moment";
import _ from "lodash";

//Redux
import {
  fetchProspects,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
  phoneFilterValues,
} from "../../store/Prospects/Table/actions/prospects";
import { fetchCompaniesListAutoComplete } from "../../store/Companies/Global/actions/companies";
import { fetchResponseTypes, fetchStatusCount, fetchSenderEmailAccounts } from "../../store/Prospects/Global/actions/prospects";

//Helpers
import api, { API } from "@helper/api/api";
import { fileDownload } from "@helper/fileDownloadHelper";
import { filterData, getFilterValue, filtersToQueryString, filterSingle } from "@helper/tableConstants";
import { useQueryTableFilters } from "@helper/hooks/useQueryTableFilters";
import { useOrganizationId } from "@helper/hooks/useOrganizationId";
import { PERMISSIONS } from "@helper/permissionConstants";
import { useOrgDateFormat } from "@helper/hooks/useOrgDateFormat";
import { toastMessages } from "@helper/toastMessagesConstants";
import useSelectList from "@helper/hooks/query/useSelectList";
import { SelectListEnum } from "@helper/hooks/query/SelectListEnum";

//Images & Icons
import EmptyAudiencesList from "../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import UserSearchLineIcon from "remixicon-react/UserSearchLineIcon";

// Style
import styles from "./Prospects.module.scss";

// Components
import { columns } from "./columns";
import Table from "@components/DataTable/Table";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import StatusCountRow from "@components/DataTable/StatusCountRow/StatusCountRow";
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import SkeletonTableFilterRow from "@components/UI/Skeletons/components/SkeletonTableFilterRow/SkeletonTableFilterRow";
import SkeletonTable from "@components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import SkeletonTwoRowsCard from "@components/UI/Skeletons/components/SkeletonTwoRowsCard/SkeletonTwoRowsCard";
import EmptyState from "@components/UI/EmptyState/EmptyState";
import Button from "@components/UI/Button/Button";
import Permission from "@components/auth/Permission";
import TableOptionsRow from "@components/DataTable/TableOptionsRow/TableOptionsRow";
import InfoIconTooltip from "@components/UI/CustomTooltip/InfoIconTooltip";
import ExportProspectsWarningForm from "@components/ExportProspectsWarningForm/ExportProspectsWarningForm";
import PageContentWrapper from "@components/PageContentWrapper/PageContentWrapper";
import useModal from "@components/UI/Modal/useModal";
import ManuallyCategorizeProspectModal from "./Modal/ManuallyCategorizeProspectModal";
import PhoneRevealModal from "./Modal/PhoneRevealModal";
import NoCreditsModal from "./Modal/NoCreditsModal";
import NoResponseModal from "./Modal/NoResponseModal";
import UserOptedOutModal from "./Modal/UserOptedOutModal";
import ProspectRow from "./ProspectRow/ProspectRow";
import UpgradeToPaidPlanModal from "./Modal/UpgradeToPaidPlanModal";

const Prospects = (props) => {
  const revealPhoneModal = useModal();
  const noCreditsModal = useModal();
  const noResponseModal = useModal();
  const userOptedOutModal = useModal();
  const upgradeToPaidPlanModal = useModal();

  let dateFormat = useOrgDateFormat();
  const { param, rows, fetchProspects, isInitiated, initiate, clearInitiated, plan, creditsChargeAmountPhoneReveal } = props;
  let image = <EmptyAudiencesList />;
  const organizationId = useOrganizationId();
  let filterTypes = ["Campaign", "Companies", "ResponseType", "Status"];
  const [tableRows, setTableRows] = useState([]);
  const categorizeModal = useModal();

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  let state = useQueryTableFilters(filterTypes);
  const [listCompaniesAutoComplete, setListCompaniesAutoComplete] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  const [activeStatus, setActiveStatus] = useState(null);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isRevealPhoneLoading, setIsRevealPhoneLoading] = useState(false);

  const handlePhoneReveal = (phoneNumber, isLoading, creditsAvailable) => {
    if (!isLoading) {
      setIsRevealPhoneLoading(false);
      fetchProspects(param);
    }
  };

  const handleWarningClose = () => {
    setShowWarningModal(false);
  };
  const handleWarningSave = async () => {
    setShowWarningModal(false);
  };

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Details of all prospects added from lists to your campaigns."}
      href={"https://help.outbase.com/user-guide/all-prospects/prospects"}
      linkText="Read More"
      disableStartTourButton
    />
  );

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
    };
    initiate(updateState);
  };

  const exportProspectsData = () => {
    let isExport = true;
    let exportParam = {
      filter: [...param.filter, { type: "IsExport", value: [isExport] }],
    };
    let totalCountProspects = props.totalCount;
    if (totalCountProspects > 50000) {
      setExportButtonDisabled(false);
      setShowWarningModal(true);
    } else {
      let chColumns = dataTableColumns.map((column) => column.field);
      chColumns.push("prospectActivityLink");
      let data = {
        chosenColumns: chColumns,
        param: exportParam,
      };
      api(API.prospects.exportProspectsData, data, "blob")
        .then((res) => {
          fileDownload("_allProspects.csv", res.data);
          setExportButtonDisabled(false);
          toast.success(toastMessages.prospects.exportFileFinishedDownloading);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    props.fetchResponseTypes();
    props.fetchSenderEmailAccounts();

    if (state.params && state.params.filter && state.params.filter.length > 0) {
      state.params.filter.map((filter) => {
        if (filter.type === "Companies") {
          searchCompanies("", filter.value);
        }
        if (filter.type === "Status") {
          setActiveStatus(parseInt(filter.value));
        }
      });
    }

    initiate(state);
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchProspects(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    let filters = [...param.filter];
    let exists = filters.find((x) => x.type === "Campaign") || {};
    let statuses = [];

    if (exists.type !== undefined) {
      statuses = [...exists.value];
    }

    let data = {
      campaignId: statuses,
      isFromCampaign: false,
    };
    props.fetchStatusCount(data);
  }, [param]);

  useEffect(() => {
    const rowsTemp = rows.map((row) =>
      ProspectRow({
        row,
        organizationId,
        setIsRevealPhoneLoading,
        revealPhoneModal,
        isRevealPhoneLoading,
        creditsChargeAmountPhoneReveal,
        categorizeModal,
        dateFormat,
        plan,
        upgradeToPaidPlanModal,
      })
    );
    setTableRows(rowsTemp);
  }, [rows]);

  useEffect(() => {
    setListCompaniesAutoComplete(props.listCompaniesAutoComplete);
  }, [props.listCompaniesAutoComplete]);

  let statusCountRow = null;
  let table = null;
  let selects = <SkeletonTableFilterRow />;
  let tableOptionsRow = null;

  const filterStatus = (status) => {
    props.changePage(0);
    filterSingle("Status", status, param, props.setFilter);
  };

  const debouncedFilterStatus = useCallback(
    _.debounce((status) => {
      filterStatus(status);
    }, 300),
    []
  );

  const searchCompanies = (searchWord, selectedValues) => {
    if ((searchWord !== "" && searchWord.length > 2) || (selectedValues !== null && selectedValues.length > 0)) {
      var data = {
        selectedValues: selectedValues,
        searchWord: searchWord,
      };

      props.fetchCompaniesListAutoComplete(data);
    }
  };

  const companiesFilterOnMenuClose = (selectedValues) => {
    if (selectedValues !== null && selectedValues.length === 0) setListCompaniesAutoComplete([]);
  };

  const buttons = (
    <div className="flex">
      &nbsp;&nbsp;
      <Permission has={PERMISSIONS.audience.create}>
        <Button id="prospectsUploadAudience" isLink={true} variant="secondary" link={"audiences"}>
          <UploadCloud2LineIcon size={16} className="mR5" /> Upload list
        </Button>
      </Permission>
      <Permission has={PERMISSIONS.audienceExplorer.view}>
        <Button id="prospectsAudienceBuilder" isLink={true} variant="primary" link={"search"}>
          <UserSearchLineIcon size={16} className="mR5 iconWhite" /> Search contacts
        </Button>
      </Permission>
    </div>
  );
  if (props.error) {
    table = <h2>Error getting Prospects</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
    statusCountRow = (
      <div className="flex w-5 mT10 mB30">
        {[1, 2, 3, 4, 5, 6].map((n) => (
          <SkeletonTwoRowsCard key={n} />
        ))}
      </div>
    );
  }

  if (props.isLoaded && props.statusCount) {
    statusCountRow = (
      <StatusCountRow
        statusCount={props.statusCount}
        click={debouncedFilterStatus}
        changeFilter={(text) => props.searchRecords(text)}
        activeStatus={activeStatus}
      />
    );
  }
  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <>
          <Table
            tableName={"Prospects"}
            columns={dataTableColumns}
            rows={tableRows}
            param={param}
            parentSort={props.sortData}
            parentCallback={props.changePage}
            pageCount={props.pageCount}
            totalCount={props.totalCount}
            changeNumRecords={props.numRecords}
            tableClassName={styles.prospectsTable}
            scrollY={true}
            tableHeight={350}
            variant="large"
            forcePageChanged={true}
          />
          <ManuallyCategorizeProspectModal
            showModal={categorizeModal.isVisible}
            handleClose={categorizeModal.hide}
            prospectId={categorizeModal.data}
            refreshData={() => fetchProspects(param)}
          />
        </>
      ) : (
        <EmptyState image={image} title="No prospects found" />
      );

    let selectRowOptions = [
      {
        option: campaignsList,
        change: (c) => filterData("Campaign", c, props.setFilter),
        placeholder: "Campaigns",
        value: getFilterValue(param.filter, "Campaign"),
      },
      {
        option: listCompaniesAutoComplete,
        change: (i) => filterData("Companies", i, props.setFilter),
        onInputChange: (i) => searchCompanies(i, getFilterValue(param.filter, "Companies")),
        onMenuClose: () => companiesFilterOnMenuClose(getFilterValue(param.filter, "Companies")),
        placeholder: "Companies",
        value: getFilterValue(param.filter, "Companies"),
      },
      {
        option: props.listResponseTypes,
        change: (s) => filterData("ResponseType", s, props.setFilter),
        placeholder: "Response Types",
        value: getFilterValue(param.filter, "ResponseType"),
      },
      {
        option: props.listSenderEmailAccounts,
        change: (s) => filterData("EmailAccount", s, props.setFilter),
        placeholder: "Email Account",
        value: getFilterValue(param.filter, "EmailAccount"),
      },
      {
        option: phoneFilterValues,
        change: (s) => filterData("PhoneNumber", s, props.setFilter),
        placeholder: "Phone Number",
        value: getFilterValue(param.filter, "PhoneNumber"),
      },
    ];

    selects = <TableFiltersRow selects={selectRowOptions} placeholder="prospects" changeFilter={(text) => props.searchRecords(text)} />;

    tableOptionsRow =
      tableRows.length > 0 ? (
        <TableOptionsRow
          applyChanges={applyDataTableColumnsChange}
          exportTable={exportProspectsData}
          exportButtonDisabled={exportButtonDisabled}
          setExportButtonDisabled={setExportButtonDisabled}
          importData={null}
          dataTableColumns={dataTableColumns}
          setDataTableColumns={setDataTableColumns}
          allDataTableColumns={columns}
        />
      ) : null;
  }

  return (
    <>
      <PageContentWrapper className="contentHeight">
        <HeaderTitle title="Prospects" icon={icon} rightContent={buttons} />
        {selects}
        {statusCountRow}
        {tableOptionsRow}
        {table}
      </PageContentWrapper>
      <ExportProspectsWarningForm
        showModal={showWarningModal}
        handleClose={handleWarningClose}
        handleSave={handleWarningSave}
        setExportButtonDisabled={setExportButtonDisabled}
      />
      {revealPhoneModal.isVisible && (
        <PhoneRevealModal
          showModal={revealPhoneModal.isVisible}
          handleSave={handlePhoneReveal}
          handleClose={revealPhoneModal.hide}
          prospectId={revealPhoneModal.data}
          handlePhoneReveal={handlePhoneReveal}
          handleNoCredits={noCreditsModal.show}
          handleError={noResponseModal.show}
          handleOptedOut={userOptedOutModal.show}
          creditsChargeAmountPhoneReveal={creditsChargeAmountPhoneReveal}
        />
      )}
      {noCreditsModal.isVisible && (
        <NoCreditsModal showModal={noCreditsModal.isVisible} handleClose={noCreditsModal.hide} organizationId={organizationId} />
      )}
      {upgradeToPaidPlanModal.isVisible && (
        <UpgradeToPaidPlanModal showModal={upgradeToPaidPlanModal.isVisible} handleClose={upgradeToPaidPlanModal.hide} />
      )}
      {noResponseModal.isVisible && <NoResponseModal showModal={noResponseModal.isVisible} handleClose={noResponseModal.hide} />}
      {userOptedOutModal.isVisible && <UserOptedOutModal showModal={userOptedOutModal.isVisible} handleClose={userOptedOutModal.hide} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.prospects.params,
    isInitiated: state.prospects.isInitiated,
    isLoading: state.prospects.isLoading,
    isLoaded: state.prospects.isLoaded,
    error: state.prospects.error,
    rows: state.prospects.rows,
    totalCount: state.prospects.totalCount,
    pageCount: state.prospects.pageCount,

    listResponseTypes: state.globalProspects.listResponseTypes,
    isLoadingListResponseTypes: state.globalProspects.isLoading,
    isLoadedListResponseTypes: state.globalCompanies.isLoaded,

    listCompaniesAutoComplete: state.globalCompanies.listCompaniesAutoComplete,
    isLoadingListCompaniesAutoComplete: state.globalCompanies.isLoading,
    isLoadedListCompaniesAutoComplete: state.globalCompanies.isLoaded,

    statusCount: state.globalProspects.statusCount,

    listSenderEmailAccounts: state.globalProspects.listSenderEmailAccounts,
    plan: state.billingAndPayment.creditInfo.plan,
    creditsChargeAmountPhoneReveal: state.billingAndPayment.creditInfo.creditsChargeAmountPhoneReveal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompaniesListAutoComplete: (data) => dispatch(fetchCompaniesListAutoComplete(data)),
    fetchResponseTypes: () => dispatch(fetchResponseTypes()),
    fetchSenderEmailAccounts: () => dispatch(fetchSenderEmailAccounts()),
    fetchProspects: (param) => dispatch(fetchProspects(param)),
    fetchStatusCount: (obj) => dispatch(fetchStatusCount(obj)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prospects);
