import React, { useEffect, useState, useRef } from "react";
import { PERMISSIONS } from "../../helper/permissionConstants";
import Permission from "../../components/auth/Permission";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { subDays } from "date-fns";

//Redux
import {
  searchRecords as searchDefferredIntrest,
  setFilter as filterDeferredInterest,
  numRecords as numRecordsDeferredInterest,
  clearFilter as clearFilterDeferredInterest,
} from "../../store/Deals/Table/actions/deferredInterestDeals";
import {
  searchRecords as searchReferrals,
  setFilter as filterReferrals,
  numRecords as numRecordsReferrals,
  clearFilter as clearFilterReferrals,
} from "../../store/Deals/Table/actions/referralsDeals";
import {
  searchRecords as searchRecordsPositiveResponses,
  setFilter as filterPositiveResponses,
  numRecords as numRecordsPositiveResponses,
  clearFilter as clearFilterPositiveResponses,
} from "../../store/Deals/Table/actions/positiveResponsesDeals";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";

//Helpers
import { filterData, numberOfRecords, getFilterValue } from "../../helper/tableConstants";
import api, { API } from "../../helper/api/api";
import { fileDownload } from "../../helper/fileDownloadHelper";

//Styles
import styles from "./Deals.module.scss";

// Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import PositiveResponses from "./components/PositiveResponses";
import Referrals from "./components/Referrals";
import DeferredInterest from "./components/DeferredInterest";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Button from "../../components/UI/Button/Button";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const Deals = (props) => {
  let records = numberOfRecords[0];
  const [params, setParams] = useState(null);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [height, setHeight] = useState(0);
  const headerContainerRef = useRef();

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  const icon = (
    <InfoIconTooltip
      tooltipClass={`${styles.leadsInfoTooltip}`}
      tooltipBody={"View your leads, broken down by Positive, Referral, and Deferred Interest."}
      href={"https://help.outbase.com/user-guide/sales/leads"}
      linkText="Read More"
      buttonId="startCampaignTour"
      disableStartTourButton
    />
  );

  useEffect(() => {
    records = records.value;
  }, [records]);

  useEffect(() => {
    if (dateRange.startDate != null && dateRange.endDate != null) {
      let startDate = dateRange.startDate instanceof Date ? dateRange.startDate : new Date(dateRange.startDate);
      let endDate = dateRange.endDate instanceof Date ? dateRange.endDate : new Date(dateRange.endDate);

      let obj = [
        {
          from: startDate.toISOString().split("T")[0],
          to: endDate.toISOString().split("T")[0],
        },
      ];
      filterData("Date", obj, props.filterDeferredInterest);
      filterData("Date", obj, props.filterPositiveResponses);
      filterData("Date", obj, props.filterReferrals);
    } else {
      props.clearFilterPositiveResponses("Date");
      props.clearFilterDeferredInterest("Date");
      props.clearFilterReferrals("Date");
    }
  }, [dateRange]);

  useEffect(() => {
    let topHeight = headerContainerRef.current.offsetHeight;
    setHeight(window.innerHeight - topHeight);
  }, []);

  const handleSearchChange = (text) => {
    props.searchRecordsDeferredIntrest(text);
    props.searchRecordsRefferrals(text);
    props.searchRecordsPositiveResponses(text);
  };

  const exportAllCSV = () => {
    const exportParams = {
      ...props.paramsReferrals,
    };

    const newFilters = [...exportParams.filter];
    for (let i = 0; i < newFilters.length; i++) {
      if (newFilters[i].type === "Type") {
        newFilters.splice(i, 1);
      }
    }
    exportParams.filter = newFilters;

    const exportAllTotalCount = props.totalCountPositiveResponses + props.totalCountReferrals + props.totalCountDeferredInterest;
    exportParams.length = exportAllTotalCount;
    api(API.deals.export, exportParams, "blob")
      .then((res) => {
        fileDownload("ExportAllLeads.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const buttons = (
    <Permission has={PERMISSIONS.deals.export}>
      <Button
        id="exportAllDeals"
        variant="primary"
        onClick={exportAllCSV}
        disabled={props.totalCountPositiveResponses + props.totalCountReferrals + props.totalCountDeferredInterest === 0}
      >
        Export all
      </Button>
    </Permission>
  );

  let selectRowOptions = [
    {
      option: campaignsList,
      change: (c) => [
        filterData("Campaign", c, props.filterDeferredInterest),
        filterData("Campaign", c, props.filterPositiveResponses),
        filterData("Campaign", c, props.filterReferrals),
      ],
      placeholder: "Campaigns",
      value: getFilterValue(params?.filter, "Campaign"),
    },
  ];

  const handleChangeEntries = (records) => {
    props.numRecordsPositiveResponses(records);
    props.numRecordsDeferredInterest(records);
    props.numRecordsReferrals(records);
  };

  let dateOptions = {
    onChange: (item) => {
      setDateRange(item);
    },
    showSelectionPreview: true,
    key: "selection",
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    editableDateInputs: false,
    startDate: new Date(subDays(Date.now(), 30)),
    endDate: new Date(Date.now()),
    inputRanges: [],
  };

  return (
    <Permission has={PERMISSIONS.deals.view}>
      <PageContentWrapper className="contentHeight">
        <div ref={headerContainerRef}>
          <HeaderTitle title="Leads" icon={icon} rightContent={buttons} />
          <TableFiltersRow
            selects={selectRowOptions}
            changeNumRecords={(records) => handleChangeEntries(records)}
            dateOptions={dateOptions}
            placeholder={"deals"}
            changeFilter={(text) => handleSearchChange(text)}
          />
        </div>
        <div style={{ display: "flex" }}>
          <PositiveResponses setParams={setParams} records={records} titleHeight={height} />
          <Referrals setParams={setParams} records={records} titleHeight={height} />
          <DeferredInterest setParams={setParams} records={records} titleHeight={height} />
        </div>
      </PageContentWrapper>
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    paramsPositiveResponses: state.positiveResponsesDeals.params,
    paramsDeferredInterest: state.deferredIntrestDeals.params,
    paramsReferrals: state.referralsDeals.params,
    totalCountDeferredInterest: state.deferredIntrestDeals.totalCount,
    totalCountReferrals: state.referralsDeals.totalCount,
    totalCountPositiveResponses: state.positiveResponsesDeals.totalCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRecordsDeferredIntrest: (search) => dispatch(searchDefferredIntrest(search)),
    searchRecordsRefferrals: (search) => dispatch(searchReferrals(search)),
    searchRecordsPositiveResponses: (search) => dispatch(searchRecordsPositiveResponses(search)),
    filterPositiveResponses: (type, value) => dispatch(filterPositiveResponses(type, value)),
    filterDeferredInterest: (type, value) => dispatch(filterDeferredInterest(type, value)),
    filterReferrals: (type, value) => dispatch(filterReferrals(type, value)),
    numRecordsPositiveResponses: (obj) => dispatch(numRecordsPositiveResponses(obj)),
    numRecordsDeferredInterest: (obj) => dispatch(numRecordsDeferredInterest(obj)),
    numRecordsReferrals: (obj) => dispatch(numRecordsReferrals(obj)),
    clearFilterPositiveResponses: (type) => dispatch(clearFilterPositiveResponses(type)),
    clearFilterDeferredInterest: (type) => dispatch(clearFilterDeferredInterest(type)),
    clearFilterReferrals: (type) => dispatch(clearFilterReferrals(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
