import React from "react";

//Components
import Modal from "@components/UI/Modal/Modal";

interface DiscardModalProps {
  handleClose: () => void;
  handleSave: () => void;
  showModal: boolean;
}

const DiscardModal: React.FC<DiscardModalProps> = (props) => {
  return (
    <Modal
      title="Cancel Create with AI"
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Confirm"
      btnType="submit"
    >
      <p>
        <b>Are you sure you want to cancel campaign creation? </b>
        <br />
        All changes and selections will be lost.
      </p>
    </Modal>
  );
};

export default DiscardModal;
