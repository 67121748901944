import React from "react";

//Styles
import styles from "./AttachedDocument.module.scss";

//Icons & Images=
import IconFilePdf from "../../../../../assets/Icons/IconFilePdf/IconFilePdf";
import IconFileCsv from "../../../../../assets/Icons/IconFileCsv/IconFileCsv";
import IconXlsxFile from "../../../../../assets/Icons/IconXlsxFile/IconXlsxFile";
import IconFileWord from "../../../../../assets/Icons/IconFileWord/IconFileWord";
import IconFileImage from "../../../../../assets/Icons/IconFileImage/IconFileImage";
import IconFilePpt from "../../../../../assets/Icons/IconFilePpt/IconFilePpt";
import IconFileText from "../../../../../assets/Icons/IconFileText/IconFileText";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

const AttachedDocument = (props) => {
  const { type } = props;
  let icon = <IconFileText imgStyle={props.imgStyle} />;

  if (type?.includes("pdf")) {
    icon = <IconFilePdf imgStyle={props.imgStyle} />;
  } else if (type?.includes("docx")) {
    icon = <IconFileWord imgStyle={props.imgStyle} />;
  } else if (type?.includes("jpg") || type?.includes("jpeg") || type?.includes("png") || type?.includes("gif") || type?.includes("svg")) {
    icon = <IconFileImage imgStyle={props.imgStyle} />;
  } else if (type?.includes("csv")) {
    icon = <IconFileCsv imgStyle={props.imgStyle} />;
  } else if (type?.includes("pptx")) {
    icon = <IconFilePpt imgStyle={props.imgStyle} />;
  } else if (type?.includes("xlsx")) {
    icon = <IconXlsxFile imgStyle={props.imgStyle} />;
  } else if (type?.includes("txt")) {
    icon = <IconFileText imgStyle={props.imgStyle} />;
  }

  return (
    <>
      {props.imgStyle ? (
        <>{icon}</>
      ) : (
        <div className={`${styles.documentWrapper} textCenter`}>
          <div className={styles.documentDetails}>
            <span className={styles.icon}>{icon}</span>
            {props.name ? <span className={styles.documentName}>{props.name}</span> : null}
          </div>
          {props.disabled ? null : (
            <DeleteBin6LineIcon className="iconGray cursor_pointer" size={15} onClick={props.onClick} />
            // <Delete click={props.click} imgStyle={styles.deleteIcon} />
          )}
        </div>
      )}
    </>
  );
};

export default AttachedDocument;
