import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Grid } from "@mui/material";

//Styles
import styles from "./Steps.module.scss";

//Components
import AntSwitch from "../../../../components/UI/AntSwitch/Switch";

const ThirdStep = (props) => {
  const [customFields, setCustomFields] = useState([...props.formikProps.values.csvCustomMappedColumns]);

  let allColumns = [];
  props.formikProps.values.csvFileColumns.map((field) => {
    allColumns.push(field.value);
  });

  let mappedColumns = [];
  //We add the used columns manually
  mappedColumns.push(props.formikProps.values.csvMappedColumns.firstName);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.lastName);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.jobTitle);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.emailAddress);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.contactCountry);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.company);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.companyWebsite);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.companyIndustry);
  mappedColumns.push(props.formikProps.values.csvMappedColumns.companySize);

  const unmappedCSVFileColumns = allColumns.filter((x) => !mappedColumns.includes(x));

  const adjustCustomFieldOption = (customField) => {
    let customFieldsUpdate = [];

    if (customFields.some((item) => item.customFieldKey === customField)) {
      customFields.map((field) => {
        if (field.customFieldKey !== customField) {
          customFieldsUpdate.push({
            customFieldKey: field.customFieldKey,
            customFieldValue: field.customFieldValue,
          });
        }
      });
    } else {
      customFieldsUpdate = [...customFields];
      customFieldsUpdate.push({
        customFieldKey: customField,
        customFieldValue: customField,
      });
    }

    setCustomFields(customFieldsUpdate);
    props.formikProps.setFieldValue("csvCustomMappedColumns", customFieldsUpdate);
  };

  const checkIfCustomFieldIsMapped = (field) => {
    var mappedCustomFields = props.formikProps.values.csvCustomMappedColumns;
    var fieldIsMapped = false;

    mappedCustomFields.map((mappedField) => {
      if (mappedField.customFieldKey === field) {
        fieldIsMapped = true;
      }
    });

    return fieldIsMapped;
  };

  return (
    <Container>
      <Row>
        <Col xl={12} className={styles.unmappedCSVFileColumnsText}>
          {unmappedCSVFileColumns.length > 0 ? (
            <>
              Here are the fields that we saw in your CSV file that weren't matched in the previous step.
              {/* <hr className={styles.customFieldsHR} /> */}
            </>
          ) : (
            "You don't have any unmapped custom fields. Proceed to the file processing step"
          )}
        </Col>
      </Row>

      {unmappedCSVFileColumns.map((customField, index) => {
        return (
          <Row>
            <Col xl={7}>
              <p className={styles.secondStepP}>{customField}</p>
            </Col>
            <Col xl={5}>
              <Grid component="label" container alignItems="center" spacing={1} className={styles.buttonLabel}>
                <Grid item className="f14 secondary300 font600 mT10">
                  Ignore
                </Grid>
                <Grid item>
                  <AntSwitch
                    className="mT10"
                    id={"switchId" + index}
                    onChange={() => {
                      adjustCustomFieldOption(customField);
                    }}
                    checked={checkIfCustomFieldIsMapped(customField)}
                    name="unsubscribeExternalService"
                  />
                </Grid>
                <Grid item className="f14 secondary300 font600 mT10">
                  Import
                </Grid>
              </Grid>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default ThirdStep;
