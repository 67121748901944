import { useEffect, useState } from "react";
import { connect } from "react-redux";
import SignalRContext from "./helper/SignalRContext";
import * as SignalR from "@microsoft/signalr";

//Redux
import { signalReceived } from "./store/Signal/actions/signal";
import { updateOrganizationAccessRemoved } from "./store/global/actions/user";

//Helper
import { SIGNALR_CONSTANTS } from "./helper/signalrConstants";
import { useAuth } from "react-oidc-context";

const SignalRWrapper = (props) => {
  const { userId, updateOrganizationAccessRemoved } = { ...props };
  const [signalRConnection, setSignalRConnection] = useState(null);

  const [signalrOrganizationId, setSignalrOrganizationId] = useState(null);

  const auth = useAuth();

  // We are using window.location because SignalRWrapper is wrapped before the Router
  const url = window.location.pathname;
  const parts = url.split("/");
  const orgIdString = parts[1]; // Note: parts[0] will be an empty string because the path starts with "/"
  const organizationId = parseInt(orgIdString, 10);

  useEffect(() => {
    setSignalrOrganizationId(organizationId);
    if (signalRConnection != null) {
      signalRConnection.stop();
      setSignalRConnection(null);
    }
  }, [organizationId]);

  useEffect(() => {
    let connection = signalRConnection ? true : false;

    if (auth.user && auth.isAuthenticated && userId && userId > 0) {
      if (!connection) {
        let url = import.meta.env.VITE_SERVER_URL + "/signalr";

        if (organizationId && organizationId > 0) {
          url += `?orgId=${organizationId}`;
        }

        let connection = new SignalR.HubConnectionBuilder()
          .withUrl(url, {
            useDefaultPath: false,
          })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: function (retryContext) {
              return 1000; //1s delay
            },
          })
          .configureLogging(SignalR.LogLevel.Information)
          .build();

        connection.serverTimeoutInMilliseconds = 200000;

        connection
          .start()
          .then(() => {
            console.log("SignalR connection started");
          })
          .catch((err) => {
            console.log("SignalR connection Error" + err);
          });

        connection.onclose((e) => {
          console.log("SignalR connection disconnected " + e);
        });

        setSignalRConnection(connection);
      }
    }
  }, [userId, organizationId, signalrOrganizationId, signalRConnection, setSignalRConnection, auth]);

  useEffect(() => {
    if (signalRConnection !== null) {
      signalRConnection.on(SIGNALR_CONSTANTS.ORGANIZATION_ACCESS_REMOVED, (data) => {
        updateOrganizationAccessRemoved(data);
        signalReceived(SIGNALR_CONSTANTS.ORGANIZATION_ACCESS_REMOVED, data);
      });
    }
  }, [signalRConnection]);

  return <SignalRContext.Provider value={signalRConnection}>{props.children}</SignalRContext.Provider>;
};

const mapStateToProps = (state) => {
  return {
    userId: state.user?.id ?? 0,
    organizationId: state.user != null && state.user.organization != null ? state.user.organization.id : 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrganizationAccessRemoved: (data) => dispatch(updateOrganizationAccessRemoved(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignalRWrapper);
