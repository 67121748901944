import FormikSelect from "@ui/Formik/FormikSelect";

import styles from "../Filters.module.scss";
import { useGetCompanyRevenueRangesQuery } from "@api/audienceExplorerApi";
import { useFormikContext } from "formik";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterCompanyRevenue = () => {
  const {
    values: {
      company: { revenue },
    },
  } = useFormikContext<SearchModel>();
  const ranges = useGetCompanyRevenueRangesQuery();

  return (
    <FormikSelect
      showValuesOutside={true}
      isMulti={true}
      name="company.revenue"
      value={revenue}
      options={ranges && ranges.data ? ranges.data : []}
      displayName="Select annual revenue range"
      placeholder="e.g. 1m-10m"
      isSearchable={true}
      enableReinitialize={true}
      dropdownButtonClass={styles.selectDropdown}
    />
  );
};

export default FilterCompanyRevenue;
