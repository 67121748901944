export const FETCH_CREDIT_INFO_REQUEST = "FETCH_CREDIT_INFO_REQUEST";
export const FETCH_CREDIT_INFO_SUCCEEDED = "FETCH_CREDIT_INFO_SUCCEEDED";
export const FETCH_CREDIT_INFO_FAILED = "FETCH_CREDIT_INFO_FAILED";
export const UPDATE_CURRENT_CREDITS = "UPDATE_CURRENT_CREDITS";

export const FETCH_BILLING_INFO_REQUEST = "FETCH_BILLING_INFO_REQUEST";
export const FETCH_BILLING_INFO_SUCCEEDED = "FETCH_BILLING_INFO_SUCCEEDED";
export const FETCH_BILLING_INFO_FAILED = "FETCH_BILLING_INFO_FAILED";

export const FETCH_PRICING_PLANS_REQUEST = "FETCH_PRICING_PLANS_REQUEST";
export const FETCH_PRICING_PLANS_SUCCEEDED = "FETCH_PRICING_PLANS_SUCCEEDED";
export const FETCH_PRICING_PLANS_FAILED = "FETCH_PRICING_PLANS_FAILED";

export const FETCH_CONTRACT_STATUS_REQUEST = "FETCH_CONTRACT_STATUS_REQUEST";
export const FETCH_CONTRACT_STATUS_SUCCEEDED =
  "FETCH_CONTRACT_STATUS_SUCCEEDED";
export const FETCH_CONTRACT_STATUS_FAILED = "FETCH_CONTRACT_STATUS_FAILED";

export const FETCH_PLAN_INFO_CHECKOUT_REQUEST = "FETCH_PLAN_INFO_CHECKOUT_REQUEST";
export const FETCH_PLAN_INFO_CHECKOUT_SUCCEEDED = "FETCH_PLAN_INFO_CHECKOUT_SUCCEEDED";
export const FETCH_PLAN_INFO_CHECKOUT_FAILED = "FETCH_PLAN_INFO_CHECKOUT_FAILED";

export const SET_CONTRACT_STATUS = "SET_CONTRACT_STATUS";

export const FETCH_COUNTRY_STATE_INFO_REQUEST = "FETCH_COUNTRY_STATE_INFO_REQUEST";
export const FETCH_COUNTRY_STATE_INFO_SUCCEEDED = "FETCH_COUNTRY_STATE_INFO_SUCCEEDED";
export const FETCH_COUNTRY_STATE_INFO_FAILED = "FETCH_COUNTRY_STATE_INFO_FAILED";
