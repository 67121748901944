import React from "react";

//Style
import styles from "../Sandbox.module.scss";

// Icons
import ArchiveIcon from "../../../assets/Icons/ArchiveIcon/ArchiveIcon";
import CampaignInUse from "../../../assets/Icons/CampaignInUse/CampaignInUse";
import CampaignNotInUse from "../../../assets/Icons/CampaignNotInUse/CampaignNotInUse";
import CircleSuccess from "../../../assets/Icons/CircleSuccess/CircleSuccess";
import CircleWarning from "../../../assets/Icons/CircleWarning/CircleWarning";
import Clicked from "../../../assets/Icons/Clicked/Clicked";
import EmailOpened from "../../../assets/Icons/EmailOpened/EmailOpened";
import Expand from "../../../assets/Icons/Expand/Expand";
import Filter from "../../../assets/Icons/Filter/Filter";
import Graph from "../../../assets/Icons/Graph/Graph";
import GraphDown from "../../../assets/Icons/GraphDown/GraphDown";
import GraphUp from "../../../assets/Icons/GraphUp/GraphUp";
import IconArchive from "../../../assets/Icons/IconArchive/IconArchive";
import IconRateUp from "../../../assets/Icons/IconRateUp/IconRateUp";
import ImageNotFound from "../../../assets/Icons/ImageNotFound/ImageNotFound";
import RateDown from "../../../assets/Icons/RateDown/RateDown";
import ReportResponse from "../../../assets/Icons/ReportResponse/ReportResponse";
import Responded from "../../../assets/Icons/Responded/Responded";
import RestoreIcon from "../../../assets/Icons/RestoreIcon/RestoreIcon";
import SectionCompany from "../../../assets/Icons/SectionCompany/SectionCompany";
import SectionInbox from "../../../assets/Icons/SectionInbox/SectionInbox";
import MessagePreview from "../../../assets/Icons/SectionMessagePreview/MessagePreview";
import MessageResponseType from "../../../assets/Icons/SectionResponseType/MessageResponseType";
import PerformanceClick from "../../../assets/Icons/PerformanceClick/PerformanceClick";
import IconAssignToCampaign from "../../../assets/Icons/IconAssignToCampaign/IconAssignToCampaign";
import IconBriefcase from "../../../assets/Icons/IconBriefcase/IconBriefcase";

const IconsSandbox = (props) => {
  return (
    <>
      <p className="f18">
        Remix Icons library
        <a className="f18 mL5" href="https://remixicon.com/" rel="noopener noreferrer" target="_blank">
          remixicon.com{" "}
        </a>
      </p>
      <p className="f18">
        More details how to{" "}
        <a className="pR5" rel="noopener noreferrer" target="_blank" href="https://www.npmjs.com/package/remixicon-react#usage">
          use
        </a>
        Remix Icons
      </p>
      <h1 style={{ borderTop: "1px solid #cacaca", marginTop: "40px", paddingTop: "20px" }}>CUSTOM ICONS</h1>
      <div className={styles.iconsWrapper}>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <IconBriefcase />
          <p>Briefcase</p>
        </div>

        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <ArchiveIcon fill="#2a3347" />
          <p>Archive</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <CampaignInUse />
          <p>Campaign in use</p>
        </div>

        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <CampaignNotInUse />
          <p>Campaign not in use</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <CircleSuccess />
          <p>Success</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <CircleWarning />
          <p>Warning</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <Clicked fill="#2a3347" />
          <p>Clicked</p>
        </div>

        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <EmailOpened fill="#2a3347" />
          <p>Email opened</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <Expand />
          <p>Expand</p>
        </div>

        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <Graph />
          <p>Graph</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <GraphDown />
          <p>Graph down</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <GraphUp />
          <p>Graph up</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <IconRateUp />
          <p>Rate up</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <RateDown />
          <p>Rate down</p>
        </div>

        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <IconArchive />
          <p>Archive Icon</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <ImageNotFound />
          <p>Image not found</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <Responded width="20" height="20" />
          <p>Responded</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <RestoreIcon />
          <p>Restore</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <Filter />
          <p>Filter</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <ReportResponse />
          <p>Report response</p>
        </div>

        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <SectionCompany />
          <p>Company</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <SectionInbox />
          <p>Inbox</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <PerformanceClick />
          <p>Performance click</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <MessagePreview />
          <p>Message preview</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <MessageResponseType />
          <p>Message response</p>
        </div>
        <div className={`${styles.iconWrapper} flex flexCol center_flex`}>
          <IconAssignToCampaign />
          <p>Assign to campaign</p>
        </div>
      </div>
    </>
  );
};

export default IconsSandbox;
