import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

//Helpers
import { formatNumberHelper } from "@helper/formatNumberHelper";

//Style
import styles from "./ProspectDetails.module.scss";

//Images & Icons
import CompanyDetailsIcon from "@assets/Icons/CompanyDetailsIcon/CompanyDetailsIcon";
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";
import GlobalFillIcon from "remixicon-react/GlobalFillIcon";

//Components
import SectionTitle from "@components/UI/SectionTitle/SectionTitle";
import UserPhoto from "@components/UI/UserPhoto/UserPhoto";

const formatLinkedInUrl = (url) => {
  return url.includes("https") ? url : "//" + url;
};

const ProspectDetails = (props) => {
  const { fullName, email, companyName, cleanCompanyName, industry, size, revenue, linkedInUrl, jobTitle, country, companyId } =
    props.prospectDetails;

  return (
    <div className={styles.detailsWrapper}>
      <SectionTitle title="Prospect details" icon={<CompanyDetailsIcon />} />
      <div className={styles.imageWrapper}>
        <div className="flex_center">
          <UserPhoto name={props.name} isCompanyImage={false} src={props.imageUrl} />
        </div>
        <p className={styles.companyName}>{fullName}</p>
        {!_.isEmpty(country) ? (
          <p className={styles.address}>
            <MapPin2FillIcon size={16} className="iconAccent" /> {country}
          </p>
        ) : null}
        <p className={styles.web}>
          <GlobalFillIcon size={16} className="iconAccent" />
          &nbsp;
          {companyId > 0 ? (
            <Link
              className={styles.companyLink}
              to={{
                pathname: "/" + props.orgId + "/company-activity-overview/" + companyId,
              }}
            >
              {cleanCompanyName ? cleanCompanyName : companyName}
            </Link>
          ) : (
            <>{cleanCompanyName ? cleanCompanyName : companyName}</>
          )}
          &nbsp;
          {!_.isEmpty(jobTitle) ? "| " + jobTitle : ""}
        </p>
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.section}>
          <p className={styles.title}>About</p>
          <p className={styles.details}>
            Company Name:
            {companyId > 0 ? (
              <Link
                className={styles.companyLink}
                to={{
                  pathname: "/" + props.orgId + "/company-activity-overview/" + companyId,
                }}
              >
                {cleanCompanyName ? cleanCompanyName : companyName}
              </Link>
            ) : (
              <>{cleanCompanyName ? cleanCompanyName : companyName}</>
            )}
          </p>
          <p className={styles.details}>
            Industry:<span>{industry}</span>
          </p>
          <p className={styles.details}>
            Company Size:<span>{size}</span>
          </p>
          <p className={styles.details}>
            Revenue:
            <span>
              {revenue && revenue !== null
                ? formatNumberHelper(
                    Math.trunc(revenue),
                    props.organizationSettings.numberGroupSeperator,
                    props.organizationSettings.numberDecimalSeperator
                  )
                : "N/A"}
            </span>
          </p>
        </div>
        <div className={styles.section}>
          <p className={styles.title}>Contact details</p>

          <p className={styles.details}>
            Email:<span>{email}</span>
          </p>
        </div>
        <div className={styles.section}>
          <p className={styles.title}>SOCIAL MEDIA</p>
          {linkedInUrl && linkedInUrl !== "" ? (
            <div>
              <a rel="noopener noreferrer" href={formatLinkedInUrl(linkedInUrl)} target="_blank">
                <LinkedinBoxFillIcon color="#0077B5" />
              </a>
            </div>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orgImageUrl: state.user.organization.imageUrl,
    orgId: state.user.organization.id,
    organizationSettings: state.organizationSettingsGeneral.settings,
  };
};

export default connect(mapStateToProps, null)(ProspectDetails);
