//Styles
import { emailTemplateStatusEnum, emailTemplateStatusOptions } from "../../../../helper/enums/emailTemplateStatusEnum";
import styles from "./StatusColumn.module.scss";

const StatusColumn = (props) => {
  const { status } = props;

  return (
    <>
      <div className={styles.statusColumnWrapper}>
        <span className={status === emailTemplateStatusEnum.Draft ? `${styles.draft}` : ""}>{emailTemplateStatusOptions[status]}</span>
      </div>
    </>
  );
};

export default StatusColumn;
