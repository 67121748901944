export const FETCH_BASIC_EMAIL_TEST_DETAILS_REQUEST =
  "FETCH_BASIC_EMAIL_TEST_DETAILS_REQUEST";
export const FETCH_BASIC_EMAIL_TEST_DETAILS_SUCCEEDED =
  "FETCH_BASIC_EMAIL_TEST_DETAILS_SUCCEEDED";
export const FETCH_BASIC_EMAIL_TEST_DETAILS_FAILED =
  "FETCH_BASIC_EMAIL_TEST_DETAILS_FAILED";

export const FETCH_ADVANCE_EMAIL_TEST_DETAILS_REQUEST =
  "FETCH_ADVANCE_EMAIL_TEST_DETAILS_REQUEST";
export const FETCH_ADVANCE_EMAIL_TEST_DETAILS_SUCCEEDED =
  "FETCH_ADVANCE_EMAIL_TEST_DETAILS_SUCCEEDED";
export const FETCH_ADVANCE_EMAIL_TEST_DETAILS_FAILED =
  "FETCH_ADVANCE_EMAIL_TEST_DETAILS_FAILED";

export const FETCH_BASIC_EMAIL_SEND_TEST_REQUEST =
  "FETCH_BASIC_EMAIL_SEND_TEST_REQUEST";
export const FETCH_BASIC_EMAIL_SEND_TEST_SUCCEEDED =
  "FETCH_BASIC_EMAIL_SEND_TEST_SUCCEEDED";
export const FETCH_BASIC_EMAIL_SEND_TEST_FAILED =
  "FETCH_BASIC_EMAIL_SEND_TEST_FAILED";

export const FETCH_ADVANCE_EMAIL_SEND_TEST_REQUEST =
  "FETCH_ADVANCE_EMAIL_SEND_TEST_REQUEST";
export const FETCH_ADVANCE_EMAIL_SEND_TEST_SUCCEEDED =
  "FETCH_ADVANCE_EMAIL_SEND_TEST_SUCCEEDED";
export const FETCH_ADVANCE_EMAIL_SEND_TEST_FAILED =
  "FETCH_ADVANCE_EMAIL_SEND_TEST_FAILED";

export const FETCH_BASIC_EMAIL_TEST_LIST_REQUEST =
  "FETCH_BASIC_EMAIL_TEST_LIST_REQUEST";
export const FETCH_BASIC_EMAIL_TEST_LIST_SUCCEEDED =
  "FETCH_BASIC_EMAIL_TEST_LIST_SUCCEEDED";
export const FETCH_BASIC_EMAIL_TEST_LIST_FAILED =
  "FETCH_BASIC_EMAIL_TEST_LIST_FAILED";

export const FETCH_ADVANCE_EMAIL_TEST_LIST_REQUEST =
  "FETCH_ADVANCE_EMAIL_TEST_LIST_REQUEST";
export const FETCH_ADVANCE_EMAIL_TEST_LIST_SUCCEEDED =
  "FETCH_ADVANCE_EMAIL_TEST_LIST_SUCCEEDED";
export const FETCH_ADVANCE_EMAIL_TEST_LIST_FAILED =
  "FETCH_ADVANCE_EMAIL_TEST_LIST_FAILED";

export const SET_DICTIONARY_VALUES = "SET_DICTIONARY_VALUES";

export const BASIC_EMAIL_TEST_CLEAR_INITIATED = "BASIC_EMAIL_TEST_CLEAR_INITIATED";
