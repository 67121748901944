import React from "react";
import _ from "lodash";
import ListEmailAccounts from "@pages/CampaignSettings/Components/EmailAccountsWeighting/ListEmailAccounts/ListEmailAccounts";

// Components

interface EmailAccount {
  imageUrl: string;
  userFullName: string;
  emailName: string;
  email: string;
  id: string;
}

interface AgentAccountProps {
  searchAccount: string;
  emailAccountsList: EmailAccount[];
  setSelectedAccounts: (accounts: string[]) => void;
  selectedAccounts: string[];
}

const AgentAccount: React.FC<AgentAccountProps> = (props) => {
  const { searchAccount, emailAccountsList, setSelectedAccounts, selectedAccounts } = props;
  let filteredEmails = emailAccountsList;
  let data = null;

  if (!_.isEmpty(emailAccountsList) && !_.isEmpty(searchAccount)) {
    filteredEmails = emailAccountsList.filter(
      (emailAccount) => emailAccount.emailName.includes(searchAccount) || emailAccount.email.includes(searchAccount)
    );
  }

  if (!_.isEmpty(filteredEmails)) {
    data = filteredEmails.map((emailAccount, index) => {
      return (
        <ListEmailAccounts
          key={index}
          imageUrl={emailAccount.imageUrl}
          userFullName={emailAccount.userFullName}
          emailName={emailAccount.emailName}
          email={emailAccount.email}
          checkBox={true}
          handleCheckBox={() => {
            const isInTheArray = _.includes(selectedAccounts, emailAccount.id);
            if (!isInTheArray) {
              setSelectedAccounts([...selectedAccounts, emailAccount.id]);
            } else {
              const indexOfEmailAccount = selectedAccounts.indexOf(emailAccount.id);
              const newEmailAccounts = selectedAccounts.filter((_, index) => index !== indexOfEmailAccount);
              setSelectedAccounts(newEmailAccounts);
            }
          }}
        />
      );
    });
  }

  return <div>{data}</div>;
};

export default AgentAccount;
