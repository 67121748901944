import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

//Redux
import {
  fetchDomains,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
} from "../../../../store/Domains/actions/domains";

//Helpers
import { numberOfRecords } from "../../../../helper/tableConstants";
import api, { API, formUrl } from "../../../../helper/api/api";
import { PERMISSIONS } from "../../../../helper/permissionConstants";
import { toastMessages } from "../../../../helper/toastMessagesConstants";

//Images & Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";

// Styles
import styles from "./Domains.module.scss";

//Components
import Permission from "../../../../components/auth/Permission";
import ActionWrapper from "../../../../components/UI/ActionWrapper/ActionWrapper";
import Table from "../../../../components/DataTable/Table";
import { columns } from "./columns";
import AntSwitch from "../../../../components/UI/AntSwitch/Switch";
import SkeletonTable from "../../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "@ui/EmptyState/EmptyState";
import { useParams } from "react-router-dom";
import { useSlice } from "@hooks/useSlice";
import { useEditTrackingMutation } from "@api/campaignApi";

const Domains = (props) => {
  const { param, rows, fetchDomains, initiate, isInitiated } = props;
  const { campaignId } = useParams();
  const [tableRows, setTableRows] = useState([]);
  const records = numberOfRecords[1];

  const { campaign } = useSlice((state) => state.campaign);

  const [checked, setChecked] = useState(campaign.trackOpens);

  const [editTracking] = useEditTrackingMutation();

  useEffect(() => {
    let state;
    if (props.arrayDomains) {
      state = {
        params: {
          length: records.value,
          filter: [{ type: "DomainId", value: props.arrayDomains }],
        },
      };
    } else {
      state = {
        params: {
          length: records.value,
        },
      };
    }
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchDomains(param);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);

    tempRows.map((r) => {
      const isVerifed = r.dnsVerified;
      r.dnsVerified = (
        <ActionWrapper>
          {" "}
          {r.dnsVerified ? (
            <CheckboxCircleFillIcon key="checkIcon" size={20} color="var(--first-stage-color)" />
          ) : (
            <CloseCircleFillIcon key="closeIcon" size={20} color="var(--danger-color)" />
          )}{" "}
        </ActionWrapper>
      );
      r.trackOpens = (
        <ActionWrapper>
          {" "}
          {campaign.trackOpens && isVerifed ? (
            <CheckboxCircleFillIcon key="checkIconTrack" size={20} color="var(--first-stage-color)" />
          ) : (
            <CloseCircleFillIcon key="closeIconTrack" size={20} color="var(--danger-color)" />
          )}{" "}
        </ActionWrapper>
      );
      r.trackClicks = (
        <ActionWrapper>
          {" "}
          {campaign.trackClicks && isVerifed ? (
            <CheckboxCircleFillIcon key="checkIconClick" size={20} color="var(--first-stage-color)" />
          ) : (
            <CloseCircleFillIcon key="closeIconTrack" size={20} color="var(--danger-color)" />
          )}{" "}
        </ActionWrapper>
      );
      return r;
    });

    setTableRows(tempRows);
  }, [rows]);

  useEffect(() => {
    setChecked(campaign.trackOpens);
  }, [campaign.trackOpens]);

  let table = null;

  if (props.error) {
    table = <h2>Error getting Domains</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  const handleChange = (e) => {
    setChecked(!checked);

    const data = {
      campaignId: campaignId,
      tracking: !checked,
    };
    editTracking(data)
      .unwrap()
      .then((response) => {
        toast.success(toastMessages.domains.successEditTracking);
      })
      .catch((err) => {
        toast.error(toastMessages.domains.errorEditTracking);
      });
  };

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Domains"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          variant="small"
          scrollY={false}
        />
      ) : (
        <EmptyState title="No matching records found" />
      );
  }

  return (
    <Permission has={PERMISSIONS.domain.view}>
      <Permission has={PERMISSIONS.campaign.edit}>
        <Form>
          <div className={styles.switchWrapper}>
            <p className={styles.switchLabel}>Tracking</p>
            <AntSwitch className="mT10" onChange={(e) => handleChange(e)} checked={checked} name="unsubscribeExternalService" />
          </div>
          {/* <Form.Check
            type="switch"
            id="custom-switch"
            label="Tracking"
            onChange={(e) => handleChange(e)}
            checked={checked}
          // disabled={unverifiedCount > 0 ? true : false}
          /> */}
        </Form>
      </Permission>
      {table}
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.domains.params,
    isInitiated: state.domains.isInitiated,
    isLoading: state.domains.isLoading,
    isLoaded: state.domains.isLoaded,
    error: state.domains.error,
    rows: state.domains.rows,
    totalCount: state.domains.totalCount,
    pageCount: state.domains.pageCount,
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDomains: (param) => dispatch(fetchDomains(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Domains);
