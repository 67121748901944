export const pricingPlanStatusEnum = {
  current: 0,
  disabled: 1,
  upgrade: 2,
  downgrade: 3,
};

export const organizationPlanOptions = [
  { value: 6, label: "Trial" },
  { value: 1, label: "Start-Up" },
  { value: 2, label: "Scale-Up" },
  { value: 3, label: "Sell More" },
  { value: 4, label: "Sell Everything" },
  { value: 5, label: "Enterprise" },
];
