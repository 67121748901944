import { api } from "./api";
import { DTParameters } from "../models/dataTables/DtParameters";
import { DTResult } from "../models/dataTables/DtResult";
import { ProspectDataTableModel } from "../models/prospect/ProspectDataTableModel";
import { ManuallyCategorizeProspectModel } from "../models/prospect/ManuallyCategorizeProspectModel";
// import { ProspectStatusEnum } from "../models/enums/ProspectStatusEnum";
// import { ProspectStatusChangeResponse } from "../models/prospect/ProspectStatusChangeResponse";
// import { CreateProspectModel } from "../models/prospect/CreateProspectModel";
// import { CloneProspectModel } from "../models/prospect/CloneProspectModel";
// import { ProspectDetailsModel } from "@/models/prospect/ProspectDetailsModel";
// import { UpdateProspectNameModel } from "@models/prospect/UpdateProspectNameModel";

export const prospectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProspects: builder.query<DTResult<ProspectDataTableModel>, DTParameters>({
      query: (params) => ({
        url: "/prospects/prospects",
        method: "POST",
        body: params,
      }),
      //providesTags: ["Prospects"],
      keepUnusedDataFor: 0,
    }),
    getProspectCampaignDetails: builder.query<ManuallyCategorizeProspectModel, number>({
      query: (prospectId) => ({
        url: `/prospects/prospect-campaign-details`,
        method: "POST",
        body: prospectId,
      }),
      //providesTags: ["EmailAccountsForCampaigns"],
    }),
    manuallyCategorizeProspect: builder.mutation<void, ManuallyCategorizeProspectModel>({
      query: (model) => ({
        url: `/prospects/manually-categorize`,
        method: "POST",
        body: model,
      }),
    }),
    // getProspectStatuses: builder.query<ProspectStatusEnum[], void>({
    //     query: () => "/prospects/selectlist-prospect-statuses",
    //     providesTags: ["ProspectStatuses"],
    // }),
    // getProspect: builder.query<ProspectDetailsModel, number>({
    //   query: (id) => ({
    //     url: `/prospects/settings?prospectId=${id}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["Prospect"],
    // }),
    // editProspectName: builder.mutation<void, UpdateProspectNameModel>({
    //   query: (model) => ({
    //     url: "/prospects",
    //     method: "PUT",
    //     body: model,
    //   }),
    //   invalidatesTags: ["Prospect"],
    // }),
  }),
});

export const {
  useGetProspectsQuery,
  useGetProspectCampaignDetailsQuery,
  useManuallyCategorizeProspectMutation,
  //   useGetProspectStatusesQuery,

  //   useEditProspectStatusMutation,
  //   useCreateProspectMutation,
  //   useCloneProspectMutation,
  //   useEditProspectNameMutation,
} = prospectApi;
