import React, { useEffect } from "react";
import { connect } from "react-redux";

//Redux
import { fetchProspectFunnelStatistics } from "../../../../store/Dashboard/Global/actions/dashboard";

//Images & Icons
import Stage1 from "../../../../assets/Dashboard/Stage1/Stage1";
import Stage2 from "../../../../assets/Dashboard/Stage2/Stage2";
import Stage3 from "../../../../assets/Dashboard/Stage3/Stage3";
import Stage4 from "../../../../assets/Dashboard/Stage4/Stage4";

//Styles
import styles from "./ProspectFunnel.module.scss";

//helpers
import { addOffsetToDate } from "../../../../helper/dateHelper";
import { formatNumberHelper } from "../../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../../helper/hooks/useGroupDecimalSeparator";

//Components
import SkeletonDealNavigator from "../../../../components/UI/Skeletons/components/SkeletonDealNavigator/SkeletonDealNavigator";

const ProspectFunnel = (props) => {
  const {
    isProspectFunnelStatisticsLoading,
    isProspectFunnelStatisticsLoaded,
    errorProspectFunnelStatistics,
    prospectFunnelStatistics,
    fetchProspectFunnelStatistics,
    startDate,
    endDate,
  } = props;
  let separators = useGroupDecimalSeparator();

  useEffect(() => {
    fetchProspectFunnelStatistics(addOffsetToDate(startDate), addOffsetToDate(endDate));
  }, [startDate, endDate]);

  let content = null;

  if (errorProspectFunnelStatistics) {
    content = <h2>Error getting content</h2>;
  }

  if (isProspectFunnelStatisticsLoading) {
    content = <SkeletonDealNavigator />;
  }

  if (isProspectFunnelStatisticsLoaded) {
    content = (
      <div className={styles.funnelWrapper}>
        <div className={styles.stageWrapper}>
          <Stage1 />
          <div className={styles.details}>
            <span className={styles.title}>Engagements</span>
            <span className={styles.data}>
              {formatNumberHelper(prospectFunnelStatistics.engaged, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
            </span>
          </div>
        </div>
        <div className={styles.stageWrapper}>
          <Stage2 />
          <div className={styles.details}>
            <span className={styles.title}>Opens</span>
            <span className={styles.data}>
              {formatNumberHelper(prospectFunnelStatistics.opens, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
            </span>
          </div>
        </div>
        <div className={styles.stageWrapper}>
          <Stage3 />
          <div className={styles.details}>
            <div className={styles.multiData}>
              <div className={styles.dataWrapper}>
                <span className={styles.title}>Responded</span>
                <span className={styles.data}>
                  {formatNumberHelper(
                    prospectFunnelStatistics.responded,
                    separators.numberGroupSeperator,
                    separators.numberDecimalSeperator
                  )}
                </span>
              </div>
              <div className={styles.dataWrapper}>
                <span className={styles.title}>Clicks</span>
                <span className={styles.data}>
                  {formatNumberHelper(prospectFunnelStatistics.clicks, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.stageWrapper}>
          <Stage4 />
          <div className={styles.details}>
            <span className={styles.title}>Leads</span>
            <span className={styles.data}>
              {formatNumberHelper(prospectFunnelStatistics.leads, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return <>{content}</>;
};

const mapStateToProps = (state) => {
  return {
    isProspectFunnelStatisticsLoading: state.dashboard.isProspectFunnelStatisticsLoading,
    isProspectFunnelStatisticsLoaded: state.dashboard.isProspectFunnelStatisticsLoaded,
    errorProspectFunnelStatistics: state.dashboard.errorProspectFunnelStatistics,
    prospectFunnelStatistics: state.dashboard.prospectFunnelStatistics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProspectFunnelStatistics: (startDate, endDate) => dispatch(fetchProspectFunnelStatistics(startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectFunnel);
