import React, { useEffect, useState } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { connect } from "react-redux";
import { loadUserFromStorage } from "../../store/global/actions";

const AuthEvents = (props) => {
  const auth = useAuth();
  const [hasTriedSignIn, setHasTriedSignIn] = useState(false);

  useEffect(() => {
    return auth.events.addUserLoaded((user) => {
      if (user != null) {
        localStorage.setItem("user", JSON.stringify(user));
        props.loadUserFromStorage();
      }
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    return auth.events.addUserSignedOut(() => {
      localStorage.removeItem("user");
      props.loadUserFromStorage();
    });
  }, [auth.events, auth.signoutSilent]);

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth, auth.events, auth.signinSilent]);

  // Automatic sign-in
  // useEffect(() => {
  //   if (!hasAuthParams() &&
  //     !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
  //     !hasTriedSignIn
  //   ) {
  //     auth.signinRedirect();
  //     setHasTriedSignIn(true);
  //   }
  // }, [auth, hasTriedSignIn]);

  // return <Redirect to={IDENTITY_CONFIG.login} />;
  return <></>;
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserFromStorage: () => dispatch(loadUserFromStorage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthEvents);
