import React from "react";

//Styles
import styles from "./Legend.module.scss";

const Legend = (props) => {
  let classes = styles.legend;

  // if (props.variant != null) {
  //   classes += " " + styles[props.variant];
  // }

  if (props.classes != null) {
    classes += " " + props.classes;
  }

  return (
    <div className={classes} style={props.style}>
      {props.legend.map((item, index) => {
        return (
          <div className={styles.legendDetails} key={index}>
            <span className={`${styles[item.variant]} ${styles.circle}`}></span>
            <span className={styles.text}>{item.text}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
