export const columns = [
  {
    label: "Company Name",
    field: "companyName",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Industry",
    field: "industry",
    type: "number",
    sort: "asc",
    width: 150,
  },
  {
    label: "Size",
    field: "size",
    type: "number",
    sort: "asc",
    width: 100,
  },
  {
    label: "# of Prospects",
    field: "prospectNumber",
    type: "number",
    sort: "asc",
    width: 90,
  },
  {
    label: "Engaged Prospects",
    field: "engagedProspects",
    type: "number",
    sort: "asc",
    width: 90,
  },
  {
    label: "Opens",
    field: "opens",
    type: "number",
    sort: "asc",
    width: 90,
  },
  {
    label: "Clicks",
    field: "clicks",
    type: "number",
    sort: "asc",
    width: 90,
  },
  {
    label: "Responses",
    field: "responses",
    type: "number",
    sort: "asc",
    width: 90,
  },
  {
    label: "Leads",
    field: "leads",
    type: "number",
    sort: "asc",
    width: 90,
  },
  {
    label: "Date Last Prospected",
    field: "dateLastProspected",
    type: "date",
    sort: "asc",
    width: 150,
  },
];
