import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import Moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Redux
import {
  fetchUserDetails as fetchUserDetailsDataTable,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  clearInitiated,
} from "../../../store/Outboss/Users/Table/actions/userDetails";
import { fetchUserDetails } from "../../../store/Outboss/Users/Global/actions/user";

//Helpers
import { numberOfRecords, filtersToQueryString, filterData } from "../../../helper/tableConstants";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";

//Style
import styles from "./User.module.scss";

//Components
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import Table from "../../../components/DataTable/Table";
import TopBannerUser from "../TopBannerUser/TopBannerUser";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import RemoveUserAccessForm from "./RemoveUserAccessForm";
import ActionImpersonate from "../../../components/UI/ActionWrapper/Actions/ActionImpersonate";
import ActionDelete from "../../../components/UI/ActionWrapper/Actions/ActionDelete";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";

const User = (props) => {
  const { param, rows, fetchUserDetailsDataTable, isInitiated, initiate, clearInitiated, fetchUserDetails } = props;
  let dateFormat = useOrgDateFormat();
  let table = null;
  const records = numberOfRecords[1];
  const [tableRows, setTableRows] = useState([]);
  let minDate = Moment.utc("0001-01-01").format(dateFormat);

  useEffect(() => {
    let userId = props.match.params.userId;
    fetchUserDetails(userId);
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "User", value: [userId] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchUserDetailsDataTable(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const [userId, setUserId] = useState(0);
  const [userOrganizationId, setUserOrganizationId] = useState(0);
  const [organizationName, setOrganizationName] = useState("");
  const [showRemoveUserAccessModal, setShowRemoveUserAccessModal] = useState(false);
  const handleRemoveUserAccessClose = () => {
    setUserId(0);
    setUserOrganizationId(0);
    setOrganizationName("");
    setShowRemoveUserAccessModal(false);
  };
  const handleRemoveUserAccessShow = (userId, userOrganizationId) => {
    setUserId(userId);
    setUserOrganizationId(userOrganizationId);
    setShowRemoveUserAccessModal(true);
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r, i) => {
      let actions = (
        <ActionWrapper>
          <div className={styles.iconsWrapper}>
            {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.impersonate.canImpersonate) ? (
              <ActionImpersonate
                key={"action" + i}
                userId={props.userDetails.id}
                history={props.history}
                organizationId={r.organizationId}
                user={props.user}
              />
            ) : null}
            {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.users.edit) ? (
              <OverlayTrigger
                key={"log-in"}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-log-in`}>
                    <Popover.Body>Disable access to Organization</Popover.Body>
                  </Popover>
                }
              >
                <div className={styles.disableAccessToOrg}>
                  <ActionDelete
                    key={"delete" + i}
                    onClick={() => {
                      setOrganizationName(r.organizationName);
                      handleRemoveUserAccessShow(0, r.userOrganizationId);
                    }}
                  />
                </div>
              </OverlayTrigger>
            ) : null}
          </div>
        </ActionWrapper>
      );
      r.actions = actions;
      r.acceptanceDate = Moment(r.acceptanceDate).format(dateFormat);

      if (r.lastLogin && r.lastLogin !== null) {
        let lastDateFormatted = Moment(r.lastLogin).format(dateFormat);
        if (lastDateFormatted > minDate) {
          r.lastLogin = lastDateFormatted;
        } else {
          r.lastLogin = "";
        }
      } else {
        r.lastLogin = "";
      }

      r.fullName = (
        <Link className={styles.link} to={{ pathname: "/outboss/user-details-outboss" }}>
          {r.fullName}
        </Link>
      );
      r.email = (
        <Link className={styles.link} to={{ pathname: "/outboss/user-details-outboss" }}>
          {r.email}
        </Link>
      );

      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>{props.error}</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded && props.userDetailsLoaded) {
    table = (
      <div className={styles.testTable}>
        <Table
          outBoss
          tableName={"User4"}
          tableClassName={`${styles.userDetailsTable} orgFiltersTable`}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          tableFooterClass="datePickerTable"
          scrollY={true}
          variant="medium"
          tableHeight={260}
          filterData={filterData}
          setFilter={props.setFilter}
        />
      </div>
    );
  }

  const breadcrumbs = [
    {
      href: "/outboss/users",
      text: "Users",
    },
    { active: true, text: props.userDetails.fullName },
  ];

  return (
    <PageContentWrapper className={styles.contentWrapper}>
      <TopBannerUser
        breadcrumbs={breadcrumbs}
        userName={props.userDetails.fullName}
        userId={props.userDetails.id}
        handleRemoveUserAccessShow={handleRemoveUserAccessShow}
      />
      <TableFiltersRow placeholder="organization" changeFilter={(text) => props.searchRecords(text)} searchOnFirstCharacter={true} />
      {table}
      <RemoveUserAccessForm
        handleClose={handleRemoveUserAccessClose}
        showModal={showRemoveUserAccessModal}
        userId={userId}
        userOrganizationId={userOrganizationId}
        organizationName={organizationName}
        fetchUserDetailsDataTable={() => fetchUserDetailsDataTable(param)}
      />
    </PageContentWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    userPermissions: state.user.userPermissions,
    userDetails: state.outbossUserDetails.userDetails,
    userDetailsLoaded: state.outbossUserDetails.isLoaded,
    param: state.outbossUserDetailsDataTable.params,
    isInitiated: state.outbossUserDetailsDataTable.isInitiated,
    isLoading: state.outbossUserDetailsDataTable.isLoading,
    isLoaded: state.outbossUserDetailsDataTable.isLoaded,
    error: state.outbossUserDetailsDataTable.error,
    rows: state.outbossUserDetailsDataTable.rows,
    totalCount: state.outbossUserDetailsDataTable.totalCount,
    pageCount: state.outbossUserDetailsDataTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails: (userId) => dispatch(fetchUserDetails(userId)),
    fetchUserDetailsDataTable: (param) => dispatch(fetchUserDetailsDataTable(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
