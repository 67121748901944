import React from "react";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import "./RateWidget.scss";

//Helper
import { formatNumberHelper } from "../../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../../helper/hooks/useGroupDecimalSeparator";

// Icons
import RateDown from "../../../../assets/Icons/RateDown/RateDown";
import IconRateUp from "../../../../assets/Icons/IconRateUp/IconRateUp";

// Components
import AreaLineChart from "../../../../components/AreaLineChart/AreaLineChart";
import GlobalAverageRate from "../GlobalAverageRate/GlobalAverageRate";
import InformationFillIcon from "remixicon-react/InformationFillIcon";

const RateWidget = (props) => {
  let separators = useGroupDecimalSeparator();

  let globalAvgRate = !_.isNull(props.globalRate) && (
    <div>
      <GlobalAverageRate globalRate={props.globalRate} now={props.rate} ariaLabel={props.ariaLabel} />
    </div>
  );

  return (
    <div className={`widgetCard ${props.classRate}`}>
      <div className="widgetHeader">
        <h1 className="widgetTitle">{props.name}</h1>
        <OverlayTrigger
          key={"key"}
          placement={"top"}
          overlay={
            <Popover id={`popover-tooltip`}>
              <Popover.Body>{props.tooltipContent}</Popover.Body>
            </Popover>
          }
        >
          <div className="max-x-content flex_center">
            <InformationFillIcon size={14} />
          </div>
        </OverlayTrigger>
      </div>
      <div className="widgetBody flex">
        <div className="total">
          <span className="percentage">
            {props.rate ? formatNumberHelper(props.rate.toFixed(2), separators.numberGroupSeperator, separators.numberDecimalSeperator) : 0}
            %
          </span>
          <div className="numberWrapper">
            <span className="number">
              {formatNumberHelper(props.totalNumber, separators.numberGroupSeperator, separators.numberDecimalSeperator)}
            </span>
          </div>
        </div>
        <div className="chartWrapper">
          <AreaLineChart chartWidth={props.width} chartHeight={props.height} data={props.dataAreaLine} />
          {props.trend === 0 ? null : props.trend > 0 ? (
            <div className="rateStatistic">
              <IconRateUp />
              <span className="upPercentage">{props.trend}</span>
            </div>
          ) : (
            <div className="rateStatistic">
              <RateDown />
              <span className="downPercentage">{props.trend}</span>
            </div>
          )}
        </div>
      </div>
      {globalAvgRate}
    </div>
  );
};

export default RateWidget;
