export const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export const waitDaysDefaultValue = 6;

export const getTimeFromValue = (value) => {
  var hour = Math.floor(value);
  var decimal = value - Math.floor(value);
  var minutes = decimal * 60;

  var formattedHour = ("0" + hour).slice(-2);
  var formattedMinutes = ("0" + minutes).slice(-2);
  return `${formattedHour}:${formattedMinutes}`;
};

export const getValueFromTime = (val) => {
  let arr = val.split(":");
  let fullNum = parseInt(arr[0]);
  let decimal = parseInt(arr[1]) / 60;

  return fullNum + decimal;
};

export const calculatePerDayValue = (list, prospectValue) => {
  let newCalcList = [...list];
  let sumOfAllSliders = newCalcList.map((s) => s.activityWeight).reduce((a, b) => a + b, 0);

  for (let i = 0; i < newCalcList.length; i++) {
    const element = { ...newCalcList[i] };
    let perDayValue = (prospectValue / sumOfAllSliders) * element.activityWeight;
    element.perDayValue = Math.round(perDayValue);
    newCalcList[i] = element;
    //checking for difference
    if (i === newCalcList.length - 1) {
      let perDayValueSum = newCalcList.map((s) => s.perDayValue).reduce((a, b) => a + b, 0);
      if (perDayValueSum !== prospectValue) {
        let difference = prospectValue - perDayValueSum;
        element.perDayValue = element.perDayValue + difference;
        newCalcList[i] = element;
      }
    }
  }

  return newCalcList;
};
