export const FETCH_CONVERSATION_PREVIEW_REQUEST =
  "FETCH_CONVERSATION_PREVIEW_REQUEST";
export const FETCH_CONVERSATION_PREVIEW_SUCCEEDED =
  "FETCH_CONVERSATION_PREVIEW_SUCCEEDED";
export const FETCH_CONVERSATION_PREVIEW_FAILED =
  "FETCH_CONVERSATION_PREVIEW_FAILED";

export const FETCH_PROSPECT_DETAILS_REQUEST = "FETCH_PROSPECT_DETAILS_REQUEST";
export const FETCH_PROSPECT_DETAILS_SUCCEEDED =
  "FETCH_PROSPECT_DETAILS_SUCCEEDED";
export const FETCH_PROSPECT_DETAILS_FAILED = "FETCH_PROSPECT_DETAILS_FAILED";
