const Error = () => {
  const throwError = () => {
    // Attempting to call an undefined function will cause a runtime error
    testCallingUndefinedFunction();
  };

  return (
    <>
      <button onClick={throwError}>Trigger Error</button>
    </>
  );
};

export default Error;
