export const columns = [
  {
    label: "Issue Date",
    field: "issueDate",
    type: "date",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Description",
    field: "pricingPlan",
    type: "text",
    sort: "asc",
    flex: 1,
    showTooltip: true,
  },
  {
    label: "Net Amount",
    field: "netAmount",
    type: "number",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Tax Amount",
    field: "taxAmount",
    type: "number",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Amount Total",
    field: "amountTotal",
    type: "number",
    sort: "asc",
    flex: 1,
  },
  {
    label: "PDF",
    field: "publicUrl",
    type: "text",
    sort: "asc",
    flex: 1,
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
    flex: 1,
    showTooltip: true,
  },
];
