import * as actionTypes from "./actionTypes";
import { toast } from "react-toastify";
import { toastMessages } from "../../../helper/toastMessagesConstants";
import api, { API, formUrl } from "../../../helper/api/api";

// #region FETCH
// DEAL NAVIGATOR
export const fetchDealNavigatorRequest = () => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_REQUEST,
  };
};

export const fetchDealNavigatorSuccess = (dealNavigator) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_SUCCEEDED,
    result: dealNavigator,
  };
};

export const fetchDealNavigatorFailed = (error) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_FAILED,
    result: error,
  };
};

export const fetchDealNavigator = (editMode, val) => {
  if (val == null) {
    return (dispatch) => {
      dispatch(fetchDealNavigatorRequest());
      return api(formUrl(API.dealNavigator.get, { editMode: editMode }))
        .then((response) => {
          dispatch(fetchDealNavigatorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(fetchDealNavigatorFailed(error.message));
        });
    };
  } else {
    return (dispatch) => {
      dispatch(fetchDealNavigatorRequest());
      return api(
        formUrl(API.dealNavigator.getTemplateEdit, {
          editMode: editMode,
          templateId: val,
        })
      )
        .then((response) => {
          dispatch(fetchDealNavigatorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(fetchDealNavigatorFailed(error.message));
        });
    };
  }
};

export const changeEditModeValue = (editMode) => {
  return {
    type: actionTypes.SET_EDIT_MODE_DEAL_NAVIGATOR,
    result: editMode,
  };
};

export const setLastAddedCardValue = () => {
  return {
    type: actionTypes.SET_LAST_ADDED_CARD_VALUE,
  };
};

// DEAL NAVIGATOR STAGES
export const fetchDealNavigatorStagesRequest = () => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_STAGES_REQUEST,
  };
};

export const fetchDealNavigatorStagesSuccess = (dealNavigatorStage) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_STAGES_SUCCEEDED,
    result: dealNavigatorStage,
  };
};

export const fetchDealNavigatorStagesFailed = (error) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_STAGES_FAILED,
    result: error,
  };
};

export const fetchDealNavigatorStages = (id) => {
  return (dispatch) => {
    dispatch(fetchDealNavigatorStagesRequest());
    return api(formUrl(API.dealNavigator.stages.getById, { id: id }))
      .then((response) => {
        dispatch(fetchDealNavigatorStagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealNavigatorStagesFailed(error.message));
      });
  };
};

// DEAL NAVIGATOR CARDS
export const fetchDealNavigatorCardsRequest = () => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_CARDS_REQUEST,
  };
};

export const fetchDealNavigatorCardsSuccess = (dealNavigatorCard) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_CARDS_SUCCEEDED,
    result: dealNavigatorCard,
  };
};

export const fetchDealNavigatorCardsFailed = (error) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_CARDS_FAILED,
    result: error,
  };
};

export const fetchDealNavigatorCards = (id) => {
  return (dispatch) => {
    dispatch(fetchDealNavigatorCardsRequest());
    return api(formUrl(API.dealNavigator.cards.getById, { id: id }))
      .then((response) => {
        dispatch(fetchDealNavigatorCardsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealNavigatorCardsFailed(error.message));
      });
  };
};

// DEAL NAVIGATOR ELEMENTS
export const fetchDealNavigatorElementsRequest = () => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_ELEMENTS_REQUEST,
  };
};

export const fetchDealNavigatorElementsSuccess = (dealNavigatorElement) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED,
    result: dealNavigatorElement,
  };
};

export const fetchDealNavigatorElementsFailed = (error) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_ELEMENTS_FAILED,
    result: error,
  };
};

export const fetchDealNavigatorElements = (id) => {
  return (dispatch) => {
    dispatch(fetchDealNavigatorElementsRequest());
    return api(formUrl(API.dealNavigator.cards.getById, { id: id }))
      .then((response) => {
        dispatch(fetchDealNavigatorElementsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealNavigatorElementsFailed(error.message));
      });
  };
};

// // DEAL NAVIGATOR DOCUMENTS
// export const fetchDealNavigatorDocumentsRequest = () => {
//   return {
//     type: actionTypes.FETCH_DEAL_NAVIGATOR_DOCUMENTS_REQUEST
//   };
// };

// export const fetchDealNavigatorDocumentsSuccess = dealNavigatorDocument => {
//   return {
//     type: actionTypes.FETCH_DEAL_NAVIGATOR_DOCUMENTS_SUCCEEDED,
//     result: dealNavigatorDocument
//   };
// };

// export const fetchDealNavigatorDocumentsFailed = error => {
//   return {
//     type: actionTypes.FETCH_DEAL_NAVIGATOR_DOCUMENTS_FAILED,
//     result: error
//   };
// };

// export const fetchDealNavigatorDocuments = () => {
//   return dispatch => {
//     dispatch(fetchDealNavigatorDocumentsRequest());
//     return api(API.dealNavigator.stages.cards.elements.document.getById)
//       .then(response => {
//         dispatch(fetchDealNavigatorDocumentsSuccess(response.data));
//       })
//       .catch(error => {
//         dispatch(fetchDealNavigatorDocumentsFailed(error.message));
//       });
//   };
// };

// #endregion

// #region ADD
// DEAL NAVIGATOR STAGES
export const addDealNavigatorStagesRequest = () => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_STAGES_REQUEST,
  };
};

export const addDealNavigatorStagesSuccess = (dealNavigatorStage) => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_STAGES_SUCCEEDED,
    result: dealNavigatorStage,
  };
};

export const addDealNavigatorStagesFailed = (error) => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_STAGES_FAILED,
    result: error,
  };
};

export const addDealNavigatorStages = (stage) => {
  return (dispatch) => {
    dispatch(addDealNavigatorStagesRequest());
    return api(API.dealNavigator.stages.create, stage)
      .then((response) => {
        dispatch(addDealNavigatorStagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addDealNavigatorStagesFailed(error.message));
        toast.error(toastMessages.dealNavigator.errorAddingStage);
      })
      .finally(() => {
        window.location.reload();
      });
  };
};

// DEAL NAVIGATOR CARDS
export const addDealNavigatorCardsRequest = () => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_CARDS_REQUEST,
  };
};

export const addDealNavigatorCardsSuccess = (dealNavigatorCard) => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_CARDS_SUCCEEDED,
    result: dealNavigatorCard,
  };
};

export const addDealNavigatorCardsFailed = (error) => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_CARDS_FAILED,
    result: error,
  };
};

export const addDealNavigatorCards = (card) => {
  return (dispatch) => {
    dispatch(addDealNavigatorCardsRequest());
    return api(API.dealNavigator.cards.create, card)
      .then((response) => {
        dispatch(addDealNavigatorCardsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addDealNavigatorCardsFailed(error.message));
        toast.error(toastMessages.dealNavigator.errorAddingCard);
      });
  };
};

// DEAL NAVIGATOR ELEMENTS
export const addDealNavigatorElementsRequest = () => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_ELEMENTS_REQUEST,
  };
};

export const addDealNavigatorElementsSuccess = (dealNavigatorElement) => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED,
    result: dealNavigatorElement,
  };
};

export const addDealNavigatorElementsFailed = (error) => {
  return {
    type: actionTypes.ADD_DEAL_NAVIGATOR_ELEMENTS_FAILED,
    result: error,
  };
};

export const addDealNavigatorElements = (formData) => {
  return (dispatch) => {
    dispatch(addDealNavigatorElementsRequest());
    return api(API.dealNavigator.elements.create, formData)
      .then((response) => {
        dispatch(addDealNavigatorElementsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addDealNavigatorElementsFailed(error.message));
        toast.error(error.response.data ?? toastMessages.dealNavigator.errorAddingElement);
      });
  };
};
// #endregion

// #region CHANGE
// DEAL NAVIGATOR STAGES
export const changeDealNavigatorStagesRequest = (dealNavigatorStage) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_STAGES_REQUEST,
    result: dealNavigatorStage,
  };
};

export const changeDealNavigatorStagesSuccess = (newStage) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_STAGES_SUCCEEDED,
    result: newStage,
  };
};

export const changeDealNavigatorStagesFailed = (error) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_STAGES_FAILED,
    result: error,
  };
};

export const changeDealNavigatorStages = (newStage, prevStage) => {
  return (dispatch) => {
    dispatch(changeDealNavigatorStagesRequest(newStage));

    return api(API.dealNavigator.stages.update, newStage)
      .then((response) => {
        dispatch(changeDealNavigatorStagesSuccess(newStage));
      })
      .catch((error) => {
        dispatch(
          changeDealNavigatorStagesFailed({
            prevState: prevStage,
            message: error.message,
          })
        );
      });
  };
};

// DEAL NAVIGATOR CARDS
export const changeDealNavigatorCardsRequest = () => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_CARDS_REQUEST,
  };
};

export const changeDealNavigatorCardsSuccess = (dealNavigatorCard) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_CARDS_SUCCEEDED,
    result: dealNavigatorCard,
  };
};

export const changeDealNavigatorCardsFailed = (error) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_CARDS_FAILED,
    result: error,
  };
};

export const changeDealNavigatorCards = (card) => {
  return (dispatch) => {
    dispatch(changeDealNavigatorCardsRequest());
    return api(formUrl(API.dealNavigator.cards.update, card))
      .then((response) => {
        dispatch(changeDealNavigatorCardsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(changeDealNavigatorCardsFailed(error.message));
        toast.error(toastMessages.dealNavigator.errorChangeCard);
      });
  };
};

// DEAL NAVIGATOR ELEMENTS
export const changeDealNavigatorElementsRequest = () => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_ELEMENTS_REQUEST,
  };
};

export const changeDealNavigatorElementsSuccess = (dealNavigatorElement) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED,
    result: dealNavigatorElement,
  };
};

export const changeDealNavigatorElementsFailed = (error) => {
  return {
    type: actionTypes.CHANGE_DEAL_NAVIGATOR_ELEMENTS_FAILED,
    result: error,
  };
};

export const changeDealNavigatorElements = (id) => {
  return (dispatch) => {
    dispatch(changeDealNavigatorElementsRequest());
    return api(formUrl(API.dealNavigator.elements.update, { id: id }))
      .then((response) => {
        dispatch(changeDealNavigatorElementsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(changeDealNavigatorElementsFailed(error.message));
        toast.error(toastMessages.dealNavigator.errorChangeElement);
      });
  };
};
// #endregion

// #region REMOVE
// DEAL NAVIGATOR STAGES
export const removeDealNavigatorStagesRequest = () => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_STAGES_REQUEST,
  };
};

export const removeDealNavigatorStagesSuccess = (dealNavigatorStage) => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_STAGES_SUCCEEDED,
    result: dealNavigatorStage,
  };
};

export const removeDealNavigatorStagesFailed = (error) => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_STAGES_FAILED,
    result: error,
  };
};

export const removeDealNavigatorStages = (id) => {
  return (dispatch) => {
    dispatch(removeDealNavigatorStagesRequest());
    return api(formUrl(API.dealNavigator.stages.remove, { id: id }))
      .then((response) => {
        dispatch(removeDealNavigatorStagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(removeDealNavigatorStagesFailed(error.message));
        toast.error(toastMessages.dealNavigator.errorRemoveStage);
      });
  };
};

// DEAL NAVIGATOR CARDS
export const removeDealNavigatorCardsRequest = (dealNavigatorCard) => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_CARDS_REQUEST,
    result: dealNavigatorCard,
  };
};

export const removeDealNavigatorCardsSuccess = () => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_CARDS_SUCCEEDED,
  };
};

export const removeDealNavigatorCardsFailed = (error) => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_CARDS_FAILED,
    result: error,
  };
};

export const removeDealNavigatorCards = (card) => {
  return (dispatch) => {
    dispatch(removeDealNavigatorCardsRequest(card));
    return api(formUrl(API.dealNavigator.cards.remove, { id: card.id }))
      .then((response) => {
        dispatch(removeDealNavigatorCardsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(removeDealNavigatorCardsFailed(error.message));
      });
  };
};

// DEAL NAVIGATOR ELEMENTS
export const removeDealNavigatorElementsRequest = () => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_ELEMENTS_REQUEST,
  };
};

export const removeDealNavigatorElementsSuccess = (dealNavigatorElement) => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_ELEMENTS_SUCCEEDED,
    result: dealNavigatorElement,
  };
};

export const removeDealNavigatorElementsFailed = (error) => {
  return {
    type: actionTypes.REMOVE_DEAL_NAVIGATOR_ELEMENTS_FAILED,
    result: error,
  };
};

export const removeDealNavigatorElements = (id) => {
  return (dispatch) => {
    dispatch(removeDealNavigatorElementsRequest());
    return api(formUrl(API.dealNavigator.elements.remove, { id: id }))
      .then((response) => {
        dispatch(removeDealNavigatorElementsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(removeDealNavigatorElementsFailed(error.message));
        toast.error(toastMessages.dealNavigator.errorRemoveCard);
      });
  };
};
// #endregion

// #region REORDER & SWAP
export const reorderDealNavigatorStagesRequest = (dealNavigatorStage) => {
  return {
    type: actionTypes.REORDER_DEAL_NAVIGATOR_STAGES_REQUEST,
    result: dealNavigatorStage,
  };
};

export const reorderDealNavigatorStagesSuccess = () => {
  return {
    type: actionTypes.REORDER_DEAL_NAVIGATOR_STAGES_SUCCEEDED,
  };
};

export const reorderDealNavigatorStagesFailed = (error) => {
  return {
    type: actionTypes.REORDER_DEAL_NAVIGATOR_STAGES_FAILED,
    result: error,
  };
};

export const reorderDealNavigatorStage = (newStage, oldStage) => {
  return (dispatch) => {
    dispatch(reorderDealNavigatorStagesRequest(newStage));
    return api(API.dealNavigator.stages.reorder, newStage)
      .then((response) => {
        dispatch(reorderDealNavigatorStagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          reorderDealNavigatorStagesFailed({
            prevState: oldStage,
            message: error.message,
          })
        );
      });
  };
};

export const reorderDealNavigatorCardsRequest = (dealNavigatorCard) => {
  return {
    type: actionTypes.REORDER_DEAL_NAVIGATOR_CARDS_REQUEST,
    result: dealNavigatorCard,
  };
};

export const reorderDealNavigatorCardsSuccess = () => {
  return {
    type: actionTypes.REORDER_DEAL_NAVIGATOR_CARDS_SUCCEEDED,
  };
};

export const reorderDealNavigatorCardsFailed = (error) => {
  return {
    type: actionTypes.REORDER_DEAL_NAVIGATOR_CARDS_FAILED,
    result: error,
  };
};

export const reorderDealNavigatorCard = (newCard, oldCard) => {
  return (dispatch) => {
    dispatch(reorderDealNavigatorCardsRequest(newCard));
    return api(API.dealNavigator.cards.reorder, newCard)
      .then((response) => {
        dispatch(reorderDealNavigatorCardsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          reorderDealNavigatorCardsFailed({
            prevState: oldCard,
            message: error.message,
          })
        );
      });
  };
};

export const swapDealNavigatorCardsRequest = (dealNavigatorCard) => {
  return {
    type: actionTypes.SWAP_DEAL_NAVIGATOR_CARDS_REQUEST,
    result: dealNavigatorCard,
  };
};

export const swapDealNavigatorCardsSuccess = () => {
  return {
    type: actionTypes.SWAP_DEAL_NAVIGATOR_CARDS_SUCCEEDED,
  };
};

export const swapDealNavigatorCardsFailed = (error) => {
  return {
    type: actionTypes.SWAP_DEAL_NAVIGATOR_CARDS_FAILED,
    result: error,
  };
};

export const swapDealNavigatorCard = (newCard, oldCard) => {
  return (dispatch) => {
    dispatch(swapDealNavigatorCardsRequest(newCard));
    return api(API.dealNavigator.cards.swap, newCard)
      .then((response) => {
        dispatch(swapDealNavigatorCardsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          swapDealNavigatorCardsFailed({
            prevState: oldCard,
            message: error.message,
          })
        );
      });
  };
};
// #endregion

// #region PUBLISH or DISCARD
export const publishDealNavigatorRequest = () => {
  return {
    type: actionTypes.PUBLISH_DEAL_NAVIGATOR_REQUEST,
  };
};

export const publishDealNavigatorSuccess = (dealNavigator) => {
  return {
    type: actionTypes.PUBLISH_DEAL_NAVIGATOR_SUCCEEDED,
    result: dealNavigator,
  };
};

export const publishDealNavigatorFailed = (error) => {
  return {
    type: actionTypes.PUBLISH_DEAL_NAVIGATOR_FAILED,
    result: error,
  };
};

export const publishDealNavigator = (val) => {
  if (val == null) {
    return (dispatch) => {
      dispatch(publishDealNavigatorRequest());
      return api(API.dealNavigator.publishDraftVersion)
        .then((response) => {
          dispatch(publishDealNavigatorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(publishDealNavigatorFailed(error.message));
        });
    };
  } else {
    return (dispatch) => {
      dispatch(publishDealNavigatorRequest());
      return api(formUrl(API.dealNavigator.publishTemplate, { templateId: val }))
        .then((response) => {
          dispatch(publishDealNavigatorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(publishDealNavigatorFailed(error.message));
        });
    };
  }
};

export const discardDealNavigatorRequest = () => {
  return {
    type: actionTypes.DISCARD_DEAL_NAVIGATOR_REQUEST,
  };
};

export const discardDealNavigatorSuccess = (dealNavigator) => {
  return {
    type: actionTypes.DISCARD_DEAL_NAVIGATOR_SUCCEEDED,
    result: dealNavigator,
  };
};

export const discardDealNavigatorFailed = (error) => {
  return {
    type: actionTypes.DISCARD_DEAL_NAVIGATOR_FAILED,
    result: error,
  };
};

export const discardDealNavigator = (val) => {
  if (val == null) {
    return (dispatch) => {
      dispatch(discardDealNavigatorRequest());
      return api(API.dealNavigator.discardDraftVersion)
        .then((response) => {
          dispatch(discardDealNavigatorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(discardDealNavigatorFailed(error.message));
        });
    };
  } else {
    return (dispatch) => {
      dispatch(discardDealNavigatorRequest());
      return api(
        formUrl(API.dealNavigator.discardTemplateDraftVersion, {
          templateId: val,
        })
      )
        .then((response) => {
          dispatch(discardDealNavigatorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(discardDealNavigatorFailed(error.message));
        });
    };
  }
};
// #endregion

//#region DEAL NAVIGATOR TEMPLATE
export const chooseDealNavigatorTemplateRequest = () => {
  return {
    type: actionTypes.CHOOSE_DEAL_NAVIGATOR_TEMPLATE_REQUEST,
  };
};

export const chooseDealNavigatorTemplateSuccess = (dealNavigator) => {
  return {
    type: actionTypes.CHOOSE_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED,
    result: dealNavigator,
  };
};

export const chooseDealNavigatorTemplateFailed = (error) => {
  return {
    type: actionTypes.CHOOSE_DEAL_NAVIGATOR_TEMPLATE_FAILED,
    result: error,
  };
};

export const chooseDealNavigatorTemplate = (val) => {
  return (dispatch) => {
    dispatch(chooseDealNavigatorTemplateRequest());
    return api(formUrl(API.dealNavigator.chooseTemplate, { templateId: val }))
      .then((response) => {
        dispatch(chooseDealNavigatorTemplateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(chooseDealNavigatorTemplateFailed(error.message));
      });
  };
};

export const fetchDealNavigatorTemplateRequest = () => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_TEMPLATE_REQUEST,
  };
};

export const fetchDealNavigatorTemplateSuccess = (dealNavigator) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_TEMPLATE_SUCCEEDED,
    result: dealNavigator,
  };
};

export const fetchDealNavigatorTemplateFailed = (error) => {
  return {
    type: actionTypes.FETCH_DEAL_NAVIGATOR_TEMPLATE_FAILED,
    result: error,
  };
};

export const fetchDealNavigatorTemplate = (val) => {
  return (dispatch) => {
    dispatch(fetchDealNavigatorTemplateRequest());
    return api(formUrl(API.dealNavigator.getTemplate))
      .then((response) => {
        dispatch(fetchDealNavigatorTemplateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealNavigatorTemplateFailed(error.message));
      });
  };
};
//#endregion

//#region RESET
export const resetDealNavigatorRequest = () => {
  return {
    type: actionTypes.RESET_DEAL_NAVIGATOR_REQUEST,
  };
};

export const resetDealNavigatorSuccess = (dealNavigator) => {
  return {
    type: actionTypes.RESET_DEAL_NAVIGATOR_SUCCEEDED,
    result: dealNavigator,
  };
};

export const resetDealNavigatorFailed = (error) => {
  return {
    type: actionTypes.RESET_DEAL_NAVIGATOR_FAILED,
    result: error,
  };
};

export const resetDealNavigator = () => {
  return (dispatch) => {
    dispatch(resetDealNavigatorRequest());
    api(formUrl(API.dealNavigator.reset))
      .then((response) => {
        dispatch(resetDealNavigatorSuccess(response.data));
      })
      .catch((error) => {
        dispatch(resetDealNavigatorFailed(error.message));
      });
  };
};
//#endregion RESET
