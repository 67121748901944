import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./ExclusionBox.module.scss";

const ExclusionBox = (props) => {
  return (
    <div className={styles.boxWraper}>
      <div className={styles.boxInfo}>
        <p className={styles.number}>{props.count}</p>
        <p className={styles.typeOfExclusion}>{props.type}</p>
        <p className={styles.exclusionOwner}>{props.owner}</p>
      </div>
      {props.withoutLink ? null : (
        <>
          <hr className={styles.customHr} />

          <NavLink
            to={{
              pathname: "/" + props.orgId + "/exclusions",
              className: styles.link,
            }}
          >
            View All
          </NavLink>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExclusionBox);
