export const columns = [
  {
    label: "Email Subject",
    field: "subject",
    type: "text",
    sort: "asc",
    minimal: "lg",
    minWidth: "170",
    width: "170",
    flex: 1,
    showTooltip: true,
  },
  {
    label: "Sender Account",
    field: "senderAccount",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: "120",
    showTooltip: true,
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: "190",
  },
  {
    label: "Date Received",
    field: "dateReceived",
    type: "date",
    sort: "asc",
    minimal: "sm",
    width: "190",
  },
  {
    label: "Delivered to Inbox",
    field: "deliveredToInbox",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    minimal: "sm",
    width: "190",
  },
  {
    label: "Delivered to Tabs",
    field: "deliveredToTabs",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    minimal: "sm",
    width: "190",
  },
  {
    label: "Delivered to Spam",
    field: "deliveredToSpam",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    minimal: "sm",
    width: "190",
  },
  {
    label: "Missing",
    field: "missing",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    minimal: "sm",
    width: "110",
  },
  {
    label: "Completed",
    field: "completed",
    type: "actions",
    sort: "asc",
    minimal: "sm",
    width: "110",
  },
];
