export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_FROM_STORAGE = "LOAD_USER_FROM_STORAGE";
export const CHANGE_ORGANIZATION_SUCCESS = "CHANGE_ORGANIZATION_SUCCESS";

export const CHANGE_USER_INFO_SUCCESS = "CHANGE_USER_INFO_SUCCESS";
export const CHANGE_JOB_TITLE_SUCCESS = "CHANGE_JOB_TITLE_SUCCESS";
export const CHANGE_LOCATION_SUCCESS = "CHANGE_LOCATION_SUCCESS";

export const FETCH_ORGANIZATION_INVITES_REQUEST = "FETCH_ORGANIZATION_INVITES_REQUEST";
export const FETCH_ORGANIZATION_INVITES_SUCCESS = "FETCH_ORGANIZATION_INVITES_SUCCESS";
export const FETCH_ORGANIZATION_INVITES_FAILED = "FETCH_ORGANIZATION_INVITES_FAILED";

export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST";
export const ACCEPT_INVITE_FAILED = "ACCEPT_INVITE_FAILED";

export const DECLINE_INVITE_REQUEST = "DECLINE_INVITE_REQUEST";
export const DECLINE_INVITE_FAILED = "DECLINE_INVITE_FAILED";

export const UPDATE_ORGANIZATION_GENERAL_SETTINGS_SUCCEEDED = "UPDATE_ORGANIZATION_GENERAL_SETTINGS_SUCCEEDED";

export const LEAVE_ORGANIZATION_REQUEST = "LEAVE_ORGANIZATION_REQUEST";
export const LEAVE_ORGANIZATION_FAILED = "LEAVE_ORGANIZATION_FAILED";
export const LEAVE_ORGANIZATION_SUCCESS = "LEAVE_ORGANIZATION_SUCCESS";

export const DELETE_ORGANIZATION_REQUEST = "DELETE_ORGANIZATION_REQUEST";
export const DELETE_ORGANIZATION_FAILED = "DELETE_ORGANIZATION_FAILED";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";

export const FETCH_GLOBAL_SETTINGS_REQUEST = "FETCH_GLOBAL_SETTINGS_REQUEST";
export const FETCH_GLOBAL_SETTINGS_SUCCESS = "FETCH_GLOBAL_SETTINGS_SUCCESS";
export const FETCH_GLOBAL_SETTINGS_FAILED = "FETCH_GLOBAL_SETTINGS_FAILED";

export const FETCH_IN_APP_NOTIFICATIONS_REQUEST = "FETCH_IN_APP_NOTIFICATIONS_REQUEST";
export const FETCH_IN_APP_NOTIFICATIONS_SUCCESS = "FETCH_IN_APP_NOTIFICATIONS_SUCCESS";
export const FETCH_IN_APP_NOTIFICATIONS_FAILED = "FETCH_IN_APP_NOTIFICATIONS_FAILED";

export const CHANGE_ORGANIZATION_DATE_FORMAT = "CHANGE_ORGANIZATION_DATE_FORMAT";
export const CHANGE_ORGANIZATION_TIME_FORMAT = "CHANGE_ORGANIZATION_TIME_FORMAT";

export const CHANGE_APPLICATION = "CHANGE_APPLICATION";

export const GET_ADMIN_ORGANIZATION_REQUEST = "GET_ADMIN_ORGANIZATION_REQUEST";
export const GET_ADMIN_ORGANIZATION_FAILED = "GET_ADMIN_ORGANIZATION_FAILED";
export const GET_ADMIN_ORGANIZATION_SUCCESS = "GET_ADMIN_ORGANIZATION_SUCCESS";

export const UPDATE_ORGANIZATION_NUMBER_OF_USERS = "UPDATE_ORGANIZATION_NUMBER_OF_USERS";

export const UPDATE_ORGANIZATION_ACCESS_REMOVED = "UPDATE_ORGANIZATION_ACCESS_REMOVED";
