import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import Moment from "moment";
import { subDays } from "date-fns";
import _ from "lodash";

//Redux
import {
  fetchPurchaseTemplates,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
} from "../../../store/EmailTemplates/Table/actions/purchaseTemplate";

//Helpers
import { useQueryTableFilters } from "../../../helper/hooks/useQueryTableFilters";
import { filterData, getFilterValue, filtersToQueryString } from "../../../helper/tableConstants";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import {
  purchaseTemplateStatusDropdown,
  purchaseTemplateStatusText,
  purchaseTemplateStatusVariant,
} from "../../../helper/enums/purchaseTemplateStatusEnum";
import { addOffsetToDate } from "../../../helper/dateHelper";

//Images & Icons
import EmptyTemplatesImage from "../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";
import EyeLineIcon from "remixicon-react/EyeLineIcon";

//Styles
import styles from "./MyOrdersTemplates.module.scss";

//Components
import Table from "../../../components/DataTable/Table";
import { columns } from "./columns";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import OrderStatusCol from "./OrderStatusCol/OrderStatusCol";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import SuccessfullyPurchasedTemplateForm from "../CreatePurchaseTemplateForm/components/SuccessfullyPurchasedTemplateForm/SuccessfullyPurchasedTemplateForm";

const MyOrdersTemplates = (props) => {
  const { param, rows, fetchPurchaseTemplates, initiate, isInitiated, clearInitiated, totalCount, pageCount } = props;

  let dateFormat = useOrgDateFormat();
  let filterTypes = ["Status"];
  let state = useQueryTableFilters(filterTypes);

  useEffect(() => {
    initiate(state);
    const query = new URLSearchParams(window.location.search);
    if (query.get("success") != null) {
      setSuccessfullyRequestForm(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchPurchaseTemplates(param);

      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  const [showSuccessfullyRequestForm, setSuccessfullyRequestForm] = useState(false);
  const handleSuccessfullyRequestFormClose = () => {
    setSuccessfullyRequestForm(false);
    fetchPurchaseTemplates(param);
  };

  const [tableRows, setTableRows] = useState([]);

  const changeUrl = (id) => {
    props.history.push("/" + props.orgId + "/template-request/" + id);
  };

  let selectRowOptions = [
    {
      option: purchaseTemplateStatusDropdown,
      change: (c) => filterData("Status", c, props.setFilter),
      placeholder: "Status",
      value: getFilterValue(param.filter, "Status"),
    },
  ];

  const filterDate = (obj) => {
    let arrayDates = [];
    arrayDates.push(addOffsetToDate(obj.startDate));
    arrayDates.push(addOffsetToDate(obj.endDate));
    filterData("DateRange", arrayDates, props.setFilter);
  };

  let dateOptions = {
    onChange: (c) => filterDate(c),
    showSelectionPreview: true,
    key: "selection",
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    editableDateInputs: false,
    startDate: new Date(subDays(Date.now(), 30)),
    endDate: new Date(Date.now()),
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r) => {
      r.templateName = (
        <Link
          className="tableLink"
          to={{
            pathname: "/" + props.orgId + "/template-request/" + r.id,
          }}
        >
          {r.templateName}
        </Link>
      );
      let actions = (
        <ActionWrapper>
          <div className={styles.iconsWrapper}>
            <OverlayTrigger
              key={"orderDetails"}
              placement={"auto"}
              overlay={
                <Popover id={`tooltip-orderDetails`}>
                  <Popover.Body>View order details</Popover.Body>
                </Popover>
              }
            >
              <div>
                <EyeLineIcon size={14} className={`iconGray ${styles.showPasswordIcon}`} onClick={() => changeUrl(r.id)} />
              </div>
            </OverlayTrigger>
          </div>
        </ActionWrapper>
      );
      r.actions = actions;
      r.status = <OrderStatusCol variant={purchaseTemplateStatusVariant[r.status]} status={purchaseTemplateStatusText[r.status]} />;
      r.createdDate = Moment(r.createdDate).format(dateFormat);
      r.dueDate = Moment(r.dueDate).format(dateFormat);

      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  let table = null;
  let image = <EmptyTemplatesImage className={styles.noDataImage} />;
  if (props.error) {
    table = <h2>Error loading My Orders</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  table = table =
    tableRows.length > 0 ? (
      <Table
        tableName={"Users"}
        columns={columns}
        rows={tableRows}
        pageCount={pageCount}
        totalCount={totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        tableHeight={366}
        variant="medium"
      />
    ) : (
      <EmptyState
        image={image}
        title="No purchased email templates found"
        content="Let’s start with creating your first email template."
        description='Click on the "Purchase Template" button above.'
      />
    );

  return (
    <div className="contentHeight">
      <TableFiltersRow
        selects={selectRowOptions}
        placeholder="..."
        changeFilter={(text) => props.searchRecords(text)}
        searchOnFirstCharacter={true}
        dateOptions={dateOptions}
      />
      {table}
      <SuccessfullyPurchasedTemplateForm handleClose={handleSuccessfullyRequestFormClose} showModal={showSuccessfullyRequestForm} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.purchaseTemplateTable.params,
    isInitiated: state.purchaseTemplateTable.isInitiated,
    isLoading: state.purchaseTemplateTable.isLoading,
    isLoaded: state.purchaseTemplateTable.isLoaded,
    error: state.purchaseTemplateTable.error,
    rows: state.purchaseTemplateTable.rows,
    totalCount: state.purchaseTemplateTable.totalCount,
    pageCount: state.purchaseTemplateTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPurchaseTemplates: (param) => dispatch(fetchPurchaseTemplates(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrdersTemplates);
