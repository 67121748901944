import { FC, useState, useEffect, useCallback } from "react";

// Redux
import {
  useApplyOptimizationMutation,
  useAskForHelpOptimizationMutation,
  useDismissOptimizationMutation,
  useGetCompletedForCampaignQuery,
  useGetCountsForCampaignQuery,
  useGetForCampaignQuery,
} from "@api/optimizationApi";
import useRouteParams from "@hooks/useRouteParams";
import { OptimizationCategoryEnum, optimizationCategoryToString } from "@models/campaign/OptimizationCategoryEnum";
import { OptimizationPriorityEnum } from "@models/campaign/OptimizationPriorityEnum";
import { OptimizationStatusEnum } from "@models/campaign/OptimizationStatusEnum";

// Helpers
import { getEnumKeys } from "@helper/enumHelper";

// Styles
import styles from "./CampaignOptimizations.module.scss";

// Components
import StatusCard from "@ui/StatusCard/StatusCard";
import FilterButtons from "@components/FilterButtons/FilterButtons";
import OptimizationStatusCards from "./Components/OptimizationStatusCards/OptimizationStatusCards";
import PastRecommendations from "./Components/PastRecommendations/PastRecommendations";
import PriorityFilter from "./Components/PriorityFitler/PriorityFitler";
import EmptyState from "@ui/EmptyState/EmptyState";
import Spinner from "@ui/Spinner/Spinner";
import SortByFilter from "./Components/SortByFilter/SortByFilter";

interface Item {
  id: number;
  category: OptimizationCategoryEnum;
  priority: OptimizationPriorityEnum;
  status: OptimizationStatusEnum;
}

const CampaignOptimizations: FC = () => {
  const { campaignId } = useRouteParams();
  const [activeCategory, setActiveCategory] = useState<OptimizationCategoryEnum | null>(null);
  const [activeStatus, setActiveStatus] = useState<string>("active");
  const { data: countsData } = useGetCountsForCampaignQuery(campaignId, { skip: !campaignId });
  const { data, isLoading, isSuccess } = useGetForCampaignQuery(
    { campaignId: campaignId, category: null, priority: null },
    { refetchOnMountOrArgChange: true, skip: !campaignId }
  );
  const { data: pastRecommendations } = useGetCompletedForCampaignQuery({
    campaignId,
    category: null,
    priority: null,
  });

  const [sortBy, setSortBy] = useState<"Latest" | "Priority">("Priority");
  const [activePriorityFilters, setActivePriorityFilters] = useState<OptimizationPriorityEnum[]>([]);

  const [dismiss] = useDismissOptimizationMutation();
  const [apply] = useApplyOptimizationMutation();
  const [askForHelp] = useAskForHelpOptimizationMutation();

  const renderStatusCards = useCallback(
    (items: Item[]) => {
      return items.map((item) => (
        <StatusCard
          model={item}
          key={item.id}
          onMarkAsDoneClick={() => apply(item.id)}
          onDismissClick={() => dismiss(item.id)}
          onHelpMeClick={() => askForHelp(item.id)}
          onRedirect = {() => apply(item.id)}
        />
      ));
    },
    [apply, dismiss, askForHelp]
  );

  const statusCounts = {
    active: countsData?.active || 0,
    inProgress: countsData?.inProgress || 0,
    completed: countsData?.completed || 0,
    total: countsData?.total || 0,
    dismissed: countsData?.dismissed || 0,
  };

  useEffect(() => {
    setActivePriorityFilters([]);
  }, [campaignId]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!isSuccess) {
    return <div>No optimizations available. All good!</div>;
  }

  const allCategories = Object.values(OptimizationCategoryEnum);
  const counts = getEnumKeys(OptimizationCategoryEnum).reduce((acc, key) => {
    const count = data?.filter((item) => item.category === Number(key)).length;
    return { ...acc, [optimizationCategoryToString(Number(key))]: count };
  }, {});

  const handleSetActiveCategory = (cat: string | null) => {
    if (cat === null) {
      setActiveCategory(null);
    } else {
      const categoryEnumValue = allCategories.find(
        (enumValue) => optimizationCategoryToString(enumValue as OptimizationCategoryEnum) === cat
      ) as OptimizationCategoryEnum;
      setActiveCategory(categoryEnumValue);
    }
  };

  const handlePriorityFilterChange = (priority: OptimizationPriorityEnum) => {
    setActivePriorityFilters((prevFilters) => {
      if (prevFilters.includes(priority)) {
        return prevFilters.filter((filter) => filter !== priority);
      } else {
        return [...prevFilters, priority];
      }
    });
  };

  const handleStatusClick = (status: string) => {
    setActiveStatus(status);
  };

  const sortedData = [...data];
  if (sortBy === "Priority") {
    sortedData.sort((a, b) => a.priority - b.priority);
  } else {
    sortedData.reverse(); // Reverse the order of items to show the latest ones first
  }

  // Filter out any invalid enum values
  const validPriorities = Object.values(OptimizationPriorityEnum).filter(
    (value): value is OptimizationPriorityEnum => typeof value === "number"
  );

  const filteredData = sortedData.filter(
    (item) =>
      (activeCategory === null || optimizationCategoryToString(item.category) === optimizationCategoryToString(activeCategory)) &&
      (activePriorityFilters.length === 0 || activePriorityFilters.includes(item.priority)) &&
      (activeStatus === "total" ||
        (activeStatus === "active" && item.status === OptimizationStatusEnum.Active) ||
        (activeStatus === "inProgress" &&
          (item.status === OptimizationStatusEnum.SupportProcessing || item.status === OptimizationStatusEnum.Processing)) ||
        (activeStatus === "dismissed" && item.status === OptimizationStatusEnum.Dismissed))
  );

  const filteredPastRecommendations =
    pastRecommendations?.filter(
      (item) =>
        (activeCategory === null || optimizationCategoryToString(item.category) === optimizationCategoryToString(activeCategory)) &&
        (activePriorityFilters.length === 0 || activePriorityFilters.includes(item.priority)) &&
        ((activeStatus === "completed" && item.status === OptimizationStatusEnum.Applied) ||
          (activeStatus === "dismissed" && item.status === OptimizationStatusEnum.Dismissed))
    ) || [];

  const handleSortByChange = (option: "Latest" | "Priority") => {
    setSortBy(option);
  };

  return (
    <>
      <h1 className={styles.sectionTitle}>Recommendations</h1>
      <p className={styles.subtitleDescription}>
        Welcome to your campaign optimization dashboard. Here, you can monitor and improve your campaign with actionable recommendations.
      </p>
      <OptimizationStatusCards
        className={styles.statusCards}
        statusCounts={statusCounts}
        totalCount={statusCounts.total}
        activeStatus={activeStatus}
        onStatusClick={handleStatusClick}
      />
      <div className={styles.filtersWrapper}>
        <FilterButtons
          categories={getEnumKeys(OptimizationCategoryEnum).map((key) => optimizationCategoryToString(Number(key)))}
          activeCategory={activeCategory !== null ? optimizationCategoryToString(activeCategory) : null}
          allCategoriesCount={data?.length || 0}
          counts={counts}
          setActiveCategory={handleSetActiveCategory}
        />

        <div className="flex">
          <PriorityFilter
            activePriorityFilters={activePriorityFilters}
            handlePriorityFilterChange={handlePriorityFilterChange}
            validPriorities={validPriorities}
          />
          <SortByFilter sortBy={sortBy} onSortChange={handleSortByChange} />
        </div>
      </div>

      {statusCounts.total === 0 ? (
        <EmptyState
          componentClass="mT10"
          title="No optimizations available"
          description="There are currently no optimizations available for this campaign."
        />
      ) : (
        <>
          {filteredData.length === 0 && filteredPastRecommendations.length === 0 && (
            <EmptyState componentClass="mT10" title="No results found" description="No recommendations match the selected filters." />
          )}
          <div className={styles.cardsWrapper}>
            {renderStatusCards(filteredData)}
            {activeStatus === "completed" && renderStatusCards(filteredPastRecommendations)}
            {activeStatus === "dismissed" && renderStatusCards(filteredPastRecommendations)}
          </div>
        </>
      )}

      {activeStatus === "total" && <PastRecommendations campaignId={campaignId} />}
    </>
  );
};

export default CampaignOptimizations;
