import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  sendingSchedule: [],
};

const fetchSendingScheduleSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.sendingSchedule = action.result;
  return newState;
};

const fetchSendingScheduleRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchSendingScheduleFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SENDING_SCHEDULE_REQUEST:
      return fetchSendingScheduleRequest(state, action);
    case actionTypes.FETCH_SENDING_SCHEDULE_SUCCEEDED:
      return fetchSendingScheduleSuccess(state, action);
    case actionTypes.FETCH_SENDING_SCHEDULE_FAILED:
      return fetchSendingScheduleFailed(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
