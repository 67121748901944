import React from "react";
import { connect } from "react-redux";

//Helpers
import { activeFilterHandler } from "../../../../helper/tableConstants";

//Styles
import styles from "./StagesStats.module.scss";

//Components
import ProspectBox from "../../../../components/DataTable/ProspectBox/ProspectBox";

const StagesStats = (props) => {
  const { statuses } = props;

  const filterStatus = (status) => {
    activeFilterHandler("Status", status, props.param, props.setFilter, props.clearFilter);
  };

  return (
    <div className={`${styles.wrapper} flex`}>
      <div onClick={() => filterStatus(0)} className={`${styles.cardWrapper} ${styles.awaitingCard}`}>
        <ProspectBox
          totalClassName={styles.total}
          number={statuses.awaitingEngagement}
          status={"Awaiting Engagement"}
          className={`${styles.card} ${styles.awaiting}`}
        />
      </div>
      <div onClick={() => filterStatus(1)} className={styles.cardWrapper}>
        <ProspectBox
          totalClassName={styles.total}
          number={statuses.engaged}
          status={"Engaged"}
          className={`${styles.card} ${styles.engaged}`}
        />
      </div>
      <div onClick={() => filterStatus(2)} className={styles.cardWrapper}>
        <ProspectBox
          totalClassName={styles.total}
          number={statuses.opened}
          status={"Opened"}
          className={`${styles.card} ${styles.opened}`}
        />
      </div>
      <div onClick={() => filterStatus(3)} className={styles.cardWrapper}>
        <ProspectBox
          totalClassName={styles.total}
          number={statuses.clicked}
          status={"Clicked"}
          className={`${styles.card} ${styles.clicked}`}
        />
      </div>
      <div onClick={() => filterStatus(4)} className={styles.cardWrapper}>
        <ProspectBox
          totalClassName={styles.total}
          number={statuses.responded}
          status={"Responded"}
          className={`${styles.card} ${styles.responded}`}
        />
      </div>
      <div onClick={() => filterStatus(5)} className={styles.cardWrapper}>
        <ProspectBox totalClassName={styles.total} number={statuses.leads} status={"Leads"} className={`${styles.card} ${styles.leads}`} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    statuses: state.companyActivityOverview.statuses,
    param: state.companyActivityOverviewTable.params,
  };
};

export default connect(mapStateToProps)(StagesStats);
