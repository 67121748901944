export const FETCH_AUDIENCES_REQUEST = "FETCH_AUDIENCES_REQUEST";
export const FETCH_AUDIENCES_SUCCEEDED = "FETCH_AUDIENCES_SUCCEEDED";
export const FETCH_AUDIENCES_FAILED = "FETCH_AUDIENCES_FAILED";

export const FETCH_AUDIENCE_DETAILS_REQUEST = "FETCH_AUDIENCE_DETAILS_REQUEST";
export const FETCH_AUDIENCE_DETAILS_SUCCEEDED = "FETCH_AUDIENCE_DETAILS_SUCCEEDED";
export const FETCH_AUDIENCE_DETAILS_FAILED = "FETCH_AUDIENCE_DETAILS_FAILED";

export const AUDIENCE_DETAILS_UPDATE = "AUDIENCE_DETAILS_UPDATE";
export const AUDIENCE_STATS_UPDATE = "AUDIENCE_STATS_UPDATE";
