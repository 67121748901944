import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import _ from "lodash";

//Helpers
import api, { API } from "../../helper/api/api";
import { useOrgNumberFormatting } from "../../helper/hooks/useOrgNumberFormatting";
import { errorResponseToList } from "../../helper/errorHelper";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";
import useSelectList from "../../helper/hooks/query/useSelectList";

//Components
import Modal from "../../components/UI/Modal/Modal";
import FormikInput from "../../components/UI/Formik/FormikInput";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Spinner from "../../components/UI/Spinner/Spinner";
import FormikSelect from "../../components/UI/Formik/FormikSelect";
import queryString from "query-string";
import { useSlice } from "@hooks/useSlice";
import { useHistory } from "react-router-dom";

const EditAudienceForm = (props) => {
  const audienceExplorerSlice = useSlice((state) => state.explorer);
  const { excludedContacts } = audienceExplorerSlice;

  let peopleSelected = useOrgNumberFormatting(parseInt(props.peopleCount - excludedContacts.length));
  let peopleCount = useOrgNumberFormatting(props.peopleCount);

  const [isLoading, setIsLoading] = useState(false);
  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  const { camId: campaignId } = queryString.parse(location.search);

  const history = useHistory();

  return (
    <Formik
      initialValues={{
        excludedContacts: _.cloneDeep(excludedContacts),
        name: props.audienceName != null ? props.audienceName : "",
        excludeDuplicateProspects: true,
        preselectedCampaigns: props.campaignIds,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        setIsLoading(true);
        let data = {
          excludedContacts: values.excludedContacts,
          audienceName: values.name,
          excludeDuplicateProspects: values.excludeDuplicateProspects,
          totalContacts: parseInt(props.peopleCount - excludedContacts.length),
          searchId: props.searchId,
          audienceId: props.audienceId,
          campaignIds: values.assignToCampaign != null ? values.assignToCampaign : values.preselectedCampaigns,
        };
        api(API.audienceExplorer.editAudience, data)
          .then((response) => {
            props.handleClose();
            setIsLoading(false);
            props.setSearchId(_.cloneDeep(null));
            props.setFilterOptions(_.cloneDeep(null));

            if (campaignId && data.campaignIds && data.campaignIds.includes(campaignId.toString())) {
              props.history.push("/" + props.orgId + "/campaigns/" + campaignId + "/overview");
            } else {
              history.push("/" + props.orgId + "/lists");
            }
          })
          .catch((error) => {
            setIsLoading(false);

            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          });
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, setFieldValue } = formikProps;
        return (
          <Modal
            title={"Save List"}
            handleClose={() => props.handleClose()}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText={"Cancel"}
            saveButtonText={"Save"}
            saveButtonDisabled={isLoading}
            btnType="submit"
            customBodyClass="overflow_unset max_height_none"
          >
            <p className="f12">
              To keep your recent list changes, save them as a new lit below and choose whether to assign to the same campaign now or later
            </p>
            <FormikInput label={"List name (*)"} name={"name"} value={values.name} placeholder={"Type Here..."} />

            <FormikSelect
              isMenuPortalTargetDisabled={true}
              label="Assign to campaign: (Optional) "
              name="assignToCampaign"
              displayName="Select campaigns/s"
              isMulti={true}
              isSearchable={true}
              showValuesOutside={true}
              options={campaignsList}
              value={values.preselectedCampaigns}
            />
            <p className="f12">
              <b>NOTE:</b> Your new list will overwrite the existing one in the campaign
            </p>

            <Checkbox
              label={"Exclude contacts that are used in other Campaigns."}
              checked={true}
              name={"excludeDuplicateProspects"}
              onChange={(e) => setFieldValue("excludeDuplicateProspects", e)}
            />

            <span>
              {peopleSelected} / {peopleCount} people selected
            </span>

            {isLoading ? <Spinner /> : null}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditAudienceForm;
