import React from "react";

//Helpers
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { calculatePercentageFromNumber } from "../../../helper/calculatePercentageHelper";

//Styles
import styles from "./AudienceProgressBar.module.scss";

//Components
import ProgressLine from "../ProgressLine/ProgressLine";

const AudienceProgressBar = ({ contactsAvailable, prospectsImported, prospectsNotImported, audienceSize, separators }) => {
  let contactsAvailableFormatted = formatNumberHelper(
    contactsAvailable,
    separators.numberGroupSeperator,
    separators.numberDecimalSeperator
  );

  let audienceSizeFormatted = formatNumberHelper(audienceSize, separators.numberGroupSeperator, separators.numberDecimalSeperator);

  let audienceData = [];
  audienceData.push({
    color: "#21c4a8",
    percentage: calculatePercentageFromNumber(contactsAvailable, audienceSize),
  });
  audienceData.push({
    color: "#fe7904",
    percentage: calculatePercentageFromNumber(prospectsImported, audienceSize),
  });
  audienceData.push({
    color: "#ee3030",
    percentage: calculatePercentageFromNumber(prospectsNotImported, audienceSize),
  });

  return (
    <div>
      <div className={styles.availableProspectsText}>
        <span className={styles.available}>{contactsAvailableFormatted}</span>
        &nbsp; / &nbsp;
        {audienceSizeFormatted}
      </div>
      <ProgressLine hideLabelStyle={true} data={audienceData} />
    </div>
  );
};

export default AudienceProgressBar;
