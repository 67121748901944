import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Helpers
import { variablesRegex, extractVariable } from "../../../helper/variablesHelper";

//Styles
import styles from "./MessagePreviewSidePanel.module.scss";

//Images & Icons
import EmptyData from "../../../assets/Images/EmptyData/EmptyData";
import CheckboxCircleLineIcon from "remixicon-react/CheckboxCircleLineIcon";

//Components
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import FormikInput from "../../../components/UI/Formik/FormikInput";
import Button from "../../../components/UI/Button/Button";

const MessagePreviewSidePanel = (props) => {
  const { combineVariablesArray } = props;
  const [editorValue, setEditorValue] = useState(props.editorValue);
  const [subjectValue, setSubjectValue] = useState(props.templateSubject);
  const [incorrectVariablesCount, setIncorrectVariablesCount] = useState(0);

  const generateVariablesValues = (editorValue, subjectValue, combineArray) => {
    const incorrectEditor = props.checkForIncorrectVariables(editorValue);
    const incorrectSubject = props.checkForIncorrectVariables(subjectValue);
    setIncorrectVariablesCount(incorrectEditor.length + incorrectSubject.length);

    const processMatched = (matched, variable1, variable2) => {
      let variable = extractVariable((variable1 || variable2).split("|")[0]);

      if (variable === "INITIAL_EMAIL_SUBJECT") {
        return matched;
      }

      const foundItemText = combineArray.find((x) => x.text === matched);
      const foundItemName = combineArray.find((x) => x.name === variable);

      if (foundItemText || foundItemName) {
        if (!incorrectEditor.includes(matched) && !incorrectSubject.includes(matched)) {
          return foundItemName.example;
        }
      }
      return `<span style='color: #ee3030; background: #EE30304D; padding: 2px 5px; border-radius: 4px'>${matched}</span>`;
    };

    return {
      editorValue: editorValue.replace(variablesRegex, (matched, variable1, variable2) => {
        return processMatched(matched, variable1, variable2);
      }),
      subjectValue: subjectValue.replace(variablesRegex, (matched, variable1, variable2) => {
        return processMatched(matched, variable1, variable2);
      }),
    };
  };

  useEffect(() => {
    const { editorValue, subjectValue } = generateVariablesValues(props.editorValue, props.templateSubject, combineVariablesArray);
    setEditorValue(editorValue);
    setSubjectValue(subjectValue);
  }, [props.editorValue, props.templateSubject]);

  let emptyData = <EmptyData />;

  return (
    <div className={styles.previewTemplateWrapper}>
      <h1 className="f16">Template preview</h1>
      {props.editorValue.length > 0 ? (
        <>
          <div className="flex_end mT10">
            <FormikInput
              inputGroupClass="m0"
              label="Send To"
              name={"sendTo"}
              placeholder="Type here..."
              value={props.sendTo}
              disabled={true}
            />
            <Button
              id="sendTestButton"
              classes="mL10"
              disabled={props.disabledTestButton}
              onClick={() => {
                if (incorrectVariablesCount > 0) {
                  props.setShowErrorVariablesModal(true);
                } else {
                  props.setShowTestEmailFormModal();
                }
              }}
            >
              Send Test
            </Button>
          </div>
          <div className={`mT20 ${styles.messagePreviewContent} ${props.hasChanges ? "mB25" : ""}`}>
            <p className="mB5 f13 pL10">
              <span className="f13 font700">To:</span> {props.sendTo}
            </p>
            <p className="mB5 f13 pL10">
              <span className="f13 font700">Subject: </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: subjectValue,
                }}
              />
            </p>
            <div
              className={`campaignSequenceBodyMessage ${styles.bodyMessage} ${props.messageBodyPreview ? props.messageBodyPreview : ""}`}
              dangerouslySetInnerHTML={{
                __html: editorValue,
              }}
            />
            <div className="flex justify_end">
              {incorrectVariablesCount > 0 ? (
                <OverlayTrigger
                  key={"active"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`tooltip-active`}>
                      <Popover.Body>You have {incorrectVariablesCount} incorrect variable(s)</Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <span className={`f12 font700 flex_center ${styles.errorCounter}`}>{incorrectVariablesCount}</span>
                  </div>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  key={"active"}
                  placement={"bottom"}
                  overlay={
                    <Popover id={`tooltip-active`}>
                      <Popover.Body>All variables are correct</Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    <CheckboxCircleLineIcon size={20} color="var(--first-stage-color)" />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </>
      ) : (
        <EmptyState
          componentClass={styles.emptyStateComponent}
          image={emptyData}
          title=""
          description="Once you start creating your email template in the editor, you can preview it here"
        />
      )}
    </div>
  );
};

export default MessagePreviewSidePanel;
