import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";
import { toast } from "react-toastify";

////CREATE////////////////////////////////
export const createWorkflowStepsRequest = () => {
  return {
    type: actionTypes.CREATE_WORKFLOW_STEPS_REQUEST,
  };
};

export const createWorkflowStepsSuccess = (setup) => {
  return {
    type: actionTypes.CREATE_WORKFLOW_STEPS_SUCCEEDED,
    result: setup,
  };
};

export const createWorkflowStepsFailed = (error) => {
  return {
    type: actionTypes.CREATE_WORKFLOW_STEPS_FAILED,
    result: error,
  };
};

export const createWorkflowSteps = (data) => {
  return (dispatch) => {
    dispatch(createWorkflowStepsRequest());
    return api(API.workflowSteps.create, data)
      .then((response) => {
        dispatch(createWorkflowStepsSuccess(response.data));
        // toast.success("Your campaign sequence has been updated.", { autoClose: 30000 });
      })
      .catch((error) => {
        dispatch(createWorkflowStepsFailed(error));
      });
  };
};
///////DELETE///////////////////////
export const deleteWorkflowStepsRequest = () => {
  return {
    type: actionTypes.DELETE_WORKFLOW_STEPS_REQUEST,
  };
};

export const deleteWorkflowStepsSuccess = (setup) => {
  return {
    type: actionTypes.DELETE_WORKFLOW_STEPS_SUCCEEDED,
    result: setup,
  };
};

export const deleteWorkflowStepsFailed = (error) => {
  return {
    type: actionTypes.DELETE_WORKFLOW_STEPS_FAILED,
    result: error,
  };
};

export const deleteWorkflowSteps = (id) => {
  return (dispatch) => {
    dispatch(deleteWorkflowStepsRequest());
    return api(formUrl(API.workflowSteps.delete, { campaignId: id }))
      .then((response) => {
        dispatch(deleteWorkflowStepsSuccess(response.data));
        // toast.success("Your campaign sequence has been updated.", { autoClose: 30000 });
      })
      .catch((error) => {
        dispatch(deleteWorkflowStepsFailed(error.message));
        toast.error(error.message);
      });
  };
};

export const fetchWorkflowStepsListRequest = () => {
  return {
    type: actionTypes.FETCH_WORKFLOW_STEPS_LIST_REQUEST,
  };
};

export const fetchWorkflowStepsListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_WORKFLOW_STEPS_LIST_SUCCEEDED,
    result: setup,
  };
};

export const fetchWorkflowStepsListFailed = (error) => {
  return {
    type: actionTypes.FETCH_WORKFLOW_STEPS_LIST_FAILED,
    result: error,
  };
};

export const fetchWorkflowStepsList = (id) => {
  return (dispatch) => {
    dispatch(fetchWorkflowStepsListRequest());
    return api(formUrl(API.workflowSteps.getList, { campaignId: id }))
      .then((response) => {
        var data = {
          steps: response.data,
          campaignId: id,
        };
        dispatch(fetchWorkflowStepsListSuccess(data));
      })
      .catch((error) => {
        dispatch(fetchWorkflowStepsListFailed(error.message));
      });
  };
};

export const createSequence = (type, stepId, obj) => {
  return {
    type: actionTypes.CREATE_WORKFLOW_STEPS_SEQUENSE,
    actionType: type,
    stepId: stepId,
    obj: obj,
  };
};

export const checkSequenceForId = (actionType, index) => {
  return {
    type: actionTypes.CHECK_SEQUENCE_FOR_ID,
    index: index,
    actionType: actionType,
  };
};

export const setActivePage = (page) => {
  return {
    type: actionTypes.SET_ACTIVE_PAGE,
    page: page,
  };
};

export const setActiveStep = (stepId) => {
  return {
    type: actionTypes.SET_ACTIVE_STEP,
    stepId: stepId,
  };
};

export const removeStep = (stepId) => {
  return {
    type: actionTypes.REMOVE_STEP,
    stepId: stepId,
  };
};

export const checkSequenceForErrors = () => {
  return (dispatch) => {
    dispatch(checkSequenceForErrorsRequest());
    dispatch(checkSequenceForErrorsSuccess());
  };
};

export const checkSequenceForErrorsRequest = () => {
  return {
    type: actionTypes.CHECK_SEQUENCE_FOR_ERRORS_REQUEST,
  };
};

export const checkSequenceForErrorsSuccess = () => {
  return {
    type: actionTypes.CHECK_SEQUENCE_FOR_ERRORS_SUCCESS,
  };
};

export const clearInitiated = (type) => {
  return {
    type: actionTypes.SEQUENCE_CLEAR_INITIATED,
  };
};
