import React from "react";
import svg from "./clicked.svg";

const Clicked = (props) => (
  <svg
    className={props.imgStyle}
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 15.995}
    height={props.height ? props.height : 15.995}
    viewBox="0 0 15.995 15.995"
  >
    <g id="icn_reports_clickrate" transform="translate(-184 -236)">
      <rect
        id="_24_base"
        data-name="24_base"
        width="15.995"
        height="15.995"
        transform="translate(184 236)"
        fill="none"
      />
      <path
        id="Path_1305"
        data-name="Path 1305"
        d="M4.423,8.633a3.041,3.041,0,0,1,4.3-4.3A2.98,2.98,0,0,1,9.553,6.9l1.433.453A4.535,4.535,0,0,0,9.78,3.276a4.6,4.6,0,0,0-6.413,0,4.53,4.53,0,0,0,0,6.413A4.394,4.394,0,0,0,7.441,10.9L6.988,9.463A2.911,2.911,0,0,1,4.423,8.633Z"
        transform="translate(182.672 234.709)"
        fill={props.fill ? props.fill : "#50248f"}
      />
      <path
        id="Path_1306"
        data-name="Path 1306"
        d="M19.053,13.018,10,10l3.018,9.053,1.433-1.433,1.433,1.433,3.169-3.169L17.62,14.451Z"
        transform="translate(180.693 232.667)"
        fill={props.fill ? props.fill : "#50248f"}
      />
    </g>
  </svg>
);

export default Clicked;
