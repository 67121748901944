import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import { columns } from "./columns";

//Helpers
import { numberOfRecords } from "../../../../helper/tableConstants";
import { audienceExplorerSoProDataEntityInUseEnum } from "../../../../helper/enums/audienceExplorerSoProDataEntityInUseEnum";

// Style
import styles from "./People.module.scss";

// Icons & Images
import Person from "../../../../assets/Icons/Person/Person";
import CompanyDefaultLogo from "../../../../assets/Icons/CompanyDefaultLogo/CompanyDefaultLogo";
import CampaignInUse from "../../../../assets/Icons/CampaignInUse/CampaignInUse";
import CampaignNotInUse from "../../../../assets/Icons/CampaignNotInUse/CampaignNotInUse";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import IconExcludeContact from "../../../../assets/Icons/IconExcludeContact/IconExcludeContact";
import IconIncludeContact from "../../../../assets/Icons/IconIncludeContact/IconIncludeContact";
import EmptyAudiencesList from "../../../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";
import TeamFillIcon from "remixicon-react/TeamFillIcon";

// Components
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import DataTable from "../../../../components/DataTable/DataTable";
import ContactDetailsForm from "../../../AudienceContacts/ContactDetailsForm";
import AlertNotification from "../../../../components/UI/AlertNotification/AlertNotification";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import useModal from "../../../../components/UI/Modal/useModal";
import { useLocation } from "react-router-dom";
import { useSlice } from "@hooks/useSlice";
import { useDataTable } from "@hooks/useDataTable";
import { explorerContactsDataTable } from "@store/registerDataTables";
import { useGetContactsQuery } from "@api/audienceExplorerApi";
import { useDispatch } from "react-redux";
import audienceExplorerSlice, { ViewMode } from "@store/AudiencesExplorer/audienceExplorerSlice";
import { ExplorerContactRowModel } from "@models/audienceExplorer/ExplorerContactRowModel";

const People = () => {
  let table = null;
  const image = <EmptyAudiencesList className="emptyStateImage" />;
  const [tableRows, setTableRows] = useState<ExplorerContactRowModel[]>([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchIdFromParams = queryParams.get("s");

  const dispatch = useDispatch();
  const explorerSlice = useSlice((state) => state.explorer);
  const explorerContactsState = useSlice((state) => state.explorerContactsDataTable);
  const dataTable = useDataTable({
    slice: explorerContactsState,
    dtActions: explorerContactsDataTable.actions,
    useQuery: useGetContactsQuery,
    filterTypes: ["SearchId"],
    useWithQuery: false,
    autoInitiate: false,
  });
  const {
    state: { rows },
    actions: { setFilter, initiate, clearFilter },
    query,
  } = dataTable;

  useEffect(() => {
    setFilter("SearchId", searchIdFromParams);
  }, [searchIdFromParams]);

  useEffect(() => {
    if (searchIdFromParams) {
      dispatch(audienceExplorerSlice.actions.resetExcludedContacts());
      const params = {
        length: numberOfRecords[2].value,
      };
      initiate(params);

      return () => {
        clearFilter("SearchId");
      };
    }
  }, [searchIdFromParams]);

  const contactDetailsModal = useModal<number>();

  const adjustPeopleSelected = (prospectId: number, prospectName: string) => {
    dispatch(audienceExplorerSlice.actions.toggleContactExcluded({ value: prospectId.toString(), label: prospectName }));
  };

  useEffect(() => {
    if (!rows) return;

    const unselectedPeopleSet = new Set(explorerSlice.excludedContacts.map((item) => item.value));

    const rowsTemp: ExplorerContactRowModel[] = _.cloneDeep(rows).map((row) => {
      const contact: ExplorerContactRowModel = {
        ...row,
        checkbox: <></>,
        fullName: <></>,
        title: <></>,
        companyName: <></>,
        inUse: <></>,
        companyLogo: <></>,
      };

      contact.companyLogo = <CompanyDefaultLogo size="extra-small" />;
      const contactIsExcluded = unselectedPeopleSet.has(row.prospectId.toString());

      const isUsedInAudience =
        row.inUse === audienceExplorerSoProDataEntityInUseEnum.Reserved || row.inUse === audienceExplorerSoProDataEntityInUseEnum.Fetched;
      const isUsedInCampaign = row.inUse === audienceExplorerSoProDataEntityInUseEnum.Fetched;
      const inUse = (
        <div className={contactIsExcluded ? styles.excludeContactIcons : styles.inUseColumn}>
          <OverlayTrigger
            key={"prospectGroupInUse"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-prospectGroupInUse`}>
                <Popover.Body>This contact {isUsedInAudience ? "appears" : "does not appear"} in an audience.</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">
              {isUsedInAudience ? <TeamFillIcon size="12" className="iconSuccess" /> : <TeamFillIcon size="12" className="iconGray500" />}
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            key={"prospectCampaignInUse"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-prospectCampaignInUse`}>
                <Popover.Body>This contact {isUsedInCampaign ? "appears" : "does not appear"} as prospect in a campaign.</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">{isUsedInCampaign ? <CampaignInUse /> : <CampaignNotInUse />}</div>
          </OverlayTrigger>
        </div>
      );
      contact.inUse = inUse;

      let checkbox = null;
      if (contactIsExcluded) {
        checkbox = (
          <OverlayTrigger
            key={"include-" + row.id}
            placement={"bottom"}
            overlay={
              <Popover id={"include-" + row.id}>
                <Popover.Body>Include this contact</Popover.Body>
              </Popover>
            }
          >
            <div
              className={`text-overflow-hidden ${styles.includeContact}`}
              onClick={() => {
                adjustPeopleSelected(row.prospectId, row.firstName + " " + row.lastName);
              }}
            >
              <IconIncludeContact />
            </div>
          </OverlayTrigger>
        );
      } else {
        checkbox = (
          <OverlayTrigger
            key={`exclude-` + row.id}
            placement={"bottom"}
            overlay={
              <Popover id={`exclude-` + row.id}>
                <Popover.Body>Exclude this contact</Popover.Body>
              </Popover>
            }
          >
            <div
              className={`text-overflow-hidden ${styles.excludeContact}`}
              onClick={() => {
                adjustPeopleSelected(row.prospectId, row.firstName + " " + row.lastName);
              }}
            >
              <IconExcludeContact />
            </div>
          </OverlayTrigger>
        );
      }

      contact.fullName = (
        <OverlayTrigger
          key={row.fullName}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.fullName}`}>
              <Popover.Body>{row.fullName}</Popover.Body>
            </Popover>
          }
        >
          <div
            id={row.fullName.replace(/\s/g, "")}
            onClick={() => contactDetailsModal.show(row.id)}
            className={`${contactIsExcluded ? styles.excludedPerson : styles.contactName} f10`}
          >
            {row.fullName}
          </div>
        </OverlayTrigger>
      );
      contact.title = (
        <OverlayTrigger
          key={row.title}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.title}`}>
              <Popover.Body>{row.title}</Popover.Body>
            </Popover>
          }
        >
          <div className={contactIsExcluded ? styles.excludedPerson : "max-x-content f10"}>{row.title}</div>
        </OverlayTrigger>
      );
      contact.companyName = (
        <OverlayTrigger
          key={row.companyName}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.cleanCompanyName ? row.cleanCompanyName : row.companyName}`}>
              <Popover.Body>{row.cleanCompanyName ? row.cleanCompanyName : row.companyName}</Popover.Body>
            </Popover>
          }
        >
          <div className={`${contactIsExcluded ? styles.excludedPerson : "max-x-content"} f10`}>
            {row.cleanCompanyName ? row.cleanCompanyName : row.companyName}
          </div>
        </OverlayTrigger>
      );
      contact.checkbox = checkbox;
      return contact;
    });

    setTableRows(rowsTemp);
  }, [rows, explorerSlice.excludedContacts.length]);

  if (query.error) {
    table = <h2>Error getting Prospects</h2>;
  }

  if ((query.isLoading || query.isFetching) && searchIdFromParams) {
    table = <Spinner description="Searching for contacts..." />;
  }

  if (query.isSuccess === false && query.isLoading === false && query.isFetching === false) {
    table = (
      <EmptyState
        componentClass="positionCenter"
        image={image}
        title={
          <>
            Select filters and click <span className="primary_text_color f16">Search</span> to see results
          </>
        }
      />
    );
  }

  if (query.isSuccess && query.isLoading === false && query.isFetching === false) {
    table =
      rows === null ? (
        <AlertNotification
          variant="danger_alert"
          alertClassName="justify-content-end"
          bodyClassName="align-items-center flex"
          notificationWrapperClass="width_100"
        >
          <span>Data is not available at this moment. Please try again later</span>
        </AlertNotification>
      ) : tableRows.length > 0 ? (
        <div className={styles.tableWrapper}>
          <div className={styles.breadcrumb}>
            <div>
              {explorerSlice.excludedContacts.length > 0 && (
                <div className={styles.excludedContactsDiv}>
                  <p className={styles.peopleInUse}>
                    {explorerSlice.excludedContacts.length} contact(s) excluded &nbsp;
                    <span onClick={() => dispatch(audienceExplorerSlice.actions.resetExcludedContacts())}>X</span>
                  </p>
                </div>
              )}
            </div>
          </div>

          <DataTable
            rows={tableRows}
            columns={columns}
            dataTable={dataTable}
            tableClassName={styles.peopleTable}
            scrollY={true}
            variant="small"
            tableHeight={210}
            tableFooterClass="relative p0"
          />
        </div>
      ) : (
        <EmptyState componentClass="positionCenter" image={image} title="No contacts found" />
      );
  }

  return (
    <div id="peoplePanel" className={styles.peoplePanel}>
      <SectionTitle
        titleClass={styles.sectionTitle}
        title={explorerSlice.viewMode === ViewMode.Companies ? "" : "Contacts"}
        titleWrapperClass={explorerSlice.viewMode === ViewMode.Companies ? "mT30" : ""}
        rightContentClass={explorerSlice.viewMode === ViewMode.Companies ? styles.panelClosed : ""}
        icon={<Person imgStyle="emptyStateImage" />}
        rightContent={
          <div className={styles.minimizePanel}>
            <ArrowLeftSLineIcon
              className={`circleToggleICon cursor_pointer iconGray400 ${
                explorerSlice.viewMode === ViewMode.Contacts ? "" : styles.transformRight
              }`}
              onClick={() => dispatch(audienceExplorerSlice.actions.clickContactsArrow())}
            />
          </div>
        }
      />
      <div className="width_100 height_100">
        <div className="flex height_100">
          {explorerSlice.viewMode === ViewMode.Companies ? <p className={styles.contactsTitle}>Contacts</p> : table}
        </div>
      </div>

      <ContactDetailsForm
        handleClose={contactDetailsModal.hide}
        showModal={contactDetailsModal.isVisible}
        contactId={contactDetailsModal.data}
      />
    </div>
  );
};

export default People;
