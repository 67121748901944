import styles from "../Filters.module.scss";
import { useGetIndustriesQuery } from "@api/audienceExplorerApi";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import AlertNotification from "@ui/AlertNotification/AlertNotification";
import FormikSelect from "@ui/Formik/FormikSelect";
import { useFormikContext } from "formik";

const FilterCompanyIndustry = () => {
  const {
    values: {
      company: {
        industries,
        naicsAndSicCodes: { type, naicsCodes, sicCodes },
      },
    },
  } = useFormikContext<SearchModel>();
  const listIndustries = useGetIndustriesQuery();

  return (
    <>
      {naicsCodes?.length > 0 || sicCodes?.length > 0 ? (
        <p>
          <AlertNotification variant="info_alert" alertClassName={styles.info_banner} dismissible="dismissible">
            To continue with searching by Industries, please clear the existing NAICS/SIC filters to ensure compatibility and accurate
            results.
          </AlertNotification>
        </p>
      ) : null}
      <FormikSelect
        menuId="recommendedIndustry"
        name="company.industries"
        value={industries}
        options={listIndustries && listIndustries.data ? listIndustries.data : []}
        displayName="Select industry"
        placeholder="e.g. B2B - Marketing and Advertising"
        dropdownButtonClass={styles.selectDropdown}
        disabled={naicsCodes?.length > 0 || sicCodes?.length > 0}
        allowIncludeExclude
        enableReinitialize
        isAutoComplete
        isMulti
        isSearchable
        showValuesOutside
        hideSelectedOptions={false}
      />
    </>
  );
};

export default FilterCompanyIndustry;
