export const columns = [
  {
    label: "Email",
    field: "email",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Name",
    field: "name",
    type: "text",
    sort: "asc",
    width: 120,
    showTooltip: true,
  },
  {
    label: "Job Title",
    field: "jobTitle",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Company Name",
    field: "companyName",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Stage",
    field: "currentStatus",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Response Type",
    field: "responseType",
    type: "text",
    sort: "asc",
    width: 150,
  },
  {
    label: "Conversations",
    field: "conversations",
    type: "actions",
    sort: "disabled",
    width: 150,
  },
  {
    label: "Last Activity",
    field: "lastActivity",
    type: "date",
    sort: "asc",
    width: 150,
  },
  {
    label: "Last Send",
    field: "lastSend",
    type: "date",
    sort: "asc",
    width: 150,
  },
  {
    label: "Current Stage",
    field: "currentStage",
    type: "number",
    sort: "asc",
    width: 150,
  },
  {
    label: "Campaign Name",
    field: "campaignName",
    type: "text",
    sort: "asc",
    width: 150,
    showTooltip: true,
  },
  {
    label: "Excluded",
    field: "isExcluded",
    type: "actions",
    sort: "asc",
    width: 80,
  },
];
