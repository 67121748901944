import { useDispatch } from "react-redux";
import { DataTableActions } from "../../store/createDataTableSlice";
import { DTSort } from "../../models/dataTables/DtSort";
import { DTParametersWithPaging } from "@models/dataTables/DtParametersWithPaging";
import { DtFilterValueType } from "@models/dataTables/DtFilterValueType";

export interface UseActionsReturn {
  searchRecords: (value: string) => void;
  setFilter: (type: string, value: DtFilterValueType | DtFilterValueType[] | null) => void;
  changePage: (page: number) => void;
  sortData: (sort: DTSort) => void;
  setItemsPerPage: (num: number) => void;
  clearFilter: (type: string) => void;
  initiate: (payload: Partial<DTParametersWithPaging>) => void;
  clearInitiated: () => void;
}

const useActions = (actions: DataTableActions): UseActionsReturn => {
  const dispatch = useDispatch();

  const searchRecords = (value: string) => dispatch(actions.searchRecords(value));
  const setFilter = (type: string, value: DtFilterValueType | DtFilterValueType[]) => dispatch(actions.setFilter({ type, value }));
  const changePage = (page: number) => dispatch(actions.changePage(page));
  const sortData = (sort: DTSort) => dispatch(actions.sortData(sort));
  const setItemsPerPage = (num: number) => dispatch(actions.setItemsPerPage(num));
  const clearFilter = (type: string) => dispatch(actions.clearFilter(type));
  const initiate = (payload: Partial<DTParametersWithPaging>) => dispatch(actions.initiate(payload));
  const clearInitiated = () => dispatch(actions.clearInitiated());

  return {
    searchRecords,
    setFilter,
    changePage,
    sortData,
    setItemsPerPage,
    clearFilter,
    initiate,
    clearInitiated,
  };
};

export default useActions;
