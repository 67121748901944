import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//Helpers
import { calculateDiscountedPrice } from "../../../../helper/pricingPlanHelper";
import { couponAppliesToEnum, couponTypeEnum } from "../../../../helper/enums/couponEnum";

// Styles
import styles from "./OrderSummaryModal.module.scss";

//Images & Icons

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import CouponField from "../CouponField/CouponField";
import FormikCheckbox from "../../../../components/UI/Formik/FormikCheckbox";

const OrderSummaryModal = (props) => {
  const {
    currentPlan,
    credits,
    users,
    coupon,
    currencySign,
    totalPrice,
    couponDiscountedFullPrice,
    couponDiscountedPrice,
    typeOfBilling,
    setCoupon,
    period,
    hasRedirect,
    showModal,
  } = props;
  const [discountPrice, setDiscountPrice] = useState(couponDiscountedFullPrice);
  useEffect(() => {
    if (coupon != null && couponDiscountedFullPrice === null) {
      let price = calculateDiscountedPrice(totalPrice, coupon);
      setDiscountPrice(price);
    }
  }, [coupon]);

  return (
    <Formik
      initialValues={{
        areTermsAccepted: props.areTermsAccepted,
      }}
      validationSchema={Yup.object().shape({
        areTermsAccepted: Yup.bool().oneOf([true], "You need to accept the terms and conditions"),
      })}
      onSubmit={(values, actions) => {
        props.handleConfirm();
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, handleChange, values } = formikProps;

        return (
          <Modal
            title={"Order Summary"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText={"Cancel"}
            saveButtonText={"Confirm"}
            btnType="submit"
            backdrop={true}
            dialogClassName={`${styles.summaryModal} customModal`}
            customBodyClass={`${styles.modalBody} ${coupon != null ? styles.discountDetailsModal : ""}`}
            saveButtonDisabled={!props.termsAccepted}
          >
            <h1 className="f14 mB15 font700">Summary</h1>
            <div className={styles.detailsWrapper}>
              <p className="f12 pB15">
                Plan <span className={` ${styles.planName} f14 font700`}>{currentPlan}</span>
              </p>
              <p className="f12 pB15">
                Users <span className="font700">{users}</span>
              </p>
              <p className="f12 pB15">
                Credits <span className="font700">{credits}</span>
              </p>
              <p className={`${styles.total} flex flex_column`}>
                {coupon != null ? <span className={styles.discountSpan}>{coupon?.discountText}</span> : null}
                <span className={`${styles.newPrice} font14 font700`}>
                  {currencySign}{" "}
                  {coupon != null && coupon.type != couponTypeEnum.multiplicator
                    ? couponDiscountedFullPrice != null && couponDiscountedPrice != null
                      ? typeOfBilling
                        ? couponDiscountedFullPrice
                        : couponDiscountedPrice
                      : discountPrice
                    : totalPrice}
                  / {typeOfBilling ? "Upfront" : "Mo."}
                </span>
                {coupon != null && coupon.type != couponTypeEnum.multiplicator ? (
                  <span className={styles.oldPrice}>
                    {currencySign} {totalPrice} / {typeOfBilling ? "Upfront" : "Mo."}
                  </span>
                ) : null}
              </p>
            </div>
            <CouponField
              setCoupon={setCoupon}
              coupon={coupon}
              appliesTo={couponAppliesToEnum.subscription}
              period={period}
              typeOfBilling={typeOfBilling}
              isFromOrderSummary={true}
              hasRedirect={hasRedirect}
              showModal={showModal}
            />
            <p className={'flex flex_column"'}>
              <FormikCheckbox
                className="flex_column"
                label={
                  <>
                    I have read and agreed with the{" "}
                    <a rel="noopener noreferrer" target="_blank" href="https://outbase.com/terms-and-conditions/#payment">
                      Terms and conditions
                    </a>
                  </>
                }
                name="areTermsAccepted"
                checked={props.termsAccepted}
                onChange={() => props.setTermsAccepted(!props.termsAccepted)}
                required
              />
            </p>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default OrderSummaryModal;
