import React from "react";
import { Route, NavLink, useRouteMatch } from "react-router-dom";

// Styles
import styles from "./Tabs.module.scss";

interface Tab {
  title: string;
  link: string;
  img?: JSX.Element; // Assuming img is JSX.Element
  tabData?: string | JSX.Element | null; // Assuming tabData can be string, JSX.Element, or null
  onClick?: () => void;
  component: JSX.Element; // Assuming component is JSX.Element
}

interface TabsProps {
  tabs: Tab[];
  tabsClassName?: string;
  tabContentClass?: string;
  onClick?: (link: string) => void;
}

const Tabs: React.FC<TabsProps> = (props) => {
  let tabsClass = styles.Tabs;
  const { path, url } = useRouteMatch();
  if (props.tabs.length < 3) {
    tabsClass += " " + styles.LessThan3;
  }

  return (
    <>
      <div className={`${tabsClass} ${props.tabsClassName}`}>
        {props.tabs.map((tab) => (
          <NavLink
            key={`${url}/${tab.link}`}
            to={`${url}/${tab.link}`}
            id={tab.title.replace(" ", "")}
            activeClassName={styles.activeTab}
            onClick={() => {
              if (props.onClick) {
                props.onClick(tab.link);
              }

              if (tab.onClick) {
                tab.onClick();
              }
            }}
          >
            {tab.img} {tab.title} {tab.tabData != null ? <span className={`${styles.spanStyle} flex_center`}>{tab.tabData}</span> : null}
          </NavLink>
        ))}
        <div className={styles.borderLine}></div>
      </div>
      <div className={`${styles.routeClass} ${props.tabContentClass}`}>
        {props.tabs.map((tab) => (
          <Route key={`${path}/${tab.link}`} exact path={`${path}/${tab.link}`} render={() => tab.component} />
        ))}
      </div>
    </>
  );
};

export default Tabs;
