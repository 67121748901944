import React, { FC } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface Step {
  label: string;
}

interface HorizontalStepperProps {
  id?: string;
  activeStep: number;
  setActiveStep: (step: number) => void;
  orientation: "horizontal" | "vertical";
  steps: Step[];
}

const HorizontalStepper: FC<HorizontalStepperProps> = ({ id, activeStep, setActiveStep, orientation, steps }) => {
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const muiTheme = createTheme({
    components: {
      MuiStepper: {
        styleOverrides: {
          root: {
            paddingTop: "0 !important",
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: "var(--secondary-color-200)",
            border: "5px solid var(--neutral-color-300)",
            width: "33px",
            height: "33px",
            borderRadius: "50%",
            "&.Mui-active": {
              color: "var(--primary-color)",
            },
            "&.Mui-completed": {
              color: "var(--primary-color)",
            },
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            outline: "none !important",
            background: "#fff !important",
            "&.Mui-focusVisible": {
              outline: "none !important",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "left",
            cursor: "pointer !important",
            "&.Mui-active": {
              color: "var(--primary-color) !important",
            },
            "&.Mui-completed": {
              color: "var(--secondary-text-color) !important",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "0.75rem !important",
            marginTop: "5px !important",
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            marginTop: "10px",
            cursor: "pointer",
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            fontSize: "0.75rem !important",
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            borderTopWidth: "5px",
            height: "5px",
            background: "var(--neutral-color-300)",
            borderColor: "var(--neutral-color-300)",
            left: "calc(-50% + 16px) !important",
            right: "calc(50% + 15px) !important",
            "&>span": {
              borderColor: "var(--neutral-color-300)",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Stepper id={id ?? "stepper_root"} activeStep={activeStep} orientation={orientation} alternativeLabel>
        {steps.map((step, index) => (
          <Step id={step.label.replace(/\s/g, "")} key={index} active={activeStep === index} onClick={handleStep(index)}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </ThemeProvider>
  );
};

export default HorizontalStepper;
