import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";

//Helpers
import { formatNumberHelper } from "../../helper/formatNumberHelper";

//Styles
import styles from "./AudienceContacts.module.scss";

//Images & Icons
import GlobalFillIcon from "remixicon-react/GlobalFillIcon";
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";

// Components
import Modal from "../../components/UI/Modal/Modal";
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";

const ContactOrCompanyDetailsForm = (props) => {
  const audienceContact = props.audienceContact;
  const isChosenCompany = props.isChosenCompany;

  return audienceContact !== null ? (
    <Modal
      title={isChosenCompany === false ? "Contact Detail" : "Company Details"}
      handleSave={props.handleClose}
      handleClose={() => props.handleClose()}
      show={props.showModal}
      oneButton={true}
      saveButtonText="Close"
      btnType="submit"
    >
      <Container>
        <div className={styles.profileDetails}>
          <div className={styles.profileImage}>
            <UserPhoto alt="org-photo" isCompanyImage={isChosenCompany} size="medium-l" className="m0" />
          </div>
          <div xl={9} className={styles.detailsInfo}>
            <h4 className={styles.headerName}>{isChosenCompany === true ? audienceContact.company : audienceContact.fullName}</h4>
            {!_.isEmpty(audienceContact.country) && !isChosenCompany && (
              <span className={styles.location}>
                <MapPin2FillIcon size={16} className="iconAccent mR5" />
                {audienceContact.country}
              </span>
            )}
            <span className={styles.webAddress}>
              <GlobalFillIcon size={16} className="iconAccent mR5" /> {audienceContact.companyWebsite}
            </span>
            {/* {isChosenCompany === true ? (
                                    <span className={styles.webAddress}>
                                        <Web imgStyle={styles.iconsStyle} /> {audienceContact.companyWebsite}
                                    </span>) :
                                    (
                                        <div className={`${styles.socialIcons} mt10`}>
                                            <div>
                                                <a href="#" target="_blank"><Facebook /></a>
                                            </div>
                                            <div>
                                                <a href="#" target="_blank"><Linkedin /></a>
                                            </div>
                                            <div>
                                                <a href="#" target="_blank"><Pinterest /></a>
                                            </div>
                                            <div>
                                                <a href="#" target="_blank"><Twitter /></a>
                                            </div>
                                        </div>
                                    )} */}
          </div>
        </div>
        <Row>
          <Col xl={12}>
            <hr />
          </Col>
        </Row>

        <Row>
          {isChosenCompany === true ? (
            <>
              <Col xl={4}>
                <span className={styles.informationType}>Industry:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.companyIndustry && audienceContact.companyIndustry !== "" ? audienceContact.companyIndustry : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Company size:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.companySize && audienceContact.companySize !== "" ? audienceContact.companySize : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Revenue:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.revenue && audienceContact.revenue !== ""
                    ? formatNumberHelper(
                        Math.trunc(audienceContact.revenue),
                        props.organizationSettings.numberGroupSeperator,
                        props.organizationSettings.numberDecimalSeperator
                      )
                    : "No Info"}
                </span>
              </Col>
            </>
          ) : (
            <>
              <Col xl={4}>
                <span className={styles.informationType}>Company:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>{audienceContact.company}</span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Job position:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.jobTitle && audienceContact.revenue !== "" ? audienceContact.jobTitle : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Industry:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.companyIndustry && audienceContact.companyIndustry !== "" ? audienceContact.companyIndustry : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Company size:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.companySize && audienceContact.companySize !== "" ? audienceContact.companySize : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Revenue:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>
                  {audienceContact.revenue && audienceContact.revenue !== ""
                    ? formatNumberHelper(
                        Math.trunc(audienceContact.revenue),
                        props.organizationSettings.numberGroupSeperator,
                        props.organizationSettings.numberDecimalSeperator
                      )
                    : "No Info"}
                </span>
              </Col>
              <Col xl={4}>
                <span className={styles.informationType}>Website:</span>
              </Col>
              <Col xl={8} className="textRight">
                <span className={styles.information}>No Info</span>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Modal>
  ) : null;
};

export default ContactOrCompanyDetailsForm;
