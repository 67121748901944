import React from "react";
import svg from "./awaiting_engagment.svg";

const AwaitingEngagment = (props) => (
  <svg
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
    className={props.imgStyle}
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 18}
    height={props.height ? props.height : 12}
    viewBox="0 0 18 12"
  >
    <g id="icn_section_message_preview" transform="translate(0 -8.008)">
      <path
        id="Union_46"
        data-name="Union 46"
        d="M7.893,5.459.688.489,0,.054A1.574,1.574,0,0,1,.421,0H15.81a1.568,1.568,0,0,1,.421.054l-.046.028h0L8.333,5.459a.388.388,0,0,1-.217.067A.4.4,0,0,1,7.893,5.459Z"
        transform="translate(0.885 8.008)"
        fill={props.fill ? props.fill : "#f58383"}
      />
      <path
        id="Union_50"
        data-name="Union 50"
        d="M1.413,11.557A1.334,1.334,0,0,1,1,11.492L7.614,6.443a.656.656,0,0,0,.064-.059l.458.378a1.337,1.337,0,0,0,1.729,0l.458-.378a.656.656,0,0,0,.064.059L17,11.492a1.334,1.334,0,0,1-.414.066Zm9.715-5.891L18,0V10.061a1.546,1.546,0,0,1-.158.687ZM0,10.061V0L6.871,5.667.158,10.748A1.546,1.546,0,0,1,0,10.061Z"
        transform="translate(0 8.45)"
        fill={props.fill ? props.fill : "#f58383"}
      />
    </g>
  </svg>
);

export default AwaitingEngagment;
