import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "moment";
import _ from "lodash";

//Helpers
import { tableColumns } from "./columns/RTEProspectColumns";
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";

//Style
import styles from "../RTE.module.scss";

//Components
import Table from "../../../../../components/DataTable/Table";

const RTEProspect = (props) => {
  const { totalProspects, prospects } = props;
  const [tableRows, setTableRows] = useState([]);
  let dateFormat = useOrgDateFormat();

  const param = {
    start: 0,
    page: 1,
    length: totalProspects,
    sort: {},
    filter: [],
    search: "",
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(prospects);

    if (rowsTemp.length > 0) {
      rowsTemp.map((r) => {
        r.email = (
          <OverlayTrigger
            key={r.email}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.email}`}>
                <Popover.Body>{r.email}</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">{r.email}</div>
          </OverlayTrigger>
        );

        r.jobTitle = (
          <OverlayTrigger
            key={r.jobTitle}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.jobTitle}`}>
                <Popover.Body>{r.jobTitle}</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">{r.jobTitle}</div>
          </OverlayTrigger>
        );

        r.company = (
          <OverlayTrigger
            key={r.company}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.company}`}>
                <Popover.Body>{r.company}</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">{r.company}</div>
          </OverlayTrigger>
        );

        r.industry = (
          <OverlayTrigger
            key={r.industry}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.industry}`}>
                <Popover.Body>{r.industry}</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">{r.industry}</div>
          </OverlayTrigger>
        );
        r.dateCreated = Moment(r.dateCreated).format(dateFormat);
        return r;
      });
    }
    setTableRows(rowsTemp);
  }, [prospects]);

  return (
    <>
      <Table
        columns={tableColumns}
        rows={tableRows}
        showPagination={false}
        param={param}
        variant="small"
        tableClassName={styles.rtepTable}
      />
    </>
  );
};

export default RTEProspect;
