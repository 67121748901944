import React, { useState } from "react";

// Styles
import styles from "./StatusCard.module.scss";

// Helpers
import Permission from "@components/auth/Permission";
import { GLOBAL_PERMISSIONS } from "@helper/globalUserRolePermissionsConstants";

// Models
import { OptimizationModel } from "@models/campaign/OptimizationModel";
import { OptimizationStatusEnum } from "@models/campaign/OptimizationStatusEnum";
import { OptimizationPriorityEnum, optimizationPriorityToString } from "@models/campaign/OptimizationPriorityEnum";
import { optimizationCategoryToString } from "@models/campaign/OptimizationCategoryEnum";
import { OptimizationActionTypeEnum } from "@models/campaign/OptimizationActionTypeEnum";

// Icons
import ErrorWarningLineIcon from "remixicon-react/ErrorWarningLineIcon";
import ArrowUpLineIcon from "remixicon-react/ArrowUpLineIcon";
import ArrowDownLineIcon from "remixicon-react/ArrowDownLineIcon";
import CheckboxCircleLineIcon from "remixicon-react/CheckboxCircleLineIcon";

// Components
import Button from "../Button/Button";
import Spinner from "@ui/Spinner/Spinner";
import useModal from "@ui/Modal/useModal";
import MoreDetailsForm from "./MoreDetailsForm/MoreDetailsForm";
import ConfirmModal from "./ConfirmForm/ConfirmForm";
import InformationLineIcon from "remixicon-react/InformationLineIcon";
import { OverlayTrigger, Popover } from "react-bootstrap";

const priorityIcons: { [key in OptimizationPriorityEnum]: JSX.Element } = {
  [OptimizationPriorityEnum.High]: <ErrorWarningLineIcon color="var(--danger-color-700)" size={16} />,
  [OptimizationPriorityEnum.Medium]: <ArrowUpLineIcon color="var(--warning-color-700)" size={16} />,
  [OptimizationPriorityEnum.Low]: <ArrowDownLineIcon color="var(--info-color)" size={16} />,
};

interface StatusCardProps {
  model: OptimizationModel;
  text: string;
  inCard?: boolean;
  onMoreDetailsClick: () => void;
  onMarkAsDoneClick: () => void;
  onHelpMeClick: () => void;
  onDismissClick: () => void;
  onRedirect: () => void;
  userPermissions: string[];
}

const priorityColors: { [key in OptimizationPriorityEnum]: string } = {
  [OptimizationPriorityEnum.High]: "var(--danger-color-200)",
  [OptimizationPriorityEnum.Medium]: "var(--warning-color-200)",
  [OptimizationPriorityEnum.Low]: "var(--info-color-200)",
};

const StatusCard: React.FC<StatusCardProps> = ({ model, onDismissClick, onMarkAsDoneClick, onHelpMeClick, onRedirect }) => {
  const [confirmModalText, setConfirmModalText] = useState("");
  const [confirmModalAction, setConfirmModalAction] = useState<() => void>(() => {});

  const priorityClassName = (() => {
    switch (model.priority) {
      case OptimizationPriorityEnum.High:
        return styles.highPriority;
      case OptimizationPriorityEnum.Medium:
        return styles.mediumPriority;
      case OptimizationPriorityEnum.Low:
        return styles.lowPriority;
      default:
        return "";
    }
  })();

  const handleClickTakeMeThere = () => {
    if (onRedirect) onRedirect();
  };

  const statusMapping: { [key in OptimizationStatusEnum]?: { message: string; className: string; icon?: JSX.Element } } = {
    [OptimizationStatusEnum.Applied]: {
      message: "Applied",
      className: styles.successStatus,
      icon: <CheckboxCircleLineIcon size={16} className="mR5" color="var(--secund-stage-color)" />,
    },
    [OptimizationStatusEnum.Dismissed]: { message: "Dismissed", className: styles.dismissedStatus },
  };

  const { message: statusMessage, className: statusClassName, icon: iconStatus } = statusMapping[model.status] || {};
  const moreDetailsForm = useModal<number>();
  const confirmModal = useModal();

  const handleClose = () => {
    moreDetailsForm.hide();
    setConfirmModalText("Are you sure you want to dismiss this recommendation?");
    setConfirmModalAction(() => onDismissClick);
    confirmModal.show();
  };

  const renderCardBody = (truncateDescription: boolean) => (
    <div className={styles.statusCardBody}>
      <div className={styles.statusCardHeader}>
        <span
          className={styles.statusIcon}
          title={optimizationPriorityToString(model.priority)}
          style={{ backgroundColor: priorityColors[model.priority], color: "white" }}
        >
          {priorityIcons[model.priority]}
          <span className={styles.statusText}>{optimizationPriorityToString(model.priority)}</span>
        </span>
        <span className={styles.statusCardText}>{optimizationCategoryToString(model.category)}</span>
      </div>
      <h2 className={styles.statusCardTitle}>{model.title}</h2>
      <div className={styles.cardBody}>
        <p className={`${styles.statusCardDescription} ${!truncateDescription ? styles.fullDescription : ""}`}>
          {truncateDescription && model.description?.length > 100 ? model.description.substring(0, 100) + "..." : model.description}
        </p>
      </div>
    </div>
  );

  const handleApplyForMeClick = () => {
    moreDetailsForm.hide();
    setConfirmModalText(
      "Your request will be processed within 48 hours. Our support team will notify you once it's done.\nSit back and relax — I got this!"
    );
    setConfirmModalAction(() => onHelpMeClick);
    confirmModal.show();
  };

  const cardFooter = (
    <>
      {model.status === OptimizationStatusEnum.Processing && (
        <span className={`${styles.messageWrapper} ${styles.info}`}>
          <Spinner spinnerClass={styles.processingSpinner} size="12px" />
          Processing...
        </span>
      )}

      {model.status === OptimizationStatusEnum.SupportProcessing && (
        <span className={`${styles.messageWrapper}  ${styles.info}`}>
          In progress
          <OverlayTrigger
            key={`overlay-trigger`}
            placement="top"
            overlay={
              <Popover id={`tooltip`}>
                <Popover.Body>Processing your request. You will be notified by email once it's ready.</Popover.Body>
              </Popover>
            }
          >
            <div className="">
              <InformationLineIcon size={16} className="mL5" />
            </div>
          </OverlayTrigger>
        </span>
      )}
      <Permission has={GLOBAL_PERMISSIONS.outboss.view}>
        {model.status === OptimizationStatusEnum.SupportProcessing && (
          <>
            <Button
              id="dismissButton"
              onClick={() => {
                setConfirmModalText("Are you sure you want to dismiss this recommendation?");
                setConfirmModalAction(() => onDismissClick);
                confirmModal.show();
              }}
              variant="link_button"
              classes={styles.linkBtn}
            >
              Dismiss
            </Button>
            <Button id="markAsDone" onClick={onMarkAsDoneClick} variant="secondary-s" classes={styles.outbossBtn}>
              Mark as done
            </Button>
          </>
        )}
      </Permission>
      {model.status === OptimizationStatusEnum.Active && (
        <>
          <Button
            id="dismissBtn"
            onClick={() => {
              setConfirmModalText("Are you sure you want to dismiss this recommendation?");
              setConfirmModalAction(() => onDismissClick);
              confirmModal.show();
            }}
            variant="link_button"
            classes={styles.linkBtn}
          >
            Dismiss
          </Button>
          <div className={styles.rightGroupBtn}>
            <Button id="moreDetails" onClick={() => moreDetailsForm.show(model.id)} variant="link_button" classes={styles.linkBtn}>
              More details
            </Button>
            {model.actionType === OptimizationActionTypeEnum.Support ? (
              <Button id="applyForMeBtn" onClick={handleApplyForMeClick} variant="primary-s">
                Apply for me
              </Button>
            ) : model.actionType === OptimizationActionTypeEnum.Manual && model.redirectUrl ? (
              <Button
                id="takeMeThereBtn"
                isLink
                isLinkWithClick
                link={model.redirectUrl}
                onClick={handleClickTakeMeThere}
                variant="primary-s"
                classes={styles.applyButton}
              >
                Take me there
              </Button>
            ) : null}
          </div>
        </>
      )}
      {statusMessage && (
        <span className={`${styles.messageWrapper} ${statusClassName}`}>
          {iconStatus && <span className={styles.statusIconWrapper}>{iconStatus}</span>}
          {statusMessage}
        </span>
      )}
    </>
  );

  return (
    <>
      <div className={`${styles.statusCard} ${priorityClassName}`}>
        {renderCardBody(true)}
        <div className={styles.statusCardFooter}>{cardFooter}</div>
      </div>
      <MoreDetailsForm
        handleClose={handleClose}
        showModal={moreDetailsForm.isVisible}
        saveButtonLink={model.redirectUrl}
        isSaveButtonLink={model.actionType === OptimizationActionTypeEnum.Manual && model.redirectUrl ? true : false}
        handleSave={model.actionType === OptimizationActionTypeEnum.Support ? handleApplyForMeClick : moreDetailsForm.hide}
        thirdButtonClick={moreDetailsForm.hide}
        saveButtonText={
          model.actionType === OptimizationActionTypeEnum.Support
            ? "Apply for me"
            : model.actionType === OptimizationActionTypeEnum.Manual && model.redirectUrl
            ? "Take me there"
            : "OK"
        }
        children={renderCardBody(false)}
        onRedirect={handleClickTakeMeThere}
      />
      <ConfirmModal
        handleClose={confirmModal.hide}
        handleSave={confirmModalAction}
        showModal={confirmModal.isVisible}
        className={styles.confirmModalText}
      >
        {confirmModalText}
      </ConfirmModal>
    </>
  );
};

export default StatusCard;
