import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";

//Helpers
import Permission from "../../auth/Permission";
import { PERMISSIONS } from "../../../helper/permissionConstants";

//Styles
import styles from "./Modal.module.scss";

//Components
import Button from "../Button/Button";

const Modal = (props) => {
  let backdrop = "static";

  if (props.backdrop) {
    backdrop = true;
  }

  return (
    <BootstrapModal
      size={props.size}
      show={props.show}
      onHide={props.handleHideModal ?? props.handleClose}
      dialogClassName={`${styles.dialog} ${props.dialogClassName} modalDialog`}
      backdrop={backdrop}
      id={props.title ? props.title.replaceAll(" ", "") : ""}
      onShow={props.onShow}
    >
      {props.title ? (
        <BootstrapModal.Header className={styles.header} closeButton={props.closeButton ? props.closeButton : true} onHide={props.onHide}>
          <BootstrapModal.Title className={styles.header_title}>
            <h1 className={props.errorTitle ? "error_msg" : null}>{props.title}</h1>
          </BootstrapModal.Title>
        </BootstrapModal.Header>
      ) : null}
      <BootstrapModal.Body
        className={`${styles.body} ${props.customBodyClass}`}
        style={props.customBodyMinHeight ? { minHeight: props.customBodyMinHeight } : null}
      >
        {props.children}
      </BootstrapModal.Body>
      {props.removeFooter ? null : (
        <BootstrapModal.Footer className={`custom_modal_footer ${props.customFooterClass}`}>
          {props.footerDetails ? props.footerDetails : null}
          {props.threeButtonsLayout !== undefined && props.threeButtonsLayout === true ? (
            <div className={styles.threeButtonRow}>
              {props.closeButtonText && (
                <Button
                  variant={props.closeButtonVariant ? props.closeButtonVariant : "secondary"}
                  onClick={props.handleClose}
                  id={props.idThirdCloseButton ? props.idThirdCloseButton : props.closeButtonText.replaceAll(" ", "")}
                >
                  {props.closeButtonText}
                </Button>
              )}
              {props.fourthButtonText && (
                <Button
                  variant={props.fourthButtonVariant || "primary"}
                  onClick={props.handleFourthButtonClick}
                  classes="m0"
                  type={props.btnType}
                  disabled={props.fourthButtonDisabled}
                  id={props.idFourthButton ? props.idFourthButton : props.fourthButtonText.replaceAll(" ", "")}
                >
                  {props.fourthButtonText}
                </Button>
              )}

              <div className="flex">
                <Permission has={props.thirdButtonPermission ? props.thirdButtonPermission : PERMISSIONS.application.view}>
                  <Button
                    id={props.idThirdSubmitButton ? props.idThirdSubmitButton : props.thirdButtonText.replaceAll(" ", "")}
                    variant={props.thirdButtonVariant ? props.thirdButtonVariant : "primary"}
                    onClick={props.thirdButtonClick}
                    classes="mR5"
                    type={props.btnType}
                    disabled={props.thirdButtonDisabled}
                  >
                    {props.thirdButtonText}
                  </Button>
                </Permission>
                <Permission has={props.saveButtonPermission ? props.saveButtonPermission : PERMISSIONS.application.view}>
                  {props.isSaveButtonLink ? (
                    <a
                      id={props.saveButtonText.replaceAll(" ", "")}
                      className={styles.secondary}
                      target={props.linkTarget || "_blank"}
                      href={props.saveButtonLink}
                      onClick={() => {
                        if (props.onRedirect) props.onRedirect;
                      }}
                    >
                      {props.saveButtonText}
                    </a>
                  ) : (
                    <Button
                      id={props.idThirdPrimaryButton ? props.idThirdPrimaryButton : props.saveButtonText.replaceAll(" ", "")}
                      variant={props.saveButtonVariant || "primary"}
                      onClick={props.handleSave}
                      type={props.btnType}
                      disabled={props.saveButtonDisabled}
                    >
                      {props.saveButtonText}
                    </Button>
                  )}
                </Permission>
              </div>
            </div>
          ) : (
            <>
              <div className="flex">
                {props.oneButton ? null : (
                  <div id="cancelButton" className="p5">
                    <Button
                      id={props.idCloseButton ? props.idCloseButton : props.closeButtonText.replaceAll(" ", "")}
                      variant="secondary"
                      onClick={props.handleClose}
                      classes="m0"
                    >
                      {props.closeButtonText}
                    </Button>
                  </div>
                )}
                <div id="confirmButton" className="p5" xs={props.oneButton ? 12 : 6}>
                  {props.isSaveButtonLink ? (
                    <a
                      id={props.saveButtonText.replaceAll(" ", "")}
                      className={styles.secondary}
                      target={props.linkTarget || "_blank"}
                      href={props.saveButtonLink}
                    >
                      {props.saveButtonText}
                    </a>
                  ) : (
                    <Button
                      id={props.idSaveButton ? props.idSaveButton : props.saveButtonText ? props.saveButtonText.replaceAll(" ", "") : ""}
                      variant={props.oneButton ? "secondary" : props.variant != null ? props.variant : "primary"}
                      onClick={props.handleSave}
                      classes="m0"
                      type={props.btnType}
                      disabled={props.saveButtonDisabled}
                      isLoading={props.isReconnectLoading}
                    >
                      {props.saveButtonText}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </BootstrapModal.Footer>
      )}
    </BootstrapModal>
  );
};

export default Modal;
