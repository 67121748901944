import * as actionTypes from "./actionTypes";
import api, { API } from "../../../../helper/api/api";

export const fetchSettingsRequest = () => {
  return {
    type: actionTypes.FETCH_GENERAL_SETTINGS_REQUEST,
  };
};

export const fetchSettingsSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_GENERAL_SETTINGS_SUCCEEDED,
    result: settings,
  };
};

export const fetchSettingsFailed = (error) => {
  return {
    type: actionTypes.FETCH_GENERAL_SETTINGS_FAILED,
    result: error,
  };
};

export const fetchSettings = () => {
  return (dispatch) => {
    dispatch(fetchSettingsRequest());
    return api(API.organization.settings.get)
      .then((response) => {
        dispatch(fetchSettingsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSettingsFailed(error.message));
      });
  };
};

export const fetchOrganizationOnboardingRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_ONBOARDING_REQUEST,
  };
};

export const fetchOrganizationOnboardingSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_ONBOARDING_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationOnboardingFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_ONBOARDING_FAILED,
    result: error,
  };
};

export const fetchOrganizationOnboarding = () => {
  return (dispatch) => {
    dispatch(fetchOrganizationOnboardingRequest());
    return api(API.organization.getOnboardingSteps)
      .then((response) => {
        dispatch(fetchOrganizationOnboardingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchOrganizationOnboardingFailed(error.message));
      });
  };
};
