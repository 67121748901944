import { api } from "./api";
import { DropdownModel } from "../models/DropDownModel";
import { CampaignsEmailAccounts } from "@models/emailAccount/CampaignEmailAccounts";
import { EmailAccountsListModel } from "@models/emailAccount/EmailAccountsListModel";
import CreateFromThirdPartyModel from "@models/emailAccount/CreateFromThirdPartyModel";
import { AxiosResponse } from "axios";

const emailAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmailAccountsSelectList: builder.query<DropdownModel[], void>({
      query: () => "/email-accounts/select-list-email-accounts",
      providesTags: ["EmailAccountsSelectList"],
    }),
    getEmailAccountsForCampaigns: builder.query<CampaignsEmailAccounts[], number[]>({
      query: (campaignIds) => ({
        url: `/email-accounts/list-email-accounts-multi-campaigns`,
        method: "POST",
        body: campaignIds,
      }),
      providesTags: ["EmailAccountsForCampaigns"],
    }),
    getEmailAccountsList: builder.query<EmailAccountsListModel[], number | undefined>({
      query: (campaignId) => `/email-accounts/list-email-accounts?campaignId=${campaignId}`,
      providesTags: ["EmailAccountsList"],
    }),
    createThirdParty: builder.mutation<AxiosResponse<any, any>, { model: CreateFromThirdPartyModel }>({
      query: (model) => ({
        url: `/email-accounts/create-third-party`,
        method: "POST",
        body: model,
      }),
    }),
  }),
});

export const {
  useGetEmailAccountsSelectListQuery,
  useGetEmailAccountsForCampaignsQuery,
  useGetEmailAccountsListQuery,
  useCreateThirdPartyMutation,
} = emailAccountApi;
