import React, { useEffect, useState } from "react";
import _ from "lodash";

//RTK
import { useGetCalendarEventDetailsQuery, useGetCalendarEventsQuery } from "@api/calendarApi";
import { useGetEmailAccountsForCampaignsQuery } from "@api/emailAccountApi";

//Helpers
import useSelectList from "@helper/hooks/query/useSelectList";
import { SelectListEnum } from "@helper/hooks/query/SelectListEnum";

// Styles
import styles from "./Calendar.module.scss";

//Images & Icons
import EmptyCampaignList from "@assets/Images/EmptyCampaignList/EmptyCampaignList";

//Components
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import CalendarView from "@components/UI/Calendar/Calendar";
import DelayedInput from "@components/UI/DelayedInput/DelayedInput";
import CalendarDetails from "./components/CalendarDetails/CalendarDetails";
import SectionTitle from "@components/UI/SectionTitle/SectionTitle";
import AgentAccount from "./components/AgentAccounts/AgentAccount";
import EmptyState from "@components/UI/EmptyState/EmptyState";
import SkeletonThreeRowCard from "@components/UI/Skeletons/components/SkeletonThreeRowCard/SkeletonThreeRowCard";
import InfoIconTooltip from "@components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "@components/PageContentWrapper/PageContentWrapper";
import { useOrganizationId } from "@hooks/useOrganizationId";
import moment from "moment";

const Calendar = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [searchAccount, setSearchAccount] = useState("");

  const organizationId = useOrganizationId();

  const { data: emailAccountsList } = useGetEmailAccountsForCampaignsQuery(campaigns, {
    skip: !campaigns,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: events,
    isFetching: fetchingEvents,
    // refetch: refetchEvents,
  } = useGetCalendarEventsQuery({ campaigns, emailAccounts: selectedAccounts }, { skip: !campaigns || !selectedAccounts });

  let image = <EmptyCampaignList />;

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);

  const eventDetailsQuery = useGetCalendarEventDetailsQuery(
    { campaignId: selectedEvent?.campaignId, date: selectedEvent?.date, emailAccounts: selectedAccounts },
    { skip: !selectedEvent, refetchOnMountOrArgChange: true }
  );

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);

  useEffect(() => {
    if (!_.isEmpty(campaignsList)) {
      setCampaigns(campaignsList.map((campaign) => campaign.value));
    }
  }, [campaignsList]);

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Daily send and error reports will show on the calendar once the campaign is activated."}
      href={"https://help.outbase.com/user-guide/campaigns/calendar"}
      linkText="Read More"
      buttonId="startCalendarTour"
      disableStartTourButton
    />
  );

  const selectCampaignHandle = (c) => {
    setCampaigns(c);
    setFilteredCampaigns(c);
  };

  let selectRowOptions = [
    {
      option: campaignsList,
      change: (c) => selectCampaignHandle(c),
      placeholder: "Campaigns",
      value: filteredCampaigns,
    },
  ];

  useEffect(() => {
    handleCloseDetails();
  }, [selectedAccounts]);

  const handleSearchChange = (text) => {
    setSearchAccount(text);
  };

  const handleEventChange = (event) => {
    if (moment.isMoment(event.date)) {
      event.date = event.date.format("YYYY-MM-DDTHH:mm:ss");
    }

    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  const handleCloseDetails = () => {
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  let content = null;
  let agentsList = <SkeletonThreeRowCard />;

  if (fetchingEvents) {
    content = <SkeletonThreeRowCard />;
  } else if (!_.isEmpty(campaigns) && _.isEmpty(events)) {
    content = (
      <div className={styles.emptyState}>
        <EmptyState
          image={image}
          title="No active or scheduled campaigns scheduled"
          description="Once a campaign has been scheduled and activated, details of the sends can be found here."
        />
      </div>
    );
  } else {
    content = <CalendarView events={events} onEventChange={handleEventChange} />;
  }

  if (!_.isEmpty(emailAccountsList)) {
    agentsList = (
      <AgentAccount
        searchAccount={searchAccount}
        emailAccountsList={emailAccountsList}
        setSelectedAccounts={setSelectedAccounts}
        selectedAccounts={selectedAccounts}
      />
    );
  } else {
    agentsList = null;
  }
  return (
    <PageContentWrapper>
      <HeaderTitle title="Campaigns Calendar" icon={icon} />
      <div className={`${styles.pageWrapper} flex`}>
        <div className={styles.leftSideContent}>
          <TableFiltersRow leftContent={null} selects={selectRowOptions} showPerPage={false} />

          <div className={styles.agentsWrapper}>
            <SectionTitle title="FILTER BY EMAIL ACCOUNT" />
            <div className={styles.searchWrapper}>
              <DelayedInput
                type="text"
                placeholder={"Search for account..."}
                textChanged={handleSearchChange}
                showSearchIcon={true}
                ariaLabel="Search"
              />
            </div>
            <div className={styles.agentsList}>{agentsList}</div>
          </div>
        </div>
        <div className={styles.rightSideContent}>
          <div className={`${styles.calendar}`}>{content}</div>
        </div>
        {showEventDetails && eventDetailsQuery.data ? (
          <CalendarDetails
            {...eventDetailsQuery.data}
            onClose={handleCloseDetails}
            showEventDetails={showEventDetails}
            onEventChange={handleEventChange}
            isLoadingDetails={eventDetailsQuery.isLoading}
            organizationId={organizationId}
            emailAccounts={selectedAccounts}
          />
        ) : null}
      </div>
    </PageContentWrapper>
  );
};

export default Calendar;
