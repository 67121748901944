import FormikTags from "@ui/Formik/FormikTags";

const FilterCompanyKeywords = () => {
  return (
    <FormikTags
      id="keyword"
      placeholder="e.g. Fintech"
      name="company.keywords"
      content={
        <p>
          Press <b>enter</b> to add a keyword.
        </p>
      }
      allowIncludeExclude={true}
    />
  );
};

export default FilterCompanyKeywords;
