import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Images & Icons
import SubtractLineIcon from "remixicon-react/SubtractLineIcon";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import ArrowUpSLineIcon from "remixicon-react/ArrowUpSLineIcon";

//Styles
import styles from "./AccordionSimple.module.scss";

interface AccordionSimpleProps {
  variant?: string;
  defaultOpen?: boolean;
  className?: string;
  eventKey: string;
  header: string;
  subHeader?: string;
  rightContent?: number;
  noCaret?: boolean;
  infoIconTooltip?: boolean;
  popoverContent?: string;
  id?: string;
  icon?: JSX.Element;
  iconHeader?: JSX.Element;
  headTitleClass?: string;
  customAccordionBodyClass?: string;
  body: JSX.Element;
  outerBody?: JSX.Element;
}

const AccordionSimple: React.FC<AccordionSimpleProps> = ({
  defaultOpen,
  variant,
  className,
  header,
  eventKey,
  id,
  icon,
  headTitleClass,
  iconHeader,
  subHeader,
  rightContent,
  noCaret,
  infoIconTooltip,
  popoverContent,
  customAccordionBodyClass,
  body,
  outerBody,
}) => {
  let classes = styles.accordionStyle;

  if (variant != null) {
    classes += " " + styles[variant];
  } else {
    classes += " " + styles.border;
  }

  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const withColor = open ? styles.active : "";

  return (
    <div className={`${styles.accordionWrapper} ${className ? className : ""} `}>
      <Accordion id={"accordionHeader" + header.replace(/[^A-Z0-9]/gi, "")} activeKey={open ? eventKey : ""}>
        <Accordion.Item className={classes} eventKey={eventKey}>
          <Card.Header className={`${styles.cardHeader} ${withColor}`}>
            <Accordion.Header
              as={Card.Header}
              variant="link"
              className={styles.toggleWrapper}
              onClick={() => setOpen(!open)}
              id={id || eventKey + "toggle"}
            >
              <div className={styles.titleWrapper}>
                {icon ? icon : null}
                <div className={styles.accordionTitle}>
                  <p className={`${headTitleClass ? headTitleClass : ""} ${styles.headTitle}`}>
                    {iconHeader ? iconHeader : null}
                    {header}
                  </p>
                  {_.isEmpty(subHeader) ? null : <p className={styles.description}>{subHeader}</p>}
                </div>
                {rightContent !== null && rightContent !== undefined ? (
                  rightContent !== 0 ? (
                    <div className={`badge_gray ${styles.rightContentNumber}`}>
                      <span>{rightContent}</span>
                    </div>
                  ) : null
                ) : null}
              </div>
              {noCaret ? (
                open ? (
                  <ArrowUpSLineIcon color="#2a3347" size={16} />
                ) : (
                  <ArrowDownSLineIcon color="#2a3347" size={16} />
                )
              ) : open ? (
                <SubtractLineIcon color="#2a3347" size={14} />
              ) : (
                <AddLineIcon color="#2a3347" size={14} />
              )}
              {infoIconTooltip && (
                <div className={styles.infoIconTooltipNumber}>
                  <OverlayTrigger
                    key={"key"}
                    placement={"bottom"}
                    overlay={
                      <Popover id={`popover-tooltip`}>
                        <Popover.Body>{popoverContent}</Popover.Body>
                      </Popover>
                    }
                  >
                    <div className="max-x-content flex_center">
                      <InformationFillIcon size={18} />
                    </div>
                  </OverlayTrigger>
                </div>
              )}
            </Accordion.Header>
          </Card.Header>
          <Accordion.Body
            id={"accordionBody" + header.replace(" ", "")}
            className={`${styles.accordionBody} ${customAccordionBodyClass ? customAccordionBodyClass : ""}`}
          >
            {body}
          </Accordion.Body>
          {open ? null : <div className={styles.outerBodyTags}>{outerBody}</div>}
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AccordionSimple;
