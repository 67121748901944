import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const resetEmailAccount = () => {
  return {
    type: actionTypes.RESET_EMAIL_ACCOUNT,
  };
};

export const activateEmailAccountTest = (val) => {
  return {
    type: actionTypes.ACTIVATE_EMAIL_ACCOUNT_TEST,
    result: val,
  };
};

export const fetchEmailAccountRequest = () => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNT_REQUEST,
  };
};

export const fetchEmailAccountSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNT_SUCCEEDED,
    result: settings,
  };
};

export const fetchEmailAccountFailed = (error) => {
  console.log("Fetch Failed!");
  return {
    type: actionTypes.FETCH_EMAIL_ACCOUNT_FAILED,
    result: error,
  };
};

export const fetchEmailAccount = (data) => {
  return (dispatch) => {
    if (data === 0) dispatch(fetchEmailAccountSuccess(null));
    else {
      dispatch(fetchEmailAccountRequest());
      return api(formUrl(API.emailAccounts.get, { id: data }))
        .then((response) => {
          dispatch(fetchEmailAccountSuccess(response.data));
        })
        .catch((error) => {
          dispatch(fetchEmailAccountFailed(error.message));
        });
    }
  };
};
