import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";
import { toast } from "react-toastify";

export const clearListOfProspects = () => {
  return {
    type: actionTypes.CLEAR_LIST_OF_PROSPECTS,
  };
};

export const fetchListOfProspectsRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_OF_PROSPECTS_REQUEST,
  };
};

export const fetchListOfProspectsSuccess = (campaigns) => {
  return {
    type: actionTypes.FETCH_LIST_OF_PROSPECTS_SUCCEEDED,
    result: campaigns,
  };
};

export const fetchListOfProspectsFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_OF_PROSPECTS_FAILED,
    result: error,
  };
};

export const fetchListOfProspects = (emailAccountId, email) => {
  return (dispatch) => {
    dispatch(fetchListOfProspectsRequest());
    return api(
      formUrl(API.responderInbox.getListOfProspects, {
        emailAccountId: emailAccountId,
        email: email,
      })
    )
      .then((response) => {
        dispatch(fetchListOfProspectsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListOfProspectsFailed(error.message));
      });
  };
};

export const fetchTotalNumberOfEmailsRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_NUMBER_OF_EMAILS_REQUEST,
  };
};

export const fetchTotalNumberOfEmailsSuccess = (totalEmails) => {
  return {
    type: actionTypes.FETCH_TOTAL_NUMBER_OF_EMAILS_SUCCEEDED,
    result: totalEmails,
  };
};

export const fetchTotalNumberOfEmailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_NUMBER_OF_EMAILS_FAILED,
    result: error,
  };
};

export const fetchTotalNumberOfEmails = () => {
  return (dispatch) => {
    dispatch(fetchTotalNumberOfEmailsRequest());
    return api(API.responderInbox.getTotalNumberOfEmailsByType)
      .then((response) => {
        dispatch(fetchTotalNumberOfEmailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTotalNumberOfEmailsFailed(error.message));
      });
  };
};

export const fetchMailDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_MESSAGE_PREVIEW_REQUEST,
  };
};

export const fetchMailDetailsSuccess = (message) => {
  return {
    type: actionTypes.FETCH_MESSAGE_PREVIEW_SUCCEEDED,
    result: message,
  };
};

export const fetchMailDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_MESSAGE_PREVIEW_FAILED,
    result: error,
  };
};

export const fetchMailDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchMailDetailsRequest());
    return api(formUrl(API.responderInbox.getMailDetails, { id: id }))
      .then((response) => {
        dispatch(fetchMailDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchMailDetailsFailed(error.message));
      });
  };
};

export const setActiveTab = (value) => {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    result: value,
  };
};

export const setFilterForReview = (value) => {
  return {
    type: actionTypes.SET_FILTER_FOR_REVIEW,
    result: value,
  };
};

export const changeBackToUnresolveRequest = () => {
  return {
    type: actionTypes.CHANGE_BACK_TO_UNRESOLVED_REQUEST,
  };
};

export const changeBackToUnresolveSuccess = (message) => {
  return {
    type: actionTypes.CHANGE_BACK_TO_UNRESOLVED_SUCCEEDED,
    result: message,
  };
};

export const changeBackToUnresolveFailed = (error) => {
  return {
    type: actionTypes.CHANGE_BACK_TO_UNRESOLVED_FAILED,
    result: error,
  };
};

export const changeBackToUnresolve = (messagePreview) => {
  let emailId = messagePreview.id;
  return (dispatch) => {
    dispatch(changeBackToUnresolveRequest());
    return api(
      formUrl(API.responderInbox.backToUnresolve, { emailId: emailId })
    )
      .then((response) => {
        dispatch(changeBackToUnresolveSuccess(messagePreview));
        dispatch(countChange(-1));
      })
      .catch((error) => {
        dispatch(changeBackToUnresolveFailed(error.message));
      });
  };
};

export const changeToResolveRequest = () => {
  return {
    type: actionTypes.CHANGE_RESOLVE_REQUEST,
  };
};

export const changeToResolveSuccess = (message) => {
  return {
    type: actionTypes.CHANGE_RESOLVE_SUCCEEDED,
    result: message,
  };
};

export const countChange = (result) => {
  return {
    type: actionTypes.RESPONDER_INBOX_COUNT_CHANGE,
    result: result,
  };
};

export const changeToResolveFailed = (error) => {
  return {
    type: actionTypes.CHANGE_RESOLVE_FAILED,
    result: error,
  };
};

export const changeToResolve = (messagePreview) => {
  let data = {
    id: messagePreview.id,
    responseType: messagePreview.responseType,
    prospectEmail: messagePreview.prospectEmail,
    referralEmails: messagePreview.referralEmails,
  };

  return (dispatch) => {
    dispatch(changeToResolveRequest());
    return api(API.responderInbox.resolve, data)
      .then((response) => {
        dispatch(changeToResolveSuccess(messagePreview));
        dispatch(countChange(1));
      })
      .catch((error) => {
        dispatch(changeToResolveFailed(error.message));
      });
  };
};

export const setLocation = (value) => {
  return {
    type: actionTypes.SET_LOCATION,
    result: value,
  };
};

export const confirmAllAutoResolved = () => {
  return (dispatch) => {
    dispatch(confirmAllAutoResolvedRequest());
    return api(
      API.responderInbox.confirmAllAutoResolved)
      .then((response) => {
        dispatch(confirmAllAutoResolvedSuccess());
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(confirmAllAutoResolvedFailed(error.message));
      });
  };
};

export const confirmAllAutoResolvedRequest = () => {
  return {
    type: actionTypes.CONFIRM_ALL_AUTO_RESOLVED_REQUEST,
  };
};

export const confirmAllAutoResolvedSuccess = () => {
  return {
    type: actionTypes.CONFIRM_ALL_AUTO_RESOLVED_SUCCEEDED,
  };
};

export const confirmAllAutoResolvedFailed = (error) => {
  return {
    type: actionTypes.CONFIRM_ALL_AUTO_RESOLVED_FAILED,
    result: error,
  };
};