import React, { useEffect, useState } from "react";

//Helpers
import { daysOfWeek } from "../../../../../helper/scheduleConstants";

//Styles
import styles from "./ListDays.module.scss";

//Components
import DayTimeToSend from "./DayTimeToSend/DayTimeToSend";
import FormikCheckbox from "../../../../../components/UI/Formik/FormikCheckbox";

const ListDays = (props) => {
  let message = props.formikProps.errors.wednesday !== undefined ? props.formikProps.errors.wednesday.from : null;

  let list = daysOfWeek.map((day, index) => {
    const currentDay = props.formikProps.values[day];
    const dayShort = day.substring(0, 3);

    return (
      <div key={index} className={styles.wrapingRow}>
        <FormikCheckbox
          labelstyle={styles.textLabel}
          name={day + ".enabled"}
          checked={currentDay.enabled}
          label={dayShort}
          onChange={(value) => {
            props.setFieldValue(day + ".enabled", value);
            props.setIsDirty(true);
          }}
        />
        <DayTimeToSend
          {...props}
          formikProps={props.formikProps}
          currentDay={currentDay}
          day={day}
          disabled={!currentDay.enabled}
          onChange={(value) => {
            props.setFieldValue(day, value);
            props.setIsDirty(true);
          }}
        />
      </div>
    );
  });
  return (
    <div id="listDays" className={styles.formWrapper}>
      <p className={styles.titleSection}>Weekly schedule</p>
      <div className="flex">
        <div className={styles.dateFromTo}>
          <p className={styles.from}>From: </p>
          <p className={styles.toLabel}>To: </p>
        </div>
        {list}
      </div>
      <div className="flex error_msg">{message}</div>
    </div>
  );
};

export default ListDays;
