import React from "react";

//Helpers
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";

//Style
import styles from "./ProspectBox.module.scss";

const ProspectBox = (props) => {
  let separators = useGroupDecimalSeparator();
  let numberFormatted = formatNumberHelper(props.number, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  let classes = `${styles.total} ` + props.totalClassName;

  return (
    <div className={` ${styles.prospectBox} ${props.className} border-5 flex`} style={{ backgroundColor: props.backgroundColor }}>
      <span className={classes}>{numberFormatted}</span>
      <span className={styles.status}>{props.status}</span>
    </div>
  );
};

export default ProspectBox;
