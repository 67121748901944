import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";

//Helpers
import useSelectList from "../../../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../../../helper/hooks/query/SelectListEnum";
import api, { API } from "../../../../helper/api/api";
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import { errorResponseToList } from "../../../../helper/errorHelper";

//Styles
import styles from "../Prospects.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import Select from "../../../../components/UI/Select/Select";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import AlertNotification from "../../../../components/UI/AlertNotification/AlertNotification";

const MoveProspectsToCampaignForm = (props) => {
  let renderPage = null;
  const [page, setPage] = useState(1);
  const [saveButtonText, setSaveButtonText] = useState("Next");
  const [closeButtonText, setCloseButtonText] = useState("Cancel");
  const { data: unarchivedCampaignsList } = useSelectList(SelectListEnum.UnarchivedCampaignsList);
  const filteredCampaignsList = unarchivedCampaignsList
    ? unarchivedCampaignsList.filter((item) => item.value !== props.campaignId.toString())
    : [];
  const [countProspectsMessage, setCountProspectsMessage] = useState("");
  const [eligibleProspectsForMove, setEligibleProspectsForMove] = useState(0);

  const resetLocalStates = (setSubmitting) => {
    setCountProspectsMessage("");
    setCloseButtonText("Close");
    if (setSubmitting) setSubmitting(false);
  };

  const moveOrCountProspects = (values, setSubmitting, setFieldError, moveProspects = false) => {
    let data = {
      oldCampaignId: props.campaignId,
      newCampaignId: values.campaignId,
      allProspectsSelected: props.allProspectsSelected,
      markedProspects: props.markedProspects,
      param: props.param,
      moveProspects: moveProspects,
    };
    setSubmitting(true);
    api(API.campaigns.moveProspectsToCampaign, data)
      .then((response) => {
        if (response) {
          if (response.data) {
            if (moveProspects) {
              if (eligibleProspectsForMove) toast.success(toastMessages.campaign.prospectMovedSuccessfully);
              props.handleClose();
              setPage(1);
              resetLocalStates(setSubmitting);
            } else {
              setEligibleProspectsForMove(response.data.movedProspects);
              let message = "No Prospects eligible to be moved to " + values.campaignName + ".";
              let saveButtonText = "Close";
              if (response.data.movedProspects > 0) {
                message = (
                  <>
                    <strong>{response.data.movedProspects}</strong> out of <strong>{response.data.totalSelectedProspects}</strong> prospects
                    qualify and will be transferred to <br /> {values.campaignName}.
                  </>
                );
                saveButtonText = "Confirm";
              }
              setSaveButtonText(saveButtonText);
              setCloseButtonText("Back");
              setSubmitting(false);

              setCountProspectsMessage(message);
            }
          }
        }
      })
      .catch((error) => {
        setSubmitting(false);
        const errorList = errorResponseToList(error);
        if (errorList.length > 0) {
          errorList.forEach((e) => setFieldError(e.field, e.message));
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleClose = (setSubmitting) => {
    if (page === 2) {
      setPage(1);
      setSaveButtonText("Next");
    } else {
      props.handleClose();
    }
    resetLocalStates(setSubmitting);
  };

  useEffect(() => {
    setPage(1);
    resetLocalStates();
  }, []);

  return (
    <Formik
      initialValues={{
        campaignId: 0,
        campaignName: "",
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        if (page === 1) setPage(2);
        moveOrCountProspects(values, actions.setSubmitting, actions.setFieldError, page === 2);
      }}
    >
      {(formikProps) => {
        const { handleSubmit, values, isSubmitting, setSubmitting, setFieldValue } = formikProps;
        switch (page) {
          case 1:
            renderPage = (
              <div className={styles.fieldInfoContainer}>
                <p className={styles.labelText}>Choose campaign to move selected prospects to</p>
                <Select
                  isMenuPortalTargetDisabled
                  name={"campaignId"}
                  placeholder={"Select Campaign"}
                  options={filteredCampaignsList}
                  onChange={(event) => {
                    setFieldValue("campaignId", event.value);
                    setFieldValue("campaignName", event.label);
                    setSubmitting(false);
                  }}
                  onChangeSelectedOption={true}
                />
              </div>
            );
            break;
          case 2:
            renderPage = (
              <>
                {isSubmitting === true ? (
                  <>
                    <Spinner size="25px" spinnerClass="mB15" />
                  </>
                ) : (
                  <>
                    <p className={styles.labelText}>{countProspectsMessage}</p>
                  </>
                )}
              </>
            );
            break;
          default:
            break;
        }
        return (
          <Modal
            title={"Move to campaign"}
            handleClose={() => handleClose(setSubmitting)}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText={closeButtonText}
            saveButtonText={saveButtonText}
            saveButtonDisabled={isSubmitting || values.campaignId === 0}
            btnType="submit"
            size="md"
          >
            <>
              {renderPage}
              <AlertNotification variant="info_alert_lighter" bodyClassName="flex">
                <div>
                  <strong className="f14">Please Note</strong>
                  <ul>
                    <li>Prospects with any type of reply (automatic or manual) can not be moved to another campaign</li>
                    <li>Prospects removed from a campaign cannot be re-added to the original campaign.</li>
                    <li>
                      If prospects have already been contacted in a prior campaign today, they will be scheduled for contacting tomorrow or
                      according to the new campaign schedule.
                    </li>
                  </ul>
                </div>
              </AlertNotification>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default MoveProspectsToCampaignForm;
