import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

//Redux
import { setFilter } from "../../store/Conversation/Table/actions/conversationTable";

//Helpers
import { filterData, getFilterValue } from "../../helper/tableConstants";
import { responseTypeOptions } from "./helpers/responseTypeOptions";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";

//Styles
import styles from "./Conversation.module.scss";

//Images & Icons
import EmptyData from "../../assets/Images/EmptyData/EmptyData";

// Components
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import ConversationInbox from "./components/ConversationInbox/ConversationInbox";
import ConversationPreview from "./components/ConversationPreview/ConversationPreview";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import ProspectDetailsPreview from "./components/ProspectDetailsPreview/ProspectDetailsPreview";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const Conversation = (props) => {
  const { isLoaded, rows } = props;
  const [params, setParams] = useState(null);
  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);
  const { data: emailAccountsList } = useSelectList(SelectListEnum.EmailAccounts);

  const icon = (
    <InfoIconTooltip
      tooltipBody={"An inbox style view of all the conversations happening from your campaign activity."}
      href={"https://help.outbase.com/user-guide/sales/conversations"}
      linkText="Read More"
      disableStartTourButton
    />
  );

  let selectRowOptions = [
    {
      option: campaignsList,
      change: (c) => filterData("Campaign", c, props.setFilter),
      placeholder: "Campaigns",
      value: getFilterValue(params?.filter, "Campaign"),
    },
    {
      option: emailAccountsList,
      change: (ea) => filterData("EmailAccount", ea, props.setFilter),
      placeholder: "Email Account",
      value: getFilterValue(params?.filter, "EmailAccount"),
    },
    {
      option: responseTypeOptions,
      change: (rt) => filterData("ResponseType", rt, props.setFilter),
      placeholder: "Response Type",
      value: getFilterValue(params?.filter, "ResponseType"),
    },
  ];

  let image = null;
  image = <EmptyData className={styles.emptyDataImage} />;

  let content = null;
  content = "No messages found yet";

  let data = null;
  if (isLoaded) {
    if (!_.isEmpty(rows)) {
      data = (
        <>
          <div className={styles.middlePart}>
            <ConversationPreview />
          </div>
          <div className={styles.rightPart}>
            <ProspectDetailsPreview />
          </div>
        </>
      );
    } else
      data = (
        <div className={styles.pageWrapper}>
          <EmptyState image={image} content={content} />
        </div>
      );
  }

  return (
    <>
      <div className={`${styles.conversationWrapper} contentHeight`}>
        <>
          <HeaderTitle title="Conversations" icon={icon} />
          <TableFiltersRow selects={selectRowOptions} showPerPage={false} />
        </>
        <div className={`${styles.contentWrapper} flex`}>
          <div className={styles.leftPart}>
            <ConversationInbox setParams={setParams} />
          </div>
          {data}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoaded: state.conversationTable.isLoaded,
    rows: state.conversationTable.rows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilter: (type, value) => dispatch(setFilter(type, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
