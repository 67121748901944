export const isMoreThanSecondsAgo = (time, seconds) => {
  const secondAgo = new Date().getTime() - seconds * 1000;
  const notificationTime = time.getTime();

  return notificationTime < secondAgo;
};

export const isMoreThanASecondAgo = (time) => {
  return isMoreThanSecondsAgo(time, 1);
};

export const convertMinutesToSeconds = (time) => {
  return time * 60;
};

export const convertSecondsToMinutes = (time) => {
  return time / 60;
};
