import * as actionTypes from "./actionTypes";
import * as dataTableActionTypes from "../../../hor/dataTableActionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

const name = "AUDIENCES";

export const fetchAudiencesRequest = () => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_REQUEST}`,
  };
};

export const fetchAudiencesSuccess = (setup) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_SUCCEEDED}`,
    result: setup,
  };
};

export const fetchAudiencesFailed = (error) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_FAILED}`,
    result: error,
  };
};

export const fetchAudiences = (param) => {
  return (dispatch) => {
    dispatch(fetchAudiencesRequest());
    return api(API.audience.post, param)
      .then((response) => {
        dispatch(fetchAudiencesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAudiencesFailed(error.message));
      });
  };
};

export const initiate = (state) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_INITIATE}`,
    result: state,
  };
};

export const changePage = (page) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CHANGE_PAGE}`,
    result: page,
  };
};

export const sortData = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SORT_DATA}`,
    result: obj,
  };
};

export const numRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_NUM_RECORDS}`,
    result: obj,
  };
};

export const searchRecords = (value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SEARCH_RECORDS}`,
    result: value,
  };
};

export const filterRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FILTER_RECORDS}`,
    result: obj,
  };
};

export const setFilter = (type, value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SET_FILTER}`,
    filterType: type,
    filterValue: value,
  };
};

export const clearFilter = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_FILTER}`,
    filterType: type,
  };
};

export const clearInitiated = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_INITIATED}`,
  };
};

export const updateRows = (rows) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_UPDATE_ROWS}`,
    rows: rows,
  };
};
