import React from "react";

//Styles
import styles from "./HeaderTitle.module.scss";

//Components
import HeaderSearch from "./components/HeaderSearch";
import HeaderSection from "../HeaderSection/HeaderSection";
interface HeaderTitleProps {
  title?: string;
  icon?: JSX.Element;
  searchPlaceholder?: string;
  textChanged?: string;
  secondTitle?: string | JSX.Element;
  showSearch?: boolean;
  rightContent?: string | JSX.Element;
  className?: string;
}

const HeaderTitle: React.FC<HeaderTitleProps> = (props) => {
  const leftContent = (
    <div className={styles.headerTitle}>
      {props.title} {props.icon}
    </div>
  );
  let rightContent = null;

  if (props.showSearch) {
    rightContent = <HeaderSearch placeholder={props.searchPlaceholder || "Search"} textChanged={props.textChanged} />;
  } else if (props.secondTitle != null) {
    rightContent = <div className={styles.secondTitle}>{props.secondTitle}</div>;
  } else if (props.rightContent != null) {
    rightContent = <div className={styles.rightContent}>{props.rightContent}</div>;
  }

  return <HeaderSection leftContent={leftContent} rightContent={rightContent} className={props.className}></HeaderSection>;
};

export default HeaderTitle;
