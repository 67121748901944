import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./TargetingCriteriaColumn.module.scss";

//Icons
import LocationPinIcon from "@icons/LocationPinIcon/LocationPinIcon";
import IconIndustry from "@icons/IconIndustry/IconIndustry";
import IconCompany from "@icons/IconCompany/IconCompany";
import IconBriefcase from "@icons/IconBriefcase/IconBriefcase";
import { useGetSearchQuery } from "@api/audienceExplorerApi";
import { LocationFilterType, SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

interface Props {
  searchModel: SearchModel;
  targetingWrapper?: string;
  maxLength: number;
}

interface InfoBlockProps {
  icon: React.ElementType;
  title: string;
  values: string[];
  showTooltip?: boolean;
}

const TargetingCriteriaColumn: React.FC<Props> = ({ searchModel, targetingWrapper, maxLength }) => {
  const noneSelected = ["Non selected"];

  const InfoBlock: React.FC<InfoBlockProps> = ({ icon: Icon, title, values }) => (
    <div className={styles.infoBlock}>
      <span className={styles.infoBlockIcon}>
        <Icon />
      </span>
      {values?.join(", ").length > maxLength ? (
        <OverlayTrigger
          key={`tooltip-${title}`}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${title}`}>
              <Popover.Body>{values?.join(", ")}</Popover.Body>
            </Popover>
          }
        >
          <div>{`${values?.join(", ").substring(0, maxLength)}...`}</div>
        </OverlayTrigger>
      ) : values && values.length > 0 ? (
        values.join(", ")
      ) : (
        noneSelected
      )}
    </div>
  );

  return (
    <div className={`${styles.columnWrapper} ${targetingWrapper}`}>
      <div className={styles.innerColumn}>
        <p>Companies</p>
        <InfoBlock icon={IconCompany} title="Company size" values={searchModel?.company?.sizes} />
        <InfoBlock
          icon={IconIndustry}
          title="Company Industry"
          values={searchModel?.company?.industries?.map((x) => x.label)}
          showTooltip={true}
        />
        <InfoBlock
          icon={LocationPinIcon}
          title="Company Location"
          values={
            searchModel?.company?.location?.type == LocationFilterType.Range
              ? [searchModel?.company?.location?.range?.location?.name]
              : searchModel?.company?.location?.region?.locations?.length > 0
              ? searchModel?.company?.location?.region?.locations.map((x) => x.label)
              : []
          }
        />
      </div>
      <div className="width_50">
        <p>Contacts</p>
        <InfoBlock
          icon={IconBriefcase}
          title="Job Titles"
          values={[...(searchModel?.contact?.seniorities ?? []), ...(searchModel?.contact?.jobTitles.map((x) => x.label) ?? [])]}
          showTooltip={true}
        />
        <InfoBlock
          icon={LocationPinIcon}
          title="Contact Location"
          values={
            searchModel?.contact?.location?.type == LocationFilterType.Range
              ? [searchModel?.contact?.location?.range?.location?.name]
              : searchModel?.contact?.location?.region?.locations?.length > 0
              ? searchModel?.contact?.location?.region?.locations.map((x) => x.label)
              : []
          }
        />
      </div>
    </div>
  );
};

export default TargetingCriteriaColumn;
