import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

//Redux
import * as actions from "@store/EmailAccounts/Table/actions/emailAccounts";
import { fetchListUsers } from "@store/OrganizationSettings/Users/actions/users";
import {
  fetchEmailAccount,
  resetEmailAccount,
  activateEmailAccountTest,
} from "@store/EmailAccounts/EmailAccountSettings/actions/emailAccounts";
import {
  fetchBasicEmailSendTest,
  fetchBasicEmailTestDetails,
  clearInitiatedTest,
} from "@store/EmailDeliverabilityTest/Global/actions/basicEmailTest";
import { signalReceived } from "@store/Signal/actions/signal";

//Helpers
import { errorResponseToList } from "@helper/errorHelper";
import { PERMISSIONS } from "@helper/permissionConstants";
import { filterData, getFilterValue } from "@helper/tableConstants";
import { useOrgDateFormat } from "@helper/hooks/useOrgDateFormat";
import { formatNumberHelper } from "@helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "@helper/hooks/useGroupDecimalSeparator";
import { permissionHelper } from "@helper/permissionHelper";
import api, { API, formUrl } from "@helper/api/api";
import { useQueryTableFilters } from "@helper/hooks/useQueryTableFilters";
import { filtersToQueryString } from "@helper/tableConstants";
import { useOrganizationId } from "@helper/hooks/useOrganizationId";
import SignalRContext from "@helper/SignalRContext";
import { SIGNALR_CONSTANTS } from "@helper/signalrConstants";
import { useQuery } from "@helper/hooks/useQuery";

//Styles
import styles from "./EmailAccount.module.scss";

//Icons & Images
import EmptyCampaignList from "@assets/Images/NoResultsFound/NoResultsFound";

//Components
import { columns } from "./columns";
import Table from "@components/DataTable/Table";
import Button from "@components/UI/Button/Button";
import ActionConversion from "@components/UI/ActionWrapper/Actions/ActionConversion";
import TableFiltersRow from "@components/DataTable/TableFiltersRow/TableFiltersRow";
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import ActionWrapper from "@components/UI/ActionWrapper/ActionWrapper";
import ActionEdit from "@components/UI/ActionWrapper/Actions/ActionEdit";
import ActionDelete from "@components/UI/ActionWrapper/Actions/ActionDelete";
import EmptyState from "@components/UI/EmptyState/EmptyState";
import SkeletonTable from "@components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import DeleteEmailAccountForm from "./DeleteEmailAccountForm/DeleteEmailAccountForm";
import AddEmailAccountModal from "./AddEmailAccountModal/AddEmailAccountModal";
import InfoIconTooltip from "@components/UI/CustomTooltip/InfoIconTooltip";
import Permission from "@components/auth/Permission";
import SendTestEmailForm from "./EmailAccountSettings/SendTestEmailForm";
import ConnectedTestEmailForm from "./EmailAccountSettings/ConnectedTestEmailForm";
import EmailExistsForm from "./EmailExistsForm";
import MandatoryAccessNotGranted from "./MandatoryAccessNotGranted";
import { EmailAccountCircle } from "@components/EmailAccountCircle/EmailAccountCircle";
import PageContentWrapper from "@components/PageContentWrapper/PageContentWrapper";
import AddEmailAccount from "@components/AddEmailAccount/AddEmailAccount";
import useModal from "@components/UI/Modal/useModal";
import GenericErrorModal from "./GenericErrorModal";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";

const EmailAccounts = (props) => {
  const organizationId = useOrganizationId();
  let dateFormat = useOrgDateFormat();
  let separators = useGroupDecimalSeparator();

  const {
    param,
    fetchEmailAccounts,
    fetchListUsers,
    rows,
    initiate,
    isInitiated,
    clearInitiated,
    activateEmailAccountTest,
    emailAccountSettings,
    basicTestDetails,
    fetchBasicEmailSendTest,
    fetchBasicEmailTestDetails,
    errorSendTest,
    clearInitiatedTest,
    isActivateEmailAccountTest,
  } = props;

  let image = <EmptyCampaignList className={styles.imageNoResults} />;
  const signalRConnection = useContext(SignalRContext);
  const { status } = basicTestDetails;
  const [tableColumns] = useState([...columns]);
  const [tableRows, setTableRows] = useState([]);

  const icon = (
    <InfoIconTooltip
      tooltipBody="Add and manage the email accounts used to send your prospecting emails."
      helpText="Need help connecting your email account?"
      href="https://help.outbase.com/user-guide/email-accounts/email-account-manager"
      linkText="Read More"
      buttonId="startEmailAccountTour"
      to={"/" + organizationId + "/email-accounts"}
    />
  );

  const [hasEmailExists, setHasEmailExists] = useState(false);
  const [showEmailExistsModal, setShowEmailExistsModal] = useState(false);
  const [mandatoryAccessModalShow, setMandatoryAccessModalShow] = useState(false);
  let query = useQuery();
  const genericErrorModal = useModal();

  let filterTypes = ["User"];
  var state = useQueryTableFilters(filterTypes);

  useEffect(() => {
    initiate(state);

    return () => {
      clearInitiated();
      activateEmailAccountTest(false);
    };
  }, []);

  useEffect(() => {
    if (isActivateEmailAccountTest) {
      setConnectedTest("Started");
      if (signalRConnection != null) {
        signalRConnection.on(SIGNALR_CONSTANTS.CONNECTION_TEST_INFO, (data) => {
          signalReceived(SIGNALR_CONSTANTS.CONNECTION_TEST_INFO, data);
          if (data?.success) {
            let campaignId = query.get("campaignId");
            let emailAccountId = query.get("emailAccountAdded");
            // if campaignId is present then redirect to campaign overview page
            if (campaignId) {
              // if emailAccountId is present then redirect to campaign overview page with emailAccountAdded query param
              // to select the email account in dropdown
              if (emailAccountId) {
                props.history.push(`/${organizationId}/campaigns/${campaignId}/overview?emailAccountAdded=${emailAccountId}`);
              } else {
                props.history.push(`/${organizationId}/campaigns/${campaignId}/overview`);
              }
            }
            setConnectedTest("Successful");
            setTestEmailAccountId(data?.emailAccountId);
            handleConfigurationTest(data?.emailAccountId);
          } else {
            if (data?.emailAccountId) {
              setTestEmailAccountId(data?.emailAccountId);
              setConnectedTest("Failed");
            }
          }
        });
      }
      setShowTestEmailFormModal(true);
    }
    return () => {
      if (signalRConnection != null) {
        signalRConnection.off(SIGNALR_CONSTANTS.CONNECTION_TEST_INFO);
      }
    };
  }, [emailAccountSettings]);

  useEffect(() => {
    if (isInitiated) {
      fetchEmailAccounts(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param, null, query);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
        if (query.get("exists") != null) {
          if (!hasEmailExists) {
            setHasEmailExists(true);
            setShowEmailExistsModal(true);
          }
        } else if (query.get("accessDenied") != null) {
          setMandatoryAccessModalShow(true);
          if (query.get("type") === "Gmail") {
            setTestEmailAccountType("Gmail");
          } else if (query.get("type") === "Microsoft") {
            setTestEmailAccountType("Microsoft");
          }
        } else if (query.get("error") != null) {
          genericErrorModal.show();
        }
      }
    }
  }, [isInitiated, param]);

  const handleCancelEmailExist = () => {
    setShowEmailExistsModal(false);
  };

  const handleCancelMandatoryAccess = () => {
    setMandatoryAccessModalShow(false);
  };

  const handleContinueMandatoryAccess = () => {
    handleAddEmailAccountContinue(testEmailAccountType);
  };

  useEffect(() => {
    if (permissionHelper(props.userRole, PERMISSIONS.emailAccounts.assignToUser)) fetchListUsers();
  }, [fetchListUsers, props.userRole]);

  useEffect(() => {
    if (Object.keys(basicTestDetails).length !== 0) {
      let dmarc = basicTestDetails?.responseModel?.signature?.Subtests?.dmarc?.status === "pass";
      let dkimResult = basicTestDetails?.responseModel?.signature?.Subtests?.dkim?.status === "pass";
      let spfResult = basicTestDetails?.responseModel?.signature?.Subtests?.spf?.status === "pass";
      if (errorSendTest === "") {
        if (dmarc && dkimResult && spfResult) setConfigurationTest("Successful");
        else if (status === 0 || status === 400) setConfigurationTest("Started");
        else setConfigurationTest("Failed");
      }
    }
  }, [basicTestDetails]);

  useEffect(() => {
    if (connectedTest === "Failed") {
      setConfigurationTest("Failed");
    } else {
      if (errorSendTest === "") {
        if (status === 400 || status === 0) {
          const interval = setInterval(() => {
            fetchBasicEmailTestDetails(emailAccountSettings.id);
          }, 10000);
          return () => clearInterval(interval);
        }
      }
    }
  }, [status]);

  useEffect(() => {
    if (errorSendTest !== "") {
      setConfigurationTest("MaxLimitReached");
    }
  }, [errorSendTest]);

  const handleConfigurationTest = (id) => {
    setConfigurationTest("Started");
    fetchBasicEmailSendTest(id);
    fetchBasicEmailTestDetails(id);
  };

  const [emailAccountDelete, setEmailAccountDelete] = useState(null);
  const [showDeleteEmailAccountModal, setShowDeleteEmailAccountModal] = useState(false);
  const [showAddEmailAccountModal, setShowAddEmailAccountModal] = useState(false);

  const handleDeleteEmailAccountClose = () => setShowDeleteEmailAccountModal(false);
  const handleDeleteEmailAccountShow = (emailAccount) => {
    setEmailAccountDelete(emailAccount);
    setShowDeleteEmailAccountModal(true);
  };

  let table = null;
  const handleAddEmailAccountClose = () => setShowAddEmailAccountModal(false);

  const [sendStatus, setSendStatus] = useState("Not started");
  const [receiveStatus, setReceiveStatus] = useState("Not started");
  const [connectedTest, setConnectedTest] = useState("Not started");
  const [configurationTest, setConfigurationTest] = useState("Not started");
  const [showTestEmailFormModal, setShowTestEmailFormModal] = useState(false);
  const [showConnectedTestEmailFormModal, setShowConnectedTestEmailFormModal] = useState(false);
  const [testEmailAccountId, setTestEmailAccountId] = useState(0);
  const [testEmailAccountType, setTestEmailAccountType] = useState("");
  const handleTestMailFormClose = () => {
    clearInitiatedTest();
    setShowTestEmailFormModal(false);
    setConnectedTest("Not started");
    setConfigurationTest("Not started");
    fetchEmailAccounts(param);
  };

  const handleConnectedTestEmailFormClose = () => {
    setSendStatus("");
    setReceiveStatus("");
    setShowConnectedTestEmailFormModal(false);
  };

  const handleAddEmailAccountContinue = (typeId) => {
    if (typeId === "SmtpImap") {
      props.history.push("/" + props.orgId + "/email-accounts/" + typeId + "/create");
    } else {
      api(API.emailAccounts.createThirdParty, {
        messagesPerDay: 500,
        averageDelay: 180,
        emailAccountType: typeId,
      })
        .then((response) => {
          if (response.data && response.data.success) {
            if (response.data.redirectUrl != null) {
              window.location = response.data.redirectUrl;
            }
          } else {
            toast.error(response.data.errorMessage);
          }
        })
        .catch((error) => {
          const errorList = errorResponseToList(error);
          if (errorList.length > 0) {
            errorList.forEach((err) => toast.error(err.message));
          } else {
            toast.error(error.message);
          }
        });
    }
  };

  const changeUrl = () => {
    setShowAddEmailAccountModal(true);
  };

  const editAccount = (accountId) => {
    props.history.push("/" + props.orgId + "/email-accounts/" + accountId + "/edit");
  };

  const sendTest = (emailAccountId, type) => {
    setTestEmailAccountId(emailAccountId);
    setTestEmailAccountType(type);
    setShowConnectedTestEmailFormModal(true);

    setSendStatus("Started");
    setReceiveStatus("Started");

    api(
      formUrl(API.emailAccounts.testEmailAccount, {
        emailAccountId: emailAccountId,
      })
    )
      .then((response) => {
        if (response.data.sendTestSuccess) setSendStatus("Successful");
        else setSendStatus("Failed");

        if (response.data.receiveTestSuccess) setReceiveStatus("Successful");
        else setReceiveStatus("Failed");

        fetchEmailAccounts(param);
      })
      .catch((err) => {
        setReceiveStatus("Failed");
        setSendStatus("Failed");
        fetchEmailAccounts(param);
      });
  };

  const handleDelete = () => {
    setShowDeleteEmailAccountModal(false);
  };

  if (props.error) {
    table = <h2>Error getting Email Accounts</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r) => {
      let extraLabel = <></>;
      let hoverContent = <>{r.emailAccount}</>;
      if (r.isSuspended) {
        hoverContent = "Your account has been suspended by Outbase. Please contact customer support.";
        extraLabel = (
          <Badge pill bg="danger" className="mL10 f12">
            Suspended
          </Badge>
        );
      }

      let emailAccount = (
        <>
          <OverlayTrigger
            key={"key"}
            placement={"bottom"}
            overlay={
              <Popover id={`popover-tooltip`}>
                <Popover.Body>{hoverContent}</Popover.Body>
              </Popover>
            }
          >
            <div className="flex min-x-content">
              <Link
                className="tableLink"
                to={{
                  pathname: "/" + props.orgId + "/email-accounts/" + r.id + "/edit",
                }}
              >
                {r.emailAccount}
              </Link>
              {extraLabel}
            </div>
          </OverlayTrigger>
        </>
      );
      r.emailAccount = emailAccount;
      let status = <EmailAccountCircle isConnected={r.isConnected} isSuspended={r.isSuspended} />;

      r.status = status;

      let emailsDelivered = formatNumberHelper(r.emailsDelivered, separators.numberGroupSeperator, separators.numberDecimalSeperator);

      r.emailsDelivered = emailsDelivered;

      let actions = (
        <ActionWrapper>
          {!r.isSuspended && <ActionConversion onClick={() => sendTest(r.id, r.type)} />}
          <Permission has={PERMISSIONS.emailAccounts.edit}>
            <ActionEdit onClick={() => editAccount(r.id)} />
          </Permission>
          <Permission has={PERMISSIONS.emailAccounts.delete}>
            {/* Uncomment the following code after the Google verification is finished to restore the functionality */}
            {/* {r.isInCampaignUse ?
            (
              <OverlayTrigger
                key={'delete-account'}
                placement={"bottom"}
                overlay={
                  <Popover id={`tooltip-delete-account`}>
                    <Popover.Body>
                      This email account cannot be deleted because it is already in use.
                    </Popover.Body>
                  </Popover>
                }
              >
                <div>
                  <ActionDelete disabled={true} />
                </div>
              </OverlayTrigger>
            ) :
            (
              <ActionDelete onClick={() => handleDeleteEmailAccountShow(r)} />
            )
          } */}
            {/* ------------------------------------------------------------- */}
            {/* Delete the following code after the Google verification is finished */}
            <ActionDelete onClick={() => handleDeleteEmailAccountShow(r)} />
          </Permission>
        </ActionWrapper>
      );
      r.actions = actions;

      if (r.lastDateUsed != null) {
        r.lastDateUsed = <span>{Moment(r.lastDateUsed).format(dateFormat)}</span>;
      } else {
        r.lastDateUsed = "-";
      }

      r.createdDate = Moment(r.createdDate).format(dateFormat);
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  let buttons = null;

  if (props.isLoaded) {
    if (tableRows.length > 0) {
      buttons = (
        <Permission has={PERMISSIONS.emailAccounts.create}>
          <Button id="addEmailAccountButton" variant="primary" children={"Connect email account"} onClick={changeUrl} />
        </Permission>
      );
    }

    const isSearching = param.search !== "" || Object.keys(param.filter).length > 0;

    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"EmailAccounts"}
          columns={tableColumns}
          rows={tableRows}
          param={param}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          scrollY={true}
          tableHeight={220}
          variant="small"
        />
      ) : isSearching ? (
        <EmptyState image={image} title="No results found" />
      ) : (
        <div className={styles.emptyState}>
          <EmptyState
            contentWrapperClass={styles.emptyStateWrapper}
            title="Connect your sender account"
            content={
              <>
                Add an email account from where your prospecting emails will be sent.
                <br /> To do this simply choose one of the options below to sign in to your account.
              </>
            }
          />
          <AddEmailAccount handleSave={handleAddEmailAccountContinue} />
        </div>
      );
  }

  const selectRowOptions = [
    {
      option: props.listUsers,
      change: (c) => filterData("User", c, props.setFilter),
      placeholder: "User",
      value: getFilterValue(param.filter, "User"),
    },
  ];

  return (
    <PageContentWrapper className="contentHeight">
      <AddEmailAccountModal
        showModal={showAddEmailAccountModal}
        handleClose={handleAddEmailAccountClose}
        handleSave={handleAddEmailAccountContinue}
      />
      <HeaderTitle title="Email Accounts" icon={icon} rightContent={buttons} />
      <Permission has={PERMISSIONS.organization.view}>
        <Permission has={PERMISSIONS.emailAccounts.assignToUser}>
          <TableFiltersRow
            selects={selectRowOptions}
            placeholder="email accounts"
            changeFilter={(text) => props.searchRecords(text)}
            searchValue={param.search}
          />
        </Permission>
      </Permission>

      {table}

      {emailAccountDelete == null ? null : (
        <DeleteEmailAccountForm
          handleClose={handleDeleteEmailAccountClose}
          showModal={showDeleteEmailAccountModal}
          emailAccount={emailAccountDelete}
          handleClick={handleDelete}
          getEmailAccounts={fetchEmailAccounts}
        />
      )}
      <SendTestEmailForm
        handleClose={handleTestMailFormClose}
        showModal={showTestEmailFormModal}
        connectedTest={connectedTest}
        configurationTest={configurationTest}
        emailAccountId={testEmailAccountId}
        emailAccountType={testEmailAccountType}
      />
      <ConnectedTestEmailForm
        handleClose={handleConnectedTestEmailFormClose}
        showModal={showConnectedTestEmailFormModal}
        sendStatus={sendStatus}
        receiveStatus={receiveStatus}
        emailAccountId={testEmailAccountId}
        emailAccountType={testEmailAccountType}
      />
      <EmailExistsForm showModal={showEmailExistsModal} handleCancel={handleCancelEmailExist} handleSave={handleCancelEmailExist} />
      <MandatoryAccessNotGranted
        showModal={mandatoryAccessModalShow}
        handleCancel={handleCancelMandatoryAccess}
        handleSave={handleContinueMandatoryAccess}
      ></MandatoryAccessNotGranted>
      <GenericErrorModal handleClose={genericErrorModal.hide} showModal={genericErrorModal.isVisible} />
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
    organization: state.user.organization,
    isInitiated: state.emailAccounts.isInitiated,
    param: state.emailAccounts.params,
    isLoading: state.emailAccounts.isLoading,
    isLoaded: state.emailAccounts.isLoaded,
    error: state.emailAccounts.error,
    rows: state.emailAccounts.rows,
    totalCount: state.emailAccounts.totalCount,
    pageCount: state.emailAccounts.pageCount,
    listUsers: state.organizationSettingsUsers.listusers,
    userRole: state.user.organization.role,
    userOrganizationId: state.user.organization.userOrganizationId,
    isActivateEmailAccountTest: state.emailAccountSettings.isActivateEmailAccountTest,
    emailAccountSettings: state.emailAccountSettings.emailAccount,
    basicTestDetails: state.basicEmailDeliverabilityTest.basicTestDetails,
    errorSendTest: state.basicEmailDeliverabilityTest.errorSendTest,
    emailId: state.emailAccountSettings.emailId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListUsers: () => dispatch(fetchListUsers()),
    fetchEmailAccounts: (param) => dispatch(actions.fetchEmailAccounts(param)),
    changePage: (page) => dispatch(actions.changePage(page)),
    sortData: (obj) => dispatch(actions.sortData(obj)),
    numRecords: (obj) => dispatch(actions.numRecords(obj)),
    searchRecords: (value) => dispatch(actions.searchRecords(value)),
    setFilter: (type, value) => dispatch(actions.setFilter(type, value)),
    clearFilter: (type) => dispatch(actions.clearFilter(type)),
    initiate: (state) => dispatch(actions.initiate(state)),
    clearInitiated: () => dispatch(actions.clearInitiated()),
    fetchBasicEmailSendTest: (emailAccountId) => dispatch(fetchBasicEmailSendTest(emailAccountId)),
    fetchBasicEmailTestDetails: (emailAccountId) => dispatch(fetchBasicEmailTestDetails(emailAccountId)),
    clearInitiatedTest: () => dispatch(clearInitiatedTest()),
    activateEmailAccountTest: (val) => dispatch(activateEmailAccountTest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAccounts);
