import React from "react";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./NotFound404.module.scss";

//Components
import Button from "../../components/UI/Button/Button";

const NotFound404 = () => {
  return (
    <div className={styles.wrap}>
      <h1>404</h1>
      <h2>Page not found</h2>

      <NavLink to="/dashboard">
        <Button id="dashboardBtn" variant="primary">
          Dashboard
        </Button>
      </NavLink>
    </div>
  );
};

export default NotFound404;
