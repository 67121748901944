import { DTFilter } from "../../models/dataTables/DtFilter";
import { filterData, getFilterValue } from "../tableConstants";

interface UseFilterParams {
  name: string;
  placeholder?: string;
  data: any[] | undefined;
  filters: DTFilter[];
  setFilter: (type: string, value: any[]) => void;
}

export const useFilter = ({ name, data, filters, setFilter, placeholder = name }: UseFilterParams) => {
  return {
    option: data,
    change: (newValue: any) => filterData(name, newValue, setFilter),
    placeholder: placeholder,
    value: getFilterValue(filters, name),
  };
};
