export const columns = [
  {
    label: "",
    field: "checkbox",
    sort: "disabled",
    minimal: "sm",
    width: 50,
    minWidth: 50,
  },
  {
    label: "Name",
    field: "fullName",
    type: "text",
    sort: "asc",
    minimal: "lg",
    showTooltip: true,
    flex: 1,
    width: 120,
    minWidth: 120,
  },
  {
    label: "Job title",
    field: "jobTitle",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
    showTooltip: true,
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    class: "flex",
    sort: "asc",
    minimal: "lg",
    width: 180,
    minWidth: 180,
  },
  {
    label: "Campaign",
    field: "campaignName",
    type: "text",
    class: "flex",
    sort: "asc",
    minimal: "lg",
    showTooltip: true,
    flex: 1,
    width: 120,
    minWidth: 120,
  },
  {
    label: "Email",
    field: "emailAddress",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 220,
    minWidth: 220,
    showTooltip: true,
  },
  {
    label: "Company",
    field: "cleanCompanyName",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
    showTooltip: true,
  },
  {
    label: "Full Company",
    field: "company",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
    showTooltip: true,
    isHidden: true,
  },
  {
    label: "Domain",
    field: "companyWebsite",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 200,
    minWidth: 200,
    showTooltip: true,
  },
  {
    label: "Industry",
    field: "companyIndustry",
    type: "text",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 180,
    minWidth: 180,
    showTooltip: true,
  },
  {
    label: "Clean Industry",
    field: "cleanCompanyIndustry",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
    isHidden: true,
  },
  {
    label: "Company Size",
    field: "companySize",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 150,
    minWidth: 150,
  },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    minimal: "lg",
    width: 150,
    minWidth: 150,
  },
  {
    label: "Clean Company Size",
    field: "cleanCompanySize",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
    isHidden: true,
  },
  {
    label: "Contact Country",
    field: "contactCountry",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 150,
    minWidth: 150,
    showTooltip: true,
  },
];
