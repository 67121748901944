import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

//Styles
import styles from "./Button.module.scss";

//Components
import Spinner from "../Spinner/Spinner";

interface ButtonProps {
  isLoading?: boolean;
  loadingText?: string;
  loadingVariant?: string;
  variant?: string;
  type?: "button" | "submit" | "reset";
  classes?: string;
  id: string;
  disabled?: boolean;
  name?: string;
  title?: string;
  isLink?: boolean;
  isLinkWithClick?: boolean;
  link?: any;
  ariaLabel?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children?: ReactNode;
  value?: any;
  isDiv?: boolean;
}

const button: React.FC<ButtonProps> = (props) => {
  let content = props.children;

  if (props.isLoading && props.loadingText) {
    content = props.loadingText;
  }

  let classes = styles.ButtonStyle;

  if (props.isLoading) {
    if (props.loadingVariant != null) {
      classes += " " + styles[props.loadingVariant];
    } else {
      classes += " " + styles.disabled;
    }
  } else {
    if (props.variant != null) {
      classes += " " + styles[props.variant];
    } else {
      classes += " " + styles.primary;
    }
  }

  if (props.classes != null) {
    classes += " " + props.classes;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (props.disabled) {
      return;
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };

  if (props.isLink) {
    if(props?.link?.startsWith("http")) {
      return <a className={classes} href={props.link} target="_blank" rel="noopener noreferrer" onClick={(e)=>{if(props.isLinkWithClick) handleClick(e)}}>
      {props.children}
    </a>
    }
    else{
      return (
        <NavLink to={props.link} className={classes} id={props.id || undefined} type={props.type} onClick={(e)=>{if(props.isLinkWithClick) handleClick(e)}}>
          {props.children}
        </NavLink>
      );
    }
  } else if (props.isDiv) {
    return (
      <div
        onClick={handleClick}
        className={`${classes} ${props.disabled ? "cursor_not_allowed" : ""}`}
        id={props.id || (props.name && props.name.replace(/\s/g, ""))}
        title={props.title || ""}
      >
        {content}
      </div>
    );
  } else {
    return (
      <button
        name={props.name}
        value={props.value}
        onClick={props.onClick}
        className={classes}
        disabled={props.disabled || props.isLoading}
        type={props.type}
        id={props.id || (props.name && props.name.replace(/\s/g, ""))}
        title={props.title || ""}
        aria-label={props.ariaLabel}
      >
        {props.isLoading ? (
          <>
            <Spinner size="0.75rem" /> {content}
          </>
        ) : (
          content
        )}
      </button>
    );
  }
};

export default button;
