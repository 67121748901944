import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

//Redux
import {
  fetchEmailTemplate,
  fetchOrganizationCustomColumns,
  setDictionaryValues,
} from "../../../store/EmailTemplates/Global/actions/emailTemplates";
import { fetchOrganizationEmailAccountsList } from "../../../store/EmailAccounts/Global/actions/emailAccounts";

//Styles
import styles from "../EditEmailTemplates/EditEmailTemplatesTabs.module.scss";

//Helpers
import { actionPageTypeEnum } from "../../../helper/enums/actionPageTypes";

//Components
import AdvanceEmailDeliverabilityTest from "../AdvanceEmailDeliverabilityTest/AdvanceEmailDeliverabilityTest";
import Button from "../../../components/UI/Button/Button";
import CreateUpdateEmailTemplate from "../CreateUpdateEmailTemplate";
import BreadcrumbElement from "../../../components/UI/BreadcrumbElement/BreadcrumbElement";
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import Tabs from "../../../components/UI/Tabs/Tabs";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { emailTemplateStatusEnum } from "../../../helper/enums/emailTemplateStatusEnum";
import PageContentWrapper from "../../../components/PageContentWrapper/PageContentWrapper";
import useModal from "../../../components/UI/Modal/useModal";

//Hooks
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const EditEmailTemplatesTabs = (props) => {
  const newConfigurationTestModal = useModal();
  
  const [showTestEmailFormModal, setShowTestEmailFormModal] = useState(false);
  const handleTestMailFormClose = () => setShowTestEmailFormModal(false);

  const history = useHistory();

  let isOnCreate = props.match.url.includes("create") || props.match.url.includes("campaign-sequence");
  let isOnEditFromCampaignSequence =
    props.match.url.includes("campaign-sequence") &&
    props.typeActivePage !== undefined &&
    props.typeActivePage === actionPageTypeEnum.editTemplate;
  let templateId = 0;
  if (!_.isEmpty(props.match.params.templateId) && !isOnCreate) {
    templateId = props.match.params.templateId;
  } else if (isOnEditFromCampaignSequence) {
    templateId = props.templateId;
  }

  const updateTemplateStates = (values) => {
    let plainString = values.emailTemplateHtml.replace(/<[^>]+>/g, "");

    let emailTemplateTemp = {
      emailTemplateHtml: values.emailTemplateHtml,
      emailTemplateText: plainString,
      emailTemplateSubject: values.emailTemplateSubject,
      emailTemplateName: values.emailTemplateName,
      emailTemplateType: values.emailTemplateType,
      emailTemplateStatus: values.emailTemplateStatus,
      chaserIncludeThread: values.chaserIncludeThread,
      chaserAddSignature: values.chaserAddSignature,
      languageMarketCode: values.languageMarketCode,
    };
    setEmailTemplate(emailTemplateTemp);
  };

  const adjustEmailTemplate = (emailTemplate) => {
    if (emailTemplate !== null && emailTemplate.id > 0) {
      return {
        emailTemplateHtml: emailTemplate.html ?? "",
        emailTemplateText: emailTemplate.text ?? "",
        emailTemplateSubject: emailTemplate.subject ?? "",
        emailTemplateName: emailTemplate.name ?? "",
        emailTemplateType: emailTemplate.type ?? 1,
        emailTemplateStatus: emailTemplate.status ?? emailTemplateStatusEnum.Draft,
        chaserIncludeThread: emailTemplate.chaserIncludeThread,
        chaserAddSignature: emailTemplate.chaserAddSignature,
        languageMarketCode: emailTemplate.languageMarketCode ?? "",
      };
    } else {
      return {
        emailTemplateHtml: "",
        emailTemplateText: "",
        emailTemplateSubject: "",
        emailTemplateName: "",
        emailTemplateType: 1,
        emailTemplateStatus: emailTemplateStatusEnum.Draft,
        chaserAddSignature: false,
        chaserIncludeThread: props.match.url.includes("campaign-sequence") ? undefined : false,
        languageMarketCode: "",
      };
    }
  };

  const [emailTemplate, setEmailTemplate] = useState(null);
  useEffect(() => {
    props.fetchOrganizationEmailAccountsList();
    props.fetchEmailTemplate(templateId);
    props.fetchOrganizationCustomColumns();
  }, [templateId]);

  const handleEmailRequestModalShow = () => {
    history.push("/" + props.orgId + "/purchase-template");
  };

  let tabs = [];
  let config = null;
  let breadcrumbs = [
    {
      href: "/" + props.orgId + "/email-templates",
      text: "Email Templates",
    },
    { active: true, text: isOnCreate ? "Create" : "Edit" },
  ];

  if (props.isLoaded && props.emailVariablesLoaded) {
    tabs.push(
      {
        link: "edit",
        component: (
          <CreateUpdateEmailTemplate
            showConfigurationTestModal={newConfigurationTestModal.isVisible}
            handleConfigurationTestModalClose={newConfigurationTestModal.hide}
            templateId={templateId}
            templateIdFromSequence={props.templateId}
            templateName={props.emailTemplateName}
            templateSubject={props.emailTemplateSubject}
            emailTemplate={emailTemplate !== null ? emailTemplate : adjustEmailTemplate(props.emailTemplate)}
            showTestEmailFormModal={showTestEmailFormModal}
            setShowTestEmailFormModal={() => setShowTestEmailFormModal(true)}
            handleTestMailFormClose={handleTestMailFormClose}
            fromCampaignSequence={props.fromCampaignSequence}
            organizationCustomColumns={props.organizationCustomColumns}
            templateDictionary={props.templateDictionary}
            setDictionaryValues={props.setDictionaryValues}
            updateTemplateStates={updateTemplateStates}
            typeActiveStep={props.typeActiveStep}
            handleHideSequenceFlow={props.handleHideSequenceFlow}
            typeActivePage={props.typeActivePage}
          />
        ),
        title: "Email Template",
      },
      {
        link: "configuration-tests",
        component: (
          <AdvanceEmailDeliverabilityTest
            showConfigurationTestModal={newConfigurationTestModal.isVisible}
            handleConfigurationTestModalClose={newConfigurationTestModal.hide}
            templateId={templateId}
            emailTemplate={emailTemplate !== null ? emailTemplate : adjustEmailTemplate(props.emailTemplate)}
            showTestEmailFormModal={showTestEmailFormModal}
            handleTestMailFormClose={handleTestMailFormClose}
            templateDictionary={props.templateDictionary}
            setDictionaryValues={props.setDictionaryValues}
          />
        ),
        title: "Deliverability Test Reports",
      }
    );
  }

  if (props.error) {
    config = <h2>Error getting Email Template</h2>;
  }

  if (props.isLoading && !props.emailVariablesLoaded) {
    config = <Spinner spinnerClass="absolute transform-x-y-50" />;
  }

  if (props.isLoaded && props.emailVariablesLoaded) {
    config = (
      <>
        {isOnCreate ? (
          <CreateUpdateEmailTemplate
            {...props}
            templateId={templateId}
            templateIdFromSequence={props.templateId}
            emailTemplate={
              emailTemplate !== null
                ? emailTemplate
                : adjustEmailTemplate(props.emailTemplate?.chaserIncludeThread !== undefined ? props.emailTemplate : null)
            }
            showTestEmailFormModal={showTestEmailFormModal}
            setShowTestEmailFormModal={() => setShowTestEmailFormModal(true)}
            handleTestMailFormClose={handleTestMailFormClose}
            organizationCustomColumns={props.organizationCustomColumns}
            templateDictionary={props.templateDictionary}
            setDictionaryValues={props.setDictionaryValues}
            updateTemplateStates={updateTemplateStates}
            fromCampaignSequence={props.fromCampaignSequence}
            typeActiveStep={props.typeActiveStep}
            isOnCreate={isOnCreate}
            handleHideSequenceFlow={props.handleHideSequenceFlow}
            typeActivePage={props.typeActivePage}
          />
        ) : (
          <Tabs tabContentClass={`${styles.editTemplatesTabs} sequenceTabs`} {...props} tabs={tabs} />
        )}
      </>
    );
  }

  return (
    <PageContentWrapper>
      {props.fromCampaignSequence ? null : (
        <div className={styles.breadcrumbDiv}>
          <BreadcrumbElement items={breadcrumbs} />
          {!isOnCreate && (
            <Button
              id="configTest"
              variant="secondary"
              children="Configuration test"
              onClick={newConfigurationTestModal.show}
              type="button"
              classes={styles.headerButton}
            >
              Deliverability test
            </Button>
          )}
        </div>
      )}

      {config}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.globalEmailTemplate.isLoading,
    isLoaded: state.globalEmailTemplate.isLoaded,
    error: state.globalEmailTemplate.error,
    emailTemplate: state.globalEmailTemplate.emailTemplate,
    emailTemplateName: state.globalEmailTemplate.emailTemplate != null ? state.globalEmailTemplate.emailTemplate.name : "",
    emailTemplateSubject: state.globalEmailTemplate.emailTemplate != null ? state.globalEmailTemplate.emailTemplate.subject : "",
    emailTemplateHtml: state.globalEmailTemplate.emailTemplate != null ? state.globalEmailTemplate.emailTemplate.html : "",
    emailTemplateText: state.globalEmailTemplate.emailTemplate != null ? state.globalEmailTemplate.emailTemplate.text : "",
    emailTemplateType: state.globalEmailTemplate.emailTemplate != null ? state.globalEmailTemplate.emailTemplate.type : 0,
    emailTemplateStatus:
      state.globalEmailTemplate.emailTemplate != null ? state.globalEmailTemplate.emailTemplate.status : emailTemplateStatusEnum.Draft,
    orgId: state.user.organization.id,
    emailVariablesLoaded: state.globalEmailTemplate.isLoaded,
    organizationCustomColumns: state.globalEmailTemplate.organizationCustomColumns,
    templateDictionary: state.globalEmailTemplate.dictionary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizationEmailAccountsList: () => dispatch(fetchOrganizationEmailAccountsList()),
    fetchOrganizationCustomColumns: () => dispatch(fetchOrganizationCustomColumns()),
    setDictionaryValues: (dictionary) => dispatch(setDictionaryValues(dictionary)),
    fetchEmailTemplate: (data) => dispatch(fetchEmailTemplate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailTemplatesTabs);
