import React, { FC } from "react";
import Modal from "../../components/UI/Modal/Modal";
import { Link } from "react-router-dom";
import styles from "./Campaigns.module.scss";

interface NoEmailAccountConnectedModalProps {
  handleClose: () => void;
  handleSave: () => void;
  showModal: boolean;
  campaignName: string;
  organizationId: string;
  campaignId: string;
}

const NoEmailAccountConnectedModal: FC<NoEmailAccountConnectedModalProps> = (props) => {
  return (
    <Modal
      title={`Warning`}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="OK"
      btnType="submit"
    >
      <div>
        <p>
          Your {props.campaignName} campaign cannot be activated because there are no connected email accounts. Please click
          <Link className={styles.noEmailAccountModalLink} to={`/${props.organizationId}/campaigns/${props.campaignId}/sending-schedule`}>
            {" "}
            here{" "}
          </Link>
          to update and check your settings.
        </p>
      </div>
    </Modal>
  );
};

export default NoEmailAccountConnectedModal;
