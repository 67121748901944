import React, { useState } from "react";

//Components
import Select from "../../../../../components/UI/Select/Select";
import Modal from "../../../../../components/UI/Modal/Modal";

const CreateEmailAccountModal = (props) => {
  const [url, setUrl] = useState(null);
  const listEmailAccountTypes = [
    { value: "/SmtpImap/create", label: "SmptpImap" },
    { value: "/create", label: "Gmail" },
  ];

  const handleSave = () => {
    props.history.push("/" + props.orgId + "/email-accounts" + url);
  };

  return (
    <Modal
      dialogClassName="customModal"
      title={"Select email account type"}
      handleClose={props.handleClose}
      handleSave={handleSave}
      show={props.showModal}
      closeButtonText={"Cancel"}
      saveButtonText="Continue"
      btnType="submit"
      size="md"
    >
      <Select
        displayName="Select email account type"
        options={listEmailAccountTypes}
        onChange={(e) => setUrl(e)}
        isSearchable={false}
        isMenuPortalTargetDisabled={true}
        className="mB20"
      />
    </Modal>
  );
};

export default CreateEmailAccountModal;
