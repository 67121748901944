import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonDealNavigator = () => {
  return (
    <div className="skeleton-wrapper mT30">
      <div>
        <SkeletonElement type="stage" />
      </div>
      <div>
        <SkeletonElement type="stage" />
      </div>
      <div>
        <SkeletonElement type="stage" />
      </div>
      <div>
        <SkeletonElement type="stage" />
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonDealNavigator;
