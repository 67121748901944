import React from "react";

const IconStartProductTour = (props) => {
  return (
    <svg className={props.imgStyle} width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.33691 14.3438H12.6631C13.7616 14.3438 14.6553 13.4501 14.6553 12.3516V1.99219C14.6553 0.893695 13.7616 0 12.6631 0H2.33691C1.23842 0 0.344727 0.893695 0.344727 1.99219V15.0078C0.344727 16.1063 1.23842 17 2.33691 17H11.999H12.6631C13.7616 17 14.6553 16.1063 14.6553 15.0078V15.0062C14.1 15.4239 13.41 15.6719 12.6631 15.6719H11.999H2.33691C1.97075 15.6719 1.67285 15.374 1.67285 15.0078C1.67285 14.6416 1.97075 14.3438 2.33691 14.3438ZM1.67285 1.99219C1.67285 1.62602 1.97075 1.32812 2.33691 1.32812H12.6631C13.0293 1.32812 13.3271 1.62602 13.3271 1.99219V12.3516C13.3271 12.7177 13.0293 13.0156 12.6631 13.0156H2.33691C2.10419 13.0156 1.88064 13.0557 1.67285 13.1294V1.99219ZM6.3877 5.59738H5.05957C5.05957 4.91612 5.35156 4.26404 5.8607 3.80827C6.37651 3.34648 7.07038 3.12574 7.76456 3.20284C8.87663 3.32626 9.77212 4.21942 9.89385 5.32664C10.0048 6.33648 9.41263 7.06111 8.95752 7.51832C8.84038 7.63599 8.73227 7.73908 8.63688 7.83009C8.20294 8.24394 8.14766 8.31353 8.14766 8.59948V8.63281H6.81954V8.59944C6.81954 7.72796 7.23697 7.32989 7.7203 6.86893C7.8142 6.77938 7.91132 6.68678 8.01624 6.58136C8.44513 6.15048 8.61184 5.81865 8.57372 5.47174C8.51934 4.97735 8.11748 4.57828 7.6181 4.52286C7.29451 4.48707 6.98479 4.58455 6.74655 4.79785C6.51513 5.00497 6.3877 5.28893 6.3877 5.59738ZM6.81934 9.62891H8.14746V10.957H6.81934V9.62891Z"
        fill="#FE7904"
      />
    </svg>
  );
};

export default IconStartProductTour;
