import React, { useState, useEffect, FC } from "react";
import { Badge, Dropdown } from "react-bootstrap";

//Style
import styles from "./SelectMulti.module.scss";

//Images & Icons
import SearchLineIcon from "remixicon-react/SearchLineIcon";

//Components
// @ts-ignore
import Select from "./Select";

interface Props {
  value?: any;
  onChange?: (event: any) => void;
  displayName?: string;
  dropdownButtonClass?: string;
  dropdownClass?: string;
  menuBodyClass?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  options?: any[];
  menuId?: string;
  className?: string;
  placeholder?: string;
  isSearchable?: boolean;
  onInputChange?: () => void;
  onInputKeyDown?: () => void;
  allowCustomValues?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  hideNoOptions?: boolean;
  isAutoComplete?: boolean;
  isMulti?: boolean;
  allowIncludeExclude?: boolean;
  allowIncludeExcludeForCustomValues?: boolean;
  requireInputToShowOptions?: boolean;
  onChangeSelectedOption?: boolean;
  hideSelectedOptions?: boolean;
  customValues?: any[];
}

const SelectMulti: FC<Props> = (props) => {
  const { value: initialValue, customValues: initialCustomValues } = props;
  const [value, setValue] = useState(initialValue);
  const [customValues, setCustomValues] = useState(initialCustomValues || []);

  const [focusInput, setFocusInput] = useState(false);

  useEffect(() => {
    setValue(props.value);
    setCustomValues(customValues ? customValues : []);
  }, [props]);

  const changeHandler = (event: any) => {
    // Check if props.options is defined
    if (props.options) {
      // Filter out any options with value 0
      const filteredEvent = event.filter((item: any) => item !== 0); // Filter out value 0

      // If filteredEvent is not empty, set the value state to the filtered event
      if (filteredEvent.length > 0) {
        setValue(filteredEvent);

        if (props.onChange) {
          props.onChange(filteredEvent);
        }
      } else {
        // If filteredEvent is empty, set the value state to an empty array
        setValue([]);

        if (props.onChange) {
          props.onChange([]);
        }
      }
    }
  };

  let display = <>{props.displayName}</>;
  if (value != null && value.length > 0) {
    display = (
      <>
        <span className="flex2 textLeft">{props.displayName}</span>
        <Badge pill variant="warning" className="mL5 f12 badge_info">
          {value.length}
        </Badge>
      </>
    );
  }

  return (
    <>
      <Dropdown
        onClick={() => {
          setFocusInput(!focusInput);
        }}
        className={props.dropdownClass}
      >
        <Dropdown.Toggle
          className={`${styles.dropdownButton} ${props.dropdownButtonClass ? props.dropdownButtonClass : "dropdownButton"}`}
          id={props.id || undefined}
          disabled={props.disabled}
        >
          {display}
        </Dropdown.Toggle>

        <Dropdown.Menu className={`${styles.menuBody} ${props.menuBodyClass}`}>
          <Select
            inputId={"dropdownInput" + props.name?.replace(/[^a-zA-Z0-9]/g, "")}
            options={props.options}
            id={props.id || undefined}
            menuId={props.menuId ? props.menuId : ""}
            className={`${styles.selectMulti} ${props.className}`}
            onChange={changeHandler}
            value={value}
            name={props.name}
            placeholder={props.placeholder}
            displayName={props.displayName}
            isMulti={true}
            isSearchable={props.isSearchable}
            menuIsOpen={true}
            disabled={props.disabled}
            multiDropdown={true}
            onInputChange={props.onInputChange}
            onInputKeyDown={props.onInputKeyDown}
            allowCustomValues={props.allowCustomValues}
            focusInput={focusInput}
            onMenuClose={props.onMenuClose}
            onMenuOpen={props.onMenuOpen}
            hideNoOptions={props.hideNoOptions}
            isAutoComplete={props.isAutoComplete}
            allowIncludeExclude={props.allowIncludeExclude}
            requireInputToShowOptions={props.requireInputToShowOptions}
            allowIncludeExcludeForCustomValues={props.allowIncludeExcludeForCustomValues}
            onChangeSelectedOption={props.onChangeSelectedOption}
            hideSelectedOptions={props.hideSelectedOptions}
          />
          <SearchLineIcon size={14} className={`iconGray ${props.isMulti === true ? styles.icon : ""}`} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SelectMulti;
