import { useParams } from "react-router-dom";

interface Params {
  organizationId?: string;
}

export const useOrganizationId = (): number => {
  const { organizationId } = useParams<Params>();
  if (organizationId != null) {
    return parseInt(organizationId);
  }

  return 0;
};
