//Helpers
import { locationFilterTypeEnum } from "@helper/enums/audienceBuilderActiveLocationFilterEnum";
import { isIt } from "../../../helper/objectHelper";
import { NaicsAndSicCodesType, SearchModel } from "./SearchModel";

const getNestedValue: object = (obj: object, key: string) => {
  return key.split(".").reduce((o: object, p: string) => (o || {})[p], obj);
};

export const onAudienceExplorerFormChange = (filterValues: SearchModel, setFiltersCount: (value: number) => void) => {
  const filterKeys = [
    "company.names",
    "company.keywords",
    "company.sizes",
    "company.industries",
    "company.domains",
    "company.naicsAndSicCodes",
    "company.technologies",
    "company.revenue",
    "company.location",
    "company.companyHeadcountGrowth",
    "company.companyFunding",
    "company.companyFoundedYear",

    "contact.names",
    "contact.jobTitles",
    "contact.departments",
    "contact.seniorities",
    "contact.location",
  ];

  const { numberOfFilters } = filterKeys.reduce(
    (acc, key) => {
      const value = getNestedValue(filterValues, key);
      if (value && value != [] && value != "") {
        // Counting the unique filters added
        if (key == "company.location") {
          // Special case for locations, we only count as valid filter if locations are selected or
          if (Number(value.type) === locationFilterTypeEnum.Region) {
            if (value.region?.locations?.length > 0) {
              acc.numberOfFilters += 1;
            }
          } else if (Number(value.type) === locationFilterTypeEnum.Range) {
            if (value.range?.location && (value.range.rangeMiles > 0 || (value.range.rangeMiles == 0 && value.range.location.range > 0))) {
              acc.numberOfFilters += 1;
            }
          }
        } else if (key == "contact.location") {
          // Special case for locations, we only count as valid filter if locations are selected or
          if (Number(value.type) === locationFilterTypeEnum.Region) {
            if (value.region?.locations?.length > 0) {
              acc.numberOfFilters += 1;
            }
          } else if (Number(value.type) === locationFilterTypeEnum.Range) {
            if (value.range?.location && (value.range.rangeMiles > 0 || (value.range.rangeMiles == 0 && value.range.location.range > 0))) {
              acc.numberOfFilters += 1;
            }
          }
        } else if (key == "company.naicsAndSicCodes") {
          if (Number(value.type) === NaicsAndSicCodesType.Naics) {
            if (value.naicsCodes && value.naicsCodes.length > 0) {
              acc.numberOfFilters += 1;
            }
          } else if (Number(value.type) === NaicsAndSicCodesType.Sic) {
            if (value.sicCodes && value.sicCodes.length > 0) {
              acc.numberOfFilters += 1;
            }
          }
        } else if (key == "company.companyHeadcountGrowth") {
          if (Number(value.timeframe) > 0 || Number(value.min) < Number(value.max)) {
            acc.numberOfFilters += 1;
          }
        } else if (key == "company.companyFunding") {
          if (value.lastFundingRoundType?.length > 0 || Number(value.min) < Number(value.max) || (value.dateFrom && value.dateTo)) {
            acc.numberOfFilters += 1;
          }
        } else if (key == "company.companyFoundedYear") {
          if (value.dateFrom && value.dateTo) {
            acc.numberOfFilters += 1;
          }
        } else {
          acc.numberOfFilters += 1;
        }
      }
      return acc;
    },
    { numberOfFilters: 0 }
  );

  setFiltersCount(numberOfFilters);
};

export const createAudienceBuilderURL = (searchURL, organizationId) => {
  return "/" + organizationId + "/search?s=" + searchURL;
};
