export const columns = [
  {
    label: "Tracking Domain",
    field: "trackingDomainUrl",
    type: "text",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 150,
    minWidth: 150,
    showTooltip: true,
  },
  {
    label: "Domain",
    field: "domainUrl",
    type: "text",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 150,
    minWidth: 150,
    showTooltip: true,
  },
  {
    label: "Associated Accounts",
    field: "associatedEmailAccountsCount",
    type: "number",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Status",
    field: "isActive",
    type: "text",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  {
    label: "DNS Verified",
    field: "dnsVerified",
    type: "actions",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  // {
  //   label: "Opens",
  //   field: "trackOpens",
  //   sort: "asc",
  //   minimal: "lg",
  //   width: "90",
  // },
  // {
  //   label: "Clicks",
  //   field: "trackClicks",
  //   sort: "asc",
  //   minimal: "lg",
  //   width: "90",
  // },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    minimal: "sm",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    sort: "disabled",
    minimal: "sm",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
];
