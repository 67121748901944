import React, { useState, useEffect, ChangeEvent } from "react";

//Styles
import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  checked?: boolean;
  name?: string;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  onlyCheckbox?: boolean;
  checkBoxClass?: string;
  labelClass?: string;
  label?: string;
  id?: string;
  checkboxOnlyClass?: string;
  fromDataTable?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  name,
  onChange,
  onClick,
  onBlur,
  disabled,
  readOnly,
  onlyCheckbox,
  checkBoxClass,
  labelClass,
  label,
  id,
  checkboxOnlyClass,
  fromDataTable,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const formattedId = name ? name.replace(/\s/g, "_").replace(/[^a-zA-Z0-9-_]/g, "") : undefined;

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);

    if (onChange) {
      onChange(isChecked);
    }
  };

  return onlyCheckbox ? (
    <label
      className={`${styles.checkboxLabel} ${
        fromDataTable ? styles.checkboxOnlyLabelClassDT : styles.checkboxOnlyLabelClass
      }  ${checkBoxClass} `}
    >
      <input
        name={name}
        checked={isChecked}
        onChange={changeHandler}
        onClick={onClick}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
        type="checkbox"
        className={checkboxOnlyClass}
      />
      <span className={`${styles.checkmark} ${styles.checkboxOnlyCheckmarkClass} ${disabled ? " " + styles.disabled : ""}`}></span>
    </label>
  ) : (
    <div className={`${styles.checkboxWrapper} flex relative ${checkBoxClass}`}>
      <label id={id || formattedId} className={`${styles.checkboxLabel} ${labelClass}`}>
        {label}
        <input
          name={name}
          checked={isChecked}
          onChange={changeHandler}
          onClick={onClick}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={readOnly}
          type="checkbox"
          aria-label="checkbox"
        />
        <span id={typeof label === "string" ? label.replaceAll(" ", "") : undefined} className={styles.checkmark}></span>
      </label>
    </div>
  );
};

export default Checkbox;
