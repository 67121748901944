import React from "react";
import { Row, Col } from "react-bootstrap";

//Styles
import styles from "./ProgressBar.module.scss";

const ProgressBar = (props) => {
  let pageOneClass = null;
  let pageTwoClass = null;
  let pageThreeClass = null;
  let pageFourClass = null;

  switch (props.pageActive) {
    case 1:
      pageOneClass = `${styles.active} ${props.pageClass}`;
      break;
    case 2:
      pageTwoClass = `${styles.active} ${props.pageClass}`;
      break;
    case 3:
      pageThreeClass = `${styles.active} ${props.pageClass}`;
      break;
    case 4:
      pageFourClass = `${styles.active} ${props.pageClass}`;
      break;
    default:
      break;
  }

  return (
    <>
      <ul className={styles.progressbar}>
        <li className={pageOneClass} onClick={props.pageOneClicked}>
          <p className={styles.line}></p>
        </li>
        <li className={pageTwoClass} onClick={props.pageTwoClicked}>
          <p className={styles.line}></p>
        </li>
        <li className={pageThreeClass} onClick={props.pageThreeClicked}>
          <p className={styles.line}></p>
        </li>
        <li className={pageFourClass} onClick={props.pageFourClicked}></li>
      </ul>

      <Row className={styles.progressBarText}>
        <Col xl={3} className={pageOneClass}>
          <span>File upload</span>
        </Col>

        <Col xl={3} className={pageTwoClass}>
          <span>Map Fields</span>
        </Col>

        <Col xl={3} className={pageThreeClass}>
          <span>Custom Mapping</span>
        </Col>

        <Col xl={3} className={pageFourClass}>
          <span>Processing</span>
        </Col>
      </Row>
      {/* <ul className={styles.progressBarText}>
        <li
          className={pageOneClass}
        >
          <span>File upload</span>
        </li>
        <li
          className={pageTwoClass}
        >
          <span>Map Fields</span>
        </li>
        <li
          className={pageThreeClass}
        >
          <span>Custom Mapping</span>
        </li>
        <li
          className={pageFourClass}
        >
          <span>Processing</span>
        </li>
      </ul> */}
    </>
  );
};

export default ProgressBar;
