import React from "react";

//Helpers
import { initialWeight } from "../../../../../helper/emailAccountConstants";

//Styles
import styles from "./AccountRangeSlider.module.scss";

//Components
import RangeSlider from "../../../../../components/Rangeslider/RangeSlider";
import EmailAccount from "../../../Components/EmailAccountsWeighting/EmailAccount/EmailAccount";

const AccountRangeSlider = (props) => {
  const { showRangeSlider } = props;
  const handleWeightChange = (val) => {
    props.handleTotalChange(props.id, val);
  };

  return (
    <div className={styles.accountRangeSlider}>
      <div className={styles.selectedAgentSettings}>
        <EmailAccount
          imageUrl={props.imageUrl}
          emailName={props.emailName}
          email={props.email}
          userFullName={props.userFullName}
          messagesPerDay={props.messagesPerDay}
          activityWeight={props.activityWeight}
          usedInOtherCampaigns={props.usedInOtherCampaigns}
          removeEmailAccount={props.removeEmailAccount}
          cardClass={props.cardClass}
          isConnected={props.isConnected}
          hasSignature={props.hasSignature}
          orgId={props.orgId}
          emailAccountId={props.emailAccountId}
          isSuspended={props.isSuspended}
          suspensionReason={props.suspensionReason}
        />
      </div>
      {showRangeSlider ? (
        <div id={"rangeSlider" + props.index} className={styles.agentSlider}>
          <p className={styles.label}>Adjust new prospects per day</p>

          <div className={`${styles.perDay}`}>
            <span className={styles.rangeSlider}>
              <RangeSlider
                initialValue={props.activityWeight != null ? props.activityWeight : initialWeight}
                onChange={handleWeightChange}
              />
            </span>
            <span className={styles.messagesPerDay}> {!Number.isNaN(props.messagesPerDay) ? props.messagesPerDay : 0} per day</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AccountRangeSlider;
