import React from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

// Style
import styles from "./ImportProgressBar.module.scss";

//Helper
import { useOrgNumberFormatting } from "../../../helper/hooks/useOrgNumberFormatting";

// Components
import ProgressBar from "../../UI/ProgressBar/ProgressBar";

const ImportProgressBar = (props) => {
  const importText = props.isFinished ? "Import finished" : "Importing prospects";

  return (
    <div className={styles.wrapper}>
      <div className={styles.creditUsageDetails}>
        <div className={styles.creditsWrapper}>
          <p>{importText}</p>
          <p>
            <span>{useOrgNumberFormatting(props.accepted)}</span>/
            <span className={styles.total}>{useOrgNumberFormatting(props.totalSize)}</span>
          </p>
        </div>
        <ProgressBar now={props.accepted} max={props.totalSize} />
        <div className={styles.plansWrapper}>
          <p>
            Campaign:
            <OverlayTrigger
              key={"notActive"}
              placement={"bottom"}
              overlay={
                <Popover id={`tooltip-notActive`}>
                  <Popover.Body>{props.campaignName}</Popover.Body>
                </Popover>
              }
            >
              <span className={styles.freePlan}>{props.campaignName}</span>
            </OverlayTrigger>
          </p>

          {props.displayMore && props.totalImports > 1 ? (
            <p className={styles.totalImportsText}>
              +{props.totalImports - 1} {props.totalImports === 2 ? "import" : "imports"}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportProgressBar);
