import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

// Styles
import styles from "./StatusColumn.module.scss";

//Components

const StatusColumn = (props) => {
  const campaignNames = Array.isArray(props.campaignName) ? props.campaignName : props.campaignName.split(",");

  const truncatedCampaigns = campaignNames.slice(0, 2);

  const campaignList = truncatedCampaigns.map((campaign, index) => {
    if (index === 1 && campaignNames.length > 2) {
      return (
        <React.Fragment key={index}>
          <p className={`m0 f12 textOverflow ${styles.secondaryText}`}>
            {campaign.trim()} {`+${campaignNames.length - 2} more`}
          </p>
        </React.Fragment>
      );
    }
    return (
      <p className={`m0 f12 textOverflow ${styles.secondaryText}`} key={index}>
        {campaign.trim()}
      </p>
    );
  });

  return (
    <div className={`${props.isInitiated ? "" : styles.notInitiated} flex_column width_100`}>
      <div className={`flex ${styles.actionICon}`}>
        <OverlayTrigger
          key={Math.random.toString()}
          placement={"bottom"}
          overlay={
            <Popover id={`assign-tooltip`}>
              <Popover.Body>{props.popoverContent}</Popover.Body>
            </Popover>
          }
        >
          <div
            onClick={props.assignToCampaignOnClick}
            id={props.status === "Unassigned" ? "fileAssignedBtnId" : "fileReassignBtnId"}
            className={` ${styles.iconAssignWrapper} max-x-content`}
          >
            <span className={`f14 flex1 ${styles.status} `}>{props.status}</span>
          </div>
        </OverlayTrigger>
      </div>
      {props.status === "Assigned" ? (
        <OverlayTrigger
          key={props.campaignName}
          placement="bottom"
          overlay={
            <Popover id={`tooltip-${props.campaignName}`}>
              <Popover.Body>{props.campaignName}</Popover.Body>
            </Popover>
          }
        >
          <div className={props.isInitiated ? "max-x-content" : styles.notInitiated}>{campaignList}</div>
        </OverlayTrigger>
      ) : null}
    </div>
  );
};

export default StatusColumn;
