import React from "react";

//Helpers
import { pricingPlanStatusEnum } from "../../../helper/enums/pricingPlanStatusEnum";
import { couponTypeEnum } from "../../../helper/enums/couponEnum";

//Style
import styles from "./PricingCard.module.scss";

//Components
import Button from "../../../components/UI/Button/Button";

const PricingCard = (props) => {
  let classes = styles.priceCard;
  let buttonText = "Choose plan";
  let buttonVariant = null;
  if (props.variant != null) {
    classes += " " + styles[props.variant];
  }
  if (props.classes != null) {
    classes += " " + props.classes;
  }
  let disabled = false;

  switch (props.pricePlanStatus) {
    case pricingPlanStatusEnum.disabled:
      classes += " " + styles["disable"];
      buttonVariant = "grayDefault";
      break;
    case pricingPlanStatusEnum.current:
      buttonText = "Current";
      buttonVariant = "grayDefault";
      if (props.isExpired) {
        buttonVariant = "grayDefault";
        buttonText = "Renewal";
      } else {
        buttonVariant = "grayDefault";
        buttonText = "Current";
        disabled = true;
      }
      break;
    case pricingPlanStatusEnum.upgrade:
      buttonVariant = "dark";
      break;
    case pricingPlanStatusEnum.downgrade:
      buttonVariant = "dark";
      break;
    default:
      break;
  }
  disabled = true;

  return (
    <div className={classes} style={props.style}>
      <div className={styles.cardHeader}>
        <h1 className={styles.cardTitle}>{props.cardTitle}</h1>
        <p className={styles.infoDetail}>Keep {props.infoDetail} salespeople busy</p>
      </div>
      <div className={styles.priceDetails}>
        <div className={`${styles.priceDetailsCost} flex`}>
          {props.couponDiscountedPrice ? (
            <p className={styles.price}>
              <span className={styles.oldPrice}>
                {props.currencySign} {props.currentPrice} <span>/Mo.</span>
              </span>
            </p>
          ) : null}

          <h1
            className={`${styles.defaultStyle} ${
              props.coupon?.isValid && props.coupon?.type !== couponTypeEnum.multiplicator ? styles.ribbon : null
            } `}
          >
            {props.currencySign} {props.couponDiscountedPrice ? props.couponDiscountedPrice : props.currentPrice}
            <span className="f18 fontNormal">/Mo.</span>
          </h1>
        </div>
        <span className={styles.appliedDiscountInfo}>
          {props.coupon?.isValid && props.coupon?.type !== couponTypeEnum.multiplicator ? props.coupon?.discountText : null}
        </span>
        <p className={`${styles.billingType} m0`}>
          {props.billingPeriod === 1
            ? "Billed monthly"
            : `Billed annually 
           ${
             props.couponDiscountedFullPrice ? props.currencySign + props.couponDiscountedFullPrice : props.currencySign + props.fullPrice
           }`}
        </p>
        <p className={`${styles.billingType} m0`}>(Excluding VAT)</p>
      </div>
      <div className={styles.packageDetails}>
        <ul>
          <li>Get {props.prospects} credits</li>
          <li>Send {props.emails} emails</li>
          <li>Reveal phone numbers</li>
          <li>{props.users !== 0 ? props.users : "Unlimited"} user accounts</li>
        </ul>
      </div>
      <div className={`${styles.buttonWrapper} flex center_flex mB15`}>
        <Button
          id={props.cardTitle.replaceAll(/[^A-Z0-9]/gi, "")}
          onClick={props.click}
          variant={buttonVariant}
          classes={styles.upgradeButton}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
export default PricingCard;
