import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  listOfCategories: [],
  unsubscribeSettings: {},
  rte: {},
  isLoading: false,
  isLoaded: false,
  error: "",
  searchValue: "",
  complianceSentences: [],
};

//#region FETCH

const fetchSupportCenterComplianceSettingsSuccess = (state, action) => {
  const newState = { ...state };
  let complianceSettings = action.result;
  let US = { ...state.unsubscribeSettings };

  US.id = complianceSettings.id;
  US.unsubscribeNotificationType =
    complianceSettings.unsubscribeNotificationType;
  US.unsubscribeEmail = complianceSettings.unsubscribeEmail;
  US.unsubscribePassword = complianceSettings.unsubscribePassword;
  US.unsubscribeExternalService = complianceSettings.unsubscribeExternalService;
  US.unsubscribeExternalUrl = complianceSettings.unsubscribeExternalUrl;

  newState.isLoaded = true;
  newState.isLoading = false;
  newState.unsubscribeSettings = US;

  return newState;
};

const fetchSupportCenterRTESuccess = (state, action) => {
  const newState = { ...state };
  let RTE = { ...state.rte };
  RTE = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.rte = RTE;

  return newState;
};

const fetchSupportCenterComplianceSentencesSuccess = (state, action) => {
  const newState = { ...state };
  let predefinedComplianceSentences = { ...state.complianceSentences };
  predefinedComplianceSentences = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.complianceSentences = predefinedComplianceSentences;

  return newState;
};
//#endregion

//#region CHANGE
const changeSupportCenterEmailNotificationsSuccess = (state, action) => {
  const newState = { ...state };
  const US = { ...state.unsubscribeSettings };
  const emailNotifications = { ...US.emailNotifications };
  emailNotifications.unsubscribeNotificationType =
    action.result.unsubscribeNotificationType;
  emailNotifications.unsubscribeEmail = action.result.unsubscribeEmail;
  emailNotifications.unsubscribePassword = action.result.unsubscribePassword;

  US.emailNotifications = emailNotifications;
  newState.unsubscribeSettings = US;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const changeSupportCenterExternalServiceSuccess = (state, action) => {
  const newState = { ...state };
  const US = { ...state.unsubscribeSettings };
  const externalService = { ...US.externalService };
  externalService.unsubscribeExternalService =
    action.result.unsubscribeExternalService;
  externalService.unsubscribeExternalUrl = action.result.unsubscribeExternalUrl;

  US.externalService = externalService;
  newState.unsubscribeSettings = US;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};
//#endregion

//#region REMOVE
const removeSupportCenterQARequested = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const removeSupportCenterQASuccess = (state, action) => {
  const newState = { ...state };
  const qaId = action.result;
  const listOfQAs = [...newState.QAs];
  const index = listOfQAs.findIndex((f) => f.id === qaId);

  listOfQAs.splice(index, 1);
  newState.QAs = listOfQAs;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const removeSupportCenterQAFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: action.result,
  };
};

const removeSupportCenterRTERequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const removeSupportCenterRTESuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const removeSupportCenterRTEFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: action.result,
  };
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region FETCH
    case actionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.result,
      };

    case actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_SUCCEEDED:
      return fetchSupportCenterComplianceSettingsSuccess(state, action);
    case actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SETTINGS_FAILED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        error: action.result,
      };

    case actionTypes.FETCH_SUPPORT_CENTER_RTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_SUPPORT_CENTER_RTE_SUCCEEDED:
      return fetchSupportCenterRTESuccess(state, action);
    case actionTypes.FETCH_SUPPORT_CENTER_RTE_FAILED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        error: action.result,
      };

    case actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_SUCCEEDED:
      return fetchSupportCenterComplianceSentencesSuccess(state, action);
    case actionTypes.FETCH_SUPPORT_CENTER_COMPLIANCE_SENTENCES_FAILED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        error: action.result,
      };
    //#endregion

    //#region CHANGE
    case actionTypes.CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_SUCCEEDED:
      return changeSupportCenterEmailNotificationsSuccess(state, action);

    case actionTypes.CHANGE_SUPPORT_CENTER_EMAIL_NOTIFICATIONS_FAILED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        error: action.result,
      };

    case actionTypes.CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_SUCCEEDED:
      return changeSupportCenterExternalServiceSuccess(state, action);

    case actionTypes.CHANGE_SUPPORT_CENTER_EXTERNAL_SERVICE_FAILED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        error: action.result,
      };
    //#endregion

    //#region REMOVE
    case actionTypes.REMOVE_SUPPORT_CENTER_QA_REQUEST:
      return removeSupportCenterQARequested(state, action);

    case actionTypes.REMOVE_SUPPORT_CENTER_QA_SUCCEEDED:
      return removeSupportCenterQASuccess(state, action);

    case actionTypes.REMOVE_SUPPORT_CENTER_QA_FAILED:
      return removeSupportCenterQAFailed(state, action);

    case actionTypes.REMOVE_SUPPORT_CENTER_RTE_REQUEST:
      return removeSupportCenterRTERequest(state, action);

    case actionTypes.REMOVE_SUPPORT_CENTER_RTE_SUCCEEDED:
      return removeSupportCenterRTESuccess(state, action);

    case actionTypes.REMOVE_SUPPORT_CENTER_RTE_FAILED:
      return removeSupportCenterRTEFailed(state, action);
    //#endregion
    default:
      break;
  }

  return state;
};

export default reducer;
