import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  listResponseTypes: [],
  statusCount: {},
};

const fetchListResponseTypesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListResponseTypesSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listResponseTypes };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listResponseTypes = list;
  return newState;
};

const fetchListResponseTypesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchStatusCountRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchStatusCountSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.statusCount };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.statusCount = list;
  return newState;
};

const fetchStatusCountFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchListSenderEmailAccountsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListSenderEmailAccountsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listSenderEmailAccounts };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listSenderEmailAccounts = list;
  return newState;
};

const fetchListSenderEmailAccountsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST_RESPONSE_TYPES_REQUEST:
      return fetchListResponseTypesRequest(state, action);
    case actionTypes.FETCH_LIST_RESPONSE_TYPES_SUCCEEDED:
      return fetchListResponseTypesSuccess(state, action);
    case actionTypes.FETCH_LIST_RESPONSE_TYPES_FAILED:
      return fetchListResponseTypesFailed(state, action);
    case actionTypes.FETCH_STATUS_COUNT_REQUEST:
      return fetchStatusCountRequest(state, action);
    case actionTypes.FETCH_STATUS_COUNT_SUCCEEDED:
      return fetchStatusCountSuccess(state, action);
    case actionTypes.FETCH_STATUS_COUNT_FAILED:
      return fetchStatusCountFailed(state, action);
    case actionTypes.FETCH_LIST_EMAIL_ACCOUNT_REQUEST:
      return fetchListSenderEmailAccountsRequest(state, action);
    case actionTypes.FETCH_LIST_EMAIL_ACCOUNT_SUCCEEDED:
      return fetchListSenderEmailAccountsSuccess(state, action);
    case actionTypes.FETCH_LIST_EMAIL_ACCOUNT_FAILED:
      return fetchListSenderEmailAccountsFailed(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
