import TagsInput from "@ui/TagsInput/TagsInput";
import { Field, useFormikContext } from "formik";
import { FC } from "react";
import { get } from "lodash";

interface FormikTagsProps {
  name: string;
  placeholder: string;
  id?: string;
  content: JSX.Element | string;
  allowIncludeExclude?: boolean;
}

const FormikTags: FC<FormikTagsProps> = ({ name, placeholder, id, content, allowIncludeExclude = false }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  // This has replaced value[name] because it allows us to access nested values, example: value[company.keywords]
  const value = get(values, name);
  const error = get(errors, name);

  const removeTags = (indexToRemove) => {
    setFieldValue(
      name,
      value.filter((_, index) => index !== indexToRemove)
    );
  };

  const addTags = (event) => {
    if (event.target.value !== "" && event.target.value.replace(/\s/g, "").length) {
      var newValue: any;

      if (allowIncludeExclude) {
        newValue = {
          value: event.target.value,
          included: true,
          label: event.target.value,
        };
      } else {
        newValue = event.target.value;
      }
      setFieldValue(name, [...value, newValue]);
      event.target.value = "";
    }
  };

  const includeOrExcludeTags = (chosenValue: string) => {
    let newValue = value.map((tag) => {
      if (tag.value === chosenValue) {
        tag = { ...tag, included: !tag.included };
      }
      return tag;
    });

    setFieldValue(name, newValue);
  };

  return (
    <Field
      as={TagsInput}
      id={id}
      placeholder={placeholder}
      tags={value}
      type="text"
      name={name}
      content={content}
      addTags={addTags}
      removeTags={removeTags}
      error={error}
      allowIncludeExclude={allowIncludeExclude}
      includeOrExcludeTags={includeOrExcludeTags}
    />
  );
};
export default FormikTags;
