import React, { useState } from "react";

//Styles
import styles from "./MessagePreviewModal.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";

const MessagePreviewModal = (props) => {
  return (
    <Modal
      title="Message Preview"
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton="true"
      btnType="submit"
      saveButtonText="OK"
      size="lg"
    >
      <div className={`${styles.contentBody} flex`}>
        <p className={styles.details}>
          Notification Name: <span>John Doe</span>
        </p>
        <p className={styles.details}>
          Category: <span>Add new user</span>
        </p>
        <p className={styles.details}>Message Preview</p>
        <p>Activity details example.............</p>
      </div>
    </Modal>
  );
};

export default MessagePreviewModal;
