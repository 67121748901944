import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { Badge } from "react-bootstrap";
import { debounce, isEqual } from "lodash";

// Helpers
import { whiteDropdownFilter, singleValueDropdownFilter, multiDropdown, timePicker, singleSelect } from "../../../helper/tableConstants";

// Components
import Checkbox from "../../UI/Checkbox/Checkbox";
import { filterOptionsFromValues } from "@pages/AudiencesExplorer/components/Filters/adjustOptionsFromValues";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div title={props.label}>
        <Checkbox
          type="checkbox"
          labelClass="f12"
          checked={props.isSelected}
          label={props.label}
          disabled
          checkBoxClass={props.data.checkBoxClass}
        ></Checkbox>
      </div>
    </components.Option>
  );
};

const CustomMenuList = (props) => {
  return (
    <components.Menu {...props}>
      <div id={props.selectProps.menuId ? props.selectProps.menuId : ""}>{props.children}</div>
    </components.Menu>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span title={props.data.labelShow ? props.data.labelShow : props.data.label}>
      {props.data.labelShow ? props.data.labelShow : props.data.label}
    </span>
  </components.MultiValue>
);

const ValueContainer = ({ children, getValue, ...props }) => {
  const length = getValue().length;
  let displayName = props.selectProps.displayName;
  if (displayName == null) {
    displayName = "Selected";
  }
  let text = null;

  if (!props.selectProps.inputValue) {
    if (length === 0) {
      text = props.selectProps.placeholder;
    } else {
      text = (
        <>
          {displayName}{" "}
          <Badge pill variant="warning" className="mL5 f12">
            {length}
          </Badge>
        </>
      );
    }
  }

  return (
    <components.ValueContainer {...props}>
      {text}

      {React.Children.map(children, (child) => {
        return child != null && child.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  );
};

const Select = (props) => {
  const {
    allowCustomValues,
    allowIncludeExclude = false,
    allowIncludeExcludeForCustomValues = false,
    isAutoComplete,
    requireInputToShowOptions = false,
    hideSelectedOptions = true,
  } = props;
  let isMulti = props.isMulti != null ? props.isMulti : false;

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState(props.inputValue);

  const options = useMemo(() => {
    if (!props.options) {
      return [];
    }

    if (requireInputToShowOptions && isAutoComplete && (!inputValue || inputValue.length === 0)) return [];
    const optionsFormatted = allowIncludeExclude ? props.options.map((option) => ({ ...option, included: true })) : props.options;
    const optionsFiltered = isAutoComplete && hideSelectedOptions ? filterOptionsFromValues(optionsFormatted, value) : optionsFormatted;
    return optionsFiltered;
  }, [props.options, value, allowIncludeExclude, isMulti, inputValue, requireInputToShowOptions]);

  useEffect(() => {
    // if (props.name === "company.location.range.location") debugger;

    if (value == null) {
      let defaultValue = null;
      if (props.defaultValue != null) {
        if (Array.isArray(props.defaultValue)) {
          defaultValue = props.options?.filter((i) => props.defaultValue.includes(i.value));
        } else {
          defaultValue = props.options?.find((i) => i.value === props.defaultValue);
        }
      }

      setValue(defaultValue);
    }
  }, [props.options, props.defaultValue, value]);

  useEffect(() => {
    // if (props.name === "company.location.range.location") debugger;

    if (props.value != null) {
      if (Array.isArray(props.value)) {
        if (isAutoComplete) {
          setValue(props.value);
        } else {
          setValue(props.options?.filter((i) => props.value.includes(i.value)));
        }
      } else if (props.options) {
        let selectedOption = props.options.find((i) => isEqual(i.value, props.value));
        if (selectedOption) {
          setValue(selectedOption);
        } else {
          selectedOption = props.options.find((i) => isEqual(i.value, props.value.value));
          if (selectedOption) {
            setValue(selectedOption);
          }
        }
      } else {
        setValue(props.value);
      }
    } else {
      setValue(null);
    }
  }, [props.value, props.options, isAutoComplete]);

  const changeHandler = (event) => {
    // if (props.name === "company.location.range.location") debugger;

    if (props.onChange == null) {
      setValue(event);
      return;
    }

    // When we have multi select
    if (Array.isArray(event)) {
      if (event == null || event.length == 0) {
        setValue([]);
        props.onChange([]);
      } else if (isAutoComplete) {
        let last = event[event.length - 1];
        if (last?.value?.label) {
          event[event.length - 1] = event[event.length - 1].value;
        }
        setValue(event);
        props.onChange(event);
      } else {
        let valueArray = Array.from(event, (x) => x.value);
        setValue(event);
        props.onChange(valueArray);
      }
    } else {
      if (event == null || event.value == null || event.value === undefined) {
        setValue(null);
        props.onChange(null);
      } else {
        setValue(event);
        props.onChange(props.onChangeSelectedOption ? event : event.value);
      }
    }
  };

  const addCustomValue = (input) => {
    const newOption = { value: input, label: input, isCustom: true };

    if (allowIncludeExcludeForCustomValues) {
      newOption.included = true;
    }

    if (isMulti) {
      // For multi-select, call changeHandler with an array of options
      changeHandler([...(value || []), newOption]);
    } else {
      // For single-select, call changeHandler with the new option
      changeHandler(newOption);
    }
  };

  const animatedComponents = makeAnimated();
  let customComponents = {};

  if (isMulti) {
    customComponents = { Option, MultiValue, animatedComponents, Menu: CustomMenuList };
    // Keep this in case we need to display only number of options selected.

    // customComponents = {
    //   Option,
    //   MultiValue,
    //   ValueContainer,
    //   animatedComponents,
    //   DropdownIndicator: () => null,
    //   IndicatorSeparator: () => null,
    //   ClearIndicator: () => null,
    // };
  }

  const selectRef = React.createRef();
  const SelectComponent = allowCustomValues ? CreatableSelect : ReactSelect;

  const debouncedInputChange = useCallback(
    debounce((e, resetOptions) => {
      if (props.onInputChange) props.onInputChange(e, resetOptions);
    }, 300),
    [props.onInputChange]
  );

  return (
    <SelectComponent
      tabIndex={props.tabIndex}
      inputId={"select" + props.inputId}
      aria-label="Select"
      ref={selectRef}
      id={props.id || undefined}
      menuId={props.menuId ? props.menuId : ""}
      isMulti={isMulti}
      menuPortalTarget={props.isMenuPortalTargetDisabled ? document.body : false}
      className={props.className}
      name={props.name}
      value={value}
      //defaultValue={defaultValue} // This is what will be set if value is missing
      inputValue={inputValue} // This is what was entered as search
      options={options}
      noOptionsMessage={props.hideNoOptions ? () => null : () => "No options"}
      styles={
        props.singleSelect
          ? singleSelect
          : props.multiDropdown
          ? multiDropdown
          : isMulti === true
          ? whiteDropdownFilter
          : props.timePicker
          ? timePicker
          : singleValueDropdownFilter
      }
      placeholder={props.placeholder}
      isSearchable={props.isSearchable != null ? props.isSearchable : false}
      onChange={changeHandler}
      isClearable={false}
      filterOption={props.filterOption}
      isDisabled={props.disabled}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      components={customComponents}
      allowSelectAll={true}
      focus={true}
      menuIsOpen={props.menuIsOpen}
      menuPlacement="auto"
      controlShouldRenderValue={props.controlShouldRenderValue}
      onMenuOpen={() => {
        if (selectRef?.current) selectRef.current.focus();
        if (props.onMenuOpen) props.onMenuOpen();
      }}
      onMenuClose={() => {
        if (props.onMenuClose) {
          props.onMenuClose();

          if (isAutoComplete) {
            setInputValue("");
          }
        }
      }}
      onInputChange={(e, actionType) => {
        setInputValue(e);

        var resetOptions = false;
        if (e === "") {
          if (actionType.action == "input-change") resetOptions = true;
        }

        debouncedInputChange(e, resetOptions);
      }}
      onKeyDown={(e) => {
        if (allowCustomValues) {
          if (e.key === "Backspace" && inputValue === "") {
            e.preventDefault();
          } else if (props.onInputKeyDown) {
            props.onInputKeyDown(e);
          }

          if (e.key === "Enter") {
            e.preventDefault();
            if (isAutoComplete) {
              if (selectRef?.current) selectRef.current.focus();
            }

            if (allowCustomValues && inputValue) {
              addCustomValue(inputValue);
            }

            setInputValue("");
          }
        }
      }}
      // Only apply this props when allowing custom values
      {...(allowCustomValues && {
        onCreateOption: (input) => addCustomValue(input),
        // Use this to hide the "Search for" in the options
        isValidNewOption: () => false,
        // formatCreateLabel: (inputValue) => `Search for: ${inputValue}`,
      })}
    />
  );
};

export default Select;
