export const FETCH_EMAIL_ACCOUNTS_LIST_REQUEST =
  "FETCH_EMAIL_ACCOUNTS_LIST_REQUEST";
export const FETCH_EMAIL_ACCOUNTS_LIST_SUCCEEDED =
  "FETCH_EMAIL_ACCOUNTS_LIST_SUCCEEDED";
export const FETCH_EMAIL_ACCOUNTS_LIST_FAILED =
  "FETCH_EMAIL_ACCOUNTS_LIST_FAILED";

export const CONNECT_EMAIL_ACCOUNTS_REQUEST = "CONNECT_EMAIL_ACCOUNTS_REQUEST";
export const CONNECT_EMAIL_ACCOUNTS_SUCCEEDED =
  "CONNECT_EMAIL_ACCOUNTS_SUCCEEDED";
export const CONNECT_EMAIL_ACCOUNTS_FAILED = "CONNECT_EMAIL_ACCOUNTS_FAILED";

export const FETCH_EMAIL_ACCOUNTS_SELECT_LIST_REQUEST =
  "FETCH_EMAIL_ACCOUNTS_SELECT_LIST_REQUEST";
export const FETCH_EMAIL_ACCOUNTS_SELECT_LIST_SUCCEEDED =
  "FETCH_EMAIL_ACCOUNTS_SELECT_LIST_SUCCEEDED";
export const FETCH_EMAIL_ACCOUNTS_SELECT_LIST_FAILED =
  "FETCH_EMAIL_ACCOUNTS_SELECT_LIST_FAILED";
export const FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_REQUEST =
  "FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_REQUEST";
export const FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_SUCCEEDED =
  "FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_SUCCEEDED";
export const FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_FAILED =
  "FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_FAILED";

export const EMAIL_ACCOUNTS_CLEAR_INITIATED = "EMAIL_ACCOUNTS_CLEAR_INITIATED";
