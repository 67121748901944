import React, { useState } from "react";
import { toast } from "react-toastify";

import Select from "../../../components/UI/Select/Select";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import Button from "../../../components/UI/Button/Button";
import SelectMulti from "../../../components/UI/Select/SelectMulti";

const FormsSandbox = () => {
  const selectOptions = [
    { value: "0", label: "None" },
    { value: "1", label: "Initial Email" },
    { value: "2", label: "Follow Up" },
  ];

  const selectChange = (event) => {
    if (event != null) {
      if (Array.isArray(event)) {
        toast.success(event.join(","));
      } else {
        toast.success(event);
      }
    } else {
      toast.success("Empty");
    }
  };

  const [checkboxValue, setCheckboxValue] = useState(false);

  const checkboxChange = (checked) => {
    if (checked) {
      toast.success("Enabled");
    } else {
      toast.success("Disabled");
    }
  };

  const checkboxControllerHandleTrue = () => {
    setCheckboxValue(true);
  };

  const checkboxControllerHandleFalse = () => {
    setCheckboxValue(false);
  };

  return (
    <>
      <SectionTitle title="<Select>" />

      <Select options={selectOptions} onChange={selectChange} placeholder={"Select"} />

      <SectionTitle title="<Select> disabled" />

      <Select displayName="Options" options={selectOptions} onChange={selectChange} placeholder={"Select"} value="1" disabled />

      <SectionTitle title="<Select> multi" />

      <Select options={selectOptions} onChange={selectChange} placeholder={"Select multi"} isMulti={true} isSearchable={true} />

      <SectionTitle title="<SelectMulti>" />

      <SelectMulti
        options={selectOptions}
        onChange={selectChange}
        placeholder="Select campaigns..."
        displayName="Campaigns"
        isMulti={true}
        isSearchable={true}
      />

      <SectionTitle title="<Checkbox>" />

      <Checkbox label="Check this" onChange={checkboxChange} checked={checkboxValue} />

      <Button id="enable-checkbox" variant="primary" onClick={checkboxControllerHandleTrue}>
        Enable checkbox
      </Button>
      <Button id="disabled-checkbox" variant="primary" onClick={checkboxControllerHandleFalse}>
        Disable checkbox
      </Button>

      <Checkbox label="Check this" onChange={checkboxChange} checked={true} />

      <SectionTitle title="<Checkbox> disabled" />

      <Checkbox label="Check this" onChange={checkboxChange} disabled />

      <SectionTitle title="Select with value, no options" />
      <Select
        value={{ label: "Test", value: "Test" }}
        options={null}
        displayName="Select region"
        placeholder="e.g. England, United Kingdom"
        enableReinitialize
        hideNoOptions
        isAutoComplete
        isSearchable
        requireInputToShowOptions
      />
    </>
  );
};

export default FormsSandbox;
