import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "moment";

//Redux
import {
  fetchProspects,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
} from "../../../store/Prospects/Table/actions/prospects";

//Helpers
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";

// Icons & Images
import Message2LineIcon from "remixicon-react/Message2LineIcon";
import IconEmailExcluded from "../../../assets/Icons/IconEmailExcluded/IconEmailExcluded";
import EmptyEmailAccount from "../../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";

// Components
import StatusBar from "../../Prospects/StatusBar/StatusBar";
import Table from "../../../components/DataTable/Table";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const PeopleTable = (props) => {
  let dateFormat = useOrgDateFormat();
  const { param, rows, fetchProspects, isInitiated } = props;
  const organizationId = useOrganizationId();
  const [tableRows, setTableRows] = useState([]);
  let table = null;
  let image = <EmptyEmailAccount />;

  useEffect(() => {
    if (isInitiated) {
      fetchProspects(param);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      r.email = (
        <OverlayTrigger
          key={r.email}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.email}`}>
              <Popover.Body>{r.email}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.id,
              }}
            >
              {r.email}
            </Link>
          </div>
        </OverlayTrigger>
      );
      if (r.isExcluded) {
        r.isExcluded = (
          <OverlayTrigger
            key={r.isExcluded}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-${r.isExcluded}`}>
                <Popover.Body>This prospect has been excluded</Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">
              <IconEmailExcluded />
            </div>
          </OverlayTrigger>
        );
      } else {
        r.isExcluded = null;
      }

      r.companyName = (
        <OverlayTrigger
          key={r.companyName}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.companyName}`}>
              <Popover.Body>{r.companyName}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content">
            <Link
              to={{
                pathname: "/" + props.orgId + "/company-activity-overview/" + r.companyId,
              }}
            >
              {r.companyName}
            </Link>
          </div>
        </OverlayTrigger>
      );

      if (r.lastActivity != null && r.lastActivity !== "") {
        r.lastActivity = Moment(r.lastActivity).format(dateFormat);
      } else {
        r.lastActivity = "-";
      }

      if (r.lastSend != null && r.lastSend !== "") {
        r.lastSend = Moment(r.lastSend).format(dateFormat);
      } else {
        r.lastSend = "-";
      }

      r.conversations = (
        <Link
          to={{
            pathname: "/" + organizationId + "/conversation/" + r.id,
          }}
        >
          <Message2LineIcon size={16} className="iconGray500" />
        </Link>
      );

      r.currentStatus = (
        <StatusBar
          isDelivered={r.delivered != null}
          isOpened={r.opened != null}
          isClicked={r.clicked != null}
          isResponder={r.isResponder}
          isLead={r.isLead}
        />
      );

      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>Error getting Prospects</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Prospects"}
          columns={columns}
          rows={tableRows}
          param={param}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          tableHeight={260}
          variant="small"
        />
      ) : (
        <EmptyState image={image} title="No people found" />
      );
  }

  return <>{table}</>;
};

const mapStateToProps = (state) => {
  return {
    param: state.prospects.params,
    isInitiated: state.prospects.isInitiated,
    isLoading: state.prospects.isLoading,
    isLoaded: state.prospects.isLoaded,
    error: state.prospects.error,
    rows: state.prospects.rows,
    totalCount: state.prospects.totalCount,
    pageCount: state.prospects.pageCount,
    listResponseTypes: state.globalProspects.listResponseTypes,
    listCompanies: state.globalCompanies.listCompanies,
    statusCount: state.globalProspects.statusCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProspects: (param) => dispatch(fetchProspects(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleTable);
