import React from "react";

//Styles
import styles from "./ResponseTypePill.module.scss";

const ResponseTypePill = (props) => {
  let responsePill = null;

  switch (props.type) {
    case "Positive response":
      responsePill = <span className={`${styles.responsePill} ${styles.positive}`}>Positive response</span>;
      break;
    case "Referral":
      responsePill = <span className={`${styles.responsePill} ${styles.referral}`}>Referral</span>;
      break;
    case "Deferred interest":
      responsePill = <span className={`${styles.responsePill} ${styles.deferred}`}>Deferred Interest</span>;
      break;
    case "Polite Decline":
      responsePill = <span className={`${styles.responsePill} ${styles.decline}`}>Polite Decline</span>;
      break;
    case "No longer with business":
      responsePill = <span className={`${styles.responsePill} ${styles.decline}`}>No longer with business</span>;
      break;
    case "Undelivered":
      responsePill = <span className={`${styles.responsePill} ${styles.decline}`}>Undelivered</span>;
      break;
    case "Out of office":
      responsePill = <span className={`${styles.responsePill} ${styles.deferred}`}>Out of office</span>;
      break;
    case "Ignore & Archive":
      responsePill = <span className={`${styles.responsePill} ${styles.ignore}`}>Ignore and archive</span>;
      break;
    case "Decline":
      responsePill = <span className={`${styles.responsePill} ${styles.decline}`}>Decline</span>;
      break;
    default:
      break;
  }

  return <>{responsePill}</>;
};

export default ResponseTypePill;
