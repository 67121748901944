import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

//Style
import styles from "./AddEmailAccount.module.scss";

const AddEmailAccount = (props) => {
  return (
    <div id="accountButtons" className={`${styles.selectEmailAccountWrapper} flex_center`}>
      <button
        id="smtpImapButton"
        className={`${styles.selectButton} ${styles.smtpButton}`}
        onClick={() => {
          props.handleSave("SmtpImap");
        }}
      >
        <span className={styles.icon}></span>
        <span className={styles.buttonText}>Sign in with SMTP</span>
      </button>

      <button
        id="gmailButton"
        className={`${styles.selectButton} ${styles.googleButton}`}
        onClick={() => {
          props.handleSave("Gmail");
        }}
      >
        <span className={styles.icon}></span>
        <span className={styles.buttonText}>Sign in with Google</span>
      </button>

      <button
        id="microsoftButton"
        className={`${styles.selectButton} ${styles.microsoftButton}`}
        onClick={() => {
          props.handleSave("Microsoft");
        }}
      >
        <span className={styles.icon}></span>
        <span className={styles.buttonText}>Sign in with Microsoft</span>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organization: state.user.organization,
  };
};

export default connect(mapStateToProps, null)(AddEmailAccount);
