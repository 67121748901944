import { OptimizationCounts } from "@models/campaign/OptimizationCounts";
import { OptimizationModel } from "./../models/campaign/OptimizationModel";
import { api } from "./api";
import { OptimizationFilters } from "@models/campaign/OptimizationFilters";

export const optimizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getForCampaign: builder.query<OptimizationModel[], OptimizationFilters>({
      query: (model) => ({
        url: `/optimizations/get-active-for-campaign`,
        method: "POST",
        body: model,
      }),
      providesTags: ["Optimizations"],
      keepUnusedDataFor: 0,
    }),
    dismissOptimization: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/optimizations/dismiss?optimizationId=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Optimizations", "Campaign"],
    }),
    applyOptimization: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/optimizations/apply?optimizationId=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Optimizations", "Campaign"],
    }),
    askForHelpOptimization: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `/optimizations/ask-support?optimizationId=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Optimizations", "Campaign"],
    }),
    getCountsForCampaign: builder.query<OptimizationCounts, number>({
      query: (campaignId) => ({
        url: `/optimizations/get-counts-for-campaign`,
        method: "GET",
        params: { campaignId },
      }),
      providesTags: ["Optimizations"],
    }),
    getCompletedForCampaign: builder.query<OptimizationModel[], OptimizationFilters>({
      query: (model) => ({
        url: `/optimizations/get-completed-for-campaign`,
        method: "POST",
        body: model,
      }),
      providesTags: ["Optimizations"],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetForCampaignQuery,
  useDismissOptimizationMutation,
  useApplyOptimizationMutation,
  useAskForHelpOptimizationMutation,
  useGetCountsForCampaignQuery,
  useGetCompletedForCampaignQuery,
} = optimizationApi;
