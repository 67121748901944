import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Helpers
import { actionTypeEnum } from "../../../../../../helper/enums/actionType";
import { emailTemplateTypeEnumOptions } from "../../../../../../helper/enums/emailTemplateTypeEnum";
import { emailTemplateStatusEnum } from "../../../../../../helper/enums/emailTemplateStatusEnum";

//Images & Icons
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import MailLineIcon from "remixicon-react/MailLineIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import AlertLineIcon from "remixicon-react/AlertLineIcon";

//Style
import styles from "./Step.module.scss";

//Components
import Button from "../../../../../../components/UI/Button/Button";

const truncateHtml = (html, maxLength) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  let textContent = div.textContent || div.innerText || "";
  return textContent.substring(0, maxLength);
};

const Step = (props) => {
  let style = null;
  let icon = null;
  let stepValue = null;
  let stepInfo = null;
  let active = null;
  let deleted = null;
  let status = null;
  let html = null;

  switch (props.type) {
    case actionTypeEnum.Email:
      style = styles.setEmail;
      icon = <MailLineIcon className={`iconWhite ${styles.emailIcon}`} />;
      stepValue = "Email Template";
      stepInfo = props.templateType ? emailTemplateTypeEnumOptions[props.templateType].label : "Select from Templates";
      active = props.active ? styles.activeEmail : null;
      deleted = props.isDeletedTemplate ? styles.deletedTemplate : null;
      html = props.templateHtml;
      status = props.templateStatus;
      break;
    case actionTypeEnum.Wait:
      style = styles.setTimeWait;
      icon = <TimeLineIcon className={styles.timeIcon} />;
      stepValue = "Set Wait Time";
      stepInfo = "Wait";
      active = props.active ? styles.activeWait : null;
      break;
    default:
      break;
  }

  return (
    <div className={styles.stepsWrapper} id={props.templateSubject ? props.templateSubject.replaceAll(" ", "") : ""}>
      <div id={"step" + (props.iterationId + 1)} className={`${styles.sequenceStep} ${style} ${active} ${deleted} ${styles.m_b_38}`}>
        <div
          onClick={props.stepClicked}
          className={`${styles.stepDetails} ${props.type === actionTypeEnum.Email ? styles.stepDetailsEmail : styles.stepWaitTime}`}
        >
          <div className="flex">
            <div onClick={props.stepClicked} className={styles.stepIcon}>
              {icon}
            </div>
            <div className={`${styles.stepValue} ${props.type === actionTypeEnum.Email && styles.emailStepValue}`}>
              <p className={styles.stepTitle}>
                {props.details ? props.details : stepValue} <span className={styles.stepInfo}>{stepInfo}</span>
              </p>
              {status === emailTemplateStatusEnum.Draft && (
                <p>
                  <span className={`f12 mL5 ${styles.statusTag}`}>{status === emailTemplateStatusEnum.Draft && "Draft"}</span>
                </p>
              )}
            </div>
          </div>
          {status === emailTemplateStatusEnum.Draft && (
            <OverlayTrigger
              key="draft"
              placement={"bottom"}
              overlay={
                <Popover id={`popover`}>
                  <Popover.Body>Campaign pending. Review and publish outstanding messages to launch.</Popover.Body>
                </Popover>
              }
            >
              <div>
                <AlertLineIcon size={20} className={styles.alertIcon} color="var(--danger-color-500)" />
              </div>
            </OverlayTrigger>
          )}
          <Button id="deleteStep" variant="roundedButton" classes={styles.deleteStep}>
            <DeleteBin6LineIcon size={18} className={styles.deleteIcon} onClick={props.deleteStep} />
          </Button>
        </div>
        {props.type === actionTypeEnum.Email && html !== undefined && <div className="mT10">{truncateHtml(html, 50) + "..."}</div>}
      </div>

      <div className={styles.addStepWrapper} id={"stepBtnWrapper" + (props.iterationId + 1)}>
        <Button
          id={"addStep" + (props.iterationId + 1)}
          variant="rounded-s"
          classes={`${styles.addStepButton} ${styles.scaleButton}`}
          ariaLabel="Add next step button"
        >
          <AddLineIcon size={18} className="secondary300" />
        </Button>
        <div className={styles.chooseStep}>
          <Button
            id={"stepEmail" + (props.iterationId + 1)}
            variant="secondary-s"
            classes={`${styles.addStepButton} ${styles.addEmail} step-email`}
          >
            <MailLineIcon size={14} onClick={props.showTemplates} />
          </Button>
          <Button
            id={"stepWait" + (props.iterationId + 1)}
            variant="rounded-s"
            classes={`${styles.addStepButton} ${styles.addWait} step-wait`}
          >
            <TimeLineIcon size={14} className={`${styles.timeIcon} iconGray500`} onClick={props.showWait} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step;
