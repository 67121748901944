import React from "react";

// Styles
import styles from "./AddPaymentModal.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import ChargifyForm from "../../../../../components/ChargifyForm/ExternalFormDataChargifyForm";

const AddPaymentModal = (props) => {
  return (
    <Modal
      dialogClassName={`customModal ${styles.paymentModal}`}
      customBodyClass={styles.paymentModalBody}
      title={"Payment method"}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      oneButton={false}
      saveButtonText="Save Card"
      btnType="submit"
      closeButtonText={"Close"}
      saveButtonDisabled={props.submitting}
    >
      <p className={styles.subTitle}>Add your credit card</p>
      <ChargifyForm
        setGetToken={props.setGetToken}
        getToken={props.getToken}
        isSubmitting={props.isSubmitting}
        handleReturnToken={props.handleReturnToken}
        creditCardInfo={props.creditCardInfo}
      />
    </Modal>
  );
};

export default AddPaymentModal;
