import React from "react";

const IconLead = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 12}
    height={props.height ? props.height : 12}
    fill="none"
    viewBox="0 0 12 14"
  >
    <path
      fill={props.fill ? props.fill : ""}
      d="M6.223 8.163v4.63H.973a4.667 4.667 0 015.25-4.63zm-.584-.62a3.499 3.499 0 01-3.5-3.5 3.499 3.499 0 117 0c0 1.934-1.566 3.5-3.5 3.5zm3.38 4.034l2.062-2.063.825.825-2.887 2.888-2.063-2.063.825-.825 1.238 1.238z"
    ></path>
  </svg>
);

export default IconLead;
