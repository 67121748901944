import { AudiencePushToCampaignDropdownModel } from "@models/audience/AudiencePushToCampaignDropdownModel";
import { AudienceDetailsModel } from "@models/audience/AudienceDetailsModel";
import { api } from "./api";
import { AssignAudienceCampaignsModel } from "@models/audience/AssignAudienceCampaignsModel";
import { AudiencesInitiatedStatusResponseModel } from "@models/audience/AudiencesInitiatedStatusResponseModel";
import { DropdownModel } from "@models/DropDownModel";

export const audienceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAudiencesForCampaign: builder.query<AudiencePushToCampaignDropdownModel[], number>({
      query: (campaignId) => ({
        url: `/audiences/select-list-audiences-campaign?campaignId=${campaignId}`,
        method: "GET",
      }),
      providesTags: ["AudiencesForCampaign"],
    }),
    getAudiencesForCampaignNotInitiated: builder.query<AudiencePushToCampaignDropdownModel[], number>({
      query: (campaignId) => ({
        url: `/audiences/select-list-not-initiated-audiences-campaign?campaignId=${campaignId}`,
        method: "GET",
      }),
      providesTags: ["AudiencesForCampaign"],
    }),
    getAudienceDetails: builder.query<AudienceDetailsModel, number>({
      query: (audienceId) => ({
        url: `/audiences/audience-details?audienceId=${audienceId}`,
        method: "GET",
      }),
      providesTags: ["AudienceDetails"],
    }),
    setAudienceCampaigns: builder.mutation<boolean, AssignAudienceCampaignsModel>({
      query: (model) => ({
        url: "/audiences/set-audience-campaigns",
        method: "POST",
        body: model,
      }),
      invalidatesTags: ["AudienceDetails", "Campaign"],
    }),
    getAudiencesInitiatedStatus: builder.mutation<AudiencesInitiatedStatusResponseModel[], number[]>({
      query: (model) => ({
        url: `/audiences/get-audience-initiated-status`,
        method: "POST",
        body: model,
      }),
    }),
    getTargetingInfo: builder.query<string, number>({
      query: (audienceId) => ({
        url: `/audiences/get-audience-targeting-info?audienceId=${audienceId}`,
        method: "GET",
      }),
      providesTags: ["AudienceDetails"],
    }),

    getAudienceContactsCompanyIndustries: builder.query<DropdownModel[], number>({
      query: (audienceId) => ({
        url: `/audiences/select-list-audience-contacts-company-industries?audienceId=${audienceId}`,
        method: "GET",
      }),
      providesTags: ["AudienceContactsCompanyIndustries"],
    }),
    getAudienceContactsCompanySizes: builder.query<DropdownModel[], number>({
      query: (audienceId) => ({
        url: `/audiences/select-list-audience-contacts-company-sizes?audienceId=${audienceId}`,
        method: "GET",
      }),
      providesTags: ["AudienceContactsCompanySizes"],
    }),
    getAudienceContactsCountries: builder.query<DropdownModel[], number>({
      query: (audienceId) => ({
        url: `/audiences/select-list-audience-contacts-countries?audienceId=${audienceId}`,
        method: "GET",
      }),
      providesTags: ["AudienceContactsCountries"],
    }),
  }),
});

export const {
  useGetAudiencesForCampaignQuery,
  useGetAudienceDetailsQuery,
  useGetAudiencesForCampaignNotInitiatedQuery,
  useSetAudienceCampaignsMutation,
  useGetAudiencesInitiatedStatusMutation,
  useGetTargetingInfoQuery,

  useGetAudienceContactsCompanyIndustriesQuery,
  useGetAudienceContactsCompanySizesQuery,
  useGetAudienceContactsCountriesQuery,
} = audienceApi;
