export const dealsTableColumns = [
  {
    label: "",
    field: "image",
    type: "actions",
    class: "overflow-visible",
    width: 65,
  },
  {
    label: "Company Name",
    field: "companyName",
    type: "text",
    width: 120,
    minWidth: 120,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Company Size",
    field: "companySize",
    type: "text",
    width: 100,
    minWidth: 100,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Date of Response",
    field: "dateOfResponse",
    type: "date",
    showTooltip: true,
    width: 140,
    minWidth: 140,
    flex: 1,
    sort: "asc",
  },
];
