import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

//Redux
import { removeSalesDocuments } from "../../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Styles
import styles from "./Videos.module.scss";

//Images & Icons
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

//Components
import ChangeVideoForm from "../../Modals/ChangeVideoForm";
import Permission from "../../../../components/auth/Permission";

const Video = (video) => {
  const [showChangeVideoModal, setShowChangeVideoModal] = useState(false);
  const handleChangeVideoClose = () => setShowChangeVideoModal(false);
  const handleChangeVideoShow = () => setShowChangeVideoModal(true);

  const deleteVideo = (id) => {
    video.removeSalesDocuments(id);
  };

  return (
    <div className={styles.videoContainer}>
      <div className={styles.videosList} dangerouslySetInnerHTML={{ __html: video.videoUrl }} />
      <div className={styles.buttonsWrapper}>
        <Permission has={PERMISSIONS.salesDocuments.documents.edit}>
          <Button id="videEdit" className={styles.editButton} onClick={handleChangeVideoShow}>
            <Edit2LineIcon size={16} className="iconGray" />
          </Button>
        </Permission>
        <Permission has={PERMISSIONS.salesDocuments.documents.delete}>
          <Button id="videoDelete" className={styles.deleteButton} onClick={() => deleteVideo(video.id)}>
            <DeleteBin6LineIcon className="iconGray" size={16} />
          </Button>
        </Permission>
      </div>
      <span>{video.comment}</span>

      <Permission has={PERMISSIONS.salesDocuments.documents.edit}>
        <ChangeVideoForm
          handleClose={handleChangeVideoClose}
          showModal={showChangeVideoModal}
          id={video.id}
          videoUrl={video.videoUrl}
          description={video.comment}
        />
      </Permission>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSalesDocuments: (id) => dispatch(removeSalesDocuments(id)),
  };
};

export default connect(null, mapDispatchToProps)(Video);
