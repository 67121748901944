import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./OrganizationSelection.module.scss";

//Helpers
import api, { formUrl, API } from "../../../helper/api/api";

const OrganizationSelection = (props) => {
  const logOrganizationLogin = (event, organizationId) => {
    event.preventDefault();
    let path = "/" + organizationId + "/dashboard";
    api(
      formUrl(API.users.logUserLoginToOrganization, {
        organizationId: organizationId,
      })
    )
      .then((response) => {
        props.history.push(path);
      })
      .catch((error) => {});
  };

  const orgs = props.orgList.map((org) => {
    const path = "/" + org.id + "/dashboard";
    let link = null;
    org.organizationAccessRemoved
      ? (link = (
          <OverlayTrigger
            key={"disabled-org"}
            placement={"left"}
            overlay={
              <Popover id={`disabled-org-tooltip`}>
                <Popover.Body>This organization is disabled by the system admin.</Popover.Body>
              </Popover>
            }
          >
            <NavLink className={styles.disabledOrganization} onClick={(e) => e.preventDefault()} key={path} to={path}>
              {org.name}
            </NavLink>
          </OverlayTrigger>
        ))
      : (link = (
          <NavLink onClick={(e) => logOrganizationLogin(e, org.id)} key={path} to={path}>
            {org.name}
          </NavLink>
        ));

    return link;
  });
  return (
    <div className={`${styles.orgSelectDropdown} ${props.customStyle}`}>
      <div className={styles.wrap}>
        <p className={styles.selectOrganizationHeader}>Choose organization:</p>
        <div className={styles.organizationList}>{orgs}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orgList: state.user.organizations,
  };
};

export default connect(mapStateToProps)(OrganizationSelection);
