import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Helpers
import { fileDownload } from "../../../helper/fileDownloadHelper";

// Styles
import styles from "./DownloadFileModal.module.scss";

// Images & Icons
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

// Components
import Spinner from "../Spinner/Spinner";

interface DownloadFileModalProps {
  show: boolean;
  title: string;
  fileName: string;
  fileData: string;
  handleClose: () => void;
}

const DownloadFileModal: React.FC<DownloadFileModalProps> = ({ show, title, fileName, fileData, handleClose }) => {
  const downloadFile = () => {
    if (fileData !== "") fileDownload(fileName, fileData);
  };

  return (
    <div className={show ? styles.modalShow : styles.modalHide}>
      <div className={styles.header}>
        <div className={styles.header_title}>
          {title}
          <button type="button" onClick={handleClose}>
            <span aria-hidden="true">
              <CloseLineIcon color="var(--white-color)" size={18} />
            </span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>
      <Container className={styles.containerBody}>
        <Row>
          <Col xl={12} className="flex_center">
            <>
              <span className={`${styles.linkReady} mR5`} onClick={downloadFile}>
                {fileName}
              </span>
              {fileData !== "" ? (
                <div className={styles.checkMarkWrap}>
                  <CheckboxCircleFillIcon color="var(--first-stage-color)" size={20} />
                </div>
              ) : (
                <div className={styles.spinnerWrap}>
                  <Spinner size="20px" />
                </div>
              )}
            </>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DownloadFileModal;
