import React, { useState } from "react";

//Helpers
import { templateGoalOptions } from "../../../../helper/enums/templateGoalOptions";
import { templateToneOptions } from "../../../../helper/enums/templateToneOptions";
import { languageTemplateOptions } from "../../../../helper/enums/languageTemplateOptions";
import { targetAudienceOptions } from "../../../../helper/enums/targetAudienceOptions";
import { aiWriterStepsEnum } from "../../../../helper/enums/aiWriterStepsEnum";

//Images & Icons
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";

//Styles
import styles from "./InitialEmailType.module.scss";

//Components
import StepperComponent from "../../../../components/UI/StepperComponent/StepperComponent";
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import Button from "../../../../components/UI/Button/Button";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import AccordionSimple from "../../../../components/UI/AccordionSimple/AccordionSimple";
import Select from "../../../../components/UI/Select/Select";

const InitialEmailType = (props) => {
  const [forms, setForms] = useState([]);

  const handleRemoveForm = (index) => {
    props.setFormCount(props.formCount - 1);
    props.setShowSecondUrl(false);
    props.setSecondUrl("");
  };

  const steps = [
    {
      label: "",
    },
    {
      label: "",
    },
  ];

  return (
    <div className={`${styles.steps} ${props.stepsWrapperClass}`} id="stepContent">
      <StepperComponent
        className={styles.stepperRoot}
        steps={steps}
        orientation="vertical"
        setActiveStep={props.setActiveStep}
        activeStep={props.activeStep}
      />
      <div
        className={`${styles.stepContent} ${
          props.stepperContentClass ? props.stepperContentClass : styles.stepperContent
        } campaignSequenceStepperContent mT15`}
      >
        {props.activeStep === aiWriterStepsEnum.TemplateSettingsStep && (
          <>
            {props.waitingAIResponse ? (
              <Spinner
                spinnerClassName={`absolute transform-x-y-50 ${styles.spinner}`}
                size="3rem"
                descriptionClass={styles.descriptionText}
                description="Sit back and relax while our AI writer prepares your awesome email templates."
              />
            ) : (
              <>
                <p className="f16 font700">TARGET LIST</p>
                <p className="f14 secondary_text_color">Provide details of your target list, using the options below.</p>
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  name="companySize"
                  label="Company size"
                  value={props.companySizeValue}
                  placeholder={"Select company size"}
                  showBorder={true}
                  selectChange={true}
                  options={targetAudienceOptions}
                />
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  name="language"
                  label="Languages"
                  value={props.languageValue}
                  placeholder={"Select language"}
                  showBorder={true}
                  selectChange={true}
                  options={languageTemplateOptions}
                />
                <div className="d-flex ">
                  <p className="f16 font700 m0">TEMPLATE REQUIREMENTS</p>
                </div>
                <p className="f14 secondary_text_color">
                  Tell us more about the template you would like to generate, such as the goal and tone of voice.
                </p>
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  label="Template Goal"
                  name="templateGoal"
                  value={props.templateGoalValue}
                  placeholder={"Select template goal "}
                  showBorder={true}
                  selectChange={true}
                  options={templateGoalOptions}
                />
                <FormikSelect
                  isMenuPortalTargetDisabled={true}
                  label="Tone"
                  name="templateTone"
                  value={props.templateToneValue}
                  placeholder={"Select tone "}
                  showBorder={true}
                  selectChange={true}
                  options={templateToneOptions}
                />
              </>
            )}
          </>
        )}
        <>
          {props.activeStep === aiWriterStepsEnum.StartingStep && (
            <>
              <AccordionSimple
                noCaret
                className="mB10"
                variant="no_border"
                header={"PRODUCT/SERVICE INFO"}
                eventKey="service_info"
                headTitleClass="f16 font700"
                defaultOpen
                body={
                  <>
                    {props.hasWebInfoLogs && (
                      <Button id="previousSearches" isDiv onClick={() => props.getAllWebsiteInfoLogs()} classes="mB10 max-x-content">
                        Previous searches
                      </Button>
                    )}
                    {props.showDropdownWebsiteLogs && props.hasWebInfoLogs && (
                      <Select
                        name="websiteLogs"
                        placeholder={"History of previous searches"}
                        options={props.allWebsiteInfoLogs}
                        onChange={(log) => props.changeWebsiteLogHandler(log)}
                      />
                    )}
                    <p className="f14 secondary_text_color mT15">
                      Tell us about your business, and provide a short explanation of your product/service and your target list.
                    </p>
                    <FormikInput
                      labelclass={styles.label}
                      name="companyName"
                      value={props.companyNameValue}
                      inputclass={styles.inputclass}
                      placeholder="e.g. Outbase "
                      label="Company Name"
                    />
                    <FormikInput
                      labelclass={styles.label}
                      name="websiteUrl"
                      value={props.websiteValue}
                      inputclass={styles.inputclass}
                      placeholder="e.g www.outbase.com"
                      label="Website"
                    />
                    {props.showSecondUrl && (
                      <div className="flex">
                        <FormikInput
                          labelclass={styles.label}
                          name={props.additionalWebsiteUrl}
                          inputclass={styles.inputclass}
                          placeholder="e.g www.outbase.com/product"
                          value={props.additionalWebsiteUrlValue}
                        />
                        <Button
                          isDiv
                          id="deleteButtonUrl"
                          variant="rounded-white-m"
                          classes="mL5 relative"
                          onClick={() => handleRemoveForm(forms.length)}
                        >
                          <DeleteBinLineIcon className={styles.binIcon} size={14} />
                        </Button>
                      </div>
                    )}
                    {forms.map((form, index) => (
                      <div key={index}>{form}</div>
                    ))}
                    <div>
                      <p
                        className={`f12 ${props.formCount >= 1 ? styles.hideLabel : "secondary_text_color cursor_pointer"}`}
                        onClick={() => {
                          props.handleAddForm();
                        }}
                      >
                        + Add URL
                      </p>
                    </div>
                    <Button
                      isDiv
                      id="search"
                      variant="secondary"
                      classes="width_100 m0"
                      onClick={(e) => {
                        props.checkWebsiteInfoSummary(props.companyNameValue);
                        props.setGeneratedFields(false);
                      }}
                      disabled={props.isSearchDisabled}
                    >
                      Search
                    </Button>
                    <div className={styles.border} />
                  </>
                }
              />

              {props.generatedField ? (
                <>
                  {props.showErrorMessage && <p className="f14 secondary_text_color">{}</p>}
                  <>
                    <p className="f14 secondary_text_color">
                      {props.showErrorMessage
                        ? props.errorMessageText
                        : "The generated description for your product/service and the unique selling propositions can be amended."}
                    </p>

                    <FormikInput
                      inputclass={styles.descriptionArea}
                      label="Description:"
                      name="description"
                      type="textarea"
                      placeholder=""
                      value={props.valueDescription}
                    />
                    <FormikInput
                      inputclass={styles.descriptionArea}
                      label="What is unique about your product or service?"
                      name="USP"
                      type="textarea"
                      placeholder=""
                      value={props.valueAbout}
                    />
                    <FormikInput
                      inputclass={styles.descriptionArea}
                      label="What is the main problem you solve for your customers?"
                      name="painPoint"
                      type="textarea"
                      placeholder=""
                      value={props.valuePainPoint}
                    />
                  </>
                </>
              ) : (
                <Spinner
                  spinnerClass={styles.spinner}
                  size="1.25rem"
                  descriptionClass={styles.descriptionText}
                  description="Sit back and relax as our cutting-edge AI meticulously crafts your captivating website description and creates your compelling unique selling points. This may take up to 30-45 seconds to ensure perfection."
                />
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default InitialEmailType;
