import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { DTSort } from "../models/dataTables/DtSort";
import { DTFilter } from "../models/dataTables/DtFilter";
import { DTParametersWithPaging } from "../models/dataTables/DtParametersWithPaging";
import { DTResult } from "../models/dataTables/DtResult";
import { DtFilterValueType } from "@models/dataTables/DtFilterValueType";

export interface DataTableState<T> {
  params: DTParametersWithPaging;
  isInitiated: boolean;
  selectMultiRow: T[];
  selectedRow: T | null;
  totalCount: number;
  pageCount: number;
  rows: T[];
}

export interface DataTableActions {
  changePage: (page: number) => PayloadAction<number>;
  sortData: (sort: DTSort) => PayloadAction<DTSort>;
  setItemsPerPage: (num: number) => PayloadAction<number>;
  searchRecords: (search: string) => PayloadAction<string>;
  setFilter: (filter: {
    type: string;
    value: DtFilterValueType | DtFilterValueType[] | null;
  }) => PayloadAction<{ type: string; value: DtFilterValueType | DtFilterValueType[] }>;
  clearFilter: (type: string) => PayloadAction<string>;
  initiate: (params: Partial<DTParametersWithPaging>) => PayloadAction<Partial<DTParametersWithPaging>>;
  clearInitiated: () => PayloadAction<void>;
}

const initialState = <T>(): DataTableState<T> => ({
  params: {
    start: 0,
    length: 25,
    sort: null,
    filter: [],
    search: "",
    page: 0,
  },
  rows: [] as T[],
  isInitiated: false,
  selectMultiRow: [],
  selectedRow: null,
  totalCount: 0,
  pageCount: 0,
});

const createDataTableSlice = <T>(name: string, dataQuery?: any) => {
  const dataTableSlice = createSlice({
    name,
    initialState: initialState<T>(),
    reducers: {
      changePage: (state, action: PayloadAction<number>) => {
        state.params.page = action.payload;
        state.params.start = action.payload * state.params.length;
      },
      sortData: (state, action: PayloadAction<DTSort>) => {
        state.params.sort = action.payload;
      },
      setItemsPerPage: (state, action: PayloadAction<number>) => {
        state.params.length = action.payload;
        state.params.start = 0;
      },
      searchRecords: (state, action: PayloadAction<string>) => {
        state.params.search = action.payload;
        state.params.page = 0;
        state.params.start = 0;
      },
      setFilter: (state, action: PayloadAction<{ type: string; value: DtFilterValueType | DtFilterValueType[] }>) => {
        if (!state.params.filter) {
          state.params.filter = [];
        }

        // Remove the filter if it already exists
        state.params.filter = state.params.filter.filter((f) => f.type !== action.payload.type);

        // If filter value is not an array, cast it into an array
        // This enables to call setFilter with a single value, without the need to manually cast it into an array
        if (!Array.isArray(action.payload.value)) {
          action.payload.value = [action.payload.value];
        }
        state.params.filter.push({ type: action.payload.type, value: action.payload.value });

        state.params.page = 0;
        state.params.start = 0;
      },
      clearFilter: (state, action: PayloadAction<string>) => {
        state.params.filter = state.params.filter.filter((f) => f.type !== action.payload);
        state.params.page = 0;
        state.params.start = 0;
      },
      clearInitiated: (state) => {
        const newState = initialState<T>();
        Object.assign(state, newState);
      },
      initiate: (state, action: PayloadAction<Partial<DTParametersWithPaging>>) => {
        state.params = { ...state.params, ...action.payload };
        state.isInitiated = true;
      },
    },
    extraReducers: (builder) => {
      if (dataQuery) {
        builder.addMatcher(dataQuery.matchFulfilled, (state, action: PayloadAction<DTResult<T>>) => {
          state.rows = action.payload.data as Draft<T>[];
          state.totalCount = action.payload.totalCount;
          state.pageCount = Math.ceil(action.payload.totalCount / state.params.length);
        });
      }
    },
  });

  const actions: DataTableActions = dataTableSlice.actions;

  return {
    ...dataTableSlice,
    actions,
    reducer: dataTableSlice.reducer,
  };
};

export default createDataTableSlice;
