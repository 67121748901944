import React from "react";

//Helpers
import { couponTypeEnum } from "../../../../helper/enums/couponEnum";

//Styles
import styles from "./SummaryCard.module.scss";

const SummaryCard = (props) => {
  const { coupon, couponDiscountedFullPrice, couponDiscountedPrice, discountPrice, totalPrice, currencySign, typeOfBilling } = props;
  return (
    <div className={`${styles.cardWrapper} ${props.className}`}>
      <div className={styles.cardBody}>
        <ul className={styles.cardItemsList}>
          <li>
            {props.createBillingInfo ? (
              <>
                <p className={`${styles.data} ${styles.accent}`}>{props.currentPlan}</p>{" "}
              </>
            ) : (
              <>
                <p className={styles.title}>Plan</p>
                <p className={`${styles.data} ${styles.accent}`}>{props.currentPlan}</p>
              </>
            )}
          </li>
          {props.createBillingInfo ? null : (
            <>
              <li>
                <p>Users</p>
                <p className={styles.data}>{props.users === 0 ? "Unlimited" : props.users}</p>
              </li>
              <li>
                <p>Credits</p>
                <p className={styles.data}>{props.credits}</p>
              </li>
            </>
          )}
          {props.createBillingInfo ? (
            <li>
              <>
                <p>{props.purchaseProduct != null ? props.purchaseProduct : "Price:"}</p>
                <p className={styles.data}>
                  {props.currencySign} {props.totalPrice}{" "}
                </p>
              </>
            </li>
          ) : (
            <li className={`${styles.monthlyPrice} ${styles.couponApplied}`}>
              {props.coupon?.isValid ? <span className={styles.discountPercentage}>{props.coupon?.discountText}</span> : null}
              <span className={styles.newPrice}>
                {currencySign}{" "}
                {coupon != null
                  ? couponDiscountedFullPrice != null && couponDiscountedPrice != null
                    ? typeOfBilling
                      ? couponDiscountedFullPrice
                      : couponDiscountedPrice
                    : discountPrice
                  : totalPrice}
              </span>
              {coupon != null && coupon?.type !== couponTypeEnum.multiplicator ? (
                <span className={styles.oldPrice}>
                  {currencySign} {totalPrice} / {typeOfBilling ? "Upfront" : "Mo."}
                </span>
              ) : null}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SummaryCard;
