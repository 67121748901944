import React, { useState } from "react";

// API
import {
  useApplyOptimizationMutation,
  useAskForHelpOptimizationMutation,
  useDismissOptimizationMutation,
  useGetCompletedForCampaignQuery,
} from "@api/optimizationApi";

// Icons
import HistoryFillIcon from "remixicon-react/HistoryFillIcon";

// Styles
import styles from "./PastReccomendations.module.scss";

// Components
import StatusCard from "@ui/StatusCard/StatusCard";
import Spinner from "@ui/Spinner/Spinner";
import { OptimizationStatusEnum } from "@models/campaign/OptimizationStatusEnum";

interface PastRecommendationsProps {
  campaignId: number;
  filterStatus?: OptimizationStatusEnum;
}

const PastRecommendations: React.FC<PastRecommendationsProps> = ({ campaignId, filterStatus }) => {
  const [show, setShow] = useState(false);
  const [dismiss] = useDismissOptimizationMutation();
  const [apply] = useApplyOptimizationMutation();
  const [askForHelp] = useAskForHelpOptimizationMutation();

  const {
    data: pastRecommendations,
    isLoading,
    isError,
    error,
  } = useGetCompletedForCampaignQuery({
    campaignId,
    category: null,
    priority: null,
  });

  if (isError) {
    console.error("Error loading past recommendations:", error);
    return <div>Error loading past recommendations</div>;
  }

  const filteredRecommendations = filterStatus
    ? pastRecommendations?.filter((recommendation) => recommendation.status === filterStatus)
    : pastRecommendations;

  return (
    <>
      {isLoading && <Spinner />}

      {filteredRecommendations && filteredRecommendations.length > 0 && (
        <p className={styles.pastRecommendations} onClick={() => setShow(!show)}>
          <HistoryFillIcon size={18} color="var(--secondary-color-500)" />
          <span className={styles.title}>{show ? "Hide Past Recommendations" : "Show Past Recommendations"}</span>
        </p>
      )}

      {show && filteredRecommendations && (
        <div className={styles.statusCardsWrapper}>
          {filteredRecommendations.map((recommendation) => (
            <StatusCard
              key={recommendation.id}
              model={recommendation}
              text={recommendation.title}
              onMarkAsDoneClick={() => apply(recommendation.id)}
              onHelpMeClick={() => askForHelp(recommendation.id)}
              onDismissClick={() => dismiss(recommendation.id)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default PastRecommendations;
