import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";

const TestVariablesStep = (props) => {
  const { basicVariables, customVariables } = props;

  return (
    <div>
      <Container>
        {!_.isEmpty(basicVariables) ? (
          <Row>
            <Col xl={12}>
              {basicVariables.map((variable, index) => {
                return (
                  <>
                    <label className="f12 mB5">{variable}</label>
                    <FormikInput
                      value={props.dictionaryValues[variable]}
                      placeholder={variable}
                      type="text"
                      name={"dictionary[" + variable + "]"}
                    />
                  </>
                );
              })}
            </Col>
          </Row>
        ) : null}
        {!_.isEmpty(customVariables) ? (
          <Row>
            <Col xl={12}>
              {customVariables.map((variable, index) => {
                return (
                  <>
                    <label className="f12 mB5">{variable}</label>
                    <FormikInput
                      value={props.dictionaryValues[variable]}
                      placeholder={variable}
                      type="text"
                      name={"dictionary[" + variable + "]"}
                    />
                  </>
                );
              })}
            </Col>
          </Row>
        ) : null}
      </Container>
    </div>
  );
};

export default TestVariablesStep;
