import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  emailTemplate: {},
  listEmailTemplateTypes: [],
  emailTemplateVariableTypes: [],
  organizationCustomColumns: [],
  dictionary: {},
  initialEmailTemplates: null,
};

//Email Template
const fetchEmailTemplateSuccess = (state, action) => {
  const newState = { ...state };
  let emailTemplate = { ...state.emailTemplate };
  emailTemplate = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.emailTemplate = emailTemplate;
  return newState;
};

//Email Template Types
const fetchEmailTemplateTypesRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchEmailTemplateTypesSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listEmailTemplateTypes };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listEmailTemplateTypes = list;
  return newState;
};

const fetchEmailTemplateTypesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

//Organization Custom Columns
const fetchOrganizationCustomColumnsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchOrganizationCustomColumnsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.organizationCustomColumns };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.organizationCustomColumns = list;
  return newState;
};

const fetchOrganizationCustomColumnsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const setDictionaryValues = (state, action) => {
  const newState = { ...state };
  let dictionary = { ...state.dictionary };
  dictionary = action.result;
  newState.dictionary = dictionary;
  return newState;
};

//Initial Email Templates
const fetchInitialEmailTemplatesRequest = (state, action) => {
  return {
    ...state,
    initialEmailTemplates: null,
  };
};

const fetchInitialEmailTemplatesSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.initialEmailTemplates };
  list = action.result;

  newState.initialEmailTemplates = list;
  return newState;
};

const fetchInitialEmailTemplatesFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.initialEmailTemplates = null;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        emailTemplate: null,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_EMAIL_TEMPLATE_SUCCEEDED:
      return fetchEmailTemplateSuccess(state, action);
    case actionTypes.FETCH_EMAIL_TEMPLATE_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        emailTemplate: null,
        error: action.result,
      };
    case actionTypes.FETCH_EMAIL_TEMPLATE_TYPES_REQUEST:
      return fetchEmailTemplateTypesRequest(state, action);
    case actionTypes.FETCH_EMAIL_TEMPLATE_TYPES_SUCCEEDED:
      return fetchEmailTemplateTypesSuccess(state, action);
    case actionTypes.FETCH_EMAIL_TEMPLATE_TYPES_FAILED:
      return fetchEmailTemplateTypesFailed(state, action);
    case actionTypes.FETCH_ORGANIZATION_CUSTOM_COLUMNS_REQUEST:
      return fetchOrganizationCustomColumnsRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_CUSTOM_COLUMNS_SUCCEEDED:
      return fetchOrganizationCustomColumnsSuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_CUSTOM_COLUMNS_FAILED:
      return fetchOrganizationCustomColumnsFailed(state, action);
    case actionTypes.SET_DICTIONARY_VALUES:
      return setDictionaryValues(state, action);
    case actionTypes.FETCH_INITIAL_EMAIL_TEMPLATES_REQUEST:
      return fetchInitialEmailTemplatesRequest(state, action);
    case actionTypes.FETCH_INITIAL_EMAIL_TEMPLATES_SUCCEEDED:
      return fetchInitialEmailTemplatesSuccess(state, action);
    case actionTypes.FETCH_INITIAL_EMAIL_TEMPLATES_FAILED:
      return fetchInitialEmailTemplatesFailed(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
