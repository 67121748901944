import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

//Redux
import { connect } from "react-redux";
import { fetchBillingInfo, fetchPricingPlans, fetchCreditInfo } from "../../../store/BillingAndPayment/actions/billingAndPayment";
import { fetchSettings } from "../../../store/OrganizationSettings/General/actions/general";

//Helpers
import {
  filterPlans,
  pricingPlanEnum,
  currencySign,
  fullFormat,
  currencyName,
  valuesPricingPlan,
  pricingPlanVariant,
  pricingPlanInfoDetails,
} from "../../../helper/pricingPlanHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";
import { termsAndPaymentStatusEnum } from "../../../helper/enums/contractStatusEnum";
import { couponAppliesToEnum } from "../../../helper/enums/couponEnum";
import { useQuery } from "../../../helper/hooks/useQuery";
import api, { API } from "../../../helper/api/api";
import { errorResponseToList } from "../../../helper/errorHelper";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";

//Styles
import styles from "./PricingTable.module.scss";

//Components
import GetStartedForm from "../components/GetStartedForm/GetStartedForm";
import PricingCard from "../PricingCard/PricingCard";
import PlanNotPermittedModal from "../components/PlanNotPermittedModal/PlanNotPermittedModal";
import EnterPrise from "../components/EnterPrise/EnterPrise";
import BillingInfoModal from "../components/BillingInfoModal/BillingInfoModal";
import CouponField from "../components/CouponField/CouponField";
import OrderSummaryModal from "../components/OrderSummaryModal/OrderSummaryModal";
import CurrencyTextLink from "../components/CurrencyTextLink/CurrencyTextLink";
import NotificationUpdatePayment from "../../OrganizationSettings/components/BillingAndPayment/NotificationUpdatePayment/NotificationUpdatePayment";

const PricingTable = (props) => {
  const {
    fetchBillingInfo,
    billingInfo,
    fetchPricingPlans,
    currencyId,
    pricingPlans,
    period,
    typeOfBilling,
    pricingPlanIsLoaded,
    creditCardInfo,
    fetchSettings,
    fetchCreditInfo,
    creditInfo,
    contractStatus,
    isCouponUsed,
    setCheck,
    setActiveTab,
    setValidCouponEntered,
    inValidPayment,
  } = props;

  const organizationId = useOrganizationId();

  const { monthlyCredits, currentPlanIsBillingMonthly, plan, startingPricingPlanCredits } = creditInfo;

  const [currentPricePlan, setCurrentPricePlan] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [pricePointId, setPricePointId] = useState(null);
  const [planChargifyHandle, setPlanChargifyHandle] = useState(null);
  const [pricePointChargifyHandle, setPricePointChargifyHandle] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [price, setPrice] = useState(null);
  const [credits, setCredits] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState(null);
  const [users, setUsers] = useState(null);
  const [fullPrice, setFullPrice] = useState(null);
  const [oldPrice, setOldPrice] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [couponDiscountedPrice, setCouponDiscountedPrice] = useState(null);
  const [couponDiscountedFullPrice, setCouponDiscountedFullPrice] = useState(null);
  const [hasRedirect, setHasRedirect] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    fetchCreditInfo();
    fetchBillingInfo();
    fetchSettings();
  }, []);

  let query = useQuery();
  if (query.get("redirect") != null) {
    if (!hasRedirect) setHasRedirect(true);
  }
  let separators = useGroupDecimalSeparator();

  useEffect(() => {
    if (hasRedirect && creditInfo?.plan != null) {
      api(API.billingAndPayment.getRegisteredUserParams)
        .then((res) => {
          let param = res.data;

          if (param.coupon.isValid) setCoupon(param.coupon);

          handleAddCreditCardShow(
            param.planId,
            param.pricePointId,
            param.chargifyHandle,
            param.pricePointChargifyHandle,
            param.pricingPlanEnum,
            fullFormat(param.price, separators),
            param.credits,
            param.billingPeriod,
            param.users,
            fullFormat(param.fullPrice, separators),
            fullFormat(param.oldPrice, separators),
            fullFormat(param.couponDiscountedPrice, separators),
            fullFormat(param.couponDiscountedFullPrice, separators)
          );

          setCheck(param.typeOfBilling);
          setActiveTab(param.typeOfBilling ? 1 : 0);
        })
        .catch((error) => {
          const errorList = errorResponseToList(error);
          if (errorList.length === 0) {
            toast.error(error.message);
          }
        });
    }
  }, [creditInfo]);

  useEffect(() => {
    fetchPricingPlans(currencyId);
  }, [currencyId]);

  useEffect(() => {
    if (pricingPlanIsLoaded) {
      if (coupon?.isValid) setValidCouponEntered(true);
      else if (coupon == null) setValidCouponEntered(false);

      let setPricePlan = filterPlans(period, typeOfBilling, currencyId, pricingPlans, separators, coupon);
      if (!setPricePlan) {
        // toast.warn(toastMessages.billing.warnNoPlansForSelectedPeriod);
        return;
      }

      setCurrentPricePlan(setPricePlan);
    }
  }, [period, typeOfBilling, pricingPlans, pricingPlanIsLoaded, coupon]);

  const [planNotPermittedModal, setPlanNotPermittedModal] = useState(false);
  const handlePlanNotPermittedModalClose = () => {
    setPlanNotPermittedModal(false);
  };

  const [showAddCreditCardModal, setShowAddCreditCardModal] = useState(false);
  const handleAddCreditCardModalClose = () => {
    setHasRedirect(false);
    fetchBillingInfo();
    fetchCreditInfo();
    fetchPricingPlans(currencyId);
    setShowAddCreditCardModal(false);
  };

  const [showBillingInfoModal, setShowBillingInfoModal] = useState(false);
  const handleBillingInfoModalClose = () => {
    setShowBillingInfoModal(false);
  };

  const [showOrderSummaryModal, setShowOrderSummaryModal] = useState(false);
  const handleOrderSummaryModalClose = () => {
    setShowOrderSummaryModal(false);
    setTermsAccepted(false);
    setHasRedirect(false);
    if (query.has("redirect")) {
      query.delete("redirect");
      props.history.replace({
        search: query.toString(),
      });
    }
  };

  const handleOrderSummaryConfirm = () => {
    handleOrderSummaryModalClose();

    setShowAddCreditCardModal(true);
  };

  const handleAddCreditCardShow = (
    planId,
    pricePointId,
    planChargifyHandle,
    pricePointChargifyHandle,
    currentPlan,
    price,
    credits,
    billingPeriod,
    users,
    fullPrice,
    oldPrice,
    couponDiscountedPrice,
    couponDiscountedFullPrice
  ) => {
    setPlanId(planId);
    setPricePointId(pricePointId);
    setPlanChargifyHandle(planChargifyHandle);
    setPricePointChargifyHandle(pricePointChargifyHandle);
    setCurrentPlan(currentPlan);
    setPrice(price);
    setCredits(credits);
    setBillingPeriod(billingPeriod);
    if (users === 0) setUsers("Unlimited");
    else setUsers(users);
    setFullPrice(fullPrice);
    setOldPrice(oldPrice);
    setCouponDiscountedPrice(couponDiscountedPrice);
    setCouponDiscountedFullPrice(couponDiscountedFullPrice);

    if (contractStatus !== "" && contractStatus === termsAndPaymentStatusEnum.notAccepted) {
      setShowOrderSummaryModal(true);
      return;
    }

    if (contractStatus !== "") {
      setShowAddCreditCardModal(true);
    }
  };

  return (
    <>
      {currentPricePlan ? (
        <>
          <CurrencyTextLink organizationId={organizationId} currency={currencyName[currencyId]} />
          {inValidPayment ? <NotificationUpdatePayment orgId={organizationId} /> : null}
          <div className={styles.pricingCardsWrapper}>
            {currentPricePlan.map((plan, i) => {
              return (
                <PricingCard
                  variant={pricingPlanVariant[i]}
                  cardTitle={valuesPricingPlan[i]}
                  infoDetail={pricingPlanInfoDetails[i]}
                  currencySign={currencySign[currencyId]}
                  oldPrice={plan?.oldPrice}
                  pricePlanStatus={plan?.status}
                  currentPrice={plan?.price}
                  prospects={plan?.credits}
                  emails="Unlimited"
                  users={plan?.users}
                  billingPeriod={plan?.billingPeriod}
                  couponDiscountedPrice={plan?.couponDiscountedPrice}
                  couponDiscountedFullPrice={plan?.couponDiscountedFullPrice}
                  fullPrice={plan?.fullPrice}
                  isExpired={plan?.isExpired}
                  coupon={coupon}
                  key={valuesPricingPlan[i]}
                  click={() =>
                    handleAddCreditCardShow(
                      plan.planId,
                      plan.pricePointId,
                      plan.chargifyHandle,
                      plan.pricePointChargifyHandle,
                      valuesPricingPlan[i],
                      plan.price,
                      plan.credits,
                      plan.billingPeriod,
                      plan.users,
                      plan.fullPrice,
                      plan.oldPrice,
                      plan.couponDiscountedPrice,
                      plan.couponDiscountedFullPrice
                    )
                  }
                />
              );
            })}
          </div>
        </>
      ) : null}
      {isCouponUsed ? null : (
        <>
          <CouponField
            setCoupon={setCoupon}
            coupon={coupon}
            appliesTo={couponAppliesToEnum.subscription}
            period={period}
            typeOfBilling={typeOfBilling}
            isFromOrderSummary={false}
            hasRedirect={hasRedirect}
            showModal={showOrderSummaryModal}
          />
        </>
      )}
      <div>
        <EnterPrise />
      </div>
      <OrderSummaryModal
        showModal={showOrderSummaryModal}
        handleConfirm={handleOrderSummaryConfirm}
        handleClose={handleOrderSummaryModalClose}
        setCoupon={setCoupon}
        coupon={coupon}
        areTermsAccepted={areTermsAccepted}
        setAreTermsAccepted={setAreTermsAccepted}
        currentPlan={currentPlan}
        license="Free"
        credits={credits}
        users={users}
        totalPrice={typeOfBilling ? fullPrice : price}
        currencySign={currencySign[currencyId]}
        oldPrice={oldPrice}
        typeOfBilling={typeOfBilling}
        period={period}
        couponDiscountedPrice={couponDiscountedPrice}
        couponDiscountedFullPrice={couponDiscountedFullPrice}
        hasRedirect={hasRedirect}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
      />
      <BillingInfoModal
        {...props}
        showModal={showBillingInfoModal}
        handleClose={handleBillingInfoModalClose}
        billingInfo={billingInfo}
        planId={planId}
        pricePointId={pricePointId}
        planChargifyHandle={planChargifyHandle}
        pricePointChargifyHandle={pricePointChargifyHandle}
        billingPeriod={billingPeriod}
        orgId={props.orgId}
        currentPlan={currentPlan}
        credits={credits}
        typeOfBilling={typeOfBilling}
        price={price}
        fullPrice={fullPrice}
        period={period}
        users={users}
        currencySign={currencySign[currencyId]}
        coupon={coupon}
        couponDiscountedPrice={couponDiscountedPrice}
      />
      <GetStartedForm
        plan={plan}
        {...props}
        handleClose={handleAddCreditCardModalClose}
        showModal={showAddCreditCardModal}
        billingInfo={billingInfo}
        creditCardInfo={creditCardInfo}
        planId={planId}
        pricePointId={pricePointId}
        planChargifyHandle={planChargifyHandle}
        pricePointChargifyHandle={pricePointChargifyHandle}
        currentPlan={currentPlan}
        price={price}
        credits={credits}
        users={users}
        billingPeriod={billingPeriod}
        fullPrice={fullPrice}
        period={period}
        currencySign={currencySign[currencyId]}
        typeOfBilling={typeOfBilling}
        orgId={props.orgId}
        totalPrice={typeOfBilling ? fullPrice : price}
        couponDiscountedFullPrice={couponDiscountedFullPrice}
        coupon={coupon}
        setCoupon={setCoupon}
        isCouponUsed={isCouponUsed}
        appliesTo={couponAppliesToEnum.subscription}
        isFromOrderSummary={false}
        hasRedirect={hasRedirect}
        couponDiscountedPrice={couponDiscountedPrice}
      />
      <PlanNotPermittedModal handleClose={handlePlanNotPermittedModalClose} showModal={planNotPermittedModal} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationName: state.user.organization.name,
    billingInfo: state.billingAndPayment.billingInfo,
    creditCardInfo: state.billingAndPayment.creditCardInfo,
    currencyId: state.billingAndPayment.creditInfo.currencyId,
    pricingPlans: state.billingAndPayment.pricingPlans,
    pricingPlanIsLoaded: state.billingAndPayment.pricingPlanIsLoaded,
    creditInfo: state.billingAndPayment.creditInfo,
    orgId: state.user.organization.id,
    contractStatus: state.billingAndPayment.contractStatus,
    isCouponUsed: state.billingAndPayment.isCouponUsed,
    organizationId: state.user.organization?.id,
    inValidPayment: state.billingAndPayment.inValidPayment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBillingInfo: () => dispatch(fetchBillingInfo()),
    fetchPricingPlans: (val) => dispatch(fetchPricingPlans(val)),
    fetchSettings: () => dispatch(fetchSettings()),
    fetchCreditInfo: () => dispatch(fetchCreditInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingTable);
