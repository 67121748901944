export enum SelectListEnum {
  CampaignStatuses,
  AudienceStatuses,
  CampaignsList,
  UnarchivedCampaignsList,
  EmailAccounts,
  ListAudienceTypes,
  ListEmailTemplateTypes,
  Categories,
  ListOfTags,
  ListEmailTemplateStatus,
}
