export const FETCH_LIST_COMPANIES_REQUEST = "FETCH_LIST_COMPANIES_REQUEST";
export const FETCH_LIST_COMPANIES_SUCCEEDED = "FETCH_LIST_COMPANIES_SUCCEEDED";
export const FETCH_LIST_COMPANIES_FAILED = "FETCH_LIST_COMPANIES_FAILED";

export const FETCH_LIST_COMPANIES_AUTOCOMPLETE_REQUEST =
  "FETCH_LIST_COMPANIES_AUTOCOMPLETE_REQUEST";
export const FETCH_LIST_COMPANIES_AUTOCOMPLETE_SUCCEEDED =
  "FETCH_LIST_COMPANIES_AUTOCOMPLETE_SUCCEEDED";
export const FETCH_LIST_COMPANIES_AUTOCOMPLETE_FAILED =
  "FETCH_LIST_COMPANIES_AUTOCOMPLETE_FAILED";

export const FETCH_STATUS_COUNT_COMPANIES_SUCCEEDED =
  "FETCH_STATUS_COUNT_COMPANIES_SUCCEEDED";
export const FETCH_STATUS_COUNT_COMPANIES_REQUEST =
  "FETCH_STATUS_COUNT_COMPANIES_REQUEST";
export const FETCH_STATUS_COUNT_COMPANIES_FAILED =
  "FETCH_STATUS_COUNT_COMPANIES_FAILED";

export const FETCH_LIST_INDUSTRY_REQUEST = "FETCH_LIST_INDUSTRY_REQUEST";
export const FETCH_LIST_INDUSTRY_SUCCEEDED = "FETCH_LIST_INDUSTRY_SUCCEEDED";
export const FETCH_LIST_INDUSTRY_FAILED = "FETCH_LIST_INDUSTRY_FAILED";

export const FETCH_LIST_SIZE_REQUEST = "FETCH_LIST_SIZE_REQUEST";
export const FETCH_LIST_SIZE_SUCCEEDED = "FETCH_LIST_SIZE_SUCCEEDED";
export const FETCH_LIST_SIZE_FAILED = "FETCH_LIST_SIZE_FAILED";
