import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { addDealNavigatorStagesSuccess } from "../../../../store/DealNavigator/actions/dealNavigator";

//Helpers
import { errorResponseToList } from "../../../../helper/errorHelper";
import api, { API } from "../../../../helper/api/api";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import FormikInput from "../../../../components/UI/Formik/FormikInput";

const AddStageForm = (props) => {
  return (
    <Formik
      initialValues={{
        name: "",
        dealNavigatorId: 0,
        organizationId: props.organizationId,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        api(API.dealNavigator.stages.create, values)
          .then((response) => {
            props.addDealNavigatorStagesSuccess(response.data);
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) =>
                actions.setFieldError(e.field, e.message)
              );
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, isSubmitting } = formikProps;

        values.dealNavigatorId = props.dealNavigatorId;

        return (
          <Modal
            title="Add New Stage"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
            backdrop="static"
          >
            <Form>
              <FormikInput
                label="Name: "
                name="name"
                type="text"
                placeholder="Stage Name"
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDealNavigatorStagesSuccess: (stage) =>
      dispatch(addDealNavigatorStagesSuccess(stage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStageForm);
