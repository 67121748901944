export const findBasicAndCustomVariables = (setBasicVariables, setCustomVariables, variables, emailTemplateVariables) => {
  let tempBasicVariables = [];
  let tempCustomVariables = [];
  let allBasicVariables = [];

  if (emailTemplateVariables !== undefined && emailTemplateVariables.length > 0) {
    emailTemplateVariables.map((type) => {
      type.emailTemplateVariables.map((variable) => {
        allBasicVariables.push(variable.name);
      });
    });
  }

  if (allBasicVariables.length > 0) {
    if (variables !== null) {
      variables.map((variable) => {
        if (allBasicVariables.indexOf(variable) > -1) {
          if (tempBasicVariables.indexOf(variable) < 0) {
            tempBasicVariables.push(variable);
          }
        } else {
          if (variable !== null) {
            tempCustomVariables.push(variable);
          }
        }
      });
    }
  }

  setBasicVariables(tempBasicVariables);
  setCustomVariables(tempCustomVariables);
};
