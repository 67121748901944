import React from "react";

//Components
import DelayedInput from "../../DelayedInput/DelayedInput";

const HeaderSearch = (props) => {
  return (
    <div className={`flex center_flex mR15 ${props.headerSearchClass ?? ""}`}>
      <DelayedInput
        showSearchIcon={true}
        type="text"
        placeholder={props.placeholder || "Search"}
        textChanged={props.textChanged}
        value={props.value}
        searchOnFirstCharacter={props.searchOnFirstCharacter}
        delayedWrapperClass={props.delayedWrapperClass ?? ""}
        ariaLabel="Text"
      />
    </div>
  );
};

export default HeaderSearch;
