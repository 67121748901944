import { FC, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Hooks
import {
  assistantApi,
  useQuitMutation,
  useStartExecutionMutation,
  useSaveDataMutation,
  useLoadDataMutation,
  useCreateMutation,
} from "@api/assistantApi";
import {
  useGetIndustriesQuery,
  useGetCountriesQuery,
  useGetJobTitleDepartmentsQuery,
  useGetJobTitleSenioritiesQuery,
} from "@api/audienceExplorerApi";
import { useSlice } from "@hooks/useSlice";
import useSignalRHook from "@hooks/useSignalRHook";

// Redux
import { QueryStatus } from "@reduxjs/toolkit/query";
import { fetchSettings } from "@store/OrganizationSettings/General/actions/general";
import { clearState, updateCampaignObject } from "@store/Assistant/assistantSlice";

// Helpers
import { SIGNALR_CONSTANTS } from "@helper/signalrConstants";
import * as assistantHelper from "../AssistantHelperMethods";

//Images & Icons
import CombinationMarkLogoDark from "@assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";
import Preparing from "@assets/Images/PreparingImage/Preparing";

// Styles
import styles from "./CreateCampaignAssistant.module.scss";

// Models
import { OrganizationSettingsModel } from "@models/organization/OrganizationSettingsModel";
import { DropdownModel } from "@models/DropDownModel";
import { AssistantExecutionModel, CampaignObjectModel, LocationType, SummaryResponseModel } from "@models/assistant/AssistantModels";
import { CreateCampaignModel } from "@models/assistant/CreateCampaignModel";

// Components
import { default as IndustryStep } from "../../RegisterOrganization/OutbaseAssistant/Components/SelectIndustiresStep/SelectIndustiresStep";
import CompanySizeStep from "../components/CompanySizeStep/CompanySizeStep";
import DepartmentsAndSeniority from "@pages/RegisterOrganization/OutbaseAssistant/Components/DepartmentsAndSeniority/DepartmentsAndSeniority";
import { default as SelectLocationStep } from "../../RegisterOrganization/OutbaseAssistant/Components/SelectCountryLocationStep/SelectCountryLocationStep";
import OrganizationInfoStep from "../../RegisterOrganization/OutbaseAssistant/Components/OrganizationInfoStep/OrganizationInfoStep";
import TemplateToneStep from "../../RegisterOrganization/OutbaseAssistant/Components/TemplateToneStep/TemplateToneStep";
import CampaignSummary from "../../RegisterOrganization/OutbaseAssistant/Components/CampaignSummary/CampaignSummary";
import ConnectAccountStep from "../../RegisterOrganization/OutbaseAssistant/Components/ConnectAccountStep/ConnectAccountStep";

import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import ProgressBar from "../../../../src/components/ProgressBar/ProgressBar";
import LoggedInUser from "../../../components/Header/LoggedInUser/LoggedInUser";
import Button from "../../../components/UI/Button/Button";
import Spinner from "../../../components/UI/Spinner/Spinner";
import DiscardModal from "./DiscardModal/DiscardModal";
import useModal from "@ui/Modal/useModal";

interface CreateCampaignAssistantProps {
  organization: OrganizationSettingsModel;
  user: any;
  history: any;

  fetchSettings: () => void;
}

const CreateCampaignAssistant: FC<CreateCampaignAssistantProps> = ({
  // Props
  organization,
  user,
  history,

  fetchSettings,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { campaignObject, executionId, currentStep, totalSteps } = useSlice((state) => state.assistant);
  const { organizationId } = useParams<any>();

  // Queries
  const { data: listIndustries } = useGetIndustriesQuery();
  const { data: listCountries } = useGetCountriesQuery();
  const { data: listJobTitleSeniorities } = useGetJobTitleSenioritiesQuery();
  const { data: listJobTitleDepartments } = useGetJobTitleDepartmentsQuery();

  // Mutations
  const [startExecution, { status: executionStatus, isError: executionError }] = useStartExecutionMutation();
  const [createCampaign] = useCreateMutation();
  const [quit] = useQuitMutation();
  const [saveData, { status: saveDataStatus }] = useSaveDataMutation();
  const [loadData] = useLoadDataMutation();

  // SignalR
  useSignalRHook(SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_CAMPAIGN_COMPLETED, (data: any) => {
    if (organizationId == data.organizationId && executionId == data.executionId) {
      history.push(`/${data.organizationId}/campaigns/${data.campaignId}/overview`);
    }
  });

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [animate, setAnimate] = useState<boolean>(false);
  const [createSearchKey, setCreateSearchKey] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<string | null>(null);
  const discardModal = useModal();

  useEffect(() => {
    fetchSettings();
    getSummary(organization.domain);

    assistantHelper.checkForEmailProviderType({ email: user.email, setAccountType: setAccountType });
    startExecution({ slug: "create-campaign", startNew: true })
      .unwrap()
      .then((response: AssistantExecutionModel) => {
        loadData({ executionId: response.id, step: response.currentStep });
      });
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        !campaignObject.audience.tags.length ? setDisabledButton(true) : setDisabledButton(false);
        break;
      case 2:
        campaignObject.audience.companySize.length === 0 ? setDisabledButton(true) : setDisabledButton(false);
        break;
      case 3:
        if (campaignObject.settings.locationType === LocationType.Country) {
          campaignObject.audience?.companyLocations?.country?.length === 0 ? setDisabledButton(true) : setDisabledButton(false);
        }
        if (campaignObject.settings.locationType === LocationType.Region) {
          campaignObject.audience?.companyLocations?.region?.length === 0 ? setDisabledButton(true) : setDisabledButton(false);
        }
        if (campaignObject.settings.locationType === LocationType.ZipCode) {
          !campaignObject.audience?.range?.location ? setDisabledButton(true) : setDisabledButton(false);
        }
        break;
      case 4:
        !campaignObject.audience.departments.length || !campaignObject.audience.seniority.length
          ? setDisabledButton(true)
          : setDisabledButton(false);
        break;
      case 5:
        !campaignObject.companyDescription.description ||
        !campaignObject.companyDescription.productInformation ||
        !campaignObject.companyDescription.problemInformation
          ? setDisabledButton(true)
          : setDisabledButton(false);
        break;
      default:
        setDisabledButton(false);
        break;
    }
  }, [campaignObject, currentStep]);

  let loggedInUser: JSX.Element = <LoggedInUser orgPage={true} history={history} />;
  let logo: any = (
    <div className={styles.logoDetails}>
      <CombinationMarkLogoDark width={150} height={37} />
    </div>
  );

  const handleUpdateObjectAction = (value: CampaignObjectModel) => {
    dispatch(updateCampaignObject(value));
  };

  const handleNext = () => {
    if (currentStep <= totalSteps) {
      let data = { ...campaignObject };
      let newAud = { ...data.audience };
      data.audience = newAud;
      saveData({ executionId, step: currentStep, data: data, createSearchKey: currentStep < 6 ? createSearchKey : false })
        .unwrap()
        .then((response: any) => {
          if (response.searchKey) {
            handleUpdateObjectAction({
              ...campaignObject,
              audience: {
                ...campaignObject.audience,
                searchKey: response.searchKey,
              },
            });
          }
          loadData({ executionId, step: currentStep + 1 });
        })
        .catch((error: any) => {
          console.error("error", error);
        });
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      dispatch({ type: "assistant/updateCurrentStep", payload: currentStep - 1 });
      loadData({ executionId, step: currentStep - 1 });
    }
  };

  const handleQuit = () => {
    quit(executionId)
      .unwrap()
      .then(() => {
        history.push(`/${organizationId}/campaigns`);
      });
  };

  const handleCompleteAssistant = () => {
    setCreateSearchKey(false);
    let model: CreateCampaignModel = assistantHelper.createCampaignRequest({
      campaignObject,
      executionId,
      organization,
      user,
    });
    setLoading(true);
    setAnimate(true);
    saveData({ executionId, step: currentStep, data: campaignObject, createSearchKey: false })
      .unwrap()
      .then(() => {
        createCampaign({ ...model, searchKey: model.searchKey || "" })
          .unwrap()
          .then(() => {
            setAnimate(false);
          });
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };

  const getSummary = async (url: string) => {
    const result = await dispatch(assistantApi.endpoints.getSummary.initiate(url));
    let newData: SummaryResponseModel = result.data;
    if (newData) {
      handleUpdateObjectAction({
        ...campaignObject,
        companyDescription: {
          websiteURL: url,
          description: newData?.description,
          productInformation: newData?.productInfo,
          problemInformation: newData?.solutionDescription,
        },
      });
    }
    return newData;
  };

  const formatCountries = (options: DropdownModel[]) => {
    let result = options.map((item) => {
      return {
        label: item.label,
        included: true,
        value: {
          label: item.label,
          included: true,
          value: "10:" + item.value,
        },
      };
    });

    return result;
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return listIndustries ? (
          <IndustryStep
            organizationSettings={campaignObject}
            changeIndustries={handleUpdateObjectAction}
            listIndustries={listIndustries}
            setCreateSearchKey={setCreateSearchKey}
          />
        ) : (
          <Spinner />
        );
      case 2:
        return (
          <CompanySizeStep
            campaignObject={campaignObject}
            setCampaignObject={handleUpdateObjectAction}
            setCreateSearchKey={setCreateSearchKey}
          />
        );
      case 3:
        return listCountries ? (
          <SelectLocationStep
            organizationSettings={campaignObject}
            setCampaignObject={handleUpdateObjectAction}
            listCountries={formatCountries(listCountries)}
            setCreateSearchKey={setCreateSearchKey}
          />
        ) : (
          <Spinner />
        );
      case 4:
        return listJobTitleSeniorities && listJobTitleDepartments ? (
          <DepartmentsAndSeniority
            organizationSettings={campaignObject}
            handleUpdateObjectAction={handleUpdateObjectAction}
            setCreateSearchKey={setCreateSearchKey}
            listJobTitleDepartments={listJobTitleDepartments}
            listJobTitleSeniorities={listJobTitleSeniorities}
            maxOptions={20}
          />
        ) : (
          <Spinner />
        );
      case 5:
        return (
          <OrganizationInfoStep
            organizationSettings={campaignObject}
            setOrganizationSettings={handleUpdateObjectAction}
            getSummary={getSummary}
          />
        );
      case 6:
        return <TemplateToneStep organizationSettings={campaignObject} setOrganizationSettings={handleUpdateObjectAction} />;
      case 7:
        return <CampaignSummary />;
      case 8:
        return (
          <ConnectAccountStep
            accountType={accountType}
            campaignObject={campaignObject}
            setCampaignObject={handleUpdateObjectAction}
            user={user}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  return executionStatus == QueryStatus.fulfilled && !executionError ? (
    <div className={styles.outbaseAssistant}>
      <div className={`width_100 ${styles.navigationMenu}`}>
        <HeaderTitle className="p0 width_100" title={logo} rightContent={loggedInUser} />
      </div>
      <div className={`flex_column ${styles.steps}`}>
        <div>
          {loading ? null : (
            <ProgressBar
              now={currentStep === totalSteps ? 99 : currentStep > 1 ? (currentStep / totalSteps) * 100 : 0}
              max={100}
              min={0}
              showLabel
              labelText={
                currentStep === Math.round(totalSteps / 2)
                  ? "Halfway there..."
                  : currentStep === totalSteps - 1
                  ? "One more step..."
                  : `${currentStep} / ${totalSteps}`
              }
            />
          )}
          {loading ? <Preparing animate={animate} topLabelText="Creating your campaign" /> : renderStepContent()}
        </div>
        {loading ? null : (
          <div className={`flex space_between ${styles.buttonsWrapper}`}>
            <Button
              id={`quitButton${currentStep}`}
              disabled={saveDataStatus === QueryStatus.pending}
              variant="link_button"
              onClick={discardModal.show}
            >
              Cancel
            </Button>
            <div className="flex">
              {currentStep > 1 && currentStep < totalSteps && (
                <Button
                  id={`back${currentStep}`}
                  variant="link_button"
                  onClick={handlePrev}
                  disabled={saveDataStatus === QueryStatus.pending}
                >
                  Go Back
                </Button>
              )}
              <Button
                id={`continue${currentStep}`}
                disabled={disabledButton || saveDataStatus === QueryStatus.pending}
                onClick={currentStep === totalSteps ? () => handleCompleteAssistant() : handleNext}
              >
                {currentStep === totalSteps
                  ? (campaignObject.emailAccounts && campaignObject.emailAccounts.length > 0) || campaignObject.emailAccount
                    ? "Continue"
                    : "Skip and Continue"
                  : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
      <DiscardModal showModal={discardModal.isVisible} handleClose={discardModal.hide} handleSave={handleQuit} />
    </div>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = (state: any) => {
  return {
    organization: state.user.organization,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignAssistant);
