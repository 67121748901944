import React from "react";
import { Link } from "react-router-dom";

//Style
import styles from "./CurrencyTextLink.module.scss";

const CurrencyTextLink = (props) => {
  return (
    <p className={`${styles.currencyTextWrapper} textCenter border-5 `}>
      Sorry we are not accepting new signups or upgrades at this time
      {/* <Link
        className="tableLink primary_text_color"
        to={{
          pathname: "/" + props.organizationId + "/organization-settings/geo-settings",
        }}
      >
        here
      </Link>{" "}
      to update your organization settings. */}
    </p>
  );
};

export default CurrencyTextLink;
