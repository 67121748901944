import React, { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../../../../helper/api/api";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

//Styles
import styles from "./DisableOrganizationModal.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const DisableOrganizationModal = (props) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const disableEnableAccess = () => {
    setSaveButtonDisabled(true);
    let data = { organizationId: props.organizationId, reason: "" };
    api(API.OUTBOSS.organizations.disableEnableOrganizationAccess, data)
      .then((res) => {
        if (props.organizationAccessRemoved) {
          toast.success(toastMessages.outboss.organizations.successEnableAccess);
        } else {
          toast.success(toastMessages.outboss.organizations.successDisableAccess);
        }
        props.fetchOrganizations();
        props.handleClose();
        setSaveButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setSaveButtonDisabled(false);
      });
  };

  return (
    <Modal
      title={props.organizationAccessRemoved ? "Enable Users' Access" : "Disable Users Access"}
      handleClose={props.handleClose}
      handleSave={disableEnableAccess}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      saveButtonDisabled={saveButtonDisabled}
      btnType="submit"
    >
      <>
        <div className={`${styles.contentBody} flex`}>
          <div className={styles.templateWrapperForm}>
            <p className={styles.emailDetails}>
              {props.organizationAccessRemoved
                ? "You will enable access to the organization " + props.organizationName + " for all users. Do you want to continue? "
                : "You will disable the access to organization " +
                  props.organizationName +
                  " for all existing users. Do you want to continue? "}
            </p>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default DisableOrganizationModal;
