export const cancelledSubscriptionReasonEnum = {
  tooExpensive: 0,
  lackOfLeads: 1,
  techIssues: 2,
  lowQualityOfData: 3,
  complianceIssues: 4,
  takingABreak: 5,
  other: 6,
  poorUserExperience: 7,
};
