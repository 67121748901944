import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";

//Helpers
import { useOrgTimeFormat } from "../../../../helper/hooks/useOrgTimeFormat";

//Styles
import styles from "./CalendarDetails.module.scss";

//Images & Icons
import EmptyCampaignList from "../../../../assets/Images/EmptyCampaignList/EmptyCampaignList";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

//Components
import SingleDatePicker from "../../../../components/SingleDatePicker/SingleDatePicker";
import TimePopover from "../../../../components/TimePopover/TimePopover";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";

const CalendarDetails = (props) => {
  const {
    isLoadingDetails,
    campaignId,
    campaignName,
    emailAccounts,
    emailAccountsPerCampaign,
    errors,
    processedItems,
    successfulSends,
    showEventDetails,
    initialEmailsSent,
    followUpEmailsSent,
    eventExist,
    onEventChange,
  } = props;

  const [eventExists, setEventExists] = useState(eventExist);
  const [longDate, setLongDate] = useState(new Date());

  var validDate = moment(props.date).isValid();
  useEffect(() => {
    if (props.date !== null && validDate === true) setLongDate(new Date(props.date));
    else setLongDate(new Date());
  }, [props.date]);

  let timeFormat = useOrgTimeFormat();
  let image = <EmptyCampaignList />;

  const handleEventExistChange = (event) => {
    setEventExists(event);
  };

  return (
    <div className={showEventDetails === true ? styles.showDetails : styles.hideDetails}>
      <div className={styles.details}>
        <div className={styles.headerDetails}>
          <p>Campaign details</p>
          <p className={styles.iconWrapper} onClick={props.onClose}>
            <CloseLineIcon size={16} className="grayIcon" />
          </p>
        </div>
        <div className={styles.campaignDetails}>
          <div className={styles.campaign}>
            <NavLink to={`/${props.organizationId}/campaigns/${props.campaignId}/overview`}>
              <h3 className={styles.campaignName}>{campaignName}</h3>
            </NavLink>

            <p>Showing for: </p>
            <SingleDatePicker
              id="calendarDetailsPicker"
              dateFormat="dd MMM yyyy"
              initialDateEvent={longDate}
              campaignId={campaignId}
              emailAccounts={props.emailAccounts}
              campaignStartTime={props.scheduledStartCampaign}
              timeFormat={timeFormat}
              eventExists={eventExist}
              onEventChange={onEventChange}
              onEventExistChange={handleEventExistChange}
              inputDatePickerClass={styles.dayPickerInput}
            />
          </div>
        </div>
        {eventExists === true ? (
          <div className={styles.campaignDetails}>
            <div className={styles.startEndTime}>
              <TimePopover
                campaignTime={props.scheduledStartCampaign}
                campaignTimeZone={props.campaignTimeZone}
                organizationTime={props.scheduledStartOrganization}
                organizationTimeZone={props.organizationTimeZone}
                timeFormat={timeFormat}
                label="Scheduled start"
                display="organization"
                className={styles.timeInfo}
              />
              <TimePopover
                campaignTime={props.scheduledEndCampaign}
                campaignTimeZone={props.campaignTimeZone}
                organizationTime={props.scheduledEndOrganization}
                organizationTimeZone={props.organizationTimeZone}
                timeFormat={timeFormat}
                label="Scheduled end"
                display="organization"
                className={styles.timeInfo}
              />
            </div>

            {props.firstSendOrganizationDate == null ? null : (
              <div className={styles.startEndTime}>
                <TimePopover
                  campaignTime={props.firstSendCampaignDate}
                  campaignTimeZone={props.campaignTimeZone}
                  organizationTime={props.firstSendOrganizationDate}
                  organizationTimeZone={props.organizationTimeZone}
                  timeFormat={timeFormat}
                  label="First send"
                  display="organization"
                  className={styles.timeInfo}
                />
                <TimePopover
                  campaignTime={props.lastSendCampaignDate}
                  campaignTimeZone={props.campaignTimeZone}
                  organizationTime={props.lastSendOrganizationDate}
                  organizationTimeZone={props.organizationTimeZone}
                  timeFormat={timeFormat}
                  label="Last send"
                  display="organization"
                  className={styles.timeInfo}
                />
              </div>
            )}

            {props.campaignTimeZone != undefined &&
            props.campaignTimeZone.currentOffsetString !== props.organizationTimeZone.currentOffsetString ? (
              <span className={styles.timezoneInfo}>
                Campaign is in different timezone than your organization. Hover over the time to show campaign time.
              </span>
            ) : null}

            {isLoadingDetails ? (
              <div className="mT30 flexCenter">
                <Spinner />
              </div>
            ) : (
              <>
                <div className={styles.info}>
                  <p className={styles.type}>
                    Processed <span>{processedItems > 0 ? processedItems : 0}</span>
                  </p>
                  <p className={styles.type}>
                    Successful sends <span>{successfulSends > 0 ? successfulSends : 0}</span>
                  </p>
                  <p className={styles.subType}>
                    - Initial Emails Sent <span>{initialEmailsSent > 0 ? initialEmailsSent : 0}</span>
                  </p>
                  <p className={styles.subType}>
                    - Follow Up Emails<span>{followUpEmailsSent > 0 ? followUpEmailsSent : 0}</span>
                  </p>
                  <p className={`${styles.type} ${styles.errors}`}>
                    Errors <span>{errors > 0 ? errors : 0}</span>
                  </p>
                  {emailAccounts > 0 ? (
                    <div className="textCenter">
                      <p>
                        Showing stats for {emailAccounts.length}/{emailAccountsPerCampaign} email account(s).
                      </p>
                    </div>
                  ) : null}
                </div>
                {processedItems > 0 ? (
                  <div className="textCenter">
                    <Link
                      className={styles.reportDetailsLink}
                      to={{
                        pathname: "/" + props.organizationId + "/send-reports",
                        search:
                          "?Campaign=" +
                          campaignId +
                          (emailAccounts.length > 0 ? "&EmailAccount=" + emailAccounts : "") +
                          "&Date=" +
                          longDate.toDateString(),
                        aboutProps: {
                          page: 1,
                        },
                      }}
                    >
                      <span>View report details</span>
                    </Link>
                  </div>
                ) : null}
              </>
            )}
          </div>
        ) : (
          <div className={styles.info}>
            <EmptyState image={image} title="There is no scheduled event for this day." />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarDetails;
