import React, { useEffect, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";

//Helpers
import api, { API } from "../../../helper/api/api";
import { errorResponseToList } from "../../../helper/errorHelper";
import useSelectList from "../../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../../helper/hooks/query/SelectListEnum";

//Redux
import { splitEmailTemplateVariables } from "../../../utils/splitEmailTemplateVariables";

//Style
import styles from "./EditEmailTemplatesTabs.module.scss";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import HorizontalStepper from "../../../components/UI/HorizontalStepper/HorizontalStepper";
import TestInfoStep from "./AdvanceEmailDeliverabilityTestModelSteps/TestInfoStep/TestInfoStep";
import TestVariablesStep from "./AdvanceEmailDeliverabilityTestModelSteps/TestVariablesStep/TestVariablesStep";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EmailTemplateViewModel } from "../../../models/emailTemplate/EmailTemplateViewModel";

//Hooks
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

interface AdvanceEmailDeliverabilityTestModelProps {
  emailTemplate: EmailTemplateViewModel;
  templateId: number;
  setDictionaryValues: (dictionary: {[key: string]: string}) => void;
  templateDictionary: { [key: string]: string};
  showModal: boolean;
  handleClose: () => void;
}

interface DictionaryState {
  [key: string]: string;
}


const AdvanceEmailDeliverabilityTestModel : React.FC<AdvanceEmailDeliverabilityTestModelProps> =  ({ emailTemplate, templateId, setDictionaryValues, templateDictionary, showModal, handleClose }) => {
  const initialState: DictionaryState = {};
  const [variables, setVariables] = useState<string[]>([]);  
  const [dictionary, setDictionary] = useState<DictionaryState>(initialState);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Test Info",
    },
    {
      label: "Test Variables",
    },
  ];

  const { data: emailAccountsList } = useSelectList(SelectListEnum.EmailAccounts);
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery();
  
  useEffect(() => {
    if (showModal) {
      let variablesEmailTextTemp = null;
      let variablesEmailSubjectTemp = null;

      if (!_.isEmpty(templateDictionary)) {
        setDictionary(templateDictionary);
      }

      if (!_.isEmpty(emailTemplate.emailTemplateText)) {
        variablesEmailTextTemp = splitEmailTemplateVariables(emailTemplate.emailTemplateText);
      }

      if (!_.isEmpty(emailTemplate.emailTemplateSubject)) {
        variablesEmailSubjectTemp = splitEmailTemplateVariables(emailTemplate.emailTemplateSubject);
      }
      if (variablesEmailTextTemp !== null && variablesEmailSubjectTemp !== null) {
        setVariables(_.concat(variablesEmailTextTemp, variablesEmailSubjectTemp));
      }
    }
  }, [showModal]);

  const setDictionaryValuesForForm = () => {
    if (variables !== null && variables.length > 0) {
      variables.map((variable) => {
        var variableName = variable;
        var result = "";

        if (variableName !== null) {
          if (emailTemplateVariables !== undefined && emailTemplateVariables.length > 0) {
            emailTemplateVariables.map((type) => {
              type.emailTemplateVariables.map((variable) => {
                if (variableName === variable.name) {
                  result = variable.example;
                }
              });
            });

            if (result !== "") {
              dictionary[variableName] = result;
              setDictionaryValues({ variableName: result });
              return result;
            } else {
              dictionary[variableName] = ("{" + variableName + "}");
              setDictionaryValues({ variableName: result });
              return "{" + variableName + "}";
            }
          } else {
            dictionary[variableName] = ("{" + variableName + "}");
            setDictionaryValues({ variableName: result });
            return "{" + variableName + "}";
          }
        }
      });
    }
  };

  useEffect(() => {
    setDictionaryValuesForForm();
  }, [variables]);

  return (
    <Formik
      initialValues={{
        emailTemplateName: emailTemplate.emailTemplateName || "",
        subject: emailTemplate.emailTemplateSubject || "",
        emailAccountId: 0,
        templateId: templateId,//
        dictionary: dictionary,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        emailAccountId: Yup.number().positive("Please choose an email account.").required("Required"),
      })}
      onSubmit={(values, actions) => {
        variables.forEach((variable) => {
          if (!values.dictionary.hasOwnProperty(variable)) values.dictionary[variable] = variable;
        });

        let model = {
          emailAccountId: values.emailAccountId,
          templateId: values.templateId,
          templateText: emailTemplate.emailTemplateText,
          templateHTML: emailTemplate.emailTemplateHtml,
          subject: values.subject,
          dictionaryOfVariable: values.dictionary,
        };

        api(API.emailDeliverabilityTest.sendAdvanceEmailTest, model)
          .then((response) => {
            if (response.data.success) toast.success(response.data.summaryMessage);
            if (!response.data.success) toast.error(response.data.summaryMessage);
            setDictionaryValues(initialState);
            handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
            handleClose();
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, handleChange, values } = formikProps;

        return (
          <Modal
            title={"Deliverability test"}
            handleClose={handleClose}
            handleSave={() => {
              if (activeStep === 0) {
                setActiveStep(activeStep + 1);
              } else {
                setActiveStep(activeStep - 1);
                handleSubmit();
              }
            }}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText={activeStep === 0 ? "Next" : "I'm done"}
            saveButtonDisabled={isSubmitting}
            btnType="submit"
            dialogClassName={styles.configurationTest}
            backdrop={true}
          >
            <Form>
              <HorizontalStepper steps={steps} orientation="horizontal" setActiveStep={setActiveStep} activeStep={activeStep} />

              {activeStep === 0 && (
                <>
                  <TestInfoStep
                    formikProps={formikProps}
                    templateId={values.templateId}
                    onChange={handleChange}
                    emailTemplateName={values.emailTemplateName}
                    optionsListEmailAccounts={emailAccountsList}
                    valueEmailAccountId={values.emailAccountId}
                    valuesSubject={values.subject}
                  />
                </>
              )}
              {activeStep === 1 && (
                <TestVariablesStep
                  formikProps={formikProps}
                  dictionary="dictionary"
                  dictionaryValues={values.dictionary}
                  variables={variables}
                />
              )}
              {isSubmitting ? <Spinner /> : null}
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AdvanceEmailDeliverabilityTestModel;
