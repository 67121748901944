import React from "react";

const Undelivered = (props) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 4.23792L10.0718 11.338L2 4.21594V16H9.0709C9.1719 16.7061 9.3783 17.3783 9.6736 18H1C0.44772 18 0 17.5523 0 17V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V9.2547C19.396 8.8334 18.7224 8.5049 18 8.2899V4.23792ZM17.501 2H2.51146L10.0619 8.662L17.501 2ZM14.7066 17.7076C15.0982 17.895 15.5369 18 16 18C17.6569 18 19 16.6569 19 15C19 14.5369 18.895 14.0982 18.7076 13.7066L14.7066 17.7076ZM13.2924 16.2934L17.2934 12.2924C16.9018 12.105 16.4631 12 16 12C14.3431 12 13 13.3431 13 15C13 15.4631 13.105 15.9018 13.2924 16.2934ZM16 20C13.2386 20 11 17.7614 11 15C11 12.2386 13.2386 10 16 10C18.7614 10 21 12.2386 21 15C21 17.7614 18.7614 20 16 20Z"
      fill="#5C656F"
    />
  </svg>
);

export default Undelivered;
