import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import Moment from "moment";

//Redux
import * as actions from "../../../store/Companies/Table/actions/index";

//Helpers
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";

//Images
import EmptyEmailAccount from "../../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";

//Components
import { columns } from "./columns";
import Table from "../../../components/DataTable/Table";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const CompaniesTable = (props) => {
  let dateFormat = useOrgDateFormat();
  const { param, rows, fetchCompanies, isInitiated } = props;
  const [tableRows, setTableRows] = useState([]);

  let table = null;
  let image = <EmptyEmailAccount />;

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      if (r.dateLastProspected != null && r.dateLastProspected !== "") {
        r.dateLastProspected = Moment(r.dateLastProspected).format(dateFormat);
      } else {
        r.dateLastProspected = "-";
      }

      r.companyName = (
        <OverlayTrigger
          key={r.companyName}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.companyName}`}>
              <Popover.Body>{r.companyName}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + props.orgId + "/company-activity-overview/" + r.id,
              }}
            >
              {r.companyName}
            </Link>
          </div>
        </OverlayTrigger>
      );
      // r.campaignName = (
      //   <OverlayTrigger
      //     key={r.campaignName}
      //     placement={"auto-start"}
      //     overlay={
      //        <Popover id={`tooltip-${r.campaignName}`}>
      //            <Popover.Body>
      //              {r.campaignName}
      //            </Popover.Body>
      //          </Popover>}
      //   >
      //     <div className="text-overflow-hidden">
      //       {r.campaignName}
      //     </div>
      //   </OverlayTrigger>
      // );
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  useEffect(() => {
    if (isInitiated) {
      fetchCompanies(param);
    }
  }, [param, isInitiated]);

  if (props.error) {
    table = <h2>Error getting Companies</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Companies"}
          columns={columns}
          rows={tableRows}
          param={param}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          tableHeight={260}
          variant="small"
        />
      ) : (
        <EmptyState image={image} title="No companies found" />
      );
  }

  return <>{table}</>;
};

const mapStateToProps = (state) => {
  return {
    param: state.companies.params,
    isInitiated: state.companies.isInitiated,
    isLoading: state.companies.isLoading,
    isLoaded: state.companies.isLoaded,
    error: state.companies.error,
    rows: state.companies.rows,
    totalCount: state.companies.totalCount,
    pageCount: state.companies.pageCount,
    listSize: state.globalCompanies.size,
    listIndustry: state.globalCompanies.listIndustry,
    statusCount: state.globalCompanies.statusCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanies: (param) => dispatch(actions.fetchCompanies(param)),
    changePage: (page) => dispatch(actions.changePage(page)),
    sortData: (obj) => dispatch(actions.sortData(obj)),
    numRecords: (obj) => dispatch(actions.numRecords(obj)),
    searchRecords: (value) => dispatch(actions.searchRecords(value)),
    setFilter: (type, value) => dispatch(actions.setFilter(type, value)),
    clearFilter: (type) => dispatch(actions.clearFilter(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
