import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Redux
import { connect } from "react-redux";
import { addUserSuccess, fetchUsers } from "../../store/OrganizationSettings/Users/actions/users";

//Helpers
import api, { API } from "../../helper/api/api";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { isBusinessEmail } from "../../helper/validationConstants";
import { errorResponseToList } from "../../helper/errorHelper";
import { organizationRoleOptions as roles } from "../../helper/enums/organizationRoleEnum";

//Components
import FormikSelect from "../../components/UI/Formik/FormikSelect";
import Modal from "../../components/UI/Modal/Modal";
import FormikInput from "../../components/UI/Formik/FormikInput";

const NewUserForm = (props) => {
  const { userEmails } = props;
  return (
    <Formik
      initialValues={{
        email: "",
        role: "", // added for our select
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .test("isBusinessEmail", "Please use a business email address to invite users", isBusinessEmail)
          .required("Required"),
        role: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        if (props.userEmails.map((email) => email.toLowerCase()).includes(values.email.toLowerCase())) {
          toast.error(toastMessages.users.errorUserExists);
          actions.setSubmitting(false);
        } else {
          api(API.users.create, values)
            .then((response) => {
              toast.success("Successfully added " + values.email + ".");
              props.addUserSuccess(response.data);
              props.handleClose();
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);
              if (errorList.length > 0) {
                errorList.forEach((e) => actions.setFieldError(e.field, e.message));
              } else {
                toast.error(error.message);
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, isSubmitting } = formikProps;

        return (
          <Modal
            title="Add new user(s)"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Add"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form>
              <FormikInput label="Email Address" name="email" type="email" placeholder="john@example.com" />
              <FormikSelect
                label="Role"
                name="role"
                placeholder="Select a role"
                options={roles}
                value={values.role}
                isMenuPortalTargetDisabled={true}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmails: state.organizationSettingsUsers.users.map((user) => user.email),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    addUserSuccess: (user) => dispatch(addUserSuccess(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserForm);
