import React, { useEffect, useState } from "react";
import { useInterval } from "react-use";

interface ElementCycleProps {
  delay?: number;
  isRunning?: boolean;
  endlessCycle?: boolean;
  objects: React.ReactNode[];
}

const ElementCycle: React.FC<ElementCycleProps> = ({ delay = 1000, isRunning: defaultRunning = true, endlessCycle = false, objects }) => {
  const [index, setIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(defaultRunning);

  useEffect(() => {
    if (defaultRunning != null) {
      setIsRunning(defaultRunning);
    }
  }, [defaultRunning]);

  useInterval(
    () => {
      if (index + 1 >= objects.length) {
        if (endlessCycle) {
          setIndex(0);
        } else {
          setIsRunning(false);
        }
      } else {
        setIndex(index + 1);
      }
    },
    isRunning ? delay : null
  );

  return <>{index >= objects.length ? objects[objects.length - 1] : objects[index]}</>;
};

export default ElementCycle;
