import _, { min } from "lodash";
import { DTParametersWithPaging } from "../models/dataTables/DtParametersWithPaging";

export const numberOfRecords = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 150, label: "150" },
];

export const emailServerSecurityDropdown = [
  { value: 1, label: "SSL" },
  { value: 2, label: "TLS" },
  { value: 3, label: "STARTTLS" },
  { value: 0, label: "None" },
];

export const emailConfirmedDropdown = [
  { value: true, label: "YES" },
  { value: false, label: "NO" },
];

export const booleanOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

export const getColumnsForReportTable = (label: string) => {
  let columns = [];

  columns.push({
    label: "",
    // headerClass: "customBorder",
    children: [
      {
        label: label,
        field: "title",
        type: "text",
        showTooltip: true,
        sort: "asc",
        headerClass: "border-left border-right border-top-d border-bottom-d",
        cellClass: "textLeft border-left border-right border-bottom-d",
        minimal: "lg",
        flex: 2,
      },
    ],
  });

  columns.push({
    label: "",
    field: "empty",
    width: 4,
    maxWidth: 4,
    children: [{ headerClass: "border-top-bottom-none", cellClass: "no_background", width: 8 }],
  });

  const childrenCampaignActivity = [
    ...(label !== "Sequence Email"
      ? [
          {
            label: "Engagements",
            field: "engaged",
            type: "number",
            columnClass: "textCenter",
            sort: "asc",
            headerClass: "border-top-d border-left border-bottom-d",
            cellClass: "textRight border-bottom-d border-left",
            wrapHeaderText: true,
            minimal: "lg",
            flex: 1,
            showTooltip: true,
          },
        ]
      : []),
    {
      label: "Emails Sent",
      field: "emailsDelivered",
      type: "number",
      sort: "asc",
      headerClass: `${label === "Sequence Email" ? "border-left border-right" : ""} border-top-d border-bottom-d`,
      cellClass: `${label === "Sequence Email" ? "border-left border-right" : ""} textRight border-bottom-d`,
      wrapHeaderText: true,
      flex: 1,
      showTooltip: true,
    },
    ...(label !== "Sequence Email"
      ? [
          {
            label: "Emails per Prospect",
            field: "emailsPerProspect",
            type: "number",
            sort: "asc",
            headerClass: "border-top-d border-bottom-d border-right",
            cellClass: "textRight border-bottom-d border-right",
            wrapHeaderText: true,
            flex: 1,
            showTooltip: true,
          },
        ]
      : []),
  ];

  columns.push({
    label: "Campaign Activity",
    children: childrenCampaignActivity,
  });

  columns.push({
    label: "",
    field: "empty",
    cellClass: "no_background",
    width: 4,
    maxWidth: 4,
  });

  columns.push({
    label: "Email Activity",
    children: [
      {
        label: "Opened",
        field: "opened",
        type: "number",
        sort: "asc",
        wrapHeaderText: true,
        headerClass: "border-left border-top-d border-bottom-d",
        cellClass: "textRight border-bottom-d border-left",
        minimal: "lg",
        flex: 1,
        showTooltip: true,
      },
      {
        label: "Opened %",
        field: "openRate",
        type: "number",
        sort: "asc",
        headerClass: "border-top-d border-bottom-d",
        cellClass: "textRight border-bottom-d",
        minimal: "lg",
        flex: 1,
        showTooltip: true,
      },
      {
        label: "Clicked",
        field: "clicked",
        type: "number",
        headerClass: "border-top-d border-bottom-d",
        cellClass: "textRight border-bottom-d",
        sort: "asc",
        minimal: "lg",
        flex: 1,
        wrapHeaderText: true,
        showTooltip: true,
      },
      {
        label: "Clicked %",
        field: "clickRate",
        type: "number",
        headerClass: "border-top-d border-bottom-d border-right",
        cellClass: "textRight border-bottom-d border-right",
        sort: "asc",
        minimal: "lg",
        flex: 1,
        showTooltip: true,
      },
    ],
  });

  columns.push({
    label: "",
    field: "empty",
    cellClass: "no_background",
    width: 4,
    maxWidth: 4,
  });

  columns.push({
    label: "Responses",
    children: [
      {
        label: "Decline",
        field: "responsesNegative",
        type: "number",
        sort: "asc",
        headerClass: "danger_bgd",
        cellClass: "textRight border-bottom-d border-left",
        wrapHeaderText: true,
        minimal: "lg",
        flex: 1,
        showTooltip: true,
      },
      {
        label: "Deferred Interest",
        field: "responsesDeferred",
        type: "number",
        sort: "asc",
        headerClass: "warning_bgd",
        cellClass: "textRight border-bottom-d",
        wrapHeaderText: true,
        minimal: "sm",
        flex: 1,
        showTooltip: true,
      },
      {
        label: "Referral",
        field: "responsesReferral",
        type: "number",
        sort: "asc",
        headerClass: "info_bgd",
        cellClass: "textRight border-bottom-d",
        wrapHeaderText: true,
        minimal: "sm",
        flex: 1,
        showTooltip: true,
      },
      {
        label: "Positive Response",
        field: "responsesPositive",
        type: "number",
        sort: "asc",
        headerClass: "right_border success_bgd",
        cellClass: "textRight border-bottom-d border-right",
        wrapHeaderText: true,
        minimal: "sm",
        flex: 1,
        showTooltip: true,
      },
    ],
  });

  columns.push({
    label: "",
    field: "empty",
    cellClass: "no_background",
    width: 4,
    maxWidth: 4,
  });

  const childrenHeadlineRates = [
    {
      label: "Unique Open %",
      field: "openRateByProspect",
      type: "number",
      sort: "asc",
      headerClass: "border-left border-top-d border-bottom-d",
      cellClass: "textRight border-left border-bottom-d",
      wrapHeaderText: true,
      minimal: "sm",
      flex: 1,
      showTooltip: true,
    },
    {
      label: "Unique Click %",
      field: "clickRateByProspect",
      type: "number",
      sort: "asc",
      headerClass: "border-top-d border-bottom-d",
      cellClass: "textRight border-bottom-d",
      minimal: "sm",
      flex: 1,
      wrapHeaderText: true,
      showTooltip: true,
    },
    {
      label: "Leads",
      field: "leads",
      type: "number",
      sort: "asc",
      headerClass: "border-top-d border-bottom-d",
      cellClass: "textRight border-bottom-d",
      wrapHeaderText: true,
      minimal: "sm",
      flex: 1,
      showTooltip: true,
    },
    ...[
      {
        label: "Leads %",
        field: "leadRate",
        type: "number",
        headerClass: "border-top-d border-right border-bottom-d",
        cellClass: "textRight border-bottom-d border-right",
        sort: "asc",
        minimal: "sm",
        flex: 1,
        wrapHeaderText: true,
        showTooltip: true,
      },
    ],
  ];

  columns.push({
    label: "Headline Rates",
    children: childrenHeadlineRates,
  });

  return columns;
};

export const paginationHeight = 250;
export const paginationHeightMedium = 180;
export const paginationHeight220 = 220;
export const paginationHeight300 = 300;

// #region For Dropdowns who have multiSelect options

export const filterData = (type: string, obj: any[], callback: (type: string, values: any[]) => void) => {
  let values: any[] = [];
  if (_.isArray(obj)) {
    if (type === "Date") {
      obj.forEach((e: any, index: number) => {
        values.push(e.from.split("T")[0], e.to.split("T")[0]);
      });
    } else {
      obj.forEach((e: any) => {
        values.push(e);
      });
    }
  } else if (obj !== null) {
    values.push(obj);
  }

  callback(type, values);
};

export const getFilterData = (type: string, obj: any[]): any[] => {
  let values: any[] = [];
  if (_.isArray(obj)) {
    if (type === "Date") {
      obj.forEach((e: { from: string; to: string }) => {
        values.push(e.from.split("T")[0], e.to.split("T")[0]);
      });
    } else {
      obj.forEach((e: any) => {
        values.push(e);
      });
    }
  } else if (obj !== null) {
    values.push(obj);
  }

  return values;
};

export interface Filter {
  type: string;
  value: any;
}

export const getFilterValue = (filters: Filter[], type: string): any => {
  const filter = filters?.find((f: Filter) => f.type === type);
  if (filter != null) return filter.value;

  return null;
};

export const filtersToQueryString = (
  params: DTParametersWithPaging,
  arrExclude: string[] | null,
  query: URLSearchParams | null
): string => {
  const queryParams: string[] = [];

  if (params != null) {
    if (params.filter != null) {
      params.filter.forEach((filter) => {
        if (filter.value.length > 0) {
          if (arrExclude) {
            if (!arrExclude.includes(filter.type)) {
              const value = filter.value.join(",");
              queryParams.push(filter.type + "=" + value);
            }
          } else {
            const value = filter.value.join(",");
            queryParams.push(filter.type + "=" + value);
          }
        }
      });
    }

    if (params.search != null && params.search.length > 0) {
      queryParams.push("q=" + params.search);
    }

    if (params.length != null) {
      queryParams.push("show=" + params.length);
    }

    if (params.page > 0) {
      const userFriendlyPage = params.page + 1;
      queryParams.push("page=" + userFriendlyPage);
    }

    if (query && query.get("tour")) {
      queryParams.push("tour=" + query.get("tour"));
    }
  }

  if (queryParams.length > 0) {
    return "?" + queryParams.join("&");
  }

  return "";
};

// #endregion For Dropdowns who have multiSelect options

//#region For Multiple Boolean Filters, Example: Active:On/Off, Paused: On/Off .......

export type ActiveFilterHandler = (
  type: string,
  status: string,
  params: DTParametersWithPaging,
  setFilter: (type: string, values: string[]) => void,
  clearFilter: (type: string) => void
) => void;

export const activeFilterHandler: ActiveFilterHandler = (type, status, params, setFilter, clearFilter) => {
  let filters: Filter[] = [...params.filter];
  let statuses: string[] = [];

  let exists: Filter | undefined = filters.find((x) => x.type === type);
  if (exists && exists.type !== undefined) {
    statuses = [...exists.value];
    let statusExists = statuses.find((x) => x === status);
    if (statusExists === status) {
      for (let i = 0; i < statuses.length; i++) {
        if (statuses[i] === status) {
          statuses.splice(i, 1);
        }
      }
    } else {
      statuses.push(status);
    }
  } else {
    statuses.push(status);
  }

  if (statuses.length > 0) {
    setFilter(type, statuses);
  } else {
    clearFilter(type);
  }
};

// #endregion For Multiple Boolean Filters, Example: Active:On/Off, Paused: On/Off .......

//#region For Dropdowns who do not have MultiSelect option

export const filterSingle = (type: string, value: any, params: { filter: Filter[] }, callback: (type: string, statuses: any[]) => void) => {
  let statuses: any[] = [];
  let filters: Filter[] = [...params.filter];
  let exists: Filter | undefined = filters.find((x) => x.type === type);

  if (exists && exists.value) {
    if (exists.value[0] !== value) {
      statuses.push(value);
    }
  } else {
    statuses.push(value);
  }
  callback(type, statuses);
};

//#endregion For Dropdowns who do not have MultiSelect option

//#region Style for Dropdowns

export const customStyles = {
  control: (styles: any) => ({
    ...styles,
    background: "#00718E",
    color: "white",
    width: " 240px;",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: "#00718E",
    color: "white",
    padding: 20,
  }),
  placeholder: (styles: any) => ({ ...styles, color: "white" }),
};

export const plainDropdownStyle = {
  control: (styles: any) => ({
    ...styles,
    background: "#ecf3f6",
    color: "#2A3347",
    width: "500px;",
    border: "solid 1px #2A3347",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: "#ecf3f6",
    color: "#2A3347",
    padding: 20,
  }),
  placeholder: (styles: any) => ({ ...styles, color: "#8a8a8a" }),
};

export const plainDropdownStyleShorten = {
  control: (styles: any) => ({
    ...styles,
    background: "#ecf3f6",
    color: "#2A3347",
    width: "250px;",
    border: "solid 1px #2A3347",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: "#ecf3f6",
    color: "#2A3347",
    padding: 20,
  }),
  placeholder: (styles: any) => ({ ...styles, color: "#8a8a8a" }),
};

//#endregion Style for Dropdowns

//#region Style for white Dropdown filter

export const whiteDropdownFilter = {
  control: (styles: any, state: any) => ({
    ...styles,
    color: "#2A3347 !important",
    width: "100%",
    minHeight: "35px",
    maxHeight: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #CACCD1",
    transition: "none",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "2",
    marginRight: "10px",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid #FE7904",
    },
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? "#dbdbdb" : "#fff",
    color: "#2A3347",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    display: "inline-block",
    "&:hover": {
      background: "#FFF8F2",
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,

    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 10px",
    height: "35px",
  }),
  container: (styles: any) => ({
    ...styles,
    width: "100%",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#aaadb5",
    whiteSpace: "nowrap",
    fontWeight: "normal",
    top: "16px",
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: "9999 !important",
  }),
  multiValue: (styles: any) => ({
    ...styles,
    background: "#369af7",
    border: "#369af7",
    color: "#fff",
    borderRadius: "5px",
    height: "22px",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "6px",
    span: {
      fontSize: "12px",
    },
  }),
  input: (styles: any) => ({
    ...styles,
    color: "#2A3347",
    overflow: "hidden",
    minWidth: "100% !important",
    input: {
      background: "transparent",
      paddingLeft: "0!important",
      "&:focus": {
        boxShadow: "none",
        border: "none",
      },
    },
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: "#fff",
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: "#CACCD1",
    "&:hover": {
      color: "#FF5D55",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: "5000",
    boxShadow: "none",
    display: "block",
    flexWrap: "wrap",
  }),
  menuList: (styles: any) => ({
    ...styles,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    border: "white",
    background: "#fff",
    display: "block",
    flexWrap: "wrap",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontSize: "12px",
    color: "#2A3347",
    padding: "15px",
    width: "100% !important",
    display: "flex",
  }),

  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#AAADB5",
    // "&:hover": {
    //   color: "#2A3347",
    // },
  }),
};

//#endregion Style for white Dropdown filter

//#region Style for Single Value Dropdown Filter
export const singleValueDropdownFilter = {
  control: (styles: any, state: any) => ({
    ...styles,
    color: "#AAADB5 !important",
    cursor: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
    width: "100% !important",
    minHeight: "35px",
    maxHeight: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #CACCD1",
    transition: "none",
    boxShadow: "none",
    fontSize: "12px",
    lineHeight: "1",
    // marginRight: "10px",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
      border: "1px solid #FE7904",
    },
  }),

  container: (styles: any, state: any) => ({
    ...styles,
    width: "100%",
    pointerEvents: state.isDisabled ? "auto !important" : null, // cancel mouse events when disabled
    cursor: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? "#dbdbdb" : "#fff",
    color: "#2A3347",
    fontSize: "12px",
    width: "100%",
    display: "inline-block",
    cursor: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
    pointerEvents: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
    // cursor: "pointer",
    "&:hover": {
      background: "#FFF8F2",
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    flexWrap: "nowrap",
    height: "35px",
    display: "flex !important",
    position: "relative",
    top: "-2px",
    padding: "0px !important",
    marginLeft: "0px",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "var(--secondary-color-300)",
    whiteSpace: "nowrap",
    top: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingLeft: "5px",
    minWidth: "100%",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "#2a3347",
    paddingLeft: "5px",
    minWidth: "100%",
    lineHeight: "135%",
  }),
  input: (styles: any) => ({
    ...styles,
    color: "#2A3347",
    overflow: "hidden",
    position: "absolute",
    display: "flex",
    // gridArea: "none",
    // gridTemplateColumns: "none",
    width: "100%",
    margin: "0px",
    input: {
      background: "transparent",
      paddingLeft: "5px !important",
      width: "100% !important",
      minWidth: "100% !important",
      "&:focus": {
        boxShadow: "none",
        border: "none",
        // minWidth: "50px !important",
      },
    },
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: "#CACCD1",
    "&:hover": {
      color: "#FF5D55",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: "2",
    boxShadow: "none",
    display: "block",
    flexWrap: "wrap",
  }),
  menuList: (styles: any) => ({
    ...styles,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    border: "white",
    background: "#fff",
    display: "block",
    flexWrap: "wrap",
    maxHeight: "160px",
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: "9999 !important",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontSize: "12px",
    color: "#AAADB5",
    padding: "15px",
    width: "100% !important",
    display: "flex",
  }),

  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#AAADB5",
    "&:hover": {
      color: "#AAADB5",
    },
  }),
};
//#region Style for Single Value Dropdown Filter

// #region Style for showing per page

export const showingPerPage = {
  control: (styles: any, state: any) => ({
    ...styles,
    background: "transparent",
    color: "#2A3347",
    minHeight: "25px",
    maxHeight: "25px",
    height: "25px",
    border: "none",
    transition: "none",
    boxShadow: "none",
    fontSize: "13px",
    padding: "0 5px",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    paddingLeft: "5px",
    paddingRight: "0",
    fontSize: "12px",
    display: "flex",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    color: "#2A3347",
    fontSize: "13px",
    // cursor: "pointer",
    "&:hover": {
      background: "#FFF8F2",
    },
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#8a8a8a",
    whiteSpace: "nowrap",
  }),
  singleValue: (styles: any) => ({
    color: "#2A3347",
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: "#CACCD1",
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    height: "25px",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#4a5263",
    width: "15px",
    padding: "0",
    "&:hover": {
      color: "#2A3347",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: "5000",
    boxShadow: "none",
    display: "block",
    minWidth: "100%",
    width: "auto",
    flexWrap: "wrap",
  }),
  menuList: (styles: any) => ({
    ...styles,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    border: "white",
    background: "#fff",
    display: "block",
    minWidth: "100%",
    width: "auto",
    zIndex: "4400",
    flexWrap: "wrap",
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontSize: "12px",
    color: "#2A3347",
    padding: "15px",
    width: "100% !important",
    display: "none",
    zIndex: "4",
  }),
};

// #endregion Style for showing per page

//#region Style for Multi Dropdown filter

export const multiDropdown = {
  control: (styles: any, state: any) => ({
    ...styles,
    color: "#8a8a8a !important",
    minWidth: "100%",
    width: "100%",
    minHeight: "35px",
    border: "1px solid #C8CCD1 !important",
    transition: "none",
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "600",
    zIndex: "5001",
    borderRadius: "0",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    marginTop: "-12px",
    background: "#ffff !important",
    paddingTop: "0",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid #FE7904",
      boxShadow: "none",
    },
  }),

  // container: (styles: any) => ({
  //   ...styles,
  //   minWidth: "280px",
  // }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? "#FAFAFA" : "#fff",
    color: "#2A3347",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    display: "flex",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",

    "&:hover": {
      background: "#FFF8F2",
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    flexWrap: "wrap",
    padding: "5px 10px",
    height: "100%",
    width: "100%",
    paddingRight: "25px",
    fontWeight: "normal",
    minWidth: "auto",
    maxHeight: "110px",
    overflowY: "auto",
    overflowX: "hidden",
    color: "var(--secondary-color-500)",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#d6d6d6",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: "35px",
    width: "100%",
  }),
  multiValue: (styles: any) => ({
    ...styles,
    background: "var(--secondary-color-200)",
    border: "1px solid var(--secondary-color-200)",
    color: "var(--secondary-color-400)",
    borderRadius: "2px",
    height: "22px",
    justifyContent: "center",
    alignItems: "center",
    span: {
      fontSize: "12px",
      color: "var(--secondary-color-500)",
    },
  }),
  input: (styles: any) => ({
    ...styles,
    color: "#2A3347",
    overflow: "hidden",
    input: {
      background: "transparent",
      paddingLeft: "0!important",
      minWidth: "50px !important",
      "&:focus": {
        boxShadow: "none",
        border: "none",
      },
    },
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: "var(--secondary-color-500);",
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: "#CACCD1",
    "&:hover": {
      color: "#FF5D55",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    margin: "0",
    zIndex: "5000",
    boxShadow: "none",
    display: "block",
    flexWrap: "wrap",
  }),
  menuList: (styles: any) => ({
    ...styles,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    border: "1px solid #C8CCD1",
    background: "#fff",
    display: "block",
    flexWrap: "wrap",
    marginTop: "-9px",
    paddingTop: "10px",
    maxHeight: "250px",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontSize: "12px",
    color: "#2A3347",
    padding: "15px",
    width: "100% !important",
    display: "flex",
  }),

  indicatorsContainer: (styles: any) => ({
    ...styles,
    display: "none",
  }),

  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#2A3347",
    "&:hover": {
      color: "#2A3347",
    },
  }),
};

//#endregion Style for Multi Dropdown filter

//#region Time picker

export const timePicker = {
  control: (styles: any, state: any) => ({
    ...styles,
    color: "#AAADB5 !important",
    cursor: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
    width: "100% !important",
    minHeight: "35px",
    maxHeight: "35px",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #CACCD1",
    transition: "none",
    boxShadow: "none",
    fontSize: "12px",
    lineHeight: "1",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
      border: "1px solid #FE7904",
    },
  }),

  container: (styles: any, state: any) => ({
    ...styles,
    width: "100%",
    pointerEvents: state.isDisabled ? "auto !important" : null, // cancel mouse events when disabled
    cursor: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isSelected ? "#dbdbdb" : "#fff",
    color: state.isDisabled ? "var(--neutral-color-500)" : "#2A3347",
    fontSize: "12px",
    width: "100%",
    display: "inline-block",
    cursor: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
    pointerEvents: state.isDisabled ? "not-allowed !important" : null, // cancel mouse events when disabled
    "&:hover": {
      background: state.isDisabled ? "var(--white-color)" : "#FFF8F2",
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    flexWrap: "nowrap",
    height: "35px",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "var(--secondary-color-300)",
    whiteSpace: "nowrap",
    top: "16px",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: "#2a3347",
    paddingBottom: "4px",
    overflow: "visible",
  }),
  input: (styles: any) => ({
    ...styles,
    color: "#2A3347",
    overflow: "hidden",
    input: {
      background: "transparent",
      backgroundColor: "transparent !important",

      "&:focus": {
        boxShadow: "none",
        border: "none",
        paddingLeft: "0 !important",
      },
    },
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: "#CACCD1",
    "&:hover": {
      color: "#FF5D55",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: "2",
    boxShadow: "none",
    display: "block",
    flexWrap: "wrap",
  }),
  menuList: (styles: any) => ({
    ...styles,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    border: "white",
    background: "#fff",
    display: "block",
    flexWrap: "wrap",
    maxHeight: "160px",
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: "9999 !important",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    padding: "0",
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontSize: "12px",
    color: "#AAADB5",
    padding: "15px",
    width: "100% !important",
    display: "flex",
  }),

  dropdownIndicator: (base: any) => ({
    ...base,
    padding: "8px 1px",
    "&:hover": {
      color: "#AAADB5",
    },
  }),
};

export const singleSelect = {
  control: (styles: any, state: any) => ({
    ...styles,
    color: "#2A3347 !important",
    borderColor: "var(--border-color)",
    boxShadow: "none",
    minHeight: "34px",
    "&:hover": {
      borderColor: "var(--border-color)",
    },
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    color: "#2A3347",
    fontSize: "12px",
    fontWeight: "600",
    width: "100%",
    display: "inline-block",
    "&:hover": {
      background: "#fff",
    },
  }),
  valueContainer: (styles: any) => ({
    ...styles,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 10px",
    height: "100%",
  }),
  container: (styles: any) => ({
    ...styles,
    width: "100%",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#aaadb5",
    whiteSpace: "nowrap",
    fontWeight: "normal",
    fontSize: "0.75rem",
    top: "16px",
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: "9999 !important",
  }),
  multiValue: (styles: any) => ({
    ...styles,
    background: "#369af7",
    border: "#369af7",
    color: "#fff",
    borderRadius: "5px",
    height: "22px",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "6px",
    span: {
      fontSize: "12px",
    },
  }),
  input: (styles: any) => ({
    ...styles,
    color: "#2A3347",
    overflow: "hidden",
    input: {
      background: "transparent",
      paddingLeft: "0!important",
      "&:focus": {
        boxShadow: "none",
        border: "none",
      },
    },
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: "#fff",
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: "#CACCD1",
    "&:hover": {
      color: "#FF5D55",
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    boxShadow: "none",
    display: "block",
    flexWrap: "wrap",
    marginTop: "4px",
  }),
  menuList: (styles: any) => ({
    ...styles,
    boxShadow: "none",
    borderRadius: "4px",
    border: "1px solid var(--neutral-color-400)",
    background: "#fff",
    display: "block",
    flexWrap: "wrap",
    maxHeight: "250px",
    overflow: "auto",
  }),
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  noOptionsMessage: (styles: any) => ({
    ...styles,
    fontSize: "12px",
    color: "#2A3347",
    padding: "15px",
    width: "100% !important",
    display: "flex",
  }),

  dropdownIndicator: (base: any) => ({
    ...base,
    display: "none",
  }),
};
//#endregion Time Picker
