import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import { toast } from "react-toastify";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { useOrganizationId } from "../../helper/hooks/useOrganizationId";

//Redux
import { fatchAllIntegrations } from "../../store/CRM-Integrations/Global/actions/globalIntegrationActions";
import { fetchHubspotRules } from "../../store/CRM-Integrations/HubSpot/Global/actions/hubspot-integration";

//Styles
import styles from "./CRMSync.module.scss";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import ConnectCRM from "./components/ConnectCRM/ConnectCRM";
import Spinner from "../../components/UI/Spinner/Spinner";
import CRMItemList from "./components/CRMItemList/CRMItemList";
import RulesetTemplates from "./components/RulsetTemplates/RulsetTemplates";
import PredefinedRules from "./components/PredefinedRules/PredefinedRules";
import CreatedRulesList from "./components/CreatedRulesList/CreatedRulesList";
import EditCustomRule from "./components/PredefinedRules/EditCustomRule/EditCustomRule";
import EditHubSpotGlobalMappings from "./components/CRMItemList/EditGlobalMappings/EditHubSpotGlobalMappings";

//Icons
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const CRMSync = (props) => {
  const [hasSetIntegration, setHasSetIntegration] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentIntegrations, setCurrentIntegrations] = useState([]);
  const [rulesetCardsData, setRulesetCardsData] = useState(null);

  const fetchHasIntegration = () => {
    api(API.integrations.getHasSetIntegration)
      .then((response) => {
        let hasIntegration = false;
        hasIntegration = response.data && response.data.length > 0;
        setHasSetIntegration(hasIntegration);
        if (response.data) {
          setCurrentIntegrations(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const fetchPredefinedRulesets = () => {
    api(formUrl(API.integrations.hubspot.getRulesets, { integrationId: 1 }))
      .then((response) => {
        if (response && response.data) {
          setRulesetCardsData(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleRulesetSelected = (selectedRulesetId) => {
    api(
      formUrl(API.integrations.hubspot.createConfigurationFromRuleset, {
        rulesetId: selectedRulesetId,
      })
    )
      .then(() => {
        fetchHubspotRules();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const { fetchHubspotRules, customRules } = props;

  const history = useHistory();

  const [isAddingRule, setIsAddingRule] = useState(false);
  const [isEdittingRule, setIsEdittingRule] = useState(false);
  const [editCustomRuleId, setEditCustomRuleId] = useState(0);
  const [showConnectCrmModal, setShowConnectCrmModal] = useState(false);

  const organizationId = useOrganizationId();

  const handleConnectCrmShowModalClose = () => {
    const qsValues = queryString.parse(props.location.search);
    if (qsValues && qsValues.edit) {
      const pathWithoutTheQuery = `/${organizationId}/crm`;
      history.replace(pathWithoutTheQuery);
      //window.location.replace()
    }

    setShowConnectCrmModal(false);
  };
  const handleConnectCrmShow = () => setShowConnectCrmModal(true);

  useEffect(() => {
    const qsValues = queryString.parse(props.location.search);
    if (qsValues && qsValues.edit) {
      setShowConnectCrmModal(true);
    }
    fetchHasIntegration();
    fetchHubspotRules();
  }, []);

  useEffect(() => {
    if (hasSetIntegration) fetchPredefinedRulesets();
  }, [hasSetIntegration]);

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Integrate Outbase with your CRM to ensure all the data from your campaigns goes directly into it."}
      href={"https://help.outbase.com/user-guide/crm"}
      linkText="Read More"
      buttonId="startCRMTour"
      disableStartTourButton
    />
  );

  // console.error("EDIT", editGlobalMappings);

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title="CRM Integrations" icon={icon} />
      {hasSetIntegration && !loading ? (
        <>
          <div className={styles.CRMWrapper}>
            <div className={styles.crmSystemWrapper}>
              <h2 className={styles.sectionTitle}>CRM System</h2>
              <div className={styles.listWrapper}>
                <CRMItemList handleConnectCrmShow={handleConnectCrmShow} currentIntegrations={currentIntegrations} />
              </div>
            </div>
            <div className={styles.integrationRulesWrapper}>
              {_.isEmpty(customRules) && isAddingRule === false ? (
                rulesetCardsData && rulesetCardsData !== null ? (
                  <RulesetTemplates setIsAddingRule={setIsAddingRule} data={rulesetCardsData} rulesetSelected={handleRulesetSelected} />
                ) : null
              ) : _.isEmpty(customRules) && isAddingRule === true ? (
                <PredefinedRules data={rulesetCardsData.data} />
              ) : !_.isEmpty(customRules) && isAddingRule === false && isEdittingRule === false ? (
                <CreatedRulesList
                  setIsAddingRule={setIsAddingRule}
                  customRules={customRules}
                  setEditCustomRuleId={setEditCustomRuleId}
                  setIsEdittingRule={setIsEdittingRule}
                />
              ) : !_.isEmpty(customRules) && isAddingRule === true && isEdittingRule === false ? (
                <PredefinedRules />
              ) : !_.isEmpty(customRules) && isAddingRule === false && isEdittingRule === true ? (
                <EditCustomRule editCustomRuleId={editCustomRuleId} />
              ) : (
                <Spinner />
              )}
            </div>
          </div>
          {showConnectCrmModal ? (
            <EditHubSpotGlobalMappings
              showModal={showConnectCrmModal}
              handleClose={handleConnectCrmShowModalClose}
              fetchHubspotRules={fetchHubspotRules}
            />
          ) : null}
        </>
      ) : !loading ? (
        <div className={styles.noCRMWrapper}>
          <ConnectCRM />
        </div>
      ) : (
        <Spinner />
      )}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    globalIntegrationsIsLoading: state.globalIntegrations.isLoading,
    globalIntegrationsIsLoaded: state.globalIntegrations.isLoaded,
    globalIntegrationsError: state.globalIntegrations.error,
    globalIntegrations: state.globalIntegrations.integrations,
    hasSetIntegration: state.globalIntegrations.hasSetIntegration,
    customRules: state.hubspotIntegration.customRules,
    hubspotIsLoading: state.hubspotIntegration.isLoading,
    hubspotIsLoaded: state.hubspotIntegration.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fatchAllIntegrations: () => dispatch(fatchAllIntegrations()),
    fetchHubspotRules: () => dispatch(fetchHubspotRules()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMSync);
