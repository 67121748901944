import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";

//Styles
import styles from "./SelectIndustriesStep.module.scss";

//Components
import FormikSelect from "../../../../../components/UI/Formik/FormikSelect";

const SelectIndustriesStep = ({
  title = (
    <>
      Welcome to Outbase AI assistant,
      <br />
      <span className="f16 font500">your partner in building the perfect campaign!</span>
    </>
  ),
  description = (
    <>
      Choose your targeting criteria, and we'll manage the rest. <br /> Start by selecting up to five industries that align with your ideal
      customer profile.
    </>
  ),
  organizationSettings,
  listIndustries,
  changeIndustries,
  setCreateSearchKey,
}) => {
  const formatValues = (values) => {
    let result = [];
    if (values)
      result = values.map((item) => {
        return {
          label: item,
          included: true,
          value: item,
        };
      });
    return result;
  };

  const handleOnChange = (values) => {
    setCreateSearchKey(true);
    let formatedValues = formatValues(values);
    changeIndustries({
      ...organizationSettings,
      audience: {
        ...organizationSettings.audience,
        tags: [...formatedValues],
      },
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        industry: organizationSettings.audience.tags.map((item) => {
          return item.value;
        }),
      }}
    >
      {(formikProps) => {
        const { values } = formikProps;
        const selectedCount = values.industry.length;
        return (
          <Form>
            <div className={`flex_center flexCol ${styles.industries}`}>
              <h1 className="f16 font700 textCenter">{title}</h1>
              <p className="f14 secondary_text_color textCenter">{description}</p>
              <FormikSelect
                isMulti
                isSearchable
                getMenuListStyles
                showValuesOutside
                label="Company industries"
                displayName="Select industry"
                placeholder="e.g. Marketing, Digital"
                name="Industry"
                options={listIndustries}
                value={values.industry}
                onChange={handleOnChange}
                formgroupClass={styles.formIndustry}
                disabled={selectedCount >= 5}
                infoMessage={selectedCount >= 5 ? "You have reached the maximum selection limit" : "You can select up to 5 industries"}
              />
              <p></p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SelectIndustriesStep;
