export const columns = [
  {
    label: "Organization Id",
    field: "organizationId",
    type: "number",
    searchable: true,
    searchType: "number",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Organization",
    field: "organizationName",
    type: "text",
    searchable: true,
    searchType: "text",
    sort: "asc",
    showTooltip: true,
    flex: 1,
  },
  {
    label: "Role",
    field: "roleName",
    type: "text",
    searchable: true,
    searchType: "text",
    sort: "asc",
    showTooltip: true,
    flex: 1,
  },
  {
    label: "Registration Date",
    field: "acceptanceDate",
    type: "date",
    searchable: true,
    searchType: "date",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Logins",
    field: "totalLogins",
    type: "date",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Last Login",
    field: "lastLogin",
    type: "date",
    searchable: true,
    searchType: "date",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    minimal: "sm",
    flex: 1,
    sort: false,
  },
];
