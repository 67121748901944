import React, { FC } from "react";
import InfoIconTooltip from "../CustomTooltip/InfoIconTooltip";

// Styles
import styles from "./LabelWithTooltip.module.scss";

interface LabelWithTooltipProps {
  label: string;
  iconSize?: number;
  tooltipBody: string;
  tooltipClass?: string;
}

const LabelWithTooltip: FC<LabelWithTooltipProps> = ({ label, iconSize = 20, tooltipBody, tooltipClass }) => {
  return (
    <div className="flex">
      <p className="m0">{label}</p>
      <InfoIconTooltip
        iconSize={iconSize}
        tooltipBody={tooltipBody}
        disableStartTour={true}
        tooltipClass={tooltipClass || styles.tooltipBody}
      />
    </div>
  );
};

export default LabelWithTooltip;
