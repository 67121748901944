import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

//Redux

//Helpers
import { permissionHelper } from "../../helper/permissionHelper";
import { PERMISSIONS } from "../../helper/permissionConstants";

//Styles
import styles from "./CampaignSettings.module.scss";

//Images & Icons
import Settings5LineIcon from "remixicon-react/Settings5LineIcon";
import MailLineIcon from "remixicon-react/MailLineIcon";
import BarChartGroupedLineIcon from "remixicon-react/BarChartGroupedLineIcon";
import EqualizerLineIcon from "remixicon-react/EqualizerLineIcon";
import Rocket2LineIcon from "remixicon-react/Rocket2LineIcon";

//Components
import Tabs from "../../components/UI/Tabs/Tabs";
import Prospects from "./Prospects/Prospects";
import Companies from "./Companies/Companies";
import CampaignPerformance from "../CampaignPerformance/CampaignPerformance";
import ResponderInbox from "../ResponderInbox/ResponderInbox";
import CampaignConfiguration from "./CampaignConfiguration/CampaignConfiguration";
import TopBannerCampaignSettings from "./TopBannerCampaignSettings";
import Deals from "./Deals/Deals";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import campaignConfigSlice from "@store/Campaigns/campaignConfigSlice";
import { useSlice } from "@hooks/useSlice";
import useRouteParams from "@hooks/useRouteParams";
import { useHistory } from "react-router-dom";
import Settings from "./Components/Settings/Settings";
import CampaignOptimizations from "./Optimization/CampaignOptimizations";
import _ from "lodash";
import { OrganizationFeatureEnum } from "@models/organization/OrganizationFeatureEnum";
import { globalPermissionHelper } from "@helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "@helper/globalUserRolePermissionsConstants";

const CampaignsSettings: FC = () => {
  let config = null;
  const tabs = [];
  const history = useHistory();

  const { organizationId, campaignId } = useRouteParams();
  const campaign = useSlice((state) => state.campaign.campaign);
  const userRole = useSlice((state) => state.user?.organization?.role);

  const dispatch = useDispatch();

  useEffect(() => {
    const currentPath = history.location.pathname;
    if (currentPath.includes("campaign-configuration")) {
      history.replace(currentPath.replace("campaign-configuration", "overview"));
    }
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(campaignConfigSlice.actions.resetCampaignState());
    };
  }, [campaignId, dispatch]);

  if (permissionHelper(userRole, PERMISSIONS.campaign.view)) {
    tabs.push({
      link: "overview",
      component: <CampaignConfiguration />,
      title: "Overview",
      img: <Settings5LineIcon size={22} className="iconGray" />,
    });
  }

  const userPermissions = useSlice((state) => state.user.userPermissions);
  const organization = useSlice((state) => state.user?.organization);
  const hasFeature = organization?.features != null && _.includes(organization.features, OrganizationFeatureEnum.CampaignOptimization);
  if (
    (hasFeature && permissionHelper(userRole, PERMISSIONS.campaign.viewOptimizations)) ||
    globalPermissionHelper(userPermissions, GLOBAL_PERMISSIONS.outboss.view)
  ) {
    tabs.push({
      link: "optimization",
      component: <CampaignOptimizations />,
      title: "Optimization (Beta)",
      img: <Rocket2LineIcon size={20} className="iconGray" />,
      tabData: campaign.optimizationsCount,
    });
  }

  if (permissionHelper(userRole, PERMISSIONS.responderInbox.view)) {
    tabs.push({
      link: "inbox",
      component: <ResponderInbox isInCampaignSettings={true} />,
      title: "Inbox",
      img: <MailLineIcon size={20} className="iconGray" />,
    });
  }

  if (permissionHelper(userRole, PERMISSIONS.prospect.view)) {
    tabs.push({
      link: "prospects",
      component: <Prospects />,
      title: "Prospects",
      tabData: campaign.prospects,
    });
  }

  if (permissionHelper(userRole, PERMISSIONS.companies.view)) {
    tabs.push({
      link: "companies",
      component: <Companies />,
      title: "Companies",
      tabData: campaign.companies,
    });
  }

  if (permissionHelper(userRole, PERMISSIONS.deals.view)) {
    tabs.push({
      link: "leads",
      component: <Deals />,
      title: "Leads",
      tabData: campaign.leads,
    });
  }

  if (permissionHelper(userRole, PERMISSIONS.organization.view)) {
    tabs.push({
      link: "reports",
      component: <CampaignPerformance />,
      title: "Stats & Reports",
      img: <BarChartGroupedLineIcon size={20} className="iconGray" />,
    });
  }

  if (permissionHelper(userRole, PERMISSIONS.campaign.view)) {
    tabs.push({
      link: "settings",
      component: <Settings />,
      title: "Settings",
      img: <EqualizerLineIcon size={20} className="iconGray" />,
    });
  }

  config = <Tabs tabsClassName={styles.tabsItem} tabContentClass={styles.campTabsStyle} tabs={tabs} />;

  const changeCampaign = async (campaignId: number) => {
    const res = history.location.pathname.split("/");
    const tabName = res[res.length - 1];

    if (tabName !== "overview") {
      history.push("/" + organizationId + "/campaigns/" + campaignId + "/" + tabName);
    } else {
      history.push("/" + organizationId + "/campaigns/" + campaignId + "/overview");
    }
  };

  const breadcrumbs = [
    {
      href: "/" + organizationId + "/campaigns",
      text: "Campaigns",
    },
    { active: true, text: campaign.name },
  ];

  return (
    <PageContentWrapper className="contentHeight overflow-y-none">
      <TopBannerCampaignSettings breadcrumbs={breadcrumbs} changeCampaign={changeCampaign} />
      <div className={styles.tabWrapper}>{config}</div>
    </PageContentWrapper>
  );
};

export default CampaignsSettings;
