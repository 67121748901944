import * as React from "react";
import { Redirect, Switch } from "react-router-dom";

// Auth
import { Callback } from "../components/auth/Callback";
// import { LogoutCallback } from "../components/auth/LogoutCallback";

// Loading page
import Loading from "../pages/Loading/Loading";

// Routes and layouts
import RouteWithLayout from "./RouteWithLayout";
import EmptyLayout from "../hoc/Layout/EmptyLayout/EmptyLayout";
import Layout from "../hoc/Layout/Layout";
import { Login } from "../components/auth/Login";
import { Impersonate } from "../components/auth/Impersonate";
import { Route } from "react-router-dom";

// pages
import OrganizationRegistrationProcess from "../pages/RegisterOrganization/RegisterOrganization";
import Dashboard from "../pages/Dashboard/Dashboard";
import Landing from "../pages/Landing/Landing";
import OrganizationPicker from "../pages/OrganizationPicker/OrganizationPicker";
import OrganizationSettings from "../pages/OrganizationSettings/OrganizationSettings";
import UserSettings from "../pages/UserSettings/UserSettings";
import DealNavigator from "../pages/DealNavigator/DealNavigator";
import { PERMISSIONS } from "../helper/permissionConstants";
import NotFound404 from "../pages/NotFound404/NotFound404";
import RouteResolver from "../pages/RouteResolver/RouteResolver";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ErrorBoundary from "../hoc/Layout/ErrorBoundary/ErrorBoundary";
import Campaigns from "../pages/Campaigns/Campaigns";
import CampaignSettings from "../pages/CampaignSettings/CampaignSettings";
import Prospects from "../pages/Prospects/Prospects";
import Companies from "../pages/Companies/Companies";
import GlobalSearch from "../pages/GlobalSearch/GlobalSearch";
import EmailAccounts from "../pages/EmailAccounts/EmailAccounts";
import SalesDocuments from "../pages/SalesDocuments/SalesDocuments";
import Exclusions from "../pages/Exclusions/Exclusions";
import CampaignSequence from "../pages/CampaignSettings/EditPages/CampaignSequence/CampaignSequence";
import Domains from "../pages/Domains/Domains";
import CRMSync from "../pages/CRMSync/CRMSync";
// import BillingAndPayment from "../pages/BillingAndPayment/BillingAndPayment";
import EmailTemplates from "../pages/EmailTemplates/EmailTemplates";
import SendingSchedule from "../pages/CampaignSettings/EditPages/SendingSchedule/SendingSchedule";
import Audiences from "../pages/Audiences/Audiences";
import Deals from "../pages/Deals/Deals";
import ComplianceSupportCenter from "../pages/ComplianceSupportCenter/ComplianceSupportCenter";
import ResponderInbox from "../pages/ResponderInbox/ResponderInbox";
import CompanyActivityOverview from "../pages/ActivityOverview/Company/CompanyActivityOverview";
import Sandbox from "../pages/Sandbox/Sandbox";
import ProspectActivityOverview from "../pages/ActivityOverview/Prospect/ProspectActivityOverview";
import PreparingEnvironment from "../pages/RegisterOrganization/components/Steps/PreparingEnvironment/PreparingEnvironment";
import Conversation from "../pages/Conversation/Conversation";
import AudiencesExplorer from "../pages/AudiencesExplorer/AudiencesExplorer";
import Calendar from "../pages/Calendar/Calendar";
import CampaginPerformance from "../pages/CampaignPerformance/CampaignPerformance";
import EditEmailTemplatesTabs from "../pages/EmailTemplates/EditEmailTemplates/EditEmailTemplatesTabs";
import HeaderTabs from "../pages/EmailAccounts/EmailAccountSettings/BasicEmailTest/HeaderTabs/HeaderTabs";
import NoPermission from "../pages/NoPermission/NoPermission";
import AudienceContacts from "../pages/AudienceContacts/AudienceContacts";
import ChargifyForm from "../components/ChargifyForm/ExternalFormDataChargifyForm";
import Pricing from "../pages/Pricing/Pricing";
import UsersOutboss from "../pages/OutBoss/Users/Users";
import EmailAccountsOutboss from "../pages/OutBoss/EmailAccounts/EmailAccounts";
import Organizations from "../pages/OutBoss/Organizations/Organizations";
import User from "../pages/OutBoss/User/User";
import UserActivityLogs from "../pages/OutBoss/UserActivityLogs/UserActivityLogs";
import NotificationActivityLogs from "../pages/OutBoss/NotificationActivityLogs/NotificationActivityLogs";
import NotificationTemplates from "../pages/OutBoss/NotificationTemplates/NotificationTemplates";
import TemplateRequest from "../pages/EmailTemplates/TemplateRequest/TemplateRequest";
import SendReports from "../pages/SendReports/SendReports";
import Health from "./Health";
import OutbossOrganizationSettings from "../pages/OutBoss/OutbossOrganizationSettings/OutbossOrganizationSettings";
import EmailAccountDetails from "../pages/OutBoss/EmailAccounts/EmailAccountDetails/EmailAccountDetails";
import VersionNumber from "../pages/VersionNumber/VersionNumber";
import OutbaseAssistant from "../pages/RegisterOrganization/OutbaseAssistant/OutbaseAssistant";
import AutoCreateOrganization from "../pages/AutoCreateOrganization/AutoCreateOrganization";
import Logout from "@components/auth/Logout";
import CreateCampaignAssistant from "../pages/Assistant/CreateCampaignAssistant/CreateCampaignAssistant";
import Error from "./Error";
import ErrorThrow from "./ErrorThrow";

const RedirectWithParams = ({ to, location }) => (
  <Redirect
    to={{
      ...to,
      search: location.search,
    }}
  />
);

export const Routes = (
  <ErrorBoundary>
    <Switch>
      <Route path="/health" exact={true} component={Health} status={200} />
      <RouteWithLayout isPrivate={false} exact={true} path="/loading" component={Loading} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/login" component={Login} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/impersonate" component={Impersonate} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/login-callback" component={Callback} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/logout" component={Logout} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/version" component={VersionNumber} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/test-error" component={Error} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} exact={true} path="/test-error-throw" component={ErrorThrow} layout={EmptyLayout} />
      {/* <RouteWithLayout isPrivate={false} exact={true} path="/logout-callback" component={LogoutCallback} layout={EmptyLayout} /> */}
      {/* <RouteWithLayout isPrivate={false} exact={true} path="/silentrenew" component={SilentRenew} layout={EmptyLayout} /> */}
      {/* ----------------------------- OUTBOSS ------------------------- */}
      <RouteWithLayout isPrivate={true} path="/outboss/users" component={UsersOutboss} layout={Layout} />
      <RouteWithLayout isPrivate={true} path="/outboss/organizations" component={Organizations} layout={Layout} />
      <RouteWithLayout
        isPrivate={true}
        path="/outboss/email-accounts/:emailAccountId/details"
        component={EmailAccountDetails}
        layout={Layout}
      />
      <RouteWithLayout isPrivate={true} path="/outboss/email-accounts" component={EmailAccountsOutboss} layout={Layout} />
      <RouteWithLayout isPrivate={true} path="/outboss/user-details/:userId" component={User} layout={Layout} />
      <RouteWithLayout
        isPrivate={true}
        path="/outboss/organization-settings/:orgId"
        component={OutbossOrganizationSettings}
        layout={Layout}
      />
      <RouteWithLayout isPrivate={true} path="/outboss/user-activity-logs" component={UserActivityLogs} layout={Layout} />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-notification-logs-outboss"
        component={NotificationActivityLogs}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/notification-templates-outboss"
        component={NotificationTemplates}
        layout={Layout}
      />
      {/* ----------------------------- OUTBOSS END ------------------------- */}
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/dashboard"
        permission={PERMISSIONS.dashboard.view}
        component={Dashboard}
        layout={Layout}
      />
      <Route exact path="/:organizationId(\d+)" render={({ match }) => <Redirect to={`/${match.params.organizationId}/dashboard`} />} />
      <RouteWithLayout isPrivate={true} path="/:organizationId/settings" component={UserSettings} layout={Layout} />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/organization-settings"
        permission={PERMISSIONS.organization.settings.edit}
        component={OrganizationSettings}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/pricing"
        permission={PERMISSIONS.billingPayment.create}
        component={Pricing}
        layout={Layout}
      />
      <RouteWithLayout isPrivate={true} path="/my-organizations" component={OrganizationPicker} layout={EmptyLayout} />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/deal-navigator"
        permission={PERMISSIONS.dealNavigator.view}
        component={DealNavigator}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/sales-resources"
        permission={PERMISSIONS.salesDocuments.documents.view}
        component={SalesDocuments}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/campaigns/:campaignId/campaign-sequence"
        permission={PERMISSIONS.campaign.view}
        component={CampaignSequence}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/campaigns/:campaignId/sending-schedule"
        component={SendingSchedule}
        layout={Layout}
        permission={PERMISSIONS.campaign.edit}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/campaigns/:campaignId"
        permission={PERMISSIONS.campaign.view}
        component={CampaignSettings}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/campaigns"
        permission={PERMISSIONS.campaign.view}
        component={Campaigns}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/prospects"
        permission={PERMISSIONS.prospect.view}
        component={Prospects}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/companies"
        permission={PERMISSIONS.companies.view}
        component={Companies}
        layout={Layout}
      />
      <RouteWithLayout isPrivate={true} path="/:organizationId/globalsearch" component={GlobalSearch} layout={Layout} />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/inbox"
        permission={PERMISSIONS.responderInbox.view}
        component={ResponderInbox}
        layout={Layout}
      />
      <RouteWithLayout isPrivate={true} path="/:organizationId/chargify" component={ChargifyForm} layout={Layout} />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/exclusions"
        permission={PERMISSIONS.exclusion.view}
        component={Exclusions}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-accounts/:accountType/create"
        component={HeaderTabs}
        layout={Layout}
        permission={PERMISSIONS.emailAccounts.create}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-accounts/:accountId"
        component={HeaderTabs}
        layout={Layout}
        permission={PERMISSIONS.emailAccounts.view}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-accounts"
        permission={PERMISSIONS.emailAccounts.view}
        component={EmailAccounts}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/compliance"
        component={ComplianceSupportCenter}
        layout={Layout}
        permission={PERMISSIONS.compliance.view}
      />
      <RouteWithLayout isPrivate={true} path="/register-organization" component={OrganizationRegistrationProcess} layout={EmptyLayout} />
      <RouteWithLayout
        isPrivate={true}
        path="/creating-organization"
        component={AutoCreateOrganization}
        layout={EmptyLayout}
        isExact={true}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-templates/create"
        component={EditEmailTemplatesTabs}
        layout={Layout}
        isExact={true}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-templates"
        component={EmailTemplates}
        layout={Layout}
        permission={PERMISSIONS.emailTemplates.view}
        isExact={true}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-template/:templateId"
        component={EditEmailTemplatesTabs}
        layout={Layout}
        isExact={true}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/email-templates"
        component={EmailTemplates}
        layout={Layout}
        permission={PERMISSIONS.emailTemplates.view}
      />

      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/domains"
        component={Domains}
        layout={Layout}
        permission={PERMISSIONS.domain.view}
      />
      <RouteWithLayout isPrivate={true} path="/:organizationId/crm" component={CRMSync} layout={Layout} permission={PERMISSIONS.crm.view} />
      {/* <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/billing-and-payment"
        component={BillingAndPayment}
        layout={Layout}
      /> */}
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/template-request/:templateRequestId"
        component={TemplateRequest}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/leads"
        component={Deals}
        layout={Layout}
        permission={PERMISSIONS.deals.view}
      />
      {/* Route to handle redirection from /audiences to /lists */}
      <Route
        exact
        path="/:organizationId/audiences"
        render={({ location, match }) => {
          const { organizationId } = match.params;
          return (
            <RedirectWithParams
              to={{
                pathname: `/${organizationId}/lists`,
              }}
              location={location}
            />
          );
        }}
      />

      {/* Route for /lists */}
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/lists"
        component={Audiences}
        layout={Layout}
        permission={PERMISSIONS.audience.view}
      />

      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/company-activity-overview/:companyId"
        component={CompanyActivityOverview}
        layout={Layout}
        permission={PERMISSIONS.companyActivityOverview.view}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/prospect-activity-overview/:prospectId"
        component={ProspectActivityOverview}
        layout={Layout}
        permission={PERMISSIONS.prospectActivityOverview.view}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/conversations/:prospectId?"
        component={Conversation}
        layout={Layout}
        permission={PERMISSIONS.conversation.view}
      />
      <RouteWithLayout isPrivate={true} path="/preparing-environment" component={PreparingEnvironment} layout={EmptyLayout} />
      {/* <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/audience-builder/:searchId?"
        component={AudiencesExplorer}
        layout={Layout}
        permission={PERMISSIONS.audienceExplorer.view}
      /> */}

      {/* Route if the user has bookmarked an old url and accessed through it */}
      <Route
        exact
        path="/:organizationId/audience-builder/:searchId?"
        render={({ location, match }) => {
          const { organizationId, searchId } = match.params;
          if (searchId) {
            return (
              <RedirectWithParams
                to={{
                  pathname: `/${organizationId}/search/${searchId}`,
                }}
                location={location}
              />
            );
          } else {
            return (
              <RedirectWithParams
                to={{
                  pathname: `/${organizationId}/search`,
                }}
                location={location}
              />
            );
          }
        }}
      />

      {/* Route with new URL */}
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/search/:searchId?"
        component={AudiencesExplorer}
        layout={Layout}
        permission={PERMISSIONS.audienceExplorer.view}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/reports"
        component={CampaginPerformance}
        layout={Layout}
        permission={PERMISSIONS.reports.view}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/send-reports"
        component={SendReports}
        layout={Layout}
        permission={PERMISSIONS.sendReports.view}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/calendar"
        component={Calendar}
        layout={Layout}
        permission={PERMISSIONS.calendar.view}
      />

      {/* Route if the user has bookmarked an old url for single audience page - remove after some time later on */}
      <Route
        exact
        path="/:organizationId/audience/:audienceId/audience-contacts/:audienceType"
        render={({ location, match }) => {
          const { organizationId, audienceId, audienceType } = match.params;
          return (
            <RedirectWithParams
              to={{
                pathname: `/${organizationId}/list/${audienceId}/list-contacts/${audienceType}`,
              }}
              location={location}
            />
          );
        }}
      />

      {/* Route with new URL */}
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/list/:audienceId/list-contacts"
        component={AudienceContacts}
        layout={Layout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/assistant/welcome-onboarding"
        component={OutbaseAssistant}
        layout={EmptyLayout}
      />
      <RouteWithLayout
        isPrivate={true}
        path="/:organizationId/assistant/create-campaign"
        component={CreateCampaignAssistant}
        layout={EmptyLayout}
      />
      <RouteWithLayout isPrivate={true} path="/sandbox" component={Sandbox} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} path="/404" component={NotFound404} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} path="/error/:organizationId?" component={ErrorPage} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} path="/noPermission" component={NoPermission} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} path="/landing" component={Landing} layout={EmptyLayout} />
      <RouteWithLayout isPrivate={false} path="/" component={RouteResolver} layout={EmptyLayout} />
    </Switch>
  </ErrorBoundary>
);
