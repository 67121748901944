import * as dataTableActionTypes from "../../../hor/dataTableActionTypes";
import api, { API } from "../../../../helper/api/api";

const name = "COMPANY_ACTIVITY_OVERVIEW";

export const fetchCompanyActivityOverviewTableRequest = () => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_REQUEST}`,
  };
};

export const fetchCompanyActivityOverviewTableSuccess = (setup) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_SUCCEEDED}`,
    result: setup,
  };
};

export const fetchCompanyActivityOverviewTableFailed = (error) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_FAILED}`,
    result: error,
  };
};

export const fetchCompanyActivityOverviewTable = (params, id, showAll) => {
  const data = {
    param: params,
    id: id,
    showAll: showAll,
  };

  return (dispatch) => {
    dispatch(fetchCompanyActivityOverviewTableRequest());
    return api(API.companyActivityOverview.getAllProspects, data)
      .then((response) => {
        dispatch(fetchCompanyActivityOverviewTableSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompanyActivityOverviewTableFailed(error.message));
      });
  };
};

export const initiate = (state) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_INITIATE}`,
    result: state,
  };
};

export const changePage = (page) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CHANGE_PAGE}`,
    result: page,
  };
};

export const sortData = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SORT_DATA}`,
    result: obj,
  };
};

export const numRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_NUM_RECORDS}`,
    result: obj,
  };
};

export const searchRecords = (value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SEARCH_RECORDS}`,
    result: value,
  };
};

export const filterRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FILTER_RECORDS}`,
    result: obj,
  };
};

export const setFilter = (type, value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SET_FILTER}`,
    filterType: type,
    filterValue: value,
  };
};

export const clearFilter = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_FILTER}`,
    filterType: type,
  };
};
