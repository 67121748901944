import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonThumbnail = () => {
  return (
    <div className="skeleton-wrapper">
      <SkeletonElement type="thumbnail" />
      <Shimmer />
    </div>
  );
};

export default SkeletonThumbnail;
