import { useParams } from "react-router-dom";

interface RouteParamsOriginal {
  campaignId: string;
  organizationId: string;
}

export interface RouteParams {
  campaignId: number;
  organizationId: number;
}

const useRouteParams = (): RouteParams => {
  const { campaignId, organizationId } = useParams<RouteParamsOriginal>();

  return {
    campaignId: parseInt(campaignId),
    organizationId: parseInt(organizationId),
  };
};

export default useRouteParams;
