import React from "react";

//Style
import styles from "./SequenceHasDraftNotification.module.scss";

//Images & Icons
import AlertLineIcon from "remixicon-react/AlertLineIcon";

const SequenceHasDraftNotification = () => {
  return (
    <div className={styles.errorWrapper} id="campaign pending">
      <AlertLineIcon size={20} color="var(--danger-color-500)" />
      <p>Campaign pending. Review and publish outstanding messages to launch.</p>
    </div>
  );
};

export default SequenceHasDraftNotification;
