import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";

//Redux
import {
  fetchSendReports,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
} from "../../store/SendReports/Table/actions/sendReports";
import { fetchEmailStagesList } from "../../store/SendReports/Global/actions/sendReports";

//Helpers
import { filterData, getFilterValue, filtersToQueryString, filterSingle } from "../../helper/tableConstants";
import { useQueryTableFilters } from "../../helper/hooks/useQueryTableFilters";
import { useOrganizationId } from "../../helper/hooks/useOrganizationId";
import { dateToUTC } from "../../helper/dateHelper";
import useSelectList from "../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";
import { useOrgTimeFormat } from "@hooks/useOrgTimeFormat";

//Images & Icons
import EmptyAudiencesList from "../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";

// Style
import styles from "./SendReports.module.scss";

// Components
import { columns } from "./columns";
import Table from "../../components/DataTable/Table";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import SkeletonTableFilterRow from "../../components/UI/Skeletons/components/SkeletonTableFilterRow/SkeletonTableFilterRow";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import TableOptionsRow from "../../components/DataTable/TableOptionsRow/TableOptionsRow";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const SendReports = (props) => {
  let timeFormat = useOrgTimeFormat();
  const { param, rows, fetchSendReports, isInitiated, initiate, clearInitiated, fetchEmailStagesList } = props;
  const icon = (
    <InfoIconTooltip
      tooltipBody={
        "A detailed breakdown of your campaign(s) daily sends and any errors which might need addressing to keep them up and running."
      }
      href={"https://help.outbase.com/user-guide/reports/send-reports"}
      linkText="Read More"
      disableStartTourButton
    />
  );
  let image = <EmptyAudiencesList />;
  const organizationId = useOrganizationId();
  let filterTypes = ["Campaign", "EmailAccount", "EmailStage", "SendStatus", "Date"];
  const [tableRows, setTableRows] = useState([]);
  const sendStatusList = [
    { label: "Successful sent", value: "1" },
    { label: "Error", value: "0" },
  ];

  let state = useQueryTableFilters(filterTypes);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  const [reportDate, setReportDate] = useState(null);

  useEffect(() => {
    const dateFilter = state.params?.filter?.find((param) => param.type === "Date");
    if (dateFilter) {
      const preExistingDate = new Date(dateFilter.value[0]);

      if (preExistingDate.getTime() !== reportDate?.getTime()) {
        setReportDate(preExistingDate);
      }
    }
  }, [state.params, reportDate]);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
    };
    initiate(updateState);
  };

  useEffect(() => {
    const isAccessedFromMenu = !state.params || !state.params.filter || !state.params.filter.some((param) => param.type === "Date");

    if (isAccessedFromMenu) {
      const currentDate = new Date();
      currentDate.setHours(1, 0, 0);

      const updatedParams = {
        ...state.params,
        filter: [
          ...(state.params && state.params.filter ? state.params.filter : []),
          {
            type: "Date",
            value: [currentDate.toDateString()],
          },
        ],
      };

      const updateState = {
        params: updatedParams,
        start: 0,
        page: 0,
        length: 25,
        sort: {},
        search: "",
      };

      initiate(updateState);
    } else {
      const updateState = {
        params: state.params,
        start: 0,
        page: 0,
        length: 25,
        sort: {},
        search: "",
      };

      initiate(updateState);

      if (props.selectedDate) {
        const selectedDateObject = new Date(props.selectedDate);
        setReportDate(selectedDateObject);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    var newDateToUTC = dateToUTC(new Date(reportDate));
    props.fetchEmailStagesList(newDateToUTC);
  }, [reportDate]);

  useEffect(() => {
    if (isInitiated) {
      fetchSendReports(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);
      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      r.emailAccount = (
        <OverlayTrigger
          key={r.emailAccount}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.emailAccount}`}>
              <Popover.Body>{r.emailAccount}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/email-accounts/" + r.emailAccountId + "/edit",
              }}
            >
              {r.emailAccount}
            </Link>
          </div>
        </OverlayTrigger>
      );

      r.sentTo = (
        <OverlayTrigger
          key={r.sentTo}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.sentTo}`}>
              <Popover.Body>{r.sentTo}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/prospect-activity-overview/" + r.prospectId,
              }}
            >
              {r.sentTo}
            </Link>
          </div>
        </OverlayTrigger>
      );

      r.sendTime = Moment(r.sendTime).format(timeFormat);

      r.name = (
        <OverlayTrigger
          key={r.name}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.name}`}>
              <Popover.Body>{r.name}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden">{r.name}</div>
        </OverlayTrigger>
      );

      r.emailStage = r.emailStage === 0 ? <span>Stage 1</span> : <span>Stage {r.emailStage / 2 + 1}</span>;

      let description = r.sendStatus === true ? "Email sent" : r.description;
      r.description = (
        <OverlayTrigger
          key={description}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${description}`}>
              <Popover.Body>{description}</Popover.Body>
            </Popover>
          }
        >
          <div className="textOverflow">{description}</div>
        </OverlayTrigger>
      );
      r.sendStatus = r.sendStatus ? <span>Sent</span> : <span>Error</span>;
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);
  const { data: emailAccountsList } = useSelectList(SelectListEnum.EmailAccounts);

  let table = null;
  let selects = <SkeletonTableFilterRow />;
  let tableOptionsRow = null;

  const filterStatus = (status) => {
    props.changePage(0);
    filterSingle("Status", status, param, props.setFilter);
  };

  const buttons = (
    <div className={`${styles.datePicker} flex_center`}>
      <DatePicker
        id="reportDate"
        selected={reportDate}
        onChange={(val) => {
          var date = new Date(val);
          date.setHours(1, 0, 0);
          filterData("Date", date.toDateString(), props.setFilter);
          setReportDate(date);
        }}
        dateFormat={"dd MMM yyyy"}
        className={styles.datePickerInput}
        popperPlacement={"top-end"}
        popperClassName={styles.popperClassName}
      />
      <CalendarLineIcon className="iconGray" size={16} />
    </div>
  );

  if (props.error) {
    table = <h2>Error getting Send reports</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded > 0 && props.isLoadedListEmailStages) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"SendReports"}
          columns={dataTableColumns}
          rows={tableRows}
          param={param}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          tableClassName={styles.prospectsTable}
          scrollY={true}
          tableHeight={250}
          variant="small"
          forcePageChanged={true}
        />
      ) : (
        <EmptyState componentClass={styles.emptyStateImage} image={image} title="No send reports found" />
      );

    let selectRowOptions = [
      {
        option: campaignsList,
        change: (c) => filterData("Campaign", c, props.setFilter),
        placeholder: "Campaigns",
        value: getFilterValue(param.filter, "Campaign"),
      },
      {
        option: emailAccountsList,
        change: (s) => filterData("EmailAccount", s, props.setFilter),
        placeholder: "Email account",
        value: getFilterValue(param.filter, "EmailAccount"),
      },
      {
        option: props.listEmailStages,
        change: (s) => filterData("EmailStage", s, props.setFilter),
        placeholder: "Stage",
        value: getFilterValue(param.filter, "EmailStage"),
      },
      {
        option: sendStatusList,
        change: (s) => filterData("SendStatus", s, props.setFilter),
        placeholder: "Status",
        value: getFilterValue(param.filter, "SendStatus"),
      },
    ];

    selects = <TableFiltersRow selects={selectRowOptions} placeholder="send reports" changeFilter={(text) => props.searchRecords(text)} />;

    tableOptionsRow =
      tableRows.length > 0 ? (
        <TableOptionsRow
          applyChanges={applyDataTableColumnsChange}
          exportTable={null}
          importData={null}
          dataTableColumns={dataTableColumns}
          setDataTableColumns={setDataTableColumns}
          allDataTableColumns={columns}
        />
      ) : null;
  }

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title="Send reports" icon={icon} rightContent={buttons} />
      {selects}
      {tableOptionsRow}
      {table}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.sendReports.params,
    isInitiated: state.sendReports.isInitiated,
    isLoading: state.sendReports.isLoading,
    isLoaded: state.sendReports.isLoaded,
    error: state.sendReports.error,
    rows: state.sendReports.rows,
    totalCount: state.sendReports.totalCount,
    pageCount: state.sendReports.pageCount,

    listEmailStages: state.globalSendReports.listEmailStages,
    isLoadingListEmailStages: state.globalSendReports.isLoading,
    isLoadedListEmailStages: state.globalSendReports.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailStagesList: (reportDate) => dispatch(fetchEmailStagesList(reportDate)),
    fetchSendReports: (param) => dispatch(fetchSendReports(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendReports);
