import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

// Style
import styles from "./StatusCol.module.scss";

const StatusCol = (props) => {
  let classes = styles.pillsStyle;

  if (props.variant != null) {
    classes += " " + styles[props.variant];
  }

  if (props.classes != null) {
    classes += " " + props.classes;
  }

  return (
    <div className={styles.columnsWrapper + " " + props.wrapperClass}>
      {props.tooltipText && props.tooltipText !== "" ? (
        <OverlayTrigger
          key={props.status}
          placement={"left"}
          overlay={
            <Popover id={`tooltip-${props.status}`}>
              <Popover.Body className="campaignsStatusColTooltip">{props.tooltipText}</Popover.Body>
            </Popover>
          }
        >
          <div className="max-x-content">
            <p className={`${props.time == null ? "m0" : "mB5"} ${classes}`} style={props.style}>
              {props.status}
            </p>
          </div>
        </OverlayTrigger>
      ) : (
        <div className="max-x-content">
          <p className={`${props.time == null ? "m0" : "mB5"} ${classes}`} style={props.style}>
            {props.status}
          </p>
        </div>
      )}
      {props.time == null ? null : <p className={styles.updateInfo}>Last update {props.time}</p>}
    </div>
  );
};

export default StatusCol;
