import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import { workflowStepConfigurationErrorEnum } from "../../../../../helper/enums/workflowStep/workflowStepConfigurationErrorEnum";

//Styles
import styles from "./SaveSequenceWarningForm.module.scss";

//Images & Icons
import AddLineIcon from "remixicon-react/AddLineIcon";
import MailFillIcon from "remixicon-react/MailFillIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const SaveSequenseWarningForm = (props) => {
  let text = (
    <p className={styles.errorMessage}>
      The campaign sequence cannot be saved because there are two of the same actions next to each other.
    </p>
  );

  if (
    props.errorType == workflowStepConfigurationErrorEnum.LastStepWaitActionError ||
    props.errorType == workflowStepConfigurationErrorEnum.FirstStepWaitActionError
  ) {
    text = (
      <p className={styles.errorMessage}>
        The campaign sequence cannot be saved because it can't start or end with a Wait time. Remove the wait time and then save your
        sequence.
      </p>
    );
  } else if (props.errorType == workflowStepConfigurationErrorEnum.StepWithoutEmailTemplateError) {
    text = (
      <p className={styles.errorMessage}>
        The campaign sequence cannot be saved because there is an email step without a template selected. Select an email from templates and
        then save your sequence.
      </p>
    );
  } else if (props.errorType == workflowStepConfigurationErrorEnum.TwoInitialEmailTemplateStepsError) {
    text = (
      <p className={styles.errorMessage}>
        The campaign sequence cannot be saved because there are two initial email templates used. Remove one of the initial emails and add
        further emails as follow-ups.
      </p>
    );
  } else if (props.errorType == workflowStepConfigurationErrorEnum.TwoStepsWithTheSameEmailTemplateError) {
    text = (
      <p className={styles.errorMessage}>
        The campaign sequence cannot be saved because a template is being used twice. Remove the duplicate template and then save your
        sequence.
      </p>
    );
  } else if (props.errorType == workflowStepConfigurationErrorEnum.FirstStepFollowUpTemplateError) {
    text = (
      <p className={styles.errorMessage}>
        The campaign sequence cannot be saved because the template chosen in the first step must be of type Initial. Please choose the
        correct template and then save your sequence.
      </p>
    );
  } else if (props.errorType == workflowStepConfigurationErrorEnum.DraftTemplatesActiveCampaign) {
    text = (
      <p className={styles.errorMessage}>
        Draft email templates cannot be added to an Active Campaign. Please review and publish the email templates you want to use and try
        again.
      </p>
    );
  }

  return (
    <Modal
      dialogClassName={`${styles.sequenceDialog} customModal`}
      title={"Campaign sequence"}
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
      size="lg"
      closeButtonText={"Cancel"}
      backdrop={true}
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.wizzardContent}>
            {text}
            <p className={styles.leftParagraph}>Follow the steps:</p>
            <div className={styles.sequenceSteps}>
              <div className={styles.fullStepWraper}>
                <div className={styles.stepWraper}>
                  <div className={`${styles.imgWraper} center_flex flex`}>
                    {/* <Button variant="rounded-s" classes={`${styles.addStepButton} ${styles.scaleButton}`}><PlusDark /></Button> */}
                    <AddLineIcon size={20} className="iconGray500" />
                  </div>
                  <div className={styles.descriptionWraper}>
                    <span className={styles.typeOfStepDescription}>Add Step</span>
                  </div>
                </div>
              </div>

              <div className={styles.fullStepWraper}>
                <div className={styles.stepWraper}>
                  <div className={`${styles.imgWraper} center_flex flex`}>
                    <MailFillIcon size={22} className="iconGray500" />
                  </div>
                  <div className={styles.descriptionWraper}>
                    <span className={styles.typeOfStepDescription}>Choose Email Template</span>
                  </div>
                </div>
              </div>

              <div className={styles.fullStepWraper}>
                <div className={styles.stepWraper}>
                  <div className={`${styles.imgWraper} center_flex flex`}>
                    <TimeLineIcon size={22} className="iconGray500" />
                  </div>
                  <div className={styles.descriptionWraper}>
                    <span className={styles.typeOfStepDescription}>Select Wait Period</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default SaveSequenseWarningForm;
