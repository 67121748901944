import React, { useEffect, useState, useRef } from "react";
import api, { API } from "../../../helper/api/api";
import { connect } from "react-redux";
import Moment from "moment";
import { toast } from "react-toastify";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";

//Redux
import {
  fetchUnsubscribesExclusions,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
} from "../../../store/Exclusions/Table/actions/unsubscribesExclusions";

//Helpers
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { useOrgDateTimeFormat } from "../../../helper/hooks/useOrgDateTimeFormat";
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";
import { numberOfRecords } from "../../../helper/tableConstants";
import { toastMessages } from "../../../helper/toastMessagesConstants";
import { SelectListEnum } from "../../../helper/hooks/query/SelectListEnum";
import useSelectList from "../../../helper/hooks/query/useSelectList";

// Style
import styles from "../Exclusions.module.scss";

// Icons & Images
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";
import EmptyData from "../../../assets/Images/EmptyData/EmptyData";

// Components
import Permission from "../../../components/auth/Permission";
import { exclusionType } from "../exclusionType";
import DeleteExclusionsForm from "../DeleteExclusionsForm";
import { columns } from "./columns";
import Table from "../../../components/DataTable/Table";
import Button from "../../../components/UI/Button/Button";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const UnsubscribesExclusions = (props) => {
  let longDateFormat = useOrgDateTimeFormat();
  const { params, rows, fetchUnsubscribesExclusions, initiate, isInitiated, clearInitiated } = props;
  const records = numberOfRecords[1];
  const [tableColumns] = useState([...columns]);
  const [tableRows, setTableRows] = useState([]);
  let separators = useGroupDecimalSeparator();
  let totalCountFormatted = formatNumberHelper(props.totalCount, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);
  const [unsubscribersCampaign, setUnsubscribersCampaign] = useState("Select Campaign");

  let image = <EmptyData />;

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "Type", value: [exclusionType.Unsubscribes] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchUnsubscribesExclusions(params);
    }
  }, [params, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const exportCSV = () => {
    const exportParams = _.cloneDeep(params);
    exportParams.length = props.totalCount;

    api(API.exclusions.export, exportParams, "blob")
      .then((res) => {
        toast.success(toastMessages.exclusions.successExportUnsubscribeExclusions);
        fileDownload("Unsubscribes.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [showDeleteExclusionsModal, setShowDeleteExclusionsModal] = useState(false);
  const [exclusionDeleteId, setExclusionDeleteId] = useState(0);
  const handleDeleteExclusionsClose = () => setShowDeleteExclusionsModal(false);
  const handleDeleteExclusionsShow = (exclusion) => {
    setExclusionDeleteId(exclusion.id);
    setShowDeleteExclusionsModal(true);
  };

  const [exclusionsSortBy, setExclusionsSort] = useState("Sort By");
  const handleSortExclusionsChange = (eventKey, event) => {
    setExclusionsSort(event.currentTarget.text);

    const sortSplit = eventKey.split(" ");
    const sort = {
      column: sortSplit[0],
      direction: sortSplit[1].toLowerCase(),
    };
    props.sortData(sort);
  };

  const handleUnsubscribersCampaignChange = (eventKey, event) => {
    var campaignId = eventKey;
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "Type", value: [exclusionType.Unsubscribes] }],
      },
    };
    if (campaignId !== "0") {
      state.params.filter.push({ type: "Campaign", value: [eventKey] });
    }
    initiate(state);
    setUnsubscribersCampaign(event.currentTarget.text);
  };

  let table = null;

  if (props.error) {
    table = <h2 className="error_message">Error getting Exclusions</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      r.email = (
        <OverlayTrigger
          key={r.email}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.email}`}>
              <Popover.Body>{r.email}</Popover.Body>
            </Popover>
          }
        >
          <div className="textOverflow">{r.email}</div>
        </OverlayTrigger>
      );
      r.exclusion = r.email;
      r.createdDate = Moment(r.createdDate).format(longDateFormat);
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  if (props.isLoaded && isInitiated) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"UNSUBSCRIBES EXCLUSIONS"}
          columns={tableColumns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={params}
          tableClassName={styles.exclusionTable}
          tableFooterClass={styles.tableFooter}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          scrollY={true}
          variant="small"
          tableHeight={390}
          hideLabelShow={true}
        />
      ) : (
        <EmptyState image={image} title="No Unsubscribe Exclusions Found" content="" />
      );
  }

  return (
    <div className={`${styles.emailExclusionsPanel} ${styles.panel} exclusionsPanel`}>
      <div className={`${styles.exclusionInfo} flex flexCol flex center_flex`}>
        <h1 className={styles.numberExclusions}>{totalCountFormatted}</h1>
        <p className="flex flex_center">
          <span className={styles.greenCircle} />
          Unsubscribes
        </p>
      </div>
      <div className={`${styles.buttonsWrapper} ${styles.responderExclusions} space_between`}>
        <div className={styles.actionWrapper}>
          <div className={styles.actionDropdown}>
            <Dropdown onSelect={handleUnsubscribersCampaignChange}>
              <Dropdown.Toggle id="dropdown-menu-align-left" className={styles.dropdownButton}>
                <p>{unsubscribersCampaign}</p>

                {/* <ExpandMoreIcon /> */}
              </Dropdown.Toggle>

              <Dropdown.Menu className={styles.actionList} alignLeft>
                <Dropdown.Item eventKey="0" className={styles.dropdownItem} href="#">
                  Select Campaign
                </Dropdown.Item>

                {campaignsList?.map((c) => {
                  return (
                    <>
                      <Dropdown.Item eventKey={c.value} className={styles.dropdownItem} href="#">
                        {c.label}
                      </Dropdown.Item>
                    </>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Permission has={PERMISSIONS.exclusion.export}>
          <Button
            id="exportUnsubscribeExclusions"
            onClick={exportCSV}
            variant="secondary-m"
            classes="mR10 m-s-min-width-auto"
            disabled={tableRows?.length === 0}
            ariaLabel="Export"
          >
            <ExternalLinkLineIcon size={20} className="iconGray500" />
            <span className="m-s-hide mL5">Export</span>
          </Button>
        </Permission>
      </div>
      {table}
      <DeleteExclusionsForm
        handleClose={handleDeleteExclusionsClose}
        showModal={showDeleteExclusionsModal}
        exclusionId={exclusionDeleteId}
        getExclusions={() => fetchUnsubscribesExclusions(params)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.unsubscribesExclusions.params,
    isInitiated: state.unsubscribesExclusions.isInitiated,
    isLoading: state.unsubscribesExclusions.isLoading,
    isLoaded: state.unsubscribesExclusions.isLoaded,
    error: state.unsubscribesExclusions.error,
    rows: state.unsubscribesExclusions.rows,
    totalCount: state.unsubscribesExclusions.totalCount,
    pageCount: state.unsubscribesExclusions.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUnsubscribesExclusions: (param) => dispatch(fetchUnsubscribesExclusions(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribesExclusions);
