import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const DeleteStepTemplateForm = (props) => {
  const deleteStep = () => {
    props.handleClick(props.stepId);
  };

  return (
    <Modal
      title={"Delete step"}
      handleClose={props.handleClose}
      handleSave={deleteStep}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>Are you sure you want to delete this Step?</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DeleteStepTemplateForm;
