import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import { useHistory, Prompt } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { RadioGroup } from "@mui/material";

//Helpers
import { templateVariableInsertToEnum } from "@helper/enums/templateVariableInsertToEnum";
import { toastMessages } from "@helper/toastMessagesConstants";
import { emailTemplateTypeEnum } from "@helper/enums/emailTemplateTypeEnum";
import { errorResponseToList } from "@helper/errorHelper";
import { actionPageTypeEnum } from "@helper/enums/actionPageTypes";
import api, { API, formUrl } from "@helper/api/api";
import { PERMISSIONS } from "@helper/permissionConstants";
import { britishEnglish } from "@helper/languageChoiceAwesomenessCheckerConstants";
import { usEnglish } from "@helper/languageChoiceAwesomenessCheckerConstants";
import { UNITED_KINGDOM } from "@helper/organizationLocationConstants";
import { aiWriterStepsEnum } from "@helper/enums/aiWriterStepsEnum";
import { getAllVariablesInText, extractVariable, removeIncorrectVariableSpanTags, removeNameDrop } from "@helper/variablesHelper";
import { handleImageUpload } from "@helper/TinyMce/imageUploadHelper";

// Redux
import { setActivePage } from "@store/WorkflowSteps/actions/workflowSteps";
//Styles
import styles from "./CreateUpdateEmailTemplate.module.scss";

//Images & Icons
import SectionInbox from "@assets/Icons/SectionInbox/SectionInbox";
import MessageResponseType from "@assets/Icons/SectionResponseType/MessageResponseType";
import IconVariablesActive from "@assets/Icons/IconVariablesActive/IconVariablesActive";
import IconMessageActive from "@assets/Icons/IconMessageActive/IconMessageActive";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import IconSparkling from "@assets/Icons/IconSparkling/IconSparkling";

// Components
import SaveChangesFooter from "@components/SaveChangesFooter/SaveChangesFooter";
import FormikInput from "@components/UI/Formik/FormikInput";
import TemplateVariables from "./TemplateVariables";
import AdvanceEmailDeliverabilityTestModel from "./EditEmailTemplates/AdvanceEmailDeliverabilityTestModel";
import Spinner from "@components/UI/Spinner/Spinner";
import FormikCheckbox from "@components/UI/Formik/FormikCheckbox";
import FormikEffect from "@components/UI/Formik/FormikEffect";
import Permission from "@components/auth/Permission";
import SendTestEmailForm from "./SendTestEmailForm";
import EmailAwesomeResult from "./EmailAwesomenessResult";
import Button from "@components/UI/Button/Button";
import SectionTitle from "@components/UI/SectionTitle/SectionTitle";
import CancelWarningForm from "@components/CancelWarningForm/CancelWarningForm";
import MessagePreviewSidePanel from "./MessagePreviewSidePanel/MessagePreviewSidePanel";
import AIEmailGenerator from "./AIEmailGenerator/AIEmailGenerator";
import FormikRadio from "@components/UI/Formik/FormikRadio";
import ErrorVariablesModal from "./ErrorVariablesModal/ErrorVariablesModal";
import { emailTemplateStatusEnum } from "@helper/enums/emailTemplateStatusEnum";
import { has } from "lodash";

//Hooks
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const CreateUpdateEmailTemplate = (props) => {
  const editorRef = useRef(null);

  const {
    emailTemplate,
    showTestEmailFormModal,
    handleTestMailFormClose,
    organizationCustomColumns,
    templateDictionary,
    setDictionaryValues,
    templateName,
    templateSubject,
  } = props;

  // Queries
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery();

  const history = useHistory();

  const [chaserCheckboxesShow, setChaserCheckboxesShow] = useState(false);
  const [hasChanges, setHasChanges] = useState();
  const [emailTemplateSubjectBackup, setEmailTemplateSubjectBackup] = useState("");
  const [initialPromptInfoResult, setInitialPromptInfoResult] = useState({});
  const [firstUrl, setFirstUrl] = useState("");
  const [secondUrl, setSecondUrl] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [uspValue, setUspValue] = useState("");
  const [painPointValue, setPainPointValue] = useState("");
  const [activeStepAIPrompt, setActiveStepAIPrompt] = useState(aiWriterStepsEnum.StartingStep);
  const [saveAsNew, setSaveAsNew] = useState(false);
  const [saveAsNewBackup, setSaveAsNewBackup] = useState(false);
  const [newTextTemplate, setNewTextTemplate] = useState(null);
  const [newSubjectTemplate, setNewSubjectTemplate] = useState("");
  const [templateNameChanged, setTemplateNameChanged] = useState(false);
  const [smartEdit, setSmartEdit] = useState(false);
  const [smartEditEnabled, setSmartEditEnabled] = useState(false);
  const [aiWriterEnabled, setAiWriterEnabled] = useState(true);
  const [disableAiWriterButton, setDisableAiWriterButton] = useState(false);
  const fromCampaignSequence = props.fromCampaignSequence !== undefined && props.fromCampaignSequence;
  const combineVariablesArray = emailTemplateVariables?.flatMap((templateVariable) => templateVariable.emailTemplateVariables) ?? [];
  const [blockNavigation, setBlockNavigation] = useState(true);

  const onChange = (values) => {
    if (saveAsNew && !saveAsNewBackup) {
      setSaveAsNew(false);
      return false;
    } else {
      setHasChanges(true);
      setBlockNavigation(false);
      props.updateTemplateStates(values);
    }
  };

  const onReset = () => {
    setHasChanges(false);
    if (fromCampaignSequence) {
      props.setActivePage(actionPageTypeEnum.templatePage);
    } else {
      history.push(`/${props.orgId}/email-templates`);
    }
    props.handleHideSequenceFlow && props.handleHideSequenceFlow();
  };

  var awesomenessTimeout = 0;
  let templateId = props.templateId;
  if (props.templateId === undefined) {
    templateId = 0;
  }

  const [templateVariableInsertTo, setTemplateVariableInsertTo] = useState(templateVariableInsertToEnum.templateBody);
  const [awesomenessResult, setAwesomenessResult] = useState("");
  const [showEmailAwesomenessResult, setShowEmailAwesomenessResult] = useState(false);
  const [showVariablesResult, setShowVariablesResult] = useState(false);
  const [showEmailTemplatePreview, setShowEmailTemplatePreview] = useState(false);
  const [showAIWriter, setShowAIWriter] = useState(false);
  const [tinyMCE, setTinyMCE] = useState(null);

  const [emailTemplateAwesomenessText, setEmailTemplateAwesomenessText] = useState("");
  const [checkAwesomenessEmailAccountId, setCheckAwesomenessEmailAccountId] = useState(0);
  const [templateRequestId, setTemplateRequestId] = useState(0);
  const [languageChoice, setLanguageChoice] = useState(emailTemplate.languageMarketCode);
  const [allAIRequestLogs, setAllAIRequestLogs] = useState([]);
  const [reopenAIPromptOnChatBox, setReopenAIPromptOnChatBox] = useState(false);

  const [showErrorVariablesModal, setShowErrorVariablesModal] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const handleCloseErrorVariablesModal = () => {
    setShowEmailTemplatePreview(true);
    setShowErrorVariablesModal(false);
  };

  useEffect(() => {
    if (templateId !== 0) {
      setSmartEditEnabled(true);
      setAiWriterEnabled(false);
    }
  }, []);

  useEffect(() => {
    if (emailTemplateAwesomenessText !== "" && showEmailAwesomenessResult) {
      checkAwesomeness();
    }
  }, [checkAwesomenessEmailAccountId, emailTemplateAwesomenessText, languageChoice]);

  useEffect(() => {
    if (
      (props.typeActiveStep && props.typeActiveStep === emailTemplateTypeEnum.FollowUp) ||
      (emailTemplate.emailTemplateType && parseInt(emailTemplate.emailTemplateType) === emailTemplateTypeEnum.FollowUp)
    ) {
      setChaserCheckboxesShow(true);
      setEmailTemplateSubjectBackup("FW: {{INITIAL_EMAIL_SUBJECT}}");
      setActiveStepAIPrompt(aiWriterStepsEnum.FollowUpStartingStep);
    } else {
      setChaserCheckboxesShow(false);
    }
  }, [emailTemplate.emailTemplateType]);

  useEffect(() => {
    if (emailTemplateSubjectBackup != "") setEmailTemplateSubjectBackup(emailTemplate.emailTemplateSubject);
  }, [emailTemplate.emailTemplateSubject]);

  const checkAwesomeness = () => {
    let data = {
      id: templateId,
      text: emailTemplateAwesomenessText,
      emailAccountId: checkAwesomenessEmailAccountId,
      languageChoice: languageChoice,
    };

    api(API.emailTemplates.checkAwesomeness, data)
      .then((res) => {
        setAwesomenessResult(res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const closeChecker = () => {
    setShowEmailAwesomenessResult(false);
    setLanguageChoice(languageChoice);
  };

  const getAIPromptInitialInfo = (emailTemplateType) => {
    api(
      formUrl(API.emailTemplates.getAIPromptInitialInfo, {
        emailTemplateRequestId: templateRequestId,
        templateType: emailTemplateType,
      })
    )
      .then((res) => {
        setInitialPromptInfoResult(res.data);
        setFirstUrl(res.data.website);
        setDescriptionValue(res.data.description);
        setUspValue(res.data.usp);
        setPainPointValue(res.data.painPoint);
        setSecondUrl(res.data.secondWebsite);
        setTemplateRequestId(res.data.id);
        if (!res.data.description || !res.data.usp || !res.data.painPoint) {
          setIsNextDisabled(true);
        }
        setDisableAiWriterButton(true);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const getAllTweakTemplateLogs = () => {
    if (templateId === 0) {
      setReopenAIPromptOnChatBox(true);
    }
    api(
      formUrl(API.emailTemplates.getAllTweakTemplateLogs, {
        emailTemplateRequestId: templateRequestId,
        emailTemplateId: templateId,
      })
    )
      .then((res) => {
        setAllAIRequestLogs(res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  //#region Prompt
  const [showCancelSequenceWarningModal, setShowCancelSequenceWarningModal] = useState(false);
  const handleCancelSequenceWarningClose = () => {
    setShowCancelSequenceWarningModal(false);
    setSaveAsNew(false);
    setSaveAsNewBackup(false);
  };

  const [pathname, setPathname] = useState(null);

  const handleCancelModal = (location) => {
    setPathname(location.pathname);
    setShowCancelSequenceWarningModal(true);
    setHasChanges(true);
    return false;
  };

  const updateFormByEditor = (setFieldValue, contentHtml, contentText) => {
    setFieldValue("emailTemplateHtml", contentHtml);
    setFieldValue("emailTemplateText", contentText);
  };

  const updateChanges = async () => {
    setHasChanges(false);
  };

  const handleCancelSequenceWarningSave = async () => {
    if (saveAsNew) {
      handleSaveAsNewTemplate();
    }
    await updateChanges();
    setShowCancelSequenceWarningModal(false);
    history.push({
      pathname: pathname,
      state: true,
    });
  };

  const handleSaveAsNewTemplate = () => {
    let templateHelper = {
      text: "",
      subject: "",
    };
    if (!saveAsNewBackup) {
      let newText = newTextTemplate.replaceAll("\n", "<br>");
      templateHelper.text = newText;
      templateHelper.subject = newSubjectTemplate;
    } else {
      templateHelper.text = newTextTemplate;
      setTemplateNameChanged(true);
    }
    let newName = "";
    let values = {
      emailTemplateHtml: templateHelper.text,
      emailTemplateSubject: templateHelper.subject,
      emailTemplateName: newName,
      emailTemplateType: emailTemplate.emailTemplateType,
      chaserIncludeThread: false,
      chaserAddSignature: false,
      languageMarketCode: emailTemplate.languageMarketCode,
    };

    props.updateTemplateStates(values);
    setShowAIWriter(false);
    setSaveAsNewBackup(false);
    setDisableAiWriterButton(false);
  };

  const checkForIncorrectVariables = (content) => {
    const variables = getAllVariablesInText(content);

    const combineArrayText = combineVariablesArray.map((item) => item.text);
    const combineArrayName = combineVariablesArray.map((item) => item.name);

    let incorrect = [];

    incorrect = variables?.filter((item) => {
      const variableName = extractVariable(item ?? "");
      const variableText = item?.replace(/\{\{([^|]+)(?:\|[^}]+)?}}/g, "{{$1}}");

      if (variableName === "INITIAL_EMAIL_SUBJECT") {
        return false;
      }

      if (!combineArrayText?.includes(variableText) || !combineArrayName?.includes(variableName.split("|")[0])) return item;
    });

    if (incorrect?.length > 0) {
      setShowEmailTemplatePreview(true);
    }

    return incorrect;
  };

  const markIncorrectVariables = (htmlAndSubject) => {
    const contentMCE = editorRef.current.getContent();
    const incorrectVariables = checkForIncorrectVariables(htmlAndSubject);
    let newContent = removeIncorrectVariableSpanTags(contentMCE);
    let nameDrops = ["{{NAMEDROPS}}", "{{NAMEDROP1}}", "{{NAMEDROP2}}", "{{NAMEDROP3}}"];

    if (incorrectVariables.length > 0) {
      incorrectVariables.forEach((variable) => {
        if (nameDrops.includes(variable)) {
          newContent = newContent
            .split(variable)
            .join(
              `<span class="incorrectVariable namedrop" contenteditable="false">${variable}<span class="tooltip">Add your client's name here</span></span>`
            );
        } else {
          newContent = newContent.split(variable).join(`<span class="incorrectVariable" contenteditable="false">${variable}</span>`);
        }
      });
    }

    editorRef.current.setContent(newContent);

    return incorrectVariables;
  };

  //#endregion

  const initialEmailSubjectVariable = "{{INITIAL_EMAIL_SUBJECT}}";

  let emailTemplateComponent = null;
  let advanceEmailDeliverabilityTestModel = null;
  let sendTestEmailForm = null;
  let firstName = props.userFirstName;

  //#region TinyMCE Editor
  const init = {
    branding: false,
    selector: "textarea",
    height: fromCampaignSequence ? "calc(100vh - 465px)" : props.isOnCreate ? "calc(100vh - 370px)" : "calc(100vh - 415px)",
    automatic_uploads: true,
    paste_data_images: true,
    images_reuse_filename: true,
    block_unsupported_drop: true,
    images_upload_handler: handleImageUpload,
    images_file_types: "jpg,jpeg,gif,png,jfif",
    placeholder: `Hi ${firstName},\n\nHere's where you create your email outreach messages.\nDon't know where to start? Click on the AI writer above and we'll write you the perfect intro in seconds!\n\nRemember, the perfect outbound message is short, relevant and to the point.\nTry and keep to under 200 words, 3-4 paragraphs at most.\nFocus on the challenge that you can help solve for the recipient - the more specific the better!`,
    plugins:
      "editimage importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link table charmap nonbreaking anchor insertdatetime advlist lists wordcount charmap emoticons",
    toolbar:
      "bold italic underline strikethrough forecolor backcolor removeformat insertfile image | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify outdent indent | numlist bullist | charmap emoticons | link anchor code | fullscreen print | undo redo",
    setup: (editor) => {
      setTinyMCE(editor);
    },
    content_style: `
    body { font-family:Arial,sans-serif; }
    .mce-content-body[data-mce-placeholder] {
      color: #78797C;
      white-space: pre;
      font-size: 12px;
    }
    .mce-content-body[data-mce-placeholder]:focus,
    .mce-content-body[data-mce-placeholder]:focus::before {
      content: '';
      }
    .incorrectVariable {
      color: #EE3030;
      position: relative;
    }
    .incorrectVariable .tooltip {
      display: block;
      visibility: visible;
      position: absolute;
      width: 100px;
      height: auto;
      line-height: 20px;
      padding: 2px;
      font-size: 0.7rem;
      text-align: center;
      color:#fff;
      background-color: #242b3c;
      border: 1px solid rgb(255, 255, 255);
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
      z-index: 999;
      top: 25px;
      left: 0;
      right: 0;
    }
    .incorrectVariable .tooltip:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #242b3c transparent;
      top: -18px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    `,
    formats: {
      h1: { block: "h1", styles: { "line-height": "1.5" } },
      h2: { block: "h2", styles: { "line-height": "1.5" } },
      h3: { block: "h3", styles: { "line-height": "1.5" } },
      h4: { block: "h4", styles: { "line-height": "1.5" } },
      h5: { block: "h5", styles: { "line-height": "1.5" } },
      h6: { block: "h6", styles: { "line-height": "1.5" } },
    },
    encoding: "html",
    preformatted: false,
    entity_encoding: "raw",
  };
  //#endregion

  if (props.isLoaded) {
    if (emailTemplate.emailTemplateType !== null || templateId === 0) {
      emailTemplateComponent = (
        <>
          <Permission has={templateId !== 0 ? PERMISSIONS.emailTemplates.edit : PERMISSIONS.emailTemplates.create} redirect>
            <Formik
              initialValues={{
                emailTemplateName: emailTemplate.emailTemplateName,
                emailTemplateSubject:
                  props.typeActiveStep === emailTemplateTypeEnum.FollowUp ? emailTemplateSubjectBackup : emailTemplate.emailTemplateSubject,
                emailTemplateHtml: emailTemplate.emailTemplateHtml,
                emailTemplateText: emailTemplate.emailTemplateText,
                emailTemplateType: emailTemplate.emailTemplateType,
                chaserIncludeThread: emailTemplate.chaserIncludeThread !== undefined ? emailTemplate.chaserIncludeThread : true,
                chaserAddSignature: emailTemplate.chaserAddSignature,
                emailTemplateStatus: emailTemplate.emailTemplateStatus,
              }}
              validationSchema={Yup.object({
                emailTemplateName: Yup.string()
                  .required("Template name is required")
                  .max(100, "Email Template name exceeds the maximum length (100 characters)"),
                emailTemplateSubject: Yup.string().required("Subject line is required"),
                emailTemplateHtml: Yup.string().required("Required"),
                emailTemplateType: Yup.string().required("Required"),
              })}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                let htmlAndSubject = values.emailTemplateHtml.concat(" ", values.emailTemplateSubject);

                let incorrect = [];
                if (values.emailTemplateStatus !== emailTemplateStatusEnum.Draft) {
                  incorrect = markIncorrectVariables(htmlAndSubject);
                }
                if (incorrect.length > 0) {
                  setShowErrorVariablesModal(true);
                  setShowAIWriter(false);
                  setShowVariablesResult(false);
                  setShowEmailAwesomenessResult(false);
                  actions.setSubmitting(false);
                } else {
                  let data = {
                    id: props.templateIdFromSequence ? props.templateIdFromSequence : templateNameChanged ? 0 : templateId,
                    organizationId: props.orgId,
                    name: values.emailTemplateName,
                    subject: values.emailTemplateSubject,
                    text: values.emailTemplateText,
                    html: removeIncorrectVariableSpanTags(values.emailTemplateHtml),
                    chaserAddSignature: values.chaserAddSignature,
                    chaserIncludeThread: values.chaserIncludeThread,
                    languageMarketCode: languageChoice,
                    type: props.typeActiveStep != null ? props.typeActiveStep : values.emailTemplateType,
                    status: values.emailTemplateStatus,
                  };

                  api(API.emailTemplates.create, data)
                    .then((res) => {
                      if (fromCampaignSequence) {
                        props.selectEmails(res.data, props.activeStepId, data.name, data.type, data.status, data.subject, data.html);
                        setHasChanges(false);
                        props.handleHideSequenceFlow && props.handleHideSequenceFlow(false);
                      } else {
                        setHasChanges(false);
                        history.push(`/${props.orgId}/email-templates`);
                      }
                    })
                    .catch((error) => {
                      const errorList = errorResponseToList(error);
                      if (errorList.length > 0) {
                        errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                      } else {
                        toast.error(error.message);
                      }
                      toast.error(toastMessages.emailTemplate.errorCreateTemplate);
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });
                }
              }}
            >
              {(formikProps) => {
                const { values, setFieldValue, handleChange, handleSubmit } = formikProps;

                const handleSaveTemplate = (status) => {
                  if (!emailTemplate.emailTemplateSubject || !emailTemplate.emailTemplateName) {
                    handleSubmit();
                    props.handleHideSequenceFlow && props.handleHideSequenceFlow(true);
                    return;
                  }

                  if (status === emailTemplateStatusEnum.Publish) {
                    let htmlAndSubject = values.emailTemplateHtml.concat(" ", values.emailTemplateSubject);
                    let incorrect = markIncorrectVariables(htmlAndSubject);
                    if (incorrect.length > 0) {
                      setShowErrorVariablesModal(true);
                      setShowAIWriter(false);
                      setShowVariablesResult(false);
                      setShowEmailAwesomenessResult(false);
                      props.handleHideSequenceFlow && props.handleHideSequenceFlow(true);
                      return;
                    }
                  }
                  if (emailTemplate.emailTemplateStatus === emailTemplateStatusEnum.Publish && status === emailTemplateStatusEnum.Draft) {
                    setNewTextTemplate(values.emailTemplateHtml);
                    setSaveAsNew(true);
                    setSaveAsNewBackup(true);
                    history.push("/" + props.orgId + "/email-templates/create");
                    return;
                  }
                  setFieldValue("emailTemplateStatus", status);
                  handleSubmit();
                  props.handleHideSequenceFlow && props.handleHideSequenceFlow(false);
                };

                const prepareSaveTemplate = (status) => (event) => {
                  event.preventDefault();
                  if (
                    emailTemplate.emailTemplateHtml !== "" &&
                    emailTemplate.emailTemplateName !== "" &&
                    emailTemplate.emailTemplateSubject !== ""
                  ) {
                    setFieldValue("emailTemplateStatus", status);
                    setBlockNavigation(false);
                    setHasChanges(false);
                  }
                  handleSaveTemplate(status);
                };

                return (
                  <>
                    <FormikEffect onChange={() => onChange(values)} />
                    <div className={`m0 d-flex ${fromCampaignSequence ? styles.campaignMessageWrapper : ""}`}>
                      <div
                        className={`${styles.editorColumn} col ${props.isOnCreate ? styles.createEditorColumn : ""} ${
                          hasChanges || fromCampaignSequence ? styles.footerShown + " " + "sequenceFooter" : " "
                        }`}
                      >
                        <SectionTitle title="Message" icon={<SectionInbox />} titleClass="m0" />
                        <div id="templateDetailsForm" className={`${styles.templateFieldsWrapper} sequenceFlexEndRow`}>
                          <div className={styles.formCol}>
                            <div className="flex sequenceCreateEmail">
                              <FormikInput
                                label="Template Name:"
                                labelclass={styles.label}
                                name={"emailTemplateName"}
                                value={values.emailTemplateName}
                                placeholder="Type here..."
                                formgroupclass={`${styles.verticalFiledWrapper} ${styles.verticalFiled}`}
                                inputclass={styles.input}
                                errorinputclass={styles.emailTemplateErrorInput}
                              />
                              <RadioGroup
                                className={styles.radioGroupWrapper}
                                name="emailTemplateType"
                                value={
                                  (!props.typeActiveStep && values.emailTemplateType === emailTemplateTypeEnum.InitialEmail) ||
                                  props.typeActiveStep === emailTemplateTypeEnum.InitialEmail
                                    ? emailTemplateTypeEnum.InitialEmail
                                    : emailTemplateTypeEnum.FollowUp
                                }
                              >
                                <FormikRadio
                                  name="emailTemplateType"
                                  labelText="Initial"
                                  onChange={() => {
                                    setHasChanges(true);
                                    setFieldValue("emailTemplateType", emailTemplateTypeEnum.InitialEmail);
                                    setActiveStepAIPrompt(aiWriterStepsEnum.StartingStep);
                                    if (chaserCheckboxesShow) {
                                      setFieldValue("emailTemplateSubject", "");
                                    } else {
                                      setFieldValue("emailTemplateSubject", emailTemplateSubjectBackup);
                                    }
                                    setChaserCheckboxesShow(false);
                                  }}
                                  checked={
                                    (!props.typeActiveStep && values.emailTemplateType === emailTemplateTypeEnum.InitialEmail) ||
                                    props.typeActiveStep === emailTemplateTypeEnum.InitialEmail
                                  }
                                  disabled={props.typeActiveStep != null}
                                />
                                <FormikRadio
                                  id="followUp"
                                  name="emailTemplateType"
                                  labelText="Follow Up"
                                  onChange={() => {
                                    setHasChanges(true);
                                    setChaserCheckboxesShow(true);
                                    setFieldValue("chaserIncludeThread", true);
                                    setFieldValue("emailTemplateType", emailTemplateTypeEnum.FollowUp);
                                    setEmailTemplateSubjectBackup(values.emailTemplateSubject);
                                    setFieldValue("emailTemplateSubject", "FW: {{INITIAL_EMAIL_SUBJECT}}");
                                    setActiveStepAIPrompt(aiWriterStepsEnum.FollowUpStartingStep);
                                  }}
                                  checked={
                                    (!props.typeActiveStep && values.emailTemplateType === emailTemplateTypeEnum.FollowUp) ||
                                    props.typeActiveStep === emailTemplateTypeEnum.FollowUp
                                  }
                                  disabled={props.typeActiveStep != null}
                                />
                              </RadioGroup>
                            </div>

                            <div className={`mB20 ${styles.subjectFieldWrapper}`}>
                              <div className={`relative mR10 ${styles.verticalFiledWrapper}`}>
                                <FormikInput
                                  label="Subject:"
                                  labelclass={styles.label}
                                  name={"emailTemplateSubject"}
                                  value={values.emailTemplateSubject}
                                  placeholder="Quick intro - Outbase / {{COMPANY_CLEAN}}"
                                  formgroupclass={`${styles.verticalFiled} mB0`}
                                  inputclass={styles.input}
                                  errorinputclass={styles.emailTemplateErrorInput}
                                  onFocus={() => {
                                    setTemplateVariableInsertTo(templateVariableInsertToEnum.templateSubject);
                                  }}
                                />
                                {chaserCheckboxesShow && (
                                  <OverlayTrigger
                                    key={"subject-tooltip"}
                                    placement={"bottom"}
                                    overlay={
                                      <Popover id={`tooltip-subject`}>
                                        <Popover.Body>
                                          <div className={styles.subjectTooltipDiv}>
                                            <span>
                                              If you want to include the subject from the initial email, do it in the following format.
                                              <br />
                                              FW: <span>{initialEmailSubjectVariable}</span>
                                            </span>
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <span className={styles.infoIconWrapper}>
                                      <InformationFillIcon className="iconGray" size={18} />
                                    </span>
                                  </OverlayTrigger>
                                )}
                              </div>

                              <div className="flex">
                                <Button
                                  id="previewTemplateDetails"
                                  variant="dark"
                                  onClick={() => {
                                    setShowAIWriter(templateId === 0 || !showAIWriter);
                                    if (!showAIWriter && templateId !== 0) setSmartEdit(true);
                                    if (!showAIWriter && templateId === 0) {
                                      getAIPromptInitialInfo(values.emailTemplateType);
                                    }
                                  }}
                                  disabled={disableAiWriterButton}
                                >
                                  <IconSparkling imgStyle="mR10" fill="#fff" />
                                  {templateId !== 0 ? "Smart Edit" : "AI Writer"}
                                </Button>
                              </div>
                            </div>

                            {chaserCheckboxesShow ? (
                              <div className={`flex mB30 mR10 ${styles.checkBoxWrapper}`}>
                                <div className="flex mR15">
                                  <FormikCheckbox
                                    label="Include previous messages as thread (*)"
                                    name="chaserIncludeThread"
                                    checkBoxClass="mR5"
                                    labelstyle="m0 noWrap"
                                    checked={values.chaserIncludeThread}
                                  />
                                  <OverlayTrigger
                                    key={"recommended"}
                                    placement={"bottom"}
                                    overlay={
                                      <Popover id={`tooltip-subject`}>
                                        <Popover.Body>
                                          <div>
                                            <span>
                                              <b>Recommended:</b> This allows you to send short follow-up messages with the original email
                                              below, making it look like you have found the previous message in your sent folder and then
                                              forwarded this onto the prospect, with a nudge to chase them for a response.
                                              <br />
                                              FW: <span className={styles.subjectTooltipOrangeSpan}>{initialEmailSubjectVariable}</span>
                                            </span>
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <span>
                                      <InformationFillIcon className="iconGray" size={18} />
                                    </span>
                                  </OverlayTrigger>
                                </div>

                                <div className="flex">
                                  <FormikCheckbox
                                    label="Add signature to email"
                                    name="chaserAddSignature"
                                    checkBoxClass="mR5"
                                    labelstyle="m0 noWrap"
                                    // onChange={(checked) => {
                                    //   values.chaserAddSignature = checked;
                                    // }}
                                    checked={values.chaserAddSignature}
                                  />
                                  <OverlayTrigger
                                    key={"signature-tooltip"}
                                    placement={"bottom"}
                                    overlay={
                                      <Popover id={`tooltip-subject`}>
                                        <Popover.Body>
                                          <div>
                                            <span>
                                              When sending a follow up email as part of a thread, we recommend using a more natural, short
                                              and personalized closure, such as: <b>Best, &#123;&#123;Sender First Name&#125;&#125;</b> as
                                              your signature is included in the first message.
                                              <br />
                                              FW: <span className={styles.subjectTooltipOrangeSpan}>{initialEmailSubjectVariable}</span>
                                            </span>
                                          </div>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <span>
                                      <InformationFillIcon className="iconGray" size={18} />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <>
                          <input id="my-file" type="file" name="my-file" style={{ display: "none" }} onChange={null} />

                          <FormikInput
                            as={Editor}
                            apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            name="emailTemplateHtml"
                            className="tox-notifications-container"
                            value={values.emailTemplateHtml}
                            inputGroupClass={hasChanges || fromCampaignSequence ? "productTourTiny m0 pT5" : "productTourTiny m0 pT5"}
                            type="textarea"
                            rows="8"
                            init={init}
                            placeholder="Type here..."
                            onClick={(e) => removeNameDrop(e, editorRef.current)}
                            onFocus={() => {
                              setTemplateVariableInsertTo(templateVariableInsertToEnum.templateBody);
                            }}
                            onEditorChange={(content, value) => {
                              let contentHtml = value.getContent({
                                format: "html",
                              });
                              let contentText = value.getContent({
                                format: "text",
                              });

                              updateFormByEditor(setFieldValue, contentHtml, contentText);

                              if (contentText !== "" && contentText !== emailTemplateAwesomenessText) {
                                if (awesomenessTimeout !== 0) {
                                  clearTimeout(awesomenessTimeout);
                                }

                                if (emailTemplateAwesomenessText === "") {
                                  setEmailTemplateAwesomenessText(contentText);
                                } else {
                                  awesomenessTimeout = setTimeout(() => {
                                    setEmailTemplateAwesomenessText(contentText);
                                  }, 2000);
                                }
                              }
                            }}
                          />
                        </>
                        <div className={`${styles.messageSubjectWrapper} flex`}>
                          <div className={`${styles.emailTemplateTypeSelect} flex campaignSequenceClass`}>
                            <Button
                              id="previewTemplateAiWriter"
                              title="AI Writer"
                              classes={`${styles.templateCheckButton} ${showAIWriter ? styles.active : ""}`}
                              disabled={!aiWriterEnabled}
                              variant=""
                              onClick={() => {
                                if (templateId === 0) {
                                  if (showAIWriter === false) {
                                    setShowAIWriter(true);
                                    setShowEmailTemplatePreview(false);
                                    setShowVariablesResult(false);
                                    setShowEmailAwesomenessResult(false);
                                    if (
                                      activeStepAIPrompt === aiWriterStepsEnum.StartingStep ||
                                      activeStepAIPrompt === aiWriterStepsEnum.TemplateSettingsStep ||
                                      activeStepAIPrompt === aiWriterStepsEnum.FollowUpStartingStep
                                    ) {
                                      getAIPromptInitialInfo(values.emailTemplateType);
                                    } else if (activeStepAIPrompt === aiWriterStepsEnum.ChatBox) {
                                      getAllTweakTemplateLogs();
                                    }
                                  } else {
                                    setShowAIWriter(false);
                                  }
                                } else {
                                  if (showAIWriter === false) {
                                    setSmartEdit(true);
                                    setShowAIWriter(true);
                                    if (reopenAIPromptOnChatBox) {
                                      getAllTweakTemplateLogs();
                                    }
                                  } else {
                                    setShowAIWriter(false);
                                    setReopenAIPromptOnChatBox(true);
                                  }
                                }
                              }}
                            >
                              <IconSparkling fill={showAIWriter ? "#fff" : "#586473"} />
                              {showEmailAwesomenessResult === false &&
                              showVariablesResult === false &&
                              showEmailTemplatePreview === false &&
                              showAIWriter === false ? (
                                <span className={`mL10`}>AI Writer</span>
                              ) : null}
                            </Button>
                            <Button
                              id="previewTemplate"
                              title="Template Preview"
                              classes={`${styles.templateCheckButton} ${showEmailTemplatePreview ? styles.active : ""}`}
                              variant=""
                              onClick={() => {
                                if (showEmailTemplatePreview === false) {
                                  setShowEmailTemplatePreview(true);
                                  setShowAIWriter(false);
                                  setShowVariablesResult(false);
                                  setShowEmailAwesomenessResult(false);
                                } else {
                                  setShowEmailTemplatePreview(false);
                                }
                              }}
                            >
                              <IconMessageActive fill={showEmailTemplatePreview ? "#fff" : "#4A5263"} />
                              {showEmailAwesomenessResult === false &&
                              showAIWriter === false &&
                              showVariablesResult === false &&
                              showEmailTemplatePreview === false ? (
                                <span className="mL10">Template Preview</span>
                              ) : null}
                            </Button>
                            <Button
                              id="awesomeness"
                              title="Check Wording"
                              classes={`${styles.templateCheckButton} ${showEmailAwesomenessResult ? styles.active : ""}`}
                              variant=""
                              ariaLabel="Check awesomeness"
                              onClick={() => {
                                if (showEmailAwesomenessResult === false) {
                                  if (
                                    (emailTemplateAwesomenessText && emailTemplateAwesomenessText !== "") ||
                                    values.emailTemplateText !== ""
                                  ) {
                                    setShowEmailAwesomenessResult(true);
                                    setShowAIWriter(false);
                                    setShowVariablesResult(false);
                                    setShowEmailTemplatePreview(false);
                                    if (emailTemplateAwesomenessText !== values.emailTemplateText && values.emailTemplateText !== "") {
                                      setEmailTemplateAwesomenessText(values.emailTemplateText);
                                    }
                                    if (languageChoice === undefined || languageChoice === "") {
                                      if (emailTemplate.languageMarketCode) {
                                        setLanguageChoice(emailTemplate.languageMarketCode);
                                      } else {
                                        if (props.orgLocation !== UNITED_KINGDOM) {
                                          setLanguageChoice(usEnglish);
                                        } else {
                                          setLanguageChoice(britishEnglish);
                                        }
                                      }
                                      checkAwesomeness();
                                    } else {
                                      checkAwesomeness();
                                    }
                                  } else {
                                    toast.error(toastMessages.emailTemplate.awesomenessEmptyTextError);
                                  }
                                } else {
                                  setShowEmailAwesomenessResult(false);
                                }
                              }}
                            >
                              <MessageResponseType fill={showEmailAwesomenessResult ? "#fff" : "#586473"} />
                              {showEmailAwesomenessResult === false &&
                              showAIWriter === false &&
                              showVariablesResult === false &&
                              showEmailTemplatePreview === false ? (
                                <span className={`mL10`}>Check wording</span>
                              ) : null}
                            </Button>
                            <Button
                              id="variables_checker"
                              title="Variables"
                              classes={`${styles.templateCheckButton} ${showVariablesResult ? styles.active : ""}`}
                              variant=""
                              ariaLabel="Variable checker"
                              onClick={() => {
                                if (showVariablesResult === false) {
                                  setShowVariablesResult(true);
                                  setShowAIWriter(false);
                                  setShowEmailAwesomenessResult(false);
                                  setShowEmailTemplatePreview(false);
                                } else {
                                  setShowVariablesResult(false);
                                }
                              }}
                            >
                              <IconVariablesActive stroke={showVariablesResult ? "#fff" : "#586473"} />
                              {showVariablesResult === false &&
                              showAIWriter === false &&
                              showEmailAwesomenessResult === false &&
                              showEmailTemplatePreview === false ? (
                                <span className={`mL10`}>Variables</span>
                              ) : null}
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${
                          showVariablesResult === false &&
                          showEmailAwesomenessResult === false &&
                          showEmailTemplatePreview === false &&
                          showAIWriter === false
                            ? ""
                            : `${styles.resultDiv}`
                        } ${hasChanges || fromCampaignSequence ? styles.footerShown + " " + "sequenceFooter" : " "}`}
                      >
                        {showAIWriter ? (
                          <>
                            <div className={styles.sectionAIWriterTitle}>
                              <AIEmailGenerator
                                stepsWrapperClass={`${
                                  hasChanges
                                    ? styles.createFooterVisible
                                    : fromCampaignSequence
                                    ? "sequenceStepperContent"
                                    : styles.stepsHeight
                                } `}
                                stepperContentClass={`${styles.editModeWrapper} ${
                                  props.isOnCreate
                                    ? styles.fromCreateToEditInitial
                                    : hasChanges || fromCampaignSequence || !props.isOnCreate
                                    ? styles.stepperContentClassHeightEdit
                                    : ""
                                }`}
                                formEmailWrapperClass={hasChanges || fromCampaignSequence ? styles.formEmailWrapper : ""}
                                chatBoxClass={` ${
                                  !props.isOnCreate
                                    ? styles.editChatBoxHeight
                                    : fromCampaignSequence
                                    ? styles.sequenceSChatBoxWrapperFooterVisible
                                    : ""
                                }`}
                                emailTemplateAIRequest={initialPromptInfoResult}
                                setInitialPromptInfoResult={setInitialPromptInfoResult}
                                templateRequestId={templateRequestId}
                                setTemplateRequestId={setTemplateRequestId}
                                firstUrl={firstUrl}
                                setFirstUrl={setFirstUrl}
                                descriptionValue={descriptionValue}
                                setDescriptionValue={setDescriptionValue}
                                uspValue={uspValue}
                                setUspValue={setUspValue}
                                painPointValue={painPointValue}
                                setPainPointValue={setPainPointValue}
                                secondUrl={secondUrl}
                                setSecondUrl={setSecondUrl}
                                emailTemplateType={values.emailTemplateType}
                                emailTemplateId={props.templateId}
                                tinyMCE={tinyMCE}
                                formikProps={formikProps}
                                activeStep={activeStepAIPrompt}
                                setActiveStep={setActiveStepAIPrompt}
                                allAIRequestLogs={allAIRequestLogs}
                                reopenAIPromptOnChatBox={reopenAIPromptOnChatBox}
                                setReopenAIPromptOnChatBox={setReopenAIPromptOnChatBox}
                                createUpdateTemplateHasChanges={hasChanges}
                                hasChangesFromCampaignSequence={fromCampaignSequence}
                                setSaveAsNew={setSaveAsNew}
                                setNewTextTemplate={setNewTextTemplate}
                                setNewSubjectTemplate={setNewSubjectTemplate}
                                chaserCheckboxesShow={chaserCheckboxesShow}
                                smartEdit={smartEdit}
                                smartEditEnabled={smartEditEnabled}
                                setSmartEditEnabled={setSmartEditEnabled}
                                setAiWriterEnabled={setAiWriterEnabled}
                                setEmailTemplateSubjectBackup={setEmailTemplateSubjectBackup}
                                isNextDisabled={isNextDisabled}
                                setIsNextDisabled={setIsNextDisabled}
                                templateIdFromSequence={props.templateIdFromSequence}
                                setDisableAiWriterButton={setDisableAiWriterButton}
                              />
                            </div>
                          </>
                        ) : showEmailAwesomenessResult ? (
                          <EmailAwesomeResult
                            result={awesomenessResult}
                            showResults={showEmailAwesomenessResult}
                            fromCampaignSequence={fromCampaignSequence}
                            closeChecker={closeChecker}
                            emailAccounts={props.emailAccounts}
                            languageChoice={languageChoice}
                            setLanguageChoice={setLanguageChoice}
                            checkAwesomenessEmailAccountId={checkAwesomenessEmailAccountId}
                            setCheckAwesomenessEmailAccountId={setCheckAwesomenessEmailAccountId}
                            resultsListClass={`${fromCampaignSequence ? "resultsListHeight" : " "} ${
                              props.isOnCreate ? styles.createModeResultsWrapper : styles.editModeResultsWrapper
                            }`}
                          />
                        ) : showVariablesResult && tinyMCE != null ? (
                          <TemplateVariables
                            tinyMCE={tinyMCE}
                            emailTemplateHtml={values.emailTemplateHtml}
                            emailTemplateSubject={values.emailTemplateSubject}
                            formikProps={formikProps}
                            templateVariableInsertTo={templateVariableInsertTo}
                            organizationCustomColumns={organizationCustomColumns}
                            variablesListClass={`${chaserCheckboxesShow ? "variablesListHeight" : " "}`}
                            disableChaserVariables={
                              (!props.typeActiveStep && values.emailTemplateType === emailTemplateTypeEnum.InitialEmail) ||
                              props.typeActiveStep === emailTemplateTypeEnum.InitialEmail
                            }
                            templateVariablesBodyClass={
                              props.isOnCreate ? styles.createModeTemplateVariablesBody : styles.editModeTemplateVariablesBody
                            }
                          />
                        ) : showEmailTemplatePreview ? (
                          <MessagePreviewSidePanel
                            emailAccounts={props.emailAccounts}
                            sendTo={props.userEmail}
                            editorValue={values.emailTemplateHtml}
                            templateSubject={values.emailTemplateSubject}
                            values={values}
                            setShowTestEmailFormModal={props.setShowTestEmailFormModal}
                            disabledTestButton={
                              values.emailTemplateSubject.length === 0 || values.emailTemplateName.length === 0 ? true : false
                            }
                            checkForIncorrectVariables={checkForIncorrectVariables}
                            combineVariablesArray={combineVariablesArray}
                            hasChanges={hasChanges}
                            messageBodyPreview={props.isOnCreate ? styles.createModeMessageBody : styles.editModeMessageBody}
                            setShowErrorVariablesModal={setShowErrorVariablesModal}
                          />
                        ) : null}
                      </div>
                    </div>
                    <Row>
                      <Col className={styles.changesFooter} xl={12} lg={12} sm={12} xs={12}>
                        <SaveChangesFooter
                          cancelClick={onReset}
                          saveChangesClick={prepareSaveTemplate(emailTemplateStatusEnum.Publish)}
                          thirdButtonClick={prepareSaveTemplate(emailTemplateStatusEnum.Draft)}
                          thirdButtonDisabled={emailTemplate.emailTemplateHtml === ""}
                          cancelButtonDisabled={formikProps.isSubmitting}
                          saveButtonDisabled={emailTemplate.emailTemplateHtml === ""}
                          saveButtonText="Publish"
                          showThirdButton={!(emailTemplate.emailTemplateStatus === emailTemplateStatusEnum.Publish && fromCampaignSequence)}
                          thirdButtonText={
                            emailTemplate.emailTemplateStatus === emailTemplateStatusEnum.Publish ? "Save as new" : "Save as draft"
                          }
                        />
                      </Col>
                    </Row>
                  </>
                );
              }}
            </Formik>
          </Permission>
        </>
      );

      if (!fromCampaignSequence) {
        advanceEmailDeliverabilityTestModel = (
          <AdvanceEmailDeliverabilityTestModel
            handleClose={props.handleConfigurationTestModalClose}
            showModal={props.showConfigurationTestModal}
            emailTemplate={emailTemplate}
            templateId={templateId}
            templateDictionary={templateDictionary}
            setDictionaryValues={setDictionaryValues}
          />
        );
      }

      sendTestEmailForm = (
        <SendTestEmailForm
          handleClose={handleTestMailFormClose}
          showModal={showTestEmailFormModal}
          userEmail={props.userEmail}
          emailTemplate={emailTemplate}
          emailAccounts={props.emailAccounts}
          templateDictionary={templateDictionary}
          setDictionaryValues={setDictionaryValues}
          typeActiveStep={props.typeActiveStep}
        />
      );
    }
  }

  return (
    <>
      <Prompt when={hasChanges && blockNavigation} message={(location) => handleCancelModal(location)} />
      <div id="spinner" className={styles.spinner + " " + styles.hidden}>
        <Spinner spinnerClass="absolute transform-y-50" />
      </div>
      {emailTemplateComponent}
      <div>
        {advanceEmailDeliverabilityTestModel}
        {sendTestEmailForm}
      </div>
      <CancelWarningForm
        handleClose={handleCancelSequenceWarningClose}
        showModal={showCancelSequenceWarningModal}
        handleSave={handleCancelSequenceWarningSave}
      />
      <ErrorVariablesModal
        handleClose={handleCloseErrorVariablesModal}
        handleSubmit={() => setShowErrorVariablesModal(false)}
        showModal={showErrorVariablesModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.globalEmailTemplate.isLoading,
    isLoaded: state.globalEmailTemplate.isLoaded,
    error: state.globalEmailTemplate.error,
    orgId: state.user.organization.id,
    orgLocation: state.user.organization.location,
    userEmail: state.user.email,
    userFirstName: state.user.firstName,
    emailAccounts: state.globalEmailAccounts.listEmailAcconts,
    activeStepId: state.workflowSteps.activeStepId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActivePage: (page) => dispatch(setActivePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateEmailTemplate);
