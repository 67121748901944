import React from "react";

//Style
import styles from "./RulesetTemplateModal.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import RulesCard from "../../CreatedRulesList/RulesCard/RulesCard";

const RulesetTemplateModal = (props) => {
  const rulsetTemplate = () => {
    if (props.rulesetSelected) props.rulesetSelected(props.rulesetId);
  };
  if (!props.ruleset || props.ruleset == null || props.ruleset.length === 0)
    return null;

  return (
    <Modal
      title={props.rulesetId ? props.rulesetId : "Ruleset template"}
      handleClose={props.handleClose}
      handleSave={rulsetTemplate}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Use Ruleset"
      btnType="submit"
      size="lg"
      customBodyClass="rulesetModal"
    >
      <div className={styles.componentWrapper}>
        {props.ruleset.map((element) => {
          return (
            <RulesCard
              eventTitle={element.eventTypeDescription}
              actionTitle={element.actionCodeDescription}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default RulesetTemplateModal;
