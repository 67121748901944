import React, { FC } from "react";

// Style
import styles from "./GroupButtons.module.scss";

interface GroupButtonsProps {
  activeTab: number;
  size?: string;
  leftButtonName: string;
  rightButtonName: string;
  setActiveTab: (tab: number) => void;
}

const GroupButtons: FC<GroupButtonsProps> = ({ activeTab, size, leftButtonName, rightButtonName, setActiveTab }) => {
  let sizeClass = "";

  if (size != null) {
    sizeClass = styles[size];
  }

  return (
    <div className={styles.buttonGroup}>
      <button className={`${styles.leftButton} ${sizeClass} ${activeTab === 0 ? styles.active : ""}`} onClick={() => setActiveTab(0)}>
        {leftButtonName}
      </button>
      <button className={`${styles.rightButton} ${sizeClass} ${activeTab === 1 ? styles.active : ""}`} onClick={() => setActiveTab(1)}>
        {rightButtonName}
      </button>
    </div>
  );
};

export default GroupButtons;
