import { api } from "./api";
import { OrganizationSettingsModel } from "../models/organization/OrganizationSettingsModel";
import { DeleteOrganizationModel } from "@models/organization/DeleteOrganizationModel";
import { ReasonDeleteOrganizationModel } from "@models/organization/ReasonDeleteOrganizationModel";

const organizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<OrganizationSettingsModel, void>({
      query: () => "/organization/settings",
      providesTags: ["OrganizationSettings"],
    }),
    getDeleteAvailabilities: builder.mutation<Number, DeleteOrganizationModel>({
      query: (model) => ({
        url: "/organization/check-delete-availability",
        method: "POST",
        body: model,
      }),
      invalidatesTags: ["OrganizationSettings"],
    }),
    deleteReasons: builder.mutation<Number, ReasonDeleteOrganizationModel>({
      query: (model) => ({
        url: "/organization/delete-reasons",
        method: "DELETE",
        body: model,
      }),
      invalidatesTags: ["OrganizationSettings"],
    }),
  }),
});

export const { useGetSettingsQuery, useGetDeleteAvailabilitiesMutation, useDeleteReasonsMutation } = organizationApi;
