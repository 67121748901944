import React from "react";

const StagePic = (props) => {
  return (
    <svg
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M258 0H0L19.1484 72H238.852L258 0Z" fill="" />
    </svg>
  );
};

export default StagePic;
