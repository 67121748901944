import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isLoadingSendTest: false,
  isLoadedSendTest: false,
  errorSendTest: "",
  error: "",
  listOfBasicTests: [],
  sendStatus: 0,
  basicTestDetails: {},
};

const fetchListOfBasicEmailTestsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchListOfBasicEmailTestsSuccess = (state, action) => {
  const newState = { ...state };
  let list = [...state.listOfBasicTests];
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listOfBasicTests = list;
  return newState;
};

const fetchListOfBasicEmailTestsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const fetchBasicEmailSendTestRequest = (state, action) => {
  return {
    ...state,
    isLoadingSendTest: true,
    isLoadedSendTest: false,
    errorSendTest: "",
  };
};

const fetchBasicEmailSendTestSuccess = (state, action) => {
  //SEND TEST
  const newState = { ...state };
  let sendStatus = state.sendStatus;
  sendStatus = action.result;
  newState.isLoadedSendTest = true;
  newState.isLoadingSendTest = false;
  newState.errorSendTest = "";
  newState.sendStatus = sendStatus;
  return newState;
};

const fetchBasicEmailSendTestFailed = (state, action) => {
  //send
  const newState = { ...state };
  newState.errorSendTest = action.result;
  newState.isLoadingSendTest = false;
  newState.isLoadedSendTest = true;
  return newState;
};

const fetchBasicEmailTestDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchBasicEmailTestDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let basicTestDetails = { ...state.basicTestDetails };
  basicTestDetails = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.basicTestDetails = basicTestDetails;
  return newState;
};

const fetchBasicEmailTestDetailsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const clearInitiated = (state, action) => {
  const newState = { ...state };

  newState.isLoaded = false;
  newState.isLoading = false;
  newState.isLoadedSendTest = false;
  newState.isLoadingSendTest = false;

  newState.errorSendTest = "";
  newState.error = "";
  newState.listOfBasicTests = [];
  newState.sendStatus = 0;
  newState.basicTestDetails = {};

  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BASIC_EMAIL_TEST_DETAILS_REQUEST:
      return fetchBasicEmailTestDetailsRequest(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_TEST_DETAILS_SUCCEEDED:
      return fetchBasicEmailTestDetailsSuccess(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_TEST_DETAILS_FAILED:
      return fetchBasicEmailTestDetailsFailed(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_SEND_TEST_REQUEST:
      return fetchBasicEmailSendTestRequest(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_SEND_TEST_SUCCEEDED:
      return fetchBasicEmailSendTestSuccess(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_SEND_TEST_FAILED:
      return fetchBasicEmailSendTestFailed(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_TEST_LIST_REQUEST:
      return fetchListOfBasicEmailTestsRequest(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_TEST_LIST_SUCCEEDED:
      return fetchListOfBasicEmailTestsSuccess(state, action);
    case actionTypes.FETCH_BASIC_EMAIL_TEST_LIST_FAILED:
      return fetchListOfBasicEmailTestsFailed(state, action);
    case actionTypes.BASIC_EMAIL_TEST_CLEAR_INITIATED:
      return clearInitiated(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
