import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Styles
import styles from "./DraftEmailTemplateWarningModal.module.scss";

//Components
import Modal from "../../../../../../components/UI/Modal/Modal";

const DraftEmailTemplateWarningModal = (props) => {
  return (
    <Modal
      dialogClassName="customModal"
      title={"Discard Changes"}
      handleSave={props.handleSave}
      show={props.showModal}
      saveButtonText="OK"
      btnType="submit"
      oneButton
      size="md"
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.wizzardContent}>
            <p>
              Draft email templates cannot be added to an Active Campaign. Please review and publish the email templates you want to use and
              try again.
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DraftEmailTemplateWarningModal;
