import React from "react";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import MixBarChart from "../../../components/MixBarChart/MixBarChart";
import PieChartAngle from "../../../components/PieChartAngle/PieChartAngle";
import AreaLineChart from "../../../components/AreaLineChart/AreaLineChart";
import StackedBarChart from "../../../components/StackedBarChart/StackedBarChart";

const ChartSandbox = () => {
  ////you can get this from: runRatesReport: state.reports.runRatesReport,
  //// call  fetchRunRatesReport: (val) => dispatch(fetchRunRatesReport(val)),
  const dataBarChart = [
    { name: "September", newEngagements: 60, chasers: 245 },
    { name: "August", newEngagements: 100, chasers: 350 },
    { name: "July", newEngagements: 75, chasers: 145 },
    { name: "June", newEngagements: 400, chasers: 400 },
    { name: "May", newEngagements: 278, chasers: 456 },
    { name: "April", newEngagements: 0, chasers: 0 },
    { name: "March", newEngagements: 0, chasers: 0 },
    { name: "February", newEngagements: 56, chasers: 278 },
    { name: "January", newEngagements: 78, chasers: 25 },
    { name: "December", newEngagements: 0, chasers: 0 },
    { name: "November", newEngagements: 0, chasers: 0 },
    { name: "October", newEngagements: 0, chasers: 0 },
  ];

  //you can get this from statusCount: state.globalProspects.statusCount
  // call  fetchStatusCount: () => dispatch(fetchStatusCount()),
  const dataPieChart = {
    awaitingEngagement: 26,
    engaged: 6,
    opened: 1,
    clicked: 1,
    responded: 1,
    leads: 1,
  };
  //you can get this from organizationReport: state.reports.organizationReport.reportChartModel...openrate, clikRate...
  //call  fetchOrganizationReport: (val) => dispatch(fetchOrganizationReport(val)),
  const dataAreaLine = [
    { name: "2020, August", value: 50 },
    { name: "2020, July", value: 70 },
    { name: "2020, June", value: 78 },
    { name: "2020, May", value: 98 },
  ];

  const barsList = [
    {
      stackId: "legendOne",
      legendType: "circle",
      barSize: "12",
      name: "Legend 1",
      dataKey: "chasers",
      fill: "#FE983E",
    },
    {
      stackId: "legendTwo",
      legendType: "circle",
      barSize: "12",
      name: "Legend 2",
      dataKey: "newEngagements",
      fill: "#D80F6F",
    },
  ];
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  return (
    <>
      <SectionTitle title="<MixBarChart>" />
      <MixBarChart data={dataBarChart} bar={barsList} />

      <SectionTitle title="<PieChartAngle>" />
      <PieChartAngle
        data={dataPieChart}
        colors={colors}
        height="200px"
        outerRadius={60}
        innerRadius={80}
      />

      <SectionTitle title="<AreaLineChart>" />
      <AreaLineChart chartWidth={150} chartHeight={50} data={dataAreaLine} />

      <SectionTitle title="<StackBarChart>" />
      <StackedBarChart data={dataBarChart} bar={barsList} />
    </>
  );
};

export default ChartSandbox;
