import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

//Redux
import {
  changePage,
  fetchResponsesByCampaign,
  initiate,
  numRecords,
} from "../../../../store/Dashboard/Tables/actions/ResponsesCampaignView/ResponsesByCampaign";

//Helpers
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";
import { addOffsetToDate } from "../../../../helper/dateHelper";
import { formatNumberHelper } from "../../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../../helper/hooks/useGroupDecimalSeparator";

//Styles
import styles from "./DashboardResponsesByCampaign.module.scss";

//Components
import Table from "../../../../components/DataTable/Table";
import ProgressLine from "../../../../components/UI/ProgressLine/ProgressLine";

const DashboardResponsesByCampaign = (props) => {
  const { startDate, endDate, params, isInitiated, rows, totalCount, pageCount, fetchResponsesByCampaign } = props;
  const [tableRows, setTableRows] = useState([]);
  const organizationId = useOrganizationId();
  let table = null;
  let separators = useGroupDecimalSeparator();
  const shouldShowPagination = totalCount > 5;

  useEffect(() => {
    let state = {
      params: {
        length: 5,
      },
    };

    props.initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchResponsesByCampaign(addOffsetToDate(startDate), addOffsetToDate(endDate), params);
    }
  }, [params, isInitiated, startDate, endDate]);

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r, index) => {
      let responseData = [];

      if (r.totalResponseRateData != null) {
        if (!isNaN(r.totalResponseRateData.positiveResponse) && r.totalResponseRateData.positiveResponse !== 0) {
          responseData.push({
            color: `var(--lead-color)`,
            percentage: `${r.totalResponseRateData.positiveResponsePercentage}`,
            label: `${r.totalResponseRateData.positiveResponsePercentage.toFixed(2)}% (${formatNumberHelper(
              r.totalResponseRateData.positiveResponse,
              separators.numberGroupSeperator,
              separators.numberDecimalSeperator
            )})`,
          });
        }

        if (!isNaN(r.totalResponseRateData.deferredInterest) && r.totalResponseRateData.deferredInterest !== 0) {
          responseData.push({
            color: `var(--responded-color)`,
            percentage: `${r.totalResponseRateData.deferredInterestPercentage}`,
            label: `${r.totalResponseRateData.deferredInterestPercentage.toFixed(2)}% (${formatNumberHelper(
              r.totalResponseRateData.deferredInterest,
              separators.numberGroupSeperator,
              separators.numberDecimalSeperator
            )})`,
          });
        }

        if (!isNaN(r.totalResponseRateData.referral) && r.totalResponseRateData.referral !== 0) {
          responseData.push({
            color: `var(--opened-color)`,
            percentage: `${r.totalResponseRateData.referralPercentage}`,
            label: `${r.totalResponseRateData.referralPercentage.toFixed(2)}% (${formatNumberHelper(
              r.totalResponseRateData.referral,
              separators.numberGroupSeperator,
              separators.numberDecimalSeperator
            )})`,
          });
        }

        if (!isNaN(r.totalResponseRateData.decline) && r.totalResponseRateData.decline !== 0) {
          responseData.push({
            color: `var(--engaged-color)`,
            percentage: `${r.totalResponseRateData.declinePercentage}`,
            label: `${r.totalResponseRateData.declinePercentage.toFixed(2)}% (${formatNumberHelper(
              r.totalResponseRateData.decline,
              separators.numberGroupSeperator,
              separators.numberDecimalSeperator
            )})`,
          });
        }
      }

      r.data = (
        <div className={styles.progressBarWrapper} key={index}>
          <div className={styles.titleWrapper}>
            <NavLink className="tableLink" to={`/${organizationId}/campaigns/${r.id}/overview`}>
              <span className={styles.title}>{r.campaignName}</span>
            </NavLink>
          </div>
          <div className={styles.progressBarList}>
            <ProgressLine data={responseData} />
          </div>
        </div>
      );

      return r;
    });

    setTableRows(tempRows);
  }, [rows]);

  table = (
    <Table
      tableName={"Responses By Campaign"}
      columns={columns}
      rows={tableRows}
      pageCount={pageCount}
      totalCount={shouldShowPagination}
      parentCallback={props.changePage}
      param={params}
      changeNumRecords={props.numRecords}
      tableClassName={styles.accordionTable}
      tableFooterClass={styles.stylePagination}
      scrollY={false}
      wrapperTableClass="no-header-table"
      variant="tableWithComponents"
      showPerPageContent={false}
      noRecordsFoundLabel={
        <span>
          No campaign information to display.{" "}
          <Link className="tableLink" to={{ pathname: "/" + organizationId + "/campaigns" }}>
            Click here to view or add a new campaign
          </Link>
        </span>
      }
    />
  );

  return <div className={styles.accordionWrapper}>{table}</div>;
};

const mapStateToProps = (state) => {
  return {
    params: state.responsesByCampaignTable.params,
    isInitiated: state.responsesByCampaignTable.isInitiated,
    rows: state.responsesByCampaignTable.rows,
    totalCount: state.responsesByCampaignTable.totalCount,
    pageCount: state.responsesByCampaignTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResponsesByCampaign: (startDate, endDate, params) => dispatch(fetchResponsesByCampaign(startDate, endDate, params)),
    initiate: (state) => dispatch(initiate(state)),
    changePage: (page) => dispatch(changePage(page)),
    numRecords: (obj) => dispatch(numRecords(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardResponsesByCampaign);
