import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { useAuth } from "react-oidc-context";

//Redux
import { fetchGlobalSettings } from "@store/global/actions/index";

//Helpers
import api, { API } from "@helper/api/api";
import { errorResponseToList } from "@helper/errorHelper";
import { toastMessages } from "@helper/toastMessagesConstants";
import { jobTitlesOptions } from "@helper/enums/jobTitlesOptions";

//Styles
import styles from "./Account.module.scss";

//Components
import FormikInput from "@components/UI/Formik/FormikInput";
import ImageUploader from "@components/UI/FileUploader/ImageUploader";
import UserPhoto from "@components/UI/UserPhoto/UserPhoto";
import SaveChangesFooter from "@components/SaveChangesFooter/SaveChangesFooter";
import FormikEffect from "@components/UI/Formik/FormikEffect";
import FormikSelect from "@components/UI/Formik/FormikSelect";
import Spinner from "@components/UI/Spinner/Spinner";

const Account = (props) => {
  const { countries, isLoaded } = props;
  const auth = useAuth();

  const [imageName, setImageName] = useState(props.imageUrl);
  const [hasNewImage, setHasNewImage] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const onChange = (values) => {
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      props.setUpdateAccount(values);
      setHasChanges(true);
      props.setUpdateAccountImage(true);
    }
  };

  const country = countries?.find((c) => c.label === props.location);
  const location = country?.value ?? "";

  const job = jobTitlesOptions?.find((c) => c.label === props.jobTitle);
  const jobTitle = job?.value;

  const initialValues = {
    firstName: props.firstName,
    lastName: props.lastName,
    jobTitle: isNaN(Number(props.jobTitle)) ? jobTitle : Number(props.jobTitle),
    location: location,
    image: props.imageUrl,
    hasNewImage: hasNewImage,
  };

  return isLoaded ? (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        firstName: Yup.string().required("First name is required").max(50, "Maximum 50 characters"),
        lastName: Yup.string().required("Last name is required").max(50, "Maximum 50 characters"),
        jobTitle: Yup.string().nullable().required("Job title is required"),
        location: Yup.string().nullable().required("Location is required"),
      })}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        let data = new FormData();
        data.append("firstName", values.firstName);
        data.append("lastName", values.lastName);
        data.append("jobTitle", values.jobTitle);
        data.append("location", values.location);
        values.image = values.image === props.imageUrl ? "" : values.image;
        data.append("image", values.image);
        data.append("hasNewImage", values.image !== "");
        api(API.account.editUser, data)
          .then(() => {
            auth.signinSilent();
            toast.success(toastMessages.users.successEditUser);
          })
          .catch((err) => {
            const errorList = errorResponseToList(err);
            if (errorList.length > 0) {
              errorList.forEach((e) => (e.field === "image" ? toast.error(e.message) : actions.setFieldError(e.field, e.message)));
            } else {
              toast.error(err.message);
            }
          });
      }}
      onReset={() => {
        setHasChanges(false);
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        return (
          <Form>
            <FormikEffect onChange={onChange(values)} />
            <div className={styles.formWrapper}>
              <div className={`${styles.imageWrapper} flex`}>
                <UserPhoto alt="user-photo" size="large" src={imageName} />
                <div className="mL10">
                  <p className={styles.organizationName}>Profile image</p>
                  <ImageUploader
                    buttonText="Update image"
                    variant="primary-s"
                    name="image"
                    handleFile={(file) => {
                      const reader = new FileReader();
                      if (file) {
                        reader.onloadend = function () {
                          const isImageType = file?.type.includes("image");
                          if (!isImageType) return;
                          setFieldValue("image", file);
                          props.setUpdateAccountImage(true);
                          setImageName([reader.result]);
                          setHasNewImage(false);
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </div>
              </div>

              <div className={styles.formsContainer}>
                <div className={styles.nameDetails}>
                  <FormikInput
                    formgroupclass="mR15"
                    placeholder="Type here..."
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                  />
                  <FormikInput placeholder="Type here..." name="lastName" label="Last Name" value={values.lastName} />
                </div>
                <FormikSelect
                  placeholder="Select Job Title..."
                  name="jobTitle"
                  label="Job Title"
                  value={values.jobTitle}
                  options={jobTitlesOptions}
                  isSearchable={true}
                  isMenuPortalTargetDisabled={true}
                />
                <FormikSelect
                  placeholder="Select Location..."
                  name="location"
                  label="Location"
                  value={values.location}
                  options={props.countries}
                  isSearchable={true}
                  isMenuPortalTargetDisabled={true}
                />
              </div>
              <Container fluid></Container>
            </div>
            <Container className={styles.general}>
              <Row>
                <Col className={styles.changesFooter} xl={12} lg={12} sm={12} xs={12}>
                  {hasChanges ? (
                    <SaveChangesFooter cancelClick={formikProps.handleReset} saveChangesClick={formikProps.handleSubmit} />
                  ) : null}
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    imageUrl: state.user.imageUrl,
    jobTitle: state.user.jobTitle,
    location: state.user.location,
    organizationId: state.user.organization.id,
    globalSettings: state.global.globalSettings,
    countries: state.global.globalSettings.countries,
    isLoaded: state.global.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
