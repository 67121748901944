import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Helpers
import { errorResponseToList } from "../../../../../helper/errorHelper";
import api, { API, formUrl } from "../../../../../helper/api/api";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const DeleteBillingAddressModal = (props) => {
  const deleteBillingInfo = () => {
    api(
      formUrl(API.billingAndPayment.deleteBillingInfo, {
        id: props.billingInfoId,
      })
    )
      .then((res) => {
        props.handleDeleteBillingInfo();
        toast.success(toastMessages.billing.successDeleteBillingInfo);
      })
      .catch((err) => {
        const errorList = errorResponseToList(err);
        if (errorList.length > 0) {
          errorList.forEach((err) => toast.error(err.message));
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Modal
      title={"Delete Step"}
      handleClose={props.handleClose}
      handleSave={deleteBillingInfo}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>Are you sure you want to delete the billing info</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DeleteBillingAddressModal;
