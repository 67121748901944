import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { addSalesDocumentsVideoSuccess } from "../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import FormikInput from "../../../components/UI/Formik/FormikInput";

const AddVideoForm = (props) => {
  return (
    <Formik
      initialValues={{
        video: "",
        description: "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        video: Yup.string().required("Please enter video embeded code"),
        description: Yup.string().required("Please enter video title"),
      })}
      onSubmit={(values, actions) => {
        let model = {
          video: values.video,
          description: values.description,
        };
        api(API.salesDocument.createVideo, model)
          .then((response) => {
            props.addSalesDocumentsVideoSuccess(response.data);
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <Modal
            title="Add New Video"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <FormikInput label="Video: " name="video" type="textarea" placeholder="Embedded video code" />
              <FormikInput label="Title: " name="description" type="text" placeholder="Title" />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSalesDocumentsVideoSuccess: (video) => {
      dispatch(addSalesDocumentsVideoSuccess(video));
    },
  };
};

export default connect(null, mapDispatchToProps)(AddVideoForm);
