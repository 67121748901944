import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Redux
import {
  fetchSalesDocumentsTable,
  changePage,
  sortData,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  numRecords,
} from "../../../../store/SalesDocuments/Table/actions/salesDocuments";
import { removeSalesDocuments } from "../../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { numberOfRecords, getFilterValue, filterData } from "../../../../helper/tableConstants";
import { documentsTableColumns } from "./documentsTableColumns";
import calculationOfSize from "../../../../helper/binaryCalculationOfFileSize";
import { PERMISSIONS } from "../../../../helper/permissionConstants";
import { createDocumentUrl } from "../../../../helper/fileHelper";
import useSelectList from "../../../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../../../helper/hooks/query/SelectListEnum";

//Styles
import styles from "./DocumentsTable.module.scss";

//Images & Icons
import EmptyTemplatesImage from "../../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

//Components
import Table from "../../../../components/DataTable/Table";
import Button from "../../../../components/UI/Button/Button";
import AddDocumentForm from "../../Modals/AddDocumentForm";
import Permission from "../../../../components/auth/Permission";
import ChangeDocumentForm from "../../Modals/ChangeDocumentForm";
import TableFiltersRow from "../../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";

const DocumentsTable = (props) => {
  const { params, rows, fetchSalesDocumentsTable, initiate, isInitiated, removeSalesDocuments } = props;

  const { data: listOfTags } = useSelectList(SelectListEnum.ListOfTags);

  const records = numberOfRecords[1];
  const [tableRows, setTableRows] = useState([]);

  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const handleAddDocumentClose = () => {
    setShowAddDocumentModal(false);
    fetchSalesDocumentsTable(params);
  };
  const handleAddDocumentShow = () => setShowAddDocumentModal(true);

  const [updateDocument, setChangeDocument] = useState({});

  const [showChangeDocumentModal, setShowChangeDocumentModal] = useState(false);
  const handleChangeDocumentModalClose = () => {
    setShowChangeDocumentModal(false);
    fetchSalesDocumentsTable(params);
  };

  const handleChangeDocumentShow = (document) => {
    setChangeDocument(document);
    setShowChangeDocumentModal(true);
  };

  let image = <EmptyTemplatesImage />;

  let table = null;

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchSalesDocumentsTable(params);
    }
  }, [params, isInitiated]);

  //#region Rows
  const deleteFile = (id) => {
    removeSalesDocuments(id).then((response) => fetchSalesDocumentsTable(params));
  };

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r) => {
      const currentDocument = { ...r };
      let tags = Array.from(r.tags);
      let id = r.id;
      let url = r.url;
      let name = r.name;
      let size = calculationOfSize(r.size);

      r.name = (
        <>
          <OverlayTrigger
            key={name}
            placement="bottom"
            overlay={
              <Popover id={`tooltip-${name}`}>
                <Popover.Body>{name}</Popover.Body>
              </Popover>
            }
          >
            <a className="tableLink" href={createDocumentUrl(r)} download={name}>
              {/* <i className="fa fa-file"></i> */}
              {name}
            </a>
          </OverlayTrigger>
        </>
      );
      r.size = size;
      r.tags = (
        <>
          {Array.isArray(tags) && tags.length === 0 ? (
            "/"
          ) : (
            <OverlayTrigger
              key={tags}
              placement="bottom"
              overlay={
                <Popover id={`tooltip-${tags}`}>
                  <Popover.Body>
                    {tags.map((tag, index) => (
                      <span key={index} style={{ marginRight: "1px" }}>
                        {tag}
                      </span>
                    ))}
                  </Popover.Body>
                </Popover>
              }
            >
              <ul className="max-x-content">
                {tags.map((tag, index) => (
                  <li key={index}>
                    <span className={styles.tagsStyle}>{tag}</span>
                  </li>
                ))}
              </ul>
            </OverlayTrigger>
          )}
        </>
      );
      r.comment = <>{r.comment.length === 0 ? "/" : <span>{r.comment}</span>}</>;

      let actions = (
        <div className={styles.actionWrapper}>
          <Permission has={PERMISSIONS.salesDocuments.documents.edit}>
            <div className="cursor_pointer mR5" onClick={() => handleChangeDocumentShow(currentDocument)}>
              <Edit2LineIcon className="iconGray" size={15} />
              {/* <EditRounded
                className={styles.editIcon}
                onClick={() => handleChangeDocumentShow(currentDocument)}
              /> */}
            </div>
          </Permission>
          <Permission has={PERMISSIONS.salesDocuments.documents.delete}>
            <div className="cursor_pointer" onClick={() => deleteFile(id)}>
              <DeleteBin6LineIcon className="iconGray" size={15} />
              {/* <DeleteIcon
                className={styles.deleteIcon}
                onClick={() => deleteFile(id)}
              /> */}
            </div>
          </Permission>
        </div>
      );
      r.actions = actions;

      return r;
    });

    setTableRows(tempRows);
  }, [rows]);
  //#endregion

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Sales Documents"}
          // tableClassName={styles.documentsTable}
          columns={documentsTableColumns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={params}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          // scrollY={false}
          variant="small"
          tableFooterClass="relative p0"
        />
      ) : (
        <EmptyState image={image} title="No documents found" />
      );
  }

  const buttons = (
    <Permission has={PERMISSIONS.salesDocuments.documents.create}>
      <Button id="addDocument" variant="primary" onClick={handleAddDocumentShow}>
        Add document
      </Button>
    </Permission>
  );

  let selectRowOptions =
    // tableRows.length > 0
    //   ?
    [
      {
        option: listOfTags,
        change: (t) => filterData("Tags", t, props.setFilter),
        placeholder: "Filter by Tag",
        value: getFilterValue(params.filter, "Tags"),
      },
    ];
  // : null;

  return (
    <>
      <TableFiltersRow rightContent={buttons} selects={selectRowOptions} placeholder="documents" />

      {table}

      <AddDocumentForm handleClose={handleAddDocumentClose} showModal={showAddDocumentModal} />

      {!_.isEmpty(updateDocument) && updateDocument.id > 0 ? (
        <ChangeDocumentForm
          handleClose={handleChangeDocumentModalClose}
          showModal={showChangeDocumentModal}
          id={updateDocument.id}
          tags={updateDocument.tags}
          comment={updateDocument.comment}
          name={updateDocument.name}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.salesDocumentsTable.params,
    isInitiated: state.salesDocumentsTable.isInitiated,
    isLoading: state.salesDocumentsTable.isLoading,
    isLoaded: state.salesDocumentsTable.isLoaded,
    error: state.salesDocumentsTable.error,
    rows: state.salesDocumentsTable.rows,
    totalCount: state.salesDocumentsTable.totalCount,
    pageCount: state.salesDocumentsTable.pageCount,
    // listOfTags: state.salesDocuments.listOfTags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesDocumentsTable: (param) => dispatch(fetchSalesDocumentsTable(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    removeSalesDocuments: (id) => dispatch(removeSalesDocuments(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);
