import React, { useState, useEffect } from "react";
import Moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

//Redux
import { connect } from "react-redux";
import {
  fetchInvoices,
  changePage,
  sortData,
  numRecords,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  clearInitiated,
} from "../../../../../store/BillingAndPayment/InvoicesTable/actions/invoices";
import { fetchCreditInfo } from "../../../../../store/BillingAndPayment/actions/billingAndPayment";
import { columns } from "./columns";

//Helpers
import api, { API } from "../../../../../helper/api/api";
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";
import { useGroupDecimalSeparator } from "../../../../../helper/hooks/useGroupDecimalSeparator";
import { formatNumbers, pricingPlanEnum, currencySign } from "../../../../../helper/pricingPlanHelper";
import { termsAndPaymentStatusEnum } from "../../../../../helper/enums/contractStatusEnum";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

//Images & Icons
import EmptyTemplatesImage from "../../../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

//Style
import styles from "./InvoicesTable.module.scss";

//Components
import Table from "../../../../../components/DataTable/Table";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import EmptyState from "../../../../../components/UI/EmptyState/EmptyState";
import AlertNotification from "../../../../../components/UI/AlertNotification/AlertNotification";

const InvoicesTable = (props) => {
  let dateFormat = useOrgDateFormat();

  const [tableRows, setTableRows] = useState([]);
  const [alert, setAlert] = useState(null);
  const {
    params,
    rows,
    initiate,
    isInitiated,
    contractStatus,
    isLoading,
    isLoaded,
    fetchInvoices,
    plan,
    waitingDowngrade,
    nextBillingDate,
    nextPlanName,
    nextPlanId,
    fetchCreditInfo,
  } = props;

  useEffect(() => {
    if (nextPlanId !== 0 && contractStatus === termsAndPaymentStatusEnum.accepted && plan !== pricingPlanEnum.trial) {
      let message = "We are processing the payment and your information will be updated shortly";
      if (waitingDowngrade)
        message =
          "We've received your request. Changes to your " +
          nextPlanName +
          " Plan are scheduled and will take place at the end of current billing period on " +
          Moment(nextBillingDate).format(dateFormat);
      const alertNotification = (
        <AlertNotification
          showButton={waitingDowngrade}
          buttonText="Cancel"
          onClick={cancelScheduledDowngrade}
          variant="info_alert"
          notificationWrapperClass={styles.billingInfoNotification}
          dismissible="dismissible"
        >
          {message}
        </AlertNotification>
      );
      setAlert(alertNotification);
    } else if (alert != null) {
      setAlert(null);
    }
  }, [tableRows, nextPlanId]);

  let image = <EmptyTemplatesImage className={styles.emptyDataImage} />;

  let state = {
    params: {
      filter: [],
      length: 10,
      search: "",
      page: 0,
    },
  };

  useEffect(() => {
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchInvoices(params);
    }
  }, [params, isInitiated]);

  let table = null;
  let separators = useGroupDecimalSeparator();

  const cancelScheduledDowngrade = () => {
    api(API.billingAndPayment.cancelScheduledDowngrade)
      .then((response) => {
        props.fetchBillingInfo();
        fetchCreditInfo();
        toast.success(toastMessages.billing.cancelScheduledDowngradeSuccess);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);

    rowsTemp.map((r) => {
      r.issueDate = <span className={styles.data}>{Moment(r.issueDate).format(dateFormat)}</span>;

      r.pricingPlan = (
        <span className={styles.data}>
          {r.isOneTimePurchase ? (
            r.pricingPlan
          ) : (
            <>
              Plan: <span className={styles.freePlan}>{r.pricingPlan}</span>
            </>
          )}
        </span>
      );

      r.amountTotal = (
        <span className={`${styles.data} ${styles.balance}`}>
          {currencySign[r.currencyId]} {formatNumbers(r.amountTotal, separators)} {r.isProratedInvoice ? "prorated" : ""}
        </span>
      );

      let publicUrl = r.publicUrl;
      r.publicUrl = (
        <span className={styles.data}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => {
              let data = {
                clickedInvoiceUrl: publicUrl,
              };
              api(API.billingAndPayment.updateInvoiceUrl, data)
                .then((res) => {
                  if (res.data !== "") {
                    var link = document.createElement("a");
                    link.href = res.data;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                  }
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }}
          >
            PDF
          </a>
        </span>
      );

      r.status = <span className={`${styles.data} ${styles.pending}`}>{r.status.charAt(0).toUpperCase() + r.status.slice(1)}</span>;
      return r;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (isLoading) {
    table = <Spinner />;
  }

  if (isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          wrapperTableClass="evenRowDark"
          tableName={"Invoices"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={params}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          click={() => {}}
          tableClassName={styles.invoiceTable}
          tableFooterClass={styles.pagination}
          scrollY={true}
          scrollX={false}
          tableHeight={495}
          variant="small"
        />
      ) : (
        <EmptyState image={image} title="No payment history found" />
      );
  }

  return (
    <div className={styles.componentWrapper}>
      {alert}
      {table}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.invoices.params,
    isInitiated: state.invoices.isInitiated,
    isLoading: state.invoices.isLoading,
    isLoaded: state.billingAndPayment.isLoaded,
    error: state.invoices.error,
    rows: state.invoices.rows,
    totalCount: state.invoices.totalCount,
    pageCount: state.invoices.pageCount,
    plan: state.billingAndPayment.creditInfo.plan,
    nextPlanId: state.billingAndPayment.creditInfo.nextPlanId,
    // contractStatus: state.billingAndPayment.contractStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
    fetchInvoices: (params) => dispatch(fetchInvoices(params)),
    fetchCreditInfo: () => dispatch(fetchCreditInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable);
