import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";
import moment from "moment";

const formatDates = (startDate, endDate) => {
  const data = {
    startDate:
      startDate != null
        ? moment(startDate).format("YYYY-MM-DD")
        : moment("2021-01-01").format("YYYY-MM-DD"),
    endDate:
      endDate != null
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
  };
  return data;
};

export const fetchTotalDataAndAudienceRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_DATA_AND_AUDIENCE_REQUEST,
  };
};

export const fetchTotalDataAndAudienceSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_TOTAL_DATA_AND_AUDIENCE_SUCCEEDED,
    result: setup,
  };
};

export const fetchTotalDataAndAudienceFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_DATA_AND_AUDIENCE_FAILED,
    result: error,
  };
};

export const fetchTotalDataAndAudience = () => {
  return (dispatch) => {
    dispatch(fetchTotalDataAndAudienceRequest());
    return api(API.dashboard.getTotalDataAndAudience)
      .then((response) => {
        dispatch(fetchTotalDataAndAudienceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTotalDataAndAudienceFailed(error.message));
      });
  };
};

export const fetchCampaignsInfoRequest = () => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS_INFO_REQUEST,
  };
};

export const fetchCampaignsInfoSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS_INFO_SUCCEEDED,
    result: setup,
  };
};

export const fetchCampaignsInfoFailed = (error) => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS_INFO_FAILED,
    result: error,
  };
};

export const fetchCampaignsInfo = () => {
  return (dispatch) => {
    dispatch(fetchCampaignsInfoRequest());
    return api(API.dashboard.getCampaignsInfo)
      .then((response) => {
        dispatch(fetchCampaignsInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCampaignsInfoFailed(error.message));
      });
  };
};

export const fetchTotalCampaignsPerformanceRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_CAMPAIGN_PERFORMANCE_REQUEST,
  };
};

export const fetchTotalCampaignsPerformanceSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_TOTAL_CAMPAIGN_PERFORMANCE_SUCCEEDED,
    result: setup,
  };
};

export const fetchTotalCampaignsPerformanceFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_CAMPAIGN_PERFORMANCE_FAILED,
    result: error,
  };
};

export const fetchTotalCampaignsPerformance = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchTotalCampaignsPerformanceRequest());
    return api(
      formUrl(
        API.dashboard.getTotalCampaignsPerformance,
        formatDates(startDate, endDate)
      )
    )
      .then((response) => {
        dispatch(fetchTotalCampaignsPerformanceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTotalCampaignsPerformanceFailed(error.message));
      });
  };
};

export const fetchTotalRunRatesRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_RUN_RATES_REQUEST,
  };
};

export const fetchTotalRunRatesSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_TOTAL_RUN_RATES_SUCCEEDED,
    result: setup,
  };
};

export const fetchTotalRunRatesFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_RUN_RATES_FAILED,
    result: error,
  };
};

export const fetchTotalRunRates = (startDate, endDate, filterBy) => {
  const data = formatDates(startDate, endDate);
  data.filterBy = filterBy;

  return (dispatch) => {
    dispatch(fetchTotalRunRatesRequest());
    return api(formUrl(API.dashboard.getTotalRunRates, data))
      .then((response) => {
        dispatch(fetchTotalRunRatesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTotalRunRatesFailed(error.message));
      });
  };
};

export const fetchTotalResponsesRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_RESPONESES_REQUEST,
  };
};

export const fetchTotalResponsesSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_TOTAL_RESPONESES_SUCCEEDED,
    result: setup,
  };
};

export const fetchTotalResponsesFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_RESPONESES_FAILED,
    result: error,
  };
};

export const fetchTotalResponses = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchTotalResponsesRequest());
    return api(
      formUrl(
        API.dashboard.getTotalResponseRate,
        formatDates(startDate, endDate)
      )
    )
      .then((response) => {
        dispatch(fetchTotalResponsesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTotalResponsesFailed(error.message));
      });
  };
};

export const fetchProspectFunnelStatisticsRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_PROESPECT_FUNNEL_REQUEST,
  };
};

export const fetchProspectFunnelStatisticsSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_TOTAL_PROESPECT_FUNNEL_SUCCEEDED,
    result: setup,
  };
};

export const fetchProspectFunnelStatisticsFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_PROESPECT_FUNNEL_FAILED,
    result: error,
  };
};

export const fetchProspectFunnelStatistics = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchProspectFunnelStatisticsRequest());
    return api(
      formUrl(
        API.dashboard.getProspectFunnelStatistics,
        formatDates(startDate, endDate)
      )
    )
      .then((response) => {
        dispatch(fetchProspectFunnelStatisticsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProspectFunnelStatisticsFailed(error.message));
      });
  };
};

export const fetchLeadRateRequest = () => {
  return {
    type: actionTypes.FETCH_TOTAL_LEAD_RATE_REQUEST,
  };
};

export const fetchLeadRateSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_TOTAL_LEAD_RATE_SUCCEEDED,
    result: setup,
  };
};

export const fetchLeadRateFailed = (error) => {
  return {
    type: actionTypes.FETCH_TOTAL_LEAD_RATE_FAILED,
    result: error,
  };
};

export const fetchLeadRate = (startDate, endDate) => {
  return (dispatch) => {
    dispatch(fetchLeadRateRequest());
    return api(
      formUrl(API.dashboard.getLeadRate, formatDates(startDate, endDate))
    )
      .then((response) => {
        dispatch(fetchLeadRateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchLeadRateFailed(error.message));
      });
  };
};

export const fetchGlobalAverageRateRequest = () => {
  return {
    type: actionTypes.FETCH_GLOBAL_AVERAGE_RATE_REQUEST,
  };
};

export const fetchGlobalAverageRateSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_GLOBAL_AVERAGE_RATE_SUCCEEDED,
    result: setup,
  };
};

export const fetchGlobalAverageRateFailed = (error) => {
  return {
    type: actionTypes.FETCH_GLOBAL_AVERAGE_RATE_FAILED,
    result: error,
  };
};

export const fetchGlobalAverageRate = () => {
  return (dispatch) => {
    dispatch(fetchGlobalAverageRateRequest());
    return api(API.dashboard.getGlobalAverageRate)
      .then((response) => {
        dispatch(fetchGlobalAverageRateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGlobalAverageRateFailed(error.message));
      });
  };
};

export const setPreviousOrganizationId = (organizationId) => {
  return {
    type: actionTypes.SET_PREVIOUS_ORGANIZATION_ID,
    result: organizationId,
  };
};

export const fetchStepsDoneRequest = () => {
  return {
    type: actionTypes.FETCH_STEPS_DONE_REQUEST,
  };
};

export const fetchStepsDoneSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_STEPS_DONE_SUCCEEDED,
    result: setup,
  };
};

export const fetchStepsDoneFailed = (error) => {
  return {
    type: actionTypes.FETCH_STEPS_DONE_FAILED,
    result: error,
  };
};

export const fetchStepsDone = () => {
  return (dispatch) => {
    dispatch(fetchStepsDoneRequest());
    return api(API.dashboard.getStepsDone)
      .then((response) => {
        dispatch(fetchStepsDoneSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchStepsDoneFailed(error.message));
      });
  };
};

export const setShowProductTour = (val) => {
  return {
    type: actionTypes.SET_SHOW_PRODUCT_TOUR,
    result: val,
  };
};
