import React from "react";
import svg from "./responded.svg";

const Responded = (props) => (
  <svg
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
    className={props.imgStyle}
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 12}
    height={props.height ? props.height : 12}
    viewBox="0 0 12 12"
  >
    <g id="icn_reports_responses" transform="translate(-59 -11)">
      <rect
        id="_24_base"
        data-name="24_base"
        width="12"
        height="12"
        transform="translate(59 11)"
        fill="none"
      />
      <g id="talk" transform="translate(59.501 12.002)">
        <path
          id="Path_1308"
          data-name="Path 1308"
          d="M8.776,4.174a2.878,2.878,0,0,1,1.557.455V1.879A1.881,1.881,0,0,0,8.453,0H1.879A1.881,1.881,0,0,0,0,1.879V9.77c0,.27.317.328.53.15L2.515,7.91H6.006a2.894,2.894,0,0,1,2.77-3.736Zm-6.724-2.1H8.28a.323.323,0,1,1,0,.646H2.052a.323.323,0,1,1,0-.646Zm0,1.73H6.2a.323.323,0,1,1,0,.646H2.052a.323.323,0,1,1,0-.646ZM4.82,6.18H2.052a.323.323,0,1,1,0-.646H4.82a.323.323,0,1,1,0,.646Zm0,0"
          transform="translate(0.001 0)"
          fill={props.fill ? props.fill : "#1288f5"}
        />
        <path
          id="Path_1309"
          data-name="Path 1309"
          d="M305.386,223.871a2.242,2.242,0,1,0,2.242,2.242A2.242,2.242,0,0,0,305.386,223.871Zm1.12,1.745L305.471,227a.322.322,0,0,1-.485.034l-.69-.69a.322.322,0,0,1,.455-.455l.427.427.812-1.083A.322.322,0,0,1,306.506,225.616Zm0,0"
          transform="translate(-296.604 -219.037)"
          fill={props.fill ? props.fill : "#1288f5"}
        />
      </g>
    </g>
  </svg>
);

export default Responded;
