import React from "react";
import { connect } from "react-redux";

//Helpers
import { activeApplicationEnum } from "../../helper/enums/activeApplicationEnum";

// Components
import DashboardDetailsView from "./Components/DashboardDetailsView/DashboardDetailsView";
import OrganizationCheckList from "../../components/UI/OrganizationCheckList/OrganizationCheckList";

const Dashboard = (props) => {
  if (props.organizationOnboardingModel != null && props.organizationOnboardingModel.onboardingCompleted != null) {
    if (props.organizationOnboardingModel.onboardingCompleted === false) return <OrganizationCheckList />;
    else return <DashboardDetailsView />;
  } else return null;
};

const mapStateToProps = (state) => {
  return {
    organization: state.user.organization,
    organizationOnboardingModel: state.organizationSettingsGeneral.organizationOnboardingModel,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
