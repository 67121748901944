import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const setPurchaseTemplateTitle = (val) => {
  return {
    type: actionTypes.SET_PURCHASE_TEMPLATE_TITLE,
    result: val,
  };
};

export const fetchPurchaseTemplateRequest = () => {
  return {
    type: actionTypes.FETCH_PURCHASE_TEMPLATE_REQUEST,
  };
};

export const fetchPurchaseTemplateSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_PURCHASE_TEMPLATE_SUCCEEDED,
    result: settings,
  };
};

export const fetchPurchaseTemplateFailed = (error) => {
  return {
    type: actionTypes.FETCH_PURCHASE_TEMPLATE_FAILED,
    result: error,
  };
};

export const fetchPurchaseTemplate = (data) => {
  return (dispatch) => {
    dispatch(fetchPurchaseTemplateRequest());
    return api(
      formUrl(API.purchaseEmailTemplates.get, { purchaseTemplateId: data })
    )
      .then((response) => {
        dispatch(fetchPurchaseTemplateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPurchaseTemplateFailed(error.message));
      });
  };
};

export const fetchJobTitleListRequest = () => {
  return {
    type: actionTypes.FETCH_JOB_TITLE_LIST_REQUEST,
  };
};

export const fetchJobTitleListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_JOB_TITLE_LIST_SUCCEEDED,
    result: setup,
  };
};

export const fetchJobTitleListFailed = (error) => {
  return {
    type: actionTypes.FETCH_JOB_TITLE_LIST_FAILED,
    result: error,
  };
};

export const fetchJobTitleList = () => {
  return (dispatch) => {
    dispatch(fetchJobTitleListRequest());
    return api(API.purchaseEmailTemplates.getJobTitleList)
      .then((response) => {
        dispatch(fetchJobTitleListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchJobTitleListFailed(error.message));
      });
  };
};

export const fetchPurchaseTemplatePriceRequest = () => {
  return {
    type: actionTypes.FETCH_PURCHASE_TEMPLATE_PRICE_REQUEST,
  };
};

export const fetchPurchaseTemplatePriceSuccess = (val) => {
  return {
    type: actionTypes.FETCH_PURCHASE_TEMPLATE_PRICE_SUCCEEDED,
    result: val,
  };
};

export const fetchPurchaseTemplatePriceFailed = (error) => {
  return {
    type: actionTypes.FETCH_PURCHASE_TEMPLATE_PRICE_FAILED,
    result: error,
  };
};

export const fetchPurchaseTemplatePrice = (val) => {
  return (dispatch) => {
    dispatch(fetchPurchaseTemplatePriceRequest());
    return api(formUrl(API.purchaseEmailTemplates.getPurchaseTemplatePrice, { currencyId: val }))
      .then((response) => {
        dispatch(fetchPurchaseTemplatePriceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchJobTitleListFailed(error.message));
      });
  };
};
