import React from "react";
import svg from "./circle-warning.svg";

const CircleWarning = (props) => (
  <svg
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
    className={props.imgStyle}
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 10}
    height={props.height ? props.height : 10}
    viewBox="0 0 10 10"
  >
    <circle
      id="Ellipse_167"
      data-name="Ellipse 167"
      cx="5"
      cy="5"
      r="5"
      fill={props.fill ? props.fill : "#faac14"}
    />
  </svg>
);

export default CircleWarning;
