import React from "react";

const IconIncludeContact = (props) => (
  <svg
    className={props.imgStyle}
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={props.onClick}
  >
    <path
      d="M10.8333 3.66665C10.8333 1.82498 9.34158 0.333313 7.49992 0.333313C5.65825 0.333313 4.16658 1.82498 4.16658 3.66665C4.16658 5.50831 5.65825 6.99998 7.49992 6.99998C9.34158 6.99998 10.8333 5.50831 10.8333 3.66665ZM12.4999 5.33331V6.99998H14.9999V9.49998H16.6666V6.99998H19.1666V5.33331H16.6666V2.83331H14.9999V5.33331H12.4999ZM0.833252 12V13.6666H14.1666V12C14.1666 9.78331 9.72492 8.66665 7.49992 8.66665C5.27492 8.66665 0.833252 9.78331 0.833252 12Z"
      fill="#DBDBDB"
    />
  </svg>
);
export default IconIncludeContact;
