import React from "react";
import { connect } from "react-redux";

//Helpers
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";

//Styles
import styles from "./TopBannerUser.module.scss";

// Components
import BreadcrumbElement from "../../../components/UI/BreadcrumbElement/BreadcrumbElement";

const TopBannerEmailAccount = (props) => {
  return (
    <div className={styles.topBannerWrapper}>
      <div className={styles.topBannerHistory}>
        <BreadcrumbElement items={props.breadcrumbs} />
        <div className={styles.userInfoWrapper}>
          <h1 className={styles.title}>
            <span>
              {props.emailAccountName} ({props.emailAccountEmail})
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBannerEmailAccount);
