import React, { useRef } from "react";
import ProspectBox from "../ProspectBox/ProspectBox";

// Styles
import styles from "./StatusCountRow.module.scss";

const StatusCountRow = (props) => {
  const cardWrap = useRef(null);

  const getClassesForCardWrapper = (cardStatus) => {
    var result = styles.cardWrapper;
    if (props.activeStatus !== null && props.activeStatus !== undefined) {
      if (cardStatus === props.activeStatus) {
        result = `${styles.cardWrapper} ${styles.cardActive}`;
      }
    }
    return result;
  };

  const cardStateHandler = (e, num) => {
    props.click(num);

    let currentActiveCard = Array.from(cardWrap.current.childNodes).filter((node) => node.classList.contains(styles.cardActive));
    if (currentActiveCard.length !== 0) {
      if (e.currentTarget.classList.contains(styles.cardActive)) {
        e.currentTarget.classList.remove(styles.cardActive);
      } else {
        currentActiveCard[0].classList.remove(styles.cardActive);
        e.currentTarget.classList.add(styles.cardActive);
      }
    } else {
      e.currentTarget.classList.add(styles.cardActive);
    }
  };

  return (
    <div ref={cardWrap} className={`${styles.wrapper} flex`}>
      <div onClick={(e) => cardStateHandler(e, 0)} className={getClassesForCardWrapper(0)}>
        <ProspectBox
          number={props.statusCount.awaitingEngagement}
          status={"Awaiting Engagement"}
          className={`${styles.card} ${styles.awaiting}`}
        />
      </div>
      <div onClick={(e) => cardStateHandler(e, 1)} className={getClassesForCardWrapper(1)}>
        <ProspectBox number={props.statusCount.engaged} status={"Engaged"} className={`${styles.card} ${styles.engaged}`} />
      </div>
      <div onClick={(e) => cardStateHandler(e, 2)} className={getClassesForCardWrapper(2)}>
        <ProspectBox number={props.statusCount.opened} status={"Opened"} className={`${styles.card} ${styles.opened}`} />
      </div>
      <div onClick={(e) => cardStateHandler(e, 3)} className={getClassesForCardWrapper(3)}>
        <ProspectBox number={props.statusCount.clicked} status={"Clicked"} className={`${styles.card} ${styles.clicked}`} />
      </div>
      <div onClick={(e) => cardStateHandler(e, 4)} className={getClassesForCardWrapper(4)}>
        <ProspectBox number={props.statusCount.responded} status={"Responded"} className={`${styles.card} ${styles.responded}`} />
      </div>
      <div onClick={(e) => cardStateHandler(e, 5)} className={getClassesForCardWrapper(5)}>
        <ProspectBox number={props.statusCount.leads} status={"Leads"} className={`${styles.card} ${styles.leads}`} />
      </div>
    </div>
  );
};

export default StatusCountRow;
