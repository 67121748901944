import React from "react";

// Styles
import styles from "./OptimizationStatusCards.module.scss";

// Icons
import Dashboard3LineIcon from "remixicon-react/Dashboard3LineIcon";
import LayoutTop2LineIcon from "remixicon-react/LayoutTop2LineIcon";
import CheckboxCircleLineIcon from "remixicon-react/CheckboxCircleLineIcon";
import CloseCircleLineIcon from "remixicon-react/CloseCircleLineIcon";
import TimerLineIcon from "remixicon-react/TimerLineIcon";

interface OptimizationStatusCardsProps {
  statusCounts: Record<string, number>;
  totalCount: number;
  className?: string;
  activeStatus: string;
  onStatusClick: (status: string) => void;
}

const OptimizationStatusCards: React.FC<OptimizationStatusCardsProps> = ({
  statusCounts,
  totalCount,
  className = "",
  activeStatus,
  onStatusClick,
}) => {
  const statusIcons = [
    { status: "Active", key: "active", icon: <LayoutTop2LineIcon color="var(--pink-color)" /> },
    { status: "In Progress", key: "inProgress", icon: <TimerLineIcon color="var(--info-color)" /> },
    { status: "Completed", key: "completed", icon: <CheckboxCircleLineIcon color="var(--secund-stage-color)" /> },
    { status: "Dismissed", key: "dismissed", icon: <CloseCircleLineIcon color="var(--neutral-color)" /> },
    { status: "All", key: "total", icon: <Dashboard3LineIcon color="var(--primary-color)" /> },
  ];

  const getStatusClass = (statusKey: string) => {
    switch (statusKey) {
      case "total":
        return styles.total;
      case "active":
        return styles.active;
      case "inProgress":
        return styles.inProgress;
      case "completed":
        return styles.completed;
      case "dismissed":
        return styles.dismissed;
      default:
        return "";
    }
  };

  return (
    <div className={`flex ${className}`}>
      {statusIcons.map((statusObj, index) => (
        <div
          key={index}
          className={`${styles.optimizationStatusCard} ${getStatusClass(statusObj.key)} ${
            activeStatus === statusObj.key ? styles.activeCard : ""
          }`}
          onClick={() => onStatusClick(statusObj.key)}
        >
          <span className={styles.iconWrapper}>{statusObj.icon}</span>
          <div className={styles.countDetails}>
            <span className={styles.statusTotal}>{statusObj.key === "total" ? totalCount : statusCounts[statusObj.key] || 0}</span>
            <span className={styles.statusLabel}>{statusObj.status}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OptimizationStatusCards;
