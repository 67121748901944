import React from "react";
import { connect } from "react-redux";
import { ButtonGroup, Button } from "react-bootstrap";

//Redux
import { setActiveTab, setFilterForReview } from "../../../store/ResponderInbox/actions/responderInbox";

//Styles
import styles from "./InboxFilter.module.scss";

const InboxFilter = (props) => {
  const { activeTab } = props;

  const handleUnresolveClick = () => {
    props.setActiveTab(0);
    setFilterForReview(false);
  };

  const handleResolveClick = () => {
    props.setActiveTab(1);
  };

  return (
    <div className={`${styles.filterWrapper} filterWrapper flex`}>
      <div className={`${styles.buttonsWrapper} buttonsWrapper flex`}>
        <ButtonGroup className="btn-custom-wrapper" toggle="true">
          <Button
            id="unresolved"
            onClick={handleUnresolveClick}
            className={`${styles.buttonWrapper} flex center_flex unresolved`}
            variant="light"
            active={activeTab === 0}
          >
            <span className={`${styles.circle} ${styles.unresolved}`} />
            <p>
              Unresolved <span className="f14">({props.unresolved})</span>
            </p>
          </Button>
          <Button
            id="resolved"
            onClick={handleResolveClick}
            className={`${styles.buttonWrapper} flex center_flex resolved`}
            variant="light"
            active={activeTab === 1}
          >
            <span className={`${styles.circle} ${styles.resolved}`} />
            <p>
              Resolved <span className="f14">({props.resolved})</span>
            </p>
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.responderInbox.activeTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (value) => dispatch(setActiveTab(value)),
    setFilterForReview: (value) => dispatch(setFilterForReview(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxFilter);
