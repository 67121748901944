import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

//Redux
import { changeDealNavigatorStages } from "../../../../store/DealNavigator/actions/dealNavigator";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import FormikInput from "../../../../components/UI/Formik/FormikInput";

const EditStageFrom = (props) => {
  const { stageTitle, stageId } = props;

  return (
    <Formik
      initialValues={{
        name: stageTitle,
        id: stageId,
      }}
      validationSchema={Yup.object({
        id: Yup.number().required("Required"),
        name: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        const newStage = {
          id: stageId,
          name: values.name,
        };

        const prevStage = {
          name: stageTitle,
          id: stageId,
        };

        props.changeDealNavigatorStages(newStage, prevStage);
        props.handleClose();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { values, handleChange, handleSubmit, isSubmitting } =
          formikProps;
        return (
          <Modal
            title={`Edit ${stageTitle} Stage`}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form>
              <FormikInput
                label="Name: "
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeDealNavigatorStages: (newStage, prevStage) =>
      dispatch(changeDealNavigatorStages(newStage, prevStage)),
  };
};

export default connect(null, mapDispatchToProps)(EditStageFrom);
