export const columns = [
  {
    label: "Tracking Domain",
    field: "trackingDomainUrl",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 190,
    minWidth: 190,
    flex: 1,
  },
  {
    label: "Domain",
    field: "domainUrl",
    type: "text",
    sort: "asc",
    minimal: "lg",
    width: 190,
    minWidth: 190,
    flex: 1,
  },
  {
    label: "DNS Verified",
    field: "dnsVerified",
    type: "actions",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
  },
  {
    label: "Opens",
    field: "trackOpens",
    type: "actions",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
  },
  {
    label: "Clicks",
    field: "trackClicks",
    type: "actions",
    sort: "asc",
    minimal: "lg",
    width: 120,
    minWidth: 120,
  },
];
