import React from "react";

// Components
import Modal from "../../components/UI/Modal/Modal";
import TestResultsMessage from "./EmailAccountSettings/TestResultsMessage/TestResultsMessage";
import AlertLineIcon from "remixicon-react/AlertLineIcon";

const MandatoryAccessNotGranted = (props) => {

    let messageLabel = null;
    messageLabel = (
        <b>Mandatory access not granted</b>
    );
    let messageDetails = null;
    messageDetails = (<div>
        <p>In order for your email account to be used for Outbase campaigns, you must grant access to both:</p>
        <ul>
            <li>Reading messages from your inbox.</li>
            <li>Sending messages on behalf of you</li>
        </ul>
        <p><em>Please click 'Continue' and try again.</em></p>
    </div>);
    let sendContent = null;
    sendContent = (
        <TestResultsMessage icon={<AlertLineIcon color='var(--danger-color)'/>} messageLabel={messageLabel} messageDetails={messageDetails} />
      );
    return(
        <Modal 
        title="Test email account"
        handleClose={props.handleCancel}
        handleSave={props.handleSave}
        show={props.showModal}
        saveButtonText="Continue"
        closeButtonText="Close"
        btnType="submit"
        >{sendContent}
        </Modal>
    )
};

export default MandatoryAccessNotGranted;


