import React, { CSSProperties, ReactNode } from "react";

//Styles
import styles from "./Action.module.scss";

export interface ActionProps {
  materialIcon?: ReactNode;
  icon?: string;
  svgIcon?: ReactNode;
  disabled?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  title?: string;
  id?: string;
  className?: string;
  spanClass?: string;
}

const Action: React.FC<ActionProps> = (props) => {
  const onClick = () => {
    if (props.disabled) {
      return false;
    } else {
      if (props.onClick) props.onClick();
    }
  };

  return (
    <>
      {props.materialIcon ? (
        <span
          className={props.disabled ? styles.disabled : styles.action}
          style={props.style}
          onClick={onClick}
          title={props.title}
          id={props.id || undefined}
        >
          {props.materialIcon}
        </span>
      ) : null}

      {props.svgIcon ? (
        <span
          className={`${props.disabled ? styles.disabled : styles.action} ${props.spanClass || undefined}`}
          style={props.style}
          onClick={onClick}
          title={props.title}
          id={props.id || undefined}
        >
          {props.svgIcon}
        </span>
      ) : null}
    </>
  );
};

export default Action;
