import React from "react";
import Moment from "moment";

//Redux
import { connect } from "react-redux";

//Helpers
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";
import { useOrgNumberFormatting } from "../../../../../helper/hooks/useOrgNumberFormatting";

//Styles
import styles from "./Usage.module.scss";

//Components
import PieChartAngle from "../../../../../components/PieChartAngle/PieChartAngle";
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";
import { organizationStatusEnum } from "../../../../../helper/enums/organizationStatus";

const colors = ["#ebebeb", "#fe7904"];

const Usage = (props) => {
  const { currentCredits, monthlyCredits, startEndDateCredits } = props.creditInfo;
  const { organizationStatus } = props;
  let dateFormat = useOrgDateFormat();

  const dataPieChart = {
    credits: monthlyCredits - currentCredits == 0 ? 1 : monthlyCredits - currentCredits,
    currentCredits: currentCredits,
  };

  return (
    <div className={styles.componentWrapper}>
      <SectionTitle title="Usage" />
      <div className={`${styles.detailsWrapper} flex`}>
        <div className={styles.pieChartWrapper}>
          <PieChartAngle
            height={150}
            innerRadius="80%"
            outerRadius="100%"
            data={dataPieChart}
            textValue="Credits"
            colors={colors}
            numberValue={`${useOrgNumberFormatting(currentCredits)}/${useOrgNumberFormatting(monthlyCredits)}`}
            topLabelClass={styles.creditsLabel}
            bottomLabelClass={styles.total}
          />
        </div>
        <div className={styles.timePeriodWrapper}>
          <h2 className={styles.title}>Time Period</h2>
          <p className={styles.date}>
            {organizationStatus === organizationStatusEnum.inactive ? "N/A" : Moment(startEndDateCredits?.startDate).format(dateFormat)}
          </p>
          <p className={styles.date}>
            {organizationStatus === organizationStatusEnum.inactive
              ? "N/A"
              : organizationStatus === organizationStatusEnum.pendingRenewal
              ? Moment(startEndDateCredits?.startDate)?.add(1, "M").format(dateFormat)
              : Moment(startEndDateCredits?.endDate).format(dateFormat)}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    creditInfo: state.billingAndPayment.creditInfo,
    organizationStatus: state.user.organization.organizationStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
