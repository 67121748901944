import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  integrations: [],
  error: "",
  hasSetIntegration: false,
};

const fetchAllIntegrationsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchAllIntegrationsSuccess = (state, action) => {
  const newState = { ...state };
  let integrations = { ...state.integrations };
  integrations = action.result;
  newState.integrations = integrations;
  newState.isLoaded = true;
  newState.isLoading = false;
  return newState;
};

const fetchAllIntegrationsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

// const fetchHasIntegrationRequest = (state, action) => {
//   return {
//     ...state,
//     isLoading: true,
//     isLoaded: false,
//   };
// }

// const fetchHasIntegrationSuccess = (state, action) => {
//   const newState = {...state};
//   newState.globalIntegrations.hasSetIntegration = action.result;
//   newState.isLoading = false;
//   newState.isLoaded = true;
//   return newState;
// }

// const fetchHasIntegrationFailed = (state, action) => {
//   const newState = { ...state };
//   newState.error = action.result;
//   newState.isLoading = false;
//   newState.isLoaded = true;
//   return newState;
// };

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_INTEGRATIONS_REQUEST:
      return fetchAllIntegrationsRequest(state, action);

    case actionTypes.GET_ALL_INTEGRATIONS_SUCCEEDED:
      return fetchAllIntegrationsSuccess(state, action);

    case actionTypes.GET_ALL_INTEGRATIONS_FAILED:
      return fetchAllIntegrationsFailed(state, action);

    // case actionTypes.GET_HAS_INTEGRATION_REQUEST:
    //   return fetchHasIntegrationRequest(state, action);

    // case actionTypes.GET_HAS_INTEGRATION_SUCCEEDED:
    //   return fetchHasIntegrationSuccess(state, action);

    // case actionTypes.GET_HAS_INTEGATION_FAILED:
    //   return fetchHasIntegrationFailed(state, action);

    default:
      break;
  }

  return state;
};

export default reducer;
