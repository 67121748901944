import React from "react";
import { connect } from "react-redux";
import { StyledPQuoteText } from "../../shared/StyledComponents";
import { Card, Accordion } from "react-bootstrap";
import { ErrorOutline } from "@mui/icons-material";

//Helpers
import { renderDocumentsFAIconsFromExtention } from "../../../../helper/documentsFAIcons";
import { DealNavigatorElementType } from "../../../../helper/enums/dealNavigatorElementType";
import { createImageUrl, createDocumentUrl } from "../../../../helper/fileHelper";

//Style
import "./Element.scss";

//Images & Icons
import { KeyboardArrowDown } from "@mui/icons-material";

export const IsEmpty = (obj) => {
  return obj === null || obj === "null" || obj === undefined || (Object.keys(obj).length === 0 && obj.constructor === Object);
};

const Element = (element) => {
  const { type, content, header, document } = element;

  return (
    <>
      {type === DealNavigatorElementType.Title ? (
        <>
          {IsEmpty(content) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any title content
            </span>
          ) : (
            <h5 className="card_title f18 mB15 clearBoth">{content}</h5>
          )}
        </>
      ) : null}
      {type === DealNavigatorElementType.Paragraph ? (
        <>
          {IsEmpty(content) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any paragraph content
            </span>
          ) : (
            <p className="text_card_background f14 clearBoth">
              <div className="textEditor_img_video" dangerouslySetInnerHTML={{ __html: content }} />
            </p>
          )}
        </>
      ) : null}
      {type === DealNavigatorElementType.Image ? (
        <>
          {IsEmpty(document) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any image
            </span>
          ) : (
            <a href={createImageUrl(document)}>
              <img className="width_100 mB15 border-5 clearBoth" src={createImageUrl(document)} style={{ clear: "both" }} alt="" />
            </a>
          )}
        </>
      ) : null}
      {type === DealNavigatorElementType.Quote ? (
        <>
          {IsEmpty(content) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any quote content
            </span>
          ) : (
            <StyledPQuoteText className="font_italic">&quot;{content}&quot;</StyledPQuoteText>
          )}
        </>
      ) : null}
      {type === DealNavigatorElementType.FAQ ? (
        <>
          {IsEmpty(content) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any FAQ content
            </span>
          ) : (
            <>
              <Accordion className={`accordion_custom mB10 clearBoth`} defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordionHeaderWrapper"> {IsEmpty(header) ? null : header}</Accordion.Header>
                  <Accordion.Body>
                    <div className="textEditor_img_video" dangerouslySetInnerHTML={{ __html: content }} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}
        </>
      ) : null}
      {type === DealNavigatorElementType.Document ? (
        <>
          {IsEmpty(document) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any document
            </span>
          ) : (
            <span className="attachmentWrapper text_card_background clearBoth">
              <a href={createDocumentUrl(document)}>
                {renderDocumentsFAIconsFromExtention(document.extension)}
                {document.name}
              </a>
            </span>
          )}
        </>
      ) : null}
      {type === DealNavigatorElementType.Video ? (
        <>
          {IsEmpty(content) ? (
            <span className="errorWrapMsg errorWrapMsg clearBoth">
              <ErrorOutline className="warningIcon" />
              You have not enter any video content
            </span>
          ) : (
            <div className="clearBoth">
              {content.startsWith("<iframe") ? (
                <>
                  <div className="videoWrapper" dangerouslySetInnerHTML={{ __html: content }} />
                </>
              ) : (
                <embed src={`${content}`} style={{ width: "100%", height: "100%" }} />
              )}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default connect(null, null)(Element);
