import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// Helpers
import api, { API, formUrl } from "../../../helper/api/api";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";
import { errorResponseToList } from "../../../helper/errorHelper";

// Images & Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";

// Components
import Modal from "../../../components/UI/Modal/Modal";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ElementCycle from "../../../components/UI/ElementCycle/ElementCycle";
import TestResultsMessage from "./TestResultsMessage/TestResultsMessage";
import InfoMessageBox from "./InfoMessageBox/InfoMessgeBox";

const ConnectedTestEmailForm = (props) => {
  let sendContent = null;
  let showSecondButton = false;
  let messageInfoBox = null;
  let [isReconnectLoading, setReconnectLoading] = useState(false);

  const sendMessages = [" We are testing your email connection, this will take a moment, please wait."];
  const [cycleRunning, setCycleRunning] = useState(false);

  useEffect(() => {
    setCycleRunning(props.showModal);
  }, [props.showModal]);

  let history = useHistory();
  const organizationId = useOrganizationId();

  const navigateBaseOfEmailAccountType = () => {
    if (props.emailAccountType === "Gmail" || props.emailAccountType === "Microsoft") {
      setReconnectLoading(true);

      api(formUrl(API.emailAccounts.reconnectThirdParty, { emailAccountId: props.emailAccountId }))
        .then((response) => {
          if (response.data && response.data.success) {
            if (response.data.redirectUrl != null) {
              window.location = response.data.redirectUrl;
            }
          } else {
            toast.error(response.data.errorMessage);
          }

          setReconnectLoading(false);
        })
        .catch((error) => {
          const errorList = errorResponseToList(error);
          if (errorList.length > 0) {
            errorList.forEach((err) => toast.error(err.message));
          } else {
            toast.error(error.message);
          }

          setReconnectLoading(false);
        });
    } else {
      if (props.emailAccountId && organizationId && organizationId > 0) {
        history.push("/" + organizationId + "/email-accounts/" + props.emailAccountId + "/edit");
      } else {
        props.handleClose();
      }
    }
  };

  if (props.sendStatus === "Started" || props.receiveStatus === "Started") {
    sendContent = (
      <TestResultsMessage
        icon={<Spinner faCircleNotch size="20px" />}
        messageLabel="Connection test:"
        messageDetails={<ElementCycle objects={sendMessages} isRunning={cycleRunning} />}
      />
    );
  } else if (props.sendStatus === "Successful" && props.receiveStatus === "Successful") {
    sendContent = (
      <TestResultsMessage
        icon={<CheckboxCircleFillIcon size={24} color="var(--first-stage-color)" />}
        messageLabel="Connection test:"
        messageDetails={"Successfully established connection"}
      />
    );
  } else {
    sendContent = (
      <TestResultsMessage
        icon={<CloseCircleFillIcon size={24} color="var(--danger-color)" />}
        messageLabel="Connection test failed:"
        messageDetails={"Email account not successfully connected. Please check your email account settings."}
      />
    );
  }

  if (props.sendStatus === "Failed" || props.receiveStatus === "Failed") {
    showSecondButton = true;
  }

  let saveButtonText = "Close";

  if (props.sendStatus === "Failed" || props.receiveStatus === "Failed") {
    saveButtonText = "Edit Settings";
    if (
      props.emailAccountId != null &&
      props.emailAccountType != null &&
      (props.emailAccountType === "Gmail" || props.emailAccountType === "Microsoft")
    ) {
      saveButtonText = "Reconnect";
    }
  }

  if (props.sendStatus === "Successful" && props.receiveStatus === "Successful") {
    messageInfoBox = <InfoMessageBox />;
  }

  return (
    <Modal
      title="Test connection"
      handleSave={showSecondButton ? navigateBaseOfEmailAccountType : props.handleClose}
      handleClose={showSecondButton && props.handleClose}
      isReconnectLoading={isReconnectLoading}
      show={props.showModal}
      saveButtonText={saveButtonText}
      closeButtonText={showSecondButton && "Close"}
      oneButton={!showSecondButton ? true : false}
      btnType="submit"
      backdrop={true}
      onHide={props.handleClose}
    >
      <div className="mB20">{sendContent}</div>
      {messageInfoBox}
    </Modal>
  );
};

export default ConnectedTestEmailForm;
