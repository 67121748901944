import React from "react";
import svg from "./agreement_icon.svg";

const IconAgreement = (props) => (
  <svg className={props.imgStyle} width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <mask id="path-1-inside-1" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.875C0 0.839466 0.839466 0 1.875 0H14.6253V7.5C14.6253 8.53553 15.4647 9.375 16.5003 9.375H24V31.125C24 32.1605 23.1605 33 22.125 33H1.875C0.839466 33 0 32.1605 0 31.125V1.875Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1.875C0 0.839466 0.839466 0 1.875 0H14.6253V7.5C14.6253 8.53553 15.4647 9.375 16.5003 9.375H24V31.125C24 32.1605 23.1605 33 22.125 33H1.875C0.839466 33 0 32.1605 0 31.125V1.875Z"
          fill="#5491F2"
        />
        <path
          d="M14.6253 0H14.8128V-0.1875H14.6253V0ZM24 9.375H24.1875V9.1875H24V9.375ZM1.875 -0.1875C0.735913 -0.1875 -0.1875 0.735913 -0.1875 1.875H0.1875C0.1875 0.94302 0.943019 0.1875 1.875 0.1875V-0.1875ZM14.6253 -0.1875H1.875V0.1875H14.6253V-0.1875ZM14.8128 7.5V0H14.4378V7.5H14.8128ZM16.5003 9.1875C15.5683 9.1875 14.8128 8.43198 14.8128 7.5H14.4378C14.4378 8.63909 15.3612 9.5625 16.5003 9.5625V9.1875ZM24 9.1875H16.5003V9.5625H24V9.1875ZM24.1875 31.125V9.375H23.8125V31.125H24.1875ZM22.125 33.1875C23.2641 33.1875 24.1875 32.2641 24.1875 31.125H23.8125C23.8125 32.057 23.057 32.8125 22.125 32.8125V33.1875ZM1.875 33.1875H22.125V32.8125H1.875V33.1875ZM-0.1875 31.125C-0.1875 32.2641 0.735913 33.1875 1.875 33.1875V32.8125C0.94302 32.8125 0.1875 32.057 0.1875 31.125H-0.1875ZM-0.1875 1.875V31.125H0.1875V1.875H-0.1875Z"
          fill="#5491F2"
          mask="url(#path-1-inside-1)"
        />
      </g>
      <path opacity="0.100338" fillRule="evenodd" clipRule="evenodd" d="M16.125 9.375H24V15.9375L16.125 9.375Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.625 0L19.6875 5.0625L24 9.375H16.5C15.4645 9.375 14.625 8.53553 14.625 7.5V0Z"
        fill="#A6C6F7"
      />
    </g>
    <g clipPath="url(#clip1)">
      <path d="M5.15625 17.5699H19.0312" stroke="white" strokeWidth="1.875" strokeLinecap="square" />
      <path d="M5.15625 20.2168H19.0312" stroke="white" strokeWidth="1.875" strokeLinecap="square" />
      <path d="M5.15625 22.864H19.0312" stroke="white" strokeWidth="1.875" strokeLinecap="square" />
      <path d="M5.15625 25.5109H14.0625" stroke="white" strokeWidth="1.875" strokeLinecap="square" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-0.1875"
        y="-0.1875"
        width="24.75"
        height="33.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="0.1875" dy="0.1875" />
        <feGaussianBlur stdDeviation="0.1875" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="clip0">
        <rect width="24" height="33" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="14.0625" height="9" fill="white" transform="translate(5.0625 16.6875)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconAgreement;
