import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Redux

//Helpers
import { useOrgNumberFormatting } from "../../../../helper/hooks/useOrgNumberFormatting";
import { audienceExplorerSoProDataEntityInUseEnum } from "../../../../helper/enums/audienceExplorerSoProDataEntityInUseEnum";
import { numberOfRecords } from "../../../../helper/tableConstants";

// Style
import styles from "./Companies.module.scss";

//Images & Icons
import TeamFillIcon from "remixicon-react/TeamFillIcon";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import CampaignInUse from "../../../../assets/Icons/CampaignInUse/CampaignInUse";
import SectionCompany from "../../../../assets/Icons/SectionCompany/SectionCompany";
import CampaignNotInUse from "../../../../assets/Icons/CampaignNotInUse/CampaignNotInUse";
import EmptyEmailAccount from "../../../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";

// Components
import { columns } from "./columns";
import DataTable from "../../../../components/DataTable/DataTable";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import UserPhoto from "../../../../components/UI/UserPhoto/UserPhoto";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import AlertNotification from "../../../../components/UI/AlertNotification/AlertNotification";
import AudExplorerCompanyDetailsForm from "../../../AudienceContacts/AudExplorerCompanyDetailsForm";
import audienceExplorerSlice, { ViewMode } from "@store/AudiencesExplorer/audienceExplorerSlice";
import { useLocation } from "react-router-dom";
import { useSlice } from "@hooks/useSlice";
import { useDataTable } from "@hooks/useDataTable";
import { explorerCompaniesDataTable } from "@store/registerDataTables";
import { useGetCompaniesQuery } from "@api/audienceExplorerApi";
import { ExplorerCompanyRowModel } from "@models/audienceExplorer/ExplorerCompanyRowModel";
import useModal from "@ui/Modal/useModal";

const Companies = () => {
  const [tableRows, setTableRows] = useState<ExplorerCompanyRowModel[]>([]);

  let table = null;
  let audExplorerCompanyDetailsForm = null;
  const image = <EmptyEmailAccount className="emptyStateImage" />;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchIdFromParams = queryParams.get("s");

  const dispatch = useDispatch();
  const explorerSlice = useSlice((state) => state.explorer);
  const explorerCompaniesState = useSlice((state) => state.explorerCompaniesDataTable);
  const dataTable = useDataTable({
    slice: explorerCompaniesState,
    dtActions: explorerCompaniesDataTable.actions,
    useQuery: useGetCompaniesQuery,
    filterTypes: ["SearchId"],
    useWithQuery: false,
    autoInitiate: false,
  });
  const {
    state: { rows },
    actions: { setFilter, initiate, clearFilter, clearInitiated },
    query,
  } = dataTable;

  useEffect(() => {
    setFilter("SearchId", searchIdFromParams);
  }, [searchIdFromParams]);

  useEffect(() => {
    if (searchIdFromParams) {
      const params = {
        length: numberOfRecords[2].value,
      };
      initiate(params);

      return () => {
        clearFilter("SearchId");
      };
    }
  }, [searchIdFromParams]);

  const totalCount = useOrgNumberFormatting(query?.data?.totalCount);

  const companyDetailsModal = useModal<number>();

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    if (!rows) return;

    const rowsTemp: ExplorerCompanyRowModel[] = _.cloneDeep(rows).map((row, index) => {
      const company: ExplorerCompanyRowModel = {
        ...row,
        companyLogo: <></>,
        name: <></>,
        industry: <></>,
        inUse: <></>,
        country: <></>,
        size: <></>,
      };

      company.companyLogo = (
        <div id={`company-${index}`}>
          <UserPhoto isCompanyImage={true} src={row.logo} size="extra-small" />
        </div>
      );
      company.name = (
        <OverlayTrigger
          key={row.name}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.cleanName ? row.cleanName : row.name}`}>
              <Popover.Body>{row.cleanName ? row.cleanName : row.name}</Popover.Body>
            </Popover>
          }
        >
          <div
            id={row.name.replace(/\s/g, "")}
            onClick={() => {
              companyDetailsModal.show(row.id);
            }}
            className="text-overflow-hidden tableLink max-x-content f10"
          >
            {row.cleanName ? row.cleanName : row.name}
          </div>
        </OverlayTrigger>
      );
      company.industry = (
        <OverlayTrigger
          key={row.industry}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.industry}`}>
              <Popover.Body>{row.industry}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content f10">{row.industry}</div>
        </OverlayTrigger>
      );
      company.country = (
        <OverlayTrigger
          key={row.country}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.country}`}>
              <Popover.Body>{row.country}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content f10">{row.country}</div>
        </OverlayTrigger>
      );
      company.size = (
        <OverlayTrigger
          key={row.size}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${row.size}`}>
              <Popover.Body>{row.size}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content f10">{row.size}</div>
        </OverlayTrigger>
      );

      const isUsedInAudience =
        row.inUse === audienceExplorerSoProDataEntityInUseEnum.Reserved || row.inUse === audienceExplorerSoProDataEntityInUseEnum.Fetched;
      const isUsedInCampaign = row.inUse === audienceExplorerSoProDataEntityInUseEnum.Fetched;
      const inUse = (
        <div className={styles.inUseColumn}>
          <OverlayTrigger
            key={"groupInUse"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-groupInUse`}>
                <Popover.Body>
                  {isUsedInAudience
                    ? "Some employees from this company appear in one or more of your lists"
                    : "A company whose employees do not appear in an list"}
                </Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">
              {isUsedInAudience ? <TeamFillIcon size="12" className="iconSuccess" /> : <TeamFillIcon size="12" className="iconGray500" />}
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            key={"campaignInUse"}
            placement={"bottom"}
            overlay={
              <Popover id={`tooltip-campaignInUse`}>
                <Popover.Body>
                  A company whose employees {isUsedInCampaign ? "appear" : "do not appear"} as prospects in a campaign.
                </Popover.Body>
              </Popover>
            }
          >
            <div className="text-overflow-hidden">{isUsedInCampaign ? <CampaignInUse /> : <CampaignNotInUse />}</div>
          </OverlayTrigger>
        </div>
      );
      company.inUse = inUse;
      return company;
    });

    setTableRows(rowsTemp);
  }, [rows]);

  if (query.error) {
    table = <h5>Error getting Companies</h5>;
  }

  if ((query.isLoading || query.isFetching) && searchIdFromParams) {
    table = <Spinner description="Searching for companies..." />;
  }

  if (query.isSuccess === false && query.isLoading === false && query.isFetching === false) {
    table = (
      <EmptyState
        componentClass="positionCenter"
        image={image}
        title={
          <>
            Select filters and click <span className="primary_text_color f16">Search</span> to see results
          </>
        }
      />
    );
  }

  if (query.isSuccess && query.isLoading === false && query.isFetching === false) {
    table =
      rows === null ? (
        <AlertNotification
          variant="danger_alert"
          alertClassName="justify-content-end"
          bodyClassName="align-items-center flex"
          notificationWrapperClass="width_100"
        >
          <p>Data is not available at this moment. Please try again later</p>
        </AlertNotification>
      ) : tableRows.length > 0 ? (
        <div className={styles.tableWrapper}>
          <p className={styles.totalCompanies}>
            <span>{rows.length} </span>of<span> {totalCount}</span> Companies
          </p>

          <DataTable
            rows={tableRows}
            columns={columns}
            dataTable={dataTable}
            tableClassName={styles.companiesTable}
            scrollY={true}
            variant="small"
            tableHeight={210}
            tableFooterClass="relative p0"
          />
        </div>
      ) : (
        <EmptyState componentClass="positionCenter" image={image} title="No companies found" />
      );

    audExplorerCompanyDetailsForm = (
      <AudExplorerCompanyDetailsForm
        handleClose={companyDetailsModal.hide}
        showModal={companyDetailsModal.isVisible}
        companyId={companyDetailsModal?.data ?? 0}
      />
    );
  }

  return (
    <div id="companiesPanel" className={styles.companiesPanel}>
      <SectionTitle
        titleClass={styles.sectionTitle}
        title={explorerSlice.viewMode === ViewMode.Contacts ? "" : "Companies"}
        titleWrapperClass={explorerSlice.viewMode === ViewMode.Contacts ? "mT30" : ""}
        rightContentClass={explorerSlice.viewMode === ViewMode.Contacts ? styles.panelClosed : ""}
        icon={<SectionCompany imgStyle="emptyStateImage" />}
        rightContent={
          <div className={styles.minimizePanel}>
            <ArrowLeftSLineIcon
              className={`circleToggleICon cursor_pointer iconGray400 ${
                explorerSlice.viewMode === ViewMode.Contacts ? styles.transformRight : ""
              }`}
              onClick={() => dispatch(audienceExplorerSlice.actions.clickCompaniesArrow())}
            />
          </div>
        }
      />
      <div className="width_100 height_100">
        <div className="flex height_100">
          {explorerSlice.viewMode === ViewMode.Contacts ? <p className={styles.companiesTitle}>Companies</p> : table}
        </div>
      </div>
      {audExplorerCompanyDetailsForm}
    </div>
  );
};

export default Companies;
