import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

//Helpers
import { useOrgDateTimeFormat } from "../../../helper/hooks/useOrgDateTimeFormat";
import { advanceEmailDeliverabilityTestsOptions } from "../../../helper/enums/advanceEmailDeliverabilityTestsOptions";
import { filterData } from "../../../helper/tableConstants";
import { toastMessages } from "../../../helper/toastMessagesConstants";
import useSelectList from "../../../helper/hooks/query/useSelectList";
import { SelectListEnum } from "../../../helper/hooks/query/SelectListEnum";

//Redux
import {
  fetchAdvanceEmailTestDetails,
  fetchListOfAdvanceEmailTests,
} from "../../../store/EmailDeliverabilityTest/Global/actions/advanceEmailTest";
import { searchRecords, setFilter } from "../../../store/EmailDeliverabilityTest/AdvanceEmailTestTable/actions/advanceEmailTestTable";

// Style
import styles from "./AdvanceEmailDeliverabilityTest.module.scss";

//Images & Icons
import EmptyDeliverabilityTestImage from "../../../assets/Images/EmptyDeliverabilityTestImage/EmptyDeliverabilityTestImage";
import ArrowLeftSFillIcon from "remixicon-react/ArrowLeftSFillIcon";
import ArrowRightSFillIcon from "remixicon-react/ArrowRightSFillIcon";

// Components
import Button from "../../../components/UI/Button/Button";
import GroupButtons from "../../../components/UI/GroupButtons/GroupButtons";
import AdvanceEmailTestByReport from "./ByReport/AdvanceEmailTestByReport";
import AllReports from "../AdvanceEmailDeliverabilityTest/AllReports/AllReports";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import AdvanceEmailDeliverabilityTestModel from "../EditEmailTemplates/AdvanceEmailDeliverabilityTestModel";
import SendTestEmailForm from "../SendTestEmailForm";
import Spinner from "../../../components/UI/Spinner/Spinner";

const AdvanceEmailDeliverabilityTest = (props) => {
  const {
    fetchListOfAdvanceEmailTests,
    listOfAdvanceTests,
    fetchAdvanceEmailTestDetails,
    emailTemplate,
    templateDictionary,
    setDictionaryValues,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [allReportsTestId, setAllReportsTestId] = useState(-1);

  let longDateTimeFormat = useOrgDateTimeFormat();
  const [templateId, setTemplateId] = useState(0);
  const [advanceTestDetails, setAdvanceTestDetails] = useState({});

  const { data: emailAccountsList } = useSelectList(SelectListEnum.EmailAccounts);

  useEffect(() => {
    if (props.templateId !== undefined) setTemplateId(parseInt(props.templateId));
    if (props.advanceTestDetails !== undefined) setAdvanceTestDetails(props.advanceTestDetails);
    return () => {
      setTemplateId(0);
      setAdvanceTestDetails({});
    };
  }, [props.templateId, props.advanceTestDetails]);

  useEffect(() => {
    if (templateId > 0) {
      fetchListOfAdvanceEmailTests(templateId);
    }
  }, [templateId]);

  useEffect(() => {
    if (!_.isEmpty(listOfAdvanceTests) && activeIndex !== -1 && templateId > 0) {
      fetchAdvanceEmailTestDetails(listOfAdvanceTests[activeIndex].value);
    }
  }, [listOfAdvanceTests, activeIndex]);

  useEffect(() => {
    if (!_.isEmpty(listOfAdvanceTests) && allReportsTestId !== -1) {
      let index = listOfAdvanceTests.findIndex((x) => parseInt(x.value) === allReportsTestId);
      setActiveIndex(index);
      setActiveTab(0);
    }
  }, [listOfAdvanceTests, allReportsTestId]);

  const handelPreviousEmailAdvanceTestDetails = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      toast.info(toastMessages.emailTemplate.noPreviousDeliverabilityTestReport);
    }
  };

  const handelNextEmailAdvanceTestDetails = () => {
    let newIndex = activeIndex + 1;
    if (newIndex < listOfAdvanceTests.length) {
      setActiveIndex(newIndex);
    } else {
      toast.info(toastMessages.emailTemplate.noNextDeliverabilityTestReport);
    }
  };

  let image = <EmptyDeliverabilityTestImage className={styles.preperingImg} />;
  let content = <p>No Deliverability Tests</p>;

  let selectRowOptions = [
    {
      option: emailAccountsList,
      change: (e) => filterData("EmailAccount", e, props.setFilter),
      placeholder: "Sender Account",
    },
    {
      option: advanceEmailDeliverabilityTestsOptions,
      change: (s) => filterData("Status", s, props.setFilter),
      placeholder: "Status",
    },
  ];

  let result = <Spinner />;
  if (props.isLoaded && !props.isLoadingTestDetails) {
    result = _.isEmpty(listOfAdvanceTests) ? (
      <div className={`${styles.emptyState} height_100`}>
        <EmptyState
          image={image}
          title="No deliverability test found"
          content="Deliverability test for this email template is not generated."
          description="Click on the “Deliverability Test” button."
        />
      </div>
    ) : (
      <>
        <div className={styles.contentWrapper}>
          {activeTab === 0 ? (
            <div className={styles.buttonsNavigation}>
              <div className={`${styles.navigationWrapper} flex`}>
                <div className={`${styles.navigationButtons} flex`}>
                  <Button id="leftArrowBtn" variant="secondary-l-icon" classes="p0" onClick={() => handelPreviousEmailAdvanceTestDetails()}>
                    <ArrowLeftSFillIcon size={22} className="iconGray400" />
                  </Button>
                  <Button id="rightArrowBtn" variant="secondary-l-icon" classes="p0" onClick={() => handelNextEmailAdvanceTestDetails()}>
                    <ArrowRightSFillIcon size={22} className="iconGray400" />
                  </Button>
                </div>
                <p>
                  Email Subject: <span>{advanceTestDetails?.subject}</span>
                </p>
              </div>
              <GroupButtons leftButtonName="By Report" rightButtonName="All Reports" setActiveTab={setActiveTab} activeTab={activeTab} />
            </div>
          ) : (
            <TableFiltersRow
              placeholder="subject"
              changeFilter={(text) => props.searchRecords(text)}
              selects={selectRowOptions}
              rightContent={
                <GroupButtons leftButtonName="By Report" rightButtonName="All Reports" setActiveTab={setActiveTab} activeTab={activeTab} />
              }
            />
          )}
        </div>

        {!_.isEmpty(advanceTestDetails) && (
          <div className={styles.contentWrapper}>
            {activeTab === 0 ? (
              <>
                <div className={styles.detailsSenderStatus}>
                  <p>
                    Sent From: <span>{advanceTestDetails.from}</span>
                  </p>
                  <div className={styles.statusDateDetails}>
                    <div className={styles.statusInfo}>
                      <div className={styles.label}>
                        <p>Status:</p>
                      </div>
                      <div className={styles.detail}>
                        <span className={`${styles.circle} ${styles.success}`} />
                        <span className={styles.status}>{advanceTestDetails.status}</span>
                      </div>
                    </div>
                    <div className={styles.timeInfo}>
                      <div className={styles.label}>
                        <p>Date Received:</p>
                      </div>
                      <div className={styles.detail}>
                        <span className={styles.date}>{moment(advanceTestDetails.date).format(longDateTimeFormat)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <AdvanceEmailTestByReport advanceTestDetails={advanceTestDetails} />
              </>
            ) : (
              activeTab === 1 && (
                <>
                  <AllReports setAllReportsTestId={setAllReportsTestId} emailTemplateId={templateId} />
                </>
              )
            )}
          </div>
        )}
      </>
    );
  }

  if (props.isLoading || props.isLoadingTestDetails) {
    result = <Spinner />;
  }

  return (
    <>
      {result}

      <AdvanceEmailDeliverabilityTestModel
        handleClose={props.handleConfigurationTestModalClose}
        showModal={props.showConfigurationTestModal}
        emailTemplate={emailTemplate}
        templateId={templateId}
        templateDictionary={templateDictionary}
        setDictionaryValues={setDictionaryValues}
      />

      <SendTestEmailForm
        handleClose={props.handleTestMailFormClose}
        showModal={props.showTestEmailFormModal}
        userEmail={props.userEmail}
        emailTemplate={emailTemplate}
        emailAccounts={props.emailAccounts}
        templateDictionary={templateDictionary}
        setDictionaryValues={setDictionaryValues}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.advanceEmailDeliverabilityTest.isLoading,
    isLoaded: state.advanceEmailDeliverabilityTest.isLoaded,
    error: state.advanceEmailDeliverabilityTest.error,
    orgId: state.user.organization.id,
    listOfAdvanceTests: state.advanceEmailDeliverabilityTest.listOfAdvanceTests,
    advanceTestDetails: state.advanceEmailDeliverabilityTest.advanceTestDetails,
    userEmail: state.user.email,
    emailAccounts: state.globalEmailAccounts.listEmailAcconts,
    isLoadingTestDetails: state.advanceEmailDeliverabilityTest.isLoadingTestDetails,
    isLoadedTestDetails: state.advanceEmailDeliverabilityTest.isLoadedTestDetails,
    errorTestDetails: state.advanceEmailDeliverabilityTest.errorTestDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListOfAdvanceEmailTests: (emailTemplateId) => dispatch(fetchListOfAdvanceEmailTests(emailTemplateId)),
    fetchAdvanceEmailTestDetails: (id) => dispatch(fetchAdvanceEmailTestDetails(id)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    searchRecords: (value) => dispatch(searchRecords(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceEmailDeliverabilityTest);
