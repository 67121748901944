import _ from "lodash";
import { useStore } from "react-redux";

export const useOrgNumberFormatting = (value, minimumFractionDigits = 0) => {
  const store = useStore();
  let organization = store.getState().organizationSettingsGeneral?.settings;
  let defaultGroupSeparatorValue = ",";
  let defaultDecimalSeparatorValue = ".";
  let result = "";

  if (value === undefined || value === null) {
    return 0;
  }

  if (!_.isEmpty(organization)) {
    let { numberGroupSeperator, numberDecimalSeperator } = organization;
    defaultGroupSeparatorValue = numberGroupSeperator;
    defaultDecimalSeparatorValue = numberDecimalSeperator;
  }

  let formattedNumber = value.toLocaleString(navigator.language, {
    minimumFractionDigits: minimumFractionDigits,
  });

  if (
    !_.isNull(defaultGroupSeparatorValue) ||
    !_.isUndefined(defaultGroupSeparatorValue) ||
    !_.isEmpty(defaultGroupSeparatorValue)
  ) {
    result = formattedNumber.replaceAll(",", defaultGroupSeparatorValue);
  }

  if (
    !_.isNull(defaultDecimalSeparatorValue) ||
    !_.isUndefined(defaultDecimalSeparatorValue) ||
    !_.isEmpty(defaultDecimalSeparatorValue)
  ) {
    if (minimumFractionDigits > 0) {
      result = result.replaceAll(".", defaultDecimalSeparatorValue);
    }
  }

  return result;
};
