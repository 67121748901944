import React from "react";

//Style
import "./SkeletonTimeline.scss";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";
import SkeletonThreeRowCard from "../SkeletonThreeRowCard/SkeletonThreeRowCard";

const SkeletonTimeline = (props) => {
  return (
    <div className="skeleton-wrapper flex skeletonTimelineWrapper">
      <div className="skeletonTimelineCard">
        <SkeletonThreeRowCard type="text-4" />
      </div>
      <div>
        <SkeletonElement type="vertical_line" />
      </div>
      <div className="skeletonTimelineCard SkeletonTimelineRight">
        <SkeletonThreeRowCard type="text-4" />
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonTimeline;
