import React, { FC } from "react";
import Action from "../Action";
import CheckLineIcon from "remixicon-react/CheckLineIcon";

interface ActionCheckProps {
  size?: number;
  classNameIcon?: string;
}

const ActionCheck: FC<ActionCheckProps> = ({ size = 18, classNameIcon = "", ...props }) => {
  return <Action materialIcon={<CheckLineIcon size={size} className={classNameIcon} />} {...props} />;
};

export default ActionCheck;
