export const audienceContactStatusEnum = {
  Ready: 1,
  AddedToCampaign: 2,
  Duplicate: 3,
  NotFound: 5,
  Excluded: 7,
  AwaitingEmailFinding: 8,
  DontImport: 10,
  Deleted: 11,
};
