import React from "react";

const Stage3 = (props) => (
  <svg width="203" height="63" viewBox="0 0 203 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M202.275 0.109901H0.610352L13.0837 62.1604H189.801L202.275 0.109901Z" fill="#FE983E" />
    <path d="M102.304 0.109871H202.274L189.004 62.1604H102.304V0.109871Z" fill="#CA7A34" />
    <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="203" height="63">
      <path d="M202.275 0.109901H0.610352L13.0837 62.1604H189.801L202.275 0.109901Z" fill="#FE983E" />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);
export default Stage3;
