import * as actionTypes from "../actions/actionTypes";
import update from "immutability-helper";
import _ from "lodash";
export const initialState = {
  isInitiated: false,
  isLoading: false,
  isLoaded: false,
  error: "",
  params: {
    start: 0,
    page: 0,
    length: 10,
    sort: {},
    filter: [],
    search: "",
  },
  rows: [],
  selectedRow: {},
  totalCount: 0,
  pageCount: 0,
  hasNewTotalCount: false,
};

const reducer = (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case actionTypes.RESPONDER_INBOX_INITIATE:
      return initiate(state, action);
    case actionTypes.FETCH_RESPONDER_INBOX_REQUEST:
      return fetchRequest(state, action);
    case actionTypes.FETCH_RESPONDER_INBOX_SUCCEEDED:
      return fetchSuccessResponderInbox(state, action);
    case actionTypes.RESPONDER_INBOX_COUNT_CHANGE:
      return responderInboxCountChange(state, action);
    case actionTypes.FETCH_RESPONDER_INBOX_FAILED:
      return fetchFailed(state, action);
    case actionTypes.RESPONDER_INBOX_CHANGE_PAGE:
      return changePage(state, action);
    case actionTypes.RESPONDER_INBOX_SORT_DATA:
      return sortData(state, action);
    case actionTypes.RESPONDER_INBOX_NUM_RECORDS:
      return numRecords(state, action);
    case actionTypes.RESPONDER_INBOX_SEARCH_RECORDS:
      return searchRecords(state, action);
    case actionTypes.RESPONDER_INBOX_SET_FILTER:
      return setFilter(state, action);
    case actionTypes.RESPONDER_INBOX_CLEAR_FILTER:
      return clearFilter(state, action);
    case actionTypes.RESPONDER_INBOX_CLEAR_INITIATE:
      return clearInitiated(state, action);
    case actionTypes.RESPONDER_INBOX_SET_SELECTED_ROW:
      return setSelectedRow(state, action);
    case actionTypes.RESPONDER_INBOX_CLEAR_SELECTED_ROW:
      return clearSelectedRow(state, action);
    case actionTypes.SET_MESSAGE_RESOLVED:
      return setMessageResolved(state, action);
    default:
      return state;
  }
};
const initiate = (state, action) => {
  const initState = { ...action.result };
  const newState = { ...state, ...initState, isInitiated: true };

  return newState;
};

const fetchRequest = (state, action) => {
  const newState = update(state, { $merge: { isLoading: true } });
  return newState;
};

const fetchFailed = (state, action) => {
  const newState = update(state, {
    $merge: {
      error: action.result,
      isLoading: false,
    },
  });
  return newState;
};

const changePage = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newPage = action.result;
  const newStart = newPage * params.length;
  params.page = newPage;
  params.start = newStart;
  newState.params = params;
  return newState;
};

const sortData = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  params.sort = action.result;
  newState.params = params;

  return newState;
};

const numRecords = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newLength = action.result.value;
  params.length = newLength;
  newState.params = params;
  return newState;
};

const searchRecords = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newSearch = action.result;
  params.search = newSearch;
  params.start = 0;
  newState.params = params;
  return newState;
};

const setFilter = (state, action) => {
  const newState = { ...state };
  const params = { ...newState.params };
  const newFilters = [...params.filter];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  newFilters.push({ type: action.filterType, value: action.filterValue });
  params.filter = newFilters;
  newState.params = params;
  return newState;
};

const clearFilter = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newFilters = [...state.params.filter];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  params.filter = newFilters;
  newState.params = params;
  return newState;
};

const clearInitiated = (state, action) => {
  const newState = { ...state };
  newState.isInitiated = false;
  newState.isLoaded = false;
  newState.isLoading = false;
  const originalParams = { ...state.params };
  originalParams.start = 0;
  originalParams.page = 0;
  originalParams.length = 10;
  originalParams.sort = {};
  originalParams.filter = [];
  originalParams.search = "";
  newState.params = originalParams;

  return newState;
};

export const setSelectedRow = (state, action) => {
  const newState = { ...state };
  newState.selectedRow = { ...action.result };
  return newState;
};

export const clearSelectedRow = (state, action) => {
  const newState = { ...state };
  newState.selectedRow = {};
  return newState;
};

export const fetchSuccessResponderInbox = (state, action) => {
  //check to see if there are more emails from the first api call so to alert the user
  const hasNewTotalCount = state.totalCount !== 0 && state.totalCount !== action.result.totalCount && state.params.start !== 0;
  //filter not to have duplicate emails
  let existingIds = state.rows.map((el) => el.id);
  let filteredEmails = action.result.data.filter((email) => !existingIds.includes(email.id));
  let newRows = [...state.rows, ...filteredEmails];

  if (state.params.start === 0) {
    newRows = action.result.data;
  }

  const newState = update(state, {
    $merge: {
      isLoaded: true,
      isLoading: false,
      rows: newRows,
      totalCount: action.result.totalCount,
      unresolved: action.result.unresolved,
      forReviewCount: action.result.forReviewCount,
      resolved: action.result.resolved,
      pageCount: action.result.totalCount / state.params.length,
    },
  });
  newState.hasNewTotalCount = hasNewTotalCount;
  return newState;
};

export const responderInboxCountChange = (state, action) => {
  const newState = update(state, {
    $merge: {
      unresolved: state.unresolved + action.result,
      resolved: state.resolved - action.result,
      forReviewCount: state.forReviewCount,
    },
  });

  return newState;
};

const setMessageResolved = (state, action) => {
  let newState = { ...state };
  let filteredEmail = newState.rows.filter((email) => email.id === action.result.id);
  filteredEmail.isResolved = true;
  filteredEmail.type = action.result.responseType;
  let newRows = newState.rows.filter((email) => email.id !== action.result.id);
  newState.rows = newRows;

  return newState;
};

export default reducer;
