import React from "react";

//Style
import styles from "../Sandbox.module.scss";

//Images
import EmptyAudiencesList from "../../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import EmptyEmailAccount from "../../../assets/Images/NoEmailAccounstImage/NoEmailAccounstImage";
import EmptyCampaignList from "../../../assets/Images/EmptyCampaignList/EmptyCampaignList";
import Preparing from "../../../assets/Images/PreparingImage/Preparing";
import NoResultsFound from "../../../assets/Images/NoResultsFound/NoResultsFound";
import EmptyData from "../../../assets/Images/EmptyData/EmptyData";

const EmptyStateImages = () => {
  let agents = <EmptyAudiencesList />;
  let email = <EmptyEmailAccount />;
  let campaign = <EmptyCampaignList />;
  let noResults = <NoResultsFound />;
  let emptyData = <EmptyData />;
  return (
    <div className={styles.emptyImagesWrapper}>
      <h5>Empty agents/users list</h5>
      <EmptyState image={agents} title="Some title" description="Some description" />
      <h5>Empty accounts</h5>
      <EmptyState image={email} title="Some title" description="Some description" />
      <h5>Empty campaign</h5>
      <EmptyState image={campaign} title="Some title" description="Some description" />
      <h5>No results found</h5>
      <EmptyState image={noResults} title="Some title" description="Some description" />
      <h5>EmptyData</h5>
      <EmptyState image={emptyData} title="Some title" description="Some description" />
    </div>
  );
};

export default EmptyStateImages;
