import { useEffect } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import ReactJsonViewCompare from "react-json-view-compare";
import { Link } from "react-router-dom";

//Redux
import { fetchUserActivityLogDetails } from "../../../../store/Outboss/UserActivityLogs/Global/actions/userActivityLogs";

//Styles
import styles from "./ActivityDetailsModal.module.scss";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const ActivityDetailsModal = (props) => {
  const { activityLogId, fetchUserActivityLogDetails, userActivityLogDetails } = props;

  let modalBody = "No data";

  useEffect(() => {
    if (activityLogId) fetchUserActivityLogDetails(activityLogId);
  }, [activityLogId]);

  if (props.isLoading) {
    modalBody = <Spinner />;
  }

  if (props.isLoaded) {
    modalBody = (
      <>
        <div className={`flex`}>
          <p className={`${styles.details}`}>
            <span className={styles.label}>Username: </span>
            <span className={styles.data}>{userActivityLogDetails.fullName}</span>
          </p>
          <p className={styles.details}>
            <span className={styles.label}>Entity Type:</span>
            <span className={styles.data}>{userActivityLogDetails.entityType}</span>
          </p>
        </div>
        <div className={`flex`}>
          <p className={`${styles.details}`}>
            <span className={styles.label}>E-mail Address: </span>
            <span className={styles.data}>{userActivityLogDetails.emailAddress}</span>
          </p>
          <p className={styles.details}>
            <span className={styles.label}>Activity Date:</span>
            <span className={styles.data}>{Moment(userActivityLogDetails.createdDate).format("DD MMM YYYY hh:mm")}</span>
          </p>
        </div>
        <div className={`flex mB20`}>
          <p className={styles.details}>
            <span className={styles.label}>Organization ID:</span>
            <Link className={styles.data} to={{ pathname: `/${userActivityLogDetails.organizationId}/dashboard/` }}>
              {userActivityLogDetails.organizationId}
            </Link>
          </p>
          <p className={`${styles.details}`}>
            <span className={styles.label}>Organization name: </span>
            <Link className={styles.data} to={{ pathname: `/${userActivityLogDetails.organizationId}/dashboard/` }}>
              {userActivityLogDetails.organizationName}
            </Link>
          </p>
        </div>
        <p className={styles.detailsLabel}>Activity Details:</p>
        <p className="f14">{userActivityLogDetails.messageDescription}</p>
        <div className="flex">
          <ReactJsonViewCompare
            oldData={JSON.parse(userActivityLogDetails.entityJSONBefore)}
            newData={JSON.parse(userActivityLogDetails.entityJSON)}
            mergedView={false}
          />
        </div>
      </>
    );
  }

  if (props.error !== "") {
    modalBody = "There has been a problem. Please try again later";
  }
  return (
    <Modal
      title="Activity Details"
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      size="xl"
      oneButton="true"
      btnType="submit"
      saveButtonText="OK"
    >
      {modalBody}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    userActivityLogDetails: state.outbossUserActivityLogs.userActivityLogDetails,
    isInitiated: state.outbossUserActivityLogs.isInitiated,
    isLoading: state.outbossUserActivityLogs.isLoading,
    isLoaded: state.outbossUserActivityLogs.isLoaded,
    error: state.outbossUserActivityLogs.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserActivityLogDetails: (id) => dispatch(fetchUserActivityLogDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetailsModal);
