import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { fetchEmailAccountsList, clearInitiated as clearInitiatedEmails } from "@store/EmailAccounts/Global/actions/emailAccounts";

//Redux
import { fetchSettings } from "@store/OrganizationSettings/General/actions/general";
import { fetchSendingSchedule } from "@store/SendingSchedule/actions/sendingShedule";
import { fetchGlobalSettings } from "@store/global/actions/index";
import { clearInitiated } from "@store/WorkflowSteps/actions/workflowSteps";

//Helpers
import api, { API } from "@helper/api/api";
import { toastMessages } from "@helper/toastMessagesConstants";
import { initialWeight } from "@helper/emailAccountConstants";
import { errorResponseToList } from "@helper/errorHelper";
import { addEmailAccountContinue } from "@helper/addEmailAccountHelper";
import useRouteParams from "@hooks/useRouteParams";

//Style
import styles from "./AddSenderAccountDrawer.module.scss";

//Icons & Images
import EmptyListAgents from "@assets/Images/EmptyListAgents/EmptyListAgents";
import AddLineIcon from "remixicon-react/AddLineIcon";
import EqualizerLineIcon from "remixicon-react/EqualizerLineIcon";

//Components
import Button from "@ui/Button/Button";
import Spinner from "@ui/Spinner/Spinner";
import AccountRangeSlider from "../AccountRangeSlider/AccountRangeSlider";

import FormikEffect from "@ui/Formik/FormikEffect";
import InfoIconTooltip from "@ui/CustomTooltip/InfoIconTooltip";
import FormikInput from "@ui/Formik/FormikInput";
import SelectMulti from "@ui/Select/SelectMulti";
import EmptyState from "@ui/EmptyState/EmptyState";
import AddEmailAccountModal from "@pages/EmailAccounts/AddEmailAccountModal/AddEmailAccountModal";
import SaveChangesFooter from "@components/SaveChangesFooter/SaveChangesFooter";
import CancelWarningForm from "@components/CancelWarningForm/CancelWarningForm";
import CreateEmailAccountModal from "../CreateEmailAccountModal/CreateEmailAccountModal";

const AddSenderAccountDrawer = (props) => {
  const { schedule, clearInitiated, listEmailAccounts, clearInitiatedEmails, emailAccountAdded } = props;
  const { campaignId, organizationId } = useRouteParams();
  const history = useHistory();

  const [isDirty, setIsDirty] = useState(false);
  const [selectedAccountValues, setSelectedAccountValues] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  let accountsComponent = null;

  const [perDayProspects, setPerDayProspects] = useState(schedule.prospectsPerDay);
  const [showAddEmailAccountModal, setShowAddEmailAccountModal] = useState(false);

  let accountRedirectUrlPrefix = "/" + organizationId;

  const emptyStateTitle = <>You need to link at least one active email account.</>;

  useEffect(() => {
    if (listEmailAccounts.length !== 0) {
      const selectedAccountInitialValues = listEmailAccounts
        .filter((acc) => acc.usedInCampaign === true)
        .map((acc) => acc.emailAccountId?.toString());
      if (emailAccountAdded) {
        if (selectedAccountInitialValues?.length !== 0 && !selectedAccountInitialValues?.includes(emailAccountAdded)) {
          props.setHasChanges(true);
          selectedAccountInitialValues.push(emailAccountAdded.toString());
        }
      }
      setSelectedAccountValues(selectedAccountInitialValues);
      let initialSelectedAccounts = listEmailAccounts.filter(
        (acc) => acc.usedInCampaign === true || acc.emailAccountId == emailAccountAdded
      );
      setSelectedAccounts(initialSelectedAccounts);
      if (emailAccountAdded) {
        const index = selectedAccountInitialValues.findIndex((acc) => acc === emailAccountAdded.toString());
        if (index !== -1) {
          handleAccountSelectionChange(selectedAccountInitialValues);
        }
      }
    }
  }, [listEmailAccounts, emailAccountAdded]);

  useEffect(() => {
    let accounts = getNewProspectPerDayValues(selectedAccounts);
    setSelectedAccounts(accounts);
  }, [perDayProspects, selectedAccountValues]);

  useEffect(() => {
    const fetchData = async () => {
      await props.fetchSettings();
      await props.fetchGlobalSettings();
      await props.fetchSendingSchedule(campaignId);
      await props.fetchEmailAccountsList(campaignId);
      await props.fetchEmailAccountsList(campaignId);
    };

    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
      clearInitiatedEmails();
    };
  }, []);

  const [showCreateEmailAccountModal, setShowCreateEmailAccountModal] = useState(false);

  const handleCreateEmailAccountClose = () => {
    setShowCreateEmailAccountModal(false);
  };

  const [pathname, setPathname] = useState(null);

  const handleAddEmailAccountClose = () => {
    setShowAddEmailAccountModal(false);
  };

  const handleAddEmailAccountContinue = (typeId) => {
    addEmailAccountContinue(typeId, campaignId, organizationId);
  };

  let form = null;

  const selectOptions = listEmailAccounts
    .filter((x) => !(!x.isConnected && !x.usedInCampaign))
    .map((e) => {
      return {
        value: e.emailAccountId?.toString(),
        label: (
          <div className={styles.detailsWrapper} disabled={!e.isConnected && !e.usedInCampaign}>
            <div className={styles.accountDetails}>
              <p className={styles.accountOwner}>
                {e.emailName}
                <span className={styles.ownerEmail}>User: {e.userFullName}</span>
              </p>
              <p className={styles.ownerEmail}>
                {e.email}{" "}
                {!e.isConnected ? (
                  <span className={styles.notConnectedEmailAccount}> Disconnected </span>
                ) : e.usedInOtherCampaigns ? (
                  <span className={styles.inAnotherCampaign}> In another campaign</span>
                ) : null}
              </p>
            </div>
          </div>
        ),
        labelShow: e.email,
        checkBoxClass: styles.listCheckbox,
        isDisabled: !e.isConnected && !e.usedInCampaign,
      };
    });

  const customButton = (
    <Button
      type="button"
      onClick={() => {
        props.setIgnore(true);
        props.setHasChanges(false);
        setShowAddEmailAccountModal(true);
      }}
      variant="link_button"
      classes={styles.addButton}
    >
      <AddLineIcon size={14} /> Add new account
    </Button>
  );

  const selectListStyled = [...selectOptions, { value: 0, label: customButton, labelShow: "", checkBoxClass: styles.hideCheckbox }];

  const removeEmailAccount = (emailAccountId) => {
    let newListValues = [...selectedAccountValues];
    let indexValues = selectedAccountValues.findIndex((x) => x === emailAccountId.toString());

    props.setHasChanges(true);
    if (indexValues > -1) {
      newListValues.splice(indexValues, 1);
      setSelectedAccountValues(newListValues);
    }

    let newList = [...selectedAccounts];
    let index = selectedAccounts.findIndex((x) => x.emailAccountId === emailAccountId);

    if (index > -1) {
      newList.splice(index, 1);
      let accNew = newList.findIndex((x) => x.activityWeight === 0);
      if (accNew > -1) {
        newList[accNew].activityWeight = initialWeight;
      }
      setSelectedAccounts(newList);
    }
  };

  const equalize = (e) => {
    e.preventDefault();

    props.setHasChanges(true);
    let newSelectedAccounts = [...selectedAccounts];

    newSelectedAccounts.forEach((account, index) => {
      let newAccount = { ...account };
      newAccount.activityWeight = initialWeight;

      newSelectedAccounts[index] = newAccount;
    });

    newSelectedAccounts = getNewProspectPerDayValues(newSelectedAccounts);
    setSelectedAccounts(newSelectedAccounts);
  };

  useEffect(() => {
    setPerDayProspects(schedule.prospectsPerDay);
  }, [schedule.prospectsPerDay]);

  const onChange = (values) => {
    if (perDayProspects !== values.prospectsPerDay) {
      if (typeof props.setHasChanges === "function") {
        props.setHasChanges(true);
      }
      setPerDayProspects(values.prospectsPerDay);
    }
  };

  const getNewProspectPerDayValues = (accounts) => {
    let newSelectedAccounts = [...accounts];
    let sumOfAllSliders = newSelectedAccounts.map((s) => s.activityWeight).reduce((a, b) => a + b, 0);

    newSelectedAccounts.forEach((account, index) => {
      let perDayValue = (perDayProspects / sumOfAllSliders) * account.activityWeight;
      let newAccount = { ...account };
      newAccount.perDayValue = Math.round(perDayValue);
      newSelectedAccounts[index] = newAccount;

      //check difference because of rounding
      if (index === newSelectedAccounts.length - 1) {
        let newSumValue = newSelectedAccounts.map((s) => s.perDayValue).reduce((a, b) => a + b, 0);

        if (newSumValue !== perDayProspects) {
          let difference = perDayProspects - newSumValue;
          if (newAccount.perDayValue + difference < 0) {
            for (let i = index - 1; i >= 0; i--) {
              if (newSelectedAccounts[i] + difference >= 0) {
                newSelectedAccounts[i] = Math.round(perDayValue) + difference;
                break;
              }
            }
          } else {
            newAccount.perDayValue = Math.round(perDayValue) + difference;
          }
          newSelectedAccounts[index] = newAccount;
        }
      }
      if (Number.isNaN(newAccount.perDayValue)) {
        newAccount.perDayValue = 0;
        newSelectedAccounts[index] = newAccount;
      }
    });

    return newSelectedAccounts;
  };

  const handleTotalChange = (id, val) => {
    const index = selectedAccounts.findIndex((acc) => acc.emailAccountId === id);

    if (index > -1) {
      props.setHasChanges(true);
      const newAccount = { ...selectedAccounts[index] };
      newAccount.activityWeight = val;

      let newSelectedAccounts = [...selectedAccounts];
      newSelectedAccounts[index] = newAccount;

      newSelectedAccounts = getNewProspectPerDayValues(newSelectedAccounts);
      setSelectedAccounts(newSelectedAccounts);
    }
  };

  const handleAccountSelectionChange = (arr) => {
    setSelectedAccountValues(arr);
    props.setHasChanges(true);
    const newSelectedAccounts = [];
    arr.forEach((val) => {
      const emailAccountId = parseInt(val);
      const index = selectedAccounts.findIndex((acc) => acc.emailAccountId === emailAccountId);
      if (index === -1) {
        const account = listEmailAccounts.find((acc) => acc.emailAccountId === emailAccountId);
        if (account) {
          let newAccount = { ...account };
          newAccount.activityWeight = initialWeight;
          newSelectedAccounts.push(newAccount);
        }
      } else {
        const account = selectedAccounts.find((acc) => acc.emailAccountId === emailAccountId);
        newSelectedAccounts.push(account);
      }
    });
    setSelectedAccounts(newSelectedAccounts);
  };

  if (props.isLoading || props.isLoadingSettings || props.globalSettingsIsLoading || props.isLoadingEmailAccounts) {
    form = <Spinner spinnerClass="absolute transform-y-50" />;
  }

  // if (props.isLoaded && props.isLoadedSettings && props.globalSettingsIsLoaded && props.isLoadedEmailAccounts) {
  if (props.isLoadedEmailAccounts) {
    if (selectedAccounts !== null) {
      let showRangeSlider = false;
      accountsComponent = selectedAccounts.map((acc, index) => {
        showRangeSlider = selectedAccounts.length > 1;
        return (
          <AccountRangeSlider
            key={index}
            id={acc.emailAccountId}
            imageUrl={acc.imageUrl}
            emailName={acc.emailName}
            cardClass={styles.accountCard}
            email={acc.email}
            userFullName={acc.userFullName}
            messagesPerDay={acc.perDayValue}
            activityWeight={acc.activityWeight}
            usedInOtherCampaigns={acc.usedInOtherCampaigns}
            handleTotalChange={handleTotalChange}
            removeEmailAccount={() => removeEmailAccount(acc.emailAccountId)}
            showRangeSlider={showRangeSlider}
            isConnected={acc.isConnected}
            hasSignature={acc.hasSignature}
            orgId={organizationId}
            emailAccountId={acc.emailAccountId}
            index={index}
            isSuspended={acc.isSuspended}
            suspensionReason={acc.suspensionReason}
          />
        );
      });
    }

    form = (
      <Formik
        initialValues={{
          id: campaignId,
          prospectsPerDay: schedule.prospectsPerDay ? schedule.prospectsPerDay : perDayProspects ? perDayProspects : "0",
        }}
        validationSchema={Yup.object({
          prospectsPerDay: Yup.number("Enter valid Number").positive("Enter").min(0).integer("enter valid Number").required("Required"),
        })}
        onSubmit={(values, actions) => {
          if (!showAddEmailAccountModal) {
            {
              const data = { ...values };
              let accountsModel = selectedAccounts.map((acc) => {
                var newAcc = {
                  id: acc.id,
                  emailAccountId: acc.emailAccountId,
                  activityWeight: acc.activityWeight,
                  prospectsPerDay: acc.perDayValue,
                };
                return newAcc;
              });

              data.campaignEmailAccounts = {
                campaignId: campaignId,
                campaignEmailAccounts: accountsModel,
              };
              props.setHasChanges(false);
              api(API.sendingSchedule.connectEmailAccounts, data)
                .then((response) => {
                  toast.success(toastMessages.sendingSchedule.successCreateEdit);
                  props.setDrawerOpen(false);
                  props.handleSave();
                })
                .catch((error) => {
                  const errorList = errorResponseToList(error);
                  if (errorList.length > 0) {
                    errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                  } else {
                    toast.error(error.message);
                  }
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }
          }
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit } = formikProps;
          return (
            <Form>
              <FormikEffect onChange={onChange(values)} />
              <div className={styles.contentWrapper}>
                <div id="campaignDailyVolumes" className={styles.leftContent}>
                  <p className={styles.infoText}>Link one or more sender accounts which will be used to send out your campaign’s emails.</p>
                  <h1 className={styles.sectionTitle}>Sending volumes</h1>
                  <div className={` ${styles.formGroupPerDay} mB10`}>
                    <div className="flex  mB5">
                      <label htmlFor="prospectsEngaged">New prospects engaged</label>
                      <InfoIconTooltip disableStartTour tooltipBody={"Max 30 per day per email account recommended"} />
                    </div>
                    <div className={styles.inputWrapper}>
                      <FormikInput
                        formgroupclass={styles.formInput}
                        type="number"
                        name="prospectsPerDay"
                        inputclass={styles.setValueInput}
                        errorinputclass={styles.perDayErrorLabel}
                        value={values.prospectsPerDay}
                        ariaLabel="Prospects Engaged"
                        disabled={selectedAccounts.length === 0}
                      />
                      <label>per day</label>
                    </div>
                  </div>

                  <div className={styles.adjustWrapper}>
                    <div className={styles.formGroupColumn}>
                      <label className="mB5" htmlFor="addAgent">
                        Sender account/s
                      </label>
                      <SelectMulti
                        options={selectListStyled}
                        onChange={(event) => {
                          handleAccountSelectionChange(event);
                        }}
                        dropdownClass="width_100"
                        value={selectedAccountValues}
                        placeholder="Select email accounts"
                        displayName="Select email accounts"
                        isMulti={true}
                        isSearchable={true}
                        className={styles.agentDropdown}
                        id="addAgent"
                      />
                    </div>

                    {selectedAccounts.length > 0 ? (
                      <div
                        className={`${styles.agentsListWrapper} ${styles.selectedAgents} ${
                          selectedAccounts.length > 0 ? styles.multiAgentsList : ""
                        }`}
                      >
                        {accountsComponent}
                      </div>
                    ) : (
                      <div className={`${styles.noAgentsSelected} ${styles.agentsListWrapper}`}>
                        <div className={styles.emptyStateWrapper}>
                          <EmptyState image={<EmptyListAgents />} titleClass={styles.emptyStateTitle} title={emptyStateTitle} />
                        </div>
                      </div>
                    )}
                    {selectedAccounts.length > 1 && (
                      <div className={styles.equalize}>
                        <p className={styles.adjust}>
                          Adjust activity weighting between <b>selected agents</b>
                        </p>
                        <Button id="equalize" onClick={(e) => equalize(e)} classes={styles.equalizeButton} variant="secondary">
                          <EqualizerLineIcon size={14} />
                          Equalize
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <AddEmailAccountModal
                showModal={showAddEmailAccountModal}
                handleClose={handleAddEmailAccountClose}
                handleSave={handleAddEmailAccountContinue}
              />
              <SaveChangesFooter
                cancelClick={() => {
                  props.handleCloseDrawer(true);
                }}
                saveChangesClick={handleSubmit}
                saveButtonDisabled={isDirty}
                cancelButtonText="Back"
                saveButtonText="Save"
                footerClassName={styles.footer}
              />
              <CreateEmailAccountModal
                {...props}
                orgId={organizationId}
                showModal={showCreateEmailAccountModal}
                handleClose={handleCreateEmailAccountClose}
              />
            </Form>
          );
        }}
      </Formik>
    );
  }

  return <>{form}</>;
};

const mapStateToProps = (state) => {
  return {
    listEmailAccounts: state.globalEmailAccounts.listEmailAcconts,
    isLoadingEmailAccounts: state.globalEmailAccounts.isLoading,
    isLoadedEmailAccounts: state.globalEmailAccounts.isLoaded,
    isLoading: state.sendingSchedule.isLoading,
    isLoaded: state.sendingSchedule.isLoaded,
    error: state.sendingSchedule.error,
    schedule: state.sendingSchedule.sendingSchedule,
    settings: state.organizationSettingsGeneral.settings,
    isLoadingSettings: state.organizationSettingsGeneral.isLoading,
    isLoadedSettings: state.organizationSettingsGeneral.isLoaded,
    globalSettings: state.global.globalSettings,
    globalSettingsIsLoading: state.global.isLoading,
    globalSettingsIsLoaded: state.global.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSendingSchedule: (campaignId) => dispatch(fetchSendingSchedule(campaignId)),
    fetchSettings: () => dispatch(fetchSettings()),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
    clearInitiated: () => dispatch(clearInitiated()),
    fetchEmailAccountsList: (val) => dispatch(fetchEmailAccountsList(val)),
    clearInitiatedEmails: () => dispatch(clearInitiatedEmails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSenderAccountDrawer);
