import { FC } from "react";
import Modal from "../../components/UI/Modal/Modal";

interface DraftTemplateModalProps {
  handleClose: () => void;
  handleSave: () => void;
  showModal: boolean;
}

const DraftTemplateModal: FC<DraftTemplateModalProps> = (props) => {
  return (
    <Modal
      title={`Warning`}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="OK"
      btnType="submit"
    >
      <div>
        <p>
          The campaign has a draft email template in the sequence. Please make sure you update the campaign sequence, prior to going live.{" "}
        </p>
      </div>
    </Modal>
  );
};

export default DraftTemplateModal;
