import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

//Helper
import { PERMISSIONS } from "../../../../../helper/permissionConstants";
import api, { API, formUrl } from "../../../../../helper/api/api";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";
import { REGEX_EMAIL } from "../../../../../helper/validationConstants";

// Styles
import styles from "./BillingContacts.module.scss";
import classes from "../../../../../components/UI/Modal/Modal.module.scss";

//Images & Icons
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

//Components
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";
import Checkbox from "../../../../../components/UI/Checkbox/Checkbox";
import Button from "../../../../../components/UI/Button/Button";
import InfoIconTooltip from "../../../../../components/UI/CustomTooltip/InfoIconTooltip";
import Permission from "../../../../../components/auth/Permission";

const BillingContacts = (props) => {
  const [emailCheckbox, setEmailCheckbox] = useState(props?.ccEmails != undefined);
  const [inputState, setInputState] = useState(true);
  const [inputEmail, setInputEmail] = useState("");
  const [hasEmail, setHasEmail] = useState(props?.billingInfoEmail !== undefined);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setEmailCheckbox(props?.ccEmails != undefined);
    setHasEmail(props?.billingInfoEmail !== undefined);
  }, []);

  useEffect(() => {
    if (props?.ccEmails != null && props?.ccEmails !== "") {
      let arrayEmails = props.ccEmails.split(",");
      setTags(arrayEmails);
    }
  }, [props?.ccEmails]);

  const icon = <InfoIconTooltip tooltipBody={"To edit this info, you have to enter Billing address first."} disableStartTour={true} />;

  const handleCheckBox = () => {
    setEmailCheckbox(!emailCheckbox);
  };

  const handelInputState = () => {
    setInputState(!inputState);
  };

  const handleChange = (e) => {
    setInputEmail(e.currentTarget.value);
  };

  const handleCancelInput = () => {
    setInputState(true);
    setInputEmail("");
  };

  const removeTags = (tagIndexToRemove) => {
    let emailToRemove = tags.filter((_, index) => index === tagIndexToRemove);
    api(
      formUrl(API.billingAndPayment.deleteBillingEmailAddress, {
        email: emailToRemove,
      })
    )
      .then((res) => {
        toast.success(toastMessages.billing.successDeletingEmailAddress);
        setTags([...tags.filter((_, index) => index !== tagIndexToRemove)]);
      })
      .catch((err) => {
        const errorList = errorResponseToList(err);
        if (errorList.length > 0) {
          errorList.forEach((err) => toast.error(err.message));
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleSave = () => {
    if (props.billingInfoEmail != null) {
      if (inputEmail.trim().length > 0 && inputEmail.match(REGEX_EMAIL)) {
        api(
          formUrl(API.billingAndPayment.addBillingEmailAddress, {
            email: inputEmail,
          })
        )
          .then((res) => {
            toast.success(toastMessages.billing.successAddBillingEmailAddress);
            setTags([...tags, inputEmail]);
            setInputEmail("");
          })
          .catch((err) => {
            const errorList = errorResponseToList(err);
            if (errorList.length > 0) {
              errorList.forEach((err) => toast.error(err.message));
            } else {
              toast.error(err.message);
            }
          });
      } else {
        toast.warn(toastMessages.billing.warnNoEmailData);
      }
    } else {
      toast.warn(toastMessages.billing.warnNoBillingIfo);
    }
  };

  let widthInput = inputState ? styles.ninety : styles.eighty;
  let backGroundStyle = inputState ? styles.greyBack : styles.whiteBack;
  return (
    <div className={styles.componentWrapper}>
      <SectionTitle title="Billing Contacts" rightContent={icon} rightContentClass="width_auto" />
      <Permission has={PERMISSIONS.billingPayment.create}>
        <Checkbox
          checkBoxClass={styles.billingInvoices}
          labelClass={styles.billingNotification}
          label="Send billing notifications and invoices to additional email addresses"
          onClick={handleCheckBox}
          checked={emailCheckbox}
          disabled={!hasEmail}
        />
        {emailCheckbox ? (
          <div className={styles.billingContacts}>
            <div className={styles.emailInputGroup}>
              <input
                type="text"
                className={`${styles.inputStyle} ${backGroundStyle} ${widthInput}`}
                disabled={inputState}
                placeholder="Enter Email"
                value={inputEmail}
                onChange={(e) => handleChange(e)}
                onKeyUp={(event) => (event.key === "Enter" ? handleSave() : null)}
              />
              {inputState ? (
                <Button id="billingEdit" variant="rounded-white-m" classes="mR10 p0">
                  <Edit2LineIcon size={16} className={`iconGray ${styles.iconEdit}`} onClick={handelInputState} />
                </Button>
              ) : (
                <>
                  <Button id="billingAdd" variant="rounded-white-m" classes={`p0 ${styles.addButton}`}>
                    <AddLineIcon size={16} className="iconGray" onClick={handleSave} />
                  </Button>
                  <Button id="billingClose" variant="rounded-white-m" classes={`p0 ${styles.addButton}`}>
                    <CloseLineIcon size={16} className="iconGray" onClick={handleCancelInput} />
                  </Button>
                </>
              )}
            </div>
            <div className={`${classes.ulModalWrapper}`}>
              <ul className="flex mT10">
                {tags.map((tag, index) => (
                  <li key={index}>
                    <span className={`${classes.tagPill} textOverflow`}>{tag}</span>
                    <span className={classes.deleteTag} onClick={() => removeTags(index)}>
                      <CloseLineIcon size={10} />
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </Permission>
    </div>
  );
};
export default BillingContacts;
