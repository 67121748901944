export const organizationRoleEnum = {
  Owner: 1,
  Admin: 2,
  Editor: 3,
  Viewer: 4,
  StandardUser: 5,
};

export const organizationRoleOptions = [
  { value: 2, label: "Admin" },
  { value: 3, label: "Editor" },
  { value: 4, label: "Viewer" },
  { value: 5, label: "Standard User" },
];

export const getOrganizationRoleDescription = (organizationRole) => {
  var organizationRoles = [...organizationRoleOptions, { value: 1, label: "Owner" }];
  var result = "";
  organizationRoles.map((role) => {
    if (role.value === organizationRole) result = role.label;
  });
  return result;
};
