import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//Redux
import { fetchImportProcesses, importProcessesUpdateProgress } from "../../../store/ProspectImportProcesses/actions/importProcesses";
import { signalReceived } from "../../../store/Signal/actions/signal";
import { fetchCreditInfo, updateCurrentCredits } from "../../../store/BillingAndPayment/actions/billingAndPayment";

//Helpers
import SignalRContext from "../../../helper/SignalRContext";

//Style
import styles from "./ImportProgress.module.scss";

// Components
import ImportProgressBar from "./ImportProgressBar";
import Separator from "../Separator/Separator";
import { SIGNALR_CONSTANTS } from "../../../helper/signalrConstants";

const ImportProgress = (props) => {
  const [prospectsImportFinished, setProspectsImportFinished] = useState(false);
  const [prospectsImportStarted, setProspectsImportStarted] = useState(null);

  const { processes, fetchImportProcesses, fetchCreditInfo, importProcessesUpdateProgress, updateCurrentCredits, signalReceived } = props;

  const signalRConnection = useContext(SignalRContext);
  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_STARTED, (data) => {
        setProspectsImportStarted(true);
        signalReceived(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_STARTED, data);
      });
      signalRConnection.on(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_UPDATE, (data) => {
        importProcessesUpdateProgress(data);
        updateCurrentCredits(data.currentCredits);
        signalReceived(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_UPDATE, data);
      });
      signalRConnection.on(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_FINISHED, (data) => {
        setProspectsImportFinished(true);
        signalReceived(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_FINISHED, data);
      });
    }

    return () => {
      console.log("Signalr OFF");
      if (signalRConnection != null) {
        try {
          signalRConnection.off(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_UPDATE);
          signalRConnection.off(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_FINISHED);
          signalRConnection.off(SIGNALR_CONSTANTS.IMPORT_PROSPECTS_UPDATE);
        } catch {}
      }
    };
  }, [signalRConnection, importProcessesUpdateProgress, updateCurrentCredits]);

  useEffect(() => {
    fetchImportProcesses();
  }, []);

  useEffect(() => {
    if (prospectsImportStarted) {
      fetchImportProcesses();
      setProspectsImportStarted(false);
    }
  }, [prospectsImportStarted]);

  useEffect(() => {
    if (prospectsImportFinished) {
      fetchImportProcesses();
      fetchCreditInfo();
      setProspectsImportFinished(false);
    }
  }, [prospectsImportFinished]);

  let content = <></>;

  if (processes != null && processes.length > 0) {
    const firstProcess = processes[0];

    content = (
      <div className={styles.importProgressWrapper}>
        <NavLink
          to={{
            pathname: "/" + props.orgId + "/campaigns/" + firstProcess.campaignId + "/overview",
          }}
        >
          <ImportProgressBar
            accepted={firstProcess.accepted}
            totalSize={firstProcess.totalSize}
            campaignName={firstProcess.campaignName}
            isFinished={firstProcess.isFinished}
            totalImports={processes.length}
            displayMore={true}
          />
        </NavLink>

        {processes.length > 1 ? (
          <div className={styles.progressListDropdownWrapper}>
            <div className={styles.progressListDropdown}>
              {processes.map((process, index) => {
                return (
                  <>
                    <NavLink
                      to={{
                        pathname: "/" + props.orgId + "/campaigns/" + process.campaignId + "/overview",
                      }}
                    >
                      <ImportProgressBar
                        accepted={process.accepted}
                        totalSize={process.totalSize}
                        campaignName={process.campaignName}
                        isFinished={process.isFinished}
                        totalImports={process.length}
                        displayMore={false}
                      />
                    </NavLink>

                    {index < processes.length - 1 ? <hr /> : null}
                  </>
                );
              })}
            </div>
          </div>
        ) : null}
        <Separator />
      </div>
    );
  }

  return content;
};

const mapStateToProps = (state) => {
  return {
    processes: state.prospectImportProcesses.processes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchImportProcesses: () => dispatch(fetchImportProcesses()),
    fetchCreditInfo: () => dispatch(fetchCreditInfo()),
    importProcessesUpdateProgress: (progress) => dispatch(importProcessesUpdateProgress(progress)),
    updateCurrentCredits: (credits) => dispatch(updateCurrentCredits(credits)),
    signalReceived: (signalName, data) => dispatch(signalReceived(signalName, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportProgress);
