import React, { useRef, useEffect, FC } from "react";
import { isEmpty } from "lodash";

// Styles
import "./ProgressBar.scss";

// Components
import { ProgressBar } from "react-bootstrap";

interface ProgressBarUIProps {
  variant?: string;
  color?: "danger" | "warning" | "secondary";
  now?: number;
  max?: number;
  min?: number;
  animated?: boolean;
  ariaLabel?: string;
}

const ProgressBarUI: FC<ProgressBarUIProps> = (props) => {
  let classes = "progress";
  if (!isEmpty(props.variant)) classes += " " + [props.variant];

  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressBarRef.current) {
      const firstChild = progressBarRef.current.firstChild as HTMLElement;

      firstChild.classList.add("success_progress");

      if (props.color === "danger") {
        firstChild.classList.remove("success_progress");
        firstChild.classList.add("danger_progress");
      }

      if (props.color === "warning") {
        firstChild.classList.remove("success_progress");
        firstChild.classList.add("warning_progress");
      }

      if (props.color === "secondary") {
        firstChild.classList.remove("success_progress");
        firstChild.classList.add("secondary_progress");
      }
    }
  }, [props.color]);

  return (
    <ProgressBar
      className={classes}
      ref={progressBarRef}
      now={props.now}
      max={props.max}
      min={props.min}
      animated={props.animated}
      aria-labelledby={props.ariaLabel}
    />
  );
};

export default ProgressBarUI;
