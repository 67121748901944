import React from "react";

//Styles
import styles from "./CampaignSearchRow.module.scss";

//Components
import UserPhoto from "../../../../UI/UserPhoto/UserPhoto";

const CampaignSearchRow = (props) => {
  return (
    <div className={styles.rowStyle}>
      <div className="flex flex_center">
        <UserPhoto alt="org-photo" isCompanyImage={true} size="small" src={props.imageUrl} />
      </div>

      <div className={styles.detailsWrapper}>
        <p className={`${styles.name} f14`}>{props.name}</p>
        <span className={`${styles.status}`}>Status: {props.status}</span>
      </div>
    </div>
  );
};

export default CampaignSearchRow;
