import React, { useState, useEffect } from "react";
import { DropdownButton, OverlayTrigger, Popover } from "react-bootstrap";

// Styles
import styles from "./TableOptionsRow.module.scss";

// Icons
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import DownloadCloud2LineIcon from "remixicon-react/DownloadCloud2LineIcon";
import EqualizerLineIcon from "remixicon-react/EqualizerLineIcon";
import SearchLineIcon from "remixicon-react/SearchLineIcon";

// Components
import Checkbox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";

const TableOptionsRow = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [dataTableColumnsOptionsRow, setDataTableColumnsOptionsRow] = useState(props.dataTableColumns);
  const [allDataTableColumnsOptionsRow, setAllDataTableColumnsOptionsRow] = useState(props.allDataTableColumns);
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);
  const [dataTableColumnsBackup, setDataTableColumnsBackup] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const applyChanges = () => {
    props.setDataTableColumns(dataTableColumnsOptionsRow);
    props.applyChanges();
    setSearchInputValue("");
    setAllDataTableColumnsOptionsRow(props.allDataTableColumns);
    toggleDropdownMenu(false);
  };

  const toggleDropdownMenu = (isOpen) => {
    setApplyButtonDisabled(true);
    setDropdownOpen(isOpen);
    setAllDataTableColumnsOptionsRow(props.allDataTableColumns);
    setSearchInputValue("");
    if (isOpen) setDataTableColumnsBackup([...dataTableColumnsOptionsRow]);
  };

  const searchAllColumns = (value) => {
    if (value !== null && value !== "") {
      setAllDataTableColumnsOptionsRow(
        props.allDataTableColumns.filter((column) => column.label.toLowerCase().includes(value.toLowerCase()))
      );
    } else {
      setAllDataTableColumnsOptionsRow(props.allDataTableColumns);
    }
  };

  const countColumnsWithLabels = (columns) => {
    return columns.filter((column) => column.label).length;
  };

  const updateErrorMessage = (columns) => {
    const count = countColumnsWithLabels(columns);
    if (count === 0) {
      setErrorMessage("At least one column must be selected.");
    } else {
      setErrorMessage("");
    }
    setApplyButtonDisabled(count === 0);
  };

  useEffect(() => {
    allDataTableColumnsOptionsRow.map((hiddenColumn) => {
      if (hiddenColumn.isHidden && hiddenColumn.isHidden === true) {
        var newIndex = -1;
        dataTableColumnsOptionsRow.map((column, columnIndex) => {
          if (column.label === hiddenColumn.label) {
            newIndex = columnIndex;
          }
        });

        if (newIndex >= 0) {
          dataTableColumnsOptionsRow.splice(newIndex, 1);
        }
      }
    });

    if (props.deleteHiddenColumnsFromAllDataTableColumns) {
      allDataTableColumnsOptionsRow.map((hiddenColumn, hiddenColumnIndex) => {
        if (hiddenColumn.isHidden && hiddenColumn.isHidden === true) {
          var newIndex = -1;
          dataTableColumnsOptionsRow.map((column, columnIndex) => {
            if (column.label === hiddenColumn.label) {
              newIndex = columnIndex;
            }
          });
          if (newIndex >= 0) {
            dataTableColumnsOptionsRow.splice(newIndex, 1);
          }
        }
      });

      if (props.deleteHiddenColumnsFromAllDataTableColumns) {
        allDataTableColumnsOptionsRow.map((hiddenColumn, hiddenColumnIndex) => {
          if (hiddenColumn.isHidden && hiddenColumn.isHidden === true) {
            allDataTableColumnsOptionsRow.splice(hiddenColumnIndex, 1);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    setDataTableColumnsOptionsRow(props.dataTableColumns);
    setDataTableColumnsBackup([...props.dataTableColumns]);
    updateErrorMessage(props.dataTableColumns);
  }, [allDataTableColumnsOptionsRow]);

  useEffect(() => {
    toggleDropdownMenu(false);
  }, [props.allDataTableColumns]);

  const hiddenColumnByDefault = (columnName) => {
    if (props.dataTableColumns.some((item) => item.label === columnName) === true) {
      return false;
    } else return true;
  };

  const checkedStatus = (columnName) => {
    return dataTableColumnsOptionsRow.some((item) => item.label === columnName);
  };

  const showHideDataTableColumns = (columnName, columnField, index) => {
    if (dataTableColumnsOptionsRow.some((item) => item.label === columnName)) {
      var newIndex = -1;
      dataTableColumnsOptionsRow.map((column, columnIndex) => {
        if (column.label === columnName) {
          newIndex = columnIndex;
        }
      });

      if (newIndex >= 0) dataTableColumnsOptionsRow.splice(newIndex, 1);
    } else {
      var newColumn = {
        label: columnName,
        field: columnField,
        type: "text",
        sort: "asc",
        minimal: "lg",
        width: "90",
      };

      if (!props.allDataTableColumns.some((item) => item.label === columnName)) {
        newColumn.sort = "disabled";
      }

      dataTableColumnsOptionsRow.push(newColumn);

      let dataTableColumnsTemp = [];
      props.allDataTableColumns.map((column) => {
        if (dataTableColumnsOptionsRow.some((item) => item.label === column.label)) {
          dataTableColumnsTemp.push(column);
        }
      });

      setDataTableColumnsOptionsRow(dataTableColumnsTemp);
    }

    updateErrorMessage(dataTableColumnsOptionsRow);
  };

  return (
    <div className={`${styles.exportAndColumnsRow} ${props.tableOptionsWrapperClass ? props.tableOptionsWrapperClass : ""}`}>
      <DropdownButton
        title={<EqualizerLineIcon size={14} className={`iconGray ${styles.adjustIcon}`} />}
        show={dropdownOpen}
        className={styles.columnsDropdown}
        id="customColumnsButton"
        key={"tableOptionsRowDropdown"}
        onClick={() => {
          if (!dropdownOpen) toggleDropdownMenu(true);
        }}
        drop={props.drop ? props.drop : "auto"}
      >
        <div>
          <div className={styles.searchWrapper}>
            <SearchLineIcon size={14} className={`iconGray ${styles.searchICon}`} />
            <input
              className={styles.searchBar}
              type="text"
              value={searchInputValue}
              placeholder="Search..."
              tabIndex="-1"
              onChange={(e) => setSearchInputValue(e.target.value)}
              onKeyUp={(e) => searchAllColumns(e.target.value)}
            />
          </div>
          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
          <div className={styles.columnsRow}>
            {allDataTableColumnsOptionsRow.map((column, index) => {
              return (
                <OverlayTrigger
                  key={column.label}
                  placement={"left"}
                  overlay={
                    <Popover id={`tooltip`}>
                      <Popover.Body>{column.label}</Popover.Body>
                    </Popover>
                  }
                >
                  <div>
                    {hiddenColumnByDefault(column.label) ? (
                      <Checkbox
                        onChange={() => showHideDataTableColumns(column.label, column.field, null)}
                        checked={() => checkedStatus(column.label)}
                        key={column.label}
                        label={column.label}
                        checkBoxClass="mB10"
                      />
                    ) : column.label !== "" ? (
                      <Checkbox
                        onChange={() => showHideDataTableColumns(column.label, column.field, index)}
                        checked={() => checkedStatus(column.label)}
                        key={column.label}
                        label={column.label}
                        checkBoxClass="mB10"
                      />
                    ) : null}
                  </div>
                </OverlayTrigger>
              );
            })}
          </div>
          <hr />

          <div className={styles.dropdownButtonsRow}>
            <div className={styles.buttonsWrapper}>
              {" "}
              <Button
                id="cancelButton"
                variant="secondary"
                classes={styles.secondary_button_s}
                onClick={() => {
                  setDataTableColumnsOptionsRow(dataTableColumnsBackup);
                  props.setDataTableColumns(dataTableColumnsBackup);
                  toggleDropdownMenu(false);
                  setErrorMessage("");
                }}
              >
                Cancel
              </Button>
              <Button
                id="applyButton"
                variant="primary"
                disabled={applyButtonDisabled}
                classes={styles.primary_button_s}
                onClick={() => applyChanges()}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </DropdownButton>

      {props.exportTable ? (
        <Button
          id="exportCSV"
          variant="white_bordered"
          onClick={() => {
            props.exportTable();
            props.setExportButtonDisabled(true);
          }}
          disabled={props.exportButtonDisabled}
          classes={styles.csvButton}
        >
          <DownloadCloud2LineIcon size={16} className="iconGray" />
          Export csv.
        </Button>
      ) : null}

      {props.importData ? (
        <>
          <Button id="importCSV" variant="white_bordered" onClick={() => props.importData()} classes={styles.csvButton}>
            <UploadCloud2LineIcon size={16} className="iconGray" />
            Import csv.
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default TableOptionsRow;
