import React from "react";

//Styles
import styles from "./MessagePreview.module.scss";

//Images & Icons
import EmptyTemplateImage from "../../../assets/Images/EmptyTemplateImage/EmptyTemplateImage";

//Components
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const MessagePreview = (props) => {
  let image = <EmptyTemplateImage />;

  return (
    <div className={styles.componentBody}>
      {props?.emailTemplate?.html != null ? (
        <div
          dangerouslySetInnerHTML={{ __html: props?.emailTemplate?.html }}
        ></div>
      ) : (
        <EmptyState
          componentClass="positionCenter"
          image={image}
          title="Your Template request is pending"
          description="Your message will be displayed as soon as the writer submits his work."
        />
      )}
    </div>
  );
};

export default MessagePreview;
