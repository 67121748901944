import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./NoUsersColOutbOss.module.scss";

//Helpers
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";
import api, { API, formUrl } from "../../../helper/api/api";

const NoUsersColOutbOss = (props) => {
  //if we decide to put back the progress bar
  // let usersData = [];
  // usersData.push({
  //     color: '#1ac472',
  //     percentage: calculatePercentageFromNumber(props.availableUsers, props.totalUsers)
  // })
  // usersData.push({
  //     color: '#fe7904',
  //     percentage: calculatePercentageFromNumber(props.invitedUsers, props.totalUsers)
  // })

  let totalUsersInt = parseInt(props.totalUsers);
  const logUserLogin = () => {
    api(
      formUrl(API.OUTBOSS.users.logAdminLogin, {
        organizationId: props.organizationId,
      })
    )
      .then((response) => {})
      .catch((error) => {});
  };

  return (
    <div className={styles.creditBalanceWrapper}>
      <OverlayTrigger
        key={"audience-size"}
        placement={"bottom"}
        overlay={
          <Popover id={`audience-size-tooltip`}>
            <Popover.Body className={"campaignAudienceTooltip"}>
              {props.totalUsers !== "0" ? (
                <span>Available Invites / Invited Users / Total Invites</span>
              ) : (
                <span>Invited Users / Total Invites</span>
              )}
            </Popover.Body>
          </Popover>
        }
      >
        <div className={`${styles.progressBarDetails} max-x-content`}>
          <p className={styles.labelsWrapper}>
            {totalUsersInt !== 0 ? (
              <>
                <span className={styles.available}>{props.availableUsers} </span>
                &nbsp;/&nbsp;
                <span className={styles.used}> {props.invitedUsers} </span>
                &nbsp;/&nbsp;
                <span className={styles.total}>{props.totalUsers}</span>
              </>
            ) : (
              <>
                &nbsp;&nbsp;&nbsp;
                <span className={styles.used}> {props.invitedUsers} </span>
                &nbsp;/&nbsp;
                <span className={styles.infinity}>&#8734;</span>
              </>
            )}
          </p>
          {/* if we decide to put back the progress bar*/}
          {/* <ProgressLine hideLabelStyle={true} data={usersData} /> */}
        </div>
      </OverlayTrigger>
      {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll) ? (
        <div onClick={logUserLogin}>
          <a
            className={styles.labelTableLink}
            rel="noopener noreferrer"
            href={"/" + props.organizationId + "/organization-settings/team-management"}
            target="_blank"
          >
            View List
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default NoUsersColOutbOss;
