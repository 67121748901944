import React from "react";
import { Container, Row } from "react-bootstrap";
import { Formik, FormikProps } from "formik";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";

//Redux

//Styles
import styles from "./Audiences.module.scss";

//Images & Icons
import IconAudienceExplorer from "../../../../assets/Icons/IconAudienceExplorer/IconIncludeContact";
import NewAudienceIcon from "../../../../assets/Icons/NewAudienceIcon/NewAudienceIcon";
import CampaignCreateNewAudience from "../../../../assets/Images/CampaignCreateNewAudince/CampaignCreateNewAudience";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import Button from "../../../../components/UI/Button/Button";

interface Params {
  campaignId: string;
  organizationId: string;
}

interface Props extends RouteComponentProps {
  handleClose: () => void;
  handleAudienceUploadFormShow: () => void;
  showModal: boolean;
}

const CreateNewAudienceForm: React.FC<Props> = ({ handleClose, handleAudienceUploadFormShow, showModal }) => {
  const { campaignId, organizationId } = useParams<Params>();
  const history = useHistory();

  const handleAudienceBuilder = () => {
    history.push("/" + organizationId + "/search?camId=" + campaignId);
  };

  const handleAudience = () => {
    handleAudienceUploadFormShow();
    handleClose();
  };

  return (
    <Modal
      title={"New List"}
      removeFooter={true}
      handleClose={handleClose}
      show={showModal}
      btnType="submit"
      customBodyClass={styles.bodyClass}
    >
      <Container>
        <Row className={styles.audienceEditForm}>
          <div className={styles.audienceNewList}>
            <CampaignCreateNewAudience />
            <p>New List from:</p>
            <br />
            <div className="flex center_flex">
              <Button
                id="createNewAudienceFormUploadButton"
                onClick={handleAudience}
                variant="secondary"
                classes={styles.secondaryButton}
                link={"audiences"}
              >
                <NewAudienceIcon /> Upload list
              </Button>
              <Button
                id="createNewAudienceFormAudienceButton"
                onClick={handleAudienceBuilder}
                variant="primary"
                classes={styles.primaryButton}
                link={"search"}
              >
                <IconAudienceExplorer imgStyle="mR5" /> Search contacts
              </Button>
            </div>
          </div>
        </Row>
      </Container>
    </Modal>
  );
};

export default CreateNewAudienceForm;
