import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  documents: [],
  listOfTags: [],
  images: [],
  isLoading: false,
  isLoaded: false,
  error: "",
  searchValue: "",
};

//#region FETCH
const fetchListOfTagsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListTagsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listOfTags };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listOfTags = list;
  return newState;
};

const fetchListOfTagsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};
//#endregion

//#region ADD
const addSalesDocumentImageSuccess = (state, action) => {
  const newState = {
    ...state,
    images: [...state.images, action.result],
  };

  return newState;
};

const addSalesDocumentImageFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};

const addSalesDocumentsVideoSuccess = (state, action) => {
  const newState = { ...state };
  const video = action.result;

  newState.documents.push(video);

  return newState;
};

const addDealNavigatorVideoFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};
//#endregion

//#region CHANGE
const changeSalesDocumentsDocumentSuccess = (state, action) => {
  const documentNextState = action.result;

  const index = state.documents.findIndex((i) => i.id === documentNextState.id);

  const newDocuments = [...state.documents];
  newDocuments[index] = {
    ...newDocuments[index],
    ...documentNextState,
  };

  return { ...state, documents: newDocuments };
};

const changeSalesDocumentsVideoSuccess = (state, action) => {
  const newState = { ...state };
  const documents = [...newState.documents];
  const index = newState.documents.findIndex((v) => v.id === action.result.id);
  const video = { ...documents[index] };

  video.id = action.result.id;
  video.videoUrl = action.result.videoUrl;
  video.comment = action.result.comment;

  documents[index] = video;
  newState.documents = documents;
  return newState;
};

const changeSalesDocumentsImageSuccess = (state, action) => {
  const newState = { ...state };
  const images = [...newState.images];
  const index = newState.images.findIndex((v) => v.id === action.result.id);
  const image = { ...images[index] };

  image.id = action.result.id;
  image.url = action.result.url;
  image.thumbnailUrl = action.result.thumbnailUrl;
  image.comment = action.result.comment;

  images[index] = image;
  newState.images = images;
  return newState;
};

const changeSalesDocumentsDocumentFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};

const changeSalesDocumentsVideoFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};

const changeSalesDocumentsImageFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};
//#endregion

//#region DELETE
const removeSalesDocumentsRequested = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const removeSalesDocumentsSuccess = (state, action) => {
  const newState = { ...state };
  const fileId = action.result;
  const documents = [...newState.documents];
  const index = documents.findIndex((f) => f.id === fileId);

  documents.splice(index, 1);
  newState.documents = documents;
  return newState;
};

const removeSalesDocumentsFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};

const removeSalesDocumentsImageRequested = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const removeSalesDocumentsImageSuccess = (state, action) => {
  const newState = { ...state };
  const fileId = action.result;
  const images = [...newState.images];
  const index = images.findIndex((f) => f.id === fileId);

  images.splice(index, 1);
  newState.images = images;
  return newState;
};

const removeSalesDocumentsImageFailed = (state, action) => {
  return {
    ...state,
    isLoaded: true,
    error: action.result,
  };
};
//#endregion

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region FETCH
    case actionTypes.FETCH_SALES_DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };

    case actionTypes.FETCH_SALES_DOCUMENTS_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        ...action.result,
      };
    case actionTypes.FETCH_SALES_DOCUMENTS_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };

    case actionTypes.FETCH_SALES_VIDEOS_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };

    case actionTypes.FETCH_SALES_VIDEOS_DOCUMENT_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        ...action.result,
      };

    case actionTypes.FETCH_SALES_VIDEOS_DOCUMENT_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };
    case actionTypes.FETCH_SALES_DOCUMENTS_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };

    case actionTypes.FETCH_SALES_DOCUMENTS_IMAGES_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        ...action.result,
      };

    case actionTypes.FETCH_SALES_DOCUMENTS_IMAGES_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        ...state,
        error: action.result,
      };
    case actionTypes.FETCH_LIST_OF_TAGS_REQUEST:
      return fetchListOfTagsRequest(state, action);

    case actionTypes.FETCH_LIST_OF_TAGS_SUCCEEDED:
      return fetchListTagsSuccess(state, action);

    case actionTypes.FETCH_LIST_OF_TAGS_FAILED:
      return fetchListOfTagsFailed(state, action);

    case actionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.result,
      };
    //#endregion

    //#region ADD
    case actionTypes.ADD_SALES_DOCUMENTS_VIDEO_SUCCEEDED:
      return addSalesDocumentsVideoSuccess(state, action);

    case actionTypes.ADD_SALES_DOCUMENTS_VIDEO_FAILED:
      return addDealNavigatorVideoFailed(state, action);

    case actionTypes.ADD_SALES_DOCUMENTS_IMAGE_SUCCEEDED:
      return addSalesDocumentImageSuccess(state, action);

    case actionTypes.ADD_SALES_DOCUMENTS_IMAGE_FAILED:
      return addSalesDocumentImageFailed(state, action);
    //#endregion

    //#region CHANGE
    case actionTypes.CHANGE_SALES_DOCUMENTS_DOCUMENT_SUCCEEDED:
      return changeSalesDocumentsDocumentSuccess(state, action);

    case actionTypes.CHANGE_SALES_DOCUMENTS_VIDEO_SUCCEEDED:
      return changeSalesDocumentsVideoSuccess(state, action);

    case actionTypes.CHANGE_SALES_DOCUMENTS_IMAGE_SUCCEEDED:
      return changeSalesDocumentsImageSuccess(state, action);

    case actionTypes.CHANGE_SALES_DOCUMENTS_DOCUMENT_FAILED:
      return changeSalesDocumentsDocumentFailed(state, action);

    case actionTypes.CHANGE_SALES_DOCUMENTS_VIDEO_FAILED:
      return changeSalesDocumentsVideoFailed(state, action);

    case actionTypes.CHANGE_SALES_DOCUMENTS_IMAGE_FAILED:
      return changeSalesDocumentsImageFailed(state, action);
    //#endregion

    //#region DELETE
    case actionTypes.REMOVE_SALES_DOCUMENTS_REQUEST:
      return removeSalesDocumentsRequested(state, action);

    case actionTypes.REMOVE_SALES_DOCUMENTS_SUCCEEDED:
      return removeSalesDocumentsSuccess(state, action);

    case actionTypes.REMOVE_SALES_DOCUMENTS_FAILED:
      return removeSalesDocumentsFailed(state, action);

    case actionTypes.REMOVE_SALES_DOCUMENTS_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.REMOVE_SALES_DOCUMENTS_DOCUMENT_SUCCESS:
      return {
        isLoading: false,
        isLoaded: true,
        error: "",
        ...state,
        ...action.result,
      };
    case actionTypes.REMOVE_SALES_DOCUMENTS_DOCUMENT_FAILED:
      return {
        isLoading: false,
        isLoaded: true,
        ...state,
        error: action.result,
      };

    case actionTypes.REMOVE_SALES_DOCUMENTS_IMAGE_REQUEST:
      return removeSalesDocumentsImageRequested(state, action);

    case actionTypes.REMOVE_SALES_DOCUMENTS_IMAGE_SUCCESS:
      return removeSalesDocumentsImageSuccess(state, action);

    case actionTypes.REMOVE_SALES_DOCUMENTS_IMAGE_FAILED:
      return removeSalesDocumentsImageFailed(state, action);
    //#endregion
    default:
      break;
  }

  return state;
};

export default reducer;
