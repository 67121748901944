import React from "react";

//Styles
import styles from "./PreparingEnvironment.module.scss";

//Images & Icons
import Preparing from "../../../../../assets/Images/PreparingImage/Preparing";

//Components
import EmptyState from "../../../../../components/UI/EmptyState/EmptyState";

const PreparingEnvironment = (props) => {
  let image = null;
  const { topLabelText } = props;
  image = <Preparing className={styles.preperingImg} topLabelText={topLabelText} />;

  return (
    <div className={styles.pageWrapper}>
      <EmptyState title={"Preparing Your Environment"} image={image} />
    </div>
  );
};

export default PreparingEnvironment;
