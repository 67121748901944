import React, { FC } from "react";
import _ from "lodash";

//Helpers
import { daysOfWeek } from "../../../../../helper/scheduleConstants";
import { removeSecondsFromTime } from "../../../../../helper/dateHelper";

//Components
import Checkbox from "../../../../../components/UI/Checkbox/Checkbox";
import styles from "./SendDays.module.scss";
import { SendingScheduleDetailsModel } from "@models/schedule/SendingScheduleDetailsModel";
import { SendingScheduleDay } from "@models/schedule/SendingScheduleDay";

interface SendDaysProps {
  schedule: SendingScheduleDetailsModel;
}

const SendDays: FC<SendDaysProps> = ({ schedule }) => {
  const list = daysOfWeek.map((day, index) => {
    const currentDay = schedule[day as keyof SendingScheduleDetailsModel] as SendingScheduleDay;
    const dayShort = day.substring(0, 3);
    const from = removeSecondsFromTime(currentDay.from);
    const to = removeSecondsFromTime(currentDay.to);
    const isEmpty = !from && !to;
    return isEmpty ? null : (
      <div key={index} className={styles.dayWrapper}>
        {!isEmpty && (
          <Checkbox
            name={dayShort}
            label={_.upperFirst(dayShort)}
            disabled={true}
            checked={currentDay.enabled}
            labelClass={`${styles.hideCheckbox}`}
          />
        )}

        {from && <input disabled={true} value={from} />}
        {to && <input disabled={true} value={to} />}
      </div>
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.fromToWrapper}>
        <p className={styles.fromLabel}>From:</p>
        <p className={styles.toLabel}>To:</p>
      </div>
      {list}
    </div>
  );
};

export default SendDays;
