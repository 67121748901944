import React, { FC, ReactNode } from "react";

// Styles
import styles from "./HeaderSection.module.scss";

interface HeaderSectionProps {
  className?: string;
  classLeftWrapper?: string;
  classRightWrapper?: string;
  leftContent: ReactNode;
  rightContent: ReactNode;
}

const HeaderSection: FC<HeaderSectionProps> = ({ className, classLeftWrapper, classRightWrapper, leftContent, rightContent }) => {
  return (
    <div className={`${styles.sectionWrapper} ${className}`}>
      <div className={`${styles.contentLeft} ${classLeftWrapper}`}>{leftContent}</div>
      <div className={`${styles.contentRight} ${classRightWrapper}`}>{rightContent}</div>
    </div>
  );
};

export default HeaderSection;
