import React from "react";

//Style
import "./SkeletonPerformanceCard.scss";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonPerformanceCard = (props) => {
  return (
    <div className="skeleton-wrapper skeletonCards">
      <SkeletonElement type="text-8 w-6" />
      <SkeletonElement type="text-26" />
      <SkeletonElement type="text-15" />
      <SkeletonElement type="text-8" />
      <Shimmer />
    </div>
  );
};

export default SkeletonPerformanceCard;
