export const variablesEnum = {
  OrganizationName: "ORGANIZATION_NAME",
  LinkToOrganizationSettings: "LINK_TO_ORGANIZATION_SETTINGS",
  LinkToMyOrganizations: "LINK_TO_MY_ORGANIZATIONS",
  EmailAccount: "EMAIL_ACCOUNT",
  LinkToAccountsList: "LINK_TO_ACCOUNTS_LIST",
  LinkToAccountSettings: "LINK_TO_ACCOUNT_SETTINGS",
  DomainName: "DOMAIN_NAME",
  LinkToDomains: "LINK_TO_DOMAINS",
  DomainsToVerify: "DOMAINS_TO_VERIFY",
  UserEmail: "USER_EMAIL",
  LinkToCampaignConfigure: "LINK_TO_CAMPAIGN_CONFIGURE",
  CampaignName: "CAMPAIGN_NAME",
  LinkToCampaignPerformance: "LINK_TO_CAMPAIGN_PERFORMANCE",
  LinkToCampaignStats: "LINK_TO_CAMPAIGN_STATS",
  NumberOfProspects: "NUMBER_OF_PROSPECTS",
  LinkToCampaignProspects: "LINK_TO_CAMPAIGN_PROSPECTS",
  AudienceName: "AUDIENCE_NAME",
  LinkToAudiences: "LINK_TO_AUDIENCES",
  DownloadAudienceContactsLink: "DOWNLOAD_AUDIENCE_CONTACTS_LINK",
  AddedAudienceContacts: "ADDED_AUDIENCE_CONTACTS",
  NumberOfResponses: "NUMBER_OF_RESPONSES",
  NumberOfUnresolvedMessages: "NUMBER_OF_UNRESOLVED_MESSAGES",
  FileName: "FILE_NAME",
  LinkToDealNavigator: "LINK_TO_DEAL_NAVIGATOR",
  MediaName: "MEDIA_NAME",
  LinkToDealNavigatorResources: "LINK_TO_DEAL_NAVIGATOR_RESOURCES",
  AllProspectsAvailable: "ALL_PROSPECTS_AVAILABLE",
  NumberDaysLeft: "NUMBER_DAYS_LEFT",
  PricingPlanLink: "PRICING_PLAN_LINK",
  OrganizationContractNumberDaysLeft: "ORGANIZATION_CONTRACT_NUMBER_DAYS_LEFT",
  NumberOfContacts: "NUMBER_OF_CONTACTS",
  NumberOfContactsNotImported: "NUMBER_OF_CONTACTS_NOT_IMPORTED",
  PurchaseTemplateCreatedDate: "PURCHASE_TEMPLATE_CREATED_DATE",
  LinkToPurchaseTemplateDetails: "LINK_TO_PURCHASE_TEMPLATE_DETAILS",
  ErrorCode: "ERROR_CODE",
  ImportTotal: "PROSPECT_IMPORT_TOTAL",
  ProspectsImported: "PROSPECTS_IMPORTED",
  ProspectsNotImported: "PROSPECTS_NOT_IMPORTED",
  LinkToAudienceImport: "LINK_TO_AUDIENCE_IMPORT",
  FirstName: "FIRST_NAME",
  LastName: "LAST_NAME",
  IntegrationName: "INTEGRATION_NAME",
  LinkToIntegrations: "LINK_TO_INTEGRATIONS",
  HolidayPauseDate: "HOLIDAY_PAUSE_DATE",
  LinkToSendingSchedule: "LINK_TO_SENDING_SCHEDULE",
  CampaignRestartDate: "CAMPAIGN_RESTART_DATE",
  LinkToCampaignsCalendar: "LINK_TO_CAMPAIGNS_CALENDAR",
  LinkToInbox: "LINK_TO_INBOX",
  ExportProspectFileCreatedDate: "EXPORT_PROSPECT_FILE_CREATED_DATE",
  SuccessfulDeliverabilityTestSent: "SUCCESSFUL_DELIVERABILITY_TEST_SENT",
  UnsuccessfulDeliverabilityTestSent: "UNSUCCESSFUL_DELIVERABILITY_TEST_SENT",
  LinkToDeliverabilityTestDetails: "LINK_TO_DELIVERABILITY_TEST_DETAILS",
};
