import { Switch } from "@mui/material";
import { styled } from "@mui/system";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,
  display: "flex",
  ".MuiSwitch-switchBase": {
    padding: 3,
    color: `var(--primary-color)`,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: `var(--primary-color)`,
        borderColor: `var(--primary-color)`,
      },
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: `var(--secondary-color-200) !important`,
        borderColor: `var(--secondary-color-200) !important`,
        opacity: 0.5,
      },
    },
  },
  ".MuiSwitch-thumb": {
    width: 10,
    height: 10,
    boxShadow: "none",
  },
  ".MuiSwitch-track": {
    border: `1px solid var(--primary-color)`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
}));

export default AntSwitch;
