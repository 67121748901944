import React, { useState } from "react";
import { columns } from "./columns";
import { connect } from "react-redux";

//Style
import styles from "./NotificationTemplates.module.scss";

// Icons
import ActionEdit from "../../../components/UI/ActionWrapper/Actions/ActionEdit";
import ActionSendTest from "../../../components/UI/ActionWrapper/Actions/ActionSendTest";

//Components
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Table from "../../../components/DataTable/Table";
import AntSwitch from "../../../components/UI/AntSwitch/Switch";
import ActionWrapper from "../../../components/UI/ActionWrapper/ActionWrapper";
import SendTestModal from "./Modal/SendTestModal";

const NotificationTemplates = (props) => {
  const [showActivityDetailsModal, setShowActivityDetailsModal] = useState(false);
  const handleActivityDetailsModalClose = () => setShowActivityDetailsModal(false);
  const handleUpdateDomainModalShow = () => setShowActivityDetailsModal(true);

  const param = {
    Start: 0,
    Length: 10,
    Search: "",
    DTSort: {
      Column: "",
      Direction: "",
    },
    Filter: [
      {
        Type: "",
        Value: [{}],
      },
    ],
  };
  const tableRows = [
    {
      notificationName: "Welcome to Outbase",
      category: "User Registration",
      sentFrom: "example@email.com",
      sentTo: "example01@email.com",
      cc: "",
      bcc: "example02@email.com",
    },
  ];

  // useEffect(() => {
  tableRows.map((r) => {
    let actions = (
      <ActionWrapper>
        <div className={styles.actionIcons}>
          <ActionEdit />
          <ActionSendTest onClick={handleUpdateDomainModalShow} />
        </div>
      </ActionWrapper>
    );
    r.actions = actions;
    let active = (
      <div className={styles.switchWrapper}>
        <AntSwitch />
      </div>
    );
    r.active = active;
    return r;
  });
  // }
  let table = null;
  table = (
    <div className={styles.testTable}>
      <Table
        tableName={"Notification Templates"}
        columns={columns}
        rows={tableRows}
        pageCount={props.pageCount}
        totalCount={props.totalCount}
        parentCallback={props.changePage}
        parentSort={props.sortData}
        param={param}
        changeNumRecords={props.numRecords}
        parentSearchHandler={props.searchRecords}
        scrollY={true}
        variant="small"
        tableClassName={styles.templatesTable}
      />
    </div>
  );
  return (
    <div className={styles.contentWrapper}>
      <HeaderTitle title="Notification Templates" />
      <TableFiltersRow
        // selects={selectRowOptions}
        placeholder="templates"
        changeFilter={(text) => props.searchRecords(text)}
      />
      {table}
      <SendTestModal handleClose={handleActivityDetailsModalClose} showModal={showActivityDetailsModal} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTemplates);
