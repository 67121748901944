import React from "react";

const IconFileWord = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667Z"
      fill="#1A75CE"
    />
    <path
      d="M23.9666 7.99999C23.9333 7.86666 23.9 7.76666 23.8333 7.66666L17.3333 0.399994V7.99999H23.9666Z"
      fill="#1A75CE"
    />
    <path
      d="M2 13.6667H22V30H2V13.6667ZM2.13333 2.13333H3.23333C4.2 2.13333 4.8 2.99999 4.8 4.49999C4.8 5.99999 4.2 6.89999 3.23333 6.89999H2.13333V2.13333V2.13333ZM3.23333 5.93332C3.66667 5.93332 3.93333 5.43333 3.93333 4.49999C3.93333 3.56666 3.66667 3.09999 3.23333 3.09999H3V5.93332H3.23333V5.93332ZM6.66667 2.03333C7.66667 2.03333 8.23333 3.06666 8.23333 4.46666C8.23333 5.86666 7.66667 6.96666 6.66667 6.96666C5.66667 6.96666 5.1 5.89999 5.1 4.46666C5.1 3.09999 5.66667 2.03333 6.66667 2.03333V2.03333ZM6.66667 5.93332C7.13333 5.93332 7.33333 5.33333 7.33333 4.49999C7.33333 3.66666 7.13333 3.09999 6.66667 3.09999C6.2 3.09999 6 3.66666 6 4.49999C6 5.33333 6.2 5.93332 6.66667 5.93332ZM10.2333 2.03333C10.5667 2.03333 10.9333 2.16666 11.2 2.43333L10.8333 3.36666C10.7333 3.19999 10.5 3.06666 10.2667 3.06666C9.7 3.06666 9.4 3.69999 9.4 4.46666C9.4 5.23333 9.7 5.93332 10.2333 5.93332C10.5 5.93332 10.6667 5.76666 10.8333 5.59999L11.2 6.53333C10.9667 6.76666 10.6667 6.96666 10.1333 6.96666C9.06667 6.96666 8.46667 5.93333 8.46667 4.49999C8.5 3.09999 9.13333 2.03333 10.2333 2.03333V2.03333ZM12.2 4.53333L11.2667 2.13333H12.2333L12.5333 3.09999C12.6333 3.39999 12.6667 3.69999 12.6667 3.69999C12.6667 3.69999 12.7333 3.39999 12.8333 3.09999L13.1333 2.13333H14.1L13.1667 4.53333L14.0667 6.89999H13.1333L12.8333 5.89999C12.7333 5.59999 12.6667 5.33333 12.6667 5.33333C12.6667 5.33333 12.6 5.59999 12.5333 5.89999L12.2333 6.89999H11.3L12.2 4.53333V4.53333Z"
      fill="white"
    />
    <path
      d="M6.49998 22.3666L4.89998 17.4333C4.83332 17.2333 4.79998 17.1666 4.76665 17.1666C4.73332 17.1333 4.69998 17.1333 4.66665 17.1L4.09998 16.9333L4.06665 16.2333H7.06665L7.13332 16.9333L6.56665 17.1V17.1666C6.56665 17.2333 6.59998 17.3666 6.66665 17.5L7.26665 19.4L8.33332 16.2333L9.13332 16.2L10.1 19.3666L10.7666 17.4666C10.8 17.3333 10.8666 17.2 10.8666 17.1333V17.1L10.3667 16.9666L10.3333 16.2333H12.6666L12.7333 16.9333L12.1 17.1333C12.0666 17.1333 12.0333 17.1666 12 17.1666C12 17.2 11.9333 17.2333 11.9 17.4L10.1333 22.3L9.29998 22.3666L8.39998 19.3333L7.33332 22.3L6.49998 22.3666V22.3666Z"
      fill="#1A75CE"
    />
    <path
      d="M14.1333 16.2H20V17.1333H14.1333V16.2ZM14.1333 18.6666H20V19.6H14.1333V18.6666ZM14.1333 21.2333H20V22.1666H14.1333V21.2333ZM4.33331 23.8333H20V24.7666H4.33331V23.8333ZM4.33331 26.3666H20V27.3H4.33331V26.3666Z"
      fill="#1A75CE"
      stroke="#1A75CE"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconFileWord;
