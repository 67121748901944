import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

// Styles
import styles from "./MessageCard.module.scss";

// Icons
import WarningIcon from "@icons/Warning/WarningIcon";
import IconArtificialIntelligence from "@icons/IconArtificialIntelligence/IconArtificialIntelligence";
import IconManualChange from "@icons/IconManualChange/IconManualChange";

// Components
import ResponseTypePill from "../ResponseType/ResponseTypePill";

const MessageCard = ({ index, classes, r, activeTab, handleMessageClick }) => (
  <div key={index} className={classes} id={`message-${index}`}>
    <div className={styles.messagesContainer} onClick={() => handleMessageClick(r)}>
      <div className={`${styles.messageCard} ${styles.unresolved}`}>
        <div className={`${styles.messageHead} flex`}>
          <div className={styles.infoWrapper}>
            <p className={styles.sender}>{r.emailFrom}</p>
          </div>
          <div className={styles.dateWrapper}>
            <ResponseTypePill type={r.responseTypeName} />
            <span className={styles.date}>{r.date.split(" ")[0]}</span>
          </div>
        </div>
        <div className={styles.messageBody}>
          <div className={`${styles.titleWrapper} flex`}>
            <div className={styles.title}>
              <h2>{r.subject.substring(0, 35)}</h2>
              {activeTab === 0 ? (
                <div className={`${styles.circle} ${styles.unresolved}`}></div>
              ) : (
                <div className="flex align-center">
                  {r.isResolved && r.isAutoResolved && !r.isManuallyResolved ? (
                    <>
                      {r.manualReview ? (
                        <OverlayTrigger
                          key={"warning_" + index}
                          placement={"bottom"}
                          overlay={
                            <Popover id={`warning`}>
                              <Popover.Body>Response categorized by AI but needs review</Popover.Body>
                            </Popover>
                          }
                        >
                          <div className={styles.iconWrapper}>
                            <WarningIcon />
                          </div>
                        </OverlayTrigger>
                      ) : null}
                      <OverlayTrigger
                        key={"ai_" + index}
                        placement={"bottom"}
                        overlay={
                          <Popover id={`aiReview`}>
                            <Popover.Body>Response categorized by AI</Popover.Body>
                          </Popover>
                        }
                      >
                        <div className={styles.iconWrapper}>
                          <IconArtificialIntelligence imgStyle="mR5" />
                        </div>
                      </OverlayTrigger>
                    </>
                  ) : r.isResolved ? (
                    <OverlayTrigger
                      key={"manual_" + index}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`manual`}>
                          <Popover.Body>Response categorized manually</Popover.Body>
                        </Popover>
                      }
                    >
                      <div className={styles.iconWrapper}>
                        <IconManualChange imgStyle="mR5" />
                      </div>
                    </OverlayTrigger>
                  ) : null}
                  <div className={`${styles.circle} ${styles.resolved}`}></div>
                </div>
              )}
            </div>
          </div>
          <p className={styles.message}>{r?.snippet?.substring(0, 35)}</p>
        </div>
      </div>
    </div>
  </div>
);

export default MessageCard;
