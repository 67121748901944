export const organizationTypeEnum = {
  Client: 1,
  Incubator: 2,
  Internal: 3,
  Dev: 4,
};

export const organizationTypeOptions = [
  { value: 1, label: "Client" },
  { value: 2, label: "Incubator" },
  { value: 3, label: "Internal" },
  { value: 4, label: "Dev" },
];

export const organizationTypeOptionsString = [
  { value: "1", label: "Client" },
  { value: "2", label: "Incubator" },
  { value: "3", label: "Internal" },
  { value: "4", label: "Dev" },
];
