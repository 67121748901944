import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

//Redux
import { removeSalesDocumentsImage } from "../../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";
import { createImageUrl } from "../../../../helper/fileHelper";

//Style
import styles from "./Images.module.scss";

//Images & Icons
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";

//Components
import ChangeImageForm from "../../Modals/ChangeImageForm";
import Permission from "../../../../components/auth/Permission";

const Image = (image) => {
  const [showChangeImageModal, setShowChangeImageModal] = useState(false);
  const handleChangeImageClose = () => setShowChangeImageModal(false);
  const handleChangeImageShow = () => setShowChangeImageModal(true);

  const deleteImage = (id) => {
    image.removeSalesDocumentsImage(id);
  };

  const [imgUrl, setImgUrl] = useState();

  useEffect(() => {
    setImgUrl(createImageUrl(image));
  }, [image]);

  return (
    <>
      <div className={styles.videoContainer}>
        <img src={imgUrl} alt={image.comment} />
        <div className={styles.buttonsWrapper}>
          <Permission has={PERMISSIONS.salesDocuments.documents.edit}>
            <Button id="imageEdit" className={styles.editButton} onClick={handleChangeImageShow}>
              <Edit2LineIcon size={16} className="iconGray" />
            </Button>
          </Permission>
          <Permission has={PERMISSIONS.salesDocuments.documents.delete}>
            <Button id="imageDelete" className={styles.deleteButton} onClick={() => deleteImage(image.id)}>
              <DeleteBin6LineIcon className="iconGray" size={16} />
            </Button>
          </Permission>
        </div>
      </div>
      <span>{image.comment}</span>
      <Permission has={PERMISSIONS.salesDocuments.documents.edit}>
        <ChangeImageForm
          handleClose={handleChangeImageClose}
          showModal={showChangeImageModal}
          id={image.id}
          description={image.comment}
          name={image.name}
        />
      </Permission>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSalesDocumentsImage: (id) => dispatch(removeSalesDocumentsImage(id)),
  };
};

export default connect(null, mapDispatchToProps)(Image);
