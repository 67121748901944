import React from "react";

//Components
import DownloadFileModal from "../../components/UI/DownloadFileModal/DownloadFileModal";

const AudienceContactsExportForm = (props) => {
  return (
    <DownloadFileModal
      title="Export Contacts"
      handleClose={props.handleClose}
      show={props.showModal}
      fileData={props.audienceContactsFileData}
      fileName={props.audience.name + "_contacts.csv"}
    />
  );
};

export default AudienceContactsExportForm;
