import styles from "../Filters.module.scss";
import { useGetJobTitleSenioritiesQuery } from "@api/audienceExplorerApi";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import FormikSelect from "@ui/Formik/FormikSelect";
import { useFormikContext } from "formik";

const FilterJobTitleSeniorities = () => {
  const {
    values: {
      contact: { seniorities },
    },
  } = useFormikContext<SearchModel>();
  const { data: options } = useGetJobTitleSenioritiesQuery();

  return (
    <FormikSelect
      menuId="recommendedSeniorities"
      id="contactsJobTitleSeniorities"
      name="contact.seniorities"
      value={seniorities}
      options={options}
      displayName="Select seniorities"
      placeholder="e.g. Manager, lead, head"
      dropdownButtonClass={styles.selectDropdown}
      isMulti
      isSearchable
      enableReinitialize
      showValuesOutside
    />
  );
};

export default FilterJobTitleSeniorities;
