import _ from "lodash";
import { useStore } from "react-redux";

export const useOrgTimeFormat = () => {
  const store = useStore();
  let organization = store.getState().organizationSettingsGeneral.settings;
  let userOrganization = store.getState().user.organization;

  if (!_.isEmpty(organization)) return organization.timeFormat;
  else if (!_.isEmpty(userOrganization)) return userOrganization.timeFormat;
  else return "HH:mm";
};
