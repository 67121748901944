import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

//Images & Icons
import "../../../assets/themings/verticalTimeline_custom.scss";

const TimelineWrapper = (props) => {
  return (
    <VerticalTimeline className={props.className}>
      {props.children}
    </VerticalTimeline>
  );
};

export default TimelineWrapper;
