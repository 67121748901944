import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { useOrgDateTimeFormat } from "../../helper/hooks/useOrgDateTimeFormat";

// Styles
import styles from "./ReceivedEmailModal.module.scss";

//Components
import Spinner from "../../components/UI/Spinner/Spinner";
import Modal from "../../components/UI/Modal/Modal";

const ReceivedEmailModal = (props) => {
  const { id, show, handleClose } = props;
  let dateTimeFormat = useOrgDateTimeFormat();
  const [model, setModel] = useState({});
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (id != null && show) {
      setLoading(true);

      api(formUrl(API.deals.getReceivedEmailDetails, { receivedEmailId: id }))
        .then((response) => {
          setError(false);
          setLoading(false);
          setModel(response.data);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    }
  }, [id, show]);

  let body = null;

  if (isLoading) {
    body = <Spinner />;
  } else if (error) {
    body = <h3>Error loading message.</h3>;
  } else {
    body = (
      <>
        <div className={styles.details}>
          <p className="font600">
            Company Name: <span>{model.companyName}</span>
          </p>
          <p className="font600">
            Company Size: <span>{model.companySize}</span>
          </p>
          <p className="font600">
            Date of Response:{" "}
            <span>{moment(model.dateOfResponse).format(dateTimeFormat)}</span>
          </p>
        </div>
        <div className={styles.messageInfo}>
          <p className={styles.titleInfo}> Message Information:</p>
          <p>From Name: {model.fromName}</p>
          <p>From Email: {model.fromEmail}</p>
          <p>To: {model.to}</p>
          <p>Message Body:</p>
          <div dangerouslySetInnerHTML={{ __html: model.messageBody }} />
        </div>
      </>
    );
  }

  return (
    <Modal
      dialogClassName="customModal"
      title="Response Details"
      handleClose={handleClose}
      handleSave={handleClose}
      show={show}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
      backdrop={true}
      size="lg"
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.modalBody}>
            {body}
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ReceivedEmailModal;
