import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import { errorResponseToList } from "../../../../../helper/errorHelper";
import api, { API, formUrl } from "../../../../../helper/api/api";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import FormikInput from "../../../../../components/UI/Formik/FormikInput";
import ConnectedTestEmailForm from "../../ConnectedTestEmailForm";

const PasswordChangeModal = (props) => {
  const [sendStatus, setSendStatus] = useState("Not started");
  const [receiveStatus, setReceiveStatus] = useState("Not started");
  const [showTestEmailFormModal, setShowTestEmailFormModal] = useState(false);

  const handleTestMailFormClose = () => setShowTestEmailFormModal(false);

  const sendTest = (emailAccountId) => {
    setShowTestEmailFormModal(true);

    setSendStatus("Started");
    setReceiveStatus("Started");

    api(
      formUrl(API.emailAccounts.testEmailAccount, {
        emailAccountId: emailAccountId,
      })
    )
      .then((response) => {
        if (response.data.sendTestSuccess) setSendStatus("Successful");
        else setSendStatus("Failed");

        if (response.data.receiveTestSuccess) setReceiveStatus("Successful");
        else setReceiveStatus("Failed");
      })
      .catch((err) => {
        setSendStatus("Failed");
        setReceiveStatus("Failed");
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          emailAccountId: props.emailAccountId,
          password: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          api(API.emailAccounts.changePassword, values)
            .then((response) => {
              props.handleClose();
              sendTest(props.emailAccountId);
              toast.success(toastMessages.emailAccount.successChangePassword);
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);
              if (errorList.length > 0) {
                errorList.forEach((e) => actions.setFieldError(e.field, e.message));
              } else {
                toast.error(error.message);
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, isSubmitting } = formikProps;

          return (
            <Modal
              title="Change your password"
              handleClose={props.handleClose}
              handleSave={handleSubmit}
              show={props.showModal}
              closeButtonText="Cancel"
              saveButtonText="Save"
              saveButtonDisabled={isSubmitting}
              btnType="submit"
            >
              <Form>
                <FormikInput label="Password" name="password" placeholder="Type Here" value={values.password} type="password" />
              </Form>
            </Modal>
          );
        }}
      </Formik>
      <ConnectedTestEmailForm
        handleClose={handleTestMailFormClose}
        showModal={showTestEmailFormModal}
        sendStatus={sendStatus}
        receiveStatus={receiveStatus}
      />
    </>
  );
};

export default PasswordChangeModal;
