import React from "react";
import { useField, Field } from "formik";

//Styles
import styles from "./FormikInput.module.scss";

//Components
import Radio from "../Radio/Radio";

const FormikRadio = ({ icon: Icon, checked, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.

  const [meta] = useField(props);
  let errorElement = null;
  let errorInput = null;

  if (meta.touched && meta.error) {
    errorElement = <div className={styles.error}>{meta.error}</div>;
    errorInput = styles.errorInput;
  }

  return (
    <div>
      <Field
        name={props.name}
        className={errorInput}
        render={({ field }) => {
          return (
            <div className="flex">
              <label className={`${props.labelstyle} ${"flex center_flex f12"} ${styles.radioLabel}`}>
                <Radio
                  name={props.name}
                  labelText={props.labelText}
                  labelSubText={props.labelSubText}
                  labelstyle={props.labelstyle}
                  checked={checked}
                  readOnly={props.readOnly}
                  disabled={props.disabled}
                  onClick={props.onClick}
                  icon={Icon}
                  {...field}
                  onChange={props.onChange}
                  // value={props.value}
                  id={props.id || props.labelText.replaceAll(/[^A-Z0-9]/gi, "")}
                />
              </label>
              {errorElement}
            </div>
          );
        }}
      />
    </div>
  );
};

export default FormikRadio;
