import React from "react";

const IconXlsxFile = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667Z"
      fill="#1F7244"
    />
    <path
      d="M23.9667 7.99999C23.9333 7.86666 23.9 7.76666 23.8333 7.66666L17.3333 0.399994V7.99999H23.9667Z"
      fill="#1F7244"
    />
    <path
      d="M2.00001 13.6667H22V30H2.00001V13.6667ZM2.86668 4.53332L1.83334 2.13332H2.93334L3.26668 3.09998C3.36668 3.39998 3.43334 3.69998 3.43334 3.69998C3.43334 3.69998 3.50001 3.39998 3.60001 3.09998L3.93334 2.13332H5.00001L3.96668 4.53332L4.96668 6.89998H3.90001L3.56668 5.89998C3.46668 5.59998 3.40001 5.33332 3.40001 5.33332C3.40001 5.33332 3.33334 5.59998 3.23334 5.89998L2.90001 6.89998H1.86668L2.86668 4.53332V4.53332ZM5.53334 2.13332H6.53334V5.93332H7.83334V6.89998H5.56668V2.13332H5.53334ZM8.53334 5.56665C8.70001 5.76665 8.96668 5.96665 9.23334 5.96665C9.50001 5.96665 9.63334 5.76665 9.63334 5.56665C9.63334 4.89998 8.20001 4.86665 8.20001 3.49998C8.20001 2.69998 8.70001 2.06665 9.56668 2.06665C9.93334 2.06665 10.3 2.16665 10.6 2.39998L10.2333 3.36665C10.1 3.23332 9.80001 3.06665 9.60001 3.06665C9.33334 3.06665 9.20001 3.26665 9.20001 3.46665C9.20001 4.16665 10.6333 4.13332 10.6333 5.49998C10.6333 6.26665 10.2 6.96665 9.30001 6.96665C8.86668 6.96665 8.43334 6.79998 8.10001 6.49998L8.53334 5.56665V5.56665ZM11.9333 4.53332L10.9 2.13332H12L12.3333 3.09998C12.4333 3.39998 12.5 3.69998 12.5 3.69998C12.5 3.69998 12.5667 3.39998 12.6667 3.09998L13 2.13332H14.0667L13.0333 4.53332L14.0333 6.89998H12.9667L12.6333 5.89998C12.5333 5.59998 12.4667 5.33332 12.4667 5.33332C12.4667 5.33332 12.4 5.59998 12.3 5.89998L11.9667 6.89998H10.9L11.9333 4.53332Z"
      fill="white"
    />
    <path
      d="M15.6 16.4H19.6667V19.1H15.6V16.4ZM15.6 20.5667H19.6667V23.2667H15.6V20.5667ZM15.6 24.7H19.6667V27.4H15.6V24.7ZM10.1 24.7H14.1667V27.4H10.1V24.7ZM4.60001 24.7H8.66667V27.4H4.60001V24.7ZM13.6333 23.1L10.9667 19.7333L13.4 16.6667H11L9.73334 18.3333L8.56667 16.6667H6.06667L8.53334 19.8L5.93334 23.1H8.36667L9.73334 21.2667L11.1 23.1H13.6333Z"
      fill="#1F7244"
    />
  </svg>
);

export default IconXlsxFile;
