export const workflowStepConfigurationErrorEnum = {
  StepWithNoDataError: 0,
  FirstStepWaitActionError: 1,
  LastStepWaitActionError: 2,
  SequentialStepsAreTheSameTypeError: 3,
  StepWithoutEmailTemplateError: 4,
  TwoInitialEmailTemplateStepsError: 5,
  TwoStepsWithTheSameEmailTemplateError: 6,
  FirstStepFollowUpTemplateError: 7,
  DraftTemplatesActiveCampaign: 8,
};
