export const PERMISSIONS = {
  application: {
    view: "OrganizationPermissions.Application.View",
  },
  organization: {
    view: "OrganizationPermissions.Organization.View",
    create: "OrganizationPermissions.Organization.Create",
    edit: "OrganizationPermissions.Organization.Edit",
    delete: "OrganizationPermissions.Organization.Delete",

    users: {
      view: "OrganizationPermissions.Organization.Users.View",
      create: "OrganizationPermissions.Organization.Users.Create",
      edit: "OrganizationPermissions.Organization.Users.Edit",
      delete: "OrganizationPermissions.Organization.Users.Delete",
    },

    settings: {
      view: "OrganizationPermissions.Organization.Settings.View",
      edit: "OrganizationPermissions.Organization.Settings.Edit",
      changeImage: "OrganizationPermissions.Organization.Settings.ChangeImage",
    },
  },

  compliance: {
    view: "OrganizationPermissions.Compliance.View",

    questionsAndAnswers: {
      view: "OrganizationPermissions.Compliance.QuestionsAndAnswers.View",
      create: "OrganizationPermissions.Compliance.QuestionsAndAnswers.Create",
      edit: "OrganizationPermissions.Compliance.QuestionsAndAnswers.Edit",
      delete: "OrganizationPermissions.Compliance.QuestionsAndAnswers.Delete",
    },

    rightToErase: {
      view: "OrganizationPermissions.Compliance.RightToErase.View",
      export: "OrganizationPermissions.Compliance.RightToErase.Export",
      delete: "OrganizationPermissions.Compliance.RightToErase.Delete",
    },

    unsubscribeSettings: {
      view: "OrganizationPermissions.Compliance.UnsubscribeSettings.View",
      edit: "OrganizationPermissions.Compliance.UnsubscribeSettings.Edit",
    },
  },

  crm: {
    view: "OrganizationPermissions.CRM.View",
    edit: "OrganizationPermissions.CRM.Edit",
  },

  campaign: {
    view: "OrganizationPermissions.Campaign.View",
    create: "OrganizationPermissions.Campaign.Create",
    edit: "OrganizationPermissions.Campaign.Edit",
    delete: "OrganizationPermissions.Campaign.Delete",
    moveProspectsToCampaign: "OrganizationPermissions.Campaign.MoveProspectsToCampaign",
    viewOptimizations: "OrganizationPermissions.Campaign.ViewOptimizations",
    applyOptimizations: "OrganizationPermissions.Campaign.ApplyOptimizations",
  },

  prospect: {
    view: "OrganizationPermissions.Prospect.View",
    create: "OrganizationPermissions.Prospect.Create",
    edit: "OrganizationPermissions.Prospect.Edit",
    delete: "OrganizationPermissions.Prospect.Delete",
  },

  reports: {
    view: "OrganizationPermissions.Reports.View",
  },

  exclusion: {
    view: "OrganizationPermissions.Exclusion.View",
    create: "OrganizationPermissions.Exclusion.Create",
    delete: "OrganizationPermissions.Exclusion.Delete",
    import: "OrganizationPermissions.Exclusion.Import",
    export: "OrganizationPermissions.Exclusion.Export",
  },

  dealNavigator: {
    view: "OrganizationPermissions.DealNavigator.View",
    edit: "OrganizationPermissions.DealNavigator.Edit",
  },

  salesDocuments: {
    documents: {
      view: "OrganizationPermissions.SalesDocuments.Documents.View",
      create: "OrganizationPermissions.SalesDocuments.Documents.Create",
      edit: "OrganizationPermissions.SalesDocuments.Documents.Edit",
      delete: "OrganizationPermissions.SalesDocuments.Documents.Delete",
    },
  },

  emailAccounts: {
    view: "OrganizationPermissions.EmailAccounts.View",
    edit: "OrganizationPermissions.EmailAccounts.Edit",
    create: "OrganizationPermissions.EmailAccounts.Create",
    delete: "OrganizationPermissions.EmailAccounts.Delete",
    sendTest: "OrganizationPermissions.EmailAccounts.SendTest",
    viewOwnerColumn: "OrganizationPermissions.EmailAccount.ViewOwnerColumn",
    assignToUser: "OrganizationPermissions.EmailAccount.AssignToUser",
  },

  companies: {
    view: "OrganizationPermissions.Companies.View",
    create: "OrganizationPermissions.Companies.Create",
    edit: "OrganizationPermissions.Companies.Edit",
    delete: "OrganizationPermissions.Companies.Delete",
  },

  emailTemplates: {
    view: "OrganizationPermissions.EmailTemplates.View",
    create: "OrganizationPermissions.EmailTemplates.Create",
    edit: "OrganizationPermissions.EmailTemplates.Edit",
    delete: "OrganizationPermissions.EmailTemplates.Delete",
    sendTestEmail: "OrganizationPermissions.EmailTemplate.SendTestEmail",
    sendTestEmailSequence: "OrganizationPermissions.EmailTemplate.SendTestEmailSequence",
  },

  domain: {
    view: "OrganizationPermissions.Domain.View",
    create: "OrganizationPermissions.Domain.Create",
    edit: "OrganizationPermissions.Domain.Edit",
    delete: "OrganizationPermissions.Domain.Delete",
  },

  audience: {
    view: "OrganizationPermissions.Audience.View",
    create: "OrganizationPermissions.Audience.Create",
    edit: "OrganizationPermissions.Audience.Edit",
    delete: "OrganizationPermissions.Audience.Delete",
    importAudienceContact: "OrganizationPermissions.Audience.ImportAudienceContact",
    exportAudienceContact: "OrganizationPermissions.Audience.ExportAudienceContact",
    archiveRestoreAudience: "OrganizationPermissions.Audience.ArchiveRestoreAudience",
    viewAudienceContacts: "OrganizationPermissions.Audience.ViewAudienceContacts",
  },

  audienceExplorer: {
    view: "OrganizationPermissions.AudienceExplorer.View",
    create: "OrganizationPermissions.AudienceExplorer.Create",
  },

  deals: {
    view: "OrganizationPermissions.Deals.View",
    export: "OrganizationPermissions.Deals.Export",
  },

  responderInbox: {
    view: "OrganizationPermissions.ResponderInbox.View",
    edit: "OrganizationPermissions.ResponderInbox.Edit",
  },

  companyActivityOverview: {
    view: "OrganizationPermissions.CompanyActivityOverview.View",
  },

  prospectActivityOverview: {
    view: "OrganizationPermissions.ProspectActivityOverview.View",
  },

  conversation: {
    view: "OrganizationPermissions.Conversation.View",
  },

  calendar: {
    view: "OrganizationPermissions.Calendar.View",
  },

  emailDeliverabilityTest: {
    view: "OrganizationPermissions.EmailDeliverabilityTest.View",
    send: "OrganizationPermissions.EmailDeliverabilityTest.Send",
  },

  integration: {
    view: "OrganizationPermissions.Integrations.View",

    hubspot: {
      getCompaniesProperties: "OrganizationPermissions.Integrations.HubSpot.GetCompaniesProperties",
      getContactsProperties: "OrganizationPermissions.Integrations.HubSpot.GetContactsProperties",
      getDealsProperties: "OrganizationPermissions.Integrations.HubSpot.GetDealsProperties",
      mapContactsAndCompaniesProperties: "OrganizationPermissions.Integrations.HubSpot.MapContactsAndCompaniesProperties",
    },
  },

  dashboard: {
    view: "OrganizationPermissions.Dashboard.View",
  },

  billingPayment: {
    view: "OrganizationPermissions.BillingPayment.View",
    create: "OrganizationPermissions.BillingPayment.Create",
    cancel: "OrganizationPermissions.BillingPayment.Cancel",
  },

  credits: {
    view: "OrganizationPermissions.Credits.View",
  },

  purchaseTemplate: {
    create: "OrganizationPermissions.PurchaseTemplate.Create",
  },

  sendReports: {
    create: "OrganizationPermissions.SendReports.View",
  },

  contract: {
    cancel: "OrganizationPermissions.Contract.Cancel",
  },
};
