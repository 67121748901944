import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const fatchAllIntegrationsRequest = () => {
  return {
    type: actionTypes.GET_ALL_INTEGRATIONS_REQUEST,
  };
};

export const fatchAllIntegrationsSuccess = (result) => {
  return {
    type: actionTypes.GET_ALL_INTEGRATIONS_SUCCEEDED,
    result: result,
  };
};

export const fatchAllIntegrationsFailed = (error) => {
  return {
    type: actionTypes.GET_ALL_INTEGRATIONS_FAILED,
    result: error,
  };
};

// export const fetchHasIntegrationRequest = () => {
//   return {
//     type: actionTypes.GET_HAS_INTEGRATION_REQUEST,
//   };
// };

// export const fetchHasIntegrationSuccess = (result) => {
//   return {
//     type: actionTypes.GET_HAS_INTEGRATION_SUCCEEDED,
//     result: result,
//   };
// };

// export const fetchHasIntegrationFailed = (error) => {
//   return {
//     type: actionTypes.GET_HAS_INTEGATION_FAILED,
//     result: error,
//   };
// };

export const fatchAllIntegrations = () => {
  return (dispatch) => {
    dispatch(fatchAllIntegrationsRequest());
    return api(API.integrations.getAllIntegrations)
      .then((response) => {
        dispatch(fatchAllIntegrationsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fatchAllIntegrationsFailed(error.message));
      });
  };
};

// export const fetchHasIntegration = () => {
//   return (dispatch) => {
//     dispatch(fetchHasIntegrationRequest());
//     return api(API.integrations.getHasSetIntegration)
//       .then((response) => { dispatch(fetchHasIntegrationSuccess(response.data)); })
//       .catch((error) => { fetchHasIntegrationFailed(error.message); });

//   }
// }
