import React, { FC } from "react";
import Action from "../Action";
import TimeLineIcon from "remixicon-react/TimeLineIcon";

interface ActionUnstuckEmailAccountProps {
  classNameIcon?: string;
  size?: number;
}

const ActionUnstuckEmailAccount: FC<ActionUnstuckEmailAccountProps> = ({ classNameIcon = "iconGray", size = 16, ...props }) => {
  return <Action id="unstuckEmailAccount" svgIcon={<TimeLineIcon size={size} className={classNameIcon} />} {...props} />;
};

export default ActionUnstuckEmailAccount;
