import { api } from "./api";
import { DTParameters } from "../models/dataTables/DtParameters";
import { DTResult } from "../models/dataTables/DtResult";
import { CampaignDataTableModel } from "../models/campaign/CampaignDataTableModel";
import { CampaignStatusEnum } from "../models/enums/CampaignStatusEnum";
import { CampaignStatusChangeResponse } from "../models/campaign/CampaignStatusChangeResponse";
import { CreateCampaignModel } from "../models/campaign/CreateCampaignModel";
import { CloneCampaignModel } from "../models/campaign/CloneCampaignModel";
import { CampaignDetailsModel } from "../models/campaign/CampaignDetailsModel";
import { UpdateCampaignNameModel } from "@models/campaign/UpdateCampaignNameModel";
import { ToggleCrmModel } from "@models/campaign/ToggleCrmModel";
import { EditTrackingModel } from "@models/campaign/EditTrackingModel";

export const campaignApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query<DTResult<CampaignDataTableModel>, DTParameters>({
      query: (params) => ({
        url: "/campaigns/campaigns",
        method: "POST",
        body: params,
      }),
      providesTags: ["Campaigns"],
      keepUnusedDataFor: 0,
    }),
    getCampaignStatuses: builder.query<CampaignStatusEnum[], void>({
      query: () => "/campaigns/selectlist-campaign-statuses",
      providesTags: ["CampaignStatuses"],
    }),
    editCampaignStatus: builder.mutation<CampaignStatusChangeResponse, { campaignId: number; status: CampaignStatusEnum }>({
      query: ({ campaignId, status }) => ({
        url: `/campaigns/edit-status?campaignId=${campaignId}&status=${status}`,
        method: "PUT",
      }),
      invalidatesTags: ["Campaigns"],
    }),
    createCampaign: builder.mutation<{ id: number }, CreateCampaignModel>({
      query: (model) => ({
        url: "/campaigns",
        method: "POST",
        body: model,
      }),
      invalidatesTags: ["Campaigns"],
    }),
    cloneCampaign: builder.mutation<{ id: number }, CloneCampaignModel>({
      query: (model) => ({
        url: "/campaigns/campaign-clone",
        method: "POST",
        body: model,
      }),
      invalidatesTags: ["Campaigns"],
    }),
    getCampaign: builder.query<CampaignDetailsModel, number>({
      query: (id) => ({
        url: `/campaigns/settings?campaignId=${id}`,
        method: "GET",
      }),
      providesTags: ["Campaign"],
    }),
    editCampaignName: builder.mutation<void, UpdateCampaignNameModel>({
      query: (model) => ({
        url: "/campaigns",
        method: "PUT",
        body: model,
      }),
      invalidatesTags: ["Campaign"],
    }),
    campaignExists: builder.query<boolean, any>({
      query: () => ({
        url: "/campaigns/campaign-exist",
        method: "GET",
      }),
      providesTags: ["CampaignExist"],
    }),
    toggleCRM: builder.mutation<void, ToggleCrmModel>({
      query: (model) => ({
        url: `campaigns/toggle-crm?campaignId=${model.campaignId}&crmId=${model.crmId}&isEnabled=${model.isEnabled}`,
        method: "POST",
      }),
      invalidatesTags: ["Campaign"],
    }),
    editTracking: builder.mutation<void, EditTrackingModel>({
      query: (model) => ({
        url: `campaigns/edit-tracking?campaignId=${model.campaignId}&tracking=${model.tracking}`,
        method: "PUT",
      }),
      invalidatesTags: ["Campaign"],
    }),
  }),
});

export const {
  useGetCampaignQuery,
  useGetCampaignsQuery,
  useGetCampaignStatusesQuery,
  useCampaignExistsQuery,

  useEditCampaignStatusMutation,
  useCreateCampaignMutation,
  useCloneCampaignMutation,
  useEditCampaignNameMutation,
  useToggleCRMMutation,
  useEditTrackingMutation,
} = campaignApi;
