import React from "react";

//Helpers
import { organizationRoleOptions as roles } from "../../../../../../helper/enums/organizationRoleEnum";

//Styles
import styles from "./InviteUser.module.scss";

//Components
import FormikInput from "../../../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../../../components/UI/Formik/FormikSelect";

const InviteUser = (props) => {
  return (
    <div className={styles.containerInviteUser}>
      <div className={`mB10 ${styles.fieldWrapper}`} id={props.id}>
        <div className={styles.emailWrapper}>
          <FormikInput label="Email" name={props.emailName} placeholder="name@example.com" inputclass="mR15" />
        </div>
        <div className={styles.roleSelectWrapper}>
          <FormikSelect label="Role" name={props.selectName} placeholder="Select a role" options={roles} className={styles.selectRole} />
        </div>
      </div>
    </div>
  );
};
export default InviteUser;
