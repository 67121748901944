import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useAuth } from "react-oidc-context";

//Redux
import { connect } from "react-redux";
import { addUserSuccess, fetchUsers } from "../../store/OrganizationSettings/Users/actions/users";

//Helpers
import api, { API } from "../../helper/api/api";
import { errorResponseToList } from "../../helper/errorHelper";
import { organizationRoleOptions as roles } from "../../helper/enums/organizationRoleEnum";

//Components
import FormikSelect from "../../components/UI/Formik/FormikSelect";
import Modal from "../../components/UI/Modal/Modal";

const ReassignOwnerForm = (props) => {
  const { userOwner, emailAccounts } = props;

  const auth = useAuth();
  var listOfEmailAcc = [];

  emailAccounts.forEach((item) => {
    if (item !== userOwner.email) {
      let obj1 = { value: item, label: item };
      listOfEmailAcc.push(obj1);
    }
  });

  return (
    <Formik
      initialValues={{
        email: "", // added for our select
      }}
      onSubmit={(values, actions) => {
        const userRole = {
          emailNewOwner: values.email,
          organizationId: props.orgId,
          emailOwner: userOwner.email,
        };
        api(API.OUTBOSS.users.changeOrganizationOwner, userRole)
          .then((response) => {
            toast.success("Successfully added owner role to " + values.email + ".");
            auth.signinSilent();
            props.fetchUsers();
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, isSubmitting } = formikProps;

        return (
          <Modal
            title="Reassign Owner"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form>
              <FormikSelect
                label="Assign owner role to:"
                name="email"
                placeholder="Select user"
                options={listOfEmailAcc}
                value={values.email}
                isMenuPortalTargetDisabled={true}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.user.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    addUserSuccess: (user) => dispatch(addUserSuccess(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReassignOwnerForm);
