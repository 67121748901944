import React from "react";
import { useHistory } from "react-router-dom";

//Images & Icons
import InformationFillIcon from "remixicon-react/InformationFillIcon";

//Styles
import "./InfoIconTooltip.scss";

interface InfoIconTooltipProps {
  id?: string;
  tooltipClass?: string;
  tooltipBody: string | JSX.Element;
  disableStartTour?: boolean;
  disableStartTourButton?: boolean;
  href?: string;
  linkText?: string;
  buttonId?: string;
  classes?: string;
  placement?: string;
  iconSize?: number;
  helpText?: string;
  to?: string;
}

const InfoIconTooltip: React.FC<InfoIconTooltipProps> = (props) => {
  let classes = "customTooltip";

  if (props.placement != null) {
    classes += " " + [props.placement];
  }

  if (props.tooltipClass != null) {
    classes += " " + props.tooltipClass;
  }

  const history = useHistory();

  const handleButtonClick = () => {
    history.push({ pathname: props.to, search: "?tour=true" });
  };

  return (
    <div className="tourCustomTooltip flex_center" id={props.id || "infoTooltipIcon"}>
      <InformationFillIcon className="iconGray" size={props.iconSize ? props.iconSize : 20} />
      <div className={classes}>
        <div>{props.tooltipBody}</div>
        {props.helpText && <p className="mT5">{props.helpText}</p>}
        <div>
          {props.disableStartTour ? null : (
            <div className="helpLinks">
              <a
                id="readMoreLink"
                className={`${props.disableStartTourButton ? "width_100" : ""} readMore`}
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.linkText}
              </a>
              {props.disableStartTourButton ? null : (
                <button className="myButton" id={props.buttonId} onClick={handleButtonClick}>
                  Start tour
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoIconTooltip;
