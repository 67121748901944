import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Cell } from "styled-css-grid";

//Redux
import { fetchSalesVideosDocuments } from "../../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Images & Icons
import AddLineIcon from "remixicon-react/AddLineIcon";

//Styles
import styles from "./Videos.module.scss";

//Components
import AddVideoForm from "../../Modals/AddVideoForm";
import Permission from "../../../../components/auth/Permission";
import Video from "./Video";

const Videos = (props) => {
  const { fetchSalesVideosDocuments, search } = props;
  const { documents } = props.salesDocuments;

  useEffect(() => {
    fetchSalesVideosDocuments(search);
  }, [search]);

  const [showAddVideoModal, setShowAddVideoModal] = useState(false);
  const handleAddVideoClose = () => setShowAddVideoModal(false);
  const handleAddVideoShow = () => setShowAddVideoModal(true);

  return (
    <>
      <Grid className={styles.videosContainer} columns={7}>
        {documents.map((video) => (
          <Cell className={styles.videoWrapper} key={video.id} style={{ height: "11rem" }}>
            <Video {...video} />
          </Cell>
        ))}
        <Permission has={PERMISSIONS.salesDocuments.documents.create}>
          <button className={` ${styles.addVideoButton}`} onClick={handleAddVideoShow} aria-label="Add video button">
            <Cell center middle>
              <AddLineIcon size={33} />
            </Cell>
          </button>
        </Permission>
      </Grid>

      <AddVideoForm handleClose={handleAddVideoClose} showModal={showAddVideoModal} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    salesDocuments: state.salesDocuments,
    search: state.salesDocuments.searchValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesVideosDocuments: (search) => dispatch(fetchSalesVideosDocuments(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
