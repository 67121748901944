import React, { useState, useEffect } from "react";
import Moment from "moment";
import _ from "lodash";

//Helpers
import { tableColumns } from "./columns/RTEProspectActivityLogColumns";
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";

//Styles
import styles from "../RTE.module.scss";

//Components
import Table from "../../../../../components/DataTable/Table";

const RTEProspectActivityLog = (props) => {
  const { totalProspectActionLogs, prospectActivityLog } = props;
  const [tableRows, setTableRows] = useState([]);
  let dateFormat = useOrgDateFormat();

  const param = {
    start: 0,
    page: 1,
    length: totalProspectActionLogs,
    sort: {},
    filter: [],
    search: "",
  };
  useEffect(() => {
    const rowsTemp = _.cloneDeep(prospectActivityLog);
    if (rowsTemp.length > 0) {
      rowsTemp.map((r) => {
        r.dateOfAction = Moment(r.dateOfAction).format(dateFormat);
        return r;
      });
    }
    setTableRows(rowsTemp);
  }, [prospectActivityLog]);

  return (
    <>
      <Table
        columns={tableColumns}
        rows={tableRows}
        showPagination={false}
        param={param}
        variant="small"
        tableClassName={styles.rtepTable}
      />
    </>
  );
};

export default RTEProspectActivityLog;
