import React from "react";
import svg from "./email_opened.svg";

const EmailOpened = (props) => (
  <svg
    className={props.imgStyle}
    id="newsletter"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 16}
    height={props.height ? props.height : 16}
    viewBox="0 0 16 16"
  >
    <path
      id="Path_1282"
      data-name="Path 1282"
      d="M181.278,1.753,179.543.3a1.251,1.251,0,0,0-1.617,0l-1.734,1.457Z"
      transform="translate(-170.735 0)"
      fill={props.fill ? props.fill : "#d80f6f"}
    />
    <path
      id="Path_1283"
      data-name="Path 1283"
      d="M46,88.712l6.343,5.074a.305.305,0,0,0,.383,0l6.343-5.074V85.657a.63.63,0,0,0-.622-.637H46.622a.63.63,0,0,0-.622.637Zm10.408.768a.472.472,0,0,1-.467.478H49.127a.478.478,0,0,1,0-.956h6.814A.472.472,0,0,1,56.408,89.48Zm-2.863-2.39a.478.478,0,0,1,0,.956H49.127a.478.478,0,0,1,0-.956Z"
      transform="translate(-44.534 -82.424)"
      fill={props.fill ? props.fill : "#d80f6f"}
    />
    <path
      id="Path_1284"
      data-name="Path 1284"
      d="M42.046,378.039a1.222,1.222,0,0,0,.368-.056l-5.871-4.3a.466.466,0,0,1-.057-.05l-.405.323a1.226,1.226,0,0,1-1.535,0l-.405-.323a.461.461,0,0,1-.057.05l-5.871,4.3a1.222,1.222,0,0,0,.368.056Z"
      transform="translate(-27.312 -362.039)"
      fill={props.fill ? props.fill : "#d80f6f"}
    />
    <path
      id="Path_1285"
      data-name="Path 1285"
      d="M324.262,203.02l-6.178,4.827,6.036,4.328a1.269,1.269,0,0,0,.142-.585Z"
      transform="translate(-308.262 -196.864)"
      fill={props.fill ? props.fill : "#d80f6f"}
    />
    <path
      id="Path_1286"
      data-name="Path 1286"
      d="M6.178,207.847,0,203.02v8.57a1.269,1.269,0,0,0,.142.585Z"
      transform="translate(0 -196.864)"
      fill={props.fill ? props.fill : "#d80f6f"}
    />
  </svg>
);

export default EmailOpened;
