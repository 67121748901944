import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

//Redux
import { fetchCampaignsInfo } from "../../../../store/Dashboard/Global/actions/dashboard";

//Styles
import styles from "./CampaignsInfo.module.scss";

//Components
import SkeletonCampaignsInfo from "../../../../components/UI/Skeletons/components/SkeletonCampaignsInfo/SkeletonCampaignsInfo";

const CampaignsInfo = (props) => {
  const { fetchCampaignsInfo, isCampaignsInfoLoading, isCampaignsInfoLoaded, errorCampaignsInfo, campaignsInfo } = props;

  useEffect(() => {
    fetchCampaignsInfo();
  }, []);

  let content = null;

  if (errorCampaignsInfo) {
    content = <h2>Error getting content</h2>;
  }

  if (isCampaignsInfoLoading) {
    content = <SkeletonCampaignsInfo />;
  }

  if (isCampaignsInfoLoaded && !_.isNull(campaignsInfo)) {
    content = (
      <div className={`${styles.campaignStatistic}`}>
        <div className={`${styles.campaignList} flex`}>
          <div className={`${styles.activeCampaign} ${styles.statusWrapper}`}>
            <span className={styles.text}>Active</span>
            <span className={`${styles.activeNumber} ${styles.dataStatus}`}>{campaignsInfo.active}</span>
          </div>
          <div className={`${styles.scheduledCampaign} ${styles.statusWrapper}`}>
            <span className={styles.text}>Scheduled</span>
            <span className={`${styles.scheduledNumber} ${styles.dataStatus}`}>{campaignsInfo.scheduled}</span>
          </div>
          <div className={`${styles.draftCampaign} ${styles.statusWrapper}`}>
            <span className={styles.text}>Draft</span>
            <span className={`${styles.draftNumber} ${styles.dataStatus}`}>{campaignsInfo.draft}</span>
          </div>
          <div className={`${styles.pausedCampaign} ${styles.statusWrapper}`}>
            <span className={styles.text}>Paused</span>
            <span className={`${styles.pausedNumber} ${styles.dataStatus}`}>{campaignsInfo.paused}</span>
          </div>
        </div>
        {/* <div className={`${styles.notification} ${styles.alerts}`}>
          <span>2 Alerts</span>
        </div> */}
        <div className={`${styles.details} flex`}>
          <p>
            <span>{campaignsInfo.users}</span>Users
          </p>
          <p>
            <span>{campaignsInfo.assigned}</span>Assigned
          </p>
          <p>
            <span>{campaignsInfo.emailAccounts}</span>Email Accounts
          </p>
        </div>
      </div>
    );
  }

  return <>{content}</>;
};

const mapStateToProps = (state) => {
  return {
    isCampaignsInfoLoading: state.dashboard.isCampaignsInfoLoading,
    isCampaignsInfoLoaded: state.dashboard.isCampaignsInfoLoaded,
    errorCampaignsInfo: state.dashboard.errorCampaignsInfo,
    campaignsInfo: state.dashboard.campaignsInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCampaignsInfo: () => dispatch(fetchCampaignsInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsInfo);
