import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label, Legend } from "recharts";

//Helper
import { formatNumberHelper } from "../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../helper/hooks/useGroupDecimalSeparator";

// Style
import "./PieChartAngle.scss";

const PieChartAngle = (props) => {
  let separators = useGroupDecimalSeparator();
  let data = [];

  if (props.data != null && Object.keys(props.data).length > 0) {
    Object.keys(props.data).forEach((key) => {
      data.push({
        name: key,
        value: props.data[key],
      });
    });
  } else {
    // If we don't have lead types then set one total parameter with 1 value (for full string)
    data.push({
      name: "Total",
      value: 1,
    });
  }

  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={props.innerRadius}
          outerRadius={props.outerRadius}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          nameKey="name"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={props.colors[index % props.colors.length]} />
          ))}
          <Label value={props.textValue} position="centerBottom" className={`label-top ${props.topLabelClass}`} />
          <Label value={props.numberValue} position="centerTop" className={`label-bottom ${props.bottomLabelClass}`} />
        </Pie>
        {!props.showLegend ? null : <Legend iconType="circle" />}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartAngle;
