import React from "react";
import { connect } from "react-redux";

//Redux
import { changeUserRole } from "../../../store/OrganizationSettings/Users/actions/users";

//Helpers
import { organizationRoleOptions as roles } from "../../../helper/enums/organizationRoleEnum";

//Components
import Select from "../../../components/UI/Select/Select";

const ChangeUserRole = (props) => {
  const { user, orgId } = props;

  const changeRoleHandler = (role) => {
    const selectedOption = roles.find((r) => r.value === role);
    const userRole = {
      role: selectedOption.value,
      roleName: selectedOption.label,
      id: user.userId,
      isInvite: user.isInvite,
      email: user.email,
      organizationId: orgId,
    };

    const prevRole = {
      role: user.role,
      roleName: user.roleName,
      id: user.userId,
      isInvite: user.isInvite,
      email: user.email,
    };

    props.changeUserRole(userRole, prevRole);
  };

  return (
    <Select
      name="roles"
      placeholder={user.roleName}
      options={roles}
      value={user.roleName}
      onChange={(role) => changeRoleHandler(role)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserRole: (userRole, prevRole) =>
      dispatch(changeUserRole(userRole, prevRole)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserRole);
