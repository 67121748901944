export const columns = [
  {
    label: "",
    field: "checkbox",
    sort: "disabled",
    minimal: "sm",
    width: 50,
    maxwidth: 50,
  },
  {
    label: "Name",
    field: "fullName",
    sort: "asc",
    minimal: "lg",
    width: 130,
    minWidth: 150,
    flex: 1,
  },
  {
    label: "Job Title",
    field: "title",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 120,
    minWidth: 100,
  },
  {
    label: "Company",
    field: "companyName",
    sort: "asc",
    minimal: "lg",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  // {
  //   label: "In Use",
  //   field: "inUse",
  //   sort: "disabled",
  //   type: "actions",
  //   minimal: "sm",
  //   width: "60",
  // },
];
