import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Cell } from "styled-css-grid";

//Redux
import { fetchSalesDocumentsImages } from "../../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Style
import styles from "./Images.module.scss";

//Images & Icons
import AddLineIcon from "remixicon-react/AddLineIcon";
import Image from "./Image";

//Components
import AddImageForm from "../../Modals/AddImageForm";
import Permission from "../../../../components/auth/Permission";

const Images = (props) => {
  const { fetchSalesDocumentsImages, search } = props;
  const { images } = props.salesDocuments;

  useEffect(() => {
    fetchSalesDocumentsImages(search);
  }, [search]);

  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const handleAddImageClose = () => setShowAddImageModal(false);
  const handleAddImageShow = () => setShowAddImageModal(true);

  return (
    <>
      <Grid columns={7} className={styles.card}>
        {images.map((image) => (
          <Cell className={styles.videoWrapper} key={image.id} style={{ height: "11rem" }}>
            <Image {...image} />
          </Cell>
        ))}
        <Permission has={PERMISSIONS.salesDocuments.documents.create}>
          <button className={` ${styles.addVideoButton}`} onClick={handleAddImageShow} aria-label="Add button">
            <Cell center middle>
              <AddLineIcon size={33} />
            </Cell>
          </button>
        </Permission>
      </Grid>

      <AddImageForm handleClose={handleAddImageClose} showModal={showAddImageModal} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    salesDocuments: state.salesDocuments,
    search: state.salesDocuments.searchValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesDocumentsImages: (search) => {
      dispatch(fetchSalesDocumentsImages(search));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Images);
