import { WorkflowStepDetails } from "@models/workflowSteps/WorkflowStepDetails";
import { api } from "./api";

export const workflowStepsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowSteps: builder.query<WorkflowStepDetails[], number>({
      query: (campaignId) => ({
        url: `/workflow-steps/workflow-steps-list?campaignId=${campaignId}`,
        method: "GET",
      }),
      providesTags: ["WorkflowSteps"],
    }),
  }),
});

export const { useGetWorkflowStepsQuery } = workflowStepsApi;
