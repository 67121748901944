export const FETCH_LIST_OF_PROSPECTS_REQUEST =
  "FETCH_LIST_OF_PROSPECTS_REQUEST";
export const FETCH_LIST_OF_PROSPECTS_SUCCEEDED =
  "FETCH_LIST_OF_PROSPECTS_SUCCEEDED";
export const FETCH_LIST_OF_PROSPECTS_FAILED = "FETCH_LIST_OF_PROSPECTS_FAILED";

export const CLEAR_LIST_OF_PROSPECTS = "CLEAR_LIST_OF_PROSPECTS";

export const FETCH_TOTAL_NUMBER_OF_EMAILS_REQUEST =
  "FETCH_TOTAL_NUMBER_OF_EMAILS_REQUEST";
export const FETCH_TOTAL_NUMBER_OF_EMAILS_SUCCEEDED =
  "FETCH_TOTAL_NUMBER_OF_EMAILS_SUCCEEDED";
export const FETCH_TOTAL_NUMBER_OF_EMAILS_FAILED =
  "FETCH_TOTAL_NUMBER_OF_EMAILS_FAILED";

export const FETCH_MESSAGE_PREVIEW_REQUEST = "FETCH_MESSAGE_PREVIEW_REQUEST";
export const FETCH_MESSAGE_PREVIEW_SUCCEEDED =
  "FETCH_MESSAGE_PREVIEW_SUCCEEDED";
export const FETCH_MESSAGE_PREVIEW_FAILED = "FETCH_MESSAGE_PREVIEW_FAILED";

export const CHANGE_RESOLVE_REQUEST = "CHANGE_RESOLVE_REQUEST";
export const CHANGE_RESOLVE_SUCCEEDED = "CHANGE_RESOLVE_SUCCEEDED";
export const CHANGE_RESOLVE_FAILED = "CHANGE_RESOLVE_FAILED";

export const RESPONDER_INBOX_COUNT_CHANGE = "RESPONDER_INBOX_COUNT_CHANGE";

export const CHANGE_BACK_TO_UNRESOLVED_REQUEST =
  "CHANGE_BACK_TO_UNRESOLVED_REQUEST";
export const CHANGE_BACK_TO_UNRESOLVED_SUCCEEDED =
  "CHANGE_BACK_TO_UNRESOLVED_SUCCEEDED";
export const CHANGE_BACK_TO_UNRESOLVED_FAILED =
  "CHANGE_BACK_TO_UNRESOLVED_FAILED";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_FILTER_FOR_REVIEW = "SET_FILTER_FOR_REVIEW";
export const SET_LOCATION = "SET_LOCATION";

export const CONFIRM_ALL_AUTO_RESOLVED_REQUEST =
  "CONFIRM_ALL_AUTO_RESOLVED_REQUEST";
export const CONFIRM_ALL_AUTO_RESOLVED_SUCCEEDED =
  "CONFIRM_ALL_AUTO_RESOLVED_SUCCEEDED";
export const CONFIRM_ALL_AUTO_RESOLVED_FAILED =
  "CONFIRM_ALL_AUTO_RESOLVED_FAILED";