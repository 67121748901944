import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

//Redux
import * as actionCreators from "../store/global/actions/user";

//Helpers
import { activeApplicationEnum } from "../helper/enums/activeApplicationEnum";
import { globalPermissionHelper } from "../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../helper/globalUserRolePermissionsConstants";

//Components
import PrivateRoute from "./PrivateRoute";
import PrivateRouteOutboss from "./PrivateRouteOutboss";

const RouteWithLayout = ({ layout, component, permission, isPrivate, ...rest }) => {
  const props = { ...rest };
  let organizationId = null;
  const hasViewAllPermission = globalPermissionHelper(props.state.user.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll);

  // useEffect(() => {
  if (props.computedMatch != null && props.computedMatch.params != null) {
    // window.usetifulTags = props.computedMatch.params;

    if (props.computedMatch.params.organizationId != null) {
      organizationId = parseInt(props.computedMatch.params.organizationId);
    }
  }

  if (hasViewAllPermission && organizationId !== null) {
    if (!props.state.user.organization || (props.state.user.organization && organizationId !== props.state.user.organization.id)) {
      if (props.state.user.organizations && props.state.user.organizations.some((org) => org.id === organizationId) === false) {
        if (props.state.user.adminOrganizationLoading === undefined || props.state.user.adminOrganizationLoading === false) {
          props.getAdminOrganization(organizationId);
        }
      }
    }
  }

  if (
    props.computedMatch != null &&
    props.computedMatch.path &&
    props.computedMatch.path != null &&
    props.computedMatch.path.startsWith("/outboss") &&
    props.state.user.application !== activeApplicationEnum.Outboss
  ) {
    props.changeApplication(activeApplicationEnum.Outboss);
  }

  if (organizationId != null) {
    if (props.state.user.organizations.some((org) => org.id === organizationId)) props.changeOrganization(organizationId);
    if (props.state.user.application !== activeApplicationEnum.Outbase) props.changeApplication(activeApplicationEnum.Outbase);
  } else {
    props.changeOrganization(null);
  }
  // }, [props.computedMatch, props.state.user.application, props.state.user.organizations, props.state.user.organization]);

  if (isPrivate) {
    return props.currentApplication === activeApplicationEnum.Outbase ? (
      <PrivateRoute layout={layout} component={component} permission={permission} {...rest} />
    ) : (
      <PrivateRouteOutboss layout={layout} component={component} permission={permission} {...rest} />
    );
  } else {
    return <Route {...rest} render={(props) => React.createElement(layout, props, React.createElement(component, props))} />;
  }
};

const mapStateToProps = (state) => {
  return {
    state,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeOrganization: (organizationId) => {
      dispatch(actionCreators.changeOrganization(organizationId));
    },
    changeApplication: (application) => {
      dispatch(actionCreators.changeApplication(application));
    },
    getAdminOrganization: (organizationId) => {
      dispatch(actionCreators.getAdminOrganization(organizationId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteWithLayout);
