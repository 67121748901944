import React from "react";

//Styles
import styles from "./TotalLoginsColOutbOss.module.scss";

const TotalLoginsColOutbOss = (props) => {
  return (
    <div className={styles.totalLoginsWrapper}>
      <p className={styles.label}>{props.totalLogins}</p>
    </div>
  );
};

export default TotalLoginsColOutbOss;
