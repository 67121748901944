import React, { useContext, useEffect } from "react";
import { Row, Col, Container, ProgressBar } from "react-bootstrap";
import { SIGNALR_CONSTANTS } from "../../../helper/signalrConstants";
import SignalRContext from "../../../helper/SignalRContext";

//Redux
import { signalReceived } from "../../../store/Signal/actions/signal";

//Styles
import styles from "../Exclusions.module.scss";

//Components
import AlertNotification from "../../../components/UI/AlertNotification/AlertNotification";

const ExclusionsImportResultForm = (props) => {
  const { setExclusionsImportInfo } = { ...props };

  const signalRConnection = useContext(SignalRContext);

  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.EXCLUSIONS_IMPORT_INFO, (data) => {
        setExclusionsImportInfo(data);
        signalReceived(SIGNALR_CONSTANTS.EXCLUSIONS_IMPORT_INFO, data);
        if (data?.importPercentage === 100 || data?.hasErrorColumnName) {
          props.setSaveButtonText("Close");
        }
      });
    }

    return () => {
      if (signalRConnection != null) {
        try {
          signalRConnection.off(SIGNALR_CONSTANTS.EXCLUSIONS_IMPORT_INFO);
        } catch {}
      }
    };
  }, [signalRConnection]);

  let result = null;

  if (true) {
    result = (
      <Container>
        <Row>
          <Col xl={12}>
            <p>Processing your exclusions list.</p>
          </Col>
          <Col xl={12} className="mB10">
            <ProgressBar
              className={styles.uploadFileProgressBar}
              now={props.exclusionsImportInfo?.importPercentage || 0}
              max={100}
              min={1}
              animated
            />
          </Col>
          <Col xl={6} className={`floatLeft ${styles.uploadFileProgressInfo}`}>
            {(props.exclusionsImportInfo?.importPercentage || 0) >= 100 ? "Done" : "Processing..."}
          </Col>

          <Col xl={6} className={`textRight ${styles.uploadFileProgressInfo}`}>
            {props.exclusionsImportInfo?.importPercentage || 0} %
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <p>The system has processed {props.exclusionsImportInfo?.processedExclusions || 0} exclusions out of which:</p>
          </Col>
        </Row>
        <Row className={styles.procesingDetails}>
          <Col xl={3}>
            <h2 id="validContacts" className={styles.importedExclusions}>
              {props.exclusionsImportInfo?.importedExclusions || 0}
            </h2>
            <p className="font600">Imported</p>
          </Col>
          <Col xl={3}>
            <h2 id="duplicateContacts" className={styles.duplicateExclusions}>
              {props.exclusionsImportInfo?.duplicateExclusions || 0}
            </h2>
            <p className="font600">Duplicates</p>
          </Col>
          <Col xl={3}>
            <h2 id="excludedContacts" className={styles.invalidExclusions}>
              {props.exclusionsImportInfo?.invalidExclusions || 0}
            </h2>
            <p className="font600">Invalid</p>
          </Col>
          <Col xl={3}>
            <h2 id="invalidContacts" className={styles.duplicateInSystemExclusions}>
              {props.exclusionsImportInfo?.duplicateInSystemExclusions || 0}
            </h2>
            <p className="font600">Exist in system</p>
          </Col>
        </Row>
        <Row>
          <Col className="textCenter">
            {props.exclusionsImportInfo?.csvString && props.exclusionsImportInfo?.importPercentage === 100 ? (
              <p
                className={styles.downloadInvalidExclusions}
                onClick={() => {
                  props.downloadInvalidExclusionsCSV(props.exclusionsImportInfo.csvString);
                }}
              >
                Download failed records
              </p>
            ) : null}
          </Col>
        </Row>
        {props.exclusionsImportInfo?.hasErrorColumnName ? ( 
          <Col xl={12}>
            <AlertNotification bodyClassName="flex align-center" variant="danger_alert">
            <p className={`f12`}>
              Invalid file format, please use Outbase exclusions CSV Template{" "}
              <a className="f12" onClick={props.downloadCSVTemplate}>
                link to template download {" "}
              </a>
              and try again.
            </p>
          </AlertNotification>
          </Col>): null}
      </Container>
    );
  }

  return result;
};

export default ExclusionsImportResultForm;
