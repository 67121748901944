export const columns = [
  {
    label: "Email Provider",
    field: "name",
    type: "text",
    sort: "asc",
    minWidth: 190,
    width: 190,
    flex: 1,
  },
  {
    label: "# of Emails Sent",
    field: "numberOfMessages",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 120,
  },
  {
    label: "Delivered To",
    field: "deliveredTo",
    type: "text",
    sort: "asc",
    flex: 2,
    width: 450,
    minWidth: 450,
  },
  {
    label: "SPF",
    field: "spf",
    type: "actions",
    sort: "asc",
    width: 110,
  },
  {
    label: "Sender Score",
    field: "senderScore",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 130,
  },
  {
    label: "Black Lists",
    field: "blackLists",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 120,
  },
  {
    label: "Delivered In",
    field: "delay",
    columnClass: "textRight",
    class: "textRight",
    sort: "asc",
    width: 140,
  },
];
