import _ from "lodash";
import { templateTimeVariables } from "../helper/templateTimeVariables";

export const splitEmailTemplateVariables = (text) => {
  if (text === "") return [];

  const variableRegex = /{{(.*?)}}/g;

  text = text.replace(/\n/g, " ");
  let arrayOfSplitVariables = text.split(" ");

  let arrayOfFilteredVariables = [];

  arrayOfSplitVariables.forEach((variable) => {
    if (
      variable === null ||
      variable === undefined ||
      variable === "" ||
      !variable.match(variableRegex)
    ) {
      return;
    }
    let matchedVariable = variable.match(variableRegex); // this can be array of strings or one string element

    let filterdVariables = "";
    if (matchedVariable.length > 1) {
      matchedVariable.forEach((matched) => {
        filterdVariables = matched
          .replace("{{", "")
          .replace("}}", "")
          .toUpperCase();

        if (templateTimeVariables.indexOf(filterdVariables) > -1) {
          return;
        }

        if (filterdVariables.includes("|")) {
          let optionalVariable = filterdVariables.split("|")[0];
          arrayOfFilteredVariables.push(optionalVariable);
          return;
        }

        arrayOfFilteredVariables.push(filterdVariables);
      });
    } else {
      filterdVariables = matchedVariable[0]
        .replace("{{", "")
        .replace("}}", "")
        .toUpperCase();
    }

    if (templateTimeVariables.indexOf(filterdVariables) > -1) {
      return;
    }

    if (filterdVariables.includes("|")) {
      let optionalVariable = filterdVariables.split("|")[0];
      arrayOfFilteredVariables.push(optionalVariable);
      return;
    }

    arrayOfFilteredVariables.push(filterdVariables);
  });

  arrayOfFilteredVariables = _.uniq(arrayOfFilteredVariables);

  return arrayOfFilteredVariables;
};
