import React from "react";

interface IconConvertProps {
  imgStyle?: string;
}

const IconConvert: React.FC<IconConvertProps> = (props) => (
  <svg width="23" height="7" viewBox="0 0 23 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.imgStyle}>
    <path d="M0.5 6.49998H22.5L15.5 0.999985" stroke="#68808D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default IconConvert;
