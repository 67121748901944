import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  notifications: [],
  olderNotifications: [],
  notificationsCount: 0,
  olderNotificationsCount: 0,
  unreadNotificationsCount: 0,
  unreadOlderNotificationsCount: 0,
};

const loadInAppNotifications = (state, action) => {
  const newState = { ...state };
  let notifications = { ...state.notifications };
  notifications = action.result.notifications;
  let olderNotifications = { ...state.olderNotifications };
  olderNotifications = action.result.olderNotifications;
  let notificationsCount = { ...state.notificationsCount };
  notificationsCount = action.result.notifications.length;
  let olderNotificationsCount = { ...state.olderNotificationsCount };
  olderNotificationsCount = action.result.olderNotifications.length;
  let unreadNotificationsCount = { ...state.unreadNotificationsCount };
  unreadNotificationsCount = action.result.unreadNotificationsCount;
  let unreadOlderNotificationsCount = {
    ...state.unreadOlderNotificationsCount,
  };
  unreadOlderNotificationsCount = action.result.unreadOlderNotificationsCount;

  newState.notifications = notifications;
  newState.olderNotifications = olderNotifications;
  newState.notificationsCount = notificationsCount;
  newState.olderNotificationsCount = olderNotificationsCount;
  newState.unreadNotificationsCount = unreadNotificationsCount;
  newState.unreadOlderNotificationsCount = unreadOlderNotificationsCount;

  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_IN_APP_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_IN_APP_NOTIFICATIONS_SUCCESS:
      return loadInAppNotifications(state, action);
    case actionTypes.FETCH_IN_APP_NOTIFICATIONS_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        inAppNotifications: [],
        error: action.result,
      };
    default:
      break;
  }

  return state;
};

export default reducer;
