import React from "react";
import _ from "lodash";

//Styles
import styles from "./SectionPreview.module.scss";

//Components
import MessageIframe from "../../../../components/UI/MessageIframe/MessageIframe";
import UserPhoto from "../../../../components/UI/UserPhoto/UserPhoto";

const SectionPreview = (props) => {
  const { sender, responder } = props;

  let preview = null;
  if (!_.isEmpty(sender) && !_.isEmpty(responder)) {
    preview = (
      <>
        <div className={styles.responderMessagePreview}>
          <UserPhoto name={responder.fullName} size="medium" />
          <div className={styles.selectedConversationBody}>
            <div className={styles.headerConversation}>
              <div className={styles.info}>
                <div className={`${styles.conversationDetails} flex`}>
                  <div
                    className={`${styles.conversationDetailsInfoContent} flex`}
                  >
                    <div className="flex">
                      <p>
                        <span className={styles.fullName}>
                          {responder.fullName}
                        </span>
                      </p>
                      <p>
                        <span className={styles.emailDetails}>
                          {responder.emailFrom}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className={`${styles.responseDate} f12`}>
                          {responder.date}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  <span className={styles.sendTo}>to:</span> {responder.emailTo}
                  {responder.cc && responder.cc !== "" ? (
                    <>
                      <br />
                      <span className={styles.sendTo}>cc:</span> {responder.cc}
                    </>
                  ) : null}
                </p>
              </div>
              <h2>
                <span>Subject:</span>
                {responder.subject}
              </h2>
            </div>
            <div className={styles.messageBody}>
              <MessageIframe>{responder.content}</MessageIframe>
            </div>
          </div>
        </div>

        <div className={`${styles.senderMessagePreview} ${styles.sender}`}>
          <UserPhoto name={sender.fullName} size="medium" />
          <div className={`${styles.selectedConversationBody} `}>
            <div className={styles.headerConversation}>
              <div className={styles.conversationDetailsInfoContent}>
                <div className="flex">
                  <p>
                    <span className={styles.fullName}>{sender.fullName}</span>
                  </p>
                  <p>
                    <span className={styles.emailDetails}>
                      {sender.emailFrom}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="f12">{sender.date}</span>
                  </p>
                </div>
              </div>
              <div>
                <p>
                  {" "}
                  <span className={styles.sendTo}>to:</span> {sender.emailTo}
                </p>
              </div>
              <h2>
                <span>Subject:</span>
                {sender.subject}
              </h2>
            </div>
            <div className={styles.messageBody}>
              <MessageIframe>{sender.content}</MessageIframe>
            </div>
          </div>
        </div>
      </>
    );
  } else if (!_.isEmpty(sender) && _.isEmpty(responder)) {
    preview = (
      <>
        <div className={`${styles.senderMessagePreview} ${styles.sender}`}>
          <UserPhoto name={sender.fullName} size="medium" />
          <div className={`${styles.selectedConversationBody}`}>
            <div className={styles.headerConversation}>
              <div className={styles.conversationDetailsInfoContent}>
                <div className="flex">
                  <p>
                    <span className={styles.fullName}>{sender.fullName}</span>
                  </p>
                  <p>
                    <span className={styles.emailDetails}>
                      {sender.emailFrom}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="f12">{sender.date}</span>
                  </p>
                </div>
              </div>
              <div>
                <p>
                  {" "}
                  <span className={styles.sendTo}>to:</span> {sender.emailTo}
                </p>
              </div>
              <h2>
                <span>Subject:</span>
                {sender.subject}
              </h2>
            </div>
            <div className={styles.messageBody}>
              <MessageIframe>{sender.content}</MessageIframe>
            </div>
          </div>
        </div>
      </>
    );
  } else preview = <span>Insert EmptyState component after merge</span>;

  return <>{preview}</>;
};

export default SectionPreview;
