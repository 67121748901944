import React, { useState } from "react";

//Styles
import styles from "./ReviewFilter.module.scss";

//Images & Icons
import CloseLineIcon from "remixicon-react/CloseLineIcon";

const ReviewFilter = (props) => {
  return (
    <>
      {props.forReviewCount > 0 ?
        <div className="flex align-center f12">
          <p
            className={`${styles.reviewLabel} ${props.filterForReview && styles.reviewLabelClicked} flex align-center cursor_pointer`}
            onClick={props.handleClickFilterForReview}
          >
            <button className={styles.buttonLink} >
              <span>For Review ({props.forReviewCount})</span>
              <>{props.filterForReview && <CloseLineIcon size={16} className="iconGray mL5" />}</>
            </button>
          </p>
          {props.filterForReview ?
            <p className={`${styles.confirmAll} f12 m0 cursor_pointer`} onClick={props.onClick}>
              Confirm all
            </p>
            : null}
        </div>
        : null
      }
    </>
  );
};

export default ReviewFilter;
