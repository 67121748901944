import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import FormikSelect from "../../../components/UI/Formik/FormikSelect";

const FormikTest = () => {
  //const [selectedValueInitial, setSelectedValueInitial] = useState('');

  const [alertRecipient, setAlertRecipient] = useState({
    name: "",
    simNumber: "",
  });

  const selectOptions = [
    { label: "Email Sent", value: 1 },
    { label: "Email Opened", value: 2 },
    { label: "Link Clicked", value: 3 },
    { label: "Decline Response", value: 4 },
    { label: "Deferred Interest Response", value: 5 },
    { label: "Referral Response", value: 6 },
    { label: "Positive Response", value: 7 },
  ];

  const formikRef = useRef();

  const [alertId, setAlertId] = useState("");

  const [alertVehicles, setAlertVehicles] = useState([]);

  const [formValues, setFormValues] = useState({
    alertId: "",
    alertVehicles: [],
    alertRecipient: {
      name: "",
      simNumber: "",
    },
  });

  const loadData = () => {
    // const ar = { ...alertRecipient };
    // ar.name = "Igor";
    // ar.simNumber = "0123456789";

    // setAlertRecipient(ar);
    // setAlertVehicles([
    //     { id: 1, vrn: 'vehicle A', selected: true },
    //     { id: 2, vrn: 'vehicle B', selected: false },
    //     { id: 3, vrn: 'vehicle C', selected: true }
    // ]);

    // setAlertId(5);

    const loadedData = { ...formValues };
    loadedData.alertRecipient = { name: "Igor", simNumber: "0123456" };
    loadedData.alertId = 4;
    loadedData.alertVehicles = [
      { id: 1, vrn: "vehicle A", selected: true },
      { id: 2, vrn: "vehicle B", selected: false },
      { id: 3, vrn: "vehicle C", selected: true },
    ];

    setFormValues(loadedData);

    let test = formikRef.current.values;
    //formikRef.current.setValues({ alertRecipient, alertId, alertVehicles });
    //formikRef.current.resetForm({ alertRecipient, alertId, alertVehicles });
    //formikRef.current.values = { alertRecipient, alertId, alertVehicles };
    let a = 5;
  };

  useEffect(() => {
    let test = formikRef.current.values;
    let b = 5;
  }, [alertId]);

  return (
    <div>
      <Button id="initialValue" type="button" onClick={loadData}>
        Change initial values
      </Button>
      <pre>State alertId: {alertId}</pre>
      {/* <Formik
                initialValues={{ alertRecipient, alertId, alertVehicles }}
                innerRef={formikRef}
                enableReinitialize={true}
                onSubmit={values => {
                    window.alert(JSON.stringify(values));
                    window.alert('State: ' + alertId)
                }
                }
                render={({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Label>AlertId</Form.Label>
                        <FormikSelect options={selectOptions} name="selectTest" value={values.alertId}
                        />
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="alertRecipient.name"
                            value={values.alertRecipient.name}
                            onChange={handleChange}
                        />
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                            type="text"
                            name="alertRecipient.simNumber"
                            value={values.alertRecipient.simNumber}
                            onChange={handleChange}
                        >
                        </Form.Control>
                        <Form.Label>Vehicles</Form.Label>
                        {
                            //get an error if we just use alertVehicles.length here??
                            values.alertVehicles.length === 0 ? null : alertVehicles.map((veh, index) => (

                                <Form.Check type="checkbox"
                                    key={veh.id}
                                    label={veh.vrn}
                                    name={`alertVehicles[${index}].selected`}
                                    checked={values.alertVehicles[index].selected}
                                    onChange={handleChange}
                                />
                            ))
                        }
                        <Button type="submit">Save</Button>
                    </Form>
                )
                }
            /> */}

      <Formik
        initialValues={formValues}
        innerRef={formikRef}
        enableReinitialize={true}
        onSubmit={(values) => {
          window.alert(JSON.stringify(values));
          window.alert("State: " + alertId);
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, setFieldValue, handleChange } = formikProps;

          return (
            <>
              <Form.Label>AlertId</Form.Label>
              <FormikSelect
                options={selectOptions}
                name="selectTest"
                value={values.alertId}
                enableReinitialize
                hideNoOptions
                isAutoComplete
                isSearchable
                requireInputToShowOptions
              />
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="alertRecipient.name" value={values.alertRecipient.name} onChange={handleChange} />
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="text"
                name="alertRecipient.simNumber"
                value={values.alertRecipient.simNumber}
                onChange={handleChange}
              ></Form.Control>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormikTest;
