export const columns = [
  {
    label: "Template Name",
    field: "name",
    type: "text",
    sort: "asc",
    minWidth: 250,
    maxWidth: 250,
    flex: 1,
    minimal: "lg",
    pinned: "left",
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
    minimal: "sm",
    pinned: "left",
    minWidth: 120,
    flex: 1,
    maxWidth: 120,
  },
  {
    label: "Stage",
    field: "type",
    type: "text",
    minWidth: 120,
    flex: 1,
    sort: "asc",
    minimal: "sm",
  },
  {
    label: "Open Rate",
    field: "openRate",
    type: "number",
    minWidth: 120,
    flex: 1,
    sort: "asc",
    columnClass: "text-right",
    minimal: "sm",
  },
  {
    label: "Click Rate",
    field: "clickRate",
    type: "number",
    minWidth: 120,
    flex: 1,
    sort: "asc",
    columnClass: "text-right",
    minimal: "sm",
  },
  {
    label: "Lead Rate",
    field: "leadRate",
    type: "number",
    minWidth: 120,
    flex: 1,
    sort: "asc",
    columnClass: "text-right",
    minimal: "sm",
  },
  {
    label: "Total Sent",
    field: "emailsDelivered",
    type: "number",
    minWidth: 120,
    flex: 1,
    sort: "asc",
    columnClass: "text-right",
    minimal: "sm",
  },
  {
    label: "Last Used",
    field: "lastUsed",
    type: "date",
    minWidth: 120,
    flex: 1,
    sort: "asc",
    columnClass: "text-right",
    minimal: "sm",
  },
  {
    label: "Last Modified",
    field: "dateModified",
    type: "date",
    minWidth: 200,
    flex: 1,
    sort: "asc",
    columnClass: "text-right",
    minimal: "sm",
  },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    pinned: "right",
    width: 95,
    minWidth: 95,
    minimal: "lg",
  },
];
