import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";

//Hooks
import useSignalRHook from "@hooks/useSignalRHook";
import { useGetEmailAccountsListQuery, useCreateThirdPartyMutation } from "@api/emailAccountApi";
import { Editor } from "@tinymce/tinymce-react";
// import { Form } from "react-bootstrap";

//Helpers
import api, { API } from "../../../../../helper/api/api";
import { getShortenedFullName } from "@pages/Assistant/AssistantHelperMethods";
import { SIGNALR_CONSTANTS } from "@helper/signalrConstants";
import { handleImageUpload } from "@helper/TinyMce/imageUploadHelper";

//Styles
import styles from "./ConnectAccountStep.module.scss";

//Components
import Spinner from "@ui/Spinner/Spinner";
import SelectMulti from "@ui/Select/SelectMulti";
import FormikInput from "@ui/Formik/FormikInput";
import Button from "@ui/Button/Button";
import Checkbox from "@ui/Checkbox/Checkbox";

//Icons
import gmailIcon from "@assets/Icons/GmaiAPIIcon/gmail.svg";
import outlookIcon from "@assets/Icons/MicrosoftAPIIcon/ms-symbollockup_mssymbol_19.png";
import SmtpIcon from "@icons/SmtpIcon/SmtpIcon";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import { toast } from "react-toastify";
import { errorResponseToList } from "@helper/errorHelper";

const ConnectAccountStep = ({
  title = "Connect your sender account",
  description = "Now we just need to connect the email account where your prospecting emails will be sent from. To do this, select from your existing email accounts or connect a new account.",
  accountType,
  campaignObject,
  setCampaignObject,
  slug,
  user,
}) => {
  const { data: emailAccountsList, error, isLoading } = useGetEmailAccountsListQuery(1);

  const [showSignature, setShowSignature] = useState(false);
  const [createEmailAccount] = useCreateThirdPartyMutation();
  const [connectedAccount, setConnectedAccount] = useState("");

  const defaultText = `<p style="font-size: 14px;">
      </p> <p style="font-size: 14px;">${user.firstName} ${user.lastName},</p> 
      <p style="font-size: 14px;">${user.organization.name} | ${connectedAccount ?? ""}</p> 

      <p style="font-size: 12px;">If you do not wish to receive any more emails please follow the <a href="{{UNSUBSCRIBE_URL}}">link</a>.
    </p>`;

  const [content, setContent] = useState(defaultText);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    setCampaignObject({ ...campaignObject, signature: content });
  }, [content]);

  let icon = null;
  switch (accountType) {
    case "Gmail":
      icon = gmailIcon;
      break;
    case "Outlook" || "Microsoft":
      icon = outlookIcon;
      break;
    default:
      icon = SmtpIcon;
      break;
  }

  const init = {
    branding: false,
    toolbar_mode: "sliding",
    height: "calc(100vh - 425px)",
    menubar: false,
    link_assume_external_targets: true,
    block_unsupported_drop: true,
    automatic_uploads: true,
    paste_data_images: true,
    images_reuse_filename: true,
    images_upload_handler: handleImageUpload,
    images_file_types: "jpg,jpeg,gif,png,jfif",
    formats: {
      h1: { block: "h1", styles: { "line-height": "1.5" } },
      h2: { block: "h2", styles: { "line-height": "1.5" } },
      h3: { block: "h3", styles: { "line-height": "1.5" } },
      h4: { block: "h4", styles: { "line-height": "1.5" } },
      h5: { block: "h5", styles: { "line-height": "1.5" } },
      h6: { block: "h6", styles: { "line-height": "1.5" } },
    },
    plugins:
      "editimage importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link table charmap nonbreaking anchor insertdatetime advlist lists wordcount charmap emoticons",
    toolbar:
      "bold italic underline strikethrough forecolor backcolor removeformat insertfile image | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify outdent indent | numlist bullist | charmap emoticons | link anchor code | fullscreen print | undo redo",
    content_style: "body { font-family:Arial,sans-serif; }",
    encoding: "html",
    preformatted: false,
    entity_encoding: "raw",
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  useSignalRHook(SIGNALR_CONSTANTS.ASSISTANT_EMAIL_ACCOUNT_CONNECTED, (response) => {
    if (response.emailAccountName != null) {
      setCampaignObject({ ...campaignObject, emailAccount: response.emailAccountName, emailAccountId: response.emailAccountId });
      setConnectedAccount(response.emailAccountName);
      setShowSignature(true);
    }
    setIsPending(false);
  });

  const handleCreateEmailAccount = (val) => {
    setIsPending(true);
    let model = {
      messagesPerDay: 500,
      averageDelay: 180,
      // emailAccountType: ["Microsoft", "Outlook"].includes(accountType) ? "Microsoft" : accountType,
      emailAccountType: val,
      assistantCallback: true,
    };
    createEmailAccount(model)
      .unwrap()
      .then((response) => {
        window.open(response.redirectUrl, "_blank", "height=650,width=550");
      })
      .catch((rejected) => {
        console.error(rejected);
      });
  };

  let buttonType = null;

  switch (accountType) {
    case "Gmail": {
      buttonType = (
        <button
          id="gmailButton"
          className={`${styles.selectButton} ${styles.googleButton}`}
          onClick={() => handleCreateEmailAccount("Gmail")}
        >
          <span className={styles.icon}></span>
          <span className={styles.buttonText}>Sign in with Google</span>
        </button>
      );
      break;
    }
    case "Outlook" || "Microsoft": {
      buttonType = (
        <button
          id="microsoftButton"
          className={`${styles.selectButton} ${styles.microsoftButton}`}
          onClick={() => handleCreateEmailAccount("Microsoft")}
        >
          <span className={styles.icon}></span>
          <span className={styles.buttonText}>Sign in with Microsoft</span>
        </button>
      );
      break;
    }
    default:
      buttonType = (
        <div className="flex_column">
          <button
            id="gmailButton"
            className={`${styles.selectButton} ${styles.googleButton}`}
            onClick={() => handleCreateEmailAccount("Gmail")}
          >
            <span className={styles.icon}></span>
            <span className={styles.buttonText}>Sign in with Google</span>
          </button>
          <button
            id="microsoftButton"
            className={`${styles.selectButton} ${styles.microsoftButton}`}
            onClick={() => handleCreateEmailAccount("Microsoft")}
          >
            <span className={styles.icon}></span>
            <span className={styles.buttonText}>Sign in with Microsoft</span>
          </button>
        </div>
      );
  }

  const form = !isLoading ? (
    <Formik enableReinitialize={true} initialValues={{ emailAccounts: [] }}>
      {(formikProps) => {
        const { values } = formikProps;
        const filteredOptions = emailAccountsList
          .filter((x) => x.isConnected || x.usedInCampaign)
          .map((account) => ({
            value: account.emailAccountId.toString(),
            label: (
              <div className={styles.accountDetails}>
                <p className={styles.ownerEmail}>
                  <b>{account.emailName}</b> / {getShortenedFullName(account.userFullName)}
                </p>
                <p className={styles.ownerEmail}>
                  {account.email}{" "}
                  {account.usedInOtherCampaigns ? <span className={styles.inAnotherCampaign}> used In another campaign</span> : null}
                </p>
              </div>
            ),
            labelShow: account.email,
            checkBoxClass: styles.listCheckbox,
            isDisabled: !account.isConnected && !account.usedInCampaign,
          }));
        return (
          <Form className="width_100 flex_column align-center ">
            <SelectMulti
              options={filteredOptions}
              onChange={(values) => {
                formikProps.setFieldValue("emailAccounts", values);
                setCampaignObject({ ...campaignObject, emailAccounts: [...values] });
              }}
              dropdownClass={`mB15 ${styles.accountDropdown}`}
              value={values.emailAccounts}
              placeholder="Select email accounts"
              displayName="Select email accounts"
              isMulti={true}
              isSearchable={true}
              id="addAgentAi"
            />
            <div className="flex_column ">
              <span className={styles.line}>or</span>
              {buttonType}
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Spinner />
  );

  let signatureStep = (
    <Formik
      enableReinitialize={true}
      initialValues={{
        privacyPolicyURL: "",
        enablePrivacyPolicy: true,
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        return (
          <Form className="mB10">
            <div className={styles.mainWrapper}>
              <h1 className="textCenter f16 font700 mB15">Connect your sender account</h1>
              <p className={`f14 secondary_text_color textCenter mB30 ${styles.description}`}>
                Now we just need to connect the email account where your prospecting emails will be sent from. To do this, simply click the
                button below to sign in to your email account.
              </p>
              <p className={styles.label}>Connected Account: </p>
              {/* <div className={styles.connectedAccountWrapper}>
                {icon && <img src={icon} alt="Connected Account Icon" />} {connectedAccount}
              </div> */}
              <p className={styles.label}>Signature</p>
              <Checkbox
                label="Add a compliance statement with a link to your privacy policy"
                checked={values.enablePrivacyPolicy}
                checkBoxClass="mB15"
              />
              <div className="flex align-center">
                <FormikInput
                  label="Privacy policy URL:"
                  name="privacyPolicyURL"
                  type="text"
                  value={values.privacyPolicyURL}
                  onChange={(e) => {
                    setFieldValue("privacyPolicyURL", e.target.value);
                  }}
                  inputclass={`${styles.urlInput}`}
                  inputGroupClass="mR15"
                />
                <Button
                  id="addPrivacyPolicy"
                  classes={styles.addButton}
                  variant="secondary"
                  type="button"
                  disabled={!values.privacyPolicyURL}
                  onClick={() => {
                    setContent(
                      content +
                        `<p style="font-size: 12px;">You can find our privacy policy <a href="${values.privacyPolicyURL}">here</a>.</p>`
                    );
                    setFieldValue("privacyPolicyURL", "");
                  }}
                >
                  <AddCircleLineIcon size={16} color="var(--secondary-color)" className="mR5" /> Add
                </Button>
              </div>
              <Editor
                apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
                init={init}
                initialValue={defaultText}
                value={content}
                onEditorChange={handleEditorChange}
                className="mB10"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  return showSignature ? (
    <div>{signatureStep}</div>
  ) : (
    <div className={`flex_center flexCol ${styles.connectAccount}`}>
      <h1 className="f16 font700 mB15">{title}</h1>
      <p className="mB30 f14 secondary_text_color textCenter">{description}</p>
      {!slug ? form : buttonType}
    </div>
  );
};

export default ConnectAccountStep;
