import { useFormikContext } from "formik";
import moment from "moment";

// Styles
import styles from "../Filters.module.scss";

// Helpers
import { dateToUTC } from "@helper/dateHelper";

// Components
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import FormikDatePicker from "@ui/Formik/FormikDatePicker";

const FilterCompanyFoundedYear = () => {
  const {
    values: {
      company: {
        companyFoundedYear: { dateFrom: companyFoundedYearDateFrom, dateTo: companyFoundedYearDateTo },
      },
    },
  } = useFormikContext<SearchModel>();

  return (
    <>
      <div className="flex">
        <FormikDatePicker
          errorClass={styles.error}
          formgroupclass="m0"
          datePickerClass={styles.formInput}
          placeholderText="From"
          name="company.companyFoundedYear.dateFrom"
          selected={companyFoundedYearDateFrom ? dateToUTC(companyFoundedYearDateFrom) : companyFoundedYearDateFrom}
          value={companyFoundedYearDateFrom ? dateToUTC(companyFoundedYearDateFrom) : companyFoundedYearDateFrom}
          dateFormat="yyyy"
          showYearPicker={true}
          maxDate={moment().toDate()}
        />
        <p className="mL5 mR5 mT5">
          <b>-</b>
        </p>
        <FormikDatePicker
          errorClass={styles.error}
          formgroupclass="m0"
          datePickerClass={styles.formInput}
          placeholderText="To"
          name="company.companyFoundedYear.dateTo"
          selected={companyFoundedYearDateTo ? dateToUTC(companyFoundedYearDateTo) : companyFoundedYearDateTo}
          value={companyFoundedYearDateTo ? dateToUTC(companyFoundedYearDateTo) : companyFoundedYearDateTo}
          dateFormat="yyyy"
          showYearPicker={true}
          maxDate={moment().toDate()}
        />
      </div>
    </>
  );
};

export default FilterCompanyFoundedYear;
