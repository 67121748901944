import React from "react";

//Helpers
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";

//Styles
import styles from "./PerfomanceBanner.module.scss";

//Images & Icons
import PerformanceEmailsSent from "../../../assets/Icons/PerformanceEmailsSent/PerformanceEmailsSent";
import PerformanceEmailsOpen from "../../../assets/Icons/PerformanceEmailsOpen/PerformanceEmailsOpen";
import PerformanceClick from "../../../assets/Icons/PerformanceClick/PerformanceClick";
import Prospects from "../../../assets/Icons/Prospects/Prospects";

//Components
import SingleCard from "../SingleCard/SingleCard";
import GroupCard from "../GroupCards/GroupCard";

const PerfomanceBanner = (props) => {
  let separators = useGroupDecimalSeparator();
  let engagedFormatted = formatNumberHelper(
    props.organizationReport.engaged,
    separators.numberGroupSeperator,
    separators.numberDecimalSeperator
  );
  let emailsDeliveredFormatted = formatNumberHelper(
    props.organizationReport.emailsDelivered,
    separators.numberGroupSeperator,
    separators.numberDecimalSeperator
  );

  return (
    <div className={`${styles.cardsWrapper} flex`}>
      <SingleCard
        trendValue={props.organizationReport?.reportTrendStats?.prospectEngaged}
        value={engagedFormatted}
        name={"Engagements"}
        icon={<Prospects />}
        data={props.organizationReport?.reportChartModel?.prospectsEngaged}
      />
      <SingleCard
        trendValue={props.organizationReport?.reportTrendStats?.emailsSent}
        value={emailsDeliveredFormatted}
        name={"Emails Delivered"}
        icon={<PerformanceEmailsSent />}
        data={props.organizationReport?.reportChartModel?.emailsSent}
      />
      <SingleCard
        trendValue={props.organizationReport?.reportTrendStats?.openRate}
        value={props.organizationReport.openRate.toFixed(2) + "%"}
        name={"Open Rate"}
        icon={<PerformanceEmailsOpen />}
        data={props.organizationReport?.reportChartModel?.openRate}
      />
      <SingleCard
        trendValue={props.organizationReport?.reportTrendStats?.clickRate}
        value={props.organizationReport.clickRate.toFixed(2) + "%"}
        name={"Click Rate"}
        icon={<PerformanceClick />}
        data={props.organizationReport?.reportChartModel?.clickRate}
      />
      <GroupCard
        trendValue={props.organizationReport?.reportTrendStats?.responseRate}
        name={"Responses"}
        rateName={"Response"}
        total={props.organizationReport.responders}
        rate={props.organizationReport.responseRate.toFixed(2) + "%"}
        variant="gray"
      />
      <GroupCard
        name={"Leads"}
        rateName={"Lead"}
        trendValue={props.organizationReport?.reportTrendStats?.leadsRate}
        total={props.organizationReport.leads}
        rate={props.organizationReport.leadRate.toFixed(2) + "%"}
        variant="gray"
      />
    </div>
  );
};

export default PerfomanceBanner;
