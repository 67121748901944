import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, Formik } from "formik";
import _ from "lodash";
import { Editor } from "@tinymce/tinymce-react";
import { Accordion, Card } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Cell } from "styled-css-grid";
import { Button } from "react-bootstrap";
import { FieldArray } from "formik";
import * as Yup from "yup";

//Redux
import { addDealNavigatorElements, setLastAddedCardValue } from "../../../../store/DealNavigator/actions/dealNavigator";
import { fetchSalesDocuments } from "../../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { DealNavigatorElementType } from "../../../../helper/enums/dealNavigatorElementType";
import { createImageUrl } from "../../../../helper/fileHelper";
import { handleImageUpload } from "@helper/TinyMce/imageUploadHelper";

//Styles
import style from "../Elements/ElementsPreview.module.scss";
import styles from "../Elements/Preview.module.scss";

//Images & Icons
import H1Icon from "remixicon-react/H1Icon";
import ParagraphIcon from "remixicon-react/ParagraphIcon";
import DoubleQuotesRIcon from "remixicon-react/DoubleQuotesRIcon";
import Attachment2Icon from "remixicon-react/Attachment2Icon";
import Image2FillIcon from "remixicon-react/Image2FillIcon";
import LiveLineIcon from "remixicon-react/LiveLineIcon";
import ArrowDropDownLineIcon from "remixicon-react/ArrowDropDownLineIcon";
import FileLineIcon from "remixicon-react/FileLineIcon";
import Dropdown from "../../../../assets/Icons/DealNavigator/Dropdown";

//Components
import Modal from "./AddOrEditCardModal";
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import ImageUploader from "../../../../components/UI/FileUploader/ImageUploader";
import DocumentUploader from "../../../../components/UI/FileUploader/DocumentUploader";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const EditCard = (props) => {
  let schema = Yup.object({
    elements: Yup.array()
      .of(
        Yup.object({
          id: Yup.number() | null,
          content: Yup.string() | null,
          header: Yup.string() | null,
          type: Yup.number(),
          cardId: Yup.number(),
          document: Yup.string() | null,
          existingDocumentId: Yup.number() | null,
        })
      )
      .required("You need to have at least one element in the card to save it."),
  });

  const { documents } = props.salesDocuments;
  const [chooseDocument, setChooseDocument] = useState({});

  const handleChooseDocument = (name, index) => {
    const newChooseDocument = { ...chooseDocument };
    newChooseDocument[index] = name;
    setChooseDocument(newChooseDocument);
  };

  const [chooseImage, setChooseImage] = useState({});

  const handleChooseImage = (name, index, imageId) => {
    const newChooseImage = { ...chooseImage };
    newChooseImage[index] = name;
    setChooseImage(newChooseImage);
  };

  const [newElement, setNewElement] = useState("");

  let listOfDocuments = [];
  let listOfImages = [];

  listOfDocuments = documents.filter((document) => document.type === 1);
  listOfImages = documents.filter((document) => document.type === 2);

  useEffect(() => {
    props.fetchSalesDocuments();
  }, []);

  //#region Editor
  const init = {
    branding: false,
    toolbar_mode: "sliding",
    menubar: "urls",
    link_assume_external_targets: true,
    block_unsupported_drop: true,
    automatic_uploads: true,
    paste_data_images: true,
    images_reuse_filename: true,
    images_upload_handler: handleImageUpload,
    images_file_types: "jpg,jpeg,gif,png,jfif",
    plugins:
      "editimage importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link table charmap nonbreaking anchor insertdatetime advlist lists wordcount charmap emoticons",
    toolbar:
      "bold italic underline strikethrough forecolor backcolor removeformat insertfile image | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify outdent indent | numlist bullist | charmap emoticons | link anchor code | fullscreen print | undo redo",
    encoding: "html",
    preformatted: false,
    entity_encoding: "raw",
  };
  //#endregion

  const scrollToLastElementRef = useRef(null);

  useEffect(() => {
    if (scrollToLastElementRef && scrollToLastElementRef.current) scrollToLastElementRef.current.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <>
      <Formik
        initialValues={{
          elements: props.elements !== undefined || props.elements.length > 0 ? props.elements : [],
        }}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          const formData = new FormData();
          formData.append("cardId", props.cardId);

          for (let i = 0; i < values.elements.length; i++) {
            formData.append(`elements[${i}].id`, values.elements[i].id | 0);
            formData.append(`elements[${i}].type`, values.elements[i].type);
            formData.append(`elements[${i}].cardId`, values.elements[i].cardId);
            formData.append(`elements[${i}].content`, values.elements[i].content);
            if (values.elements[i].type === DealNavigatorElementType.FAQ) {
              formData.append(`elements[${i}].header`, values.elements[i].header);
            }
            if (
              values.elements[i].type === DealNavigatorElementType.Image ||
              values.elements[i].type === DealNavigatorElementType.Document
            ) {
              formData.append(`elements[${i}].document`, values.elements[i].document);
              formData.append(`elements[${i}].existingDocumentId`, values.elements[i].existingDocumentId | 0);
            }
          }

          props.addDealNavigatorElements(formData);
          props.submitCard();
          props.setLastAddedCardValue();
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => {
          const { values, handleSubmit, isSubmitting, setFieldValue, errors } = formikProps;

          return (
            <>
              <div id="spinner" className={style.spinner + " " + style.hidden}>
                <Spinner />
              </div>
              <Modal
                handleClose={props.handleClose}
                handleSave={handleSubmit}
                show={props.showModal}
                closeButtonText="Cancel"
                saveButtonText="Save"
                saveButtonDisabled={isSubmitting}
                btnType="submit"
              >
                <fieldset>
                  <FieldArray
                    name="elements"
                    render={(arrayHelpers) => (
                      <>
                        <div className="flex">
                          <div className={style.addedElementsWrapper}>
                            <div className={style.elementsAddedWrapper}>
                              <Row className={`${style.scrollBody} p15`}>
                                <Col className={` ${style.contentBody} p0`}>
                                  {values.elements.map((element, index) => {
                                    const cardId = element.cardId;
                                    const id = element.id | 0;
                                    if (index === arrayHelpers.length - 1) {
                                    }

                                    return (
                                      <Fragment key={index}>
                                        <Container ref={scrollToLastElementRef}>
                                          <Row className={styles.formWrapper}>
                                            <Button
                                              id="deleteButton"
                                              className={`${styles.deleteButton}`}
                                              size="sm"
                                              onClick={() => arrayHelpers.remove(index)}
                                            >
                                              <i className="fa fa-trash" />
                                            </Button>
                                            <Col className={styles.PreviewInputsWrapper} lg={12}>
                                              <Field value={id} type="hidden" className={styles.formField} />
                                              {element.type === DealNavigatorElementType.Title ? (
                                                <>
                                                  <FormikInput
                                                    name={`elements[${index}].content`}
                                                    type="text"
                                                    label="Title"
                                                    value={element.content}
                                                  />
                                                  <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                  <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                </>
                                              ) : null}
                                              {element.type === DealNavigatorElementType.Quote ? (
                                                <>
                                                  <FormikInput
                                                    name={`elements[${index}].content`}
                                                    type="text"
                                                    label="Quote"
                                                    value={element.content}
                                                    as="textarea"
                                                    rows="4"
                                                  />
                                                  <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                  <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                </>
                                              ) : null}
                                              {element.type === DealNavigatorElementType.Paragraph ? (
                                                <>
                                                  <FormikInput
                                                    as={Editor}
                                                    apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
                                                    name={`elements[${index}].content`}
                                                    className="tox-notifications-container"
                                                    value={element.content}
                                                    label="Paragraph"
                                                    type="textarea"
                                                    rows="8"
                                                    init={init}
                                                    onEditorChange={(value) => setFieldValue(`elements[${index}].content`, value)}
                                                  />
                                                  <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                  <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                </>
                                              ) : null}
                                              {element.type === DealNavigatorElementType.FAQ ? (
                                                <>
                                                  <FormikInput
                                                    name={`elements[${index}].header`}
                                                    type="text"
                                                    label="Title"
                                                    placeholder="Enter Title"
                                                    value={element.header}
                                                  />
                                                  <FormikInput
                                                    as={Editor}
                                                    apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
                                                    name={`elements[${index}].content`}
                                                    className="tox-notifications-container"
                                                    value={element.content}
                                                    label="Description"
                                                    type="textarea"
                                                    rows="8"
                                                    init={init}
                                                    onEditorChange={(value) => setFieldValue(`elements[${index}].content`, value)}
                                                  />
                                                  <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                  <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                </>
                                              ) : null}
                                              {element.type === DealNavigatorElementType.Video ? (
                                                <>
                                                  <FormikInput
                                                    name={`elements[${index}].content`}
                                                    type="url"
                                                    label="Video"
                                                    placeholder="Enter embedded video url"
                                                    value={element.content}
                                                  />
                                                  <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                  <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                </>
                                              ) : null}
                                              {element.type === DealNavigatorElementType.Image ? (
                                                <>
                                                  <label className={styles.labelTitle}>Attach image from</label>
                                                  {!_.isEmpty(listOfImages) ? (
                                                    <>
                                                      <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                      <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                      <div className={styles.attachFiles}>
                                                        <ImageUploader
                                                          buttonText="Your computer"
                                                          variant="primary"
                                                          handleFile={(file) => {
                                                            setFieldValue(`elements[${index}].document`, file);
                                                          }}
                                                        />
                                                        <p> OR </p>
                                                        <Accordion className={styles.addElementsAccrodion} defaultActiveKey="0">
                                                          <Accordion.Item className="rounded" eventKey="0">
                                                            <Accordion.Header className={styles.addElementsHeader}>
                                                              <span>Choose from resources</span>
                                                            </Accordion.Header>
                                                            <Accordion.Body className={`${styles.addElementBody} ${styles.imageBody}`}>
                                                              {listOfImages.map((image) => (
                                                                <Cell
                                                                  className={
                                                                    values.elements[index]?.document?.name === image.name &&
                                                                    _.isUndefined(chooseImage[index])
                                                                      ? styles.active
                                                                      : chooseImage[index] === image.name
                                                                      ? styles.active
                                                                      : styles.imagesWrapper
                                                                  }
                                                                  key={image.id}
                                                                >
                                                                  <img
                                                                    src={createImageUrl(image)}
                                                                    width="50px"
                                                                    height="50px"
                                                                    onClick={() => {
                                                                      setFieldValue(`elements[${index}].existingDocumentId`, image.id);
                                                                      handleChooseImage(image.name, index, image.id);
                                                                    }}
                                                                    alt=""
                                                                  />
                                                                </Cell>
                                                              ))}
                                                            </Accordion.Body>
                                                          </Accordion.Item>
                                                        </Accordion>
                                                      </div>
                                                      {!_.isUndefined(values.elements[index]?.document) &&
                                                      _.isUndefined(chooseImage[index]) ? (
                                                        <span className={styles.attachFileDetails}>
                                                          File Name: {values.elements[index]?.document?.name}
                                                        </span>
                                                      ) : chooseImage[index] != null ? (
                                                        <span className={styles.attachFileDetails}>File Name: {chooseImage[index]}</span>
                                                      ) : null}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <ImageUploader
                                                        buttonText="Your computer"
                                                        variant="primary"
                                                        handleFile={(file) => {
                                                          setFieldValue(`elements[${index}].document`, file);
                                                        }}
                                                      />
                                                      <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                      <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                      <span>No existings images for this organizations. Add your image!</span>
                                                      {!_.isUndefined(values.elements[index].document) ? (
                                                        <span className={styles.attachFileDetails}>
                                                          File Name: {values.elements[index].document?.name}
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </>
                                              ) : null}
                                              {element.type === DealNavigatorElementType.Document ? (
                                                <>
                                                  <label className={styles.labelTitle}>Attach file from</label>
                                                  {!_.isEmpty(listOfDocuments) ? (
                                                    <>
                                                      <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                      <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                      <div className={styles.attachFiles}>
                                                        <DocumentUploader
                                                          buttonText="Your computer"
                                                          variant="primary"
                                                          handleFile={(file) => {
                                                            setFieldValue(`elements[${index}].document`, file);
                                                          }}
                                                        />
                                                        <p> OR </p>
                                                        <Accordion className={styles.addElementsAccrodion}>
                                                          <Card className="rounded">
                                                            <Card.Header className={styles.addElementsHeader}>
                                                              <Accordion.Collapse
                                                                as={Button}
                                                                variant="link"
                                                                eventKey="0"
                                                                className={styles.buttonText}
                                                              >
                                                                <span>Choose from resources</span>
                                                                <ArrowDropDownLineIcon className="iconGray" />
                                                              </Accordion.Collapse>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="0">
                                                              <Card.Body className={`${styles.addElementBody} ${styles.documentBody}`}>
                                                                {listOfDocuments.map((document) => (
                                                                  <Cell className={styles.documentContent} key={document.id}>
                                                                    <FileLineIcon className="iconGray" size={18} />
                                                                    <span
                                                                      onClick={() => {
                                                                        setFieldValue(`elements[${index}].existingDocumentId`, document.id);
                                                                        handleChooseDocument(document.name, index);
                                                                      }}
                                                                    >
                                                                      {document.name.split(".")[0]}
                                                                    </span>
                                                                  </Cell>
                                                                ))}
                                                              </Card.Body>
                                                            </Accordion.Collapse>
                                                          </Card>
                                                        </Accordion>
                                                      </div>
                                                      {!_.isUndefined(values.elements[index].document) &&
                                                      _.isUndefined(chooseDocument[index]) ? (
                                                        <span className={styles.attachFileDetails}>
                                                          File Name: {values.elements[index]?.document?.name}
                                                        </span>
                                                      ) : chooseDocument[index] != null ? (
                                                        <span className={styles.attachFileDetails}>File Name: {chooseDocument[index]}</span>
                                                      ) : chooseDocument[index] != null ? (
                                                        <span className={styles.attachFileDetails}>File Name: {chooseDocument[index]}</span>
                                                      ) : null}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <DocumentUploader
                                                        buttonText="Your computer"
                                                        variant="primary"
                                                        handleFile={(file) => {
                                                          setFieldValue(`elements[${index}].document`, file);
                                                        }}
                                                      />
                                                      <Field value={element.type} type="hidden" name={`elements[${index}].type`} />
                                                      <Field value={cardId} type="hidden" name={`elements[${index}].cardId`} />
                                                      <span>No existings documents for this organizations. Add your document!</span>
                                                      {!_.isUndefined(values.elements[index].document) ? (
                                                        <span className={styles.attachFileDetails}>
                                                          File Name: {values.elements[index].document?.name}
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </>
                                              ) : null}
                                            </Col>
                                          </Row>
                                        </Container>
                                      </Fragment>
                                    );
                                  })}
                                  {errors.elements != null ? <p className={style.errorWrapMsg}>{errors.elements}</p> : null}
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className={style.addModalContainer}>
                            <Row className={style.buttonsWrapper}>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addTitle"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      type: DealNavigatorElementType.Title,
                                      cardId: props.cardId,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <H1Icon className="iconGray" size={22} />
                                  <span>Title</span>
                                </Button>
                              </div>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addAttachment"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      type: DealNavigatorElementType.Document,
                                      cardId: props.cardId,
                                      existingDocumentId: 0,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <Attachment2Icon className="iconGray" size={22} />
                                  <span>Attachment</span>
                                </Button>
                              </div>
                            </Row>
                            <Row className={style.buttonsWrapper}>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addParagraph"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      type: DealNavigatorElementType.Paragraph,
                                      cardId: props.cardId,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <ParagraphIcon className="iconGray" size={22} />
                                  <span>Paragraph</span>
                                </Button>
                              </div>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addImage"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      type: DealNavigatorElementType.Image,
                                      cardId: props.cardId,
                                      existingDocumentId: 0,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <Image2FillIcon className="iconGray" size={22} />
                                  <span>Image</span>
                                </Button>
                              </div>
                            </Row>
                            <Row className={style.buttonsWrapper}>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addQuote"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      type: DealNavigatorElementType.Quote,
                                      cardId: props.cardId,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <DoubleQuotesRIcon className="iconGray" size={22} />
                                  <span>Quote</span>
                                </Button>
                              </div>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addVideo"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      type: DealNavigatorElementType.Video,
                                      cardId: props.cardId,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <LiveLineIcon className="iconGray" size={22} />
                                  <span>Video</span>
                                </Button>
                              </div>
                            </Row>
                            <Row className={style.buttonsWrapper}>
                              <div className={`${style.addElementWrapper} p0`}>
                                <Button
                                  id="addFAQ"
                                  onClick={(e) => (
                                    e.preventDefault,
                                    arrayHelpers.push({
                                      content: "",
                                      header: "",
                                      type: DealNavigatorElementType.FAQ,
                                      cardId: props.cardId,
                                    }),
                                    setNewElement(Math.random)
                                  )}
                                  className={`${style.addElementBtn} flex`}
                                >
                                  <Dropdown imgStyle={style.HeadIcon} />
                                  <span>Dropdown</span>
                                </Button>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </>
                    )}
                  />
                </fieldset>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentElement: state.dealNavigator.element,
    salesDocuments: state.salesDocuments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDealNavigatorElements: (formData) => dispatch(addDealNavigatorElements(formData)),
    fetchSalesDocuments: () => dispatch(fetchSalesDocuments()),
    setLastAddedCardValue: () => dispatch(setLastAddedCardValue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCard);
