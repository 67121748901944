import { useGetJobTitlesQuery } from "@api/audienceExplorerApi";
import styles from "../Filters.module.scss";
import FormikSelect from "@ui/Formik/FormikSelect";
import { useState } from "react";
import { useFormikContext } from "formik";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterJobTitles = () => {
  const {
    values: {
      contact: { jobTitles },
    },
  } = useFormikContext<SearchModel>();
  const [searchInput, setSearchInput] = useState("");
  const { data: options } = useGetJobTitlesQuery(searchInput, { skip: searchInput.length < 3 });

  return (
    <FormikSelect
      showValuesOutside
      name="contact.jobTitles"
      id="contactsJobTitles"
      options={options}
      value={jobTitles}
      displayName="Select job title"
      placeholder="e.g. Marketing Executive"
      onInputChange={(input: string) => setSearchInput(input)}
      dropdownButtonClass={styles.selectDropdown}
      customValuesFormName="jobTitlesCustomValues"
      enableReinitialize
      allowCustomValues
      allowIncludeExclude
      allowIncludeExcludeForCustomValues
      isAutoComplete
      isMulti
      isSearchable
      hideNoOptions
      requireInputToShowOptions
    />
  );
};

export default FilterJobTitles;
