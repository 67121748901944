import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "react-oidc-context";
import { Formik, FormikValues, FormikHelpers } from "formik";

// Components
import Modal from "../../components/UI/Modal/Modal";
import FormikCheckbox from "../../components/UI/Formik/FormikCheckbox";
import FormikInput from "../../components/UI/Formik/FormikInput";
//helpers
import api, { API } from "../../helper/api/api";
import { errorResponseToList } from "../../helper/errorHelper";
import { OrganizationDeleteReasonEnum } from "../../models/organization/DeleteReasonEnum";
import { useDeleteReasonsMutation } from "@api/organizationApi";

interface DeleteConfirmModalProps {
  userId: number;
  organizationId: number;
  handleClose: () => void;
  showModal: boolean;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = (props) => {
  const { userId, organizationId } = props;
  const [listReasons, setListReasons] = useState<OrganizationDeleteReasonEnum[]>([]);
  const [noButtonClicked, setNoButtonClicked] = useState(true);
  const [deleteReasons] = useDeleteReasonsMutation();
  const auth = useAuth();
  return (
    <Formik
      initialValues={{
        notWhatIWasLookingFor: false,
        noValueExpected: false,
        other: false,
        improve: "",
      }}
      enableReinitialize={true}
      onSubmit={(values: FormikValues, actions: FormikHelpers<FormikValues>) => {
        if (listReasons.length === 0) return;
        const data = {
          reasons: listReasons,
          improve: values.improve,
          organizationId: organizationId,
        };

        deleteReasons(data)
          .unwrap()
          .then((response) => {
            auth.signinSilent();
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => {
        const handleCheckBoxSelect = (val: boolean, reason: OrganizationDeleteReasonEnum) => {
          if (val) {
            setListReasons((prevState) => [...prevState, reason]);
          } else {
            setListReasons((listReasons) => listReasons.filter((item) => item !== reason));
          }
        };
        if (listReasons.length > 0) setNoButtonClicked(false);
        else if (listReasons.length === 0) setNoButtonClicked(true);

        return (
          <Modal
            dialogClassName={"customModal"}
            customBodyClass={"paymentModalBody"}
            title={"We are sorry to see you go"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            saveButtonText="Delete"
            btnType="submit"
            variant="danger"
            closeButtonText="Cancel"
            saveButtonDisabled={noButtonClicked || isSubmitting}
          >
            <div>
              <p>
                <strong>Before you leave, could you let us know a little more about your experience with Outbase?</strong> Any feedback
                would be greatly appreciated and help us to improve Outbase for future users.
              </p>
              <Container>
                <Row>
                  <Col xl={12} className="p-0">
                    <p className="mb-2">Reasons for leaving *</p>
                  </Col>
                  <Col xl={12}>
                    <FormikCheckbox
                      label="Outbase was not what I was looking for"
                      name="notWhatIWasLookingFor"
                      checked={values.notWhatIWasLookingFor}
                      onChange={(val: boolean) => handleCheckBoxSelect(val, OrganizationDeleteReasonEnum.NotWhatIWasLookingFor)}
                    />
                    <FormikCheckbox
                      label="I didn't find the value I expected"
                      name="noValueExpected"
                      checked={values.noValueExpected}
                      onChange={(val: boolean) => handleCheckBoxSelect(val, OrganizationDeleteReasonEnum.NoValueExpected)}
                    />
                    <FormikCheckbox
                      label="Other"
                      name="other"
                      checked={values.other}
                      onChange={(val: boolean) => handleCheckBoxSelect(val, OrganizationDeleteReasonEnum.Other)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} className="mT10 p-0">
                    <p>What should we improve?</p>
                    <FormikInput
                      label=""
                      name="improve"
                      type="textarea"
                      placeholder="Tell us more"
                      inputGroupClass="mb-2"
                      value={values.improve}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default DeleteConfirmModal;
