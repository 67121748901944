import React, { useEffect, useState } from "react";

//Helpers
import { britishEnglish } from "../../helper/languageChoiceAwesomenessCheckerConstants";
import { usEnglish } from "../../helper/languageChoiceAwesomenessCheckerConstants";

// Styles
import styles from "./EmailAwesomenessResult.module.scss";

// Components
import ProgressBar from "../../components/UI/ProgressBar/ProgressBar";
import Spinner from "../../components/UI/Spinner/Spinner";
import SectionTitle from "../../components/UI/SectionTitle/SectionTitle";
import FormikSelect from "../../components/UI/Formik/FormikSelect";
import AntSwitch from "../../components/UI/AntSwitch/Switch";

const EmailAwesomenessResult = (props) => {
  const result = props.result;

  const [antCheck, setCheck] = useState(true);

  useEffect(() => {
    if (props.languageChoice !== "") {
      if (props.languageChoice !== britishEnglish) setCheck(false);
    }
  }, []);

  var awesomenessPercentage = parseFloat(result.AwesomenessRating).toFixed(4);
  const getClassFromPercentage = (percentage) => {
    if (percentage >= 0 || percentage <= 50) return styles.bad;
    if (percentage >= 50.0001 || percentage <= 80) return styles.warning;
    if (percentage >= 80.0001 || percentage <= 100) return styles.great;
  };

  const getClassFromGrade = (grade) => {
    if (grade === 1) return styles.bad;
    if (grade === 2) return styles.warning;
    if (grade === 3) return styles.great;
  };

  var resultDiv = null;

  const emailAccounts = props.emailAccounts.map((email) => {
    return { label: email.name + " (" + email.email + ")", value: email.id };
  });
  const emailAccountOptions = [{ label: "None", value: 0 }, ...emailAccounts];

  if (result === "") {
    resultDiv = <Spinner />;
  } else {
    resultDiv = (
      <div id="awesomenessWrapper" className={`campaignSequenceAwesomenessResultsWrapper ${styles.awesomenessResultsWrapper}`}>
        <div className={styles.header}>
          <SectionTitle title="Check Wording" />
          {/* <span onClick={props.closeChecker}>X</span> */}
        </div>
        <>
          <p className="mB15">
            We recommend you to use an email account signature when checking the message content in order to get better results.
          </p>
          <div className="flex justify_end mB20">
            <p className={`${antCheck ? styles.notActive : ""} mB0 mR10`}>U. S. English</p>
            <AntSwitch
              defaultChecked={antCheck}
              inputProps={{ "aria-label": "Switch A" }}
              onChange={(value) => {
                if (!value.target.checked) {
                  props.setLanguageChoice(usEnglish);
                  setCheck(false);
                } else {
                  props.setLanguageChoice(britishEnglish);
                  setCheck(true);
                }
              }}
            />
            <p className={`${!antCheck ? styles.notActive : ""} mB0 mL10`}>British English</p>
          </div>
          <FormikSelect
            inputGroupClass={styles.selectTypeInput}
            formgroupClass={styles.typeFiled}
            showEmptyLabel={true}
            name={"checkAwesomenessEmailAccountId"}
            labelclass={styles.selectLabel}
            label="Email account:"
            placeholder={""}
            value={props.checkAwesomenessEmailAccountId}
            onChange={(event) => {
              props.setCheckAwesomenessEmailAccountId(event);
            }}
            options={emailAccountOptions}
          />
        </>
        <>
          <h1 className={`${getClassFromPercentage(awesomenessPercentage)} f18 textCenter`}>{result.AwesomenessRatingPercentage}</h1>
          <ProgressBar max={100} min={1} now={awesomenessPercentage} ariaLabel="Results progress" />
          <div className={getClassFromPercentage(awesomenessPercentage)}>
            <p className="f13 m0 textCenter">{result.Scores != null ? result.Scores.Rating.Text : ""}</p>
          </div>
        </>
        <div
          className={` ${styles.resultsList} ${props.resultsListClass ?? ""} ${
            props.fromCampaignSequence ? styles.campaignResultList : styles.mainPageList
          } `}
        >
          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.WordCount.Grade : "")} text-center`}>
              <h2>{result.WordCount}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Word count</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.WordCount.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.WordCount.Text : ""}
              </p>
              <p className={`${styles.info} f13 m0`}>
                {result.Scores != null
                  ? "We recommend having " +
                    result.Scores.WordCount.RecommendedMinimum +
                    "-" +
                    result.Scores.WordCount.RecommendedMaximum +
                    " words."
                  : ""}
              </p>
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.ParagraphCount.Grade : "")} text-center`}>
              <h2>{result.ParagraphCount}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Paragraphs</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.ParagraphCount.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.ParagraphCount.Text : ""}
              </p>
              <p className={`${styles.info} f13 m0`}>
                {result.Scores != null
                  ? "We recommend having " +
                    result.Scores.ParagraphCount.RecommendedMinimum +
                    "-" +
                    result.Scores.ParagraphCount.RecommendedMaximum +
                    " paragraphs."
                  : ""}
              </p>
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.WordsPerParagraph.Grade : "")} text-center`}>
              <h2>{parseFloat(result.WordsPerParagraph).toFixed(1)}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Words per Paragraphs</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.WordsPerParagraph.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.WordsPerParagraph.Text : ""}
              </p>
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.LettersPerWord.Grade : "")} text-center`}>
              <h2>{parseFloat(result.LettersPerWord).toFixed(1)}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Letters per word</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.LettersPerWord.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.LettersPerWord.Text : ""}
              </p>
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.SpellingErrors.Grade : "")} text-center`}>
              <h2>{result.SpellingErrors}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Spellcheck</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.SpellingErrors.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.SpellingErrors.Text : ""}
              </p>

              {result.SpellCheck != null
                ? result.SpellCheck.FlaggedTokens.map((value) => {
                    return (
                      <span className={styles.awesomenessSpans}>
                        {" "}
                        {value.Token} <span className={styles.spanBadges}>{value.Suggestions[0].suggestion}</span>
                      </span>
                    );
                  })
                : null}
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.DomainsLinked.Grade : "")} text-center`}>
              <h2>{result.LinkCount}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Number of unique domains linked</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.DomainsLinked.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.DomainsLinked.Text : ""}
              </p>
              <h6 className={`${styles.info} f13 m0`}>We recommend having 1-2 domain links.</h6>
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.SpamWords.Grade : "")} text-center`}>
              <h2>{result.SpamWordsCount}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Spam words</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.SpamWords.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.SpamWords.Text : ""}
              </p>
              <h6 className={`${styles.info} f13 mB5`}>We recommend having 0 spam words.</h6>

              {result.SpamWords != null
                ? Object.entries(result.SpamWords).map(([key, value]) => {
                    return (
                      <span className={styles.awesomenessSpans}>
                        {" "}
                        {key} : <span className={styles.spanBadges}>{value.toString()}</span>
                      </span>
                    );
                  })
                : null}
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.SpamCategories.Grade : "")} text-center`}>
              <h2>{result.SpamCategoriesCount}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Spam words categories</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.SpamCategories.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.SpamCategories.Text : ""}
              </p>
              <p className={`${styles.info} f13 m0`}>We recommend having 0 spam categories.</p>
            </div>
          </div>

          <div className={`${styles.resultRow} flex`}>
            <div className={`${getClassFromGrade(result.Scores != null ? result.Scores.DuplicateWords.Grade : "")} text-center`}>
              <h2>{result.DuplicateWordsCount}</h2>
            </div>
            <div>
              <p className={`${styles.section} f14 m0`}>Duplicate Words (3 or more occurrences)</p>
              <p className={`${getClassFromGrade(result.Scores != null ? result.Scores.DuplicateWords.Grade : "")} f13`}>
                {result.Scores != null ? result.Scores.DuplicateWords.Text : ""}
              </p>
              <p className={`${styles.info} f13 m0`}>We recommend having 0 duplicate words.</p>

              {result.DuplicateWords != null
                ? Object.entries(result.DuplicateWords).map(([key, value]) => {
                    if (isNaN(key)) {
                      return (
                        <span className={styles.awesomenessSpans} key={key}>
                          {" "}
                          {key} : <span className={styles.spanBadges}>{value.toString()}</span>
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return resultDiv;
};

export default EmailAwesomenessResult;
