import React from "react";
import { useField, useFormikContext } from "formik";

//Styles
import styles from "./FormikInput.module.scss";

//Components
import Checkbox from "../Checkbox/Checkbox";

const FormikCheckbox = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();

  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.

  const [field, meta] = useField({ ...props });
  let errorElement = null;
  let errorInput = null;

  if (meta.touched && meta.error) {
    errorElement = <div className={styles.errorCheckbox}>{meta.error}</div>;
    errorInput = styles.errorInput;
  }

  const changeHandler = (checked) => {
    setFieldValue(field.name, checked);

    if (props.onChange != null) {
      props.onChange(checked);
    }
  };

  return (
    <div className={`flex ${props.className ? props.className : ""}`}>
      <label className={`${props.labelstyle} ${"flex center_flex f12"} ${styles.checkboxLabel}`}>
        <Checkbox
          name={props.name}
          label={props.label}
          checked={props.checked}
          readOnly={props.readOnly}
          disabled={props.disabled}
          checkBoxClass={props.checkBoxClass ?? ""}
          onChange={(checked) => {
            changeHandler(checked);
          }}
          onClick={props.onClick}
          onBlur={field.onBlur}
        />
      </label>
      {errorElement}
    </div>
  );
};

export default FormikCheckbox;
