import React, { useEffect, useState, useRef } from "react";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import Permission from "../../../components/auth/Permission";
import { connect } from "react-redux";
import {
  searchRecords as searchDefferredIntrest,
  setFilter as filterDeferredInterest,
  numRecords as numRecordsDeferredInterest,
} from "../../../store/Deals/Table/actions/deferredInterestDeals";
import {
  searchRecords as searchReferrals,
  setFilter as filterReferrals,
  numRecords as numRecordsReferrals,
} from "../../../store/Deals/Table/actions/referralsDeals";
import {
  searchRecords as searchRecordsPositiveResponses,
  setFilter as filterPositiveResponses,
  numRecords as numRecordsPositiveResponses,
} from "../../../store/Deals/Table/actions/positiveResponsesDeals";
import { filterData, numberOfRecords } from "../../../helper/tableConstants";
import api, { API } from "../../../helper/api/api";
import { toast } from "react-toastify";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { subDays } from "date-fns";

// Components
import PositiveResponses from "./components/PositiveResponses";
import Referrals from "./components/Referrals";
import DeferredInterest from "./components/DeferredInterest";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Button from "../../../components/UI/Button/Button";

const Deals = (props) => {
  let records = numberOfRecords[1];
  const { filterDeferredInterest, filterPositiveResponses, filterReferrals } = props;
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [height, setHeight] = useState(0);
  const headerContainerRef = useRef();

  useEffect(() => {
    let topHeight = headerContainerRef.current.offsetHeight;
    setHeight(window.innerHeight - topHeight);
  }, []);

  useEffect(() => {
    records = records.value;
  }, [records]);

  useEffect(() => {
    if (dateRange.startDate != null && dateRange.endDate != null) {
      let obj = [
        {
          from: dateRange.startDate.toLocaleDateString(),
          to: dateRange.endDate.toLocaleDateString(),
        },
      ];

      filterData("Date", obj, filterDeferredInterest);
      filterData("Date", obj, filterPositiveResponses);
      filterData("Date", obj, filterReferrals);
    }
  }, [dateRange]);

  const handleSearchChange = (text) => {
    props.searchRecordsDeferredIntrest(text);
    props.searchRecordsRefferrals(text);
    props.searchRecordsPositiveResponses(text);
  };

  const exportAllCSV = () => {
    const exportParams = {
      ...props.paramsReferrals,
    };

    const newFilters = [...exportParams.filter];
    for (let i = 0; i < newFilters.length; i++) {
      if (newFilters[i].type === "Type") {
        //export all doesn't need Type
        newFilters.splice(i, 1);
      }
    }
    exportParams.filter = newFilters;
    const exportAllTotalCount = props.totalCountPositiveResponses + props.totalCountReferrals + props.totalCountDeferredInterest;
    exportParams.length = exportAllTotalCount;
    api(API.deals.export, exportParams, "blob")
      .then((res) => {
        fileDownload("ExportAllDeals.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const buttons = (
    <Permission has={PERMISSIONS.deals.export}>
      <Button
        id="exportAllDeals"
        variant="primary"
        onClick={exportAllCSV}
        disabled={props.totalCountPositiveResponses + props.totalCountReferrals + props.totalCountDeferredInterest === 0}
      >
        Export all
      </Button>
    </Permission>
  );

  const handleChangeEntries = (records) => {
    props.numRecordsPositiveResponses(records);
    props.numRecordsDeferredInterest(records);
    props.numRecordsReferrals(records);
  };

  let dateOptions = {
    onChange: (item) => {
      setDateRange(item);
    },
    showSelectionPreview: true,
    key: "selection",
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    editableDateInputs: false,
    startDate: new Date(subDays(Date.now(), 30)),
    endDate: new Date(Date.now()),
    inputRanges: [],
  };

  return (
    <Permission has={PERMISSIONS.deals.view}>
      <div className="contentHeight">
        <div ref={headerContainerRef}>
          <TableFiltersRow
            rightContent={buttons}
            // selects={selectRowOptions}
            changeNumRecords={(records) => handleChangeEntries(records)}
            dateOptions={dateOptions}
          />
        </div>
        <div style={{ display: "flex" }}>
          <PositiveResponses records={records} titleHeight={height} />
          <Referrals records={records} titleHeight={height} />
          <DeferredInterest records={records} titleHeight={height} />
        </div>
      </div>
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    listOfCampaigns: state.deals.listOfCampaigns,
    paramsPositiveResponses: state.positiveResponsesDeals.params,
    paramsDeferredInterest: state.deferredIntrestDeals.params,
    paramsReferrals: state.referralsDeals.params,
    totalCountDeferredInterest: state.deferredIntrestDeals.totalCount,
    totalCountReferrals: state.referralsDeals.totalCount,
    totalCountPositiveResponses: state.positiveResponsesDeals.totalCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRecordsDeferredIntrest: (search) => dispatch(searchDefferredIntrest(search)),
    searchRecordsRefferrals: (search) => dispatch(searchReferrals(search)),
    searchRecordsPositiveResponses: (search) => dispatch(searchRecordsPositiveResponses(search)),
    //fetchListOfCampaigns: () => dispatch(fetchListOfCampaigns()),
    filterPositiveResponses: (type, value) => dispatch(filterPositiveResponses(type, value)),
    filterDeferredInterest: (type, value) => dispatch(filterDeferredInterest(type, value)),
    filterReferrals: (type, value) => dispatch(filterReferrals(type, value)),
    numRecordsPositiveResponses: (obj) => dispatch(numRecordsPositiveResponses(obj)),
    numRecordsDeferredInterest: (obj) => dispatch(numRecordsDeferredInterest(obj)),
    numRecordsReferrals: (obj) => dispatch(numRecordsReferrals(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
