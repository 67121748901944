import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../../helper/api/api";
import { toastMessages } from "../../../helper/toastMessagesConstants";

//Styles
import styles from "./Users.module.scss";

//Components
import Modal from "../../../components/UI/Modal/Modal";

const DisableEnableUserAccessForm = (props) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const disableEnableAccess = () => {
    setSaveButtonDisabled(true);
    let data = { userId: props.userId, reason: "" };
    api(API.OUTBOSS.users.disableEnableUserAccess, data)
      .then((res) => {
        if (props.userAccessRemoved) {
          toast.success(toastMessages.outboss.user.successEnableAccess);
        } else {
          toast.success(toastMessages.outboss.user.successDisableAccess);
        }
        props.fetchUsers();
        props.handleClose();
        setSaveButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setSaveButtonDisabled(false);
      });
  };

  return (
    <Modal
      title={props.userAccessRemoved ? "Enable Access To Outbase" : "Disable Access To Outbase"}
      dialogClassName={styles.newExclusionsForm}
      handleClose={props.handleClose}
      handleSave={disableEnableAccess}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      saveButtonDisabled={saveButtonDisabled}
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>
              {props.userAccessRemoved
                ? "You will enable access to Outbase for this user. Do you want to continue? "
                : "You will disable the user’s access to Outbase. Do you want to continue? "}
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DisableEnableUserAccessForm;
