import * as actionTypes from "./dataTableActionTypes";
import update from "immutability-helper";
import _ from "lodash";
export const initialState = {
  isInitiated: false,
  isLoading: false,
  isLoaded: false,
  error: "",
  params: {
    start: 0,
    page: 0,
    length: 10,
    sort: {},
    filter: [],
    search: "",
  },
  rows: [],
  selectMultiRow: [],
  selectedRow: {},
  totalCount: 0,
  pageCount: 0,
};

const dataTableReducer =
  (section) =>
  (state = _.cloneDeep(initialState), action) => {
    switch (action.type) {
      case `${section}_${actionTypes.DT_INITIATE}`:
        return initiate(state, action);
      case `${section}_${actionTypes.DT_FETCH_REQUEST}`:
        return fetchRequest(state, action);
      case `RESPONDER_INBOX_${actionTypes.DT_FETCH_SUCCEEDED}`:
        return fetchSuccessResponderInbox(state, action);
      case `RESPONDER_INBOX_COUNT_CHANGE`:
        return responderInboxCountChange(state, action);
      case `${section}_${actionTypes.DT_FETCH_SUCCEEDED}`:
        return fetchSuccess(state, action);
      case `${section}_${actionTypes.DT_FETCH_FAILED}`:
        return fetchFailed(state, action);
      case `${section}_${actionTypes.DT_CHANGE_PAGE}`:
        return changePage(state, action);
      case `${section}_${actionTypes.DT_SORT_DATA}`:
        return sortData(state, action);
      case `${section}_${actionTypes.DT_NUM_RECORDS}`:
        return numRecords(state, action);
      case `${section}_${actionTypes.DT_SEARCH_RECORDS}`:
        return searchRecords(state, action);
      // case `${section}_${actionTypes.DT_FILTER_RECORDS}`:
      //   return filterRecords(state, action);
      case `${section}_${actionTypes.DT_SET_FILTER}`:
        return setFilter(state, action);
      case `${section}_${actionTypes.DT_CLEAR_FILTER}`:
        return clearFilter(state, action);
      case `${section}_${actionTypes.DT_CLEAR_INITIATED}`:
        return clearInitiated(state, action);
      case `${section}_${actionTypes.DT_SET_SELECT_MULTI_ROWS}`:
        return setSelectMultiRows(state, action);
      case `${section}_${actionTypes.DT_CLEAR_SELECT_MULTI_ROWS}`:
        return clearSelectMultiRows(state, action);
      case `${section}_${actionTypes.DT_SET_SELECTED_ROW}`:
        return setSelectedRow(state, action);
      case `${section}_${actionTypes.DT_CLEAR_SELECTED_ROW}`:
        return clearSelectedRow(state, action);
      case `${section}_${actionTypes.DT_UPDATE_ROWS}`:
        return updateRows(state, action);
      default:
        return state;
    }
  };

const initiate = (state, action) => {
  const initState = { ...action.result };
  const newState = { ...state, ...initState, isInitiated: true };

  return newState;
};

const fetchRequest = (state, action) => {
  const newState = update(state, { $merge: { isLoading: true } });
  return newState;
};

const fetchSuccess = (state, action) => {
  const newState = update(state, {
    $merge: {
      isLoaded: true,
      isLoading: false,
      rows: action.result.data,
      totalCount: action.result.totalCount,
      pageCount: action.result.totalCount / state.params.length,
      audienceBuilderSearchId: action.result.audienceBuilderSearchId,
    },
  });

  return newState;
};

const fetchFailed = (state, action) => {
  const newState = update(state, {
    $merge: {
      error: action.result,
      isLoading: false,
    },
  });
  return newState;
};

const changePage = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newPage = action.result;
  const newStart = newPage * params.length;
  params.page = newPage;
  params.start = newStart;
  newState.params = params;
  return newState;
};

const sortData = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  params.sort = action.result;
  newState.params = params;

  return newState;
};

const numRecords = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newLenght = action.result.value;
  params.length = newLenght;
  newState.params = params;
  params.start = 0;
  params.page = 0;
  return newState;
};

const searchRecords = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newSearch = action.result;
  params.search = newSearch;
  params.page = 0;
  params.start = 0;
  newState.params = params;
  return newState;
};

const setFilter = (state, action) => {
  const newState = { ...state };
  const params = { ...newState.params };
  const newFilters = [...params.filter];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  newFilters.push({ type: action.filterType, value: action.filterValue });
  params.filter = newFilters;
  params.page = 0;
  params.start = 0;
  newState.params = params;
  return newState;
};

const clearFilter = (state, action) => {
  const newState = { ...state };
  const params = { ...state.params };
  const newFilters = Array.isArray(state.params.filter) ? [...state.params.filter] : [];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  params.filter = newFilters;
  params.page = 0;
  params.start = 0;
  newState.params = params;
  return newState;
};

const clearInitiated = (state, action) => {
  const newState = { ...state };
  newState.isInitiated = false;
  newState.isLoaded = false;
  newState.isLoading = false;
  const originalParams = { ...state.params };
  originalParams.start = 0;
  originalParams.page = 0;
  originalParams.length = 10;
  originalParams.sort = {};
  originalParams.filter = [];
  originalParams.search = "";
  newState.params = originalParams;

  return newState;
};

export const setSelectMultiRows = (state, action) => {
  const newState = { ...state };
  let selectMultiRows = [...state.selectMultiRows];
  const combained = selectMultiRows.concat(action.result);
  selectMultiRows = combained;
  newState.selectMultiRows = selectMultiRows;
  return newState;
};

export const clearSelectMultiRows = (state, action) => {
  const newState = { ...state };
  newState.selectMultiRows = [];
  return newState;
};

export const setSelectedRow = (state, action) => {
  const newState = { ...state };
  newState.selectedRow = { ...action.result };
  return newState;
};

export const clearSelectedRow = (state, action) => {
  const newState = { ...state };
  newState.selectedRow = {};
  return newState;
};

export const updateRows = (state, action) => {
  const rows = action.rows;
  let newRows = [...state.rows];

  rows.forEach((row) => {
    var id = row.id;
    var element = newRows.find((elem) => elem.id === id);
    var index = newRows.findIndex((elem) => elem.id === id);

    if (element != null) {
      const newElement = { ...element, ...row };

      newRows = [...newRows.slice(0, index), newElement, ...newRows.slice(index + 1)];
    }
  });

  const newState = update(state, {
    $merge: {
      rows: newRows,
    },
  });

  return newState;
};

export const fetchSuccessResponderInbox = (state, action) => {
  const newState = update(state, {
    $merge: {
      isLoaded: true,
      isLoading: false,
      rows: action.result.data,
      totalCount: action.result.totalCount,
      unresolved: action.result.unresolved,
      resolved: action.result.resolved,
      forReviewCount: action.result.forReviewCount,
      pageCount: action.result.totalCount / state.params.length,
    },
  });

  return newState;
};

export const responderInboxCountChange = (state, action) => {
  const newState = update(state, {
    $merge: {
      unresolved: state.unresolved + action.result,
      resolved: state.resolved - action.result,
      forReviewCount: state.forReviewCount,
    },
  });

  return newState;
};

export default dataTableReducer;
