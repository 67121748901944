import React from "react";

const IconBriefcase = (props) => (
  <svg className={props.imgStyle} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33366 10.4149V14.9959C9.33366 15.18 9.18439 15.3293 9.00033 15.3293C8.93993 15.3293 8.88066 15.3128 8.82886 15.2818L6.00033 13.5846L3.17183 15.2818C3.01397 15.3764 2.80921 15.3252 2.71449 15.1674C2.68341 15.1156 2.66699 15.0563 2.66699 14.9959V10.4149C1.44771 9.4375 0.666992 7.93557 0.666992 6.2513C0.666992 3.30578 3.05481 0.917969 6.00033 0.917969C8.94586 0.917969 11.3337 3.30578 11.3337 6.2513C11.3337 7.93557 10.5529 9.4375 9.33366 10.4149ZM6.00033 10.2513C8.20946 10.2513 10.0003 8.46043 10.0003 6.2513C10.0003 4.04216 8.20946 2.2513 6.00033 2.2513C3.79119 2.2513 2.00033 4.04216 2.00033 6.2513C2.00033 8.46043 3.79119 10.2513 6.00033 10.2513ZM6.00033 8.91797C4.52757 8.91797 3.33366 7.72403 3.33366 6.2513C3.33366 4.77854 4.52757 3.58464 6.00033 3.58464C7.47306 3.58464 8.66699 4.77854 8.66699 6.2513C8.66699 7.72403 7.47306 8.91797 6.00033 8.91797Z"
      fill="#CACCD1"
    />
  </svg>
);

export default IconBriefcase;
