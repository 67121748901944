import React from "react";
import svg from "./company.svg";

const SectionCompany = (props) => (
  <svg className={props.imgStyle} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g transform="translate(0 -0.178)">
      <path
        fill="#586473"
        d="M8.715,1.314,1.364.193a1.182,1.182,0,0,0-.95.262,1.2,1.2,0,0,0-.415.9V15.506a.672.672,0,0,0,.669.672H2.842V12.652a1.17,1.17,0,0,1,1.17-1.175H5.685a1.17,1.17,0,0,1,1.17,1.175v3.526H9.7V2.476a1.175,1.175,0,0,0-.983-1.162ZM3.678,9.965h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Zm0-2.015h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Zm0-2.015h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Zm0-2.015h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007ZM7.022,9.965h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Zm0-2.015h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Zm0-2.015h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Zm0-2.015h-1a.5.5,0,0,1,0-1.007h1a.5.5,0,0,1,0,1.007Z"
        transform="translate(0 0)"
      />
      <path
        fill="#fc8f12"
        d="M20.262,10.348l-4.762-1v9.8h4.514a1.172,1.172,0,0,0,1.17-1.17V11.492A1.165,1.165,0,0,0,20.262,10.348Zm-1.585,6.794h-1a.5.5,0,0,1,0-1h1a.5.5,0,1,1,0,1Zm0-2.006h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Zm0-2.006h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Z"
        transform="translate(-5.185 -2.97)"
      />
    </g>
  </svg>
);

export default SectionCompany;
