import React, { useEffect, useState } from "react";

//Helpers
import api, { API, formUrl } from "../../../../helper/api/api";

//Components
import HeaderTitle from "../../../../components/UI/HeaderTitle/HeaderTitle";
import NotificationSwitch from "./NotificationSwitch";

const NotificationSettings = (props) => {
  const { organizationId } = props;
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = () => {
    let data = {
      organizationId: organizationId,
    };
    api(formUrl(API.organization.getNotificationsForOrganization, data))
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((error) => console.log(error));
  };

  const toggleNotificationHandler = (event, notificationType) => {
    event.persist();
    let data = {
      organizationId: organizationId,
      notificationType: notificationType,
    };
    api(API.organization.toggleNotificationForOrganization, data)
      .then((res) => {})
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  let render = (
    <div>
      {notifications.map((r) => (
        <NotificationSwitch
          key={r.id}
          title={r.notification}
          isEnabled={r.isEnabled}
          onChange={(event) => toggleNotificationHandler(event, r.notificationType)}
        />
      ))}
    </div>
  );

  return (
    <div className="contentHeight">
      <HeaderTitle title={"Notification Settings"} />
      {render}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default NotificationSettings;
