import { booleanOptions } from "../../../../helper/tableConstants";

export const columns = [
  {
    label: "Start",
    field: "start",
    type: "text",
    searchable: true,
    searchType: "date",
    sort: "asc",
    class: "textLeft",
    flex: 1,
  },
  {
    label: "End",
    field: "end",
    type: "text",
    searchable: true,
    searchType: "date",
    sort: "asc",
    class: "textLeft",
    flex: 1,
  },
  {
    label: "Sync from",
    field: "syncFromDate",
    type: "text",
    searchable: true,
    searchType: "date",
    sort: "asc",
    class: "textLeft",
    flex: 1,
  },
  {
    label: "Success?",
    field: "isSuccess",
    type: "boolean",
    searchable: true,
    searchType: "select",
    option: booleanOptions,
    placeholder: "Choose...",
    class: "textCenter",
    flex: 1,
  },
  {
    label: "Error message",
    field: "errorMessage",
    type: "text",
    searchable: true,
    searchType: "text",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Summaries loaded",
    field: "summariesLoaded",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "New emails",
    field: "newEmails",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Emails loaded success",
    field: "emailsLoadedSuccess",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Emails loaded error",
    field: "emailsLoadedError",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Emails processed success",
    field: "emailsProcessedSuccess",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Emails processed error",
    field: "emailsProcessedError",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Emails stored success",
    field: "emailsStoredSuccess",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
  {
    label: "Emails stored error",
    field: "emailsStoredError",
    class: "textRight",
    type: "number",
    searchable: true,
    searchType: "range",
    flex: 1,
    sort: "asc",
  },
];
