export const campaignStatusEnum = {
  setup: 0,
  active: 1,
  paused: 2,
  archived: 3,
  scheduled: 4,
};

export const campaignStatusOptions = [
  { value: 0, label: "Setup" },
  { value: 1, label: "Active" },
  { value: 2, label: "Paused" },
  { value: 3, label: "Archived" },
  { value: 4, label: "Scheduled" },
];

export const campaignStatusOptionsString = [
  { value: "0", label: "Setup" },
  { value: "1", label: "Active" },
  { value: "2", label: "Paused" },
  { value: "3", label: "Archived" },
  { value: "4", label: "Scheduled" },
];