import React, { useContext, useEffect, FC, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

//Redux

//Helpers
import { processNotificationLinks } from "../../../helper/processNotificationLinksHelper";
import { SIGNALR_CONSTANTS } from "../../../helper/signalrConstants";
import SignalRContext from "../../../helper/SignalRContext";

//Images & Icons
import Notification3LineIcon from "remixicon-react/Notification3LineIcon";
import NoNotifications from "../../../assets/Icons/NoNotifications/NoNotifications";

// Style
import styles from "./HeaderNotifications.module.scss";

// Components
import NotificationActions from "./NotificationActions";
import {
  useGetNotificationsQuery,
  useMarkAllNotificationsAsReadMutation,
  useMarkNotificationAsReadMutation,
} from "../../../api/notificationApi";
import { addNotifications, markNotificationAsRead } from "../../../store/global/notificationsSlice";
import { useOrganization } from "../../../helper/hooks/useOrganization";
import { InAppNotificationLogModel } from "../../../models/notifications/InAppNotificationLogModel";
import { RootState } from "../../../store/configureStore";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import useSignalRHook from "@hooks/useSignalRHook";

const HeaderNotifications: FC = () => {
  const { data } = useGetNotificationsQuery();
  const organization = useOrganization();
  const dispatch = useDispatch();
  const dateFormat = useOrgDateFormat();

  const state = useSelector((state: RootState) => state.notifications);
  const { notifications, olderNotifications, unreadNotificationsCount, unreadOlderNotificationsCount } = state;
  const totalUnread = unreadNotificationsCount + unreadOlderNotificationsCount;
  const hasUnread = totalUnread > 0;

  const [markAllNotificationsAsRead, { error: markAllReadError }] = useMarkAllNotificationsAsReadMutation();
  const [sendMarkNotificationAsReadRequest] = useMarkNotificationAsReadMutation();

  useSignalRHook(SIGNALR_CONSTANTS.IN_APP_NOTIFICATIONS_GET, (data: unknown) => dispatch(addNotifications(data)));

  let notificationDot = null;
  let notificationsDropDown = null;

  const getNotificationTypeClass = useCallback((notification: InAppNotificationLogModel) => {
    let notificationType = null;
    switch (notification.notificationSuccessTypeEnum) {
      case 0:
        notificationType = "";
        break;
      case 1:
        notificationType = styles.warningNotification;
        break;
      case 2:
        notificationType = styles.successNotification;
        break;
      case 3:
        notificationType = styles.errorNotification;
        break;
      default:
        break;
    }

    return notificationType;
  }, []);

  useEffect(() => {
    if (markAllReadError) {
      if ("data" in markAllReadError && markAllReadError.data) {
        toast.error(markAllReadError.data);
      } else {
        toast.error("An error occurred while marking all notifications as read");
      }
    }
  }, [markAllReadError]);

  const markAsRead = useCallback(
    (notificationId: number) => {
      sendMarkNotificationAsReadRequest(notificationId)
        .unwrap()
        .then((result) => {
          dispatch(markNotificationAsRead(notificationId));
        })
        .catch((error) => {
          toast.error(error.message);
        });
    },
    [sendMarkNotificationAsReadRequest, dispatch]
  );

  const renderNotifications = useCallback(
    (notifications: InAppNotificationLogModel[]) => {
      return notifications.map((notification) => {
        // var createdDate = Moment(notification.createdDate, 'DD-MM-YYYY HH:mm').fromNow();
        return (
          <div
            key={notification.id}
            className={`${styles.notificationsRow} ${styles.notificationWrap} ${getNotificationTypeClass(notification)} flex`}
          >
            <div className={styles.notificationMessage}>
              <p onClick={() => markAsRead(notification.id)}>{notification.message}</p>
            </div>
            <div className={styles.actions}>
              <NotificationActions notification={notification} markAsRead={markAsRead} />
              <div className={styles.notificationDate}>
                <p>{moment(notification.createdDate).fromNow()}</p>
              </div>
            </div>
          </div>
        );
      });
    },
    [getNotificationTypeClass, markAsRead]
  );

  const getNotifications = useCallback(
    (notifications: InAppNotificationLogModel[], olderNotifications: InAppNotificationLogModel[]) => {
      if (!organization) {
        return null;
      }

      let olderNotificationsCount = olderNotifications != null ? olderNotifications.length : 0;
      let notificationsCount = notifications ? notifications.length : 0;
      return notificationsCount > 0 || olderNotificationsCount > 0 ? (
        <>
          {renderNotifications(processNotificationLinks(notifications, organization, dateFormat))}

          {olderNotificationsCount > 0 ? (
            <>
              <Row className={`${styles.headerRow} ${styles.labelsColor}`}>
                <Col xl={6}>
                  <p className={styles.todayP}>Older</p>
                </Col>
              </Row>

              {renderNotifications(processNotificationLinks(olderNotifications, organization, dateFormat))}
            </>
          ) : null}
        </>
      ) : (
        <Row className={`${styles.notificationsRow} ${styles.notificationWrap}`}>
          <Col xl={12} className={styles.noNotifications}>
            <NoNotifications />
            <p>No Notifications</p>
          </Col>
        </Row>
      );
    },
    [organization, renderNotifications]
  );

  const notificationsWrap = useMemo(
    () => getNotifications(notifications, olderNotifications),
    [notifications, olderNotifications, getNotifications]
  );

  notificationDot = hasUnread && <span className={styles.notifDot}></span>;

  notificationsDropDown = (
    <div className={styles.notificationsDiv}>
      <>
        <Notification3LineIcon className="iconGray400" />
        {notificationDot}
      </>

      <div className={styles.myNotificationsDrop}>
        <div className={styles.wrap}>
          <Row className={styles.headerRow}>
            <Col xl={12}>
              <p className={styles.headerTitle}>Notification Center</p>
            </Col>
          </Row>
          {hasUnread && (
            <Row className={`${styles.headerRow} ${styles.labelsColor}`}>
              <Col xl={6}>
                <p className={styles.unreadP}>Unread ({totalUnread})</p>
              </Col>
              <Col xl={6}>
                {hasUnread && (
                  <p className={styles.markAllAsRead} onClick={() => markAllNotificationsAsRead()}>
                    Mark All as Read
                  </p>
                )}
              </Col>
            </Row>
          )}

          <div className={styles.listNotifications}>
            {notifications?.length > 0 ? (
              <Row className={`${styles.headerRow} ${styles.labelsColor}`}>
                <Col xl={6}>
                  <p className={styles.todayP}>Today</p>{" "}
                </Col>
              </Row>
            ) : null}
            {notificationsWrap}
          </div>
        </div>
      </div>
    </div>
  );

  return <>{notificationsDropDown}</>;
};

export default HeaderNotifications;
