import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

//Components
import FormikInput from "../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../components/UI/Formik/FormikSelect";
import Button from "../../../components/UI/Button/Button";
import FormikCheckbox from "../../../components/UI/Formik/FormikCheckbox";
import FormikTimePicker from "../../../components/UI/Formik/FormikTimePicker";

const FormikSandbox = () => {
  const options = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
  ];

  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState({
    text: "",
    textarea: "",
    number: 0,
    password: "",
    select: 0,
    selectDefault: 1,
    selectWithValue: 2,
    selectsearchable: 0,
    selectmulti: [1, 2],
    selectmultisearchable: [],

    checkbox: false,
    checkboxTrue: true,
    checkboxDisabled: true,
  });

  const selectChange = (event) => {
    if (event != null) {
      if (Array.isArray(event)) {
        toast.success(event.join(","));
      } else {
        toast.success(event);
      }
    } else {
      toast.success("Empty");
    }
  };

  const changeInitialValues = () => {
    setInitialValues({
      text: "testText",
      textarea: "testTextArea",
      number: 1,
      password: "testPassword",
      select: 3,
      selectDefault: 1,
      selectWithValue: 2,
      selectsearchable: 0,
      selectmulti: [1, 2],
      selectmultisearchable: [],

      checkbox: false,
      checkboxTrue: true,
      checkboxDisabled: true,
    });

    formikRef.setValues({
      text: "testText",
      textarea: "testTextArea",
      number: 1,
      password: "testPassword",
      select: 3,
      selectDefault: 1,
      selectWithValue: 2,
      selectsearchable: 0,
      selectmulti: [1, 2],
      selectmultisearchable: [],

      checkbox: false,
      checkboxTrue: true,
      checkboxDisabled: true,
    });

    formikRef.resetForm({
      text: "testText",
      textarea: "testTextArea",
      number: 1,
      password: "testPassword",
      select: 3,
      selectDefault: 1,
      selectWithValue: 2,
      selectsearchable: 0,
      selectmulti: [1, 2],
      selectmultisearchable: [],

      checkbox: false,
      checkboxTrue: true,
      checkboxDisabled: true,
    });
  };

  const checkboxChange = (checked) => {
    if (checked) {
      toast.success("Enabled");
    } else {
      toast.success("Disabled");
    }
  };

  // const checkboxClicked = (checked) => {
  //   toast.success("Clicked");
  // };

  return (
    <Formik
      initialValues={{
        text: "",
        textarea: "",
        number: 0,
        password: "",
        select: 0,
        selectDefault: 1,
        selectWithValue: 2,
        selectsearchable: 0,
        selectmulti: [1, 2],
        selectmultisearchable: [],

        checkbox: false,
        checkboxTrue: true,
        checkboxDisabled: true,
      }}
      validationSchema={Yup.object({
        text: Yup.string().required("Required"),
        textarea: Yup.string().required("Required"),
        number: Yup.number().required("Required"),
        password: Yup.string().required("Required"),
        select: Yup.number().required("Required"),
        selectDefault: Yup.number().required("Required"),
        selectWithValue: Yup.number().required("Required"),
        selectsearchable: Yup.number().required("Required"),
        selectmulti: Yup.array().of(Yup.number()).required("Required"),
        selectmultisearchable: Yup.array().of(Yup.number()).required("Required"),
      })}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        toast.success("Submitted!");
      }}
      onReset={() => {}}
    >
      {(formikProps) => {
        const { values } = formikProps;

        return (
          <Form>
            <>
              <div className="flex1 mR15">
                <p>
                  <code>Default form</code>
                </p>
                <FormikInput label="Vertical form / default" name="text" value={values.text} placeholder="This is a text field..." />
              </div>
              <div className="flex1 mR15">
                <p style={{ marginBottom: "33px" }}>
                  <code> labelPosition="left"</code>
                </p>
                <FormikInput
                  label="Left label form"
                  name="text"
                  labelPosition="left"
                  value={values.text}
                  placeholder="This is a text field..."
                />
              </div>
              <div className="flex1 mR15">
                <p style={{ marginBottom: "33px" }}>
                  <code> labelPosition="right"</code>
                </p>
                <FormikInput
                  label="Right label form"
                  name="text"
                  labelPosition="right"
                  value={values.text}
                  placeholder="This is a text field..."
                />
              </div>

              <FormikInput
                label="Password"
                name="password"
                type="password"
                value={values.password}
                placeholder="This is a password field..."
              />

              <FormikInput label="Number" name="number" type="number" value={values.number} placeholder="This is a number field..." />

              <FormikInput
                label="Textarea"
                name="textarea"
                type="textarea"
                rows={8}
                value={values.textarea}
                placeholder="This is a textarea field..."
              />

              <FormikSelect label="Select" name="select" options={options} value={values.select} onChange={selectChange} />

              <FormikSelect
                label="Select default - no initial value so set to a system default one"
                name="selectDefault"
                options={options}
                value={values.selectDefault}
                defaultValue={1}
                onChange={selectChange}
              />

              <FormikSelect
                label="Select with value - we have an existing value and a default value as well, set to the existing value"
                name="selectWithValue"
                options={options}
                value={values.selectWithValue}
                defaultValue={1}
                onChange={selectChange}
              />

              <FormikSelect
                label="Select searchable"
                name="selectsearchable"
                options={options}
                value={values.selectsearchable}
                onChange={selectChange}
                isSearchable={true}
              />

              <FormikSelect
                label="Select multiple"
                name="selectmulti"
                options={options}
                value={values.selectmulti}
                onChange={selectChange}
                isSearchable={false}
                isMulti={true}
              />

              <FormikSelect
                label="Select multiple searchable"
                name="selectmultisearchable"
                options={options}
                value={values.selectmultisearchable}
                onChange={selectChange}
                isSearchable={true}
                isMulti={true}
              />

              <FormikCheckbox label="Checkbox" name="checkbox" onChange={checkboxChange} value={values.checkbox} />

              <FormikCheckbox label="Checkbox checked" name="checkboxTrue" onChange={checkboxChange} checked={values.checkboxTrue} />

              <FormikCheckbox
                label="Checkbox disabled"
                name="checkboxDisabled"
                onChange={checkboxChange}
                checked={values.checkboxDisabled}
                disabled
              />

              <FormikTimePicker label="Time picker" name="timePicker" value="17:00" timeIntervals={15} placeholder="Time" />

              <FormikTimePicker
                label="Time picker disabled"
                name="timePicker"
                value="17:00"
                disabled={true}
                timeIntervals={15}
                placeholder="Time disabled"
              />

              <FormikTimePicker
                label="Time picker limited hours"
                name="timePicker"
                value="17:00"
                minValue="16:45"
                maxValue="17:15"
                timeIntervals={15}
                placeholder="Time limited hours"
              />

              <Button id="submit-hours-checkbox" type="submit">
                Submit
              </Button>

              <pre>{JSON.stringify(values, null, 2)}</pre>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikSandbox;
