import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfQuarter,
  endOfQuarter,
} from "date-fns";

const dates = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  startOfLastQuarter: startOfQuarter(addMonths(new Date(), -3)),
  endOfLastQuarter: endOfQuarter(addMonths(new Date(), -3)),
};

const staticRangeHandler = {
  range: () => ({}),
  isSelected(range: any) {
    const definedRange = this.range() as any;
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges: any[]) {
  return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }));
}

export const customStaticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: dates.startOfToday,
      endDate: dates.endOfToday,
    }),
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: dates.startOfYesterday,
      endDate: dates.endOfYesterday,
    }),
  },

  {
    label: "This Week",
    range: () => ({
      startDate: dates.startOfWeek,
      endDate: dates.endOfWeek,
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: dates.startOfLastWeek,
      endDate: dates.endOfLastWeek,
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: dates.startOfMonth,
      endDate: dates.endOfMonth,
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: dates.startOfLastMonth,
      endDate: dates.endOfLastMonth,
    }),
  },
  {
    label: "This Quarter",
    range: () => ({
      startDate: dates.startOfQuarter,
      endDate: dates.endOfQuarter,
    }),
  },
  {
    label: "Last Quarter",
    range: () => ({
      startDate: dates.startOfLastQuarter,
      endDate: dates.endOfLastQuarter,
    }),
  },
]);
