import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

//Redux
import {
  searchRecords,
  fetchGlobalSearchProspects,
  fetchGlobalSearchCompanies,
  fetchGlobalSearchCampaigns,
} from "../../../store/GlobalSearch/actions/globalSearch";
//Styles
import styles from "./GlobalSearch.module.scss";

//Components
import ProspectSearch from "./ProspectSearch/ProspectSearch";
import CompaniesSearch from "./CompaniesSearch/CompaniesSearch";
import CampaignsSearch from "./CampaignsSearch/CampaignsSearch";

const GlobalSearch = (props) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (props.search.length > 0) {
      props.fetchGlobalSearchProspects(props.search);
      props.fetchGlobalSearchCompanies(props.search);
      props.fetchGlobalSearchCampaigns(props.search);
    }
  }, [props.search]);

  useEffect(() => {
    document.addEventListener("click", handleCloseOnClick, false);
    return () => {
      document.removeEventListener("click", handleCloseOnClick, false);
    };
  }, []);

  const handleCloseOnClick = (e) => {
    if (elementRef.current && !elementRef.current.contains(e.target)) {
      props.hrefClicked();
    }
  };

  return (
    <div ref={elementRef} className={`${styles.myAccountDrop} ${props.customStyle}`} style={{ right: props.rightWidth }}>
      <div className={styles.wrap}>
        {/* <p className={styles.headingStyle}>Last Searched</p> */}
        <ProspectSearch hrefClicked={props.hrefClicked} />
        <CompaniesSearch hrefClicked={props.hrefClicked} />
        <CampaignsSearch hrefClicked={props.hrefClicked} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    search: state.globalSearh.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRecords: (value) => dispatch(searchRecords(value)),
    fetchGlobalSearchProspects: (value) => dispatch(fetchGlobalSearchProspects(value)),
    fetchGlobalSearchCompanies: (value) => dispatch(fetchGlobalSearchCompanies(value)),
    fetchGlobalSearchCampaigns: (value) => dispatch(fetchGlobalSearchCampaigns(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
