import api, { API } from "../../../../helper/api/api";
import * as actionTypes from "../actions/actionTypes";

export const fetchEmailStagesListRequest = () => {
  return {
    type: actionTypes.FETCH_EMAIL_STAGES_LIST_REQUEST,
  };
};

export const fetchEmailStagesListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_EMAIL_STAGES_LIST_SUCCEEDED,
    result: setup,
  };
};

export const fetchEmailStagesListFailed = (error) => {
  return {
    type: actionTypes.FETCH_EMAIL_STAGES_LIST_FAILED,
    result: error,
  };
};

export const fetchEmailStagesList = (reportDate) => {
  return (dispatch) => {
    dispatch(fetchEmailStagesListRequest());
    const data = {
      reportDate: reportDate,
    };
    return api(API.sendReports.getEmailStagesList, data)
      .then((response) => {
        dispatch(fetchEmailStagesListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEmailStagesListFailed(error.message));
      });
  };
};
