import { useQuery } from "./useQuery";
import { numberOfRecords } from "../tableConstants";

export const useQueryTableFilters = (filterTypes, campaignId) => {
  let query = useQuery();
  let state = {
    params: {
      filter: [],
      length: 25,
      search: "",
      page: 0,
      start: 0,
      sort: {},
    },
  };

  if (query.get("show") != null) {
    const show = parseInt(query.get("show"));
    let records = numberOfRecords.find((n) => n.value === show);
    if (records != null) {
      state.params.length = records.value;
    }
  }

  if (query.get("q") != null) {
    state.params.search = query.get("q");
  }

  if (query.get("page") != null) {
    const page = parseInt(query.get("page")) - 1;
    state.params.page = page;
    state.params.start = state.params.length * page;
  }

  filterTypes.forEach((filter) => {
    if (query.get(filter) != null) {
      const newFilter = { type: filter, value: query.get(filter).split(",") };
      state.params.filter.push(newFilter);
    }
  });

  // if (campaignId !== undefined && campaignId !== "") {
  //   const newFilter = { type: "Campaign", value: [campaignId] };
  //   state.params.filter.push(newFilter);
  // }

  return state;
};
