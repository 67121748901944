import React, { useEffect, useState } from "react";
import _ from "lodash";

// Styles
import styles from "./ProgressLine.module.scss";

interface ProgressLineProps {
  backgroundColor?: string;
  data?: { percentage: string; color: string; label: string | null }[];
  label?: string;
  hideLabelStyle?: boolean;
}

const ProgressLine: React.FC<ProgressLineProps> = ({
  backgroundColor = `var(--secondary-color-200)`,
  data = [
    {
      percentage: "0%",
      color: "white",
      label: "",
    },
  ],
  label,
  hideLabelStyle,
}) => {
  const [widths, setWidths] = useState<string[]>(
    data.map(() => {
      return "0%";
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      setWidths(
        data.map((item) => {
          return parseFloat(item.percentage).toFixed(2) + "%";
        })
      );
    });
  }, [data]);

  return (
    <>
      <div
        className={`${styles.progressVisualFull} progressLineHelper`}
        style={{
          backgroundColor,
        }}
      >
        {data.map((item, index) => {
          if (widths[index] === "0%") {
            return null;
          }

          return (
            <div
              key={index}
              style={{
                width: widths[index],
                backgroundColor: item.color,
              }}
              className={styles.progressVisualPart}
            />
          );
        })}
      </div>
      {data.map((item, index) => {
        return (
          <span key={index}>
            {!_.isNull(item.label) && !hideLabelStyle ? (
              <span className={styles.label} style={{ width: `${Math.floor(Number(item.percentage))}%` }}>
                {item.label}
              </span>
            ) : null}
          </span>
        );
      })}
    </>
  );
};

export default ProgressLine;
