import { FC, useEffect } from "react";
import Loading from "../../pages/Loading/Loading";
import { useAuth } from "react-oidc-context";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import { dispatchLogout } from "../../helper/UserMetadata/dispatchUserEvents";
import { Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";

const Logout: FC = () => {
  const auth = useAuth();

  useEffect(() => {
    const logout = async () => {
      try {
        // We need this to dispatch a global event in window.outbase.logout
        // Google Tag Manager listens for this event and dispatches it to 3rd party plugins.
        // More specifically it will call Posthog's reset function.
        dispatchLogout();
        Sentry.setUser(null);
        await auth.clearStaleState();
        if (auth.isAuthenticated) {
          await auth.signoutRedirect();
        }
      } catch (error) {
        await auth.removeUser();
      }
    };

    logout();
  }, [auth]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <Loading />;
    case "signoutRedirect":
      return <Loading />;
    default:
      break;
  }

  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.isAuthenticated && auth.error) {
    return <ErrorPage error={auth.error.message} />;
  }

  if (!auth.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Loading />;
};

export default Logout;
