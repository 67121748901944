import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Styles
import styles from "./ImportStartedModal.module.scss";

//Components
import Modal from "../../components/UI/Modal/Modal";

const ImportStartedModal = (props) => {
  const { show, handleClose, model } = props;

  const campaign = model?.campaign;
  const numberOfProspects = model?.numberOfProspects;

  return (
    <Modal
      dialogClassName="customModal"
      title="Importing prospects"
      handleClose={handleClose}
      handleSave={handleClose}
      show={show}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
      backdrop={true}
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.modalBody}>
            <p className={styles.prospectCampaignDetails}>
              Importing <b>{numberOfProspects} prospects</b> prospects to campaign <b>{campaign}</b>.
            </p>
            <p className="m0">Sit tight, this won't take long!</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ImportStartedModal;
