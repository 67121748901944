export const FETCH_RESPONDER_INBOX_REQUEST = "FETCH_RESPONDER_INBOX_REQUEST";
export const FETCH_RESPONDER_INBOX_SUCCEEDED =
  "FETCH_RESPONDER_INBOX_SUCCEEDED";
export const FETCH_RESPONDER_INBOX_FAILED = "FETCH_RESPONDER_INBOX_FAILED";
export const RESPONDER_INBOX_CHANGE_PAGE = "RESPONDER_INBOX_CHANGE_PAGE";
export const RESPONDER_INBOX_SORT_DATA = "RESPONDER_INBOX_SORT_DATA";
export const RESPONDER_INBOX_NUM_RECORDS = "RESPONDER_INBOX_NUM_RECORDS";
export const RESPONDER_INBOX_SEARCH_RECORDS = "RESPONDER_INBOX_SEARCH_RECORDS";
export const RESPONDER_INBOX_FILTER_RECORDS = "RESPONDER_INBOX_FILTER_RECORDS";
export const RESPONDER_INBOX_SET_FILTER = "RESPONDER_INBOX_SET_FILTER";
export const RESPONDER_INBOX_CLEAR_FILTER = "RESPONDER_INBOX_CLEAR_FILTER";
export const RESPONDER_INBOX_INITIATE = "RESPONDER_INBOX_INITIATE";
export const RESPONDER_INBOX_SET_SELECTED_ROW =
  "RESPONDER_INBOX_SET_SELECTED_ROW";
export const RESPONDER_INBOX_CLEAR_SELECTED_ROW =
  "RESPONDER_INBOX_CLEAR_SELECTED_ROW";
export const RESPONDER_INBOX_CLEAR_INITIATE = "RESPONDER_INBOX_CLEAR_INITIATE";
export const RESPONDER_INBOX_COUNT_CHANGE = "RESPONDER_INBOX_COUNT_CHANGE";
export const SET_FILTER_FOR_REVIEW = "SET_FILTER_FOR_REVIEW";

export const SET_MESSAGE_RESOLVED = "SET_MESSAGE_RESOLVED";

