export const placeholderHelper = (placeholderHTML) => {
  return (editor) => {
    editor.on("init", function () {
      const editorContainer = editor.getContainer();
      const placeholderElement = editorContainer.querySelector(".tox-edit-area__iframe");

      if (!placeholderElement) {
        return;
      }

      const placeholderDiv = document.createElement("div");
      placeholderDiv.innerHTML = placeholderHTML;
      placeholderDiv.style.position = "absolute";
      placeholderDiv.style.top = "10px";
      placeholderDiv.style.left = "10px";
      placeholderDiv.style.right = "0";
      placeholderDiv.style.bottom = "0";
      placeholderDiv.style.padding = "10px";
      placeholderDiv.style.pointerEvents = "none";
      placeholderDiv.style.display = "flex";
      placeholderDiv.style.zIndex = "1";

      placeholderElement.parentNode.insertBefore(placeholderDiv, placeholderElement);

      const updatePlaceholderVisibility = () => {
        const content = editor.getContent({ format: "text" }).trim();
        if (content.length === 0) {
          placeholderDiv.style.display = "flex";
        } else {
          placeholderDiv.style.display = "none";
        }
      };

      editor.on("keyup change blur", updatePlaceholderVisibility);
      editor.on("focus", () => {
        placeholderDiv.style.display = "none";
      });

      updatePlaceholderVisibility();
    });
  };
};

export const addCustomMenuItems = (customMenuItems) => {
  return (editor) => {
    customMenuItems.forEach((item) => {
      editor.ui.registry.addMenuItem(item.name, {
        text: item.text,
        value: item.value,
        onAction: () => {
          editor.insertContent(item.content);
        },
      });
    });
  };
};
