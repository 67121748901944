import React from "react";

//Styles
import styles from "./CreditBalanceColOutbOss.module.scss";

//Components
import ProgressLine from "../../UI/ProgressLine/ProgressLine";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { calculatePercentageFromNumber } from "../../../helper/calculatePercentageHelper";

const CreditBalanceColOutbOss = (props) => {
  let creditsData = [];
  creditsData.push({
    color: "#1ac472",
    percentage: calculatePercentageFromNumber(props.availableFormatted, props.totalFormatted),
  });
  creditsData.push({
    color: "#fe7904",
    percentage: calculatePercentageFromNumber(props.usedFormatted, props.totalFormatted),
  });

  return (
    <div className={styles.creditBalanceWrapper}>
      <OverlayTrigger
        key={"audience-size"}
        placement={"bottom"}
        overlay={
          <Popover id={`audience-size-tooltip`}>
            <Popover.Body className={"campaignAudienceTooltip"}>
              <span>Available Credits / Used Credits / Total Credits</span>
            </Popover.Body>
          </Popover>
        }
      >
        <div className={`${styles.progressBarDetails} creditBalanceDetails`}>
          <p className={styles.labelsWrapper}>
            <span className={styles.available}>{props.availableFormatted ? props.availableFormatted : 0} </span>/
            <span className={styles.used}> {props.usedFormatted ? props.usedFormatted : 0} </span>/
            <span className={styles.total}> {props.totalFormatted ? props.totalFormatted : 0}</span>
          </p>
          <ProgressLine hideLabelStyle={true} data={creditsData} />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        key={"refill-days"}
        placement={"bottom"}
        overlay={
          <Popover id={`refill-days-tooltip`}>
            <Popover.Body className={"campaignAudienceTooltip"}>
              <span>Next auto-refill in {props.nextRefill} day(s).</span>
            </Popover.Body>
          </Popover>
        }
      >
        <div className={`${styles.refillWrapper} flex`}>
          {props.isTrial ? null : <span className={styles.refillLabel}>Next auto-refill in {props.nextRefill} day(s).</span>}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default CreditBalanceColOutbOss;
