// CampaignRow.ts
import React from "react";
import _ from "lodash";
import Moment from "moment";
import { CampaignRowModel } from "../../models/campaign/CampaignRowModel";
import { CampaignDataTableModel } from "../../models/campaign/CampaignDataTableModel";
import { getCampaignStatusData } from "../../helper/campaignHelpers";
import { permissionHelper } from "../../helper/permissionHelper";
import { PERMISSIONS } from "../../helper/permissionConstants";
import ActionAndCol from "../../components/DataTable/ActionAndIconsCol/ActionAndIconCol";
import CampaignInfoCol from "../../components/DataTable/CampaignInfoCol/CampaignInfoCol";
import NumberAndPercentageCol from "../../components/DataTable/NumberAndPercentageCol/NumberAndPercentageCol";
import StatusCol from "../../components/DataTable/StatusCol/StatusCol";
import UserLineIcon from "remixicon-react/UserLineIcon";

export const createCampaignRow = (
  row: CampaignDataTableModel,
  index: number,
  userRole: string,
  organizationId: number,
  handleChangeStatus: Function,
  goLiveModalShow: Function,
  archiveCampaignModalShow: Function,
  cloneModalShow: Function,
  dateFormat: string
): CampaignRowModel => {
  let campaignStatusData = getCampaignStatusData(row.status, row.campaignSetupProgressModel);

  let campaign: CampaignRowModel = {
    ...row,
    name: <></>,
    emailsSent: <></>,
    opens: <></>,
    clicks: <></>,
    responses: <></>,
    leads: <></>,
    status: <></>,
    lastActiveDate: "",
    actions: <></>,
  };

  campaign.raw = _.cloneDeep(row);

  if (permissionHelper(userRole, PERMISSIONS.campaign.edit)) {
    campaign.actions = (
      <ActionAndCol
        id={row.id}
        buttonId={`button-${index + 1}`}
        iterationId={index}
        status={row.status}
        setup={row.campaignSetupProgressModel.stepsDone}
        maxSteps={row.campaignSetupProgressModel.maxSteps}
        changeStatus={handleChangeStatus}
        openActivationModal={() => goLiveModalShow(row)}
        openArchiveModel={() => archiveCampaignModalShow(campaign.raw)}
        openCloneModal={() => cloneModalShow(row.id)}
      />
    );
  }

  campaign.name = (
    <CampaignInfoCol
      name={row.name}
      createdBy={row.createdBy}
      orgId={organizationId}
      campaignId={row.id}
      imported={row.prospectsImported}
      available={row.prospectsAvailable}
      engaged={row.prospectsEngaged}
      createdDate={Moment(row.createdDate).format(dateFormat)}
      now={10}
      min={1}
      id={"campaignName" + index}
    />
  );
  campaign.emailsSent = (
    <NumberAndPercentageCol
      key={`emails-${index}`}
      id={`emails-${index}`}
      variant="emails"
      total={row.emailsDelivered}
      percentage={row.emailsPerProspect.toFixed(2)}
      customText={
        <span key={`email-key-${index}`}>
          per <UserLineIcon key={`icon-key-${index}`} size={16} className="iconGray400" />{" "}
        </span>
      }
      tooltip={`On average we've sent ${row.emailsPerProspect.toFixed(2)} emails to each prospect`}
    />
  );
  campaign.opens = (
    <NumberAndPercentageCol
      key={`opens-${index}`}
      id={`opens-${index}`}
      variant="opens"
      total={row.opens}
      percentage={row.openRate.toFixed(2)}
    />
  );
  campaign.clicks = (
    <NumberAndPercentageCol
      key={`clicks-${index}`}
      id={`clicks-${index}`}
      variant="click"
      total={row.clicks}
      percentage={row.clickRate.toFixed(2)}
    />
  );
  campaign.responses = (
    <NumberAndPercentageCol
      key={`responses-${index}`}
      id={`responses-${index}`}
      variant="responses"
      total={row.responses}
      percentage={row.responseRate.toFixed(2)}
    />
  );
  campaign.leads = (
    <NumberAndPercentageCol
      key={`leads-${index}`}
      id={`leads-${index}`}
      variant="leads"
      total={row.leads}
      percentage={row.leadRate.toFixed(2)}
    />
  );
  campaign.status = (
    <StatusCol
      status={campaignStatusData.status}
      time={row.lastStatusChangeDate != null ? Moment.utc(row.lastStatusChangeDate).fromNow() : null}
      variant={campaignStatusData.statusVariant}
      tooltipText={campaignStatusData.tooltipText}
    />
  );

  campaign.lastActiveDate = Moment(row.lastActiveDate).format(dateFormat);

  return campaign;
};
