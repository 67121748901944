import { useContext, useEffect } from "react";
import { SIGNALR_CONSTANTS } from "../../helper/signalrConstants";
import SignalRContext from "../../helper/SignalRContext";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";
import Preparing from "../../assets/Images/PreparingImage/Preparing";
import { useGetLatestCampaignWizardForUserQuery } from "../../api/campaignWizardApi";

const AutoCreateOrganization = (props) => {
  const { user } = { ...props };
  const auth = useAuth();
  const signalRConnection = useContext(SignalRContext);

  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_CAMPAIGN_COMPLETED, (data) => {
        auth.signinSilent().then((user) => {
          window.location.replace(`/${data.organizationId}/campaigns/${data.campaignId}/overview`);
        });
      });

      signalRConnection.on(SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_COMPLETED, (data) => {
        auth.signinSilent().then((user) => {
          window.location.replace(`/${data.organizationId}/campaigns/${data.campaignId}/overview`);
        });
      });
    }

    return () => {
      if (signalRConnection != null) {
        signalRConnection.off(SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_CAMPAIGN_COMPLETED);
        signalRConnection.off(SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_COMPLETED);
      }
    };
  }, [signalRConnection, auth]);

  useEffect(() => {
    if (user) {
      // If the user has existing organizations and the whole demo creation finished, we should skip the create organization step and go to the dashboard
      if (!user.isCreatingOrganization && user.organizations && user.organizations.length > 0 && user.organizations[0].id) {
        window.location.replace(`/${user.organizations[0].id}/dashboard`);
      }
      // If the user has been created more than 5 minutes ago, we should skip the create organization step
      else if (user.createdDateUnix && user.createdDateUnix < new Date().getTime() - 1000 * 60 * 5) {
        window.location.replace(`/register-organization`);
      }
    }
  }, [user]);

  const { data: campaignWizard, isLoading } = useGetLatestCampaignWizardForUserQuery();

  useEffect(() => {
    if (!isLoading && campaignWizard && campaignWizard.campaignId) {
      window.location.replace(`/${campaignWizard.organizationId}/campaigns/${campaignWizard.campaignId}/overview`);
    }
  }, [campaignWizard]);

  return <Preparing animate={true} topLabelText="Creating your organization" />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AutoCreateOrganization);
