import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const TimelineElement = ({ ...props }) => {
  return (
    <VerticalTimelineElement
      className={props.className}
      contentArrowStyle={props.contentArrowStyle}
      contentStyle={props.contentStyle}
      key={props.key}
      date={props.date}
      dateClassName={props.dateClassName}
      icon={props.icon}
      iconStyle={props.iconStyle}
      iconOnClick={props.onClick}
      position={props.position}
      textClassName={props.textClassName}
      onTimelineElementClick={props.onClick}
    >
      {props.children}
    </VerticalTimelineElement>
  );
};

export default TimelineElement;
