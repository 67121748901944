import React from "react";

//Style
import styles from "./TestResultsMessage.module.scss";

const TestResultsMessage = (props) => {
  return (
    <div className={`${styles.resultWrapper} ${props.firstStep ? styles.afterLine : ""} flex `}>
      <div className={styles.iconWrapper}>{props.icon}</div>
      <div className="mL5">
        <p id={props.id} className="f14 m0 secondary_text_color">
          {props.messageLabel}
        </p>
        <p className="f12 m0 secondary_text_color">{props.messageDetails}</p>
      </div>
    </div>
  );
};

export default TestResultsMessage;
