import React, { useState } from "react";
import { Alert } from "react-bootstrap";

//Icons
import IconExclamationMark from "../../../assets/Icons/IconExclamationMark/IconExclamationMark";
import InformationLineIcon from "remixicon-react/InformationLineIcon";

//Style
import "./AlertNotification.scss";

const AlertNotification = (props) => {
  const [show, setShow] = useState(true);

  let variant = "";

  if (props.variant != null) {
    variant += " " + [props.variant];
  }

  let icon = null;
  let variantButton = "";

  switch (props.variant) {
    case "info_alert":
      icon = <InformationLineIcon size={18} />;
      variantButton = "info_alert_btn";
      break;
    case "info_alert_light":
      icon = <InformationLineIcon size={18} />;
      break;
    case "info_alert_lighter":
      icon = <InformationLineIcon size={18} />;
      variantButton = "info_alert_btn";
      break;
    case "danger_alert":
      icon = <IconExclamationMark />;
      variantButton = "danger_alert_btn";
      break;
    case "warning_alert":
      icon = props.icon ? props.icon : null;
      variantButton = "warning_alert_btn";
      break;
    case "success_alert":
      icon = props.icon ? props.icon : null;
      variantButton = "success_alert_btn";
      break;
    case "no_icon_notification":
      icon = props.icon ? props.icon : null;
      variantButton = "success_alert_btn";
      break;
    default:
      break;
  }

  if (show) {
    return (
      <div className={props.notificationWrapperClass} id={props.id ? props.id : "notificationBox"}>
        <Alert
          className={props.alertClassName}
          bsPrefix="defaultNotification"
          onClose={() => setShow(false)}
          dismissible={props.dismissible}
          variant={variant}
        >
          <div className={`notificationBody ${props.bodyWrapperClass ? props.bodyWrapperClass : ""}`}>
            <div className={`bodyClassName ${props.bodyClassName ? props.bodyClassName : ""}`}>
              {!props.hideIcon && icon}
              {props.children}
            </div>
            {props.showButton ? (
              <div className="alertButtonWrapper">
                <button className={`alert_btn ${variantButton}`} onClick={props.onClick}>
                  {props.buttonText}
                </button>
              </div>
            ) : null}
          </div>
        </Alert>
      </div>
    );
  }
  return null;
};

export default AlertNotification;
