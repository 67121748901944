import _ from "lodash";
import { useSlice } from "./useSlice";

export const useOrgDateFormat = (): string => {
  const organization: any = useSlice((state) => state.organizationSettingsGeneral);
  const userOrganization: any = useSlice((state) => state.user);

  const settings = organization?.settings;
  if (settings && !_.isEmpty(settings)) return settings.dateFormat;

  const userOrganizationSettings = userOrganization?.organization;
  if (userOrganizationSettings && !_.isEmpty(userOrganization)) return userOrganizationSettings.dateFormat;

  return "DD MMM YYYY";
};
