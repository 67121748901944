import styles from "../Filters.module.scss";
import { useGetTechnologiesQuery } from "@api/audienceExplorerApi";
import { useState } from "react";
import FormikSelect from "@ui/Formik/FormikSelect";
import { useFormikContext } from "formik";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const FilterCompanyTechnology = () => {
  const {
    values: {
      company: { technologies },
    },
  } = useFormikContext<SearchModel>();
  const [searchInput, setSearchInput] = useState("");

  const { data: options } = useGetTechnologiesQuery(searchInput, { skip: searchInput === "" });

  return (
    <FormikSelect
      name="company.technologies"
      options={options}
      displayName="Select technology"
      value={technologies}
      placeholder="e.g. HubSpot"
      onInputChange={(input: string) => setSearchInput(input)}
      dropdownButtonClass={styles.selectDropdown}
      disabled={technologies?.length >= 20}
      allowIncludeExclude={false}
      isAutoComplete
      isMulti
      isSearchable
      enableReinitialize
      hideNoOptions
      requireInputToShowOptions
      showValuesOutside
    />
  );
};

export default FilterCompanyTechnology;
