import React from "react";
const NewAudienceIcon = (props) => (
  <svg className={props.imgStyle} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.19592 7.85199C7.04859 7.83932 6.90592 7.83299 6.76792 7.83299C6.08755 7.82269 5.41227 7.9518 4.78368 8.21235C4.15509 8.4729 3.5865 8.85938 3.11293 9.34799C2.62432 9.82157 2.23783 10.3902 1.97728 11.0188C1.71673 11.6473 1.58763 12.3226 1.59793 13.003C1.59949 13.0906 1.58309 13.1776 1.54972 13.2586C1.51635 13.3396 1.46673 13.4129 1.40393 13.474C1.34284 13.5368 1.26953 13.5864 1.18852 13.6198C1.10751 13.6532 1.02052 13.6696 0.932925 13.668C0.845327 13.6696 0.758336 13.6532 0.677327 13.6198C0.596317 13.5864 0.523007 13.5368 0.461925 13.474C0.399117 13.4129 0.349497 13.3396 0.31613 13.2586C0.282763 13.1776 0.266357 13.0906 0.267925 13.003C0.264563 11.8836 0.551306 10.7823 1.1002 9.8067C1.6491 8.83106 2.44142 8.01428 3.39992 7.43599C4.36262 6.85216 5.46137 6.5305 6.58692 6.50299C6.69485 6.9898 6.90218 7.44907 7.19592 7.85199Z"
      fill="white"
    />
    <path
      d="M15.6029 12.563H13.9769V10.937C13.9784 10.8494 13.962 10.7624 13.9287 10.6814C13.8953 10.6004 13.8457 10.5271 13.7829 10.466C13.6576 10.3418 13.4883 10.2721 13.3119 10.2721C13.1354 10.2721 12.9661 10.3418 12.8409 10.466C12.778 10.5271 12.7284 10.6004 12.6951 10.6814C12.6617 10.7624 12.6453 10.8494 12.6469 10.937V12.563H11.0209C10.9333 12.5614 10.8463 12.5778 10.7653 12.6112C10.6842 12.6446 10.6109 12.6942 10.5499 12.757C10.4256 12.8823 10.356 13.0516 10.356 13.228C10.356 13.4044 10.4256 13.5737 10.5499 13.699C10.6109 13.7618 10.6842 13.8114 10.7653 13.8448C10.8463 13.8782 10.9333 13.8946 11.0209 13.893H12.6469V15.519C12.6465 15.6064 12.6634 15.6931 12.6967 15.7739C12.7299 15.8548 12.7789 15.9283 12.8407 15.9901C12.9026 16.0519 12.976 16.1009 13.0569 16.1342C13.1378 16.1675 13.2244 16.1844 13.3119 16.184C13.3993 16.1844 13.4859 16.1675 13.5668 16.1342C13.6477 16.1009 13.7211 16.0519 13.783 15.9901C13.8448 15.9283 13.8938 15.8548 13.927 15.7739C13.9603 15.6931 13.9773 15.6064 13.9769 15.519V13.893H15.6029C15.6903 13.8934 15.7769 13.8765 15.8578 13.8432C15.9387 13.8099 16.0121 13.7609 16.074 13.6991C16.1358 13.6373 16.1848 13.5638 16.218 13.4829C16.2513 13.4021 16.2683 13.3154 16.2679 13.228C16.2683 13.1406 16.2513 13.0539 16.218 12.973C16.1848 12.8922 16.1358 12.8187 16.074 12.7569C16.0121 12.6951 15.9387 12.6461 15.8578 12.6128C15.7769 12.5795 15.6903 12.5626 15.6029 12.563Z"
      fill="white"
    />
    <path
      d="M13.1638 9.51499C12.2756 9.08593 11.3013 8.86468 10.3148 8.86799C9.45898 8.85376 8.60928 9.015 7.81813 9.34178C7.02699 9.66856 6.31118 10.1539 5.71483 10.768C5.10078 11.3643 4.6154 12.0802 4.28862 12.8713C3.96184 13.6624 3.8006 14.5121 3.81483 15.368C3.81326 15.4556 3.82967 15.5426 3.86304 15.6236C3.8964 15.7046 3.94602 15.7779 4.00883 15.839C4.06991 15.9018 4.14322 15.9514 4.22423 15.9848C4.30524 16.0182 4.39223 16.0346 4.47983 16.033C4.56743 16.0346 4.65442 16.0182 4.73543 15.9848C4.81644 15.9514 4.88975 15.9018 4.95083 15.839C5.01364 15.7779 5.06326 15.7046 5.09663 15.6236C5.12999 15.5426 5.1464 15.4556 5.14483 15.368C5.13453 14.6876 5.26364 14.0123 5.52419 13.3838C5.78474 12.7552 6.17122 12.1866 6.65983 11.713C7.13341 11.2244 7.702 10.8379 8.33059 10.5774C8.95918 10.3168 9.63446 10.1877 10.3148 10.198C10.6628 10.1983 11.0097 10.2355 11.3498 10.309C11.884 9.90604 12.5054 9.63408 13.1638 9.51499Z"
      fill="white"
    />
    <path
      d="M6.56793 6.49997C6.50687 6.20825 6.47604 5.91101 6.47593 5.61297C6.47593 5.45297 6.48193 5.29897 6.49393 5.15097C6.03639 5.09607 5.61677 4.86946 5.31993 4.51697C5.01177 4.1682 4.84426 3.71734 4.84993 3.25197C4.84618 2.99909 4.89429 2.74813 4.99129 2.51456C5.0883 2.281 5.23213 2.06979 5.41393 1.89397C5.58975 1.71218 5.80096 1.56834 6.03452 1.47133C6.26809 1.37433 6.51905 1.32622 6.77193 1.32997C7.10975 1.32811 7.4418 1.4176 7.73293 1.58897C8.01842 1.75114 8.25476 1.98748 8.41693 2.27297C8.814 2.05468 9.24505 1.90491 9.69193 1.82997C9.42736 1.28319 9.01384 0.822189 8.49893 0.499971C7.98389 0.169441 7.38389 -0.00427172 6.77193 -2.85889e-05C6.34386 -0.00685671 5.91893 0.0740929 5.52336 0.237827C5.12778 0.401561 4.76995 0.644606 4.47193 0.951971C4.16456 1.24999 3.92152 1.60782 3.75779 2.0034C3.59405 2.39898 3.5131 2.8239 3.51993 3.25197C3.50854 4.08493 3.82745 4.88851 4.40693 5.48697C4.96448 6.09778 5.74178 6.46216 6.56793 6.49997Z"
      fill="white"
    />
    <path
      d="M10.3197 8.86482C9.89174 8.87065 9.46706 8.78924 9.07162 8.62556C8.67619 8.46189 8.31752 8.21923 8.01889 7.91267C7.71228 7.61413 7.4705 7.25638 7.30698 6.86091C7.14346 6.46544 7.06245 6.04076 7.0687 5.61286C7.06274 5.18501 7.14348 4.76025 7.30698 4.36482C7.47048 3.96939 7.71251 3.61177 8.01889 3.31306C8.31752 3.0065 8.67619 2.76384 9.07162 2.60017C9.46706 2.43649 9.89174 2.35508 10.3197 2.36091C10.7476 2.35485 11.1723 2.43599 11.5677 2.59968C11.9632 2.76337 12.321 3.00631 12.6195 3.31306C12.9259 3.61177 13.1679 3.96939 13.3314 4.36482C13.4949 4.76025 13.5766 5.18501 13.5706 5.61286C13.5757 6.04259 13.4925 6.469 13.3275 6.86579C13.1624 7.26259 12.919 7.62156 12.6107 7.92097C12.3132 8.22601 11.9558 8.46714 11.5619 8.62947C11.1679 8.79179 10.7457 8.87174 10.3197 8.86482ZM10.3197 3.69099C10.0666 3.68788 9.81505 3.73643 9.58139 3.83357C9.34773 3.93071 9.13654 4.07435 8.9603 4.25593C8.77857 4.43201 8.63444 4.64331 8.53745 4.87703C8.44045 5.11074 8.39219 5.3618 8.39584 5.61482C8.39231 5.86737 8.44142 6.11794 8.53842 6.35115C8.63542 6.58436 8.7787 6.79522 8.9603 6.97078C9.13622 7.15278 9.34766 7.29694 9.58139 7.39411C9.81512 7.49129 10.0666 7.53936 10.3197 7.53572C10.5703 7.54024 10.8184 7.49318 11.0501 7.39753C11.2819 7.30189 11.4919 7.16004 11.6664 6.98005C11.8503 6.80385 11.9963 6.59178 12.0951 6.35701C12.1938 6.12223 12.2433 5.86949 12.2406 5.61482C12.2442 5.3618 12.196 5.11074 12.099 4.87703C12.002 4.64331 11.8578 4.43201 11.6761 4.25593C11.5 4.075 11.2893 3.93166 11.056 3.83503C10.8227 3.73841 10.5722 3.69052 10.3197 3.69392V3.69099Z"
      fill="white"
    />
  </svg>
);

export default NewAudienceIcon;
