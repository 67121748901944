import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../../helper/api/api";

export const fetchOrganizationCreditInfoRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_CREDIT_INFO_REQUEST,
  };
};

export const fetchOrganizationCreditInfoSuccess = (events) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_CREDIT_INFO_SUCCEEDED,
    result: events,
  };
};

export const fetchOrganizationCreditInfoFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_CREDIT_INFO_FAILED,
    result: error,
  };
};

export const fetchOrganizationCreditInfo = (organizationId) => {
  return (dispatch) => {
    dispatch(fetchOrganizationCreditInfoRequest());
    let data = {
      organizationId: organizationId,
    };
    return api(
      formUrl(API.OUTBOSS.organizations.getOrganizationCreditsInfo, data)
    )
      .then((response) => {
        dispatch(fetchOrganizationCreditInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchOrganizationCreditInfoFailed(error.message));
      });
  };
};
