import React from "react";

// Images
import HubSpotLogo from "../../../../assets/Images/CRM/HubSpotLogo/HubSpotLogo";

//Styles
import styles from "./AllowSelectedCRM.module.scss";

const AllowSelectedCRM = (props) => {
  return (
    <div className={styles.componentWrapper}>
      <HubSpotLogo width="30%" height="100%" />
      <p>Allow Outbase application to connect to your HubSpot CRM account. </p>
    </div>
  );
};

export default AllowSelectedCRM;
