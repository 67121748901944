import Moment from "moment";

export const dateToTime = (date?: Date): string | null => {
  if (date !== undefined) {
    return Moment(date).format("HH:mm");
  }
  return null;
};

export const timeToDate = (time?: string): Date | null => {
  let date = null;

  if (time != null && time !== "" && typeof time === "string") {
    const timeSplit = time.split(":");

    if (timeSplit.length >= 2) {
      date = new Date(2001, 1, 1, parseInt(timeSplit[0]), parseInt(timeSplit[1]), 0); //any date will do
    }
  }
  return date;
};

export const removeSecondsFromTime = (time: string | null): string => {
  let newTime = time;

  if (time != null && time !== "" && typeof time === "string") {
    const timeSplit = time.split(":");

    if (timeSplit.length >= 2) {
      newTime = timeSplit[0] + ":" + timeSplit[1];
    }
  }
  return newTime as string;
};

export const removeSecondsFromTimeWithOffset = (time?: string): string => {
  let newTime = time;

  if (time != null && time !== "" && typeof time === "string") {
    const timeSplit = time.split(":");

    let offset = getTimeZoneOffset();
    let hoursOffset = offset / 60;
    let newHour = parseInt(timeSplit[0]) - hoursOffset;

    if (newHour < 0) newHour = 24 + newHour;

    if (timeSplit.length >= 2) {
      newTime = newHour + ":" + timeSplit[1];
    }
  }
  return newTime as string;
};

export const getTimeZoneOffset = (): number => {
  let date = new Date();
  let offset = date.getTimezoneOffset();
  return offset;
};

export const dateToUTC = (initialDate?: Date): Moment.Moment | null => {
  if (initialDate) {
    var newDate = new Date(initialDate);
    var utcDate = Moment.utc();
    utcDate.set("year", newDate.getFullYear());
    utcDate.set("month", newDate.getMonth());
    utcDate.set("date", newDate.getDate());

    return utcDate;
  }

  return null;
};

export const addHours = (numOfHours: number, date: Date = new Date()): Date | undefined => {
  if (date == null) return;
  let cloneDate = new Date(date.valueOf());

  cloneDate.setTime(cloneDate.getTime() + numOfHours * 60 * 60 * 1000);
  return cloneDate;
};

export const addOffsetToDate = (date: Date): Date => {
  let offset = getTimeZoneOffset();
  let hours = (offset / 60) * -1;

  let newDate = addHours(hours, date);
  return newDate as Date;
};
