import React from "react";

//Styles
import styles from "./EmptyState.module.scss";

interface EmptyStateProps {
  componentClass?: string;
  contentWrapperClass?: string;
  titleClass?: string;
  title?: string | JSX.Element;
  image?: JSX.Element;
  content?: string;
  description?: string;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  return (
    <div id="emptyStateImage" className={styles.emptyStateWrapper + " " + (props.componentClass ? props.componentClass : "")}>
      {props.title && <h1 className={props.titleClass}>{props.title}</h1>}
      {props.image && <div className={styles.imageWrapper}>{props.image}</div>}
      <div className={styles.contentWrapper + " " + (props.contentWrapperClass ? props.contentWrapperClass : "")}>
        <>{props.content}</>
        {props.description && <p>{props.description}</p>}
      </div>
    </div>
  );
};

export default EmptyState;
