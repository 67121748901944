import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const fetchGlobalSearchProspectsRequest = () => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_PROSPECTS_REQUEST,
  };
};

export const fetchGlobalSearchProspectsSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_PROSPECTS_SUCCEEDED,
    result: setup,
  };
};

export const fetchGlobalSearchProspectsFailed = (error) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_PROSPECTS_FAILED,
    result: error,
  };
};

export const fetchGlobalSearchProspects = (param) => {
  return (dispatch) => {
    dispatch(fetchGlobalSearchProspectsRequest());
    return api(formUrl(API.prospects.globalSearch, { search: param }))
      .then((response) => {
        dispatch(fetchGlobalSearchProspectsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGlobalSearchProspectsFailed(error.message));
      });
  };
};
/////////////////////////////////////////////////////////////////
export const fetchGlobalSearchCompaniesRequest = () => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_COMPANIES_REQUEST,
  };
};

export const fetchGlobalSearchCompaniesSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_COMPANIES_SUCCEEDED,
    result: setup,
  };
};

export const fetchGlobalSearchCompaniesFailed = (error) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_COMPANIES_FAILED,
    result: error,
  };
};

export const fetchGlobalSearchCompanies = (param) => {
  return (dispatch) => {
    dispatch(fetchGlobalSearchCompaniesRequest());
    return api(formUrl(API.companies.globalSearch, { search: param }))
      .then((response) => {
        dispatch(fetchGlobalSearchCompaniesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGlobalSearchCompaniesFailed(error.message));
      });
  };
};
///////////////////////////////////////////////////
export const fetchGlobalSearchCampaignsRequest = () => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_CAMPAIGNS_REQUEST,
  };
};

export const fetchGlobalSearchCampaignsSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_CAMPAIGNS_SUCCEEDED,
    result: setup,
  };
};

export const fetchGlobalSearchCampaignsFailed = (error) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SEARCH_CAMPAIGNS_FAILED,
    result: error,
  };
};

export const fetchGlobalSearchCampaigns = (param) => {
  return (dispatch) => {
    dispatch(fetchGlobalSearchCampaignsRequest());
    return api(formUrl(API.campaigns.globalSearch, { search: param }))
      .then((response) => {
        dispatch(fetchGlobalSearchCampaignsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGlobalSearchCampaignsFailed(error.message));
      });
  };
};
/////////////////////////////////////////////////////
export const searchRecords = (value) => {
  return {
    type: actionTypes.GLOBAL_SEARCH_RECORDS,
    result: value,
  };
};
