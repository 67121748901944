import * as actionTypes from "./actionTypes";
import api, { API } from "../../../../helper/api/api";

//FILTER//////////////////////////////////
export const setFilterReport = (type, value) => {
  return {
    type: actionTypes.SET_FILTER_REPORT,
    filterType: type,
    filterValue: value,
  };
};

//ORGANIZATION REPORT///////////////////////////////
export const fetchOrganizationReportRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_REQUEST,
  };
};

export const fetchOrganizationReportSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationReportFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_FAILED,
    result: error,
  };
};

export const fetchOrganizationReport = (filter) => {
  return (dispatch) => {
    dispatch(fetchOrganizationReportRequest());
    return api(API.reports.getOrganizationReport, filter)
      .then((response) => {
        if (response.data != null) {
          dispatch(fetchOrganizationReportSuccess(response.data));
        } else {
          dispatch(fetchOrganizationReportFailed("Error loading report."));
        }
      })
      .catch((error) => {
        dispatch(fetchOrganizationReportFailed(error.message));
      });
  };
};
///////////////ORGANIZATION BY DAY//////////////////////////////////////////////////////////////
export const fetchOrganizationReportByDayRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_DAY_REQUEST,
  };
};

export const fetchOrganizationReportByDaySuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_DAY_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationReportByDayFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_DAY_FAILED,
    result: error,
  };
};

export const fetchOrganizationReportByDay = (filters) => {
  return (dispatch) => {
    dispatch(fetchOrganizationReportByDayRequest());
    return api(API.reports.getReportByDay, filters)
      .then((response) => {
        if (response.data != null) {
          dispatch(fetchOrganizationReportByDaySuccess(response.data));
        } else {
          dispatch(fetchOrganizationReportByDayFailed("Error loading report."));
        }
      })
      .catch((error) => {
        dispatch(fetchOrganizationReportByDayFailed(error.message));
      });
  };
};
////////////////////ORGANIZATION BY MONTH///////////////////////////////////////////////
export const fetchOrganizationReportByMonthRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_MONTH_REQUEST,
  };
};

export const fetchOrganizationReportByMonthSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_MONTH_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationReportByMonthFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_MONTH_FAILED,
    result: error,
  };
};

export const fetchOrganizationReportByMonth = (filters) => {
  return (dispatch) => {
    dispatch(fetchOrganizationReportByMonthRequest());
    return api(API.reports.getReportByMonth, filters)
      .then((response) => {
        if (response.data != null) {
          dispatch(fetchOrganizationReportByMonthSuccess(response.data));
        } else {
          dispatch(
            fetchOrganizationReportByMonthFailed("Error loading report.")
          );
        }
      })
      .catch((error) => {
        dispatch(fetchOrganizationReportByMonthFailed(error.message));
      });
  };
};

////////////////////ORGANIZATION BY INDUSTRY///////////////////////////////////////////////
export const fetchOrganizationReportByIndustryRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_REQUEST,
  };
};

export const fetchOrganizationReportByIndustrySuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationReportByIndustryFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_FAILED,
    result: error,
  };
};

export const fetchOrganizationReportByIndustry = (filters) => {
  return (dispatch) => {
    dispatch(fetchOrganizationReportByIndustryRequest());
    return api(API.reports.getReportByIndustry, filters)
      .then((response) => {
        if (response.data != null) {
          dispatch(fetchOrganizationReportByIndustrySuccess(response.data));
        } else {
          dispatch(
            fetchOrganizationReportByIndustryFailed("Error loading report.")
          );
        }
      })
      .catch((error) => {
        dispatch(fetchOrganizationReportByIndustryFailed(error.message));
      });
  };
};

////////////////////ORGANIZATION BY COMPANY SIZE///////////////////////////////////////////////
export const fetchOrganizationReportByCompanySizeRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_REQUEST,
  };
};

export const fetchOrganizationReportByCompanySizeSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationReportByCompanySizeFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_FAILED,
    result: error,
  };
};

export const fetchOrganizationReportByCompanySize = (filters, direction) => {
  if(!direction) direction = "asc";
  return (dispatch) => {
    dispatch(fetchOrganizationReportByCompanySizeRequest());
    return api(API.reports.getReportByCompanySize, {filters, direction})
      .then((response) => {
        if (response.data != null) {
          dispatch(fetchOrganizationReportByCompanySizeSuccess(response.data));
        } else {
          dispatch(
            fetchOrganizationReportByCompanySizeFailed("Error loading report.")
          );
        }
      })
      .catch((error) => {
        dispatch(fetchOrganizationReportByCompanySizeFailed(error.message));
      });
  };
};

////////////////////FILTERED BY STAGE///////////////////////////////////////////////
export const fetchOrganizationReportByStageRequest = () => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_STAGE_REQUEST,
  };
};

export const fetchOrganizationReportByStageSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_STAGE_SUCCEEDED,
    result: setup,
  };
};

export const fetchOrganizationReportByStageFailed = (error) => {
  return {
    type: actionTypes.FETCH_ORGANIZATION_REPORT_BY_STAGE_FAILED,
    result: error,
  };
};

export const fetchOrganizationReportByStage = (filters) => {
  return (dispatch) => {
    dispatch(fetchOrganizationReportByStageRequest());
    return api(API.reports.getReportByStage, filters)
      .then((response) => {
        if (response.data != null) {
          dispatch(fetchOrganizationReportByStageSuccess(response.data));
        } else {
          dispatch(
            fetchOrganizationReportByStageFailed("Error loading report.")
          );
        }
      })
      .catch((error) => {
        dispatch(fetchOrganizationReportByStageFailed(error.message));
      });
  };
};

//Run Rates REPORT///////////////////////////////
export const fetchRunRatesReportRequest = () => {
  return {
    type: actionTypes.FETCH_RUN_RATES_REPORT_REQUEST,
  };
};

export const fetchRunRatesReportSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_RUN_RATES_REPORT_SUCCEEDED,
    result: setup,
  };
};

export const fetchRunRatesReportFailed = (error) => {
  return {
    type: actionTypes.FETCH_RUN_RATES_REPORT_FAILED,
    result: error,
  };
};

export const fetchRunRatesReport = (filter) => {
  return (dispatch) => {
    dispatch(fetchRunRatesReportRequest());
    return api(API.reports.getReportByRunRates, filter)
      .then((response) => {
        dispatch(fetchRunRatesReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRunRatesReportFailed(error.message));
      });
  };
};

//////CLEAR_INITIATED_REPORT///////////
export const clearInitiatedReport = () => {
  return {
    type: actionTypes.CLEAR_INITIATED_REPORT,
  };
};
