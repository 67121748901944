import React from "react";

//Styles
import styles from "./CompaniesSearchRow.module.scss";

//Components
import UserPhoto from "../../../../UI/UserPhoto/UserPhoto";

const CompaniesSearchRow = (props) => {
  return (
    <div className={styles.rowStyle}>
      <div className="flex flex_center">
        <UserPhoto alt="org-photo" isCompanyImage={true} size="small" src={props.imageUrl} />
      </div>
      <div className={styles.details}>
        {/* //<UserPhoto size='small' src={props.imageUrl} /> */}
        <span className={`${styles.name} f14`}>{props.name}</span>
        <div className={styles.industryDetails}>
          <span>{props.industry}</span>
          <span>{props.country}</span>
        </div>
      </div>
    </div>
  );
};

export default CompaniesSearchRow;
