import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../helper/api/api";
import { toastMessages } from "../../helper/toastMessagesConstants";

//Styles
import styles from "./Exclusions.module.scss";

//Components
import Modal from "../../components/UI/Modal/Modal";

const DeleteExclusionForm = (props) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const deleteExclusion = () => {
    setSaveButtonDisabled(true);
    let data = { exclusionId: props.exclusionId };
    api(API.exclusions.remove, data)
      .then((res) => {
        toast.success(toastMessages.exclusions.successDeleteExclusion);
        props.getExclusions();
        props.handleClose();
        setSaveButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setSaveButtonDisabled(false);
      });
  };

  return (
    <Modal
      title="Delete Exclusion"
      dialogClassName={styles.newExclusionsForm}
      handleClose={props.handleClose}
      handleSave={deleteExclusion}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      saveButtonDisabled={saveButtonDisabled}
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>Are you sure you want to delete this exclusion?</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DeleteExclusionForm;
