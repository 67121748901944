import { FC } from "react";

// Styles
import styles from "./FilterButtons.module.scss";

// Components
import Button from "../UI/Button/Button";

interface FilterButtonsProps {
  categories: string[];
  activeCategory: string | null;
  allCategoriesCount: number;
  counts: { [key: string]: number };
  setActiveCategory: (category: string | null) => void;
}

const FilterButtons: FC<FilterButtonsProps> = ({ categories, activeCategory, allCategoriesCount, counts, setActiveCategory }) => {
  const handleCategoryClick = (category: string) => {
    setActiveCategory(category === activeCategory ? null : category);
  };

  return (
    <div className={styles.filterWrapper}>
      <Button
        id="allCategories"
        variant={activeCategory === null ? "dark-500" : "grayDefault"}
        onClick={() => setActiveCategory(null)}
        classes={styles.filterButton}
      >
        All Categories ({allCategoriesCount})
      </Button>
      {categories.map((category) => (
        <Button
          id="category"
          key={category}
          variant={activeCategory === category ? "dark-500" : "grayDefault"}
          onClick={() => handleCategoryClick(category)}
          disabled={counts[category] === 0}
          classes={styles.filterButton}
        >
          {category} ({counts[category] || 0})
        </Button>
      ))}
    </div>
  );
};

export default FilterButtons;
