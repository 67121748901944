import React from "react";
import svg from "./Graph.svg";

const Graph = (props) => (
  <svg className={props.imgStyle} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 7.99901V11.999C0 12.3528 0.140546 12.6921 0.390719 12.9423C0.640893 13.1925 0.980201 13.333 1.334 13.333H2.668C3.02111 13.3325 3.3596 13.192 3.60928 12.9423C3.85897 12.6926 3.99947 12.3541 4 12.001V8.00101C4.00026 7.82582 3.96602 7.65231 3.89922 7.49036C3.83242 7.32841 3.73438 7.1812 3.61069 7.05714C3.48701 6.93308 3.3401 6.8346 3.17835 6.76732C3.0166 6.70004 2.84318 6.66527 2.668 6.66501H1.334C0.980201 6.66501 0.640893 6.80556 0.390719 7.05573C0.140546 7.3059 0 7.64521 0 7.99901ZM1.334 7.99901H2.668V11.999H1.334V7.99901Z"
      fill="#586473"
    />
    <path
      d="M9.49902 1.334C9.49902 0.980548 9.35875 0.641542 9.10901 0.391426C8.85927 0.14131 8.52047 0.000529914 8.16702 0H6.83302C6.65784 0 6.48437 0.0345048 6.32253 0.101545C6.16068 0.168584 6.01362 0.266846 5.88974 0.39072C5.76587 0.514593 5.66761 0.661652 5.60057 0.8235C5.53353 0.985349 5.49902 1.15882 5.49902 1.334V12C5.49902 12.1752 5.53353 12.3487 5.60057 12.5105C5.66761 12.6723 5.76587 12.8194 5.88974 12.9433C6.01362 13.0672 6.16068 13.1654 6.32253 13.2325C6.48437 13.2995 6.65784 13.334 6.83302 13.334H8.16702C8.52047 13.3335 8.85927 13.1927 9.10901 12.9426C9.35875 12.6925 9.49902 12.3535 9.49902 12V1.334ZM6.83302 12V1.334H8.16702V12H6.83302Z"
      fill="#586473"
    />
    <path
      d="M14.998 4.66799C14.998 4.31463 14.8577 3.97573 14.608 3.72577C14.3582 3.47581 14.0194 3.33526 13.666 3.33499H12.332C12.1569 3.33486 11.9835 3.36924 11.8216 3.43617C11.6598 3.5031 11.5127 3.60127 11.3888 3.72507C11.2649 3.84886 11.1667 3.99585 11.0996 4.15764C11.0326 4.31944 10.998 4.49286 10.998 4.66799V12C10.9978 12.1753 11.0321 12.3489 11.099 12.511C11.166 12.673 11.2642 12.8202 11.3881 12.9442C11.512 13.0682 11.6592 13.1666 11.8212 13.2336C11.9831 13.3007 12.1567 13.3351 12.332 13.335H13.666C13.8412 13.3349 14.0146 13.3002 14.1763 13.233C14.3381 13.1659 14.485 13.0675 14.6087 12.9435C14.7324 12.8195 14.8304 12.6724 14.8972 12.5105C14.9641 12.3486 14.9983 12.1751 14.998 12V4.66799ZM12.33 12V4.66799H13.664V12H12.33Z"
      fill="#586473"
    />
    <path
      d="M0.667 16H3.335C3.5119 16 3.68155 15.9297 3.80664 15.8047C3.93173 15.6796 4.002 15.5099 4.002 15.333C4.002 15.1561 3.93173 14.9865 3.80664 14.8614C3.68155 14.7363 3.5119 14.666 3.335 14.666H0.667C0.4901 14.666 0.320446 14.7363 0.19536 14.8614C0.0702729 14.9865 0 15.1561 0 15.333C0 15.5099 0.0702729 15.6796 0.19536 15.8047C0.320446 15.9297 0.4901 16 0.667 16Z"
      fill="#586473"
    />
    <path
      d="M6.16602 14.666C5.98912 14.666 5.81947 14.7363 5.69438 14.8614C5.56929 14.9865 5.49902 15.1561 5.49902 15.333C5.49902 15.5099 5.56929 15.6796 5.69438 15.8047C5.81947 15.9297 5.98912 16 6.16602 16H8.83402C9.01092 16 9.18058 15.9297 9.30566 15.8047C9.43075 15.6796 9.50102 15.5099 9.50102 15.333C9.50102 15.1561 9.43075 14.9865 9.30566 14.8614C9.18058 14.7363 9.01092 14.666 8.83402 14.666H6.16602Z"
      fill="#586473"
    />
    <path
      d="M14.333 14.666H11.665C11.4881 14.666 11.3185 14.7363 11.1934 14.8614C11.0683 14.9865 10.998 15.1561 10.998 15.333C10.998 15.5099 11.0683 15.6796 11.1934 15.8047C11.3185 15.9297 11.4881 16 11.665 16H14.333C14.5099 16 14.6796 15.9297 14.8047 15.8047C14.9298 15.6796 15 15.5099 15 15.333C15 15.1561 14.9298 14.9865 14.8047 14.8614C14.6796 14.7363 14.5099 14.666 14.333 14.666Z"
      fill="#586473"
    />
  </svg>
);

export default Graph;
