import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "../../../../components/UI/Modal/Modal";
import { connect } from "react-redux";
import { fetchResponseDetails } from "../../../../store/Deals/Table/actions/deals";

// Styles
import styles from "./ResponseEmailModal.module.scss";
import { useOrgDateTimeFormat } from "../../../../helper/hooks/useOrgDateTimeFormat";
import moment from "moment";

const ResponseEmailModal = (props) => {
  const { id, fetchResponseDetails, responseDetails } = props;
  let dateTimeFormat = useOrgDateTimeFormat();

  useEffect(() => {
    fetchResponseDetails(id);
  }, [id]);

  return (
    <Modal
      dialogClassName="customModal"
      title="Response Details"
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton={true}
      saveButtonText="OK"
      btnType="submit"
      backdrop={true}
      size="lg"
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.modalBody}>
            <div className={styles.details}>
              <p className="font600">
                Company Name: <span>{responseDetails.companyName}</span>
              </p>
              <p className="font600">
                Company Size: <span>{responseDetails.companySize}</span>
              </p>
              <p className="font600">
                Date of Response:{" "}
                <span>
                  {moment(responseDetails.dateOfResponse).format(
                    dateTimeFormat
                  )}
                </span>
              </p>
            </div>
            <div className={styles.messaageInfo}>
              <p className={styles.titleInfo}> Message Information:</p>
              <p>From Name: {responseDetails.fromName}</p>
              <p>From Email: {responseDetails.fromEmail}</p>
              <p>To: {responseDetails.to}</p>
              <p>Message Body:</p>
              <div
                dangerouslySetInnerHTML={{
                  __html: responseDetails.messageBody,
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    responseDetails: state.deals.responseDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResponseDetails: (id) => dispatch(fetchResponseDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseEmailModal);
