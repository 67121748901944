import React from "react";

//Images and Icons
import IconFileWord from "../assets/Icons/IconFileWord/IconFileWord";
import IconFileCsv from "../assets/Icons/IconFileCsv/IconFileCsv";
import IconXlsxFile from "../assets/Icons/IconXlsxFile/IconXlsxFile";
import IconFilePdf from "../assets/Icons/IconFilePdf/IconFilePdf";
import IconFileImage from "../assets/Icons/IconFileImage/IconFileImage";
import IconFileText from "../assets/Icons/IconFileText/IconFileText";
import IconFilePpt from "../assets/Icons/IconFilePpt/IconFilePpt";

export const renderDocumentsFAIconsFromExtention = (extension) => {
  switch (extension.toLowerCase()) {
    case "docx":
      return <IconFileWord imgStyle="mR5" />;
    case "csv":
      return <IconFileCsv imgStyle="mR5" />;
    case "xlsx":
      return <IconXlsxFile imgStyle="mR5" />;
    case "pptx":
      return <IconFilePpt imgStyle="mR5" />;
    case "pdf":
      return <IconFilePdf imgStyle="mR5" />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <IconFileImage imgStyle="mR5" />;
    default:
      return <IconFileText imgStyle="mR5" />;
  }
};
