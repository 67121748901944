import React, { FC } from "react";
import styles from "./TypingAnimation.module.scss";

const TypingAnimation: FC = () => {
  return (
    <div className={styles.typingAnimation}>
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </div>
  );
};

export default TypingAnimation;
