import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faSpinner } from "@fortawesome/free-solid-svg-icons";

//Styles
import styles from "./Spinner.module.scss";

interface SpinnerProps {
  size?: string;
  spinnerClass?: string;
  description?: string;
  descriptionClass?: string;
  faCircleNotch?: boolean;
}

const Spinner: FC<SpinnerProps> = (props) => {
  let fontSize = props.size != null ? props.size : "3.75rem";
  return (
    <div
      className={`${styles.wrap} ${props.spinnerClass ? props.spinnerClass : ""} ${props.description && styles.flexColumn}`}
      style={{ fontSize: fontSize }}
    >
      <FontAwesomeIcon className={styles.spinner} icon={props.faCircleNotch ? faCircleNotch : faSpinner} spin />
      {props.description && <p className={`${styles.description} ${props.descriptionClass}`}>{props.description}</p>}
    </div>
  );
};

export default Spinner;
