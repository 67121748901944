import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Styles
import styles from "./Pricing.module.scss";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import PricingTable from "./PricingTable/PricingTable";
import GroupButtons from "../../components/UI/GroupButtons/GroupButtons";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const Pricing = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [antCheck, setCheck] = useState(true);
  const [validCouponEntered, setValidCouponEntered] = useState(false);

  useEffect(() => {
    if (activeTab === 0) setCheck(false);
    else setCheck(true);
  }, [activeTab]);

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title="Pricing" />
      <div className={styles.contentWrapper}>
        <div className={styles.buttonsWrapper}>
          <div className={`${styles.toggleButtonsWrapper} flex_center mB10`}>
            <GroupButtons leftButtonName="Monthly" rightButtonName="Annual" setActiveTab={setActiveTab} activeTab={activeTab} />
          </div>
        </div>

        {activeTab === 1 ? (
          <>
            {" "}
            <h1 className={styles.savePlan}>Save 10% with our annual payment plan</h1>
            <p className="f16 textCenter secondary_text_color">
              Sign up for a whole year of powerful sales prospecting and enjoy a 10% discount when paying annually.
            </p>
          </>
        ) : (
          ""
        )}

        <PricingTable
          {...props}
          typeOfBilling={antCheck}
          period={activeTab}
          setCheck={setCheck}
          setActiveTab={setActiveTab}
          setValidCouponEntered={setValidCouponEntered}
        />
      </div>
    </PageContentWrapper>
  );
};
const mapStateToProps = (state) => {
  return {
    isRenewedSubscription: state.billingAndPayment.creditInfo.isRenewedSubscription,
  };
};

export default connect(mapStateToProps)(Pricing);
