import React from "react";

//Styles
import styles from "./InfoLinks.module.scss";

const InfoLinks = (props) => {
  // const { setShowProductTour } = props;
  return (
    <div className={`${styles.infoLinkDrop} ${props.dropdownClass}`}>
      <div className={styles.wrap}>
        <p className={`${styles.dropdownHeader} font700 f16 m0`}>Help & getting started</p>
        {/* <a
          onClick={() => setShowProductTour(true)}
          id="productTourGetStarted"
          href="#"
          rel="noopener noreferrer"
          className={`${styles.links} primary_link`}
        >
          Getting Started Checklist
        </a>
        <hr /> */}
        <a
          href="https://help.outbase.com/getting-started/your-first-campaign "
          rel="noopener noreferrer"
          target="_blank"
          className={styles.links}
        >
          Getting started guide
        </a>
        <hr />
        <a href="https://help.outbase.com/user-guide/introduction" rel="noopener noreferrer" target="_blank" className={styles.links}>
          User documentation
        </a>
      </div>
    </div>
  );
};

export default InfoLinks;
