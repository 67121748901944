import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import Modal from "../../../components/UI/Modal/Modal";
import DatePicker from "react-datepicker";

//Styles

const SyncModal = (props) => {
  const [date, setDate] = useState(new Date());

  const saveHandler = () => {
    props.handleSave(date);
  };

  return (
    <Modal
      title="Sync inbox"
      handleClose={props.handleClose}
      handleSave={() => saveHandler()}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Sync"
      btnType="submit"
      customBodyClass="overflow-visible"
    >
      <>
        <p className="mB15">
          Starting a sync for <b>{props.email}</b>.<br /> Choose a starting date:
        </p>
        <DatePicker
          showTimeSelect={false}
          selected={date}
          onChange={(val) => setDate(val)}
          className={props.datePickerClass}
          placeholderText="Pick a date"
        />
      </>
    </Modal>
  );
};

export default SyncModal;
