import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

// Helper
import { waitDaysDefaultValue } from "../../../../../helper/scheduleConstants";
import { actionPageTypeEnum } from "../../../../../helper/enums/actionPageTypes";

// Style
import styles from "./Wait.module.scss";

//Images & Icons
import SectionCompany from "../../../../../assets/Icons/SectionCompany/SectionCompany";

// Components
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";

const Wait = (props) => {
  const { waitDays, activeStepId, isWait } = props;
  const daysInputRef = useRef();
  const [daysValue, setDaysValue] = useState(waitDays ? waitDays : waitDaysDefaultValue);

  useEffect(() => {
    if (props.currentPage == actionPageTypeEnum.wait) {
      let days = waitDays ? waitDays : waitDaysDefaultValue;
      setDaysValue(days);
      let obj = {
        id: props.activeStepId,
        days: days,
      };
      props.addWait(obj);
    }
  }, [props.activeStepId]);

  useEffect(() => {
    if (!waitDays) setDaysValue(waitDaysDefaultValue);
    else setDaysValue(waitDays);
  }, [activeStepId]);

  const handleSave = (e) => {
    if (daysInputRef.current.value === "0") {
      setDaysValue(waitDaysDefaultValue);
      let obj = {
        id: props.activeStepId,
        days: waitDaysDefaultValue,
      };
      props.addWait(obj);
    } else {
      setDaysValue(daysInputRef.current.value);
      let obj = {
        id: props.activeStepId,
        days: daysInputRef.current.value,
      };
      props.addWait(obj);
    }
  };

  const handleBlur = () => {
    if (daysInputRef.current.value === "") {
      setDaysValue(waitDaysDefaultValue);
      let obj = {
        id: props.activeStepId,
        days: waitDaysDefaultValue,
      };
      props.addWait(obj);
    }
  };

  return (
    <div className={styles.tableWrapper}>
      <SectionTitle icon={<SectionCompany />} title="Wait Time" description="Set Wait Time" descriptionTextStyle="noWrap" />
      <div className={`${styles.setTimeWrapper} flex flexRow`}>
        <div className={`${styles.inputWrapper} flex`}>
          <div className={styles.waitPeriodWrapper}>
            <p>
              We recommend waiting {waitDaysDefaultValue} days between emails as this allows you to contact prospects on different days each
              week e.g. if the first email is sent on a Monday, with {waitDaysDefaultValue} working days wait, the next will be sent the
              following Tuesday.
            </p>
            <Form.Group className={styles.formGroup} controlId="days">
              <Form.Label className={`${styles.inputLabel}`}>Days</Form.Label>
              <Form.Control
                onChange={(e) => handleSave(e)}
                onBlur={(e) => handleBlur(e)}
                ref={daysInputRef}
                className={styles.setValueInput}
                type="number"
                value={daysValue}
                defaultValue={waitDaysDefaultValue}
                min={1}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeStepId: state.workflowSteps.activeStepId,
    currentPage: state.workflowSteps.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Wait);
