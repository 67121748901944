import { WorkflowStepDetails } from "@models/workflowSteps/WorkflowStepDetails";
import { campaignApi } from "@api/campaignApi";
import { CampaignDetailsModel } from "@models/campaign/CampaignDetailsModel";
import { CampaignStatusEnum } from "@models/enums/CampaignStatusEnum";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/configureStore";
import { workflowStepsApi } from "@api/workflowStepsApi";
import { SendingScheduleDetailsModel } from "@models/schedule/SendingScheduleDetailsModel";
import { sendingScheduleApi } from "@api/sendingScheduleApi";
import { AudienceDetailsModel } from "@models/audience/AudienceDetailsModel";
import { audienceApi } from "@api/audienceApi";

// Define the state type
export interface CampaignState {
  campaign: CampaignDetailsModel;
  workflowSteps: WorkflowStepDetails[];
  sendingSchedule: SendingScheduleDetailsModel;
  audience: AudienceDetailsModel | null;
  returnToCampaignId: number | null;
  openPushAudienceModal: boolean;
}

// Define the initial state
const initialState: CampaignState = {
  campaign: {
    id: 0,
    name: "",
    responderExclusionsCrossCampaigns: 0,
    organizationName: "",
    statusName: "",
    trackClicks: false,
    trackOpens: false,
    emailAccounts: [],
    createdBy: "",
    status: CampaignStatusEnum.Setup,
    created: "",
    lastActiveDate: "",
    prospects: 0,
    companies: 0,
    leads: 0,
    organizationId: 0,
    lastSendDate: null,
    nextSendDate: null,
    audienceId: null,
    campaignSetupProgressModel: {
      isSetSchedule: false,
      isSetWorkflow: false,
      hasNewProspects: false,
      hasAvailableContacts: false,
      isDraftInSequence: false,
      isSetProspectsInCampaign: false,
      maxSteps: 0,
      stepsDone: 0,
      canGoLive: false,
    },
    campaignIntegrations: [],
  },
  workflowSteps: [],
  sendingSchedule: {
    maxProspectsPerCompany: 0,
    prospectsPerDay: 0,
    publicHolidaysExcluded: false,
    startDate: null,
    targetedCountries: [],
    timeZone: "",
    timeZoneModel: {
      id: "",
      name: "",
      currentOffsetString: "",
      displayName: "",
      ticks: 0,
    },
    monday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    tuesday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    wednesday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    thursday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    friday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    saturday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    sunday: {
      enabled: false,
      day: null,
      from: null,
      to: null,
    },
    pauseDates: {
      startDate: null,
      endDate: null,
    },
  },
  audience: null,
  returnToCampaignId: null,
  openPushAudienceModal: false,
};

export const campaignConfigSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setReturnToCampaignId: (state, action: PayloadAction<number>) => {
      state.returnToCampaignId = action.payload;
    },
    setOpenPushAudienceModal: (state, action: PayloadAction<boolean>) => {
      state.openPushAudienceModal = action.payload;
    },
    resetCampaignState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(campaignApi.endpoints.getCampaign.matchFulfilled, (state, action: PayloadAction<CampaignDetailsModel>) => {
      state.campaign = action.payload;
    });

    builder.addMatcher(
      workflowStepsApi.endpoints.getWorkflowSteps.matchFulfilled,
      (state, action: PayloadAction<WorkflowStepDetails[]>) => {
        state.workflowSteps = action.payload;
      }
    );

    builder.addMatcher(
      sendingScheduleApi.endpoints.getSchedule.matchFulfilled,
      (state, action: PayloadAction<SendingScheduleDetailsModel>) => {
        state.sendingSchedule = action.payload;
      }
    );

    builder.addMatcher(audienceApi.endpoints.getAudienceDetails.matchFulfilled, (state, action: PayloadAction<AudienceDetailsModel>) => {
      state.audience = action.payload;
    });
  },
});

export const selectCampaign = (state: RootState) => state.campaign.campaign; // selector to get the campaign data

export default campaignConfigSlice;
