import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

//REDUX
import { fetchTimelineResponses } from "../../../../store/CompanyActivityOverview/actions/companyActivityOverview";
import { initiate } from "../../../../store/CompanyActivityOverview/Table/actions/companyActivityOverview";

//Helpers
import { numberOfRecords } from "../../../../helper/tableConstants";
import { useOrgDateFormat } from "../../../../helper/hooks/useOrgDateFormat";

// Style
import styles from "../../Shared/EventTimeline.module.scss";

//Images & Icons
import EmptyTemplatesImage from "../../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

//Components
import TimelineWrapper from "../../../../components/UI/Timeline/VerticalTimeline";
import Events from "../../Shared/Events";
import ReceivedEmailModal from "../../../SmartModal/ReceivedEmailModal";
import SentEmailDetailsModal from "../../Shared/SentEmailDetailsModal/SentEmailDetailsModal";
import SkeletonTimeline from "../../../../components/UI/Skeletons/components/SkeletonTimeline/SkeletonTimeline";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";

const EventTimeline = (props) => {
  const [receivedEmailId, setReceivedEmailId] = useState(0);
  const [showReceivedEmailDetails, setShowReceivedEmailDetails] = useState(false);

  const [sentEmailAction, setSentEmailAction] = useState(null);
  const [showSentEmailDetails, setShowSentEmailDetails] = useState(false);
  const [engagedDate, setEngagedDate] = useState("");

  let image = <EmptyTemplatesImage />;

  const handleResponseDetailsClose = () => {
    setShowReceivedEmailDetails(false);
  };

  const handleResponseDetailsShow = (value) => {
    setReceivedEmailId(value);
    setShowReceivedEmailDetails(true);
  };

  const handleSentEmailClose = () => {
    setShowSentEmailDetails(false);
  };

  const handleSentEmailDetailsShow = (value) => {
    setSentEmailAction(value);
    setShowSentEmailDetails(true);
  };

  let dateFormat = useOrgDateFormat();
  const { params, id, isInitiated, isTimelineLoading, actions } = props;

  const records = numberOfRecords[1];

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
      },
    };

    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      props.fetchTimelineResponses(params, Number(id));
    }
    props.setParams(params);
  }, [params, id, isInitiated]);

  useEffect(() => {
    if (!isTimelineLoading && !_.isEmpty(actions)) {
      let firstDate = actions?.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })[0].date;

      setEngagedDate(firstDate);
    }
  }, [actions]);

  let body = null;

  if (isTimelineLoading) {
    body = (
      <>
        {[1, 2].map((n) => (
          <SkeletonTimeline key={n} />
        ))}
      </>
    );
  } else if (_.isEmpty(actions)) {
    body = <EmptyState image={image} title="No activity has been found" />;
  } else {
    body = (
      <div className={styles.timelineCompanyBody}>
        <div className={`${styles.dateTime} border-5`}>
          <span>
            Company Engaged <p>{!_.isEmpty(engagedDate) ? moment(engagedDate).format(dateFormat) : ""}</p>
          </span>
        </div>
        <TimelineWrapper className={styles.verticalTimeline}>
          {actions.map((action, index) => {
            return (
              <Events
                action={action}
                key={index}
                index={index}
                handleResponseDetailsShow={handleResponseDetailsShow}
                handleSentEmailDetailsShow={handleSentEmailDetailsShow}
              />
            );
          })}
        </TimelineWrapper>
      </div>
    );
  }

  return (
    <>
      {body}

      {receivedEmailId !== 0 ? (
        <>
          <ReceivedEmailModal handleClose={handleResponseDetailsClose} show={showReceivedEmailDetails} id={receivedEmailId} />
        </>
      ) : null}

      {sentEmailAction !== 0 ? (
        <>
          <SentEmailDetailsModal handleClose={handleSentEmailClose} show={showSentEmailDetails} action={sentEmailAction} />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.companyActivityOverviewTable.params,
    isInitiated: state.companyActivityOverviewTable.isInitiated,
    isTimelineLoading: state.companyActivityOverview.isTimelineLoading,
    actions: state.companyActivityOverview.listOfResponses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTimelineResponses: (params, id) => dispatch(fetchTimelineResponses(params, id)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTimeline);
