import React from "react";

//Components
import Modal from "../../../../components/UI/Modal/Modal";

const ConfirmModal = (props) => {
  return (
    <Modal
      title="Confirm all"
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Confirm All"
      //   saveButtonDisabled={isSubmitting}
      btnType="submit"
      backdrop="static"
    >
      <p>Are you sure you want to confirm all auto-resolved responses?</p>
    </Modal>
  );
};

export default ConfirmModal;
