import React from "react";

//Styles
import styles from "./SaveChanges.module.scss";

//Components
import Button from "../UI/Button/Button";

const SaveChangesFooter = (props) => {
  let cancelButtonType = "reset";
  if (props.cancelButtonType != null) cancelButtonType = props.cancelButtonType;

  let saveButtonType = "submit";
  if (props.saveButtonType != null) saveButtonType = props.saveButtonType;

  let cancelButtonText = "Cancel";
  if (props.cancelButtonText != null) cancelButtonText = props.cancelButtonText;

  let saveButtonText = "Save Changes";
  if (props.saveButtonText != null) saveButtonText = props.saveButtonText;

  return (
    <div className={`${styles.SaveChangesFooter} ${props.footerClassName ? props.footerClassName : ""} save_changes_footer`}>
      <div id="cancelFooterButton" className="p5">
        <Button
          id={props.cancelButtonId ? props.cancelButtonId : cancelButtonText.replaceAll(" ", "")}
          variant="secondary"
          onClick={props.cancelClick}
          type={cancelButtonType}
          disabled={props.cancelButtonDisabled}
        >
          {cancelButtonText}
        </Button>
      </div>
      {props.showThirdButton && (
        <div id="thirdFooterButton" className="p5">
          <Button
            id={props.thirdButtonText ? props.thirdButtonText.replaceAll(" ", "") : ""}
            variant="secondary"
            onClick={props.thirdButtonClick}
            type={props.thirdButtonType || "button"}
            disabled={props.thirdButtonDisabled}
          >
            {props.thirdButtonText}
          </Button>
        </div>
      )}

      <div id="confirmFooterButton" className="p5">
        <Button
          variant="primary"
          onClick={props.saveChangesClick}
          type={saveButtonType}
          disabled={props.saveButtonDisabled}
          id={props.saveButtonId ? props.saveButtonId : saveButtonText.replaceAll(" ", "")}
        >
          {saveButtonText}
        </Button>
      </div>
    </div>
  );
};

export default SaveChangesFooter;
