import React from "react";
import { toast } from "react-toastify";

//API
import api, { API } from "../../../../../helper/api/api";

//Helpers
import { PERMISSIONS } from "../../../../../helper/permissionConstants";
import { termsAndPaymentStatusEnum } from "../../../../../helper/enums/contractStatusEnum";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

//Style
import styles from "./WrittenAgreement.module.scss";

//Images & Icons
import IconAgreement from "../../../../../assets/Icons/IconAgreement/IconAgreement";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

//Components
import Button from "../../../../../components/UI/Button/Button";
import AlertNotification from "../../../../../components/UI/AlertNotification/AlertNotification";
import Permission from "../../../../../components/auth/Permission";

const WrittenAgreement = (props) => {
  let contract = null;
  let infoBanner = null;

  const handleCancel = () => {
    api(API.billingAndPayment.cancelContract)
      .then((res) => {
        toast.success(toastMessages.billing.cancelContractPending);
      })
      .catch((error) => {
        const errorList = errorResponseToList(error);
        if (errorList.length > 0) {
          errorList.forEach((err) => toast.error(err.message));
        } else {
          toast.error(error.message);
        }
      })
      .finally(() => {});
  };

  const handleResend = () => {
    api(API.billingAndPayment.requestReminder)
      .then((res) => {
        toast.success(toastMessages.billing.successRequestReminderForSignedContract);
      })
      .catch((error) => {
        const errorList = errorResponseToList(error);
        if (errorList.length > 0) {
          errorList.forEach((err) => toast.error(err.message));
        } else {
          toast.error(error.message);
        }
      })
      .finally(() => {});
  };
  switch (props.contractStatus) {
    case termsAndPaymentStatusEnum.awaitingPaymentConfirmation:
      contract = (
        <div className={styles.buttonWrapper}>
          <Permission has={PERMISSIONS.contract.cancel}>
            <div className={styles.pendingSigning}>
              <Button id="cancelAgreement" onClick={handleCancel} children="Cancel" variant="secondary" />
            </div>
          </Permission>

          <div className={styles.pendingSigning}>
            <Button id="pendingSigning" onClick={handleResend} children="Resend" variant="primary" classes={styles.darkButton} />
            <p>Pending Signing...</p>
          </div>
        </div>
      );
      infoBanner = (
        <AlertNotification variant="info_alert" dismissible={true}>
          Check your email and sign the agreement to continue.
        </AlertNotification>
      );
      break;
    case termsAndPaymentStatusEnum.accepted:
      contract = (
        <div className={styles.signedWrapper}>
          <CheckboxCircleFillIcon color="var(--first-stage-color)" />
          <p>Signed</p>
        </div>
      );
      break;
    default:
      break;
  }
  return (
    <>
      <div className={`${styles.componentWrapper} flex space_between`}>
        <a href={props.url ? props.url : ""} target={props.url ? "_blank" : ""} rel="noopener noreferrer">
          <div className={styles.documentDetailsWrapper}>
            <IconAgreement />
            <p className={styles.documentName}>{props.documentName}</p>
          </div>
        </a>
        {contract}
      </div>
      {infoBanner}
    </>
  );
};

export default WrittenAgreement;
