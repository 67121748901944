import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../helper/api/api";

export const fetchAdvanceEmailTestDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_TEST_DETAILS_REQUEST,
  };
};

export const fetchAdvanceEmailTestDetailsSuccess = (details) => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_TEST_DETAILS_SUCCEEDED,
    result: details,
  };
};

export const fetchAdvanceEmailTestDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_TEST_DETAILS_FAILED,
    result: error,
  };
};

export const fetchAdvanceEmailTestDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchAdvanceEmailTestDetailsRequest());
    return api(
      formUrl(API.emailDeliverabilityTest.getAdvanceEmailTestDetails, {
        id: id,
      })
    )
      .then((response) => {
        dispatch(fetchAdvanceEmailTestDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAdvanceEmailTestDetailsFailed(error.message));
      });
  };
};

export const fetchAdvanceEmailSendTestRequest = () => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_SEND_TEST_REQUEST,
  };
};

export const fetchAdvanceEmailSendTestSuccess = (status) => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_SEND_TEST_SUCCEEDED,
    result: status,
  };
};

export const fetchAdvanceEmailSendTestFailed = (error) => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_SEND_TEST_FAILED,
    result: error,
  };
};

export const fetchAdvanceEmailSendTest = (model) => {
  return (dispatch) => {
    dispatch(fetchAdvanceEmailSendTestRequest());
    return api(API.emailDeliverabilityTest.sendAdvanceEmailTest, model)
      .then((response) => {
        dispatch(fetchAdvanceEmailSendTestSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAdvanceEmailSendTestFailed(error.message));
      });
  };
};

export const fetchListOfAdvanceEmailTestsRequest = () => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_TEST_LIST_REQUEST,
  };
};

export const fetchListOfAdvanceEmailTestsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_TEST_LIST_SUCCEEDED,
    result: data,
  };
};

export const fetchListOfAdvanceEmailTestsFailed = (error) => {
  return {
    type: actionTypes.FETCH_ADVANCE_EMAIL_TEST_LIST_FAILED,
    result: error,
  };
};

export const fetchListOfAdvanceEmailTests = (emailTemplateId) => {
  return (dispatch) => {
    dispatch(fetchListOfAdvanceEmailTestsRequest());
    return api(
      formUrl(API.emailDeliverabilityTest.listOfAdvanceEmailTest, {
        emailTemplateId: emailTemplateId,
      })
    )
      .then((response) => {
        dispatch(fetchListOfAdvanceEmailTestsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListOfAdvanceEmailTestsFailed(error.message));
      });
  };
};

export const setDictionaryValues = (dictionary) => {
  return {
    type: actionTypes.SET_DICTIONARY_VALUES,
    result: dictionary,
  };
};
