import React from "react";
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

//Helpers
import { formatNumberHelper } from "../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../helper/hooks/useGroupDecimalSeparator";

// Style
import "./StackedBarChart.scss";

const StackedBarChart = (props) => {
  let separators = useGroupDecimalSeparator();
  return (
    <ResponsiveContainer className={props.data.length < 1 ? "pointerEventsNone" : " "} width="100%" height={300}>
      <BarChart
        data={props.data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          tickFormatter={(tick) => {
            return formatNumberHelper(tick, separators.numberGroupSeperator, separators.numberDecimalSeperator);
          }}
          type="number"
          domain={[0, "dataMax"]}
        />
        <Tooltip formatter={(value) => formatNumberHelper(value, separators.numberGroupSeperator, separators.numberDecimalSeperator)} />
        <Legend verticalAlign="top" height={45} align="left" />
        {props.bar.map((item, index) => {
          return (
            <Bar
              stackId={1}
              key={index}
              legendType={item.legendType}
              barSize={item.barSize}
              name={item.name}
              dataKey={item.dataKey}
              fill={item.fill}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
