import React, { FC } from "react";
import Action from "../Action";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";

interface ActionCloneProps {
  size?: number;
  classNameIcon?: string;
}

const ActionClone: FC<ActionCloneProps> = ({ size = 20, classNameIcon = "iconGray", ...props }) => {
  return <Action svgIcon={<FileCopyLineIcon size={size} className={classNameIcon} />} {...props} />;
};

export default ActionClone;
