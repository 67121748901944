import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../../helper/api/api";
import Modal from "../../../components/UI/Modal/Modal";
import { toastMessages } from "../../../helper/toastMessagesConstants";

//Styles
import styles from "./User.module.scss";

const RemoveUserAccessForm = (props) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const removeAccess = () => {
    setSaveButtonDisabled(true);
    let userOrgId = parseInt(props.userOrganizationId);
    let data = { userId: props.userId, userOrganizationId: userOrgId };
    api(API.OUTBOSS.users.removeUserAccess, data)
      .then((res) => {
        toast.success(toastMessages.outboss.user.successRemoveAccess);
        props.fetchUserDetailsDataTable();
        props.handleClose();
        setSaveButtonDisabled(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setSaveButtonDisabled(false);
      });
  };

  return (
    <Modal
      title={props.userId > 0 ? "Remove All Organizations" : "Remove User Access"}
      dialogClassName={styles.newExclusionsForm}
      handleClose={props.handleClose}
      handleSave={removeAccess}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      saveButtonDisabled={saveButtonDisabled}
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>
              {props.userId > 0
                ? "The system will remove this user’s access to all organizations where he has access to. Do you want to continue? "
                : "The system will remove this user’s access to organization " + props.organizationName + ". Do you want to continue? "}
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default RemoveUserAccessForm;
