import { api } from "./api";
import { ExclusionCountModel } from "@models/exclusion/ExclusionCountModel";

export const exclusionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExclusionCount: builder.query<ExclusionCountModel, number>({
      query: (campaignId) => ({
        url: `/exclusions/exclusions-count?campaignId=${campaignId}`,
        method: "GET",
      }),
      providesTags: ["ExclusionCount"],
    }),
  }),
});

export const { useGetExclusionCountQuery } = exclusionApi;
