import React from "react";

//Components
import SkeletonAvatarText from "../../../components/UI/Skeletons/components/SkeletonAvatarText/SkeletonAvatarText";
import SkeletonDealNavigator from "../../../components/UI/Skeletons/components/SkeletonDealNavigator/SkeletonDealNavigator";
import SkeletonPieChart from "../../../components/UI/Skeletons/components/SkeletonPieChart/SkeletonPieChart";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import SkeletonThumbnail from "../../../components/UI/Skeletons/components/SkeletonThumbnail/SkeletonThumbnail";
import SkeletonTimeline from "../../../components/UI/Skeletons/components/SkeletonTimeline/SkeletonTimeline";

//Style
import styles from "../Sandbox.module.scss";

const SkeletonSandbox = () => {
  return (
    <div className={styles.skeletonWrapperComponent}>
      <h6>Skeleton Avatar with text</h6>
      <div className={styles.componentBgd}>
        <SkeletonAvatarText />
      </div>
      <h6 className="mT15">Skeleton table</h6>
      <div className={styles.componentBgd}>
        <SkeletonTable />
      </div>
      <h6 className="mT15">Skeleton pie chart</h6>
      <div className={styles.componentBgd}>
        <SkeletonPieChart />
      </div>
      <h6 className="mT15">Skeleton timeline</h6>
      <div className={styles.componentBgd}>
        <SkeletonTimeline />
      </div>
      <h6 className="mT15">Skeleton deal navigator</h6>
      <div
        className={styles.componentBgd}
        style={{ width: "300px", margin: "auto" }}
      >
        <SkeletonDealNavigator />
      </div>
      <h6 className="mT15">Skeleton thumbnail</h6>
      <div className={styles.componentBgd}>
        <SkeletonThumbnail />
      </div>
    </div>
  );
};

export default SkeletonSandbox;
