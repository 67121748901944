import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Redux
import { fetchGlobalSearchProspects } from "../../../../store/GlobalSearch/actions/globalSearch";

//Hooks
import { useOrganizationId } from "../../../../helper/hooks/useOrganizationId";

//Styles
import styles from "./ProspectSearch.module.scss";

//Components
import ProspectSearchRow from "../ProspectSearchRow/ProspectSearchRow";
import SkeletonAvatarText from "../../../UI/Skeletons/components/SkeletonAvatarText/SkeletonAvatarText";

const ProspectSearch = (props) => {
  const organizationId = useOrganizationId();
  const { param } = props;

  let list = null;

  if (props.error) {
    list = <h2>Error getting Prospects</h2>;
  }

  if (props.isLoading) {
    list = <SkeletonAvatarText />;
  }

  if (props.isLoaded) {
    list =
      props.listProspects.length > 0 ? (
        props.listProspects.map((p, index) => (
          <Link
            key={index}
            to={{
              pathname: "/" + organizationId + "/prospect-activity-overview/" + p.id,
            }}
            onClick={props.hrefClicked}
            className="text_decoration_none"
          >
            <ProspectSearchRow
              name={p.firstName}
              lastName={p.lastName}
              jobPosition={p.jobTitle}
              company={p.companyName}
              campaign={p.campaignName}
            />
          </Link>
        ))
      ) : (
        <p className={styles.noResultMessage}>No results found</p>
      );
  }

  return (
    <>
      <div className={styles.titleWrapper}>
        <h5 className={styles.title}>Prospects</h5>
        {props.listProspects.length > 0 ? (
          <Link
            to={{
              pathname: "/" + props.organizationId + "/globalsearch",
              search: "search=" + props.param,
              aboutProps: {
                page: 1,
              },
            }}
          >
            <p onClick={props.hrefClicked} className={styles.link}>
              Show all
            </p>
          </Link>
        ) : null}
      </div>
      {list}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.globalSearh.search,
    isLoading: state.globalSearh.prospectsIsLoading,
    isLoaded: state.globalSearh.prospectsIsLoaded,
    error: state.globalSearh.prospectsError,
    listProspects: state.globalSearh.listProspects,
    organizationId: state.user.organization?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGlobalSearchProspects: (param) => dispatch(fetchGlobalSearchProspects(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectSearch);
