//Components
import Modal from "../../../../../components/UI/Modal/Modal";

const CancelModal = (props) => {
  return (
    <Modal
      dialogClassName={"customModal"}
      customBodyClass={"paymentModalBody"}
      title={"Are you sure you want to cancel?"}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      variant="danger"
      saveButtonText="I'm sure"
      btnType="submit"
      closeButtonText={"Keep current plan"}
      saveButtonDisabled={props.submitting}
    >
      <p>
        By canceling your subscription, you will no longer be charged and your team will lose access to the Outbase workspace{" "}
        <strong>{props.date}</strong>
      </p>
    </Modal>
  );
};

export default CancelModal;
