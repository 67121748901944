export const organizationStatusEnum = {
  active: 0,
  pendingRenewal: 1,
  inactive: 2,
};

export const organizationStatusOptions = [
  { value: 0, label: "Active" },
  { value: 1, label: "Pending renewal" },
  { value: 2, label: "Inactive" },
];

export const organizationStatusOptionsString = [
  { value: "0", label: "Active" },
  { value: "1", label: "Pending renewal" },
  { value: "2", label: "Inactive" },
];