import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

//Redux
import { fetchTimelineResponses } from "../../../../../store/ProspectActivityOverview/actions/prospectActivityOverview";

//Helpers
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";

// Styles
import styles from "../../../Shared/EventTimeline.module.scss";

//Images & Icons
import EmptyTemplatesImage from "../../../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

//Components
import TimelineWrapper from "../../../../../components/UI/Timeline/VerticalTimeline";
import Events from "../../../Shared/Events";
import ReceivedEmailModal from "../../../../SmartModal/ReceivedEmailModal";
import SentEmailDetailsModal from "../../../Shared/SentEmailDetailsModal/SentEmailDetailsModal";
import SkeletonTimeline from "../../../../../components/UI/Skeletons/components/SkeletonTimeline/SkeletonTimeline";
import EmptyState from "../../../../../components/UI/EmptyState/EmptyState";

const EventTimeline = (props) => {
  const [receivedEmailId, setReceivedEmailId] = useState(0);
  const [showReceivedEmailDetails, setShowReceivedEmailDetails] = useState(false);

  const [sentEmailAction, setSentEmailAction] = useState(null);
  const [showSentEmailDetails, setShowSentEmailDetails] = useState(false);
  const [engagedDate, setEngagedDate] = useState("");

  let image = <EmptyTemplatesImage />;

  const handleResponseDetailsClose = () => {
    setShowReceivedEmailDetails(false);
  };

  const handleResponseDetailsShow = (value) => {
    setReceivedEmailId(value);
    setShowReceivedEmailDetails(true);
  };

  const handleSentEmailClose = () => {
    setShowSentEmailDetails(false);
  };

  const handleSentEmailDetailsShow = (value) => {
    setSentEmailAction(value);
    setShowSentEmailDetails(true);
  };

  let dateFormat = useOrgDateFormat();
  const { params, id, actions, isTimelineLoading } = props;

  useEffect(() => {
    props.fetchTimelineResponses(params, Number(id));
    props.setParams(params);
  }, [params, id]);

  useEffect(() => {
    if (!isTimelineLoading && !_.isEmpty(actions)) {
      let firstDate = actions?.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })[0].date;

      setEngagedDate(firstDate);
    }
  }, [actions]);

  let body = null;

  if (isTimelineLoading) {
    body = (
      <>
        {[1, 2].map((n) => (
          <SkeletonTimeline key={n} />
        ))}
      </>
    );
  } else if (_.isEmpty(actions)) {
    body = <EmptyState image={image} title="No activity has been found" />;
  } else {
    body = (
      <div className={styles.timelineProspectsBody}>
        <div className={`${styles.dateTime} border-5`}>
          <span>
            Prospect Engaged <p>{!_.isEmpty(engagedDate) ? moment(engagedDate).format(dateFormat) : ""}</p>
          </span>
        </div>
        <TimelineWrapper className={styles.verticalTimeline}>
          {actions.map((action, index) => {
            return (
              <Events
                action={action}
                index={index}
                key={index}
                handleResponseDetailsShow={handleResponseDetailsShow}
                handleSentEmailDetailsShow={handleSentEmailDetailsShow}
              />
            );
          })}
        </TimelineWrapper>
      </div>
    );
  }

  return (
    <>
      {body}

      {receivedEmailId !== 0 ? (
        <>
          <ReceivedEmailModal handleClose={handleResponseDetailsClose} show={showReceivedEmailDetails} id={receivedEmailId} />
        </>
      ) : null}

      {sentEmailAction !== 0 ? (
        <>
          <SentEmailDetailsModal handleClose={handleSentEmailClose} show={showSentEmailDetails} action={sentEmailAction} />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.prospectActivityOverview.params,
    actions: state.prospectActivityOverview.listOfResponses,
    isTimelineLoading: state.prospectActivityOverview.isTimelineLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTimelineResponses: (params, id) => dispatch(fetchTimelineResponses(params, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTimeline);
