import React, { useEffect, useState, useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

//Redux
import { connect } from "react-redux";
import {
  fetchDeferredIntrest,
  changePage,
  sortData,
  filterRecords,
  setFilter,
  clearFilter,
  initiate,
  numRecords,
} from "../../../store/Deals/Table/actions/deferredInterestDeals";

//Helpers
import api, { API } from "../../../helper/api/api";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { formatNumberHelper } from "../../../helper/formatNumberHelper";
import { useGroupDecimalSeparator } from "../../../helper/hooks/useGroupDecimalSeparator";
import { dealsType } from "./dealsType";
import { fileDownload } from "../../../helper/fileDownloadHelper";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";

// Styles
import styles from "./Prospects.module.scss";

// Icons & Images
import ExternalLinkLineIcon from "remixicon-react/ExternalLinkLineIcon";
import EmptyData from "../../../assets/Images/EmptyData/EmptyData";

// Components
import { dealsTableColumns } from "./dealsTableColumns";
import LeadConversationModal from "../modal/LeadConversationModal";
import UserPhoto from "../../../components/UI/UserPhoto/UserPhoto";
import Permission from "../../../components/auth/Permission";
import Button from "../../../components/UI/Button/Button";
import Table from "../../../components/DataTable/Table";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";

const DeferredInterest = (props) => {
  let dateFormat = useOrgDateFormat();
  const { params, rows, initiate, isInitiated, fetchDeferredIntrest, totalCount } = props;
  const [height, setHeight] = useState(0);
  const headerContainerRef = useRef();
  let separators = useGroupDecimalSeparator();
  let totalCountFormatted = formatNumberHelper(totalCount, separators.numberGroupSeperator, separators.numberDecimalSeperator);
  const organizationId = useOrganizationId();
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    var state = {
      params: {
        length: props.records.value,
        filter: [{ type: "Type", value: [dealsType.DeferredInterest] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchDeferredIntrest(params);
    }
    props.setParams(params);
  }, [params, isInitiated]);

  useEffect(() => {
    let topHeight = headerContainerRef.current.offsetHeight;
    setHeight(window.innerHeight - topHeight);
  }, []);

  const exportDeferredInterest = () => {
    const exportParams = _.cloneDeep(params);
    exportParams.length = totalCount;
    api(API.deals.export, exportParams, "blob")
      .then((res) => {
        fileDownload("DeferredInterestLeads.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [deal, setDeal] = useState({});

  const [showDealsDetailsModal, setShowDealsDetailsModal] = useState(false);

  const handleDealDetailsClose = () => {
    setShowDealsDetailsModal(false);
    fetchDeferredIntrest(params);
  };

  const handleDealDetailsShow = (deal) => {
    setDeal(deal);
    setShowDealsDetailsModal(true);
  };

  let image = <EmptyData className={styles.noDataImage} />;

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r) => {
      const currentDeal = { ...r };

      let image = <UserPhoto alt="org-photo" isCompanyImage={false} size="small" src={currentDeal.image} />;
      r.image = image;

      let companyNameAction = (
        <OverlayTrigger
          key={r.companyName}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-active`}>
              <Popover.Body>{r.cleanCompanyName ? r.cleanCompanyName : r.companyName}</Popover.Body>
            </Popover>
          }
        >
          <span>
            <Link
              className="tableLink"
              to={{
                pathname: "/" + organizationId + "/company-activity-overview/" + r.companyId,
              }}
            >
              {r.cleanCompanyName ? r.cleanCompanyName : r.companyName}
            </Link>
          </span>
        </OverlayTrigger>
      );

      r.companyName = companyNameAction;

      let dateOfResponseAction = !_.isEmpty(currentDeal.dateOfResponse) ? (
        <span className="tableLink" onClick={() => handleDealDetailsShow(currentDeal)}>
          {Moment(r.dateOfResponse).format(dateFormat)}
        </span>
      ) : null;

      r.dateOfResponse = dateOfResponseAction;

      return r;
    });

    setTableRows(tempRows);
  }, [rows]);

  let table = null;

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Deferred Intrest"}
          columns={dealsTableColumns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={params}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          scrollY={true}
          tableClassName={`${styles.customTable} dealsTable`}
          showingOf={false}
          hideLabelShow={true}
          tableHeight={365}
          variant="small"
        />
      ) : (
        <EmptyState image={image} title="No results found" />
      );
  }

  return (
    <>
      <div className={`${styles.resultsTableWrapper}`}>
        {/* <span className={`${styles.description} flex center_flex`}>
            Prospects who have replied to your campaign and asked you to contact
            them at a later date.
          </span> */}
        <div ref={headerContainerRef}>
          <div className={`${styles.columnHeader} flex flexCol flex center_flex`}>
            <h1 className={`${styles.totalCount} ${styles.yellow}`}>{totalCountFormatted}</h1>
            <p className="flex flex_center">
              <span className={`${styles.yellow} ${styles.circle}`} />
              Deferred Interest
            </p>
          </div>

          <div className={`${styles.buttonsWrapper} justify_end`}>
            <Permission has={PERMISSIONS.deals.export}>
              <Button
                id="exportDeferred"
                onClick={exportDeferredInterest}
                variant="secondary-m"
                classes="mR10"
                disabled={tableRows?.length === 0}
              >
                <ExternalLinkLineIcon size={20} className="iconGray500 mR5" />
                Export
              </Button>
            </Permission>
          </div>
        </div>
        {/* <Permission has={PERMISSIONS.deals.view}>
          <Table
            tableName={"Deferred Intrest"}
            columns={dealsTableColumns}
            rows={tableRows}
            pageCount={props.pageCount}
            totalCount={props.totalCount}
            parentCallback={props.changePage}
            parentSort={props.sortData}
            param={params}
            changeNumRecords={props.numRecords}
            parentSearchHandler={props.searchRecords}
            scrollY={true}
            tableClassName={`${styles.customTable} dealsTable`}
            showingOf={false}
            tableHeight={height - 365}
            variant="small"
          />
        </Permission> */}
        <Permission has={PERMISSIONS.deals.view}>{table}</Permission>
      </div>

      <Permission has={PERMISSIONS.deals.view}>
        {!_.isEmpty(deal) ? (
          <>
            <LeadConversationModal handleClose={handleDealDetailsClose} showModal={showDealsDetailsModal} deal={deal} />
          </>
        ) : null}
      </Permission>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.deferredIntrestDeals.params,
    isInitiated: state.deferredIntrestDeals.isInitiated,
    isLoading: state.deferredIntrestDeals.isLoading,
    isLoaded: state.deferredIntrestDeals.isLoaded,
    error: state.deferredIntrestDeals.error,
    rows: state.deferredIntrestDeals.rows,
    totalCount: state.deferredIntrestDeals.totalCount,
    pageCount: state.deferredIntrestDeals.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeferredIntrest: (param) => dispatch(fetchDeferredIntrest(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeferredInterest);
