import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { subDays, endOfDay, startOfDay } from "date-fns";
import _ from "lodash";

//Redux
import {
  setFilterReport,
  fetchOrganizationReport,
  fetchOrganizationReportByDay,
  fetchOrganizationReportByMonth,
  fetchOrganizationReportByIndustry,
  fetchOrganizationReportByCompanySize,
  fetchOrganizationReportByStage,
  clearInitiatedReport,
} from "../../store/Reports/Global/actions/reports";

//Helpers
import { filterData, getFilterValue, getColumnsForReportTable } from "../../helper/tableConstants";
import { addOffsetToDate } from "../../helper/dateHelper";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";

//Styles
import styles from "./CampaignPerformance.module.scss";

//Images & ICons
import EmptyCampaignList from "../../assets/Images/NoResultsFound/NoResultsFound";

// Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import SelectMulti from "../../components/UI/Select/SelectMulti";
import ShowDateRangePicker from "../../components/DataTable/ShowDateRangePicker/ShowDateRangePicker";
import PerformanceBanner from "./PerformanceBanner/PerfomanceBanner";
import Table from "../../components/DataTable/Table";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import SkeletonPerformanceCard from "../../components/UI/Skeletons/components/SkeletonPerformanceCard/SkeletonPerformanceCard";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import useSelectList from "../../helper/hooks/query/useSelectList";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import Accordion from "../../components/UI/Accordion/Accordion";
import { sort } from "semver";

const CampaignPerformance = (props) => {
  const {
    fetchOrganizationReport,
    organizationReport,
    fetchOrganizationReportByDay,
    organizationReportByDay,
    fetchOrganizationReportByMonth,
    organizationReportByMonth,
    filter,
    clearInitiatedReport,
    fetchOrganizationReportByIndustry,
    organizationReportByIndustry,
    fetchOrganizationReportByCompanySize,
    organizationReportByCompanySize,
    fetchOrganizationReportByStage,
    organizationReportByStage,
    organizationReportIsLoading,
    organizationReportIsLoaded,
    industryReportIsLoaded,
    industryReportIsLoading,
    companySizeReportIsLoading,
    companySizeReportIsLoaded,
    stageReportIsLoaded,
    stageReportIsLoading,
    dayReportIsLoaded,
    dayReportIsLoading,
    monthReportIsLoaded,
    monthReportIsLoading,
  } = props;

  const [tableRowsIndustry, setTableRowsIndustry] = useState([]);
  const [tableRowsCompanySize, setTableRowsCompanySize] = useState([]);
  const [tableRowsStage, setTableRowsStage] = useState([]);
  const [tableRowsMonth, setTableRowsMonth] = useState([]);
  const [tableRowsDay, setTableRowsDay] = useState([]);

  const [industryOpen, setIndustryOpen] = useState(false);
  const [companySizeOpen, setCompanySizeOpen] = useState(false);
  const [stageOpen, setStageOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const [dayOpen, setDayOpen] = useState(false);
  const { campaignId } = useParams();

  let image = <EmptyCampaignList className={styles.imageNoResults} />;

  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);
  const { data: emailAccountsList } = useSelectList(SelectListEnum.EmailAccounts);

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Report on your campaign(s), with detailed breakdowns by industry, company sizes, month, etc."}
      href={"https://help.outbase.com/user-guide/reports"}
      linkText="Read More"
      buttonId="startCampaignTour"
      disableStartTourButton
    />
  );

  useEffect(() => {
    fetchOrganizationReport(filter);
  }, []);

  useEffect(() => {
    if (filter.length > 0) {
      fetchOrganizationReport(filter);
    }
    if (industryOpen) {
      fetchOrganizationReportByIndustry(filter);
    }
    if (companySizeOpen) {
      fetchOrganizationReportByCompanySize(filter);
    }
    if (stageOpen) {
      fetchOrganizationReportByStage(filter);
    }
    if (monthOpen) {
      fetchOrganizationReportByMonth(filter);
    }
    if (dayOpen) {
      fetchOrganizationReportByDay(filter);
    }
  }, [filter]);

  const clearPerformanceSettings = async () => {
    if (campaignId === null) {
      await props.clearInitiatedReport();
    }
  };

  const compareBy = (key, ascending) => {
    let reverse = ascending === "asc" ? 1 : -1;
    return function (a, b) {
      if (a[key] < b[key]) return -1 * reverse;
      if (a[key] > b[key]) return 1 * reverse;
      return 0;
    };
  };

  useEffect(() => {
    return () => clearPerformanceSettings();
  }, []);

  let initialParam = {
    sort: {},
    filter: [],
    search: "",
  };

  const [paramIndustry, setParamIndustry] = useState(initialParam);
  const [paramCompanySize, setParamCompanySize] = useState(initialParam);
  const [paramStage, setParamStage] = useState(initialParam);
  const [paramMonth, setParamMonth] = useState(initialParam);
  const [paramDay, setParamDay] = useState(initialParam);

  const sortTable = (dtSort, tableRows, table) => {
    var tempRows = tableRows;
    let sortColumn = dtSort.column;

    if (sortColumn === "title" && (table === "Day" || table === "Month")) {
      sortColumn = "date";
    }

    if (table !== "Company Size" || sortColumn !== "title") {
      tempRows.sort(compareBy(sortColumn, dtSort.direction));
    }
    if (table === "Industry") {
      let paramTemp = paramIndustry;
      paramTemp.sort = dtSort;
      setParamIndustry(paramTemp);
      setTableRowsIndustry(_.cloneDeep(tempRows));
    }
    if (table === "Company Size") {
      let paramTemp = paramCompanySize;
      paramTemp.sort = dtSort;
      setParamCompanySize(paramTemp);
      setTableRowsCompanySize(_.cloneDeep(tempRows));
      if (sortColumn === "title") fetchOrganizationReportByCompanySize(filter, dtSort.direction);
    }
    if (table === "Sequence Email") {
      let paramTemp = paramStage;
      paramTemp.sort = dtSort;
      setParamStage(paramTemp);
      setTableRowsStage(_.cloneDeep(tempRows));
    }
    if (table === "Month") {
      let paramTemp = paramMonth;
      paramTemp.sort = dtSort;
      setParamMonth(paramTemp);
      setTableRowsMonth(_.cloneDeep(tempRows));
    }
    if (table === "Day") {
      let paramTemp = paramDay;
      paramTemp.sort = dtSort;
      setParamDay(paramTemp);
      setTableRowsDay(_.cloneDeep(tempRows));
    }
  };

  let industryColumns = getColumnsForReportTable("Industry");
  let companySizeColumns = getColumnsForReportTable("Company Size");
  let stageColumns = getColumnsForReportTable("Sequence Email");
  let monthColumns = getColumnsForReportTable("Month");
  let dayColumns = getColumnsForReportTable("Day");

  let topBanner = null;
  let industryBody = null;
  let companySizeBody = null;
  let stageBody = null;
  let monthBody = null;
  let dayBody = null;

  const handleMapReports = (report) => {
    report.map((r) => {
      r.clickRate = r?.clickRate?.toFixed(2) ?? 0;
      r.openRate = r?.openRate?.toFixed(2) ?? 0;
      r.leadRateByEmailDelivered = r?.leadRateByEmailDelivered?.toFixed(2) ?? 0;
      r.leadRate = r?.leadRate?.toFixed(2) ?? 0;
      r.emailsPerProspect = r?.emailsPerProspect?.toFixed(2) ?? 0;
      r.openRateByProspect = r?.openRateByProspect?.toFixed(2) ?? 0;
      r.clickRateByProspect = r?.clickRateByProspect?.toFixed(2) ?? 0;

      return r;
    });

    return report;
  };

  useEffect(() => {
    const rowsTempIndustry = _.cloneDeep(organizationReportByIndustry);
    setTableRowsIndustry(rowsTempIndustry);
  }, [organizationReportByIndustry]);

  useEffect(() => {
    const rowsTempCompanySize = _.cloneDeep(organizationReportByCompanySize);
    setTableRowsCompanySize(rowsTempCompanySize);
  }, [organizationReportByCompanySize]);

  useEffect(() => {
    const rowsTempStage = _.cloneDeep(organizationReportByStage);
    setTableRowsStage(rowsTempStage);
  }, [organizationReportByStage]);

  useEffect(() => {
    const rowsTempMonth = _.cloneDeep(organizationReportByMonth);
    setTableRowsMonth(rowsTempMonth);
  }, [organizationReportByMonth]);

  useEffect(() => {
    const rowsTempDay = _.cloneDeep(organizationReportByDay);
    setTableRowsDay(rowsTempDay);
  }, [organizationReportByDay]);

  if (organizationReportIsLoading) {
    topBanner = (
      <div className="flex mB30">
        {" "}
        {[1, 2, 3, 4, 5, 6, 7].map((n) => (
          <SkeletonPerformanceCard key={n} />
        ))}
      </div>
    );
  }

  if (industryReportIsLoading) {
    industryBody = (
      <>
        <SkeletonTable />{" "}
      </>
    );
  }

  if (companySizeReportIsLoading) {
    companySizeBody = (
      <>
        <SkeletonTable />{" "}
      </>
    );
  }

  if (stageReportIsLoading) {
    stageBody = (
      <>
        <SkeletonTable />{" "}
      </>
    );
  }

  if (dayReportIsLoading) {
    dayBody = (
      <>
        <SkeletonTable />{" "}
      </>
    );
  }

  if (monthReportIsLoading) {
    monthBody = (
      <>
        <SkeletonTable />{" "}
      </>
    );
  }

  if (organizationReportIsLoaded) {
    topBanner = <PerformanceBanner organizationReport={organizationReport} />;
  }

  if (industryReportIsLoaded) {
    industryBody =
      tableRowsIndustry.length > 0 ? (
        <Table
          nestedHeader
          tableName={"Industry"}
          columns={industryColumns}
          rows={tableRowsIndustry}
          parentCallback={() => {}}
          parentSort={(event) => {
            sortTable(event, tableRowsIndustry, "Industry");
          }}
          param={paramIndustry}
          parentNumRecords={10}
          parentSearchHandler={() => {}}
          classes={styles.campaignPerformanceTable}
          scrollY={true}
          wrapperTableClass="reportsTable"
          showPagination={false}
        />
      ) : (
        <EmptyState image={image} title="No results found" />
      );
  }

  if (companySizeReportIsLoaded) {
    companySizeBody =
      tableRowsCompanySize.length > 0 ? (
        <Table
          nestedHeader
          tableName={"Size"}
          columns={companySizeColumns}
          rows={tableRowsCompanySize}
          parentCallback={() => {}}
          parentSort={(event) => {
            sortTable(event, tableRowsCompanySize, "Company Size");
          }}
          param={paramCompanySize}
          parentNumRecords={10}
          parentSearchHandler={() => {}}
          classes={styles.campaignPerformanceTable}
          scrollY={true}
          wrapperTableClass="reportsTable"
          showPagination={false}
        />
      ) : (
        <EmptyState image={image} title="No results found" />
      );
  }

  if (stageReportIsLoaded) {
    stageBody =
      tableRowsStage.length > 0 ? (
        <Table
          nestedHeader
          tableName={"Sequence Email"}
          columns={stageColumns}
          rows={tableRowsStage}
          parentCallback={() => {}}
          parentSort={(event) => {
            sortTable(event, tableRowsStage, "Sequence Email");
          }}
          param={paramStage}
          parentNumRecords={10}
          parentSearchHandler={() => {}}
          classes={styles.campaignPerformanceTable}
          scrollY={true}
          wrapperTableClass="reportsTable"
          showPagination={false}
        />
      ) : (
        <EmptyState image={image} title="No results found" />
      );
  }

  if (dayReportIsLoaded) {
    dayBody =
      tableRowsDay.length > 0 ? (
        <>
          <Table
            nestedHeader
            tableName={"Campaigns"}
            columns={dayColumns}
            rows={tableRowsDay}
            parentCallback={() => {}}
            parentSort={(event) => {
              sortTable(event, tableRowsDay, "Day");
            }}
            param={paramDay}
            parentNumRecords={10}
            parentSearchHandler={() => {}}
            classes={styles.campaignPerformanceTable}
            scrollY={true}
            wrapperTableClass="reportsTable"
            showPagination={false}
          />
        </>
      ) : (
        <EmptyState image={image} title="No results found" />
      );
  }

  if (monthReportIsLoaded) {
    monthBody =
      tableRowsMonth.length > 0 ? (
        <Table
          nestedHeader
          tableName={"Campaigns"}
          columns={monthColumns}
          rows={tableRowsMonth}
          parentCallback={() => {}}
          parentSort={(event) => {
            sortTable(event, tableRowsMonth, "Month");
          }}
          param={paramMonth}
          parentNumRecords={10}
          parentSearchHandler={() => {}}
          classes={styles.campaignPerformanceTable}
          scrollY={true}
          wrapperTableClass="reportsTable"
          showPagination={false}
        />
      ) : (
        <EmptyState image={image} title="No results found" />
      );
  }

  const serializeDate = (date) => date.toISOString();

  const filterDate = (obj) => {
    let arrayDates = [];
    if (obj.startDate) {
      arrayDates.push(serializeDate(startOfDay(addOffsetToDate(obj.startDate))));

      if (!obj.endDate || obj.endDate.getTime() === obj.startDate.getTime()) {
        arrayDates.push(serializeDate(endOfDay(addOffsetToDate(obj.startDate))));
      } else {
        arrayDates.push(serializeDate(endOfDay(addOffsetToDate(obj.endDate))));
      }
    }

    filterData("DateRange", arrayDates, props.setFilter);
  };

  let dateOptions = {
    onChange: (c) => filterDate(c),
    showSelectionPreview: true,
    key: "selection",
    moveRangeOnFirstSelection: false,
    months: 2,
    direction: "horizontal",
    editableDateInputs: false,
    startDate: new Date(subDays(Date.now(), 30)),
    endDate: new Date(Date.now()),
    inputRanges: [],
  };

  const handleIndustryFetch = () => {
    if (organizationReportByIndustry === undefined || organizationReportByIndustry.length === 0) {
      fetchOrganizationReportByIndustry(filter);
    }
  };

  const handleCompanyFetch = () => {
    if (organizationReportByCompanySize === undefined || organizationReportByCompanySize.length === 0) {
      fetchOrganizationReportByCompanySize(filter);
    }
  };

  const handleStageFetch = () => {
    if (organizationReportByStage === undefined || organizationReportByStage.length === 0) {
      fetchOrganizationReportByStage(filter);
    }
  };

  const handleMonthFetch = () => {
    if (organizationReportByMonth === undefined || organizationReportByMonth.length === 0) {
      fetchOrganizationReportByMonth(filter);
    }
  };

  const handleDayFetch = () => {
    if (organizationReportByDay === undefined || organizationReportByDay.length === 0) {
      fetchOrganizationReportByDay(filter);
    }
  };

  return (
    <PageContentWrapper>
      <HeaderTitle title="Campaign Performance" icon={icon} />
      {topBanner}
      <div className={styles.filterWrapper}>
        {/* <HeaderSearch
          placeholder={"Search Reports"}
          //textChanged={(text) => props.searchRecords(text)}
          textChanged={(text) => filterData("Search", text, props.setFilter)}
        /> */}
        {campaignId ? null : (
          <div className="mR15">
            <SelectMulti
              isSearchable
              displayName="Campaign"
              options={campaignsList}
              onChange={(c) => filterData("Campaign", c, props.setFilter)}
              value={getFilterValue(filter, "Campaign")}
              dropdownButtonClass="selectFilterDropdown"
            />
          </div>
        )}
        <div className="mR15">
          <SelectMulti
            isSearchable
            displayName="Email Account"
            options={emailAccountsList}
            onChange={(c) => filterData("EmailAccount", c, props.setFilter)}
            value={getFilterValue(props.filter, "EmailAccount")}
            dropdownButtonClass="selectFilterDropdown"
          />
        </div>
        <div className="mR15">
          <ShowDateRangePicker {...dateOptions} />
        </div>
      </div>
      <div className={styles.accordionsWrapper}>
        <Accordion
          bodyClass={styles.accPerformanceBody}
          variant="whiteTransparent"
          header="Industry Report"
          noCaret={true}
          showConfiguration={false}
          eventKey="industry"
          toggleHandler={(open) => {
            setIndustryOpen(open);
            if (open) handleIndustryFetch();
          }}
          body={industryBody}
        />
      </div>
      <div className={styles.accordionsWrapper}>
        <Accordion
          bodyClass={styles.accPerformanceBody}
          variant="whiteTransparent"
          header="Company Size Report"
          noCaret={true}
          showConfiguration={false}
          eventKey="company-size"
          toggleHandler={(open) => {
            setCompanySizeOpen(open);
            if (open) handleCompanyFetch();
          }}
          body={companySizeBody}
        />
      </div>
      <div className={styles.accordionsWrapper}>
        <Accordion
          bodyClass={styles.accPerformanceBody}
          variant="whiteTransparent"
          header="Sequence Report"
          noCaret={true}
          showConfiguration={false}
          eventKey="sequence"
          toggleHandler={(open) => {
            setStageOpen(open);
            if (open) handleStageFetch();
          }}
          body={stageBody}
        />
      </div>
      <div className={styles.accordionsWrapper}>
        <Accordion
          bodyClass={styles.accPerformanceBody}
          variant="whiteTransparent"
          header="Monthly Report"
          noCaret={true}
          showConfiguration={false}
          eventKey="monthly"
          toggleHandler={(open) => {
            setMonthOpen(open);
            if (open) handleMonthFetch();
          }}
          body={monthBody}
        />
      </div>
      <div className={styles.accordionsWrapper}>
        <Accordion
          bodyClass={styles.accPerformanceBody}
          variant="whiteTransparent"
          header="Daily Report"
          noCaret={true}
          showConfiguration={false}
          eventKey="daily"
          toggleHandler={(open) => {
            setDayOpen(open);
            if (open) handleDayFetch();
          }}
          body={dayBody}
        />
      </div>
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationReportIsLoaded: state.reports.organizationReportIsLoaded,
    organizationReportIsLoading: state.reports.organizationReportIsLoading,
    industryReportIsLoaded: state.reports.industryReportIsLoaded,
    industryReportIsLoading: state.reports.industryReportIsLoading,
    companySizeReportIsLoaded: state.reports.companySizeReportIsLoaded,
    companySizeReportIsLoading: state.reports.companySizeReportIsLoading,
    stageReportIsLoaded: state.reports.stageReportIsLoaded,
    stageReportIsLoading: state.reports.stageReportIsLoading,
    dayReportIsLoaded: state.reports.dayReportIsLoaded,
    dayReportIsLoading: state.reports.dayReportIsLoading,
    monthReportIsLoaded: state.reports.monthReportIsLoaded,
    monthReportIsLoading: state.reports.monthReportIsLoading,
    organizationReport: state.reports.organizationReport,
    organizationReportByDay: state.reports.organizationReportByDay,
    organizationReportByMonth: state.reports.organizationReportByMonth,
    organizationReportByIndustry: state.reports.organizationReportByIndustry,
    organizationReportByCompanySize: state.reports.organizationReportByCompanySize,
    organizationReportByStage: state.reports.organizationReportByStage,
    filter: state.reports.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizationReport: (val) => dispatch(fetchOrganizationReport(val)),
    fetchOrganizationReportByDay: (val) => dispatch(fetchOrganizationReportByDay(val)),
    fetchOrganizationReportByMonth: (val) => dispatch(fetchOrganizationReportByMonth(val)),
    fetchOrganizationReportByIndustry: (filter) => dispatch(fetchOrganizationReportByIndustry(filter)),
    fetchOrganizationReportByCompanySize: (filter, direction) => dispatch(fetchOrganizationReportByCompanySize(filter, direction)),
    fetchOrganizationReportByStage: (filter) => dispatch(fetchOrganizationReportByStage(filter)),
    setFilter: (type, value) => dispatch(setFilterReport(type, value)),
    clearInitiatedReport: () => dispatch(clearInitiatedReport()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPerformance);
