export const columns = [
  {
    label: "Template Name",
    field: "templateName",
    type: "text",
    sort: "asc",
  },
  {
    label: "Requested By",
    field: "requestedBy",
    type: "text",
    sort: "asc",
  },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
  },
  {
    label: "Due Date",
    field: "dueDate",
    type: "date",
    sort: "asc",
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
  },
  {
    label: "",
    field: "actions",
    type: "actions",
    width: 150,
  },
];
