import React from "react";

const IconFileCsv = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667Z"
      fill="#1F7244"
    />
    <path
      d="M23.9667 7.99999C23.9333 7.86666 23.9 7.76666 23.8333 7.66666L17.3333 0.399994V7.99999H23.9667Z"
      fill="#1F7244"
    />
    <path
      d="M2 13.6667H22V30H2V13.6667ZM4.16667 2.03333C4.6 2.03333 5.03333 2.16666 5.36667 2.43333L4.9 3.36666C4.76667 3.19999 4.46667 3.06666 4.2 3.06666C3.5 3.06666 3.13333 3.69999 3.13333 4.46666C3.13333 5.23333 3.53333 5.93332 4.2 5.93332C4.53333 5.93332 4.76667 5.76666 4.96667 5.59999L5.43333 6.53333C5.13333 6.76666 4.76667 6.96666 4.13333 6.96666C2.8 6.96666 2.03333 5.93333 2.03333 4.49999C2 3.09999 2.8 2.03333 4.16667 2.03333V2.03333ZM6.56667 5.56666C6.76667 5.76666 7.03333 5.96666 7.33333 5.96666C7.63333 5.96666 7.76667 5.76666 7.76667 5.56666C7.76667 4.89999 6.16667 4.86666 6.16667 3.49999C6.16667 2.69999 6.73333 2.06666 7.66667 2.06666C8.06667 2.06666 8.46667 2.16666 8.8 2.39999L8.4 3.36666C8.23333 3.23333 7.93333 3.06666 7.66667 3.06666C7.36667 3.06666 7.23333 3.26666 7.23333 3.46666C7.23333 4.16666 8.83333 4.13333 8.83333 5.49999C8.83333 6.26666 8.33333 6.96666 7.36667 6.96666C6.9 6.96666 6.4 6.79999 6.03333 6.49999L6.56667 5.56666V5.56666ZM9.46667 2.13333H10.6667L11.2 4.83333C11.2667 5.13333 11.3 5.59999 11.3 5.59999H11.3333C11.3333 5.59999 11.4 5.13333 11.4333 4.83333L12 2.13333H13.1667L11.8667 6.89999H10.7667L9.46667 2.13333V2.13333Z"
      fill="white"
    />
    <path
      d="M16.5 25.8667H19.3333M4.66666 18H7.49999H4.66666ZM8.59999 18H11.4333H8.59999ZM12.5667 18H15.4H12.5667ZM16.5 18H19.3333H16.5ZM4.66666 19.9667H7.49999H4.66666ZM8.59999 19.9667H11.4333H8.59999ZM12.5667 19.9667H15.4H12.5667ZM16.5 19.9667H19.3333H16.5ZM4.66666 21.9333H7.49999H4.66666ZM8.59999 21.9333H11.4333H8.59999ZM12.5667 21.9333H15.4H12.5667ZM16.5 21.9333H19.3333H16.5ZM4.66666 23.9H7.49999H4.66666ZM8.59999 23.9H11.4333H8.59999ZM12.5667 23.9H15.4H12.5667ZM16.5 23.9H19.3333H16.5ZM4.66666 25.8667H7.49999H4.66666ZM8.59999 25.8667H11.4333H8.59999ZM12.5667 25.8667H15.4H12.5667Z"
      stroke="#1F7244"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
);
export default IconFileCsv;
