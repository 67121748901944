import { DropdownModel } from "@models/DropDownModel";

export interface CampaignObjectModel {
  audience: {
    tags: LabelValueModel[];
    companySize: string[];
    companyLocations: {
      country: LabelValueModel[];
      region: LabelValueModel[];
      zipCode: LabelValueModel[];
    };
    contactLocations: {
      country: LabelValueModel[];
      region: LabelValueModel[];
      zipCode: LabelValueModel[];
    };
    range: {
      location: {};
      rangeMiles: number;
    };
    departments: Department[];
    seniority: string[];
    searchKey?: string;
  };
  tone: string;
  companyDescription: {
    websiteURL?: string;
    description?: string;
    productInformation?: string;
    problemInformation?: string;
  };
  settings: {
    hasChanges: boolean;
    locationType: LocationType;
  };
  emailAccounts?: string[];
  emailAccount?: string;
  emailAccountId?: number | null;
  signature?: string;
}

export enum LocationType {
  Country,
  Region,
  ZipCode,
}

export interface Department extends DropdownModel {
  checked: boolean;
}

export interface AssistantExecutionModel {
  id: number;
  assistantId: number;
  assistantExecutionStatus: number;
  currentStep: number;
  totalSteps: number;
  data: CampaignObjectModel;
}

export interface StartExecutionModel {
  slug: string;
  startNew?: boolean;
}

export interface LoadStepDataModel {
  executionId: number;
  step: number;
}

export interface SaveStepDataModel {
  executionId: number;
  step: number;
  data: Object;
  createSearchKey: boolean;
}

export interface LabelValueModel extends DropdownModel {
  includes: boolean;
}

export interface SummaryResponseModel {
  description: string;
  productInfo: string;
  solutionDescription: string;
}
