import * as actionTypes from "./actionTypes";
import api, { API } from "../../../helper/api/api";

export { loadUser, loadUserFromStorage, changeOrganization } from "./user";

export const fetchGlobalSettingsRequest = (error) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SETTINGS_REQUEST,
    result: error,
  };
};

export const fetchGlobalSettingsSuccess = (settings) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SETTINGS_SUCCESS,
    result: settings,
  };
};

export const fetchGlobalSettingsFailed = (settings) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SETTINGS_FAILED,
    result: settings,
  };
};

export const fetchGlobalSettings = () => {
  return (dispatch) => {
    dispatch(fetchGlobalSettingsRequest());

    return api(API.settings.general)
      .then((response) => {
        dispatch(fetchGlobalSettingsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGlobalSettingsFailed(error.message));
      });
  };
};
