export const columns = [
  {
    label: "Campaign",
    field: "name",
    type: "text",
    minWidth: 300,
    sort: "asc",
    flex: 1,
    minimal: "lg",
  },
  {
    label: "Emails",
    field: "emailsSent",
    type: "number",
    columnClass: "textLeft",
    sort: "asc",
    minimal: "sm",
    width: 150,
    maxWidth: 150,
    minWidth: 100,
  },
  {
    label: "Opens",
    field: "opens",
    type: "number",
    columnClass: "textLeft",
    sort: "asc",
    minimal: "sm",
    width: 150,
    minWidth: 100,
  },
  {
    label: "Clicks",
    field: "clicks",
    type: "number",
    columnClass: "textLeft",
    sort: "asc",
    minimal: "sm",
    width: 150,
    minWidth: 100,
  },
  {
    label: "Responses",
    field: "responses",
    type: "number",
    columnClass: "textLeft",
    sort: "asc",
    minimal: "sm",
    width: 150,
    minWidth: 100,
  },
  {
    label: "Leads",
    field: "leads",
    type: "number",
    columnClass: "textLeft",
    sort: "asc",
    minimal: "sm",
    width: 150,
    minWidth: 100,
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
    minimal: "sm",
    width: 150,
    minWidth: 100,
  },
  // {
  //   label: "Last Active Date",
  //   field: "lastActiveDate",
  //   type: "date",
  //   columnClass: "textCenter",
  //   sort: "asc",
  //   minimal: "lg",
  //   width: "110",
  // },
  {
    label: "Actions",
    field: "actions",
    type: "actions",
    columnClass: "textLeft",
    sort: "disabled",
    minimal: "sm",
    width: 150,
    minWidth: 100,
  },
];
