import React from "react";

//Components
import SkeletonElement from "../../SkeletonElements";

const SkeletonTableRow = () => {
  return (
    <>
      <tr>
        <td>
          <SkeletonElement type="text-15 w-4" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-4" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-7" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-5" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-5" />
        </td>
      </tr>
      <tr>
        <td>
          <SkeletonElement type="text-15 w-4" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-4" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-7" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-5" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-5" />
        </td>
      </tr>
      <tr>
        <td>
          <SkeletonElement type="text-15 w-4" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-4" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-7" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-5" />
        </td>
        <td>
          <SkeletonElement type="text-15 w-5" />
        </td>
      </tr>
    </>
  );
};

export default SkeletonTableRow;
