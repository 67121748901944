import React from "react";
import { connect } from "react-redux";

//Helpers
import { formatNumberHelper } from "@helper/formatNumberHelper";

// Styles
import styles from "./CompanyDetails.module.scss";

//Images & Icons;
import CompanyDetailsIcon from "@assets/Icons/CompanyDetailsIcon/CompanyDetailsIcon";
import GlobalFillIcon from "remixicon-react/GlobalFillIcon";
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";

// Components
import UserPhoto from "@components/UI/UserPhoto/UserPhoto";
import SectionTitle from "@components/UI/SectionTitle/SectionTitle";

const CompanyDetails = (props) => {
  const { domain, industry, name, cleanName, size, revenue, linkedInUrl, country } = props.companyDetails;

  return (
    <div className={styles.detailsWrapper}>
      <SectionTitle title="Company details" icon={<CompanyDetailsIcon />} />
      <div className={styles.imageWrapper}>
        <div className="flex_center">
          <UserPhoto src={props.imgUrl} isCompanyImage={true} />
        </div>
        <p className={styles.companyName}>{cleanName ? cleanName : name}</p>
        {country && country !== "" ? (
          <p className={`${styles.address} flex_center`}>
            <MapPin2FillIcon size={16} className="iconAccent mR5" /> {country}
          </p>
        ) : null}
        <p className={`${styles.web} flex_center`}>
          <GlobalFillIcon size={16} className="iconAccent mR5" /> {domain}
        </p>
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.section}>
          <p className={styles.title}>About</p>
          <p className={styles.details}>
            Company Name:<span>{cleanName ? cleanName : name}</span>
          </p>
          <p className={styles.details}>
            Industry:<span>{industry}</span>
          </p>
          <p className={styles.details}>
            Company Size:<span>{size}</span>
          </p>
          <p className={styles.details}>
            Revenue:
            <span>
              {revenue && revenue !== null
                ? formatNumberHelper(
                    Math.trunc(revenue),
                    props.organizationSettings.numberGroupSeperator,
                    props.organizationSettings.numberDecimalSeperator
                  )
                : "N/A"}
            </span>
          </p>
        </div>
        <div className={styles.section}>
          <p className={styles.title}>SOCIAL MEDIA</p>
          {linkedInUrl && linkedInUrl !== "" ? (
            <div>
              <a rel="noopener noreferrer" href={linkedInUrl.includes("https") ? linkedInUrl : "//" + linkedInUrl} target="_blank">
                <LinkedinBoxFillIcon color="#0077B5" />
              </a>
            </div>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationSettings: state.organizationSettingsGeneral.settings,
  };
};

export default connect(mapStateToProps, null)(CompanyDetails);
