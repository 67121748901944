import React, { useRef, ChangeEvent } from "react";
import { toast } from "react-toastify";

// Helpers
import { toastMessages } from "../../../helper/toastMessagesConstants";

// Styles
import styles from "./FileUploader.module.scss";

// Components
import Button from "../Button/Button";

interface FileUploaderProps {
  handleFile: (file: File) => void;
  buttonText: React.ReactNode;
  variant?: string;
  classes?: string;
  disabled?: boolean;
  uploadImage?: boolean;
  accept?: string;
  name?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  handleFile,
  buttonText,
  variant = "primary",
  classes,
  disabled,
  uploadImage,
  accept,
  name,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) {
      event.preventDefault();
      if (hiddenFileInput.current) {
        hiddenFileInput.current.click();
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      if (uploadImage && !fileUploaded.type.includes("image")) {
        toast.error(toastMessages.fileUploader.errorFileNotImage);
        return;
      }
      handleFile(fileUploaded);
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    }
  };

  return (
    <>
      <Button id="add" disabled={disabled} onClick={handleClick} variant={variant} classes={classes} ariaLabel="Add">
        {buttonText}
      </Button>
      <input type="file" ref={hiddenFileInput} onChange={handleChange} className={styles.inputStyle} accept={accept} name={name} />
    </>
  );
};

export default FileUploader;
