export const dealsTableColumns = [
  {
    label: "",
    field: "image",
    type: "actions",
    width: 30,
    minWidth: 30,
    cellClass: "ag-center-cols-viewport",
  },
  {
    label: "Company Name",
    field: "companyName",
    type: "text",
    width: 130,
    minWidth: 130,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Company Size",
    field: "companySize",
    type: "text",
    width: 80,
    minWidth: 80,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Date of Response",
    field: "dateOfResponse",
    type: "date",
    width: 90,
    minWidth: 90,
    flex: 1,
    sort: "asc",
  },
];
