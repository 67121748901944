import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

//Redux
import {
  searchRecords,
  sortData,
  fetchConversationInbox,
  setSelectedRow,
} from "../../../../store/Conversation/Table/actions/conversationTable";
import { fetchConversationDetails, fetchProspectDetails } from "../../../../store/Conversation/actions/conversation";

//Helpers
import { columns } from "../../helpers/columns";

//Styles
import styles from "./ConversationInbox.module.scss";

//Images & Icons
import SectionInbox from "../../../../assets/Icons/SectionInbox/SectionInbox";
import EmptyCampaignList from "../../../../assets/Images/EmptyCampaignList/EmptyCampaignList";

//Components
import Table from "../../../../components/DataTable/Table";
import SkeletonThreeRowCard from "../../../../components/UI/Skeletons/components/SkeletonThreeRowCard/SkeletonThreeRowCard";
import UserPhoto from "../../../../components/UI/UserPhoto/UserPhoto";
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import DelayedInput from "../../../../components/UI/DelayedInput/DelayedInput";
import ResponseTypeIcon from "../ResponseTypeIcon/ResponseTypeIcon";
import ResponsesLegend from "./ResponsesLegend/ResponsesLegend";
import EmptyState from "../../../../components/UI/EmptyState/EmptyState";

const ConversationInbox = (props) => {
  const { params, rows, isLoaded, isLoading, error, fetchConversationInbox, fetchConversationDetails, fetchProspectDetails, selectedRow } =
    props;
  let cloneRows = _.cloneDeep(rows);

  let image = <EmptyCampaignList className={styles.emptyDataImage} />;
  let { prospectId } = useParams();

  useEffect(() => {
    fetchConversationInbox(params)
      .then((res) => {
        if (_.isUndefined(prospectId) && isLoaded) {
          prospectId = rows[0].id;
        }
      })
      .catch((err) => err);

    props.setParams(params);
  }, [params]);

  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (!_.isUndefined(prospectId) && isLoaded) {
      fetchConversationDetails(prospectId);
      fetchProspectDetails(prospectId);
    }
  }, [prospectId, isLoaded]);

  const handleSearchChange = (text) => {
    props.searchRecords(text);
  };

  const handleConversationClick = (conversation) => {
    fetchConversationDetails(conversation.id);
    fetchProspectDetails(conversation.id);
  };

  const handleMessageClick = (message) => {
    props.setSelectedRow(message);
  };

  let table = null;

  let selectedRowIndex = null;
  selectedRowIndex = cloneRows.findIndex((x) => x.id === selectedRow.id);

  const [globalIndex, setGlobalIndex] = useState(0);

  useEffect(() => {
    if (props.isLoaded && !_.isNull(document)) {
      if (!_.isNull(document.getElementById(`message-${selectedRowIndex}`)) && globalIndex === selectedRowIndex) {
        let parent = document.getElementById(`message-${selectedRowIndex}`)?.parentElement?.parentElement?.parentElement;
        if (parent != null) {
          parent.style.backgroundColor = `var(--active-background-color)`;
          parent.style.color = `var(--primary-text-color)`;
          parent.classList.remove(`.Table_outbaseTable__3Wz_o`);
        }
      } else if (!_.isNull(document.getElementById(`message-${globalIndex}`))) {
        let parent = document.getElementById(`message-${globalIndex}`)?.parentElement?.parentElement?.parentElement;
        if (parent != null) {
          parent.style.backgroundColor = `var(--white-color)`;
          parent.style.color = `var(--primary-text-color)`;
        }
      }
    }
  }, [props.isLoaded, selectedRowIndex, globalIndex]);

  useEffect(() => {
    const tempRows = _.cloneDeep(rows);
    tempRows.map((r, index) => {
      let splitedArray = r.fullName.split(" ");
      let firstName = splitedArray[0];
      let lastName = splitedArray[splitedArray.length - 1];
      let imageUrl = r.imageUrl;
      let classes = null;
      if (index === selectedRowIndex) {
        classes = styles.activeRow;
        setGlobalIndex(index);
      }

      r.inboxConversation = (
        <div key={index} className={classes} id={`message-${index}`} onClick={() => handleMessageClick(r)}>
          <div key={index} className={`${styles.inboxContainer} flex`} id={`message-${index}`} onClick={() => handleConversationClick(r)}>
            <div className={`${styles.image} flex`}>
              <UserPhoto name={`${firstName} ${lastName}`} src={imageUrl} size="medium" />
            </div>

            <div className={styles.content}>
              <div className={`${styles.fullNameWrapper} flex`}>
                <div className={styles.fullName}>
                  <h2>{r.fullName}</h2>
                  <ResponseTypeIcon type={r.responseType} />
                </div>
              </div>
              <p className={styles.company}>{r.companyName}</p>
            </div>
          </div>
        </div>
      );

      return r;
    });

    setTableRows(tempRows);
  }, [rows, selectedRowIndex]);

  if (error) {
    table = <h2>Error getting Conversation Inbox </h2>;
  }

  if (isLoading) {
    table = (
      <div>
        {[1, 2, 3, 4].map((n) => (
          <SkeletonThreeRowCard key={n} />
        ))}
      </div>
    );
  }

  if (isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Conversation"}
          columns={columns}
          rows={tableRows}
          param={params}
          parentSearchHandler={props.searchRecords}
          scrollY={false}
          variant="no-header"
          tableClassName={styles.conversationInboxTable}
          wrapperTableClass="no-header-table"
        />
      ) : (
        <EmptyState image={image} componentClass="mT30" title="No messages found yet" />
      );
  }

  const [sortBy, setSortBy] = useState("Sort By");
  const handleSortChange = (eventKey, event) => {
    setSortBy(event.currentTarget.text);

    const sortSplit = eventKey.split(" ");
    const sort = {
      column: sortSplit[0],
      direction: sortSplit[1].toLowerCase(),
    };
    props.sortData(sort);
  };

  let sortSection = null;
  sortSection =
    tableRows.length > 0 ? (
      <div className={styles.sortWrapper}>
        <Dropdown className={styles.sort} onSelect={handleSortChange}>
          <Dropdown.Toggle id="dropdown-menu-align-right">{sortBy}</Dropdown.Toggle>

          <Dropdown.Menu alignRight>
            <Dropdown.Item eventKey="name ASC" href="#">
              Sort by Full Name A-Z
            </Dropdown.Item>
            <Dropdown.Item eventKey="name DESC" href="#">
              Sort by Full Name Z-A
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ) : null;

  let legend = null;
  legend = tableRows.length > 0 ? <ResponsesLegend /> : null;
  return (
    <>
      <div className={styles.conversationInboxWrapper}>
        <SectionTitle title="CONVERSATIONS" icon={<SectionInbox imgStyle={styles.inboxIcon} />} />
        <DelayedInput
          showSearchIcon={true}
          type="text"
          placeholder={"Search for messages..."}
          className={styles.searchFilterTable}
          textChanged={handleSearchChange}
          wrapper={styles.wrapper}
          ariaLabel="Search"
        />
        {legend}
        {sortSection}
        {table}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    params: state.conversationTable.params,
    isLoading: state.conversationTable.isLoading,
    isLoaded: state.conversationTable.isLoaded,
    error: state.conversationTable.error,
    rows: state.conversationTable.rows,
    selectedRow: state.conversationTable.selectedRow,
    isInitiated: state.conversationTable.isInitiated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConversationInbox: (param) => dispatch(fetchConversationInbox(param)),
    fetchConversationDetails: (id) => dispatch(fetchConversationDetails(id)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    setSelectedRow: (obj) => dispatch(setSelectedRow(obj)),
    sortData: (obj) => dispatch(sortData(obj)),
    fetchProspectDetails: (id) => dispatch(fetchProspectDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationInbox);
