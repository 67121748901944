import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../helper/api/api";
import { errorResponseToList } from "../../helper/errorHelper";
import { fileDownload } from "../../helper/fileDownloadHelper";

//Styles
import styles from "./Exclusions.module.scss";

//Components
import Modal from "../../components/UI/Modal/Modal";
import AddExclusionsForm from "./components/AddExclusionsForm";
import ExclusionsImportResultForm from "./components/ExclusionsImportResultForm";
import FormikEffect from "../../components/UI/Formik/FormikEffect";

const NewExclusionForm = (props) => {
  const [CSVFileName, setCSVFileName] = useState("");
  const [CSVFileError, setCSVFileError] = useState(false);
  const [showExclusionsResultError, setShowExclusionsResultError] = useState(false);
  const [exclusionsResultError, setExclusionsResultError] = useState("");
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [oneButton, setOneButton] = useState(false);
  const [modalSize, setModalSize] = useState("lg");
  const [showUploadResult, setShowUploadResult] = useState(false);
  const [exclusionsImportInfo, setExclusionsImportInfo] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    setCSVFileError(false);
    setCSVFileName("");
    setShowExclusionsResultError(false);
    setExclusionsResultError("");
    setShowUploadResult(false);
    setSaveButtonText("Save");
    setOneButton(false);
    setModalSize("lg");
  }, []);

  const handleClose = () => {
    props.handleClose();
    setCSVFileError(false);
    setCSVFileName("");
    setShowExclusionsResultError(false);
    setExclusionsResultError("");
    setShowUploadResult(false);
    setSaveButtonText("Save");
    setOneButton(false);
    setModalSize("lg");
    setExclusionsImportInfo(null);
  };

  const downloadInvalidExclusionsCSV = (invalidCSVString) => {
    let data = {
      csvString: invalidCSVString,
    };
    api(API.exclusions.exportInvalidExclusions, data, "blob")
      .then((res) => {
        fileDownload("Exclusions - Download failed records.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const onFormChange = (exclusions, csvFile) => {
    setShowExclusionsResultError(false);
    setExclusionsResultError("");
    if (exclusions.length !== 0 || csvFile !== "") {
      setSaveButtonDisabled(false);
      setExclusionsImportInfo(null);
    } else {
      setSaveButtonDisabled(true);
    }
  };

  const downloadCSVTemplate = () => {
    api(API.exclusions.downloadCSVTemplate, null, "blob")
      .then((res) => {
        fileDownload("Exclusions.csv", res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  let renderPage = null;

  return (
    <Formik
      initialValues={{
        exclusions: "",
        csvFile: "",
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        setSaveButtonDisabled(true);

        if (showUploadResult) {
          setSaveButtonDisabled(false);
          actions.setFieldValue("exclusions", "");
          actions.setFieldValue("csvFile", "");
          handleClose();
        } else {
          setShowExclusionsResultError(false);
          setExclusionsResultError("");

          if (values.exclusions.length !== 0 || values.csvFile !== "") {
            if (
              !(
                values.exclusions.length !== 0 ||
                //values.csvFile !== "" ||
                values.csvFile.type === "csv" ||
                values.csvFile.type === "application/vnd.ms-excel" ||
                values.csvFile.type === "text/csv"
              )
            ) {
              setShowExclusionsResultError(true);
              setExclusionsResultError("The file format is not valid");
            } else {
              let data = new FormData();
              if (values.exclusions.length !== 0) {
                data.append("exclusions", values.exclusions);
              }

              if (values.csvFile !== "") {
                data.append("csvFile", values.csvFile);
                values.csvFile = "";
              }

              setSaveButtonText("Run in backgound");
              setOneButton(true);
              setModalSize("md");
              setShowUploadResult(true);
              api(API.exclusions.create, data)
                .then((res) => {})
                .catch((error) => {
                  const errorList = errorResponseToList(error);
                  if (errorList.length > 0) {
                    errorList.forEach((e) => (e.field === "csvFile" ? toast.error(e.message) : actions.setFieldError(e.field, e.message)));
                  } else {
                    toast.error(error.message);
                  }
                  handleClose();
                })
                .finally(() => {
                  actions.setFieldValue("csvFile", "");
                  setSaveButtonDisabled(false);
                  setCSVFileName("");
                  setCSVFileError(false);
                });
            }
          } else {
            setSaveButtonDisabled(false);
            setCSVFileError(false);
            actions.setFieldError("exclusions", "Please enter Exclusions or upload a file");
          }
        }
      }}
      onReset={() => {
        handleClose();
      }}
    >
      {(formikProps) => {
        const { values, handleSubmit, setFieldValue } = formikProps;
        if (showUploadResult) {
          renderPage = (
            <ExclusionsImportResultForm
              setExclusionsImportInfo={setExclusionsImportInfo}
              exclusionsImportInfo={exclusionsImportInfo}
              downloadInvalidExclusionsCSV={downloadInvalidExclusionsCSV}
              setSaveButtonText={setSaveButtonText}
              downloadCSVTemplate={downloadCSVTemplate}
            />
          );
        } else {
          renderPage = (
            <AddExclusionsForm
              setSaveButtonDisabled={setSaveButtonDisabled}
              setFieldValue={setFieldValue}
              setCSVFileName={setCSVFileName}
              setCSVFileError={setCSVFileError}
              CSVFileName={CSVFileName}
              CSVFileError={CSVFileError}
              showExclusionsResultError={showExclusionsResultError}
              exclusionsResultError={exclusionsResultError}
              values={values}
              onFormChange={onFormChange}
              organizationId={props.organizationId}
              downloadCSVTemplate={downloadCSVTemplate}
            />
          );
        }
        return (
          <Modal
            title="Add Exclusions"
            size={modalSize}
            dialogClassName={styles.newExclusionsForm}
            handleClose={() => {
              handleClose();
            }}
            handleSave={saveButtonDisabled ? handleClose : handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            oneButton={oneButton}
            saveButtonText={saveButtonText}
            btnType="submit"
          >
            <FormikEffect onChange={() => onFormChange(values.exclusions, values.csvFile)} />
            <div>{renderPage}</div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default NewExclusionForm;
