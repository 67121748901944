import React from "react";

//Styles
import styles from "./MoreDetailsForm.module.scss";

//Components
import Modal from "../../Modal/Modal";

interface MoreDetailsFormProps {
  handleClose: () => void;
  handleSave?: () => void;
  thirdButtonClick?: () => void;
  isSaveButtonLink?: boolean;
  showModal: boolean;
  saveButtonText: string;
  children: React.ReactNode;
  saveButtonLink: React.ReactNode;
  onRedirect?: () => void;
}

const MoreDetailsForm: React.FC<MoreDetailsFormProps> = ({
  handleClose,
  showModal,
  handleSave,
  children,
  saveButtonText,
  isSaveButtonLink,
  saveButtonLink,
  thirdButtonClick,
  onRedirect,
}) => {
  return (
    <Modal
      threeButtonsLayout
      thirdButtonText="Cancel"
      thirdButtonClick={thirdButtonClick}
      handleClose={handleClose}
      handleSave={handleSave}
      show={showModal}
      customBodyClass={styles.modalBody}
      closeButtonText="Dismiss"
      saveButtonText={saveButtonText}
      isSaveButtonLink={isSaveButtonLink}
      btnType="submit"
      backdrop="static"
      thirdButtonVariant="secondary"
      closeButtonVariant="link_button"
      saveButtonLink={saveButtonLink}
      onRedirect={onRedirect}
    >
      <p>{children}</p>
    </Modal>
  );
};

export default MoreDetailsForm;
