import React from "react";
import { FieldArray } from "formik";
import { toast } from "react-toastify";

//Styles
import styles from "./RegistrationStepMembers.module.scss";

//Images & Icons
import UserAddLineIcon from "remixicon-react/UserAddLineIcon";

//Components
import Button from "../../../../../components/UI/Button/Button";
import InviteUser from "./InviteUser/InviteUser";

const RegistrationStepMembers = (props) => {
  let listOfUsers = props.users;

  const hasErrors = Object.keys(props.stepTwoErrors).length > 0;
  const hasNonEmptyEmail = props.users.some((user) => user.email.trim() !== "");
  const isFormValid = props.formikProps.isValid;

  const handleAddMemberClick = (event, arrayHelpers) => {
    event.preventDefault();
    if (listOfUsers.length < 2) arrayHelpers.push({ email: "", role: "" });
    else {
      toast.error("You've reached the maximum number of users for a Trial organization.");
    }
  };

  return (
    <fieldset className={`width_100 ${styles.inviteTeamFieldset}`}>
      <div className={styles.formContainer}>
        <div className={styles.stepInfo}>
          <h1 className="f16 font700 textCenter">Invite your team</h1>
          <p className="f14 textCenter">Invite your colleagues and choose how much of your campaign they can see/edit</p>
        </div>

        <FieldArray
          name="invites"
          render={(arrayHelpers) => (
            <>
              <>
                {listOfUsers.map((user, index) => {
                  const emailName = `invites[${index}].email`;
                  const roleName = `invites[${index}].role`;

                  return (
                    <div key={index}>
                      <InviteUser emailName={emailName} selectName={roleName} index={index} />
                    </div>
                  );
                })}
              </>
              <div className={styles.addMemberWrapper}>
                <p className={`m0 flex cursor_pointer ${styles.addMember}`} onClick={(e) => handleAddMemberClick(e, arrayHelpers)}>
                  <UserAddLineIcon size={17} className="mR5" />
                  Add member
                </p>
              </div>
            </>
          )}
        />
      </div>
      {/* <div className="width_100 flex justify_end"> */}
      <div className={styles.actionWrapper}>
        <Button id="back" variant="link_button" onClick={props.backClick}>
          Go Back
        </Button>
        {hasNonEmptyEmail && !hasErrors && isFormValid ? (
          <Button id="next" type="submit" name="next" variant="primary">
            Next
          </Button>
        ) : (
          <Button id="skip" type="submit" variant="primary">
            Skip & Continue
          </Button>
        )}
      </div>
    </fieldset>
  );
};
export default RegistrationStepMembers;
