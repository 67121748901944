import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { discardDealNavigator } from "../../../store/DealNavigator/actions/dealNavigator";

//Helpers
import { toastMessages } from "../../../helper/toastMessagesConstants";

//Styles
import styles from "../../Exclusions/Exclusions.module.scss";

//Components
import Modal from "../../../components/UI/Modal/Modal";

const DiscardDraftModel = (props) => {
  const { templateId, orgId, discardDealNavigator } = props;
  const discardDraftVersion = () => {
    if (orgId !== 1) discardDealNavigator();
    else discardDealNavigator(templateId);
    toast.success(toastMessages.dealNavigator.successDiscardDraftModel);
    props.handleClose();
  };

  return (
    <Modal
      title="Discard Draft Version"
      dialogClassName={styles.newExclusionsForm}
      handleClose={props.handleClose}
      handleSave={discardDraftVersion}
      show={props.showModal}
      closeButtonText="No"
      saveButtonText="Yes"
      btnType="submit"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>Are you sure you want to discard all your changes?</p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    discardDealNavigator: (templateId) => dispatch(discardDealNavigator(templateId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscardDraftModel);
