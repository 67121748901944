import React from "react";

const IconFileImage = (props) => (
  <svg
    className={props.imgStyle}
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667Z"
      fill="#999999"
    />
    <path
      d="M23.9666 7.99999C23.9333 7.86666 23.9 7.76666 23.8333 7.66666L17.3333 0.399994V7.99999H23.9666Z"
      fill="#999999"
    />
    <path
      d="M3.26666 24.6667V12.3333H20.8667V24.1333L15.9 17.4667C15.8667 17.4333 15.8333 17.4 15.8 17.4C15.7667 17.4 15.7 17.4333 15.7 17.4667L11.4667 22.6333L8.2 19.7333C8.13333 19.7 8.06666 19.7 8 19.7333L3.26666 24.6667Z"
      fill="white"
    />
    <path
      d="M10.1333 15.6333C10.1333 14.7 9.36667 13.9333 8.43334 13.9333C7.5 13.9333 6.73334 14.7 6.73334 15.6333C6.73334 16.5667 7.5 17.3333 8.43334 17.3333C9.36667 17.3 10.1333 16.5667 10.1333 15.6333Z"
      fill="#999999"
    />
  </svg>
);
export default IconFileImage;
