import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  purchaseTemplateTitle: {},
  purchaseTemplate: {},
  listJobTitles: {},
  purchaseTemplatePrice:""
};

const setPurchaseTemplateTitle = (state, action) => {
  const newState = { ...state };
  let newPurchaseTemplateTitle = { ...state.purchaseTemplateTitle };
  newPurchaseTemplateTitle = action.result;
  newState.purchaseTemplateTitle = newPurchaseTemplateTitle;
  return newState;
};

const fetchPurchaseTemplateRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchPurchaseTemplateSuccess = (state, action) => {
  const newState = { ...state };
  let emailTemplate = { ...state.purchaseTemplate };
  emailTemplate = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.purchaseTemplate = emailTemplate;
  return newState;
};

const fetchPurchaseTemplateFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchJobTitleListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchJobTitleListSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listJobTitles };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listJobTitles = list;
  return newState;
};

const fetchListCampaignsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchPurchaseTemplatePriceRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchPurchaseTemplatePriceSuccess = (state, action) => {
  const newState = { ...state };
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.purchaseTemplatePrice = action.result;
  return newState;
};

const fetchPurchaseTemplatePriceFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PURCHASE_TEMPLATE_TITLE:
      return setPurchaseTemplateTitle(state, action);
    case actionTypes.FETCH_PURCHASE_TEMPLATE_REQUEST:
      return fetchPurchaseTemplateRequest(state, action);
    case actionTypes.FETCH_PURCHASE_TEMPLATE_SUCCEEDED:
      return fetchPurchaseTemplateSuccess(state, action);
    case actionTypes.FETCH_PURCHASE_TEMPLATE_FAILED:
      return fetchPurchaseTemplateFailed(state, action);
    case actionTypes.FETCH_JOB_TITLE_LIST_REQUEST:
      return fetchJobTitleListRequest(state, action);
    case actionTypes.FETCH_JOB_TITLE_LIST_SUCCEEDED:
      return fetchJobTitleListSuccess(state, action);
    case actionTypes.FETCH_JOB_TITLE_LIST_FAILED:
      return fetchListCampaignsFailed(state, action);
      case actionTypes.FETCH_PURCHASE_TEMPLATE_PRICE_REQUEST:
        return fetchPurchaseTemplatePriceRequest(state, action);
      case actionTypes.FETCH_PURCHASE_TEMPLATE_PRICE_SUCCEEDED:
        return fetchPurchaseTemplatePriceSuccess(state, action);
      case actionTypes.FETCH_PURCHASE_TEMPLATE_PRICE_FAILED:
        return fetchPurchaseTemplatePriceFailed(state, action);
    default:
      break;
  }
  return state;
};
export default reducer;
