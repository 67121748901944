import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Redux
import { setPurchaseTemplateTitle, fetchJobTitleList } from "../../store/EmailTemplates/Global/actions/purchaseTemplates";
import { fetchPurchaseTemplates } from "../../store/EmailTemplates/Table/actions/purchaseTemplate";

//Styles
import styles from "./EmailTemplates.module.scss";

//Helpers
import { PERMISSIONS } from "../../helper/permissionConstants";
import { useOrganizationId } from "../../helper/hooks/useOrganizationId";

//Images & Icons
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import Permission from "../../components/auth/Permission";
import Button from "../../components/UI/Button/Button";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import Templates from "./Templates/Templates";
import MyOrdersTemplates from "./MyOrdersTemplates/MyOrdersTemplates";
import Tabs from "../../components/UI/Tabs/Tabs";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const EmailTemplates = (props) => {
  const organizationId = useOrganizationId();
  const { setPurchaseTemplateTitle, fetchJobTitleList, fetchPurchaseTemplates, param, rows } = props;
  let config = null;

  useEffect(() => {
    fetchJobTitleList();
    fetchPurchaseTemplates(param);
  }, []);

  const icon = (
    <InfoIconTooltip
      tooltipBody="Create and review the email templates being used by your campaigns."
      helpText="Need help writing email templates? "
      href={"https://help.outbase.com/user-guide/email-templates"}
      linkText="Read More"
      buttonId="startEmailTemplatesTour"
      to={"/" + organizationId + "/email-templates/templates"}
    />
  );

  const changeUrl = () => {
    props.history.push("/" + props.orgId + "/email-templates/create");
  };

  if (props.location.pathname === `/${organizationId}/email-templates`) props.history.push(`/${organizationId}/email-templates/templates`);

  const tabs = [
    {
      link: "templates",
      component: <Templates {...props} />,
      title: "Templates",
    },
  ];

  if (rows.length > 0) {
    tabs.push({
      link: "my-orders",
      component: <MyOrdersTemplates {...props} />,
      title: "My Orders",
    });
  }

  config = <Tabs tabContentClass={styles.emailTemplatesTabs} {...props} tabs={tabs} />;

  const buttons = (
    <Permission has={PERMISSIONS.emailTemplates.create}>
      <div className="flex">
        <Button id="newTemplate" variant="primary" onClick={changeUrl}>
          <AddCircleLineIcon size={18} className="iconWhite mR5" />
          New template
        </Button>
      </div>
    </Permission>
  );

  return (
    <PageContentWrapper>
      <HeaderTitle title={"Email Templates"} rightContent={buttons} icon={icon} />
      {config}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization !== undefined ? state.user.organization.id : 0,
    param: state.purchaseTemplateTable.params,
    rows: state.purchaseTemplateTable.rows,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPurchaseTemplateTitle: (val) => dispatch(setPurchaseTemplateTitle(val)),
    fetchJobTitleList: () => dispatch(fetchJobTitleList()),
    fetchPurchaseTemplates: (param) => dispatch(fetchPurchaseTemplates(param)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
