import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../../../helper/api/api";

export const mapContactsAndCompaniesPropertiesRequest = () => {
  return {
    type: actionTypes.MAP_CONTACTS_AND_COMPANIES_PROPERTIES_REQUEST,
  };
};

export const mapContactsAndCompaniesPropertiesSuccess = (result) => {
  return {
    type: actionTypes.MAP_CONTACTS_AND_COMPANIES_PROPERTIES_SUCCEEDED,
    result: result,
  };
};

export const mapContactsAndCompaniesPropertiesFailed = (error) => {
  return {
    type: actionTypes.MAP_CONTACTS_AND_COMPANIES_PROPERTIES_FAILED,
    result: error,
  };
};

export const mapContactsAndCompaniesProperties = (data) => {
  return (dispatch) => {
    dispatch(mapContactsAndCompaniesPropertiesRequest());
    return api(API.integrations.hubspot.mapContactsAndCompaniesProperties, data)
      .then((response) => {
        dispatch(mapContactsAndCompaniesPropertiesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(mapContactsAndCompaniesPropertiesFailed(error.message));
      });
  };
};

export const editHubspotIntegrationRequest = () => {
  return {
    type: actionTypes.EDIT_HUBSPOT_INTEGRATION_REQUEST,
  };
};

export const editHubspotIntegrationSuccess = (result) => {
  return {
    type: actionTypes.EDIT_HUBSPOT_INTEGRATION_SUCCEEDED,
    result: result,
  };
};

export const editHubspotIntegrationFailed = (error) => {
  return {
    type: actionTypes.EDIT_HUBSPOT_INTEGRATION_FAILED,
    result: error,
  };
};

export const editHubspotIntegration = () => {
  return (dispatch) => {
    dispatch(editHubspotIntegrationRequest());
    return api()
      .then((response) => {
        dispatch(editHubspotIntegrationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(editHubspotIntegrationFailed(error.message));
      });
  };
};

export const deleteHubspotIntegrationRequest = () => {
  return {
    type: actionTypes.DELETE_HUBSPOT_INTEGRATION_REQUEST,
  };
};

export const deleteHubspotIntegrationSuccess = (result) => {
  return {
    type: actionTypes.DELETE_HUBSPOT_INTEGRATION_SUCCEEDED,
    result: result,
  };
};

export const deleteHubspotIntegrationFailed = (error) => {
  return {
    type: actionTypes.DELETE_HUBSPOT_INTEGRATION_FAILED,
    result: error,
  };
};

export const deleteHubspotIntegration = () => {
  return (dispatch) => {
    dispatch(deleteHubspotIntegrationRequest());
    return api()
      .then((response) => {
        dispatch(deleteHubspotIntegrationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteHubspotIntegrationFailed(error.message));
      });
  };
};

export const addHubspotRulesetRequest = () => {
  return {
    type: actionTypes.ADD_HUBSPOT_RULESET_REQUEST,
  };
};

export const addHubspotRulesetSuccess = (result) => {
  return {
    type: actionTypes.ADD_HUBSPOT_RULESET_SUCCEEDED,
    result: result,
  };
};

export const addHubspotRulesetFailed = (error) => {
  return {
    type: actionTypes.ADD_HUBSPOT_RULESET_FAILED,
    result: error,
  };
};

export const addHubspotRuleset = () => {
  return (dispatch) => {
    dispatch(addHubspotRulesetRequest());
    return api()
      .then((response) => {
        dispatch(addHubspotRulesetSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addHubspotRulesetFailed(error.message));
      });
  };
};

export const addHubspotRuleRequest = () => {
  return {
    type: actionTypes.ADD_HUBSPOT_RULE_REQUEST,
  };
};

export const addHubspotRuleSuccess = (result) => {
  return {
    type: actionTypes.ADD_HUBSPOT_RULE_SUCCEEDED,
    result: result,
  };
};

export const addHubspotRuleFailed = (error) => {
  return {
    type: actionTypes.ADD_HUBSPOT_RULE_FAILED,
    result: error,
  };
};

export const addHubspotRule = () => {
  return (dispatch) => {
    dispatch(addHubspotRuleRequest());
    return api()
      .then((response) => {
        dispatch(addHubspotRuleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(addHubspotRuleFailed(error.message));
      });
  };
};

export const editHubspotRuleRequest = () => {
  return {
    type: actionTypes.EDIT_HUBSPOT_RULE_REQUEST,
  };
};

export const editHubspotRuleSuccess = (result) => {
  return {
    type: actionTypes.EDIT_HUBSPOT_RULE_SUCCEEDED,
    result: result,
  };
};

export const editHubspotRuleFailed = (error) => {
  return {
    type: actionTypes.EDIT_HUBSPOT_RULE_FAILED,
    result: error,
  };
};

export const editHubspotRule = (model) => {
  return (dispatch) => {
    dispatch(editHubspotRuleRequest());
    return api(API.integrations.hubspot.editCustomRules, model)
      .then((response) => {
        dispatch(editHubspotRuleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(editHubspotRuleFailed(error.message));
      });
  };
};

export const deleteHubspotRuleRequest = () => {
  return {
    type: actionTypes.DELETE_HUBSPOT_RULE_REQUEST,
  };
};

export const deleteHubspotRuleSuccess = (result) => {
  return {
    type: actionTypes.DELETE_HUBSPOT_RULE_SUCCEEDED,
    result: result,
  };
};

export const deleteHubspotRuleFailed = (error) => {
  return {
    type: actionTypes.DELETE_HUBSPOT_RULE_FAILED,
    result: error,
  };
};

export const deleteHubspotRule = (id) => {
  return (dispatch) => {
    dispatch(deleteHubspotRuleRequest());
    return api(formUrl(API.integrations.hubspot.deleteCustomRules, { id: id }))
      .then((response) => {
        dispatch(deleteHubspotRuleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(deleteHubspotRuleFailed(error.message));
      });
  };
};

export const fetchHubspotRulesRequest = () => {
  return {
    type: actionTypes.FETCH_HUBSPOT_RULES_REQUEST,
  };
};

export const fetchHubspotRulesSuccess = (result) => {
  return {
    type: actionTypes.FETCH_HUBSPOT_RULES_SUCCEEDED,
    result: result,
  };
};

export const fetchHubspotRulesFailed = (error) => {
  return {
    type: actionTypes.FETCH_HUBSPOT_RULES_FAILED,
    result: error,
  };
};

export const fetchHubspotRules = () => {
  return (dispatch) => {
    dispatch(fetchHubspotRulesRequest());
    return api(API.integrations.hubspot.getAllCustomRules)
      .then((response) => {
        dispatch(fetchHubspotRulesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchHubspotRulesFailed(error.message));
      });
  };
};

export const fetchCompaniesPropertiesRequest = () => {
  return {
    type: actionTypes.FETCH_COMPANIES_PROPERTIES_REQUEST,
  };
};

export const fetchCompaniesPropertiesSuccess = (result) => {
  return {
    type: actionTypes.FETCH_COMPANIES_PROPERTIES_SUCCEEDED,
    result: result,
  };
};

export const fetchCompaniesPropertiesFailed = (error) => {
  return {
    type: actionTypes.FETCH_COMPANIES_PROPERTIES_FAILED,
    result: error,
  };
};

export const fetchCompaniesProperties = () => {
  return (dispatch) => {
    dispatch(fetchCompaniesPropertiesRequest());
    return api(API.integrations.hubspot.getCompaniesProperties)
      .then((response) => {
        dispatch(fetchCompaniesPropertiesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompaniesPropertiesFailed(error.message));
      });
  };
};

export const fetchContactsPropertiesRequest = () => {
  return {
    type: actionTypes.FETCH_CONTACT_PROPERTIES_REQUEST,
  };
};

export const fetchContactsPropertiesSuccess = (result) => {
  return {
    type: actionTypes.FETCH_CONTACT_PROPERTIES_SUCCEEDED,
    result: result,
  };
};

export const fetchContactsPropertiesFailed = (error) => {
  return {
    type: actionTypes.FETCH_CONTACT_PROPERTIES_FAILED,
    result: error,
  };
};

export const fetchContactsProperties = () => {
  return (dispatch) => {
    dispatch(fetchContactsPropertiesRequest());
    return api(API.integrations.hubspot.getContactsProperties)
      .then((response) => {
        dispatch(fetchContactsPropertiesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchContactsPropertiesFailed(error.message));
      });
  };
};

export const fetchDealsPropertiesRequest = () => {
  return {
    type: actionTypes.FETCH_DEALS_PROPERTIES_REQUEST,
  };
};

export const fetchDealsPropertiesSuccess = (result) => {
  return {
    type: actionTypes.FETCH_DEALS_PROPERTIES_SUCCEEDED,
    result: result,
  };
};

export const fetchDealsPropertiesFailed = (error) => {
  return {
    type: actionTypes.FETCH_DEALS_PROPERTIES_FAILED,
    result: error,
  };
};

export const fetchDealsProperties = () => {
  return (dispatch) => {
    dispatch(fetchDealsPropertiesRequest());
    return api(API.integrations.hubspot.getDealsProperties)
      .then((response) => {
        dispatch(fetchDealsPropertiesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealsPropertiesFailed(error.message));
      });
  };
};

export const fetchCustomRuleByIdRequest = () => {
  return {
    type: actionTypes.GET_CUSTOM_RULE_BY_ID_REQUEST,
  };
};

export const fetchCustomRuleByIdSuccess = (result) => {
  return {
    type: actionTypes.GET_CUSTOM_RULE_BY_ID_SUCCEEDED,
    result: result,
  };
};

export const fetchCustomRuleByIdFailed = (error) => {
  return {
    type: actionTypes.GET_CUSTOM_RULE_BY_ID_FAILED,
    result: error,
  };
};

export const fetchCustomRuleById = (id) => {
  return (dispatch) => {
    dispatch(fetchCustomRuleByIdRequest());
    return api(formUrl(API.integrations.hubspot.getCustomRuleById, { id: id }))
      .then((response) => {
        dispatch(fetchCustomRuleByIdSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCustomRuleByIdFailed(error.message));
      });
  };
};

export const fetchRulesCustomFieldsRequest = () => {
  return {
    type: actionTypes.GET_RULES_CUSTOM_FIELDS_REQUEST,
  };
};

export const fetchRulesCustomFieldsSuccess = (result) => {
  return {
    type: actionTypes.GET_RULES_CUSTOM_FIELDS_SUCCEEDED,
    result: result,
  };
};

export const fetchRulesCustomFieldsFailed = (error) => {
  return {
    type: actionTypes.GET_RULES_CUSTOM_FIELDS_FAILED,
    result: error,
  };
};

export const fetchRulesCustomFields = (key, fieldName) => {
  return (dispatch) => {
    dispatch(fetchRulesCustomFieldsRequest());
    return api(
      formUrl(API.integrations.hubspot.getRulesCustomFields, {
        key: key,
        fieldName: fieldName,
      })
    )
      .then((response) => {
        dispatch(fetchRulesCustomFieldsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchRulesCustomFieldsFailed(error.message));
      });
  };
};

export const fetchDealsPipelinesRequest = () => {
  return {
    type: actionTypes.GET_DEALS_PIPELINES_REQUEST,
  };
};

export const fetchDealsPipelinesSuccess = (result) => {
  return {
    type: actionTypes.GET_DEALS_PIPELINES_SUCCEEDED,
    result: result,
  };
};

export const fetchDealsPipelinesFailed = (error) => {
  return {
    type: actionTypes.GET_DEALS_PIPELINES_FAILED,
    result: error,
  };
};

export const fetchDealsPipelines = () => {
  return (dispatch) => {
    dispatch(fetchDealsPipelinesRequest());
    return api(API.integrations.hubspot.getDealsPipelines)
      .then((response) => {
        dispatch(fetchDealsPipelinesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealsPipelinesFailed(error.message));
      });
  };
};

export const fetchDealsPipelineByIdRequest = () => {
  return {
    type: actionTypes.GET_DEALS_PIPELINE_BY_ID_REQUEST,
  };
};

export const fetchDealsPipelineByIdSuccess = (result) => {
  return {
    type: actionTypes.GET_DEALS_PIPELINE_BY_ID_SUCCEEDED,
    result: result,
  };
};

export const fetchDealsPipelineByIdFailed = (error) => {
  return {
    type: actionTypes.GET_DEALS_PIPELINE_BY_ID_FAILED,
    result: error,
  };
};

export const fetchDealsPipelineById = (id) => {
  return (dispatch) => {
    dispatch(fetchDealsPipelineByIdRequest());
    return api(
      formUrl(API.integrations.hubspot.getDealsPipelineById, { id: id })
    )
      .then((response) => {
        dispatch(fetchDealsPipelineByIdSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDealsPipelineByIdFailed(error.message));
      });
  };
};

export const fetchHubSpotConfigurationRequest = () => {
  return {
    type: actionTypes.GET_CONFIGURATION_REQUEST,
  };
};

export const fetchHubSpotConfigurationSuccess = (result) => {
  return {
    type: actionTypes.GET_CONFIGURATION_SUCCEEDED,
    result: result,
  };
};

export const fetchHubSpotConfigurationFailed = (error) => {
  return {
    type: actionTypes.GET_CONFIGURATION_FAILED,
    result: error,
  };
};

export const fetchHubSpotConfiguration = () => {
  return (dispatch) => {
    dispatch(fetchHubSpotConfigurationRequest());
    return api(API.integrations.hubspot.getConfiguration)
      .then((response) => {
        dispatch(fetchHubSpotConfigurationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchHubSpotConfigurationFailed(error.message));
      });
  };
};

export const fecthGlobalMappingsCustomFieldsRequest = () => {
  return {
    type: actionTypes.GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_REQUEST,
  };
};

export const fecthGlobalMappingsCustomFieldsSuccess = (result) => {
  return {
    type: actionTypes.GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_SUCCEEDED,
    result: result,
  };
};

export const fecthGlobalMappingsCustomFieldsFailed = (error) => {
  return {
    type: actionTypes.GET_GLOBAL_MAPPINGS_CUSTOM_FIELDS_FAILED,
    result: error,
  };
};

export const fecthGlobalMappingsCustomFields = (key) => {
  return (dispatch) => {
    dispatch(fecthGlobalMappingsCustomFieldsRequest());
    return api(
      formUrl(API.integrations.hubspot.getGlobalMappingsCustomFields, {
        key: key,
      })
    )
      .then((response) => {
        dispatch(fecthGlobalMappingsCustomFieldsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fecthGlobalMappingsCustomFieldsFailed(error.message));
      });
  };
};
