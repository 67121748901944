export const FETCH_LIST_OF_CAMPAIGNS_REQUEST =
  "FETCH_LIST_OF_CAMPAIGNS_REQUEST";
export const FETCH_LIST_OF_CAMPAIGNS_SUCCEEDED =
  "FETCH_LIST_OF_CAMPAIGNS_SUCCEEDED";
export const FETCH_LIST_OF_CAMPAIGNS_FAILED = "FETCH_LIST_OF_CAMPAIGNS_FAILED";

export const FETCH_COMPANY_DETAILS_REQUEST = "FETCH_COMPANY_DETAILS_REQUEST";
export const FETCH_COMPANY_DETAILS_SUCCEEDED =
  "FETCH_COMPANY_DETAILS_SUCCEEDED";
export const FETCH_COMPANY_DETAILS_FAILED = "FETCH_COMPANY_DETAILS_FAILED";

export const FETCH_PROSPECTS_STATUSES_REQUEST =
  "FETCH_PROSPECTS_STATUSES_REQUEST";
export const FETCH_PROSPECTS_STATUSES_SUCCEEDED =
  "FETCH_PROSPECTS_STATUSES_SUCCEEDED";
export const FETCH_PROSPECTS_STATUSES_FAILED =
  "FETCH_PROSPECTS_STATUSES_FAILED";

export const FETCH_TIMELINE_RESPONSES_REQUEST =
  "FETCH_TIMELINE_RESPONSES_REQUEST";
export const FETCH_TIMELINE_RESPONSES_SUCCEEDED =
  "FETCH_TIMELINE_RESPONSES_SUCCEEDED";
export const FETCH_TIMELINE_RESPONSES_FAILED =
  "FETCH_TIMELINE_RESPONSES_FAILED";
