import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//Hooks
import {
  useGetIndustriesQuery,
  useGetCountriesQuery,
  useGetJobTitleDepartmentsQuery,
  useGetJobTitleSenioritiesQuery,
} from "@api/audienceExplorerApi";
import { useQuitMutation } from "@api/assistantApi";
import useSignalRHook from "@hooks/useSignalRHook";

//Redux
import { fetchSettings } from "@store/OrganizationSettings/General/actions/general";
import { signalReceived } from "@store/Signal/actions/signal";

//Helpers
import { temperatureAIChainTemplatesEnum } from "@helper/enums/temperatureAIChainTemplatesEnum";
import { templateSettingAIChainTemplatesEnum } from "@helper/enums/templateSettingAIChainTemplatesEnum";
import { EmailTemplateTone } from "@helper/enums/emailTemplateTypeEnum";
import { SIGNALR_CONSTANTS } from "@helper/signalrConstants";
import api, { API, formUrl } from "@helper/api/api";
import SignalRContext from "@helper/SignalRContext";
import { checkForEmailProviderType } from "@pages/Assistant/AssistantHelperMethods";

//Images & Icons
import CombinationMarkLogoDark from "@assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";
import Preparing from "@assets/Images/PreparingImage/Preparing";

//Styles
import styles from "./OutbaseAssistant.module.scss";

//Components
import CreateOrganizationStep from "./Components/CreateOrganizationStep/CreateOrganizationStep";
import HeaderTitle from "@components/UI/HeaderTitle/HeaderTitle";
import LoggedInUser from "@components/Header/LoggedInUser/LoggedInUser";
import Button from "@components/UI/Button/Button";
import SelectIndustriesStep from "./Components/SelectIndustiresStep/SelectIndustiresStep";
import SelectCountryLocationStep from "./Components/SelectCountryLocationStep/SelectCountryLocationStep";
import CompanySizeStep from "@pages/Assistant/components/CompanySizeStep/CompanySizeStep";
import DepartmentsAndSeniority from "./Components/DepartmentsAndSeniority/DepartmentsAndSeniority";
import OrganizationInfoStep from "./Components/OrganizationInfoStep/OrganizationInfoStep";
import TemplateToneStep from "./Components/TemplateToneStep/TemplateToneStep";
import CampaignSummary from "./Components/CampaignSummary/CampaignSummary";
import ConnectAccountStep from "./Components/ConnectAccountStep/ConnectAccountStep";
import ProgressBar from "@components/ProgressBar/ProgressBar";
import Spinner from "@components/UI/Spinner/Spinner";
import CreateSignatureStep from "./Components/SignatureStep/SignatureStep";
import { LocationType } from "@models/assistant/AssistantModels";

const OutbaseAssistant = (props) => {
  // Hooks
  const { data: listIndustries } = useGetIndustriesQuery();
  const { data: listCountries } = useGetCountriesQuery();
  const { data: listJobTitleDepartments } = useGetJobTitleDepartmentsQuery();
  const { data: listJobTitleSeniorities } = useGetJobTitleSenioritiesQuery();

  const url = new URL(window.location.href);
  const slug = url.pathname.split("/").pop();

  const [disabledButton, setDisabledButton] = useState(true);
  const totalSteps = 8;
  const { organizationId } = useParams();
  const [quit] = useQuitMutation();
  const [currentStep, setCurrentStep] = useState(null);
  const [executionId, setExecutionId] = useState(null);
  const [createSearchKey, setCreateSearchKey] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [hasSummary, setHasSummary] = useState(false);
  // Initialize campaign object
  const [organizationSettings, setOrganizationSettings] = useState({
    audience: {
      tags: [],
      companySize: [],
      companyLocations: {},
      contactLocations: {},
      range: {
        location: {},
        rangeMiles: 0,
      },
      departments: [],
      seniority: [],
      searchKey: "",
    },
    tone: EmailTemplateTone.InformalTone,
    companyDescription: {
      websiteURL: props.organization.domain ?? "",
      description: props.predefinedCompanyDetails?.description ?? "",
      productInformation: props.predefinedCompanyDetails?.productInformation ?? "",
      problemInformation: props.predefinedCompanyDetails?.problemInformation ?? "",
    },
    settings: {
      hasChanges: false,
      locationType: LocationType.Country,
    },
    emailAccount: "",
    emailAccountId: "",
    signature: "",
  });

  useSignalRHook(SIGNALR_CONSTANTS.CAMPAIGN_WIZARD_CAMPAIGN_COMPLETED, (data) => {
    if (organizationId == data.organizationId && executionId == data.executionId) {
      props.history.push(`/${data.organizationId}/campaigns/${data.campaignId}/overview`);
    }
  });

  const startExecutionPromise = () => {
    return new Promise((resolve, reject) => {
      api(API.assistant.startExecution, { slug: slug })
        .then((response) => {
          setExecutionId(response.data.id);
          setIsCompleted(false);
          let data = response.data.data;
          if (data != null && Object.keys(data).length > 0) setOrganizationSettings(response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const submitDataPromise = () => {
    return new Promise((resolve, reject) => {
      let data = {
        executionId: executionId,
        step: currentStep,
        data: organizationSettings,
        createSearchKey: createSearchKey,
      };
      setIsSubmiting(true);
      api(API.assistant.saveStepData, data)
        .then((response) => {
          if (response.data.searchKey) {
            setOrganizationSettings({
              ...organizationSettings,
              audience: {
                ...organizationSettings.audience,
                searchKey: response.data.searchKey,
              },
            });
          }
          resolve(response);
        })
        .catch((error) => {
          setCreateSearchKey(false);
          console.log(error);
          reject(error);
        });
    });
  };

  const handleCreateCampaignPromise = (emailAccountId = null) => {
    return new Promise((resolve, reject) => {
      let data = {
        emailAccountId: organizationSettings.emailAccountId ?? emailAccountId,
        searchKey: organizationSettings.audience.searchKey,
        signature: organizationSettings.signature,
        executionId: executionId,
        request: {
          initialTemplate: {
            website: props.organization.domain,
            companyName: props.organization.name,
            senderName: props.user.firstName,
            description: organizationSettings.companyDescription.description,
            usp: organizationSettings.companyDescription.productInformation,
            companySizeTargeting: organizationSettings.audience.companySize,
            painPoint: organizationSettings.companyDescription.problemInformation,
            websiteURL: organizationSettings.companyDescription.websiteURL,
            temperature: temperatureAIChainTemplatesEnum.initial,
            tone: organizationSettings.tone,
            templateSettings: templateSettingAIChainTemplatesEnum.templateSetting,
            additionalInstructions: "",
            signature: organizationSettings.signature,
          },
          followUps: [
            {
              followUpSettingId: 1,
              temperature: temperatureAIChainTemplatesEnum.followup,
              additionalInformation: "",
            },
            {
              followUpSettingId: 2,
              temperature: temperatureAIChainTemplatesEnum.followup,
              additionalInformation: "",
            },
            {
              followUpSettingId: 3,
              temperature: temperatureAIChainTemplatesEnum.followup,
              additionalInformation: "",
            },
          ],
        },
      };
      api(API.assistant.createCampaign, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    startExecutionPromise().then((response) => {
      let initialStep = response.currentStep ?? 1;
      setCurrentStep(initialStep);
      loadStep(response.id, initialStep);
    });
    getSummary(props.organization.domain);
    fetchSettings();
    checkForEmailProviderType({ email: props.user.email, setAccountType: setAccountType });
  }, []);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        !organizationSettings.audience.tags.length ? setDisabledButton(true) : setDisabledButton(false);
        break;
      case 2:
        !organizationSettings.audience.companySize.length ? setDisabledButton(true) : setDisabledButton(false);
        break;
      case 3:
        if (organizationSettings.settings?.locationType === LocationType.Country) {
          !organizationSettings.audience?.companyLocations?.country?.length ? setDisabledButton(true) : setDisabledButton(false);
        }
        if (organizationSettings.settings?.locationType === LocationType.Region) {
          !organizationSettings.audience?.companyLocations?.region?.length ? setDisabledButton(true) : setDisabledButton(false);
        }
        if (organizationSettings.settings?.locationType === LocationType.ZipCode) {
          !organizationSettings.audience?.range?.location || Object.keys(organizationSettings.audience?.range?.location)?.length === 0
            ? setDisabledButton(true)
            : setDisabledButton(false);
        }
        break;
      case 4:
        !organizationSettings.audience.departments.length || !organizationSettings.audience.seniority.length
          ? setDisabledButton(true)
          : setDisabledButton(false);
        break;
      case 5:
        !organizationSettings.companyDescription.description ||
        !organizationSettings.companyDescription.productInformation ||
        !organizationSettings.companyDescription.problemInformation
          ? setDisabledButton(true)
          : setDisabledButton(false);
        break;
      default:
        setDisabledButton(false);
        break;
    }
  }, [organizationSettings, currentStep]);

  let user = <LoggedInUser orgPage={true} history={props.history} />;

  let logo = (
    <div className={styles.logoDetails}>
      <CombinationMarkLogoDark width={150} height={37} />
    </div>
  );

  const handleQuit = () => {
    quit(executionId)
      .unwrap()
      .then(() => {
        props.history.push(`/${organizationId}/dashboard`);
      });
  };

  const handleQuitOnboarding = (isCompleted = false, emailAccountId = null) => {
    setCreateSearchKey(false);
    if (isCompleted || emailAccountId) {
      setLoading(true);
      setAnimate(true);
      submitDataPromise().then(() => {
        handleCreateCampaignPromise(emailAccountId).then((response) => {
          setAnimate(false);
        });
      });
    } else {
      handleQuit();
    }
  };

  const getSummary = (newUrl = null) => {
    return new Promise((resolve, reject) => {
      let data = null;
      api(formUrl(API.assistant.getSummary, { url: newUrl ?? props.organization.domain }))
        .then((response) => {
          data = response.data;
          setOrganizationSettings((prevState) => ({
            ...prevState,
            companyDescription: {
              websiteURL: newUrl,
              description: data.description,
              productInformation: data.productInfo,
              problemInformation: data.solutionDescription,
            },
          }));
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const loadStep = (initialId = null, step = null) => {
    let data = {
      executionId: initialId ?? executionId,
      step: step ?? currentStep,
    };
    api(API.assistant.loadStepData, data).catch((error) => {
      console.log(error);
      return;
    });
  };

  const handleNext = () => {
    if (currentStep <= totalSteps) {
      submitDataPromise().then(() => {
        setIsSubmiting(false);
        setCurrentStep(currentStep + 1);
        loadStep(null, currentStep + 1);
      });
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      loadStep(null, currentStep - 1);
      setCurrentStep(currentStep - 1);
    }
  };

  const formatCountries = (options) => {
    if (!options || !Array.isArray(options)) return [];
    let result = options.map((item) => {
      return {
        label: item.label,
        included: true,
        value: {
          label: item.label,
          included: true,
          value: "10:" + item.value,
        },
      };
    });

    return result;
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <SelectIndustriesStep
            organizationSettings={organizationSettings}
            changeIndustries={setOrganizationSettings}
            listIndustries={listIndustries}
            setCreateSearchKey={setCreateSearchKey}
          />
        );
      case 2:
        return (
          <CompanySizeStep
            campaignObject={organizationSettings}
            setCampaignObject={setOrganizationSettings}
            setCreateSearchKey={setCreateSearchKey}
          />
        );
      case 3:
        return (
          <SelectCountryLocationStep
            organizationSettings={organizationSettings}
            setCampaignObject={setOrganizationSettings}
            listCountries={formatCountries(listCountries)}
            setCreateSearchKey={setCreateSearchKey}
          />
        );
      case 4:
        return (
          <DepartmentsAndSeniority
            organizationSettings={organizationSettings}
            handleUpdateObjectAction={setOrganizationSettings}
            setCreateSearchKey={setCreateSearchKey}
            listJobTitleDepartments={listJobTitleDepartments}
            listJobTitleSeniorities={listJobTitleSeniorities}
            maxOptions={20}
          />
        );
      case 5:
        return (
          <OrganizationInfoStep
            organizationSettings={organizationSettings}
            setOrganizationSettings={setOrganizationSettings}
            getSummary={getSummary}
          />
        );
      case 6:
        return <TemplateToneStep organizationSettings={organizationSettings} setOrganizationSettings={setOrganizationSettings} />;
      case 7:
        return <CampaignSummary />;
      case 8:
        return (
          <ConnectAccountStep
            campaignObject={organizationSettings}
            setCampaignObject={setOrganizationSettings}
            accountType={accountType}
            slug={slug}
            user={props.user}
          />
        );
      default:
        return null;
    }
  };
  return isCompleted != null ? (
    <div className={styles.outbaseAssistant}>
      <div className={`width_100 ${styles.navigationMenu}`}>
        <HeaderTitle className="p0 width_100" title={logo} rightContent={user} />
      </div>
      <div className={`flex_column ${styles.steps}`}>
        <div>
          {loading ? null : (
            <ProgressBar
              now={currentStep === 10 ? 99 : (currentStep / totalSteps) * 100}
              max={100}
              min={0}
              showLabel
              labelText={
                currentStep === Math.round(totalSteps / 2)
                  ? "Halfway there..."
                  : currentStep === totalSteps - 1
                  ? "One more step..."
                  : `${currentStep} / ${totalSteps}`
              }
            />
          )}
          {loading ? <Preparing animate={animate} topLabelText="Creating your campaign" /> : renderStepContent()}
        </div>
        {loading ? null : (
          <div className={`flex space_between ${styles.buttonsWrapper} ${currentStep === 1 ? "justify_end" : ""}`}>
            <Button id={`quitButton${currentStep}`} disabled={isSubmiting} variant="link_button" onClick={handleQuit}>
              Quit Onboarding
            </Button>
            <div className="flex">
              {currentStep > 1 && currentStep < totalSteps && (
                <Button id={`back${currentStep}`} variant="link_button" onClick={handlePrev} disabled={isSubmiting}>
                  Go Back
                </Button>
              )}
              <Button
                id={`continue${currentStep}`}
                disabled={disabledButton || isSubmiting}
                onClick={currentStep === totalSteps ? handleQuitOnboarding : handleNext}
              >
                {currentStep === totalSteps ? (organizationSettings.emailAccount ? "Continue" : "Skip and continue") : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

const mapStateToProps = (state) => {
  return {
    organization: state.user.organization,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutbaseAssistant);
