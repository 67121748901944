import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import { formatNumberHelper } from "../../helper/formatNumberHelper";

//Styles
import styles from "./AudienceContacts.module.scss";

//Images & Icons
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";
import GlobalFillIcon from "remixicon-react/GlobalFillIcon";

// Components
import Modal from "../../components/UI/Modal/Modal";
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";
import Spinner from "@ui/Spinner/Spinner";
import { useGetCompanyDetailsQuery } from "@api/audienceExplorerApi";
import { useGroupDecimalSeparator } from "@hooks/useGroupDecimalSeparator";

interface DetailsFormProps {
  showModal: boolean;
  handleClose: () => void;
  companyId?: number | null;
}

interface InfoRowProps {
  label: string;
  value: string | number | null;
  formatFunction?: (value: number) => string;
}

const InfoRow: React.FC<InfoRowProps> = ({ label, value, formatFunction }) => (
  <>
    <Col xl={4}>
      <span className={styles.informationType}>{label}:</span>
    </Col>
    <Col xl={8} className="textRight">
      <span className={styles.information}>{value ? (formatFunction ? formatFunction(Number(value)) : value) : "N/A"}</span>
    </Col>
  </>
);

const AudExplorerCompanyDetailsForm: React.FC<DetailsFormProps> = (props) => {
  const separators = useGroupDecimalSeparator();
  const { data: company, isLoading } =
    props.companyId && props.companyId !== 0 ? useGetCompanyDetailsQuery(props.companyId) : { data: null, isLoading: false };

  return (
    <Modal
      title={"Company Details"}
      handleSave={props.handleClose}
      handleClose={props.handleClose}
      show={props.showModal}
      oneButton
      saveButtonText="Close"
      btnType="submit"
    >
      {isLoading && <Spinner />}
      {company && (
        <Container>
          <div className={styles.profileDetails}>
            <div className={styles.profileImage}>
              <UserPhoto alt="org-photo" isCompanyImage={true} src={company.logo} size="medium-l" className="m0" />
            </div>
            <div xl={9} className={styles.detailsInfo}>
              <h4 className={styles.headerName}>{company.name}</h4>
              <span className={styles.location}>
                <MapPin2FillIcon size={16} className="iconAccent mR5" />
                {company.calculatedLabel}
              </span>

              <span className={styles.webAddress}>
                <GlobalFillIcon size={16} className="iconAccent mR5" />
                <a
                  href={
                    company.domain.startsWith("http://") || company.domain.startsWith("https://")
                      ? company.domain
                      : `http://${company.domain}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {company.domain}
                </a>
              </span>
            </div>
          </div>

          <Row>
            <Col xl={12}>
              <hr />
            </Col>
          </Row>

          <Row>
            <InfoRow label="Industry" value={company.industry} />
            <InfoRow label="Company size" value={company.size} />
            <InfoRow
              label="Revenue"
              value={company.revenue}
              formatFunction={(value: number) =>
                formatNumberHelper(
                  Math.trunc(Number(value)),
                  separators?.numberGroupSeperator ?? ",",
                  separators?.numberDecimalSeperator ?? "."
                )
              }
            />
          </Row>
        </Container>
      )}
    </Modal>
  );
};

export default AudExplorerCompanyDetailsForm;
