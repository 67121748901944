export const columns = [
  {
    label: "Prospect",
    field: "name",
    type: "text",
    sort: "asc",
    flex: 2,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Contact info",
    field: "contactInfo",
    type: "text",
    sort: "asc",
    flex: 2,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Job Title",
    field: "jobTitle",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Company",
    field: "companyName",
    type: "text",
    sort: "asc",
    flex: 2,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Current Status",
    field: "currentStatus",
    type: "action",
    sort: "asc",
    flex: 2,
    width: 200,
    minWidth: 200,
  },
  {
    label: "Response Type",
    field: "responseType",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    showTooltip: false,
  },
  {
    label: "Current Stage",
    field: "currentStage",
    type: "number",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
  },
  {
    label: "Current campaign",
    field: "campaignName",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    showTooltip: true,
  },
  {
    label: "Conversations",
    field: "conversations",
    type: "actions",
    sort: "disabled",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Last Send",
    field: "lastSend",
    type: "date",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Last Activity",
    field: "lastActivity",
    type: "date",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "First Activity",
    field: "firstActivity",
    type: "date",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Created Date",
    field: "createdDate",
    type: "date",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Excluded",
    field: "isExcluded",
    type: "actions",
    sort: "asc",
    width: 80,
    isHidden: true,
  },
  {
    label: "Prospect Country",
    field: "prospectCountry",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Industry",
    field: "industry",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
  {
    label: "Company Size",
    field: "companySize",
    type: "text",
    sort: "asc",
    flex: 1,
    width: 150,
    minWidth: 150,
    isHidden: true,
  },
];
