//Images & Icons
import MapPinLineIcon from "remixicon-react/MapPinLineIcon";
import AwardLineIcon from "remixicon-react/AwardLineIcon";
import ContactsLineIcon from "remixicon-react/ContactsLineIcon";

// Components
import AccordionSimple from "../../../../components/UI/AccordionSimple/AccordionSimple";
import FilterContactName from "./ContactFilters/FilterContactName";
import FilterContactLocation from "./ContactFilters/FilterContactLocation";
import FilterJobTitleDepartments from "./ContactFilters/FilterJobTitleDepartments";
import FilterJobTitleSeniorities from "./ContactFilters/FilterJobTitleSeniorities";
import FilterJobTitles from "./ContactFilters/FilterJobTitles";

import { useFormikContext } from "formik";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";

const ContactFilterGroup: React.FC = () => {
  const {
    values: {
      contact: {
        jobTitles,
        departments,
        seniorities,
        location: {
          type,
          region: { locations },
          range,
        },
        names,
      },
    },
  } = useFormikContext<SearchModel>();

  const jobTitleSectionCount = jobTitles.length + departments.length + seniorities.length;
  const locationFilterCount = (locations.length > 0 ? 1 : 0) + (range?.location?.value?.name ? 1 : 0);

  return (
    <>
      <h1 className="f14 mT10 mB10">Select contacts by:</h1>
      <div id="contactFilters">
        <AccordionSimple
          icon={<AwardLineIcon size={16} />}
          className="mB10"
          header={"Job title & seniority"}
          eventKey="titleAndFunctions"
          rightContent={jobTitleSectionCount}
          defaultOpen={jobTitleSectionCount > 0}
          body={
            <>
              <AccordionSimple
                icon={<></>}
                className="mB10"
                header="Departments"
                eventKey="departmentsFunctions"
                rightContent={departments.length}
                defaultOpen={departments.length > 0}
                body={<FilterJobTitleDepartments />}
              />
              <AccordionSimple
                icon={<></>}
                className="mB10"
                header="Seniority"
                eventKey="managementLevel"
                rightContent={seniorities.length}
                defaultOpen={seniorities.length > 0}
                body={<FilterJobTitleSeniorities />}
              />

              <FilterJobTitles />
            </>
          }
        />
        <AccordionSimple
          icon={<MapPinLineIcon size={16} />}
          className="mB10"
          header="Locations"
          eventKey="contactLocation"
          rightContent={locationFilterCount}
          defaultOpen={locationFilterCount > 0}
          body={<FilterContactLocation />}
        />
        <AccordionSimple
          icon={<ContactsLineIcon size={16} />}
          className="mB10"
          header={"Names"}
          eventKey="name"
          rightContent={names.length}
          defaultOpen={names.length > 0}
          body={<FilterContactName />}
        />
      </div>
    </>
  );
};

export default ContactFilterGroup;
