import React from "react";
import { connect } from "react-redux";
import { fetchProspectDetails } from "../../../../store/Conversation/actions/conversation";
import _ from "lodash";
import { Link } from "react-router-dom";

//Style
import styles from "./ProspectDetailsPreview.module.scss";

//Images & Icons
import LinkedinBoxFillIcon from "remixicon-react/LinkedinBoxFillIcon";

// Components
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import UserPhoto from "../../../../components/UI/UserPhoto/UserPhoto";

const ProspectDetailsPreview = (props) => {
  const { prospectDetails } = props;

  return (
    <div className={styles.prospectDetailsWrapper}>
      <SectionTitle title="PROSPECT DETAILS" />
      <div className={`${styles.headerWrapper} flex`}>
        <div className={styles.avatarWrapper}>
          <UserPhoto isCompanyImage={true} name={prospectDetails.fullName} src={props.orgImageUrl} />
        </div>
        <div className={styles.detailsInfo}>
          <p className={styles.name}>{prospectDetails.fullName}</p>
          <p>{prospectDetails.jobTitle}</p>
          {!_.isEmpty(prospectDetails.country) ? <p>{prospectDetails.country}</p> : null}
        </div>
      </div>
      <div className={styles.aboutWrapper}>
        <p className={styles.title}>About</p>
        <p>
          <span>Company:</span>
          {prospectDetails.companyId > 0 ? (
            <Link
              className={styles.companyLink}
              to={{
                pathname: "/" + props.orgId + "/company-activity-overview/" + prospectDetails.companyId,
              }}
            >
              {prospectDetails.companyName}
            </Link>
          ) : (
            prospectDetails.companyName
          )}
        </p>
        <p>
          <span>Company Size:</span> {prospectDetails.size}
        </p>
        <p>
          <span>Industry:</span> {prospectDetails.industry}
        </p>
        <p>
          <span>Revenue:</span> {prospectDetails.revenue}
        </p>
      </div>
      <div className={styles.aboutWrapper}>
        <p className={styles.title}>Contact Details</p>
        <p>
          <span>Phone:</span> {prospectDetails.phone}
        </p>
        <p>
          <span>Email:</span> {prospectDetails.email}
        </p>
      </div>
      <div className={styles.aboutWrapper}>
        <p className={styles.title}>Social Media</p>
        {prospectDetails.linkedInUrl && prospectDetails.linkedInUrl !== "" ? (
          <div>
            <a
              rel="noopener noreferrer"
              href={prospectDetails.linkedInUrl.includes("https") ? prospectDetails.linkedInUrl : "//" + prospectDetails.linkedInUrl}
              target="_blank"
            >
              <LinkedinBoxFillIcon color="#0077B5" />
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orgImageUrl: state.user.organization.imageUrl,
    orgId: state.user.organization.id,
    prospectDetails: state.conversation.prospectDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProspectDetails: (id) => dispatch(fetchProspectDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectDetailsPreview);
