import React from "react";

// Styles
import styles from "./ConfigurationTestDetail.module.scss";

const ConfigurationTestDetail = (props) => {
  return (
    <div className={styles.detailsWrapper}>
      <div className={`${styles.iconWrapper} mR15`}>{props.icon}</div>
      <div className={styles.testName}>{props.testName}</div>
      <div className={`${styles.result} ${styles.fail}`}>{props.result}</div>
      <div className={styles.description}>{props.description}</div>
      <div className={styles.info}>
        <p className={styles.infoText}>{props.info}</p>
      </div>
    </div>
  );
};

export default ConfigurationTestDetail;
