import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Form } from "formik";

//Styles
import styles from "../Exclusions.module.scss";

//Images and Icons
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";

//Components
import FormikInput from "../../../components/UI/Formik/FormikInput";

const AddExclusionsForm = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <p className={styles.modalTitle}>Outbase Recommends</p>
        </Col>
        <Col md={7}>
          <p>
            Excluding all partners, clients, active sales engagements and competitors. This is usually a simple CRM export based on status.
          </p>
          <p>Exclusions can be submitted in either of the following formats:</p>
          <ul>
            <li>
              <b>Individual exclusion (e.g. john.smith@outbase.com)</b>
            </li>
            <li>
              <b>Domain exclusion (e.g. outbase.com)</b>
            </li>
          </ul>
          <p>
            Please note: Outbase cannot add exclusions using only a company name and multi-brand operations often use various trading names,
            or parent or group email services.
          </p>
          <p className="m0">
            <b>Important: </b>Uploading a CSV? Ensure all domains and Email address exclusions are listed in a single column headed
            "Exclusion".
          </p>
          <button id="downloadCSV" href={null} className={styles.downloadCSVTemplateButton} onClick={props.downloadCSVTemplate}>
            Download CSV template
          </button>
        </Col>
        <Col md={5}>
          <Row>
            <Col md={12}>
              <Form>
                <FormikInput
                  onChange={(e) => {
                    props.setSaveButtonDisabled(false);
                  }}
                  name="exclusions"
                  type="textarea"
                  rows="8"
                  placeholder="Enter Exclusions, 1 Per Line"
                  inputGroupClass="m0"
                  inputclass={styles.exclusionsTextArea}
                  onBlur={(e) => {
                    let text = e.target.value;
                    if (text === "") props.onFormChange(text, props.values.csvFile);
                  }}
                />
              </Form>
            </Col>
            <Col md={12}>
              <label id="uploadCSVFile" className={styles.uploadCSVFile}>
                UPLOAD CSV FILE
                <input
                  id="csvFile"
                  name="csvFile"
                  type="file"
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={(event) => {
                    props.setSaveButtonDisabled(false);
                    props.setFieldValue("csvFile", event.currentTarget.files[0]);
                    props.setCSVFileName(event.currentTarget.files[0].name);
                    props.setCSVFileError(false);
                    event.currentTarget.value = null;
                  }}
                  className="form-control"
                />
              </label>
              <p className={styles.uploadFileContainer}>
                {props.CSVFileName}
                {props.CSVFileName && props.CSVFileName !== "" ? (
                  <DeleteBin6LineIcon
                    size={16}
                    className={`mL10 cursor_pointer ${styles.removeCSVFile}`}
                    click={() => {
                      props.setFieldValue("csvFile", null);
                      props.setCSVFileName("");
                      props.setCSVFileError(false);
                      props.onFormChange(props.values.exclusions, "");
                    }}
                  />
                ) : null}
              </p>

              <p className={styles.csvFileError} hidden={!props.CSVFileError}>
                The uploaded file has unsupported file format.
              </p>
              <p className={styles.exclusionsResultError} hidden={!props.showExclusionsResultError}>
                {props.exclusionsResultError}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AddExclusionsForm;
