import React from "react";

//Components
import Modal from "@components/UI/Modal/Modal";

interface ConfirmModalProps {
  handleClose: () => void;
  handleSave: () => void;
  showModal: boolean;
  children: React.ReactNode;
  className?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ children, handleClose, showModal, handleSave, className }) => {
  return (
    <Modal
      handleClose={handleClose}
      handleSave={handleSave}
      show={showModal}
      closeButtonText="Cancel"
      saveButtonText="Confirm"
      btnType="submit"
    >
      <p className={className}>{children}</p>
    </Modal>
  );
};

export default ConfirmModal;
