import { useEffect, useState } from "react";

//Styles
import styles from "./CompanySizeStep.module.scss";

// Models
import { CampaignObjectModel } from "@models/assistant/AssistantModels";
import { CompanySizeEnum as CompanySizes } from "@models/enums/CompanySizeEnum";

//Components
import Button from "@components/UI/Button/Button";

interface CompanySizeStepProps {
  title?: string;
  description?: JSX.Element;
  campaignObject: CampaignObjectModel;

  setCampaignObject: (campaignObject: CampaignObjectModel) => void;
  setCreateSearchKey: (value: boolean) => void;
}

const CompanySizeStep = ({
  title = "What company sizes are most likely to buy from you?",
  description = (
    <>
      We recommend focusing on one company size group for a more customised strategy.
      <br />
      To target different company sizes, consider creating separate lists in new campaigns.
    </>
  ),
  campaignObject,

  setCampaignObject,
  setCreateSearchKey,
}: CompanySizeStepProps) => {
  const [selectedSizes, setSelectedSizes] = useState<string[]>(campaignObject.audience.companySize);

  useEffect(() => {
    handleSizeSelected(selectedSizes);
  }, []);

  const handleSizeSelected = (values: string[]) => {
    setCampaignObject({
      ...campaignObject,
      audience: {
        ...campaignObject.audience,
        companySize: [...values],
      },
    });
  };

  const handleSizeClick = (size: keyof typeof CompanySizes) => {
    const sizes = CompanySizes[size].split("/");
    setCreateSearchKey(true);
    setSelectedSizes(sizes);
    handleSizeSelected(sizes);
  };

  const renderButton = (sizeKey: keyof typeof CompanySizes, label: string, employeeRange: string) => (
    <Button
      id={`${sizeKey}CompanySize`}
      onClick={() => handleSizeClick(sizeKey)}
      value={CompanySizes[sizeKey]}
      variant="onBoardingButton"
      classes={`flex_column align-center justify_start ${styles.autoButton} ${
        selectedSizes.join("/").includes(CompanySizes[sizeKey]) ? styles.active : ""
      } ${styles[`${sizeKey.toLowerCase()}_company`]}`}
    >
      <p className={`f14 m0 font500 ${styles.title}`}>{label}</p>
      <p className={`f14 ${styles.size}`}>{employeeRange}</p>
    </Button>
  );

  return (
    <>
      <h1 className="f16 font700 mB15 textCenter">{title}</h1>
      <p className="f14 secondary_text_color textCenter">{description}</p>
      <div className="flex_center">
        {renderButton("SMALL", "Small", "0-50 employees")}
        {renderButton("MEDIUM", "Medium", "51-1000 employees")}
        {renderButton("LARGE", "Large", "1000+ employees")}
      </div>
    </>
  );
};

export default CompanySizeStep;
