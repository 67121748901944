import * as actionTypes from "./actionTypes";
import api, { API } from "../../../../helper/api/api";

export const fetchCompaniesListRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_COMPANIES_REQUEST,
  };
};

export const fetchCompaniesListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_LIST_COMPANIES_SUCCEEDED,
    result: setup,
  };
};

export const fetchCompaniesListFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_COMPANIES_FAILED,
    result: error,
  };
};

export const fetchCompaniesList = () => {
  return (dispatch) => {
    dispatch(fetchCompaniesListRequest());
    return api(API.prospects.listCompanies)
      .then((response) => {
        dispatch(fetchCompaniesListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompaniesListFailed(error.message));
      });
  };
};

export const fetchCompaniesListAutoCompleteRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_COMPANIES_AUTOCOMPLETE_REQUEST,
  };
};

export const fetchCompaniesListAutoCompleteSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_LIST_COMPANIES_AUTOCOMPLETE_SUCCEEDED,
    result: setup,
  };
};

export const fetchCompaniesListAutoCompleteFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_COMPANIES_AUTOCOMPLETE_FAILED,
    result: error,
  };
};

export const fetchCompaniesListAutoComplete = (data) => {
  return (dispatch) => {
    dispatch(fetchCompaniesListAutoCompleteRequest());

    return api(API.prospects.listCompaniesAutoComplete, data)
      .then((response) => {
        dispatch(fetchCompaniesListAutoCompleteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompaniesListAutoCompleteFailed(error.message));
      });
  };
};

export const fetchStatusCountRequestForCompanies = () => {
  return {
    type: actionTypes.FETCH_STATUS_COUNT_COMPANIES_REQUEST,
  };
};

export const fetchStatusCountSuccessForCompanies = (setup) => {
  return {
    type: actionTypes.FETCH_STATUS_COUNT_COMPANIES_SUCCEEDED,
    result: setup,
  };
};

export const fetchStatusCountFailedForCompanies = (error) => {
  return {
    type: actionTypes.FETCH_STATUS_COUNT_COMPANIES_FAILED,
    result: error,
  };
};

export const fetchStatusCountForCompanies = (obj) => {
  return (dispatch) => {
    dispatch(fetchStatusCountRequestForCompanies());
    return api(API.companies.statuscount, obj)
      .then((response) => {
        dispatch(fetchStatusCountSuccessForCompanies(response.data));
      })
      .catch((error) => {
        dispatch(fetchStatusCountFailedForCompanies(error.message));
      });
  };
};

export const fetchIndustryListRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_INDUSTRY_REQUEST,
  };
};

export const fetchIndustryListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_LIST_INDUSTRY_SUCCEEDED,
    result: setup,
  };
};

export const fetchIndustryListFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_INDUSTRY_FAILED,
    result: error,
  };
};

export const fetchIndustryList = () => {
  return (dispatch) => {
    dispatch(fetchIndustryListRequest());
    return api(API.companies.listIndustry)
      .then((response) => {
        dispatch(fetchIndustryListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchIndustryListFailed(error.message));
      });
  };
};

export const fetchSizeListRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_SIZE_REQUEST,
  };
};

export const fetchSizeListSuccess = (setup) => {
  return {
    type: actionTypes.FETCH_LIST_SIZE_SUCCEEDED,
    result: setup,
  };
};

export const fetchSizeListFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_SIZE_FAILED,
    result: error,
  };
};

export const fetchSizeList = () => {
  return (dispatch) => {
    dispatch(fetchSizeListRequest());
    return api(API.companies.size)
      .then((response) => {
        dispatch(fetchSizeListSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSizeListFailed(error.message));
      });
  };
};
