import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const fetchCreditInfoRequest = () => {
  return {
    type: actionTypes.FETCH_CREDIT_INFO_REQUEST,
  };
};

export const fetchCreditInfoSuccess = (events) => {
  return {
    type: actionTypes.FETCH_CREDIT_INFO_SUCCEEDED,
    result: events,
  };
};

export const fetchCreditInfoFailed = (error) => {
  return {
    type: actionTypes.FETCH_CREDIT_INFO_FAILED,
    result: error,
  };
};

export const fetchCreditInfo = () => {
  return (dispatch) => {
    dispatch(fetchCreditInfoRequest());
    return api(API.billingAndPayment.getCreditInfo)
      .then((response) => {
        dispatch(fetchCreditInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCreditInfoFailed(error.message));
      });
  };
};

export const updateCurrentCredits = (credits) => {
  return {
    type: actionTypes.UPDATE_CURRENT_CREDITS,
    result: credits,
  };
};

export const fetchBillingInfoRequest = () => {
  return {
    type: actionTypes.FETCH_BILLING_INFO_REQUEST,
  };
};

export const fetchBillingInfoSuccess = (events) => {
  return {
    type: actionTypes.FETCH_BILLING_INFO_SUCCEEDED,
    result: events,
  };
};

export const fetchBillingInfoFailed = (error) => {
  return {
    type: actionTypes.FETCH_BILLING_INFO_FAILED,
    result: error,
  };
};

export const fetchBillingInfo = () => {
  return (dispatch) => {
    dispatch(fetchBillingInfoRequest());
    return api(API.billingAndPayment.getBillingInfo)
      .then((response) => {
        dispatch(fetchBillingInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBillingInfoFailed(error.message));
      });
  };
};

export const fetchPricingPlansRequest = () => {
  return {
    type: actionTypes.FETCH_PRICING_PLANS_REQUEST,
  };
};

export const fetchPricingPlansSuccess = (events) => {
  return {
    type: actionTypes.FETCH_PRICING_PLANS_SUCCEEDED,
    result: events,
  };
};

export const fetchPricingPlansFailed = (error) => {
  return {
    type: actionTypes.FETCH_PRICING_PLANS_FAILED,
    result: error,
  };
};

export const fetchPricingPlans = (val) => {
  return (dispatch) => {
    dispatch(fetchPricingPlansRequest());
    return api(formUrl(API.billingAndPayment.getPricingPlans, { currencyId: val }))
      .then((response) => {
        dispatch(fetchPricingPlansSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPricingPlansFailed(error.message));
      });
  };
};

export const fetchContractStatusRequest = () => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATUS_REQUEST,
  };
};

export const fetchContractStatusSuccess = (events) => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATUS_SUCCEEDED,
    result: events,
  };
};

export const fetchContractStatusFailed = (error) => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATUS_FAILED,
    result: error,
  };
};

export const fetchContractStatus = () => {
  return (dispatch) => {
    dispatch(fetchContractStatusRequest());
    return api(API.billingAndPayment.getContractStatus)
      .then((response) => {
        dispatch(fetchContractStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchContractStatusFailed(error.message));
      });
  };
};

export const fetchPlanInfoCheckoutRequest = () => {
  return {
    type: actionTypes.FETCH_PLAN_INFO_CHECKOUT_REQUEST,
  };
};

export const fetchPlanInfoCheckoutSuccess = (events) => {
  return {
    type: actionTypes.FETCH_PLAN_INFO_CHECKOUT_SUCCEEDED,
    result: events,
  };
};

export const fetchPlanInfoCheckoutFailed = (error) => {
  return {
    type: actionTypes.FETCH_PLAN_INFO_CHECKOUT_FAILED,
    result: error,
  };
};

export const fetchPlanInfoCheckout = () => {
  return (dispatch) => {
    dispatch(fetchPlanInfoCheckoutRequest());
    return api(API.billingAndPayment.getPlanInfoCheckoutStatus)
      .then((response) => {
        dispatch(fetchPlanInfoCheckoutSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchPlanInfoCheckoutFailed(error.message));
      });
  };
};

export const setContractStatus = (status) => {
  return {
    type: actionTypes.SET_CONTRACT_STATUS,
    result: status,
  };
};

export const fetchCountryStateInfoRequest = () => {
  return {
    type: actionTypes.FETCH_COUNTRY_STATE_INFO_REQUEST,
  };
};

export const fetchCountryStateInfoSuccess = (events) => {
  return {
    type: actionTypes.FETCH_COUNTRY_STATE_INFO_SUCCEEDED,
    result: events,
  };
};

export const fetchCountryStateInfoFailed = (error) => {
  return {
    type: actionTypes.FETCH_COUNTRY_STATE_INFO_FAILED,
    result: error,
  };
};

export const fetchCountryStateInfo = () => {
  return (dispatch) => {
    dispatch(fetchCountryStateInfoRequest());
    return api(API.billingAndPayment.getCountryStateInfo)
      .then((response) => {
        dispatch(fetchCountryStateInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCountryStateInfoFailed(error.message));
      });
  };
};

