import React from "react";
import { useSelector } from "react-redux";
import { Formik, Form, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
//Helpers

//Components
import Modal from "../../components/UI/Modal/Modal";
import FormikInput from "../../components/UI/Formik/FormikInput";
import { RootState } from "../../store/configureStore";
import { useCreateCampaignMutation } from "../../api/campaignApi";
import { CreateCampaignModel } from "../../models/campaign/CreateCampaignModel";

interface FormValues {
  campaignName: string;
}

interface NewCampaignFormProps {
  handleClose: () => void;
  showModal: boolean;
}

const NewCampaignForm: React.FC<NewCampaignFormProps> = ({ handleClose, showModal }) => {
  const orgId = useSelector((state: RootState) => state.user.organization.id);
  const [createCampaign] = useCreateCampaignMutation();
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        campaignName: "",
      }}
      validationSchema={Yup.object({
        campaignName: Yup.string().required("Required").max(100, "Campaign name exceeds the maximum length (100 characters)"),
      }).required("Required")}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        const data: CreateCampaignModel = {
          name: values.campaignName,
        };

        createCampaign(data)
          .unwrap()
          .then((id) => {
            history.push("/" + orgId + "/campaigns/" + id + "/overview");
            handleClose();
          })
          .catch((error) => {
            actions.setSubmitting(false);
            toast.error(error?.data);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <Modal
            title="Create Campaign"
            handleClose={handleClose}
            handleSave={handleSubmit}
            show={showModal}
            closeButtonText="Cancel"
            saveButtonText="Enter & Continue"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
            backdrop="static"
          >
            <Form>
              <FormikInput name="campaignName" type="text" placeholder="Give it a Name" label="Campaign Name" />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default NewCampaignForm;
