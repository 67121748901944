import React from "react";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import styles from "./TimePopover.module.scss";

//Images & Icons
import InformationFillIcon from "remixicon-react/InformationFillIcon";

const TimePopover = (props) => {
  const { label, campaignTime, campaignTimeZone, organizationTime, organizationTimeZone, timeFormat, display } = props;

  // Scheduled end
  return (
    <div className={`${styles.popoverWrapper} ${props.className}`}>
      <span>
        {campaignTime == null ? (
          <>
            <span className={styles.label}>{label}:</span>
            <br />-
          </>
        ) : (
          <>
            <OverlayTrigger
              key={"scheduled-end"}
              placement={"top"}
              overlay={
                <Popover id={`popover`}>
                  <Popover.Body>
                    <div>
                      <b>Campaign time:</b>
                      <br />
                      {moment(campaignTime).format(timeFormat)}{" "}
                      <span className={styles.offset}>
                        ({campaignTimeZone.currentOffsetString}) {campaignTimeZone.displayName}
                      </span>
                      <br />
                      <b>Organization time:</b>
                      <br />
                      {moment(organizationTime).format(timeFormat)}{" "}
                      <span className={styles.offset}>
                        ({organizationTimeZone.currentOffsetString}) {organizationTimeZone.displayName}
                      </span>
                      <br />
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <span className={`${styles.scheduleDetailsWrapper} cursor_pointer`}>
                <span className={styles.label}>{label}:</span>
                <br />
                {
                  display === "campaign" ? (
                    <span className={styles.scheduleDetails}>
                      {moment(campaignTime).format(timeFormat)}{" "}
                      <span className={styles.offset}>({campaignTimeZone.currentOffsetString})</span> <InformationFillIcon size={16} />
                    </span>
                  ) : (
                    <span className={styles.scheduleDetails}>
                      {moment(organizationTime).format(timeFormat)} {"(" + organizationTimeZone.currentOffsetString + ")"}
                      <InformationFillIcon size={16} />
                    </span>
                  )
                  // Version with organization offset as well
                  // <span>{moment(organizationTime).format(timeFormat)} <span className={styles.offset}>({organizationOffsetText})</span> <InformationFillIcon size={16} /></span>
                }
              </span>
            </OverlayTrigger>
          </>
        )}
      </span>
    </div>
  );
};

export default TimePopover;
