// export const DOMAIN_URL_CHECK = /^(http|https|www):\/\/|[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
export const DOMAIN_URL_CHECK =
  /^((https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/)|[a-zA-Z0-9])(((([a-zA-Z0-9])&&([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))).*$/;
export const PASSWORD_CHECK =
  /(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
export const REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const isBusinessEmail = function (value) {
  let arrayEmails = [
    "@yahoo",
    "@gmail",
    "@outlook",
    "@yandex",
    "@zoho",
    "@protonMail",
    "@hotmail",
    "@live",
  ];
  return !arrayEmails.some((e) => value?.includes(e));
};

export const arraySpecialCharacters = [
  "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=",
  "{", "}", "[", "]", "|", "\\", ":", ";", '"', "'", "<", ">", ",",
  "?", "/", "`", "~"
];

export const isSpecialCharacter = function (value) {
  return !arraySpecialCharacters.some((e) => value?.includes(e));
};
