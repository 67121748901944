import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Dropdown } from "react-bootstrap";
import Moment from "moment";

// Styles
import "react-date-range/dist/theme/default.css";
import "react-date-range/dist/styles.css";
import "./ShowDateRangePicker.scss";

//helper
import { getTimeZoneOffset } from "@helper/dateHelper";
import { useOrgDateFormat } from "@helper/hooks/useOrgDateFormat";

//Components
import Button from "../../UI/Button/Button";

interface ShowDateRangePickerProps {
  rangeKey?: string;
  variant?: string;
  classes?: string;
  startDate?: Date;
  endDate?: Date;
  maxDate?: Date;
  appliedStartDate?: Date;
  appliedEndDate?: Date;
  onSubmit?: boolean;
  onChange: (range: { startDate: Date | undefined; endDate: Date | undefined }) => void;
  buttonWrapperClass?: string;
  customDropdownClass?: string;
  showSelectionPreview?: boolean;
  moveRangeOnFirstSelection?: boolean;
  months?: number;
  direction?: "vertical" | "horizontal" | undefined;
  editableDateInputs?: boolean;
  inputRanges?: any[];
  staticRanges?: any[];
}

interface Range {
  startDate: Date | undefined;
  endDate: Date | undefined;
  maxDate: Date | undefined;
  key: string;
}

interface AppliedRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

const ShowDateRangePicker: React.FC<ShowDateRangePickerProps> = (props) => {
  const { rangeKey = "selection" } = props;

  let classes = "customCalendarStyle";

  if (props.variant != null) {
    classes += " " + [props.variant];
  }

  if (props.classes != null) {
    classes += " " + props.classes;
  }

  let title = "Date";
  let dateFormat = useOrgDateFormat();
  var utcMoment = Moment.utc();
  var utcDate = new Date(utcMoment.format());

  const [isOpen, setIsOpen] = useState(false);

  const [currentRange, setCurrentRange] = useState<Range>({
    startDate: props.startDate != null ? props.startDate : utcDate,
    endDate: props.endDate != null ? props.endDate : utcDate,
    maxDate: props.maxDate ?? undefined,
    key: rangeKey,
  });

  const [appliedRange, setAppliedRange] = useState<AppliedRange>({
    startDate: props.appliedStartDate ?? undefined,
    endDate: props.appliedEndDate ?? undefined,
  });

  const dropdownToggle = (newValue: boolean) => {
    setIsOpen(newValue);
  };

  const handleChange = (item: any) => {
    setCurrentRange(item[currentRange.key]);
  };

  const handleApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.onSubmit) {
      e.preventDefault();
    }
    setIsOpen(false);
    const newRange = {
      startDate: currentRange.startDate ? new Date(currentRange.startDate.getTime()) : undefined,
      endDate: currentRange.endDate ? new Date(currentRange.endDate.getTime()) : undefined,
    };
    setAppliedRange(newRange);
    props.onChange(newRange);
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.onSubmit) {
      e.preventDefault();
    }
    setCurrentRange({
      startDate: props.startDate ?? undefined,
      endDate: props.endDate ?? undefined,
      maxDate: props.maxDate ?? undefined,
      key: rangeKey,
    });

    const newRange = {
      startDate: new Date(0),
      endDate: props.endDate ?? undefined,
      maxDate: props.maxDate ?? undefined,
      key: rangeKey,
    };

    setAppliedRange(newRange);
    props.onChange(newRange);
    setIsOpen(false);
  };

  if (appliedRange.startDate != null && appliedRange.endDate != null) {
    let hours = 1;
    let offset = getTimeZoneOffset();

    if (offset < 0) hours = (offset / 60) * -1;

    let newStartDate = new Date(appliedRange.startDate.valueOf());
    let newEndDate = new Date(appliedRange.endDate.valueOf());

    const propStartDateString = Moment(props.startDate).utcOffset(hours).format(dateFormat);
    const startDateString = Moment(newStartDate).utcOffset(hours).format(dateFormat);
    const endDateString = Moment(newEndDate).utcOffset(hours).format(dateFormat);

    title = propStartDateString !== startDateString ? "Date" : `${startDateString} - ${endDateString}`;
  }

  return (
    <div className={`dateRangeWrapper ${props.buttonWrapperClass ? props.buttonWrapperClass : undefined}`}>
      <Dropdown title={title} onToggle={dropdownToggle} show={isOpen} id={title.replace(/\s/g, "")}>
        <Dropdown.Toggle className={"selectFilterDropdown " + props.customDropdownClass}>{title}</Dropdown.Toggle>
        <Dropdown.Menu className="rangePickerBody">
          <DateRangePicker
            onChange={(item) => handleChange(item)}
            moveRangeOnFirstSelection={props.moveRangeOnFirstSelection}
            months={props.months}
            ranges={[currentRange]}
            maxDate={currentRange.maxDate}
            direction={props.direction}
            editableDateInputs={props.editableDateInputs}
            inputRanges={props.inputRanges}
            staticRanges={props.staticRanges}
            className={classes}
          />
          <div className="buttonsWrapper">
            <Button
              id="clearButton"
              ariaLabel="Clear button"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClear(e)}
              classes="clear"
              variant="secondary-s"
            >
              Clear
            </Button>
            <Button
              id="applyButton"
              ariaLabel="Apply button"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleApply(e)}
              classes="apply"
              variant="primary-s"
            >
              Apply
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ShowDateRangePicker;
