import React, { FC } from "react";
import Action, { ActionProps } from "../Action";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";

interface ActionEditProps extends ActionProps {
  size?: number;
  classNameIcon?: string;
  onClick?: () => void;
}

const ActionEdit: FC<ActionEditProps> = ({ size = 18, classNameIcon = "", ...props }) => {
  return <Action id="edit" svgIcon={<Edit2LineIcon size={size} className={`iconGray ${classNameIcon}`} />} {...props} />;
};

export default ActionEdit;
