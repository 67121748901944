import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";

//Redux
import { fetchSettings } from "../../store/OrganizationSettings/General/actions/general";
import { fetchGlobalSettings } from "../../store/global/actions/index";

//Styles
import styles from "./OrganizationSettings.module.scss";

//Components
import Spinner from "../../components/UI/Spinner/Spinner";
import OptionalSettings from "./components/OptionalSettings";
import GeneralSettings from "./components/GeneralSettings";
import Tabs from "../../components/UI/Tabs/Tabs";
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import TeamManagement from "../TeamManagement/TeamManagement";
import BillingAndPayment from "./components/BillingAndPayment/BillingAndPayment";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const OrganizationSettings = (props) => {
  useEffect(() => {
    if (props.organizationId > 0) {
      props.fetchSettings();
      props.fetchGlobalSettings();
    }
  }, []);

  const [updateGeneralInfo, setUpdateGeneralInfo] = useState(null);
  const [updateGeneralInfoImage, setUpdateGeneralInfoImage] = useState(false);

  const [updateOptionalInfo, setUpdateOptionalInfo] = useState(null);

  const tabs = [
    {
      link: "general-settings",
      component: (
        <GeneralSettings {...props} setUpdateGeneralInfo={setUpdateGeneralInfo} setUpdateGeneralInfoImage={setUpdateGeneralInfoImage} />
      ),
      title: "General Info",
    },
    {
      link: "geo-settings",
      component: <OptionalSettings {...props} setUpdateOptionalInfo={setUpdateOptionalInfo} />,
      title: "GEO Settings",
    },
    {
      link: "team-management",
      component: <TeamManagement />,
      title: "Team Management",
    },
    {
      link: "billing-and-payment",
      component: <BillingAndPayment {...props} />,
      title: "Billing and Payment",
    },
  ];

  let config = null;

  if (props.isLoading || props.globalSettingsisLoading) {
    config = <Spinner spinnerClass="absolute transform-y-50" />;
  }

  if (props.error) {
    config = <p>Error loading settings.</p>;
  }

  if (props.isLoaded && props.globalSettingsisLoaded) {
    config = <Tabs tabContentClass={styles.organizationTabs} {...props} tabs={tabs} />;
  }

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title={"Organization Settings"} />
      {config}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.user.organization !== undefined ? state.user.organization.id : 0,
    isLoading: state.organizationSettingsGeneral.isLoading,
    isLoaded: state.organizationSettingsGeneral.isLoaded,
    globalSettingsisLoading: state.global.isLoading,
    globalSettingsisLoaded: state.global.isLoaded,
    error: state.organizationSettingsGeneral.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: () => dispatch(fetchSettings()),
    fetchGlobalSettings: () => dispatch(fetchGlobalSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSettings);
