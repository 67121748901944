import React from "react";
import { Row, Col } from "react-bootstrap";

//Styles
import styles from "./AudienceTargetingDrawer.module.scss";

//Images & Icons
import IconCompany from "../../../assets/Icons/IconCompany/IconCompany";
import IconIndustry from "../../../assets/Icons/IconIndustry/IconIndustry";
import IconBriefcase from "../../../assets/Icons/IconBriefcase/IconBriefcase";
import LocationPinIcon from "../../../assets/Icons/LocationPinIcon/LocationPinIcon";

//Components
import Drawer from "../../../components/UI/Drawer/Drawer";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { LocationFilterType, SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import _ from "lodash";

type Props = {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  audienceName: string;
  elementId: string;
  searchModel: SearchModel;
};

const AudienceTargetingDrawer: React.FC<Props> = (props) => {
  const { isOpen, handleCloseDrawer, audienceName, elementId, searchModel } = props;

  const renderOption = (option: any): JSX.Element | null => {
    let result: JSX.Element | null = null;
    if (option && (option.label || _.isString(option))) {
      result = <span>{option.label || option || "N/A"}</span>;
    }
    return result;
  };

  const renderSection = (title: string, values: any[], IconComponent: any) => {
    return (
      <section>
        <Row>
          <Col className={styles.targetingSubTitle}>
            <IconComponent imgStyle="mR5" />
            {title}
          </Col>
        </Row>
        <hr />
        <Row className={styles.targetingTagsHeader}>
          <Col sm={2}></Col>
          <Col>Included:</Col>
          <Col>Excluded:</Col>
        </Row>
        <Row className={styles.targetingTags}>
          <Col sm={2}></Col>
          <Col className={styles.included}>
            {values.some((value) => value.included === true || value.included === undefined) ? (
              values
                .filter((x) => x.included === true || x.included === undefined)
                .map((value, index) => <React.Fragment key={index}>{renderOption(value)}</React.Fragment>)
            ) : (
              <span>N/A</span>
            )}
          </Col>
          <Col sm={5} className={styles.excluded}>
            {values.some((value) => value.included === false) ? (
              values
                .filter((x) => x.included === false)
                .map((value, index) => <React.Fragment key={index}>{renderOption(value)}</React.Fragment>)
            ) : (
              <span>N/A</span>
            )}
          </Col>
        </Row>
      </section>
    );
  };

  let render = null;

  render = (
    <>
      <div className={styles.AudienceTargetingDrawer}>
        <Row>
          <Col>
            <div className={styles.targetingHeader}>
              <strong>List name:</strong> <span>{audienceName}</span>
            </div>
          </Col>
        </Row>
        {searchModel == null ? (
          <Spinner />
        ) : (
          <>
            {renderSection("Company size", searchModel.company.sizes, IconCompany)}
            {renderSection("Industry", searchModel.company.industries, IconIndustry)}
            {renderSection(
              "Company Location",
              searchModel?.company?.location?.type == LocationFilterType.Range
                ? [searchModel?.company?.location?.range?.location?.name]
                : searchModel?.company?.location?.region?.locations?.length > 0
                ? searchModel?.company?.location?.region?.locations.map((x) => x)
                : [],
              LocationPinIcon
            )}
            {renderSection(
              "Contact Location",
              searchModel?.contact?.location?.type == LocationFilterType.Range
                ? [searchModel?.contact?.location?.range?.location?.name]
                : searchModel?.contact?.location?.region?.locations?.length > 0
                ? searchModel?.contact?.location?.region?.locations.map((x) => x)
                : [],
              LocationPinIcon
            )}
            {renderSection("Job Title", [...searchModel.contact?.seniorities, ...searchModel.contact?.jobTitles], IconBriefcase)}
          </>
        )}
      </div>
    </>
  );

  return (
    <Drawer
      elementId={elementId}
      title="List Targeting Summary"
      isOpen={isOpen}
      handleCloseDrawer={handleCloseDrawer}
      content={<>{render}</>}
    />
  );
};

export default AudienceTargetingDrawer;
