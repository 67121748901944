import React from "react";

const InfoIncludeValue = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.09091C3.28878 1.09091 1.09091 3.28878 1.09091 6C1.09091 8.71122 3.28878 10.9091 6 10.9091C8.71122 10.9091 10.9091 8.71122 10.9091 6C10.9091 3.28878 8.71122 1.09091 6 1.09091ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
      fill="#EE3030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70887 3.82487C8.49474 3.61096 8.14774 3.61113 7.93383 3.82526L4.75008 7.01219L3.61671 5.87906C3.40269 5.66504 3.05569 5.66504 2.84167 5.87906C2.62765 6.09309 2.62765 6.44008 2.84167 6.6541L4.36275 8.17495C4.46556 8.27776 4.60502 8.3355 4.75041 8.33546C4.89581 8.33543 5.03523 8.27761 5.13799 8.17475L8.70926 4.59991C8.92317 4.38578 8.923 4.03879 8.70887 3.82487Z"
      fill="#EE3030"
    />
  </svg>
);

export default InfoIncludeValue;
