import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Helpers
import api, { API, formUrl } from "../../../helper/api/api";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";
import { errorResponseToList } from "../../../helper/errorHelper";

// Images & Icons
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

// Components
import Modal from "../../../components/UI/Modal/Modal";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ElementCycle from "../../../components/UI/ElementCycle/ElementCycle";
import TestResultsMessage from "./TestResultsMessage/TestResultsMessage";
import InfoMessageBox from "./InfoMessageBox/InfoMessgeBox";

const SendTestEmailForm = (props) => {
  let sendContent = null;
  let receiveContent = null;
  let showSecondButton = false;
  let history = useHistory();
  let messageInfoBox = null;
  const organizationId = useOrganizationId();
  let [isReconnectLoading, setReconnectLoading] = useState(false);

  const receiveMessages = [" We are testing your email connection, this will take a moment, please wait."];
  const sendMessages = [" We are testing your email connection, this will take a moment, please wait."];

  const configSuccessMessage = (
    <>
      Your email account is successfully configured for the main authentication standards. <br />
      To see the results and learn more about these standards click{" "}
      <Link
        className="tableLink f12"
        to={{
          pathname: "/" + organizationId + "/email-accounts/" + props.emailAccountId + "/configuration-test",
        }}
      >
        here
      </Link>{" "}
      .
    </>
  );

  const configFailedMessage = (
    <>
      Your email account is not successfully configured for the main authentication standards. <br />
      To see the results and learn more about the recommended actions click{" "}
      <Link
        className="tableLink f12"
        to={{
          pathname: "/" + organizationId + "/email-accounts/" + props.emailAccountId + "/configuration-test",
        }}
      >
        here
      </Link>
      .{" "}
    </>
  );

  const configLimitReachedMessage = (
    <>
      You have reached your daily limit for configuration tests. Please try again tomorrow or check your latest test results{" "}
      <Link
        className="tableLink f12"
        to={{
          pathname: "/" + organizationId + "/email-accounts/" + props.emailAccountId + "/configuration-test",
        }}
      >
        here
      </Link>
      .{" "}
    </>
  );
  const [cycleRunning, setCycleRunning] = useState(false);

  useEffect(() => {
    setCycleRunning(props.showModal);
  }, [props.showModal]);

  const navigateBaseOfEmailAccountType = () => {
    if (props.emailAccountType === "Gmail" || props.emailAccountType === "Microsoft") {
      setReconnectLoading(true);

      api(formUrl(API.emailAccounts.reconnectThirdParty, { emailAccountId: props.emailAccountId }))
        .then((response) => {
          if (response.data && response.data.success) {
            if (response.data.redirectUrl != null) {
              window.location = response.data.redirectUrl;
            }
          } else {
            toast.error(response.data.errorMessage);
          }

          setReconnectLoading(false);
        })
        .catch((error) => {
          const errorList = errorResponseToList(error);
          if (errorList.length > 0) {
            errorList.forEach((err) => toast.error(err.message));
          } else {
            toast.error(error.message);
          }

          setReconnectLoading(false);
        });
    } else {
      if (props.emailAccountId && organizationId && organizationId > 0) {
        history.push("/" + organizationId + "/email-accounts/" + props.emailAccountId + "/edit");
      } else {
        props.handleClose();
      }
    }
  };

  switch (props.connectedTest) {
    case "Started":
      sendContent = (
        <TestResultsMessage
          firstStep
          icon={<Spinner faCircleNotch size="20px" />}
          messageLabel="Connection test"
          messageDetails={<ElementCycle objects={sendMessages} isRunning={cycleRunning} />}
        />
      );
      break;
    case "Successful":
      sendContent = (
        <TestResultsMessage
          id="emailConnected"
          firstStep
          icon={<CheckboxCircleFillIcon size={26} color="var(--first-stage-color)" />}
          messageLabel="Connection test"
          messageDetails="Successfully established connection"
        />
      );
      break;
    case "Failed":
      sendContent = (
        <TestResultsMessage
          id="emailNotConnected"
          firstStep
          icon={<CloseCircleFillIcon size={26} color="var(--danger-color)" />}
          messageLabel="Connection test"
          messageDetails="Email account not successfully connected. Please check your email account settings"
        />
      );
      receiveContent = (
        <TestResultsMessage icon={<span className="grayCircle"></span>} messageLabel="Configuration test" messageDetails={""} />
      );
      break;
    default:
      break;
  }

  switch (props.configurationTest) {
    case "Started":
      receiveContent = (
        <TestResultsMessage
          icon={<Spinner faCircleNotch size="20px" />}
          messageLabel="Configuration test"
          messageDetails={<ElementCycle objects={receiveMessages} isRunning={cycleRunning} />}
        />
      );
      break;
    case "Successful":
      receiveContent = (
        <TestResultsMessage
          id="configConnected"
          icon={<CheckboxCircleFillIcon size={26} color="var(--first-stage-color)" />}
          messageLabel="Configuration test"
          messageDetails={configSuccessMessage}
        />
      );
      break;
    case "Failed":
      receiveContent = (
        <TestResultsMessage
          id="configFailed"
          icon={<CloseCircleFillIcon size={26} color="var(--danger-color)" />}
          messageLabel="Configuration test"
          messageDetails={configFailedMessage}
        />
      );
      break;
    case "MaxLimitReached":
      receiveContent = (
        <TestResultsMessage
          icon={<CloseCircleFillIcon size={26} color="var(--danger-color)" />}
          messageLabel="Configuration test"
          messageDetails={configLimitReachedMessage}
        />
      );
      break;
    default:
      break;
  }

  if (props.connectedTest === "Failed" || props.configurationTest === "Failed") {
    showSecondButton = true;
  }

  let saveButtonText = "Close";

  if (props.connectedTest === "Failed" || props.configurationTest === "Failed") {
    saveButtonText = "Edit Settings";
    if (
      props.emailAccountId != null &&
      props.emailAccountType != null &&
      (props.emailAccountType === "Gmail" || props.emailAccountType === "Microsoft")
    ) {
      saveButtonText = "Reconnect";
    }
  }

  if (
    (props.connectedTest === "Successful" && props.configurationTest === "Failed") ||
    (props.connectedTest === "Successful" && props.configurationTest === "Successful")
  ) {
    messageInfoBox = <InfoMessageBox />;
  }

  return (
    <Modal
      title="Test email account"
      handleSave={showSecondButton ? navigateBaseOfEmailAccountType : props.handleClose}
      handleClose={showSecondButton && props.handleClose}
      isSaveButtonLink={showSecondButton}
      saveButtonLink={"/" + organizationId + "/email-accounts/" + props.emailAccountId + "/edit"}
      // isCloseDisabled={props.connectedTest === "Started"}
      saveButtonDisabled={props.connectedTest === "Started" || props.configurationTest === "Started"}
      isReconnectLoading={isReconnectLoading}
      show={props.showModal}
      saveButtonText={saveButtonText}
      closeButtonText={showSecondButton && "Close"}
      oneButton={!showSecondButton ? true : false}
      btnType="submit"
      backdrop={true}
      onHide={props.handleClose}
    >
      <div className="mB20">{sendContent}</div>
      {receiveContent}
      {messageInfoBox}
    </Modal>
  );
};

export default SendTestEmailForm;
