import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  emailAccount: {
    id:0,
    user: 0,
    name: "",
    email: "",
    smtp: {
      host: "",
      port: "",
      username: "",
      password: "",
      security: "",
    },
    imap: {
      host: "",
      port: "",
      security: "",
    },
    messagesPerDay: "",
    delayMin: "",
    delayMax: "",
    privacyPolicyURL: "",
    checkForUnsubscribeURL: false,
    isConnected: false,
  },
  isActivateEmailAccountTest: false
};

const fetchEmailAccountSuccess = (state, action) => {
  const newState = { ...state };
  let emailAccount = { ...state.emailAccount };
  emailAccount = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.emailAccount = emailAccount;
  return newState;
};

const activateEmailAccountTest = (state, action) => {
  const newState = {...state};
  newState.isActivateEmailAccountTest = action.result;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMAIL_ACCOUNT_REQUEST:
      return {
        ...state,
        emailAccount: null,
        isLoading: true,
        isLoaded: false,
      };
      case actionTypes.FETCH_EMAIL_ACCOUNT_SUCCEEDED:
      return fetchEmailAccountSuccess(state, action);
    case actionTypes.FETCH_EMAIL_ACCOUNT_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        emailAccount: null,
        error: action.result,
      };
    case actionTypes.RESET_EMAIL_ACCOUNT:
      return initialState;
    case actionTypes.ACTIVATE_EMAIL_ACCOUNT_TEST:
      return activateEmailAccountTest(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
