import React from "react";

const IconMessageActive = ({ imgStyle, fill = "#4A5263" }) => (
  <svg className={imgStyle} width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.830786 0.75C0.371969 0.75 4.95911e-05 1.12194 4.95911e-05 1.58074V14.878C4.95911e-05 15.3337 0.378284 15.7033 0.823975 15.7033H15.7908C16.2459 15.7033 16.6148 15.3337 16.6148 14.878V14.0418H1.66152V4.32217L8.30741 10.3035L16.6148 2.82684V1.58074C16.6148 1.12194 16.2428 0.75 15.784 0.75H0.830786ZM11.6304 10.7188V12.3803H18.2762V10.7188H11.6304ZM14.1226 6.56515V8.22663H18.2762V6.56515H14.1226ZM2.02214 2.41147H14.5926L8.30741 8.06821L2.02214 2.41147Z"
      fill={fill}
    />
    <path d="M14.1226 8.22663V6.56515H18.2762V8.22663H14.1226Z" fill={fill} />
    <path d="M11.6304 12.3803V10.7188H18.2762V12.3803H11.6304Z" fill={fill} />
  </svg>
);

export default IconMessageActive;
