import { FC, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Helpers
import Modal from "../../../components/UI/Modal/Modal";
import { useRemoveSuspensionMutation } from "@api/outboss/outbossEmailAccountsApi";

//Styles

interface RemoveSuspensionModalProps {
  emailAccountId: number;
  email: string;
  showModal: boolean;
  handleClose: () => void;
  fetchEmailAccountsCallback: () => void;
}

const RemoveSuspensionModal: FC<RemoveSuspensionModalProps> = ({
  emailAccountId,
  email,
  showModal,
  handleClose,
  fetchEmailAccountsCallback,
}) => {
  const [removeSuspension, query] = useRemoveSuspensionMutation();

  useEffect(() => {
    if (query.isSuccess) {
      handleClose();
      // Using this until we switch fetchEmailAccounts to RTK query - then we can invalidate the tag.
      fetchEmailAccountsCallback();
    }
  }, [query.isSuccess, handleClose]);

  return (
    <Modal
      title="Remove Suspension"
      handleClose={handleClose}
      handleSave={() => {
        removeSuspension(emailAccountId);
      }}
      show={showModal}
      closeButtonText="Cancel"
      saveButtonText="Remove suspension"
      btnType="submit"
      customBodyClass="overflow_unset max_height_none"
      saveButtonDisabled={query.isLoading}
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>
              Are you sure you want to remove suspension for: <b>{email}</b>?<br />
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default RemoveSuspensionModal;
