import React from "react";
// import ShowingPerPage from "../ShowingPerPage/ShowingPerPage";
import Select from "../../UI/Select/Select";
import HeaderSearch from "../../UI/HeaderTitle/components/HeaderSearch";
import HeaderSection from "../../UI/HeaderSection/HeaderSection";
import ShowDateRangePicker from "../ShowDateRangePicker/ShowDateRangePicker";
import SelectMulti from "../../UI/Select/SelectMulti";

// Styles
import styles from "./TableFiltersRow.module.scss";

const TableFiltersRow = (props) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      background: "#00718E",
      color: "white",
      width: " 240px;",
    }),
    option: (provided, state) => ({
      ...provided,
      background: "#00718E",
      color: "white",
      padding: 20,
    }),
    placeholder: (styles) => ({ ...styles, color: "white" }),
  };

  let search = null;
  if (props.changeFilter != null)
    search = (
      <HeaderSearch
        placeholder={"Search " + props.placeholder}
        textChanged={props.changeFilter}
        value={props.searchValue}
        searchOnFirstCharacter={props.searchOnFirstCharacter}
        headerSearchClass={props.headerSearchClass}
        delayedWrapperClass={props.delayedWrapperClass ?? ""}
      />
    );

  let selects = null;
  if (props.selects != null) {
    selects = props.selects.map((s, index) => {
      const showSelectedValues = s.showSelectedValues != null ? s.showSelectedValues : false;
      let multi = true;
      if (s.isMulti === false) multi = false;

      return (
        <div className={`${styles.wrapper} ${props.tableFilterClass ?? " "}`} key={index}>
          {showSelectedValues === true ? (
            <Select
              isMulti={multi}
              styles={customStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              options={s.option}
              onChange={s.change}
              placeholder={s.placeholder}
              defaultValue={s.defaultValue}
              value={s.value}
            />
          ) : (
            <SelectMulti
              options={s.option}
              onChange={s.change}
              placeholder={s.placeholder}
              displayName={s.placeholder}
              isMulti={true}
              isSearchable={true}
              value={s.value}
              onInputChange={s.onInputChange ? s.onInputChange : null}
              onMenuClose={s.onMenuClose ? s.onMenuClose : null}
              dropdownButtonClass="selectFilterDropdown"
              menuBodyClass="selectFilterDropdownBody"
              dropdownMenuClass={styles.listOptionsWrapper}
            />
          )}
        </div>
      );
    });
  }

  // let showingPerPage = null;
  // if (props.showPerPage == null || props.showPerPage === true) {
  //   showingPerPage = (
  //     <div>
  //       <ShowingPerPage
  //         numberOfRecords={numberOfRecords}
  //         change={props.changeNumRecords}
  //         defaultValue={props.showPerPageValue != null ? numberOfRecords.find(n => n.value === props.showPerPageValue) : numberOfRecords[1]}
  //       />
  //     </div>
  //   );
  // }

  let datePicker = null;
  if (props.dateOptions != null) {
    datePicker = <ShowDateRangePicker {...props.dateOptions} />;
  }

  return (
    <HeaderSection
      className={props.headerClassName}
      rightContent={props.rightContent}
      classLeftWrapper={props.classLeftWrapper}
      classRightWrapper={props.classRightWrapper}
      leftContent={
        <>
          {search}
          {selects}
          {datePicker}
          {/* {showingPerPage} */}
        </>
      }
    />
  );
};

export default TableFiltersRow;
