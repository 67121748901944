import React from "react";

import ProgressBarUI from "../../../components/UI/ProgressBar/ProgressBar";

const ProgressBarSandbox = () => {
  return (
    <>
      <ProgressBarUI now={60} max={100} min={1} animated={false} />
    </>
  );
};

export default ProgressBarSandbox;
