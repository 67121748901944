import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import api, { API } from "../api/api";
import { dispatchOrganizationUpdated, dispatchUserReady } from "./dispatchUserEvents";
import SignalRContext from "../SignalRContext";
import { SIGNALR_CONSTANTS } from "../signalrConstants";
import _ from "lodash";
import * as Sentry from "@sentry/react";

const UserMetaData = (props) => {
  let pathname = window?.location?.pathname;
  let isInError = window?.location?.pathname?.includes("/error") ?? false;
  const [userInitiated, setUserInitiated] = React.useState(false);

  useEffect(() => {
    if (window?.location?.pathname?.includes("/my-organizations")) {
      setUserInitiated(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (props.user?.id && !isInError) {
      if (!userInitiated) {
        dispatchUserReady(props.user);
        setUserInitiated(true);

        Sentry.setUser({ id: props.user.id, email: props.user.email, username: props.user.email });
      }

      if (props.user.organization?.id) {
        api(API.organization.getMetadata)
          .then((response) => {
            if (response?.data && !_.isEmpty(response.data)) {
              dispatchOrganizationUpdated(response?.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [props.user, isInError, userInitiated]);

  const signalRConnection = useContext(SignalRContext);
  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(SIGNALR_CONSTANTS.ORGANIZATION_UPDATED, (data) => {
        dispatchOrganizationUpdated(data);
      });
    }

    return () => {
      if (signalRConnection != null) {
        try {
          signalRConnection.off(SIGNALR_CONSTANTS.ORGANIZATION_UPDATED);
        } catch {}
      }
    };
  }, [signalRConnection]);

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(UserMetaData);
