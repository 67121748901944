import axios from "axios";

const instance = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  withCredentials: true,
});

// const token =  localStorage.getItem('access_token');
// instance.defaults.headers.common['Authorization'] = "Bearer " + token;

axios.defaults.headers.post["Content-Type"] = "aplication/json";
axios.defaults.headers.post["Accept"] = "aplication/json";

export default instance;
