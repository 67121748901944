import styles from "../Filters.module.scss";
import { useGetDomainsAutoCompleteQuery, useGetIndustriesQuery } from "@api/audienceExplorerApi";
import { SearchModel } from "@pages/AudiencesExplorer/helpers/SearchModel";
import FormikSelect from "@ui/Formik/FormikSelect";
import { useFormikContext } from "formik";
import { useState } from "react";

const FilterCompanyDomain = () => {
  const {
    values: {
      company: { domains },
    },
  } = useFormikContext<SearchModel>();
  const [searchInput, setSearchInput] = useState("");
  const { data: options } = useGetDomainsAutoCompleteQuery(searchInput, { skip: searchInput.length < 3 });

  return (
    <FormikSelect
      name="company.domains"
      value={domains}
      options={options}
      displayName="Select Domain"
      placeholder="sopro.io"
      onInputChange={(input: string) => setSearchInput(input)}
      dropdownButtonClass={styles.selectDropdown}
      allowIncludeExclude
      isAutoComplete
      isMulti
      isSearchable
      enableReinitialize
      hideNoOptions
      requireInputToShowOptions
      showValuesOutside
    />
  );
};

export default FilterCompanyDomain;
