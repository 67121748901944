import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Cell } from "styled-css-grid";

//Redux
import {
  fetchDealNavigatorCards,
  reorderDealNavigatorCard,
  removeDealNavigatorCards,
  swapDealNavigatorCard,
  setLastAddedCardValue,
} from "../../../../store/DealNavigator/actions/dealNavigator";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Styles
import styles from "./Cards.module.scss";

//Images & Icons
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import ArrowUpLineIcon from "remixicon-react/ArrowUpLineIcon";
import ArrowDownLineIcon from "remixicon-react/ArrowDownLineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";

//Components
import Element from "../Elements/Element";
import Permission from "../../../../components/auth/Permission";
import EditCard from "./EditCard";
import Button from "../../../../components/UI/Button/Button";

const Card = (card) => {
  const { id, position, offset, order, elements, stageId, edit, dealNavigatorId, lastAddedCardId } = card;

  const [showEditCardModal, setShowEditCardModal] = useState(false);

  const handleEditCardClose = () => {
    setShowEditCardModal(false);
    card.setLastAddedCardValue(0);

    if (card.elements.length === 0) {
      card.removeDealNavigatorCards(card);
    }
  };

  const handleSubmit = () => {
    setShowEditCardModal(false);
    card.setLastAddedCardValue(0);
  };

  const handleEditCardShow = () => setShowEditCardModal(true);

  useEffect(() => {
    if (lastAddedCardId !== 0) {
      setShowEditCardModal(true);
    }
  }, [lastAddedCardId]);

  //#region functions
  const moveCardUp = (card) => (e) => {
    let newCard = {
      id: id,
      stageId: stageId,
      dealNavigatorId: dealNavigatorId,
      order: order - 1,
    };

    const oldCard = {
      id: id,
      stageId: stageId,
      dealNavigatorId: dealNavigatorId,
      order: order,
    };

    card.reorderDealNavigatorCard(newCard, oldCard);
  };

  const moveCardDown = (card) => (e) => {
    let newCard = {
      id: id,
      stageId: stageId,
      dealNavigatorId: dealNavigatorId,
      order: order + 1,
    };

    const oldCard = {
      id: id,
      stageId: stageId,
      dealNavigatorId: dealNavigatorId,
      order: order,
    };

    card.reorderDealNavigatorCard(newCard, oldCard);
  };

  const swapCard = (e) => {
    let newCard = {
      id: id,
      stageId: stageId,
      position: position === 3 ? 1 : 3,
    };

    const oldCard = {
      id: id,
      stageId: stageId,
      position: position,
    };

    card.swapDealNavigatorCard(newCard, oldCard);
  };

  const removeCard = (e) => {
    card.removeDealNavigatorCards(card);
  };
  //#endregion

  const faIcon = position === 1 ? <ArrowRightLineIcon /> : <ArrowLeftLineIcon />;
  const cardStyle = position === 1 ? `${styles.Cards} ${styles.LeftCard}` : `${styles.Cards} ${styles.RightCard}`;

  return (
    <>
      <Cell left={2} top={order} width={1}>
        <div className={styles.middleLine}>
          <div style={{ top: offset }} className={"mT30 " + styles.middleCyrcle}></div>
        </div>
      </Cell>

      <Fragment key={id}>
        <Cell
          left={position}
          top={order}
          style={{
            marginTop: offset,
          }}
          className={cardStyle}
        >
          {edit && (
            <Permission has={PERMISSIONS.dealNavigator.edit}>
              <div className={`${styles.CardsSettingButtons} mB10 flex`}>
                <Button
                  id="editCardButton"
                  variant="rounded-white-m"
                  classes="p0"
                  onClick={() => {
                    handleEditCardShow();
                  }}
                >
                  <Edit2LineIcon className="iconGray" size={18} />
                </Button>
                <Button id="upButton" variant="rounded-white-m" onClick={moveCardUp(card)} classes="p0">
                  <ArrowUpLineIcon className="iconGray" size={18} />
                </Button>
                <Button id="downButton" variant="rounded-white-m" classes="p0" onClick={moveCardDown(card)}>
                  <ArrowDownLineIcon className="iconGray" size={18} />
                </Button>
                <Button id="deleteButton" variant="rounded-white-m" classes="p0" onClick={removeCard}>
                  <DeleteBin6LineIcon className="iconGray" size={18} />
                </Button>
              </div>
              <div className={`${styles.switchPlace} absolute transform-y-50`}>
                <Button id="swapButton" classes="p0" variant="primary-rounded-m" onClick={swapCard}>
                  {faIcon}
                </Button>
              </div>
            </Permission>
          )}
          {elements.map((element) => (
            <Element key={element.id} {...element} />
          ))}
        </Cell>
      </Fragment>

      {edit && lastAddedCardId !== 0 ? (
        <Permission has={PERMISSIONS.dealNavigator.edit}>
          <EditCard
            handleClose={handleEditCardClose}
            submitCard={handleSubmit}
            showModal={showEditCardModal}
            cardId={lastAddedCardId}
            elements={card.elements}
            organizationId={card.organizationId}
          />
        </Permission>
      ) : edit && lastAddedCardId === 0 ? (
        <Permission has={PERMISSIONS.dealNavigator.edit}>
          <EditCard
            handleClose={handleEditCardClose}
            submitCard={handleSubmit}
            showModal={showEditCardModal}
            cardId={id}
            elements={card.elements}
            organizationId={card.organizationId}
          />
        </Permission>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // dealNavigatorCards: state.dealNavigator.stages.cards,
    dealNavigatorId: state.dealNavigator.id,
    organizationId: state.user.organization.id,
    edit: state.dealNavigator.isInEditMode,
    lastAddedCardId: state.dealNavigator.lastAddedCardId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDealNavigatorCards: (id) => dispatch(fetchDealNavigatorCards(id)),
    removeDealNavigatorCards: (card) => dispatch(removeDealNavigatorCards(card)),
    reorderDealNavigatorCard: (newCard, oldCard) => dispatch(reorderDealNavigatorCard(newCard, oldCard)),
    swapDealNavigatorCard: (newCard, oldCard) => dispatch(swapDealNavigatorCard(newCard, oldCard)),
    setLastAddedCardValue: () => dispatch(setLastAddedCardValue()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
