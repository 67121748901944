import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Redux
import { fetchCountryStateInfo } from "../../../../store/BillingAndPayment/actions/billingAndPayment";

//Helpers
import { filterStates } from "../../../../helper/pricingPlanHelper";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import Spinner from "../../../../components/UI/Spinner/Spinner";

const BillingInfo = (props) => {
  const {
    values,
    selectListCountry,
    countryWithStates,
    fetchCountryStateInfo,
    countryStateInfoIsLoading,
    countryStateInfoIsLoaded,
    setShowCoupon,
    coupon,
    setHasStates,
    setFieldValue,
  } = props;

  useEffect(() => {
    fetchCountryStateInfo();
  }, []);

  let listCountries = selectListCountry.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  let listStates = filterStates(values?.country, countryWithStates);

  const [countries, selectListCountriesSelectList] = useState(listCountries);
  const [selectListStates, setSelectListStates] = useState(listStates);
  const [clearSelected, setClearSelected] = useState(false);

  const handleHasStatesChange = (listStates) => {
    if (listStates.length > 0) {
      setHasStates(true);
    } else setHasStates(false);
  };

  useEffect(() => {
    if (selectListCountry.length > 0) {
      let listCountries = selectListCountry.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
      let listStates = filterStates(values?.country, countryWithStates);
      selectListCountriesSelectList(listCountries);
      setSelectListStates(listStates);
      handleHasStatesChange(listStates);
    }
  }, [selectListCountry, countryWithStates]);

  const changeHandler = (code) => {
    if (setFieldValue) setFieldValue("state", "");
    setClearSelected(true);
    let listCountries = selectListCountry.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    let listStates = filterStates(code, countryWithStates);
    selectListCountriesSelectList(listCountries);
    setSelectListStates(listStates);
    handleHasStatesChange(listStates);
  };

  let formBillingInfo = null;

  if (countryStateInfoIsLoading) formBillingInfo = <Spinner size="2rem" />;

  if (countryStateInfoIsLoaded) {
    if (coupon?.code != null) {
      setShowCoupon(true);
    }

    formBillingInfo = (
      <>
        <div className="flex">
          <FormikInput
            name="firstName"
            value={values.firstName}
            type="text"
            placeholder="First Name"
            label="First Name"
            formgroupclass="mR5"
          />
          <FormikInput name="lastName" value={values.lastName} type="text" placeholder="Last Name" label="Last Name" formgroupclass="mL5" />
        </div>
        <FormikInput name="email" value={values.email} type="text" placeholder="Email Address" label="Email Address" />
        <FormikInput name="organizationName" value={values.organizationName} type="text" placeholder="Organization" label="Organization" />
        <FormikInput name="address" value={values.address} type="text" placeholder="Street Address" label="Street Address" />
        <FormikInput name="vatNumber" value={values.vatNumber} type="text" placeholder="Optional VAT number" label="VAT Number" />
        <FormikInput name="city" value={values.city} type="text" placeholder="City" label="City" />
        {countries?.length > 0 ? (
          <FormikSelect
            label="Country"
            name="country"
            options={countries}
            value={values.country}
            isSearchable={true}
            onChange={changeHandler}
            isMenuPortalTargetDisabled={true}
          />
        ) : null}
        <div className="flex">
          {selectListStates?.length > 0 ? (
            <FormikSelect
              label="County/State"
              name="state"
              options={selectListStates}
              value={values.state}
              isSearchable={true}
              isMenuPortalTargetDisabled={true}
              inputGroupClass="mB5"
              onChange={() => setClearSelected(false)}
              clearSelected={clearSelected}
            />
          ) : null}
          <FormikInput name="zip" value={values.zip} type="text" placeholder="Zip" label="Zip" />
        </div>
      </>
    );
  }
  return <div className="flex_column flex1">{formBillingInfo}</div>;
};

const mapStateToProps = (state) => {
  return {
    selectListCountry: state.billingAndPayment.selectListCountryWithStates,
    countryWithStates: state.billingAndPayment.countryWithStates,
    countryStateInfoIsLoading: state.billingAndPayment.countryStateInfoIsLoading,
    countryStateInfoIsLoaded: state.billingAndPayment.countryStateInfoIsLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCountryStateInfo: () => dispatch(fetchCountryStateInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
