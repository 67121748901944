export const columns = [
  {
    label: "Template Name",
    field: "name",
    type: "text",
    sort: "asc",
    showTooltip: true,
    flex: 1,
    width: 120,
    minWidth: 120,
  },
  {
    label: "Subject",
    field: "subject",
    type: "text",
    sort: "asc",
    showTooltip: true,
    width: 120,
    minWidth: 120,
    flex: 1,
  },
  {
    label: "Status",
    field: "status",
    type: "text",
    sort: "asc",
    width: 120,
    minWidth: 120,
    flex: 1,
  },
  {
    label: "Stage",
    field: "type",
    type: "text",
    width: 120,
    minWidth: 120,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Date Created",
    field: "dateCreated",
    type: "date",
    flex: 1,
    width: 120,
    minWidth: 120,
    sort: "asc",
  },
  {
    label: "Open Rate",
    field: "openRate",
    type: "number",
    flex: 1,
    width: 120,
    minWidth: 120,
    sort: "asc",
  },
  {
    label: "Click Rate",
    field: "clickRate",
    type: "number",
    width: 120,
    minWidth: 120,
    flex: 1,
    sort: "asc",
  },
  {
    label: "Lead Rate",
    field: "leadRate",
    type: "number",
    flex: 1,
    width: 120,
    minWidth: 120,
    sort: "asc",
  },
  {
    label: "Last Used",
    field: "lastUsed",
    type: "date",
    flex: 1,
    width: 120,
    minWidth: 120,
    sort: "asc",
  },
  {
    label: "Total Sent",
    field: "emailsDelivered",
    type: "number",
    flex: 1,
    width: 120,
    minWidth: 120,
    sort: "asc",
  },
  // {
  //   label: 'Actions',
  //   field: 'actions',
  //   width: 150
  // }
];
