import { couponTypeEnum, couponProviderEnum, couponAppliesToEnum } from "./enums/couponEnum";

export const filterPlans = (activeTab, typeOfBilling, currencyId, pricingPlans, separators, coupon) => {
  let agreementPeriod = activeTab === 0 ? 3 : 12;
  let billingPeriod = typeOfBilling ? agreementPeriod : 1;
  let couponDiscountedPrice = null;
  let couponDiscountedFullPrice = null;

  if (agreementPeriod === 3 && billingPeriod === 3) {
    return false;
  }
  let plansArray = [];
  for (let i = 0; i < pricingPlans.length; i++) {
    const plan = pricingPlans[i];
    let oldPrice = null;
    let pricePoint = plan.pricePoint.filter(
      (point) => point.agreementPeriod === agreementPeriod && point.billingPeriod === billingPeriod
    )[0];

    if (agreementPeriod === 12 && billingPeriod === 12) {
      let monthlyPricePoint = plan.pricePoint.filter((point) => point.agreementPeriod === agreementPeriod && point.billingPeriod === 1)[0];
      oldPrice = getPriceForBillingPeriod(monthlyPricePoint, currencyId, separators);
    }

    if (isCouponValid(coupon)) {
      couponDiscountedPrice = getPriceForBillingPeriod(pricePoint, currencyId, separators, false, coupon);

      couponDiscountedFullPrice = getPriceForBillingPeriod(pricePoint, currencyId, separators, true, coupon);
    }

    let formattedPrice = getPriceForBillingPeriod(pricePoint, currencyId, separators, false);
    let fullPriceForPlan = getPriceForBillingPeriod(pricePoint, currencyId, separators, true);

    let obj = {
      planId: plan.id,
      chargifyHandle: plan.chargifyHandle,
      pricePointChargifyHandle: pricePoint.chargifyHandle,
      pricePointId: pricePoint.id,
      isExpired: pricePoint.isExpired,
      credits: formatNumbers(plan.credits, separators),
      price: formattedPrice,
      billingPeriod: pricePoint.billingPeriod,
      users: plan.numberOfUsers,
      status: pricePoint.status, // plan.status,
      oldPrice: oldPrice,
      fullPrice: fullPriceForPlan,
      couponDiscountedPrice: couponDiscountedPrice,
      couponDiscountedFullPrice: couponDiscountedFullPrice,
    };
    plansArray.push(obj);
  }
  return plansArray;
};

export const getPriceForBillingPeriod = (pricePoint, currencyId, separators, calcFullPrice, coupon) => {
  let arr = pricePoint.pricePointCurrency;
  let priceObj = arr.filter((p) => p.currencyId === currencyId)[0];
  let price = priceObj.price;

  let calcBillingPeriodPrice = pricePoint.billingPeriod === 1 ? price : price / pricePoint.billingPeriod;
  if (calcFullPrice) {
    calcBillingPeriodPrice = price;
  }

  //if the coupon is valid
  if (coupon != null && coupon.isValid) {
    //to check if the coupon is for subscription
    if (coupon.couponProvider === couponProviderEnum.chargify && coupon.appliesTo === couponAppliesToEnum.subscription) {
      //check what kind of discount it is
      if (coupon.type === couponTypeEnum.percentageOff) {
        //apply percentage discount to the price
        calcBillingPeriodPrice -= calcBillingPeriodPrice * (coupon.value / 100);
      }
      if (coupon.type === couponTypeEnum.amountOf) {
        calcBillingPeriodPrice -= coupon.value;
        if (pricePoint.billingPeriod === 12 && !calcFullPrice) {
          calcBillingPeriodPrice = (price - coupon.value) / 12;
        }
        if (calcBillingPeriodPrice < 0) calcBillingPeriodPrice = 0;
      }
    }
  }

  calcBillingPeriodPrice = formatDecimalsPrice(calcBillingPeriodPrice);
  let formattedPrice = formatNumbers(calcBillingPeriodPrice, separators);

  return formattedPrice;
};

export const formatDecimalsPrice = (val) => {
  let dec = val % 1;
  if (dec !== 0) {
    if (dec > 0.5) {
      val = Math.round(val);
    } else {
      val = val?.toFixed(2);
    }
  }
  return val;
};

export const formatNumbers = (value, obj) => {
  let formattedNumber = value.toLocaleString(navigator.language, {
    minimumFractionDigits: obj.minimumFractionDigits,
  });
  let result = formattedNumber.replaceAll(",", obj.numberGroupSeperator);
  let dec = value % 1;
  if (dec !== 0) {
    let lastThree = result.slice(-3, result.length);
    lastThree = lastThree.replaceAll(".", obj.numberDecimalSeperator);
    result = result.slice(0, -3);
    result = result.concat(lastThree);
  }
  return result;
};

export const fullFormat = (val, separators) => {
  return formatNumbers(formatDecimalsPrice(val), separators);
};

export const pricingPlanEnum = {
  trial: "Trial",
  startUp: "Start-Up",
  scaleUp: "Scale-Up",
  sellMore: "Sell More",
  sellEverything: "Sell Everything",
  enterprise: "Enterprise",
  freemium: "Freemium",
};

export const currencySign = [null, "€", "£", "$"];
export const currencyName = [null, "EUR", "GBP", "USD"];
export const valuesPricingPlan = ["Start-Up", "Scale-Up", "Sell More", "Sell Everything", "Enterprise"];
export const pricingPlanVariant = ["startUp", "scaleUp", "current", "scaleUp2"];
export const pricingPlanInfoDetails = ["1", "1-2", "3-5", "6-12"];

export const filterStates = (code, countryWithStates) => {
  if (code !== "" && code != null) {
    let states = countryWithStates.filter((s) => s.code === code)[0]?.states;
    return states?.map((s) => {
      return {
        value: s.code,
        label: s.name,
      };
    });
  } else {
    return [];
  }
};

export const getPriceForPlan = (planId, currencyId, pricingPlans) => {
  let plan = pricingPlans.filter((p) => p.id === planId)[0];
  let pricePoint = plan.pricePoint[0];
  let pricePointCurrency = pricePoint.pricePointCurrency.filter((c) => c.currencyId == currencyId)[0];
  let price = pricePointCurrency.price;

  return price;
};

export const isCouponValid = (coupon) => {
  if (coupon != null && coupon.isValid) {
    //to check if the coupon is for subscription
    if (coupon.couponProvider == couponProviderEnum.chargify) {
      return true;
    }
    return false;
  }
  return false;
};

export const calculateDiscountedPrice = (price, coupon) => {
  if (price == null) return;

  let intPrice = price;
  let isDecimal = false;

  if (isNaN(price) || typeof price === "string") {
    let lastThree = price.slice(-3, price.length);
    if (lastThree.includes(",")) {
      lastThree = lastThree.replaceAll(",", ".");
      price = price.slice(0, -3);
      price = price.concat(lastThree);
    }

    let firstThree = price.slice(0, 3);
    if (firstThree.includes(".")) {
      firstThree = firstThree.replaceAll(".", ",");
      price = price.slice(3, price.length);
      price = firstThree.concat(price);
    }

    let dec = price % 1;
    isDecimal = dec !== 0 && !isNaN(dec);

    if (isDecimal) intPrice = parseFloat(price);
    else {
      let result = price.replaceAll(",", "");
      result = result.replaceAll(".", "");
      intPrice = parseInt(result);
    }
  }
  if (isCouponValid(coupon)) {
    if (coupon.type == couponTypeEnum.percentageOff) {
      //apply percentage discount to the price
      intPrice -= intPrice * (coupon.value / 100);
    }
    if (coupon.type == couponTypeEnum.amountOf) {
      intPrice -= coupon.value;
      if (intPrice < 0) intPrice = 0;
    }
  }

  if (isDecimal) return intPrice.toFixed(2);
  else return intPrice;
};
