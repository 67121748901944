import React from "react";

//Style
import style from "./AlertNotification.module.scss";

//Components
import AlertNotification from "../../../../components/UI/AlertNotification/AlertNotification";

const InfoMessageBox = (props) => {
  return (
    <AlertNotification variant="info_alert_lighter" notificationWrapperClass="mT20">
      <div className={style.messageBodyWrapper}>
        <p className="mB10">
          <strong>Important</strong>
        </p>
        <p className="mB10">Any responses that you get from the prospecting activities will be synced in Outbase's inbox.</p>
        <p className="mB10">
          <strong>Please note:</strong> To ensure this, please check that there are no filters set to this email which handle incoming
          emails automatically.
        </p>
        <p className="mB10 font_italic">
          *Any rules applied that cause the emails to be moved or deleted from your inbox, can result in responses not beings synced in
          Outbase.
        </p>
      </div>
    </AlertNotification>
  );
};

export default InfoMessageBox;
