import React, { useMemo, FC } from "react";
import { Formik, FormikHelpers } from "formik";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";

// Components
import Modal from "../../components/UI/Modal/Modal";
import FormikSelect from "../../components/UI/Formik/FormikSelect";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useCloneCampaignMutation, useGetCampaignQuery } from "../../api/campaignApi";
import { useGetEmailAccountsSelectListQuery } from "../../api/emailAccountApi";
import { useGetAudiencesForCampaignQuery } from "../../api/audienceApi";
import { useOrgIdState } from "../../helper/hooks/useOrgIdState";
import { CloneCampaignModel } from "../../models/campaign/CloneCampaignModel";
import { AudiencePushToCampaignDropdownModel } from "../../models/audience/AudiencePushToCampaignDropdownModel";
import { CampaignEmailAccountDetailsModel } from "../../models/campaign/CampaignEmailAccountDetailsModel";
import { useHistory } from "react-router-dom";

interface FormValues {
  emailAccounts: number[];
  audienceId: number | null;
}

interface CloneCampaignModalProps {
  campaignId: number;
  showModal: boolean;
  redirect?: boolean;
  handleClose: () => void;
}

const CloneCampaignModal: FC<CloneCampaignModalProps> = ({ campaignId, showModal, redirect, handleClose }) => {
  const orgId = useOrgIdState();
  const { data: campaignDetails, isLoading } = useGetCampaignQuery(campaignId, { skip: !campaignId });
  const { data: audiences } = useGetAudiencesForCampaignQuery(campaignId, { skip: !campaignId });
  const { data: emailAccountsData } = useGetEmailAccountsSelectListQuery();

  const history = useHistory();
  const [cloneCampaign] = useCloneCampaignMutation();

  const emailAccounts = useMemo(() => {
    return emailAccountsData?.map((account) => ({ value: parseInt(account.value), label: account.label }));
  }, [emailAccountsData]);

  const mapAudienceList = useMemo(() => {
    if (audiences?.length && audiences?.length > 0) {
      return audiences.map((audience: AudiencePushToCampaignDropdownModel) => {
        return {
          value: audience.id,
          label: (
            <span className="flex">
              {audience.name} {audience.isAssigned ? null : <span style={{ color: "gray" }}>(Unassigned)</span>}{" "}
              <span className="textOverflow" style={{ color: "green", float: "right" }}>
                &nbsp; &nbsp; {audience.availableContacts} contacts available
              </span>
            </span>
          ),
          labelShow: audience.name,
        };
      });
    }
    return [];
  }, [audiences]);

  const filterLabel = (option: any, inputValue: string) => {
    return option.data.labelShow?.toLowerCase().includes(inputValue.toLowerCase()) && option;
  };

  return (
    <div>
      {campaignDetails && emailAccountsData && audiences ? (
        <Formik
          initialValues={{
            emailAccounts:
              campaignDetails.emailAccounts != null && campaignDetails.emailAccounts.length > 0
                ? campaignDetails.emailAccounts.map((account: CampaignEmailAccountDetailsModel) => account.emailAccountId)
                : [],
            audienceId: campaignDetails.audienceId,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            audienceId: Yup.number().notRequired(),
            emailAccounts: Yup.array().of(Yup.number()),
          })}
          onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
            const data: CloneCampaignModel = {
              campaignId: campaignId,
              emailAccounts: values.emailAccounts,
              audienceId: values.audienceId,
            };

            cloneCampaign(data)
              .unwrap()
              .then((response) => {
                toast.success("Campaign cloned successfully");
                handleClose();
                if (redirect) history.push("/" + orgId + "/campaigns/" + response + "/overview");
              })
              .catch((error: Error) => {
                toast.error(error.message);
              });
          }}
        >
          {(formikProps) => {
            const { handleSubmit, values, isSubmitting, setSubmitting } = formikProps;
            return (
              <Modal
                title={`Clone campaign`}
                handleClose={handleClose}
                onShow={() => setSubmitting(false)}
                handleSave={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  if (isSubmitting === false) {
                    setSubmitting(true);
                    handleSubmit();
                  }
                }}
                show={showModal}
                closeButtonText="Cancel"
                saveButtonText="Save"
                btnType="submit"
                customBodyClass="overflow_unset max_height_none"
                saveButtonDisabled={isSubmitting}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Form>
                    <p>
                      This campaign will be cloned with all its current settings. Make sure to review them before activating the campaign.
                    </p>
                    <FormikSelect
                      isMulti
                      showBorder
                      name="emailAccounts"
                      label="Email Accounts"
                      placeholder="Email Accounts"
                      options={emailAccounts}
                      showValuesOutside
                      value={values.emailAccounts}
                      isMenuPortalTargetDisabled
                    />
                    <FormikSelect
                      showBorder
                      name="audienceId"
                      label="List"
                      placeholder="Select list"
                      options={mapAudienceList}
                      value={values.audienceId}
                      isSearchable={true}
                      filterOption={filterLabel}
                      isMenuPortalTargetDisabled
                    />
                  </Form>
                )}
              </Modal>
            );
          }}
        </Formik>
      ) : null}
    </div>
  );
};

export default CloneCampaignModal;
