import React, { useState, useContext } from "react";
import { Prompt } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useAuth } from "react-oidc-context";

//Redux
import { updateOrganizationSettingsSuccess } from "../../../store/global/actions/user";
import { fetchSettings } from "../../../store/OrganizationSettings/General/actions/general";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import { toastMessages } from "../../../helper/toastMessagesConstants";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { DOMAIN_URL_CHECK } from "../../../helper/validationConstants";
import { companySizeDropDown } from "../../../helper/enums/companySizeEnum";
import { industryDropDown } from "../../../helper/enums/industryEnum";
import { organizationTypeOptions } from "../../../helper/enums/organizationTypeEnum";
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";

//Styles
import styles from "./GeneralSettings.module.scss";

// Components
import FormikInput from "../../../components/UI/Formik/FormikInput";
import SaveChangesFooter from "../../../components/SaveChangesFooter/SaveChangesFooter";
import FormikEffect from "../../../components/UI/Formik/FormikEffect";
import api, { API } from "../../../helper/api/api";
import Permission from "../../../components/auth/Permission";
import UserPhoto from "../../../components/UI/UserPhoto/UserPhoto";
import FormikSelect from "../../../components/UI/Formik/FormikSelect";
import ImageUploader from "../../../components/UI/FileUploader/ImageUploader";
import CancelWarningForm from "../../../components/CancelWarningForm/CancelWarningForm";

const GeneralSettings = (props) => {
  const auth = useAuth();
  const [hasChanges, setHasChanges] = useState(false);
  const [imageName, setImageName] = useState(props?.settings?.imageUrl ?? "");
  const [hasNewImage, setHasNewImage] = useState(false);
  const [removePrompt, setRemovePrompt] = useState(false);

  const onChange = (values) => {
    setHasChanges(true);
    props.setUpdateGeneralInfo(values);
    props.setUpdateGeneralInfoImage(hasNewImage);
  };

  const [showCancelWarningModal, setShowCancelWarningModal] = useState(false);
  const handleCancelWarningClose = () => {
    setShowCancelWarningModal(false);
  };

  const [pathname, setPathname] = useState(null);

  const updateChanges = async () => {
    setRemovePrompt(true);
    setHasChanges(false);
  };

  const handleCancelWarningSave = async () => {
    await updateChanges();
    setShowCancelWarningModal(false);
    props.history.push({
      pathname: pathname,
      state: true,
    });
  };

  const handleCancelModal = (location) => {
    if (removePrompt) {
      return true;
    }
    setPathname(location.pathname);
    setShowCancelWarningModal(true);
    return false;
  };

  return (
    <Permission has={PERMISSIONS.organization.settings.view} redirect>
      <Formik
        initialValues={{
          currency: props.settings?.currency,
          language: props.settings?.language,
          timeZone: props.settings?.timeZone,
          timeFormat: props.settings?.timeFormat,
          dateFormat: props.settings?.dateFormat,
          name: props.settings?.name,
          domain: props.settings?.domain,
          organizationType: props.settings?.type,
          industry: props.settings?.industry || "",
          companySize: props.settings?.companySize || "",
          image: props.settings?.imageUrl,
          hasNewImage: false,
          location: props.settings?.location,
          numberDecimalSeperator: props.settings?.numberDecimalSeperator,
          numberGroupSeperator: props.settings?.numberGroupSeperator,
        }}
        validationSchema={Yup.object({
          currency: Yup.string().required("Required"),
          language: Yup.string().required("Required"),
          timeZone: Yup.string().required("Required"),
          timeFormat: Yup.string().required("Required"),
          numberDecimalSeperator: Yup.string().required("Required"),
          numberGroupSeperator: Yup.string().required("Required"),
          dateFormat: Yup.string().required("Required"),
          name: Yup.string().required("Required"),
          domain: Yup.string().required("Required").matches(DOMAIN_URL_CHECK, "Must be Valid Domain Or Website Url"),
        })}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let data = new FormData();
          data.append("name", values.name);
          data.append("domain", values.domain);
          data.append("industry", values.industry);
          data.append("companySize", values.companySize);
          data.append("image", values.image);
          data.append("hasNewImage", hasNewImage);
          data.append("currency", values.currency);
          data.append("language", values.language);
          data.append("timeZone", values.timeZone);
          data.append("timeFormat", values.timeFormat);
          data.append("numberDecimalSeperator", values.numberDecimalSeperator);
          data.append("numberGroupSeperator", values.numberGroupSeperator);
          data.append("dateFormat", values.dateFormat);
          data.append("location", values.location);
          data.append("organizationType", values.organizationType);

          api(API.organization.settings.update, data)
            .then((response) => {
              props.fetchSettings();
              auth.signinSilent();
              toast.success(toastMessages.settings.successGeneralSettingsUpdate);
              setHasChanges(false);
            })
            .catch((error) => {
              const errorList = errorResponseToList(error);
              if (errorList.length > 0) {
                errorList.forEach((e) => (e.field === "image" ? toast.error(e.message) : actions.setFieldError(e.field, e.message)));
              } else {
                toast.error(error.message);
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
        onReset={() => {
          setHasChanges(false);
        }}
      >
        {(formikProps) => {
          const { values, setFieldValue } = formikProps;

          return (
            <Form>
              <Prompt when={hasChanges} message={(location) => handleCancelModal(location)} />
              <FormikEffect onChange={() => onChange(values)} />
              <div className={styles.formSection}>
                <div className={`${styles.imageWrapper} flex`}>
                  <UserPhoto alt="org-photo" isCompanyImage={true} size="large" src={imageName} />
                  <div className="mL10">
                    <h3 className={styles.organizationName}>Organization Logo</h3>
                    <ImageUploader
                      buttonText="Update logo"
                      variant="primary-s"
                      classes={styles.updateLogo}
                      handleFile={(file) => {
                        let isImageType = file?.type.includes("image");
                        if (!isImageType) return;
                        setFieldValue("image", file);
                        var reader = new FileReader();
                        if (file != null) {
                          var url = reader.readAsDataURL(file);
                        }
                        reader.onloadend = (e) => {
                          setImageName([reader.result]);
                          setHasNewImage(true);
                          props.setUpdateGeneralInfoImage(true);
                        };
                      }}
                    />
                  </div>
                </div>

                <div className={styles.inputGroup}>
                  <FormikInput label="Website (*)" name={"domain"} value={values.domain} placeholder="Type here..." />
                  <FormikSelect
                    label="Industry *"
                    name="industry"
                    value={values.industry}
                    options={industryDropDown}
                    placeholder="Select one..."
                    isSearchable={true}
                  />
                  <FormikInput label="Organization *" name={"name"} value={values.name} placeholder="Type here..." />
                  <FormikSelect
                    label="Company Size *"
                    name="companySize"
                    value={values.companySize}
                    options={companySizeDropDown}
                    placeholder="Select one..."
                    isSearchable={true}
                  />
                  <FormikSelect
                    label="Location (*)"
                    name="location"
                    value={values.location}
                    options={props.globalSettings.countries}
                    placeholder="Select one..."
                    isSearchable={true}
                  />
                  {globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.view) ? (
                    <FormikSelect
                      label="Organization Type (*)"
                      name="organizationType"
                      value={values.organizationType}
                      options={organizationTypeOptions}
                      placeholder="Select one..."
                      isSearchable={true}
                    />
                  ) : null}
                </div>
              </div>
              <Container className={styles.general}>
                <Row>
                  <Col className={styles.changesFooter} xl={12} lg={12} sm={12} xs={12}>
                    {hasChanges ? (
                      <SaveChangesFooter
                        cancelClick={formikProps.handleReset}
                        saveChangesClick={formikProps.handleSubmit}
                        cancelButtonDisabled={formikProps.isSubmitting}
                        saveButtonDisabled={formikProps.isSubmitting}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
      <CancelWarningForm showModal={showCancelWarningModal} handleClose={handleCancelWarningClose} handleSave={handleCancelWarningSave} />
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.organizationSettingsGeneral.isLoading,
    isLoaded: state.organizationSettingsGeneral.isLoaded,
    settings: state.organizationSettingsGeneral.settings,
    error: state.organizationSettingsGeneral.error,
    globalSettings: state.global.globalSettings,
    userPermissions: state.user.userPermissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrganizationSettingsSuccess: (updatedSettings) => dispatch(updateOrganizationSettingsSuccess(updatedSettings)),
    fetchSettings: () => dispatch(fetchSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
