export const columns = [
  {
    label: "",
    field: "companyLogo",
    type: "actions",
    sort: "disabled",
    width: 55,
    maxwidth: 55,
  },
  {
    label: "Company Name",
    field: "name",
    sort: "asc",
    width: 100,
    minWidth: 100,
    flex: 1,
  },
  {
    label: "Location",
    field: "country",
    sort: "asc",
    width: 100,
    minWidth: 100,
  },
  {
    label: "Industry",
    field: "industry",
    type: "text",
    sort: "asc",
    width: 100,
    minWidth: 100,
  },
  {
    label: "Size",
    field: "size",
    sort: "asc",
    width: 100,
    minWidth: 100,
  },
  // {
  //   label: "In Use",
  //   field: "inUse",
  //   type: "actions",
  //   sort: "disabled",
  //   width: "70",
  // },
];
