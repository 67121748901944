export const variablesRegex = /\[(^}]+)}}|{([^}]+)}+/g;

export const getAllVariablesInText = (content) => {
  return content.match(variablesRegex) || [];
};

export const extractVariable = (variable) => {
  const match = variable.match(/[{\[(]+([^}\])]+)[}\])]*/);
  return match ? match[1] : variable;
};

export const checkForIncorrectVariables = (content, combineVariablesArray) => {
  const variables = getAllVariablesInText(content);
  const combineArrayText = combineVariablesArray.map((item) => item.text);
  const combineArrayName = combineVariablesArray.map((item) => item.name);
  let incorrect = [];

  incorrect = variables?.filter((item) => {
    const variableName = extractVariable(item);
    const variableText = item.replace(/\{\{([^|]+)(?:\|[^}]+)?}}/g, "{{$1}}");

    if (variableName === "INITIAL_EMAIL_SUBJECT") {
      return false;
    }

    if (!combineArrayText.includes(variableText) || !combineArrayName.includes(variableName.split("|")[0])) return item;
  });

  return incorrect;
};

// Remove the span tags that when incorrect becomes correct
export const removeIncorrectVariableSpanTags = (text) => {
  const regex =
    /<span class="incorrectVariable(?: namedrop)?" contenteditable="(false|true)">(.*?)<\/span>|<span class="tooltip">.*?<\/span>/gs;
  const clearSpan = text.replace(regex, "$2");
  return clearSpan;
};

// Filter out any HTML tags that arise when you put each var in new line in the editor
export const filterOutHtmlTags = (text) => {
  const htmlTagPattern = /<[^>]+>/g;
  const clearHtml = text.map((inputString) => inputString.replace(htmlTagPattern, "").match(/\{\{[^\}]+\}\}/g));
  const flatVariables = clearHtml.flat();
  return flatVariables;
};

// Remove the namedrop variable on click
export const removeNameDrop = (event, content) => {
  const target = event.target;
  if (target.classList.contains("incorrectVariable") && !target.classList.contains("namedrop")) {
    target.setAttribute("contenteditable", "true");
  }
  if (target.tagName !== "SPAN" || !target.classList.contains("namedrop")) {
    return;
  }
  target.classList.remove("namedrop", "incorrectVariable");

  const tooltip = target.querySelector(".tooltip");
  if (tooltip) {
    tooltip.remove();
  }

  target.outerHTML = target.innerHTML;

  const last = content.getBody().lastChild;
  const lastChar = last.textContent.charAt(last.textContent.length - 1);
  content.selection.setCursorLocation(lastChar, true);
  // Reupdate the content
  content.setContent(content.getContent());
};
