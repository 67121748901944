import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

//Helpers
import api, { API } from "../../../../helper/api/api";
import { errorResponseToList } from "../../../../helper/errorHelper";
import { toastMessages } from "../../../../helper/toastMessagesConstants";
import { statusCodes } from "../../../../helper/enums/statusCodes";
import { calculateDiscountedPrice, pricingPlanEnum } from "../../../../helper/pricingPlanHelper";
import { couponAppliesToEnum } from "../../../../helper/enums/couponEnum";

// Styles
import styles from "./GetStartedForm.module.scss";

//Images & Icons
import LockFillIcon from "remixicon-react/LockFillIcon";

//Components
import Modal from "../../../../components/UI/Modal/Modal";
import BillingInfo from "../BillingInfo/BillingInfo";
import SummaryCard from "../SummaryCard/SummaryCard";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import Stepper from "../../../../components/UI/Stepper/Stepper";
import FormikEffect from "../../../../components/UI/Formik/FormikEffect";
import CouponField from "../CouponField/CouponField";

const GetStartedForm = (props) => {
  const {
    billingInfo,
    planId,
    pricePointId,
    planChargifyHandle,
    pricePointChargifyHandle,
    currentPlan,
    price,
    credits,
    currencySign,
    typeOfBilling,
    creditCardInfo,
    billingPeriod,
    users,
    fullPrice,
    period,
    plan,
    createBillingInfo,
    coupon,
    setCoupon,
    couponDiscountedFullPrice,
    totalPrice,
    isCouponUsed,
    hasRedirect,
    showModal,
    couponDiscountedPrice,
  } = props;

  let renderPage = null;
  const [page, setPage] = useState(1);
  const [saveButtonText, setSaveButtonText] = useState("Next");
  const [token, setToken] = useState();
  const [getToken, setGetToken] = useState(false);
  const [updateBillingInfo, setUpdateBillingInfo] = useState(false);

  const [discountPrice, setDiscountPrice] = useState(couponDiscountedFullPrice);
  const [hasStates, setHasStates] = useState(false);
  const firstCardEver = creditCardInfo === null || creditCardInfo === undefined;
  const [isNewCard, setIsNewCard] = useState(firstCardEver || false);

  useEffect(() => {
    if (coupon != null && couponDiscountedFullPrice === null) {
      let price = calculateDiscountedPrice(totalPrice, coupon);
      setDiscountPrice(price);
    }
  }, [coupon]);

  const pageHandler = (newPage) => {
    if (newPage === 1) setSaveButtonText("Next");
    else setSaveButtonText("Confirm");

    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
  }, [showModal]);

  const nextPageHandler = () => {
    let newPage = page + 1;
    setPage(newPage);
  };

  const onChange = () => {
    if (page === 1) {
      setUpdateBillingInfo(true);
    }
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const formikRef = useRef();

  const submitForm = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (token) {
      submitForm();
    }
  }, [token]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        id: billingInfo !== null ? billingInfo?.id : 0,
        firstName: billingInfo !== null ? billingInfo?.firstName : "",
        lastName: billingInfo !== null ? billingInfo?.lastName : "",
        email: billingInfo !== null ? billingInfo?.email : "",
        organizationName: billingInfo !== null ? billingInfo?.organizationName : "",
        address: billingInfo !== null ? billingInfo?.address : "",
        city: billingInfo !== null ? billingInfo?.city : "",
        country: billingInfo !== null ? billingInfo?.country : "",
        state: billingInfo !== null ? billingInfo?.state : "",
        zip: billingInfo !== null ? billingInfo?.zip : "",
        vatNumber: billingInfo !== null ? billingInfo?.vatNumber : "",
      }}
      enableReinitialize={billingInfo !== null}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
        organizationName: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        zip: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions, isValid) => {
        if (page === 1) {
          nextPageHandler();
          setSaveButtonText("Confirm");
        }
        if (page === 2) {
          if (hasStates && values.state === "") {
            actions.setFieldError("state", "Required");
            toast.error("County/State is required");
            actions.setSubmitting(false);
            return;
          } else if (!hasStates && values.state !== "") {
            values.state = "";
          }
          let obj = {
            token: token,
            billingInfo: values,
            updateBillingInfo: updateBillingInfo,
            planChargifyHandle: planChargifyHandle,
            pricingPointChargifyHandle: pricePointChargifyHandle,
            pricingPlan: planId,
            pricingPoint: pricePointId,
            billingPeriod: billingPeriod,
            createBillingInfo: createBillingInfo,
            couponCode: coupon?.code,
          };
          if (createBillingInfo) {
            api(API.billingAndPayment.createBillingInfoPaymentProfile, obj)
              .then((res) => {
                props.handleSuccessfullyRequestFormShow();
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);
                if (errorList.length > 0) {
                  errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                }
                toast.error(error.message, { autoClose: false });
                props.handleClose();
                actions.setSubmitting(false);
                pageHandler(1);
              })
              .finally(() => {
                props.handleClose();
                actions.setSubmitting(false);
                pageHandler(1);
              });
          } else {
            api(API.billingAndPayment.upgradePricingPlan, obj)
              .then((res) => {
                props.handleClose();
                actions.setSubmitting(false);
                pageHandler(1);
                if (plan === pricingPlanEnum.trial) toast.success(toastMessages.billing.successUpgradePricingPlanCall);
                else toast.success(toastMessages.billing.successUpgradePricingPlanCall);

                props.history.push("/" + props.orgId + "/organization-settings/billing-and-payment");
              })
              .catch((error) => {
                console.log(error);
                console.log("ERROR_RESPONSE:");
                console.log(error?.response);
                console.log("ERROR_RESPONSE_DATA:");
                console.log(error?.response?.data);
                console.log("ERROR_RESPONSE_DATA_ACTION_LINK:");
                console.log(error?.response?.data?.action_link);
                if (error.response.status != null && error.response.status === statusCodes.unprocessableEntity) {
                  if (error.response.data.action_link) {
                    var url =
                      error.response.data.action_link +
                      "&callback_url=" +
                      import.meta.env.VITE_SERVER_URL +
                      "/chargify/callback&redirect_url=" +
                      import.meta.env.VITE_LOCAL_URL +
                      "/" +
                      props.orgId +
                      "/organization-settings/billing-and-payment";

                    window.location.href = url;
                  }
                }

                let errorMessage = null;
                if (error?.response?.data?.errors?.length > 0) errorMessage = error?.response?.data?.errors[0];
                if (errorMessage) toast.warn(errorMessage, { autoClose: false });
                else toast.error(error.message, { autoClose: false });
                props.handleClose();
                actions.setSubmitting(false);
                pageHandler(1);
              })
              .finally(() => {
                props.handleClose();
                actions.setSubmitting(false);
                pageHandler(1);
              });
          }
        }
      }}
    >
      {(formikProps) => {
        const { handleSubmit, values, isSubmitting, setFieldValue, submitForm, setSubmitting } = formikProps;
        const handleSubmitWholeForm = () => {
          if (page === 1 || (isNewCard === false && firstCardEver === false)) {
            submitForm();
            setSubmitting(false);
          } else {
            setGetToken(true);
          }
        };
        const handleReturnToken = (token) => {
          //if its an invalid card
          if (token.errors != null) {
            toast.error(token.errors);
          } else {
            //if we all ready have credit card info
            if (creditCardInfo != null && !isNewCard) {
              //there is no change in the credit card submit form no token
              if (token?.invalidFields?.length === 5 && token?.message != null) {
                setToken("noToken");
                //handleSubmit();
              } else {
                //we have an error show it to the user
                if (token.message != null) {
                  toast.error(token.message);
                } else {
                  setToken(token);
                  //   handleSubmit();
                }
              }
            } //first time input credit card
            else {
              //we have an error show it to the user
              if (token.message != null) {
                toast.error(token.message);
              } //submit form with token
              else {
                setToken(token);
                //handleSubmit();
              }
            }
          }
        };

        switch (page) {
          case 1:
            renderPage = (
              <BillingInfo
                setFieldValue={setFieldValue}
                values={values}
                click={handleSubmit}
                setDisableSaveButton={"Next"}
                setHasStates={setHasStates}
              />
            );
            break;
          case 2:
            renderPage = (
              <PaymentDetails
                isSubmitting={isSubmitting}
                getToken={getToken}
                creditCardInfo={creditCardInfo}
                click={handleSubmit}
                setGetToken={setGetToken}
                handleReturnToken={handleReturnToken}
                setSubmitting={setSubmitting}
                isNewCard={isNewCard}
                setIsNewCard={setIsNewCard}
                firstCardEver={firstCardEver}
              />
            );
            break;
          default:
            break;
        }

        return (
          <Modal
            title={props.title != null ? props.title : "Payment"}
            handleClose={props.handleClose}
            handleSave={() => handleSubmitWholeForm()}
            show={props.showModal}
            closeButtonText={"Cancel"}
            saveButtonText={saveButtonText}
            btnType="submit"
            backdrop={true}
            dialogClassName={`${styles.paymentModal} customModal`}
            size="lg"
            saveButtonDisabled={isSubmitting}
            footerDetails={
              <div className={styles.secureDescriptionWrapper}>
                <LockFillIcon size={18} className="iconGray400" />
                <p className={`f12 ${styles.descriptionText}`}>This is a secured 128-bit SSL encryption payment.</p>
              </div>
            }
          >
            <FormikEffect onChange={onChange(values)} />
            <div className={`${styles.modalFormWrapper}`}>
              <div className="flex">
                <div className={styles.stepperWrapper}>
                  <Stepper
                    label="Billing Info"
                    label2="Payment Details"
                    pageActive={page}
                    pageOneClicked={() => pageHandler(1)}
                    pageTwoClicked={() => pageHandler(2)}
                  />
                  {renderPage}
                </div>
                <div className={styles.summaryWrapper}>
                  <SummaryCard
                    createBillingInfo={createBillingInfo}
                    purchaseProduct={props.purchaseProduct}
                    currentPlan={currentPlan}
                    license="Free"
                    credits={credits}
                    users={users}
                    totalPrice={typeOfBilling ? fullPrice : price}
                    period={period}
                    currencySign={currencySign}
                    typeOfBilling={typeOfBilling}
                    className="mB25"
                    coupon={coupon}
                    discountPrice={discountPrice}
                    couponDiscountedPrice={couponDiscountedPrice}
                    couponDiscountedFullPrice={couponDiscountedFullPrice}
                  />
                  {isCouponUsed ? null : (
                    <CouponField
                      formikInputWrapperClass={styles.promoWrapperClassPayment}
                      textLabelClass={styles.paymentModalField}
                      setCoupon={setCoupon}
                      coupon={coupon}
                      hideTextLabel={true}
                      appliesTo={couponAppliesToEnum.subscription}
                      period={period}
                      typeOfBilling={typeOfBilling}
                      isFromOrderSummary={false}
                      hasRedirect={hasRedirect}
                      showModal={showModal}
                    />
                  )}
                </div>
              </div>
              {/* <AlertNotification
                  variant="info_alert"
                  notificationWrapperClass="mT15"
                >
                  <p>
                    You will be emailed with a copy of the contract to be
                    signed, prior to be charged.
                  </p>
                </AlertNotification> */}
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default GetStartedForm;
