import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Helpers
import { activeApplicationEnum } from "../../../helper/enums/activeApplicationEnum";
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";

//Icons
import CopperCoinFillIcon from "remixicon-react/CopperCoinFillIcon";
import CheckboxBlankCircleLineIcon from "remixicon-react/CheckboxBlankCircleLineIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

//Style
import styles from "./OrganizationCheckList.module.scss";

//Components
import CheckList from "../CheckList/CheckList";
import ProgressBar from "../../ProgressBar/ProgressBar";
import AlertNotification from "../AlertNotification/AlertNotification";

const OrganizationCheckList = (props) => {
  const organizationId = useOrganizationId();
  const dateFormat = useOrgDateFormat();

  if (props.organizationOnboardingModel === null) return <></>;
  const {
    audienceCreated,
    emailAccountConnected,
    campaignSetup,
    goLiveTodayOrEarlier,
    scheduledLiveDate,
    emailTemplatesInitialDraftCount,
    emailTemplatesInitialPublishedCount,
    emailTemplatesFollowUpDraftCount,
    emailTemplatesFollowUpPublishedCount,
  } = props.organizationOnboardingModel || {};

  const subStepsCompleted =
    (emailTemplatesInitialDraftCount > 0 || emailTemplatesInitialPublishedCount > 0) +
    (emailTemplatesFollowUpDraftCount > 0 || emailTemplatesFollowUpPublishedCount > 0) +
    (emailTemplatesInitialPublishedCount > 0 && emailTemplatesFollowUpPublishedCount > 0);

  let buttonText = "Create Initial Email";

  if (
    (emailTemplatesInitialDraftCount || emailTemplatesInitialPublishedCount) > 0 &&
    !(emailTemplatesFollowUpDraftCount || emailTemplatesFollowUpPublishedCount > 0)
  ) {
    buttonText = "Create Follow Up";
  } else if (
    (emailTemplatesFollowUpDraftCount || emailTemplatesFollowUpPublishedCount) > 0 &&
    !(emailTemplatesInitialDraftCount || emailTemplatesInitialPublishedCount > 0)
  ) {
    buttonText = "Create Initial Template";
  } else if (emailTemplatesInitialDraftCount > 0 || emailTemplatesFollowUpDraftCount > 0) {
    buttonText = "Review Messages";
  }

  const emailBodyContent = (
    <>
      <p className="flex align-center">
        {emailTemplatesInitialDraftCount || emailTemplatesInitialPublishedCount > 0 ? (
          <CheckboxCircleFillIcon className="mR5" color="var(--first-stage-color)" size={16} />
        ) : (
          <CheckboxBlankCircleLineIcon className="mR5" size={16} />
        )}
        <span className={styles.highlightedText}> 1. Create your initial email template </span> – this is your first step in engaging your
        defined audience
      </p>
      <p className="flex align-center">
        {emailTemplatesFollowUpDraftCount || emailTemplatesFollowUpPublishedCount > 0 ? (
          <CheckboxCircleFillIcon className="mR5" color="var(--first-stage-color)" size={16} />
        ) : (
          <CheckboxBlankCircleLineIcon className="mR5" size={16} />
        )}
        <span className={styles.highlightedText}> 2. Develop at least one follow-up email </span> to ensure consistent communication with
        your audience – this is vital for maintaining engagement.
      </p>
      <p className="flex align-center">
        {emailTemplatesInitialPublishedCount && emailTemplatesFollowUpPublishedCount > 0 ? (
          <CheckboxCircleFillIcon className="mR5" color="var(--first-stage-color)" size={16} />
        ) : (
          <CheckboxBlankCircleLineIcon className="mR5" size={16} />
        )}
        <span className={styles.highlightedText}> 3. Review and publish your emails.</span> If any of your initial or follow-up emails are
        still in draft status, finalize them to complete this onboarding step.
      </p>
    </>
  );

  const audienceBodyContent = (
    <>
      <p>
        Here, you'll create a list of organizations that could potentially become your clients. Consider the industry you're targeting, the
        ideal company size, and the specific job roles you want to connect with.
      </p>
      <p>Don't worry, we'll guide you through the process. Click 'Build a List' to craft your list in just a few minutes.</p>
      <p>You can create as many lists as you'd like or make adjustments to your saved lists later.</p>
    </>
  );

  const campaignBodyContent = (
    <>
      <p>Set the dates and times for sending your emails and the order in which you want them to go out.</p>
      <p>When everything looks perfect, hit 'Activate Campaign' to launch it and start seeing those leads coming in.</p>
    </>
  );

  const accountBodyContent = (
    <>
      <p>This email address will be used to both sending emails from your campaign and receiving responses from prospects.</p>
      <p>
        As you scale campaigns you can add multiple accounts, on multiple email domains - however to get your first campaign setup you just
        need one.
      </p>
    </>
  );

  const steps = [
    {
      label: "Create Your First List",
      description: "What does your ideal customer look like?",
      body: audienceBodyContent,
      buttonText: "Build a List",
      to: { pathname: "/" + organizationId + "/search" },
      completed: audienceCreated,
      linkText: "How to find your ideal customer",
      learnMoreLink: "https://outbase.com/blog/knowledge-hub/how-to-find-your-ideal-customers/",
      id: "audiencesStep",
    },
    {
      label: "Connect An Email Address",
      description: "Which email account would you like us to use?",
      body: accountBodyContent,
      buttonText: "Connect your Email",
      to: { pathname: `/${organizationId}/email-accounts` },
      completed: emailAccountConnected,
      linkText: "Email account settings",
      learnMoreLink: "https://help.outbase.com/getting-started/add-an-email-account/email-account-and-general-settings",
    },
    {
      subSteps: [3],
      subStepsCompleted: subStepsCompleted,
      label: "Add Your Email Messages",
      description: "Begin crafting your outreach by creating an initial email template.",
      body: emailBodyContent,
      buttonText: buttonText,
      to: { pathname: "/" + organizationId + "/email-templates/templates" },
      completed: emailTemplatesInitialPublishedCount && emailTemplatesFollowUpPublishedCount > 0,
      linkText: "How many follow ups should I send? ",
      learnMoreLink: "https://outbase.com/blog/to-chase-or-not-to-chase-that-is-the-question/",
    },
    {
      label: "Start Campaign",
      description: "Now it's time to bring it all together: the schedules, lists, messages, and send volumes.",
      body: campaignBodyContent,
      buttonText: "Define your Campaign",
      to: { pathname: "/" + organizationId + "/campaigns" },
      completed: campaignSetup,
    },
  ];

  const completedStepsCount = steps.filter((step) => step.completed).length;
  const totalSteps = steps.length;
  const completionPercentage = (completedStepsCount / totalSteps) * 100;
  let labelText = "";

  if (completedStepsCount === totalSteps) {
    if (goLiveTodayOrEarlier) {
      labelText = "100% Your campaign is about to start sending emails";
    } else if (scheduledLiveDate) {
      const scheduledLiveDateFormat = moment(scheduledLiveDate).format(dateFormat);
      labelText = `100% Your campaign is scheduled to start on ${scheduledLiveDateFormat}`;
    } else {
      labelText = `100% Your campaign is scheduled to start soon`;
    }
  } else if (completionPercentage >= 75) {
    labelText = "75% complete - Almost there!";
  } else if (completionPercentage >= 50) {
    labelText = "50% complete - Nice work!";
  } else {
    labelText = `${completionPercentage}% complete`;
  }

  return (
    <div className={styles.organizationCheckList}>
      {completionPercentage === 100 ? (
        <AlertNotification dismissible notificationWrapperClass="mB15" variant="no_icon_notification">
          <span className="f18">🎉</span> <span className="f14">Congratulations! Your Outbase campaign is set up and scheduled.</span>
        </AlertNotification>
      ) : null}
      <h1 className="f18 mB20">👋 Hello {props.name}, welcome to Outbase</h1>
      <p className={`f14 ${styles.subHeader}`}>
        The checklist below will guide you through getting your first campaign up and running in no time.
      </p>
      <AlertNotification
        alertClassName={styles.notificationWrapper}
        bodyClassName={styles.notificationBody}
        variant="warning_alert"
        icon={<CopperCoinFillIcon size={16} />}
      >
        Earn extra 100 credits by completing the onboarding and starting your first campaign.
      </AlertNotification>
      <ProgressBar now={completionPercentage} max={100} min={0} showLabel labelText={labelText} />
      <CheckList className="mT20" steps={steps} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.user.firstName,
    organizationOnboardingModel: state.organizationSettingsGeneral.organizationOnboardingModel,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
    previousOrganizationId: state.dashboard.previousOrganizationId,
  };
};

export default connect(mapStateToProps, null)(OrganizationCheckList);
