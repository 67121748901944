import React from "react";

// Styles
import styles from "./AddEmailTemplateForm.module.scss";

//Components
import Modal from "../../../../../../components/UI/Modal/Modal";
import StatsBox from "../../StatsBox/StatsBox";

const AddEmailTemplateForm = (props) => {
  const addEmailTemplate = () => {
    props.handleClick(props.templateId, props.stepId, props.templateName);
  };
  return (
    <Modal
      title={"Email template details"}
      handleClose={props.handleClose}
      handleSave={addEmailTemplate}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="Select template"
      btnType="submit"
      size="md"
      customBodyClass={styles.templateDetailsModal}
    >
      <>
        <StatsBox
          name={props.templateName}
          openRate={props.openRate}
          clickRate={props.clickRate}
          leadRate={props.leadRate}
          inUse={props.dateCreated}
          totalSend={props.totalSend}
          boxClassName={styles.statisticBox}
          status={props.status}
          statsWrapperClassName={styles.modalStatsWrapper}
        />
        <div className={styles.templateWrapper}>
          <p className="mT15 mB15">
            Subject: <b>{props.subject}</b>
          </p>
          <div dangerouslySetInnerHTML={{ __html: props.emailHtml }}></div>
        </div>
      </>
    </Modal>
  );
};

export default AddEmailTemplateForm;
