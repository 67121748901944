import api, { API } from "@helper/api/api";

const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/gif", "image/pjpeg"];

const isFileTypeAllowed = (mimeType, fileExtension) => {
  return ALLOWED_MIME_TYPES.includes(mimeType) && fileExtension !== "jfif";
};

const handleImageUpload = async (blobInfo, progress) => {
  try {
    const blob = blobInfo.blob();
    const fileName = blobInfo.filename();
    const mimeType = blob.type;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (!isFileTypeAllowed(mimeType, fileExtension)) {
      throw new Error("Unsupported File Format. Please upload a JPEG, PNG, JPG, or GIF file.");
    }

    const data = new FormData();
    data.append("image", blob, fileName);

    const response = await api(API.emailTemplates.uploadImage, data);
    progress(100);
    return response.data;
  } catch (error) {
    progress(100);
    throw error;
  }
};

export { handleImageUpload };
