import React from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { variablesEnum } from "./enums/inAppNotificationTemplateVariablesEnum";
import Moment from "moment";
import moment_timezone from "moment-timezone";

export const processNotificationLinks = (notifications, organization, dateFormat) => {
  if (!notifications) return [];

  const variableRegex = /{{(.*?)}}/g;
  const clonedNotifications = _.cloneDeep(notifications);

  clonedNotifications.forEach((notification) => {
    let notificationData = {};
    if (notification && notification.data) {
      notificationData = JSON.parse(notification.data);
    }

    const minDate = Moment.utc("0001-01-01").format(dateFormat);
    moment_timezone.tz.setDefault("UTC");
    let notificationMessage = notification.message;
    let notificationLink = notification.link;

    if (
      Object.prototype.toString.call(notificationMessage) === "[object String]" &&
      Object.prototype.toString.call(notificationLink) === "[object String]"
    ) {
      let regexMessageMatches = notificationMessage.match(variableRegex);
      let regexLinkMatches = notificationLink.match(variableRegex);

      // We change the variables that were found in the messages
      if (regexMessageMatches != null) {
        regexMessageMatches.forEach((match) => {
          match = match.replace("{{", "").replace("}}", "").toUpperCase();
          switch (match) {
            case variablesEnum.OrganizationName: {
              notificationMessage = notificationMessage.replace(match, organization.name);
              break;
            }
            case variablesEnum.EmailAccount: {
              notificationMessage = notificationMessage.replace(match, notificationData.ea);
              break;
            }
            case variablesEnum.DomainName: {
              notificationMessage = notificationMessage.replace(match, notificationData.d);
              break;
            }
            case variablesEnum.UserEmail: {
              notificationMessage = notificationMessage.replace(match, notificationData.e);
              break;
            }
            case variablesEnum.CampaignName: {
              notificationMessage = notificationMessage.replace(match, notificationData.cn);
              break;
            }
            case variablesEnum.NumberOfProspects: {
              notificationMessage = notificationMessage.replace(match, notificationData.pi); //ProspectsImported
              break;
            }
            case variablesEnum.AudienceName: {
              notificationMessage = notificationMessage.replace(match, notificationData.an);
              break;
            }
            case variablesEnum.AddedAudienceContacts: {
              notificationMessage = notificationMessage.replace(match, notificationData.aa);
              break;
            }
            case variablesEnum.FileName: {
              notificationMessage = notificationMessage.replace(match, notificationData.dn); //DocumentName
              break;
            }
            case variablesEnum.NumberDaysLeft: {
              notificationMessage = notificationMessage.replace(match, notificationData.nd);
              break;
            }
            case variablesEnum.PurchaseTemplateCreatedDate: {
              notificationMessage = notificationMessage.replace(match, Moment(notificationData.da).format(dateFormat));
              break;
            }
            case variablesEnum.ProspectsImported: {
              notificationMessage = notificationMessage.replace(match, notificationData.pi);
              break;
            }
            case variablesEnum.ProspectsNotImported: {
              notificationMessage = notificationMessage.replace(match, notificationData.pn);
              break;
            }
            case variablesEnum.ImportTotal: {
              notificationMessage = notificationMessage.replace(match, notificationData.i);
              break;
            }
            case variablesEnum.ErrorCode: {
              notificationMessage = notificationMessage.replace(match, notificationData.ec);
              break;
            }
            case variablesEnum.FirstName: {
              notificationMessage = notificationMessage.replace(match, notificationData.f);
              break;
            }
            case variablesEnum.LastName: {
              notificationMessage = notificationMessage.replace(match, notificationData.l);
              break;
            }
            case variablesEnum.IntegrationName: {
              notificationMessage = notificationMessage.replace(match, notificationData.in);
              break;
            }
            case variablesEnum.HolidayPauseDate: {
              notificationMessage = notificationMessage.replace(match, Moment(notificationData.da).format(dateFormat));
              break;
            }
            case variablesEnum.CampaignRestartDate: {
              var campaignRestartDate = notificationData.crd > minDate ? Moment(notificationData.crd).format(dateFormat) : "NA";
              notificationMessage = notificationMessage.replace(match, campaignRestartDate);
              break;
            }
            case variablesEnum.NumberOfResponses: {
              notificationMessage = notificationMessage.replace(match, notificationData.nr);
              break;
            }
            case variablesEnum.MediaName: {
              notificationMessage = notificationMessage.replace(match, notificationData.mn);
              break;
            }
            case variablesEnum.SuccessfulDeliverabilityTestSent: {
              notificationMessage = notificationMessage.replace(match, notificationData.sd);
              break;
            }
            case variablesEnum.UnsuccessfulDeliverabilityTestSent: {
              notificationMessage = notificationMessage.replace(match, notificationData.us);
              break;
            }
            case variablesEnum.ExportProspectFileCreatedDate: {
              notificationMessage = notificationMessage.replace(match, Moment(notificationData.da).format(dateFormat));
              break;
            }
            default:
              break;
          }
        });
      }

      while (notificationMessage.indexOf("{{") >= 0 || notificationMessage.indexOf("{{") >= 0) {
        notificationMessage = _.replace(notificationMessage, "{{", "");
        notificationMessage = _.replace(notificationMessage, "}}", "");
      }

      // We create the notification link depending on the matched case
      if (regexLinkMatches != null) {
        regexLinkMatches.forEach((match) => {
          match = match.replace("{{", "").replace("}}", "").toUpperCase();
          switch (match) {
            case variablesEnum.LinkToOrganizationSettings: {
              notificationMessage = (
                <>
                  <NavLink to={`/${notificationData.oi}/organization-settings/general-settings`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToMyOrganizations: {
              notificationMessage = (
                <>
                  <NavLink to={`/my-organizations`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToAccountsList: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/email-accounts`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToAccountSettings: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/email-accounts/` + notificationData.ei + `/edit`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToDomains: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/domains`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToCampaignConfigure: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/campaigns/` + notificationData.ci + `/overview`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToCampaignProspects: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/campaigns/` + notificationData.ci + `/prospects?show=25`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToAudiences: {
              notificationMessage = (
                <>
                  <NavLink
                    to={
                      notificationData.ai
                        ? `/${organization.id}/list/` + notificationData.ai + "/list-contacts/" + notificationData.at
                        : `/${organization.id}/lists?show=25`
                    }
                  >
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToAudienceImport: {
              notificationMessage = (
                <>
                  <NavLink
                    to={
                      notificationData.ai
                        ? `/${organization.id}/list/` +
                          notificationData.ai +
                          "/list-contacts/" +
                          notificationData.at +
                          "?CampaignId=" +
                          notificationData.ci +
                          "&ImportRemaining=" +
                          notificationData.ir
                        : `/${organization.id}/lists?show=25`
                    }
                  >
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.PricingPlanLink: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/pricing`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToPurchaseTemplateDetails: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/template-request/` + notificationData.pt}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToIntegrations: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/crm`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToSendingSchedule: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/campaigns/${notificationData.ci}/sending-schedule`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToCampaignsCalendar: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/calendar`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToInbox: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/inbox`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToDealNavigator: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/deal-navigator`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToDealNavigatorResources: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/sales-documents`}>
                    <span>{notificationMessage}</span>
                  </NavLink>
                </>
              );
              break;
            }
            case variablesEnum.LinkToDeliverabilityTestDetails: {
              notificationMessage = (
                <>
                  <NavLink to={`/${organization.id}/email-template/${notificationData.td}/configuration-tests`}></NavLink>
                  <span>{notificationMessage}</span>
                </>
              );
              break;
            }
            default:
              break;
          }
        });
      }

      notification.message = notificationMessage;
    }
  });

  return clonedNotifications;
};
