export const isIt = {
  string: (object) => {
    if (object && object.constructor === String && typeof object === "string") {
      return true;
    }
  },
  number: (object) => {
    if (object && object.constructor === Number) {
      return true;
    }
  },
  array: (object) => {
    if (object && object.constructor === Array) {
      return true;
    }
  },
  boolean: (object) => {
    if (object && object.constructor === Boolean) {
      return true;
    }
  },
  object: (object) => {
    if (object && object.constructor === Object) {
      return true;
    }
  },
};
