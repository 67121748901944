import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Styles
import style from "./StatusBarProspect.module.scss";

//Images & Icons
import IconEngaged from "@assets/Icons/AwaitingEngagment/AwaitingEngagment";
import IconOpened from "@assets/Icons/EmailOpened/EmailOpened";
import IconClicked from "@assets/Icons/Clicked/Clicked";
import IconResponded from "@assets/Icons/Responded/Responded";
import IconLead from "@assets/Icons/IconLead/IconLead";

const IconComponent = ({ statusActive, isActive, style, fillActive, fillInactive, Icon, width, height }) => (
  <OverlayTrigger
    key={isActive}
    placement={"bottom"}
    overlay={
      <Popover id={`tooltip-${isActive}`}>
        <Popover.Body>{isActive === "isResponder" ? "isResponded".substring(2) : isActive.substring(2)}</Popover.Body>
      </Popover>
    }
  >
    <div className={`${style.item} ${statusActive ? style[isActive] : ""}`}>
      <Icon fill={statusActive ? fillActive : fillInactive} width={width} height={height} />
    </div>
  </OverlayTrigger>
);

const StatusBar = (props) => {
  const { isDelivered, isOpened, isClicked, isResponder, isLead } = {
    ...props,
  };

  const statuses = [
    { isActive: "isDelivered", statusActive: isDelivered, fillActive: "#FCCD72", Icon: IconEngaged, width: 12, height: 12 },
    { isActive: "isOpened", statusActive: isOpened, fillActive: "#E86FA9", Icon: IconOpened, width: 12, height: 12 },
    { isActive: "isClicked", statusActive: isClicked, fillActive: "#6A45A0", Icon: IconClicked, width: 12, height: 12 },
    { isActive: "isResponder", statusActive: isResponder, fillActive: "#1288F5", Icon: IconResponded, width: 12, height: 12 },
    { isActive: "isLead", statusActive: isLead, fillActive: "#1AC472", Icon: IconLead, width: 11, height: 13 },
  ];

  return (
    <div className={style.statusBarContainer}>
      {statuses.map((status, index) => (
        <IconComponent key={index} {...status} style={style} fillInactive="#D6D6D6" />
      ))}
    </div>
  );
};

export default StatusBar;
