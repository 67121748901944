import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  error: "",
  userActivityLogDetails: {},
};

const fetchUserActivityLogDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchUserActivityLogDetailsFailed = (state, action) => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: action.state,
  };
};

const fetchUserActivityLogDetailsSuccess = (state, action) => {
  const newState = { ...state };
  let userActivityLog = { ...newState.userActivityLogDetails };
  userActivityLog = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.userActivityLogDetails = userActivityLog;
  return newState;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_ACTIVITY_LOG_DETAILS_REQUEST:
      return fetchUserActivityLogDetailsRequest(state, action);
    case actionTypes.FETCH_USER_ACTIVITY_LOG_DETAILS_SUCCEEDED:
      return fetchUserActivityLogDetailsSuccess(state, action);
    case actionTypes.FETCH_USER_ACTIVITY_LOG_DETAILS_FAILED:
      return fetchUserActivityLogDetailsFailed(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
