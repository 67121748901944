import { FC } from "react";
import Modal from "@components/UI/Modal/Modal";

//Styles
import styles from "./NoCreditsModal.module.scss";

import noCreditsImg from "./svg/noCredits.svg";

interface Props {
  handleClose: () => void;
  showModal: boolean;
  organizationId: string;
}

const NoCreditsModal: FC<Props> = (props) => {
  let purchaseCreditsLink = `/${props.organizationId}/pricing`;

  return (
    <Modal
      handleClose={props.handleClose}
      show={props.showModal}
      closeButtonText="Later"
      saveButtonText="Upgrade Plan"
      isSaveButtonLink={true}
      saveButtonLink={purchaseCreditsLink}
      linkTarget="_self"
    >
      <div className={styles.noCreditsModal}>
        <img src={noCreditsImg} className={styles.noCreditsImg} alt="Purchase credits" />
        <p className={styles.introText}>You have used up your available credits!</p>
        <p>Upgrade now to instantly receive more credits and continue connecting with key prospects effortlessly.</p>
      </div>
    </Modal>
  );
};

export default NoCreditsModal;
