import * as dataTableActionTypes from "../../../hor/dataTableActionTypes";
import api, { API } from "../../../../helper/api/api";

const name = "PROSPECTS";

export const fetchProspectsRequest = () => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_REQUEST}`,
  };
};

export const fetchProspectsSuccess = (setup) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_SUCCEEDED}`,
    result: setup,
  };
};

export const fetchProspectsFailed = (error) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_FAILED}`,
    result: error,
  };
};

export const fetchProspects = (param) => {
  return (dispatch) => {
    dispatch(fetchProspectsRequest());
    return api(API.prospects.post, param)
      .then((response) => {
        dispatch(fetchProspectsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProspectsFailed(error.message));
      });
  };
};

export const initiate = (state) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_INITIATE}`,
    result: state,
  };
};

export const changePage = (page) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CHANGE_PAGE}`,
    result: page,
  };
};

export const sortData = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SORT_DATA}`,
    result: obj,
  };
};

export const numRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_NUM_RECORDS}`,
    result: obj,
  };
};

export const searchRecords = (value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SEARCH_RECORDS}`,
    result: value,
  };
};

export const filterRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FILTER_RECORDS}`,
    result: obj,
  };
};

export const setFilter = (type, value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SET_FILTER}`,
    filterType: type,
    filterValue: value,
  };
};

export const clearFilter = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_FILTER}`,
    filterType: type,
  };
};

export const clearInitiated = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_INITIATED}`,
  };
};

export const phoneFilterValues = [{value: "phoneFound", label: "Available phone numbers"}, {value: "phoneRevealed", label: "Revealed phone numbers"}];
