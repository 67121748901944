import React, { useRef, useEffect } from "react";

// Style
import styles from "./SequenceSteps.module.scss";

//Images & Icons
import SectionCompany from "../../../../../assets/Icons/SectionCompany/SectionCompany";
import MailLineIcon from "remixicon-react/MailLineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import LightbulbFlashLineIcon from "remixicon-react/LightbulbFlashLineIcon";

// Components
import Button from "../../../../../components/UI/Button/Button";
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";
import Step from "./Step/Step";
import ProductTourLinks from "../../../ProductTourLinks/ProductTourLinks";
import VideoModal from "../../../../Dashboard/Components/WelcomeModal/VideoModal/VideoModal";
import { emailTemplateStatusEnum } from "../../../../../helper/enums/emailTemplateStatusEnum";
import { actionTypeEnum } from "../../../../../helper/enums/actionType";
import useModal from "@components/UI/Modal/useModal";

const SequenceSteps = (props) => {
  const { steps } = props;
  let list = null;
  let lastStepIndex = props.steps.length - 1;
  // let firstStep = styles.visibility;

  const timeLineRef = useRef(null);
  const videoModal = useModal();

  const scrollToRef = () => {
    //get the active element in the list and scroll to it
    if (timeLineRef.current != null) {
      let el = timeLineRef.current;

      let lastNodeIndex = null;
      //all the steps
      let nodes = el.childNodes;
      for (let index = 1; index < nodes.length; index++) {
        const element = nodes[index];
        const stepNode = element.childNodes[0];

        //all the classes from the step
        const classList = stepNode.classList;
        for (let i = 0; i < classList.length; i++) {
          const element = classList[i];
          if (element != null) {
            //check if the step is active
            if (element.includes("Step_activeEmail__") || element.includes("Step_activeWait")) {
              lastNodeIndex = index;
            }
          }
        }
      }

      if (lastNodeIndex != null) {
        let scrollElement = nodes[lastNodeIndex];
        scrollElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  useEffect(() => {
    const hasDraftStep = steps.some((st) => st.status === emailTemplateStatusEnum.Draft);
    if (!hasDraftStep) {
      props.setStatusDraft(false);
    }
  }, [steps]);

  useEffect(() => {
    let hasActive = steps.filter((st) => st.active === true);
    if (hasActive.length > 0) {
      scrollToRef();
    }
  }, [steps]);

  const handleTemplatesShow = (last, id) => {
    if (props.steps.length > 0 && id === 0) {
      id = 0;
    }
    props.showTemplates(last, id);
  };

  const handleWaitShow = (last, id) => {
    props.showWait(last, id);
  };

  const handleDeleteStepShow = (id) => {
    props.deleteStep(id);
  };

  const handleStepClicked = (id, type, templateId, waitDays) => {
    props.stepClicked(id, type, templateId, waitDays);
  };

  if (props.steps[0].type !== undefined) {
    // firstStep = null;
    list = props.steps.map((s, i) => {
      const isActive = s.active || (i === 0 && props.steps.every((step) => !step.active));
      if (s.status === emailTemplateStatusEnum.Draft) {
        props.setStatusDraft(true);
      }
      return (
        <Step
          details={s.details}
          templateId={s.templateId}
          showTemplates={() => handleTemplatesShow(lastStepIndex === i, s.id)}
          showWait={() => handleWaitShow(lastStepIndex === i, s.id)}
          type={s.type}
          deleteStep={() => handleDeleteStepShow(s.id)}
          stepClicked={() => handleStepClicked(s.id, s.type, s.templateId, s.waitDays)}
          templateType={s.templateType}
          templateHtml={s.html}
          templateSubject={s.subject}
          templateStatus={s.status}
          active={isActive}
          isDeletedTemplate={s.isDeletedTemplate}
          iterationId={i}
          key={i}
        />
      );
    });
    let lastEntry = props.steps[props.steps.length - 1].type;
    if (lastEntry === actionTypeEnum.Wait) {
      list.push(
        <div className={styles.infoStepNotification} key="additionalText">
          <p className={`${styles.infoMessage} m0`}>
            <LightbulbFlashLineIcon className="mR10" size={20} color="var(--warning-color-700)" /> Add one more follow-up step to further
            increase the reply rates
          </p>
        </div>
      );
    }
  }

  // Texts for the heading section
  const heading = {
    title: "Sequence Flow",
    description:
      "Here you'll design your email flow including the email copy, the number of emails you'd like to send, and the wait times between emails",
  };

  return (
    <div
      className={`${styles.pageWrapper} ${props.hideSequenceFlow ? styles.closedSection : styles.openedSection}`}
      id="sequenceStepsWrapper"
    >
      <>
        <SectionTitle
          titleClass={styles.sectionSequenceTitle}
          rightContentClass={styles.iconContent}
          titleWrapperClass={styles.titleSectionWrapper}
          sectionTitleClass={styles.mainTitleSection}
          icon={<SectionCompany />}
          title="Sequence Flow"
          rightContent={
            props.showIcon ? (
              <ArrowLeftSLineIcon
                className={` ${
                  props.hideSequenceFlow ? styles.arrowRotate : styles.arrowIcon
                } cursor_pointer circleToggleICon cursor_pointer iconGray400 `}
                onClick={() => props.handleHideSequenceFlow(!props.hideSequenceFlow)}
              />
            ) : null
          }
        />
      </>

      <div
        ref={timeLineRef}
        className={`${styles.timelineWrapper} ${props.timelineWrapperClass ? props.timelineWrapperClass : styles.viewMode}`}
      >
        <ProductTourLinks
          labelTour={heading.description}
          labelClass="pr-2"
          show={videoModal.show}
          id="sequenceStep"
          tourButton
          linksWrapperClass="w-100 mb-5 align-items-start"
        />

        <div className={styles.stepsWrapper}>
          <div className={`${styles.startSequenceWrapper} ${styles.m_b_38}`}>
            <div className={`${styles.siquenceStart} `}>
              <p>Sequence Start</p>
            </div>
          </div>

          <div id="addStepWrapper" className={styles.addStepWrapper}>
            <Button id="addSteps" variant="rounded-s" classes={`${styles.addStepButton} ${styles.scaleButton}`}>
              <AddLineIcon size={18} className="secondary300" />
            </Button>

            <div className={`${styles.chooseStep}`}>
              <Button id="stepsEmail" variant="secondary-s" classes={`${styles.addStepButton} ${styles.addEmail} step-email`}>
                <MailLineIcon size={14} onClick={() => handleTemplatesShow(false, 0)} />
              </Button>
            </div>
          </div>
        </div>

        {list}
      </div>

      <VideoModal modal={videoModal} />
    </div>
  );
};

export default SequenceSteps;
