import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { useAuth } from "react-oidc-context";

//Helpers
import { useOrganizationId } from "../../../helper/hooks/useOrganizationId";
import { activeApplicationEnum } from "../../../helper/enums/activeApplicationEnum";
import { PERMISSIONS } from "../../../helper/permissionConstants";
import { globalPermissionHelper } from "../../../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../../../helper/globalUserRolePermissionsConstants";
import { jobTitlesOptions } from "../../../helper/enums/jobTitlesOptions";
import api, { API } from "../../../helper/api/api";

//Styles
import styles from "./AccountDetails.module.scss";

//Images & Icons
import { ArrowDropDown } from "@mui/icons-material";

//Components
import Selection from "../OrganizationSelection/OrganizationSelection";
import Permission from "../../../components/auth/Permission";
import CreditUsage from "../CreditUsage/CreditUsage";

const AccountDetails = (props) => {
  const { creditInfo, name, surname } = props;
  const { currentCredits, monthlyCredits, remainingDays, plan } = creditInfo;
  const organizationId = useOrganizationId();
  const auth = useAuth();
  const fullName = `${name} ${surname}`;

  let showCredits = props.organizationOnboardingModel != null && props.organizationOnboardingModel?.onboardingCompleted === false;

  const stopImpersonation = () => {
    api(API.impersonate.stopImpersonation)
      .then((res) => {
        auth.signinSilent();
        props.history.push("/outboss/organizations");
      })
      .catch((error) => {
        // toast.error(error.message);
      });
  };

  let jobTitle;
  if (isNaN(Number(props.jobTitle))) {
    jobTitle = props.jobTitle;
  } else {
    const job = jobTitlesOptions.find((option) => option.value === Number(props.jobTitle));
    jobTitle = job ? job.label : undefined;
  }

  return (
    <div className={`${styles.myAccountDrop} ${props.customStyle}`}>
      <div className={styles.wrap}>
        {organizationId !== 0 && props.currentApplication === activeApplicationEnum.Outbase ? (
          <>
            <NavLink to={`/${props.organizationId}/settings/account`} className={styles.head}>
              <span title={fullName} className={styles.accName}>
                {fullName}
              </span>
              <span className={styles.accEmail}>{props.userEmail}</span>
              {props.jobTitle !== undefined && props.jobTitle !== "" ? <span className={styles.jobTitle}>{jobTitle}</span> : null}
            </NavLink>
            {showCredits && (
              <Permission has={PERMISSIONS.credits.view}>
                <CreditUsage
                  {...props}
                  usageWrapperClass={styles.creditUsageWrapper}
                  usageDetailsWrapperClass={styles.usageDetailsClass}
                  upgradeButtonClass="width_100 mT15"
                  current={currentCredits}
                  total={monthlyCredits}
                  now={currentCredits}
                  plan={plan}
                  remainingDays={remainingDays}
                  orgId={props.organizationId}
                  ariaLabel="Remaining days"
                />
              </Permission>
            )}

            <hr />
            <Permission has={PERMISSIONS.organization.settings.view}>
              <NavLink to={`/${props.organizationId}/organization-settings/general-settings`} className={styles.head}>
                <span>Organization settings</span>
              </NavLink>
            </Permission>

            <div className={styles.switchOrganization}>
              <div className={styles.organizationsWrap} onClick={props.swapOrganizationClick}>
                <span className={styles.switchOrg}>Switch Organization:</span>
                <div className={styles.listOrg}>
                  <span>{props.organizationName}</span>
                  <ArrowDropDown />
                </div>
                <Selection customStyle={styles.orgSelectDropdown} history={props.history} />
              </div>
            </div>
            <NavLink to={`/my-organizations`} className={styles.head}>
              <span>All Organizations</span>
            </NavLink>
            <hr />
          </>
        ) : null}

        {props.currentApplication === activeApplicationEnum.Outbase ? (
          <>
            {props.impersonatorId && props.impersonatorId > 0 ? (
              <div id="swithToOutbaseStopImpersonation" className={styles.head}>
                <span className={styles.switchApplication} onClick={stopImpersonation}>
                  Switch to OutbOSS
                </span>
              </div>
            ) : globalPermissionHelper(props.userPermissions, GLOBAL_PERMISSIONS.outboss.view) ? (
              <NavLink id="swithToOutboss" className={styles.head} to="/outboss/organizations">
                <span className={styles.switchApplication}>Switch to OutbOSS</span>
              </NavLink>
            ) : null}
          </>
        ) : (
          <>
            {/* <hr /> */}
            <NavLink id="swithToOutbase" className={styles.head} to="/my-organizations">
              <span className={styles.switchApplication}>Switch to Outbase</span>
            </NavLink>
          </>
        )}

        <NavLink id="logout" className={styles.head} to="/logout">
          Logout
        </NavLink>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.user.firstName,
    organizationId: state.user.organization?.id,
    surname: state.user.lastName,
    jobTitle: state.user.jobTitle,
    organizationName: state.user.organization?.name,
    userEmail: state.user.email,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
    impersonatorId: state.user.impersonatorId,
    userPermissions: state.user.userPermissions,
    creditInfo: state.billingAndPayment.creditInfo,
    organizationOnboardingModel: state.organizationSettingsGeneral.organizationOnboardingModel,
    organization: state.user.organization,
  };
};

export default connect(mapStateToProps)(AccountDetails);
