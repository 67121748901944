import React, { useEffect } from "react";
import Loading from "../../pages/Loading/Loading";
import { useAuth } from "react-oidc-context";
import { IDENTITY_CONFIG } from "../../helper/authConstants";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";

export const Login = (props) => {
  const auth = useAuth();

  let extraQueryParams = null;
  let redirectUrl = window.location.pathname;
  if (props.location) {
    const query = queryString.parse(props.location.search);
    if (query?.redirectUrl) {
      redirectUrl = query.redirectUrl;
    }

    if (query.params) {
      extraQueryParams = query.params;
    }
  }

  if (redirectUrl === "/login") {
    redirectUrl = "/";
  }

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <Loading />;
    case "signoutRedirect":
      return <Loading />;
    default:
      break;
  }

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.error) {
      const state = {
        redirectUrl: redirectUrl,
      };

      let requestModal = { state: state };

      if (extraQueryParams != null) {
        requestModal.extraQueryParams = {
          params: extraQueryParams,
        };
      }

      auth.signinRedirect(requestModal);
    }
  }, [auth, redirectUrl, extraQueryParams]);

  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.error) {
    return <ErrorPage error={auth.error.message} />;
  }

  if (auth.isAuthenticated) {
    return <Redirect to={redirectUrl} />;
  }

  return <Loading />;
};

export default Login;
