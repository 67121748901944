import React from "react";

//Helpers
import { campaignStatusEnum } from "../../../../helper/enums/campaignStatusEnum";

//Styles
import styles from "./StatusIcon.module.scss";

//Images & Icons
import PlayCircleLineIcon from "remixicon-react/PlayCircleLineIcon";
import PauseCircleLineIcon from "remixicon-react/PauseCircleLineIcon";

const StatusIcon = (props) => {
  let icon = null;

  const handleClick = (status) => {
    props.changeStatus(status);
  };

  const openActivationModal = (status) => {
    props.openActivationModal(status);
  };

  let disabled = false;

  switch (props.status) {
    case campaignStatusEnum.setup:
      icon = (
        <PlayCircleLineIcon
          size={50}
          className={`iconSuccess ${styles.playIcon}`}
          onClick={() => openActivationModal(campaignStatusEnum.active)}
        />
      );

      disabled = !props.canGoLive;
      break;
    case campaignStatusEnum.active:
      icon = (
        <PauseCircleLineIcon
          size={50}
          className={`iconAccent ${styles.pauseIcon}`}
          onClick={() => handleClick(campaignStatusEnum.paused)}
        />
      );
      break;
    case campaignStatusEnum.paused:
      icon = (
        <PlayCircleLineIcon
          size={50}
          className={`iconSuccess ${styles.playIcon}`}
          onClick={() => openActivationModal(campaignStatusEnum.active)}
        />
      );

      disabled = !props.canGoLive;
      break;
    case campaignStatusEnum.scheduled:
      icon = (
        <PauseCircleLineIcon
          size={50}
          className={`iconAccent ${styles.pauseIcon}`}
          onClick={() => handleClick(campaignStatusEnum.paused)}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div disabled={disabled || props.isSubmitting} className={styles.iconWrapper} id="changeStatusButton">
      {icon}
    </div>
  );
};
export default StatusIcon;
