import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { connect } from "react-redux";
import Moment from "moment";
import api, { API } from "../../../../../helper/api/api";
import { toast } from "react-toastify";

//Redux
import { fetchEmailTemplate } from "../../../../../store/EmailTemplates/Global/actions/emailTemplates";
import { setActivePage } from "../../../../../store/WorkflowSteps/actions/workflowSteps";
import {
  fetchEmailTemplates,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
} from "../../../../../store/EmailTemplates/Table/actions/emailTemplates";

//Helpers
import { useOrgDateFormat } from "../../../../../helper/hooks/useOrgDateFormat";
import { emailTemplateTypeEnum } from "../../../../../helper/enums/emailTemplateTypeEnum";
import { campaignStatusEnum } from "../../../../../helper/enums/campaignStatusEnum";
import { emailTemplateStatusEnum } from "../../../../../helper/enums/emailTemplateStatusEnum";
import { checkForIncorrectVariables } from "../../../../../helper/variablesHelper";

// Styles
import styles from "./TemplatePage.module.scss";

//Images & Icons
import SectionCompany from "../../../../../assets/Icons/SectionCompany/SectionCompany";
import EmptyTemplatesImage from "../../../../../assets/Images/EmptyTemplatesImage/EmptyTemplatesImage";

// Components
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Button from "../../../../../components/UI/Button/Button";
import StatsBox from "../StatsBox/StatsBox";
import TemplateCard from "../TemplateList/TemplateCard/TemplateCard";
import EmptyState from "../../../../../components/UI/EmptyState/EmptyState";
import TableFiltersRow from "../../../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import AddEmailTemplateForm from "../TemplateList/AddEmailTemplateForm/AddEmailTemplateForm";
import DraftEmailTemplateWarningModal from "./DraftEmailTemplateWarningModal/DraftEmailTemplateWarningModal";
import AccordionSimple from "../../../../../components/UI/AccordionSimple/AccordionSimple";


// Hooks
import { useSlice } from "@hooks/useSlice";
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const TemplatePage = (props) => {
  const {
    fetchEmailTemplates,
    isInitiated,
    initiate,
    param,
    isLoading,
    isLoaded,
    fetchEmailTemplate,
    emailTemplate,
    templateId,
    rows,
    steps,
    typeActiveStep,
    setTypeActiveStep,
    open,
    setOpen,
  } = props;
  let body = null;
  let dateFormat = useOrgDateFormat();

  const [showAddEmailTemplateModal, setShowAddEmailTemplateModal] = useState(false);
  const [emailTemplateId, setEmailTemplateId] = useState(0);
  const [stepId, setStepId] = useState(0);
  const [emailText, setEmailText] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [html, setHtml] = useState(null);
  const [templateType, setTemplateType] = useState(null);
  const [openRate, setOpenRate] = useState(null);
  const [clickRate, setClickRate] = useState(null);
  const [leadRate, setLeadRate] = useState(null);
  const [totalSend, setTotalSend] = useState(null);
  const [subject, setSubject] = useState(null);
  const [dateCreated, setDateCreated] = useState(null);
  const [status, setStatus] = useState(null);
  const [indexActiveStep, setIndexActiveStep] = useState(-1);
  let image = <EmptyTemplatesImage />;

  const { campaign } = useSlice((state) => state.campaign);
  // Queries
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery();

  const [showDraftEmailTemplateWarningModel, setShowDraftEmailTemplateWarningModel] = useState(false);

  const handleAddEmailTemplateClose = () => setShowAddEmailTemplateModal(false);
  const handleAddEmailTemplateShow = (
    emailTemplateId,
    stepId,
    emailText,
    templateName,
    html,
    templateType,
    openRate,
    clickRate,
    leadRate,
    totalSend,
    subject,
    dateCreated,
    status
  ) => {
    setEmailTemplateId(emailTemplateId);
    setStepId(stepId);
    setEmailText(emailText);
    setTemplateName(templateName);
    setShowAddEmailTemplateModal(true);
    setHtml(html);
    setTemplateType(templateType);
    setOpenRate(openRate);
    setClickRate(clickRate);
    setLeadRate(leadRate);
    setTotalSend(totalSend);
    setSubject(subject);
    setDateCreated(Moment(dateCreated).format(dateFormat));
    setStatus(status);
  };

  const handleClick = (templateId, templateName, templateType, status, subject, html) => {
    if (campaign.status === campaignStatusEnum.active && status === emailTemplateStatusEnum.Draft) {
      setShowAddEmailTemplateModal(false);
      setShowDraftEmailTemplateWarningModel(true);
      return;
    }
    if (status === emailTemplateStatusEnum.Draft) {
      props.setStatusDraft(true);
    }
    let activeStepId = 0;
    if (indexActiveStep !== -1) {
      activeStepId = indexActiveStep;
    }
    let activeStepObj = props.listWorkflowSteps[activeStepId];
    props.addEmail(templateId, activeStepObj.id, templateName, templateType, status, subject, html);
    setShowAddEmailTemplateModal(false);
    let waitDays = 0;
    if (activeStepObj.waitDays !== undefined) {
      waitDays = activeStepObj.waitDays;
    }
    props.changed(activeStepObj.id, activeStepObj.type, templateId, waitDays);
    props.setTemplateId(templateId);
    fetchEmailTemplate(templateId);
  };

  const changeTemplateStatusFromSequence = () => {
    let activeStepId = 0;
    if (indexActiveStep !== -1) {
      activeStepId = indexActiveStep;
    }
    let draftStep = null;
    let activeStepObj = props.listWorkflowSteps[activeStepId];
    let nextEmailStepObj = props.listWorkflowSteps[activeStepId + 2];
    if (nextEmailStepObj !== undefined && nextEmailStepObj.status === emailTemplateStatusEnum.Draft) {
      draftStep = nextEmailStepObj;
    }
    let combineVariablesArray = emailTemplateVariables?.flatMap((templateVariable) => templateVariable.emailTemplateVariables) ?? [];
    let htmlAndSubject = emailTemplate.html.concat(" ", emailTemplate.subject);
    let incorrect = checkForIncorrectVariables(htmlAndSubject, combineVariablesArray);
    if (incorrect?.length > 0) {
      props.setShowErrorVariablesModal(true);
    } else {
      let data = {
        id: templateId,
        organizationId: props.orgId,
        name: emailTemplate.name,
        subject: emailTemplate.subject,
        text: emailTemplate.text,
        html: emailTemplate.html,
        chaserAddSignature: emailTemplate.chaserAddSignature,
        chaserIncludeThread: emailTemplate.chaserIncludeThread,
        languageMarketCode: emailTemplate.languageMarketCode,
        type: emailTemplate.type,
        status: emailTemplateStatusEnum.Publish,
      };

      api(API.emailTemplates.create, data)
        .then((res) => {
          let id = templateId;
          props.selectEmails(id, activeStepObj.id, data.name, data.type, data.status, data.subject, data.html);
          if (draftStep !== null) {
            props.changed(draftStep.id, draftStep.type, draftStep.templateId, draftStep.waitDays);
            id = draftStep.templateId;
          }
          fetchEmailTemplate(id);
          fetchEmailTemplates(param);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    var state = {
      params: {
        filter: [],
        length: 999,
        sort: {},
      },
    };
    if (!isInitiated) {
      initiate(state);
    }
  }, []);

  useEffect(() => {
    if (isInitiated) {
      let params = { ...param };
      params.filter = [];
      params.length = 999;
      params.sort = {};
      fetchEmailTemplates(params);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    let activeIndex = steps.findIndex((s) => s.active != null && s.active === true);
    setIndexActiveStep(activeIndex);

    if (activeIndex === 0 || (steps && steps.length === 1 && activeIndex === -1)) {
      setTypeActiveStep(emailTemplateTypeEnum.InitialEmail);
    } else if (activeIndex !== -1) {
      setTypeActiveStep(emailTemplateTypeEnum.FollowUp);
    }
  }, [steps]);

  useEffect(() => {
    if (templateId !== null) {
      fetchEmailTemplate(templateId);
    }
  }, [templateId, fetchEmailTemplate]);

  if (isLoading) {
    body = <Spinner />;
  }

  if (isLoaded && emailTemplate && props.rows.some((x) => x.id === emailTemplate.id && x.stage === typeActiveStep)) {
    body = (
      <>
        <Accordion className={`${styles.accordionWrapper} width_100`} defaultActiveKey="0">
          <Accordion.Item className={styles.accordionWrapperItem} eventKey="0">
            <Card className={styles.accordionCard}>
              <Accordion.Header
                className={styles.accordionHeader}
                as={Button}
                variant="link"
                classes={styles.link}
                eventKey="0"
                onClick={() => setOpen(!open)}
              >
                <StatsBox
                  name={emailTemplate.name}
                  openRate={emailTemplate.openRate ?? 0}
                  clickRate={emailTemplate.clickRate ?? 0}
                  leadRate={emailTemplate.leadRate ?? 0}
                  inUse={Moment(emailTemplate.inUse).format(dateFormat)}
                  totalSend={emailTemplate.totalSend ?? 0}
                  boxClassName={open ? styles.OpenedCard : ""}
                  status={emailTemplate.status ?? 0}
                  isAccordion
                  open={open}
                  onClick={() => props.editEmailTemplate()}
                  onPublishClick={changeTemplateStatusFromSequence}
                />
              </Accordion.Header>
            </Card>
            <Accordion.Body eventKey="0">
              <Card.Body id="templateBody" className={styles.accordionBody}>
                <div className={styles.border} />
                <p className="f14">
                  <b>Subject: </b>
                  {emailTemplate.subject}
                </p>
                <div dangerouslySetInnerHTML={{ __html: emailTemplate.html }}></div>
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }

  let list = rows.map((r, i) => {
    if (typeActiveStep === r.stage && r.id !== templateId) {
      return (
        <TemplateCard
          onClick={() =>
            handleAddEmailTemplateShow(
              r.id,
              props.activeStepId,
              r.text,
              r.name,
              r.html,
              r.stage,
              r.openRate,
              r.clickRate,
              r.leadRate,
              r.emailsDelivered,
              r.subject,
              r.dateCreated,
              r.status
            )
          }
          templateName={r.name}
          subject={r.subject}
          openRate={r.openRate}
          clickRate={r.clickRate}
          leadRate={r.leadRate}
          emailsDelivered={r.emailsDelivered}
          status={r.status}
          inUse={Moment(r.dateCreated).format(dateFormat)}
          key={i}
        />
      );
    }

    return null;
  });

  if (props.isLoaded) {
    let countFollowUpEmails = rows.filter((r) => r.stage === emailTemplateTypeEnum.FollowUp).length;
    list =
      list.length > 0 ? (
        countFollowUpEmails === 0 && typeActiveStep === emailTemplateTypeEnum.FollowUp ? (
          <EmptyState
            image={image}
            title="No follow up email template found"
            description='Let’s start with creating your first email template.
        Click on the "New Template" button.'
          />
        ) : (
          list
        )
      ) : (
        <EmptyState
          image={image}
          title="No Initial email template found"
          description='Let’s start with creating your first email template.
          Click on the "New Template" button.'
        />
      );
  }

  const templatesList = (
    <>
      <div className="d-flex align-center">
        <TableFiltersRow
          selects={[]}
          searchValue={param.search}
          placeholder="message templates"
          changeFilter={(text) => props.searchRecords(text)}
          headerSearchClass={`${styles.searchInputWrapper} m0`}
          classLeftWrapper="width_100 m0"
          headerClassName="width_100"
          delayedWrapperClass="width_100 mR5"
        />
        <span className="f14 pB10 mR10">or</span>
        <Button
          id="newTemplate"
          onClick={() => {
            props.setTemplateId(0);
            props.addEmailTemplate();
          }}
          classes={styles.createTemplateBtn}
          variant="secondary"
        >
          Create new template
        </Button>
      </div>
      <div id="templateListWrapper">{list}</div>
    </>
  );

  return (
    <div className={styles.templatePageWrapper}>
      <SectionTitle icon={<SectionCompany />} title="Templates" />
      <div className={styles.cardsWrapper}>
        {body}
        {emailTemplate && props.rows.some((x) => x.id === emailTemplate.id && x.stage === typeActiveStep) ? (
          <AccordionSimple className="mT10" header="Change to other template" defaultOpen={null} eventKey="0" body={templatesList} />
        ) : (
          templatesList
        )}
      </div>

      <AddEmailTemplateForm
        handleClose={handleAddEmailTemplateClose}
        showModal={showAddEmailTemplateModal}
        emailTemplateId={emailTemplateId}
        stepId={stepId}
        emailHtml={html}
        emailText={emailText}
        templateName={templateName}
        templateType={templateType}
        status={status}
        openRate={openRate}
        clickRate={clickRate}
        leadRate={leadRate}
        totalSend={totalSend}
        subject={subject}
        dateCreated={dateCreated}
        handleClick={() => handleClick(emailTemplateId, templateName, templateType, status, subject, html)}
      />
      <DraftEmailTemplateWarningModal
        showModal={showDraftEmailTemplateWarningModel}
        handleClose={() => setShowDraftEmailTemplateWarningModel(false)}
        handleSave={() => setShowDraftEmailTemplateWarningModel(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.emailTemplates.params,
    isInitiated: state.emailTemplates.isInitiated,
    isLoading: state.globalEmailTemplate.isLoading,
    isLoaded: state.globalEmailTemplate.isLoaded,
    error: state.globalEmailTemplate.error,
    rows: state.emailTemplates.rows,
    totalCount: state.emailTemplates.totalCount,
    pageCount: state.emailTemplates.pageCount,
    orgId: state.user.organization.id,
    emailTemplate: state.globalEmailTemplate.emailTemplate,
    activeStepId: state.workflowSteps.activeStepId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmailTemplates: (param) => dispatch(fetchEmailTemplates(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    fetchEmailTemplate: (data) => dispatch(fetchEmailTemplate(data)),
    setActivePage: (page) => dispatch(setActivePage(page)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
