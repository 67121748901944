export const CREATE_SENDING_SCHEDULE_REQUEST =
  "CREATE_SENDING_SCHEDULE_REQUEST";
export const CREATE_SENDING_SCHEDULE_SUCCEEDED =
  "CREATE_SENDING_SCHEDULE_SUCCEEDED";
export const CREATE_SENDING_SCHEDULE_FAILED = "CREATE_SENDING_SCHEDULE_FAILED";

export const FETCH_SENDING_SCHEDULE_REQUEST = "FETCH_SENDING_SCHEDULE_REQUEST";
export const FETCH_SENDING_SCHEDULE_SUCCEEDED =
  "FETCH_SENDING_SCHEDULE_SUCCEEDED";
export const FETCH_SENDING_SCHEDULE_FAILED = "FETCH_SENDING_SCHEDULE_FAILED";
