import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth, hasAuthParams } from "react-oidc-context";

//Helpers
import { organizationStatusEnum } from "../helper/enums/organizationStatus";
import { organizationRoleEnum } from "../helper/enums/organizationRoleEnum";
import { globalPermissionHelper } from "../helper/globalPermissionHelper";
import { GLOBAL_PERMISSIONS } from "../helper/globalUserRolePermissionsConstants";

//Components
import Loading from "../pages/Loading/Loading";
import Permission from "../components/auth/Permission";
import NoPermission from "../pages/NoPermission/NoPermission";
import NoPermissionUpdatePackage from "../pages/NoPermissionUpdatePackage/NoPermissionUpdatePackage";
import NoPermissionPricingPackageLink from "../pages/NoPermissionPricingPackageLink/NoPermissionPricingPackageLink";
import NoPermissionOrganizationAccessRemoved from "../pages/NoPermissionOrganizationAccessRemoved/NoPermissionOrganizationAccessRemoved";
import Login from "../components/auth/Login";

const PrivateRoute = ({ component, layout, permission, user, ...rest }) => {
  const auth = useAuth();

  const renderFn = (Component, Layout, Perm, User) => (props) => {
    if (!!Component && auth.isAuthenticated) {
      if (Perm) {
        let redirectUrl = "/dashboard";
        if (User?.organization) {
          redirectUrl += "/" + User.organization.id;

          let permission = (
            <Permission has={Perm} redirect redirectUrl={redirectUrl}>
              {React.createElement(Layout, props, React.createElement(component, props))}
            </Permission>
          );

          if (User.organization.organizationAccessRemoved === true) {
            if (globalPermissionHelper(User.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewDisabled) === false)
              return React.createElement(NoPermissionOrganizationAccessRemoved, props);
          }

          if (
            User.organization.organizationStatus === organizationStatusEnum.active ||
            User.organization.organizationStatus === organizationStatusEnum.pendingRenewal
          ) {
            return permission;
          } else if (User.organization.organizationStatus === organizationStatusEnum.inactive) {
            // Admins and Owners have access only to billing and payment
            // Outboss users will have access to all the functionalities
            // Viewer, Editor and Standard User do not have permission to enter the organization
            if (
              globalPermissionHelper(User.userPermissions, GLOBAL_PERMISSIONS.globalOrganizations.viewAll) ||
              globalPermissionHelper(User.userPermissions, GLOBAL_PERMISSIONS.outboss.view)
            ) {
              return permission;
            }
            if (
              !globalPermissionHelper(User.userPermissions, GLOBAL_PERMISSIONS.outboss.view) &&
              (User.organization.role === organizationRoleEnum.Admin || User.organization.role === organizationRoleEnum.Owner)
            ) {
              if (
                props.location.pathname === `/${User.organization.id}/organization-settings/billing-and-payment` ||
                props.location.pathname === `/${User.organization.id}/pricing`
              ) {
                return permission;
              } else {
                return React.createElement(NoPermissionPricingPackageLink, props);
              }
            } else {
              return React.createElement(NoPermissionUpdatePackage, props);
            }
          }
        }
      }

      // These are the paths that are allowed to be accessed without an organization
      let allowedPaths = ["/my-organizations", "/sandbox", "/register-organization", "/creating-organization"];

      if (
        !User?.adminOrganizationLoading &&
        props.history.action === "POP" &&
        User.organization == null &&
        !allowedPaths.includes(props.location.pathname) &&
        !props.location.pathname.startsWith("/outboss")
      ) {
        return React.createElement(NoPermission, props);
      }

      if (!User?.adminOrganizationLoading) return React.createElement(Layout, props, React.createElement(component, props));
    } else {
      return <Login />;
    }
  };

  return <Route {...rest} render={renderFn(component, layout, permission, user)} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
