import React from "react";
import { NavLink } from "react-router-dom";

//Style
import styles from "./SideMenuContent.module.scss";

const MenuSubItem = (props) => {
  return (
    <li
      id={"menuSubItem" + props.title.replaceAll(" ", "")}
      className={`${props.isMenuOpened(props.url) ? styles.menuDropdownLiActive : styles.menuDropdownLiNotActive}`}
    >
      <NavLink to={props.url}>{props.title}</NavLink>
    </li>
  );
};

export default MenuSubItem;
