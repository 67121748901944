import React, { FC, ReactNode } from "react";
import styles from "./ErrorPageComponent.module.scss";

//Components
import Button from "../../../../components/UI/Button/Button";

interface ErrorPageProps {
  image: ReactNode;
  title: string;
  subtitle?: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  error?: object | string;
  onDashboardBackButtonClick?: () => void;
  onReloadButtonClick?: () => void;
}

const ErrorPageComponent: FC<ErrorPageProps> = ({
  image,
  title,
  subtitle,
  subtitle1,
  subtitle2,
  subtitle3,
  error,
  onDashboardBackButtonClick = () => {},
  onReloadButtonClick = () => {},
}) => {
  return (
    <div className={styles.contentWrapper}>
      <div className={styles.imageWrapper}>{image}</div>
      <div className={styles.messageWrapper}>
        <h1 className={styles.title}>{title}</h1>
        {subtitle && <p className={`${styles.textBody} mB20`}>{subtitle}</p>}
        {subtitle1 && <p className={`${styles.textBody} mB20`}>{subtitle1}</p>}
        {subtitle2 && <p className={`${styles.textBody} mB20`}>{subtitle2}</p>}
        {subtitle3 && <p className={styles.textBody}>{subtitle3}</p>}

        {error && (
          <>
            <p className={`${styles.errorBody} mB20 font_italic`}>
              {error === null
                ? "An unknown error occurred"
                : typeof error === "object" && error !== null
                ? error.message || JSON.stringify(error)
                : error}
            </p>
          </>
        )}
        <div className="flex">
          <Button id="reload" variant="primary" onClick={onReloadButtonClick}>
            Reload
          </Button>
          <Button id="backToHome" variant="secondary" onClick={onDashboardBackButtonClick}>
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPageComponent;
