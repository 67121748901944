import React, { useContext } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAuth } from "react-oidc-context";

//Redux
import { changeUserInfoSuccess } from "../../store/global/actions/user";

//Helpers
import { errorResponseToList } from "../../helper/errorHelper";
import api, { API } from "../../helper/api/api";
import { toastMessages } from "../../helper/toastMessagesConstants";

//Components
import Modal from "../../components/UI/Modal/Modal";

const ChangeOrgPhotoForm = (props) => {
  const auth = useAuth();
  return (
    <Formik
      initialValues={{
        image: props.image,
      }}
      validationSchema={Yup.object({
        image: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        const formData = new FormData();
        formData.append("image", values.image);

        return api(API.organization.changeImage, formData)
          .then((res) => {
            auth.signinSilent();
            toast.success(toastMessages.settings.successChangeOrganizationPhoto);
            props.handleClose();
          })
          .catch((err) => {
            const errorList = errorResponseToList(err);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(err.message);
            }
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue } = formikProps;

        return (
          <Modal
            title="Change organization photo"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.show}
            closeButtonText="Cancel"
            saveButtonText="Save changes"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form>
              <input
                id="image"
                name="image"
                type="file"
                onChange={(event) => {
                  setFieldValue("image", event.currentTarget.files[0]);
                }}
                className="form-control"
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.user.organizationId,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserInfoSuccess: (userInfo) => dispatch(changeUserInfoSuccess(userInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOrgPhotoForm);
