import * as actionTypes from "../actions/actionTypes";

const initialState = {
  organizationReportIsLoading: false,
  organizationReportIsLoaded: false,
  industryReportIsLoading: false,
  industryReportIsLoaded: false,
  companySizeReportIsLoading: false,
  companySizeReportIsLoaded: false,
  stageReportIsLoading: false,
  stageReportIsLoaded: false,
  dayReportIsLoading: false,
  dayReportIsLoaded: false,
  monthReportIsLoading: false,
  monthReportIsLoaded: false,
  error: "",
  filter: [],
  search: "",
  organizationReport: {},
  organizationReportByDay: [],
  organizationReportByMonth: [],
  organizationReportByIndustry: [],
  organizationReportByCompanySize: [],
  organizationReportByStage: [],
  runRatesReport: [],
};

///////////////SET FILTER//////////////////////////////////
const setFilterReport = (state, action) => {
  const newState = { ...state };
  const newFilters = [...state.filter];
  let newReportByIndustry = [...state.organizationReportByIndustry];
  newReportByIndustry = [];
  let newReportByCompanySize = [...state.organizationReportByCompanySize];
  newReportByCompanySize = [];
  let newReportByStage = [...state.organizationReportByStage];
  newReportByStage = [];
  let newReportByMonth = [...state.organizationReportByMonth];
  newReportByMonth = [];
  let newReportByDay = [...state.organizationReportByDay];
  newReportByDay = [];
  for (let i = 0; i < newFilters.length; i++) {
    if (newFilters[i].type === action.filterType) {
      newFilters.splice(i, 1);
    }
  }
  newFilters.push({ type: action.filterType, value: action.filterValue });
  newState.filter = newFilters;
  newState.organizationReportByIndustry = newReportByIndustry;
  newState.organizationReportByCompanySize = newReportByCompanySize;
  newState.organizationReportByStage = newReportByStage;
  newState.organizationReportByMonth = newReportByMonth;
  newState.organizationReportByDay = newReportByDay;
  return newState;
};

/////CLEAR REPORTS AND FILTER//////////////////////////
const clearInitiatedReport = (state, action) => {
  const newState = { ...state };
  newState.organizationReportIsLoaded = false;
  newState.organizationReportIsLoading = false;
  newState.industryReportIsLoaded = false;
  newState.industryReportIsLoading = false;
  newState.companySizeReportIsLoaded = false;
  newState.companySizeReportIsLoading = false;
  newState.stageReportIsLoaded = false;
  newState.stageReportIsLoading = false;
  newState.dayReportIsLoaded = false;
  newState.dayReportIsLoading = false;

  newState.error = "";
  let newFilter = { ...state.filter };
  newFilter = [];
  newState.filter = newFilter;

  let newOrganizationReport = { ...state.organizationReport };
  newOrganizationReport = {};
  newState.organizationReport = newOrganizationReport;

  let newOrganizationReportByDay = [...state.organizationReportByDay];
  newOrganizationReportByDay = [];
  newState.organizationReportByDay = newOrganizationReportByDay;

  let newOrganizationReportByMonth = [...state.organizationReportByMonth];
  newOrganizationReportByMonth = [];
  newState.organizationReportByMonth = newOrganizationReportByMonth;

  let newOrganizationReportByIndustry = [...state.organizationReportByIndustry];
  newOrganizationReportByIndustry = [];
  newState.organizationReportByIndustry = newOrganizationReportByIndustry;

  let newOrganizationReportByCompanySize = [
    ...state.organizationReportByCompanySize,
  ];
  newOrganizationReportByCompanySize = [];
  newState.organizationReportByCompanySize = newOrganizationReportByCompanySize;

  let newOrganizationReportByStage = [...state.organizationReportByStage];
  newOrganizationReportByStage = [];
  newState.organizationReportByStage = newOrganizationReportByStage;

  return newState;
};

//////////////////////////////////////////////////////////////
const fetchOrganizationReportRequest = (state, action) => {
  return {
    ...state,
    organizationReportIsLoading: true,
  };
};

const fetchOrganizationReportSuccess = (state, action) => {
  const newState = { ...state };
  let newReport = { ...state.organizationReport };
  newReport = action.result;
  newState.organizationReportIsLoaded = true;
  newState.organizationReportIsLoading = false;
  newState.organizationReport = newReport;
  return newState;
};

const fetchOrganizationReportFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.organizationReportIsLoading = false;
  return newState;
};

///////////////BY DAY///////////////////////////////////////////////////////
const fetchOrganizationReportByDayRequest = (state, action) => {
  return {
    ...state,
    dayReportIsLoading: true,
    dayReportIsLoaded: false,
  };
};

const fetchOrganizationReportByDaySuccess = (state, action) => {
  const newState = { ...state };
  let newReport = [...state.organizationReportByDay];
  newReport = action.result;
  newState.dayReportIsLoaded = true;
  newState.dayReportIsLoading = false;
  newState.organizationReportByDay = newReport;
  return newState;
};

const fetchOrganizationReportByDayFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.dayReportIsLoading = false;
  return newState;
};

///////////////BY MONTH///////////////////////////////////////////////////////
const fetchOrganizationReportByMonthRequest = (state, action) => {
  return {
    ...state,
    monthReportIsLoading: true,
    monthReportIsLoaded: false,
  };
};

const fetchOrganizationReportByMonthSuccess = (state, action) => {
  const newState = { ...state };
  let newReport = [...state.organizationReportByMonth];
  newReport = action.result;
  newState.monthReportIsLoaded = true;
  newState.monthReportIsLoading = false;
  newState.organizationReportByMonth = newReport;
  return newState;
};

const fetchOrganizationReportByMonthFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.monthReportIsLoading = false;
  return newState;
};

///////////////BY INDUSTRY///////////////////////////////////////////////////////
const fetchOrganizationReportByIndustryRequest = (state, action) => {
  return {
    ...state,
    industryReportIsLoading: true,
    industryReportIsLoaded: false,
  };
};

const fetchOrganizationReportByIndustrySuccess = (state, action) => {
  const newState = { ...state };
  let newReport = [...state.organizationReportByIndustry];
  newReport = action.result;
  newState.industryReportIsLoaded = true;
  newState.industryReportIsLoading = false;
  newState.organizationReportByIndustry = newReport;
  return newState;
};

const fetchOrganizationReportByIndustryFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.industryReportIsLoading = false;
  return newState;
};

///////////////BY COMPANY SIZE///////////////////////////////////////////////////////
const fetchOrganizationReportByCompanySizeRequest = (state, action) => {
  return {
    ...state,
    companySizeReportIsLoading: true,
    companySizeReportIsLoaded: false,
  };
};

const fetchOrganizationReportByCompanySizeSuccess = (state, action) => {
  const newState = { ...state };
  let newReport = [...state.organizationReportByCompanySize];
  newReport = action.result;
  newState.companySizeReportIsLoaded = true;
  newState.companySizeReportIsLoading = false;
  newState.organizationReportByCompanySize = newReport;
  return newState;
};

const fetchOrganizationReportByCompanySizeFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.companySizeReportIsLoading = false;
  return newState;
};

///////////////BY STAGE///////////////////////////////////////////////////////
const fetchOrganizationReportByStageRequest = (state, action) => {
  return {
    ...state,
    stageReportIsLoading: true,
    stageReportIsLoaded: false,
  };
};

const fetchOrganizationReportByStageSuccess = (state, action) => {
  const newState = { ...state };
  let newReport = [...state.organizationReportByStage];
  newReport = action.result;
  newState.stageReportIsLoaded = true;
  newState.stageReportIsLoading = false;
  newState.organizationReportByStage = newReport;
  return newState;
};

const fetchOrganizationReportByStageFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.stageReportIsLoading = false;
  return newState;
};

///RUN RATES///////////////////////////
const fetchRunRatesReportRequest = (state, action) => {
  return {
    ...state,
    organizationReportIsLoading: true,
  };
};

const fetchRunRatesReportSuccess = (state, action) => {
  const newState = { ...state };
  let newReport = { ...state.runRatesReport };
  newReport = action.result;
  newState.organizationReportIsLoaded = true;
  newState.organizationReportIsLoading = false;
  newState.runRatesReport = newReport;
  return newState;
};

const fetchRunRatesReportFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.organizationReportIsLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_REPORT:
      return setFilterReport(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_REQUEST:
      return fetchOrganizationReportRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_SUCCEEDED:
      return fetchOrganizationReportSuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_FAILED:
      return fetchOrganizationReportFailed(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_DAY_REQUEST:
      return fetchOrganizationReportByDayRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_DAY_SUCCEEDED:
      return fetchOrganizationReportByDaySuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_DAY_FAILED:
      return fetchOrganizationReportByDayFailed(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_MONTH_REQUEST:
      return fetchOrganizationReportByMonthRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_MONTH_SUCCEEDED:
      return fetchOrganizationReportByMonthSuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_MONTH_FAILED:
      return fetchOrganizationReportByMonthFailed(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_REQUEST:
      return fetchOrganizationReportByIndustryRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_SUCCEEDED:
      return fetchOrganizationReportByIndustrySuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_INDUSTRY_FAILED:
      return fetchOrganizationReportByIndustryFailed(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_REQUEST:
      return fetchOrganizationReportByCompanySizeRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_SUCCEEDED:
      return fetchOrganizationReportByCompanySizeSuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_COMPANY_SIZE_FAILED:
      return fetchOrganizationReportByCompanySizeFailed(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_STAGE_REQUEST:
      return fetchOrganizationReportByStageRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_STAGE_SUCCEEDED:
      return fetchOrganizationReportByStageSuccess(state, action);
    case actionTypes.FETCH_ORGANIZATION_REPORT_BY_STAGE_FAILED:
      return fetchOrganizationReportByStageFailed(state, action);
    case actionTypes.FETCH_RUN_RATES_REPORT_REQUEST:
      return fetchRunRatesReportRequest(state, action);
    case actionTypes.FETCH_RUN_RATES_REPORT_SUCCEEDED:
      return fetchRunRatesReportSuccess(state, action);
    case actionTypes.FETCH_RUN_RATES_REPORT_FAILED:
      return fetchRunRatesReportFailed(state, action);
    case actionTypes.CLEAR_INITIATED_REPORT:
      return clearInitiatedReport(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
