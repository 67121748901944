import React, { useState } from "react";
import { DropdownButton } from "react-bootstrap";
import { Formik, FormikProps, FormikHelpers } from "formik";
import * as Yup from "yup";

//Styles
import "./FormikRangeFilter.scss";

//Components
import Button from "../../Button/Button";
import IconConvert from "../../../../assets/Icons/IconConvert/IconConvert";
import FormikInput from "../FormikInput";
import { filterData } from "@helper/tableConstants";

interface FormikRangeFilterProps {
  field: string;
  setFilter: Function;
  title: string;
}

interface FormValues {
  from: number;
  to: number;
}

const FormikRangeFilter: React.FC<FormikRangeFilterProps> = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownToggle = (newValue: boolean) => {
    setShowDropdown(newValue);
  };

  const clearFilter = (resetForm: Function) => {
    resetForm();
    setShowDropdown(false);
    filterData(props.field, [0, 0], props.setFilter);
  };

  return (
    <Formik
      initialValues={{
        from: 0,
        to: 0,
      }}
      validationSchema={Yup.object({
        from: Yup.number().required("Required"),
        to: Yup.number().required("Required"),
      })}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        let value = [values.from, values.to];
        filterData(props.field, value, props.setFilter);
      }}
    >
      {(formikProps: FormikProps<FormValues>) => {
        const { values, handleSubmit, resetForm } = formikProps;
        return (
          <DropdownButton
            title={values.to > 0 ? values.from + " - " + values.to : props.title}
            onToggle={dropdownToggle}
            show={showDropdown}
            className="customButtonStyle"
          >
            <div className="contentWrapper">
              <div className="flex flex_center">
                <div className="flex flex_column formGroup">
                  <FormikInput
                    type="number"
                    label="From:"
                    name="from"
                    value={values.from}
                    placeholder="0"
                    isMenuPortalTargetDisabled={true}
                    inputclass="setValueInput"
                    inputGroupClass="formGroupClass"
                  />
                </div>
                <IconConvert imgStye="convertIcon" />
                <div className="flex flex_column formGroup">
                  <FormikInput
                    type="number"
                    label="To:"
                    name="to"
                    value={values.to}
                    placeholder="0"
                    isMenuPortalTargetDisabled={true}
                    inputclass="setValueInput"
                    inputGroupClass="formGroupClass"
                  />
                </div>
              </div>
              <p className="clearFilter" onClick={() => clearFilter(resetForm)}>
                Clear filters
              </p>
            </div>
            <div className="dropdownButtonsRow flex">
              <Button id="cancelButton" classes="cancelButton" onClick={() => setShowDropdown(false)}>
                Cancel
              </Button>
              <Button id="applyButton" classes="applyButton" onClick={() => handleSubmit()}>
                Apply
              </Button>
            </div>
          </DropdownButton>
        );
      }}
    </Formik>
  );
};

export default FormikRangeFilter;
