import React, { useState } from "react";
import { Formik, Form } from "formik";

//Hooks
import { useGetCompanyLocationsAutoCompleteQuery, useGetCompanyLocationsRangeAutoCompleteQuery } from "@api/audienceExplorerApi";

//Styles
import styles from "./SelectCountryLocationStep.module.scss";

//Models
import { CampaignObjectModel, LocationType } from "@models/assistant/AssistantModels";
import { locationRangeOptions } from "@helper/enums/locationRangeOptionsEnum";

//Components
import FormikSelect from "@components/UI/Formik/FormikSelect";
import Spinner from "@components/UI/Spinner/Spinner";
import Button from "@ui/Button/Button";

interface SelectCountryLocationStepProps {
  title?: string;
  description?: React.ReactNode;
  organizationSettings: CampaignObjectModel;
  setCampaignObject: (obj: any) => void;
  listCountries: any[];
  setCreateSearchKey: (key: boolean) => void;
}

const SelectCountryLocationStep: React.FC<SelectCountryLocationStepProps> = ({
  title = "Which location do you primarily target?",
  description = (
    <>
      Start by selecting the type of location you want to target for your contacts and companies.
      <br />
      Please note that you can only choose one option: countries, specific regions, or postal/zip codes.
    </>
  ),
  organizationSettings,
  setCampaignObject,
  listCountries,
  setCreateSearchKey,
}) => {
  const [locationTypeSelected, setLocationTypeSelected] = useState(organizationSettings.settings.locationType);
  const [searchInput, setSearchInput] = useState("");

  const { data: options } = useGetCompanyLocationsAutoCompleteQuery(searchInput, { skip: searchInput.length < 3 });
  const { data: optionsRange } = useGetCompanyLocationsRangeAutoCompleteQuery(searchInput, { skip: searchInput.length < 3 });

  const handleOnChange = (values: any) => {
    setCreateSearchKey(true);
    if (locationTypeSelected === LocationType.Country) {
      setCampaignObject({
        ...organizationSettings,
        audience: {
          ...organizationSettings.audience,
          companyLocations: {
            ...organizationSettings.audience.companyLocations,
            country: [values],
          },
          contactLocations: {
            ...organizationSettings.audience.contactLocations,
            country: [values],
          },
        },
      });
    } else if (locationTypeSelected === LocationType.Region) {
      setCampaignObject({
        ...organizationSettings,
        audience: {
          ...organizationSettings.audience,
          companyLocations: {
            ...organizationSettings.audience.companyLocations,
            region: [...values],
          },
          contactLocations: {
            ...organizationSettings.audience.contactLocations,
            region: [...values],
          },
        },
      });
    } else if (locationTypeSelected === LocationType.ZipCode) {
    }
  };

  const handleOnChangeRangeLocation = (values: any) => {
    setCreateSearchKey(true);
    if (locationTypeSelected === LocationType.ZipCode) {
      setCampaignObject({
        ...organizationSettings,
        audience: {
          ...organizationSettings.audience,
          range: {
            location: { ...values },
            rangeMiles: organizationSettings?.audience?.range?.rangeMiles ?? 0,
          },
        },
      });
    }
  };

  const handleOnChangeRangeMiles = (value: any) => {
    setCreateSearchKey(true);
    if (locationTypeSelected === LocationType.ZipCode) {
      setCampaignObject({
        ...organizationSettings,
        audience: {
          ...organizationSettings.audience,
          range: {
            location: { ...organizationSettings.audience.range.location },
            rangeMiles: value,
          },
        },
      });
    }
  };

  const handleChangeLocationType = (locationType: LocationType) => {
    setLocationTypeSelected(locationType);
    setCampaignObject({
      ...organizationSettings,
      settings: {
        ...organizationSettings.settings,
        locationType: locationType,
      },
    });
  };

  const renderButtons = (
    <div className="flex_center">
      <Button
        id="locationTypeCountry"
        onClick={() => handleChangeLocationType(LocationType.Country)}
        value={LocationType.Country}
        variant="onBoardingLocationsBtn"
        classes={`flex_column align-center justify_start ${styles.autoButton} ${
          locationTypeSelected == LocationType.Country ? styles.active : ""
        } ${styles.country}`}
      >
        <p className={`f14 m0 font500 ${styles.title}`}>Country</p>
      </Button>
      <Button
        id="locationTypeRegion"
        onClick={() => handleChangeLocationType(LocationType.Region)}
        value={LocationType.Region}
        variant="onBoardingLocationsBtn"
        classes={`flex_column align-center justify_start ${styles.autoButton} ${
          locationTypeSelected == LocationType.Region ? styles.active : ""
        } ${styles.medium_company}`}
      >
        <p className={`f14 m0 font500 ${styles.title}`}>Region</p>
      </Button>
      <Button
        id="locationTypeZip"
        onClick={() => handleChangeLocationType(LocationType.ZipCode)}
        value={LocationType.ZipCode}
        variant="onBoardingLocationsBtn"
        classes={`flex_column align-center justify_start ${styles.autoButton} ${
          locationTypeSelected == LocationType.ZipCode ? styles.active : ""
        } ${styles.large_company}`}
      >
        <p className={`f14 m0 font500 ${styles.title}`}>Zip/Postal Code</p>
      </Button>
    </div>
  );

  const selectByCountryForm = (values: any) => (
    <Form>
      <div className={`flex_center flexCol ${styles.locations}`}>
        <h1 className="f16 font700 mB15">{title}</h1>
        <p className="f14 secondary_text_color textCenter">{description}</p>
        {renderButtons}
        <p className={styles.optionDescription}>
          When targeting countries, we recommend starting with a single location. You can always add more countries later if needed. If you
          are targeting multiple locations, ensure that all targeted countries are in the same or nearby time zone.
        </p>
        <FormikSelect
          enableReinitialize
          isSearchable
          getMenuListStyles
          isMenuPortalTargetDisabled
          label="Select Country"
          displayName="Select Country"
          placeholder="e.g. United Kingdom"
          name="Locations"
          id="countries"
          options={listCountries.slice(1)}
          value={values}
          onChange={handleOnChange}
          formgroupClass={styles.formLocation}
        />
      </div>
    </Form>
  );

  const selectByRegionForm = (values: any) => (
    <Form>
      <div className={`flex_center flexCol ${styles.locations}`}>
        <h1 className="f16 font700 mB15">{title}</h1>
        <p className="f14 secondary_text_color textCenter">{description}</p>
        {renderButtons}
        <p className={styles.optionDescription}>
          To target specific regions, you can select multiple locations within the same country, like London and Manchester, for a more
          tailored approach.
        </p>
        <FormikSelect
          enableReinitialize
          isAutoComplete
          isMenuPortalTargetDisabled
          isMulti
          hideNoOptions
          isSearchable
          showValuesOutside
          requireInputToShowOptions
          name="regions"
          id="regions"
          value={values}
          options={options}
          displayName="Select region"
          placeholder="e.g. England, United Kingdom"
          onInputChange={(input: string) => setSearchInput(input)}
          onChange={handleOnChange}
          dropdownButtonClass={styles.selectDropdown}
          selectClass={styles.selectDropdown}
          formgroupClass={styles.formLocation}
        />
      </div>
    </Form>
  );

  const selectByZipCodeForm = (values: any) => (
    <Form>
      <div className={`flex_center flexCol ${styles.locations}`}>
        <h1 className="f16 font700 mB15">{title}</h1>
        <p className="f14 secondary_text_color textCenter">{description}</p>
        {renderButtons}
        <p className={styles.optionDescription}>
          For even more specific targeting, select locations by zip/postal code. This is ideal for focusing on particular neighborhoods or
          areas within a city for highly tailored outreach.
        </p>
        <FormikSelect
          enableReinitialize
          hideNoOptions
          isAutoComplete
          isSearchable
          requireInputToShowOptions
          formgroupClass={styles.formLocation}
          id="zipCode"
          name="zipCode"
          options={optionsRange}
          placeholder={"e.g. BN2 1PJ, Brighton and Hove, England, United Kingdom"}
          onInputChange={(input: string) => setSearchInput(input)}
          onChange={handleOnChangeRangeLocation}
        />
        <FormikSelect
          formgroupClass={styles.formLocation}
          name="contact.location.range.rangeMiles"
          id="prospectRangeDropdown"
          placeholder="e.g. Within: 5mi (8km)"
          isSearchable
          allowCustomValues
          options={locationRangeOptions}
          enableReinitialize
          onChange={(value: number) => {
            // Only setting when > 0, because if we select "Specify radius" the value is 0 and that should display the other input
            if (value > 0) {
              handleOnChangeRangeMiles(value);
            }
          }}
        />
      </div>
    </Form>
  );

  return listCountries ? (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          locationsByCountry:
            organizationSettings.audience?.companyLocations?.country?.length > 0
              ? organizationSettings.audience?.companyLocations?.country[0]
              : [],
          locationsByRegion:
            organizationSettings.audience?.companyLocations?.region?.length > 0
              ? organizationSettings.audience?.companyLocations?.region.map((item) => {
                  return { label: item.label, value: item.value };
                })
              : [],
          locationsByZip:
            organizationSettings.audience?.companyLocations?.zipCode?.length > 0
              ? organizationSettings.audience?.companyLocations?.zipCode
              : [],
          rangeMiles: -1,
        }}
      >
        {(formikProps) => {
          const { values } = formikProps;
          switch (locationTypeSelected) {
            case LocationType.Country:
              return selectByCountryForm(values.locationsByCountry);
            case LocationType.Region:
              return selectByRegionForm(values.locationsByRegion);
            case LocationType.ZipCode:
              return selectByZipCodeForm(values.locationsByZip);
            default:
              return selectByCountryForm(values.locationsByCountry);
          }
        }}
      </Formik>
    </>
  ) : (
    <Spinner />
  );
};

export default SelectCountryLocationStep;
