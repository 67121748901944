import React from "react";
import _ from "lodash";

//Styles
import styles from "./ProspectInfoCard.module.scss";

const ProspectInfoCard = (props) => {
  let image,
    name,
    company,
    jobTitle,
    email = null;

  if (!_.isEmpty(props.image)) image = props.image;
  if (!_.isEmpty(props.name)) name = props.name;
  if (!_.isEmpty(props.company)) company = props.company;
  if (!_.isEmpty(props.jobTitle)) jobTitle = props.jobTitle;
  if (!_.isEmpty(props.email)) email = props.email;

  return (
    <div className={`${styles.cardWrapper} flex`}>
      <div className={styles.imageWrapper}>{image}</div>
      <div className={`${styles.infoWrapper} text-overflow-hidden`}>
        <p className={styles.name}>{name}</p>
        <p className={styles.email}>{email}</p>
        <p className={styles.title}>
          <span>{company}</span>
          {jobTitle}
        </p>
      </div>
    </div>
  );
};

export default ProspectInfoCard;
