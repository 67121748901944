import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const fetchConversationDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_CONVERSATION_PREVIEW_REQUEST,
  };
};

export const fetchConversationDetailsSuccess = (conversation) => {
  return {
    type: actionTypes.FETCH_CONVERSATION_PREVIEW_SUCCEEDED,
    result: conversation,
  };
};

export const fetchConversationDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_CONVERSATION_PREVIEW_FAILED,
    result: error,
  };
};

export const fetchConversationDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchConversationDetailsRequest());
    return api(formUrl(API.conversation.getConversationDetails, { id: id }))
      .then((response) => {
        dispatch(fetchConversationDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchConversationDetailsFailed(error.message));
      });
  };
};

export const fetchProspectDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_PROSPECT_DETAILS_REQUEST,
  };
};

export const fetchProspectDetailsSuccess = (responseDetails) => {
  return {
    type: actionTypes.FETCH_PROSPECT_DETAILS_SUCCEEDED,
    result: responseDetails,
  };
};

export const fetchProspectDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_PROSPECT_DETAILS_FAILED,
    result: error,
  };
};

export const fetchProspectDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchProspectDetailsRequest());
    return api(formUrl(API.conversation.getProspectDetails, { id: id }))
      .then((response) => {
        dispatch(fetchProspectDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProspectDetailsFailed(error.message));
      });
  };
};
