import React from "react";
import { Link } from "react-router-dom";

//Styles
import styles from "./Landing.module.scss";

//Components
import Button from "../../components/UI/Button/Button";

const Landing = (props) => {
  return (
    <div className={styles.landing}>
      <h1>Welcome to Outbase</h1>
      <Link
        to={{
          pathname: "/login",
          state: { redirectUri: props.location.pathname },
        }}
      >
        <Button id="loginBtn" classes={styles.landing} variant="white">
          Login
        </Button>
      </Link>
    </div>
  );
};

export default Landing;
