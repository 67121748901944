import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  listEmailAcconts: [],
  selectListEmailAccounts: [],
  listOfEmailAccountsMultiCampaigns: [],
};
/////////////////////////////////////////
const fetchEmailAccountsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchEmailAccountsListSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listEmailAcconts };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listEmailAcconts = list;
  return newState;
};

const fetchEmailAccountsListFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const fetchListEmailAccountsMultiCampaignsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListEmailAccountsMultiCampaignsSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listOfEmailAccountsMultiCampaigns };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listOfEmailAccountsMultiCampaigns = list;
  return newState;
};

const fetchListEmailAccountsMultiCampaignsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  newState.isLoaded = true;
  return newState;
};
////////////////////////////////////

const connectEmailAccountsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const connectEmailAccountsSuccess = (state, action) => {
  const newState = { ...state };
  // let list = { ...state.listEmailAcconts }
  // list = action.result
  newState.isLoaded = true;
  newState.isLoading = false;
  // newState.listCampaigns = list;
  return newState;
};

const connectEmailAccountsFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};
////////////////////////////////////////////////////////////

const fetchEmailAccountsSelectListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchEmailAccountsSelectListSuccess = (state, action) => {
  const newState = { ...state };
  let list = [...state.selectListEmailAccounts];
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.selectListEmailAccounts = list;
  return newState;
};

const fetchEmailAccountsSelectListFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const clearInitiated = (state, action) => {
  const newState = { ...state };
  newState.listEmailAcconts = [];
  newState.isLoaded = false;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMAIL_ACCOUNTS_LIST_REQUEST:
      return fetchEmailAccountsListRequest(state, action);
    case actionTypes.FETCH_EMAIL_ACCOUNTS_LIST_SUCCEEDED:
      return fetchEmailAccountsListSuccess(state, action);
    case actionTypes.FETCH_EMAIL_ACCOUNTS_LIST_FAILED:
      return fetchEmailAccountsListFailed(state, action);
    case actionTypes.FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_REQUEST:
      return fetchListEmailAccountsMultiCampaignsRequest(state, action);
    case actionTypes.FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_SUCCEEDED:
      return fetchListEmailAccountsMultiCampaignsSuccess(state, action);
    case actionTypes.FETCH_LIST_EMAIL_ACCOUNTS_MULTI_CAMPAIGNS_FAILED:
      return fetchListEmailAccountsMultiCampaignsFailed(state, action);
    case actionTypes.CONNECT_EMAIL_ACCOUNTS_REQUEST:
      return connectEmailAccountsRequest(state, action);
    case actionTypes.CONNECT_EMAIL_ACCOUNTS_SUCCEEDED:
      return connectEmailAccountsSuccess(state, action);
    case actionTypes.CONNECT_EMAIL_ACCOUNTS_FAILED:
      return connectEmailAccountsFailed(state, action);
    case actionTypes.FETCH_EMAIL_ACCOUNTS_SELECT_LIST_REQUEST:
      return fetchEmailAccountsSelectListRequest(state, action);
    case actionTypes.FETCH_EMAIL_ACCOUNTS_SELECT_LIST_SUCCEEDED:
      return fetchEmailAccountsSelectListSuccess(state, action);
    case actionTypes.FETCH_EMAIL_ACCOUNTS_SELECT_LIST_FAILED:
      return fetchEmailAccountsSelectListFailed(state, action);
    case actionTypes.EMAIL_ACCOUNTS_CLEAR_INITIATED:
      return clearInitiated(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
