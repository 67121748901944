import * as actionTypes from "./actionTypes";

export const signalReceived = (signalName, data) => {
  const signal = {
    name: signalName,
    time: new Date(),
    data: data
  };

  return {
    type: actionTypes.SIGNAL_RECEIVED,
    result: signal,
  };
};