import React from "react";
import _ from "lodash";

// Style
import styles from "./TableDeliveredToData.module.scss";

const TableDeliveredToData = (props) => {
  const { data, totalPerProvider } = props;

  let inbox = data.find((type) => type.key === "Inbox");
  let spam = data.find((type) => type.key === "Spam");
  let tabs = data.find((type) => type.key === "Tabs");
  let missing = data.find((type) => type.key === "Missing");

  let inboxValue = !_.isEmpty(inbox) ? inbox.value : 0;
  let spamValue = !_.isEmpty(spam) ? spam.value : 0;
  let tabsValue = !_.isEmpty(tabs) ? tabs.value : 0;
  let missingValue = !_.isEmpty(missing) ? missing.value : 0;

  return (
    <div className={styles.providerMessagesWrapper}>
      <div className={`${styles.success} ${styles.pill}`}>
        <span>Inbox</span>{" "}
        <span>{Math.round((inboxValue / totalPerProvider) * 100)}%</span>
      </div>
      <div className={`${styles.warning} ${styles.pill}`}>
        <span>Tabs</span>{" "}
        <span>{Math.round((tabsValue / totalPerProvider) * 100)}%</span>
      </div>
      <div className={`${styles.danger} ${styles.pill}`}>
        <span>Spam</span>{" "}
        <span>{Math.round((spamValue / totalPerProvider) * 100)}%</span>
      </div>
      <div className={`${styles.secondary} ${styles.pill}`}>
        <span>Missing</span>{" "}
        <span>{Math.round((missingValue / totalPerProvider) * 100)}%</span>
      </div>
    </div>
  );
};

export default TableDeliveredToData;
