import SignalRContext from "@helper/SignalRContext";
import { useEffect } from "react";
import { useContext } from "react";

const useSignalRHook = (eventName: string, callback: (args: unknown) => void, deps: unknown[] = []) => {
  const signalRConnection = useContext(SignalRContext);

  useEffect(() => {
    if (signalRConnection != null) {
      signalRConnection.on(eventName, callback);

      // Clean up: remove the event listener when the component unmounts
      return () => {
        signalRConnection.off(eventName, callback);
      };
    }
  }, [signalRConnection, eventName, callback, ...deps]); // Re-run the effect if any of these values change
};

export default useSignalRHook;
