import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

//Redux
import { fetchBillingInfo } from "../../../../../store/BillingAndPayment/actions/billingAndPayment";

//API
import api, { API, formUrl } from "../../../../../helper/api/api";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { PERMISSIONS } from "../../../../../helper/permissionConstants";

//Styles
import styles from "./PaymentMethod.module.scss";

//Images & Icons
import AddLineIcon from "remixicon-react/AddLineIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";

//Components
import Permission from "../../../../../components/auth/Permission";
import InfoIconTooltip from "../../../../../components/UI/CustomTooltip/InfoIconTooltip";
import SectionTitle from "../../../../../components/UI/SectionTitle/SectionTitle";
import Button from "../../../../../components/UI/Button/Button";
import BillingAddressModal from "../BillingAddressModal/BillingAddressModal";
import AddPaymentModal from "../AddPaymentModal/AddPaymentModal";
import DeleteBillingAddressModal from "../DeleteBillingAddressModal/DeleteBilingAddressModal";
import AddCreditCardModalWarning from "../AddCreditCardModalWarning/AddCreditCardModalWarning";

const PaymentMethod = (props) => {
  const { fetchBillingInfo, billingInfo, creditCardInfo } = props;
  const [getToken, setGetToken] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  const [showAddCreditCardModal, setShowAddCreditCardModal] = useState(false);
  const handleAddCreditCardModalClose = () => {
    setShowAddCreditCardModal(false);
  };

  const [showAddCreditCardModalWarning, setShowAddCreditCardModalWarning] = useState(false);

  const handleAddCreditCardModalCloseWarning = () => {
    setShowAddCreditCardModalWarning(false);
  };

  const handleAddCreditCardModalCloseBilling = () => {
    setShowAddCreditCardModalWarning(false);
    setShowBillingAddressModal(true);
  };

  const handleAddCreditCardShow = () => {
    if (billingInfo != null) {
      setShowAddCreditCardModal(true);
    } else {
      setShowAddCreditCardModalWarning(true);
    }
  };

  const [showAddBillingAddressModal, setShowBillingAddressModal] = useState(false);
  const handleBillingAddressModalClose = () => {
    setShowBillingAddressModal(false);
  };
  const handleAddBillingAddressShow = () => setShowBillingAddressModal(true);

  const [showDeleteBillingAddressModal, setDeleteBillingAddressModal] = useState(false);
  const handleDeleteBillingAddressModalClose = () => {
    setDeleteBillingAddressModal(false);
  };
  const handleDeleteBillingAddressShow = () => setDeleteBillingAddressModal(true);

  const handleCreateUpdateBillingInfo = () => {
    fetchBillingInfo();
    setShowBillingAddressModal(false);
  };

  const handleDeleteBillingInfo = (billingInfoId) => {
    fetchBillingInfo();
    setDeleteBillingAddressModal(false);
  };

  const handleSaveCreditCardModal = () => {
    setGetToken(true);
  };

  const handleReturnToken = (val) => {
    if (typeof val !== "object") {
      setSubmitting(true);
      api(formUrl(API.billingAndPayment.createUpdateCreditCard, { token: val }))
        .then((res) => {
          fetchBillingInfo();
          setShowAddCreditCardModal(false);
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          const errorList = errorResponseToList(error);
          if (errorList.length > 0) {
            errorList.forEach((err) => toast.error(err.message));
          } else {
            toast.error(error.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
          //actions.setSubmitting(false);
        });
    } else {
      let text = JSON.stringify(val);
      api(API.billingAndPayment.logPaymentError, { error: text })
        .then((res) => {})
        .catch((error) => {})
        .finally(() => {});
      if (val.message != null) {
        toast.error(val?.message);
      } else {
        toast.error(val?.errors);
      }
    }
  };

  const icon = <InfoIconTooltip tooltipBody={"To edit this info, you have to enter Billing address first."} disableStartTour={true} />;

  return (
    <div className={styles.componentWrapper}>
      <SectionTitle title="Payment Method" rightContent={icon} rightContentClass="width_auto" />
      <div className={styles.addDetailsWrapper}>
        <>
          {creditCardInfo ? (
            <>
              {" "}
              <span className={styles.address}>{creditCardInfo?.number}</span>
              <Permission has={PERMISSIONS.billingPayment.create}>
                <div className={styles.actionButtons}>
                  <OverlayTrigger
                    key={"editBillingMethod"}
                    placement={"top"}
                    overlay={
                      <Popover id={`tooltip-active`}>
                        <Popover.Body>You can change your Payment Details here for future payments</Popover.Body>
                      </Popover>
                    }
                  >
                    <div>
                      <Button id="editPaymentBtn" variant="rounded-white-m" classes={`${styles.editButton} p0 mR10`}>
                        <Edit2LineIcon size={16} className={`iconGray ${styles.iconEdit}`} onClick={handleAddCreditCardShow} />
                      </Button>
                    </div>
                  </OverlayTrigger>
                  {/* <Button variant="rounded-white-m">
                            <Delete click={handleAddCreditCardShow} />
                        </Button> */}
                </div>
              </Permission>
            </>
          ) : (
            <Permission has={PERMISSIONS.billingPayment.create}>
              <Button
                id="addPaymentBtn"
                ariaLabel="Add credit card"
                variant="rounded-s"
                classes={styles.addButton}
                onClick={handleAddCreditCardShow}
              >
                <AddLineIcon className={styles.addButtonIcon} />
              </Button>
              <p className={styles.buttonText}>Add Credit Card</p>
            </Permission>
          )}
          {props.isLoaded ? (
            <AddPaymentModal
              handleClose={handleAddCreditCardModalClose}
              showModal={showAddCreditCardModal}
              handleSave={handleSaveCreditCardModal}
              handleReturnToken={handleReturnToken}
              setGetToken={setGetToken}
              getToken={getToken}
              creditCardInfo={creditCardInfo}
              submitting={submitting}
            />
          ) : null}
          <BillingAddressModal
            handleClose={handleBillingAddressModalClose}
            showModal={showAddBillingAddressModal}
            billingInfo={billingInfo}
            handleCreateUpdate={handleCreateUpdateBillingInfo}
          />
          <AddCreditCardModalWarning
            handleClose={handleAddCreditCardModalCloseWarning}
            showModal={showAddCreditCardModalWarning}
            handleSave={handleAddCreditCardModalCloseBilling}
          />

          <DeleteBillingAddressModal
            handleClose={handleDeleteBillingAddressModalClose}
            showModal={showDeleteBillingAddressModal}
            handleDeleteBillingInfo={handleDeleteBillingInfo}
            billingInfoId={billingInfo?.id}
          />
        </>
      </div>
      <p className={styles.addressLabel}>Billing Address</p>
      <div className={styles.addDetailsWrapper}>
        <>
          {billingInfo ? (
            <>
              {" "}
              <span className={styles.address}>
                {billingInfo?.address}, {billingInfo?.city}, {billingInfo?.country}
              </span>
              <Permission has={PERMISSIONS.billingPayment.create}>
                <div className={styles.actionButtons}>
                  <OverlayTrigger
                    key={"editBillingAddress"}
                    placement={"top"}
                    overlay={
                      <Popover id={"tooltip-active-1"}>
                        <Popover.Body>You can update your Billing Info here to ensure accurate invoicing</Popover.Body>
                      </Popover>
                    }
                  >
                    <div>
                      <Button id="editPaymentButton" variant="rounded-white-m" classes="mR10 p0">
                        <Edit2LineIcon size={16} className={`iconGray ${styles.iconEdit}`} onClick={handleAddBillingAddressShow} />
                      </Button>
                    </div>
                  </OverlayTrigger>
                  {/* <Button variant="rounded-white-m">
                                    <Delete click={handleDeleteBillingAddressShow} />
                                </Button> */}
                </div>
              </Permission>
            </>
          ) : (
            <Permission has={PERMISSIONS.billingPayment.create}>
              <Button
                id="addPaymentMethod"
                ariaLabel="Add billing address"
                variant="rounded-s"
                classes={styles.addButton}
                onClick={handleAddBillingAddressShow}
              >
                <AddLineIcon className={styles.addButtonIcon} />
              </Button>
              <p className={styles.buttonText}>Add billing Address</p>
            </Permission>
          )}
        </>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    organizationName: state.user.organization.name,
    isLoading: state.billingAndPayment.isLoading,
    isLoaded: state.billingAndPayment.isLoaded,
    billingInfo: state.billingAndPayment.billingInfo,
    creditCardInfo: state.billingAndPayment.creditCardInfo,
    selectListCountry: state.billingAndPayment.selectListCountryWithStates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBillingInfo: () => dispatch(fetchBillingInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
