import React from "react";
import { Dropdown } from "react-bootstrap";

const DropdownItem = (props) => {
  return (
    <Dropdown.Item onClick={props.onClick} className={props.itemStyle}>
      {props.icon}
    </Dropdown.Item>
  );
};

export default DropdownItem;
