import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

//Helpers
import api, { API, formUrl } from "../../../../../helper/api/api";

//Components
import HeaderTitle from "../../../../../components/UI/HeaderTitle/HeaderTitle";
import ExtendTrialModal from "../GeneralSettings/ExtendTrialModal/ExtendTrialModal";
import Button from "../../../../../components/UI/Button/Button";

const GeneralSettings = (props) => {
  const { organizationId, data } = props;

  const [showExtendTrialModal, setShowExtendTrialModal] = useState(false);
  const handleExtendTrialModalClose = () => setShowExtendTrialModal(false);
  const handleExtendTrialModalShow = () => setShowExtendTrialModal(true);
  const [organizationPayment, setOrganizationPayment] = useState(null);

  const fetchOrganizationPayment = () => {
    let data = {
      organizationId: organizationId,
    };
    api(formUrl(API.OUTBOSS.organizations.getOrganizationPaymentDetails, data))
      .then((res) => {
        if (res.data) setOrganizationPayment(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchOrganizationPayment();
  }, []);

  let extendTrialModal = null;

  if (organizationPayment != null) {
    extendTrialModal = (
      <ExtendTrialModal
        organizationId={organizationId}
        organizationPayment={organizationPayment}
        handleClose={handleExtendTrialModalClose}
        showModal={showExtendTrialModal}
      />
    );
  }

  return (
    <div className="contentHeight">
      <HeaderTitle title={"General Settings"} />

      <Row>
        <Col xl={3}>
          <Button id="extendTrial" classes="mR15 mB15" variant="primary" onClick={handleExtendTrialModalShow}>
            Extend trial
          </Button>
        </Col>
      </Row>

      {extendTrialModal}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default GeneralSettings;
