import React from "react";

const MessageResponseType = ({ imgStyle, fill = "#586473" }) => (
  <svg className={imgStyle} xmlns="http://www.w3.org/2000/svg" width="16" height="14.516" viewBox="0 0 16 14.516">
    <defs></defs>
    <g transform="translate(0.26 -4.453)">
      <path
        fill={fill}
        className="a"
        d="M50.654,10.666c.2-.407.174-.643-.1-.835s-.441-.127-.91.322L48.127,8.892c.072-.116.133-.232.211-.335a.264.264,0,0,0,.013-.31A2.471,2.471,0,0,0,45.9,6.8H45.66c.082-.1.142-.185.216-.259A3,3,0,0,1,47.457,5.8a2.385,2.385,0,0,1,1.853.433,3.036,3.036,0,0,0,.426.253.676.676,0,0,0,.322.079.983.983,0,0,1,.713.238,5.8,5.8,0,0,1,.573.483,1.438,1.438,0,0,1,.238.384,1.4,1.4,0,0,1,.085.281c.133.528.483.667.949.372.4.344.815.687,1.216,1.041a.409.409,0,0,1,.048.6c-.085.114-.185.216-.28.322L52.473,11.5c-.249.272-.42.29-.708.063C51.4,11.266,51.03,10.966,50.654,10.666Z"
        transform="translate(-38.691 -1.095)"
      />
      <path
        fill={fill}
        className="a"
        d="M18.014,28.959l-.714.9-6.5,8.2a.822.822,0,0,1-1.247.132c-.3-.251-.608-.5-.909-.753a.787.787,0,0,1-.085-1.185q3.886-4.307,7.775-8.611a.528.528,0,0,1,.05-.037Z"
        transform="translate(-7.209 -19.575)"
      />
      <path
        fill={fill}
        className="b"
        d="M60.746,56.754a3.2,3.2,0,0,0-.4-.988,2.845,2.845,0,0,0-3.648-1,.137.137,0,0,1-.116,0c-.521-.333-1.05-.655-1.555-1.008q-.244-.172-.483-.354L53.26,54.946q.367.322.717.669c.388.38.746.79,1.113,1.19a.227.227,0,0,1,.05.161,2.77,2.77,0,0,0,.087,1.126A2.8,2.8,0,0,0,56.8,59.93a2.723,2.723,0,0,0,2.181.053.518.518,0,0,0,.076-.053.609.609,0,0,0-.053-.055L57.4,58.544a.2.2,0,0,1-.077-.119c-.019-.349-.032-.7-.043-1.05a.175.175,0,0,1,.039-.1q.232-.291.471-.576a.175.175,0,0,1,.1-.051c.335-.053.667-.106,1-.15a.238.238,0,0,1,.161.047c.41.336.815.676,1.221,1.017.148.124.293.253.455.394.011-.066.018-.1.021-.129q.031-.256.06-.511l-.045-.483Q60.753,56.791,60.746,56.754Z"
        transform="translate(-45.068 -41.217)"
      />
      <path
        fill={fill}
        className="b"
        d="M5.442,8.485c-.124-.143-.2-.269-.1-.45a.185.185,0,0,0,.014-.051A2.85,2.85,0,0,0,4.112,4.9a2.779,2.779,0,0,0-2.39-.3c-.048.014-.095.04-.161.068l.209.183q.716.634,1.43,1.269a.17.17,0,0,1,.056.108q.013.52.013,1.039a.19.19,0,0,1-.043.114c-.161.18-.31.36-.47.534a.24.24,0,0,1-.132.074c-.322.043-.643.079-.975.122a.212.212,0,0,1-.183-.061L.153,6.875c-.1-.082-.2-.172-.317-.275l-.1.629.031.5A.626.626,0,0,0-.2,7.8a6.4,6.4,0,0,0,.219.7,2.694,2.694,0,0,0,1.608,1.5,2.676,2.676,0,0,0,1.858.016.193.193,0,0,1,.148,0c.2.129.41.256.6.4.343.264.688.526,1.028.8L6.6,9.7Q5.99,9.123,5.442,8.485Z"
        transform="translate(0)"
      />
    </g>
  </svg>
);

export default MessageResponseType;
