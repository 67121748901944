export const templateTimeVariables = [
  "THIS_OR_NEXT",
  "WEEKDAY_SHORT",
  "LAST_HTML_CONTENT",
  "LAST_TEXT_CONTENT",
  "LAST_SENT_DATE",
  "WEEKDAY_FULL",
  "MORNING_OR_AFTERNOON_GREETING",
  "MORNING_OR_AFTERNOON_BODY",
  "REMAINING_DAYS_WEEKEND",
  "MONTH_FULL",
  "MONTH_SHORT",
  "LATERTHIS_OR_EARLYNEXT",
  "WEEKDAY_PLUS_1_FULL",
  "WEEKDAY_PLUS_2_FULL",
  "WEEKDAY_PLUS_3_FULL",
  "WEEKDAY_PLUS_4_FULL",
  "WEEKDAY_PLUS_1_INFORMAL",
  "WEEKDAY_PLUS_2_INFORMAL",
  "WEEKDAY_PLUS_3_INFORMAL",
  "WEEKDAY_PLUS_4_INFORMAL",
  // "LAST_EMAIL_SENT_WEEKDAY",
  // "LAST_EMAIL_SENT_MORNING_OR_AFTERNOON_BODY",
];
