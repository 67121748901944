import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import _ from "lodash";
import { connect } from "react-redux";

//Helpers
import { numberOfRecords } from "../../../../helper/tableConstants";

//Redux
import {
  changePage,
  clearFilter,
  fetchAdvanceEmailTests,
  filterRecords,
  initiate,
  numRecords,
  searchRecords,
  setFilter,
  sortData,
} from "../../../../store/EmailDeliverabilityTest/AdvanceEmailTestTable/actions/advanceEmailTestTable";

//Style
import styles from "./AdvanceEmailTestByReport.module.scss";

//Images & Icons
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

//Components
import Table from "../../../../components/DataTable/Table";
import SkeletonTable from "../../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import TableDeliveredToData from "./Delivered To Data/TableDeliveredToData";

const AdvanceEmailTestByReport = (props) => {
  const { fetchAdvanceEmailTests, initiate, isInitiated } = props;
  const { listOfGroupedProvidersData } = props.advanceTestDetails;
  let total = listOfGroupedProvidersData.length;
  let table = null;

  const [param, setParam] = useState({
    start: 0,
    page: 1,
    length: total,
    sort: {},
    filter: [],
    search: "",
  });
  const [tableData, setTableData] = useState(_.cloneDeep(listOfGroupedProvidersData));
  const records = numberOfRecords[1];

  useEffect(() => {
    var state = {
      params: {
        length: records.value,
        filter: [{ type: "EmailTemplateId", value: [props.emailTemplateId] }],
      },
    };
    initiate(state);
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchAdvanceEmailTests(param);
    }
  }, [param, isInitiated]);

  useEffect(() => {
    setTableData(_.cloneDeep(listOfGroupedProvidersData));
  }, [listOfGroupedProvidersData]);

  tableData.map((r, index) => {
    let spfValidation = null;
    let emptyDelay = "N/A";
    let blackListNumber = r.blackLists;

    if (r.spf === "pass") spfValidation = <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" className={styles.icon} />;
    else spfValidation = <CloseCircleFillIcon size={22} color="var(--primary-color)" />;

    r.spf = <span>{spfValidation}</span>;

    if (_.isEmpty(r.delay)) r.delay = emptyDelay;

    r.deliveredTo = <TableDeliveredToData data={r.iTypeCount} totalPerProvider={r.numberOfMessages} />;

    r.blackLists = <span className={styles.blackListNumbers}>{blackListNumber}</span>;

    return r;
  });

  if (props.error) {
    table = <h2>Error getting Domains</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  if (props.isLoaded) {
    table = (
      <div>
        <Table
          tableName={"Advance Email Deliverability Tests"}
          columns={columns}
          rows={tableData}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          variant="small"
          tableHeight={345}
        />
      </div>
    );
  }

  return (
    <div className={styles.contentWrapper}>
      <div>{table}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.advanceEmailDeliverabilityTestTable.params,
    isInitiated: state.advanceEmailDeliverabilityTestTable.isInitiated,
    isLoading: state.advanceEmailDeliverabilityTestTable.isLoading,
    isLoaded: state.advanceEmailDeliverabilityTestTable.isLoaded,
    error: state.advanceEmailDeliverabilityTestTable.error,
    rows: state.advanceEmailDeliverabilityTestTable.rows,
    totalCount: state.advanceEmailDeliverabilityTestTable.totalCount,
    pageCount: state.advanceEmailDeliverabilityTestTable.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAdvanceEmailTests: (param) => dispatch(fetchAdvanceEmailTests(param)),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceEmailTestByReport);
