import React from "react";

//Style
import "./SkeletonAvatarText.scss";

//Components
import SkeletonElement from "../../SkeletonElements";
import Shimmer from "../Shimmer/Shimmer";

const SkeletonAvatarText = (props) => {
  return (
    <div className="skeleton-wrapper">
      <div className="skeletonAvatarTextWrapper">
        <SkeletonElement type="circle-m" />
        <SkeletonElement type="text-12 w-3" />
      </div>
      <div className="skeletonAvatarTextWrapper">
        <SkeletonElement type="circle-m" />
        <SkeletonElement type="text-12 w-2" />
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonAvatarText;
