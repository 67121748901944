import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Styles
import classes from "./Loading.module.scss";

//Components
import Logo from "../../assets/Logo/Logo";

const Loading = (props) => (
  <div className={classes.loading}>
    <Container>
      <Row>
        <Col lg={10} className="mx-auto text-center text-white">
          <Logo style={{ width: "350px", margin: "50px" }} />
          <h1>Loading...</h1>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Loading;
