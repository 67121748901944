import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import * as Yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";

//Redux
import { fetchListUsers } from "@store/OrganizationSettings/Users/actions/users";
import {
  fetchEmailAccount,
  resetEmailAccount,
  activateEmailAccountTest,
} from "@store/EmailAccounts/EmailAccountSettings/actions/emailAccounts";
import {
  fetchSupportCenterComplianceSentences,
  fetchSupportCenterComplianceSettings,
} from "@store/ComplianceSupportCenter/actions/supportCenterQA";

//Helpers
import { PERMISSIONS } from "@helper/permissionConstants";
import { permissionHelper } from "@helper/permissionHelper";
import { errorResponseToList } from "@helper/errorHelper";
import api, { API, formUrl } from "@helper/api/api";
import { emailServerSecurityDropdown } from "@helper/tableConstants";
import { convertMinutesToSeconds, convertSecondsToMinutes } from "@helper/timeHelper";
import { useQuery } from "../../../helper/hooks/useQuery";
import { handleImageUpload } from "@helper/TinyMce/imageUploadHelper";
import { placeholderHelper, addCustomMenuItems } from "@helper/TinyMce/initHelper";

//Styles
import styles from "./EmailAccountSettings.module.scss";

//Images & Icons
import SectionInbox from "@assets/Icons/SectionInbox/SectionInbox";

//Components
import FormikInput from "@components/UI/Formik/FormikInput";
import FormikSelect from "@components/UI/Formik/FormikSelect";
import FormikCheckbox from "@components/UI/Formik/FormikCheckbox";
import Spinner from "@components/UI/Spinner/Spinner";
import Button from "@components/UI/Button/Button";
import SectionTitle from "@components/UI/SectionTitle/SectionTitle";
import ConnectedTestEmailForm from "./ConnectedTestEmailForm";
import SaveChangesFooter from "@components/SaveChangesFooter/SaveChangesFooter";
import FormikEffect from "@components/UI/Formik/FormikEffect";
import ActionEdit from "@components/UI/ActionWrapper/Actions/ActionEdit";
import PasswordChangeModal from "./BasicEmailTest/PasswordChangeModal/PasswordChangeModal";
import Permission from "@components/auth/Permission";
import StepperComponent from "@components/UI/StepperComponent/StepperComponent";
import InfoIconTooltip from "@components/UI/CustomTooltip/InfoIconTooltip";
import LabelWithTooltip from "@components/UI/LabelWithTooltip/LableWithTooltip";

const EmailAccountSettings = (props) => {
  const {
    fetchListUsers,
    emailAccount,
    fetchSupportCenterComplianceSentences,
    fetchSupportCenterComplianceSettings,
    emailAccountId,
    setSendTestValues,
    receiveStatus,
    sendStatus,
    handleTestMailFormClose,
    showTestEmailFormModal,
    activateEmailAccountTest,
    fetchEmailAccount,
  } = props;
  const signatureEditorRef = useRef(null);
  const formikRef = useRef();
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  let isSmtp = props.emailAccountType === "SmtpImap";
  let isConnected = emailAccount?.isConnected;

  if (emailAccount != null) {
    isSmtp = emailAccount.emailAccountType === "SmtpImap";
    isConnected = emailAccount?.isConnected;
  }

  const [hasChanges, setHasChanges] = useState(false);
  const onChange = (values) => {
    setSendTestValues(values);
    setHasChanges(true);
    if (values.email === "") {
      setDuplicateEmail(false);
    }
  };

  let accountId = emailAccountId || 0;

  useEffect(() => {
    fetchListUsers();
    if (permissionHelper(props.userRole, PERMISSIONS.compliance.view)) {
      fetchSupportCenterComplianceSentences();
      fetchSupportCenterComplianceSettings();
    }
    props.fetchEmailAccount(accountId);

    return () => {
      //  props.resetEmailAccount();
    };
  }, []);

  const [serverSettings, setServerSettings] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const [serverCheckBox, setServerCheckbox] = useState(true);
  const [checkForUnsubscribeURL, setUnsubscribeUrl] = useState(true);
  const [currentValuesCompliance, setCurrentValuesCompliance] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  // const [passwordChange, setPasswordChange] = useState(null);
  const [showPasswordChangeModal, setPasswordChangeModal] = useState(false);

  const [duplicateEmail, setDuplicateEmail] = useState(false);

  const handlePasswordChangeClose = () => setPasswordChangeModal(false);
  const handlePasswordChangeShow = () => {
    setPasswordChangeModal(true);
  };

  let query = useQuery();

  const steps = [
    {
      label: "Email Account",
      description: "Add an email account.",
    },
    {
      label: "Signature",
      description: "Add an email signature and compliance statement.",
    },
  ];

  const privacyPolicyURLLabel = (
    <p>
      Read about the importance of adding a{" "}
      <a
        className="primary_link"
        rel="noopener noreferrer"
        href="https://intercom.help/outbase/en/articles/8564421-essential-elements-incorporating-a-privacy-policy-and-unsubscribe-link-in-your-email-campaigns"
        target="_blank"
      >
        privacy policy URL
      </a>
    </p>
  );

  const checkFormikDuplicateEmail = () => {
    formikRef.current.validateField("email");
    formikRef.current.setFieldTouched("email");
  };

  useEffect(() => {
    if (duplicateEmail) {
      checkFormikDuplicateEmail();
    }
  }, [duplicateEmail]);

  const checkServerSettings = (email, values, validateField, setFieldTouched) => {
    if (serverCheckBox && email != null) {
      api(formUrl(API.emailAccounts.mxrecords, { email: email }))
        .then((res) => {
          const val = _.cloneDeep(values);

          if (res.data && res.data.duplicateEmail) {
            setDuplicateEmail(true);
          } else if (res.data && res.data.success && !res.data.duplicateEmail) {
            setDuplicateEmail(false);
            setServerSettings(res.data);
            setCurrentValues(val);
          }
        })
        .catch((err) => {
          console.log("CheckServerSettings error:" + err);
          return;
        });
    } else {
      return;
    }
  };

  const tooltipBody = (
    <div className={styles.subjectTooltipDiv}>
      <span className={styles.subjectTooltipSpan}>
        If this is a Gmail account that uses 2-step verification, you will have to create an app password for Outbase and add the App
        password{" "}
        <a
          className="primary_link"
          href="https://support.google.com/accounts/answer/185833?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>
        <br />
      </span>
    </div>
  );

  let allMenus = {};
  let complianceSentencesDropDown = [];
  if (props.complianceSentences !== undefined) {
    props.complianceSentences.map((sentence) => {
      complianceSentencesDropDown.push({
        label: (
          <OverlayTrigger
            key={"audience-size"}
            placement={"left"}
            overlay={
              <Popover id={`${sentence.sentenceShortname}`}>
                <Popover.Body>{sentence.plainSentence}</Popover.Body>
              </Popover>
            }
          >
            <div>{sentence.sentenceShortName}</div>
          </OverlayTrigger>
        ),
        value: sentence.sentence,
      });
    });
  }

  let form = null;

  if (props.isLoading) {
    form = <Spinner spinnerClass="absolute transform-y-50" />;
  }

  allMenus["urls"] = { title: "URLs", items: "privacyPolicy unsubscribe" };

  const customMenuItems = [
    {
      name: "privacyPolicy",
      text: "Privacy policy",
      value: "{{PRIVACY_POLICY_URL}}",
      content: '<a href="{{PRIVACY_POLICY_URL}}">Privacy policy</a>',
    },
    {
      name: "unsubscribe",
      text: "Unsubscribe",
      value: "{{UNSUBSCRIBE_URL}}",
      content: '<a href="{{UNSUBSCRIBE_URL}}">Unsubscribe</a>',
    },
  ];

  const placeholderHTML = `
  <div style="color: #78797C; font-size: 12px;">
    <span style="font-weight:bold;">John Doe</span><br/>
    Sales Manager, Outbase.<br>
    123 Business Road, Suite 456<br>
    London, UK SW1A 1AA<br>
    Phone: +44 20 7946 0958<br>
    Email: <span style="font-weight: bold;color: #FE7904;">john.doe@outbase.com</span><br>
    Connect with me on <span style="font-weight: bold;color: #FE7904;">LinkedIn</span> | Follow us on <span style="font-weight: bold;color: #FE7904;">Twitter</span><br>
    <span style="font-weight: bold;color: #FE7904;">Book a Demo</span>: Schedule Now
    <br>
    <br>
    <p style="font-size: 8px;">Please find a link to our 
    <span style=" text-decoration: underline;">privacy policy</span>, and if you don't want us to send any further emails - <span style=" text-decoration: underline;">no thank you.</span></p>
  </div>
`;

  if (props.isLoaded && props.isListUsersLoaded) {
    const init = {
      branding: false,
      toolbar_mode: "sliding",
      height: "calc(100vh - 425px)",
      link_assume_external_targets: true,
      block_unsupported_drop: true,
      automatic_uploads: true,
      paste_data_images: true,
      images_reuse_filename: true,
      images_upload_handler: handleImageUpload,
      images_file_types: "jpg,jpeg,gif,png,jfif",
      menu: allMenus,
      menubar: "urls",
      plugins:
        "editimage importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link table charmap nonbreaking anchor insertdatetime advlist lists wordcount charmap emoticons",
      toolbar:
        "bold italic underline strikethrough forecolor backcolor removeformat insertfile image | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify outdent indent | numlist bullist | charmap emoticons | link anchor code | fullscreen print | undo redo",
      content_style: "body { font-family:Arial,sans-serif; }",
      setup: (editor) => {
        placeholderHelper(placeholderHTML)(editor);
        addCustomMenuItems(customMenuItems)(editor);
      },
      encoding: "html",
      preformatted: false,
      entity_encoding: "raw",
    };

    form = (
      <Formik
        innerRef={formikRef}
        initialValues={
          emailAccount != null
            ? {
                id: emailAccount.id,
                userOrganizationId: emailAccount.userOrganizationId.toString(),
                name: currentValues !== null ? currentValues.name : emailAccount.name,
                email: currentValues !== null ? currentValues.email : emailAccount.email,
                smtp: currentValues !== null ? currentValues.smtp : emailAccount.smtp,
                imap: currentValues !== null ? currentValues.imap : emailAccount.imap,
                messagesPerDay: currentValues !== null ? currentValues.messagesPerDay : emailAccount.messagesPerDay,
                averageDelay:
                  currentValues !== null
                    ? convertSecondsToMinutes(currentValues.averageDelay)
                    : convertSecondsToMinutes(emailAccount.averageDelay),
                signature: currentValues !== null ? currentValues.signature : emailAccount.signature,
                serverCheckBox: currentValues !== null ? currentValues.serverCheckBox : serverCheckBox,
                privacyPolicyURL: currentValues !== null ? currentValues.privacyPolicyURL : emailAccount.privacyPolicyURL,
                checkForUnsubscribeURL: currentValues !== null ? currentValues.checkForUnsubscribeURL : emailAccount.checkForUnsubscribeURL,
                emailAccountType: currentValues !== null ? currentValues.emailAccountType : emailAccount.emailAccountType,
              }
            : {
                id: 0,
                userOrganizationId: props.userId,
                name: currentValues !== null ? currentValues.name : "",
                email: currentValues !== null ? currentValues.email : "",
                smtp: {
                  host: serverSettings !== null && serverSettings !== "" ? serverSettings.smtp.host : "",
                  port: serverSettings !== null && serverSettings !== "" ? serverSettings.smtp.port : "",
                  username: "",
                  password: currentValues !== null && currentValues.smtp !== null ? currentValues.smtp.password : "",
                  security: serverSettings !== null && serverSettings !== "" ? serverSettings.smtp.security : "",
                },
                imap: {
                  host: serverSettings !== null && serverSettings !== "" ? serverSettings.imap.host : "",
                  port: serverSettings !== null && serverSettings !== "" ? serverSettings.imap.port : "",
                  username: "",
                  password: currentValues !== null ? currentValues.imap.password : "",
                  security: serverSettings !== null && serverSettings !== "" ? serverSettings.imap.security : "",
                },
                messagesPerDay: 500,
                averageDelay: 3,
                signature: currentValues !== null ? currentValues.signature : "",
                serverCheckBox: serverCheckBox,
                privacyPolicyURL: currentValues !== null ? currentValues.privacyPolicyURL : "",
                checkForUnsubscribeURL: currentValues !== null ? currentValues.checkForUnsubscribeURL : true,
                emailAccountType: props.emailAccountType,
              }
        }
        enableReinitialize={true}
        validationSchema={
          isSmtp
            ? Yup.object({
                userOrganizationId: Yup.string().required("Required"),
                name: Yup.string().required("Required").max(100, "Your From Name is too long. Please shorten it (100 characters)"),
                email: Yup.string()
                  .email("The value entered is invalid.")
                  .required("Required")
                  .test("unique", "Email already exists", () => {
                    var result = !duplicateEmail;
                    return result;
                  }),
                smtp: Yup.object({
                  // Merged smtp username with Email for now
                  // username: Yup.string().required("Required"),
                  password: Yup.string().required("Required"),
                  host: Yup.string().required("Required"),
                  port: Yup.string().required("Required"),
                  security: Yup.number().required("Required"),
                }),
                imap: Yup.object({
                  host: Yup.string().required("Required"),
                  port: Yup.string().required("Required"),
                  security: Yup.number().required("Required"),
                }),
                messagesPerDay: Yup.number("Enter valid number")
                  .positive("Enter valid number")
                  .integer("Enter valid number")
                  .required("Required"),
                averageDelay: Yup.number("Enter valid number")
                  .positive("Enter valid number")
                  .integer("Enter valid number")
                  .required("Required")
                  .min(1, "Minimum allowed is 1 min")
                  .max(5, " Maximum allowed is 5 min"),
                signature: Yup.string("Enter signature"),
              })
            : //not smtp
              Yup.object({
                userOrganizationId: Yup.string().required("Required"),
                name: Yup.string().required("Required").max(101, "Your From Name is too long. Please shorten it (100 characters)"),
                email: Yup.string().email("The value entered is invalid.").required("Required"),
                messagesPerDay: Yup.number("Enter valid number")
                  .positive("Enter valid number")
                  .integer("Enter valid number")
                  .required("Required"),
                averageDelay: Yup.number("Enter valid number")
                  .positive("Enter valid number")
                  .integer("Enter valid number")
                  .required("Required")
                  .min(1, "Minimum allowed is 1 min")
                  .max(5, " Maximum allowed is 5 min"),
                signature: Yup.string("Enter signature"),
              })
        }
        onSubmit={(values, actions) => {
          if (activeStep === 0) {
            setActiveStep(1);
            actions.setSubmitting(false);
            return;
          }
          if (
            (values.signature.indexOf("{{UNSUBSCRIBE_URL}}") > 0 && values.checkForUnsubscribeURL === true) ||
            values.checkForUnsubscribeURL === false
          ) {
            values.averageDelay = convertMinutesToSeconds(values.averageDelay);

            if (query.get("campaignId") != null) {
              values.campaignId = query.get("campaignId");
              if (query.get("callback") != null) {
                values.campaignConfigCallback = query.get("callback");
              }
            }

            api(API.emailAccounts.create, values)
              .then((res) => {
                if (values.campaignId) {
                  // if email account is added from campaign configuration page and there is no other connected email account
                  // then redirect to email account and then to campaign configuration page the email account added is already connected and the email account step is completed
                  if (res.data.count == 0) {
                    activateEmailAccountTest(!res.data.isSuspended);
                    props.history.push("/" + props.organizationId + "/email-accounts?&campaignId=" + values.campaignId);
                  } else {
                    // if email account is added from campaign configuration page and there is no other connected email account
                    // then redirect to email account page and then campaign configuration page the email account added should be selected in the dropdown list the user should select it
                    activateEmailAccountTest(!res.data.isSuspended);
                    props.history.push(
                      "/" + props.organizationId + "/email-accounts?emailAccountAdded=" + res.data.id + "&campaignId=" + values.campaignId
                    );
                  }
                } else {
                  // activateEmailAccountTest(true);
                  // fetchEmailAccount(res.data.id);
                  // props.history.push("/" + props.organizationId + "/email-accounts/");
                  // toast.success(toastMessages.emailAccount.successSavedEmailAccount);
                  if (res && res.data) {
                    activateEmailAccountTest(!res.data.isSuspended);
                    fetchEmailAccount(res.data.id);
                    if (res.data.isConnected) {
                      actions.setSubmitting(false);
                    }
                    props.history.push("/" + props.organizationId + "/email-accounts/");
                  }
                }
              })
              .catch((error) => {
                const errorList = errorResponseToList(error);
                if (errorList.length > 0) {
                  errorList.forEach((err) => toast.error(err.message));
                } else {
                  toast.error(error.message);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            scrollToRef(signatureEditorRef);
            actions.setSubmitting(false);
          }
        }}
        onReset={() => {
          //commented because ressets values on checking a checkBox Check for unsubscribe parameter in signature
          //and removes saves changes footer
          // setHasChanges(false);
        }}
      >
        {(formikProps) => {
          const { values, setFieldValue, validateField, setFieldTouched, handleSubmit } = formikProps;

          const handleStepChange = (step) => {
            if (activeStep === 0) handleSubmit();
            else setActiveStep(step);
          };

          return (
            <>
              <FormikEffect onChange={() => onChange(values)} />
              <Row className={`${styles.formsWrapper} height_100`}>
                <Field name="id" value={values.id} type="hidden" />
                <Field name="smtp.username" value={values.email} type="hidden" />
                <Field name="imap.username" value={values.email} type="hidden" />
                <Field name="imap.password" value={values !== null && values.smtp !== null ? values.smtp.password : ""} type="hidden" />
                <Field name="emailAccountType" value={values != null ? values.emailAccountType : props.emailAccountType} type="hidden" />

                <Col id="stepperComponentWrapper" md={2} className={styles.stepperComponentWrapper}>
                  <StepperComponent
                    setActiveStep={handleStepChange}
                    handleSubmit={handleSubmit}
                    orientation="vertical"
                    activeStep={activeStep}
                    steps={steps}
                  />
                </Col>
                <Col md={10} className={styles.emailAccountFormsWrapper}>
                  {activeStep === 0 && (
                    <Row className={`height_100`}>
                      <Col md={9} className={styles.emailDetailsFormsWrapper}>
                        <Row className={styles.emailDetailsWrapper}>
                          <SectionTitle titleClass="pL15" title="Email Account" icon={<SectionInbox />} />
                          <Col id="emailDetails" md={5} className={`${styles.accountDetails} mB5`}>
                            <FormikInput
                              label="Email"
                              name="email"
                              inputclass={styles.inputclass}
                              labelclass="noWrap"
                              placeholder={isSmtp ? "Type Here" : ""}
                              value={values.email}
                              onBlur={(e) => checkServerSettings(e.target.value, values, validateField, setFieldTouched)}
                              disabled={emailAccount != null}
                              errorinputclass="absolute"
                            />
                            <FormikInput
                              label="From Name"
                              name="name"
                              inputclass={styles.inputclass}
                              labelclass="noWrap"
                              errorinputclass="absolute"
                              placeholder="Type Here"
                              value={values.name}
                            />
                            {isSmtp ? (
                              <div className={styles.passInfoIconWraper}>
                                <FormikInput
                                  disabled={emailAccount != null}
                                  hideEye={emailAccount != null}
                                  label="Password"
                                  id="password"
                                  name="smtp.password"
                                  inputclass={styles.inputPasswordclass}
                                  labelclass="noWrap"
                                  placeholder="Type Here"
                                  value={values.smtp?.password}
                                  type="password"
                                  formgroupclass={styles.passwordInput}
                                  errorinputclass="absolute"
                                />
                                <InfoIconTooltip
                                  tooltipClass={styles.passwordEmailTooltip}
                                  disableStartTour
                                  tooltipBody={tooltipBody}
                                  id="email-password-tooltip"
                                />

                                {emailAccount != null ? (
                                  <span className={styles.subjectTooltip}>
                                    <ActionEdit onClick={handlePasswordChangeShow} />
                                  </span>
                                ) : null}
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>

                          <Col md={7} className={styles.hostsWrapper} id="hostsWrapper">
                            <div id="serverSettings">
                              {isSmtp ? (
                                <React.Fragment>
                                  <div>
                                    <FormikCheckbox
                                      label="Auto-populate server settings"
                                      name="serverCheckBox"
                                      labelstyle={styles.checkBox}
                                      labelclass="noWrap"
                                      onChange={(checked) => {
                                        setServerCheckbox(checked);
                                        setHasChanges(true);
                                      }}
                                      checked={values.serverCheckBox}
                                    />
                                  </div>
                                  <div className={`${styles.fieldsWrapper} flex`}>
                                    <div className={styles.formikWrapper}>
                                      <FormikInput
                                        label="SMTP Host"
                                        name="smtp.host"
                                        labelclass="noWrap"
                                        inputclass={styles.inputclass}
                                        placeholder="Type Here"
                                        value={values.smtp?.host}
                                        errorinputclass="absolute"
                                      />
                                    </div>
                                    <div className={styles.formikWrapper}>
                                      <FormikInput
                                        label="SMTP Port"
                                        name="smtp.port"
                                        labelclass="noWrap"
                                        formgroupclass={styles.widthInput}
                                        placeholder="Type Here"
                                        value={values.smtp?.port}
                                        type="number"
                                        errorinputclass="absolute"
                                      />
                                    </div>
                                    <div className={styles.formikWrapper}>
                                      <FormikSelect
                                        enableReinitialize
                                        label="Security"
                                        name="smtp.security"
                                        placeholder="SSL"
                                        labelclass="noWrap"
                                        options={emailServerSecurityDropdown}
                                        value={values.smtp?.security}
                                        onChange={(event) => {
                                          setHasChanges(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className={`${styles.fieldsWrapper} flex`}>
                                    <div className={styles.formikWrapper}>
                                      <FormikInput
                                        label="IMAP Host"
                                        name="imap.host"
                                        labelclass="noWrap"
                                        inputclass={styles.inputclass}
                                        placeholder="Type Here"
                                        value={values.imap?.host}
                                        errorinputclass="absolute"
                                      />
                                    </div>
                                    <div className={styles.formikWrapper}>
                                      <FormikInput
                                        label="IMAP Port"
                                        name="imap.port"
                                        labelclass="noWrap"
                                        formgroupclass={styles.widthInput}
                                        placeholder="Type Here"
                                        value={values.imap?.port}
                                        type="number"
                                        errorinputclass="absolute"
                                      />
                                    </div>
                                    <div className={styles.formikWrapper}>
                                      <FormikSelect
                                        enableReinitialize
                                        label="Security"
                                        name="imap.security"
                                        placeholder="SSL"
                                        labelclass="noWrap"
                                        options={emailServerSecurityDropdown}
                                        value={values.imap?.security}
                                        onChange={(event) => {
                                          setHasChanges(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Permission has={PERMISSIONS.emailAccounts.assignToUser}>
                        <Col id="generalSettings" md={3} className={styles.borderLeft}>
                          <SectionTitle title="General Settings" />
                          <FormikSelect
                            label={"Assign to user"}
                            name={"userOrganizationId"}
                            placeholder={"User"}
                            options={props.listUsers}
                            value={values.userOrganizationId}
                            onChange={(event) => {
                              setHasChanges(true);
                            }}
                          />
                          <Row>
                            <Col md={6}>
                              <FormikInput
                                label={"Max emails per day"}
                                name="messagesPerDay"
                                formgroupclass={styles.messagesPerDay}
                                inputclass={styles.inputclass}
                                placeholder="Type Here"
                                value={values.messagesPerDay}
                                type="number"
                              />
                            </Col>

                            <Col md={6}>
                              <FormikInput
                                label="Average Delay (min)"
                                name="averageDelay"
                                inputclass={styles.inputclass}
                                value={values.averageDelay}
                                formgroupclass={styles.widthInput60}
                                type="number"
                              />
                            </Col>
                            <Col md={12}>
                              <p className="f12">
                                *This is the average delay, with emails being sent with varying delays of +/- 50% of the specified delay
                                time. We recommend an average delay of 3 to 5 minutes. For more information see the{" "}
                                <a
                                  className="primary_link"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href="https://help.outbase.com/getting-started/add-an-email-account/email-account-and-general-settings"
                                >
                                  User Documentation
                                </a>
                                .
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Permission>
                    </Row>
                  )}
                  {activeStep === 1 && (
                    <Row>
                      <Permission has={PERMISSIONS.emailAccounts.edit}>
                        <Col xl={9} id="signature">
                          <div className={`${styles.generalSettings} flex flexCol`}>
                            <SectionTitle title="Signature" icon={<SectionInbox />} />
                            <p className={styles.signatureEditor}>
                              Add your email signature below either by copying and pasting it or by creating one in the editor below.
                            </p>

                            <FormikCheckbox
                              label="Check for unsubscribe link option in signature"
                              labelstyle={!values.checkForUnsubscribeURL && "mB10"}
                              name="checkForUnsubscribeURL"
                              onChange={(checked) => {
                                setFieldValue("checkForUnsubscribeURL", checked);
                                // values.checkForUnsubscribeURL = checked;
                                setUnsubscribeUrl(checked);
                                setHasChanges(true);
                              }}
                              checked={values.checkForUnsubscribeURL}
                            />
                            {values.signature.indexOf("{{UNSUBSCRIBE_URL}}") < 0 && values.checkForUnsubscribeURL === true ? (
                              <p ref={signatureEditorRef} className={`${styles.signatureEditorError}`}>
                                Your signature doesn't include an unsubscribe link. Please add it manually or through the compliance
                                section.
                              </p>
                            ) : null}
                            <FormikInput
                              as={Editor}
                              apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
                              name="signature"
                              className="tox-notifications-container"
                              inputGroupClass="m0"
                              value={values.signature}
                              type="textarea"
                              rows="8"
                              init={init}
                              placeholder="Type here..."
                              onEditorChange={(content, value) => {
                                let text = value.getContent({ format: "text" });
                                let html = content;
                                setFieldValue("signature", html);
                                setFieldValue("text", text);
                              }}
                            />
                          </div>
                        </Col>
                      </Permission>
                      <Col id="compliance" xl={3} className={`${styles.complianceWrapper} mB20`}>
                        <Formik
                          initialValues={
                            emailAccount != null
                              ? {
                                  privacyPolicyURLCompliance:
                                    currentValuesCompliance !== null ? currentValuesCompliance.privacyPolicyURL : "",
                                  complianceSentence: currentValuesCompliance !== null ? currentValuesCompliance.complianceSentence : "",
                                  signatureCompliance: values.signature,
                                }
                              : {
                                  privacyPolicyURLCompliance:
                                    currentValuesCompliance !== null ? currentValuesCompliance.privacyPolicyURL : "",
                                  complianceSentence: currentValuesCompliance !== null ? currentValuesCompliance.complianceSentence : "",
                                  signatureCompliance: values.signature,
                                }
                          }
                          enableReinitialize={true}
                          validationSchema={Yup.object({
                            privacyPolicyURLCompliance: Yup.string().required("Privacy policy URL is required"),
                            complianceSentence: Yup.string().required("You have to choose one message"),
                          })}
                          onSubmit={(values, actionsCompliance) => {
                            var signature = values.signatureCompliance;
                            signature = signature + " \n " + values.complianceSentence;
                            formikProps.setFieldValue("signature", signature);
                            formikProps.setFieldValue("privacyPolicyURL", values.privacyPolicyURLCompliance);
                            formikProps.setFieldValue("privacyPolicyURLCompliance", values.privacyPolicyURLCompliance);
                            setFieldValue("privacyPolicyURLCompliance", values.privacyPolicyURLCompliance);
                            setFieldValue("complianceSentence", values.complianceSentence);
                            formikProps.setFieldValue("complianceSentence", values.complianceSentence);

                            const val = _.cloneDeep(values);
                            setCurrentValuesCompliance(val);
                            setHasChanges(true);
                          }}
                        >
                          {(formikProps) => {
                            const { values } = formikProps;
                            return (
                              <>
                                <FormikEffect onChange={onChange} />
                                <Permission has={PERMISSIONS.emailAccounts.edit}>
                                  <Form className={styles.formsWrapper}>
                                    <SectionTitle title="Compliance" />
                                    <p className="mB15">
                                      Add a compliance statement with a link to your privacy policy and an unsubscribe option to your
                                      signature.
                                    </p>
                                    <FormikSelect
                                      label={"Statements"}
                                      name={"complianceSentence"}
                                      value={values.complianceSentence}
                                      placeholder={
                                        values.complianceSentence !== "" ? values.complianceSentence : "Add message from the list"
                                      }
                                      options={complianceSentencesDropDown}
                                      id="selectId"
                                    />
                                    <FormikInput
                                      label={
                                        <LabelWithTooltip
                                          tooltipClass="top"
                                          iconSize={16}
                                          label="Privacy policy URL"
                                          tooltipBody={privacyPolicyURLLabel}
                                        />
                                      }
                                      name="privacyPolicyURLCompliance"
                                      inputclass={styles.inputclass}
                                      errorinputclass={styles.errorMessage}
                                      value={values.privacyPolicyURLCompliance}
                                      formgroupclass={styles.widthInput60}
                                    />
                                    <Button
                                      id="addStatement"
                                      variant="dark"
                                      classes="mT10 textRight mL0"
                                      children="Add statement"
                                      onClick={() => {
                                        formikProps.handleSubmit();
                                      }}
                                      type="button"
                                    />
                                  </Form>
                                </Permission>
                              </>
                            );
                          }}
                        </Formik>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xl={12} lg={12} sm={12} xs={12}>
                      {activeStep === 0 ? (
                        <SaveChangesFooter
                          cancelClick={() => {
                            activateEmailAccountTest(false);
                            props.history.push("/" + props.organizationId + "/email-accounts/");
                          }}
                          saveChangesClick={handleSubmit}
                          cancelButtonDisabled={formikProps.isSubmitting}
                          saveButtonDisabled={formikProps.isSubmitting}
                          saveButtonText={"Next: Add signature"}
                          saveButtonId="addSignature"
                        />
                      ) : hasChanges || serverSettings !== null || accountId !== 0 ? (
                        <SaveChangesFooter
                          cancelClick={() => {
                            activateEmailAccountTest(false);
                            props.history.push("/" + props.organizationId + "/email-accounts/");
                          }}
                          saveChangesClick={formikProps.handleSubmit}
                          cancelButtonDisabled={formikProps.isSubmitting}
                          saveButtonDisabled={formikProps.isSubmitting}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <PasswordChangeModal
                  handleClose={handlePasswordChangeClose}
                  showModal={showPasswordChangeModal}
                  emailAccountId={emailAccount?.id}
                />
              </Row>
              <ConnectedTestEmailForm
                handleClose={handleTestMailFormClose}
                showModal={showTestEmailFormModal}
                sendStatus={sendStatus}
                receiveStatus={receiveStatus}
                emailAccountId={emailAccount?.id}
                emailAccountType={emailAccount?.emailAccountType}
              />
            </>
          );
        }}
      </Formik>
    );
  }

  return (
    <div className="height_100">
      <div id="spinner" className={styles.spinner + " " + styles.hidden}>
        <Spinner />
      </div>
      {form}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.organization.userOrganizationId.toString(),
    organizationId: state.user.organization.id,
    listUsers: state.organizationSettingsUsers.listusers,
    isListUsersLoading: state.organizationSettingsUsers.isLoading,
    isListUsersLoaded: state.organizationSettingsUsers.isLoaded,
    emailAccount: state.emailAccountSettings.emailAccount,
    isLoading: state.emailAccountSettings.isLoading,
    isLoaded: state.emailAccountSettings.isLoaded,
    isError: state.emailAccountSettings.isError,
    complianceSentences: state.complianceSupportCenterQA.complianceSentences,
    unsubscribeExternalUrl: state.complianceSupportCenterQA.unsubscribeExternalUrl,
    unsubscribeExternalService: state.complianceSupportCenterQA.unsubscribeExternalService,
    userRole: state.user.organization.role,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchListUsers: () => dispatch(fetchListUsers()),
    fetchEmailAccount: (data) => dispatch(fetchEmailAccount(data)),
    fetchSupportCenterComplianceSentences: () => dispatch(fetchSupportCenterComplianceSentences()),
    fetchSupportCenterComplianceSettings: () => dispatch(fetchSupportCenterComplianceSettings()),
    resetEmailAccount: () => dispatch(resetEmailAccount()),
    activateEmailAccountTest: (val) => dispatch(activateEmailAccountTest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailAccountSettings);
