import React from "react";

const RestoreIcon = (props) => (
  <svg className={props.imgStyle} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M0.235203 2.77221L2.6344 0.246738C2.70857 0.168386 2.79672 0.106249 2.89379 0.0639035C2.99086 0.021558 3.09493 -0.000159536 3.2 8.82251e-07H12.8C12.9051 -0.000159536 13.0091 0.021558 13.1062 0.0639035C13.2033 0.106249 13.2914 0.168386 13.3656 0.246738L15.7648 2.77221C15.8395 2.85025 15.8988 2.94314 15.9392 3.04549C15.9796 3.14783 16.0002 3.2576 16 3.36842V14.3158C16 15.2446 15.2824 16 14.4 16H1.6C0.717602 16 2.08867e-06 15.2446 2.08867e-06 14.3158V3.36842C-0.000239191 3.2576 0.020426 3.14783 0.0608007 3.04549C0.101175 2.94314 0.160457 2.85025 0.235203 2.77221ZM12.4688 1.68421H3.5312L2.7312 2.52632H13.2688L12.4688 1.68421ZM14.4 14.3158V4.21053H1.6L1.5984 14.3158H14.4Z"
        fill="#5C656F"
      />
      <path d="M9.6 13L6.4 13L6.4 10.6L4 10.6L8 6.59998L12 10.6L9.6 10.6L9.6 13Z" fill="#5C656F" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RestoreIcon;
