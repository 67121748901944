import { FC } from "react";
import Modal from "@components/UI/Modal/Modal";

//Styles
import styles from "./NoCreditsModal.module.scss";

import noCreditsImg from "./svg/noCredits.svg";
import { useOrganizationId } from "@hooks/useOrganizationId";

interface Props {
  handleClose: () => void;
  showModal: boolean;
}

const UpgradeToPaidPlanModal: FC<Props> = (props) => {
  const {showModal, handleClose} = props;
  
  const organizationId = useOrganizationId();
  let purchaseCreditsLink = `/${organizationId}/pricing`;

  return (
    <Modal
      handleClose={handleClose}
      show={showModal}
      closeButtonText="Later"
      saveButtonText="Upgrade Plan"
      isSaveButtonLink={true}
      saveButtonLink={purchaseCreditsLink}
      linkTarget="_self"
    >
      <div className={styles.noCreditsModal}>
        <img src={noCreditsImg} className={styles.noCreditsImg} alt="Purchase credits" />
        <p className={styles.introText}>Phone numbers are available exclusively for paid clients.</p>
        <p>Choose from our variety of plans to find the best fit for your needs.</p>
      </div>
    </Modal>
  );
};

export default UpgradeToPaidPlanModal;
