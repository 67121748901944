import { DataTableActions, DataTableState } from "../../store/createDataTableSlice";
import useActions, { UseActionsReturn } from "./useActions";
import { useDataTableWithQueryParams } from "./useDataTableWithQueryParams";

export interface UseDataTableReturn<T> {
  actions: UseActionsReturn;
  state: DataTableState<T>;
  query: any; // Replace with the correct type if different
}

export const useDataTable = <T>({
  slice,
  dtActions,
  useQuery,
  filterTypes,
  useWithQuery = true,
  autoInitiate = true,
}: {
  slice: DataTableState<T>;
  dtActions: DataTableActions;
  useQuery: any; // Replace with the correct type if different
  filterTypes: string[];
  useWithQuery?: boolean;
  autoInitiate?: boolean;
}) => {
  const actions = useActions(dtActions);
  const { params, isInitiated } = slice;
  const query = useQuery(params, { skip: !isInitiated, refetchOnMountOrArgChange: true });

  useDataTableWithQueryParams(slice, actions, filterTypes, useWithQuery, autoInitiate);

  return {
    actions,
    state: slice,
    query,
  };
};
