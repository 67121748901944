import React, { FC } from "react";
import Modal from "../../components/UI/Modal/Modal";

interface CampaignActivatedModalProps {
  handleClose: () => void;
  handleSave: () => void;
  showModal: boolean;
  campaignName: string;
  emailAddress?: string;
}

const CampaignActivatedModal: FC<CampaignActivatedModalProps> = (props) => {
  const showDisconnected = props.emailAddress != null && props.emailAddress.length > 0;

  return (
    <Modal
      title={`Success`}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      show={props.showModal}
      closeButtonText="Cancel"
      saveButtonText="OK"
      btnType="submit"
    >
      <div>
        <p>
          Your {props.campaignName} campaign was activated.
          {showDisconnected && (
            <p>
              These accounts are disconnected and won't be sending any emails:
              <br />
              <b>{props.emailAddress}</b>
            </p>
          )}
        </p>
      </div>
    </Modal>
  );
};

export default CampaignActivatedModal;
