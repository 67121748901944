import React, { useEffect, useContext, useState } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

//Redux
import { fetchOrganizationInvites, acceptInvite, declineInvite } from "../../store/global/actions/invites";
import { organizationModalActionType } from "./organizationModalActionType";
import DeleteLeaveOrganizationForm from "./DeleteLeaveOrganizationForm";
import { useGetDeleteAvailabilitiesMutation } from "../../api/organizationApi";

//Helpers
import { organizationRoleEnum } from "../../helper/enums/organizationRoleEnum";
import api, { formUrl, API } from "../../helper/api/api";
import { activeApplicationEnum } from "../../helper/enums/activeApplicationEnum";
import { OrganizationDeleteStatusEnum } from "../../models/organization/OrganizationDeleteStatusEnum";

//Styles
import styles from "./OrganizationPicker.module.scss";

//Images & Icons
import CombinationMarkLogoDark from "../../assets/Logo/CombinationMarkLogoDark/CombinationMarkLogoDark";
import TeamLineIcon from "remixicon-react/TeamLineIcon";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import Button from "../../components/UI/Button/Button";
import UserPhoto from "../../components/UI/UserPhoto/UserPhoto";
import LoggedInUser from "../../components/Header/LoggedInUser/LoggedInUser";
import SkeletonTableRow from "../../components/UI/Skeletons/components/SkeletonTableRow/SkeletonTableRow";
import DeleteConfirmModal from "./DeleteConfirmModal";
import useModal from "../../components/UI/Modal/useModal";
import DeleteActiveOrganizationModal from "./DeleteActiveOrganizationModal";

const OrganizationPicker = (props) => {
  const auth = useAuth();
  const { orgInvities, orgList } = props;

  const [getDeleteAvailabilities] = useGetDeleteAvailabilitiesMutation();
  // useEffect(() => {
  //   if (props.user?.isCreatingOrganization) {
  //     props.history.push("/creating-organization");
  //   }
  // }, [props.user]);

  useEffect(() => {
    props.fetchInvites();
  }, []);

  useEffect(() => {
    if (props.currentApplication !== activeApplicationEnum.Outbase) {
      document.body.classList.add("outbossTheme");
    } else {
      document.body.classList.remove("outbossTheme");
    }
  }, [props.currentApplication]);

  useEffect(() => {
    auth.signinSilent();
  }, [orgInvities]);

  const loginToOrganization = (event, organizationAccessRemoved, organizationId) => {
    event.preventDefault();
    if (!organizationAccessRemoved) {
      let path = "/" + organizationId + "/dashboard";
      api(
        formUrl(API.users.logUserLoginToOrganization, {
          organizationId: organizationId,
        })
      )
        .then((response) => {
          props.history.push(path);
        })
        .catch((error) => {});
    }
  };

  const [modalActionType, setModalActionType] = useState(organizationModalActionType.Delete);
  const [organizationId, setOrganizationId] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(0);

  const handleDeleteLeaveOrganizationModalShow = (modalActionType, organizationId) => {
    setModalActionType(modalActionType);
    setOrganizationId(organizationId);
    deleteLeaveOrganization.show();
  };
  const deleteLeaveOrganization = useModal();
  const deleteActiveOrganization = useModal();
  const deleteConfirm = useModal();

  const checkDeleteAvailability = (organizationId) => {
    setOrganizationId(organizationId);
    const data = {
      organizationId: organizationId,
      userId: props.user.id,
    };

    getDeleteAvailabilities(data)
      .unwrap()
      .then((response) => {
        if (response) {
          switch (response) {
            case OrganizationDeleteStatusEnum.FreeTrialDelete:
              setDeleteStatus(OrganizationDeleteStatusEnum.FreeTrialDelete);
              handleDeleteLeaveOrganizationModalShow(organizationModalActionType.Delete, organizationId);
              break;
            case OrganizationDeleteStatusEnum.PaidActiveDelete:
              setDeleteStatus(OrganizationDeleteStatusEnum.PaidActiveDelete);
              deleteActiveOrganization.show();
              break;
            case OrganizationDeleteStatusEnum.PaidCanceledInactiveDelete:
              setDeleteStatus(OrganizationDeleteStatusEnum.PaidCanceledInactiveDelete);
              handleDeleteLeaveOrganizationModalShow(organizationModalActionType.Delete, organizationId);
              break;
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let list = null;

  if (props.error) {
    list = <h2>Error getting companies</h2>;
  }

  if (props.isLoading) {
    list = <SkeletonTableRow />;
  }

  if (props.isLoaded) {
    let allOrg = [...props.orgList, ...props.orgInvities];
    list = allOrg.map((org) => {
      if (org.inviteId === null || org.inviteId === undefined) {
        let row = (
          <tr key={org.id} className={org.organizationAccessRemoved ? styles.accessRemoved : ""}>
            <td>
              <NavLink onClick={(e) => loginToOrganization(e, org.organizationAccessRemoved, org.id)} to={"/" + org.id + "/dashboard"}>
                <UserPhoto alt="org-photo" isCompanyImage={true} size="medium" src={org.imageUrl} />
                <div
                  className={
                    org.organizationAccessRemoved ? `${styles.organizationDisabled} font600 mL10` : `${styles.organization} font600 mL10`
                  }
                >
                  {org.name}
                </div>
              </NavLink>
            </td>
            <td disabled={true}>
              <NavLink onClick={(e) => loginToOrganization(e, org.organizationAccessRemoved, org.id)} to={"/" + org.id + "/dashboard"}>
                <div className={org.organizationAccessRemoved ? styles.organizationDisabled : styles.organization}>{org.domain}</div>
              </NavLink>
            </td>
            <td>
              <span className={styles.myOrgDate}>{org.createdDateTime}</span>
            </td>
            <td>
              <span className={styles.spanOrganization}>{org.roleName}</span>
            </td>
            <td className={styles.actionDropdown}>
              <Dropdown drop="left" disabled={org.organizationAccessRemoved ? true : false}>
                <Dropdown.Toggle className={styles.dropdownButton} id={org.name} aria-label="action">
                  <MoreVert />
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.actionList} alignRight>
                  {org.role === organizationRoleEnum.Owner ? (
                    <Dropdown.Item className={styles.dropdownItem} onClick={() => checkDeleteAvailability(org.id)}>
                      Delete
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      className={styles.dropdownItem}
                      onClick={() => handleDeleteLeaveOrganizationModalShow(organizationModalActionType.Leave, org.id)}
                    >
                      Leave Organization
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        );

        return org.organizationAccessRemoved ? (
          <OverlayTrigger
            key={"disabled-org"}
            placement={"top"}
            overlay={
              <Popover id={`disabled-org-tooltip`}>
                <Popover.Body>This organization is disabled by the system admin.</Popover.Body>
              </Popover>
            }
          >
            {row}
          </OverlayTrigger>
        ) : (
          row
        );
      }

      return (
        <tr className={`${styles.inviteOrgRow} font_italic`}>
          <td>
            <div className={styles.columnWrapper}>
              <UserPhoto alt="org-photo" isCompanyImage={true} size="medium" src={org.imageUrl} />
              <div className={`${styles.organization} font600 mL10`}>{org.name}</div>
            </div>
          </td>
          <td>
            <div className={styles.organization}>{org.domain}</div>
          </td>
          <td>
            <span className={styles.myOrgDate}>{org.createdDateTime}</span>
          </td>
          <td>
            <div className={`${styles.columnWrapper}`}>
              <span>Invited</span>
              {/* <CheckIcon
                className={styles.acceptButton}
                onClick={() => props.acceptInvite(org.inviteId)}
              />
              <CloseIcon
                className={styles.declineButton}
                onClick={() => props.declineInvite(org.inviteId)}
              /> */}
            </div>
          </td>
          <td className={styles.actionDropdown}>
            <div>
              <span className={styles.acceptButton} onClick={() => props.acceptInvite(org.inviteId)}>
                Accept
              </span>
              /
              <span className={styles.declineButton} onClick={() => props.declineInvite(org.inviteId)}>
                Decline
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  let user = <LoggedInUser orgPage={true} history={props.history} />;

  let logo = (
    <div className={styles.logoDetails}>
      <CombinationMarkLogoDark width={150} height={37} />
    </div>
  );

  return (
    <>
      <div className={styles.navigationMenu}>
        <HeaderTitle className="p0 width_100" title={logo} rightContent={user} />
      </div>
      <div className={styles.organizationPickerWrapper}>
        <HeaderTitle title={"My Organizations"} />

        <div className={styles.tableWrap}>
          <NavLink className={styles.createOrgButton} to="register-organization">
            <Button id="createOrgBtn" variant="primary" classes="pull-right mB15">
              <TeamLineIcon size={18} className="mR5" />
              Create Organization
            </Button>
          </NavLink>
          <table className={styles.organizationTable}>
            <thead>
              <tr>
                <th>Organization Name</th>
                <th>Domain</th>
                <th>Date Joined</th>
                <th>Role</th>
                <th className={styles.actionHeader}></th>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </table>
        </div>

        <DeleteLeaveOrganizationForm
          handleClose={deleteLeaveOrganization.hide}
          showModal={deleteLeaveOrganization.isVisible}
          modalActionType={modalActionType}
          organizationId={organizationId}
          deleteStatus={deleteStatus}
          setShowDeleteConfirmModal={deleteConfirm.show}
        />

        <DeleteConfirmModal
          showModal={deleteConfirm.isVisible}
          modalActionType={modalActionType}
          organizationId={organizationId}
          handleClose={deleteConfirm.hide}
          userId={props.user.id}
        />

        <DeleteActiveOrganizationModal
          organizationId={organizationId}
          handleClose={deleteActiveOrganization.hide}
          showModal={deleteActiveOrganization.isVisible}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orgList: state.user.organizations,
    user: state.user,
    orgInvities: state.invites.orgInvities,
    isLoading: state.invites.isLoading,
    isLoaded: state.invites.isLoaded,
    currentApplication: state.user.application !== undefined ? state.user.application : activeApplicationEnum.Outbase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvites: () => dispatch(fetchOrganizationInvites()),
    acceptInvite: (orgId) => dispatch(acceptInvite(orgId)),
    declineInvite: (orgId) => dispatch(declineInvite(orgId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPicker);
