export const SIGNALR_CONSTANTS = {
  AUDIENCE_CONTACTS_IMPORT_STARTED: "AudienceContactsImportStarted",
  AUDIENCE_CONTACTS_IMPORT_INFO: "AudienceContactsImportInfo",
  IMPORT_PROSPECTS_STARTED: "ImportProspectsStarted",
  IMPORT_PROSPECTS_UPDATE: "ImportProspectsUpdate",
  IMPORT_PROSPECTS_FINISHED: "ImportProspectsFinished",

  EXCLUSIONS_IMPORT_INFO: "ExclusionsImportInfo",

  IN_APP_NOTIFICATIONS_GET: "InAppNotificationsGet",

  CONTRACT_CHANGED: "ContractChanged",
  PLAN_CHANGED: "PlanChanged",
  FAILED_PAYMENT: "FailedPayment",
  INVOICE_ADDED: "InvoiceAdded",

  ORGANIZATION_ACCESS_REMOVED: "OrganizationAccessRemoved",
  OUTBASE_ACCESS_REMOVED: "OutbaseAccessRemoved",

  ORGANIZATION_CREATED: "OrganizationCreated",
  ORGANIZATION_UPDATED: "OrganizationUpdated",

  ASSISTANT_EMAIL_ACCOUNT_CONNECTED: "AssistantEmailAccountConnected",

  CAMPAIGN_WIZARD_CAMPAIGN_COMPLETED: "CampaignWizardCampaignCreated",
  CAMPAIGN_WIZARD_AUDIENCE_COMPLETED: "CampaignWizardAudienceCreated",
  CAMPAIGN_WIZARD_TEMPLATES_COMPLETED: "CampaignWizardTemplatesCreated",
  CAMPAIGN_WIZARD_COMPLETED: "CampaignWizardCompleted",

  CONNECTION_TEST_INFO: "ConnectionTestInfo",

  AUDIENCE_INITIATED: "AudienceInitiated",
};
