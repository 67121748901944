import React from "react";
import { Row, Col } from "react-bootstrap";

//Helpers
import { emailTemplateTypeEnum } from "../../../../helper/enums/emailTemplateTypeEnum";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";

const TestInfoStep = (props) => {
  const {
    subjectValue,
    initialEmailTemplatesOptions,
    emailTemplate,
    initialEmailTemplateChosen,
    initialEmailTemplateId,
    emailAccountOptions,
    emailAccountIdValue,
    sendToValue,
  } = props;
  return (
    <Row>
      <Col xl={12}>
        <FormikInput label="Subject" name={"subject"} placeholder="Type here..." value={subjectValue} />
      </Col>
      {parseInt(emailTemplate.emailTemplateType) === emailTemplateTypeEnum.FollowUp ||
      props.typeActiveStep === emailTemplateTypeEnum.FollowUp ? (
        <Col xl={12}>
          <FormikSelect
            name="initialEmailTemplateId"
            label="Initial email template"
            isMenuPortalTargetDisabled={true}
            options={initialEmailTemplatesOptions}
            onChange={(e) => {
              let initialEmailSubject = initialEmailTemplateChosen(e);
              props.setFieldValue("initialEmailTemplateId", e);
              props.setFieldValue("dictionary[INITIAL_EMAIL_SUBJECT]", initialEmailSubject);
            }}
            placeholder="Choose initial email template"
            value={initialEmailTemplateId}
          />
        </Col>
      ) : null}
      <Col xl={12}>
        <FormikSelect
          name="emailAccountId"
          label="Send from"
          options={emailAccountOptions}
          placeholder="Choose email account"
          value={emailAccountIdValue}
          isMenuPortalTargetDisabled={true}
        />
      </Col>
      <Col xl={12}>
        <FormikInput label="Send To" name={"sendTo"} placeholder="Type here..." value={sendToValue} disabled={true} />
      </Col>
    </Row>
  );
};

export default TestInfoStep;
