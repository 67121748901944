import React from "react";

const IconAssignToCampaign = (props) => (
  <svg className={props.imgStyle} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17_2)">
      <path
        d="M13.5592 8.411C13.626 8.18723 13.6313 7.94954 13.5742 7.7231C13.5172 7.49662 13.4 7.28977 13.2351 7.12439L6.48592 0.386568C6.32161 0.220839 6.1156 0.102539 5.88965 0.0441538C5.6637 -0.0142317 5.42617 -0.0105377 5.20214 0.054846C4.97811 0.12023 4.77588 0.244878 4.6168 0.415637C4.45772 0.586395 4.34769 0.796932 4.29832 1.02502L3.30174 5.51042L0.999087 7.81148C0.385992 8.42142 0.0291927 9.2426 0.00171339 10.107C-0.025766 10.9714 0.278153 11.8135 0.85127 12.4612C1.42438 13.1089 2.22336 13.513 3.08467 13.5909C3.94597 13.6688 4.80448 13.4145 5.48448 12.8802L7.57 14.9641C7.7578 15.1519 7.98078 15.3009 8.22609 15.4025C8.47148 15.5042 8.73444 15.5564 9.00005 15.5564C9.26567 15.5564 9.52863 15.5042 9.77394 15.4025C10.0193 15.3009 10.2423 15.1519 10.4301 14.9641C10.6179 14.7763 10.7669 14.5533 10.8685 14.308C10.9701 14.0626 11.0224 13.7997 11.0224 13.5341C11.0224 13.2685 10.9701 13.0055 10.8685 12.7601C10.7669 12.5148 10.6179 12.2918 10.4301 12.104L8.54552 10.2179L12.5967 9.31851C12.8238 9.26756 13.033 9.15642 13.2024 8.99674C13.3717 8.83706 13.495 8.63476 13.5592 8.411ZM3.40707 12.3163C2.99206 12.3149 2.58672 12.1909 2.24191 11.9599C1.89711 11.729 1.6282 11.4013 1.46895 11.0181C1.3097 10.6348 1.2672 10.2131 1.34678 9.80579C1.42636 9.39846 1.62447 9.02373 1.91626 8.72864L3.89321 6.75169L6.86511 9.72357L4.88816 11.7005C4.69415 11.8958 4.46339 12.0508 4.20919 12.1565C3.95498 12.2622 3.68237 12.3165 3.40707 12.3163ZM9.72681 13.5348C9.7265 13.6786 9.68364 13.8189 9.60369 13.9383C9.52373 14.0577 9.41018 14.1506 9.27749 14.2056C9.14472 14.2605 8.99866 14.2749 8.85772 14.247C8.71679 14.2191 8.58729 14.15 8.48555 14.0486L6.43083 11.9922L7.45819 10.9648L9.51455 13.0196C9.65074 13.1565 9.72704 13.3418 9.72681 13.5348ZM7.98972 9.01385L4.60297 5.62871L5.57524 1.3005L12.3098 8.05291L7.98972 9.01385Z"
        fill="#5C656F"
      />
      <path
        d="M9.07839 0C8.9065 0 8.74161 0.0682901 8.62012 0.189847C8.49856 0.311404 8.43027 0.476271 8.43027 0.648179C8.43027 0.820089 8.49856 0.984954 8.62012 1.10651C8.74161 1.22806 8.9065 1.29636 9.07839 1.29636C9.93799 1.29636 10.7623 1.63781 11.3701 2.24559C11.9779 2.85338 12.3193 3.67771 12.3193 4.53725C12.3193 4.70916 12.3876 4.87403 12.5092 4.99558C12.6307 5.11714 12.7956 5.18543 12.9675 5.18543C13.1394 5.18543 13.3043 5.11714 13.4259 4.99558C13.5473 4.87403 13.6157 4.70916 13.6157 4.53725C13.6157 3.3339 13.1376 2.17983 12.2867 1.32893C11.4358 0.478031 10.2818 0 9.07839 0Z"
        fill="#5C656F"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_2">
        <rect width="14" height="15.5556" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconAssignToCampaign;
