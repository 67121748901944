import React from "react";
import { Container, Row, Col } from "react-bootstrap";

//Styles
import styles from "./EmailTemplates.module.scss";

//Components
import Modal from "@components/UI/Modal/Modal";

const PreviewEmailTemplate = (props) => {
  const fourthButtonDisabled = props.emailTemplate.emailTemplateStatus === 1;
  return (
    <Modal
      title="Preview Email Template"
      btnType="submit"
      size="lg"
      threeButtonsLayout
      show={props.showModal}
      handleClose={props.handleClose}
      saveButtonText="Publish"
      handleSave={props.handlePublish}
      saveButtonDisabled={fourthButtonDisabled}
      thirdButtonText="Send test email"
      thirdButtonVariant="secondary"
      thirdButtonClick={() => props.handleTestEmailFormShow(props.emailTemplate)}
      fourthButtonText="Edit"
      fourthButtonVariant="secondary"
      handleFourthButtonClick={props.handleEdit}
      saveButtonPermission={props.saveButtonPermission}
      thirdButtonPermission={props.thirdButtonPermission}
    >
      <Container>
        <Row>
          <Col xl={12} className={styles.previewModalBody}>
            <h4>{props.emailTemplate.emailTemplateName}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: props.emailTemplate.emailTemplateHtml,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default PreviewEmailTemplate;
