import * as dataTableActionTypes from "../../../hor/dataTableActionTypes";
import api, { API } from "../../../../helper/api/api";

const name = "EMAIL_TEMPLATES";

export const fetchEmailTemplatesRequest = () => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_REQUEST}`,
  };
};

export const fetchEmailTemplatesSuccess = (setup) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_SUCCEEDED}`,
    result: setup,
  };
};

export const fetchEmailTemplatesFailed = (error) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FETCH_FAILED}`,
    result: error,
  };
};

export const fetchEmailTemplates = (param) => {
  return (dispatch) => {
    dispatch(fetchEmailTemplatesRequest());
    return api(API.emailTemplates.post, param)
      .then((response) => {
        dispatch(fetchEmailTemplatesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEmailTemplatesFailed(error.message));
      });
  };
};

export const initiate = (state) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_INITIATE}`,
    result: state,
  };
};

export const changePage = (page) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CHANGE_PAGE}`,
    result: page,
  };
};

export const sortData = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SORT_DATA}`,
    result: obj,
  };
};

export const numRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_NUM_RECORDS}`,
    result: obj,
  };
};

export const searchRecords = (value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SEARCH_RECORDS}`,
    result: value,
  };
};

export const filterRecords = (obj) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_FILTER_RECORDS}`,
    result: obj,
  };
};

export const setFilter = (type, value) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_SET_FILTER}`,
    filterType: type,
    filterValue: value,
  };
};

export const clearFilter = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_FILTER}`,
    filterType: type,
  };
};

export const clearInitiated = (type) => {
  return {
    type: `${name}_${dataTableActionTypes.DT_CLEAR_INITIATED}`,
  };
};
