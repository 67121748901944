import React, { useState, FC, ImgHTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Avatar from "react-avatar";

//Styles
import styles from "./UserPhoto.module.scss";

//Images & Icons
import CompanyDefaultLogo from "../../../assets/Icons/CompanyDefaultLogo/CompanyDefaultLogo";

interface UserPhotoProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  isCompanyImage?: boolean;
  isEditable?: boolean;
  alt?: string;
  name?: string;
  size?: string;
}

const UserPhoto: FC<UserPhotoProps> = (props) => {
  const { src, isCompanyImage, isEditable, alt, name } = props;
  const size = props.size || "default";

  let image = null;

  const [isUndefined, updateIsUndefined] = useState(false);

  const onError = () => {
    updateIsUndefined(true);
  };

  if (src == null || src === "" || isUndefined) {
    if (isCompanyImage) {
      const styleWrap = styles.defaultPhotoCompanyWrap + " " + styles[size];
      let style = styles.defaultPhotoCompany + " " + styles[size];

      if (isEditable) {
        style += " " + styles.pointer;
      }

      image = (
        <div className={styleWrap}>
          <CompanyDefaultLogo imgStyle={styles.companyAvatar} />
        </div>
      );
    } else if (name) {
      let style = styles.defaultPhoto + " " + styles[size];
      image = <Avatar round name={name} className={style} />;
    } else {
      let style = styles.defaultPhoto + " " + styles[size];

      if (isEditable) {
        style += " " + styles.pointer;
      }

      image = <FontAwesomeIcon icon={faUserCircle} className={style} />;
    }
  } else {
    let style = styles.userPhoto + " " + styles[size];

    if (isEditable) {
      style += " " + styles.pointer;
    }

    image = <img className={style} src={src} alt={alt} onError={onError} />;
  }

  return image;
};

export default UserPhoto;
