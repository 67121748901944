import React from "react";
import { XAxis, YAxis, Tooltip, Area, AreaChart } from "recharts";

const AreaLineChart = (props) => {
  return (
    <AreaChart
      style={{ zIndex: "111", width: "100%" }}
      width={props.chartWidth}
      height={props.chartHeight}
      data={props.data}
      margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis hide={true} dataKey="name" />
      <YAxis hide={true} />
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <Tooltip />
      {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" /> */}
      <Area
        type="monotone"
        dataKey="value"
        stroke="#82ca9d"
        fillOpacity={1}
        fill="url(#colorPv)"
      />
    </AreaChart>
  );
};

export default AreaLineChart;
