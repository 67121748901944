import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: "",
  users: [],
  listusers: [],
};

const changeUserRole = (state, action) => {
  const newState = { ...state };
  const userRole = action.result;

  const index = newState.users.findIndex(
    (u) => u.userId === userRole.id && u.email === userRole.email
  );
  const user = { ...newState.users[index] };
  user.role = userRole.role;
  user.roleName = userRole.roleName;
  newState.users[index] = user;

  return newState;
};

const changeUserRoleFailed = (state, action) => {
  const newState = { ...state };
  const userRole = action.result.prevState;

  const index = newState.users.findIndex((u) => u.userId === userRole.id);
  const user = { ...newState.users[index] };
  user.role = userRole.role;
  user.roleName = userRole.roleName;
  newState.users[index] = user;

  return newState;
};

const removeUserRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const removeUserFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const removeUserSuccess = (state, action) => {
  const newState = { ...state };
  const userId = action.result.id;
  const index = newState.users.findIndex((u) => u.userId === userId);

  if (index > 0) {
    newState.users.splice(index, 1);
    newState.isLoaded = true;
    newState.isLoading = false;
    return newState;
  }

  return state;
};

const fetchListUsersRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchListUsersSuccess = (state, action) => {
  const newState = { ...state };
  let list = { ...state.listusers };
  list = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.listusers = list;
  return newState;
};

const fetchListUsersFailed = (state, action) => {
  const newState = { ...state };
  newState.error = action.result;
  newState.isLoading = false;
  return newState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case actionTypes.FETCH_USERS_SUCCEEDED:
      return {
        isLoading: false,
        isLoaded: true,
        users: action.result,
        error: "",
      };
    case actionTypes.FETCH_USERS_FAILED:
      return {
        isLoading: false,
        isLoaded: false,
        users: [],
        error: action.result,
      };
    case actionTypes.ADD_USER_SUCCEEDED:
      return {
        ...state,
        users: [...state.users, action.result],
      };
    case actionTypes.CHANGE_ROLE_REQUEST:
      return changeUserRole(state, action);
    case actionTypes.CHANGE_ROLE_FAILED:
      return changeUserRoleFailed(state, action);
    case actionTypes.REMOVE_USER_REQUEST:
      return removeUserRequest(state, action);
    case actionTypes.REMOVE_USER_FAILED:
      return removeUserFailed(state, action);
    case actionTypes.REMOVE_USER_SUCCEEDED:
      return removeUserSuccess(state, action);
    case actionTypes.FETCH_LIST_USERS_REQUEST:
      return fetchListUsersRequest(state, action);
    case actionTypes.FETCH_LIST_USERS_SUCCEEDED:
      return fetchListUsersSuccess(state, action);
    case actionTypes.FETCH_LIST_USERS_FAILED:
      return fetchListUsersFailed(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
