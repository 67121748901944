import React, { useEffect, useState } from "react";
import Moment from "moment";

//Redux
import { connect } from "react-redux";

//Style
import styles from "./HeaderAlertNotification.module.scss";

//helpers
import { termsAndPaymentStatusEnum } from "../../helper/enums/contractStatusEnum";
import { pricingPlanEnum } from "../../helper/pricingPlanHelper";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";

//Components
import AlertNotification from "../UI/AlertNotification/AlertNotification";

const HeaderAlertNotification = (props) => {
  const { contractStatus, user, plan, hasNewTotalCount, nextBillingDate, waitingDowngrade, nextPlanName } = props;
  const [infoAlert, setInfoAlert] = useState(null);
  const [dangerAlert, setDangerAlert] = useState(null);
  let dateFormat = useOrgDateFormat();

  useEffect(() => {
    let infoNotificationMessage = "";
    let dangerNotificationMessages = [];
    let waitBannerMessage = "We are processing the payment and your information will be updated shortly";
    if (waitingDowngrade)
      waitBannerMessage =
        "We've received your request. Changes to your " +
        nextPlanName +
        " Plan are scheduled and will take place at the end of current billing period on " +
        Moment(nextBillingDate).format(dateFormat);

    if (contractStatus === termsAndPaymentStatusEnum.awaitingPaymentConfirmation || waitingDowngrade) {
      infoNotificationMessage = waitBannerMessage;
    }

    if (contractStatus === termsAndPaymentStatusEnum.accepted && plan === pricingPlanEnum.trial) {
      infoNotificationMessage = <span>{waitBannerMessage}</span>;
    }

    if (hasNewTotalCount) {
      infoNotificationMessage = "You have new messages in your inbox refresh your inbox to get them.";
    }

    if (user.adminOrganization && user.adminOrganization.id > 0) {
      dangerNotificationMessages.push("You are now logged in as an Admin in " + user.adminOrganization.name);
    }
    if (user.impersonatorId && user.impersonatorId > 0) {
      dangerNotificationMessages.push("You are now logged in as " + user.firstName + " " + user.lastName + " in Outbase");
    }

    if (infoNotificationMessage !== "") {
      const infoNotification = (
        <AlertNotification
          variant="info_alert"
          notificationWrapperClass={dangerNotificationMessages.length > 0 ? styles.infoNotificationWithDanger : styles.infoNotification}
          dismissible="dismissible"
        >
          {infoNotificationMessage}
        </AlertNotification>
      );
      setInfoAlert(infoNotification);
    } else {
      setInfoAlert(null);
    }

    if (dangerNotificationMessages.length > 0) {
      const alertNotification = (
        <AlertNotification
          variant="danger_alert"
          notificationWrapperClass={infoNotificationMessage !== "" ? styles.dangerNotificationWithInfo : styles.dangerNotification}
          dismissible="dismissible"
        >
          {dangerNotificationMessages.map((message, index) => {
            return (
              <>
                <span className={index > 0 ? "mL26" : ""}>{message}</span>
                <br />
              </>
            );
          })}
        </AlertNotification>
      );
      setDangerAlert(alertNotification);
    } else {
      setDangerAlert(null);
    }
  }, [contractStatus, user.adminOrganization, user.impersonatorId, plan, hasNewTotalCount, waitingDowngrade]);

  return (
    <>
      {infoAlert}
      {dangerAlert}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    contractStatus: state.billingAndPayment.contractStatus,
    waitingDowngrade: state.billingAndPayment.waitingDowngrade,
    nextBillingDate: state.billingAndPayment.nextBillingDate,
    nextPlanName: state.billingAndPayment.nextPlanName,
    plan: state.billingAndPayment.creditInfo.plan,
    hasNewTotalCount: state.responderInboxTable.hasNewTotalCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAlertNotification);
