import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//Redux
import { fetchPurchaseTemplate } from "../../../store/EmailTemplates/Global/actions/purchaseTemplates";
import { fetchBillingInfo, fetchCreditInfo } from "../../../store/BillingAndPayment/actions/billingAndPayment";

//Styles
import styles from "./TemplateRequest.module.scss";

//Components
import HeaderTitle from "../../../components/UI/HeaderTitle/HeaderTitle";
import SectionTitle from "../../../components/UI/SectionTitle/SectionTitle";
import RequestDetail from "../RequestDetail/RequestDetail";
import MessagePreview from "../MessagePreview/MessagePreview";
import BreadcrumbElement from "../../../components/UI/BreadcrumbElement/BreadcrumbElement";

const TemplateRequest = (props) => {
  const { fetchPurchaseTemplate, purchaseTemplate, fetchBillingInfo, billingInfo, fetchCreditInfo, creditCardInfo } = props;
  const { templateRequestId } = useParams();

  useEffect(() => {
    if (templateRequestId != null) fetchPurchaseTemplate(templateRequestId);
  }, [templateRequestId]);

  useEffect(() => {
    fetchBillingInfo();
    fetchCreditInfo();
  }, []);

  const breadcrumbs = [
    {
      href: "/" + props.orgId + "/email-templates/my-orders",
      text: "Email Templates",
    },
    { active: true, text: "Template Request" },
  ];

  return (
    <>
      <BreadcrumbElement items={breadcrumbs} />
      <HeaderTitle title="Pre Pitch Process" />
      <div className={`${styles.contentWrapper} flex`}>
        <div className={styles.messagePreview}>
          <SectionTitle title="Message Preview" />
          <MessagePreview emailTemplate={purchaseTemplate.emailTemplate} />
        </div>
        <div className={styles.requestDetail}>
          <SectionTitle title="Request Details" />
          <RequestDetail purchaseTemplate={purchaseTemplate} billingInfo={billingInfo} creditCardInfo={creditCardInfo} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.purchaseTemplate.isLoading,
    isLoaded: state.purchaseTemplate.isLoaded,
    error: state.purchaseTemplate.error,
    purchaseTemplate: state.purchaseTemplate.purchaseTemplate,
    billingInfo: state.billingAndPayment.billingInfo,
    creditCardInfo: state.billingAndPayment.creditCardInfo,
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPurchaseTemplate: (data) => dispatch(fetchPurchaseTemplate(data)),
    fetchBillingInfo: () => dispatch(fetchBillingInfo()),
    fetchCreditInfo: () => dispatch(fetchCreditInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateRequest);
