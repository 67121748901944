import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import _ from "lodash";

//Redux
import { fetchInitialEmailTemplates } from "../../store/EmailTemplates/Global/actions/emailTemplates";
import { fetchEmailAccountsSelectList } from "../../store/EmailAccounts/Global/actions/emailAccounts";

//Helpers
import api, { API } from "../../helper/api/api";
import { errorResponseToList } from "../../helper/errorHelper";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { emailTemplateTypeEnum } from "../../helper/enums/emailTemplateTypeEnum";

//Components
import Modal from "../../components/UI/Modal/Modal";
import Permission from "../../components/auth/Permission";
import { splitEmailTemplateVariables } from "../../utils/splitEmailTemplateVariables";
import HorizontalStepper from "../../components/UI/HorizontalStepper/HorizontalStepper";
import TestInfoStep from "./SendTestEmailFormSteps/TestInfoStep/TestInfoStep";
import TestVariableStep from "./SendTestEmailFormSteps/TestVariableStep/TestVariableStep";

//Hooks
import { useGetTemplateVariablesQuery } from "@api/emailTemplateApi";

const SendTestEmailForm = (props) => {
  const { emailTemplate, fetchInitialEmailTemplates, initialEmailTemplates } = props;
  const [variables, setVariables] = useState(null);
  const [dictionary, setDictionary] = useState({});
  const [basicVariables, setBasicVariables] = useState(null);
  const [customVariables, setCustomVariables] = useState(null);
  const [initialEmailVariables, setInitialEmailVariables] = useState(null);
  const [initialEmailTemplatesOptions, setInitialEmailTemplatesOptions] = useState([{ label: "None", value: 0 }]);
  const [initialEmailTemplateDictionary, setInitialEmailTemplateDictionary] = useState({});
  const [initialTemplatesFetched, setInitialTemplatesFetched] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  // Queries
  const { data: emailTemplateVariables } = useGetTemplateVariablesQuery();

  useEffect(() => {
    fetchEmailAccountsSelectList();
  }, []);

  const steps = [
    {
      label: "Test Info",
    },
    {
      label: "Test Variables",
    },
  ];

  useEffect(() => {
    if (
      (parseInt(emailTemplate.emailTemplateType) === emailTemplateTypeEnum.FollowUp ||
        props.typeActiveStep === emailTemplateTypeEnum.FollowUp) &&
      initialTemplatesFetched === false
    ) {
      setInitialTemplatesFetched(true);
      fetchInitialEmailTemplates();
    }
  }, [emailTemplate]);

  useEffect(() => {
    if (initialEmailTemplates) {
      let initialTemplateOptions = initialEmailTemplates.map((template) => {
        return { label: template.name, value: template.id };
      });
      setInitialEmailTemplatesOptions([...[{ label: "None", value: 0 }], ...initialTemplateOptions]);
    }
  }, [initialEmailTemplates]);

  const findBasicAndCustomVariables = () => {
    let tempBasicVariables = [];
    let tempCustomVariables = [];
    let allBasicVariables = [];

    if (emailTemplateVariables !== undefined && emailTemplateVariables.length > 0) {
      emailTemplateVariables.map((type) => {
        type.emailTemplateVariables.map((variable) => {
          allBasicVariables.push(variable.name);
        });
      });
    }

    if (allBasicVariables.length > 0) {
      if (variables !== null) {
        variables.map((variable) => {
          if (allBasicVariables.indexOf(variable) > -1) {
            if (tempBasicVariables.indexOf(variable) < 0) {
              tempBasicVariables.push(variable);
            }
          } else {
            if (variable !== null) {
              tempCustomVariables.push(variable);
            }
          }
        });
      }
    }

    setBasicVariables(tempBasicVariables);
    setCustomVariables(tempCustomVariables);
  };

  const setCurrentEmailTemplateVariables = () => {
    let variablesEmailTextTemp = null;
    let variablesEmailSubjectTemp = null;

    if (!_.isEmpty(emailTemplate.emailTemplateText)) {
      variablesEmailTextTemp = splitEmailTemplateVariables(emailTemplate.emailTemplateText);
    }

    if (!_.isEmpty(emailTemplate.emailTemplateSubject)) {
      variablesEmailSubjectTemp = splitEmailTemplateVariables(emailTemplate.emailTemplateSubject);
    }
    if (variablesEmailTextTemp !== null && variablesEmailSubjectTemp !== null) {
      setVariables(_.concat(variablesEmailTextTemp, variablesEmailSubjectTemp));
    }
  };

  useEffect(() => {
    if (props.showModal) {
      setCurrentEmailTemplateVariables();
    }
  }, [props.showModal]);

  const setDictionaryValuesForForm = (variablesList, dictionary, setDictionary) => {
    if (variablesList !== null && variablesList.length > 0) {
      variablesList.map((variable) => {
        var variableName = variable;
        var result = "";

        if (variableName !== null) {
          if (emailTemplateVariables !== undefined && emailTemplateVariables.length > 0) {
            emailTemplateVariables.map((type) => {
              type.emailTemplateVariables.map((variable) => {
                if (variableName === variable.name) {
                  result = variable.example;
                }
              });
            });

            if (result !== "") {
              dictionary[variableName] = result;
              setDictionary(dictionary);
              return result;
            } else {
              dictionary[variableName] = "{" + variableName + "}";
              setDictionary(dictionary);
              return "{" + variableName + "}";
            }
          } else {
            dictionary[variableName] = "{" + variableName + "}";
            setDictionary(dictionary);
            return "{" + variableName + "}";
          }
        }
      });
    }
  };

  useEffect(() => {
    setDictionaryValuesForForm(variables, dictionary, setDictionary);
    findBasicAndCustomVariables();
  }, [variables]);

  const addInitialEmailTemplateVariables = (initialEmailTemplateHTML) => {
    let initialVariablesTemp = null;

    if (!_.isEmpty(initialEmailTemplateHTML)) {
      initialVariablesTemp = splitEmailTemplateVariables(initialEmailTemplateHTML);
    }

    setDictionaryValuesForForm(initialVariablesTemp, initialEmailTemplateDictionary, setInitialEmailTemplateDictionary);
    setInitialEmailVariables(initialVariablesTemp);
  };

  const initialEmailTemplateChosen = (initialEmailTemplateId) => {
    let initialEmailSubject = "";
    if (initialEmailTemplateId > 0) {
      let initialEmailTemplate = initialEmailTemplates.find((em) => em.id === initialEmailTemplateId);
      let initialEmailTemplateHTML = initialEmailTemplate?.html;
      initialEmailSubject = initialEmailTemplate?.subject;

      var variablesEmailSubject = splitEmailTemplateVariables(initialEmailTemplate?.subject);
      if (
        emailTemplateVariables !== undefined &&
        emailTemplateVariables.length > 0 &&
        variablesEmailSubject !== undefined &&
        variablesEmailSubject.length > 0
      ) {
        variablesEmailSubject.map((subjectVariable) => {
          emailTemplateVariables.map((type) => {
            type.emailTemplateVariables.map((variable) => {
              if (subjectVariable === variable.name) {
                initialEmailSubject = initialEmailSubject.replace("{{" + subjectVariable + "}}", variable.example);
              }
            });
          });
        });
      }

      addInitialEmailTemplateVariables(initialEmailTemplateHTML);
    } else {
      setCurrentEmailTemplateVariables();
      initialEmailSubject = "{INITIAL_EMAIL_SUBJECT}";
    }

    return initialEmailSubject;
  };

  const handleCloseModal = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      setActiveStep(0);
      props.handleClose();
    }
  };

  return (
    <div>
      <Permission has={PERMISSIONS.emailTemplates.sendTestEmail}>
        <Formik
          initialValues={{
            sendTo: props.userEmail,
            subject: "Test: " + emailTemplate.emailTemplateSubject,
            dictionary: dictionary,
            emailAccountId: 0,
            initialEmailTemplateId: 0,
            initialEmailTemplateDictionary: initialEmailTemplateDictionary,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            emailAccountId: Yup.number().moreThan(0, "Required").required("Required"),
            sendTo: Yup.string().required("Required"),
            subject: Yup.string().required("Required"),
          })}
          onSubmit={(values, actions) => {
            let continueVar = true;
            if (emailTemplate.emailTemplateHtml === null || emailTemplate.emailTemplateHtml === "") {
              toast.error(toastMessages.emailTemplate.errorSendTestEmail);
            } else {
              variables.forEach((variable) => {
                if (!(variable in values.dictionary)) {
                  values.dictionary[variable] = "";
                }
                if (values.dictionary[variable] === "") {
                  continueVar = false;
                  actions.setFieldError("dictionary[" + variable + "]", "Required");
                  actions.setSubmitting(false);
                  return;
                }
              });

              if (continueVar) {
                let data = {
                  sendTo: values.sendTo,
                  name: props.emailName,
                  subject: values.subject,
                  text: emailTemplate.emailTemplateText,
                  html: emailTemplate.emailTemplateHtml,
                  emailAccountId: values.emailAccountId > 0 ? values.emailAccountId : 0,
                  dictionaryOfVariable: values.dictionary,
                  initialEmailTemplateId: values.initialEmailTemplateId,
                  initialEmailTemplateDictionaryOfVariable: values.initialEmailTemplateDictionary,
                  chaserAddSignature: emailTemplate.chaserAddSignature,
                  chaserIncludeThread: emailTemplate.chaserIncludeThread,
                };

                api(API.emailTemplates.sendTestEmail, data)
                  .then((res) => {
                    if (res.data.success) toast.success(toastMessages.emailTemplate.successSendTestEmail);

                    if (!res.data.success) toast.error(<p>{res.data.errorMessage}</p>);
                  })
                  .catch((error) => {
                    const errorList = errorResponseToList(error);
                    if (errorList.length > 0) {
                      errorList.forEach((e) => actions.setFieldError(e.field, e.message));
                    } else {
                      toast.error(error.message);
                    }
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                    props.handleClose();
                  });
              }
            }
          }}
        >
          {(formikProps) => {
            const { handleSubmit, values, isSubmitting, setFieldValue } = formikProps;
            const emailAccounts = props.emailAccounts
              .filter((email) => email.isConnected === true)
              .map((email) => {
                return {
                  label: email.name + " (" + email.email + ")",
                  value: email.id,
                };
              });
            const emailAccountOptions = [
              // { label: "Outbase TestBot", value: 0 },
              // {
              //   label: "--------------------------------------------------------------------",
              //   value: -1,
              // },
              // },
              ...emailAccounts,
            ];

            return (
              <Modal
                title="Send test email"
                handleClose={() => handleCloseModal()}
                handleSave={() => {
                  if (activeStep === 0) {
                    setActiveStep(activeStep + 1);
                  } else {
                    setActiveStep(activeStep - 1);
                    handleSubmit();
                  }
                }}
                show={props.showModal}
                closeButtonText={activeStep === 0 ? "Cancel" : "Back"}
                saveButtonText={activeStep === 0 ? "Next" : "I'm done"}
                saveButtonDisabled={isSubmitting}
                btnType="submit"
              >
                <Form>
                  <Container>
                    <HorizontalStepper steps={steps} orientation="horizontal" setActiveStep={setActiveStep} activeStep={activeStep} />
                    {activeStep === 0 && (
                      <TestInfoStep
                        formikProps={formikProps}
                        subjectValue={values.subject}
                        initialEmailTemplatesOptions={initialEmailTemplatesOptions}
                        emailTemplate={emailTemplate}
                        initialEmailTemplateChosen={initialEmailTemplateChosen}
                        initialEmailTemplateId={values.initialEmailTemplateId}
                        emailAccountOptions={emailAccountOptions}
                        emailAccountIdValue={values.emailAccountId}
                        setFieldValue={setFieldValue}
                        sendToValue={values.sendTo}
                      />
                    )}
                    {activeStep === 1 && (
                      <TestVariableStep
                        formikProps={formikProps}
                        basicVariables={basicVariables}
                        initialEmailVariables={initialEmailVariables}
                        customVariables={customVariables}
                        dictionary="dictionary"
                        subjectValue={values.subject}
                        dictionaryValues={values.dictionary}
                        initialEmailTemplateDictionaryValues={values.initialEmailTemplateDictionary}
                      />
                    )}
                  </Container>
                </Form>
              </Modal>
            );
          }}
        </Formik>
      </Permission>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    initialEmailTemplates: state.globalEmailTemplate.initialEmailTemplates,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchInitialEmailTemplates: () => dispatch(fetchInitialEmailTemplates()),
    fetchEmailAccountsSelectList: () => dispatch(fetchEmailAccountsSelectList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendTestEmailForm);
