export const columns = [
  {
    label: "User Name",
    field: "fullName",
    type: "text",
    sort: "asc",
    searchable: true,
    searchType: "text",
    flex: 1,
  },
  {
    label: "Email Address",
    field: "emailAddress",
    type: "text",
    sort: "asc",
    searchable: true,
    searchType: "text",
    flex: 1,
  },
  {
    label: "Organization Name",
    field: "organizationName",
    type: "text",
    sort: "asc",
    searchable: true,
    searchType: "text",
    flex: 1,
  },
  {
    label: "Entity",
    field: "entityType",
    type: "text",
    sort: "asc",
    searchable: true,
    searchType: "text",
    flex: 1,
  },
  {
    label: "Activity Date",
    field: "activityDate",
    type: "date",
    sort: "asc",
    searchable: true,
    searchType: "date",
    flex: 1,
  },
  {
    label: "Activity Details",
    field: "activityDetails",
    type: "actions",
    sort: false,
    flex: 1,
  },
];
