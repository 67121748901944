import { api } from "./api";
import { SendingScheduleDetailsModel } from "@models/schedule/SendingScheduleDetailsModel";

export const sendingScheduleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSchedule: builder.query<SendingScheduleDetailsModel, number>({
      query: (campaignId) => ({
        url: `/sending-schedule?campaignId=${campaignId}`,
        method: "GET",
      }),
      providesTags: ["SendingSchedule"],
    }),
  }),
});

export const { useGetScheduleQuery } = sendingScheduleApi;
