import * as actionTypes from "../actions/actionTypes";

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  error: "",
  creditInfo: {},
};

const fetchCreditInfoRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
  };
};

const fetchCreditInfoSuccess = (state, action) => {
  const newState = { ...state };
  let newCreditInfo = { ...newState.creditInfo };
  newCreditInfo = action.result;
  newState.isLoaded = true;
  newState.isLoading = false;
  newState.creditInfo = newCreditInfo;
  return newState;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORGANIZATION_CREDIT_INFO_REQUEST:
      return fetchCreditInfoRequest(state, action);
    case actionTypes.FETCH_ORGANIZATION_CREDIT_INFO_SUCCEEDED:
      return fetchCreditInfoSuccess(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
