import { AxiosResponse } from "axios";
import api, { API, formUrl } from "../../helper/api/api";

import { CampaignObjectModel } from "@models/assistant/AssistantModels";
import { OrganizationSettingsModel } from "@models/organization/OrganizationSettingsModel";
import { templateSettingAIChainTemplatesEnum } from "@helper/enums/templateSettingAIChainTemplatesEnum";
import { temperatureAIChainTemplatesEnum } from "@helper/enums/temperatureAIChainTemplatesEnum";

/* params definitions start */
interface checkForEmailProviderTypeParams {
  email: string;
  setAccountType: React.Dispatch<React.SetStateAction<string | null>>;
}
interface createCampaignRequestParams {
  campaignObject: CampaignObjectModel;
  executionId: number;
  organization: OrganizationSettingsModel;
  user: any;
}
/* params definitions end */

export const checkForEmailProviderType = async ({ email, setAccountType }: checkForEmailProviderTypeParams) => {
  let type: string | null = null;
  await api(formUrl(API.emailAccounts.mxrecords, { email }))
    .then((response: AxiosResponse<any>) => {
      setAccountType(response.data.name);
      type = response.data.name;
    })
    .catch((error) => {
      console.log(error);
    });
  return type;
};

export const createCampaignRequest = ({ campaignObject, executionId, organization, user }: createCampaignRequestParams) => {
  return {
    emailAccountId: campaignObject.emailAccountId,
    emailAccounts: campaignObject.emailAccounts,
    signature: campaignObject.signature,
    searchKey: campaignObject.audience.searchKey,
    executionId: executionId,
    request: {
      initialTemplate: {
        website: organization.domain,
        companyName: organization.name,
        senderName: user.firstName,
        description: campaignObject.companyDescription.description,
        usp: campaignObject.companyDescription.productInformation,
        painPoint: campaignObject.companyDescription.problemInformation,
        temperature: temperatureAIChainTemplatesEnum.initial,
        companySizeTargeting: campaignObject.audience.companySize,
        tone: campaignObject.tone,
        templateSettings: templateSettingAIChainTemplatesEnum.templateSetting,
        additionalInstructions: "",
      },
      followUps: [
        {
          followUpSettingId: 1,
          temperature: temperatureAIChainTemplatesEnum.followup,
          additionalInformation: "",
        },
        {
          followUpSettingId: 2,
          temperature: temperatureAIChainTemplatesEnum.followup,
          additionalInformation: "",
        },
        {
          followUpSettingId: 3,
          temperature: temperatureAIChainTemplatesEnum.followup,
          additionalInformation: "",
        },
      ],
    },
    type: 3,
  };
};

export const getShortenedFullName = (fullName: string) => {
  const parts = fullName.split(" ");
  if (parts.length >= 2) {
    return parts[0] + " " + parts[parts.length - 1].charAt(0) + ".";
  } else if (parts.length === 1) {
    return fullName;
  } else {
    return "Unknown";
  }
};
