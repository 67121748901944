import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Moment from "moment";
import _ from "lodash";

//Helpers
import { useOrgDateFormat } from "../../../helper/hooks/useOrgDateFormat";
import { filterData, getFilterValue, filtersToQueryString, filterSingle } from "../../../helper/tableConstants";
import { useQueryTableFilters } from "../../../helper/hooks/useQueryTableFilters";

//Redux
import * as actions from "../../../store/Companies/Table/actions/index";
import { fetchStatusCountForCompanies, fetchIndustryList, fetchSizeList } from "../../../store/Companies/Global/actions/companies";

//Styles
import styles from "./Companies.module.scss";

//Icons & Images
import UploadCloud2LineIcon from "remixicon-react/UploadCloud2LineIcon";
import UserSearchLineIcon from "remixicon-react/UserSearchLineIcon";
import EmptyAudiencesList from "../../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";

//Components
import Table from "../../../components/DataTable/Table";
import StatusCountRow from "../../../components/DataTable/StatusCountRow/StatusCountRow";
import TableFiltersRow from "../../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import { columns } from "./columns";
import SkeletonTableFilterRow from "../../../components/UI/Skeletons/components/SkeletonTableFilterRow/SkeletonTableFilterRow";
import SkeletonTable from "../../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import SkeletonTwoRowsCard from "../../../components/UI/Skeletons/components/SkeletonTwoRowsCard/SkeletonTwoRowsCard";
import Button from "../../../components/UI/Button/Button";
import EmptyState from "../../../components/UI/EmptyState/EmptyState";
import TableOptionsRow from "../../../components/DataTable/TableOptionsRow/TableOptionsRow";
import useRouteParams from "@hooks/useRouteParams";

const Companies = (props) => {
  const location = useLocation();
  const history = useHistory();

  let dateFormat = useOrgDateFormat();
  const { param, rows, fetchCompanies, initiate, isInitiated, clearInitiated } = props;
  const { campaignId, organizationId } = useRouteParams();
  let filterTypes = ["Industry", "Size", "Status"];
  let state = useQueryTableFilters(filterTypes);
  let originalRender = true;
  const [tableRows, setTableRows] = useState([]);
  const [dataTableColumns, setDataTableColumns] = useState(_.cloneDeep(columns));
  const [activeStatus, setActiveStatus] = useState(null);

  let image = <EmptyAudiencesList />;
  useEffect(() => {
    originalRender = false;
  }, [campaignId]);

  const applyDataTableColumnsChange = () => {
    let updateState = {
      params: param,
      start: 0,
      page: 0,
      length: 10,
      sort: {},
      search: "",
    };
    initiate(updateState);
  };

  useEffect(() => {
    if (param.filter.length === 0) {
      let updateState = {
        params: {
          filter: [
            { type: "Campaign", value: [campaignId] },
            { type: "fromcampaign", value: [true] },
          ],
          start: 0,
          page: 0,
          length: 10,
          sort: {},
          search: "",
        },
      };
      if (state.params && state.params.filter && state.params.filter.length > 0) {
        state.params.filter.map((filter) => {
          if (filter.type === "Status") {
            setActiveStatus(parseInt(filter.value));
          }
        });
      }

      initiate(updateState);
      props.fetchIndustryList();
      props.fetchSizeList();
    } else {
      if (state.params && state.params.filter) {
        if (state.params.filter.length > 0) {
          state.params.filter.map((filter) => {
            if (filter.type === "Status") {
              setActiveStatus(parseInt(filter.value));
            }
          });
        }
        if (!state.params.filter.find((n) => n.type === "Campaign")) state.params.filter.push({ type: "Campaign", value: [campaignId] });
        if (!state.params.filter.find((n) => n.type === "fromcampaign")) state.params.filter.push({ type: "fromcampaign", value: [true] });
      }

      initiate(state);
      props.fetchIndustryList();
      props.fetchSizeList();
    }
  }, []);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  useEffect(() => {
    if (isInitiated) {
      if (originalRender && param.filter.length > 0) {
        const querystring = location.search;
        let excludedFilter = ["Campaign"];
        fetchCompanies(param);
        const newQuerystring = filtersToQueryString(param, excludedFilter);
        if (querystring !== newQuerystring) {
          history.replace(location.pathname + newQuerystring);
        }
        let filters = [...param.filter];
        props.fetchStatusCountForCompanies(filters);
      }
    }
  }, [param]);

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    rowsTemp.map((r) => {
      r.checkBox = <Checkbox />;

      if (r.dateLastProspected != null && r.dateLastProspected !== "") {
        r.dateLastProspected = Moment(r.dateLastProspected).format(dateFormat);
      } else {
        r.dateLastProspected = "-";
      }

      r.companyName = (
        <OverlayTrigger
          key={r.companyName}
          placement={"bottom"}
          overlay={
            <Popover id={`tooltip-${r.cleanCompanyName ? r.cleanCompanyName : r.companyName}`}>
              <Popover.Body>{r.cleanCompanyName ? r.cleanCompanyName : r.companyName}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden max-x-content">
            <Link
              className="tableLink"
              to={{
                pathname: "/" + props.orgId + "/company-activity-overview/" + r.id,
              }}
            >
              {r.cleanCompanyName ? r.cleanCompanyName : r.companyName}
            </Link>
          </div>
        </OverlayTrigger>
      );
      r.campaignName = (
        <OverlayTrigger
          key={r.campaignName}
          placement={"auto-start"}
          overlay={
            <Popover id={`tooltip-${r.campaignName}`}>
              <Popover.Body>{r.campaignName}</Popover.Body>
            </Popover>
          }
        >
          <div className="text-overflow-hidden">{r.campaignName}</div>
        </OverlayTrigger>
      );

      r.createdDate = Moment(r.createdDate).format(dateFormat);
      return r;
    });
    setTableRows(rowsTemp);
  }, [rows]);

  let table = null;
  let selects = null;
  let statusContRow = null;
  let tableOptionsRow = null;

  let selectRowOptions = [
    {
      option: props.listIndustry,
      change: (i) => filterData("Industry", i, props.setFilter),
      placeholder: "Industry",
      value: getFilterValue(param.filter, "Industry"),
    },
    {
      option: props.listSize,
      change: (s) => filterData("Size", s, props.setFilter),
      placeholder: "Size",
      value: getFilterValue(param.filter, "Size"),
    },
  ];

  const handleAudienceBuilder = (e) => {
    history.push("/" + props.orgId + "/search");
  };

  const handleAudience = (e) => {
    history.push("/" + props.orgId + "/lists");
  };

  const buttons = (
    <div className="flex">
      <Button id="campaignSettingsCompanyUploadAudience" onClick={handleAudience} variant="secondary" link={"audiences"}>
        <UploadCloud2LineIcon size={16} className="mR5" /> Upload list
      </Button>
      <Button id="campaignSettingsCompanyAudienceBuilder" onClick={handleAudienceBuilder} variant="primary" link={"search"}>
        <UserSearchLineIcon size={16} className="mR5 iconWhite" /> Search contacts
      </Button>
    </div>
  );

  const filterStatus = (status) => {
    props.changePage(0);
    filterSingle("Status", status, param, props.setFilter, props.clearFilter);
  };

  if (props.error) {
    table = <h2>Error getting Companies</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
    statusContRow = (
      <div className="flex w-5 mT10 mB30">
        {[1, 2, 3, 4, 5, 6].map((n) => (
          <SkeletonTwoRowsCard key={n} />
        ))}
      </div>
    );
  }

  if (props.isLoaded && props.statusCount) {
    statusContRow = <StatusCountRow statusCount={props.statusCount} click={filterStatus} activeStatus={activeStatus} />;
  }

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Companies"}
          columns={dataTableColumns}
          rows={tableRows}
          param={param}
          parentSort={props.sortData}
          parentCallback={props.changePage}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          changeNumRecords={props.numRecords}
          scrollY={true}
          tableHeight={490}
          variant="small"
          tableClassName={`${styles.companyTable} m-max-height-none`}
          forcePageChanged={true}
        />
      ) : (
        <EmptyState image={image} title="No results found" />
      );

    if (props.isLoadedlistCampaigns) {
      selectRowOptions = [
        {
          option: props.listIndustry,
          change: (i) => filterData("Industry", i, props.setFilter),
          placeholder: "Industry",
          value: getFilterValue(param.filter, "Industry"),
        },
        {
          option: props.listSize,
          change: (s) => filterData("Size", s, props.setFilter),
          placeholder: "Size",
          value: getFilterValue(param.filter, "Size"),
        },
      ];
    }
    selects = (
      <div className={styles.filterRow}>
        <TableFiltersRow selects={selectRowOptions} placeholder="companies" changeFilter={(text) => props.searchRecords(text)} />
        {buttons}
      </div>
    );

    tableOptionsRow =
      tableRows.length > 0 ? (
        <TableOptionsRow
          applyChanges={applyDataTableColumnsChange}
          exportTable={null}
          importData={null}
          dataTableColumns={dataTableColumns}
          setDataTableColumns={setDataTableColumns}
          allDataTableColumns={columns}
        />
      ) : null;
  }

  return (
    <>
      {selects}
      {statusContRow}
      {tableOptionsRow}
      {table}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.companies.params,
    isInitiated: state.companies.isInitiated,
    isLoading: state.companies.isLoading,
    isLoaded: state.companies.isLoaded,
    error: state.companies.error,
    rows: state.companies.rows,
    totalCount: state.companies.totalCount,
    pageCount: state.companies.pageCount,
    listSize: state.globalCompanies.size,
    listIndustry: state.globalCompanies.listIndustry,
    statusCount: state.globalCompanies.statusCount,
    orgId: state.user.organization.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIndustryList: () => dispatch(fetchIndustryList()),
    fetchSizeList: () => dispatch(fetchSizeList()),
    fetchCompanies: (param) => dispatch(actions.fetchCompanies(param)),
    fetchStatusCountForCompanies: (obj) => dispatch(fetchStatusCountForCompanies(obj)),
    changePage: (page) => dispatch(actions.changePage(page)),
    sortData: (obj) => dispatch(actions.sortData(obj)),
    numRecords: (obj) => dispatch(actions.numRecords(obj)),
    searchRecords: (value) => dispatch(actions.searchRecords(value)),
    setFilter: (type, value) => dispatch(actions.setFilter(type, value)),
    clearFilter: (type) => dispatch(actions.clearFilter(type)),
    initiate: (state) => dispatch(actions.initiate(state)),
    clearInitiated: () => dispatch(actions.clearInitiated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
