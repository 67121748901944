import React from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment_timezone from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

//Styles
import "./Calendar.scss";

const CalendarView = (props) => {
  moment_timezone.tz.setDefault("UTC");

  const { events, onEventChange } = props;
  let now = moment().toDate();

  const handleEventClick = (event) => {
    onEventChange(event.event.extendedProps);
  };

  const eventsFormat = events.map((event) => {
    let eventFormat = { ...event };
    if (eventFormat.start.startsWith("2021-04-12") && eventFormat.campaignId === 3) {
    }
    // eventFormat.start = moment.utc(eventFormat.start).format("YYYY-MM-DD HH:mm:ss");
    // eventFormat.end = moment.utc(eventFormat.end).format("YYYY-MM-DD HH:mm:ss");
    eventFormat.extendedProps = { ...event };
    return eventFormat;
  });

  function renderEventContent(eventInfo) {
    let event = eventInfo.event.extendedProps;
    let customEventStyle = {
      backgroundColor: event.color,
      border: `1px solid ${event.color}`,
      paddingLeft: "5px",
      paddingRight: "5px",
      color: "#fff",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      padding: "2px 5px",
      fontSize: "12px",
      borderRadius: "0 5px 5px 0",
      "&:hover": {
        backgroundColor: event.color,
      },
    };

    let startDate = moment(event.start).format("YYYY-MM-DD");
    let endDate = moment(event.end).format("YYYY-MM-DD");
    let nowDate = moment(now).format("YYYY-MM-DD");

    let startIsSameOrBefore = startDate < nowDate;
    let endIsSameOrBefore = endDate < nowDate;
    let today = startDate === nowDate;

    if (startIsSameOrBefore && endIsSameOrBefore) {
      customEventStyle = {
        ...customEventStyle,
        position: "relative",
        opacity: 0.6,
      };
    } else if (today) {
      customEventStyle = {
        ...customEventStyle,
        opacity: 1,
      };
    } else {
      customEventStyle = {
        ...customEventStyle,
        backgroundColor: "transparent",
        color: `${event.color}`,
        position: "relative",
        borderLeft: `4px solid ${event.color}`,
        ":after": {
          content: "",
          position: "absolute",
          top: 0,
          right: "auto",
          bottom: 0,
          left: 0,
          width: "4px",
          backgroundColor: "red",
          height: "4px",
        },
      };
    }

    return (
      <>
        <div style={customEventStyle}>{eventInfo.event.title}</div>
      </>
    );
  }

  return (
    <div className="calendarWrapper">
      <FullCalendar
        timeZone="UTC"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "today,prev,next title",
          center: "",
          right: "dayGridMonth,timeGridWeek",
        }}
        height="calc(100vh - 145px)"
        firstDay={1}
        buttonText={{
          week: "Week",
          month: "Month",
          today: "Today",
          prev: "Back",
          next: "Next",
        }}
        fixedWeekCount={false}
        initialView="dayGridMonth"
        editable={false}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={eventsFormat}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
      />
    </div>
  );
};

export default CalendarView;
