import { TemplatesDataTableModel } from "@models/emailTemplate/TemplatesDataTableModel";
import { campaignApi } from "../api/campaignApi";
import { complianceApi } from "../api/complianceApi";
import { CampaignDataTableModel } from "../models/campaign/CampaignDataTableModel";
import { CSCQuestionsAndAnswersDetailsModel } from "../models/compliance/CSCQuestionsAndAnswersDetailsModel";
import createDataTableSlice from "./createDataTableSlice";
import { emailTemplateApi } from "@api/emailTemplateApi";
import { ExplorerContactDataTableModel } from "@models/audienceExplorer/ExplorerContactDataTableModel";
import { audienceExplorerApi } from "@api/audienceExplorerApi";
import { ExplorerCompanyDataTableModel } from "@models/audienceExplorer/ExplorerCompanyDataTableModel";

export const campaignsDataTable = createDataTableSlice<CampaignDataTableModel>("campaignsDataTable", campaignApi.endpoints.getCampaigns);
export const questionsDataTable = createDataTableSlice<CSCQuestionsAndAnswersDetailsModel>(
  "questionsDataTable",
  complianceApi.endpoints.getQuestionsAndAnswers
);
export const templatesDataTable = createDataTableSlice<TemplatesDataTableModel>(
  "templatesDataTable",
  emailTemplateApi.endpoints.getTemplates
);

export const explorerContactsDataTable = createDataTableSlice<ExplorerContactDataTableModel>(
  "explorerContactsDataTable",
  audienceExplorerApi.endpoints.getContacts
);

export const explorerCompaniesDataTable = createDataTableSlice<ExplorerCompanyDataTableModel>(
  "explorerCompaniesDataTable",
  audienceExplorerApi.endpoints.getCompanies
);
