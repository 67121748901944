import React from "react";

const EmailExcluded = (props) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.12946 9.6125H1.5V6V1.97319L7.2653 5.97864C7.35263 6.0372 7.4563 6.06858 7.5625 6.06858C7.6687 6.06858 7.77237 6.0372 7.8597 5.97864L13.8304 1.97319V4.27409V5.97864L14.875 6.5V1.5125C14.8746 1.24409 14.7644 0.986792 14.5686 0.796998C14.3728 0.607204 14.1073 0.500402 13.8304 0.5H1.29464C1.01767 0.500268 0.752123 0.607028 0.556274 0.796851C0.360426 0.986673 0.250277 1.24405 0.25 1.5125V9.6125C0.250414 9.88091 0.360608 10.1382 0.556427 10.328C0.752245 10.5178 1.01771 10.6246 1.29464 10.625H9.12946V9.6125ZM12.6807 1.5125L7.5625 4.9469L2.44427 1.5125H12.6807Z"
      fill="#2A3347"
    />
    <circle cx="12.5" cy="9.5" r="3.9" stroke="#EE3030" stroke-width="1.2" />
    <path d="M10 7.01436L15.8777 12.2896" stroke="#EE3030" stroke-width="1.2" />
  </svg>
);

export default EmailExcluded;
