import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

import _ from "lodash";

//Helpers
import { PERMISSIONS } from "../../../../helper/permissionConstants";

//Images & Icons
import InformationFillIcon from "remixicon-react/InformationFillIcon";

//Styles
import styles from "./TestInfoStep.module.scss";

//Components
import FormikInput from "../../../../components/UI/Formik/FormikInput";
import FormikSelect from "../../../../components/UI/Formik/FormikSelect";
import FormikCheckbox from "../../../../components/UI/Formik/FormikCheckbox";
import Permission from "../../../../components/auth/Permission";
import { useSlice } from "@hooks/useSlice";

const TestInfoStep = (props) => {
  const {
    emailTemplatesRows,
    emailAccountsInOrganization,
    setIncludePreviousMessage,
    setEmailAccountId,
    setAddSignature,
    uniqueTemplateIds
  } = props;

  const { campaign } = useSlice((state) => state.campaign);
  const emailAccountsInCampaign = campaign.emailAccounts;

  let emailAccounts = {};
  if (emailAccountsInCampaign && emailAccountsInCampaign.length > 0) {
    emailAccounts = emailAccountsInCampaign
      .filter((email) => email.isConnected === true)
      .map((email) => {
        return {
          label: email.emailName + " (" + email.email + ")",
          value: email.emailAccountId,
        };
      });
  } else {
    emailAccounts = props.emailAccountsInOrganization
      .filter((email) => email.isConnected === true)
      .map((email) => {
        return {
          label: email.name + " (" + email.email + ")",
          value: email.id,
        };
      });
  }
  const emailAccountOptions = [...emailAccounts];

  return (
    <div>
      <Permission has={PERMISSIONS.emailTemplates.sendTestEmail}>
          <Row>
            <Col xl={12}>
              {uniqueTemplateIds.map((item, index) => {
                let itemIndex = index + 1;
                return (
                <>
                <label className="f12 mB5">{"Subject " + itemIndex}</label>
                <FormikInput 
                  name={"subject[" + item + "]"}
                  placeholder={"Subject " + itemIndex} 
                  value={props.subjectValues[item]}
                  type="text" />
                </>);
              })}
            </Col>
          </Row>
        <FormikSelect
          label="Send from"
          name={props.emailAccountId}
          options={emailAccountOptions}
          placeholder="Choose email account"
          value={props.emailAccountIdValue}
          isMenuPortalTargetDisabled={true}
        />
        <FormikInput label="Send To" name={props.sendTo} placeholder="Type here..." value={props.sendToValue} disabled={true} />
      </Permission>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    emailAccountsInOrganization: state.globalEmailAccounts.listEmailAcconts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TestInfoStep);
