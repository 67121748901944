import * as actionTypes from "./actionTypes";
import api, { API, formUrl } from "../../../helper/api/api";

export const fetchListOfCampaignsRequest = () => {
  return {
    type: actionTypes.FETCH_LIST_OF_CAMPAIGNS_REQUEST,
  };
};

export const fetchListOfCampaignsSuccess = (campaigns) => {
  return {
    type: actionTypes.FETCH_LIST_OF_CAMPAIGNS_SUCCEEDED,
    result: campaigns,
  };
};

export const fetchListOfCampaignsFailed = (error) => {
  return {
    type: actionTypes.FETCH_LIST_OF_CAMPAIGNS_FAILED,
    result: error,
  };
};

export const fetchListOfCampaigns = (companyId) => {
  return (dispatch) => {
    dispatch(fetchListOfCampaignsRequest());
    return api(
      formUrl(API.companyActivityOverview.getListOfCampaings, {
        companyId: companyId,
      })
    )
      .then((response) => {
        dispatch(fetchListOfCampaignsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchListOfCampaignsFailed(error.message));
      });
  };
};

export const fetchCompanyDetailsRequest = () => {
  return {
    type: actionTypes.FETCH_COMPANY_DETAILS_REQUEST,
  };
};

export const fetchCompanyDetailsSuccess = (responseDetails) => {
  return {
    type: actionTypes.FETCH_COMPANY_DETAILS_SUCCEEDED,
    result: responseDetails,
  };
};

export const fetchCompanyDetailsFailed = (error) => {
  return {
    type: actionTypes.FETCH_COMPANY_DETAILS_FAILED,
    result: error,
  };
};

export const fetchCompanyDetails = (companyId) => {
  return (dispatch) => {
    dispatch(fetchCompanyDetailsRequest());
    return api(
      formUrl(API.companyActivityOverview.getCompanyDetails, {
        companyId: companyId,
      })
    )
      .then((response) => {
        dispatch(fetchCompanyDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchCompanyDetailsFailed(error.message));
      });
  };
};

export const fetchProspectsStatusesRequest = () => {
  return {
    type: actionTypes.FETCH_PROSPECTS_STATUSES_REQUEST,
  };
};

export const fetchProspectsStatusesSuccess = (statuses) => {
  return {
    type: actionTypes.FETCH_PROSPECTS_STATUSES_SUCCEEDED,
    result: statuses,
  };
};

export const fetchProspectsStatusesFailed = (error) => {
  return {
    type: actionTypes.FETCH_PROSPECTS_STATUSES_FAILED,
    result: error,
  };
};

export const fetchProspectsStatuses = (companyId) => {
  return (dispatch) => {
    dispatch(fetchProspectsStatusesRequest());
    return api(
      formUrl(API.companyActivityOverview.getStatuses, { companyId: companyId })
    )
      .then((response) => {
        dispatch(fetchProspectsStatusesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchProspectsStatusesFailed(error.message));
      });
  };
};

export const fetchTimelineResponsesRequest = () => {
  return {
    type: actionTypes.FETCH_TIMELINE_RESPONSES_REQUEST,
  };
};

export const fetchTimelineResponsesSuccess = (responses) => {
  return {
    type: actionTypes.FETCH_TIMELINE_RESPONSES_SUCCEEDED,
    result: responses,
  };
};

export const fetchTimelineResponsesFailed = (error) => {
  return {
    type: actionTypes.FETCH_TIMELINE_RESPONSES_FAILED,
    result: error,
  };
};

export const fetchTimelineResponses = (params, id) => {
  const data = {
    param: params,
    id: id,
  };

  return (dispatch) => {
    dispatch(fetchTimelineResponsesRequest());
    return api(API.companyActivityOverview.getResponses, data)
      .then((response) => {
        dispatch(fetchTimelineResponsesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTimelineResponsesFailed(error.message));
      });
  };
};
