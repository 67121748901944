import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { changeSalesDocumentsImageSuccess } from "../../../store/SalesDocuments/actions/salesDocuments";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";

//Icons
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";

//Components
import ImageUploader from "../../../components/UI/FileUploader/ImageUploader";
import Modal from "../../../components/UI/Modal/Modal";

const ChangeImageForm = (props) => {
  const { id, description, name, showModal, handleClose } = props;
  const [currentDocumentName, setCurrentDocumentName] = useState(name);

  useEffect(() => {
    if (showModal) {
      setCurrentDocumentName(name);
    }
  }, [name, showModal]);

  return (
    <Formik
      initialValues={{
        id: id,
        image: null,
        description: description,
      }}
      validationSchema={Yup.object({
        id: Yup.number().required("Required"),
        description: Yup.string().required("Required"),
      }).required("Required")}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("image", values.image);
        formData.append("description", values.description);

        api(API.salesDocument.updateImage, formData)
          .then((response) => {
            props.changeSalesDocumentsImageSuccess(response.data);
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, handleChange, values, resetForm, dirty } = formikProps;

        const handleModalClose = () => {
          resetForm();
          setCurrentDocumentName(name);
          handleClose();
        };

        const handleDeleteDocument = () => {
          setFieldValue("image", null);
          setCurrentDocumentName("");
        };

        return (
          <Modal
            title="Update Image"
            handleClose={handleModalClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText="Save"
            saveButtonDisabled={isSubmitting}
            btnType="submit"
          >
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Field name="id" type="hidden" />
              <ImageUploader
                buttonText="Your computer"
                variant="primary"
                handleFile={(file) => {
                  setFieldValue("image", file);
                  setCurrentDocumentName(file.name);
                }}
                disabled={!!currentDocumentName}
              />
              <div className="mL10">
                <span>{currentDocumentName || "No document uploaded"}</span>
                {currentDocumentName && (
                  <DeleteBinLineIcon size={16} className="iconGray cursor_pointer mL10" onClick={handleDeleteDocument} />
                )}
              </div>
              <label>Title</label>
              <Field name="description" type="text" value={values.description} onChange={handleChange} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeSalesDocumentsImageSuccess: (document) => {
      dispatch(changeSalesDocumentsImageSuccess(document));
    },
  };
};

export default connect(null, mapDispatchToProps)(ChangeImageForm);
