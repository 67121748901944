import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

//Redux
import {
  fetchDealNavigator,
  publishDealNavigator,
  changeEditModeValue,
  chooseDealNavigatorTemplate,
} from "../../store/DealNavigator/actions/dealNavigator";

//Helpers
import { toastMessages } from "../../helper/toastMessagesConstants";
import { permissionHelper } from "../../helper/permissionHelper";
import { PERMISSIONS } from "../../helper/permissionConstants";

//Styles
import styles from "./DealNavigator.module.scss";
import StageStyles from "./components/Stages/Stage.module.scss";

//Icons & Images
import SignedOrder from "../../assets/DealNavigator/SignedOrder/SignedOrder";
import IconSignedOrder from "../../assets/Icons/IconSignOrder/IconSignOrder";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import AddLineIcon from "remixicon-react/AddLineIcon";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import DiscardDraftModel from "./components/DiscardModel";
import SaveChangesFooter from "../../components/SaveChangesFooter/SaveChangesFooter";
import Button from "../../components/UI/Button/Button";
import Stage from "./components/Stages/Stage";
import AddStageForm from "./components/Stages/AddStageForm";
import Permission from "../../components/auth/Permission";
import EmptyDealNavigatorModal from "./components/EmptyDealNaviagator/Modal/EmptyDealNavigatorModal";
import ResetModal from "./components/Reset/ResetModal";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";

const DealNavigator = (props) => {
  const { edit, orgId } = props;
  const { stages, id, isInTemplateMode } = props.dealNavigator;

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Define your sales pipeline, either using our template or by creating your own."}
      href={"https://help.outbase.com/user-guide/deal-navigator"}
      linkText="Read More"
      buttonId="startCampaignTour"
      disableStartTourButton
    />
  );

  useEffect(() => {
    if (orgId !== 1) props.fetchDealNavigator(edit);
    else if (templateId != null) props.fetchDealNavigator(edit, templateId);
  }, [edit]);

  useEffect(() => {
    if (orgId !== 1) {
      setShowDealNavTemplateModal(stages.length === 0 && permissionHelper(props.userRole, PERMISSIONS.dealNavigator.edit));
    }
  }, [stages]);

  const [showAddStageModal, setShowAddStageModal] = useState(false);

  const [templateId, setTemplateId] = useState(null);

  const handleAddStageClose = () => {
    setShowAddStageModal(false);
    adjustScale();
  };

  const handleAddStageShow = () => setShowAddStageModal(true);

  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

  const handleDiscardChangesClose = () => setShowDiscardChangesModal(false);
  const handleDiscardChangesShow = () => setShowDiscardChangesModal(true);

  const publishDraftVersion = () => {
    if (isNaN(templateId)) {
      props.publishDealNavigator();
    } else {
      props.publishDealNavigator(templateId);
    }
    toast.success(toastMessages.dealNavigator.successPublishDraftVersion);
  };

  const enterEditMode = () => {
    props.changeEditModeValue(true);
    toast.info(toastMessages.dealNavigator.infoEnterEditMode);
  };

  const handleSubmitTemplate = (id) => {
    if (orgId !== 1) {
      props.chooseDealNavigatorTemplate(id);
      props.fetchDealNavigator(edit);
    } else {
      props.fetchDealNavigator(edit, id);
      setTemplateId(id);
      setShowDealNavTemplateModal(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(stages) && !_.isNull(stages)) {
      adjustScale();
    }
  }, [stages]);

  const adjustScale = () => {
    const listOfStages = document.getElementById("list-of-stages");
    const totalStages = stages.length;
    let scale = 1 + totalStages / 10.0;
    let width = 534;

    let gradientsOfSVGImg = [
      `var(--first-stage-color)`,
      `var(--secund-stage-color)`,
      `var(--third-stage-color)`,
      `var(--fourth-stage-color)`,
      `var(--fifth-stage-color)`,
      `var(--sixth-stage-color)`,
      `var(--seventh-stage-color)`,
      `var(--eighth-stage-color)`,
    ];

    let pathD = [
      "M212 0H0L19 72H193L212 0Z",
      "M258 0H0L19.1484 72H238.852L258 0Z",
      "M304 0H0L19 72H285L304 0Z",
      "M350 0H0L19.0517 72H330.948L350 0Z",
      "M396 0H0L19 72H377L396 0Z",
      "M442 0H0L19 72H423L442 0Z",
      "M488 0H0L19 72H469L488 0Z",
      "M534 0H0L19 72H515.203L534 0Z",
    ];

    let widthStage = ["212px", "258px", "304px", "350px", "396px", "442px", "488px", "534px"];

    let gradientsOfTextColor = ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff", "#fff"];

    let listChildrens = [];
    if (!_.isEmpty(listOfStages)) listChildrens = listOfStages.children;

    if (!_.isEmpty(listChildrens)) {
      for (let index = 0; index < listChildrens.length; index++) {
        let stageImg = listChildrens[index].firstElementChild.firstElementChild.firstElementChild;
        let stageText = listChildrens[index].firstElementChild.firstElementChild.lastChild;
        let stageWidth = listChildrens[index].firstElementChild.firstElementChild.firstElementChild;
        if (index !== listChildrens.length - 1) {
          // stageImg.setAttribute('style', `width: ${width}px; transform: scale(${scale}, 1);`);
          // stageImg.setAttribute('style', `width: ${width}px; height: 72px`);
          // stageImg.setAttribute('style', `transform: scale(${scale}, 1);`);
        }

        let stageSVGPath = stageImg.firstChild;
        let stageSVGPathD = stageImg.firstChild;
        stageSVGPath.setAttribute("style", `fill: ${gradientsOfSVGImg[listChildrens.length - index - 1]}`);
        stageSVGPathD.setAttribute("d", ` ${pathD[listChildrens.length - index - 1]}`);
        stageText.setAttribute("style", `color: ${gradientsOfTextColor[listChildrens.length - index - 1]}`);
        stageWidth.setAttribute("style", `width: ${widthStage[listChildrens.length - index - 1]}`);

        scale -= 0.118;
        width -= 50;
      }
    }
  };
  const [showDealNavTemplateModal, setShowDealNavTemplateModal] = useState(stages.length === 0 || orgId === 1);

  const handleNavTemplateClose = () => setShowDealNavTemplateModal(false);
  const handleTemplateModalShow = () => setShowDealNavTemplateModal(true);

  const [showResetNavTemplateModal, setShowResetNavTemplateModal] = useState(false);

  const handleResetTemplateClose = () => setShowResetNavTemplateModal(false);
  const handleResetModalShow = () => setShowResetNavTemplateModal(true);
  let preview = isInTemplateMode ? "Preview" : "";
  return (
    <>
      {edit ? (
        <>
          <PageContentWrapper className={styles.dealNavigatorPageWrapper}>
            <HeaderTitle title="Deal Navigator" icon={icon} />
            <div className={`${styles.navigatorButton} pB10 flex justify_end`}>
              {stages.length < 7 ? (
                <Permission has={PERMISSIONS.dealNavigator.edit}>
                  <Button id="addNesStage" variant="primary" onClick={handleAddStageShow}>
                    <AddLineIcon className="iconWhite mR5" size={18} />
                    Add new stage
                  </Button>
                </Permission>
              ) : null}
            </div>
            <Accordion id="list-of-stages" className={styles.navigatorStagesWrapper}>
              {stages.map((stage, index) => (
                <Stage key={index} {...stage} index={index} adjustScale={adjustScale} />
              ))}
              {!_.isEmpty(stages) ? (
                <>
                  <div className={StageStyles.PitchsWrapper}>
                    <div className={`${StageStyles.signedOrderImg} ${StageStyles.pitchLevelImg}`}>
                      <button className={`${StageStyles.StyledAccordionToggle} cursor_default`}>
                        <SignedOrder />
                        <span className={styles.signedOrderText}>
                          <IconSignedOrder imgStyle={styles.orderIcon} />
                          Signed Order!
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </Accordion>

            <Permission has={PERMISSIONS.dealNavigator.edit}>
              <SaveChangesFooter
                cancelClick={handleDiscardChangesShow}
                saveChangesClick={publishDraftVersion}
                cancelButtonText="Discard"
                saveButtonText="Publish"
              />

              <DiscardDraftModel handleClose={handleDiscardChangesClose} showModal={showDiscardChangesModal} templateId={templateId} />
            </Permission>
          </PageContentWrapper>

          <Permission has={PERMISSIONS.dealNavigator.edit}>
            <AddStageForm handleClose={handleAddStageClose} showModal={showAddStageModal} dealNavigatorId={id} />
          </Permission>
        </>
      ) : (
        <>
          <PageContentWrapper className={styles.dealNavigatorPageWrapper}>
            <HeaderTitle title={"Deal Navigator " + preview} icon={icon} />
            <div className={` ${styles.navigatorButton} pB10 flex justify_end`}>
              {isInTemplateMode ? null : (
                <Permission has={PERMISSIONS.dealNavigator.edit}>
                  <Button id="reset" variant="editButton" classes={styles.resetButton} onClick={handleResetModalShow}>
                    Reset
                  </Button>
                  <Button id="editMode" className="transformUppercase" variant="primary" onClick={enterEditMode}>
                    <Edit2LineIcon size={16} className="iconWhite mR5" />
                    Edit mode
                  </Button>
                </Permission>
              )}
              <ResetModal handleClose={handleResetTemplateClose} showModal={showResetNavTemplateModal} />
              <EmptyDealNavigatorModal
                handleClose={handleNavTemplateClose}
                showModal={showDealNavTemplateModal}
                handleSubmit={handleSubmitTemplate}
              />
            </div>
            <Accordion id="list-of-stages" className={styles.navigatorStagesWrapper}>
              {stages.map((stage, index) => (
                <Stage key={index} {...stage} index={index} />
              ))}
              {!_.isEmpty(stages) ? (
                <>
                  <div className={StageStyles.PitchsWrapper}>
                    <div
                      className={`${
                        isInTemplateMode ? StageStyles.signedOrderImg + " " + StageStyles.pitchLevelImg : StageStyles.pitchLevelImg
                      }`}
                    >
                      <button className={`${StageStyles.StyledAccordionToggle} ${styles.signed_order} cursor_default`}>
                        <SignedOrder />
                        <span className={styles.signedOrderText}>
                          <IconSignedOrder imgStyle={styles.orderIcon} />
                          Signed Order!
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </Accordion>
            {isInTemplateMode ? (
              <SaveChangesFooter
                cancelClick={handleTemplateModalShow}
                saveChangesClick={publishDraftVersion}
                cancelButtonText="Back"
                saveButtonText="Use Template Sample"
              />
            ) : null}
          </PageContentWrapper>
        </>
      )}
    </>
  );
};

// MAPSTATETOPROPS
const mapStateToProps = (state) => {
  return {
    orgId: state.user.organization.id,
    dealNavigator: state.dealNavigator,
    edit: state.dealNavigator.isInEditMode,
    isLoaded: state.dealNavigator.isLoaded,
    isInTemplateMode: state.dealNavigator.isInTemplateMode,
    userRole: state.user.organization.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDealNavigator: (editMode, templateId) => dispatch(fetchDealNavigator(editMode, templateId)),
    publishDealNavigator: (templateId) => dispatch(publishDealNavigator(templateId)),
    changeEditModeValue: (editMode) => dispatch(changeEditModeValue(editMode)),
    chooseDealNavigatorTemplate: (templateId) => dispatch(chooseDealNavigatorTemplate(templateId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealNavigator);
