import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { InputLabel } from "@mui/material";
import { connect } from "react-redux";
import { toast } from "react-toastify";

//Redux
import { fetchDomains } from "../../../store/Domains/actions/domains";

//Helpers
import { errorResponseToList } from "../../../helper/errorHelper";
import api, { API } from "../../../helper/api/api";
import { isSpecialCharacter } from "../../../helper/validationConstants";

//Styles
import styles from "./UpdateDomainForm.module.scss";

//Images & Icons
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import FileCopyLineIcon from "remixicon-react/FileCopyLineIcon";

//Components
import Modal from "../../../components/UI/Modal/Modal";
import Button from "../../../components/UI/Button/Button";
import FormikInput from "../../../components/UI/Formik/FormikInput";

const UpdateDomainForm = (props) => {
  const domain = { ...props.domain };
  const [isVerifying, setIsVerifying] = useState(false);
  const trackingServerDomain = import.meta.env.VITE_TRACKING_DOMAIN;

  const handlePrefixChanged = (event, values, setFieldValue) => {
    // If value is the same with the original value, then take the original dns verified value.
    // Else set to null and force a new verification.
    var isSame = domain.trackingDomainPrefix === event.target.value;
    var verified = isSame ? domain.dnsVerified : null;
    setFieldValue("dnsVerified", verified);
  };

  const handleVerifyClick = (values, setFieldValue, handleSubmit) => {
    setIsVerifying(true);

    if (!isSpecialCharacter(values.trackingDomainPrefix)) {
      handleSubmit();
      setIsVerifying(false);
      return;
    }
    const data = {
      trackingDomainUrl: values.trackingDomainPrefix + "." + values.domainUrl,
    };

    api(API.domains.verify, data)
      .then((response) => {
        setFieldValue("dnsVerified", response.data.verified);
        if (response.data.message && response.data.message !== "") {
          if (response.data.verified) {
            setIsVerifying(false);
            toast.info(response.data.message);
          } else {
            setIsVerifying(false);
            toast.warn(response.data.message);
          }
        }
      })
      .catch((error) => {
        const errorList = errorResponseToList(error);

        if (errorList.length > 0) {
          // errorList.forEach((e) =>
          //   actions.setFieldError(e.field, e.message)
          // );
        } else {
          toast.error(error.message);
        }
        setIsVerifying(false);
      });
  };

  return (
    <Formik
      initialValues={{
        id: domain.id,
        domainUrl: domain.domainUrl,
        trackingDomainPrefix: domain.trackingDomainPrefix != null ? domain.trackingDomainPrefix : "",
        dnsVerified: domain.dnsVerified,
        trackOpens: domain.trackOpens,
        trackClicks: domain.trackClicks,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        id: Yup.number().required("Required"),
        trackingDomainPrefix: Yup.string()
          .required("Required")
          .test("isSpecialCharacter", "Special characters are not allowed in a domain", isSpecialCharacter),
        dnsVerified: Yup.bool().test("Is true", "Must verify the domain", (value) => value === true),
        trackOpens: Yup.bool().required("Required"),
        trackClicks: Yup.bool().required("Required"),
      }).required("Required")}
      onSubmit={(values, actions) => {
        if (isVerifying) return;
        api(API.domains.update, values)
          .then((response) => {
            props.fetchDomains();
            props.handleClose();
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);

            if (errorList.length > 0) {
              errorList.forEach((e) => {
                actions.setFieldError(e.field, e.message);

                if (e.field === "trackingDomainPrefix") {
                  actions.setFieldValue("dnsVerified", false);
                }
              });
            } else {
              toast.error(error.response.data);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, setFieldValue, handleChange, values } = formikProps;

        let dnsStatus = null;

        if (values.dnsVerified === true) {
          dnsStatus = (
            <div className="flex_center mT10">
              <CheckboxCircleFillIcon size={22} color="var(--first-stage-color)" />

              <span className="mL5">The domain is successfully verified.</span>
            </div>
          );
        } else if (values.dnsVerified === false) {
          dnsStatus = (
            <div className="flex_center mT10">
              <CloseCircleFillIcon size={22} color="var(--primary-color)" />
              <span className="mL5">The domain is not yet verified</span>
            </div>
          );
        } else {
          dnsStatus = <span>Press Verify to check this domain.</span>;
        }

        return (
          <Modal
            title="Custom tracking domain set up"
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            closeButtonText="Cancel"
            saveButtonText={values.dnsVerified ? "Save" : "Verify first"}
            saveButtonDisabled={isSubmitting || !values.dnsVerified}
            btnType="submit"
            dialogClassName={styles.domainsModal}
          >
            <div className={styles.modalContent}>
              <p className="mB15">
                Set up a custom tracking domain through your hosting provider to track opens, clicks, and unsubscribes and fully white-label
                your Outbase campaigns.
              </p>
              <div className={styles.step}>
                <p className={`${styles.stepDetails} mB15 flex`}>
                  Add a CNAME record in your DNS server that points to:
                  <span className={`${styles.domain} flex`}>
                    {trackingServerDomain}{" "}
                    <FileCopyLineIcon
                      name="Copy"
                      size={18}
                      onClick={() => navigator.clipboard.writeText(trackingServerDomain)}
                      className="cursor_pointer"
                    />
                  </span>
                </p>
                <p className="font_italic">CNAME Record</p>
              </div>
              <Form>
                <Field value={values.id} type="hidden" />
                <Field value={values.dnsVerified} type="hidden" />
                {/* <FormikInput
                // label="Your domain name"
                name="domainUrl"
                type="text"
                placeholder="company.com"
                value={values.domainUrl}
                readOnly
              /> */}
                <div className="flex mB20">
                  <FormikInput
                    label="Name:"
                    name="trackingDomainPrefix"
                    type="text"
                    inputclass="mb20"
                    formgroupclass="mB5"
                    placeholder={"em"}
                    value={values.trackingDomainPrefix}
                    onChange={(e) => {
                      handleChange(e);
                      handlePrefixChanged(e, values, setFieldValue);
                    }}
                    append={
                      <>
                        <InputLabel className={styles.domainUrl}>{"." + values.domainUrl}</InputLabel>
                        {/* <InputLabel className={styles.domainUrl}>
                        {"." + values.domainUrl}
                      </InputLabel>

                      <Button
                        type="button"
                        variant="dark"
                        onClick={() => {
                          handleVerifyClick(values, setFieldValue);
                        }}
                      >
                        Verify
                      </Button> */}
                      </>
                    }
                  />
                  <div className={styles.valueWrapper}>
                    <p className={styles.label}>Value/Point to:</p>
                    <p className={styles.trackingApi}>{trackingServerDomain}</p>
                  </div>
                </div>
                <div className={styles.step}>
                  <div className={`${styles.verifyWrapper} flex space_between`}>
                    <p className={`${styles.label} mB15`}>Once you’ve added the CNAME record as above, click verify:</p>
                    <Button
                      id="verifyButton"
                      type="button"
                      variant="dark"
                      classes="m0"
                      onClick={() => {
                        handleVerifyClick(values, setFieldValue, handleSubmit);
                      }}
                      isLoading={isVerifying}
                      loadingText="Verifying"
                    >
                      Verify
                    </Button>
                  </div>
                </div>
                {dnsStatus}

                {/* <div className="mT15 flex">
                  <FormikCheckbox
                    name="trackOpens"
                    label="Track Opens"
                    checked={values.trackOpens}
                    disabled={!values.dnsVerified}
                  />
                  <FormikCheckbox
                    name="trackClicks"
                    label="Track Clicks"
                    checked={values.trackClicks}
                    disabled={!values.dnsVerified}
                  />
                </div> */}
              </Form>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDomains: () => {
      dispatch(fetchDomains());
    },
  };
};

export default connect(null, mapDispatchToProps)(UpdateDomainForm);
