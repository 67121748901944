import { FC } from "react";
import Modal from "@components/UI/Modal/Modal";

interface Props {
  handleClose: () => void;
  showModal: boolean;
}

const UserOptedOutModal: FC<Props> = (props) => {
  return (
    <Modal
      title="User Opted Out"
      handleClose={props.handleClose}
      handleSave={props.handleClose}
      show={props.showModal}
      oneButton
      saveButtonText="OK"
    >
      <div>
        <p>This user has opted out of phone number reveals.</p>
        <p>Note: You will not be charged for this request!</p>
      </div>
    </Modal>
  );
};

export default UserOptedOutModal;
