import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { RadioGroup } from "@mui/material";

//Helpers
import { checkIfIsChecked } from "../../../../../helper/checkIfIsChecked";

//Images & Icon
import informal from "../../../../../assets/Images/OutbaseAssistant/informal_tone.svg";
import formal from "../../../../../assets/Images/OutbaseAssistant/formal_tone.svg";

//Styles
import styles from "./TemplateToneStep.module.scss";

//Components
import FormikRadio from "../../../../../components/UI/Formik/FormikRadio";

const TemplateToneStep = ({ organizationSettings, setOrganizationSettings }) => {
  return (
    <Formik
      initialValues={{
        selectedTone: organizationSettings.tone == "Formal" ? 1 : 0,
      }}
    >
      {(formikProps) => {
        const { values, handleChange, setFieldValue } = formikProps;

        const handleToneChange = (selectedTone) => {
          setFieldValue("selectedTone", selectedTone);
          setOrganizationSettings({
            ...organizationSettings,
            tone: selectedTone === 0 ? "Informal" : "Formal",
          });
        };

        return (
          <Form>
            <div className={`flex_center flex_column ${styles.templateTone}`}>
              <div className="textCenter mB30">
                <h1 className="f16 font700 mB15">What tone best resonates with your potential buyers</h1>
                <p className={`f14 secondary_text_color textCenter ${styles.description}`}>
                  Select the informal or formal email example, depending on which version most suits your list. Please note these are just
                  examples to guide our system to write your emails.
                </p>
              </div>
              <RadioGroup
                id="selectedTone"
                className={styles.radioButtonWrapper}
                name="selectedTone"
                onChange={(e) => {
                  handleChange(e);
                  handleToneChange(e.target.value);
                }}
                value={values.selectedTone}
              >
                <div id="informal" className="flex_column p5">
                  <FormikRadio
                    name="selectedTone"
                    labelText="Informal tone"
                    radioLabelClass={styles.label}
                    onClick={() => handleToneChange(0)}
                    checked={checkIfIsChecked(values.selectedTone, 0)}
                  />
                  <img src={informal} className="cursor_pointer" alt="informal" onClick={() => handleToneChange(0)} />
                </div>
                <div id="formal" className="flex_column p5">
                  <FormikRadio
                    name="selectedTone"
                    labelText="Formal tone"
                    radioLabelClass={styles.label}
                    onClick={() => handleToneChange(1)}
                    checked={checkIfIsChecked(values.selectedTone, 1)}
                  />
                  <img src={formal} className="cursor_pointer" alt="formal" onClick={() => setFieldValue("selectedTone", 1)} />
                </div>
              </RadioGroup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TemplateToneStep;
