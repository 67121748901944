import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

//Helpers
import api, { API } from "../../../../../helper/api/api";
import { errorResponseToList } from "../../../../../helper/errorHelper";
import { toastMessages } from "../../../../../helper/toastMessagesConstants";

// Styles
import styles from "./BillingAddressModal.module.scss";

//Components
import Modal from "../../../../../components/UI/Modal/Modal";
import BillingInfo from "../../../../Pricing/components/BillingInfo/BillingInfo";

const BillingAddressModal = (props) => {
  const { billingInfo } = props;
  const [hasStates, setHasStates] = useState(false);

  return (
    <Formik
      initialValues={{
        id: billingInfo !== null ? billingInfo?.id : 0,
        firstName: billingInfo !== null ? billingInfo?.firstName : "",
        lastName: billingInfo !== null ? billingInfo?.lastName : "",
        email: billingInfo !== null ? billingInfo?.email : "",
        organizationName: billingInfo !== null ? billingInfo?.organizationName : "",
        address: billingInfo !== null ? billingInfo?.address : "",
        city: billingInfo !== null ? billingInfo?.city : "",
        country: billingInfo !== null ? billingInfo?.country : "",
        state: billingInfo !== null ? billingInfo?.state : "",
        zip: billingInfo !== null ? billingInfo?.zip : "",
        vatNumber: billingInfo !== null ? billingInfo?.vatNumber : "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
        organizationName: Yup.string().required("Required"),
        address: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        country: Yup.string().required("Required"),
        zip: Yup.string().required("Required"),
      })}
      onSubmit={(values, actions) => {
        if (hasStates && values.state === "") {
          actions.setFieldError("state", "Required");
          return;
        } else if (!hasStates && values.state !== "") {
          values.state = "";
        }

        api(API.billingAndPayment.createUpdateBillingInfo, values)
          .then((response) => {
            props.handleCreateUpdate();
            toast.success(toastMessages.billing.successCreateUpdateBillingInfo);
          })
          .catch((error) => {
            const errorList = errorResponseToList(error);
            if (errorList.length > 0) {
              errorList.forEach((e) => actions.setFieldError(e.field, e.message));
            } else {
              toast.error(error.message);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { handleSubmit, values, setFieldValue, isSubmitting } = formikProps;
        return (
          <Modal
            dialogClassName={`customModal ${styles.paymentModal}`}
            title={"Billing address"}
            handleClose={props.handleClose}
            handleSave={handleSubmit}
            show={props.showModal}
            oneButton={false}
            saveButtonText={billingInfo !== null ? "Save" : "Add"}
            btnType="submit"
            closeButtonText={"Close"}
            saveButtonDisabled={isSubmitting}
          >
            <Container>
              <Row>
                <Col xl={12}>
                  <div className={styles.inputWrapper}>
                    <BillingInfo values={values} setHasStates={setHasStates} setFieldValue={setFieldValue} />
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default BillingAddressModal;
