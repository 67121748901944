import React from "react";
import svg from "./mail-megaphone.svg";

interface MailMegaphoneProps {
  imgStyle?: string;
}

const MailMegaphoneIllustration: React.FC<MailMegaphoneProps> = ({ imgStyle }) => (
  <img src={svg} className={imgStyle} alt="Arrow to the top" />
);

export default MailMegaphoneIllustration;
