import { api } from "./api";
import { CampaignWizard } from "../models/entities/CampaignWizard";

const campaignWizardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLatestCampaignWizard: builder.query<CampaignWizard, void>({
      query: () => "campaign-wizard/get-latest",
    }),
    getLatestCampaignWizardForUser: builder.query<CampaignWizard, void>({
      query: () => "campaign-wizard/get-latest-for-user",
    }),
  }),
});

export const { useGetLatestCampaignWizardQuery, useGetLatestCampaignWizardForUserQuery } = campaignWizardApi;
