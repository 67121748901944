import { Tabs, Tab } from "react-bootstrap";

// Styles
import styles from "./Filters.module.scss";

//Images & Icons
import Filter from "../../../../assets/Icons/Filter/Filter";

// Components
import SectionTitle from "../../../../components/UI/SectionTitle/SectionTitle";
import SectionCompany from "../../../../assets/Icons/SectionCompany/SectionCompany";
import Person from "../../../../assets/Icons/Person/Person";
import Recommended from "../Recommended/Recommended";
import CompanyFilterGroup from "./CompanyFiltersGroup";
import ContactFilterGroup from "./ContactsFilterGroup";

interface FiltersProps {
  resetForm: () => void;
  filtersIncluded: number;
}

const Filters: React.FC<FiltersProps> = (props) => {
  return (
    <div id="filtersPannel" className={styles.filtersPanel}>
      <SectionTitle
        titleClass={styles.sectionFilter}
        title="Filters"
        icon={<Filter imgStyle={styles.iconFunnel} />}
        numberCounter={props.filtersIncluded}
        rightContent={
          <p id="clearFilter" className={styles.clearAll} onClick={() => props.resetForm()}>
            Clear all
          </p>
        }
      />
      <div className={styles.panelBody}>
        {props.filtersIncluded === 0 ? <span className={styles.filterErrorMessage}>Please select at least one filter.</span> : null}

        <Recommended />

        <Tabs className="custom_tabs custom_tabs_icon" defaultActiveKey={1}>
          <Tab
            eventKey={1}
            title={
              <>
                <SectionCompany imgStyle="mR5" />
                Companies
              </>
            }
          >
            <CompanyFilterGroup />
          </Tab>

          <Tab
            eventKey={2}
            title={
              <>
                <Person imgStyle="mR5" /> Contacts
              </>
            }
          >
            <ContactFilterGroup />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Filters;
