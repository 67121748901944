import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from "react-toastify";
import Moment from "moment";

//Redux
import { clearInitiated } from "../../store/WorkflowSteps/actions/workflowSteps";
import { fetchAudiences as fetchAudiencesList } from "../../store/Audiences/Global/actions/audiences";
import {
  fetchAudiences,
  changePage,
  sortData,
  numRecords,
  searchRecords,
  setFilter,
  clearFilter,
  filterRecords,
  initiate,
  updateRows,
} from "../../store/Audiences/Table/actions/audiences";
import _ from "lodash";

//Helpers
import api, { API, formUrl } from "../../helper/api/api";
import { numberOfRecords, filterData, filtersToQueryString, getFilterValue } from "../../helper/tableConstants";
import { PERMISSIONS } from "../../helper/permissionConstants";
import { useQueryTableFilters } from "../../helper/hooks/useQueryTableFilters";
import { audienceTypeEnum } from "../../helper/enums/audienceTypeEnum";
import { useGroupDecimalSeparator } from "../../helper/hooks/useGroupDecimalSeparator";
import { toastMessages } from "../../helper/toastMessagesConstants";
import { useOrgDateFormat } from "../../helper/hooks/useOrgDateFormat";
import { SIGNALR_CONSTANTS } from "../../helper/signalrConstants";
import { isMoreThanASecondAgo } from "../../helper/timeHelper";
import { SelectListEnum } from "../../helper/hooks/query/SelectListEnum";
import useSelectList from "../../helper/hooks/query/useSelectList";

//Styles
import "../Audiences/audiencesStyle.scss";
import styles from "./Audiences.module.scss";

//Images & Icons
import IconAudienceExplorer from "../../assets/Icons/IconAudienceExplorer/IconIncludeContact";
import NewAudienceIcon from "../../assets/Icons/NewAudienceIcon/NewAudienceIcon";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import EmptyAudiencesList from "../../assets/Images/EmptyAudiencesList/EmptyAudiencesList";

//Components
import HeaderTitle from "../../components/UI/HeaderTitle/HeaderTitle";
import { columns } from "./columns";
import Table from "../../components/DataTable/Table";
import TableFiltersRow from "../../components/DataTable/TableFiltersRow/TableFiltersRow";
import Permission from "../../components/auth/Permission";
import Button from "../../components/UI/Button/Button";
import AudienceUploadForm from "./AudienceUploadForm";
import AudienceContactsExportForm from "./AudienceContactsExportForm";
import AudienceArchiveRestoreForm from "./AudienceArchiveRestoreForm";
import AudiencePushToCampaignForm from "./AudiencePushToCampaignForm";
import SkeletonTable from "../../components/UI/Skeletons/components/SkeletonTable/SkeletonTable";
import EmptyState from "../../components/UI/EmptyState/EmptyState";
import ImportStartedModal from "../AudienceContacts/ImportStartedModal";
import InfoIconTooltip from "../../components/UI/CustomTooltip/InfoIconTooltip";
import AudienceColumn from "./AudiencesTableComponents/AudienceColumn/AudienceColumn";
import StatusColumn from "./AudiencesTableComponents/StatusColumn/StatusColumn";
import SizeColumn from "./AudiencesTableComponents/SizeColumn/SizeColumn";
import ActionColumn from "./AudiencesTableComponents/ActionColumn/ActionColumn";
import TargetingCriteriaColumn from "./AudiencesTableComponents/TargetingCriteriaColumn/TargetingCriteriaColumn";
import AudienceTargetingDrawer from "../AudienceContacts/AudienceTargetingDrawer/AudienceTargetingDrawer";
import PageContentWrapper from "../../components/PageContentWrapper/PageContentWrapper";
import campaignConfigSlice from "@store/Campaigns/campaignConfigSlice";
import { useGetAudiencesInitiatedStatusMutation } from "@api/audienceApi";
import { useSlice } from "@hooks/useSlice";

const Audiences = (props) => {
  const { param, rows, fetchAudiences, isInitiated, initiate, fetchAudiencesList, clearInitiated, updateRows, signal } = props;

  const [chosenAudience, setChosenAudience] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [getAudiencesInitiatedStatus] = useGetAudiencesInitiatedStatusMutation();

  const dispatch = useDispatch();
  const setOpenPushAudienceModal = (value) => dispatch(campaignConfigSlice.actions.setOpenPushAudienceModal(value));

  const handleOpenDrawer = (audience) => {
    setChosenAudience(audience);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setChosenAudience(null);
  };

  let table = null;
  const records = numberOfRecords[0];
  let filterTypes = ["Campaign", "Type", "Status"];
  let state = useQueryTableFilters(filterTypes);
  let separators = useGroupDecimalSeparator();
  let dateFormat = useOrgDateFormat();
  const [tableRows, setTableRows] = useState([]);
  let image = <EmptyAudiencesList />;
  let text = "";
  const intervalId = useRef();
  const [prospectsImportStartedModel, setProspectsImportStartedModel] = useState(null);
  const [showImportStartedModal, setShowImportStartedModal] = useState(false);
  const handleTargetingDrawerShow = () => setDrawerOpen(true);

  const handleCloseImportStartedModal = () => {
    setShowImportStartedModal(false);
  };

  const icon = (
    <InfoIconTooltip
      tooltipBody={"Create, add to, and view all the contacts available to use across your campaigns."}
      href={"https://help.outbase.com/user-guide/data-and-targeting/audiences"}
      linkText="Read More"
      buttonId="startCampaignTour"
      disableStartTourButton
    />
  );

  const { data: audienceStatuses } = useSelectList(SelectListEnum.AudienceStatuses);
  const { data: campaignsList } = useSelectList(SelectListEnum.CampaignsList);
  const { data: listAudienceTypes } = useSelectList(SelectListEnum.ListAudienceTypes);

  useEffect(() => {
    if (signal == null || signal.name == null) return;

    // If this is an object from an old state ignore it.
    if (isMoreThanASecondAgo(signal.time)) return;

    switch (signal.name) {
      case SIGNALR_CONSTANTS.IMPORT_PROSPECTS_STARTED:
        setShowImportStartedModal(true);
        setProspectsImportStartedModel(signal.data);
        break;
      case SIGNALR_CONSTANTS.IMPORT_PROSPECTS_UPDATE: {
        let audienceUpdate = {
          id: signal.data.audienceId,
          audienceSize: signal.data.audienceSize,
          availableContacts: signal.data.audienceAvailable,
          prospectsImported: signal.data.audienceImported,
          prospectsNotImported: signal.data.audienceNotImported,
          currentImportProcessId: signal.data.currentImportProcessId,
          status: signal.data.audienceStatus,
          campaignId: signal.data.campaignId,
          campaignName: signal.data.campaignName,
        };

        let updatedRows = [audienceUpdate];
        updateRows(updatedRows);
        break;
      }

      case SIGNALR_CONSTANTS.IMPORT_PROSPECTS_FINISHED:
        fetchAudiences(param);
        toast.success(toastMessages.audience.setAudienceImportSettings.importFinished);
        break;
      default:
        break;
    }
  }, [signal]);

  useEffect(() => {
    fetchAudiencesList();
    initiate(state);

    return () => {
      if (intervalId && intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInitiated) {
      fetchAudiences(param);
      const querystring = props.location.search;
      const newQuerystring = filtersToQueryString(param);

      if (querystring !== newQuerystring) {
        props.history.replace(props.location.pathname + newQuerystring);
      }
    }
  }, [param, isInitiated]);

  useEffect(() => {
    return () => {
      clearInitiated();
    };
  }, []);

  const [audience, setAudience] = useState("");
  const [updateImportSettingsOnly, setUpdateImportSettingsOnly] = useState(false);

  const openPushAudienceModal = useSlice((state) => state.campaign.openPushAudienceModal);
  const [showAudienceUploadFormModal, setAudienceUploadFormModal] = useState(openPushAudienceModal);

  const handleAudienceUploadFormClose = () => {
    setAudienceUploadFormModal(false);
    setAudience("");
    setUpdateImportSettingsOnly(false);
    setOpenPushAudienceModal(false);
  };
  const handleAudienceUploadFormShow = (audience) => {
    if (audience !== null) {
      setAudience(audience);
    }
    setAudienceUploadFormModal(true);
  };

  const [showAudiencePushToCampaignFormModal, setAudiencePushToCampaignFormModal] = useState(false);
  const handleAudiencePushToCampaignFormClose = () => {
    setAudiencePushToCampaignFormModal(false);
    setAudience("");
    setUpdateImportSettingsOnly(false);
  };

  const handleAudiencePushToCampaignFormShow = (audience) => {
    if (audience.availableContacts) {
      setAudience(audience);
      setAudiencePushToCampaignFormModal(true);
    } else if (audience.availableContacts === 0 && audience.status.props.children === "Unassigned") {
      setAudiencePushToCampaignFormModal(false);
    } else if (audience.availableContacts === 0 && audience.status.props.children === "Assigned") {
      var data = {
        audienceId: audience.id,
        campaignId: [],
      };

      api(API.audience.setAudienceImportSettings, data)
        .then((response) => {
          toast.success(toastMessages.audience.listUpdated);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => fetchAudiences(param));
    }
  };

  const [audienceContactsFileData, setAudienceContactsFileData] = useState("");
  const [showAudienceContactsExportFormModal, setAudienceContactsExportFormModal] = useState(false);
  const handleAudienceContactsExportFormModalClose = () => {
    setAudience("");
    setAudienceContactsFileData("");
    setAudienceContactsExportFormModal(false);
  };
  const handleAudienceContactsExportFormModalShow = (audience) => {
    setAudience(audience);
    setAudienceContactsExportFormModal(true);

    let data = {
      audienceId: audience.id,
    };
    api(formUrl(API.audience.exportAudienceContacts, data, "blob"))
      .then((res) => {
        setAudienceContactsFileData(res.data);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const [audienceArchiveRestoreFormModal, setAudienceArchiveRestoreFormModal] = useState(false);
  const handleAudienceArchiveRestoreFormModalClose = () => {
    setAudience("");
    setAudienceArchiveRestoreFormModal(false);
  };

  const handleAudienceArchiveRestoreFormModalShow = (audience) => {
    if (audience.status.props.status === "Unassigned") {
      let data = {
        audienceId: audience.id,
      };

      api(formUrl(API.audience.archiveRestoreAudience, data))
        .then((res) => {
          toast.success(
            audience.isArchived ? toastMessages.audience.successRestoreAudience : toastMessages.audience.successArchiveAudience
          );
          fetchAudiences(param);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      setAudience(audience);
      setAudienceArchiveRestoreFormModal(true);
    }
  };

  const checkIfActionAssignIsDisable = (audience) => {
    if (audience.status === "Unassigned") {
      if (audience.availableContacts) {
        return false;
      } else {
        return true;
      }
    } else if (audience.status === "Assigned") {
      return false;
    } else {
      return false;
    }
  };

  const handleTooltipText = (audience) => {
    if (audience.status === "Unassigned") {
      if (audience.availableContacts) {
        return "Assign to campaign";
      } else {
        return "Cannot assign List with 0 contacts";
      }
    } else if (audience.status === "Assigned") {
      if (audience.availableContacts) {
        return "Unassign/Reassign";
      } else {
        return "Unassign";
      }
    } else {
      if (audience.availableContacts) {
        return "Unassign/Reassign";
      } else {
        return "Unassign/Reassign";
      }
    }
  };

  const checkAudienceInitiatedStatus = (notInitiatedIds) => {
    if (notInitiatedIds && notInitiatedIds.length === 0) return;

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      getAudiencesInitiatedStatus(notInitiatedIds)
        .unwrap()
        .then((res) => {
          if (res) {
            let rowsTemp = _.cloneDeep(rows);
            let shouldUpdateRows = false;
            res.map((r) => {
              let row = rowsTemp.find((row) => row.id === r.audienceId);
              if (row && r.isInitiated === true) {
                row.isInitiated = r.isInitiated;
                shouldUpdateRows = true;
              }
            });

            if (shouldUpdateRows) {
              updateRows(rowsTemp);
              clearInterval(intervalId.current);
            }
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }, 5000);
  };

  useEffect(() => {
    const rowsTemp = _.cloneDeep(rows);
    let notInitiateIds = [];

    rowsTemp.map((r) => {
      let isInitiated = r.isInitiated;

      if (isInitiated === false && r.type === audienceTypeEnum.Search) {
        notInitiateIds.push(r.id);
      }

      if (r.type === audienceTypeEnum.File) {
        r.source = <>File</>;
      }
      if (r.type === audienceTypeEnum.Search) {
        r.source = <>Search</>;
      }

      r.audienceName = (
        <AudienceColumn
          name={r.name}
          createdByName={r.createdByName}
          isInitiated={isInitiated}
          orgId={props.orgId}
          link={{ pathname: "/" + props.orgId + "/list/" + r.id + "/list-contacts/" + r.type }}
          date={(r.createdDate = Moment(r.createdDate).format(dateFormat))}
          source={r.source}
        />
      );

      if (r.searchURL !== "") {
        r.audienceBuilderSearchURL = (
          <span
            className={isInitiated ? styles.audienceBuilderSearchURL : styles.notInitiated}
            onClick={() => {
              props.history.push("/" + props.orgId + "/search?s=" + r.searchURL + "&id=" + r.id);
            }}
          >
            <OverlayTrigger
              key={r.name}
              placement={"bottom"}
              overlay={
                <Popover id={`assign-tooltip`}>
                  <Popover.Body>Edit list</Popover.Body>
                </Popover>
              }
            >
              <div>
                <Edit2LineIcon size={22} className="mR5 iconGray" />
              </div>
            </OverlayTrigger>
          </span>
        );
      }

      r.targetingCriteria = <TargetingCriteriaColumn searchModel={r.searchModel} maxLength={20} />;

      let actions = (
        <ActionColumn
          key={r.name}
          isInitiated={isInitiated}
          searchURL={r.searchURL}
          audienceBuilderSearchURL={r.audienceBuilderSearchURL}
          isArchived={r.isArchived}
          handleOpenDrawer={handleOpenDrawer}
          availableContacts={r.availableContacts}
          onClick={() => handleAudienceArchiveRestoreFormModalShow(r)}
          type={r.type}
          popoverContent={handleTooltipText(r)}
          status={r.status}
          assignToCampaignOnClick={() => {
            handleAudiencePushToCampaignFormShow(r);
          }}
          uploadOnClick={() => handleAudienceUploadFormShow(r)}
          downloadOnClick={() => handleAudienceContactsExportFormModalShow(r)}
          disabled={checkIfActionAssignIsDisable(r)}
          handleTargetingDrawerShow={handleTargetingDrawerShow}
          setChosenAudience={() => {
            setChosenAudience(r);
          }}
        />
      );
      r.actions = actions;

      r.audienceSizeProgressBar = (
        <SizeColumn
          isInitiated={isInitiated}
          availableContacts={r.availableContacts}
          audienceSize={r.audienceSize}
          separators={separators}
        />
      );

      r.status = (
        <StatusColumn
          disabled={checkIfActionAssignIsDisable(r)}
          isInitiated={isInitiated}
          assignToCampaignOnClick={() => {
            handleAudiencePushToCampaignFormShow(r);
          }}
          status={r.status}
          campaignName={r.campaignName}
          availableContacts={r.availableContacts}
          popoverContent={handleTooltipText(r)}
        />
      );
      r.createdDate = Moment(r.createdDate).format(dateFormat);

      return r;
    });

    checkAudienceInitiatedStatus(notInitiateIds);
    setTableRows(rowsTemp);
  }, [rows]);

  if (props.error) {
    table = <h2>Error getting Lists</h2>;
  }

  if (props.isLoading) {
    table = <SkeletonTable />;
  }

  let selectRowOptions = null;
  let audienceUploadForm = null;
  let audienceEditForm = null;
  let audiencePushToCampaignForm = null;
  // let audienceExplorerPushToCampaignForm = null;
  let audienceContactsExportForm = null;
  let audienceArchiveRestoreForm = null;
  let audienceTargetingDrawer = null;
  let importStartedModal = (
    <ImportStartedModal show={showImportStartedModal} handleClose={handleCloseImportStartedModal} model={prospectsImportStartedModel} />
  );

  if (props.isLoaded) {
    table =
      tableRows.length > 0 ? (
        <Table
          tableName={"Lists"}
          columns={columns}
          rows={tableRows}
          pageCount={props.pageCount}
          totalCount={props.totalCount}
          parentCallback={props.changePage}
          parentSort={props.sortData}
          param={param}
          changeNumRecords={props.numRecords}
          parentSearchHandler={props.searchRecords}
          tableClassName={styles.audiencesTable}
          variant="medium"
          tableHeight={220}
        />
      ) : (
        <EmptyState
          image={image}
          title="No lists found"
          content="No lists to display."
          description={"To create an list use the buttons above to either build a new list or upload a file"}
        />
      );

    selectRowOptions = [
      {
        option: listAudienceTypes,
        change: (o) => filterData("Type", o, props.setFilter),
        placeholder: "Type",
        value: getFilterValue(param.filter, "Type"),
      },
      {
        option: audienceStatuses,
        change: (o) => filterData("Status", o, props.setFilter),
        placeholder: "Status",
        value: getFilterValue(param.filter, "Status"),
      },
      {
        option: campaignsList,
        change: (o) => filterData("Campaign", o, props.setFilter),
        placeholder: "Campaign",
        value: getFilterValue(param.filter, "Campaign"),
      },
    ];

    audienceUploadForm = (
      <AudienceUploadForm
        handleClose={handleAudienceUploadFormClose}
        showModal={showAudienceUploadFormModal}
        listAudiences={props.listAudiences}
        audience={audience}
        getAudiences={() => fetchAudiences(param)}
        orgId={props.orgId}
      />
    );

    audiencePushToCampaignForm = (
      <AudiencePushToCampaignForm
        handleClose={handleAudiencePushToCampaignFormClose}
        showModal={showAudiencePushToCampaignFormModal}
        listAudiences={props.listAudiences}
        // listCampaigns={props.listCampaigns}
        audience={audience}
        updateImportSettingsOnly={updateImportSettingsOnly}
        getAudiences={() => fetchAudiences(param)}
        isFromAudiencesListView={true}
        orgId={props.orgId}
      />
    );

    audienceContactsExportForm = (
      <AudienceContactsExportForm
        handleClose={handleAudienceContactsExportFormModalClose}
        showModal={showAudienceContactsExportFormModal}
        audience={audience}
        audienceContactsFileData={audienceContactsFileData}
      />
    );

    audienceArchiveRestoreForm = (
      <AudienceArchiveRestoreForm
        handleClose={handleAudienceArchiveRestoreFormModalClose}
        showModal={audienceArchiveRestoreFormModal}
        audience={audience}
        getAudiences={() => fetchAudiences(param)}
      />
    );

    audienceTargetingDrawer = (
      <AudienceTargetingDrawer
        audienceName={chosenAudience?.name ?? ""}
        isOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        searchModel={chosenAudience?.searchModel ?? null}
      />
    );
  }

  const buttons = (
    <div className="flex">
      <Permission has={PERMISSIONS.audience.create}>
        <Button id="audienceUpload" variant="secondary" onClick={() => handleAudienceUploadFormShow(null)}>
          <NewAudienceIcon imgStyle="mR5" /> Upload List
        </Button>
      </Permission>
      <Button id="audienceBuilder" isLink={true} variant="primary" link={"search"}>
        <IconAudienceExplorer imgStyle="mR5" /> Search contacts
      </Button>
    </div>
  );

  return (
    <PageContentWrapper className="contentHeight">
      <HeaderTitle title={"Your lists"} icon={icon} rightContent={buttons} />

      <TableFiltersRow selects={selectRowOptions} placeholder="lists" changeFilter={(text) => props.searchRecords(text)} />
      {table}

      {audienceUploadForm}
      {audienceEditForm}
      {audienceContactsExportForm}
      {audienceArchiveRestoreForm}
      {audiencePushToCampaignForm}
      {audienceTargetingDrawer}
      {/* {audienceExplorerPushToCampaignForm} */}
      {importStartedModal}
    </PageContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    param: state.audiences.params,
    isInitiated: state.audiences.isInitiated,
    isLoading: state.audiences.isLoading,
    isLoaded: state.audiences.isLoaded,
    error: state.audiences.error,
    rows: state.audiences.rows,
    totalCount: state.audiences.totalCount,
    pageCount: state.audiences.pageCount,
    listAudiences: state.globalAudiences.listAudiences,
    orgId: state.user.organization.id,
    organizationSettings: state.organizationSettingsGeneral.settings,

    signal: state.signal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAudiences: (param) => dispatch(fetchAudiences(param)),
    fetchAudiencesList: () => dispatch(fetchAudiencesList()),
    changePage: (page) => dispatch(changePage(page)),
    sortData: (obj) => dispatch(sortData(obj)),
    numRecords: (obj) => dispatch(numRecords(obj)),
    searchRecords: (value) => dispatch(searchRecords(value)),
    filterRecords: (obj) => dispatch(filterRecords(obj)),
    setFilter: (type, value) => dispatch(setFilter(type, value)),
    clearFilter: (type) => dispatch(clearFilter(type)),
    initiate: (state) => dispatch(initiate(state)),
    clearInitiated: () => dispatch(clearInitiated()),
    updateRows: (update) => dispatch(updateRows(update)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audiences);
