import React from "react";

const LocationPinIcon = (props) => (
  <svg className={props.imgStyle} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2427 10.8247L6 15.0673L1.75736 10.8247C-0.585787 8.4815 -0.585787 4.68254 1.75736 2.33939C4.10051 -0.00375545 7.89947 -0.00375545 10.2427 2.33939C12.5858 4.68254 12.5858 8.4815 10.2427 10.8247ZM6 9.2487C7.47273 9.2487 8.66667 8.05476 8.66667 6.58203C8.66667 5.10927 7.47273 3.91536 6 3.91536C4.52724 3.91536 3.33333 5.10927 3.33333 6.58203C3.33333 8.05476 4.52724 9.2487 6 9.2487ZM6 7.91536C5.2636 7.91536 4.66667 7.31843 4.66667 6.58203C4.66667 5.84565 5.2636 5.2487 6 5.2487C6.7364 5.2487 7.33333 5.84565 7.33333 6.58203C7.33333 7.31843 6.7364 7.91536 6 7.91536Z"
      fill="#CACCD1"
    />
  </svg>
);

export default LocationPinIcon;
