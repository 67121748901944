import { WebStorageStateStore } from "oidc-client-ts";

const AUTH_URL = import.meta.env.VITE_SERVER_URL;
const IDENTITY_CLIENT_ID = "spa";
const REDIRECT_URL = import.meta.env.VITE_LOCAL_URL + "/login-callback";
const SILENT_REDIRECT_URL = import.meta.env.VITE_LOCAL_URL + "/silentrenew";
const LOGOFF_REDIRECT_URL = import.meta.env.VITE_LOCAL_URL + "/logout-callback";

export const IDENTITY_CONFIG = {
  authority: AUTH_URL, //(string): The URL of the OIDC provider.
  client_id: IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
  login: AUTH_URL + "/login",
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  response_type: "code",
  scope: "openid profile email IdentityServerApi", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),

  // These could go in App.js if we need the state store.
  onSigninCallback: (data) => {
    // Only redirect if we are on login-callback page
    if (window.location.href.indexOf("login-callback") !== -1) {
      window.history.replaceState({}, document.title, "/"); //window.location.pathname);

      window.location.pathname = data?.state?.redirectUrl ?? "";
    }
  },
};

export const METADATA_OIDC = {
  issuer: AUTH_URL,
  jwks_uri: AUTH_URL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: AUTH_URL + "/connect/authorize",
  token_endpoint: AUTH_URL + "/connect/token",
  userinfo_endpoint: AUTH_URL + "/connect/userinfo",
  end_session_endpoint: AUTH_URL + "/connect/endsession",
  check_session_iframe: AUTH_URL + "/connect/checksession",
  revocation_endpoint: AUTH_URL + "/connect/revocation",
  introspection_endpoint: AUTH_URL + "/connect/introspect",
};
