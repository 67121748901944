import { Container, Row, Col } from "react-bootstrap";

//Components
import Modal from "../../components/UI/Modal/Modal";

interface DeleteActiveOrganizationModalProps {
  handleClose: () => void;
  organizationId: string;
  showModal: boolean;
}

const DeleteActiveOrganizationModal: React.FC<DeleteActiveOrganizationModalProps> = (props) => {
  const { handleClose, organizationId } = props;
  return (
    <Modal
      title={"Delete Organization"}
      handleSave={handleClose}
      show={props.showModal}
      saveButtonText="OK"
      btnType="submit"
      oneButton
      size="md"
    >
      <Container>
        <Row>
          <Col xl={12}>
            <p>You have an active paid subscription associated with this organization.</p>
            <p>
              Before proceeding with the deletion of your organizational data, please cancel your subscription through the
              <a href={"/" + organizationId + "/organization-settings/billing-and-payment"}> Billing and Payment </a>
              page to avoid any unwanted charges.
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DeleteActiveOrganizationModal;
