import React from "react";

//Components
import Modal from "../../../components/UI/Modal/Modal";

const ErrorVariablesModal = (props) => {
  return (
    <Modal
      title="Incorrect Variables"
      handleClose={props.handleClose}
      handleSave={props.handleSubmit}
      show={props.showModal}
      oneButton
      saveButtonText="OK"
      btnType="submit"
      backdrop="static"
    >
      <>
        <p>There are incorrect variables in the template and it will prevent emails from being sent out!</p>
        <p>Please check the spelling of all variables and try again</p>
      </>
    </Modal>
  );
};

export default ErrorVariablesModal;
