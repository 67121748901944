import React, { useState } from "react";
import { toast } from "react-toastify";

//Helpers
import api, { API, formUrl } from "../../../../helper/api/api";

//Style
import styles from "./CRMItemList.module.scss";

//Icons
import IconExclamationMark from "../../../../assets/Icons/IconExclamationMark/IconExclamationMark";
import CrmLogo from "../../../../assets/Images/CRM/CrmLogo";

//Components
import Button from "../../../../components/UI/Button/Button";
import Modal from "../../../../components/UI/Modal/Modal";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import DeleteBin6LineIcon from "remixicon-react/DeleteBin6LineIcon";
import CheckLineIcon from "remixicon-react/CheckLineIcon";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";

const CRMItemList = (props) => {
  const { handleConnectCrmShow } = props;
  const [showDeleteCrmModal, setShowDeleteCrmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDisconnectCRM = (integrationId) => {
    setIsLoading(true);
    api(
      formUrl(API.integrations.disconnectIntegration, {
        integrationId: integrationId,
      })
    )
      .then((response) => {
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <>
      {props.currentIntegrations && props.currentIntegrations.length > 0
        ? props.currentIntegrations.map((element) => {
            return (
              <div key={element.id} className={`${styles.listWrapper} ${styles.active}`}>
                <div className={`${styles.logoWrapper}`}>
                  <div className={styles.crmImage}>
                    <CrmLogo integrationName={element.name} />
                    <CheckboxCircleFillIcon size={18} color="var(--first-stage-color)" className={styles.icon} />
                  </div>
                  <p className={styles.crmTitle}>{element.name}</p>
                </div>
                <div className={styles.statusWrapper}>
                  <>
                    <div className={styles.actionsWrapper}>
                      {/* Check if is connected or not */}
                      <Button id="connected" classes={styles.connectedButton} variant="secondary">
                        {element.isConnected ? (
                          <>
                            <CheckLineIcon size={18} className={`mR5 iconSuccess`} width="15" height="100%" />
                            Connected
                          </>
                        ) : (
                          <>
                            <IconExclamationMark iconClass={styles.checkIcon} width="15" height="100%" fill="#76dcaa" />
                            Disconnected
                          </>
                        )}
                      </Button>
                    </div>
                    <div className={styles.actionButtons}>
                      <button className={styles.edit} onClick={() => handleConnectCrmShow()}>
                        <Edit2LineIcon className="iconGray" size={15} />
                      </button>
                      <button className={styles.delete}>
                        <DeleteBin6LineIcon className="iconGray" size={15} onClick={() => setShowDeleteCrmModal(true)} />
                        <Modal
                          title={"Disconnect " + element.name + " CRM"}
                          handleClose={() => setShowDeleteCrmModal(false)}
                          handleSave={() => handleDisconnectCRM(element.id)}
                          show={showDeleteCrmModal}
                          closeButtonText="Cancel"
                          saveButtonText="Yes"
                          btnType="submit"
                          size="md"
                          saveButtonDisabled={isLoading}
                        >
                          The system will disconnect the current integration with {element.name}. Are you sure?
                        </Modal>
                      </button>
                    </div>
                  </>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};

export default CRMItemList;
